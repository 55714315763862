import {Action} from '@ngrx/store';

import {ProductWarrantyType} from '@appModels/core-setup/product-warranty-type/product-warranty-type';

export enum ProductWarrantyTypesActionTypes {
  productWarrantyGetProductWarrantyTypes = '[ProductWarrantyType] get',
  productWarrantyGetProductWarrantyTypesSuccess = '[ProductWarrantyType] get productWarranties success',
  productWarrantyTypeError = '[ProductWarrantyType] error'
}

export class GetProductWarrantyTypes implements Action {
  readonly type = ProductWarrantyTypesActionTypes.productWarrantyGetProductWarrantyTypes;
}

export class GetProductWarrantyTypesSuccess implements Action {
  readonly type = ProductWarrantyTypesActionTypes.productWarrantyGetProductWarrantyTypesSuccess;

  constructor(public payload: ProductWarrantyType[]) {
  }
}


export class ProductWarrantyTypesError implements Action {
  readonly type = ProductWarrantyTypesActionTypes.productWarrantyTypeError;

  constructor(public payload: any) {
  }
}

export type ProductWarrantyTypesActions =
  | GetProductWarrantyTypes
  | GetProductWarrantyTypesSuccess
  | ProductWarrantyTypesError;
