import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {CoreReportingService} from '../core-reporting.service';
import {Consignment} from '@appModels/core-inventory/consignment/consignment';
import {StockingPoint} from '@appModels/core-inventory/stocking-point/stocking-point';
import {ReportSchema} from '@appModels/core-reporting/report-schema/report-schema';

@Injectable({providedIn: 'root'})
export class ReportSchemaService extends CoreReportingService {

  constructor(
    private http: HttpClient,
  ) {
    super(http, '');
  }

  getReportSchemasByTenant(query?): Observable<any> {
    const tenantId = localStorage.getItem('tenant');
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        if (query[val] !== null && query[val] !== undefined && query[val] !== '') {
          params = params.append(val, query[val]);
        }
      });
    }

    params = params.append('tenantId', tenantId);

    return this.http.get(`${this.base_url}reportschemas`,
      {params: params, headers: this.headers}).pipe(
      tap(reportSchemas => this.log(`fetched report schemas`)),
      catchError(this.handleError('getReportSchemas', []))
    );
  }

  getReportSchemasForUser(query?): Observable<ReportSchema[]> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        if (query[val] !== null && query[val] !== undefined && query[val] !== '') {
          params = params.append(val, query[val]);
        }
      });
    }

    params = params.append('tenantId', localStorage.getItem('tenant'));

    return this.http.get<ReportSchema[]>(`${this.base_url}reportschemasforuser`,
      {params: params, headers: this.headers}).pipe(
      tap(reportSchemas => this.log(`fetched report schemas`)),
      catchError(this.handleError<ReportSchema[]>('getReportSchemas', []))
    );
  }

  getReportSchema(reportSchemaid: number): Observable<any> {
    return this.http.get(`${this.base_url}reportschema/${reportSchemaid}`, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched reportSchema id=${reportSchemaid}`)),
      catchError(this.handleError(`getReportSchema id=${reportSchemaid}`))
    );
  }

  getReportSchemaWrapper(reportSchemaid: number): Observable<any> {
    return this.http.get(`${this.base_url}getreportschemawrapper/${reportSchemaid}`, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched reportSchema id=${reportSchemaid}`)),
      catchError(this.handleError(`getReportSchema id=${reportSchemaid}`))
    );
  }

  getReportSchemaFormat(reportSchemaid: number): Observable<any> {
    return this.http.get(`${this.base_url}getreportschemastructure/${reportSchemaid}`, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched reportSchema id=${reportSchemaid}`)),
      catchError(this.handleError(`getReportSchema id=${reportSchemaid}`))
    );
  }

  getDefaultReportSchema(): Observable<{}> {
    return this.http.get(`${this.base_url}defaultreportschema`, {headers: this.headers}).pipe(
      tap((consignment: Consignment) => this.log(`fetched consignment w/ id=${consignment.id}`)),
      catchError(this.handleError<Consignment>('fetched Consignment'))
    );
  }

  saveReportSchemaFile(reportSchema): Observable<{}> {
    return this.http.post(`${this.base_url}reportschemas`, reportSchema, {headers: this.headers}).pipe(
      tap(_ => {}),
      catchError(this.handleError<{}>('addReportSchema'))
    );
  }

  updateReportSchema(reportSchema, reportSchemaId: number): Observable<{}> {
    return this.http.put(`${this.base_url}reportschema/${reportSchemaId}`, reportSchema, {headers: this.headers}).pipe(
      tap(_ => {}),
      catchError(this.handleError<{}>('addReportSchema'))
    );
  }

  deleteReportSchema(reportSchemaId: number): Observable<{}> {
    return this.http.delete<{}>(`${this.base_url}reportschema/${reportSchemaId}`, { headers: this.headers }).pipe(
      tap(_ => this.log(`deleted report schema id=${reportSchemaId}`)),
      catchError(this.handleError<{}>('deleteReportSchema'))
    );
  }

  patchReportSchemaRole(fileId, roleId, canExport?): Observable<any> {
    const canExportParam = canExport ? '?canExport=1' : '?canExport=0'
    return this.http.put(`${this.base_url}report/${fileId}/role/${roleId}${canExportParam}`, '', {headers: this.headers}).pipe(
      tap((res) => this.log(`patch reportSchema w/ roleId=${roleId}`)),
      catchError(this.handleError<StockingPoint>('patchReportSchemaRole'))
    );
  }

  deleteRoleToReportSchema(fileId: number, roleId: number): Observable<any> {
    return this.http.delete<any>(`${this.base_url}report/${fileId}/role/${roleId}`, {headers: this.headers}).pipe(
      tap(_ => this.log(`deleted role to report schema`)),
      catchError(this.handleError<any>('deleted report schema'))
    );
  }

  patchReportSchemaUser(fileId, userId, canExport?): Observable<any> {
    const canExportParam = canExport ? '?canExport=1' : '?canExport=0'
    return this.http.put(`${this.base_url}report/${fileId}/user/${userId}${canExportParam}`, '', {headers: this.headers}).pipe(
      tap((res) => this.log(`patch reportSchema w/ userId=${userId}`)),
      catchError(this.handleError<StockingPoint>('patchReportSchemaRole'))
    );
  }

  deleteUserToReportSchema(fileId: number, userId: number): Observable<any> {
    return this.http.delete<any>(`${this.base_url}report/${fileId}/user/${userId}`, {headers: this.headers}).pipe(
      tap(_ => this.log(`deleted user to report schema`)),
      catchError(this.handleError<any>('deleted report schema'))
    );
  }

  getDataGroups(): Observable<any> {
    return this.http.get(`${this.base_url}reportdatagroups`, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched report data groups`)),
      catchError(this.handleError(`get report data groups`))
    );
  }
}
