import { environment } from "environments/environment";

export const featureFlagsFieldForce:Record<string, boolean> = {
  enableHeldPayments: true,
  enableClawback: !environment.production,
  enableStockWarrantyPayments: !environment.production,
  enableClawbackManagement: true,
  enableSpecialProductVLI: !environment.production,
  enableSalesOverview: !environment.production,
  enableSalesStaffPerformanceHistory:true,
  enableBulkReassignContract: true,
  enableSecurityDepositRegistry: true,
  enablePortableCashbackViewOnPayouts: true,
  enableSepReferral: true,
  enableSecurityWallet:true
}
