import { Action } from '@ngrx/store';

import { CustomerStatus } from '@appModels/core-crm/customer-status/customer-status';

export enum CustomerStatusActionTypes {
  customerStatusGetCustomerStatuses = '[CustomerStatus] get',
  customerStatusGetCustomerStatusesSuccess = '[CustomerStatus] get customerStatuses success',
  customerStatusAddCustomerStatus = '[CustomerStatus] add CustomerStatus',
  customerStatusAddCustomerStatusSuccess = '[CustomerStatus] add customerStatus success',
  customerStatusAddAdditionalCustomerStatusInfo = '[CustomerStatus] add additional CustomerStatus Info',
  customerStatusAddAdditionalCustomerStatusInfoSuccess = '[CustomerStatus] add additional customerStatus Info success',
  customerStatusDeleteCustomerStatus = '[CustomerStatus] delete customerStatus',
  customerStatusDeleteCustomerStatusSuccess = '[CustomerStatus] delete customerStatus success',
  customerStatusGetCustomerStatusById = '[CustomerStatus] get customerStatus by id',
  customerStatusGetCustomerStatusByIdSuccess = '[CustomerStatus] get customerStatus by id success',
  customerStatusUpdateCustomerStatus = '[CustomerStatus] update customerStatus',
  customerStatusUpdateCustomerStatusSuccess = '[CustomerStatus] update customerStatus success',
  customerStatusSearchCustomerStatuses = '[CustomerStatus] search customerStatuses',
  customerStatusSearchCustomerStatusesSuccess = '[CustomerStatus] search customerStatuses success',
  customerStatusSearchCustomerStatusesReset = '[CustomerStatus] search customerStatuses reset',
  customerStatusError = '[CustomerStatus] error'
}

export class GetCustomerStatuses implements Action {
  readonly type = CustomerStatusActionTypes.customerStatusGetCustomerStatuses;
}

export class GetCustomerStatusesSuccess implements Action {
  readonly type = CustomerStatusActionTypes.customerStatusGetCustomerStatusesSuccess;
  constructor(public payload: CustomerStatus[]) {}
}

export class AddCustomerStatus implements Action {
  readonly type = CustomerStatusActionTypes.customerStatusAddCustomerStatus;
  constructor(public payload: CustomerStatus) {}
}

export class AddCustomerStatusSuccess implements Action {
  readonly type = CustomerStatusActionTypes.customerStatusAddCustomerStatusSuccess;
  constructor(public payload: CustomerStatus) {}
}

export class GetCustomerStatusById implements Action {
  readonly type = CustomerStatusActionTypes.customerStatusGetCustomerStatusById;
  constructor(public payload: number) {}
}

export class GetCustomerStatusByIdSuccess implements Action {
  readonly type = CustomerStatusActionTypes.customerStatusGetCustomerStatusByIdSuccess;
  constructor(public payload: CustomerStatus) {}
}

export class UpdateCustomerStatus implements Action {
  readonly type = CustomerStatusActionTypes.customerStatusUpdateCustomerStatus;
  constructor(public payload: CustomerStatus) {}
}

export class UpdateCustomerStatusSuccess implements Action {
  readonly type = CustomerStatusActionTypes.customerStatusUpdateCustomerStatusSuccess;
  constructor(public payload: CustomerStatus) {}
}

export class DeleteCustomerStatus implements Action {
  readonly type = CustomerStatusActionTypes.customerStatusDeleteCustomerStatus;
  constructor(public payload: CustomerStatus) {}
}

export class DeleteCustomerStatusSuccess implements Action {
  readonly type = CustomerStatusActionTypes.customerStatusDeleteCustomerStatusSuccess;
  constructor(public payload: CustomerStatus) {}
}

export class SearchCustomerStatuses implements Action {
  readonly type = CustomerStatusActionTypes.customerStatusSearchCustomerStatuses;
  constructor(public payload: string) {}
}

export class SearchCustomerStatusesSuccess implements Action {
  readonly type = CustomerStatusActionTypes.customerStatusSearchCustomerStatusesSuccess;
  constructor(public payload: CustomerStatus[]) {}
}

export class SearchCustomerStatusesReset implements Action {
  readonly type = CustomerStatusActionTypes.customerStatusSearchCustomerStatusesReset;
}

export class CustomerStatusError implements Action {
  readonly type = CustomerStatusActionTypes.customerStatusError;
  constructor(public payload: any) {}
}

export type CustomerStatusActions =
  | GetCustomerStatuses
  | GetCustomerStatusesSuccess
  | AddCustomerStatus
  | AddCustomerStatusSuccess
  | GetCustomerStatusById
  | GetCustomerStatusByIdSuccess
  | UpdateCustomerStatus
  | UpdateCustomerStatusSuccess
  | DeleteCustomerStatus
  | DeleteCustomerStatusSuccess
  | SearchCustomerStatuses
  | SearchCustomerStatusesSuccess
  | SearchCustomerStatusesReset
  | CustomerStatusError;
