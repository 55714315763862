import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {TenantCalendarService} from '../../../../services/core-masterdata/tenant-calendar/tenant-calendar.service';
import {
  GetAllTenantHoliday,
  GetAllTenantHolidaySuccess,
  RemoveTenantHoliday,
  RemoveTenantHolidaySuccess,
  SaveTenantHoliday,
  SaveTenantHolidaySuccess,
  TenantHolidayActionTypes,
  TenantHolidayError
} from '../../../actions/core-masterdata/tenant-calendar/tenant-holiday.actions';

@Injectable()
export class TenantHolidayEffects {


  
  tenantHolidays$ = createEffect(() => this.actions$.pipe(
    ofType(TenantHolidayActionTypes.tenantHolidayGetAll),
    mergeMap((action: GetAllTenantHoliday) =>
      this.tenantCalendarService
        .getTenantHolidays(action.payload)
        .pipe(
          map(tenantHolidays => new GetAllTenantHolidaySuccess(tenantHolidays)),
          catchError(error => of(new TenantHolidayError(error)))
        )
    )
  ));

  
  tenantHolidaysDelete$ = createEffect(() => this.actions$.pipe(
    ofType(TenantHolidayActionTypes.tenantHolidayRemove),
    mergeMap((action: RemoveTenantHoliday) =>
      this.tenantCalendarService
        .deleteTenantHoliday(action.payload)
        .pipe(
          map(tenantHolidays => new RemoveTenantHolidaySuccess(action.payload)),
          catchError(error => of(new TenantHolidayError(error)))
        )
    )
  ));


  
  saveTenantHolidays$ = createEffect(() => this.actions$.pipe(
    ofType(TenantHolidayActionTypes.tenantHolidaySave),
    mergeMap((action: SaveTenantHoliday) =>
      this.tenantCalendarService
        .saveTenantHolidays(action.payload)
        .pipe(
          map(response => {
            this.toastr.success('Holidays added successfully!', 'Successful!');
            this.router.navigateByUrl('/setup/tenant-calendar/holidays');
            return new SaveTenantHolidaySuccess();
          }),
          catchError(error => of(new TenantHolidayError(error)))
        )
    )
  ));

  constructor(
    private actions$: Actions,
    private toastr: ToastrService,
    private router: Router,
    private tenantCalendarService: TenantCalendarService
  ) {
  }
}
