import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable, of} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';

import {CommissionReport} from '@appModels/core-fieldforce/commission-report/commission-report';
import {CoreIncentivemanagementService} from '../core-incentivemanagement.service';


@Injectable({providedIn: 'root'})
export class CommissionReportService extends CoreIncentivemanagementService {


  constructor(
    private http: HttpClient,
  ) {
    super(http, 'commissionreport');
  }


  filterCommissions(query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        params = params.append(val, query[val]);
      });
    }

    console.log('filtering commissions from service ');
    params = params.append('tenantId', localStorage.getItem('tenant'));
    return this.http.get<any>(this.base_url + 's', {params, headers: this.headers}).pipe(
      tap(customers => this.log(`fetched customers`)),
      catchError(this.handleError('getCustomers', []))
    );
  }

  filterMonthlyCommissions(query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        params = params.append(val, query[val]);
      });
    }

    params = params.append('tenantId', localStorage.getItem('tenant'));
    return this.http.get<any>(this.base_url + 's/monthly', {params, headers: this.headers}).pipe(
      tap(customers => this.log(`fetched monthly commissions`)),
      catchError(this.handleError('filterMonthlyCommissions', []))
    );
  }

  downloadMonthlyCommissionsReport(query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        params = params.append(val, query[val]);
      });
    }

    params = params.append('tenantId', localStorage.getItem('tenant'));
    return this.http.get(this.base_url + 's/monthly/downloadcsv', {
      params,
      headers: this.headers,
      responseType: 'blob'
    }).pipe(
      tap(customers => this.log(`fetched monthly commissions csv`)),
      catchError(this.handleError('downloadMonthlyCommissionsReport', []))
    );
  }


  downloadCommissionsReport(query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        params = params.append(val, query[val]);
      });
    }

    return this.http.get(this.base_url + 's/downloadcsv', {
      params,
      headers: this.headers,
      responseType: 'blob'
    }).pipe(
      tap(customers => this.log(`fetched commissions csv`)),
      catchError(this.handleError('downloadCommissionsReport', []))
    );
  }


  /** GET sales staff definitions by id. Will 404 if id not found */
  getcommission(id: number): Observable<CommissionReport> {
    const url = `${this.base_url}/${id}`;
    console.log('getcommission2');

    return this.http.get<CommissionReport>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched commission id=${id}`)),
      catchError(this.handleError<CommissionReport>(`getcommission id=${id}`))
    );
  }

  /* GET sales staff definitions whose name contains search term */
  searchcommission(tenantId: number, dateFrom: string, dateTo: string): Observable<CommissionReport[]> {

    console.log('searchcommission service');
    return this.http.get<CommissionReport[]>(`${this.base_url}/?tenantId=${tenantId}&dateFrom=${dateFrom}&dateTo=${dateTo}`).pipe(
      tap(_ => this.log(`found commission matching "${tenantId}"`)),
      catchError(this.handleError<CommissionReport[]>('commission', []))
    );
  }

  /* GET sales staff definitions whose name contains search term */
  searchcommissionbytenantid(tenantId: number): Observable<CommissionReport[]> {

    console.log('searchcommissionbvytenantid service');
    return this.http.get<CommissionReport[]>(`${this.base_url}/?tenantId=${tenantId}&dateFrom=&dateTo=`).pipe(
      tap(_ => this.log(`found commission matching "${tenantId}"`)),
      catchError(this.handleError<CommissionReport[]>('commission', []))
    );
  }

  /* GET sales staff definitions whose name contains search term */
  searchcommissionByType(id: number, type: string): Observable<CommissionReport[]> {
    if (!type.trim()) {
      // if not search term, return empty stockingPoint array.
      return of([]);
    }
    return this.http.get<CommissionReport[]>(this.base_url + '/commissions?tenantId=' + id + '&type=' + type, {headers: this.headers}).pipe(
      tap(commission => this.log(`fetched sales Staff Definitions`)),
      catchError(this.handleError('getPossibleLineManagers', []))
    );
  }


}
