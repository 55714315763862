import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';

import {CoreInventoryService} from '../core-inventory.service';
import * as moment from 'moment';


@Injectable({providedIn: 'root'})
export class StockingPointInventoryProductService extends CoreInventoryService {


  constructor(
    private http: HttpClient,
  ) {
    super(http, '');
  }

  /** GET stockingPoint products from the server */
  getStockingPointInventoryProduct(stockingPointId: number, filters: any) {
    const tenantId = localStorage.getItem('tenant');
    const url = `${this.base_url}` + `stockingpoint/${stockingPointId}/products/tenant/${tenantId}`;
    const params = {};

    if (filters != '') {

      if (filters.consignmentCreateDate != null) {
        params['consignmentCreateDate'] = moment(filters.consignmentCreateDate).format('YYYY-MM-DD');
      }
      if (filters.productName != null) {
        params['productName'] = filters.productName;
      }

      if (filters.physicalStatus != null) {
        params['physicalStatus'] = filters.physicalStatus;
      }

      if (filters.searchParam != null) {
        params['searchParam'] = filters.searchParam;
      }
      if (filters.startPage != null) {
        params['startPage'] = Number(filters.startPage);
      }
      if (filters.numberPerPage != null) {
        params['numberPerPage'] = Number(filters.numberPerPage);
      }
    }


    return this.http.get<any>(url, {headers: this.headers, params: params}).pipe(
      tap(stockingPointInventoryproducts => this.log(`fetched stockingPointInventoryProduct`)),
      catchError(this.handleError('getStockingPointInventoryProducts', []))
    );
  }

  /** GET stockingPoint products from warrantySeedstock from the server */
  getWarrantySeedstockProductsFromStockingPoint(stockingPointIds: string, productVersionId: number, serial: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('stockingPointIds', stockingPointIds);
    params = params.append('serial', serial);
    const url = `${this.base_url}` + `stockingpoint/product/${productVersionId}/warrantySeedstock`;
    return this.http.get<any>(url, {params: params, headers: this.headers}).pipe(
      tap(stockingPointInventoryproducts => this.log(`fetched stockingPointInventoryProduct`)),
      catchError(this.handleError('getStockingPointInventoryProducts', []))
    );
  }

  /** GET stockingPoint products summaries by version, status and type */
  getProductsInStockingPointSummary(stockingPointId): Observable<any> {
    const url = `${this.base_url}` + `stockingpoint/${stockingPointId}/summary`;
    return this.http.get<any>(url, {headers: this.headers}).pipe(
      tap(stockingPointInventoryproducts => this.log(`fetched stockingPointInventoryProduct summary`)),
      catchError(this.handleError('getProductsInStockingPointSummary', []))
    );
  }

  downloadInventoryOnStockingPointCsv(stockingPointId, query?): Observable<any> {
    let params = new HttpParams();
    
    if (query !== undefined) {
      Object.keys(query).forEach(val => {
        if (query[val] != null) {
          if (val === 'consignmentCreateDate') {
            params = params.append(val, moment(query[val]).format('YYYY-MM-DD'))
          } else {
            params = params.append(val, query[val]);
          }
        }
      });
    }

    return this.http.get(`${this.base_url}stockingpoint/${stockingPointId}/products/csv`, {
      params,
      headers: this.headers,
      responseType: 'blob'
    }).pipe(
      tap(customers => this.log(`fetched inventory csv`)),
      catchError(this.handleError('downloadInventoryOnStockingPointCsv', []))
    );
  }

}
