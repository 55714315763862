import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';

import {RateValidationQualification} from '@appModels/core-accounting/rate-validation-qualification/rate-validation-qualification';
import {RateValidationQualificationActions, RateValidationQualificationActionTypes} from '@appStore/actions/core-accounting/rate-validation-qualification/rate-validation-qualification.actions';
import * as _ from 'lodash';

export interface State extends EntityState<RateValidationQualification> {
  loaded: boolean;
  loading: boolean;
  error: any;
  selectedRateValidationQualificationId: number;
  searchRateValidationQualifications: RateValidationQualification[];
  paging: any;
}

export const adapter: EntityAdapter<RateValidationQualification> = createEntityAdapter<RateValidationQualification>();

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
  selectedRateValidationQualificationId: null,
  error: null,
  searchTerm: '',
  searchRateValidationQualifications: null,
  paging: null
});

export function reducer(state = initialState, action: RateValidationQualificationActions): State {
  switch (action.type) {
    case RateValidationQualificationActionTypes.rateValidationQualificationGetRateValidationQualifications:
    case RateValidationQualificationActionTypes.rateValidationQualificationGetRateValidationQualificationByTenant:
    case RateValidationQualificationActionTypes.rateValidationQualificationAddRateValidationQualification:
    case RateValidationQualificationActionTypes.rateValidationQualificationAddAdditionalRateValidationQualificationInfo:
    case RateValidationQualificationActionTypes.rateValidationQualificationDeleteRateValidationQualification:
    case RateValidationQualificationActionTypes.rateValidationQualificationUpdateRateValidationQualification:
    case RateValidationQualificationActionTypes.rateValidationQualificationSearchRateValidationQualifications:
    case RateValidationQualificationActionTypes.rateValidationQualificationGetRateValidationQualificationById:
      return {
        ...state,
        loading: true
      };

    case RateValidationQualificationActionTypes.rateValidationQualificationGetRateValidationQualificationsSuccess:
      return adapter.setAll(action.payload.content, {
        ...state,
        loading: false,
        loaded: true,
        paging: _.omit(action.payload, ['content'])
      });

    case RateValidationQualificationActionTypes.rateValidationQualificationGetRateValidationQualificationByTenantSuccess:
      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });

    case RateValidationQualificationActionTypes.rateValidationQualificationGetRateValidationQualificationByIdSuccess:
      return { ...state, selectedRateValidationQualificationId: action.payload.id, loading: false };

    case RateValidationQualificationActionTypes.rateValidationQualificationAddRateValidationQualificationSuccess:
      return adapter.addOne(action.payload, {
        ...state,
        selectedRateValidationQualificationId: action.payload.id,
        loading: false,
        loaded: true
      });

    case RateValidationQualificationActionTypes.rateValidationQualificationUpdateRateValidationQualificationSuccess: {
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state,
          loading: false,
          loaded: true
        }
      );
    }

    case RateValidationQualificationActionTypes.rateValidationQualificationDeleteRateValidationQualificationSuccess: {
      return adapter.removeOne(action.payload.id, {
        ...state,
        loading: false,
        loaded: true
      });
    }

    case RateValidationQualificationActionTypes.rateValidationQualificationSearchRateValidationQualificationsSuccess:
      return {
        ...state,
        searchRateValidationQualifications: action.payload,
        loading: false
      };

    case RateValidationQualificationActionTypes.rateValidationQualificationSearchRateValidationQualificationsReset:
      return {
        ...state,
        searchRateValidationQualifications: null
      };

    case RateValidationQualificationActionTypes.rateValidationQualificationError:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload
      };

    default:
      return state;
  }
}

export const rateValidationQualificationEntitySelectors = adapter.getSelectors();
