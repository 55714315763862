import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getShippingCompanyStore = createFeatureSelector('shippingCompany');

export const getShippingCompanyEntities = createSelector(
  getShippingCompanyStore,
  fromReducers.shippingCompany.shippingCompanyEntitySelectors.selectAll
);

export const getShippingCompanies = createSelector(getShippingCompanyEntities, entities => {
  return Object.values(entities);
});

export const getTopShippingCompanies = createSelector(getShippingCompanyEntities, entities => {
  return Object.values(entities).slice(1, 5);
});

export const getShippingCompaniesLoaded = createSelector(
  getShippingCompanyStore,
  (shippingCompanyStore: fromReducers.shippingCompany.State) => shippingCompanyStore.loaded
);

export const getShippingCompaniesLoading = createSelector(
  getShippingCompanyStore,
  (shippingCompanyStore: fromReducers.shippingCompany.State) => shippingCompanyStore.loading
);

export const getShippingCompaniesPaging = createSelector(
  getShippingCompanyStore,
  (shippingCompanyStore: fromReducers.shippingCompany.State) => shippingCompanyStore.paging
);

export const getSelectedShippingCompanyId = createSelector(
  getShippingCompanyStore,
  (shippingCompanyStore: fromReducers.shippingCompany.State) => shippingCompanyStore.selectedShippingCompanyId
);

export const getSelectedShippingCompany = createSelector(
  getShippingCompanyStore,
  (shippingCompanyStore: fromReducers.shippingCompany.State) => shippingCompanyStore.selectedShippingCompany
);

export const getSearchShippingCompanies = createSelector(
  getShippingCompanyStore,
  (shippingCompanyStore: fromReducers.shippingCompany.State) => shippingCompanyStore.searchShippingCompanies
);

export const getShippingCompanyById = createSelector(
  getShippingCompanyEntities,
  getSelectedShippingCompanyId,
  (entities, id) => entities.find(i => i.id === id)
);

export const getShippingCompaniesError = createSelector(
  getShippingCompanyStore,
  (shippingCompanyStore: fromReducers.shippingCompany.State) => shippingCompanyStore.error
);
