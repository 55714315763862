import {ChangeDetectorRef, Component, TemplateRef, ViewChild, ViewEncapsulation} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';


import {ToastrService} from "ngx-toastr";

import {Router} from "@angular/router";
import {CurrentUserDetails} from "@appModels/ticketing/current.user.details";
import {TeamsService} from "@appServices/core-ticketing/teams-service";
import {ProjectService} from "@appServices/core-ticketing/project-service";
import {CreateProject} from "@appModels/ticketing/create-project";
import {TicketWorkflow} from "@appModels/ticketing/ticket.workflow";
import * as go from 'gojs';
import { LibraryComponent } from '../library-component/library-component';

@Component({
  moduleId: module.id,
  templateUrl: './create-project-component.html',
  animations: [
    trigger('toggleAnimation', [
      transition(':enter', [style({ opacity: 0, transform: 'scale(0.95)' }), animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' }))]),
      transition(':leave', [animate('75ms', style({ opacity: 0, transform: 'scale(0.95)' }))]),
    ]),
  ],
  styleUrls: ['./create-project-component.css', '../../../tailwind.css'],
  encapsulation: ViewEncapsulation.None

})
export class CreateProjectComponent {



  constructor(
    private toastrService: ToastrService,
    private ref: ChangeDetectorRef,
    private teamsService: TeamsService,
    private projectService: ProjectService,
    private router: Router,

  ) {

  }

  mainLoading = false;

  userSearchResults : CurrentUserDetails[] = [];
  selectedWorkflow: TicketWorkflow = {name:"", statuses : [], transitions : []};

  project : CreateProject = {
    workflows :[],
    name:"",


  }

  editingWorkflow=false;
  newStatus = {
    id:0,
    name:"New Status",
    colorCode:"#FF4613"
  }

  addStatusToWorkflow(workflow: TicketWorkflow){

    workflow.statuses.push(
      JSON.parse(JSON.stringify(this.newStatus))
    );

    let newTransitionArray = [];



    this.newStatus.name = "New Status";
    this.newStatus.id = 0;
    this.newStatus.colorCode="#FF4613";


  }




  createProject(){

    // this.mainLoading = true;
    // this.newTeam.tenantId = +localStorage.getItem("tenant");
    //
    // this.projectService.crea(this.newTeam).subscribe(x=> {
    //   this.toastrService.success("Team Created Successfully");
    //   this.router.navigateByUrl(`/tailwind/playground/teams`);
    // })

  }

  addWorkflow(){

    //if we are adding a new workflow, then push onto existing list
    if (this.selectedWorkflow.id == null && this.selectedWorkflow.id == undefined || this.selectedWorkflow.id == 0) {
      this.project.workflows.push(JSON.parse(JSON.stringify(this.selectedWorkflow)));
      this.selectedWorkflow = {name: "", statuses: [], transitions: []};
    }
    else {
      for(let i = 0 ; i< this.project.workflows.length; i++) {
        // if (this.project.workflows[])
      }
    }
    this.editingWorkflow = false;
  }

  editing = false;
  startEditingWorkflow(workflow: TicketWorkflow) {
    this.selectedWorkflow = workflow;
    this.editingWorkflow = true;
  }


  public state = {
    // Diagram state props
    diagramNodeData: [
      { id: 'Alpha', text: "Alpha", color: 'lightblue' },
      { id: 'Beta', text: "Beta", color: 'orange' }
    ],
    diagramLinkData: [
      { key: -1, from: 'Alpha', to: 'Beta', text:"test link" }
    ],
    diagramModelData: { prop: 'value' },
    skipsDiagramUpdate: false,

    // Palette state props
    paletteNodeData: [
      { key: 'PaletteNode1', color: 'firebrick' },
      { key: 'PaletteNode2', color: 'blueviolet' },
      { key: 'PaletteNode3', color: 'firebrick' }
    ]
  }; // end state object

  public diagramDivClassName: string = 'myDiagramDiv';
  public paletteDivClassName = 'myPaletteDiv';


// initialize diagram / templates

  /**
   * Handle GoJS model changes, which output an object of data changes via Mode.toIncrementalData.
   * This method should iterate over thoe changes and update state to keep in sync with the FoJS model.
   * This can be done with any preferred state management method, as long as immutability is preserved.
   */
  public diagramModelChange = function(changes: go.IncrementalData) {
    console.log(changes);
    // see gojs-angular-basic for an example model changed handler that preserves immutability
    // when setting state, be sure to set skipsDiagramUpdate: true since GoJS already has this update
  };



  public initPalette(): go.Palette {
    const $ = go.GraphObject.make;
    const palette = $(go.Palette);

    // define the Node template
    palette.nodeTemplate =
      $(go.Node, 'Auto',
        $(go.Shape, 'RoundedRectangle', { stroke: null },
          new go.Binding('fill', 'color')
        ),
        $(go.TextBlock, { margin: 8 },
          new go.Binding('text', 'key'))
      );

    palette.model = new go.GraphLinksModel(
      {
        linkKeyProperty: 'key'  // IMPORTANT! must be defined for merges and data sync when using GraphLinksModel
      });

    return palette;
  }



  @ViewChild(LibraryComponent) graphComponent: LibraryComponent;

  showingChart = false;
  add() {
    this.showingChart = true;

    this.ref.detectChanges();
  }
  nodeList = [{"id":-1, "loc":"155 -138", "category":"Start"},
    {"id":0, "loc":"190 15", "text":"Shopping"},
    {"id":1, "loc":"353 32", "text":"Browse Items"},
    {"id":2, "loc":"353 166", "text":"Search Items"},
    {"id":3, "loc":"512 12", "text":"View Item"},
    {"id":4, "loc":"661 17", "text":"View Cart"},
    {"id":5, "loc":"644 171", "text":"Update Cart"},
    {"id":6, "loc":"800 96", "text":"Checkout"},
    {"id":-2, "loc":"757 229", "category":"End"}
  ];
  linkList = [ { "from": -1, "to": 0, "text": "Visit online store" },
    { "from": 0, "to": 1,  "progress": "true", "text": "Browse" },
    { "from": 0, "to": 2,  "progress": "true", "text": "Use search bar" },
    { "from": 1, "to": 2,  "progress": "true", "text": "Use search bar" },
    { "from": 2, "to": 3,  "progress": "true", "text": "Click item" },
    { "from": 2, "to": 2,  "text": "Another search", "curviness": 20 },
    { "from": 1, "to": 3,  "progress": "true", "text": "Click item" },
    { "from": 3, "to": 0,  "text": "Not interested", "curviness": -100 },
    { "from": 3, "to": 4,  "progress": "true", "text": "Add to cart" },
    { "from": 4, "to": 0,  "text": "More shopping", "curviness": -150 },
    { "from": 4, "to": 5,  "text": "Update needed", "curviness": -50 },
    { "from": 5, "to": 4,  "text": "Update made" },
    { "from": 4, "to": 6,  "progress": "true", "text": "Proceed" },
    { "from": 6, "to": 5,  "text": "Update needed" },
    { "from": 6, "to": -2, "progress": "true", "text": "Purchase made" }
  ];

}
