import {ChangeDetectorRef, Component, TemplateRef, ViewChild} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import {ToastrService} from "ngx-toastr";
import {ActivatedRoute, Router} from "@angular/router";
import {TicketCustomFieldService} from "@appServices/core-ticketing/ticket-custom-field-service";
import {CustomField} from "@appModels/ticketing/custom-field";
import {CustomFieldType} from "@appModels/ticketing/custom-field-type";

@Component({
  moduleId: module.id,
  templateUrl: './customfields-update.component.html',
  animations: [
    trigger('toggleAnimation', [
      transition(':enter', [style({ opacity: 0, transform: 'scale(0.95)' }), animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' }))]),
      transition(':leave', [animate('75ms', style({ opacity: 0, transform: 'scale(0.95)' }))]),
    ]),
  ],
  styleUrls: ['./customfields-update.component.css', '../../../tailwind.css']
})
export class CustomfieldsUpdateComponent {
  constructor(
    private toastrService: ToastrService,
    private ref: ChangeDetectorRef,
    private customFieldService: TicketCustomFieldService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.customFieldId = +this.route.snapshot.paramMap.get('id');

    this.customFieldService.getCustomField(this.customFieldId).subscribe(x => {
      this.customField = x;
    })

    this.getCustomFieldTypes();
  }

  customFieldId= null;

  customField : CustomField = {
    id:0,
    name:null,
    customFieldTypeId:null,
    customFieldOptions:[],
    mandatory:false,
    systemFieldId:null,
    description:null,
  }

  customFieldTypes : CustomFieldType[] = [];

  mainLoading = true;

  systemFields = [
    {id:0, name:"Updates Promise To Pay Date in Contract Table"},
    {id:1, name:"Inserts Customer Additional Phone Number"},

  ]

  getCustomFieldTypes() {
    this.customFieldService.getCustomFieldTypes().subscribe(x => {
      this.customFieldTypes = x;
      this.mainLoading = false;
    })
  }

  removeCustomFieldOption(option:any) {
    this.customField.customFieldOptions = this.customField.customFieldOptions.filter(x => x.value != option.value)
  }


  newFieldOption = null;
  addOptionToField() {
    if (this.newFieldOption == null || this.newFieldOption == undefined || this.newFieldOption.length < 1) {
      this.toastrService.error("Please enter a longer name")
      return;
    }
    this.customField.customFieldOptions.push({id:0, value:this.newFieldOption});
    this.newFieldOption = null;
  }

  loadingCreate = false;

  updateCustomField() {
    if (this.customField.customFieldTypeId == null || this.customField.customFieldTypeId == undefined) {
      this.toastrService.error("Please select a type");
      return;
    }
    if (this.customField.customFieldTypeId == 3 && this.customField.customFieldOptions.length == 0) {
      this.toastrService.error("Please add at least one option");
      return;
    }
    if (this.customField.name == null || this.customField.name == undefined || this.customField.name.length < 3) {
      this.toastrService.error("Please enter a longer name");
      return;
    }

    this.loadingCreate = true;
    this.customFieldService.updateCustomField(this.customField, this.customFieldId).subscribe(x => {
      this.toastrService.success("Updated Custom Field");
      this.router.navigateByUrl(`/ticketing/customfields`);
    })

  }



}
