import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';

import {Language} from '@appModels/core-setup/language/language';

import {CoreMasterDataService} from '../core-masterdata.service';


@Injectable({ providedIn: 'root' })
export class LanguageService extends CoreMasterDataService {


  constructor(
    private http: HttpClient,
  ) {
    super(http, 'language');
  }

  /** GET languages from the server */
  getLanguages(): Observable<Language[]> {
    return this.http.get<Language[]>(this.base_url , { headers: this.headers }).pipe(
      tap(languages => this.log(`fetched languages`)),
      catchError(this.handleError('getLanguages', []))
    );
  }

  /** GET language by id. Return `undefined` when id not found */
  getLanguageNo404<Data>(id: number): Observable<Language> {
    const url = `${this.base_url}/?id=${id}`;
    return this.http.get<Language[]>(url).pipe(
      map(languages => languages[0]), // returns a {0|1} element array
      tap(h => {
        const outcome = h ? `fetched` : `did not find`;
        this.log(`${outcome} language id=${id}`);
      }),
      catchError(this.handleError<Language>(`getLanguage id=${id}`))
    );
  }

  /** GET language by id. Will 404 if id not found */
  getLanguage(id: number): Observable<Language> {
    const url = `${this.base_url}/${id}`;
    return this.http.get<Language>(url,{ headers: this.headers }).pipe(
      tap(_ => this.log(`fetched language id=${id}`)),
      catchError(this.handleError<Language>(`getLanguage id=${id}`))
    );
  }

  /** GET language by id. Will 404 if id not found */
  getCountryLanguage(id: number): Observable<any> {
    const url = `${this.base_url}/country/${id}`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched language id=${id}`)),
      catchError(this.handleError<any>(`getLanguage id=${id}`))
    );
  }

  /* GET languages whose name contains search term */
  searchLanguages(term: string): Observable<Language[]> {
    if (!term.trim()) {
      // if not search term, return empty language array.
      return of([]);
    }
    return this.http.get<Language[]>(`api/languages/?name=${term}`).pipe(
      tap(_ => this.log(`found languages matching "${term}"`)),
      catchError(this.handleError<Language[]>('searchLanguages', []))
    );
  }

  //////// Save methods //////////

  /** POST: add a new language to the server */
  addLanguage(language: Language): Observable<Language> {
    return this.http.post<Language>(this.base_url , language, { headers: this.headers }).pipe(
      tap((language: Language) => this.log(`added language w/ id=${language.id}`)),
      catchError(this.handleError<Language>('addLanguage'))
    );
  }

  /** DELETE: delete the language from the server */
  deleteLanguage(language: Language | number): Observable<Language> {
    const id = typeof language === 'number' ? language : language.id;
    const url = `${this.base_url}/${id}`;

    return this.http.delete<Language>(url, { headers: this.headers }).pipe(
      tap(_ => this.log(`deleted language id=${id}`)),
      catchError(this.handleError<Language>('deleteLanguage'))
    );
  }

  /** PUT: update the language on the server */
  updateLanguage(language: Language): Observable<any> {
    return this.http.put(this.base_url, language, { headers: this.headers }).pipe(
      tap(_ => this.log(`updated language id=${language.id}`)),
      catchError(this.handleError<any>('updateLanguage'))
    );
  }

}
