import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { ProductAttribute } from '@appModels/core-setup/product-attribute/product-attribute';
import { User } from '@appModels/core-identity/user/user';

import { CoreMasterDataService } from '../core-masterdata.service';



@Injectable({ providedIn: 'root' })
export class ProductAttributeService extends CoreMasterDataService {


  constructor(
    private http: HttpClient,

  ) {
    super(http, 'globalproductattribute');
  }

  /** GET productAttributes from the server */
  getProductAttributes(): Observable<ProductAttribute[]> {
    return this.http.get<ProductAttribute[]>(this.base_url + 's', { headers: this.headers }).pipe(
      tap(productAttributes => this.log(`fetched productAttributes`)),
      catchError(this.handleError('getProductAttributes', []))
    );
  }

  /** GET productAttribute by id. Return `undefined` when id not found */
  getProductAttributeNo404<Data>(id: number): Observable<ProductAttribute> {
    const url = `${this.base_url}/?id=${id}`;
    return this.http.get<ProductAttribute[]>(url).pipe(
      map(productAttributes => productAttributes[0]), // returns a {0|1} element array
      tap(h => {
        const outcome = h ? `fetched` : `did not find`;
        this.log(`${outcome} productAttribute id=${id}`);
      }),
      catchError(this.handleError<ProductAttribute>(`getProductAttribute id=${id}`))
    );
  }

  /** GET productAttribute by id. Will 404 if id not found */
  getProductAttribute(id: number): Observable<ProductAttribute> {
    const url = `${this.base_url}/${id}`;
    return this.http.get<ProductAttribute>(url,{ headers: this.headers }).pipe(
      tap(_ => this.log(`fetched productAttribute id=${id}`)),
      catchError(this.handleError<ProductAttribute>(`getProductAttribute id=${id}`))
    );
  }

  /** GET productAttribute by global product id. Will 404 if id not found */
  getProductAttributeByGlobalId(id: number): Observable<any> {
    const url = `${this.base_url}s/globalproduct/${id}`;
    return this.http.get<any>(url,{ headers: this.headers }).pipe(
      tap(_ => this.log(`fetched productAttribute id=${id}`)),
      catchError(this.handleError<any>(`getProductAttribute id=${id}`))
    );
  }

    /** GET productAttribute by module. Will 404 if id not found */
  getProductAttributeBy(id: number, _module: string): Observable<User[]> {
    const url = `${this.base_url}/${id}/${_module}`;
    return this.http.get<User[]>(url, { headers: this.headers }).pipe(
      tap(_ => this.log(`fetched productAttribute id=${id}`)),
      catchError(this.handleError<User[]>(`getProductAttribute id=${id}`))
    );
  }

  /* GET productAttributes whose name contains search term */
  searchProductAttributes(term: string): Observable<ProductAttribute[]> {
    if (!term.trim()) {
      // if not search term, return empty productAttribute array.
      return of([]);
    }
    return this.http.get<ProductAttribute[]>(`api/productAttributes/?name=${term}`).pipe(
      tap(_ => this.log(`found productAttributes matching "${term}"`)),
      catchError(this.handleError<ProductAttribute[]>('searchProductAttributes', []))
    );
  }

  //////// Save methods //////////

  /** POST: add a new productAttribute to the server */
  addProductAttribute(productAttribute: ProductAttribute): Observable<ProductAttribute> {
    return this.http.post<ProductAttribute>(this.base_url , productAttribute, { headers: this.headers }).pipe(
      tap((productAttribute: ProductAttribute) => this.log(`added productAttribute w/ id=${productAttribute.id}`)),
      catchError(this.handleError<ProductAttribute>('addProductAttribute'))
    );
  }

  /** DELETE: delete the productAttribute from the server */
  deleteProductAttribute(productAttribute: ProductAttribute | number): Observable<ProductAttribute> {
    const id = typeof productAttribute === 'number' ? productAttribute : productAttribute.id;
    const url = `${this.base_url}/${id}`;

    return this.http.delete<ProductAttribute>(url, { headers: this.headers }).pipe(
      tap(_ => this.log(`deleted productAttribute id=${id}`)),
      catchError(this.handleError<ProductAttribute>('deleteProductAttribute'))
    );
  }

  /** PUT: update the productAttribute on the server */
  updateProductAttribute(productAttribute: ProductAttribute): Observable<any> {
    return this.http.put(`${this.base_url}/${productAttribute.id}`, productAttribute, { headers: this.headers }).pipe(
      tap(_ => this.log(`updated productAttribute id=${productAttribute.id}`)),
      catchError(this.handleError<any>('updateProductAttribute'))
    );
  }

  /** Patch: update the productAttribute on the server */
  editProductAttribute(data): Observable<any> {
    return this.http.patch(`${this.base_url}/${data.id}/${data.param}/${data.value}`, {}, { headers: this.headers }).pipe(
      tap(_ => this.log(`updated productAttribute id=${data.id}`)),
      catchError(this.handleError<any>('updateProductAttribute'))
    );
  }

}
