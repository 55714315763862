import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getTicketCreationFieldsStore = createFeatureSelector('ticketCreationFields');

export const getTicketCreationFieldsEntities = createSelector(
  getTicketCreationFieldsStore,
  fromReducers.ticketCreationFields.ticketCreationFieldsEntitySelectors.selectAll
);

export const getTicketCreationFieldsPaging = createSelector(
  getTicketCreationFieldsStore,
  (ticketCreationFieldsStore: fromReducers.ticketCreationFields.State) => ticketCreationFieldsStore.paging
);

export const getTicketCreationFields = createSelector(getTicketCreationFieldsEntities, entities => {
  return Object.values(entities);
});


export const getTicketCreationFieldsLoaded = createSelector(
  getTicketCreationFieldsStore,
  (ticketCreationFieldsStore: fromReducers.ticketCreationFields.State) => ticketCreationFieldsStore.loaded
);

export const getTicketCreationFieldsLoading = createSelector(
  getTicketCreationFieldsStore,
  (ticketCreationFieldsStore: fromReducers.ticketCreationFields.State) => ticketCreationFieldsStore.loading
);

export const getSelectedTicketCreationFieldsId = createSelector(
  getTicketCreationFieldsStore,
  (ticketCreationFieldsStore: fromReducers.ticketCreationFields.State) => ticketCreationFieldsStore.selectedTicketCreationFieldsId
);


export const getTicketCreationFieldsById = createSelector(
  getTicketCreationFieldsStore,
  (ticketCreationFieldsStore: fromReducers.ticketCreationFields.State) => ticketCreationFieldsStore.selectedTicketCreationFields
);

export const getTicketCreationFieldsError = createSelector(
  getTicketCreationFieldsStore,
  (ticketCreationFieldsStore: fromReducers.ticketCreationFields.State) => ticketCreationFieldsStore.error
);
