import {Action} from '@ngrx/store';

import {Commission} from '@appModels/core-fieldforce/commission/commission';

export enum CommissionActionTypes {
  commissionGetCommissions = '[Commission] get',
  commissionGetCommissionsSuccess = '[Commission] get commission success',
  commissionGetCommissionsByTenantId = '[Commission] get commission  by tenant id',
  commissionGetCommissionsByTenantIdSuccess = '[Commission] get commission  by tenant id success',
  commissionAddCommission = '[Commission] add commission',
  commissionAddSCommissionSuccess = '[Commission] add commission success',
  commissionDeleteCommission = '[Commission] delete commission',
  commissionDeleteCommissionSuccess = '[Commission] delete commission success',
  commissionGetCommissionById = '[Commission] get commission by id',
  commissionGetCommissionByIdSuccess = '[Commission] get commission by id success',
  commissionUpdateCommission = '[Commission] update commission',
  commissionUpdateCommissionSuccess = '[Commission] update commission success',
  commissionSearchCommissions = '[Commission] search commission',
  commissionSearchCommissionsSuccess = '[Commission] search commission success',
  commissionSearchCommissionsReset = '[Commission] search commission reset',
  commissionsError = '[Commission] error'
}

export class GetCommissions implements Action {
  readonly type = CommissionActionTypes.commissionGetCommissions;
}

export class GetCommissionsSuccess implements Action {
  readonly type = CommissionActionTypes.commissionGetCommissionsSuccess;

  constructor(public payload: Commission[]) {
  }
}

export class AddCommission implements Action {
  readonly type = CommissionActionTypes.commissionAddCommission;

  constructor(public payload: Commission) {
  }
}

export class AddCommissionSuccess implements Action {
  readonly type = CommissionActionTypes.commissionAddSCommissionSuccess;

  constructor(public payload: Commission) {
  }
}


export class GetCommissionById implements Action {
  readonly type = CommissionActionTypes.commissionGetCommissionById;

  constructor(public payload: number) {
  }
}

export class GetCommissionByIdSuccess implements Action {
  readonly type = CommissionActionTypes.commissionGetCommissionByIdSuccess;

  constructor(public payload: Commission) {
  }
}

export class UpdateCommission implements Action {
  readonly type = CommissionActionTypes.commissionUpdateCommission;

  constructor(public payload: Commission) {
  }
}

export class UpdateCommissionSuccess implements Action {
  readonly type = CommissionActionTypes.commissionUpdateCommissionSuccess;

  constructor(public payload: Commission) {
  }
}

export class DeleteCommission implements Action {
  readonly type = CommissionActionTypes.commissionDeleteCommission;

  constructor(public payload: Commission) {
  }
}

export class DeleteCommissionSuccess implements Action {
  readonly type = CommissionActionTypes.commissionDeleteCommissionSuccess;

  constructor(public payload: Commission) {
  }
}

export class SearchCommissions implements Action {
  readonly type = CommissionActionTypes.commissionSearchCommissions;

  constructor(public payload: string) {
  }
}

export class SearchCommissionsSuccess implements Action {
  readonly type = CommissionActionTypes.commissionSearchCommissionsSuccess;

  constructor(public payload: Commission[]) {
  }
}

export class GetCommissionsByTenantId implements Action {
  readonly type = CommissionActionTypes.commissionGetCommissionsByTenantId;

  constructor(public payload: any) {
  }
}

export class GetCommissionsByTenantIdIdSuccess implements Action {
  readonly type = CommissionActionTypes.commissionGetCommissionsByTenantIdSuccess;

  constructor(public payload: any) {
  }
}

export class SearchCommissionsReset implements Action {
  readonly type = CommissionActionTypes.commissionSearchCommissionsReset;
}

export class CommissionsError implements Action {
  readonly type = CommissionActionTypes.commissionsError;

  constructor(public payload: any) {
  }
}

export type CommissionActions =
  | GetCommissions
  | GetCommissionsSuccess
  | AddCommission
  | AddCommissionSuccess
  | GetCommissionById
  | GetCommissionByIdSuccess
  | UpdateCommission
  | UpdateCommissionSuccess
  | DeleteCommission
  | DeleteCommissionSuccess
  | SearchCommissions
  | SearchCommissionsSuccess
  | SearchCommissionsReset
  | GetCommissionsByTenantId
  | GetCommissionsByTenantIdIdSuccess
  | CommissionsError;
