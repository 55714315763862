import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { Timezone } from '@appModels/core-setup/timezone/timezone';

import { CoreMasterDataService } from '../core-masterdata.service';



@Injectable({ providedIn: 'root' })
export class TimezoneService extends CoreMasterDataService {


  constructor(
    private http: HttpClient,

  ) {
    super(http, 'timezone');
  }

  /** GET timezones from the server */
  getTimezones(): Observable<Timezone[]> {
    return this.http.get<Timezone[]>(this.base_url , { headers: this.headers }).pipe(
      tap(timezones => this.log(`fetched timezones`)),
      catchError(this.handleError('getTimezones', []))
    );
  }

  /** GET timezone by id. Return `undefined` when id not found */
  getTimezoneNo404<Data>(id: number): Observable<Timezone> {
    const url = `${this.base_url}/?id=${id}`;
    return this.http.get<Timezone[]>(url).pipe(
      map(timezones => timezones[0]), // returns a {0|1} element array
      tap(h => {
        const outcome = h ? `fetched` : `did not find`;
        this.log(`${outcome} timezone id=${id}`);
      }),
      catchError(this.handleError<Timezone>(`getTimezone id=${id}`))
    );
  }

  /** GET timezone by id. Will 404 if id not found */
  getTimezone(id: number): Observable<Timezone> {
    const url = `${this.base_url}/${id}`;
    return this.http.get<Timezone>(url,{ headers: this.headers }).pipe(
      tap(_ => this.log(`fetched timezone id=${id}`)),
      catchError(this.handleError<Timezone>(`getTimezone id=${id}`))
    );
  }

  /* GET timezones whose name contains search term */
  searchTimezones(term: string): Observable<Timezone[]> {
    if (!term.trim()) {
      // if not search term, return empty timezone array.
      return of([]);
    }
    return this.http.get<Timezone[]>(`api/timezones/?name=${term}`).pipe(
      tap(_ => this.log(`found timezones matching "${term}"`)),
      catchError(this.handleError<Timezone[]>('searchTimezones', []))
    );
  }

  //////// Save methods //////////

  /** POST: add a new timezone to the server */
  addTimezone(timezone: Timezone): Observable<Timezone> {
    return this.http.post<Timezone>(this.base_url , timezone, { headers: this.headers }).pipe(
      tap((timezone: Timezone) => this.log(`added timezone w/ id=${timezone.id}`)),
      catchError(this.handleError<Timezone>('addTimezone'))
    );
  }

  /** DELETE: delete the timezone from the server */
  deleteTimezone(timezone: Timezone | number): Observable<Timezone> {
    const id = typeof timezone === 'number' ? timezone : timezone.id;
    const url = `${this.base_url}/${id}`;

    return this.http.delete<Timezone>(url, { headers: this.headers }).pipe(
      tap(_ => this.log(`deleted timezone id=${id}`)),
      catchError(this.handleError<Timezone>('deleteTimezone'))
    );
  }

  /** PUT: update the timezone on the server */
  updateTimezone(timezone: Timezone): Observable<any> {
    return this.http.put(this.base_url, timezone, { headers: this.headers }).pipe(
      tap(_ => this.log(`updated timezone id=${timezone.id}`)),
      catchError(this.handleError<any>('updateTimezone'))
    );
  }

}
