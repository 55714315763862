import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';

import { HomeComponent } from './home.component';

import { ModuleRoutes } from './home.routing';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(ModuleRoutes),
        FormsModule,
        TranslateModule,
    ],
    declarations: [
      HomeComponent
    ]
})
export class HomeModule { }
