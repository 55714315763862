import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable, of, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';

import { CoreSmileIdentityService } from '../core-smile-identity.service';

@Injectable({ providedIn: 'root' })
export class SmileIdService extends CoreSmileIdentityService {

  constructor(
    private http: HttpClient,
  ) {
    super(http, 'smileid');
  }

   getGlobalProducts(): Observable<any> {
    const url = `${this.base_url}/globalproducts/${localStorage.getItem('tenant')}`;
    return this.http.get<any>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`getGlobalProducts`)),
      catchError(this.handleError<any>(`getGlobalProducts`, []))
    );
  }

  updateGlobalProducts(globalProductId: boolean): Observable<any> {
    const url = `${this.base_url}/globalproduct/update/${globalProductId}`;
    return this.http.put<any>(url, {}, {headers: this.headers}).pipe(
      tap(_ => this.log(`updateGlobalProducts`)),
      catchError(this.handleError<any>(`updateGlobalProducts`, []))
    );
  }

  saveLeadGlobalProducts(customerId: number, globalProductId: boolean): Observable<any> {
    return this.http.post<any>(`${this.base_url}/customer/lead/save/product-details/${customerId}/${globalProductId}/${localStorage.getItem('tenant')}`, {}, {headers: this.headers, observe: 'response'})
  }

  getCustomerGlobalProducts(customerId: number): Observable<any> {
    const url = `${this.base_url}/customer/lead/product/${customerId}/${localStorage.getItem('tenant')}`;
    return this.http.get<any>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`getCustomerGlobalProducts`)),
      catchError(this.handleError<any>(`getCustomerGlobalProducts`, []))
    );
  }

  getGlobalProductByTenantProductCatalogueOnKyc(tenantProductCatalogueId):any{
    return this.http.get<any>(`${this.base_url}/customer/kyc/${tenantProductCatalogueId}`);
  }

  toggleSmileId(GLOBAL_PRODUCT_ID: number, value: boolean): Observable<any> {
    const params = new HttpParams().set("smileIdRequired", value.toString());
    return this.http.put(`${this.base_url}/globalproduct/update/${GLOBAL_PRODUCT_ID}`, {}, {params,headers: this.headers, observe: 'response', responseType: 'text'})
  }

  getLeadSmileIdProduct(customerId:number){
    const url = `${this.base_url}/leadSmileIdProduct/customer/${customerId}/tenant/${localStorage.getItem('tenant')}`;
    return this.http.get<any>(url, {headers: this.headers}).pipe(
      tap(getLeadSmileIdProduct => this.log(`fetched LeadSmileIdProduct`)),
      catchError(this.handleError<any>(`getLeadSmileIdProduct`, []))
    );
  }
}
