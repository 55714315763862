import {Action} from '@ngrx/store';
import {TicketCreationFields} from "../../../../models/core-setup/ticket-creation-fields/ticket-creation-fields";


export enum TicketCreationFieldsActionTypes {
  ticketcreationfieldsGetticketcreationfieldss = '[TicketCreationFields] get',
  ticketcreationfieldsGetticketcreationfieldssSuccess = '[TicketCreationFields] get ticketcreationfields success',
  ticketcreationfieldsGeticketcreationfieldssByTenantId = '[TicketCreationFields] get sales ticketcreationfields  by tenant id',
  ticketcreationfieldsGeticketcreationfieldssByTenantIdSuccess = '[TicketCreationFields] get sales ticketcreationfields  by tenant id success',
  ticketcreationfieldsAddticketcreationfields = '[TicketCreationFields] add ticketcreationfields',
  ticketcreationfieldsAddSticketcreationfieldsSuccess = '[TicketCreationFields] add ticketcreationfields success',
  ticketcreationfieldsAddticketcreationfieldsAttributes = '[TicketCreationFields] add ticketcreationfields Attributesd',
  ticketcreationfieldsAddticketcreationfieldsAttributesSuccess = '[TicketCreationFields] add additional ticketcreationfields Info success',
  ticketcreationfieldsDeleteticketcreationfields = '[TicketCreationFields] delete ticketcreationfields',
  ticketcreationfieldsDeleteticketcreationfieldsSuccess = '[TicketCreationFields] delete ticketcreationfields success',
  ticketcreationfieldsGetticketcreationfieldsById = '[TicketCreationFields] get ticketcreationfields by id',
  ticketcreationfieldsGetticketcreationfieldsByIdSuccess = '[TicketCreationFields] get ticketcreationfields by id success',
  ticketcreationfieldsUpdateticketcreationfields = '[TicketCreationFields] update ticketcreationfields',
  ticketcreationfieldsUpdateticketcreationfieldsSuccess = '[TicketCreationFields] update ticketcreationfields success',
  ticketcreationfieldsSearchticketcreationfieldss = '[TicketCreationFields] search ticketcreationfields',
  ticketcreationfieldsSearchticketcreationfieldssSuccess = '[TicketCreationFields] search ticketcreationfields success',
  ticketcreationfieldsSearchticketcreationfieldssReset = '[TicketCreationFields] search ticketcreationfields reset',
  ticketcreationfieldsFilterticketcreationfields = '[TicketCreationFields] filter ticketcreationfields ',
  ticketcreationfieldsFilterticketcreationfieldsSuccess = '[TicketCreationFields] filter ticketcreationfields success',
  ticketcreationfieldssError = '[TicketCreationFields] error'
}

export class Getticketcreationfieldss implements Action {
  readonly type = TicketCreationFieldsActionTypes.ticketcreationfieldsGetticketcreationfieldss;
}

export class GetticketcreationfieldssSuccess implements Action {
  readonly type = TicketCreationFieldsActionTypes.ticketcreationfieldsGetticketcreationfieldssSuccess;

  constructor(public payload: TicketCreationFields[]) {
  }
}

export class Addticketcreationfields implements Action {
  readonly type = TicketCreationFieldsActionTypes.ticketcreationfieldsAddticketcreationfields;

  constructor(public payload: TicketCreationFields) {
  }
}

export class AddticketcreationfieldsSuccess implements Action {
  readonly type = TicketCreationFieldsActionTypes.ticketcreationfieldsAddSticketcreationfieldsSuccess;

  constructor(public payload: TicketCreationFields) {
  }
}


export class Filterticketcreationfields implements Action {
  readonly type = TicketCreationFieldsActionTypes.ticketcreationfieldsFilterticketcreationfields;

  constructor(public payload?) {
  }
}

export class FilterticketcreationfieldsSuccess implements Action {
  readonly type = TicketCreationFieldsActionTypes.ticketcreationfieldsFilterticketcreationfieldsSuccess;

  constructor(public payload) {
  }
}


export class GetticketcreationfieldsById implements Action {
  readonly type = TicketCreationFieldsActionTypes.ticketcreationfieldsGetticketcreationfieldsById;

  constructor(public payload) {
  }
}

export class GetticketcreationfieldsByIdSuccess implements Action {
  readonly type = TicketCreationFieldsActionTypes.ticketcreationfieldsGetticketcreationfieldsByIdSuccess;

  constructor(public payload) {
  }
}

export class Updateticketcreationfields implements Action {
  readonly type = TicketCreationFieldsActionTypes.ticketcreationfieldsUpdateticketcreationfields;

  constructor(public payload: TicketCreationFields) {
  }
}

export class UpdateticketcreationfieldsSuccess implements Action {
  readonly type = TicketCreationFieldsActionTypes.ticketcreationfieldsUpdateticketcreationfieldsSuccess;

  constructor(public payload: TicketCreationFields) {
  }
}

export class Deleteticketcreationfields implements Action {
  readonly type = TicketCreationFieldsActionTypes.ticketcreationfieldsDeleteticketcreationfields;

  constructor(public payload: TicketCreationFields) {
  }
}

export class DeleteticketcreationfieldsSuccess implements Action {
  readonly type = TicketCreationFieldsActionTypes.ticketcreationfieldsDeleteticketcreationfieldsSuccess;

  constructor(public payload: TicketCreationFields) {
  }
}

export class Searchticketcreationfieldss implements Action {
  readonly type = TicketCreationFieldsActionTypes.ticketcreationfieldsSearchticketcreationfieldss;

  constructor(public payload: string) {
  }
}

export class SearchticketcreationfieldssSuccess implements Action {
  readonly type = TicketCreationFieldsActionTypes.ticketcreationfieldsSearchticketcreationfieldssSuccess;

  constructor(public payload: TicketCreationFields[]) {
  }
}

export class GetticketcreationfieldssByTenantId implements Action {
  readonly type = TicketCreationFieldsActionTypes.ticketcreationfieldsGeticketcreationfieldssByTenantId;

  constructor(public payload: number) {
  }
}

export class GetticketcreationfieldssByTenantIdIdSuccess implements Action {
  readonly type = TicketCreationFieldsActionTypes.ticketcreationfieldsGeticketcreationfieldssByTenantIdSuccess;

  constructor(public payload: TicketCreationFields[]) {
  }
}

export class SearchticketcreationfieldssReset implements Action {
  readonly type = TicketCreationFieldsActionTypes.ticketcreationfieldsSearchticketcreationfieldssReset;
}

export class ticketcreationfieldssError implements Action {
  readonly type = TicketCreationFieldsActionTypes.ticketcreationfieldssError;

  constructor(public payload: any) {
  }
}

export type TicketCreationFieldsActions =
  | Getticketcreationfieldss
  | GetticketcreationfieldssSuccess
  | Addticketcreationfields
  | AddticketcreationfieldsSuccess
  | GetticketcreationfieldsById
  | GetticketcreationfieldsByIdSuccess
  | Updateticketcreationfields
  | UpdateticketcreationfieldsSuccess
  | Deleteticketcreationfields
  | DeleteticketcreationfieldsSuccess
  | Searchticketcreationfieldss
  | SearchticketcreationfieldssSuccess
  | SearchticketcreationfieldssReset
  | GetticketcreationfieldssByTenantId
  | GetticketcreationfieldssByTenantIdIdSuccess
  | Filterticketcreationfields
  | FilterticketcreationfieldsSuccess
  | ticketcreationfieldssError;
