import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import {of} from "rxjs";
import {WorkOrderL3Service} from '../../../../services/core-aftersales/work-order/work-order-l3.service';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {
  WorkOrderL3ActionClose, WorkOrderL3ActionCloseSuccess,
  WorkOrderL3ActionPerformRepair, WorkOrderL3ActionPerformRepairSuccess,
  WorkOrderL3ActionTypes,
  WorkOrderL3AddNote,
  WorkOrderL3AddNoteSuccess,
  WorkOrderL3AssignAction,
  WorkOrderL3AssignActionSuccess,
  WorkOrderL3AssignTechnician,
  WorkOrderL3AssignTechnicianSuccess, WorkOrderL3ChangeState, WorkOrderL3ChangeStateSuccess,
  WorkOrderL3Error,
  WorkOrderL3Get, WorkOrderL3GetAll, WorkOrderL3GetAllSuccess,
  WorkOrderL3GetSuccess,
  WorkOrderL3Save,
  WorkOrderL3SaveSuccess
} from '../../../actions/core-aftersales/work-order/workorderl3.action';

@Injectable()
export class Workorderl3Effects {

  
  workOrderL3Save$ = createEffect(() => this.actions$.pipe(
    ofType(WorkOrderL3ActionTypes.workOrderL3Save),
    mergeMap((action: WorkOrderL3Save) =>
      this.workOrderL3Service
        .saveWorkOrder(action.payload)
        .pipe(
          map(wo => {
              if (wo) {
                this.toastr.success('Work order created successfully!', 'Successful!');
                this.router.navigateByUrl('/warranty-and-service/work_order/module/' + wo.id);
                return new WorkOrderL3SaveSuccess(wo);
              }
              this.toastr.error('There was an error saving work order!', 'Unknown error');
              return new WorkOrderL3Error({type: 500, message: 'Internal error'});
            }
          ),
          catchError(error => of(new WorkOrderL3Error(error)))
        )
    )
  ));

  
  workOrderL3Get$ = createEffect(() => this.actions$.pipe(
    ofType(WorkOrderL3ActionTypes.workOrderL3Get),
    mergeMap((action: WorkOrderL3Get) =>
      this.workOrderL3Service
        .getWorkOrder(action.payload)
        .pipe(
          map(wo => {
              if (wo) {
                return new WorkOrderL3GetSuccess(wo);
              }
              this.toastr.error('There was an error getting work order!', 'Unknown error');
              return new WorkOrderL3Error({type: 500, message: 'Internal error'});
            }
          ),
          catchError(error => of(new WorkOrderL3Error(error)))
        )
    )
  ));

  
  workOrderAssigned$ = createEffect(() => this.actions$.pipe(
    ofType(WorkOrderL3ActionTypes.workOrderL3AssignTechnician),
    switchMap((action: WorkOrderL3AssignTechnician) =>
      this.workOrderL3Service
        .assignToTechnician(action.payload.id, action.payload.technicianId)
        .pipe(
          map(wo => {
              if (wo !== undefined) {
                this.toastr.success('Work order assigned successfully!', 'Successful!');
                return new WorkOrderL3AssignTechnicianSuccess(wo);
              }
              this.toastr.error('There was an error assiging work order technician!', 'Unknown error');
              return new WorkOrderL3Error({type: 500, message: 'Internal error'});
            }
          ),
          catchError(error => of(new WorkOrderL3Error(error)))
        )
    )
  ));

  
  workOrderActionAssigned$ = createEffect(() => this.actions$.pipe(
    ofType(WorkOrderL3ActionTypes.workOrderL3AssignAction),
    switchMap((action: WorkOrderL3AssignAction) =>
      this.workOrderL3Service
        .assignAction(action.payload.id, action.payload.actionId)
        .pipe(
          map(wo => {
              if (wo !== undefined) {
                this.toastr.success('Action assigned successfully!', 'Successful!');
                return new WorkOrderL3AssignActionSuccess(wo);
              }
              this.toastr.error('There was an error assiging action to work order!', 'Unknown error');
              return new WorkOrderL3Error({type: 500, message: 'Internal error'});
            }
          ),
          catchError(error => of(new WorkOrderL3Error(error)))
        )
    )
  ));

  
  workOrderAddNote$ = createEffect(() => this.actions$.pipe(
    ofType(WorkOrderL3ActionTypes.workOrderL3AddNote),
    switchMap((action: WorkOrderL3AddNote) =>
      this.workOrderL3Service
        .addNote(action.payload.id, action.payload.note)
        .pipe(
          map(wo => {
              if (wo !== undefined) {
                this.toastr.success('Note added successfully!', 'Successful!');
                return new WorkOrderL3AddNoteSuccess(wo);
              }
              this.toastr.error('There was an error adding note!', 'Unknown error');
              return new WorkOrderL3Error({type: 500, message: 'Internal error'});
            }
          ),
          catchError(error => of(new WorkOrderL3Error(error)))
        )
    )
  ));

  
  workOrderStateChange$ = createEffect(() => this.actions$.pipe(
    ofType(WorkOrderL3ActionTypes.workOrderL3ChangeState),
    switchMap((action: WorkOrderL3ChangeState) =>
      this.workOrderL3Service
        .changeState(action.payload.id, action.payload.stateId)
        .pipe(
          map(wo => {
              if (wo !== undefined) {
                this.toastr.success('Work order state change successfully!', 'Successful!');
                return new WorkOrderL3ChangeStateSuccess(wo);
              }
              this.toastr.error('There was an error assiging work order state!', 'Unknown error');
              return new WorkOrderL3Error({type: 500, message: 'Internal error'});
            }
          ),
          catchError(error => of(new WorkOrderL3Error(error)))
        )
    )
  ));

  
  workOrderRepair$ = createEffect(() => this.actions$.pipe(
    ofType(WorkOrderL3ActionTypes.workOrderL3PerformRepair),
    switchMap((action: WorkOrderL3ActionPerformRepair) =>
      this.workOrderL3Service
        .repairAction(action.payload.id, action.payload.other)
        .pipe(
          map(wo => {
              if (wo !== undefined) {
                this.toastr.success('Item repaired successfully!', 'Successful!');
                return new WorkOrderL3ActionPerformRepairSuccess(wo);
              }
              this.toastr.error('There was an error repairing item!', 'Unknown error');
              return new WorkOrderL3Error({type: 500, message: 'Internal error'});
            }
          ),
          catchError(error => of(new WorkOrderL3Error(error)))
        )
    )
  ));

  
  workOrderClosed$ = createEffect(() => this.actions$.pipe(
    ofType(WorkOrderL3ActionTypes.workOrderL3Close),
    switchMap((action: WorkOrderL3ActionClose) =>
      this.workOrderL3Service
        .closeAction(action.payload.id, action.payload.comment)
        .pipe(
          map(wo => {
              if (wo !== undefined) {
                this.toastr.success('Work Order closed successfully!', 'Successful!');
                return new WorkOrderL3ActionCloseSuccess(wo);
              }
              this.toastr.error('There was an error closing work order!', 'Unknown error');
              return new WorkOrderL3Error({type: 500, message: 'Internal error'});
            }
          ),
          catchError(error => of(new WorkOrderL3Error(error)))
        )
    )
  ));

  
  workOrders$ = createEffect(() => this.actions$.pipe(
    ofType(WorkOrderL3ActionTypes.workOrderL3GetAll),
    mergeMap((action: WorkOrderL3GetAll) =>
      this.workOrderL3Service
        .getWorkOrders(action.payload)
        .pipe(
          map(wo => {
            if (wo !== undefined) {
              return new WorkOrderL3GetAllSuccess(wo);
            }
            return new WorkOrderL3Error({type: 500, message: 'Internal error'});
          }),
          catchError(error => of(new WorkOrderL3Error(error)))
        )
    )
  ));

  constructor(
    private actions$: Actions,
    private workOrderL3Service: WorkOrderL3Service,
    private toastr: ToastrService,
    private router: Router
  ) {
  }
}
