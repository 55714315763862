import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

import {
  VatConfigurationActionTypes,
  VatConfigurationError,
  AddVatConfiguration,
  AddVatConfigurationSuccess,
  DeleteVatConfiguration,
  DeleteVatConfigurationSuccess,
  GetVatConfigurationById,
  GetVatConfigurationByIdSuccess,
  GetVatConfigurationByProductId,
  GetVatConfigurationByProductIdSuccess,
  GetVatConfigurations,
  GetVatConfigurationsByTenant,
  GetVatConfigurationsByTenantSuccess,
  GetVatConfigurationsSuccess,
  SearchVatConfigurations,
  SearchVatConfigurationsSuccess,
  UpdateVatConfigurationSuccess
} from '@appStore/actions/core-accounting/vat-configuration/vat-configuration.actions';
import {VatConfigurationService} from '@appServices/core-accounting/vat-configuration/vat-configuration.service';
import {ToastrService} from 'ngx-toastr';

import * as fromRouterActions from '@appStore/actions/router.actions';
import { Router } from '@angular/router';

@Injectable()
export class VatConfigurationEffects {
  constructor(
    private actions$: Actions, 
    private vatConfigurationService: VatConfigurationService,
    private toastr: ToastrService,
    private router: Router
    ) {}

  
  loadVatConfigurations$ = createEffect(() => this.actions$.pipe(
    ofType(VatConfigurationActionTypes.vatConfigurationGetVatConfigurations),
    mergeMap((action: GetVatConfigurations) =>
      this.vatConfigurationService
        .getVatConfigurations(action.payload)
        .pipe(
          map(vatConfigurations => new GetVatConfigurationsSuccess(vatConfigurations)),
          catchError(error => of(new VatConfigurationError(error)))
        )
    )
  ));

  // load vatConfiguration by tenant product catalogue ID
  // @Effect()
  // loadVatConfigurationByProductId$ = this.actions$.pipe(
  //   ofType(VatConfigurationActionTypes.vatConfigurationGetVatConfigurationByProductId),
  //   mergeMap(() =>
  //     this.vatConfigurationService
  //       .getVatConfigurationBy(+localStorage.getItem('tenant'))
  //       .pipe(
  //         map(vatConfiguration => new GetVatConfigurationByProductIdSuccess(vatConfiguration)),
  //         catchError(error => of(new VatConfigurationError(error)))
  //       )
  //   )
  // );

  // @Effect()
  // loadVatConfigurationsByTenant$ = this.actions$.pipe(
  //   ofType(VatConfigurationActionTypes.vatConfigurationGetVatConfigurations),
  //   mergeMap(() =>
  //     this.vatConfigurationService
  //       .getVatConfigurationByTenant(+localStorage.getItem('tenant'))
  //       .pipe(
  //         map(vatConfigurations => new GetVatConfigurationsSuccess(vatConfigurations)),
  //         catchError(error => of(new VatConfigurationError(error)))
  //       )
  //   )
  // );

  
  getVatConfigurationById$ = createEffect(() => this.actions$.pipe(
    ofType(VatConfigurationActionTypes.vatConfigurationGetVatConfigurationById),
    mergeMap((action: GetVatConfigurationById) =>
      this.vatConfigurationService
        .getVatConfiguration(action.payload)
        .pipe(
          map(vatConfiguration => new GetVatConfigurationByIdSuccess(vatConfiguration)),
          catchError(error => of(new VatConfigurationError(error)))
        )
    )
  ));

  
  addVatConfiguration$ = createEffect(() => this.actions$.pipe(
    ofType(VatConfigurationActionTypes.vatConfigurationAddVatConfiguration),
    switchMap((action: AddVatConfiguration) =>
      this.vatConfigurationService
        .addVatConfiguration(action.payload)
        .pipe(
          map(
            vatConfiguration => {
              if (vatConfiguration !== undefined) {
                this.toastr.success('VAT Configuration has been successfully added!', 'Successful!');
                this.router.navigateByUrl(`/setup/vat-configurations`);
                return new AddVatConfigurationSuccess(vatConfiguration)
              }
              return new VatConfigurationError({ type: 500, message: 'Internal error' })
            }
            ),
          catchError(error => of(new VatConfigurationError(error)))
        )
    )
  ));

  
  addAdditionalVatConfigurationInfo$ = createEffect(() => this.actions$.pipe(
    ofType(VatConfigurationActionTypes.vatConfigurationAddAdditionalVatConfigurationInfo),
    mergeMap((action: AddVatConfiguration) =>
      this.vatConfigurationService
        .addVatConfigurationAdditionalInfo(action.payload)
        .pipe(
          map(vatConfiguration => new GetVatConfigurations()),
          catchError(error => of(new VatConfigurationError(error)))
        )
    )
  ));

  
  updateVatConfiguration$ = createEffect(() => this.actions$.pipe(
    ofType(VatConfigurationActionTypes.vatConfigurationUpdateVatConfiguration),
    mergeMap((action: AddVatConfiguration) =>
      this.vatConfigurationService
        .updateVatConfiguration(action.payload)
        .pipe(
          map(
            vatConfiguration => {
              if (vatConfiguration !== undefined) {
                this.toastr.success('VAT Configuration has been successfully updated!', 'Successful!');
                this.router.navigateByUrl(`/setup/vat-configurations`);
                return new UpdateVatConfigurationSuccess(action.payload)
              }
              return new VatConfigurationError({ type: 500, message: 'Internal error' })
            }
            ),
          catchError(error => of(new VatConfigurationError(error)))
        )
    )
  ));

  
  deleteVatConfiguration$ = createEffect(() => this.actions$.pipe(
    ofType(VatConfigurationActionTypes.vatConfigurationDeleteVatConfiguration),
    mergeMap((action: DeleteVatConfiguration) =>
      this.vatConfigurationService
        .deleteVatConfiguration(action.payload)
        .pipe(
          map(() => new DeleteVatConfigurationSuccess(action.payload)),
          catchError(error => of(new VatConfigurationError(error)))
        )
    )
  ));

  
  searchVatConfigurations$ = createEffect(() => this.actions$.pipe(
    ofType(VatConfigurationActionTypes.vatConfigurationSearchVatConfigurations),
    mergeMap((action: SearchVatConfigurations) =>
      this.vatConfigurationService
        .searchVatConfigurations(action.payload)
        .pipe(
          map(vatConfigurations => new SearchVatConfigurationsSuccess(vatConfigurations)),
          catchError(error => of(new VatConfigurationError(error)))
        )
    )
  ));

  // @Effect()
  // updateVatConfigurationSuccess$ = this.actions$.pipe(
  //   ofType(VatConfigurationActionTypes.vatConfigurationUpdateVatConfigurationSuccess),
  //   map(vatConfiguration => new fromRouterActions.Go({ path: ['/setup/vat-configurations'] }))
  // );
}
