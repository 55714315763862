import {Action} from '@ngrx/store';
import {Ticket} from "../../../../models/core-aftersales/ticket";


export enum TicketActionTypes {
  ticketGetTickets = '[Ticket] get',
  ticketGetTicketsSuccess = '[Ticket] get ticket success',
  ticketGeTicketByTenantId = '[Ticket] get ticket  by tenant id',
  ticketGeTicketByTenantIdSuccess = '[Ticket] get ticket  by tenant id success',
  ticketAddTicket = '[Ticket] add ticket',
  ticketAddTicketSuccess = '[Ticket] add ticket success',
  ticketDeleteTicket = '[Ticket] delete ticket',
  ticketDeleteTicketSuccess = '[Ticket] delete ticket success',
  ticketGetTicketById = '[Ticket] get ticket by id',
  ticketGetTicketByIdSuccess = '[Ticket] get ticket by id success',
  ticketUpdateTicket = '[Ticket] update ticket',
  ticketUpdateTicketSuccess = '[Ticket] update ticket success',
  ticketSearchTickets = '[Ticket] search ticket',
  ticketSearchTicketsSuccess = '[Ticket] search ticket success',
  ticketSearchTicketsReset = '[Ticket] search ticket reset',
  ticketFilterTicketsStaff = '[Ticket] filter ticket ',
  ticketFilterTicketSuccess = '[Ticket] filter ticket success',
  ticketClose = '[Ticket] Close',
  ticketCloseSuccess = '[Ticket] Close Success',
  reassignTicket = '[Ticket] Reassign',
  reassignTicketSuccess = '[Ticket] Reassign Success',
  ticketsError = '[Ticket] error'
}

export class GetTickets implements Action {
  readonly type = TicketActionTypes.ticketGetTickets;
}

export class GetTicketsSuccess implements Action {
  readonly type = TicketActionTypes.ticketGetTicketsSuccess;

  constructor(public payload: Ticket[]) {
  }
}

export class AddTicket implements Action {
  readonly type = TicketActionTypes.ticketAddTicket;

  constructor(public payload: Ticket) {
  }
}

export class AddTicketSuccess implements Action {
  readonly type = TicketActionTypes.ticketAddTicketSuccess;

  constructor(public payload: Ticket) {
  }
}


export class FilterTickets implements Action {
  readonly type = TicketActionTypes.ticketFilterTicketsStaff;

  constructor(public payload?) {
  }
}

export class FilterTicketsSuccess implements Action {
  readonly type = TicketActionTypes.ticketFilterTicketSuccess;

  constructor(public payload) {
  }
}


export class GetTicketById implements Action {
  readonly type = TicketActionTypes.ticketGetTicketById;

  constructor(public payload) {
  }
}

export class GetTicketByIdSuccess implements Action {
  readonly type = TicketActionTypes.ticketGetTicketByIdSuccess;

  constructor(public payload) {
  }
}

export class UpdateTicket implements Action {
  readonly type = TicketActionTypes.ticketUpdateTicket;

  constructor(public payload: Ticket) {
  }
}

export class UpdateTicketSuccess implements Action {
  readonly type = TicketActionTypes.ticketUpdateTicketSuccess;

  constructor(public payload: Ticket) {
  }
}

export class DeleteTicket implements Action {
  readonly type = TicketActionTypes.ticketDeleteTicket;

  constructor(public payload: Ticket) {
  }
}

export class DeleteTicketSuccess implements Action {
  readonly type = TicketActionTypes.ticketDeleteTicketSuccess;

  constructor(public payload: Ticket) {
  }
}

export class SearchTickets implements Action {
  readonly type = TicketActionTypes.ticketSearchTickets;

  constructor(public payload: string) {
  }
}

export class SearchTicketsSuccess implements Action {
  readonly type = TicketActionTypes.ticketSearchTicketsSuccess;

  constructor(public payload: Ticket[]) {
  }
}

export class GetTicketsByTenantId implements Action {
  readonly type = TicketActionTypes.ticketGeTicketByTenantId;

  constructor(public payload: number) {
  }
}

export class GetTicketsByTenantIdSuccess implements Action {
  readonly type = TicketActionTypes.ticketGeTicketByTenantIdSuccess;

  constructor(public payload: Ticket[]) {
  }
}

export class CloseTicket implements Action {
  readonly type = TicketActionTypes.ticketClose;

  constructor(public payload: any) {
  }
}

export class CloseTicketSuccess implements Action {
  readonly type = TicketActionTypes.ticketCloseSuccess;

  constructor(public payload: Ticket) {
  }
}

export class TicketsReset implements Action {
  readonly type = TicketActionTypes.ticketSearchTicketsReset;
}

export class ReassignTicket implements Action {
  readonly type = TicketActionTypes.reassignTicket;

  constructor(public payload: any) {
  }
}

export class ReassignTicketSuccess implements Action {
  readonly type = TicketActionTypes.reassignTicketSuccess;

  constructor(public payload: Ticket) {
  }
}

export class TicketsError implements Action {
  readonly type = TicketActionTypes.ticketsError;

  constructor(public payload: any) {
  }
}

export type TicketActions =
  | GetTickets
  | GetTicketsSuccess
  | AddTicket
  | AddTicketSuccess
  | GetTicketById
  | GetTicketByIdSuccess
  | UpdateTicket
  | UpdateTicketSuccess
  | DeleteTicket
  | DeleteTicketSuccess
  | SearchTickets
  | SearchTicketsSuccess
  | GetTicketsByTenantId
  | GetTicketsByTenantIdSuccess
  | FilterTickets
  | FilterTicketsSuccess
  | CloseTicket
  | CloseTicketSuccess
  | ReassignTicket
  | ReassignTicketSuccess
  | TicketsError;
