import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getCustomerStatusStore = createFeatureSelector('customerStatus');

export const getCustomerStatusEntities = createSelector(
  getCustomerStatusStore,
  fromReducers.customerStatus.customerStatusEntitySelectors.selectAll
);

export const getCustomerStatuses = createSelector(getCustomerStatusEntities, entities => {
  return Object.values(entities);
});

export const getTopCustomerStatuses = createSelector(getCustomerStatusEntities, entities => {
  return Object.values(entities).slice(1, 5);
});

export const getCustomerStatusesLoaded = createSelector(
  getCustomerStatusStore,
  (customerStatusStore: fromReducers.customerStatus.State) => customerStatusStore.loaded
);

export const getCustomerStatusesLoading = createSelector(
  getCustomerStatusStore,
  (customerStatusStore: fromReducers.customerStatus.State) => customerStatusStore.loading
);

export const getSelectedCustomerStatusId = createSelector(
  getCustomerStatusStore,
  (customerStatusStore: fromReducers.customerStatus.State) => customerStatusStore.selectedCustomerStatusId
);

export const getSearchCustomerStatuses = createSelector(
  getCustomerStatusStore,
  (customerStatusStore: fromReducers.customerStatus.State) => customerStatusStore.searchCustomerStatuses
);

export const getCustomerStatusById = createSelector(
  getCustomerStatusEntities,
  getSelectedCustomerStatusId,
  (entities, id) => entities.find(i => i.id === id)
);

export const getCustomerStatusesError = createSelector(
  getCustomerStatusStore,
  (customerStatusStore: fromReducers.customerStatus.State) => customerStatusStore.error
);
