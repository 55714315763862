import {Action} from '@ngrx/store';
import {Part} from "../../../../models/core-aftersales/part";


export enum PartActionTypes {
  partGetParts = '[Part] get',
  partGetPartsSuccess = '[Part] get part success',
  partGePartByTenantId = '[Part] get part  by tenant id',
  partGPartByTenantIdSuccess = '[Part] get part  by tenant id success',
  partAddPart = '[Part] add part',
  partAddParttSuccess = '[Part] add part success',
  partDeletePart = '[Part] delete part',
  partDeletePartSuccess = '[Part] delete part success',
  partGetPartById = '[Part] get part by id',
  partGetPartByIdSuccess = '[Part] get part by id success',
  partUpdatePart = '[Part] update part',
  partUpdatePartSuccess = '[Part] update part success',
  partSearchParts = '[Part] search part',
  partSearchPartsSuccess = '[Part] search part success',
  partSearchPartsReset = '[Part] search part reset',
  partFilterPartsStaff = '[Part] filter part ',
  partFilterPartSuccess = '[Part] filter part success',
  partsError = '[Part] error'
}

export class GetParts implements Action {
  readonly type = PartActionTypes.partGetParts;
}

export class GetPartsSuccess implements Action {
  readonly type = PartActionTypes.partGetPartsSuccess;

  constructor(public payload: Part[]) {
  }
}

export class AddPart implements Action {
  readonly type = PartActionTypes.partAddPart;

  constructor(public payload: Part) {
  }
}

export class AddPartSuccess implements Action {
  readonly type = PartActionTypes.partAddParttSuccess;

  constructor(public payload: Part) {
  }
}



export class FilterParts implements Action {
  readonly type = PartActionTypes.partFilterPartsStaff;

  constructor(public payload?) {
  }
}

export class FilterPartsSuccess implements Action {
  readonly type = PartActionTypes.partFilterPartSuccess;

  constructor(public payload) {
  }
}


export class GetPartById implements Action {
  readonly type = PartActionTypes.partGetPartById;

  constructor(public payload) {
  }
}

export class GetPartByIdSuccess implements Action {
  readonly type = PartActionTypes.partGetPartByIdSuccess;

  constructor(public payload) {
  }
}

export class UpdatePart implements Action {
  readonly type = PartActionTypes.partUpdatePart;

  constructor(public payload: Part) {
  }
}

export class UpdatePartSuccess implements Action {
  readonly type = PartActionTypes.partUpdatePartSuccess;

  constructor(public payload: Part) {
  }
}

export class DeletePart implements Action {
  readonly type = PartActionTypes.partDeletePart;

  constructor(public payload: Part) {
  }
}

export class DeletePartSuccess implements Action {
  readonly type = PartActionTypes.partDeletePartSuccess;

  constructor(public payload: Part) {
  }
}

export class SearchParts implements Action {
  readonly type = PartActionTypes.partSearchParts;

  constructor(public payload: string) {
  }
}

export class SearchPartsSuccess implements Action {
  readonly type = PartActionTypes.partSearchPartsSuccess;

  constructor(public payload: Part[]) {
  }
}

export class GetPartsByTenantId implements Action {
  readonly type = PartActionTypes.partGePartByTenantId;

  constructor(public payload: number) {
  }
}

export class GetPartsByTenantIdSuccess implements Action {
  readonly type = PartActionTypes.partGPartByTenantIdSuccess;

  constructor(public payload: Part[]) {
  }
}

export class PartsReset implements Action {
  readonly type = PartActionTypes.partSearchPartsReset;
}

export class PartsError implements Action {
  readonly type = PartActionTypes.partsError;

  constructor(public payload: any) {
  }
}

export type PartActions =
  | GetParts
  | GetPartsSuccess
  | AddPart
  | AddPartSuccess
  | GetPartById
  | GetPartByIdSuccess
  | UpdatePart
  | UpdatePartSuccess
  | DeletePart
  | DeletePartSuccess
  | SearchParts
  | SearchPartsSuccess
  | GetPartsByTenantId
  | GetPartsByTenantIdSuccess
  | FilterParts
  | FilterPartsSuccess
  | PartsError;
