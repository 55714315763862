import {Action} from '@ngrx/store';

import {ProductWarranty} from '@appModels/core-setup/product-warranty/product-warranty';

export enum ProductWarrantyActionTypes {
  productWarrantyGetProductWarranties = '[ProductWarranty] get',
  productWarrantyGetProductWarrantiesSuccess = '[ProductWarranty] get productWarranties success',
  productWarrantyAddProductWarranties = '[ProductWarranty] add productWarranties',
  productWarrantyAddProductWarrantiesSuccess = '[ProductWarranty] add productWarranties success',
  productWarrantyAddProductWarranty = '[ProductWarranty] add productWarranty',
  productWarrantyAddProductWarrantySuccess = '[ProductWarranty] add productWarranty success',
  productWarrantyDeleteProductWarranty = '[ProductWarranty] delete productWarranty',
  productWarrantyDeleteProductWarrantySuccess = '[ProductWarranty] delete productWarranty success',
  productWarrantyGetProductWarrantyById = '[ProductWarranty] get productWarranty by id',
  productWarrantyGetProductWarrantyByIdSuccess = '[ProductWarranty] get productWarranty by id success',
  productWarrantyUpdateProductWarranty = '[ProductWarranty] update productWarranty',
  productWarrantyUpdateProductWarrantySuccess = '[ProductWarranty] update productWarranty success',
  productWarrantyUpdateProductWarranties = '[ProductWarranty] update productWarranties',
  productWarrantyUpdateProductWarrantiesSuccess = '[ProductWarranty] update productWarranties success',
  productWarrantyEditProductWarranty = '[ProductWarranty] edit productWarranty',
  productWarrantyEditProductWarrantySuccess = '[ProductWarranty] edit productWarranty success',
  productWarrantySearchProductWarranties = '[ProductWarranty] search productWarranties',
  productWarrantySearchProductWarrantiesSuccess = '[ProductWarranty] search productWarranties success',
  productWarrantySearchProductWarrantiesReset = '[ProductWarranty] search productWarranties reset',
  productWarrantyError = '[ProductWarranty] error'
}

export class GetProductWarranties implements Action {
  readonly type = ProductWarrantyActionTypes.productWarrantyGetProductWarranties;
}

export class GetProductWarrantiesSuccess implements Action {
  readonly type = ProductWarrantyActionTypes.productWarrantyGetProductWarrantiesSuccess;

  constructor(public payload: ProductWarranty[]) {
  }
}


export class AddProductWarranty implements Action {
  readonly type = ProductWarrantyActionTypes.productWarrantyAddProductWarranty;

  constructor(public payload: ProductWarranty) {
  }
}

export class AddProductWarrantySuccess implements Action {
  readonly type = ProductWarrantyActionTypes.productWarrantyAddProductWarrantySuccess;

  constructor(public payload: ProductWarranty) {
  }
}

export class AddProductWarranties implements Action {
  readonly type = ProductWarrantyActionTypes.productWarrantyAddProductWarranties;

  constructor(public payload: ProductWarranty[]) {
  }
}

export class AddProductWarrantiesSuccess implements Action {
  readonly type = ProductWarrantyActionTypes.productWarrantyAddProductWarrantiesSuccess;

  constructor(public payload: ProductWarranty[]) {
  }
}

export class GetProductWarrantyById implements Action {
  readonly type = ProductWarrantyActionTypes.productWarrantyGetProductWarrantyById;

  constructor(public payload: number) {
  }
}

export class GetProductWarrantyByIdSuccess implements Action {
  readonly type = ProductWarrantyActionTypes.productWarrantyGetProductWarrantyByIdSuccess;

  constructor(public payload: ProductWarranty) {
  }
}


export class UpdateProductWarranty implements Action {
  readonly type = ProductWarrantyActionTypes.productWarrantyUpdateProductWarranty;

  constructor(public payload: ProductWarranty) {
  }
}

export class UpdateProductWarrantySuccess implements Action {
  readonly type = ProductWarrantyActionTypes.productWarrantyUpdateProductWarrantySuccess;

  constructor(public payload: ProductWarranty) {
  }
}

export class UpdateProductWarranties implements Action {
  readonly type = ProductWarrantyActionTypes.productWarrantyUpdateProductWarranties;

  constructor(public payload: ProductWarranty[]) {
  }
}

export class UpdateProductWarrantiesSuccess implements Action {
  readonly type = ProductWarrantyActionTypes.productWarrantyUpdateProductWarrantiesSuccess;

  constructor(public payload: ProductWarranty[]) {
  }
}

export class EditProductWarranty implements Action {
  readonly type = ProductWarrantyActionTypes.productWarrantyUpdateProductWarranty;

  constructor(public payload) {
  }
}

export class EditProductWarrantySuccess implements Action {
  readonly type = ProductWarrantyActionTypes.productWarrantyUpdateProductWarrantySuccess;

  constructor(public payload) {
  }
}

export class DeleteProductWarranty implements Action {
  readonly type = ProductWarrantyActionTypes.productWarrantyDeleteProductWarranty;

  constructor(public payload: ProductWarranty) {
  }
}

export class DeleteProductWarrantySuccess implements Action {
  readonly type = ProductWarrantyActionTypes.productWarrantyDeleteProductWarrantySuccess;

  constructor(public payload: ProductWarranty) {
  }
}

export class SearchProductWarranties implements Action {
  readonly type = ProductWarrantyActionTypes.productWarrantySearchProductWarranties;

  constructor(public payload: string) {
  }
}

export class SearchProductWarrantiesSuccess implements Action {
  readonly type = ProductWarrantyActionTypes.productWarrantySearchProductWarrantiesSuccess;

  constructor(public payload: ProductWarranty[]) {
  }
}

export class SearchProductWarrantiesReset implements Action {
  readonly type = ProductWarrantyActionTypes.productWarrantySearchProductWarrantiesReset;
}

export class ProductWarrantyError implements Action {
  readonly type = ProductWarrantyActionTypes.productWarrantyError;

  constructor(public payload: any) {
  }
}

export type ProductWarrantyActions =
  | GetProductWarranties
  | GetProductWarrantiesSuccess
  | AddProductWarranties
  | AddProductWarrantiesSuccess
  | AddProductWarrantySuccess
  | GetProductWarrantyById
  | GetProductWarrantyByIdSuccess
  | UpdateProductWarranty
  | UpdateProductWarrantySuccess
  | UpdateProductWarranties
  | UpdateProductWarrantiesSuccess
  | EditProductWarranty
  | EditProductWarrantySuccess
  | DeleteProductWarranty
  | DeleteProductWarrantySuccess
  | SearchProductWarranties
  | SearchProductWarrantiesSuccess
  | SearchProductWarrantiesReset
  | ProductWarrantyError;
