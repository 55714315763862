import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {RepairCentreStatus} from '@appModels/core-aftersales/repaircentrestatus';
import {
  RepairCentreStateActions,
  RepairCentreStateActionTypes
} from '../../../actions/core-aftersales/repaircentres/repaircentrestate.actions';

export interface State extends EntityState<RepairCentreStatus> {
  error: any;
  loaded: boolean;
  loading: boolean;
}

export const adapter: EntityAdapter<RepairCentreStatus> = createEntityAdapter<RepairCentreStatus>();

export const initialState: State = adapter.getInitialState({
  error: null,
  loaded: false,
  loading: false
});

export function reducer(state = initialState, action: RepairCentreStateActions): State {
  switch (action.type) {
    case RepairCentreStateActionTypes.repairCentreStatusGetAll:
      return {
        ...state,
        loading: true,
        loaded: false
      };

    case RepairCentreStateActionTypes.repairCentreStatusError:
      return adapter.addOne(action.payload, {
        ...state,
        error: action.payload.id,
        loading: false,
        loaded: true
      });

    case RepairCentreStateActionTypes.repairCentreStatusGetAllSuccess:
      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });
    default:
      return state;
  }
}

export const repairCentreStateEntitySelectors = adapter.getSelectors();

