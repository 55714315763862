import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {WorkOrderStatus} from '../../../../models/core-aftersales/work-order-status';
import {
  WorkOrderStateActions,
  WorkOrderStateActionTypes
} from '../../../actions/core-aftersales/work-order/workorderstate.action';

export interface State extends EntityState<WorkOrderStatus> {
  error: any;
  loaded: boolean;
  loading: boolean;
}

export const adapter: EntityAdapter<WorkOrderStatus> = createEntityAdapter<WorkOrderStatus>();

export const initialState: State = adapter.getInitialState({
  error: null,
  loaded: false,
  loading: false
});

export function reducer(state = initialState, action: WorkOrderStateActions): State {
  switch (action.type) {
    case WorkOrderStateActionTypes.workOrderStatusGetAll:
    case WorkOrderStateActionTypes.workOrderStatusGetForTenant:
      return {
        ...state,
        loading: true,
        loaded: false
      };

    case WorkOrderStateActionTypes.workOrderStatusGetAllSuccess:
    case WorkOrderStateActionTypes.workOrderStatusGetForTenantSuccess:
      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });
    default:
      return state;
  }
}

export const workOrderStatusEntitySelectors = adapter.getSelectors();

