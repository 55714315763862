<div class="modal-right-overlay">
  <div>
    <h3 class="script-title">{{ "Call Script and Score" | translate }}</h3>
  </div>
  <call-script-container
    [customer]="awaitingEscalation"
    [awaitingEscalation]="awaitingEscalation"
    (scoreSaved)="onAllScoreSaved()"
  ></call-script-container>
</div>
