import { Injectable } from '@angular/core';

import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import * as fromReducer from '@appStore/reducers';
import * as fromSelectors from '@appStore/selectors';
import { LoginRequired, LoginSuccess } from '@appStore/actions/core-auth/auth/auth.actions';
import { ModuleNav } from '@appStore/actions/nav.actions';
import { nav } from '../../../config/nav';

export interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}

export const MODULES_ROUTES: RouteInfo[] = nav.moduleNav.logo;

@Injectable({ providedIn: 'root' })
export class AuthGuard  {
  constructor(private store: Store<fromReducer.auth.State>) {}

  canActivate(): Observable<boolean> {
    return this.store.pipe(
      select(fromSelectors.isAuthenticated),
      map(authed => {
          // `authed` is boolean as we defined in "Actions" as `isAuthenticated`
          this.store.dispatch(new ModuleNav(MODULES_ROUTES));
          if (localStorage.getItem('authorization') !== null) {
              return true;
          }
          localStorage.setItem('redirectUrl', location.pathname )
          // If user is not currently Logged-in dispatch Login required action
          this.store.dispatch(new LoginRequired());
          return false ;
      })
    );
  }
}


@Injectable({ providedIn: 'root' })
export class PublicGuard  {
  constructor(private store: Store<fromReducer.auth.State>) {}

  canActivate(): Observable<boolean> {
    return this.store.pipe(
      select(fromSelectors.isAuthenticated),
      map(auth => {
          // `authed` is boolean as we defined in "Actions" as `isAuthenticated`

        if (!auth) {
            // Dispatch an event to let "Effects" navigate users to login
          return true;
        }
        return false;
      })
    );
  }
}
