import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getOOWRepairConditionsStore = createFeatureSelector('oowRepairCondition');

export const getOOWRepairConditionsEntities = createSelector(
  getOOWRepairConditionsStore,
  fromReducers.oowRepairCondition.oowRepairConditionEntitySelectors.selectAll
);

export const getOOWRepairConditions = createSelector(getOOWRepairConditionsEntities, entities => {
  return Object.values(entities);
});

export const getOOwRepairConditionsLoading = createSelector(
  getOOWRepairConditionsStore,
  (oowStore: fromReducers.oowRepairCondition.State) => oowStore.loading
);
