import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';

import {ProductCatalogue} from '@appModels/core-setup/product-catalogue/product-catalogue';

import {CoreMasterDataService} from '../core-masterdata.service';


@Injectable({ providedIn: 'root' })
export class ProductCatalogueService extends CoreMasterDataService {


  constructor(
    private http: HttpClient,

  ) {
    super(http, 'tenantproductcatalogue');
  }

  /** GET productCatalogues from the server */
  getProductCatalogues(): Observable<ProductCatalogue[]> {
    return this.http.get<ProductCatalogue[]>(this.base_url + 's', { headers: this.headers }).pipe(
      tap(productCatalogues => this.log(`fetched productCatalogues`)),
      catchError(this.handleError('getProductCatalogues', []))
    );
  }

  getProductCataloguesByTenantAndType(tenantId: number, type: number, query?): Observable<ProductCatalogue[]> {
    let params = new HttpParams();
    if (query !== undefined){
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        params = params.append(val, query[val]);
      });
    }
    return this.http.get<ProductCatalogue[]>(this.base_url + '/tenant/' + tenantId + '/globalproducttype/' + type, {params, headers: this.headers}).pipe(
      tap(productCatalogues => this.log(`fetched productCatalogues`)),
      catchError(this.handleError('getProductCatalogues', []))
    );
  }

  getProductCataloguesByTenantWithoutPrice(tenantId: number): Observable<ProductCatalogue[]> {
    return this.http.get<ProductCatalogue[]>(this.base_url + '/tenant/' + tenantId + '/withoutPrice', {headers: this.headers}).pipe(
      tap(productCatalogues => this.log(`fetched productCatalogues`)),
      catchError(this.handleError('getProductCatalogues', []))
    );
  }

  getProductCataloguesByTenantAndTypeAndParent(tenantId: number, type: string, product: number): Observable<ProductCatalogue[]> {
    return this.http.get<ProductCatalogue[]>(this.base_url + '/tenant/' + tenantId + '/globalproducttypes/' + type + '/parentGlobalProduct/' + product, {headers: this.headers}).pipe(
      tap(productCatalogues => this.log(`fetched productCatalogues`)),
      catchError(this.handleError('getProductCatalogues', []))
    );
  }

   /** GET tenant product catalogue from the server */
   getTenantProductCatalogue(query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        params = params.append(val, query[val]);
      });
    }
    return this.http.get<any>(this.base_url + 's/'+ localStorage.getItem('tenant') + '/tenant', {
      params,
      headers: this.headers
    }).pipe(
      tap(tenantProductCatalogue => this.log(`fetched tenantProductCatalogue`)),
      catchError(this.handleError('getTenantProductCatalogue', []))
    );
  }

  /** GET productCatalogue by id. Return `undefined` when id not found */
  getProductCatalogueNo404<Data>(id: number): Observable<ProductCatalogue> {
    const url = `${this.base_url}/?id=${id}`;
    return this.http.get<ProductCatalogue[]>(url).pipe(
      map(productCatalogues => productCatalogues[0]), // returns a {0|1} element array
      tap(h => {
        const outcome = h ? `fetched` : `did not find`;
        this.log(`${outcome} productCatalogue id=${id}`);
      }),
      catchError(this.handleError<ProductCatalogue>(`getProductCatalogue id=${id}`))
    );
  }

  /** GET productCatalogue by id. Will 404 if id not found */
  getProductCatalogue(id: number): Observable<ProductCatalogue> {
    const url = `${this.base_url}/${id}`;
    return this.http.get<ProductCatalogue>(url,{ headers: this.headers }).pipe(
      tap(_ => this.log(`fetched productCatalogue id=${id}`)),
      catchError(this.handleError<ProductCatalogue>(`getProductCatalogue id=${id}`))
    );
  }

   /** GET productCatalogue by module. Will 404 if id not found */
   getProductCatalogueByTenant(id: number, _module: string): Observable<ProductCatalogue[]> {
    const url = `${this.base_url}s/${id}/${_module}`;
    return this.http.get<ProductCatalogue[]>(url, { headers: this.headers }).pipe(
      tap(_ => this.log(`fetched tenant id=${id}`)),
      catchError(this.handleError<ProductCatalogue[]>(`getTenant id=${id}`))
    );
  }

  getActiveSKUs(): Observable<any> {
    return this.http.get<any[]>(`${this.base_url}/tenant/${localStorage.getItem('tenant')}/activeskus`, { headers: this.headers }).pipe(
      tap(_ => this.log(`fetched active SKUs`)),
      catchError(this.handleError('getActiveSKUs', []))
    );
  }


  /* GET productCatalogues whose name contains search term */
  searchProductCatalogues(term: string): Observable<ProductCatalogue[]> {
    if (!term.trim()) {
      // if not search term, return empty productCatalogue array.
      return of([]);
    }
    return this.http.get<ProductCatalogue[]>(`api/productCatalogues/?name=${term}`).pipe(
      tap(_ => this.log(`found productCatalogues matching "${term}"`)),
      catchError(this.handleError<ProductCatalogue[]>('searchProductCatalogues', []))
    );
  }

  //////// Save methods //////////

  /** POST: add a new productCatalogue to the server */
  addProductCatalogue(productCatalogue): Observable<ProductCatalogue> {
    return this.http.post(this.base_url + '/', productCatalogue, {headers: this.headers}).pipe(
      tap((pc: ProductCatalogue) => this.log(`added productCatalogue w/ id=${pc.id}`)),
      catchError(this.handleError<ProductCatalogue>('addProductCatalogue'))
    );
  }

  /** DELETE: delete the productCatalogue from the server */
  deleteProductCatalogue(productCatalogue: ProductCatalogue | number): Observable<ProductCatalogue> {
    const id = typeof productCatalogue === 'number' ? productCatalogue : productCatalogue.id;
    const url = `${this.base_url}/${id}`;

    return this.http.delete<ProductCatalogue>(url, { headers: this.headers }).pipe(
      tap(_ => this.log(`deleted productCatalogue id=${id}`)),
      catchError(this.handleError<ProductCatalogue>('deleteProductCatalogue'))
    );
  }

  /** PUT: update the productCatalogue on the server */
  updateProductCatalogue(productCatalogue: ProductCatalogue): Observable<any> {
    return this.http.put(`${this.base_url}/${productCatalogue.id}`, productCatalogue, { headers: this.headers }).pipe(
      tap(_ => this.log(`updated productCatalogue id=${productCatalogue.id}`)),
      catchError(this.handleError<any>('updateProductCatalogue'))
    );
  }

  /** Patch: update the productCatalogue on the server */
  editProductCatalogue(data): Observable<any> {
    return this.http.patch(`${this.base_url}/${data.id}/${data.param}/${data.value}`, {}, { headers: this.headers }).pipe(
      tap(_ => this.log(`updated productCatalogue id=${data.id}`)),
      catchError(this.handleError<any>('updateProductCatalogue'))
    );
  }

  /** Patch: remove the productCatalogue on the server */
  removeProductCatalogue(data): Observable<any> {
    return this.http.delete(`${this.base_url}/${data.id}/${data.param}/${data.value}`, { headers: this.headers }).pipe(
      tap(_ => this.log(`delete productCatalogue id=${data.id}`)),
      catchError(this.handleError<any>('deleteProductCatalogue'))
    );
  }

}
