import {Action} from '@ngrx/store';

import {RateValidationQualification} from '@appModels/core-accounting/rate-validation-qualification/rate-validation-qualification';

export enum RateValidationQualificationActionTypes {
  rateValidationQualificationGetRateValidationQualifications = '[RateValidationQualification] get',
  rateValidationQualificationGetRateValidationQualificationsSuccess = '[RateValidationQualification] get rateValidationQualifications success',
  rateValidationQualificationGetRateValidationQualificationByTenant = '[RateValidationQualification] get rateValidationQualifications by tenant id',
  rateValidationQualificationGetRateValidationQualificationByTenantSuccess = '[RateValidationQualification] get rateValidationQualifications by tenant id success',
  rateValidationQualificationGetRateValidationQualificationByProductId = '[RateValidationQualification] get rateValidationQualification by product id',
  rateValidationQualificationGetRateValidationQualificationByProductIdSuccess = '[RateValidationQualification] get rateValidationQualification by product id success',
  rateValidationQualificationAddRateValidationQualification = '[RateValidationQualification] add RateValidationQualification',
  rateValidationQualificationAddRateValidationQualificationSuccess = '[RateValidationQualification] add rateValidationQualification success',
  rateValidationQualificationAddAdditionalRateValidationQualificationInfo = '[RateValidationQualification] add additional RateValidationQualification Info',
  rateValidationQualificationAddAdditionalRateValidationQualificationInfoSuccess = '[RateValidationQualification] add additional rateValidationQualification Info success',
  rateValidationQualificationDeleteRateValidationQualification = '[RateValidationQualification] delete rateValidationQualification',
  rateValidationQualificationDeleteRateValidationQualificationSuccess = '[RateValidationQualification] delete rateValidationQualification success',
  rateValidationQualificationGetRateValidationQualificationById = '[RateValidationQualification] get rateValidationQualification by id',
  rateValidationQualificationGetRateValidationQualificationByIdSuccess = '[RateValidationQualification] get rateValidationQualification by id success',
  rateValidationQualificationUpdateRateValidationQualification = '[RateValidationQualification] update rateValidationQualification',
  rateValidationQualificationUpdateRateValidationQualificationSuccess = '[RateValidationQualification] update rateValidationQualification success',
  rateValidationQualificationSearchRateValidationQualifications = '[RateValidationQualification] search rateValidationQualifications',
  rateValidationQualificationSearchRateValidationQualificationsSuccess = '[RateValidationQualification] search rateValidationQualifications success',
  rateValidationQualificationSearchRateValidationQualificationsReset = '[RateValidationQualification] search rateValidationQualifications reset',
  rateValidationQualificationError = '[RateValidationQualification] error',
  rateValidationQualificationGetRateValidationQualificationsByTenantId = '[RateValidationQualification] get rateValidationQualifications by tenant id',
  rateValidationQualificationGetRateValidationQualificationsByTenantIdSuccess = '[RateValidationQualification] get rateValidationQualifications by tenant id success',

}

export class GetRateValidationQualifications implements Action {
  readonly type = RateValidationQualificationActionTypes.rateValidationQualificationGetRateValidationQualifications;
  constructor(public payload?) {}
}

export class GetRateValidationQualificationsSuccess implements Action {
  readonly type = RateValidationQualificationActionTypes.rateValidationQualificationGetRateValidationQualificationsSuccess;
  constructor(public payload) {}
}

export class GetRateValidationQualificationsByTenantId implements Action {
  readonly type = RateValidationQualificationActionTypes.rateValidationQualificationGetRateValidationQualificationsByTenantId;
}

export class GetRateValidationQualificationsByTenantIdSuccess implements Action {
  readonly type = RateValidationQualificationActionTypes.rateValidationQualificationGetRateValidationQualificationsByTenantIdSuccess;

  constructor(public payload: RateValidationQualification[]) {
  }
}

export class AddRateValidationQualification implements Action {
  readonly type = RateValidationQualificationActionTypes.rateValidationQualificationAddRateValidationQualification;
  constructor(public payload: RateValidationQualification) {}
}

export class GetRateValidationQualificationsByTenant implements Action {
  readonly type = RateValidationQualificationActionTypes.rateValidationQualificationGetRateValidationQualificationByTenant;

  constructor(public payload) {
  }
}

export class GetRateValidationQualificationsByTenantSuccess implements Action {
  readonly type = RateValidationQualificationActionTypes.rateValidationQualificationGetRateValidationQualificationByTenantSuccess;

  constructor(public payload: RateValidationQualification[]) {
  }
}

export class AddRateValidationQualificationSuccess implements Action {
  readonly type = RateValidationQualificationActionTypes.rateValidationQualificationAddRateValidationQualificationSuccess;
  constructor(public payload: RateValidationQualification) {}
}

export class AddAdditionalRateValidationQualificationInfo implements Action {
  readonly type = RateValidationQualificationActionTypes.rateValidationQualificationAddAdditionalRateValidationQualificationInfo;
  constructor(public payload) {}
}

export class AddAdditionalRateValidationQualificationInfoSuccess implements Action {
  readonly type = RateValidationQualificationActionTypes.rateValidationQualificationAddAdditionalRateValidationQualificationInfoSuccess;
  constructor(public payload) {}
}

export class GetRateValidationQualificationById implements Action {
  readonly type = RateValidationQualificationActionTypes.rateValidationQualificationGetRateValidationQualificationById;
  constructor(public payload: number) {}
}

export class GetRateValidationQualificationByIdSuccess implements Action {
  readonly type = RateValidationQualificationActionTypes.rateValidationQualificationGetRateValidationQualificationByIdSuccess;
  constructor(public payload: RateValidationQualification) {}
}

export class GetRateValidationQualificationByProductId implements Action {
  readonly type = RateValidationQualificationActionTypes.rateValidationQualificationGetRateValidationQualificationByProductId;
  constructor(public payload: number) {}
}

export class GetRateValidationQualificationByProductIdSuccess implements Action {
  readonly type = RateValidationQualificationActionTypes.rateValidationQualificationGetRateValidationQualificationByProductIdSuccess;
  constructor(public payload: RateValidationQualification) {}
}

export class UpdateRateValidationQualification implements Action {
  readonly type = RateValidationQualificationActionTypes.rateValidationQualificationUpdateRateValidationQualification;
  constructor(public payload: RateValidationQualification) {}
}

export class UpdateRateValidationQualificationSuccess implements Action {
  readonly type = RateValidationQualificationActionTypes.rateValidationQualificationUpdateRateValidationQualificationSuccess;
  constructor(public payload: RateValidationQualification) {}
}

export class DeleteRateValidationQualification implements Action {
  readonly type = RateValidationQualificationActionTypes.rateValidationQualificationDeleteRateValidationQualification;
  constructor(public payload: RateValidationQualification) {}
}

export class DeleteRateValidationQualificationSuccess implements Action {
  readonly type = RateValidationQualificationActionTypes.rateValidationQualificationDeleteRateValidationQualificationSuccess;
  constructor(public payload: RateValidationQualification) {}
}

export class SearchRateValidationQualifications implements Action {
  readonly type = RateValidationQualificationActionTypes.rateValidationQualificationSearchRateValidationQualifications;
  constructor(public payload: string) {}
}

export class SearchRateValidationQualificationsSuccess implements Action {
  readonly type = RateValidationQualificationActionTypes.rateValidationQualificationSearchRateValidationQualificationsSuccess;
  constructor(public payload: RateValidationQualification[]) {}
}

export class SearchRateValidationQualificationsReset implements Action {
  readonly type = RateValidationQualificationActionTypes.rateValidationQualificationSearchRateValidationQualificationsReset;
}

export class RateValidationQualificationError implements Action {
  readonly type = RateValidationQualificationActionTypes.rateValidationQualificationError;
  constructor(public payload: any) {}
}

export type RateValidationQualificationActions =
  | GetRateValidationQualifications
  | GetRateValidationQualificationsSuccess
  | AddRateValidationQualification
  | AddRateValidationQualificationSuccess
  | GetRateValidationQualificationsByTenant
  | GetRateValidationQualificationsByTenantSuccess
  | AddAdditionalRateValidationQualificationInfo
  | AddAdditionalRateValidationQualificationInfoSuccess
  | GetRateValidationQualificationById
  | GetRateValidationQualificationByIdSuccess
  | GetRateValidationQualificationByProductId
  | GetRateValidationQualificationByProductIdSuccess
  | UpdateRateValidationQualification
  | UpdateRateValidationQualificationSuccess
  | DeleteRateValidationQualification
  | DeleteRateValidationQualificationSuccess
  | SearchRateValidationQualifications
  | SearchRateValidationQualificationsSuccess
  | SearchRateValidationQualificationsReset
  | RateValidationQualificationError
  | GetRateValidationQualificationsByTenantId
  | GetRateValidationQualificationsByTenantIdSuccess;
