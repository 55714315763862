import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';
import {getWorkOrderStore} from '@appStore/selectors';

export const getReportSchemaStore = createFeatureSelector('reportSchema');

export const getReportSchemaEntities = createSelector(
  getReportSchemaStore,
  fromReducers.reportSchema.reportSchemaEntitySelectors.selectAll
);

export const getReportSchemaLoading = createSelector(
  getReportSchemaStore,
  (reportSchemaStore: fromReducers.reportSchema.State) => reportSchemaStore.loading
);

export const getReportSchemaPaging = createSelector(
  getReportSchemaStore,
  (reportSchemaStore: fromReducers.reportSchema.State) => reportSchemaStore.paging
);


export const getReportSchemas = createSelector(getReportSchemaEntities, entities => {
  return Object.values(entities);
});
