import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable, of} from 'rxjs';

import {CoreTicketingService} from "@appServices/core-ticketing/core-ticketing.service";
import {catchError, tap} from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class TicketUserService extends CoreTicketingService {

  constructor(
    private http: HttpClient,
  ) {
    super(http, 'user');
    this.headers = this.headers.append('skip-cache', 'true');
  }

  getAccessInfo(): Observable<any> {

    const url = `${this.base_url}/getAccessInfo`;
    return this.http.get<any>(url, {headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }

}
