import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getWalletStore = createFeatureSelector('wallet');

export const getWalletEntities = createSelector(
  getWalletStore,
  fromReducers.wallet.walletEntitySelectors.selectAll
);

export const getWallets = createSelector(getWalletEntities, entities => {
  return Object.values(entities);
});

export const getTenantWallets = createSelector(getWalletEntities, entities => {
  return Object.values(entities);
});

export const getTopWallets = createSelector(getWalletEntities, entities => {
  return Object.values(entities).slice(1, 5);
});

export const getWalletPaymentPaging = createSelector(
  getWalletStore,
  (walletStore: fromReducers.wallet.State) => walletStore.paging
);

export const getWalletsLoaded = createSelector(
  getWalletStore,
  (walletStore: fromReducers.wallet.State) => walletStore.loaded
);

export const getWalletsLoading = createSelector(
  getWalletStore,
  (walletStore: fromReducers.wallet.State) => walletStore.loading
);

export const getSelectedWalletId = createSelector(
  getWalletStore,
  (walletStore: fromReducers.wallet.State) => walletStore.selectedWalletId
);

export const getSearchWallets = createSelector(
  getWalletStore,
  (walletStore: fromReducers.wallet.State) => walletStore.searchWallets
);

export const getWalletById = createSelector(
  getWalletEntities,
  getSelectedWalletId,
  (entities, id) => entities.find(i => i.id === id)
);

export const getWalletsError = createSelector(
  getWalletStore,
  (walletStore: fromReducers.wallet.State) => walletStore.error
);
