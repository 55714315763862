import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import {Store} from '@ngrx/store';

import {Observable, of} from 'rxjs';
import {catchError, mergeMap} from 'rxjs/operators';

import * as fromReducers from '@appStore/reducers';
import * as fromSelectors from '@appStore/selectors';
import {GetTimezones} from '@appStore/actions/core-masterdata/timezone/timezone.actions';
import { PermissionResolver } from '@appServices/permission-resolver/permission-resolver.service';

@Injectable({ providedIn: 'root' })
export class TimezonesGuard  {
  constructor(
    private store: Store<fromReducers.timezone.State>,
    private permissionResolver: PermissionResolver
    ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.checkStore().pipe(
      mergeMap(() => {
        return of(true);
      }),
      catchError(() => of(false))
    );
  }

  checkStore(): Observable<boolean> {
    this.store.select(fromSelectors.getTimezonesLoaded).subscribe(loaded => {
      if (!loaded) {
        this.store.dispatch(new GetTimezones());
      }
    });
    return of(true);
  }
}
