import {Action} from '@ngrx/store';
import {CommissionReport} from "../../../../models/core-fieldforce/commission-report/commission-report";

export enum CommissionReportActionTypes {
  commissionReportGetCommissions = '[CommissionReport] get',
  commissionReportGetCommissionsSuccess = '[CommissionReport] get commission success',
  commissionReportGetCommissionsByTenantId = '[CommissionReport] get commission  by tenant id',
  commissionReportGetCommissionsByTenantIdSuccess = '[CommissionReport] get commission  by tenant id success',
  commissionReportAddCommission = '[CommissionReport] add commission',
  commissionReportAddSCommissionSuccess = '[CommissionReport] add commission success',
  commissionReportDeleteCommission = '[CommissionReport] delete commission',
  commissionReportDeleteCommissionSuccess = '[CommissionReport] delete commission success',
  commissionReportGetCommissionById = '[CommissionReport] get commission by id',
  commissionReportGetCommissionByIdSuccess = '[CommissionReport] get commission by id success',
  commissionReportSearchCommissions = '[CommissionReport] search commission',
  commissionReportSearchCommissionsSuccess = '[CommissionReport] search commission success',
  commissionReportSearchCommissionsReset = '[CommissionReport] search commission reset',
  commissionsReportError = '[CommissionReport] error',
  commissionReportFilterCommission = '[CommissionReport] filter commission report',
  commissionReportFilterCommissionSuccess = '[CommissionReport] filter commission report success',
  commissionReportMonthlyFilterCommission = '[CommissionReport] monthly filter commission report',
  commissionReportMonthlyFilterCommissionSuccess = '[CommissionReport] monthly filter commission report success'
}

export class GetCommissionsReport implements Action {
  readonly type = CommissionReportActionTypes.commissionReportGetCommissions;
}

export class FilterCommissionReport implements Action {
  readonly type = CommissionReportActionTypes.commissionReportFilterCommission;

  constructor(public payload?) {
  }
}

export class FilterCommissionReportSuccess implements Action {
  readonly type = CommissionReportActionTypes.commissionReportFilterCommissionSuccess;

  constructor(public payload) {
  }
}


export class FilterCommissionMonhtlyReport implements Action {
  readonly type = CommissionReportActionTypes.commissionReportMonthlyFilterCommission;

  constructor(public payload?) {
  }
}

export class FilterCommissionMonthlyReportSuccess implements Action {
  readonly type = CommissionReportActionTypes.commissionReportMonthlyFilterCommissionSuccess;

  constructor(public payload) {
  }
}

export class GetCommissionsReportSuccess implements Action {
  readonly type = CommissionReportActionTypes.commissionReportGetCommissionsSuccess;

  constructor(public payload: CommissionReport[]) {
  }
}


export class AddCommissionReportSuccess implements Action {
  readonly type = CommissionReportActionTypes.commissionReportAddSCommissionSuccess;

  constructor(public payload: CommissionReport) {
  }
}


export class GetCommissionReportById implements Action {
  readonly type = CommissionReportActionTypes.commissionReportGetCommissionById;

  constructor(public payload) {
  }
}

export class GetCommissionReportByIdSuccess implements Action {
  readonly type = CommissionReportActionTypes.commissionReportGetCommissionByIdSuccess;

  constructor(public payload) {
  }
}


export class SearchCommissionsReport implements Action {
  readonly type = CommissionReportActionTypes.commissionReportSearchCommissions;

  constructor(public payload: string) {
  }
}

export class SearchCommissionsReportSuccess implements Action {
  readonly type = CommissionReportActionTypes.commissionReportSearchCommissionsSuccess;

  constructor(public payload: CommissionReport[]) {
  }
}

export class GetCommissionsReportByTenantId implements Action {
  readonly type = CommissionReportActionTypes.commissionReportGetCommissionsByTenantId;

  constructor(public payload: number) {
  }
}

export class GetCommissionsReportByTenantIdIdSuccess implements Action {
  readonly type = CommissionReportActionTypes.commissionReportGetCommissionsByTenantIdSuccess;

  constructor(public payload: CommissionReport[]) {
  }
}

export class SearchCommissionsReportReset implements Action {
  readonly type = CommissionReportActionTypes.commissionReportSearchCommissionsReset;
}

export class CommissionsReportError implements Action {
  readonly type = CommissionReportActionTypes.commissionsReportError;

  constructor(public payload: any) {
  }
}

export type CommissionReportActions =
  | GetCommissionsReport
  | GetCommissionsReportSuccess
  | GetCommissionReportById
  | GetCommissionReportByIdSuccess
  | SearchCommissionsReport
  | SearchCommissionsReportSuccess
  | SearchCommissionsReportReset
  | GetCommissionsReportByTenantId
  | GetCommissionsReportByTenantIdIdSuccess
  | CommissionsReportError
  | FilterCommissionReport
  | FilterCommissionReportSuccess
  | FilterCommissionMonhtlyReport
  | FilterCommissionMonthlyReportSuccess;
