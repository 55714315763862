import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {pick} from 'lodash';

import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';

import { CoreCrmService } from '../core-crm.service';


@Injectable({ providedIn: 'root' })
export class ContractVerificationService extends CoreCrmService {


  constructor(
    private http: HttpClient,

  ) {
    super(http, 'contractverification');
  }


  getContractDetails(contractVerificationId: number): Observable<any> {
    const url = `${this.base_url}/${contractVerificationId}`;
    return this.http.get<any>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched customer id=`)),
      catchError(this.handleError<any>(`getCustomer id=`))
    );
  }

}
