import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {Fault} from '../../../../models/core-setup/fault-action-setup/fault';
import {FaultActionTypes, FaultsActions} from '../../../actions/core-aftersales/faults/fault.actions';

export interface State extends EntityState<Fault> {
  error: any;
  loaded: boolean;
  loading: boolean;
  selectedFaultId: number;
  selectedFault: Fault;
}

export const adapter: EntityAdapter<Fault> = createEntityAdapter<Fault>();

export const initialState: State = adapter.getInitialState({
  error: null,
  loaded: false,
  loading: false,
  selectedFaultId: null,
  selectedFault: null,
});

export function reducer(state = initialState, action: FaultsActions): State {
  switch (action.type) {
    case FaultActionTypes.faultGetAllForWorkOrder:
    case FaultActionTypes.faultAdd:
    case FaultActionTypes.faultAddSuccess:
    case FaultActionTypes.faultUpdateFault:
    case FaultActionTypes.faultGetFaultById:
      return {
        ...state,
        loading: true,
        loaded: false
      };

    case FaultActionTypes.faultError:
      return adapter.addOne(action.payload, {
        ...state,
        error: action.payload.id,
        loading: false,
        loaded: true
      });

    case FaultActionTypes.faultGetAllForWorkOrderSuccess:
      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });

      case FaultActionTypes.faultGetFaultByIdSuccess:
        return {
          ...state,
          selectedFaultId: action.payload.id,
          selectedFault: action.payload,
          loading: false
        };

        case FaultActionTypes.faultUpdateFaultSuccess: {
          return adapter.updateOne(
            {
              id: action.payload.id,
              changes: action.payload
            },
            {
              ...state,
              loading: false,
              loaded: true
            }
          );
        }

    default:
      return state;
  }
}

export const faultEntitySelectors = adapter.getSelectors();

