import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { CoreCrmService } from '@appServices/core-crm/core-crm.service';
import { CreateCall } from '@appModels/core-crm/create-call/create-call';


@Injectable({ providedIn: 'root' })
export class CreateCallService extends CoreCrmService {


  constructor(
    private http: HttpClient,

  ) {
    super(http, 'call/create');
  }

  /** GET createCalls from the server */
  getCreateCalls(query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        params = params.append(val, query[val]);
      });
    }
    return this.http.get<any>(this.base_url + '/tenant/' + localStorage.getItem('tenant'), {
      params,
      headers: this.headers
    }).pipe(
      tap(createCalls => this.log(`fetched createCalls`)),
      catchError(this.handleError('getCreateCalls', []))
    );
  }

  getCreateCallsByTenantId(id: number): Observable<CreateCall[]> {
    return this.http.get<CreateCall[]>(this.base_url + '/tenant/' + id, { headers: this.headers }).pipe(
      tap(createCalls => this.log(`fetched createCalls`)),
      catchError(this.handleError('getCreateCalls', []))
    );
  }

  /** GET createCall by id. Return `undefined` when id not found */
  getCreateCallNo404<Data>(id: number): Observable<CreateCall> {
    const url = `${this.base_url}/?id=${id}`;
    return this.http.get<CreateCall[]>(url).pipe(
      map(createCalls => createCalls[0]), // returns a {0|1} element array
      tap(h => {
        const outcome = h ? `fetched` : `did not find`;
        this.log(`${outcome} createCall id=${id}`);
      }),
      catchError(this.handleError<CreateCall>(`getCreateCall id=${id}`))
    );
  }

  /** GET createCall by id. Will 404 if id not found */
  getCreateCall(id: number): Observable<CreateCall> {
    const url = `${this.base_url}/${id}`;
    return this.http.get<CreateCall>(url, { headers: this.headers }).pipe(
      tap(_ => this.log(`fetched createCall id=${id}`)),
      catchError(this.handleError<CreateCall>(`getCreateCall id=${id}`))
    );
  }

  /** GET createCall by tenant id. Will 404 if id not found */
  getCreateCallByTenant(query?): Observable<CreateCall[]> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        params = params.append(val, query[val]);
      });
    }
    const url = `${this.base_url}/tenant/` + localStorage.getItem('tenant');
    return this.http.get<any>(url, { headers: this.headers }).pipe(
      tap(_ => this.log(`fetched createCall id=`)),
      catchError(this.handleError(`getCreateCall `, []))
    );
  }

  /** GET createCall by product id. Will 404 if id not found */
  getCreateCallProductCatalogue(id): Observable<any> {
    // const id = localStorage.getItem('tenant');
    const url = `${this.base_url}/tenant/productcatalogue/${id}`;
    return this.http.get(url, { headers: this.headers }).pipe(
      tap(_ => this.log(`fetched createCalls by product catalogue id=${id}`)),
      catchError(this.handleError(`getCreateCallByProductCatalogue id=${id}`))
    );
  }

  /* GET createCalls whose name contains search term */
  searchCreateCalls(term: string): Observable<CreateCall[]> {
    if (!term.trim()) {
      // if not search term, return empty createCall array.
      return of([]);
    }
    return this.http.get<CreateCall[]>(`api/createCalls/?name=${term}`).pipe(
      tap(_ => this.log(`found createCalls matching "${term}"`)),
      catchError(this.handleError<CreateCall[]>('searchCreateCalls', []))
    );
  }

  //////// Save methods //////////

  /** POST: add a new createCall to the server */
  addCall(createCall: CreateCall): Observable<CreateCall> {
    return this.http.post<CreateCall>(this.base_url, createCall, { headers: this.headers }).pipe(
      tap((createCall: CreateCall) => this.log(`added createCall w/ id=${createCall.id}`)),
      catchError(this.handleError<CreateCall>('addCreateCall'))
    );
  }

  // addCreateCall(createCall: CreateCall): Observable<CreateCall> {
  //   return this.http.post<CreateCall>(this.base_url + '/', createCall, { headers: this.headers }).pipe(
  //     tap((newCreateCall: CreateCall) => {
  //       this.log(`added createCall w/ id=${newCreateCall.tenantId}`);
  //       // patch info
  //       this.editCreateCall({ value: createCall.id, id: newCreateCall.tenantId, param: 'tenant' }).subscribe(data => {
  //       }, error => console.log(error, 'createCall error'));
  //     }),
  //     catchError(this.handleError<CreateCall>('addCreateCall'))
  //   );
  // }

  /** POST: add a new createCall to the server */
  addCreateCallAdditionalInfo(createCall): Observable<{}> {
    return this.http.post(`${this.base_url}/additionalInfo/${createCall.id}`, createCall.value, { headers: this.headers }).pipe(
      tap((res) => this.log(`added createCall  info `)),
      catchError(this.handleError('addCreateCall'))
    );
  }

  /** DELETE: delete the createCall from the server */
  deleteCreateCall(createCall: CreateCall | number): Observable<CreateCall> {
    const id = typeof createCall === 'number' ? createCall : createCall.id;
    const url = `${this.base_url}/${id}`;

    return this.http.delete<CreateCall>(url, { headers: this.headers }).pipe(
      tap(_ => this.log(`deleted createCall id=${id}`)),
      catchError(this.handleError<CreateCall>('deleteCreateCall'))
    );
  }

  /** PUT: update the createCall on the server */
  updateCreateCall(createCall: CreateCall): Observable<any> {
    return this.http.put(`${this.base_url}/${createCall.id}`, createCall, { headers: this.headers }).pipe(
      tap(_ => this.log(`updated createCall id=${createCall.id}`)),
      catchError(this.handleError<any>('updateCreateCall'))
    );
  }

  /** Patch: update the createCall on the server */
  editCreateCall(data): Observable<any> {
    return this.http.patch(`${this.base_url}/${data.id}/${data.param}/${data.value}`, {}, { headers: this.headers }).pipe(
      tap(_ => this.log(`edit createCall id=${data.id}`)),
      catchError(this.handleError<any>('editCreateCall'))
    );
  }

}
