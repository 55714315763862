import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {CoreInventoryService} from '../core-inventory.service';
import {StockingPointUser} from '../../../models/core-inventory/stocking-point-user/stocking-point-user';
import {User} from '../../../models/core-identity/user/user';


@Injectable({providedIn: 'root'})
export class StockingPointUserService extends CoreInventoryService {


  constructor(
    private http: HttpClient,
  ) {
    super(http, 'stockingpointuser');
  }

  /** GET stockingPoint by id. Will 404 if id not found */
  getStockingPointUserByUser(userId: number): Observable<StockingPointUser> {
    const url = `${this.base_url}/${userId}`;
    return this.http.get<StockingPointUser>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched stockingPoint id=${userId}`)),
      catchError(this.handleError<StockingPointUser>(`getStockingPoint id=${userId}`))
    );
  }

  getStockingPointsUserByUser(userId: number): Observable<StockingPointUser[]> {
    const url = `${this.base_url}s/${userId}`;
    return this.http.get<StockingPointUser[]>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched stockingPoint id=${userId}`)),
      catchError(this.handleError<StockingPointUser[]>(`getStockingPoint id=${userId}`))
    );
  }

  getAvailableUsers(email: string) {
    const tenantId = localStorage.getItem('tenant');

    let params = new HttpParams();
    params = params.append('email', email);

    return this.http.get<User[]>(this.base_url + '/availableusers/' + tenantId, {
      params: params,
      headers: this.headers
    }).pipe(
      tap(countries => this.log(`fetched available users`)),
      catchError(this.handleError('getAvailableUsers', []))
    );
  }

  /** GET stocking point user from the server */
  getAllStockingPointUsers(query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      Object.keys(query).forEach(val => {
        if (query[val] !== null && query[val] !== undefined && query[val] !== '') {
          params = params.append(val, query[val]);
        }
      });
    }

    return this.http.get<any>(this.base_url, {params: params, headers: this.headers}).pipe(
      tap(types => this.log(`fetched stocking point users`)),
      catchError(this.handleError('getAllStockingPointUsers', []))
    );
  }

  saveStockingPointUser(stockingPointUser: StockingPointUser) {
    const userId = stockingPointUser.user.id;
    const stockingPointId = stockingPointUser.stockingPoint.id;
    return this.http.post(this.base_url, {
      userId: userId,
      stockingPointId: stockingPointId
    }, {headers: this.headers}).pipe(
      tap(types => this.log(`saved stocking point user `)),
      catchError(this.handleError('saveStockingPointUser', null))
    );
  }

  activateStockingPointUser(id: number) {
    return this.http.patch(this.base_url + '/' + id + '/activate', {headers: this.headers}).pipe(
      tap(types => this.log(`activated stocking point user `)),
      catchError(this.handleError('activateStockingPointUser', null))
    );
  }

  deactivateStockingPointUser(id: number) {
    return this.http.patch(this.base_url + '/' + id + '/deactivate', {headers: this.headers}).pipe(
      tap(types => this.log(`deactivated stocking point user `)),
      catchError(this.handleError('deactivateStockingPointUser', null))
    );
  }
}
