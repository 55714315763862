import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { Escalation } from '@appModels/core-crm/escalation/escalation';

import { CoreCrmService } from '../core-crm.service';

@Injectable({ providedIn: 'root' })
export class EscalationService extends CoreCrmService {


  constructor(
    private http: HttpClient,
  ) {
    super(http, 'escalation');
  }

  /** GET customers update escalation from the server */
  getCustomerUpdateEscalation(query?): Observable<any> {
    let params = new HttpParams();
    if (query && query.search) {
      Object.keys(query.search).forEach(key => {
        if (query.search[key].trim() !== '') {
          params = params.append(key, query.search[key]);
        }
      });
    }
    if (query) {
      Object.keys(query).forEach(key => {
        if (key !== 'search') {
          params = params.append(key, query[key]);
        }
      });
    }

    const tenantId = localStorage.getItem('tenant');
    const url = `${this.base_url}/customerupdate/tenant/${tenantId}`;
    return this.http.get<any>(url, { params, headers: this.headers }).pipe(
      tap(customersUpdated => this.log(`fetched updated customers escalation`)),
      catchError(this.handleError('getCustomerUpdate', []))
    );
  }

  /** GET escalation by id. Will 404 if id not found */
  getEscalations(query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        params = params.append(val, query[val]);
      });
    }
    const tenantId = localStorage.getItem('tenant');
    const url = `${this.base_url}/generalinquiry/tenant/${tenantId}`;
    return this.http.get<any>(url, { params, headers: this.headers }).pipe(
      tap(escalations => this.log(`fetched escalations`)),
      catchError(this.handleError<any>(`getEscalation`, []))
    );
  }

  /** GET product return escalation by id. Will 404 if id not found */
  getProductReturnEscalations(query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        params = params.append(val, query[val]);
      });
    }
    const tenantId = localStorage.getItem('tenant');
    const url = `${this.base_url}/productreturn/tenant/${tenantId}`;
    return this.http.get<any>(url, { params, headers: this.headers }).pipe(
      tap(productReturnEscalations => this.log(`fetched product return escalations`)),
      catchError(this.handleError<any>(`getProductReturnEscalations`, []))
    );
  }

  /** GET payment support escalation by id. Will 404 if id not found */
  getPaymentSupportEscalations(query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        params = params.append(val, query[val]);
      });
    }
    const tenantId = localStorage.getItem('tenant');
    const url = `${this.base_url}/paymentsupport/tenant/${tenantId}`;
    return this.http.get<any>(url, { params, headers: this.headers }).pipe(
      tap(paymentSupportEscalations => this.log(`fetched payment support escalations`)),
      catchError(this.handleError<any>(`getPaymentSupportEscalations`, []))
    );
  }

  /** GET customer deposit refund escalation by id. Will 404 if id not found */
  getCustomerDepositRefundEscalations(query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        params = params.append(val, query[val]);
      });
    }
    const tenantId = localStorage.getItem('tenant');
    const url = `${this.base_url}/customerdepositrefundescalations/tenant/${tenantId}`;
    return this.http.get<any>(url, { params, headers: this.headers }).pipe(
      tap(customerDepositEscalations => this.log(`fetched Customer Deposit Refund escalations`)),
      catchError(this.handleError<any>(`getCustomerDepositRefundEscalations`, []))
    );
  }

  /** GET rate plan change escalation by id. Will 404 if id not found */
  getRateChangeEscalations(query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        params = params.append(val, query[val]);
      });
    }
    const tenantId = localStorage.getItem('tenant');
    const url = `${this.base_url}/rateupdate/tenant/${tenantId}`;
    return this.http.get<any>(url, { params, headers: this.headers }).pipe(
      tap(paymentSupportEscalations => this.log(`fetched rate update escalations`)),
      catchError(this.handleError<any>(`getRateUpdateEscalations`, []))
    );
  }

  /** GET accessory sale escalation by id. Will 404 if id not found */
  getAccessorySaleEscalations(query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        params = params.append(val, query[val]);
      });
    }
    const tenantId = localStorage.getItem('tenant');
    const url = `${this.base_url}/accessorysale/tenant/${tenantId}`;
    return this.http.get<any>(url, { params, headers: this.headers }).pipe(
      tap(accessorySaleEscalations => this.log(`fetched accessory sale escalations`)),
      catchError(this.handleError<any>(`getAccessorySaleEscalations`, []))
    );
  }

  /** GET sales agent list escalation by id. Will 404 if id not found */
  getSalesAgentEscalations(query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        params = params.append(val, query[val]);
      });
    }
    const tenantId = localStorage.getItem('tenant');
    const url = `${this.base_url}/addagent/tenant/${tenantId}`;
    return this.http.get<any>(url, { params, headers: this.headers }).pipe(
      tap(accessorySaleEscalations => this.log(`fetched sales agent escalations`)),
      catchError(this.handleError<any>(`getSalesAgentEscalations`, []))
    );
  }

  /** GET token request list escalation by id. Will 404 if id not found */
  getTokenRequestEscalation(query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        params = params.append(val, query[val]);
      });
    }
    const tenantId = localStorage.getItem('tenant');
    const url = `${this.base_url}/tokenrequest/tenant/${tenantId}`;
    return this.http.get<any>(url, { params, headers: this.headers }).pipe(
      tap(accessorySaleEscalations => this.log(`fetched token request escalations`)),
      catchError(this.handleError<any>(`getTokenRequestEscalation`, []))
    );
  }

  /** GET warranty extension request list escalation by id. Will 404 if id not found */
  getWarrantyExtensionRequest(query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        params = params.append(val, query[val]);
      });
    }
    const tenantId = localStorage.getItem('tenant');
    const url = `${this.base_url}/warrantyextension/tenant/${tenantId}`;
    return this.http.get<any>(url, { params, headers: this.headers }).pipe(
      tap(() => this.log(`fetched warrantyextension request escalations`)),
      catchError(this.handleError<any>(`getWarrantyExtensionRequest`, []))
    );
  }

  /** GET grace days request list escalation by id. Will 404 if id not found */
  getGraceDaysEscalations(query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        params = params.append(val, query[val]);
      });
    }
    const tenantId = localStorage.getItem('tenant');
    const url = `${this.base_url}/graceDays/tenant/${tenantId}`;
    return this.http.get<any>(url, { params, headers: this.headers }).pipe(
      tap(() => this.log(`fetched gracedays request escalations`)),
      catchError(this.handleError<any>(`getGraceDaysEscalations`, []))
    );
  }

  /** GET upsell request list escalation by id. Will 404 if id not found */
  getUpsellRequest(query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        params = params.append(val, query[val]);
      });
    }
    const tenantId = localStorage.getItem('tenant');
    const url = `${this.base_url}/upsell/tenant/${tenantId}`;
    return this.http.get<any>(url, { params, headers: this.headers }).pipe(
      tap(() => this.log(`fetched upsell request escalations`)),
      catchError(this.handleError<any>(`getUpsellRequest`, []))
    );
  }

  /** GET escalations. Return `undefined` when id not found */
  getEscalationNo404<Data>(id: number): Observable<Escalation> {
    const url = `${this.base_url}/?id=${id}`;
    return this.http.get<Escalation[]>(url).pipe(
      map(escalations => escalations[0]), // returns a {0|1} element array
      tap(h => {
        const outcome = h ? `fetched` : `did not find`;
        this.log(`${outcome} escalation id=${id}`);
      }),
      catchError(this.handleError<Escalation>(`getEscalation id=${id}`))
    );
  }

  /** GET escalation by id. Will 404 if id not found */
  getEscalation(id: number): Observable<Escalation> {
    const url = `${this.base_url}/${id}`;
    return this.http.get<Escalation>(url, { headers: this.headers }).pipe(
      tap(_ => this.log(`fetched escalation id=${id}`)),
      catchError(this.handleError<Escalation>(`getEscalation id=${id}`))
    );
  }

  /** GET escalation by id. Will 404 if id not found */
  getUnverifiedEscalations(query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        params = params.append(val, query[val]);
      });
    }
    const tenantId = localStorage.getItem('tenant');
    const url = `${this.base_url}/customerregistration/tenant/${tenantId}`;
    return this.http.get<any>(url, { params, headers: this.headers }).pipe(
      tap(escalations => this.log(`fetched escalations`)),
      catchError(this.handleError<any>(`getEscalation`, []))
    );
  }

  /* GET escalations whose name contains search term */
  searchEscalations(term: string): Observable<Escalation[]> {
    if (!term.trim()) {
      // if not search term, return empty escalation array.
      return of([]);
    }
    return this.http.get<Escalation[]>(this.base_url + `/search/${term}`, { headers: this.headers }).pipe(
      tap(_ => this.log(`found escalations matching "${term}"`)),
      catchError(this.handleError<Escalation[]>('searchEscalations', []))
    );
  }

  //////// Save methods //////////

  /** POST: add a new escalation to the server */
  addEscalation(escalation: Escalation): Observable<Escalation> {
    return this.http.post<Escalation>(this.base_url, escalation, { headers: this.headers }).pipe(
      tap((escalation: Escalation) => this.log(`added escalation w/ id=${escalation.id}`)),
      catchError(this.handleError<Escalation>('addEscalation'))
    );
  }

  /** POST: approve/reject a new escalation to the server */
  approveRejectEscalation(escalation): Observable<any> {
    return this.http.post<any>(this.base_url + '/approveReject/escalationCallLog', escalation, { headers: this.headers }).pipe(
      tap((escalation: any) => this.log(`added escalation w/ id=`)),
      catchError(this.handleError<any>('addEscalation'))
    );
  }

  /** POST: approve/reject a new escalation to the server */
  approveRejectDeposit(escalation): Observable<any> {
    return this.http.post<any>(this.base_url + '/approveReject/depositrefund', escalation, { headers: this.headers }).pipe(
      tap((escalation: any) => this.log(`added escalation w/ id=`)),
      catchError(this.handleError<any>('addEscalation'))
    );
  }

  /** POST: approve/reject a new escalation to the server */
  approveRejectCustomerEscalation(escalation): Observable<any> {
    return this.http.post<any>(this.base_url + '/approveReject/escalationCustomerUpdate', escalation, { headers: this.headers }).pipe(
      tap((escalation: any) => this.log(`added escalation w/ id=`)),
      catchError(this.handleError<any>('addEscalation'))
    );
  }

  /** POST: approve/reject a new escalation to the server */
  approveRejectTokenRequestEscalation(escalation): Observable<any> {
    return this.http.post<any>(this.base_url + '/approveReject/escalationtokenrequest', escalation, { headers: this.headers }).pipe(
      tap((data: any) => this.log(`added escalation w/ id=`)),
      catchError(this.handleError<any>('approveRejectTokenRequestEscalation'))
    );
  }

  /** POST: approve/reject warranty extension request */
  approveWarrantyExtension(escalation): Observable<any> {
    return this.http.post<any>(this.base_url + '/approveReject/warrantyextension', escalation, { headers: this.headers }).pipe(
      tap((data: any) => this.log(`added escalation w/ id=`)),
      catchError(this.handleError<any>('approveRejectTokenRequestEscalation'))
    );
  }

  /** POST: approve/reject grace days request */
  approveGraceDays(escalation): Observable<any> {
    return this.http.post<any>(this.base_url + '/approveReject/graceDays', escalation, { headers: this.headers }).pipe(
      tap((data: any) => this.log(`added escalation w/ id=`)),
      catchError(this.handleError<any>('approveGraceDays'))
    );
  }

  /** POST: approve/reject upsell request */
  approveUpsell(escalation): Observable<any> {
    return this.http.post<any>(this.base_url + '/approveReject/upsell', escalation, { headers: this.headers }).pipe(
      tap((data: any) => this.log(`added escalation w/ id=`)),
      catchError(this.handleError<any>('approveUpsell'))
    );
  }

  /** POST: add product return escalation to the server */
  addProductEscalation(data): Observable<any> {
    return this.http.post(`${this.base_url}/${data.id}/${data.param}/${data.value}`, {}, { headers: this.headers }).pipe(
      tap(_ => this.log(`updated escalation id=${data.id}`)),
      catchError(this.handleError<any>('addEscalation'))
    );
  }

  /** DELETE: delete the escalation from the server */
  deleteEscalation(escalation: Escalation | number): Observable<Escalation> {
    const id = typeof escalation === 'number' ? escalation : escalation.id;
    const url = `${this.base_url}/${id}`;

    return this.http.delete<Escalation>(url, { headers: this.headers }).pipe(
      tap(_ => this.log(`deleted escalation id=${id}`)),
      catchError(this.handleError<Escalation>('deleteEscalation'))
    );
  }

  /** PUT: update the escalation on the server */
  updateEscalation(escalation: Escalation): Observable<any> {
    return this.http.put(`${this.base_url}/${escalation.id}`, escalation, { headers: this.headers }).pipe(
      tap(_ => this.log(`updated escalation id=${escalation.id}`)),
      catchError(this.handleError<any>('updateEscalation'))
    );
  }

  approveSalvageValue(salvageValueDto): Observable<any> {
    return this.http.post<any>(this.base_url + '/salvageValue', salvageValueDto, { headers: this.headers }).pipe(
      tap(() => this.log(`approved salvage value`)),
      catchError(this.handleError<Escalation>('approved salvage value'))
    );
  }

  getSalvageValuesForEscalation(escalationCallLogId): Observable<any> {
    return this.http.get<any>(this.base_url + '/salvageValues/escalation/' + escalationCallLogId, { headers: this.headers }).pipe(
      tap(_ => this.log(`fetched salvage values for escalation`)),
      catchError(this.handleError<Escalation>(`getSalvageValues`))
    );
  }

  getSalvageValueLogs(inventoryProductId): Observable<any> {
    return this.http.get<any>(this.base_url + '/salvageValue/' + inventoryProductId, { headers: this.headers }).pipe(
      tap(_ => this.log(`fetched salvage value log`)),
      catchError(this.handleError<Escalation>(`getSalvagevalueLogs`))
    );
  }

  getReturnedItems(escalationCallLogId): Observable<any> {
    return this.http.get<any>(this.base_url + '/returnedItems/' + escalationCallLogId, { headers: this.headers }).pipe(
      tap(_ => this.log(`fetched returned items`)),
      catchError(this.handleError<Escalation>(`getReturnedItems`))
    );
  }

  getWriteOffEscalations(query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        params = params.append(val, query[val]);
      });
    }
    const tenantId = localStorage.getItem('tenant');
    const url = `${this.base_url}/contractwrittenoffescalations/tenant/${tenantId}`;
    return this.http.get<any>(url, { params, headers: this.headers }).pipe(
      tap(customersUpdated => this.log(`fetched write off escalation`)),
      catchError(this.handleError('getWriteOffEscalations', []))
    );
  }

  getProductReplaceEscalations(query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      Object.keys(query).forEach(val => {
        if (query[val] != null && query[val] != '') {
          params = params.append(val, query[val]);
        }
      });
    }
    const tenantId = localStorage.getItem('tenant');
    const url = `${this.base_url}/productreplacescalations/tenant/${tenantId}`;
    return this.http.get<any>(url, { params, headers: this.headers }).pipe(
      tap(customersUpdated => this.log(`fetched getProductReplaceEscalations`)),
      catchError(this.handleError('getProductReplaceEscalations', []))
    );
  }

  getAccessoryReplaceEscalations(query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      Object.keys(query).forEach(val => {
        if (query[val] != null && query[val] != '') {
          params = params.append(val, query[val]);
        }
      });
    }
    const tenantId = localStorage.getItem('tenant');
    const url = `${this.base_url}/accessoryreplacescalations/tenant/${tenantId}`;
    return this.http.get<any>(url, { params, headers: this.headers }).pipe(
      tap(customersUpdated => this.log(`fetched getAccessoryReplaceEscalations`)),
      catchError(this.handleError('getAccessoryReplaceEscalations', []))
    );
  }

}
