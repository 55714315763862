import {Action} from '@ngrx/store';

import {ManualPayment} from '@appModels/core-accounting/manual-payment/manual-payment';

export enum ManualPaymentActionTypes {
  manualPaymentGetManualPayments = '[ManualPayment] get',
  manualPaymentGetManualPaymentsSuccess = '[ManualPayment] get manualPayments success',
  manualPaymentAddManualPayment = '[ManualPayment] add ManualPayment',
  manualPaymentUploadManualPayment = '[ManualPayment] upload ManualPayment',
  manualPaymentUploadManualPaymentSuccess = '[ManualPayment] upload ManualPayment success',
  manualPaymentAddManualPaymentSuccess = '[ManualPayment] add manualPayment success',
  manualPaymentDeleteManualPayment = '[ManualPayment] delete manualPayment',
  manualPaymentDeleteManualPaymentSuccess = '[ManualPayment] delete manualPayment success',
  manualPaymentGetManualPaymentById = '[ManualPayment] get manualPayment by id',
  manualPaymentGetManualPaymentByIdSuccess = '[ManualPayment] get manualPayment by id success',
  manualPaymentUpdateManualPayment = '[ManualPayment] update manualPayment',
  manualPaymentUpdateManualPaymentSuccess = '[ManualPayment] update manualPayment success',
  manualPaymentSearchManualPayments = '[ManualPayment] search manualPayments',
  manualPaymentSearchManualPaymentsSuccess = '[ManualPayment] search manualPayments success',
  manualPaymentSearchManualPaymentsReset = '[ManualPayment] search manualPayments reset',
  manualPaymentError = '[ManualPayment] error'
}

export class GetManualPayments implements Action {
  readonly type = ManualPaymentActionTypes.manualPaymentGetManualPayments;
  constructor(public payload?) {
  }
}

export class GetManualPaymentsSuccess implements Action {
  readonly type = ManualPaymentActionTypes.manualPaymentGetManualPaymentsSuccess;
  constructor(public payload: any) {}
}

export class AddManualPayment implements Action {
  readonly type = ManualPaymentActionTypes.manualPaymentAddManualPayment;
  constructor(public payload) {}
}

export class AddManualPaymentSuccess implements Action {
  readonly type = ManualPaymentActionTypes.manualPaymentAddManualPaymentSuccess;
  constructor(public payload: ManualPayment) {}
}

export class UploadManualPayment implements Action {
  readonly type = ManualPaymentActionTypes.manualPaymentUploadManualPayment;
  constructor(public payload) {}
}

export class UploadManualPaymentSuccess implements Action {
  readonly type = ManualPaymentActionTypes.manualPaymentUploadManualPaymentSuccess;
  constructor(public payload) {}
}

export class GetManualPaymentById implements Action {
  readonly type = ManualPaymentActionTypes.manualPaymentGetManualPaymentById;
  constructor(public payload: number) {}
}

export class GetManualPaymentByIdSuccess implements Action {
  readonly type = ManualPaymentActionTypes.manualPaymentGetManualPaymentByIdSuccess;
  constructor(public payload: ManualPayment) {}
}

export class UpdateManualPayment implements Action {
  readonly type = ManualPaymentActionTypes.manualPaymentUpdateManualPayment;
  constructor(public payload: ManualPayment) {}
}

export class UpdateManualPaymentSuccess implements Action {
  readonly type = ManualPaymentActionTypes.manualPaymentUpdateManualPaymentSuccess;
  constructor(public payload: ManualPayment) {}
}

export class DeleteManualPayment implements Action {
  readonly type = ManualPaymentActionTypes.manualPaymentDeleteManualPayment;
  constructor(public payload: ManualPayment) {}
}

export class DeleteManualPaymentSuccess implements Action {
  readonly type = ManualPaymentActionTypes.manualPaymentDeleteManualPaymentSuccess;
  constructor(public payload: ManualPayment) {}
}

export class SearchManualPayments implements Action {
  readonly type = ManualPaymentActionTypes.manualPaymentSearchManualPayments;
  constructor(public payload: string) {}
}

export class SearchManualPaymentsSuccess implements Action {
  readonly type = ManualPaymentActionTypes.manualPaymentSearchManualPaymentsSuccess;
  constructor(public payload: ManualPayment[]) {}
}

export class SearchManualPaymentsReset implements Action {
  readonly type = ManualPaymentActionTypes.manualPaymentSearchManualPaymentsReset;
}

export class ManualPaymentError implements Action {
  readonly type = ManualPaymentActionTypes.manualPaymentError;
  constructor(public payload: any) {}
}

export type ManualPaymentActions =
  | GetManualPayments
  | GetManualPaymentsSuccess
  | AddManualPayment
  | AddManualPaymentSuccess
  | UploadManualPayment
  | UploadManualPaymentSuccess
  | GetManualPaymentById
  | GetManualPaymentByIdSuccess
  | UpdateManualPayment
  | UpdateManualPaymentSuccess
  | DeleteManualPayment
  | DeleteManualPaymentSuccess
  | SearchManualPayments
  | SearchManualPaymentsSuccess
  | SearchManualPaymentsReset
  | ManualPaymentError;
