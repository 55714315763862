import {Action} from '@ngrx/store';

export enum WorkOrderStateActionTypes {
  workOrderStatusGetAll = '[Work Order Status] Get All',
  workOrderStatusGetAllSuccess = '[Work Order Status] Get All Success',
  workOrderStatusGetForTenant = '[Work Order Status] Get All For Tenant',
  workOrderStatusGetForTenantSuccess = '[Work Order Status] Get All For Tenant Success',
  workOrderStatusError = '[Work Order Status] Error'
}

export class WorkOrderStatusGetAll implements Action {
  readonly type = WorkOrderStateActionTypes.workOrderStatusGetAll;

  constructor(public payload: number) {

  }
}

export class WorkOrderStatusGetAllSuccess implements Action {
  readonly type = WorkOrderStateActionTypes.workOrderStatusGetAllSuccess;

  constructor(public payload: any) {

  }
}

export class WorkOrderStatusGetForTenant implements Action {
  readonly type = WorkOrderStateActionTypes.workOrderStatusGetForTenant;

  constructor() {

  }
}

export class WorkOrderStatusGetForTenantSuccess implements Action {
  readonly type = WorkOrderStateActionTypes.workOrderStatusGetForTenantSuccess;

  constructor(public payload: any) {

  }
}

export class WorkOrderStatusError implements Action {
  readonly type = WorkOrderStateActionTypes.workOrderStatusError;

  constructor(public payload: any) {

  }
}


export type WorkOrderStateActions =
  | WorkOrderStatusGetAll
  | WorkOrderStatusGetAllSuccess
  | WorkOrderStatusError
  | WorkOrderStatusGetForTenant
  | WorkOrderStatusGetForTenantSuccess;


