import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getRouteStore = createFeatureSelector('route');

export const getRouteEntities = createSelector(
  getRouteStore,
  fromReducers.route.routeEntitySelectors.selectAll
);

export const getRoutes = createSelector(getRouteEntities, entities => {
  return Object.values(entities);
});

export const getTopRoutes = createSelector(getRouteEntities, entities => {
  return Object.values(entities).slice(1, 5);
});

export const getRoutePaging = createSelector(
  getRouteStore,
  (routeStore: fromReducers.route.State) => routeStore.paging
);

export const getRoutesLoaded = createSelector(
  getRouteStore,
  (routeStore: fromReducers.route.State) => routeStore.loaded
);

export const getRoutesLoading = createSelector(
  getRouteStore,
  (routeStore: fromReducers.route.State) => routeStore.loading
);

export const getSelectedRouteId = createSelector(
  getRouteStore,
  (routeStore: fromReducers.route.State) => routeStore.selectedRouteId
);

export const getSearchRoutes = createSelector(
  getRouteStore,
  (routeStore: fromReducers.route.State) => routeStore.searchRoutes
);

export const getRouteById = createSelector(
  getRouteEntities,
  getSelectedRouteId,
  (entities, id) => entities.find(i => i.id === id)
);

export const getRoutesError = createSelector(
  getRouteStore,
  (routeStore: fromReducers.route.State) => routeStore.error
);


