import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { mergeMap, map, catchError, tap, debounceTime, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import {
  PaymentCategorizationActionTypes,
  GetPaymentCategorizations,
  GetPaymentCategorizationsSuccess,
  PaymentCategorizationError,
  AddPaymentCategorization,
  AddPaymentCategorizationSuccess,
  EditPaymentCategorization,
  DeletePaymentCategorizationSuccess,
  DeletePaymentCategorization,
  GetPaymentCategorizationById,
  GetPaymentCategorizationByIdSuccess,
  UpdatePaymentCategorizationSuccess,
  SearchPaymentCategorizations,
  SearchPaymentCategorizationsSuccess,
  AddFinancedRate
} from '@appStore/actions/core-accounting/payment-categorization/payment-categorization.actions';
import { PaymentCategorizationService } from '@appServices/core-accounting/payment-categorization/payment-categorization.service';

import * as fromRouterActions from '@appStore/actions/router.actions';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Injectable()
export class PaymentCategorizationEffects {
  constructor(
    private actions$: Actions,
    private paymentCategorizationService: PaymentCategorizationService,
    private toastr: ToastrService,
    private router: Router
    ) {}

  
  loadPaymentCategorizations$ = createEffect(() => this.actions$.pipe(
    ofType(PaymentCategorizationActionTypes.paymentCategorizationGetPaymentCategorizations),
    mergeMap(() =>
      this.paymentCategorizationService
        .getPaymentCategorizations()
        .pipe(
          map(paymentCategorizations => new GetPaymentCategorizationsSuccess(paymentCategorizations)),
          catchError(error => of(new PaymentCategorizationError(error)))
        )
    )
  ));

  
  getPaymentCategorizationById$ = createEffect(() => this.actions$.pipe(
    ofType(PaymentCategorizationActionTypes.paymentCategorizationGetPaymentCategorizationById),
    mergeMap((action: GetPaymentCategorizationById) =>
      this.paymentCategorizationService
        .getPaymentCategorization(action.payload)
        .pipe(
          map(paymentCategorization => new GetPaymentCategorizationByIdSuccess(paymentCategorization)),
          catchError(error => of(new PaymentCategorizationError(error)))
        )
    )
  ));

  
  addPaymentCategorization$ = createEffect(() => this.actions$.pipe(
    ofType(PaymentCategorizationActionTypes.paymentCategorizationAddPaymentCategorization),
    switchMap((action: AddPaymentCategorization) =>
      this.paymentCategorizationService
        .addPaymentCategorization(action.payload)
        .pipe(
          map(
            paymentCategorization => {
              if (paymentCategorization !== undefined) {
                this.toastr.success('Recent Payments Category has been successfully added!', 'Successful!')
                this.router.navigateByUrl(`/setup/payment-categorization`);
                return new AddPaymentCategorizationSuccess(paymentCategorization)
              }
              return new PaymentCategorizationError({ type: 500, message: 'Internal error' })
            }
            ),
          catchError(error => of(new PaymentCategorizationError(error)))
        )
    )
  ));

  
  addFinancedRate$ = createEffect(() => this.actions$.pipe(
    ofType(PaymentCategorizationActionTypes.paymentCategorizationAddFinancedRate),
    switchMap((action: AddFinancedRate) =>
      this.paymentCategorizationService
        .addPaymentCategorization(action.payload)
        .pipe(
          map(financedRate => new EditPaymentCategorization({ value: financedRate.id, id: action.payload.rateId, param: 'rate' })),
          catchError(error => of(new PaymentCategorizationError(error)))
        )
    )
  ));

    
  addAdditionalPaymentCategorizationInfo$ = createEffect(() => this.actions$.pipe(
    ofType(PaymentCategorizationActionTypes.paymentCategorizationAddAdditionalPaymentCategorizationInfo),
    mergeMap((action: AddPaymentCategorization) =>
      this.paymentCategorizationService
        .addPaymentCategorizationAdditionalInfo(action.payload)
        .pipe(
          map(paymentCategorization => new GetPaymentCategorizations()),
          catchError(error => of(new PaymentCategorizationError(error)))
        )
    )
  ));

  
  updatePaymentCategorization$ = createEffect(() => this.actions$.pipe(
    ofType(PaymentCategorizationActionTypes.paymentCategorizationUpdatePaymentCategorization),
    mergeMap((action: AddPaymentCategorization) =>
      this.paymentCategorizationService
        .updatePaymentCategorization(action.payload)
        .pipe(
          map(
            paymentCategorization => {
              if (paymentCategorization !== undefined) {
                this.toastr.success('Recent Payments Category has been successfully updated!', 'Successful!')
                this.router.navigateByUrl(`/setup/payment-categorization`);
                return new UpdatePaymentCategorizationSuccess(action.payload)
              }
              return new PaymentCategorizationError({ type: 500, message: 'Internal error' })
            }
            ),
          catchError(error => of(new PaymentCategorizationError(error)))
        )
    )
  ));

  
  deletePaymentCategorization$ = createEffect(() => this.actions$.pipe(
    ofType(PaymentCategorizationActionTypes.paymentCategorizationDeletePaymentCategorization),
    mergeMap((action: DeletePaymentCategorization) =>
      this.paymentCategorizationService
        .deletePaymentCategorization(action.payload)
        .pipe(
          map(() => new DeletePaymentCategorizationSuccess(action.payload)),
          catchError(error => of(new PaymentCategorizationError(error)))
        )
    )
  ));

  
  searchPaymentCategorizations$ = createEffect(() => this.actions$.pipe(
    ofType(PaymentCategorizationActionTypes.paymentCategorizationSearchPaymentCategorizations),
    mergeMap((action: SearchPaymentCategorizations) =>
      this.paymentCategorizationService
        .searchPaymentCategorizations(action.payload)
        .pipe(
          map(paymentCategorizations => new SearchPaymentCategorizationsSuccess(paymentCategorizations)),
          catchError(error => of(new PaymentCategorizationError(error)))
        )
    )
  ));

  
  updatePaymentCategorizationSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(PaymentCategorizationActionTypes.paymentCategorizationUpdatePaymentCategorizationSuccess),
    map(paymentCategorization => new fromRouterActions.Go({ path: ['/setup/payment-categorization'] }))
  ));
}
