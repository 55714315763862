import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getSmsTemplateStore = createFeatureSelector('smsTemplate');

export const getSmsTemplateEntities = createSelector(
  getSmsTemplateStore,
  fromReducers.smsTemplate.smsTemplateEntitySelectors.selectAll
);

export const getSmsTemplates = createSelector(getSmsTemplateEntities, entities => {
  return Object.values(entities);
});

export const getSmsTemplatesPaging = createSelector(
  getSmsTemplateStore,
  (smsTemplateStore: fromReducers.smsTemplate.State) => smsTemplateStore.paging
);

export const getTopSmsTemplates = createSelector(getSmsTemplateEntities, entities => {
  return Object.values(entities).slice(1, 5);
});

export const getSmsTemplatesLoaded = createSelector(
  getSmsTemplateStore,
  (smsTemplateStore: fromReducers.smsTemplate.State) => smsTemplateStore.loaded
);

export const getSmsTemplatesLoading = createSelector(
  getSmsTemplateStore,
  (smsTemplateStore: fromReducers.smsTemplate.State) => smsTemplateStore.loading
);

export const getSelectedSmsTemplateId = createSelector(
  getSmsTemplateStore,
  (smsTemplateStore: fromReducers.smsTemplate.State) => smsTemplateStore.selectedSmsTemplateId
);

export const getSearchSmsTemplates = createSelector(
  getSmsTemplateStore,
  (smsTemplateStore: fromReducers.smsTemplate.State) => smsTemplateStore.searchSmsTemplates
);

export const getSmsTemplateById = createSelector(
  getSmsTemplateStore,
  (smsTemplateStore: fromReducers.smsTemplate.State) => smsTemplateStore.selectedSmsTemplate
);

export const getSmsTemplatesError = createSelector(
  getSmsTemplateStore,
  (smsTemplateStore: fromReducers.smsTemplate.State) => smsTemplateStore.error
);
