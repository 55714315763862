import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

import {
  AddTenant,
  AddTenantAdmin,
  AddTenantSuccess,
  DeleteTenant,
  DeleteTenantSuccess,
  EditTenant,
  EditTenantSuccess,
  GetTenantById,
  GetTenantByIdSuccess,
  GetTenants,
  GetTenantsSuccess,
  SearchTenants,
  SearchTenantsSuccess,
  TenantActionTypes,
  TenantError,
  UpdateTenantSuccess
} from '@appStore/actions/core-masterdata/tenant/tenant.actions';
import {TenantService} from '@appServices/core-masterdata/tenant/tenant.service';
import {UserService} from '@appServices/core-auth/user/user.service';
import {ToastrService} from 'ngx-toastr';

@Injectable()
export class TenantEffects {

  addTenant$ = createEffect(() => this.actions$.pipe(
    ofType(TenantActionTypes.tenantAddTenant),
    switchMap((action: AddTenant) =>
      this.tenantService
        .addTenant(action.payload)
        .pipe(
          map(
            tenant => {
              if (tenant !== undefined) {
                this.toastr.success('Tenant has been successfully registered!', 'Successful!');
                return new AddTenantSuccess(tenant)
              }
              return new TenantError({type: 500, message: 'Internal error'})
            }
          ),
          catchError(error => of(new TenantError(error)))
        )
    )
  ));


  loadTenants$ = createEffect(() => this.actions$.pipe(
    ofType(TenantActionTypes.tenantGetTenants),
    mergeMap((action: GetTenants) =>
      this.tenantService
        .getTenants(action.payload)
        .pipe(
          map(tenants => new GetTenantsSuccess(tenants)),
          catchError(error => of(new TenantError(error)))
        )
    )
  ));


  getTenantById$ = createEffect(() => this.actions$.pipe(
    ofType(TenantActionTypes.tenantGetTenantById),
    mergeMap((action: GetTenantById) =>
      this.tenantService
        .getTenant(action.payload)
        .pipe(
          map(tenant => new GetTenantByIdSuccess(tenant)),
          catchError(error => of(new TenantError(error)))
        )
    )
  ));

  addTenantAdmin$ = createEffect(() => this.actions$.pipe(
    ofType(TenantActionTypes.tenantAddTenantAdmin),
    mergeMap((action: AddTenantAdmin) =>
      this.userService
        .addUser(action.payload)
        .pipe(
          map(
            user => {
              if (user !== undefined) {
                this.toastr.success('Admin has been successfully registered!', 'Successful!');
                return new EditTenant({value: user.id, id: action.payload.tenantId, param: 'user', activation: true})
              }
              this.toastr.error('There was an error registering this admin user', "Unknown error");
              return new TenantError({ type: 500, message: 'Internal error' })
            }
          ),
          catchError(error => of(new TenantError(error)))
        )
    )
  ));

  updateTenantSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(TenantActionTypes.tenantUpdateTenantSuccess),
    map(tenant => {
      console.log(location.pathname, 'activation');
      if (location.pathname === '/activation') {
        location.href = '/home';
        return null;
      }
      return new SearchTenantsSuccess(tenant);
      // return new fromRouterActions.Go({ path: ['/setup/tenants'] });
    })));


  updateTenant$ = createEffect(() => this.actions$.pipe(
    ofType(TenantActionTypes.tenantUpdateTenant),
    mergeMap((action: AddTenant) =>
      this.tenantService
        .updateTenant(action.payload)
        .pipe(
          map(tenant => new UpdateTenantSuccess(action.payload)),
          catchError(error => of(new TenantError(error)))
        )
    )
  ));


  editTenant$ = createEffect(() => this.actions$.pipe(
    ofType(TenantActionTypes.tenantEditTenant),
    mergeMap((action: AddTenant) =>
      this.tenantService
        .editTenant(action.payload)
        .pipe(
          map(tenant => new GetTenants()),
          catchError(error => of(new TenantError(error)))
        )
    )
  ));


  deleteTenant$ = createEffect(() => this.actions$.pipe(
    ofType(TenantActionTypes.tenantDeleteTenant),
    mergeMap((action: DeleteTenant) =>
      this.tenantService
        .deleteTenant(action.payload)
        .pipe(
          map(() => new DeleteTenantSuccess(action.payload)),
          catchError(error => of(new TenantError(error)))
        )
    )
  ));


  searchTenants$ = createEffect(() => this.actions$.pipe(
    ofType(TenantActionTypes.tenantSearchTenants),
    mergeMap((action: SearchTenants) =>
      this.tenantService
        .searchTenants(action.payload)
        .pipe(
          map(tenants => new SearchTenantsSuccess(tenants)),
          catchError(error => of(new TenantError(error)))
        )
    )
  ));

  constructor(
    private actions$: Actions,
    private tenantService: TenantService,
    private userService: UserService,
    private toastr: ToastrService
  ) {
  }
}
