import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getTenantStore = createFeatureSelector('tenant');

export const getTenantEntities = createSelector(
  getTenantStore,
  fromReducers.tenant.tenantEntitySelectors.selectAll
);

export const getTenants = createSelector(getTenantEntities, entities => {
  return Object.values(entities);
});

export const getTopTenants = createSelector(getTenantEntities, entities => {
  return Object.values(entities).slice(1, 5);
});

export const getTenantsPaging = createSelector(
  getTenantStore,
  (tenantStore: fromReducers.tenant.State) => tenantStore.paging
);

export const getTenantsLoaded = createSelector(
  getTenantStore,
  (tenantStore: fromReducers.tenant.State) => tenantStore.loaded
);

export const getTenantsLoading = createSelector(
  getTenantStore,
  (tenantStore: fromReducers.tenant.State) => tenantStore.loading
);

export const getSelectedTenantId = createSelector(
  getTenantStore,
  (tenantStore: fromReducers.tenant.State) => tenantStore.selectedTenantId
);

export const getSearchTenants = createSelector(
  getTenantStore,
  (tenantStore: fromReducers.tenant.State) => tenantStore.searchTenants
);

export const getTenantById = createSelector(
  getTenantEntities,
  getSelectedTenantId,
  (entities, id) => entities.find(i => i.id === id)
);

export const getTenantsError = createSelector(
  getTenantStore,
  (tenantStore: fromReducers.tenant.State) => tenantStore.error
);
