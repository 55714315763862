import { Action } from '@ngrx/store';

import { SmsProvider } from '@appModels/core-messaging/sms-provider/sms-provider';

export enum SmsProviderActionTypes {
  smsProviderGetSmsProviders = '[SmsProvider] get',
  smsProviderGetSmsProvidersSuccess = '[SmsProvider] get smsProviders success',
  smsProviderAddSmsProvider = '[SmsProvider] add SmsProvider',
  smsProviderAddSmsProviderSuccess = '[SmsProvider] add smsProvider success',
  smsProviderAddAdditionalSmsProviderInfo = '[SmsProvider] add additional SmsProvider Info',
  smsProviderAddAdditionalSmsProviderInfoSuccess = '[SmsProvider] add additional smsProvider Info success',
  smsProviderDeleteSmsProvider = '[SmsProvider] delete smsProvider',
  smsProviderDeleteSmsProviderSuccess = '[SmsProvider] delete smsProvider success',
  smsProviderGetSmsProviderById = '[SmsProvider] get smsProvider by id',
  smsProviderGetSmsProviderByIdSuccess = '[SmsProvider] get smsProvider by id success',
  smsProviderUpdateSmsProvider = '[SmsProvider] update smsProvider',
  smsProviderUpdateSmsProviderSuccess = '[SmsProvider] update smsProvider success',
  smsProviderSearchSmsProviders = '[SmsProvider] search smsProviders',
  smsProviderSearchSmsProvidersSuccess = '[SmsProvider] search smsProviders success',
  smsProviderSearchSmsProvidersReset = '[SmsProvider] search smsProviders reset',
  smsProviderError = '[SmsProvider] error'
}

export class GetSmsProviders implements Action {
  readonly type = SmsProviderActionTypes.smsProviderGetSmsProviders;
}

export class GetSmsProvidersSuccess implements Action {
  readonly type = SmsProviderActionTypes.smsProviderGetSmsProvidersSuccess;
  constructor(public payload: SmsProvider[]) {}
}

export class AddSmsProvider implements Action {
  readonly type = SmsProviderActionTypes.smsProviderAddSmsProvider;
  constructor(public payload: SmsProvider) {}
}

export class AddSmsProviderSuccess implements Action {
  readonly type = SmsProviderActionTypes.smsProviderAddSmsProviderSuccess;
  constructor(public payload: SmsProvider) {}
}

export class AddAdditionalSmsProviderInfo implements Action {
  readonly type = SmsProviderActionTypes.smsProviderAddAdditionalSmsProviderInfo;
  constructor(public payload) {}
}

export class AddAdditionalSmsProviderInfoSuccess implements Action {
  readonly type = SmsProviderActionTypes.smsProviderAddAdditionalSmsProviderInfoSuccess;
  constructor(public payload) {}
}

export class GetSmsProviderById implements Action {
  readonly type = SmsProviderActionTypes.smsProviderGetSmsProviderById;
  constructor(public payload: number) {}
}

export class GetSmsProviderByIdSuccess implements Action {
  readonly type = SmsProviderActionTypes.smsProviderGetSmsProviderByIdSuccess;
  constructor(public payload: SmsProvider) {}
}

export class UpdateSmsProvider implements Action {
  readonly type = SmsProviderActionTypes.smsProviderUpdateSmsProvider;
  constructor(public payload: SmsProvider) {}
}

export class UpdateSmsProviderSuccess implements Action {
  readonly type = SmsProviderActionTypes.smsProviderUpdateSmsProviderSuccess;
  constructor(public payload: SmsProvider) {}
}

export class DeleteSmsProvider implements Action {
  readonly type = SmsProviderActionTypes.smsProviderDeleteSmsProvider;
  constructor(public payload: SmsProvider) {}
}

export class DeleteSmsProviderSuccess implements Action {
  readonly type = SmsProviderActionTypes.smsProviderDeleteSmsProviderSuccess;
  constructor(public payload: SmsProvider) {}
}

export class SearchSmsProviders implements Action {
  readonly type = SmsProviderActionTypes.smsProviderSearchSmsProviders;
  constructor(public payload: string) {}
}

export class SearchSmsProvidersSuccess implements Action {
  readonly type = SmsProviderActionTypes.smsProviderSearchSmsProvidersSuccess;
  constructor(public payload: SmsProvider[]) {}
}

export class SearchSmsProvidersReset implements Action {
  readonly type = SmsProviderActionTypes.smsProviderSearchSmsProvidersReset;
}

export class SmsProviderError implements Action {
  readonly type = SmsProviderActionTypes.smsProviderError;
  constructor(public payload: any) {}
}

export type SmsProviderActions =
  | GetSmsProviders
  | GetSmsProvidersSuccess
  | AddSmsProvider
  | AddSmsProviderSuccess
  | AddAdditionalSmsProviderInfo
  | AddAdditionalSmsProviderInfoSuccess
  | GetSmsProviderById
  | GetSmsProviderByIdSuccess
  | UpdateSmsProvider
  | UpdateSmsProviderSuccess
  | DeleteSmsProvider
  | DeleteSmsProviderSuccess
  | SearchSmsProviders
  | SearchSmsProvidersSuccess
  | SearchSmsProvidersReset
  | SmsProviderError;
