import { Action } from '@ngrx/store';

import { Country } from '@appModels/core-setup/country/country';

export enum CountryActionTypes {
  countryGetCountries = '[Country] get',
  countryGetCountriesSuccess = '[Country] get countries success',
  countryGetCountriesPaged = '[Country] get paged',
  countryGetCountriesPagedSuccess = '[Country] get paged countries success',
  countryAddCountry = '[Country] add Country',
  countryAddCountrySuccess = '[Country] add country success',
  countryDeleteCountry = '[Country] delete country',
  countryDeleteCountrySuccess = '[Country] delete country success',
  countryGetCountryById = '[Country] get country by id',
  countryGetCountryByIdSuccess = '[Country] get country by id success',
  countryUpdateCountry = '[Country] update country',
  countryUpdateCountrySuccess = '[Country] update country success',
  countrySearchCountries = '[Country] search countries',
  countrySearchCountriesSuccess = '[Country] search countries success',
  countrySearchCountriesReset = '[Country] search countries reset',
  countryError = '[Country] error'
}

export class GetCountries implements Action {
  readonly type = CountryActionTypes.countryGetCountries;
}

export class GetCountriesSuccess implements Action {
  readonly type = CountryActionTypes.countryGetCountriesSuccess;
  constructor(public payload: Country[]) {}
}

export class GetCountriesPaged implements Action {
  readonly type = CountryActionTypes.countryGetCountriesPaged;
  constructor(public payload: any) {}
}

export class GetCountriesPagedSuccess implements Action {
  readonly type = CountryActionTypes.countryGetCountriesPagedSuccess;
  constructor(public payload: any) {}
}


export class AddCountry implements Action {
  readonly type = CountryActionTypes.countryAddCountry;
  constructor(public payload: Country) {}
}

export class AddCountrySuccess implements Action {
  readonly type = CountryActionTypes.countryAddCountrySuccess;
  constructor(public payload: Country) {}
}

export class GetCountryById implements Action {
  readonly type = CountryActionTypes.countryGetCountryById;
  constructor(public payload: number) {}
}

export class GetCountryByIdSuccess implements Action {
  readonly type = CountryActionTypes.countryGetCountryByIdSuccess;
  constructor(public payload: Country) {}
}

export class UpdateCountry implements Action {
  readonly type = CountryActionTypes.countryUpdateCountry;
  constructor(public payload: Country) {}
}

export class UpdateCountrySuccess implements Action {
  readonly type = CountryActionTypes.countryUpdateCountrySuccess;
  constructor(public payload: Country) {}
}

export class DeleteCountry implements Action {
  readonly type = CountryActionTypes.countryDeleteCountry;
  constructor(public payload: Country) {}
}

export class DeleteCountrySuccess implements Action {
  readonly type = CountryActionTypes.countryDeleteCountrySuccess;
  constructor(public payload: Country) {}
}

export class SearchCountries implements Action {
  readonly type = CountryActionTypes.countrySearchCountries;
  constructor(public payload: string) {}
}

export class SearchCountriesSuccess implements Action {
  readonly type = CountryActionTypes.countrySearchCountriesSuccess;
  constructor(public payload: Country[]) {}
}

export class SearchCountriesReset implements Action {
  readonly type = CountryActionTypes.countrySearchCountriesReset;
}

export class CountryError implements Action {
  readonly type = CountryActionTypes.countryError;
  constructor(public payload: any) {}
}

export type CountryActions =
  | GetCountries
  | GetCountriesSuccess
  | GetCountriesPaged
  | GetCountriesPagedSuccess
  | AddCountry
  | AddCountrySuccess
  | GetCountryById
  | GetCountryByIdSuccess
  | UpdateCountry
  | UpdateCountrySuccess
  | DeleteCountry
  | DeleteCountrySuccess
  | SearchCountries
  | SearchCountriesSuccess
  | SearchCountriesReset
  | CountryError;
