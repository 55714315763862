import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';

import {Product} from '@appModels/core-setup/product/product';
import {ProductActions, ProductActionTypes} from '@appStore/actions/core-masterdata/product/product.actions';
import * as _ from 'lodash';

export interface State extends EntityState<Product> {
  loaded: boolean;
  loading: boolean;
  error: any;
  selectedProductId: number;
  searchProducts: Product[];
  paging: any;
}

export const adapter: EntityAdapter<Product> = createEntityAdapter<Product>();

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
  selectedProductId: null,
  error: null,
  searchTerm: '',
  searchProducts: null,
  paging: null
});

export function reducer(state = initialState, action: ProductActions): State {
  switch (action.type) {
    case ProductActionTypes.productGetProducts:
    case ProductActionTypes.productAddProduct:
    case ProductActionTypes.productDeleteProduct:
    case ProductActionTypes.productUpdateProduct:
    case ProductActionTypes.productSearchProducts:
    case ProductActionTypes.productGetProductById:
    case ProductActionTypes.productGetProductsByType:
      return {
        ...state,
        loading: true
      };

    case ProductActionTypes.productGetProductsByTypeSuccess:
      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });

    case ProductActionTypes.productGetProductByIdSuccess:
      return { ...state, selectedProductId: action.payload.id, loading: false };

    case ProductActionTypes.productAddProductSuccess:
      return adapter.addOne(action.payload, {
        ...state,
        selectedProductId: action?.payload?.id,
        loading: false,
        loaded: true
      });

    case ProductActionTypes.productUpdateProductSuccess: {
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state,
          loading: false,
          loaded: true
        }
      );
    }

    case ProductActionTypes.productDeleteProductSuccess: {
      return adapter.removeOne(action.payload.id, {
        ...state,
        loading: false,
        loaded: true
      });
    }

    case ProductActionTypes.productSearchProductsSuccess:
      return {
        ...state,
        searchProducts: action.payload,
        loading: false
      };

    case ProductActionTypes.productSearchProductsReset:
      return {
        ...state,
        searchProducts: null
      };

    case ProductActionTypes.productError:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload
      };
    case ProductActionTypes.productGetProductsSuccess:
      return adapter.setAll(action.payload.content, {
        ...state,
        loading: false,
        loaded: true,
        paging: _.omit(action.payload, ['content'])
      });
    default:
      return state;
  }
}

export const productEntitySelectors = adapter.getSelectors();
