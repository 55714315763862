import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

import {
  AddTenantCustomField,
  AddTenantCustomFieldAdmin,
  AddTenantCustomFieldSuccess,
  DeleteTenantCustomField,
  DeleteTenantCustomFieldSuccess,
  EditTenantCustomField,
  EditTenantCustomFieldSuccess,
  GetTenantCustomFieldById,
  GetTenantCustomFieldByIdSuccess,
  GetTenantCustomFields,
  GetTenantCustomFieldsSuccess,
  SearchTenantCustomFields,
  SearchTenantCustomFieldsSuccess,
  TenantCustomFieldActionTypes,
  TenantCustomFieldError,
  UpdateTenantCustomField,
  UpdateTenantCustomFieldSuccess
} from '@appStore/actions/core-masterdata/tenant-custom-field/tenant-custom-field.actions';
import {TenantCustomFieldService} from '@appServices/core-masterdata/tenant-custom-field/tenant-custom-field.service';
import {UserService} from '@appServices/core-auth/user/user.service';

import * as fromRouterActions from '@appStore/actions/router.actions';
import {TenantCustomFieldOptionService} from '@appServices/core-masterdata/tenant-custom-field-option/tenant-custom-field-option.service';

@Injectable()
export class TenantCustomFieldEffects {
  
  addTenantCustomField$ = createEffect(() => this.actions$.pipe(
    ofType(TenantCustomFieldActionTypes.tenantCustomFieldAddTenantCustomField),
    mergeMap((action: AddTenantCustomField) =>
      this.tenantCustomFieldService
        .addTenantCustomField(action.payload)
        .pipe(
          map(tenantCustomField => {

            // this.tenantCustomFieldService.editTenantCustomField({
            //   id: tenantCustomField.id,
            //   param: 'customfieldtype',
            //   value: action.payload.customFieldTypeId
            // }).subscribe(() => {
            //   this.tenantCustomFieldService.editTenantCustomField({
            //     id: tenantCustomField.id,
            //     param: 'language',
            //     value: action.payload.fieldLanguageId
            //   }).subscribe(() => {
            //     this.tenantCustomFieldService.editTenantCustomField({
            //       id: tenantCustomField.id,
            //       param: 'moduleaction',
            //       value: action.payload.moduleActionId
            //     }).subscribe(() => {
            //
                  action.payload.options.map(async element => {
                    let v = {
                      tenantCustomFieldsOptionsValue: element
                    };
                    let result = await this.tenantCustomFieldOptionService.addTenantCustomFieldOption(v).toPromise();
                    await this.tenantCustomFieldOptionService.editTenantCustomFieldOption({
                      value: tenantCustomField.id,
                      param: 'tenantcustomfield',
                      id: result.id
                    }).toPromise();
                    return true;
                  });
                // });
            //   });
            // });


            // this.tenantCustomFieldService.editTenantCustomField({
            //   id: tenantCustomField.id,
            //   param: 'tenant',
            //   value: localStorage.getItem('tenant')
            // }).subscribe();


            return new AddTenantCustomFieldSuccess(tenantCustomField);
          }),
          catchError(error => of(new TenantCustomFieldError(error)))
        )
    )
  ));

  
  loadTenantCustomFields$ = createEffect(() => this.actions$.pipe(
    ofType(TenantCustomFieldActionTypes.tenantCustomFieldGetTenantCustomFields),
    mergeMap(() =>
      this.tenantCustomFieldService
        .getTenantCustomFields()
        .pipe(
          map(tenantCustomFields => new GetTenantCustomFieldsSuccess(tenantCustomFields)),
          catchError(error => of(new TenantCustomFieldError(error)))
        )
    )
  ));

  
  getTenantCustomFieldById$ = createEffect(() => this.actions$.pipe(
    ofType(TenantCustomFieldActionTypes.tenantCustomFieldGetTenantCustomFieldById),
    mergeMap((action: GetTenantCustomFieldById) =>
      this.tenantCustomFieldService
        .getTenantCustomField(action.payload)
        .pipe(
          map(tenantCustomField => new GetTenantCustomFieldByIdSuccess(tenantCustomField)),
          catchError(error => of(new TenantCustomFieldError(error)))
        )
    )
  ));
  
  updateTenantCustomField$ = createEffect(() => this.actions$.pipe(
    ofType(TenantCustomFieldActionTypes.tenantCustomFieldUpdateTenantCustomField),
    mergeMap((action: UpdateTenantCustomField) =>
      this.tenantCustomFieldService
        .updateTenantCustomField(action.payload)
        .pipe(
          map(tenantCustomField => new GetTenantCustomFields()),
          catchError(error => of(new TenantCustomFieldError(error)))
        )
    )
  ));

  
  addTenantCustomFieldAdmin$ = createEffect(() => this.actions$.pipe(
    ofType(TenantCustomFieldActionTypes.tenantCustomFieldAddTenantCustomFieldAdmin),
    switchMap((action: AddTenantCustomFieldAdmin) =>
      this.userService
        .addUser(action.payload)
        .pipe(
          map(user => new EditTenantCustomField({ value: user.id, id: action.payload.tenantCustomFieldId, param: 'user' })),
          catchError(error => of(new TenantCustomFieldError(error)))
        )
    )
  ));
  
  updateTenantCustomFieldSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(TenantCustomFieldActionTypes.tenantCustomFieldUpdateTenantCustomFieldSuccess),
    map(tenantCustomField => new fromRouterActions.Go({path: ['/setup/tenant-custom-fields']}))
  ));

  
  editTenantCustomField$ = createEffect(() => this.actions$.pipe(
    ofType(TenantCustomFieldActionTypes.tenantCustomFieldEditTenantCustomField),
    mergeMap((action: AddTenantCustomField) =>
      this.tenantCustomFieldService
        .editTenantCustomField(action.payload)
        .pipe(
          map(tenantCustomField => new GetTenantCustomFields()),
          catchError(error => of(new TenantCustomFieldError(error)))
        )
    )
  ));

  
  deleteTenantCustomField$ = createEffect(() => this.actions$.pipe(
    ofType(TenantCustomFieldActionTypes.tenantCustomFieldDeleteTenantCustomField),
    mergeMap((action: DeleteTenantCustomField) =>
      this.tenantCustomFieldService
        .deleteTenantCustomField(action.payload)
        .pipe(
          map(() => new DeleteTenantCustomFieldSuccess(action.payload)),
          catchError(error => of(new TenantCustomFieldError(error)))
        )
    )
  ));

  
  searchTenantCustomFields$ = createEffect(() => this.actions$.pipe(
    ofType(TenantCustomFieldActionTypes.tenantCustomFieldSearchTenantCustomFields),
    mergeMap((action: SearchTenantCustomFields) =>
      this.tenantCustomFieldService
        .searchTenantCustomFields(action.payload)
        .pipe(
          map(tenantCustomFields => new SearchTenantCustomFieldsSuccess(tenantCustomFields)),
          catchError(error => of(new TenantCustomFieldError(error)))
        )
    )
  ));

  constructor(private actions$: Actions,
              private tenantCustomFieldService: TenantCustomFieldService,
              private userService: UserService,
              private tenantCustomFieldOptionService: TenantCustomFieldOptionService) {
  }
}
