import {Injectable} from '@angular/core';
import {CoreAftersalesService} from '@appServices/core-aftersales/core-aftersales.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WorkOrderStatusService extends CoreAftersalesService {

  constructor(private http: HttpClient) {
    super(http, 'workorderstatus');
  }

  getWorkOrderStates(repairCentreId: number): Observable<any> {
    const url = `${this.base_url}/${repairCentreId}`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched wo states for repair centre=${repairCentreId}`)),
      catchError(this.handleError(`getWorkOrderStates id=${repairCentreId}`))
    );
  }

  getWorkOrderStatesForTenant(): Observable<any> {
    const tenantId = localStorage.getItem('tenant');
    const url = `${this.base_url}/tenant/${tenantId}`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched wo states for tenant=${tenantId}`)),
      catchError(this.handleError(`getWorkOrderStatesForTenant id=${tenantId}`))
    );
  }
}
