import {Injectable} from '@angular/core';
import {CoreAftersalesService} from '../core-aftersales.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from "rxjs";
import {catchError, tap} from 'rxjs/operators';
import {FaultWarrantyDisposition} from '../../../models/core-setup/fault-action-setup/fault-warranty-disposition';
import {Fault} from '../../../models/core-setup/fault-action-setup/fault';

@Injectable({
  providedIn: 'root'
})
export class FaultService extends CoreAftersalesService {

  constructor(private http: HttpClient) {
    super(http, 'fault');
  }

  /** GET fault warranty dispositions from the server */
  getAllFaultWarrantyDispositions(): Observable<FaultWarrantyDisposition[]> {
    return this.http.get<FaultWarrantyDisposition[]>(this.base_url + '/dispositions', {headers: this.headers}).pipe(
      tap(types => this.log(`fetched fault warranty dispositions`)),
      catchError(this.handleError('getAllFaultWarrantyDisposition', []))
    );
  }

  getWorkOrderFaults(workOrderId: number): Observable<Fault[]> {
    return this.http.get<Fault[]>(this.base_url + '/' + workOrderId, {headers: this.headers}).pipe(
      tap(types => this.log(`fetched work order faults`)),
      catchError(this.handleError('getProductFaults', [])));
  }

  getFaults(level, globalProductId, query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        params = params.append(val, query[val]);
      });
    }
    return this.http.get<any>(`${this.base_url}/level/${level}/globalProductId/${globalProductId}`, {
      params,
      headers: this.headers
    }).pipe(
      tap(faults => this.log(`fetched faults`)),
      catchError(this.handleError('getAllFaults', []))
    );
  }

  getFault(id): Observable<any> {
    const url = `${this.base_url}/id/${id}`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched fault id=${id}`)),
      catchError(this.handleError(`getFault id=${id}`))
    );
  }

  deleteFault(id): Observable<any> {
    const url = `${this.base_url}/id/${id}`;
    return this.http.delete(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`delete fault id=${id}`)),
      catchError(this.handleError(`deleteFault id=${id}`))
    );
  }


  saveFault(fault: Fault) {
    return this.http.post<any>(this.base_url, fault, {headers: this.headers}).pipe(
      tap(fault => this.log(`saved faults`)),
      catchError(this.handleError('saveFault', []))
    );
  }

  updateFault(fault: Fault) {
    return this.http.patch<any>(`${this.base_url}/update/${fault.id}`, fault, {headers: this.headers}).pipe(
      tap(fault => this.log(`update fault`)),
      catchError(this.handleError('updateFault', []))
    );
  }
}

