import { Action } from '@ngrx/store';

import { Currency } from '@appModels/core-setup/currency/currency';

export enum CurrencyActionTypes {
  currencyGetCurrencies = '[Currency] get',
  currencyGetCurrenciesSuccess = '[Currency] get currencies success',
  currencyGetCurrenciesPaged = '[Currency] get paged',
  currencyGetCurrenciesPagedSuccess = '[Currency] get paged currencies success',
  currencyAddCurrency = '[Currency] add Currency',
  currencyAddCurrencySuccess = '[Currency] add currency success',
  currencyDeleteCurrency = '[Currency] delete currency',
  currencyDeleteCurrencySuccess = '[Currency] delete currency success',
  currencyGetCurrencyById = '[Currency] get currency by id',
  currencyGetCurrencyByIdSuccess = '[Currency] get currency by id success',
  currencyUpdateCurrency = '[Currency] update currency',
  currencyUpdateCurrencySuccess = '[Currency] update currency success',
  currencySearchCurrencies = '[Currency] search currencies',
  currencySearchCurrenciesSuccess = '[Currency] search currencies success',
  currencySearchCurrenciesReset = '[Currency] search currencies reset',
  currencyError = '[Currency] error'
}

export class GetCurrencies implements Action {
  readonly type = CurrencyActionTypes.currencyGetCurrencies;
}

export class GetCurrenciesSuccess implements Action {
  readonly type = CurrencyActionTypes.currencyGetCurrenciesSuccess;
  constructor(public payload: Currency[]) {}
}

export class GetCurrenciesPaged implements Action {
  readonly type = CurrencyActionTypes.currencyGetCurrenciesPaged;
  constructor(public payload: any) {}
}

export class GetCurrenciesPagedSuccess implements Action {
  readonly type = CurrencyActionTypes.currencyGetCurrenciesPagedSuccess;
  constructor(public payload: any) {}
}

export class AddCurrency implements Action {
  readonly type = CurrencyActionTypes.currencyAddCurrency;
  constructor(public payload: Currency) {}
}

export class AddCurrencySuccess implements Action {
  readonly type = CurrencyActionTypes.currencyAddCurrencySuccess;
  constructor(public payload: Currency) {}
}

export class GetCurrencyById implements Action {
  readonly type = CurrencyActionTypes.currencyGetCurrencyById;
  constructor(public payload: number) {}
}

export class GetCurrencyByIdSuccess implements Action {
  readonly type = CurrencyActionTypes.currencyGetCurrencyByIdSuccess;
  constructor(public payload: Currency) {}
}

export class UpdateCurrency implements Action {
  readonly type = CurrencyActionTypes.currencyUpdateCurrency;
  constructor(public payload: Currency) {}
}

export class UpdateCurrencySuccess implements Action {
  readonly type = CurrencyActionTypes.currencyUpdateCurrencySuccess;
  constructor(public payload: Currency) {}
}

export class DeleteCurrency implements Action {
  readonly type = CurrencyActionTypes.currencyDeleteCurrency;
  constructor(public payload: Currency) {}
}

export class DeleteCurrencySuccess implements Action {
  readonly type = CurrencyActionTypes.currencyDeleteCurrencySuccess;
  constructor(public payload: Currency) {}
}

export class SearchCurrencies implements Action {
  readonly type = CurrencyActionTypes.currencySearchCurrencies;
  constructor(public payload: string) {}
}

export class SearchCurrenciesSuccess implements Action {
  readonly type = CurrencyActionTypes.currencySearchCurrenciesSuccess;
  constructor(public payload: Currency[]) {}
}

export class SearchCurrenciesReset implements Action {
  readonly type = CurrencyActionTypes.currencySearchCurrenciesReset;
}

export class CurrencyError implements Action {
  readonly type = CurrencyActionTypes.currencyError;
  constructor(public payload: any) {}
}

export type CurrencyActions =
  | GetCurrencies
  | GetCurrenciesSuccess
  | GetCurrenciesPaged
  | GetCurrenciesPagedSuccess
  | AddCurrency
  | AddCurrencySuccess
  | GetCurrencyById
  | GetCurrencyByIdSuccess
  | UpdateCurrency
  | UpdateCurrencySuccess
  | DeleteCurrency
  | DeleteCurrencySuccess
  | SearchCurrencies
  | SearchCurrenciesSuccess
  | SearchCurrenciesReset
  | CurrencyError;
