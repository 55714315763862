import { Action } from '@ngrx/store';

import { ProductAttribute } from '@appModels/core-setup/product-attribute/product-attribute';

export enum ProductAttributeActionTypes {
  productAttributeGetProductAttributes = '[ProductAttribute] get',
  productAttributeGetProductAttributesSuccess = '[ProductAttribute] get productAttributes success',
  productAttributeAddProductAttribute = '[ProductAttribute] add ProductAttribute',
  productAttributeAddProductAttributeSuccess = '[ProductAttribute] add productAttribute success',
  productAttributeDeleteProductAttribute = '[ProductAttribute] delete productAttribute',
  productAttributeDeleteProductAttributeSuccess = '[ProductAttribute] delete productAttribute success',
  productAttributeGetProductAttributeById = '[ProductAttribute] get productAttribute by id',
  productAttributeGetProductAttributeByIdSuccess = '[ProductAttribute] get productAttribute by id success',
  productAttributeUpdateProductAttribute = '[ProductAttribute] update productAttribute',
  productAttributeUpdateProductAttributeSuccess = '[ProductAttribute] update productAttribute success',
  productAttributeEditProductAttribute = '[ProductAttribute] edit productAttribute',
  productAttributeEditProductAttributeSuccess = '[ProductAttribute] edit productAttribute success',
  productAttributeSearchProductAttributes = '[ProductAttribute] search productAttributes',
  productAttributeSearchProductAttributesSuccess = '[ProductAttribute] search productAttributes success',
  productAttributeSearchProductAttributesReset = '[ProductAttribute] search productAttributes reset',
  productAttributeError = '[ProductAttribute] error'
}

export class GetProductAttributes implements Action {
  readonly type = ProductAttributeActionTypes.productAttributeGetProductAttributes;
}

export class GetProductAttributesSuccess implements Action {
  readonly type = ProductAttributeActionTypes.productAttributeGetProductAttributesSuccess;
  constructor(public payload: ProductAttribute[]) {}
}

export class AddProductAttribute implements Action {
  readonly type = ProductAttributeActionTypes.productAttributeAddProductAttribute;
  constructor(public payload: ProductAttribute) {}
}

export class AddProductAttributeSuccess implements Action {
  readonly type = ProductAttributeActionTypes.productAttributeAddProductAttributeSuccess;
  constructor(public payload: ProductAttribute) {}
}

export class GetProductAttributeById implements Action {
  readonly type = ProductAttributeActionTypes.productAttributeGetProductAttributeById;
  constructor(public payload: number) {}
}

export class GetProductAttributeByIdSuccess implements Action {
  readonly type = ProductAttributeActionTypes.productAttributeGetProductAttributeByIdSuccess;
  constructor(public payload: ProductAttribute) {}
}

export class UpdateProductAttribute implements Action {
  readonly type = ProductAttributeActionTypes.productAttributeUpdateProductAttribute;
  constructor(public payload: ProductAttribute) {}
}

export class UpdateProductAttributeSuccess implements Action {
  readonly type = ProductAttributeActionTypes.productAttributeUpdateProductAttributeSuccess;
  constructor(public payload: ProductAttribute) {}
}

export class EditProductAttribute implements Action {
  readonly type = ProductAttributeActionTypes.productAttributeEditProductAttribute;
  constructor(public payload) {}
}

export class EditProductAttributeSuccess implements Action {
  readonly type = ProductAttributeActionTypes.productAttributeEditProductAttributeSuccess;
  constructor(public payload) {}
}

export class DeleteProductAttribute implements Action {
  readonly type = ProductAttributeActionTypes.productAttributeDeleteProductAttribute;
  constructor(public payload: ProductAttribute) {}
}

export class DeleteProductAttributeSuccess implements Action {
  readonly type = ProductAttributeActionTypes.productAttributeDeleteProductAttributeSuccess;
  constructor(public payload: ProductAttribute) {}
}

export class SearchProductAttributes implements Action {
  readonly type = ProductAttributeActionTypes.productAttributeSearchProductAttributes;
  constructor(public payload: string) {}
}

export class SearchProductAttributesSuccess implements Action {
  readonly type = ProductAttributeActionTypes.productAttributeSearchProductAttributesSuccess;
  constructor(public payload: ProductAttribute[]) {}
}

export class SearchProductAttributesReset implements Action {
  readonly type = ProductAttributeActionTypes.productAttributeSearchProductAttributesReset;
}

export class ProductAttributeError implements Action {
  readonly type = ProductAttributeActionTypes.productAttributeError;
  constructor(public payload: any) {}
}

export type ProductAttributeActions =
  | GetProductAttributes
  | GetProductAttributesSuccess
  | AddProductAttribute
  | AddProductAttributeSuccess
  | GetProductAttributeById
  | GetProductAttributeByIdSuccess
  | UpdateProductAttribute
  | UpdateProductAttributeSuccess
  | EditProductAttribute
  | EditProductAttributeSuccess
  | DeleteProductAttribute
  | DeleteProductAttributeSuccess
  | SearchProductAttributes
  | SearchProductAttributesSuccess
  | SearchProductAttributesReset
  | ProductAttributeError;
