import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';

import {ProductCatalogue} from '@appModels/core-setup/product-catalogue/product-catalogue';
import {
  ProductCatalogueActions,
  ProductCatalogueActionTypes
} from '@appStore/actions/core-masterdata/product-catalogue/product-catalogue.actions';

export interface State extends EntityState<ProductCatalogue> {
  loaded: boolean;
  loading: boolean;
  error: any;
  selectedProductCatalogueId: number;
  searchProductCatalogues: ProductCatalogue[];
  tenantProductCatalogues: ProductCatalogue[];
  selectedProductsCatalogue: ProductCatalogue;
}

export const adapter: EntityAdapter<ProductCatalogue> = createEntityAdapter<ProductCatalogue>();

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
  selectedProductCatalogueId: null,
  error: null,
  searchTerm: '',
  searchProductCatalogues: null,
  tenantProductCatalogues: [],
  selectedProductsCatalogue: null
});

export function reducer(state = initialState, action: ProductCatalogueActions): State {
  switch (action.type) {
    case ProductCatalogueActionTypes.productCatalogueGetProductCatalogues:
    case ProductCatalogueActionTypes.productCatalogueAddProductCatalogue:
    case ProductCatalogueActionTypes.productCatalogueEditProductCatalogue:
    case ProductCatalogueActionTypes.productCatalogueDeleteProductCatalogue:
    case ProductCatalogueActionTypes.productCatalogueUpdateProductCatalogue:
    case ProductCatalogueActionTypes.productCatalogueSearchProductCatalogues:
    case ProductCatalogueActionTypes.productCatalogueGetProductCatalogueById:
    case ProductCatalogueActionTypes.productCatalogueGetProductCataloguesByType:
      return {
        ...state,
        loading: true
      };

    case ProductCatalogueActionTypes.productCatalogueGetProductCataloguesSuccess:
    case ProductCatalogueActionTypes.productCatalogueGetProductCataloguesByTypeSuccess:
      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });


    case ProductCatalogueActionTypes.productCatalogueGetProductCatalogueByIdSuccess:
      return { ...state, selectedProductsCatalogue: action.payload, selectedProductCatalogueId: action.payload.id, loading: false };

    case ProductCatalogueActionTypes.productCatalogueAddProductCatalogueSuccess:
      return adapter.addOne(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });

    case ProductCatalogueActionTypes.productCatalogueEditProductCatalogueSuccess: {
        return adapter.updateOne(
          {
            id: action.payload.id,
            changes: action.payload
          },
          {
            ...state,
            loading: false,
            loaded: true
          }
        );
      }

    case ProductCatalogueActionTypes.productCatalogueUpdateProductCatalogueSuccess: {
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state,
          loading: false,
          loaded: true
        }
      );
    }

    case ProductCatalogueActionTypes.productCatalogueDeleteProductCatalogueSuccess: {
      return adapter.removeOne(action.payload.id, {
        ...state,
        loading: false,
        loaded: true
      });
    }

    case ProductCatalogueActionTypes.productCatalogueSearchProductCataloguesSuccess:
      return {
        ...state,
        searchProductCatalogues: action.payload,
        loading: false
      };

    case ProductCatalogueActionTypes.productCatalogueSearchProductCataloguesReset:
      return {
        ...state,
        searchProductCatalogues: null
      };

    case ProductCatalogueActionTypes.productCatalogueError:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload
      };

    default:
      return state;
  }
}

export const productCatalogueEntitySelectors = adapter.getSelectors();
