import {Action} from '@ngrx/store';

import {ShippingCompany} from '@appModels/core-inventory/shipping-company/shipping-company';

export enum ShippingCompanyActionTypes {
  shippingCompanyGetShippingCompanies = '[ShippingCompany] get',
  shippingCompanyGetShippingCompaniesSuccess = '[ShippingCompany] get shippingCompanies success',
  shippingCompanyGetShippingCompaniesByTenantId = '[ShippingCompany] get shipping companies by tenant id',
  shippingCompanyGetShippingCompaniesByTenantIdSuccess = '[ShippingCompany] get shipping companies by tenant id success',
  shippingCompanyGetShippingCompaniesByTenantIdPaged = '[ShippingCompany] get shipping companies by tenant id paged',
  shippingCompanyGetShippingCompaniesByTenantIdPagedSuccess = '[ShippingCompany] get shipping companies by tenant id paged success',
  shippingCompanyAddShippingCompany = '[ShippingCompany] add ShippingCompany',
  shippingCompanyAddShippingCompanySuccess = '[ShippingCompany] add shippingCompany success',
  shippingCompanyAddAdditionalShippingCompanyInfo = '[ShippingCompany] add additional ShippingCompany Info',
  shippingCompanyAddAdditionalShippingCompanyInfoSuccess = '[ShippingCompany] add additional shippingCompany Info success',
  shippingCompanyDeleteShippingCompany = '[ShippingCompany] delete shippingCompany',
  shippingCompanyDeleteShippingCompanySuccess = '[ShippingCompany] delete shippingCompany success',
  shippingCompanyGetShippingCompanyById = '[ShippingCompany] get shippingCompany by id',
  shippingCompanyGetShippingCompanyByIdSuccess = '[ShippingCompany] get shippingCompany by id success',
  shippingCompanyUpdateShippingCompany = '[ShippingCompany] update shippingCompany',
  shippingCompanyUpdateShippingCompanySuccess = '[ShippingCompany] update shippingCompany success',
  shippingCompanySearchShippingCompanies = '[ShippingCompany] search shippingCompanies',
  shippingCompanySearchShippingCompaniesSuccess = '[ShippingCompany] search shippingCompanies success',
  shippingCompanySearchShippingCompaniesReset = '[ShippingCompany] search shippingCompanies reset',
  shippingCompanyAddTenantShippingCompanies = '[ShippingCompany] add shippingCompany to tenant',
  shippingCompanyAddTenantShippingCompaniesSuccess = '[ShippingCompany] add shippingCompany to tenant success',

  shippingCompanyError = '[ShippingCompany] error'
}

export class GetShippingCompanies implements Action {
  readonly type = ShippingCompanyActionTypes.shippingCompanyGetShippingCompanies;
}

export class GetShippingCompaniesSuccess implements Action {
  readonly type = ShippingCompanyActionTypes.shippingCompanyGetShippingCompaniesSuccess;

  constructor(public payload: ShippingCompany[]) {
  }
}

export class GetShippingCompanyByTenantIdPaged implements Action {
  readonly type = ShippingCompanyActionTypes.shippingCompanyGetShippingCompaniesByTenantIdPaged;

  constructor(public payload: any) {
  }
}

export class GetShippingCompanyByTenantIdIdPagedSuccess implements Action {
  readonly type = ShippingCompanyActionTypes.shippingCompanyGetShippingCompaniesByTenantIdPagedSuccess;

  constructor(public payload: any) {
  }
}

export class GetShippingCompanyByTenantId implements Action {
  readonly type = ShippingCompanyActionTypes.shippingCompanyGetShippingCompaniesByTenantId;

  constructor(public payload: number) {
  }
}

export class GetShippingCompanyByTenantIdIdSuccess implements Action {
  readonly type = ShippingCompanyActionTypes.shippingCompanyGetShippingCompaniesByTenantIdSuccess;

  constructor(public payload: ShippingCompany[]) {
  }
}

export class AddShippingCompany implements Action {
  readonly type = ShippingCompanyActionTypes.shippingCompanyAddShippingCompany;

  constructor(public payload: ShippingCompany) {
  }
}

export class AddShippingCompanySuccess implements Action {
  readonly type = ShippingCompanyActionTypes.shippingCompanyAddShippingCompanySuccess;

  constructor(public payload: ShippingCompany) {
  }
}

export class AddAdditionalShippingCompanyInfo implements Action {
  readonly type = ShippingCompanyActionTypes.shippingCompanyAddAdditionalShippingCompanyInfo;

  constructor(public payload) {
  }
}

export class AddAdditionalShippingCompanyInfoSuccess implements Action {
  readonly type = ShippingCompanyActionTypes.shippingCompanyAddAdditionalShippingCompanyInfoSuccess;

  constructor(public payload) {
  }
}

export class GetShippingCompanyById implements Action {
  readonly type = ShippingCompanyActionTypes.shippingCompanyGetShippingCompanyById;

  constructor(public payload: number) {
  }
}

export class GetShippingCompanyByIdSuccess implements Action {
  readonly type = ShippingCompanyActionTypes.shippingCompanyGetShippingCompanyByIdSuccess;

  constructor(public payload: ShippingCompany) {
  }
}

export class UpdateShippingCompany implements Action {
  readonly type = ShippingCompanyActionTypes.shippingCompanyUpdateShippingCompany;

  constructor(public payload: ShippingCompany) {
  }
}

export class UpdateShippingCompanySuccess implements Action {
  readonly type = ShippingCompanyActionTypes.shippingCompanyUpdateShippingCompanySuccess;

  constructor(public payload: ShippingCompany) {
  }
}

export class DeleteShippingCompany implements Action {
  readonly type = ShippingCompanyActionTypes.shippingCompanyDeleteShippingCompany;

  constructor(public payload: ShippingCompany) {
  }
}

export class DeleteShippingCompanySuccess implements Action {
  readonly type = ShippingCompanyActionTypes.shippingCompanyDeleteShippingCompanySuccess;

  constructor(public payload: ShippingCompany) {
  }
}

export class SearchShippingCompanies implements Action {
  readonly type = ShippingCompanyActionTypes.shippingCompanySearchShippingCompanies;

  constructor(public payload: string) {
  }
}

export class SearchShippingCompaniesSuccess implements Action {
  readonly type = ShippingCompanyActionTypes.shippingCompanySearchShippingCompaniesSuccess;

  constructor(public payload: ShippingCompany[]) {
  }
}

export class SearchShippingCompaniesReset implements Action {
  readonly type = ShippingCompanyActionTypes.shippingCompanySearchShippingCompaniesReset;
}

export class ShippingCompanyAddTenant implements Action {
  readonly type = ShippingCompanyActionTypes.shippingCompanyAddTenantShippingCompanies;

  constructor(public payload: ShippingCompany) {
  }
}

export class ShippingCompanyAddTenantSuccess implements Action {
  readonly type = ShippingCompanyActionTypes.shippingCompanyAddTenantShippingCompaniesSuccess;

  constructor(public payload: any) {
  }
}


export class ShippingCompanyError implements Action {
  readonly type = ShippingCompanyActionTypes.shippingCompanyError;

  constructor(public payload: any) {
  }
}

export type ShippingCompanyActions =
  | GetShippingCompanies
  | GetShippingCompaniesSuccess
  | AddShippingCompany
  | AddShippingCompanySuccess
  | AddAdditionalShippingCompanyInfo
  | AddAdditionalShippingCompanyInfoSuccess
  | GetShippingCompanyById
  | GetShippingCompanyByIdSuccess
  | UpdateShippingCompany
  | UpdateShippingCompanySuccess
  | DeleteShippingCompany
  | DeleteShippingCompanySuccess
  | SearchShippingCompanies
  | SearchShippingCompaniesSuccess
  | SearchShippingCompaniesReset
  | GetShippingCompanyByTenantId
  | GetShippingCompanyByTenantIdIdSuccess
  | GetShippingCompanyByTenantIdPaged
  | GetShippingCompanyByTenantIdIdPagedSuccess
  | ShippingCompanyAddTenant
  | ShippingCompanyAddTenantSuccess
  | ShippingCompanyError;
