import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';

import {VatConfiguration} from '@appModels/core-accounting/vat-configuration/vat-configuration';

import {CoreAccountingService} from '../core-accounting.service';


@Injectable({ providedIn: 'root' })
export class VatConfigurationService extends CoreAccountingService {


  constructor(
    private http: HttpClient,

  ) {
    super(http, 'vatconfiguration');
  }

  /** GET vatConfigurations from the server */
  getVatConfigurations(query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        params = params.append(val, query[val]);
      });
    }
    return this.http.get<any>(this.base_url + '/tenant/' + localStorage.getItem('tenant'), {
      params,
      headers: this.headers
    }).pipe(
      tap(vatConfigurations => this.log(`fetched vatConfigurations`)),
      catchError(this.handleError('getVatConfigurations', []))
    );
  }

  getVatConfigurationsByTenantId(id: number): Observable<VatConfiguration[]> {
    return this.http.get<VatConfiguration[]>(this.base_url + '/tenant/' + id, {headers: this.headers}).pipe(
      tap(vatConfigurations => this.log(`fetched vatConfigurations`)),
      catchError(this.handleError('getVatConfigurations', []))
    );
  }

  /** GET vatConfiguration by id. Return `undefined` when id not found */
  getVatConfigurationNo404<Data>(id: number): Observable<VatConfiguration> {
    const url = `${this.base_url}/?id=${id}`;
    return this.http.get<VatConfiguration[]>(url).pipe(
      map(vatConfigurations => vatConfigurations[0]), // returns a {0|1} element array
      tap(h => {
        const outcome = h ? `fetched` : `did not find`;
        this.log(`${outcome} vatConfiguration id=${id}`);
      }),
      catchError(this.handleError<VatConfiguration>(`getVatConfiguration id=${id}`))
    );
  }

  /** GET vatConfiguration by id. Will 404 if id not found */
  getVatConfiguration(id: number): Observable<VatConfiguration> {
    const url = `${this.base_url}/${id}/`;
    return this.http.get<VatConfiguration>(url,{ headers: this.headers }).pipe(
      tap(_ => this.log(`fetched vatConfiguration id=${id}`)),
      catchError(this.handleError<VatConfiguration>(`getVatConfiguration id=${id}`))
    );
  }

  /** GET vatConfiguration by tenant id. Will 404 if id not found */
  getVatConfigurationByTenant(query?): Observable<VatConfiguration[]> {
    let params = new HttpParams();
    if (query !== undefined){
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        params = params.append(val, query[val]);
      });
    }
    const url = `${this.base_url}/tenant/` + localStorage.getItem('tenant');
    return this.http.get<any>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched vatConfiguration id=`)),
      catchError(this.handleError(`getVatConfiguration `, []))
    );
  }

  /** GET vatConfiguration by product id. Will 404 if id not found */
  getVatConfigurationProductCatalogue(id): Observable<any> {
    // const id = localStorage.getItem('tenant');
    const url = `${this.base_url}/tenant/productcatalogue/${id}/`;
    return this.http.get(url,{ headers: this.headers }).pipe(
      tap(_ => this.log(`fetched vatConfigurations by product catalogue id=${id}`)),
      catchError(this.handleError(`getVatConfigurationByProductCatalogue id=${id}`))
    );
  }

  /* GET vatConfigurations whose name contains search term */
  searchVatConfigurations(term: string): Observable<VatConfiguration[]> {
    if (!term.trim()) {
      // if not search term, return empty vatConfiguration array.
      return of([]);
    }
    return this.http.get<VatConfiguration[]>(`api/vatConfigurations/?name=${term}`).pipe(
      tap(_ => this.log(`found vatConfigurations matching "${term}"`)),
      catchError(this.handleError<VatConfiguration[]>('searchVatConfigurations', []))
    );
  }

  //////// Save methods //////////

  /** POST: add a new vatConfiguration to the server */
  addVatConfiguration(vatConfiguration: VatConfiguration): Observable<VatConfiguration> {
    return this.http.post<VatConfiguration>(this.base_url + '/', vatConfiguration, { headers: this.headers }).pipe(
      tap((vatConfiguration: VatConfiguration) => this.log(`added vatConfiguration w/ id=${vatConfiguration.id}`)),
      catchError(this.handleError<VatConfiguration>('addVatConfiguration'))
    );
  }

  // addVatConfiguration(vatConfiguration: VatConfiguration): Observable<VatConfiguration> {
  //   return this.http.post<VatConfiguration>(this.base_url + '/', vatConfiguration, { headers: this.headers }).pipe(
  //     tap((newVatConfiguration: VatConfiguration) => {
  //       this.log(`added vatConfiguration w/ id=${newVatConfiguration.tenantId}`);
  //       // patch info
  //       this.editVatConfiguration({ value: vatConfiguration.id, id: newVatConfiguration.tenantId, param: 'tenant' }).subscribe(data => {
  //       }, error => console.log(error, 'vatConfiguration error'));
  //     }),
  //     catchError(this.handleError<VatConfiguration>('addVatConfiguration'))
  //   );
  // }

  /** POST: add a new vatConfiguration to the server */
  addVatConfigurationAdditionalInfo(vatConfiguration): Observable<{}> {
    return this.http.post(`${this.base_url}/additionalInfo/${vatConfiguration.id}`, vatConfiguration.value, { headers: this.headers }).pipe(
      tap((res) => this.log(`added vatConfiguration  info `)),
      catchError(this.handleError('addVatConfiguration'))
    );
  }

  /** DELETE: delete the vatConfiguration from the server */
  deleteVatConfiguration(vatConfiguration: VatConfiguration | number): Observable<VatConfiguration> {
    const id = typeof vatConfiguration === 'number' ? vatConfiguration : vatConfiguration.id;
    const url = `${this.base_url}/${id}`;

    return this.http.delete<VatConfiguration>(url, { headers: this.headers }).pipe(
      tap(_ => this.log(`deleted vatConfiguration id=${id}`)),
      catchError(this.handleError<VatConfiguration>('deleteVatConfiguration'))
    );
  }

  /** PUT: update the vatConfiguration on the server */
  updateVatConfiguration(vatConfiguration: VatConfiguration): Observable<any> {
    return this.http.put(`${this.base_url}/${vatConfiguration.id}`, vatConfiguration, { headers: this.headers }).pipe(
      tap(_ => this.log(`updated vatConfiguration id=${vatConfiguration.id}`)),
      catchError(this.handleError<any>('updateVatConfiguration'))
    );
  }

  /** Patch: update the vatConfiguration on the server */
  editVatConfiguration(data): Observable<any> {
    return this.http.patch(`${this.base_url}/${data.id}/${data.param}/${data.value}`, {}, {headers: this.headers}).pipe(
      tap(_ => this.log(`edit vatConfiguration id=${data.id}`)),
      catchError(this.handleError<any>('editVatConfiguration'))
    );
  }

}
