import {createEntityAdapter, EntityAdapter, EntityState,} from '@ngrx/entity';

import {Currency} from '@appModels/core-setup/currency/currency';
import {CurrencyActions, CurrencyActionTypes} from '@appStore/actions/core-masterdata/currency/currency.actions';
import * as _ from 'lodash';

export interface State extends EntityState<Currency> {
  loaded: boolean;
  loading: boolean;
  error: any;
  selectedCurrencyId: number;
  searchCurrencies: Currency[];
  paging: any;
}

export const adapter: EntityAdapter<Currency> = createEntityAdapter<Currency>();

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
  selectedCurrencyId: null,
  error: null,
  searchTerm: '',
  searchCurrencies: null,
  paging: null
});

export function reducer(state = initialState, action: CurrencyActions): State {
  switch (action.type) {
    case CurrencyActionTypes.currencyGetCurrencies:
    case CurrencyActionTypes.currencyAddCurrency:
    case CurrencyActionTypes.currencyDeleteCurrency:
    case CurrencyActionTypes.currencyUpdateCurrency:
    case CurrencyActionTypes.currencySearchCurrencies:
    case CurrencyActionTypes.currencyGetCurrencyById:
    case CurrencyActionTypes.currencyGetCurrenciesPaged:
      return {
        ...state,
        loading: true
      };

    case CurrencyActionTypes.currencyGetCurrenciesSuccess:
      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });

    case CurrencyActionTypes.currencyGetCurrencyByIdSuccess:
      return {...state, selectedCurrencyId: action.payload.id, loading: false};

    case CurrencyActionTypes.currencyAddCurrencySuccess:
      return adapter.addOne(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });

    case CurrencyActionTypes.currencyUpdateCurrencySuccess: {
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state,
          loading: false,
          loaded: true
        }
      );
    }

    case CurrencyActionTypes.currencyDeleteCurrencySuccess: {
      return adapter.removeOne(action.payload.id, {
        ...state,
        loading: false,
        loaded: true
      });
    }

    case CurrencyActionTypes.currencySearchCurrenciesSuccess:
      return {
        ...state,
        searchCurrencies: action.payload,
        loading: false
      };

    case CurrencyActionTypes.currencySearchCurrenciesReset:
      return {
        ...state,
        searchCurrencies: null
      };

    case CurrencyActionTypes.currencyError:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload
      };

    case CurrencyActionTypes.currencyGetCurrenciesPagedSuccess:
      return adapter.setAll(action.payload.content, {
        ...state,
        loading: false,
        loaded: true,
        paging: _.omit(action.payload, ['content'])
      });
    default:
      return state;
  }
}

export const currencyEntitySelectors = adapter.getSelectors();
