import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getStockingPointUserStore = createFeatureSelector('stockingPointUser');

export const getStockingPointUserEntities = createSelector(
  getStockingPointUserStore,
  fromReducers.stockingPointUser.StockingPointUserEntitySelectors.selectAll
);

export const getStockingPointUsers = createSelector(getStockingPointUserEntities, entities => {
  return Object.values(entities);
});

export const getStockingPointUserSelectedId = createSelector(
  getStockingPointUserStore,
  (stockingPointUserStore: fromReducers.stockingPointUser.State) => stockingPointUserStore.selectedUserId
);


export const getStockingPointUserById = createSelector(
  getStockingPointUserEntities,
  getStockingPointUserSelectedId,
  (entities, id) => entities.find(i => i.id === id)
);

export const getStockingPointUsersLoading = createSelector(
  getStockingPointUserStore,
  (StockingPointUserStore: fromReducers.stockingPointUser.State) => StockingPointUserStore.loading
);

export const getStockingPointUsersPaging = createSelector(
  getStockingPointUserStore,
  (StockingPointUserStore: fromReducers.stockingPointUser.State) => StockingPointUserStore.paging
);
