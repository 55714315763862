import { Action } from '@ngrx/store';
import {
  EntityAdapter,
  createEntityAdapter,
  EntityState,
  Update
} from '@ngrx/entity';

import { Module } from '@appModels/core-setup/module/module';
import { ModuleActions, ModuleActionTypes } from '@appStore/actions/core-masterdata/module/module.actions';

export interface State extends EntityState<Module> {
  loaded: boolean;
  loading: boolean;
  error: any;
  selectedModuleId: number;
  searchModules: Module[];
}

export const adapter: EntityAdapter<Module> = createEntityAdapter<Module>();

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
  selectedModuleId: null,
  error: null,
  searchTerm: '',
  searchModules: null
});

export function reducer(state = initialState, action: ModuleActions): State {
  switch (action.type) {
    case ModuleActionTypes.moduleGetModules:
    case ModuleActionTypes.moduleAddModule:
    case ModuleActionTypes.moduleDeleteModule:
    case ModuleActionTypes.moduleUpdateModule:
    case ModuleActionTypes.moduleSearchModules:
    case ModuleActionTypes.moduleGetModuleById:
      return {
        ...state,
        loading: true
      };

    case ModuleActionTypes.moduleGetModulesSuccess:
      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });

    case ModuleActionTypes.moduleGetModuleByIdSuccess:
      return { ...state, selectedModuleId: action.payload.id, loading: false };

    case ModuleActionTypes.moduleAddModuleSuccess:
      return adapter.addOne(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });

    case ModuleActionTypes.moduleUpdateModuleSuccess: {
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state,
          loading: false,
          loaded: true
        }
      );
    }

    case ModuleActionTypes.moduleDeleteModuleSuccess: {
      return adapter.removeOne(action.payload.id, {
        ...state,
        loading: false,
        loaded: true
      });
    }

    case ModuleActionTypes.moduleSearchModulesSuccess:
      return {
        ...state,
        searchModules: action.payload,
        loading: false
      };

    case ModuleActionTypes.moduleSearchModulesReset:
      return {
        ...state,
        searchModules: null
      };

    case ModuleActionTypes.moduleError:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload
      };

    default:
      return state;
  }
}

export const moduleEntitySelectors = adapter.getSelectors();
