import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { mergeMap, map, catchError, tap, debounceTime, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import {
  CurrencyActionTypes,
  GetCurrenciesSuccess,
  CurrencyError,
  AddCurrency,
  AddCurrencySuccess,
  DeleteCurrencySuccess,
  DeleteCurrency,
  GetCurrencyById,
  GetCurrencyByIdSuccess,
  UpdateCurrencySuccess,
  SearchCurrencies,
  SearchCurrenciesSuccess, GetCurrenciesPaged, GetCurrenciesPagedSuccess
} from '@appStore/actions/core-masterdata/currency/currency.actions';
import { CurrencyService } from '@appServices/core-masterdata/currency/currency.service';

import * as fromRouterActions from '@appStore/actions/router.actions';

@Injectable()
export class CurrencyEffects {
  constructor(private actions$: Actions, private currencyService: CurrencyService) {}

  
  loadCurrencies$ = createEffect(() => this.actions$.pipe(
    ofType(CurrencyActionTypes.currencyGetCurrencies),
    mergeMap(() =>
      this.currencyService
        .getCurrencies()
        .pipe(
          map(currencies => new GetCurrenciesSuccess(currencies)),
          catchError(error => of(new CurrencyError(error)))
        )
    )
  ));

  
  loadCurrenciesPaged$ = createEffect(() => this.actions$.pipe(
    ofType(CurrencyActionTypes.currencyGetCurrenciesPaged),
    mergeMap((action: GetCurrenciesPaged) =>
      this.currencyService
        .getCurrenciesPaged(action.payload)
        .pipe(
          map(currencies => new GetCurrenciesPagedSuccess(currencies)),
          catchError(error => of(new CurrencyError(error)))
        )
    )
  ));


  
  getCurrencyById$ = createEffect(() => this.actions$.pipe(
    ofType(CurrencyActionTypes.currencyGetCurrencyById),
    mergeMap((action: GetCurrencyById) =>
      this.currencyService
        .getCurrency(action.payload)
        .pipe(
          map(currency => new GetCurrencyByIdSuccess(currency)),
          catchError(error => of(new CurrencyError(error)))
        )
    )
  ));

  
  addCurrency$ = createEffect(() => this.actions$.pipe(
    ofType(CurrencyActionTypes.currencyAddCurrency),
    switchMap((action: AddCurrency) =>
      this.currencyService
        .addCurrency(action.payload)
        .pipe(
          map(currency => new AddCurrencySuccess(currency)),
          catchError(error => of(new CurrencyError(error)))
        )
    )
  ));

  
  updateCurrency$ = createEffect(() => this.actions$.pipe(
    ofType(CurrencyActionTypes.currencyUpdateCurrency),
    mergeMap((action: AddCurrency) =>
      this.currencyService
        .updateCurrency(action.payload)
        .pipe(
          map(currency => new UpdateCurrencySuccess(action.payload)),
          catchError(error => of(new CurrencyError(error)))
        )
    )
  ));

  
  deleteCurrency$ = createEffect(() => this.actions$.pipe(
    ofType(CurrencyActionTypes.currencyDeleteCurrency),
    mergeMap((action: DeleteCurrency) =>
      this.currencyService
        .deleteCurrency(action.payload)
        .pipe(
          map(() => new DeleteCurrencySuccess(action.payload)),
          catchError(error => of(new CurrencyError(error)))
        )
    )
  ));

  
  searchCurrencies$ = createEffect(() => this.actions$.pipe(
    ofType(CurrencyActionTypes.currencySearchCurrencies),
    mergeMap((action: SearchCurrencies) =>
      this.currencyService
        .searchCurrencies(action.payload)
        .pipe(
          map(currencies => new SearchCurrenciesSuccess(currencies)),
          catchError(error => of(new CurrencyError(error)))
        )
    )
  ));

  
  updateCurrencySuccess$ = createEffect(() => this.actions$.pipe(
    ofType(CurrencyActionTypes.currencyUpdateCurrencySuccess),
    map(currency => new fromRouterActions.Go({ path: ['/setup/currencies'] }))
  ));
}
