/**
 *
 *
 * @export
 * @interface Page
 */
export class Page {
  first: Boolean = false;
  last: Boolean = false;
  number: Number = 0;
  numberOfElements: Number = 0;
  size = 0;
  sort: any = null;
  totalElements: Number = 0;
  totalPages: Number = 0;
  content?:any;
}
