import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getCurrencyStore = createFeatureSelector('currency');

export const getCurrencyEntities = createSelector(
  getCurrencyStore,
  fromReducers.currency.currencyEntitySelectors.selectAll
);

export const getCurrencies = createSelector(getCurrencyEntities, entities => {
  return Object.values(entities);
});

export const getTopCurrencies = createSelector(getCurrencyEntities, entities => {
  return Object.values(entities).slice(1, 5);
});

export const getCurrenciesLoaded = createSelector(
  getCurrencyStore,
  (currencyStore: fromReducers.currency.State) => currencyStore.loaded
);

export const getCurrenciesLoading = createSelector(
  getCurrencyStore,
  (currencyStore: fromReducers.currency.State) => currencyStore.loading
);

export const getSelectedCurrencyId = createSelector(
  getCurrencyStore,
  (currencyStore: fromReducers.currency.State) => currencyStore.selectedCurrencyId
);

export const getSearchCurrencies = createSelector(
  getCurrencyStore,
  (currencyStore: fromReducers.currency.State) => currencyStore.searchCurrencies
);

export const getCurrencyById = createSelector(
  getCurrencyEntities,
  getSelectedCurrencyId,
  (entities, id) => entities.find(i => i.id === id)
);

export const getCurrenciesError = createSelector(
  getCurrencyStore,
  (currencyStore: fromReducers.currency.State) => currencyStore.error
);

export const getCurrenciesPaging = createSelector(
  getCurrencyStore,
  (currencyStore: fromReducers.currency.State) => currencyStore.paging
);
