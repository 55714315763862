import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {RepairCentreType} from '@appModels/core-aftersales/repaircentretype';
import {
  RepairCentreTypesActions,
  RepairCentreTypesActionTypes
} from '../../../actions/core-aftersales/repaircentres/repaircentretype.actions';

export interface State extends EntityState<RepairCentreType> {
  error: any;
  loaded: boolean;
  loading: boolean;
}

export const adapter: EntityAdapter<RepairCentreType> = createEntityAdapter<RepairCentreType>();

export const initialState: State = adapter.getInitialState({
  error: null,
  loaded: false,
  loading: false
});

export function reducer(state = initialState, action: RepairCentreTypesActions): State {
  switch (action.type) {
    case RepairCentreTypesActionTypes.repairCentreTypeGetAll:
    case RepairCentreTypesActionTypes.repairCentreTypeSave:
      return {
        ...state,
        loading: true,
        loaded: false
      };

    case RepairCentreTypesActionTypes.repairCentreTypeError:
      return adapter.addOne(action.payload, {
        ...state,
        error: action.payload.id,
        loading: false,
        loaded: true
      });

    case RepairCentreTypesActionTypes.repairCentreTypeGetAllSuccess:
    case RepairCentreTypesActionTypes.repairCentreTypeSaveSuccess:
      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });
    default:
      return state;
  }
}

export const repairCentreTypeEntitySelectors = adapter.getSelectors();

