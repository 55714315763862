import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';

import {Commission} from '@appModels/core-fieldforce/commission/commission';
import {CoreIncentivemanagementService} from "../core-incentivemanagement.service";


@Injectable({providedIn: 'root'})
export class CommissionService extends CoreIncentivemanagementService {


  constructor(
    private http: HttpClient,
  ) {
    super(http, 'commission');
  }


  getCommissionById(id: number): Observable<Commission> {
    const url = `${this.base_url}/${id}`;
    return this.http.get<Commission>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched commissionDefinition id=${id}`)),
      catchError(this.handleError<Commission>(`getcommissionDefinition id=${id}`))
    );
  }


  getCommissiontNo404<Data>(id: number): Observable<Commission> {
    const url = `${this.base_url}/?id=${id}`;
    return this.http.get<Commission[]>(url).pipe(
      map(commission => commission[0]), // returns a {0|1} element array
      tap(h => {
        const outcome = h ? `fetched` : `did not find`;
        this.log(`${outcome} commission id=${id}`);
      }),
      catchError(this.handleError<Commission>(`getStockingPoint id=${id}`))
    );
  }

  /** GET sales staff definitions by id. Will 404 if id not found */
  getcommission(id: number): Observable<Commission> {
    const url = `${this.base_url}/${id}`;

    return this.http.get<Commission>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched commission id=${id}`)),
      catchError(this.handleError<Commission>(`getcommission id=${id}`))
    );
  }

  getCommissionType(): Observable<any> {
    const url = `${this.base_url}Types`;

    return this.http.get<any>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched commission types`)),
      catchError(this.handleError<any>(`getCommissionType`))
    );
  }

  /* GET sales staff definitions whose name contains search term */
  searchcommission(term: string): Observable<Commission[]> {
    if (!term.trim()) {
      // if not search term, return empty stockingPoint array.
      return of([]);
    }
    return this.http.get<Commission[]>(`${this.base_url}/searchcommission/?searchString=${term}`).pipe(
      tap(_ => this.log(`found commission matching "${term}"`)),
      catchError(this.handleError<Commission[]>('commission', []))
    );
  }

  /* GET sales staff definitions whose name contains search term */
  searchcommissionByType(id: number, type: string): Observable<Commission[]> {
    if (!type.trim()) {
      // if not search term, return empty stockingPoint array.
      return of([]);
    }
    return this.http.get<Commission[]>(this.base_url + '/commissions?tenantId=' + id + '&type=' + type, {headers: this.headers}).pipe(
      tap(commission => this.log(`fetched sales Staff Definitions`)),
      catchError(this.handleError('getPossibleLineManagers', []))
    );

    /*return this.http.get<commission[]>(`corefieldforceservice/api/v1/commissions?type=${type}`).pipe(
      tap(_ => this.log(`found commission matching "${type}"`)),
      catchError(this.handleError<commission[]>('commission', []))
    );*/
  }

  //////// Save methods //////////

  /** POST: add a new sales staff definition to the server */
  addcommission(commission: Commission): Observable<Commission> {
    return this.http.post<Commission>(this.base_url, commission, {headers: this.headers}).pipe(
      tap((commission: Commission) => this.log(`added salesStaf w/ id=${commission.id}`)),
      catchError(this.handleError<Commission>('addcommissionDefinition'))
    );
  }

  /** POST: add a new sales staff definition to the server */
  addcommissionAttributes(commission): Observable<{}> {
    return this.http.post(`${this.base_url}/additionalInfo/${commission.id}`, commission.value, {headers: this.headers}).pipe(
      tap((res) => this.log(`added commission  info `)),
      catchError(this.handleError('addcommission'))
    );
  }

  payIncentive(incentiveId): Observable<any> {
    return this.http.post<any>(`${this.base_url}/payout/${incentiveId}`, {}, {headers: this.headers}).pipe(
      tap((_) => this.log(`pay incentive`)),
      catchError(this.handleError<any>('payIncentive'))
    );
  }

  /** DELETE: delete the sales staff Definition from the server */
  deletecommission(commission: Commission | number): Observable<Commission> {
    const id = typeof commission === 'number' ? commission : commission.id;
    const url = `${this.base_url}/commission/${id}`;

    return this.http.delete<Commission>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`deleted commission id=${id}`)),
      catchError(this.handleError<Commission>('deletecommission'))
    );
  }

  /** PUT: update the stockingPoint on the server */
  updatecommission(commission: Commission): Observable<any> {
    return this.http.put(`${this.base_url}/${commission.id}`, commission, {headers: this.headers}).pipe(
      tap(_ => this.log(`updated stockingPoint id=${commission.id}`)),
      catchError(this.handleError<any>('updatecommission'))
    );
  }



}
