import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getEscalationStore = createFeatureSelector('escalation');

export const getEscalationEntities = createSelector(
  getEscalationStore,
  fromReducers.escalation.escalationEntitySelectors.selectAll
);

export const getEscalations = createSelector(getEscalationEntities, entities => {
  return Object.values(entities);
});

export const getTopEscalations = createSelector(getEscalationEntities, entities => {
  return Object.values(entities).slice(1, 5);
});

export const getEscalationsPaging = createSelector(
  getEscalationStore,
  (escalationStore: fromReducers.escalation.State) => escalationStore.paging
);

export const getEscalationsLoaded = createSelector(
  getEscalationStore,
  (escalationStore: fromReducers.escalation.State) => escalationStore.loaded
);

export const getEscalationsLoading = createSelector(
  getEscalationStore,
  (escalationStore: fromReducers.escalation.State) => escalationStore.loading
);

export const getSelectedEscalationId = createSelector(
  getEscalationStore,
  (escalationStore: fromReducers.escalation.State) => escalationStore.selectedEscalationId
);

export const getSearchEscalations = createSelector(
  getEscalationStore,
  (escalationStore: fromReducers.escalation.State) => escalationStore.searchEscalations
);

export const getEscalationById = createSelector(
  getEscalationEntities,
  getSelectedEscalationId,
  (entities, id) => entities.find(i => i.id === id)
);

export const getEscalationsError = createSelector(
  getEscalationStore,
  (escalationStore: fromReducers.escalation.State) => escalationStore.error
);
