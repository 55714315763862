import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "kyc-top-alerts",
  template: `<div
      *ngIf="
      componentMode === kycComponentModeEnum.EDIT &&
        customerIsASalesAgentWithId !== undefined &&
        customerIsASalesAgentWithId !== null
      "
      class="notification error m-t-20"
    >
      {{
        "Alert: this sale is being made to a client who is also registered as a Sales Agent"
          | translate
      }}
    </div>
    <div
      *ngIf="showMessage || assignedUser !== null"
      class="notification error m-t-20"
    >
      {{ "Contract already assigned or processed to: " | translate }}
      <b
        >{{ assignedUser?.firstName }} {{ assignedUser?.lastName }} -
        {{ assignedUser?.email }}</b
      >
      <br />
      {{ "Please proceed to the next customer!" | translate }}
    </div>`,
    encapsulation: ViewEncapsulation.None
})
export class KycTopAlertsComponent implements OnInit {
  @Input() customerIsASalesAgentWithId: any;
  @Input() showMessage: any;
  @Input() assignedUser: any;
  @Input() kycComponentModeEnum: any;
  @Input() componentMode: any;
  constructor() {}

  ngOnInit() {}
}
