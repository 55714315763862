import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {of} from "rxjs";
import {RepairCentreTypeService} from '../../../../services/core-aftersales/repaircentre/repaircentretype.service';
import {
  GetAllRepairCentreTypes,
  GetAllRepairCentreTypeSuccess,
  RepairCentreTypeError,
  RepairCentreTypesActionTypes, SaveRepairCentreTypes, SaveRepairCentreTypesSuccess
} from '../../../actions/core-aftersales/repaircentres/repaircentretype.actions';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';

@Injectable()
export class RepairCentreTypeEffects {

  
  repairCentreTypes = createEffect(() => this.actions$.pipe(
    ofType(RepairCentreTypesActionTypes.repairCentreTypeGetAll),
    mergeMap((action: GetAllRepairCentreTypes) =>
      this.repairCentreTypeService
        .getRepairCentreTypes()
        .pipe(
          map(repairCentres => new GetAllRepairCentreTypeSuccess(repairCentres)),
          catchError(error => of(new RepairCentreTypeError(error)))
        )
    )));

  
  repairCentreTypesNew = createEffect(() => this.actions$.pipe(
    ofType(RepairCentreTypesActionTypes.repairCentreTypeSave),
    mergeMap((action: SaveRepairCentreTypes) =>
      this.repairCentreTypeService
        .saveRepairCentreTypes(action.payload)
        .pipe(
          map(repairCentres => {
            if (repairCentres) {
              this.toastr.success('Repair Centre Types Configured Successfully!', 'Successful!');
              this.router.navigateByUrl('/setup/priceconfiguration/types');
              return new SaveRepairCentreTypesSuccess(repairCentres);
            }
          }),
          catchError(error => of(new RepairCentreTypeError(error)))
        )
    )));

  constructor(
    private actions$: Actions,
    private toastr: ToastrService,
    private router: Router,
    private repairCentreTypeService: RepairCentreTypeService,
  ) {
  }
}
