import {Injectable} from '@angular/core';
import {CoreAftersalesService} from '@appServices/core-aftersales/core-aftersales.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {RepairCentre} from '@appModels/core-aftersales/repaircentre';
import {WorkOrder} from '../../../models/core-aftersales/work-order';
import {PayjoyResponse} from '@appModels/core-crm/payjoy/payjoy-response';
import {WarrantyExtension} from '@appModels/core-aftersales/warranty-extension';

@Injectable({
  providedIn: 'root'
})
export class WorkOrderService extends CoreAftersalesService {

  constructor(private http: HttpClient) {
    super(http, 'workorder');
  }

  /** POST: save work order to the server */
  saveWorkOrder(workOrder, ticket, locationImages): Observable<any> {
    const formData = new FormData();

    for (let i = 0; i < locationImages.length; i++) {
      formData.append('files', locationImages[i], locationImages[i]['name']);
    }
    formData.append('workOrder', new Blob([JSON.stringify(workOrder)], {
      type: 'application/json'
    }));
    formData.append('ticket', new Blob([JSON.stringify(ticket)], {
      type: 'application/json'
    }));

    this.setHeader(true);

    return this.http.post(this.base_url , formData, {headers: this.headers}).pipe(
      tap((wo: WorkOrder) => this.log(`added work order w/ id=${wo.id}`)),
      catchError(this.handleError<RepairCentre>('saveWorkOrder'))
    );
  }

  /** POST: receive items */
  receiveItems(workOrder, ticket): Observable<any> {
    const formData = new FormData();

    formData.append('items', new Blob([JSON.stringify(workOrder)], {
      type: 'application/json'
    }));
    formData.append('ticket', new Blob([JSON.stringify(ticket)], {
      type: 'application/json'
    }));

    this.setHeader(true);

    return this.http.post(this.base_url + '/reception', formData, {headers: this.headers}).pipe(
      tap(_ => this.log(`received items`)),
      catchError(this.handleError<any>('receiveItems'))
    );
  }

  getWorkOrder(id: number): Observable<any> {
    const url = `${this.base_url}/${id}`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched wo id=${id}`)),
      catchError(this.handleError(`getWorkOrder id=${id}`))
    );
  }

  getWorkOrdersForAccount(accountNumber: string): Observable<any> {
    const url = `${this.base_url}/accountNumber/${accountNumber}`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched wo for accountNumber=${accountNumber}`)),
      catchError(this.handleError(`getWorkOrdersForAccount accountNumber=${accountNumber}`))
    );
  }

  fetchWorkOrders(accountNumber: string, contractProductId: Number): Observable<any> {
    const url = `${this.base_url}s/search-using-or-condition?accountNumber=${accountNumber}&contractProductId=${contractProductId}`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched wos`)),
      catchError(this.handleError(`fetchWorkOrders`))
    );
  }

  getContractProductFromAccount(id: number): Observable<any> {
    const url = `${this.base_url}/${id}/contractproduct`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched contract product for wo=${id}`)),
      catchError(this.handleError(`getContractProductFromAccount id=${id}`))
    );
  }

  getWorkOrdersWithFaultyModules(moduleId: number, repairCentreId: number): Observable<any> {
    const url = `${this.base_url}/withfaultymodules/${moduleId}/repaircentre/${repairCentreId}`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched wo with faulty modules for repair centre = ${repairCentreId} and module =${moduleId}`)),
      catchError(this.handleError(`getWorkOrdersWithFaultyModules repair centre = ${repairCentreId} and module =${moduleId}`))
    );
  }

  getAmountThatCanBeAddedToContract(id: number): Observable<any> {
    const url = `${this.base_url}/contractamount/${id}`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched wo id=${id}`)),
      catchError(this.handleError(`getAmountThatCanBeAddedToContract id=${id}`))
    );
  }

  getWorkOrderPhoneDetails(workOrderId: number): Observable<any> {
    const url = `${this.base_url}/${workOrderId}/phonedetails`;
    return this.http.get<PayjoyResponse>(url, {headers: this.headers}).pipe(
      tap(res => this.log(`res`)),
      catchError(this.handleError('res', ''))
    );
  }

  getWorkOrderInspection(): Observable<any> {
    const url = `${this.base_url}inspectionstatus`;
    return this.http.get<any>(url, {headers: this.headers}).pipe(
      tap(res => this.log(`res`)),
      catchError(this.handleError('res', ''))
    );
  }

  getPhoneSwOptions(id: number): Observable<any> {
    const url = `${this.base_url}/phoneswversions/${id}`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched phone sw options for product=${id}`)),
      catchError(this.handleError(`getPhoneSwOptions id=${id}`))
    );
  }

  saveAmountThatCanBeAddedToContract(id: number, amount: any): Observable<any> {
    const url = `${this.base_url}/contractamount/${id}`;

    return this.http.patch(url, {amount: +amount}, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched wo id=${id}`)),
      catchError(this.handleError(`saveAmountThatCanBeAddedToContract id=${id}`))
    );
  }

  addTokensToWorkOrder(id: number, tokenRequestIds: number[]): Observable<any> {
    const url = `${this.base_url}/${id}/tokens`;
    return this.http.post(url, tokenRequestIds, {headers: this.headers}).pipe(
      tap(_ => this.log(`saved tokens for wo id=${id}`)),
      catchError(this.handleError(`addTokensToWorkOrder id=${id}`))
    );
  }

  addWaitingForPartsToWorkOrder(id: number, waitingForParts: any[]): Observable<any> {
    const url = `${this.base_url}/${id}/waitingforparts`;
    return this.http.post(url, waitingForParts, {headers: this.headers}).pipe(
      tap(_ => this.log(`saved waitingForParts for wo id=${id}`)),
      catchError(this.handleError(`waitingForParts id=${id}`))
    );
  }

  removeWaitingForPartsToWorkOrder(id: number, waitingForPartsId: number): Observable<any> {
    const url = `${this.base_url}/${id}/waitingforparts/delete/${waitingForPartsId}`;
    return this.http.post(url, {}, {headers: this.headers}).pipe(
      tap(_ => this.log(`saved waitingForParts for wo id=${id}`)),
      catchError(this.handleError(`waitingForParts id=${id}`))
    );
  }

  getWorkOrders(query?): Observable<any> {
    const tenantId = localStorage.getItem('tenant');
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        if (query[val] !== null && query[val] !== undefined && query[val] !== '') {
          params = params.append(val, query[val]);
        }
      });
    }

    params = params.append('tenantId', tenantId);

    return this.http.get(this.base_url + 's',
      {params: params, headers: this.headers}).pipe(
      tap(countries => this.log(`fetched work orders`)),
      catchError(this.handleError('getWorkOrders', []))
    );
  }


  assignToTechnician(id: number, technicianId: number): Observable<any> {
    const url = `${this.base_url}/${id}/technician/${technicianId}`;
    return this.http.patch(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`assigjed wo id=${id}`)),
      catchError(this.handleError(`assignToTechnician id=${id} technicianId=${technicianId}`))
    );
  }

  assignAction(id: number, actionId: number, l2Fault: number, l3Fault: number, l4Fault: number): Observable<any> {
    const url = actionId != null
      ? `${this.base_url}/${id}/action/${actionId}/l2Fault/${l2Fault}/l3Fault/${l3Fault}/l4Fault/${l4Fault}`
      : `${this.base_url}/${id}/action`;

    return this.http.patch(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`assigned action to wo id=${id}`)),
      catchError(this.handleError(`assignAction id=${id} actionId=${actionId}`))
    );
  }

  changeState(id: number, stateId: number): Observable<any> {
    const url = `${this.base_url}/${id}/changeState/${stateId}`;
    return this.http.patch(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`change state of wo id=${id}`)),
      catchError(this.handleError(`changeState id=${id} stateId=${stateId}`))
    );
  }

  cancelWorkOrder(id: number, stateId: number, cancellingComment: string, cancelledBy: string): Observable<any> {
    const body = {
      'cancellingComment': cancellingComment,
      'cancelledBy': cancelledBy
    };

    const url = `${this.base_url}/${id}/cancel`;
    return this.http.patch(url, body, {headers: this.headers}).pipe(
      tap(_ => this.log(`cancel wo id=${id}`)),
      catchError(this.handleError(`cancel wo id=${id} stateId=${stateId}`))
    );
  }

  closeAction(id: number, remainingDays: number, comment: string, returnToRepairCentre = false, collectionStatus : string, agentId : any, serviceProvider : string, deliveryTerms : string): Observable<any> {
    const url = !returnToRepairCentre ? `${this.base_url}/${id}/close` : `${this.base_url}/${id}/return`;
    return this.http.post(url,
      {closingComment: comment, remainingDays: remainingDays,
      collectionStatus : collectionStatus, agentId : agentId, serviceProvider: serviceProvider, deliveryTerms: deliveryTerms},
      {headers: this.headers}).pipe(
      tap(_ => this.log(`closed wo id=${id}`)),
      catchError(this.handleError(`closeAction id=${id}`))
    );
  }

  escalateAction(id: number): Observable<any> {
    const url = `${this.base_url}/${id}/escalate`;
    return this.http.patch(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`escalated wo id=${id}`)),
      catchError(this.handleError(`escalateAction id=${id}`))
    );
  }

  replaceAction(id: number, productId: number): Observable<any> {
    const url = `${this.base_url}/${id}/replace/${productId}`;
    return this.http.patch(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`preformed replace wo id=${id}`)),
      catchError(this.handleError(`replaceAction id=${id}`))
    );
  }

  createEscalation(id: number): Observable<any> {
    const url = `${this.base_url}/create/escalation/${id}`;
    return this.http.patch(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`escalated wo id=${id}`)),
      catchError(this.handleError(`escalateAction id=${id}`))
    );
  }

  /** POST: repair action */
  repairAction(id, items, modules, other): Observable<any> {
    return this.http.post(this.base_url + '/' + id + '/repair', {
      items: items,
      otherRepairs: other,
      modules: modules
    }, {headers: this.headers}).pipe(
      tap((wo: WorkOrder) => this.log(`repaired work order w/ id=${wo.id}`)),
      catchError(this.handleError<RepairCentre>('repairAction'))
    );
  }

  /** POST: create quoatation */
  generateQuoatation(id, items): Observable<any> {
    return this.http.post(this.base_url + '/' + id + '/quotatation', items, {headers: this.headers}).pipe(
      tap((wo: WorkOrder) => this.log(`generated quoatation work order w/ id=${wo.id}`)),
      catchError(this.handleError<RepairCentre>('generateQuoatation'))
    );
  }

  /** POST: create quoatation */
  addOrRemoveWorkOrderRepair(id, workOrderRepairId, add): Observable<any> {
    return this.http.patch(this.base_url + '/' + id + '/repair/' + workOrderRepairId + '?add=' + add, null, {headers: this.headers}).pipe(
      tap((wo: WorkOrder) => this.log(`Add or remove WorkOrderRepair w/ id=${wo.id}`)),
      catchError(this.handleError<RepairCentre>('generateQuoatation'))
    );
  }

  /** POST: invalidate quoatation */
  invalidateQuoatation(id): Observable<any> {
    return this.http.post(this.base_url + '/' + id + '/quotatation/invalidate', {headers: this.headers}).pipe(
      tap((wo: WorkOrder) => this.log(`generated quoatation work order w/ id=${wo.id}`)),
      catchError(this.handleError<RepairCentre>('generateQuoatation'))
    );
  }

  /** POST: add note */
  addNote(id, note): Observable<any> {
    return this.http.post(this.base_url + '/' + id + '/note', note, {headers: this.headers}).pipe(
      tap((wo: WorkOrder) => this.log(`added note to work order w/ id=${wo.id}`)),
      catchError(this.handleError<RepairCentre>('addNote'))
    );
  }

  /** POST: add note */
  saveTestCaseResults(id, results): Observable<any> {
    return this.http.post(this.base_url + '/' + id + '/testcases', results, {headers: this.headers}).pipe(
      tap((wo: WorkOrder) => this.log(`saved test case results to work order w/ id=${wo.id}`)),
      catchError(this.handleError<RepairCentre>('saveTestCaseResults'))
    );
  }

  /** POST: save token request escalation */
  saveTokenRequestEscalation(id, escalationObject): Observable<any> {
    return this.http.post(this.base_url + '/' + id + '/savetokenrequestescalation', escalationObject, {headers: this.headers}).pipe(
      tap((wo: WorkOrder) => this.log(`saved escalations to work order w/ id=${wo.id}`)),
      catchError(this.handleError<RepairCentre>('saveTokenRequestEscalation'))
    );
  }

  /** GET: get token request escalation */
  getTokenRequestEscalation(id): Observable<any> {
    return this.http.get(this.base_url + '/' + id + '/tokenrequestescalation', {headers: this.headers}).pipe(
      tap((wo: WorkOrder) => this.log(`get escalations f0r work order w/ id=${id}`)),
      catchError(this.handleError<RepairCentre>('getTokenRequestEscalation'))
    );
  }

  /** DELETE: remove token request escalation */
  removeTokenRequestEscalation(id): Observable<any> {
    return this.http.delete(this.base_url + '/' + id + '/removetokenrequestescalation', {headers: this.headers}).pipe(
      tap((wo: WorkOrder) => this.log(`removed escalations fpr work order w/ id=${wo.id}`)),
      catchError(this.handleError<RepairCentre>('removeTokenRequestEscalation'))
    );
  }

  /** POST: save token request escalation */
  saveWarrantyRequestEscalation(id: number, escalationObject: WarrantyExtension, files?): Observable<any> {
    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i], files[i]['name']);
    }
    formData.append('escalation', new Blob([JSON.stringify(escalationObject)], {
      type: 'application/json'
    }));

    this.setHeader(true);

    return this.http.post(this.base_url + '/' + id + '/saveWarrantyRequestEscalation', formData, {headers: this.headers}).pipe(
      tap((response) => this.log(`saved escalations to work order w/ id=${id}`)),
      // catchError(this.handleError<any>('saveWarrantyRequestEscalation'))
    );
  }

  isWorkOrderForSamsungOrNokiaOrItel(id): Observable<any> {
    return this.http.get(this.base_url + '/' + id + '/isfornokiaorsamsungoritel', {headers: this.headers}).pipe(
      tap((wo: WorkOrder) => this.log(`isWorkOrderForSamsungOrNokiaOrItel/ id=${wo.id}`)),
      catchError(this.handleError<any>('isWorkOrderForSamsungOrNokiaOrItel'))
    );
  }

  reassignRepairCentre(workOrderId: number, newRepairCentreId: number): Observable<any> {
    return this.http.patch(this.base_url + '/' + workOrderId + '/reassignrepaircentre/' + newRepairCentreId, {headers: this.headers}).pipe(
      tap((wo: WorkOrder) => this.log(`reassignRepairCentre/ id=${wo.id}`)),
      catchError(this.handleError<any>('reassignRepairCentre'))
    );
  }

  canRequestWarrantyExtension(id: number): Observable<any> {
    const url = `${this.base_url}/${id}/warrantyextension/check`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched wo id=${id}`)),
      catchError(this.handleError(`getAmountThatCanBeAddedToContract id=${id}`))
    );
  }

  editPcbaSerial(id: number, oldSerial: string, newSerial: string): Observable<any> {
    const url = `${this.base_url}/${id}/changepcbaserial?oldSerial=${oldSerial}&newSerial=${newSerial}`;
    return this.http.patch(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`changed pcba`)),
      catchError(this.handleError(`editPcbaSerial`))
    );
  }
}
