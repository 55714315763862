import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getProductCatalogueStore = createFeatureSelector('productCatalogue');

export const getProductCatalogueEntities = createSelector(
  getProductCatalogueStore,
  fromReducers.productCatalogue.productCatalogueEntitySelectors.selectAll
);

export const getProductCatalogues = createSelector(getProductCatalogueEntities, entities => {
  return Object.values(entities);
});

export const getTenantProductCatalogues = createSelector(
  getProductCatalogueStore,
  (productCatalogueStore: fromReducers.productCatalogue.State) => productCatalogueStore.tenantProductCatalogues
);

export const getTopProductCatalogues = createSelector(getProductCatalogueEntities, entities => {
  return Object.values(entities).slice(1, 5);
});

export const getProductCataloguesLoaded = createSelector(
  getProductCatalogueStore,
  (productCatalogueStore: fromReducers.productCatalogue.State) => productCatalogueStore.loaded
);

export const getProductCataloguesLoading = createSelector(
  getProductCatalogueStore,
  (productCatalogueStore: fromReducers.productCatalogue.State) => productCatalogueStore.loading
);

export const getSelectedProductCatalogueId = createSelector(
  getProductCatalogueStore,
  (productCatalogueStore: fromReducers.productCatalogue.State) => productCatalogueStore.selectedProductCatalogueId
);

export const getSearchProductCatalogues = createSelector(
  getProductCatalogueStore,
  (productCatalogueStore: fromReducers.productCatalogue.State) => productCatalogueStore.searchProductCatalogues
);

export const getProductCatalogueById = createSelector(
  getProductCatalogueStore,
  (productCatalogueStore: fromReducers.productCatalogue.State) => productCatalogueStore.selectedProductsCatalogue
);

export const getProductCataloguesError = createSelector(
  getProductCatalogueStore,
  (productCatalogueStore: fromReducers.productCatalogue.State) => productCatalogueStore.error
);
