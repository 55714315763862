import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { mergeMap, map, catchError, tap, debounceTime, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import {
  SmsTemplateActionTypes,
  GetSmsTemplatesSuccess,
  SmsTemplateError,
  AddSmsTemplate,
  AddSmsTemplateSuccess,
  DeleteSmsTemplateSuccess,
  DeleteSmsTemplate,
  GetSmsTemplateById,
  GetSmsTemplateByIdSuccess,
  UpdateSmsTemplateSuccess,
  SearchSmsTemplates,
  SearchSmsTemplatesSuccess,
  GetSmsTemplates
} from '@appStore/actions/core-masterdata/sms-template/sms-template.actions';
import { SmsTemplateService } from '@appServices/core-masterdata/sms-template/sms-template.service';

import * as fromRouterActions from '@appStore/actions/router.actions';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class SmsTemplateEffects {
  constructor(
    private actions$: Actions, 
    private smsTemplateService: SmsTemplateService,
    private router: Router,
    private toastr: ToastrService
    ) {}

  
  loadSmsTemplates$ = createEffect(() => this.actions$.pipe(
    ofType(SmsTemplateActionTypes.smsTemplateGetSmsTemplates),
    mergeMap((action: GetSmsTemplates) =>
      this.smsTemplateService
        .getSmsTemplates(action.payload)
        .pipe(
          map(smsTemplates => new GetSmsTemplatesSuccess(smsTemplates)),
          catchError(error => of(new SmsTemplateError(error)))
        )
    )
  ));

  
  getSmsTemplateById$ = createEffect(() => this.actions$.pipe(
    ofType(SmsTemplateActionTypes.smsTemplateGetSmsTemplateById),
    mergeMap((action: GetSmsTemplateById) =>
      this.smsTemplateService
        .getSmsTemplate(action.payload)
        .pipe(
          map(smsTemplate => new GetSmsTemplateByIdSuccess(smsTemplate)),
          catchError(error => of(new SmsTemplateError(error)))
        )
    )
  ));

  
  addSmsTemplate$ = createEffect(() => this.actions$.pipe(
    ofType(SmsTemplateActionTypes.smsTemplateAddSmsTemplate),
    switchMap((action: AddSmsTemplate) =>
      this.smsTemplateService
        .addSmsTemplate(action.payload)
        .pipe(
          map(
            smsTemplate => {
              if (smsTemplate !== undefined){
                this.toastr.success('SMS Template Message has been created successfully!', 'Successful!');
                this.router.navigateByUrl(`/setup/sms-templates`);
                return  new GetSmsTemplates(smsTemplate)
              }
              return new SmsTemplateError({ type: 500, message: 'Internal error' })
            }),
          catchError(error => of(new SmsTemplateError(error)))
        )
    )
  ));


  
  updateSmsTemplate$ = createEffect(() => this.actions$.pipe(
    ofType(SmsTemplateActionTypes.smsTemplateUpdateSmsTemplate),
    mergeMap((action: AddSmsTemplate) =>
      this.smsTemplateService
        .updateSmsTemplate(action.payload)
        .pipe(
          // map(smsTemplate => new UpdateSmsTemplateSuccess(action.payload)),
          // catchError(error => of(new SmsTemplateError(error)))
          map(
            smsTemplate => {
              if (smsTemplate !== undefined){
                this.toastr.success('SMS Template Message has been updated successfully!', 'Successful!');
                this.router.navigateByUrl(`/setup/sms-templates`);
                return  new GetSmsTemplates(smsTemplate)
              }
              return new SmsTemplateError({ type: 500, message: 'Internal error' })
            }),
          catchError(error => of(new SmsTemplateError(error)))
        )
    )
  ));

  
  deleteSmsTemplate$ = createEffect(() => this.actions$.pipe(
    ofType(SmsTemplateActionTypes.smsTemplateDeleteSmsTemplate),
    mergeMap((action: DeleteSmsTemplate) =>
      this.smsTemplateService
        .deleteSmsTemplate(action.payload)
        .pipe(
          map(() => new DeleteSmsTemplateSuccess(action.payload)),
          catchError(error => of(new SmsTemplateError(error)))
        )
    )
  ));

  
  searchSmsTemplates$ = createEffect(() => this.actions$.pipe(
    ofType(SmsTemplateActionTypes.smsTemplateSearchSmsTemplates),
    mergeMap((action: SearchSmsTemplates) =>
      this.smsTemplateService
        .searchSmsTemplates(action.payload)
        .pipe(
          map(smsTemplates => new SearchSmsTemplatesSuccess(smsTemplates)),
          catchError(error => of(new SmsTemplateError(error)))
        )
    )
  ));

  
  updateSmsTemplateSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(SmsTemplateActionTypes.smsTemplateUpdateSmsTemplateSuccess),
    map(smsTemplate => new fromRouterActions.Go({ path: ['/setup/sms-templates'] }))
  ));
}
