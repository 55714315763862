import { Action } from '@ngrx/store';

import { RateFinancedDefinition } from '@appModels/core-accounting/rate-financed-definition/rate-financed-definition';

export enum RateFinancedDefinitionActionTypes {
  rateFinancedDefinitionGetRateFinancedDefinitions = '[RateFinancedDefinition] get',
  rateFinancedDefinitionGetRateFinancedDefinitionsSuccess = '[RateFinancedDefinition] get rateFinancedDefinitions success',
  rateFinancedDefinitionAddRateFinancedDefinition = '[RateFinancedDefinition] add RateFinancedDefinition',
  rateFinancedDefinitionAddRateFinancedDefinitionSuccess = '[RateFinancedDefinition] add rateFinancedDefinition success',
  rateFinancedDefinitionAddFinancedRate = '[RateFinancedDefinition] add FinancedRateDefinition',
  rateFinancedDefinitionAddAdditionalRateFinancedDefinitionInfo = '[RateFinancedDefinition] add additional RateFinancedDefinition Info',
  rateFinancedDefinitionAddAdditionalRateFinancedDefinitionInfoSuccess = '[RateFinancedDefinition] add additional rateFinancedDefinition Info success',
  rateFinancedDefinitionDeleteRateFinancedDefinition = '[RateFinancedDefinition] delete rateFinancedDefinition',
  rateFinancedDefinitionDeleteRateFinancedDefinitionSuccess = '[RateFinancedDefinition] delete rateFinancedDefinition success',
  rateFinancedDefinitionGetRateFinancedDefinitionById = '[RateFinancedDefinition] get rateFinancedDefinition by id',
  rateFinancedDefinitionGetRateFinancedDefinitionByIdSuccess = '[RateFinancedDefinition] get rateFinancedDefinition by id success',
  rateFinancedDefinitionUpdateRateFinancedDefinition = '[RateFinancedDefinition] update rateFinancedDefinition',
  rateFinancedDefinitionUpdateRateFinancedDefinitionSuccess = '[RateFinancedDefinition] update rateFinancedDefinition success',
  rateFinancedDefinitionEditRateFinancedDefinition = '[RateFinancedDefinition] edit rateFinancedDefinition',
  rateFinancedDefinitionEditRateFinancedDefinitionSuccess = '[RateFinancedDefinition] edit rateFinancedDefinition success',
  rateFinancedDefinitionSearchRateFinancedDefinitions = '[RateFinancedDefinition] search rateFinancedDefinitions',
  rateFinancedDefinitionSearchRateFinancedDefinitionsSuccess = '[RateFinancedDefinition] search rateFinancedDefinitions success',
  rateFinancedDefinitionSearchRateFinancedDefinitionsReset = '[RateFinancedDefinition] search rateFinancedDefinitions reset',
  rateFinancedDefinitionError = '[RateFinancedDefinition] error'
}

export class GetRateFinancedDefinitions implements Action {
  readonly type = RateFinancedDefinitionActionTypes.rateFinancedDefinitionGetRateFinancedDefinitions;
}

export class GetRateFinancedDefinitionsSuccess implements Action {
  readonly type = RateFinancedDefinitionActionTypes.rateFinancedDefinitionGetRateFinancedDefinitionsSuccess;
  constructor(public payload: RateFinancedDefinition[]) {}
}

export class AddRateFinancedDefinition implements Action {
  readonly type = RateFinancedDefinitionActionTypes.rateFinancedDefinitionAddRateFinancedDefinition;
  constructor(public payload: RateFinancedDefinition) {}
}

export class AddRateFinancedDefinitionSuccess implements Action {
  readonly type = RateFinancedDefinitionActionTypes.rateFinancedDefinitionAddRateFinancedDefinitionSuccess;
  constructor(public payload: RateFinancedDefinition) {}
}

export class AddFinancedRate implements Action {
  readonly type = RateFinancedDefinitionActionTypes.rateFinancedDefinitionAddFinancedRate;
  constructor(public payload) {}
}

export class AddAdditionalRateFinancedDefinitionInfo implements Action {
  readonly type = RateFinancedDefinitionActionTypes.rateFinancedDefinitionAddAdditionalRateFinancedDefinitionInfo;
  constructor(public payload) {}
}

export class AddAdditionalRateFinancedDefinitionInfoSuccess implements Action {
  readonly type = RateFinancedDefinitionActionTypes.rateFinancedDefinitionAddAdditionalRateFinancedDefinitionInfoSuccess;
  constructor(public payload) {}
}

export class GetRateFinancedDefinitionById implements Action {
  readonly type = RateFinancedDefinitionActionTypes.rateFinancedDefinitionGetRateFinancedDefinitionById;
  constructor(public payload: number) {}
}

export class GetRateFinancedDefinitionByIdSuccess implements Action {
  readonly type = RateFinancedDefinitionActionTypes.rateFinancedDefinitionGetRateFinancedDefinitionByIdSuccess;
  constructor(public payload: RateFinancedDefinition) {}
}

export class UpdateRateFinancedDefinition implements Action {
  readonly type = RateFinancedDefinitionActionTypes.rateFinancedDefinitionUpdateRateFinancedDefinition;
  constructor(public payload: RateFinancedDefinition) {}
}

export class UpdateRateFinancedDefinitionSuccess implements Action {
  readonly type = RateFinancedDefinitionActionTypes.rateFinancedDefinitionUpdateRateFinancedDefinitionSuccess;
  constructor(public payload: RateFinancedDefinition) {}
}

export class EditRateFinancedDefinition implements Action {
  readonly type = RateFinancedDefinitionActionTypes.rateFinancedDefinitionEditRateFinancedDefinition;
  constructor(public payload) {}
}

export class EditRateFinancedDefinitionSuccess implements Action {
  readonly type = RateFinancedDefinitionActionTypes.rateFinancedDefinitionEditRateFinancedDefinitionSuccess;
  constructor(public payload) {}
}

export class DeleteRateFinancedDefinition implements Action {
  readonly type = RateFinancedDefinitionActionTypes.rateFinancedDefinitionDeleteRateFinancedDefinition;
  constructor(public payload: RateFinancedDefinition) {}
}

export class DeleteRateFinancedDefinitionSuccess implements Action {
  readonly type = RateFinancedDefinitionActionTypes.rateFinancedDefinitionDeleteRateFinancedDefinitionSuccess;
  constructor(public payload: RateFinancedDefinition) {}
}

export class SearchRateFinancedDefinitions implements Action {
  readonly type = RateFinancedDefinitionActionTypes.rateFinancedDefinitionSearchRateFinancedDefinitions;
  constructor(public payload: string) {}
}

export class SearchRateFinancedDefinitionsSuccess implements Action {
  readonly type = RateFinancedDefinitionActionTypes.rateFinancedDefinitionSearchRateFinancedDefinitionsSuccess;
  constructor(public payload: RateFinancedDefinition[]) {}
}

export class SearchRateFinancedDefinitionsReset implements Action {
  readonly type = RateFinancedDefinitionActionTypes.rateFinancedDefinitionSearchRateFinancedDefinitionsReset;
}

export class RateFinancedDefinitionError implements Action {
  readonly type = RateFinancedDefinitionActionTypes.rateFinancedDefinitionError;
  constructor(public payload: any) {}
}

export type RateFinancedDefinitionActions =
  | GetRateFinancedDefinitions
  | GetRateFinancedDefinitionsSuccess
  | AddRateFinancedDefinition
  | AddRateFinancedDefinitionSuccess
  | AddFinancedRate
  | AddAdditionalRateFinancedDefinitionInfo
  | AddAdditionalRateFinancedDefinitionInfoSuccess
  | GetRateFinancedDefinitionById
  | GetRateFinancedDefinitionByIdSuccess
  | UpdateRateFinancedDefinition
  | UpdateRateFinancedDefinitionSuccess
  | EditRateFinancedDefinition
  | EditRateFinancedDefinitionSuccess
  | DeleteRateFinancedDefinition
  | DeleteRateFinancedDefinitionSuccess
  | SearchRateFinancedDefinitions
  | SearchRateFinancedDefinitionsSuccess
  | SearchRateFinancedDefinitionsReset
  | RateFinancedDefinitionError;
