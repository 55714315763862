import {Injectable} from '@angular/core';
import {CoreAftersalesService} from '@appServices/core-aftersales/core-aftersales.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from "rxjs";
import {RepairCentreType} from '@appModels/core-aftersales/repaircentretype';
import {catchError, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RepairCentreTypeService extends CoreAftersalesService {

  constructor(private http: HttpClient) {
    super(http, 'repaircentretype');
  }

  /** GET repair centre types from the server */
  getRepairCentreTypes(): Observable<RepairCentreType[]> {
    const tenantId = localStorage.getItem('tenant');
    return this.http.get<RepairCentreType[]>(this.base_url + '?tenantId=' + tenantId, {headers: this.headers}).pipe(
      tap(types => this.log(`fetched repair centre types`)),
      catchError(this.handleError('getRepairCentreTypes', []))
    );
  }

  /** POST repair centre types */
  saveRepairCentreTypes(types: RepairCentreType[]): Observable<RepairCentreType[]> {
    const tenantId = localStorage.getItem('tenant');
    return this.http.post<RepairCentreType[]>(this.base_url + '?tenantId=' + tenantId, types, {headers: this.headers}).pipe(
      tap(newTypes => this.log(`saved repair centre types`)),
      catchError(this.handleError('saveRepairCentreTypes', []))
    );
  }
}

