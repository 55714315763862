import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {FaultWarrantyDisposition} from '../../../../models/core-setup/fault-action-setup/fault-warranty-disposition';
import {
  FaultWarrantyDispositionActions,
  FaultWarrantyDispositionActionTypes
} from '../../../actions/core-masterdata/fault-action-setup/fault-warranty-disposition.actions';

export interface State extends EntityState<FaultWarrantyDisposition> {
  error: any;
  loaded: boolean;
  loading: boolean;
}

export const adapter: EntityAdapter<FaultWarrantyDisposition> = createEntityAdapter<FaultWarrantyDisposition>();

export const initialState: State = adapter.getInitialState({
  error: null,
  loaded: false,
  loading: false
});

export function reducer(state = initialState, action: FaultWarrantyDispositionActions): State {
  switch (action.type) {
    case FaultWarrantyDispositionActionTypes.faultWarrantyDispositionGetAll:
      return {
        ...state,
        loading: true,
        loaded: false
      };

    case FaultWarrantyDispositionActionTypes.faultWarrantyDispositionError:
      return adapter.addOne(action.payload, {
        ...state,
        error: action.payload.id,
        loading: false,
        loaded: true
      });

    case FaultWarrantyDispositionActionTypes.faultWarrantyDispositionGetAllSuccess:
      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });
    default:
      return state;
  }
}

export const faultWarrantyDispositionEntitySelectors = adapter.getSelectors();

