import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';

import {ProductWarranty} from '@appModels/core-setup/product-warranty/product-warranty';
import {User} from '@appModels/core-identity/user/user';

import {CoreMasterDataService} from '../core-masterdata.service';


@Injectable({providedIn: 'root'})
export class ProductWarrantyService extends CoreMasterDataService {


  constructor(
    private http: HttpClient,
  ) {
    super(http, 'globalproductwarrantytypes' +
      '');
  }

  /** GET product warranties from the server */
  getProductWarranties(): Observable<ProductWarranty[]> {
    return this.http.get<ProductWarranty[]>(this.base_url , {headers: this.headers}).pipe(
      tap(products => this.log(`fetched global product product warranties`)),
      catchError(this.handleError('getProductWarranties', []))
    );
  }

  /** GET product warranty by id. Return `undefined` when id not found */
  getProductWarrantyNo404<Data>(id: number): Observable<ProductWarranty> {
    const url = `${this.base_url}/?id=${id}`;
    return this.http.get<ProductWarranty[]>(url).pipe(
      map(products => products[0]), // returns a {0|1} element array
      tap(h => {
        const outcome = h ? `fetched` : `did not find`;
        this.log(`${outcome} product warranty id=${id}`);
      }),
      catchError(this.handleError<ProductWarranty>(`getProductWarranty id=${id}`))
    );
  }

  /** GET product warranty by id. Will 404 if id not found */
  getProductWarranty(id: number): Observable<ProductWarranty> {
    const url = `${this.base_url}/${id}`;
    return this.http.get<ProductWarranty>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched global product warranty  id=${id}`)),
      catchError(this.handleError<ProductWarranty>(`getProductWarranty id=${id}`))
    );
  }

  //////// Save methods //////////
  /** POST: add a new product warranty to the server */
  addProductWarranty(productWarranty: ProductWarranty): Observable<ProductWarranty> {
    return this.http.post<ProductWarranty>(this.base_url , productWarranty, {headers: this.headers}).pipe(
      tap((pw: ProductWarranty) => this.log(`added product warranty w/ id=${pw.id}`)),
      catchError(this.handleError<ProductWarranty>('addProductWarranty'))
    );
  }

  /** POST: add a new product warranties to the server */
  addProductWarranties(productWarranties: ProductWarranty[]): Observable<ProductWarranty[]> {
    return this.http.post<ProductWarranty[]>(this.base_url ,
      productWarranties, {headers: this.headers}).pipe(
      tap((pw: ProductWarranty[]) => this.log(`added global product warranties`)),
      catchError(this.handleError<any>('addProductWarranties'))
    );
  }

  /** DELETE: delete the product warranty from the server */
  deleteProductWarranty(productWarranty: ProductWarranty | number): Observable<ProductWarranty> {
    const id = typeof productWarranty === 'number' ? productWarranty : productWarranty.id;
    const url = `${this.base_url}/${id}`;

    return this.http.delete<ProductWarranty>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`deleted product warranty id=${id}`)),
      catchError(this.handleError<ProductWarranty>('deleteProductWarranty'))
    );
  }

  /** PUT: update the product warranty on the server */
  updateProductWarranty(productWarranty: ProductWarranty): Observable<any> {
    return this.http.put(`${this.base_url}/${productWarranty.id}`, productWarranty, {headers: this.headers}).pipe(
      tap(_ => this.log(`updated product warranty id=${productWarranty.id}`)),
      catchError(this.handleError<any>('updateProductWarranty'))
    );
  }

  /** Patch: update the product warranty on the server */
  editProductWarranty(data): Observable<any> {
    return this.http.patch(`${this.base_url}/${data.id}/${data.param}/${data.value}`, {}, {headers: this.headers}).pipe(
      tap(_ => this.log(`updated product warranty id=${data.id}`)),
      catchError(this.handleError<any>('updateProductWarranty'))
    );
  }

}
