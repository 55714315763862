import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';

import {Product} from '@appModels/core-setup/product/product';
import {User} from '@appModels/core-identity/user/user';

import {CoreMasterDataService} from '../core-masterdata.service';


@Injectable({providedIn: 'root'})
export class ProductWarrantyTypesService extends CoreMasterDataService {


  constructor(
    private http: HttpClient,
  ) {
    super(http, 'productwarrantytype');
  }

  /** GET product warranty types from the server */
  getProductWarrantyTypes(): Observable<Product[]> {
    return this.http.get<Product[]>(this.base_url + 's', {headers: this.headers}).pipe(
      tap(products => this.log(`fetched product warranty types`)),
      catchError(this.handleError('getProductWarrantyTypes', []))
    );
  }
}
