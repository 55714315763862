import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getCustomRegionStore = createFeatureSelector('customRegion');

export const getCustomRegionEntities = createSelector(
  getCustomRegionStore,
  fromReducers.customRegion.customRegionEntitySelectors.selectAll
);

export const getCustomRegions = createSelector(getCustomRegionEntities, entities => {
  return Object.values(entities);
});

export const getTopCustomRegions = createSelector(getCustomRegionEntities, entities => {
  return Object.values(entities).slice(1, 5);
});

export const getCustomRegionsLoaded = createSelector(
  getCustomRegionStore,
  (customRegionStore: fromReducers.customRegion.State) => customRegionStore.loaded
);

export const getCustomRegionsLoading = createSelector(
  getCustomRegionStore,
  (customRegionStore: fromReducers.customRegion.State) => customRegionStore.loading
);

export const getCustomRegionsPaging = createSelector(
  getCustomRegionStore,
  (customRegionStore: fromReducers.customRegion.State) => customRegionStore.paging
);

export const getSelectedCustomRegionId = createSelector(
  getCustomRegionStore,
  (customRegionStore: fromReducers.customRegion.State) => customRegionStore.selectedCustomRegionId
);

export const getSearchCustomRegions = createSelector(
  getCustomRegionStore,
  (customRegionStore: fromReducers.customRegion.State) => customRegionStore.searchCustomRegions
);

export const getCustomRegionById = createSelector(
  getCustomRegionEntities,
  getSelectedCustomRegionId,
  (entities, id) => entities.find(i => i.id === id)
);

export const getCustomRegionsError = createSelector(
  getCustomRegionStore,
  (customRegionStore: fromReducers.customRegion.State) => customRegionStore.error
);
