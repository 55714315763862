import {Action} from '@ngrx/store';

import {WorkorderTestflow} from "../../../../models/core-aftersales/workorder-testflow/workorder-testflow";

export enum WorkorderTestFlowActionTypes {
  workordertestflowGettestflows = '[WorkorderTestflow] get',
  workordertestflowGettestflowsSuccess = '[WorkorderTestflow] get workordertestflow success',
  workordertestflowGetestflowsByTenantId = '[WorkorderTestflow] get sales workordertestflow  by tenant id',
  workordertestflowGetestflowsByTenantIdSuccess = '[WorkorderTestflow] get workordertestflow  by tenant id success',
  workordertestflowAddtestflow = '[WorkorderTestflow] add workordertestflow',
  workordertestflowAddStestflowSuccess = '[WorkorderTestflow] add workordertestflow success',
  workordertestflowAddtestflowAttributes = '[WorkorderTestflow] add workordertestflow Attributesd',
  workordertestflowAddtestflowAttributesSuccess = '[WorkorderTestflow] add additional workordertestflow Info success',
  workordertestflowDeletetestflow = '[WorkorderTestflow] delete workordertestflow',
  workordertestflowDeletetestflowSuccess = '[WorkorderTestflow] delete workordertestflow success',
  workordertestflowGettestflowById = '[WorkorderTestflow] get workordertestflow by id',
  workordertestflowGettestflowByIdSuccess = '[WorkorderTestflow] get workordertestflow by id success',
  workordertestflowUpdatetestflow = '[WorkorderTestflow] update workordertestflow',
  workordertestflowUpdatetestflowSuccess = '[WorkorderTestflow] update workordertestflow success',
  workordertestflowSearchtestflows = '[WorkorderTestflow] search workordertestflow',
  workordertestflowSearchtestflowsSuccess = '[WorkorderTestflow] search workordertestflow success',
  workordertestflowSearchtestflowsReset = '[WorkorderTestflow] search workordertestflow reset',
  workordertestflowFiltertestflow = '[WorkorderTestflow] filter workordertestflow ',
  workordertestflowFiltertestflowSuccess = '[WorkorderTestflow] filter workordertestflow success',
  workordertestflowGettestflowsByProduct = '[WorkorderTestflow] get by product',
  workordertestflowGettestflowsByProductSuccess = '[WorkorderTestflow] get workordertestflow by product success',
  workordertestflowsError = '[WorkorderTestflow] error'
}

export class Getworkordertestflows implements Action {
  readonly type = WorkorderTestFlowActionTypes.workordertestflowGettestflows;
}

export class GetworkordertestflowsSuccess implements Action {
  readonly type = WorkorderTestFlowActionTypes.workordertestflowGettestflowsSuccess;

  constructor(public payload: WorkorderTestflow[]) {
  }
}

export class Addworkordertestflow implements Action {
  readonly type = WorkorderTestFlowActionTypes.workordertestflowAddtestflow;

  constructor(public payload: WorkorderTestflow) {
  }
}

export class AddworkordertestflowSuccess implements Action {
  readonly type = WorkorderTestFlowActionTypes.workordertestflowAddStestflowSuccess;

  constructor(public payload: WorkorderTestflow) {
  }
}


export class Filterworkordertestflow implements Action {
  readonly type = WorkorderTestFlowActionTypes.workordertestflowFiltertestflow;

  constructor(public payload?) {
  }
}

export class FilterworkordertestflowSuccess implements Action {
  readonly type = WorkorderTestFlowActionTypes.workordertestflowFiltertestflowSuccess;

  constructor(public payload) {
  }
}


export class GetworkordertestflowById implements Action {
  readonly type = WorkorderTestFlowActionTypes.workordertestflowGettestflowById;

  constructor(public payload) {
  }
}

export class GetworkordertestflowByIdSuccess implements Action {
  readonly type = WorkorderTestFlowActionTypes.workordertestflowGettestflowByIdSuccess;

  constructor(public payload) {
  }
}

export class Updateworkordertestflow implements Action {
  readonly type = WorkorderTestFlowActionTypes.workordertestflowUpdatetestflow;

  constructor(public payload: WorkorderTestflow) {
  }
}

export class UpdateworkordertestflowSuccess implements Action {
  readonly type = WorkorderTestFlowActionTypes.workordertestflowUpdatetestflowSuccess;

  constructor(public payload: WorkorderTestflow) {
  }
}

export class Deleteworkordertestflow implements Action {
  readonly type = WorkorderTestFlowActionTypes.workordertestflowDeletetestflow;

  constructor(public payload: WorkorderTestflow) {
  }
}

export class DeleteworkordertestflowSuccess implements Action {
  readonly type = WorkorderTestFlowActionTypes.workordertestflowDeletetestflowSuccess;

  constructor(public payload: WorkorderTestflow) {
  }
}

export class Searchworkordertestflows implements Action {
  readonly type = WorkorderTestFlowActionTypes.workordertestflowSearchtestflows;

  constructor(public payload: string) {
  }
}

export class SearchworkordertestflowsSuccess implements Action {
  readonly type = WorkorderTestFlowActionTypes.workordertestflowSearchtestflowsSuccess;

  constructor(public payload: WorkorderTestflow[]) {
  }
}

export class GetworkordertestflowsByTenantId implements Action {
  readonly type = WorkorderTestFlowActionTypes.workordertestflowGetestflowsByTenantId;

  constructor(public payload: number) {
  }
}

export class GetworkordertestflowsByTenantIdIdSuccess implements Action {
  readonly type = WorkorderTestFlowActionTypes.workordertestflowGetestflowsByTenantIdSuccess;

  constructor(public payload: WorkorderTestflow[]) {
  }
}

export class SearchworkordertestflowsReset implements Action {
  readonly type = WorkorderTestFlowActionTypes.workordertestflowSearchtestflowsReset;
}

export class workordertestflowsError implements Action {
  readonly type = WorkorderTestFlowActionTypes.workordertestflowsError;

  constructor(public payload: any) {
  }
}

export class GetWorkOrderTestFlowByProduct implements Action {
  readonly type = WorkorderTestFlowActionTypes.workordertestflowGettestflowsByProduct;

  constructor(public payload) {
  }
}

export class GetWorkOrderTestFlowByProductSuccess implements Action {
  readonly type = WorkorderTestFlowActionTypes.workordertestflowGettestflowsByProductSuccess;

  constructor(public payload) {
  }
}


export type WorkorderTestFlowActions =
  | Getworkordertestflows
  | GetworkordertestflowsSuccess
  | Addworkordertestflow
  | AddworkordertestflowSuccess
  | GetworkordertestflowById
  | GetworkordertestflowByIdSuccess
  | Updateworkordertestflow
  | UpdateworkordertestflowSuccess
  | Deleteworkordertestflow
  | DeleteworkordertestflowSuccess
  | Searchworkordertestflows
  | SearchworkordertestflowsSuccess
  | SearchworkordertestflowsReset
  | GetworkordertestflowsByTenantId
  | GetworkordertestflowsByTenantIdIdSuccess
  | Filterworkordertestflow
  | FilterworkordertestflowSuccess
  | GetWorkOrderTestFlowByProduct
  | GetWorkOrderTestFlowByProductSuccess
  | workordertestflowsError;
