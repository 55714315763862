import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';

import {User} from '@appModels/core-identity/user/user';

import {CoreMasterDataService} from '../core-masterdata.service';
import {TenantProductWarranty} from '../../../models/core-setup/tenant-product-warranty/tenant-product-warranty';


@Injectable({providedIn: 'root'})
export class TenantProductWarrantyService extends CoreMasterDataService {


  constructor(
    private http: HttpClient,
  ) {
    super(http, 'tenantproductwarrantytypes' +
      '');
  }

  /** GET tenant product warranties from the server */
  getTenantProductWarranties(): Observable<TenantProductWarranty[]> {
    return this.http.get<TenantProductWarranty[]>(this.base_url , {headers: this.headers}).pipe(
      tap(products => this.log(`fetched tenant product warranties`)),
      catchError(this.handleError('getTenantProductWarranties', []))
    );
  }

  /** GET product warranty by id. Return `undefined` when id not found */
  getTenantProductWarrantyNo404<Data>(id: number): Observable<TenantProductWarranty> {
    const url = `${this.base_url}/?id=${id}`;
    return this.http.get<TenantProductWarranty[]>(url).pipe(
      map(products => products[0]), // returns a {0|1} element array
      tap(h => {
        const outcome = h ? `fetched` : `did not find`;
        this.log(`${outcome} tenant product warranty id=${id}`);
      }),
      catchError(this.handleError<TenantProductWarranty>(`getTenantProductWarranty id=${id}`))
    );
  }

  /** GET tenant product warranty by id. Will 404 if id not found */
  getTenantProductWarranty(id: number): Observable<TenantProductWarranty> {
    const url = `${this.base_url}/${id}`;
    return this.http.get<TenantProductWarranty>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched tenant product warranty id=${id}`)),
      catchError(this.handleError<TenantProductWarranty>(`getTenantProductWarranty id=${id}`))
    );
  }

  /* GET tenant product warranties whose name contains search term */
  searchTenantProductWarranties(term: string): Observable<TenantProductWarranty[]> {
    if (!term.trim()) {
      // if not search term, return empty product warranty array.
      return of([]);
    }
    return this.http.get<TenantProductWarranty[]>(`api/tenantProductwarranties/?name=${term}`).pipe(
      tap(_ => this.log(`found tenant product warranties matching "${term}"`)),
      catchError(this.handleError<TenantProductWarranty[]>('searchTenantProductWarranties', []))
    );
  }

  //////// Save methods //////////

  /** POST: add a new tenant product warranty to the server */
  addTenantProductWarranty(tenantProductWarranty: TenantProductWarranty): Observable<TenantProductWarranty> {
    return this.http.post<TenantProductWarranty>(this.base_url , tenantProductWarranty, {headers: this.headers}).pipe(
      tap((pw: TenantProductWarranty) => this.log(`added tenant product warranty w/ id=${pw.id}`)),
      catchError(this.handleError<TenantProductWarranty>('addTenantProductWarranty'))
    );
  }

  /** POST: add a new tenant product warranties to the server */
  addTenantProductWarranties(tenantProductWarranty: TenantProductWarranty[]): Observable<TenantProductWarranty[]> {
    return this.http.post<TenantProductWarranty[]>(this.base_url , tenantProductWarranty, {headers: this.headers}).pipe(
      tap((pw: TenantProductWarranty[]) => this.log(`added tenant product warranty w/`)),
      catchError(this.handleError<any>('addTenantProductWarranties'))
    );
  }

  /** DELETE: delete the tenant product warranty from the server */
  deleteProductWarranty(tenantProductWarranty: TenantProductWarranty | number): Observable<TenantProductWarranty> {
    const id = typeof tenantProductWarranty === 'number' ? tenantProductWarranty : tenantProductWarranty.id;
    const url = `${this.base_url}/${id}`;

    return this.http.delete<TenantProductWarranty>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`deleted tenant product warranty id=${id}`)),
      catchError(this.handleError<TenantProductWarranty>('deleteTenantProductWarranty'))
    );
  }

  /** PUT: update the product warranty on the server */
  updateTenantProductWarranty(tenantProductWarranty: TenantProductWarranty): Observable<any> {
    return this.http.put(`${this.base_url}/${tenantProductWarranty.id}`, tenantProductWarranty, {headers: this.headers}).pipe(
      tap(_ => this.log(`updated tenant product warranty id=${tenantProductWarranty.id}`)),
      catchError(this.handleError<any>('updateTenantProductWarranty'))
    );
  }

  /** Patch: update the tenant product warranty on the server */
  editTenantProductWarranty(data): Observable<any> {
    return this.http.patch(`${this.base_url}/${data.id}/${data.param}/${data.value}`, {}, {headers: this.headers}).pipe(
      tap(_ => this.log(`updated tenant product warranty id=${data.id}`)),
      catchError(this.handleError<any>('updateTenantProductWarranty'))
    );
  }

}
