
<div class="flex flex-row justify-start my-8 mx-4">
  <div class="flex flex-row   gap-3 justify-start">
    <div class="text-xl text-black-dark-light">
      Home
    </div>
    <div class="text-xl font-black text-black-dark-light">
      /
    </div>
    <div class="text-xl text-black-dark-light cursor-pointer hover:text-dlight ease-in-out duration-300" routerLink="/ticketing/customfields">
      Custom Fields
    </div>
    <div class="text-xl font-black text-black-dark-light">
      /
    </div>
    <div class="text-xl text-black-dark-light text-orange-600">
      New Custom Field
    </div>
  </div>

</div>

<div class="mt-3" *ngIf="mainLoading">
  <div class="flex flex-row justify-center items-center w-full  gap-4">
    <span class="m-auto  inline-block h-14 w-14 animate-spin rounded-full border-8 border-[#f1f2f3] border-l-dlight align-middle"></span>
  </div>
</div>


<ng-container *ngIf="!mainLoading">
  <div class="w-2/3 m-auto">
    <div style="border-radius:20px" class="bg-white box-shadow-pop p-4">
      <div class="font-semibold text-lg">New Custom Field Details</div>

      <form>
        <div class="mt-6">
          <label class="label_title">Name
            <span *ngIf="customField.name == null || customField.name.length < 3" class="text-red-500">*</span>
            <span *ngIf="customField.name != null && customField.name.length >= 3" class="text-green-500">*</span>
          </label>
          <input class=" form-input form-input-focus" placeholder="Enter a name"  [(ngModel)]="customField.name" name="name"/>
        </div>
        <label class="label_title mt-3">Custom Field Type</label>
        <select class="select_input form-input form-input-focus" placeholder="Select Custom Field Type..."  [(ngModel)]="customField.customFieldTypeId" name="customFieldTypeId">
          <option value="" disabled>{{'Please select Field Type' | translate}}</option>
          <option [value]="type.id" *ngFor="let type of customFieldTypes"> {{type.name}} </option>
        </select>

        <div class="mt-6">
          <label class="label_title">Description</label>
          <textarea rows="2" class=" form-input form-input-focus" placeholder="Enter a description"  [(ngModel)]="customField.description" name="description"></textarea>
        </div>


        <div *ngIf="customField.customFieldTypeId == 3" class="mt-3">
          <div class="bg-white ticket-table mt-2 mx-auto w-2/3" style="border-radius:20px">

            <div class="grid grid-cols-2 py-3 ticket-row" *ngIf="customField.customFieldOptions.length > 0">
              <div class="col-span-1 m-auto">
                OPTION VALUE
              </div>
              <div class="col-span-1 m-auto">
                ACTIONS
              </div>
            </div>
              <div class="grid grid-cols-2 py-3 ticket-row" *ngFor="let option of customField.customFieldOptions">
                <div class="col-span-1 m-auto">
                  {{option.value}}
                </div>
                <div class="col-span-1 m-auto">
                  <button (click)="removeCustomFieldOption(option)" type="button" class="btn btn-sm rounded-full btn-outline-dlight "><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" xml:space="preserve" width="24" height="24"><path fill="none" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" d="M17.25 20.25h-9c-.825 0-1.5-.675-1.5-1.5V6h12v12.75c0 .825-.675 1.5-1.5 1.5zm3-14.25h-15m5.25 0V4.5c0-.45.3-.75.75-.75h3c.45 0 .75.3.75.75V6m-2.25 11.25V9m3 8.25V9m-6 8.25V9"/></svg>&nbsp;&nbsp;Remove </button>
                </div>
              </div>

          </div>

          <div class="mt-3 flex flex-row justify-center w-full items-center gap-2">
            <input type="text" class="form-input w-auto form-input-focus" [ngModelOptions]="{standalone: true}" [(ngModel)]="newFieldOption"  placeholder="Insert Option">
            <button type="button" class="btn btn-dlight" (click)="addOptionToField()">Add Option</button>
          </div>
        </div>

<!--        <div class="mt-3 flex flex-row justify-start items-center gap-3">-->
<!--          <label class="label_title mt-2">Is Mandatory</label>-->
<!--          <input type="checkbox" [ngModelOptions]="{standalone: true}" [(ngModel)]="customField.mandatory">-->
<!--        </div>-->

        <div class="mt-3 flex flex-row justify-center w-full" *ngIf="!loadingCreate">
          <button class="btn btn-dlight" (click)="createCustomField()">Create Custom Field</button>
        </div>

        <div class="mt-3" *ngIf="loadingCreate">
          <div class="flex flex-row justify-center items-center w-full  gap-4">
            <span class="m-auto  inline-block h-14 w-14 animate-spin rounded-full border-8 border-[#f1f2f3] border-l-dlight align-middle"></span>
          </div>
        </div>

      </form>
    </div>
  </div>


</ng-container>

