import {Action} from '@ngrx/store';

import {Consignment} from '@appModels/core-inventory/consignment/consignment';

export enum ConsignmentActionTypes {
  consignmentGetConsignments = '[Consignment] get',
  consignmentGetConsignmentsSuccess = '[Consignment] get consignments success',
  consignmentAddConsignment = '[Consignment] add Consignment',
  consignmentAddConsignmentSuccess = '[Consignment] add consignment success',
  consignmentPatchShippingCompany = '[Consignment] patch shipping company',
  consignmentPatchShippingCompanySuccess = '[Consignment] patch shipping company success',
  consignmentAddAdditionalConsignmentInfo = '[Consignment] add additional Consignment Info',
  consignmentAddAdditionalConsignmentInfoSuccess = '[Consignment] add additional consignment Info success',
  consignmentDeleteConsignment = '[Consignment] delete consignment',
  consignmentDeleteConsignmentSuccess = '[Consignment] delete consignment success',
  consignmentGetConsignmentById = '[Consignment] get consignment by id',
  consignmentGetConsignmentByStockingPointId = '[Consignment] get consignment by tenant id',
  consignmentGetConsignmentByStockingPointIdSuccess = '[Consignment] get consignment by tenant id success',
  consignmentGetConsignmentByTenantId = '[Consignment] get consignment by tenant id',
  consignmentGetConsignmentByTenantIdSuccess = '[Consignment] get consignment by tenant id success',
  consignmentGetConsignmentByIdSuccess = '[Consignment] get consignment by id success',
  consignmentUpdateConsignment = '[Consignment] update consignment',
  consignmentUpdateConsignmentSuccess = '[Consignment] update consignment success',
  consignmentSearchConsignments = '[Consignment] search consignments',
  consignmentSearchConsignmentsSuccess = '[Consignment] search consignments success',
  consignmentSearchConsignmentsReset = '[Consignment] search consignments reset',
  consignmentPatchStockingPointFrom = '[Consignment] patch stocking point from',
  consignmentPatchStockingPointFromSuccess = '[Consignment] patch stocking point from success',
  consignmentPatchStockingPointTo = '[Consignment] patch stocking point to',
  consignmentPatchStockingPointToSuccess = '[Consignment] patch stocking point to success',
  consignmentError = '[Consignment] error',
  consignmentDoNothing = '[Consignment] consignmentDoNothing',
  consignmentAddWithProduct = '[Consignment] consignmentAddWithProduct',
  consignmentOk = '[Consignment] consignmentOk'

}

export class GetConsignments implements Action {
  readonly type = ConsignmentActionTypes.consignmentGetConsignments;
}

export class GetConsignmentsSuccess implements Action {
  readonly type = ConsignmentActionTypes.consignmentGetConsignmentsSuccess;

  constructor(public payload: Consignment[]) {
  }
}

export class AddConsignment implements Action {
  readonly type = ConsignmentActionTypes.consignmentAddConsignment;

  constructor(public payload: Consignment) {
  }
}

export class AddConsignmentWithProduct implements Action {
  readonly type = ConsignmentActionTypes.consignmentAddWithProduct;

  constructor(public payload) {
  }
}

export class AddConsignmentSuccess implements Action {
  readonly type = ConsignmentActionTypes.consignmentAddConsignmentSuccess;

  constructor(public payload: Consignment) {
  }
}

export class AddAdditionalConsignmentInfo implements Action {
  readonly type = ConsignmentActionTypes.consignmentAddAdditionalConsignmentInfo;

  constructor(public payload) {
  }
}

export class AddAdditionalConsignmentInfoSuccess implements Action {
  readonly type = ConsignmentActionTypes.consignmentAddAdditionalConsignmentInfoSuccess;

  constructor(public payload) {
  }
}

export class GetConsignmentById implements Action {
  readonly type = ConsignmentActionTypes.consignmentGetConsignmentById;

  constructor(public payload: number) {
  }
}

export class GetConsignmentByIdSuccess implements Action {
  readonly type = ConsignmentActionTypes.consignmentGetConsignmentByIdSuccess;

  constructor(public payload: Consignment) {
  }
}

export class GetConsignmentByStockingPointId implements Action {
  readonly type = ConsignmentActionTypes.consignmentGetConsignmentByStockingPointId;

  constructor(public payload: number) {
  }
}

export class GetConsignmentByStockingPointIdSuccess implements Action {
  readonly type = ConsignmentActionTypes.consignmentGetConsignmentByStockingPointIdSuccess;

  constructor(public payload: Consignment[]) {
  }
}

export class GetConsignmentByTenantId implements Action {
  readonly type = ConsignmentActionTypes.consignmentGetConsignmentByTenantId;

  constructor(public payload: any) {
  }
}

export class GetConsignmentByTenantIdSuccess implements Action {
  readonly type = ConsignmentActionTypes.consignmentGetConsignmentByTenantIdSuccess;

  constructor(public payload: any) {
  }
}

export class UpdateConsignment implements Action {
  readonly type = ConsignmentActionTypes.consignmentUpdateConsignment;

  constructor(public payload: Consignment) {
  }
}

export class UpdateConsignmentSuccess implements Action {
  readonly type = ConsignmentActionTypes.consignmentUpdateConsignmentSuccess;

  constructor(public payload: Consignment) {
  }
}

export class DeleteConsignment implements Action {
  readonly type = ConsignmentActionTypes.consignmentDeleteConsignment;

  constructor(public payload: Consignment) {
  }
}

export class DeleteConsignmentSuccess implements Action {
  readonly type = ConsignmentActionTypes.consignmentDeleteConsignmentSuccess;

  constructor(public payload: Consignment) {
  }
}

export class SearchConsignments implements Action {
  readonly type = ConsignmentActionTypes.consignmentSearchConsignments;

  constructor(public payload: string) {
  }
}

export class SearchConsignmentsSuccess implements Action {
  readonly type = ConsignmentActionTypes.consignmentSearchConsignmentsSuccess;

  constructor(public payload: Consignment[]) {
  }
}

export class SearchConsignmentsReset implements Action {
  readonly type = ConsignmentActionTypes.consignmentSearchConsignmentsReset;
}

export class ConsignmentPatchStockingPointFrom implements Action {
  readonly type = ConsignmentActionTypes.consignmentPatchStockingPointFrom;

  constructor(public payload: Consignment) {
  }
}

export class ConsignmentPatchStockingPointFromSuccess implements Action {
  readonly type = ConsignmentActionTypes.consignmentPatchStockingPointFromSuccess;

  constructor(public payload: Consignment) {
  }
}

export class ConsignmentPatchStockingPointTo implements Action {
  readonly type = ConsignmentActionTypes.consignmentPatchStockingPointTo;

  constructor(public payload: Consignment) {
  }
}

export class ConsignmentPatchStockingPointToSuccess implements Action {
  readonly type = ConsignmentActionTypes.consignmentPatchStockingPointToSuccess;

  constructor(public payload: Consignment) {
  }
}

export class ConsignmentPatchShippingCompany implements Action {
  readonly type = ConsignmentActionTypes.consignmentPatchShippingCompany;

  constructor(public payload: Consignment) {
  }
}

export class ConsignmentPatchShippingCompanySuccess implements Action {
  readonly type = ConsignmentActionTypes.consignmentPatchShippingCompanySuccess;

  constructor(public payload: Consignment) {
  }
}

export class ConsignmentError implements Action {
  readonly type = ConsignmentActionTypes.consignmentError;

  constructor(public payload: any) {
  }
}

export class ConsignmentDoNothing implements Action {
  readonly type = ConsignmentActionTypes.consignmentDoNothing;

  constructor() {
  }
}

export class ConsignmentOk implements Action {
  readonly type = ConsignmentActionTypes.consignmentOk;

  constructor() {
  }
}

export type ConsignmentActions =
  | GetConsignments
  | GetConsignmentsSuccess
  | AddConsignment
  | AddConsignmentSuccess
  | AddAdditionalConsignmentInfo
  | AddAdditionalConsignmentInfoSuccess
  | GetConsignmentById
  | GetConsignmentByIdSuccess
  | UpdateConsignment
  | UpdateConsignmentSuccess
  | DeleteConsignment
  | DeleteConsignmentSuccess
  | SearchConsignments
  | SearchConsignmentsSuccess
  | SearchConsignmentsReset
  | GetConsignmentByStockingPointId
  | GetConsignmentByStockingPointIdSuccess
  | GetConsignmentByTenantId
  | GetConsignmentByTenantIdSuccess
  | ConsignmentPatchStockingPointFrom
  | ConsignmentPatchStockingPointFromSuccess
  | ConsignmentPatchStockingPointTo
  | ConsignmentPatchStockingPointToSuccess
  | ConsignmentPatchShippingCompany
  | ConsignmentPatchShippingCompanySuccess
  | ConsignmentError
  | ConsignmentDoNothing
  | ConsignmentOk
  | AddConsignmentWithProduct;
