import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import * as _ from 'lodash';
import {SoftLaunchProject} from '../../../../models/core-soft-launch/soft-launch-project';
import {
  SoftLaunchProjectActions,
  SoftLaunchProjectActionTypes
} from '../../../actions/core-soft-launch/project/soft-launch-project.actions';

export interface State extends EntityState<SoftLaunchProject> {
  error: any;
  loaded: boolean;
  loading: boolean;
  selectedId: number;
  paging: any;
}

export const adapter: EntityAdapter<SoftLaunchProject> = createEntityAdapter<SoftLaunchProject>();

export const initialState: State = adapter.getInitialState({
  error: null,
  loaded: false,
  loading: false,
  selectedId: null,
  paging: null
});

export function reducer(state = initialState, action: SoftLaunchProjectActions): State {
  switch (action.type) {
    case SoftLaunchProjectActionTypes.softLaunchProjectGet:
    case SoftLaunchProjectActionTypes.softLaunchProjectAdd:
      return {
        ...state,
        loading: true,
        loaded: false
      };

    case SoftLaunchProjectActionTypes.softLaunchProjectAddSuccess:
    case SoftLaunchProjectActionTypes.softLaunchProjectGetSuccess:
      return adapter.addOne(action.payload, {
        ...state,
        selectedId: action.payload.id,
        loading: false,
        loaded: true
      });

    case SoftLaunchProjectActionTypes.softLaunchProjectError:
      return adapter.addOne(action.payload, {
        ...state,
        error: action.payload.id,
        loading: false,
        loaded: true
      });

    case SoftLaunchProjectActionTypes.softLaunchProjectGetAllSuccess:
      return adapter.setAll(action.payload.content, {
        ...state,
        loading: false,
        loaded: true,
        paging: _.omit(action.payload, ['content'])
      });
    default:
      return state;
  }
}

export const softLaunchProjectEntitySelectors = adapter.getSelectors();

