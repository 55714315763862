import {ChangeDetectorRef, Component, TemplateRef, ViewChild, ViewEncapsulation} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';


import {ToastrService} from "ngx-toastr";

import {Router} from "@angular/router";
import {TicketTypeService} from "@appServices/core-ticketing/ticket-type.service";
import {BasePojo} from "@appModels/ticketing/base-pojo";

@Component({
  moduleId: module.id,
  templateUrl: './ticket-type-create-component.html',
  animations: [
    trigger('toggleAnimation', [
      transition(':enter', [style({ opacity: 0, transform: 'scale(0.95)' }), animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' }))]),
      transition(':leave', [animate('75ms', style({ opacity: 0, transform: 'scale(0.95)' }))]),
    ]),
  ],
  styleUrls: ['./ticket-type-create-component.css', '../../../tailwind.css'],
  encapsulation: ViewEncapsulation.None

})
export class TicketTypeCreateComponent {



  constructor(
    private toastrService: ToastrService,
    private ref: ChangeDetectorRef,
    private ticketTypeService: TicketTypeService,
    private router: Router,

  ) {

  }

  mainLoading = false;


  ticketType : BasePojo = {
    name:"",
    description:null
  }


  createTicketType() {
    if (this.ticketType.name == null || this.ticketType.name == undefined || this.ticketType.name.length < 3) {
      this.toastrService.error("Please enter a longer name");
      return;
    }

    this.mainLoading = true;


    this.ticketTypeService.createTicketType(this.ticketType, true).subscribe(x => {
      this.toastrService.success("New Ticket Type Created");
      this.mainLoading = false;
      this.router.navigateByUrl(`/ticketing/tickettype`);

    })
  }
}
