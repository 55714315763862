import { Action } from '@ngrx/store';

// Use 'enum' to be registered as a map later
export enum NavActionTypes {
    Nav = '[Nav] Nav',
    ModuleNav = '[Nav] Module',
    SubNav = '[Nav] SubNav Module',
    Success = '[Nav] Success',
    ModuleSuccess = '[Nav] Module Success',
    SubNavSuccess = '[Nav] SubNav Success',
}

export class Nav implements Action {
  readonly type = NavActionTypes.Nav;

  constructor(public payload) {}
}

export class ModuleNav implements Action {
  readonly type = NavActionTypes.ModuleNav;

  constructor(public payload) {}
}

export class SubNav implements Action {
  readonly type = NavActionTypes.SubNav;

  constructor(public payload) {}
}

export class NavSuccess implements Action {
  readonly type = NavActionTypes.Success;

  constructor(public payload: string) {}
}

export class ModuleNavSuccess implements Action {
  readonly type = NavActionTypes.ModuleSuccess;

  constructor(public payload: string) {}
}

export class SubNavSuccess implements Action {
  readonly type = NavActionTypes.SubNavSuccess;

  constructor(public payload: string) {}
}


export type NavActions =
    | Nav
    | ModuleNav
    | SubNav
    | NavSuccess
    | ModuleNavSuccess
    | SubNavSuccess;
