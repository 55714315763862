<h1 class="m-40">Frequently Asked Questions</h1>

<section class="row">
  <div class="template col-sm">
    <h2>Getting started on Atlas </h2>
    <div class="m-t-20 m-b-10 border" (click)="changeValue('atlas_instance')"
      [attr.aria-expanded]="!isCollapsed['atlas_instance']" aria-controls="atlas_instance">
      <h4 class="m-b-10">
        <a href="javascript:void(0)">
          1. How do I set up my new Atlas instance?
          <!-- <span *ngIf="isCollapsed['atlas_instance']">
            <i class="fa fa-plus-circle align"></i>
          </span> -->
          <!-- <span *ngIf="!isCollapsed['atlas_instance']"><i class="fa fa-minus-circle align active"></i></span> -->
        </a>
      </h4>
      <p id="atlas_instance" [collapse]="isCollapsed['atlas_instance']" [isAnimated]="true">
        Your new Atlas instance can be fully set up by a user with Tenant Admin permissions on the Setup module. This
        will involve configuring geographical, financial, product and CRM information to be captured for your
        organization.
      </p>
    </div>

    <div class="m-t-20 m-b-10 border" (click)="changeValue('functionalities')"
      [attr.aria-expanded]="!isCollapsed['functionalities']" aria-controls="functionalities">
      <h4 class="m-b-10">
        <a href="javascript:void(0)">
          2. What functionalities do the different modules have?
        </a>
      </h4>
      <ul id="functionalities" [collapse]="isCollapsed['functionalities']" [isAnimated]="true">
        <li>
          <p>• Setup Module: New instance set up, rate setup and management, CRM set up</p>
        </li>
        <li>
          <p>• Identity Management: New user and user roles setup, user permissions allocation</p>
        </li>
        <li>
          <p>• Field Force: Stocking point setup, sales structure and hierarchy levels setup and configuration</p>
        </li>
        <li>
          <p>• Inventory: Shipping companies setup, creation and management of consignments</p>
        </li>
        <li>
          <p>• CRM: New customer registration, customer calls and actions, escalations from customer actions</p>
        </li>
        <li>
          <p>• Finance: Management of held payments, manual payments and customer finance report</p>
        </li>
        <li>
          <p>• Knowledge Base: Assistant manuals on navigating and using your Atlas instance </p>
        </li>
      </ul>
    </div>

    <div class="m-t-20 m-b-10 border" (click)="changeValue('navigation')"
      [attr.aria-expanded]="!isCollapsed['navigation']" aria-controls="navigation">
      <h4 class="m-b-10">
        <a href="javascript:void(0)">
          3. How do I navigate between modules on V4?
        </a>
      </h4>
      <p id="navigation" [collapse]="isCollapsed['navigation']" [isAnimated]="true">
        You can easily navigate between modules by clicking on the drop down arrow next to the module name.
      </p>
    </div>

    <div class="m-t-20 m-b-10 border" (click)="changeValue('password')"
      [attr.aria-expanded]="!isCollapsed['password']" aria-controls="password">
      <h4 class="m-b-10">
        <a href="javascript:void(0)">
          4. What do I do if I can’t remember my password?
        </a>
      </h4>
      <p id="password" [collapse]="isCollapsed['password']" [isAnimated]="true">
        You can use the forgot password option to receive reset instructions to your email, or you can request for
        support from one of your organization’s administrators in resetting your password.
      </p>
    </div>

    <div class="m-t-20 m-b-10 border" (click)="changeValue('support')"
      [attr.aria-expanded]="!isCollapsed['support']" aria-controls="support">
      <h4 class="m-b-10">
        <a href="javascript:void(0)">
          5. How do I get support with questions on Atlas?
        </a>
      </h4>
      <div id="support" [collapse]="isCollapsed['support']" [isAnimated]="true">
        <p>
          There are many support lines put in place to ensure that you receive adequate support on Atlas. These are in
          order of lines of support and include:
        </p>
        <ul>
          <li>
            <p>1. Access to instructional manuals on the Knowledge Base module </p>
          </li>
          <li>
            <p>2. Access to the Business Service Desk (BSD) team for any general inquiries, bug reporting, service
              requests
              or feature requests.</p>
          </li>
          <li>
            <p>
              3. Access to a dedicated Customer Success Manager (CSM) to support with other Atlas related queries
            </p>
          </li>
        </ul>
      </div>

    </div>

  </div>

  <div class="template col-sm">
    <h2>Setting Up & Customizing Your Atlas Instance </h2>
    <div class="m-t-20 m-b-10 border" (click)="changeValue('rate')"
      [attr.aria-expanded]="!isCollapsed['rate']" aria-controls="rate">
      <h4 class="m-b-10">
        <a href="javascript:void(0)">
          1. How do I add new rates on Atlas?
        </a>
      </h4>
      <div id="rate" [collapse]="isCollapsed['rate']" [isAnimated]="true">
        <p>
          There are many support lines put in place to ensure that you receive adequate support on Atlas. These are in
          order of lines of support and include:
        </p>
        <ul>
          <li>
            <p>• Navigating to Finance under the Setup module and selecting ‘Rates’ from the drop down menu. </p>
          </li>
          <li>
            <p>• Entering the general rate information of the rate which includes: Product information, Rate name, Rate
              type, Total cost of the product, description of the rate plan and activating the rate.</p>
          </li>
        </ul>
      </div>
    </div>

    <div class="m-t-20 m-b-10 border" (click)="changeValue('payment_plan')"
      [attr.aria-expanded]="!isCollapsed['payment_plan']" aria-controls="payment_plan">
      <h4 class="m-b-10">
        <a href="javascript:void(0)">
          2. How can I change existing payment plans?
        </a>
      </h4>
      <p id="payment_plan" [collapse]="isCollapsed['payment_plan']" [isAnimated]="true">
        This action is guarded by permissions that your tenant administrators will have. Please reach out to your tenant
        administrator should you want to change the existing rate plans.
      </p>
    </div>


    <div class="m-t-20 m-b-10 border" (click)="changeValue('existing_customer')"
      [attr.aria-expanded]="!isCollapsed['existing_customer']" aria-controls="existing_customer">
      <h4 class="m-b-10">
        <a href="javascript:void(0)">
          3. Will the changes made to rates affect existing customers?
        </a>
      </h4>
      <p id="payment_plan" [collapse]="isCollapsed['existing_customer']" [isAnimated]="true">
        No, the changes made to a rate plan will not be applied to customers who had already been registered under the
        old rate plan. This change will only apply to new customers registered after the change. Please reach out to
        your CSM in case of further questions.
      </p>
    </div>

    <div class="m-t-20 m-b-10 border" (click)="changeValue('products')"
      [attr.aria-expanded]="!isCollapsed['products']" aria-controls="products">
      <h4 class="m-b-10">
        <a href="javascript:void(0)">
          4. How do I add new products I’ve purchased to Atlas?
        </a>
      </h4>
      <p id="products" [collapse]="isCollapsed['products']" [isAnimated]="true">
        Once your partnerships representative at d.light has confirmed that your new products have shipped, please raise
        a ticket to add the new product to your Atlas instance or reach out to your CSM for further assistance.
      </p>
    </div>

    <div class="m-t-20 m-b-10 border" (click)="changeValue('registration')"
      [attr.aria-expanded]="!isCollapsed['registration']" aria-controls="registration">
      <h4 class="m-b-10">
        <a href="javascript:void(0)">
          5. Do I have the power to customize my customer registration fields on Atlas?
        </a>
      </h4>
      <p id="registration" [collapse]="isCollapsed['registration']" [isAnimated]="true">
        Yes, a user with tenant administrator permissions can setup customizable fields which will reflect on the
        customer registration form. This user can also customize the customer statuses and identification types
        available. </p>
    </div>

    <div class="m-t-20 m-b-10 border" (click)="changeValue('customizations')"
      [attr.aria-expanded]="!isCollapsed['customizations']" aria-controls="customizations">
      <h4 class="m-b-10">
        <a href="javascript:void(0)">
          6. What if I want to make customizations to other fields in Atlas?
        </a>
      </h4>
      <p id="customizations" [collapse]="isCollapsed['customizations']" [isAnimated]="true">
        Please raise a feature request on the Business Service Desk, detailing the changes you would like to make and
        why. Your CSM will then reach out to you to discuss the way forward and whether the feature can be implemented.
      </p>
    </div>

    <div class="m-t-20 m-b-10 border" (click)="changeValue('setup_change')"
      [attr.aria-expanded]="!isCollapsed['setup_change']" aria-controls="setup_change">
      <h4 class="m-b-10">
        <a href="javascript:void(0)">
          7. Which permissions are needed to make any setup changes on Atlas?
        </a>
      </h4>
      <p id="customizations" [collapse]="isCollapsed['setup_change']" [isAnimated]="true">
        Tenant administrator permissions.
      </p>
    </div>


  </div>
</section>

<section class="row">
  <div class="template col-sm">
    <h2>Managing Your Users & Permissions on Atlas </h2>
    <div class="m-t-20 m-b-10 border" (click)="changeValue('new_user')"
      [attr.aria-expanded]="!isCollapsed['new_user']" aria-controls="new_user">
      <h4 class="m-b-10">
        <a href="javascript:void(0)">
          1. How do I add a new user?
        </a>
      </h4>
      <p id="new_user" [collapse]="isCollapsed['new_user']" [isAnimated]="true">
        A new user can be added under User Management which is found in the Identity Management module. This action is
        guarded by the permissions assigned to you so kindly confirm access first. After, please refer to this
        <b>assistant article</b> to access the step by step guide.
      </p>
    </div>

    <div class="m-t-20 m-b-10 border" (click)="changeValue('deactivate')"
      [attr.aria-expanded]="!isCollapsed['deactivate']" aria-controls="deactivate">
      <h4 class="m-b-10">
        <a href="javascript:void(0)">
          2. How do I deactivate a user who has left my organization?
        </a>
      </h4>
      <p id="deactivate" [collapse]="isCollapsed['deactivate']" [isAnimated]="true">
        You can change the status of a user from enabled to disabled under the Identity Management module. This will
        restrict their access to Atlas. Please ensure that the user logs out of Atlas on all devices.
      </p>
    </div>

    <div class="m-t-20 m-b-10 border" (click)="changeValue('new_role')"
      [attr.aria-expanded]="!isCollapsed['new_role']" aria-controls="new_role">
      <h4 class="m-b-10">
        <a href="javascript:void(0)">
          3. How do I add a new role and permissions?
        </a>
      </h4>
      <p id="new_role" [collapse]="isCollapsed['new_role']" [isAnimated]="true">
        A new user role and the permissions associated with it can be added under Role Management which is found in the
        Identity Management module. This action is guarded by the permissions assigned to you so kindly confirm access
        first. After, please refer to this <b>assistant article</b> to access the step by step guide.
      </p>
    </div>

    <div class="m-t-20 m-b-10 border" (click)="changeValue('edit_role')"
      [attr.aria-expanded]="!isCollapsed['edit_role']" aria-controls="edit_role">
      <h4 class="m-b-10">
        <a href="javascript:void(0)">
          4. Can I edit a role and permissions after creation?
        </a>
      </h4>
      <p id="edit_role" [collapse]="isCollapsed['edit_role']" [isAnimated]="true">
        A new user role and the permissions associated with it can be added under Role Management which is found in the
        Identity Management module. This action is guarded by the permissions assigned to you so kindly confirm access
        first. After, please refer to this <b>assistant article</b> to access the step by step guide.
      </p>
    </div>

    <div class="m-t-20 m-b-10 border" (click)="changeValue('remove_permission')"
      [attr.aria-expanded]="!isCollapsed['remove_permission']" aria-controls="remove_permission">
      <h4 class="m-b-10">
        <a href="javascript:void(0)">
          5. How do I remove access permissions for a specific user?
        </a>
      </h4>
      <p id="remove_permission" [collapse]="isCollapsed['remove_permission']" [isAnimated]="true">
        You can do this by editing the user and the permissions assigned to them. Please refer to this <b>assistant
          article</b> to access the step by step guide.
      </p>
    </div>

  </div>

  <div class="template col-sm">
    <h2>Managing Your Sales Structure/Route to Market on Atlas </h2>
    <div class="m-t-20 m-b-10 border" (click)="changeValue('field_force')"
      [attr.aria-expanded]="!isCollapsed['field_force']" aria-controls="field_force">
      <h4 class="m-b-10">
        <a href="javascript:void(0)">
          1. What is a field force force type in V4?
        </a>
      </h4>
      <p id="field_force" [collapse]="isCollapsed['field_force']" [isAnimated]="true">
        A sales force type is the name of the different distribution channels available in your organization. These
        include warehouses, distributors, dealers, retail outlets, sales agents etc. Adding these different field force
        types is covered in the next section.
      </p>
    </div>

    <div class="m-t-20 m-b-10 border" (click)="changeValue('new_agent')"
      [attr.aria-expanded]="!isCollapsed['new_agent']" aria-controls="new_agent">
      <h4 class="m-b-10">
        <a href="javascript:void(0)">
          2. How do I add a new agent/retailer/distributor?
        </a>
      </h4>
      <p id="new_agent" [collapse]="isCollapsed['new_agent']" [isAnimated]="true">
        A user with the permissions to perform this action can add the above mentioned staff types under the Field Force
        Module. This user can do so by first defining the sales staff hierarchy level and then adding the new sales
        staff information under Manage Sales Staff. Please refer to this assistant article for a step by step guide.
      </p>
    </div>


    <div class="m-t-20 m-b-10 border" (click)="changeValue('staff_level')"
      [attr.aria-expanded]="!isCollapsed['staff_level']" aria-controls="staff_level">
      <h4 class="m-b-10">
        <a href="javascript:void(0)">
          3. What are staff hierarchy levels and how do they affect actions on Atlas?
        </a>
      </h4>
      <p id="staff_level" [collapse]="isCollapsed['staff_level']" [isAnimated]="true">
        These are the sales staff reporting structures configured for your organization. An example could be a Regional
        Director holding the highest rank and adding the configured Regional Director as a line manager for the Regional
        Manager etc.
      </p>
    </div>

    <div class="m-t-20 m-b-10 border" (click)="changeValue('disable_agent')"
      [attr.aria-expanded]="!isCollapsed['disable_agent']" aria-controls="disable_agent">
      <h4 class="m-b-10">
        <a href="javascript:void(0)">
          4. How do I disable an agent/retailer/distributor?
        </a>
      </h4>
      <p id="disable_agent" [collapse]="isCollapsed['disable_agent']" [isAnimated]="true">
        Any sales staff who has left your organization can be disabled to restrict them from accessing company
        information on Atlas. To do so, identify the user you want to change access for and click ‘Edit’. Then uncheck
        the box labelled ‘Enabled’ and save changes.
      </p>
    </div>

    <div class="m-t-20 m-b-10 border" (click)="changeValue('stocking_point')"
      [attr.aria-expanded]="!isCollapsed['stocking_point']" aria-controls="stocking_point">
      <h4 class="m-b-10">
        <a href="javascript:void(0)">
          5. How do I create a stocking point?
        </a>
      </h4>
      <p id="stocking_point" [collapse]="isCollapsed['stocking_point']" [isAnimated]="true">
        A stocking point is any entity that can hold stock. This could be a sales agent, dealer or warehouse. Stocking
        points are configured under the Field Force Module while creating a new sales staff type. To create a stocking
        point, please refer to this assistant article for a step by step guide.
      </p>
    </div>

    <div class="m-t-20 m-b-10 border" (click)="changeValue('commissions')"
      [attr.aria-expanded]="!isCollapsed['commissions']" aria-controls="commissions">
      <h4 class="m-b-10">
        <a href="javascript:void(0)">
          6. How do I create commissions and KPIs for my sales agents?
        </a>
      </h4>
      <p id="commissions" [collapse]="isCollapsed['commissions']" [isAnimated]="true">
        No Answer
      </p>
    </div>

  </div>
</section>

<section class="row">
  <div class="template col-sm">
    <h2>Managing Your Inventory on Atlas </h2>
    <div class="m-t-20 m-b-10 border" (click)="changeValue('new_stocking')"
      [attr.aria-expanded]="!isCollapsed['new_stocking']" aria-controls="new_stocking">
      <h4 class="m-b-10">
        <a href="javascript:void(0)">
          1. Where can I add a new stocking point?
        </a>
      </h4>
      <p id="new_stocking" [collapse]="isCollapsed['new_stocking']" [isAnimated]="true">
        Stocking points are configured under the Field Force module. To add a new stocking point, please refer to this
        <b>assistant article</b> for a step by step guide.
      </p>
    </div>

    <div class="m-t-20 m-b-10 border" (click)="changeValue('consignment')"
      [attr.aria-expanded]="!isCollapsed['consignment']" aria-controls="consignment">
      <h4 class="m-b-10">
        <a href="javascript:void(0)">
          2. What is a consignment and how do I create one?
        </a>
      </h4>
      <p id="consignment" [collapse]="isCollapsed['consignment']" [isAnimated]="true">
        A consignment is a shipment of products from one stocking point to another. You can create a consignment under
        the Inventory Module by clicking on Consignments > Add Consignments. Important to note that a newly created
        consignment doesn’t have products attached to it automatically and this must be done as an additional action.
        Please refer to this <b>assistant article</b> for a step by step guide.
      </p>
    </div>

    <div class="m-t-20 m-b-10 border" (click)="changeValue('consignment_status')"
      [attr.aria-expanded]="!isCollapsed['consignment_status']" aria-controls="consignment_status">
      <h4 class="m-b-10">
        <a href="javascript:void(0)">
          3. How do I change my consignment status?
        </a>
      </h4>
      <p id="consignment_status" [collapse]="isCollapsed['consignment_status']" [isAnimated]="true">
        The status automatically changes once an action is performed by the sender or recipient of the consignment. The
        only manual change made is when products have been added to a consignment and it is ready to ship. To make this
        change, simply click on the status name and it will change from ‘Generated’ to ‘In transit’.
      </p>
    </div>

    <div class="m-t-20 m-b-10 border" (click)="changeValue('upload_inventory')"
      [attr.aria-expanded]="!isCollapsed['upload_inventory']" aria-controls="upload_inventory">
      <h4 class="m-b-10">
        <a href="javascript:void(0)">
          4. Can I bulk upload inventory?
        </a>
      </h4>
      <p id="upload_inventory" [collapse]="isCollapsed['upload_inventory']" [isAnimated]="true">
        Yes, you can send batches of product using the ‘Batch Consignment Product Upload’ function available under
        Inventory > Add consignments. This will allow you to upload a CSV file with multiple serial and account numbers
        to your desired stocking point. Please refer to this <b>assistant article</b> to access the step by step guide.
      </p>
    </div>

    <div class="m-t-20 m-b-10 border" (click)="changeValue('bulk_action')"
      [attr.aria-expanded]="!isCollapsed['bulk_action']" aria-controls="bulk_action">
      <h4 class="m-b-10">
        <a href="javascript:void(0)">
          5. Can a recipient perform bulk actions on inventory received?
        </a>
      </h4>
      <p id="bulk_action" [collapse]="isCollapsed['bulk_action']" [isAnimated]="true">
        Yes, a recipient can either accept/reject inventory sent to them in bulk or per product.
      </p>
    </div>

    <div class="m-t-20 m-b-10 border" (click)="changeValue('inventory_status')"
      [attr.aria-expanded]="!isCollapsed['inventory_status']" aria-controls="inventory_status">
      <h4 class="m-b-10">
        <a href="javascript:void(0)">
          6. How do I view inventory movement status?
        </a>
      </h4>
      <p id="inventory_status" [collapse]="isCollapsed['inventory_status']" [isAnimated]="true">
        Inventory movement statuses can be viewed on the Consignments landing page.
      </p>
    </div>

    <div class="m-t-20 m-b-10 border" (click)="changeValue('send_stock')"
      [attr.aria-expanded]="!isCollapsed['send_stock']" aria-controls="send_stock">
      <h4 class="m-b-10">
        <a href="javascript:void(0)">
          7. Why am I getting an error while trying to send stock?
        </a>
      </h4>
      <p id="send_stock" [collapse]="isCollapsed['send_stock']" [isAnimated]="true">
        Answer not available
      </p>
    </div>

  </div>

  <div class="template col-sm">
    <h2>Registering & Managing Customers on Atlas </h2>
    <div class="m-t-20 m-b-10 border" (click)="changeValue('customer_registration')"
      [attr.aria-expanded]="!isCollapsed['customer_registration']" aria-controls="customer_registration">
      <h4 class="m-b-10">
        <a href="javascript:void(0)">
          1. How do I register a customer?
        </a>
      </h4>
      <p id="customer_registration" [collapse]="isCollapsed['customer_registration']" [isAnimated]="true">
        You can register a new customer under the CRM module by clicking ‘Add customer’ and entering all the customer
        details which includes general, product and payment information then saving changes. New customers will be
        notified of their registration and automatically receive future updates via SMS.
      </p>
    </div>

    <div class="m-t-20 m-b-10 border" (click)="changeValue('lead')"
      [attr.aria-expanded]="!isCollapsed['lead']" aria-controls="lead">
      <h4 class="m-b-10">
        <a href="javascript:void(0)">
          2. Why is my customer showing up as a lead?
        </a>
      </h4>
      <p id="lead" [collapse]="isCollapsed['lead']" [isAnimated]="true">
        A customer is registered as a lead when there is no product or payment information attached to them. This means
        that there is no existing contract between the customer and your organization.
      </p>
    </div>


    <div class="m-t-20 m-b-10 border" (click)="changeValue('customer_status')"
      [attr.aria-expanded]="!isCollapsed['customer_status']" aria-controls="customer_status">
      <h4 class="m-b-10">
        <a href="javascript:void(0)">
          3. What do the customer statuses mean?
        </a>
      </h4>
      <p id="customer_status" [collapse]="isCollapsed['customer_status']" [isAnimated]="true">
        The customer status provides a quick overview of the customer profile and their payment progress. Please refer
        to this <b>assistant article</b> to view the meaning of the different customer statuses and how to customize
        your own.
      </p>
    </div>

    <div class="m-t-20 m-b-10 border" (click)="changeValue('error_registration')"
      [attr.aria-expanded]="!isCollapsed['error_registration']" aria-controls="error_registration">
      <h4 class="m-b-10">
        <a href="javascript:void(0)">
          4. Why am I getting an error when trying to register a new customer?
        </a>
      </h4>
      <p id="error_registration" [collapse]="isCollapsed['error_registration']" [isAnimated]="true">
        Answer not Available
      </p>
    </div>

    <div class="m-t-20 m-b-10 border" (click)="changeValue('action_type')"
      [attr.aria-expanded]="!isCollapsed['action_type']" aria-controls="action_type">
      <h4 class="m-b-10">
        <a href="javascript:void(0)">
          5. What are the different action types available and what do they do?
        </a>
      </h4>
      <p id="action_type" [collapse]="isCollapsed['action_type']" [isAnimated]="true">
        There are different actions that you can perform on your existing customers as part of the customer management
        process. Please refer to this <b>assistant article</b> for the action types and their effects on the customer
        profile.
      </p>
    </div>

    <div class="m-t-20 m-b-10 border" (click)="changeValue('escalated_action')"
      [attr.aria-expanded]="!isCollapsed['escalated_action']" aria-controls="escalated_action">
      <h4 class="m-b-10">
        <a href="javascript:void(0)">
          6. Which call actions are escalated?
        </a>
      </h4>
      <div id="escalated_action" [collapse]="isCollapsed['escalated_action']" [isAnimated]="true">
        <p>
          Some actions require the attention and approval of a supervisor in order for the process to be completed.
          These include:
        </p>
        <ul>
          <li>
            <p>• Customer edits </p>
          </li>
          <li>
            <p>• Payment support</p>
          </li>
          <li>
            <p>• Warranty claims </p>
          </li>
        </ul>
      </div>
    </div>

    <div class="m-t-20 m-b-10 border" (click)="changeValue('registration_report')"
      [attr.aria-expanded]="!isCollapsed['registration_report']" aria-controls="registration_report">
      <h4 class="m-b-10">
        <a href="javascript:void(0)">
          7. How do I get registration reports?
        </a>
      </h4>
      <p id="registration_report" [collapse]="isCollapsed['registration_report']" [isAnimated]="true">
        Customer registration reports can be accessed under CRM reports by a user with the privileges to do so.
      </p>
    </div>

  </div>
</section>

<section class="row">
  <div class="template col-sm">
    <h2>Managing Your Finances on Atlas </h2>
    <div class="m-t-20 m-b-10 border" (click)="changeValue('finance_report')"
      [attr.aria-expanded]="!isCollapsed['finance_report']" aria-controls="finance_report">
      <h4 class="m-b-10">
        <a href="javascript:void(0)">
          1. How can I access customer finance reports?
        </a>
      </h4>
      <p id="finance_report" [collapse]="isCollapsed['finance_report']" [isAnimated]="true">
        Users with the permissions to access these reports can do so by navigating to reports under the Finance module
        and filtering to select the report they would like to see. Reports can also be downloaded to your device for
        further analysis. Please refer to this <b>assistant article</b> for a step by step guide.
      </p>
    </div>

    <div class="m-t-20 m-b-10 border" (click)="changeValue('manual_payment')"
      [attr.aria-expanded]="!isCollapsed['manual_payment']" aria-controls="manual_payment">
      <h4 class="m-b-10">
        <a href="javascript:void(0)">
          2. How do I add manual payments?
        </a>
      </h4>
      <p id="manual_payment" [collapse]="isCollapsed['manual_payment']" [isAnimated]="true">
        Manual payments can be added to a customer profile by going to Finance Module > Manual payments > Add Manual
        Payment. Please refer to this assistant article for a step by step guide.
      </p>
    </div>

    <div class="m-t-20 m-b-10 border" (click)="changeValue('payment_held')"
      [attr.aria-expanded]="!isCollapsed['payment_held']" aria-controls="payment_held">
      <h4 class="m-b-10">
        <a href="javascript:void(0)">
          3. Why was a payment sent to held payments?
        </a>
      </h4>

      <div id="payment_held" [collapse]="isCollapsed['payment_held']" [isAnimated]="true">
        <p>
          Payments can be sent to held payments instead of generating a token to send out to the customer for a number
          of reasons. These include among others:
        </p>
        <ul>
          <li>
            <p> • Amount paid by the customer was less than the amount expected on the rate plan, hence no token is
              generated and payment is held until top up.</p>
          </li>
          <li>
            <p> • Customer account was inactive at the time of payment and needs action before the payment can be
              applied to them.</p>
          </li>
          <li>
            <p> • The customer entered a wrong reference field while making the payment thus the payment is held and
              must be corrected before it is applied.</p>
          </li>
          <li>
            <p> • Approval is required before payment can be applied to a customer account.</p>
          </li>
        </ul>
      </div>

    </div>

    <div class="m-t-20 m-b-10 border" (click)="changeValue('held_payments')"
      [attr.aria-expanded]="!isCollapsed['held_payments']" aria-controls="held_payments">
      <h4 class="m-b-10">
        <a href="javascript:void(0)">
          4. How do I release and assign held payments?
        </a>
      </h4>
      <p id="held_payments" [collapse]="isCollapsed['held_payments']" [isAnimated]="true">
        Payments that are held can be reprocessed by users with the permissions to do so by editing the payment. This is
        done under the CRM Module > Held payments > Edit selected payment. Please refer to this <b>assistant article</b>
        to access the step by step guide.
      </p>
    </div>
  </div>

  <div class="template col-sm">
    <h2>Tokens Support </h2>
    <div class="m-t-20 m-b-10 border" (click)="changeValue('token_generated')"
      [attr.aria-expanded]="!isCollapsed['token_generated']" aria-controls="token_generated">
      <h4 class="m-b-10">
        <a href="javascript:void(0)">
          1. Why are tokens not generated when a payment has been made?
        </a>
      </h4>
      <div id="token_generated" [collapse]="isCollapsed['token_generated']" [isAnimated]="true">
        <p>
          Tokens may not be generated and sent to a customer due to a number of reasons. These could be due to payment
          or technical issues. For payment issues, the issue can be resolved by internal team members with permissions
          to troubleshoot and effect changes. For technical issues, the issue should be reported immediately to d.light
          via the Business Service desk and flagged with the CSM for further assistance. Some examples of payment
          related token issues are:
        </p>
        <ul>
          <li>
            <p> • Amount paid by the customer was less than the amount expected on the rate plan, hence no token is
              generated and payment is held until top up.</p>
          </li>
          <li>
            <p> • Customer account was inactive at the time of payment and needs action before the payment can be
              applied to them.</p>
          </li>
          <li>
            <p> • The customer entered a wrong reference field while making the payment thus the payment is held and
              must be corrected before it is applied.</p>
          </li>
          <li>
            <p> • Approval is required before payment can be applied to a customer account.</p>
          </li>
        </ul>
        <p>Some examples of technical related token issues are: </p>
        <p>• SMS provider delays or downtime </p>
        <p>• Atlas > SMS provider integration downtimes </p>
      </div>

    </div>

    <div class="m-t-20 m-b-10 border" (click)="changeValue('token_entered')"
      [attr.aria-expanded]="!isCollapsed['token_entered']" aria-controls="token_entered">
      <h4 class="m-b-10">
        <a href="javascript:void(0)">
          2. Why does the unit not accept the token entered?
        </a>
      </h4>
      <p id="token_entered" [collapse]="isCollapsed['token_entered']" [isAnimated]="true">
        Tokens for d.light PAYG units have a specific token index sequence. Tokens must thus be entered into the unit in
        the order in which they were generated for the unit to accept the token. The tokens start from index 0. To
        determine which token must be entered into the unit, press 03# on the base unit. The next index required will be
        displayed on the screen which will assist with determining which token index to enter. If the correct token
        index is entered and the unit still rejects the token, please log a ticket on the Business Service Desk for
        further support.
      </p>
    </div>


    <div class="m-t-20 m-b-10 border" (click)="changeValue('token_expire')"
      [attr.aria-expanded]="!isCollapsed['token_expire']" aria-controls="token_expire">
      <h4 class="m-b-10">
        <a href="javascript:void(0)">
          3. Do the tokens issued expire?
        </a>
      </h4>
      <p id="token_expire" [collapse]="isCollapsed['token_expire']" [isAnimated]="true">
        No, the tokens issued to customers do not expire.
      </p>
    </div>

    <div class="m-t-20 m-b-10 border" (click)="changeValue('demo_token')"
      [attr.aria-expanded]="!isCollapsed['demo_token']" aria-controls="demo_token">
      <h4 class="m-b-10">
        <a href="javascript:void(0)">
          4. How do I request for demo tokens?
        </a>
      </h4>
      <p id="demo_token" [collapse]="isCollapsed['demo_token']" [isAnimated]="true">
        Please log a ticket on the Business Service Desk as a support request for further assistance.
      </p>
    </div>

    <div class="m-t-20 m-b-10 border" (click)="changeValue('token_index')"
      [attr.aria-expanded]="!isCollapsed['token_index']" aria-controls="token_index">
      <h4 class="m-b-10">
        <a href="javascript:void(0)">
          5. What is a token index and why is my unit rejecting the current one?
        </a>
      </h4>
      <p id="token_index" [collapse]="isCollapsed['token_index']" [isAnimated]="true">
        Tokens for d.light PAYG units have a specific token index sequence. Tokens must thus be entered into the unit in
        the order in which they were generated for the unit to accept the token. The tokens start from index 0. To
        determine which token must be entered into the unit, press 03# on the base unit. The next index required will be
        displayed on the screen which will assist with determining which token index to enter. For further assistance,
        please reach out to your CSM.
      </p>
    </div>
  </div>
</section>

<section class="row">
  <div class="template col-sm">
    <h2>Getting support from d.light </h2>
    <div class="m-t-20 m-b-10 border" (click)="changeValue('support_channel')"
      [attr.aria-expanded]="!isCollapsed['support_channel']" aria-controls="support_channel">
      <h4 class="m-b-10">
        <a href="javascript:void(0)">
          1. Which support channels do I have access to?
        </a>
      </h4>
      <div id="support_channel" [collapse]="isCollapsed['support_channel']" [isAnimated]="true">
        <p>
          There are many support lines put in place to ensure that you receive adequate support and use Atlas to your
          liking. These are in order of lines of support:
        </p>
        <ul>
          <li>
            <p> • Access to Knowledge Base for training manuals</p>
          </li>
          <li>
            <p> • Access to the Business Service Desk team for any general inquiries, bug reporting, service requests or
              feature requests.</p>
          </li>
          <li>
            <p> • Access to a dedicated Customer Success Manager (CSM) to support with other Atlas related queries</p>
          </li>
        </ul>
      </div>
    </div>

    <div class="m-t-20 m-b-10 border" (click)="changeValue('service_desk')"
      [attr.aria-expanded]="!isCollapsed['service_desk']" aria-controls="service_desk">
      <h4 class="m-b-10">
        <a href="javascript:void(0)">
          2. How do I get access to the Business Service Desk or set up new users?
        </a>
      </h4>
      <p id="service_desk" [collapse]="isCollapsed['service_desk']" [isAnimated]="true">
        Please reach out to your organization’s administrator who will then reach out to your Customer Success Manager
        to set up access for you on the BSD.
      </p>
    </div>

    <div class="m-t-20 m-b-10 border" (click)="changeValue('ticket_issue_type')"
      [attr.aria-expanded]="!isCollapsed['ticket_issue_type']" aria-controls="ticket_issue_type">
      <h4 class="m-b-10">
        <a href="javascript:void(0)">
          3. Which issue type should I select when creating a ticket?
        </a>
      </h4>
      <p id="ticket_issue_type" [collapse]="isCollapsed['ticket_issue_type']" [isAnimated]="true">
        Please refer to this SOP guide for further guidance on the ticket types and how to effectively use your Business
        Service Desk.
      </p>
    </div>

    <div class="m-t-20 m-b-10 border" (click)="changeValue('approval')"
      [attr.aria-expanded]="!isCollapsed['approval']" aria-controls="approval">
      <h4 class="m-b-10">
        <a href="javascript:void(0)">
          4. How do I set up approvers from my organization on BSD?
        </a>
      </h4>
      <p id="approval" [collapse]="isCollapsed['approval']" [isAnimated]="true">
        Please reach out to your Customer Success Manager to set up approvers for your organization on the BSD.
      </p>
    </div>
  </div>
</section>