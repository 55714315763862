<h1>Terms & Conditions</h1>

<section class="row">
    <div class="template">
        <div class="m-b-10 border">
            <p id="atlas_instance">
                Please read the following Terms and Conditions (the “<b>Terms</b>”) carefully before accessing or using
                d.light design
                Inc.’s (“<b>d.light</b>” or “<b>we</b>”” or “<b>our</b>”) proprietary cloud-based software platform
                designated “<b>Atlas</b>”. These Terms,
                our Privacy Policy (“<b>Privacy Policy</b>”) and, if applicable, the Distribution Agreement (as
                defined below) govern your use of Atlas.
            </p>
        </div>

        <div class="m-t-20 m-b-10 border">
            <h4 class="m-b-10">
                <a href="javascript:void(0)">
                    1. Description of and Access to Atlas
                </a>
            </h4>
            <p>
                d.light is a global provider of solar lighting technology and product
                solutions and the owner of Atlas. Atlas gives users, d.light staff, distribution partners (and their
                users) and other
                partners of d.light, access to our software solution to provide payment support, technical support,
                product
                tracking, and other customer relationship management services to end customers of d.light’s product
                solutions.
                Subject to the terms and conditions of these Terms (and, if applicable, the Distribution Agreement (as
                defined
                below)), d.light will make Atlas available to you solely for the purposes described in this Section 1.
            </p>
        </div>

        <div class="m-t-20 m-b-10 border">
            <h4 class="m-b-10">
                <a href="javascript:void(0)">
                    2. Privacy Policy
                </a>
            </h4>
            <p>
                Please refer to our Privacy Policy for information on how we collect, use and disclose
                information from our users. You acknowledge and agree that your use of Atlas is subject to our Privacy
                Policy.
            </p>
        </div>

        <div class="m-t-20 m-b-10 border">
            <h4 class="m-b-10">
                <a href="javascript:void(0)">
                    3. Access Conditions and Agreement to Terms
                </a>
            </h4>
            <p>
                Atlas is available to you upon the condition that if you are
                a d.light employee or contractor, you agree to be bound by these Terms, and if you are a third party
                distributor
                or partner, in addition to agreeing to be bound by these Terms, you or the company or entity you
                represent have
                entered into a separate Strategic Partnership and Distribution Agreement (or another similar agreement)
                with
                d.light (“<b>Distribution Agreement</b>”). If you are an employee, contractor or consultant using Atlas
                on behalf of or
                as part of your work for a company or other entity that has entered into a Distribution Agreement with
                d.light
                (a “<b>Distributor</b>”), you represent and warrant that you have the authority to access Atlas and bind
                the Distributor
                to these Terms. In that case, “you” and “your” will refer to the Distributor.
            </p>
        </div>

        <div class="m-t-20 m-b-10 border">
            <h4 class="m-b-10">
                <a href="javascript:void(0)">
                    4. Account
                </a>
            </h4>
            <p>
                In order to use Atlas, you must create an account (“<b>Account</b>”). You agree to provide accurate,
                complete and up-to-date information for your Account and to update as needed to keep it accurate and
                complete. Failure to do so by you may result in suspension or termination of your Account. You will not
                disclose
                your Account password to anyone and you will notify d.light immediately of any unauthorized use of your
                Account. You are solely responsible for all activities that occur under your Account, including but not
                limited to
                updating information on your account (whether or not accurate), whether or not you know about them.
            </p>
        </div>

        <div class="m-t-20 m-b-10 border">
            <h4 class="m-b-10">
                <a href="javascript:void(0)">
                    5. Proprietary Rights; Privacy and Data Protection
                </a>
            </h4>
            <div class="m-l-20">
                <p>
                    <b class="m-b-10"> 5.1 Definitions </b> <br />For purposes of these Terms: (a) “<b>Content</b>”
                    means text, graphics, images, software, audio,
                    video, works of authorship of any kind, and information or other materials that are generated,
                    provided or
                    otherwise made available through Atlas Platform, including Marketing Materials; (b)
                    “<b>Controller</b>” means the
                    natural or legal person, public authority, agency or other body which, alone or jointly with others,
                    determines
                    the purposes and means of the processing of End Customer Data; (c) “<b>End Customer Data</b>” means
                    all data and
                    information related to end customers of the d.light product solutions, including, without
                    limitation, end
                    customer identity and contact information (i.e., Personal Data), payment history, products ordered,
                    products
                    repaired and product usage; (d) “<b>Intellectual Property Rights</b>” means patent rights
                    (including, without
                    limitation, patent applications and disclosures), copyrights, trade secrets, know-how, and any other
                    intellectual
                    property rights recognized in any country or jurisdiction in the world; (e) “<b>Marketing
                        Materials</b>” means
                    marketing and promotion materials related to d.light, its technology and product solutions that are
                    provided or
                    otherwise made available to Distributors through Atlas; (f) “<b>Personal Data</b>” has the meaning
                    assigned to the
                    terms “personal data” or “personal information” under applicable privacy and data protection laws;
                    (g)
                    “<b>Process</b>,” “<b>Processes</b>,” “<b>Processing</b>,” and “<b>Processed</b>” means any
                    operation or set of operations which is
                    performed on data or sets of data, whether or not by automated means, such as collection, recording,
                    organization, structuring, storage, adaptation or alteration, retrieval, consultation, use,
                    disclosure by
                    transmission, dissemination, or otherwise making available, alignment or combination, restriction,
                    erasure, or
                    destruction; and (h) “<b>Security Incident</b>” has the meaning assigned by applicable laws to the
                    terms “security
                    incident,” “security breach,” “personal data breach,” and other similar terms but shall include, at
                    a minimum,
                    any situation where you know, discover, are notified of, or suspect that End Customer Data has been
                    or is likely
                    to have been accessed, disclosed, acquired or used by unauthorized persons, in an unauthorized
                    manner or in
                    violation of these Terms or applicable laws.
                </p>

                <p>
                    <b class="m-b-10"> 5.2 d.light Ownership </b> <br />Subject to the limited rights expressly granted
                    hereunder, and, if applicable, under
                    the Distribution Agreement, d.light reserves all rights, title and interest in and to Atlas (and any
                    enhancements,
                    improvements, or adaptations made to thereto) and the Content, including all Intellectual Property
                    Rights
                    therein. You acknowledge that Atlas and the Content are protected by copyright, trademark, and other
                    laws of
                    the United States and foreign countries and are otherwise proprietary to d.light. You agree not to
                    remove, alter
                    or obscure any copyright, trademark, service mark or other proprietary rights notices incorporated
                    in or
                    accompanying Atlas, or the Content. No rights are granted to you hereunder other than as expressly
                    set forth
                    herein. Subject to the limited rights expressly granted hereunder, and, if applicable, under
                    the Distribution Agreement, d.light reserves all rights, title and interest in and to Atlas (and any
                    enhancements,
                    improvements, or adaptations made to thereto) and the Content, including all Intellectual Property
                    Rights
                    therein. You acknowledge that Atlas and the Content are protected by copyright, trademark, and other
                    laws of
                    the United States and foreign countries and are otherwise proprietary to d.light. You agree not to
                    remove, alter
                    or obscure any copyright, trademark, service mark or other proprietary rights notices incorporated
                    in or
                    accompanying Atlas, or the Content. No rights are granted to you hereunder other than as expressly
                    set forth
                    herein.
                </p>

                <p>
                    <b class="m-b-10"> 5.3 Your Ownership </b> <br />
                    If you are a Distributor, d.light does not claim any ownership rights in any End User
                    Customer Data that you submit or enter into Atlas and nothing in these Terms will be deemed to
                    restrict any
                    rights that you may have to use and exploit such End Customer Data.
                </p>
                <p>
                    <b class="m-b-10"> 5.4 Rights in Content Granted by d.light </b> <br />
                    Subject to your compliance with these Terms and, if applicable, the
                    Distribution Agreement, d.light grants to you a limited, non-exclusive, non-transferable license,
                    with no right to
                    sublicense, to view, use, alter, modify, and display the Content (except for the Marketing
                    Materials, which are
                    subject to the last sentence of this Section 5.4) solely in connection with your permitted use of
                    Atlas and, if
                    applicable, solely for the purposes set forth in the Distribution Agreement, provided that you shall
                    not copy,
                    download, extract, export or otherwise remove the Content from Atlas except as otherwise authorized
                    by d.light
                    via Atlas. If you are a Distributor, your use of the Marketing Materials is governed by the
                    Distribution Agreement.
                </p>
                <p>
                    <b class="m-b-10"> 5.5 Rights in End Customer Data Granted by You </b> <br />
                    If you are a Distributor, by submitting or entering any End
                    Customer Data into Atlas, you hereby grant to d.light a non-exclusive, non-transferable, worldwide,
                    perpetual,
                    royalty-free license, with the right to sublicense, to use, copy, modify, process, create derivative
                    works based
                    upon, and distribute the End Customer Data in connection with operating d.light’s business,
                    including without
                    limitation, operating and providing Atlas to you and other users of the platform.
                </p>
                <p>
                    <b class="m-b-10"> 5.6 Feedback </b> <br />
                    We welcome feedback, comments and suggestions for improvements to Atlas (“Feedback”).
                    As a consideration to your use of Atlas, you hereby assign to us all right, title and interest to
                    any Feedback
                    provided by you, including all Intellectual Property Rights therein.
                </p>
                <div>
                    <p>
                        <b class="m-b-10"> 5.7 Privacy and Data Protection </b> <br />
                        If you are a Distributor, you agree that: (i) to the fullest extent permitted
                        by applicable privacy and data protection laws, you and d.light are independent Controllers and
                        not
                        joint
                        Controllers of End Customer Data and as such independently determine the purposes and the means
                        of
                        the
                        Processing; (ii) subject to these Terms, neither you or d.light is required to retain
                        authorization
                        or instruction
                        from the other party with regard to the Processing of End Customer Data it controls; and (iii)
                        when
                        you Process
                        End Customer Data, including Personal Data, you will:
                        <span class="m-l-20">
                            <p class="m-l-20 m-t-10">
                                <b>(a)</b> Only Process End Customer Data in accordance with the Terms, applicable
                                privacy and data
                                protection laws, the Distribution Agreement and any other instructions we or end
                                customers
                                may present you with;
                            </p>
                            <p class="m-l-20">
                                <b>(b)</b> Present each end customer with a clear privacy notice prior to collecting End
                                Customer
                                Data.
                                The privacy notice shall contain sufficient information for the end customer to
                                understand:
                                (i) d.light is
                                an independent Controller of their Personal Data with a hyperlink to the d.light Privacy
                                Policy; (ii) what
                                of their End Customer Data will be Processed and shared; (iii) the purposes for
                                Processing
                                their End
                                Customer Data; (iv) their Personal Data will be transferred internationally in order to
                                perform the
                                contract and at all times in compliance with applicable privacy and data protection
                                laws;
                                and (v) all other
                                information required by applicable privacy and data protection laws;
                            </p>
                            <p class="m-l-20">
                                <b>(c)</b> Obtain all necessary authorizations and/or lawful bases from end customers
                                for you and
                                d.light
                                to Process End Customer Data;
                            </p>
                            <p class="m-l-20">
                                <b>(d)</b> Comply with all applicable privacy and data protection laws. This includes,
                                but is not
                                limited to:
                                (i) implementing and maintaining appropriate security measures to protect End Customer
                                Data;
                                (ii) the
                                fulfillment of individual rights requests (e.g., access, deletion, etc.); and (iii) any
                                requirements around
                                international transfers of End Customer Data. For transfers of Personal Data originating
                                in
                                the European
                                Economic Area, you and d.light shall use the European Commission Decision C(2004)5271
                                Standard
                                Contractual Clauses for Controllers to Controllers, the terms of which are herein
                                incorporated by
                                reference. The Illustrative Commercial Clauses (Optional) are expressly not included;
                            </p>
                            <p class="m-l-20">
                                <b>(e)</b> Provide prompt written notice to d.light upon becoming aware of a Security
                                Incident and
                                remediate the Security Incident (including at d.light’s instruction), at your own cost.
                                Where required by
                                applicable privacy and data protection laws, you will provide notice of the Security
                                Incident to your end
                                customers; and
                            </p>
                            <p class="m-l-20">
                                <b>(f)</b> Inform d.light of any dispute or claim brought by an End Customer or any
                                regulatory
                                authority
                                concerning the Processing of End Customer Data and cooperate with d.light to resolve the
                                dispute or
                                claim within a reasonable time, at your sole cost.
                            </p>
                        </span>
                    </p>
                </div>

            </div>
        </div>

        <div class="m-t-20 m-b-10 border">
            <h4 class="m-b-10">
                <a href="javascript:void(0)">
                    6. Confidential Information
                </a>
            </h4>
            <p>
                Subject to applicable law or regulation, the parties shall maintain the confidentiality of any
                Confidential
                Information and shall not, without the prior written consent of the disclosing party (“Discloser”),
                disclose
                such confidential information to any third party (excluding affiliates – affiliates means entities
                controlling, controlled by or under common control with d.light). “Confidential Information” means any
                information that is designated as “Confidential” by the Discloser or that the receiving party
                (“Recipient”)
                should reasonably believe to be confidential given the circumstances. Confidential information excludes
                any information that: (i) is known by the Recipient prior to disclosure; (ii) becomes publicly available
                through no fault of the Recipient; (iii) is disclosed to Recipient by a third party with the legal right
                to
                make such disclosure; or (iv) independently developed by Recipient without use or reference to the
                Discloser’s Confidential Information.
            </p>
        </div>

        <div class="m-t-20 m-b-10 border">
            <h4 class="m-b-10">
                <a href="javascript:void(0)">
                    7. General Prohibitions and d.light’s Enforcement Rights
                </a>
            </h4>
            <p class="m-b-10">You agree not to do any of the following:</p>
            <div class="m-l-20">
                <p>
                    <b>(a)</b> Post, upload, publish, submit or transmit any data or information to Atlas that: (i)
                    infringes,
                    misappropriates or violates a third party’s patent, copyright, trademark, trade secret, moral rights
                    or
                    other intellectual property rights, or rights of publicity or privacy; (ii) violates, or encourages
                    any conduct
                    that would violate, any applicable law or regulation or would give rise to civil liability; or (iii)
                    is fraudulent,
                    false, misleading or deceptive; (iv) is defamatory, obscene, pornographic, vulgar or offensive;
                </p>
                <p>
                    <b>(b)</b>Use, display, mirror or frame Atlas or any individual element within Atlas, d.light’s
                    name, any
                    d.light trademark, logo or other proprietary information, or the layout and design of any page or
                    form
                    contained on a page, without d.light’s express written consent;
                </p>
                <p>
                    <b>(c) </b> Access or tamper with d.light’s computer systems, or the technical delivery systems of
                    d.light’s
                    providers;
                </p>
                <p>
                    <b>(d)</b> Attempt to probe, scan or test the vulnerability of any d.light system or network or
                    breach any
                    security or authentication measures;
                </p>
                <p>
                    <b>(e)</b> Avoid, bypass, remove, deactivate, impair, descramble or otherwise circumvent any
                    technological measure implemented by d.light or any of d.light’s providers or any other third party
                    to
                    protect Atlas and Content;
                </p>
                <p>
                    <b>(f)</b> Attempt to access or search Atlas and Content or download Content from Atlas through the
                    use
                    of any engine, software, tool, agent, device or mechanism (including spiders, robots, crawlers, data
                    mining tools or the like) other than the software and/or search agents provided by d.light or other
                    generally available third-party web browsers;
                </p>
                <p>
                    <b>(g)</b> Use any meta tags or other hidden text or metadata utilizing a d.light trademark, logo
                    URL or
                    product name without d.light’s express written consent;
                </p>
                <p>
                    <b>(h)</b> Use Atlas or the Content, or any portion thereof, for the benefit of any third party or
                    in any
                    manner not permitted by these Terms;
                </p>
                <p>
                    <b>(i)</b> Forge any TCP/IP packet header or any part of the header information in any email or
                    newsgroup
                    posting, or in any way use Atlas and the Content to send altered, deceptive or false
                    source-identifying
                    information;
                </p>
                <p>
                    <b>(j)</b> Attempt to decipher, decompile, disassemble or reverse engineer any of the software used
                    to
                    provide Atlas and the Content;
                </p>
                <p>
                    <b>(k)</b> Interfere with, or attempt to interfere with, the access of any user, host or network,
                    including,
                    without limitation, sending a virus, overloading, flooding, spamming, or mail-bombing Atlas;
                </p>
                <p>
                    <b>(l)</b> Impersonate or misrepresent your affiliation with any person or entity;
                </p>
                <p>
                    <b>(m)</b> Violate any applicable law or regulation; or
                </p>
                <p>
                    <b>(n)</b> Encourage or enable any other individual to do any of the foregoing.
                </p>
            </div>
            <p>
                Although we are not obligated to monitor access to or use of Atlas or Content, or to review or edit any
                Content
                or End Customer Data, we have the right to do so for the purpose of operating Atlas, to ensure
                compliance with
                these Terms and, if applicable, the Distribution Agreement, and to comply with applicable law or other
                legal
                requirements. We reserve the right, but are not obligated, to remove or disable access to any Content or
                End
                Customer Data, at any time and without notice.
            </p>
        </div>


        <div class="m-t-20 m-b-10 border">
            <h4 class="m-b-10">
                <a href="javascript:void(0)">
                    8. Termination
                </a>
            </h4>
            <p>
                If you cease to be an employee or contractor of d.light, or if either we or you terminate
                the Distribution Agreement, you will cease to have access to Atlas and the Content. In addition, we may
                terminate your access to Atlas and the Content if you breach these Terms (or, if applicable, the
                Distribution
                Agreement), with notice to you. If you are a Distributor, upon any termination, you may request to
                export your
                End Customer Data within no more than 30 days after termination. Upon any termination, the following
                Sections
                will survive: 5.1, 5.2, 5.5, 9, 11 and 13.
            </p>
        </div>

        <div class="m-t-20 m-b-10 border">
            <h4 class="m-b-10">
                <a href="javascript:void(0)">
                    9. Warranty Disclaimers
                </a>
            </h4>
            <p>
                Atlas and the content are provided “as is,” without warranty of any kind. Without limiting the
                foregoing, d.light explicitly disclaims any implied warranties of merchantability, fitness for a
                particular purpose, quiet enjoyment and non-infringement, and any warranties arising out of course of
                dealing or usage of trade. d.light makes no warranty that atlas will meet your requirements or be
                available on an uninterrupted, secure, or error free basis. d.light makes no warranty regarding the
                quality, accuracy, timeliness, truthfulness, completeness or reliability of any content. In no event
                shall d.light, its shareholders, directors, officers, employees or other representatives be liable for
                general, indirect, consequential, or punitive damages related to your use of atlas and the content.
            </p>
        </div>

        <div class="m-t-20 m-b-10 border">
            <h4 class="m-b-10">
                <a href="javascript:void(0)">
                    10. Indemnity
                </a>
            </h4>
            <p>
                You will indemnify and hold harmless d.light and its officers, directors, employees and
                agents, and any of its successors and assigns from and against any claims, disputes, demands,
                liabilities,
                damages, losses, and costs and expenses, including, without limitation, reasonable legal and accounting
                fees
                arising out of or in any way connected with (i) your misuse of Atlas or the Content, or (ii) your
                violation of these
                Terms, our Privacy Policy and, if applicable, the Distribution Agreement.
            </p>
        </div>

        <div class="m-t-20 m-b-10 border">
            <h4 class="m-b-10">
                <a href="javascript:void(0)">
                    11. Limitation of Liability
                </a>
            </h4>
            <div class="m-l-20">
                <p>
                    <b>(a)</b> NEITHER D.LIGHT NOR ANY OTHER PARTY INVOLVED IN CREATING, PRODUCING, OR DELIVERING
                    ATLAS OR CONTENT WILL BE LIABLE FOR ANY INCIDENTAL, SPECIAL, EXEMPLARY OR CONSEQUENTIAL
                    DAMAGES, OR DAMAGES FOR LOST PROFITS, LOST REVENUES, LOST SAVINGS, LOST BUSINESS
                    OPPORTUNITY, LOSS OF DATA OR GOODWILL, SERVICE INTERRUPTION, COMPUTER DAMAGE OR
                    SYSTEM FAILURE OR THE COST OF SUBSTITUTE SERVICES OF ANY KIND ARISING OUT OF OR IN
                    CONNECTION WITH THESE TERMS OR FROM THE USE OF OR INABILITY TO USE ATLAS OR CONTENT
                    WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR
                    ANY OTHER LEGAL THEORY, AND WHETHER OR NOT D.LIGHT OR ANY OTHER PARTY HAS BEEN
                    INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, EVEN IF A LIMITED REMEDY SET FORTH HEREIN IS
                    FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE. SOME JURISDICTIONS DO NOT ALLOW THE
                    EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, SO THE
                    ABOVE LIMITATION MAY NOT APPLY TO YOU.
                </p>
                <p>
                    <b>(b)</b>IN NO EVENT WILL D.LIGHT’S TOTAL AGGREGATE LIABILITY ARISING OUT OF OR IN CONNECTION
                    WITH THESE TERMS OR FROM THE USE OF OR INABILITY TO USE ATLAS OR CONTENT EXCEED USD
                    10,000.
                </p>
                <p>
                    <b>(c) </b> THE EXCLUSIONS AND LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL
                    ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN D.LIGHT AND YOU.
                </p>
            </div>
        </div>


        <div class="m-t-20 m-b-10 border">
            <h4 class="m-b-10">
                <a href="javascript:void(0)">
                    12. Changes to Terms or Atlas
                </a>
            </h4>
            <p>
                We may update the Terms at any time, in our sole discretion, by posting
                the updated Terms on Atlas or through other communications. If you continue to use the Atlas Platform
                after
                we have posted updated Terms, you are agreeing to be bound by the updated Terms. If you don’t agree to
                be
                bound by the updated Terms, then, you will immediately cease to use Atlas and must provide us with
                written
                notification of the same promptly and in any event, no later than three (3) business days. d.light may
                change
                or discontinue all or any part of Atlas, at any time and without notice, at its sole discretion.
            </p>
        </div>
        <div class="m-t-20 m-b-10 border">
            <h4 class="m-b-10">
                <a href="javascript:void(0)">
                    13. Changes to Terms or Atlas
                </a>
            </h4>
            <div class="m-l-20">
                <p>
                    <b class="m-b-10"> (a) Governing Law and Arbitration </b> <br /> These Terms and any action related thereto will be governed by
                    the Federal Arbitration Act, federal arbitration law, and the laws of the State of California, without
                    regard to its conflict of laws provisions. Any dispute, claim or controversy arising out of or relating to
                    these Terms or the breach, termination, enforcement, interpretation or validity thereof or the use of
                    Atlas, Content and End Customer Data, shall be settled by arbitration in accordance with the UNCITRAL
                    Arbitration Rules then in force. The place of arbitration shall be San Francisco, California, and the
                    language used in the arbitral proceedings shall be English. Judgment on the award rendered may be
                    entered in any court having jurisdiction.
                </p>
                <p>
                    <b class="m-b-10"> (b) Entire Agreement </b> <br /> These Terms (together with the Privacy Policy and Distribution Agreement if
                    applicable) constitute the entire and exclusive understanding and agreement between d.light and you
                    regarding Atlas, the Content and End Customer Data, and these Terms (together with the Privacy Policy,
                    Distribution Agreement if applicable) supersede and replace any and all prior oral or written
                    understandings or agreements between d.light and you regarding Atlas, Content and End Customer
                    Data. If any provision of these Terms is held invalid or unenforceable by a court of competent
                    jurisdiction, that provision will be enforced to the maximum extent permissible and the other provisions
                    of these Terms will remain in full force and effect. You may not assign or transfer these Terms, by
                    operation of law or otherwise, without d.light’s prior written consent. Any attempt by you to assign or
                    transfer these Terms, without such consent, will be null. d.light may freely assign or transfer these Terms
                    without restriction. Subject to the foregoing, these Terms will bind and inure to the benefit of the
                    parties, their successors and permitted assigns.
                </p>
                <p>
                    <b class="m-b-10"> (c) Notices </b> <br /> Any notices or other communications provided by d.light under these Terms, including
                    those regarding modifications to these Terms, will be given by posting to Atlas. If there are any
                    amendments, modifications or changes in notices, you will promptly update the same on Atlas, and we
                    do not accept any claims or liabilities for you not doing so.
                </p>
                <p>
                    <b class="m-b-10"> (d) Waiver of Rights </b> <br /> d.light’s failure to enforce any right or provision of these Terms will not be
                    considered a waiver of such right or provision. The waiver of any such right or provision will be effective
                    only if in writing and signed by a duly authorized representative of d.light. Except as expressly set forth
                    in these Terms, the exercise by either party of any of its remedies under these Terms will be without
                    prejudice to its other remedies under these Terms, the Distribution Agreement (if applicable) or
                    otherwise.
                </p>
            </div>
        </div>

        <div class="m-t-20 m-b-10 border">
            <h4 class="m-b-10">
                <a href="javascript:void(0)">
                    14. Contact Information
                </a>
            </h4>
            <p>
                If you have any questions about these Terms or Atlas, please contact d.light at
                legal&#64;dlight.com.
            </p>
        </div>




    </div>
</section>