import {ChangeDetectorRef, Component, TemplateRef, ViewChild} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import {ToastrService} from "ngx-toastr";
import * as moment from "moment/moment";
import {ProjectService} from "@appServices/core-ticketing/project-service";
import {ProjectListRow} from "@appModels/ticketing/project.list.row";

@Component({
  moduleId: module.id,
  templateUrl: './projects-table.component.html',
  animations: [
    trigger('toggleAnimation', [
      transition(':enter', [style({ opacity: 0, transform: 'scale(0.95)' }), animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' }))]),
      transition(':leave', [animate('75ms', style({ opacity: 0, transform: 'scale(0.95)' }))]),
    ]),
  ],
  styleUrls: ['./projects-table.component.css', '../../../tailwind.css']
})
export class ProjectsTableComponent {
  constructor(
    private toastrService: ToastrService,
    private ref: ChangeDetectorRef,
    private projectService: ProjectService
  ) {
    this.getProjects();
  }

  loadingTable = true;
  dateFormat = moment().format('YYYY-MM-DD hh:mm:ss');
  moment: any = moment;

  getProjects() {
    this.projectService.getProjects(true).subscribe(x=> {
      this.projects = x;
      this.loadingTable = false;
    })
  }

  projects : ProjectListRow[] = [];

}
