import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getWorkOrderRepairStore = createFeatureSelector('workOrderRepair');

export const getWorkOrderRepairEntities = createSelector(
  getWorkOrderRepairStore,
  fromReducers.workOrderRepair.workOrderRepairEntitySelectors.selectAll
);

export const getWorkOrderRepairs = createSelector(getWorkOrderRepairEntities, entities => {
  return Object.values(entities);
});
