import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';

import {CoreCrmService} from '../../core-crm.service';
import { markets } from '@appContainers/directives/access-control/__utils__/markets';
import { __convertOptions__ } from 'app/util/format-name';


@Injectable({providedIn: 'root'})
export class CallDispositionService extends CoreCrmService {

  tenantId = localStorage.getItem('tenant');
  viMarkets =  markets;


  constructor(
    private http: HttpClient,
  ) {
    super(http, 'calldisposition');
  }

  getCallDispositionTypesTelesales(): Observable<any[]> {
    return this.http.get<any[]>(
        `${this.base_url}/types/telesales?tenantId=${localStorage.getItem('tenant')}`,
        {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched getCallDispositionTypesTelesales`)),
      catchError(this.handleError('getCallDispositionTypesTelesales', []))
    );
  }

  getCallDispositionCategoriesByDispositionTypeId(typeId: number): Observable<any[]> {
    return this.http.get<any[]>(
        `${this.base_url}/categories/?callDispositionTypeId=${typeId}`,
        {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched getCallDispositionCategoriesByDispositionTypeId`)),
      catchError(this.handleError('getCallDispositionCategoriesByDispositionTypeId', []))
    );
  }

  getCallDispositionSubCategoriesByDispositionCategoryId(categoryId: number): Observable<any[]> {
    return this.http.get<any[]>(
        `${this.base_url}/subcategories/?callDispositionCategoryId=${categoryId}`,
        {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched getCallDispositionSubCategoriesByDispositionCategoryId`)),
      catchError(this.handleError('getCallDispositionSubCategoriesByDispositionCategoryId', []))
    );
  }

  getCallGroups(): Observable<any[]> {
    return this.http.get<any[]>(`${this.base_url}/new/groups`,  {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched CallGroups`)),
      catchError(this.handleError('getCallGroups', []))
    );
  }

  getLevel1Calls(group: string): Observable<any[]> {
    let params = new HttpParams();
    params = params.append('group', group);

    return this.http.get<any[]>(`${this.base_url}/new/level1`,  {params, headers: this.headers}).pipe(
      tap(_ => this.log(`fetched Level1Calls`)),
      catchError(this.handleError('getLevel1Calls', []))
    );
  }

  getLevel2Calls(group: string, level1: string): Observable<any[]> {
    let params = new HttpParams();
    params = params.append('group', group);
    params = params.append('level1', level1);

    return this.http.get<any[]>(`${this.base_url}/new/level2`,  {params, headers: this.headers}).pipe(
      tap(_ => this.log(`fetched Level2Calls`)),
      catchError(this.handleError('getLevel2Calls', []))
    );
  }

  getLevel3Calls(group: string, level1: string, level2: string, contractId: number): Observable<any[]> {
    let params = new HttpParams();
    params = params.append('group', group);
    params = params.append('level1', level1);
    params = params.append('level2', level2);
    if (contractId !== null) {
      params = params.append('contractId', contractId.toString());
    }

    return this.http.get<any[]>(`${this.base_url}/new/level3`,  {params, headers: this.headers}).pipe(
      tap(_ => this.log(`fetched Level3Calls`)),
      catchError(this.handleError('getLevel3Calls', []))
    );
  }

  filterlevel1CallsByTenant(group:string)
  {
    const arr =  [ "Tala Customer Enquiry","Tala Customer Complaint", "Aftersales Issues", "d.light App","Product return/Repossession", "d.light App","USSD"]
    return this.getLevel1Calls(group).pipe(
      map(response => {
        if(markets.has(this.tenantId)){
          return response
        } else {
          return response.filter(item => !arr.includes(item));
        }
      })
    )
  }

  transformCallGroupsforSurvey(){
    return this.getCallGroups().pipe(
      map(response => response.map((item: any) => __convertOptions__(item))),
      catchError(error => {
        this.log(`Network error occurred: ${error.message}`);
        return of([]); 
      })
    ); 
}

}
