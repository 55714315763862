import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

import {
  GetTicketById,
  GetTicketByIdSuccess,
  GetTicketsByTenantId,
  GetTicketsByTenantIdSuccess,
  TicketActionTypes,
  TicketsError,
  FilterTickets, CloseTicket, CloseTicketSuccess, ReassignTicket, ReassignTicketSuccess
} from '@appStore/actions/core-aftersales/ticket/ticket.actions';
import {TenantService} from '@appServices/core-masterdata/tenant/tenant.service';

import {ToastrService} from 'ngx-toastr';
import {TicketService} from '../../../../services/core-aftersales/ticket/ticket.service';
import {FilterTicketsSuccess} from '../../../actions/core-aftersales/ticket/ticket.actions';

@Injectable()
export class TicketEffects {
  
  loadTickets$ = createEffect(() => this.actions$.pipe(
    ofType(TicketActionTypes.ticketGeTicketByTenantId),
    mergeMap((action: GetTicketsByTenantId) =>
      this.ticketService
        .getTenantTickets(action.payload)
        .pipe(
          map(data => new GetTicketsByTenantIdSuccess(data)),
          catchError(error => of(new TicketsError(error)))
        )
    )
  ));


  
  filterTickets$ = createEffect(() => this.actions$.pipe(
    ofType(TicketActionTypes.ticketFilterTicketsStaff),
    mergeMap((action: FilterTickets) =>
      this.ticketService
        .filterTickets(action.payload)
        .pipe(
          map(customers => new FilterTicketsSuccess(customers)),
          catchError(error => of(new TicketsError(error)))
        )
    )
  ));

  
  ticket$ = createEffect(() => this.actions$.pipe(
    ofType(TicketActionTypes.ticketGetTicketById),
    mergeMap((action: GetTicketById) =>
      this.ticketService
        .getTicket(action.payload)
        .pipe(
          map(ticket => new GetTicketByIdSuccess(ticket)),
          catchError(error => of(new TicketsError(error)))
        )
    )
  ));

  
  closeTicket$ = createEffect(() => this.actions$.pipe(
    ofType(TicketActionTypes.ticketClose),
    mergeMap((action: CloseTicket) =>
      this.ticketService
        .closeTicket(action.payload.id, action.payload.closeComment, action.payload.dispositionId)
        .pipe(
          map(ticket => {
            if (ticket) {
              this.toastr.success('Ticket closed successfully!', 'Successful!');
              return new CloseTicketSuccess(ticket);
            } else {
              return new TicketsError({type: 500, message: 'Internal error'});
            }
          }),
          catchError(error => of(new TicketsError(error)))
        )
    )
  ));

  
  reasignTicket$ = createEffect(() => this.actions$.pipe(
    ofType(TicketActionTypes.reassignTicket),
    mergeMap((action: ReassignTicket) =>
      this.ticketService
        .reassignTicket(action.payload.id, action.payload.repairCentreId)
        .pipe(
          map(ticket => {
            if (ticket) {
              this.toastr.success('Ticket reassigned successfully!', 'Successful!');
              return new ReassignTicketSuccess(ticket);
            } else {
              return new TicketsError({type: 500, message: 'Internal error'});
            }
          }),
          catchError(error => of(new TicketsError(error)))
        )
    )
  ));

  constructor(
    private actions$: Actions,
    private ticketService: TicketService,
    private tenantService: TenantService,
    private toastr: ToastrService
  ) {
  }
}
