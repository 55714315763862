import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getProductWarrantyTypesStore = createFeatureSelector('productWarrantyTypes');

export const getProductWarrantyTypesEntities = createSelector(
  getProductWarrantyTypesStore,
  fromReducers.productWarrantyTypes.productWarrantyTypesEntitySelectors.selectAll
);

export const getProductWarrantyTypes = createSelector(getProductWarrantyTypesEntities, entities => {
  return Object.values(entities);
});

