import {createEntityAdapter, EntityAdapter, EntityState,} from '@ngrx/entity';

import {StockingPoint} from '@appModels/core-inventory/stocking-point/stocking-point';
import {
  StockingPointActions,
  StockingPointActionTypes
} from '@appStore/actions/core-inventory/stocking-point/stocking-point.actions';
import * as _ from 'lodash';

export interface State extends EntityState<StockingPoint> {
  count: number,
  startPage: number,
  numberPerPage: number,
  loaded: boolean;
  loading: boolean;
  error: any;
  selectedStockingPointId: number;
  selectedStockingPoint: StockingPoint;
  searchStockingPoints: StockingPoint[];
  paging: any;
}

export const adapter: EntityAdapter<StockingPoint> = createEntityAdapter<StockingPoint>();

export const initialState: State = adapter.getInitialState({
  count: null,
  startPage: null,
  numberPerPage: null,
  loaded: false,
  loading: false,
  selectedStockingPointId: null,
  selectedStockingPoint: null,
  error: null,
  searchTerm: '',
  searchStockingPoints: null,
  paging: null
});

export function reducer(state = initialState, action: StockingPointActions): State {
  switch (action.type) {
    case StockingPointActionTypes.stockingPointGetStockingPoints:
    case StockingPointActionTypes.stockingPointAddStockingPoint:
    case StockingPointActionTypes.stockingPointAddAdditionalStockingPointInfo:
    case StockingPointActionTypes.stockingPointDeleteStockingPoint:
    case StockingPointActionTypes.stockingPointUpdateStockingPoint:
    case StockingPointActionTypes.stockingPointSearchStockingPoints:
    case StockingPointActionTypes.stockingPointGetStockingPointById:
    case StockingPointActionTypes.stockingPointGetStockingPointsByTenantId:

      return {
        ...state,
        loading: true
      };

    case StockingPointActionTypes.stockingPointGetStockingPointsSuccess:
      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });

    case StockingPointActionTypes.stockingPointGetStockingPointByIdSuccess:
      return {
        ...state,
        selectedStockingPointId: action.payload.id,
        selectedStockingPoint: action.payload,
        loading: false
      };

    case StockingPointActionTypes.stockingPointAddStockingPointSuccess:
      return adapter.addOne(action.payload, {
        ...state,
        selectedStockingPointId: action.payload.id,
        loading: false,
        loaded: true
      });

    case StockingPointActionTypes.stockingPointGetStockingPointsByTenantIdSuccess:
      return adapter.setAll(action.payload.content, {
        ...state,
        loading: false,
        loaded: true,
        paging: _.omit(action.payload, ['content'])
      });

    case StockingPointActionTypes.stockingPointUpdateStockingPointSuccess: {
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state,
          loading: false,
          loaded: true
        }
      );
    }

    case StockingPointActionTypes.stockingPointDeleteStockingPointSuccess: {
      return adapter.removeOne(action.payload.id, {
        ...state,
        loading: false,
        loaded: true
      });
    }

    case StockingPointActionTypes.stockingPointSearchStockingPointsSuccess:
      return {
        ...state,
        searchStockingPoints: action.payload,
        loading: false
      };

    case StockingPointActionTypes.stockingPointSearchStockingPointsReset:
      return {
        ...state,
        searchStockingPoints: null
      };

    case StockingPointActionTypes.stockingPointError:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload
      };

    default:
      return state;
  }
}

export const stockingPointEntitySelectors = adapter.getSelectors();
