import {Injectable} from '@angular/core';
import {CoreAftersalesService} from '@appServices/core-aftersales/core-aftersales.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {RepairCentre} from '@appModels/core-aftersales/repaircentre';
import {User} from '@appModels/core-identity/user/user';

@Injectable({
  providedIn: 'root'
})
export class RepairCentreService extends CoreAftersalesService {

  constructor(private http: HttpClient) {
    super(http, 'repaircentre');
  }

  /** GET repair centre */
  getRepairCentre(id: number): Observable<any> {
    return this.http.get<RepairCentre>(this.base_url + '/' + id,
      {headers: this.headers}).pipe(
      tap(rc => this.log(`fetched repair centre`)),
      catchError(this.handleError('getRepairCentre', []))
    );
  }

  /** GET linked repair centres */
  getLinkedRepairCentre(id: number): Observable<any> {
    return this.http.get<RepairCentre[]>(this.base_url + '/linked/' + id,
      {headers: this.headers}).pipe(
      tap(rc => this.log(`fetched linked repair centre`)),
      catchError(this.handleError('getLinkedRepairCentre', []))
    );
  }

  /** GET parent repair centre */
  getParentRepairCentreStockingPoint(id: number): Observable<any> {
    return this.http.get<RepairCentre>(this.base_url + '/link/' + id,
      {headers: this.headers}).pipe(
      tap(rc => this.log(`fetched repair centre parent stocking point`)),
      catchError(this.handleError('getParentRepairCentreStockingPoint', []))
    );
  }

  /** GET repair centres */
  getRepairCenters(level): Observable<any> {
    const tenantId = localStorage.getItem('tenant');
    return this.http.get<RepairCentre>(`${this.base_url}/level/${level}/tenant/${tenantId}`,
      {headers: this.headers}).pipe(
      tap(rc => this.log(`fetched repair centres`)),
      catchError(this.handleError('getRepairCenters', []))
    );
  }

  /** GET repair centre links from the server */
  getRepairCentreLinks(level: number): Observable<RepairCentre[]> {
    const tenantId = localStorage.getItem('tenant');
    return this.http.get<RepairCentre[]>(`${this.base_url}/level/${level}/tenant/${tenantId}`,
      {headers: this.headers}).pipe(
      tap(rc => this.log(`fetched repair centres`)),
      catchError(this.handleError('getRepairCenters', []))
    );
  }

  /** GET repair centres by level and mapped user */
  getRepairCentersByLevelAndMappedUser(level): Observable<any> {
    const tenantId = localStorage.getItem('tenant');
    const user = JSON.parse(localStorage.getItem('user'));
    return this.http.get<RepairCentre>(`${this.base_url}/level/${level}/tenant/${tenantId}/user/${user.id}`,
      {headers: this.headers}).pipe(
      tap(rc => this.log(`fetched repair centres`)),
      catchError(this.handleError('getRepairCenters', []))
    );
  }

  /** GET repair centres */
  getPartByRepairCenters(partId): Observable<any> {
    const tenantId = localStorage.getItem('tenant');
    return this.http.get<any>(`${this.base_url}/tenant/${tenantId}/parts/${partId}`,
      {headers: this.headers}).pipe(
      tap(rc => this.log(`fetched parts by repair centres`)),
      catchError(this.handleError('getPartsByRepairCenters', []))
    );
  }

  // /** GET repair centre links from the server */
  // getRepairCentreLinks(level: number): Observable<any[]> {
  //   const tenantId = localStorage.getItem('tenant');
  //   return this.http.get<any[]>(this.base_url + '/tenant/' + tenantId + '/level/' + level,
  //     {headers: this.headers}).pipe(
  //     tap(_ => this.log(`fetched repair centre links`)),
  //     catchError(this.handleError('getRepairCentreLinks', []))
  //   );
  // }

  /** GET repair centre links from the server */
  getEnabledRepairCentres(): Observable<RepairCentre[]> {
    const tenantId = localStorage.getItem('tenant');
    return this.http.get<RepairCentre[]>(this.base_url + '/enabled/tenant/' + tenantId,
      {headers: this.headers}).pipe(
      tap(countries => this.log(`fetched enabled repair centres`)),
      catchError(this.handleError('getEnabledRepairCentres', []))
    );
  }

  searchEnabledRepairCentres(search: string): Observable<RepairCentre[]> {
    const tenantId = localStorage.getItem('tenant');
    let params = new HttpParams();
    params = params.append('search', search);

    return this.http.get<RepairCentre[]>(this.base_url + '/enabled/tenant/' + tenantId + '/search',
      {params, headers: this.headers}).pipe(
      tap(countries => this.log(`fetched enabled repair centres`)),
      catchError(this.handleError('getEnabledRepairCentres', []))
    );
  }

  /** GET repair centre links from the server */
  validateName(name: string, salesStaffId?): Observable<boolean> {
    const tenantId = localStorage.getItem('tenant');
    let params = new HttpParams();
    params = params.append('name', name);
    if (salesStaffId) {
      params = params.append('salesStaffId', salesStaffId);
    }
    return this.http.get<any>(this.base_url + '/validatename/tenant/' + tenantId,
      {params: params, headers: this.headers}).pipe(
      tap(countries => this.log(`fetched enabled repair centres`)),
      catchError(this.handleError('getEnabledRepairCentres', []))
    );
  }

  getAllRepairCentres(query?): Observable<any> {
    const tenantId = localStorage.getItem('tenant');
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        if (query[val] !== null && query[val] !== undefined && query[val] !== '') {
          params = params.append(val, query[val]);
        }
      });
    }

    params = params.append('tenantId', tenantId);

    return this.http.get(this.base_url ,
      {params: params, headers: this.headers}).pipe(
      tap(countries => this.log(`fetched repair centres`)),
      catchError(this.handleError('getAllRepairCentres', []))
    );
  }

  getRepairCentreItems(id, query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      Object.keys(query).forEach(val => {
        if (query[val] !== null && query[val] !== undefined && query[val] !== '') {
          params = params.append(val, query[val]);
        }
      });
    }

    return this.http.get(this.base_url + '/received/' + id,
      {params: params, headers: this.headers}).pipe(
      tap(countries => this.log(`fetched repair centre received items`)),
      catchError(this.handleError('getRepairCentreItems', []))
    );
  }

  getRepairCentreFaultyItems(id, query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      Object.keys(query).forEach(val => {
        if (query[val] !== null && query[val] !== undefined && query[val] !== '') {
          params = params.append(val, query[val]);
        }
      });
    }

    return this.http.get(this.base_url + '/faulty/' + id,
      {params: params, headers: this.headers}).pipe(
      tap(countries => this.log(`fetched repair centre faulty items`)),
      catchError(this.handleError('getRepairCentreFaultyItems', []))
    );
  }

  getRepairCentreItemsByTicket(ticketId, id): Observable<any> {
    return this.http.get(this.base_url + '/received/' + id + '/ticket/' + ticketId,
      {headers: this.headers}).pipe(
      tap(countries => this.log(`fetched repair centre items by ticket`)),
      catchError(this.handleError('getRepairCentreItemsByTicket', []))
    );
  }

  getRepairCentreSubunitInventoryCount(id, query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      Object.keys(query).forEach(val => {
        params = params.append(val, query[val]);
      });
    }

    return this.http.get(this.base_url + '/inventory/' + id + '/subunit/count',
      {params, headers: this.headers}).pipe(
      tap(countries => this.log(`fetched repair centre subunit inventory count`)),
      catchError(this.handleError('getRepairCentreSubunitInventoryCount', []))
    );
  }

  getRepairCentrePartInventoryCount(id, query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      Object.keys(query).forEach(val => {
        params = params.append(val, query[val]);
      });
    }
    return this.http.get(this.base_url + '/inventory/' + id + '/part/count',
      {params, headers: this.headers}).pipe(
      tap(countries => this.log(`fetched repair centre part inventory count`)),
      catchError(this.handleError('getRepairCentrePartInventoryCount', []))
    );
  }

  getRepairCentreItemsForEscalation(repairCentreId): Observable<any> {
    return this.http.get(this.base_url + '/escalated/' + repairCentreId,
      {headers: this.headers}).pipe(
      tap(countries => this.log(`fetched repair centre escalation items`)),
      catchError(this.handleError('getRepairCentreItemsForEscalation', []))
    );
  }

  getRepairCentreItemsForReturn(repairCentreId, desinationRepairCentreId): Observable<any> {
    return this.http.get(this.base_url + '/needreturn/' + repairCentreId + '/destination/' + desinationRepairCentreId,
      {headers: this.headers}).pipe(
      tap(countries => this.log(`fetched repair centre return items`)),
      catchError(this.handleError('getRepairCentreItemsForReturn', []))
    );
  }

  /** POST: save repair centre to the server */
  saveRepairCentre(repairCentre, locationImages): Observable<any> {
    const tenantId = localStorage.getItem('tenant');
    repairCentre.tenantId = tenantId;

    const formData = new FormData();

    for (let i = 0; i < locationImages.length; i++) {
      formData.append('files', locationImages[i], locationImages[i]['name']);
    }
    formData.append('data', new Blob([JSON.stringify(repairCentre)], {
      type: 'application/json'
    }));

    this.setHeader(true);

    // return this.http.put(this.base_url, formData, { headers: this.headers }).pipe(
    return this.http.post(this.base_url , formData, {headers: this.headers}).pipe(
      tap((rc: RepairCentre) => this.log(`added repair centre w/ id=${rc.id}`)),
      catchError(this.handleError<RepairCentre>('saveRepairCentre'))
    );
  }

  /** PUT: update repair centre on the server */
  updateRepairCentre(repairCentre, locationImages): Observable<any> {
    const tenantId = localStorage.getItem('tenant');
    repairCentre.tenantId = tenantId;

    const formData = new FormData();

    for (let i = 0; i < locationImages.length; i++) {
      formData.append('files', locationImages[i], locationImages[i]['name']);
    }
    formData.append('data', new Blob([JSON.stringify(repairCentre)], {
      type: 'application/json'
    }));
    if (repairCentre.images != null && repairCentre.images.length > 0) {
      formData.append('images', new Blob([JSON.stringify(repairCentre.images)], {
        type: 'application/json'
      }));
    }

    this.setHeader(true);

    return this.http.post(this.base_url + '/' + repairCentre.id , formData, {headers: this.headers}).pipe(
      tap((rc: RepairCentre) => this.log(`updated repair centre w/ id=${rc.id}`)),
      catchError(this.handleError<RepairCentre>('updateRepairCentre'))
    );
  }

  changeState(id: number, state: string): Observable<RepairCentre> {
    return this.http.patch(this.base_url + '/changeState/' + id + '/' + state, {headers: this.headers}).pipe(
      tap((rc: RepairCentre) => this.log(`change repair centre state w/ id=${rc.id}`)),
      catchError(this.handleError<RepairCentre>('changeRepairCentreState'))
    );
  }

  /** GET repair centre location images server */
  getRepairCentreLocationImage(id: number): Observable<any> {
    return this.http.get<Blob>(this.base_url + '/image/' + id ,
      {headers: this.headers, responseType: 'blob' as 'json'}).pipe(
      tap(countries => this.log(`fetched repair centre location image`)),
      catchError(this.handleError('getRepairCentreLocationImage', []))
    );
  }

  getRepairCentresForLocation(query?): Observable<any> {
    const tenantId = localStorage.getItem('tenant');
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        if (query[val] !== null && query[val] !== undefined && query[val] !== '') {
          params = params.append(val, query[val]);
        }
      });
    }

    params = params.append('tenantId', tenantId);

    return this.http.get(this.base_url + '/getRepairCentresByLocation',
      {params: params, headers: this.headers}).pipe(
      tap(countries => this.log(`fetched repair centres`)),
      catchError(this.handleError('getAllRepairCentres', []))
    );
  }

  getAvailableUsers(repairCentreId: number, email: string) {
    const tenantId = localStorage.getItem('tenant');

    let params = new HttpParams();
    params = params.append('email', email);

    return this.http.get<User[]>(this.base_url + '/availableusers/tenant/' + tenantId, {
      params: params,
      headers: this.headers
    }).pipe(
      tap(countries => this.log(`fetched available users`)),
      catchError(this.handleError('getAvailableUsers', []))
    );
  }

  getByUser() {
    const tenantId = localStorage.getItem('tenant');
    const user = JSON.parse(localStorage.getItem('user'));

    return this.http.get<User[]>(this.base_url + '/user/' + user.id + '/tenant/' + tenantId, {
      headers: this.headers
    }).pipe(
      tap(countries => this.log(`fetched user repair centres`)),
      catchError(this.handleError('getByUser', []))
    );
  }
}
