import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

import {
  AddPage,
  AddPageSuccess,
  PageActionTypes,
  PageError,
  DeletePage,
  DeletePageSuccess,
  EditPage,
  GetPageById,
  GetPageByIdSuccess,
  GetPages,
  GetPagesSuccess,
  SearchPages,
  SearchPagesSuccess,
  UpdatePageSuccess,
} from '@appStore/actions/core-knowledge/page/page.actions';
import {KnowledgeBasePageService} from '@appServices/core-knowledge/knowledge-base-page/knowledge-base-page.service';
import {ToastrService} from 'ngx-toastr';

@Injectable()
export class PageEffects {
  constructor(
    private actions$: Actions,
    private pageService: KnowledgeBasePageService,
    private toastr: ToastrService
  ) { }

  
  loadPages$ = createEffect(() => this.actions$.pipe(
    ofType(PageActionTypes.pageGetPages),
    mergeMap((action: GetPages) =>
      this.pageService
        .getKnowledgeBasePages(action.payload)
        .pipe(
          map(pages => new GetPagesSuccess(pages)),
          catchError(error => of(new PageError(error)))
        )
    )
  ));

  
  getPageById$ = createEffect(() => this.actions$.pipe(
    ofType(PageActionTypes.pageGetPageById),
    mergeMap((action: GetPageById) =>
      this.pageService
        .getKnowledgeBasePage(action.payload)
        .pipe(
          map(page => new GetPageByIdSuccess(page)),
          catchError(error => of(new PageError(error)))
        )
    )
  ));

  // @TODO Migrate to graph after full implementation of the backend
  // @Effect()
  // getPageById$ = this.actions$.pipe(
  //   ofType(PageActionTypes.pageGetPageById),
  //   mergeMap((action: GetPageById) =>
  //     this.pageService
  //       .getPageGraph(action.payload)
  //       .pipe(
  //         map(page => {
  //           console.log(page, 'page')
  //          return  new GetPageByIdSuccess(page.data.page)
  //         }),
  //         catchError(error => of(new PageError(error)))
  //       )
  //   )
  // );

  
  addPage$ = createEffect(() => this.actions$.pipe(
    ofType(PageActionTypes.pageAddPage),
    switchMap((action: AddPage) =>
      this.pageService
        .addKnowledgeBasePage(action.payload)
        .pipe(
          map(
            page => {
              if (page !== undefined) {
                this.toastr.success('Page has been successfully added!', 'Successful!');
                return new AddPageSuccess(page)
              }
              return new PageError({ type: 500, message: 'Internal error' })
            }
          ),
          catchError(error => of(new PageError(error)))
        )
    )
  ));

  // @Effect()
  // updatePage$ = this.actions$.pipe(
  //   ofType(PageActionTypes.pageUpdatePage),
  //   mergeMap((action: AddPage) =>
  //     this.pageService
  //       .updateKnowledgeBase(action.payload)
  //       .pipe(
  //         map(
  //           page => {
  //             if (page !== undefined) {
  //               this.toastr.success('Page has been successfully updated!', 'Successful!');
  //               return new UpdatePageSuccess(action.payload);
  //             }
  //             return new PageError({ type: 500, message: 'Internal error' });
  //           }
  //           ),
  //         catchError(error => of(new PageError(error)))
  //       )
  //   )
  // );

  
  editPage$ = createEffect(() => this.actions$.pipe(
    ofType(PageActionTypes.pageEditPage),
    mergeMap((action: AddPage) =>
      this.pageService
        .editKnowledgeBasePage(action.payload)
        .pipe(
          map(page => new GetPages()),
          catchError(error => of(new PageError(error)))
        )
    )
  ));

  
  deletePage$ = createEffect(() => this.actions$.pipe(
    ofType(PageActionTypes.pageDeletePage),
    mergeMap((action: DeletePage) =>
      this.pageService
        .deleteKnowledgeBasePage(action.payload)
        .pipe(
          map(() => new DeletePageSuccess(action.payload)),
          catchError(error => of(new PageError(error)))
        )
    )
  ));

  
  searchPages$ = createEffect(() => this.actions$.pipe(
    ofType(PageActionTypes.pageSearchPages),
    mergeMap((action: SearchPages) =>
      this.pageService
        .searchKnowledgeBasePages(action.payload)
        .pipe(
          map(pages => new SearchPagesSuccess(pages)),
          catchError(error => of(new PageError(error)))
        )
    )
  ));

  // @Effect()
  // updatePageSuccess$ = this.actions$.pipe(
  //   ofType(PageActionTypes.pageUpdatePageSuccess),
  //   map(page => new fromRouterActions.Go({ path: ['/crm/pages'] }))
  // );
}
