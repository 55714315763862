import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {
  PermissionChannelGroupActions,
  PermissionChannelGroupActionTypes
} from "../../../actions/core-identity/permission-channel-group/permission-channel-group.actions";
import {PermissionChannelGroup} from "../../../../models/core-identity/permission-channel-group/permission-channel-group";

export interface State extends EntityState<PermissionChannelGroup> {
  loaded: boolean;
  loading: boolean;
  error: any;
  selectedPermissionChannelGroupId: number;
  searchPermissionChannelGroups: PermissionChannelGroup[];
}

export const adapter: EntityAdapter<PermissionChannelGroup> = createEntityAdapter<PermissionChannelGroup>();

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
  selectedPermissionChannelGroupId: null,
  error: null,
  searchTerm: '',
  searchPermissionChannelGroups: null
});

export function reducer(state = initialState, action: PermissionChannelGroupActions): State {
  switch (action.type) {
    case PermissionChannelGroupActionTypes.assignPermissionsFromChannelGroupToRole:

      return {
        ...state,
        loading: true
      };

    case PermissionChannelGroupActionTypes.assignPermissionsFromChannelGroupToRoleSuccess:
      return {
        ...state,
        loading: false,
        loaded: false
      };

    case PermissionChannelGroupActionTypes.assignPermissionsFromChannelGroupToRoleError:
      return {
        ...state,
        loading: false,
        loaded: false
      };

    default:
      return state;
  }
}

export const roleEntitySelectors = adapter.getSelectors();
