import { Action } from '@ngrx/store';
import {
  EntityAdapter,
  createEntityAdapter,
  EntityState,
  Update
} from '@ngrx/entity';

import { ProductType } from '@appModels/core-setup/product-type/product-type';
import { ProductTypeActions, ProductTypeActionTypes } from '@appStore/actions/core-masterdata/product-type/product-type.actions';

export interface State extends EntityState<ProductType> {
  loaded: boolean;
  loading: boolean;
  error: any;
  selectedProductTypeId: number;
  searchProductTypes: ProductType[];
}

export const adapter: EntityAdapter<ProductType> = createEntityAdapter<ProductType>();

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
  selectedProductTypeId: null,
  error: null,
  searchTerm: '',
  searchProductTypes: null
});

export function reducer(state = initialState, action: ProductTypeActions): State {
  switch (action.type) {
    case ProductTypeActionTypes.productTypeGetProductTypes:
    case ProductTypeActionTypes.productTypeAddProductType:
    case ProductTypeActionTypes.productTypeDeleteProductType:
    case ProductTypeActionTypes.productTypeUpdateProductType:
    case ProductTypeActionTypes.productTypeSearchProductTypes:
    case ProductTypeActionTypes.productTypeGetProductTypeById:
      return {
        ...state,
        loading: true
      };

    case ProductTypeActionTypes.productTypeGetProductTypesSuccess:
      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });

    case ProductTypeActionTypes.productTypeGetProductTypeByIdSuccess:
      return { ...state, selectedProductTypeId: action.payload.id, loading: false };

    case ProductTypeActionTypes.productTypeAddProductTypeSuccess:
      return adapter.addOne(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });

    case ProductTypeActionTypes.productTypeUpdateProductTypeSuccess: {
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state,
          loading: false,
          loaded: true
        }
      );
    }

    case ProductTypeActionTypes.productTypeDeleteProductTypeSuccess: {
      return adapter.removeOne(action.payload.id, {
        ...state,
        loading: false,
        loaded: true
      });
    }

    case ProductTypeActionTypes.productTypeSearchProductTypesSuccess:
      return {
        ...state,
        searchProductTypes: action.payload,
        loading: false
      };

    case ProductTypeActionTypes.productTypeSearchProductTypesReset:
      return {
        ...state,
        searchProductTypes: null
      };

    case ProductTypeActionTypes.productTypeError:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload
      };

    default:
      return state;
  }
}

export const productTypeEntitySelectors = adapter.getSelectors();
