import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from "@angular/core";


@Injectable({
  providedIn: 'root'
})
export class VersionService {

  private versionSubject$ = new BehaviorSubject<boolean>(false)



  watchVersion(): Observable<any> {
    return this.versionSubject$.asObservable()
  }

  changeVersion()  {
    this.versionSubject$.next(true)
  }
}
