import {ChangeDetectorRef, Component, TemplateRef, ViewChild} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {debounce} from 'lodash';

import {TicketingService} from "@appServices/core-ticketing/customer/ticketing-service";
import {ToastrService} from "ngx-toastr";
import * as moment from "moment/moment";
import {ActivatedRoute, Router} from "@angular/router";
import {CurrentUserDetails} from "@appModels/ticketing/current.user.details";
import {TeamsService} from "@appServices/core-ticketing/teams-service";
import {TicketTypeService} from "@appServices/core-ticketing/ticket-type.service";
import {BasePojo} from "@appModels/ticketing/base-pojo";
import {PermissionResolver} from "@appServices/permission-resolver/permission-resolver.service";
import {RequestTypeService} from "@appServices/core-ticketing/request-type.service";
import {AdminZoneService} from "@appServices/core-masterdata/admin-zone/admin-zone.service";

@Component({
  moduleId: module.id,
  templateUrl: './issues-component.html',
  animations: [
    trigger('toggleAnimation', [
      transition(':enter', [style({ opacity: 0, transform: 'scale(0.95)' }), animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' }))]),
      transition(':leave', [animate('75ms', style({ opacity: 0, transform: 'scale(0.95)' }))]),
    ]),
  ],
  styleUrls: ['./issues-component.css', '../../tailwind.css']
})
export class IssuesComponent {
  constructor(
    private toastrService: ToastrService,
    private modalService: BsModalService,
    private ticketingService: TicketingService,
    private teamsService: TeamsService,
    private ticketTypeService: TicketTypeService,
    private requestTypeService: RequestTypeService,
    private route: ActivatedRoute,
    private router: Router,
    private permissionResolverService: PermissionResolver,
    private countryAdminZoneService: AdminZoneService
  ) {

    this.searchUsers = debounce(this.searchUsers, 500)

    this.getTicketPriorities();
    this.getTicketSources();
    this.getTicketTypes();
    this.getRequestTypes();
    this.getAdminZones();

    this.route.queryParams.subscribe(params => {
      console.log("param change")
      this.ticketFilter.assignedToMe = params['assignedToMe'];
      this.ticketFilter.teamUuid = params['teamUuid'];
      this.ticketFilter.raisedByMe = params['raisedByMe'];
      this.ticketFilter.taggedIn = params['taggedIn'];
      this.ticketFilter.assignedTeamId = params['assignedTeamId'];
      this.ticketFilter.assignedTeamName = params['assignedTeamName'];
      this.ticketFilter.assignedUserId = null;
      this.ticketFilter.assignedUserName = null;

      this.filterTickets();
    });



  }


  moment:any = moment;
  ticketModalReference: BsModalRef;
  viewTicketModalReference: BsModalRef;
  reassignTicketsModalReference: BsModalRef;
  @ViewChild('newTicketModal') public newTicketModal;
  @ViewChild('viewTicketModal') public viewTicketModal;
  @ViewChild('reassignTicketsModal') public reassignTicketsModal;


  loadingTable = true;
  ticketFilter = {
    page: 0,
    size: 10,
    last: false,
    assignedToMe:null,
    ticketPriorityId:0,
    teamUuid:null,
    ticketTypeId:0,
    requestTypeId:0,
    adminZoneId:0,
    raisedByMe:null,
    notAssigned:null,
    assignedUserId:null,
    assignedUserName:null,
    assignedTeamId:null,
    assignedTeamName:null,
    taggedIn:null,
    query:null
  }
  priorities : BasePojo[] = [];
  sources: BasePojo[] = [];
  ticketTypes : BasePojo[] = [];
  requestTypes: BasePojo[] = [];
  adminZones: BasePojo[] = [];


  openNewTicketModal(confirmation: TemplateRef<any>) {
    this.ticketModalReference = this.modalService.show(confirmation, { class: 'custom-window-class' });
  }
  openViewTicketModal(confirmation: TemplateRef<any>, ticketId:number) {
    this.selectedTicketId = ticketId;
    this.viewTicketModalReference = this.modalService.show(confirmation, { class: 'modal-xl' });
  }

  openReassignTicketsModal(confirmation: TemplateRef<any>) {
    this.reassignTicketsModalReference = this.modalService.show(confirmation, { class: 'modal-xl' });
  }

  selectedTicketId : number = null;


  tickets =[];



  getTicketPriorities() {
    this.ticketingService.getPriorities().subscribe(x => {
      this.priorities = x;
      this.priorities = [{id:0,name:'Priority'}].concat(x);
    })
  }

  getTicketSources() {
    this.ticketingService.getSources().subscribe(x => {
      this.sources = x;
    })
  }

  getTicketTypes() {
    this.ticketTypeService.getTicketTypes(true).subscribe(x => {
      this.ticketTypes = [{id:0,name:'All Ticket Types'}].concat(x);

    })
  }

  getRequestTypes() {
    this.requestTypeService.getRequestTypes(true).subscribe(x => {
      this.requestTypes = [{id:0,name:'All Request Types'}].concat(x);

    })
  }

  getAdminZones() {
    this.countryAdminZoneService.getAdminZoneRegion({
      id: +localStorage.getItem('countryId'),
      parentId: 0,
      levelId: 1
    }).subscribe(data => {
      this.adminZones = data.map((v) => {
        return {id: v.id, name: v.countryAdministrativeZoneName};
      });
      let temp : BasePojo[] =  [{id:0,name:'Region'}];
      this.adminZones = temp.concat(this.adminZones);
    })
  }

  changeFilter() {
    this.ticketFilter.page = 0;
    this.ticketFilter.size = 10;
    this.filterTickets()
  }

  filterPage(page:number) {
    this.ticketFilter.page = page;
    this.filterTickets();
  }

  filterTickets() {
    this.loadingTable = true;
    this.selectedAllTickets = false;
    this.selectedTickets = [];
    this.ticketingService.filter(this.ticketFilter).subscribe(x=> {
      this.tickets = x.content;
      this.ticketFilter.last = x.last;
      this.loadingTable = false;
    })
  }


  userSearch = "Assigned To";
  userSearchResults : CurrentUserDetails[] = [];
  loadingUserSearch = false;
  showUserDropdown = false;
  searchUsers() {
    this.userSearchResults = []
    if (this.userSearch == null || this.userSearch.length == 0) {
      this.showUserDropdown = false;
      this.ticketFilter.assignedUserId = null;
      this.ticketFilter.assignedTeamId = null;
      this.ticketFilter.assignedTeamName = null;
      this.ticketFilter.assignedUserName = null;
    }
    if (this.userSearch == null || this.userSearch == undefined || this.userSearch.length < 3) {
      return;
    }

    this.showUserDropdown = true;

    this.userSearchResults = [];

    this.loadingUserSearch = true;
    this.teamsService.queryUsersAndTeams(this.userSearch, true).subscribe( x => {
      this.userSearchResults = x;
      this.loadingUserSearch = false;
    })
  }

  selectUser(user: CurrentUserDetails) {
    this.showUserDropdown = false;
    this.ticketFilter.notAssigned = null;
    this.userSearchResults = [];
    this.userSearch = user.name;
    if (user.type == 'user') {
      this.ticketFilter.assignedTeamId = null;
      this.ticketFilter.assignedTeamName = null;
      this.ticketFilter.assignedUserName = user.name;
      this.ticketFilter.assignedUserId = user.id;
    } else if (user.type == 'team') {
      this.ticketFilter.assignedUserId = null;
      this.ticketFilter.assignedUserName = null;
      this.ticketFilter.assignedTeamId = user.id;
      this.ticketFilter.assignedTeamName = user.name;
    }
    this.ticketFilter.assignedToMe = null;
    this.ticketFilter.taggedIn = null;
    this.ticketFilter.raisedByMe = null;
  }



  openTicketPage(id:any) {
    this.viewTicketModalReference.hide();
    this.router.navigateByUrl(`/ticketing/ticket/${id}`)

  }

  openCustomerPage(encryptedId:any) {
    this.viewTicketModalReference.hide();
    this.router.navigateByUrl(`/crm/customers/${encryptedId}`)
  }

  closeViewModal() {
    this.viewTicketModalReference.hide();
  }


  closeNewTicketModal(event:any) {
    this.ticketModalReference.hide();
  }

  filterUnassignedTickets() {
    this.ticketFilter.notAssigned = true ;
    this.showUserDropdown = false;
    this.userSearch = "Not Assigned";
  }

  can(action) {
    return this.permissionResolverService.setModuleName("Ticketing").can(action);
  }


  selectedAllTickets = false;
  selectedTickets = [];

  now = new Date();
  changeAllTicketsSelection(checked : boolean) {
    if (checked) {
      this.tickets.forEach(x => {x.selected = true;
        if (this.selectedTickets.filter(y => y.id == x.id).length == 0) {
          this.selectedTickets.push(x);
        }
      });
    } else {
      this.selectedTickets = [];
      this.tickets.forEach(x => x.selected = false);
    }
  }
  selectTicket(id:number, selected: boolean) {
    if (selected && this.selectedTickets.filter(x => x.id == id).length == 0) {
      this.selectedTickets.push(this.tickets.filter(x => x.id == id)[0]);
    } else {
      this.selectedTickets = this.selectedTickets.filter(x => x.id != id);
    }

  }

  selectAssignTarget(user: CurrentUserDetails) {
    this.showUserDropdown = false;
    this.userSearchResults = [];
    this.userSearch = user.name;
    this.assignTarget = user;
  }

  assignTarget: CurrentUserDetails = {

  }
  bulkReassign() {

    for (let i = 0; i < this.selectedTickets.length; i++) {
      if (this.selectedTickets[i].warranty == true || this.selectedTickets[i].repossession == true) {
        this.toastrService.error("Ticket " + this.selectedTickets[i].ticketNumber + " is a warranty/repossession ticket type, cannot do bulk reassign on it");
        return;
      }
    }

    let body = {
      tickets:[]
    }
    body.tickets = this.selectedTickets.map(x => {return x.id});
    let params = {
      userId:null,
      teamId:null
    }
    if (this.assignTarget.type == 'user') {
      params.userId = this.assignTarget.id;
    } else {
      params.teamId = this.assignTarget.id;
    }
    console.log(params);
    this.ticketingService.bulkAssign(body, params).subscribe(x => {
      this.toastrService.success("Successfuly Assigned Tickets");
      for (let i = 0; i < this.tickets.length; i++) {
        if (this.tickets[i].selected) {
          this.tickets[i].assignedColorCode = this.assignTarget.colorCode;
          this.tickets[i].assignedFirstName = this.assignTarget.firstName;
          this.tickets[i].assignedLastName = this.assignTarget.lastName;
          this.tickets[i].assignedName = this.assignTarget.name;
          this.tickets[i].assignedInitials = this.assignTarget.initials;
          this.tickets[i].assignedRepairCentreId = this.assignTarget.repairCentreId;
        }
      }
      this.tickets.forEach(x => x.selected = false);

      this.selectedTickets = [];
      this.selectedAllTickets = false;

      this.reassignTicketsModalReference.hide();
    })
  }

}
