import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';

import {User} from '@appModels/core-identity/user/user';
import {UserActions, UserActionTypes} from '@appStore/actions/core-identity/user/user.actions';

export interface State extends EntityState<User> {
  loaded: boolean;
  loading: boolean;
  error: any;
  selectedUserId: number;
  searchUsers: User[];
}

export const adapter: EntityAdapter<User> = createEntityAdapter<User>();

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
  selectedUserId: null,
  error: null,
  searchTerm: '',
  searchUsers: null
});

export function reducer(state = initialState, action: UserActions): State {
  switch (action.type) {
    case UserActionTypes.userGetUsers:
    case UserActionTypes.userGetTenantUsers:
    case UserActionTypes.userAddUser:
    case UserActionTypes.userUploadUser:
    case UserActionTypes.userUploadUserSuccess:
    case UserActionTypes.userDeleteUser:
    case UserActionTypes.userUpdateUser:
    case UserActionTypes.userSearchUsers:
    case UserActionTypes.userGetUserById:
      return {
        ...state,
        loading: true
      };

    case UserActionTypes.userGetUsersSuccess:
      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });

    case UserActionTypes.userGetUserByIdSuccess:
      return { ...state, selectedUserId: action.payload.id, loading: false };

    case UserActionTypes.userAddUserSuccess:
      return adapter.addOne(action.payload, {
        ...state,
        error: null,
        loading: false,
        loaded: true
      });
    case UserActionTypes.userUpdateUserSuccess: {
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state,
          loading: false,
          loaded: true
        }
      );
    }

    case UserActionTypes.userDeleteUserSuccess: {
      return adapter.removeOne(action.payload.id, {
        ...state,
        loading: false,
        loaded: true
      });
    }

    case UserActionTypes.userSearchUsersSuccess:
      return {
        ...state,
        searchUsers: action.payload,
        loading: false
      };

    case UserActionTypes.userSearchUsersReset:
      return {
        ...state,
        searchUsers: null
      };

    case UserActionTypes.userError:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload
      };

    default:
      return state;
  }
}

export const userEntitySelectors = adapter.getSelectors();
