import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {pick} from 'lodash';

import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';

import {ManualPayment} from '@appModels/core-accounting/manual-payment/manual-payment';

import {CoreAccountingService} from '../core-accounting.service';


const pickProps = data => pick(data, [
    'accountNumber',
    'amount',
    'isBulk',
    'paymentDescription',
    'paymentReferenceCode',
    'phoneNumber',
    'tenantId'
    ]);

@Injectable({ providedIn: 'root' })
export class ManualPaymentService extends CoreAccountingService {

  constructor(
    private http: HttpClient,

  ) {
    super(http, 'manualpayment');
  }

  /** GET manualPayments from the server */
  // getManualPayments(): Observable<ManualPayment[]> {
  //   return this.http.get<ManualPayment[]>(this.base_url, { headers: this.headers }).pipe(
  //     tap(manualPayments => this.log(`fetched manualPayments`)),
  //     catchError(this.handleError('getManualPayments', []))
  //   );
  // }

  getManualPayments(query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        params = params.append(val, query[val]);
      });
    }
    return this.http.get<any>(this.base_url + '/tenant/' + localStorage.getItem('tenant'), {
      params,
      headers: this.headers
    }).pipe(
      tap(rates => this.log(`fetched manualPayments`)),
      catchError(this.handleError('getManualPayments', []))
    );
  }

  /** GET manualPayment by id. Return `undefined` when id not found */
  getManualPaymentNo404<Data>(id: number): Observable<ManualPayment> {
    const url = `${this.base_url}/?id=${id}`;
    return this.http.get<ManualPayment[]>(url).pipe(
      map(manualPayments => manualPayments[0]), // returns a {0|1} element array
      tap(h => {
        const outcome = h ? `fetched` : `did not find`;
        this.log(`${outcome} manualPayment id=${id}`);
      }),
      catchError(this.handleError<ManualPayment>(`getManualPayment id=${id}`))
    );
  }

  /** GET manualPayment by id. Will 404 if id not found */
  getManualPayment(id: number): Observable<ManualPayment> {
    const url = `${this.base_url}/id/${id}`;
    return this.http.get<ManualPayment>(url).pipe(
      tap(_ => this.log(`fetched manualPayment id=${id}`)),
      catchError(this.handleError<ManualPayment>(`getManualPayment id=${id}`))
    );
  }

  /** GET manual payment by tenantId. Will 404 if id not found */
  getManualPaymentByTenant(id: number): Observable<ManualPayment[]> {
    const url = `${this.base_url}/tenant/${id}/`;
    return this.http.get<ManualPayment[]>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched manual Payment id=${id}`)),
      catchError(this.handleError<ManualPayment[]>(`getManualPayment id=${id}`))
    );
  }

  /* GET manualPayments whose name contains search term */
  searchManualPayments(term: string): Observable<ManualPayment[]> {
    if (!term.trim()) {
      // if not search term, return empty manualPayment array.
      return of([]);
    }
    return this.http.get<ManualPayment[]>(`api/manualPayments/?name=${term}`).pipe(
      tap(_ => this.log(`found manualPayments matching "${term}"`)),
      catchError(this.handleError<ManualPayment[]>('searchManualPayments', []))
    );
  }

  //////// Save methods //////////

  /** POST: add a new manualPayment to the server */
  addManualPayment(manualPayment): Observable<ManualPayment> {
    return this.http.post(this.base_url + '/', manualPayment, {headers: this.headers}).pipe(
      tap((newManualPayment: ManualPayment) => {
        this.log(`added manualPayment w/ id=${newManualPayment.id}`);
      }),
      catchError(this.handleError<ManualPayment>('addManualPayment'))
    );
  }

  /** POST: add a new manualPayment to the server */
  validateManualPayment(manualPayment): Observable<any> {
    this.setHeader(true);
    return this.http.post(this.base_url + '/validatepreprocessing/tenant/' + localStorage.getItem('tenant'), manualPayment, {headers: this.headers}).pipe(
      tap((newManualPayment: any) => {
        this.log(`validate manualPayment w/ id=${newManualPayment.id}`);
      }),
      catchError(this.handleError<any>('validateManualPayment'))
    );
  }

  uploadManualPayment(manualPayment): Observable<{}> {
    this.setHeader(true);
    return this.http.post<{}>(this.base_url + '/batchupload/tenant/'+ localStorage.getItem('tenant'), manualPayment, { headers: this.headers}).pipe(
      tap((manualPayment) => this.log(`uploaded manualPayments `)),
      catchError(this.handleError<{}>('uploadManualPayment'))
    );
  }

  /** DELETE: delete the manualPayment from the server */
  deleteManualPayment(manualPayment: ManualPayment | number): Observable<ManualPayment> {
    const id = typeof manualPayment === 'number' ? manualPayment : manualPayment.id;
    const url = `${this.base_url}/${id}`;

    return this.http.delete<ManualPayment>(url, { headers: this.headers }).pipe(
      tap(_ => this.log(`deleted manualPayment id=${id}`)),
      catchError(this.handleError<ManualPayment>('deleteManualPayment'))
    );
  }

  /** PUT: update the manualPayment on the server */
  updateManualPayment(manualPayment: ManualPayment): Observable<any> {
    return this.http.put(`${this.base_url}/${manualPayment.id}/`, pickProps(manualPayment), { headers: this.headers }).pipe(
      tap(_ => this.log(`updated manualPayment id=${manualPayment.id}`)),
      catchError(this.handleError<any>('updateManualPayment'))
    );
  }

  /** Patch: update the manualPayment on the server */
  editManualPayment(data): Observable<any> {
    return this.http.patch(`${this.base_url}/${data.id}/${data.param}/${data.value}`, {}, {headers: this.headers}).pipe(
      tap(_ => this.log(`updated manualPayment id=${data.id}`)),
      catchError(this.handleError<any>('update manualPayment'))
    );
  }

}
