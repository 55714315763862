import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getPaymentCategorizationStore = createFeatureSelector('paymentCategorization');

export const getPaymentCategorizationEntities = createSelector(
  getPaymentCategorizationStore,
  fromReducers.paymentCategorization.paymentCategorizationEntitySelectors.selectAll
);

export const getPaymentCategorizations = createSelector(getPaymentCategorizationEntities, entities => {
  return Object.values(entities);
});

export const getTopPaymentCategorizations = createSelector(getPaymentCategorizationEntities, entities => {
  return Object.values(entities).slice(1, 5);
});

export const getPaymentCategorizationsLoaded = createSelector(
  getPaymentCategorizationStore,
  (paymentCategorizationStore: fromReducers.paymentCategorization.State) => paymentCategorizationStore.loaded
);

export const getPaymentCategorizationsLoading = createSelector(
  getPaymentCategorizationStore,
  (paymentCategorizationStore: fromReducers.paymentCategorization.State) => paymentCategorizationStore.loading
);

export const getSelectedPaymentCategorizationId = createSelector(
  getPaymentCategorizationStore,
  (paymentCategorizationStore: fromReducers.paymentCategorization.State) => paymentCategorizationStore.selectedPaymentCategorizationId
);

export const getSearchPaymentCategorizations = createSelector(
  getPaymentCategorizationStore,
  (paymentCategorizationStore: fromReducers.paymentCategorization.State) => paymentCategorizationStore.searchPaymentCategorizations
);

export const getPaymentCategorizationById = createSelector(
  getPaymentCategorizationEntities,
  getSelectedPaymentCategorizationId,
  (entities, id) => entities.find(i => i.id === id)
);

export const getPaymentCategorizationsError = createSelector(
  getPaymentCategorizationStore,
  (paymentCategorizationStore: fromReducers.paymentCategorization.State) => paymentCategorizationStore.error
);
