import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

import {
  AddContract,
  AddContractSuccess,
  ContractActionTypes,
  ContractError,
  DeleteContract,
  DeleteContractSuccess,
  GetContractById,
  GetContractByIdSuccess,
  GetContractPayments,
  GetContractPaymentsSuccess,
  GetContracts,
  GetContractsSuccess,
  SearchContracts,
  SearchContractsSuccess,
  UpdateContractSuccess
} from '@appStore/actions/core-accounting/contract/contract.actions';
import {ContractService} from '@appServices/core-accounting/contract/contract.service';


import * as fromRouterActions from '@appStore/actions/router.actions';
import {ToastrService} from 'ngx-toastr';
import {WalletService} from '@appServices/core-accounting/wallet/wallet.service';
import { Router, ActivatedRoute } from '@angular/router';


@Injectable()
export class ContractEffects {

  constructor(
    private actions$: Actions,
    private contractService: ContractService,
    private walletService: WalletService,
    private toastr: ToastrService,
    private router: Router,
    private routeContract: ActivatedRoute
  ) {
  }

  
  loadContractPayments$ = createEffect(() => this.actions$.pipe(
    ofType(ContractActionTypes.contractGetContractPayments),
    mergeMap((action: GetContractPayments) =>
      this.walletService
        .getContractProdWallets(action.payload)
        .pipe(
          map(payments => new GetContractPaymentsSuccess(payments)),
          catchError(error => of(new ContractError(error)))
        )
    )
  ));

  
  loadContracts$ = createEffect(() => this.actions$.pipe(
    ofType(ContractActionTypes.contractGetContracts),
    mergeMap(() =>
      this.contractService
        .getContracts()
        .pipe(
          map(contracts => new GetContractsSuccess(contracts)),
          catchError(error => of(new ContractError(error)))
        )
    )
  ));
  
  addContract$ = createEffect(() => this.actions$.pipe(
    ofType(ContractActionTypes.contractAddContract),
    switchMap((action: AddContract) =>
      this.contractService
        .addContract(action.payload.contract)
        .pipe(
          map(
            contract => {
              if (contract !== undefined) {
                this.toastr.success(contract['message'], 'Successful!');
                return new AddContractSuccess(contract)
              }
              return new ContractError({ type: 500, message: 'Internal error' })
            }
          ),
          catchError(error =>  {
            return of(new ContractError(error))
          })
        )
    )
  ));

  
  getContractById$ = createEffect(() => this.actions$.pipe(
    ofType(ContractActionTypes.contractGetContractById),
    mergeMap((action: GetContractById) =>
      this.contractService
        .getContract(action.payload)
        .pipe(
          map(contract => new GetContractByIdSuccess(contract)),
          catchError(error => of(new ContractError(error)))
        )
    )
  ));

  
  addAdditionalContractInfo$ = createEffect(() => this.actions$.pipe(
    ofType(ContractActionTypes.contractAddAdditionalContractInfo),
    mergeMap((action: AddContract) =>
      this.contractService
        .addContractAdditionalInfo(action.payload)
        .pipe(
          map(contract => new GetContracts()),
          catchError(error => of(new ContractError(error)))
        )
    )
  ));

  
  updateContract$ = createEffect(() => this.actions$.pipe(
    ofType(ContractActionTypes.contractUpdateContract),
    mergeMap((action: AddContract) =>
      this.contractService
        .updateContract(action.payload)
        .pipe(
          map(contract => new UpdateContractSuccess(action.payload)),
          catchError(error => of(new ContractError(error)))
        )
    )
  ));

  
  deleteContract$ = createEffect(() => this.actions$.pipe(
    ofType(ContractActionTypes.contractDeleteContract),
    mergeMap((action: DeleteContract) =>
      this.contractService
        .deleteContract(action.payload)
        .pipe(
          map(() => new DeleteContractSuccess(action.payload)),
          catchError(error => of(new ContractError(error)))
        )
    )
  ));

  
  searchContracts$ = createEffect(() => this.actions$.pipe(
    ofType(ContractActionTypes.contractSearchContracts),
    mergeMap((action: SearchContracts) =>
      this.contractService
        .searchContracts(action.payload)
        .pipe(
          map(contracts => new SearchContractsSuccess(contracts)),
          catchError(error => of(new ContractError(error)))
        )
    )
  ));

  
  updateContractSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(ContractActionTypes.contractUpdateContractSuccess),
    map(contract => new fromRouterActions.Go({ path: ['/setup/contracts'] }))
  ));
}
