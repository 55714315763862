import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getContractStore = createFeatureSelector('contract');

export const getContractEntities = createSelector(
  getContractStore,
  fromReducers.contract.contractEntitySelectors.selectAll
);

export const getContracts = createSelector(getContractEntities, entities => {
  return Object.values(entities);
});

export const getTopContracts = createSelector(getContractEntities, entities => {
  return Object.values(entities).slice(1, 5);
});

export const getContractsLoaded = createSelector(
  getContractStore,
  (contractStore: fromReducers.contract.State) => contractStore.loaded
);

export const getContractPayments = createSelector(
  getContractStore,
  (contractStore: fromReducers.contract.State) => contractStore.payments.content
);

export const getContractsLoading = createSelector(
  getContractStore,
  (contractStore: fromReducers.contract.State) => contractStore.loading
);

export const getSelectedContractId = createSelector(
  getContractStore,
  (contractStore: fromReducers.contract.State) => contractStore.selectedContractId
);

export const getSearchContracts = createSelector(
  getContractStore,
  (contractStore: fromReducers.contract.State) => contractStore.searchContracts
);

export const getContractById = createSelector(
  getContractEntities,
  getSelectedContractId,
  (entities, id) => entities.find(i => i.id === id)
);

export const getContractsError = createSelector(
  getContractStore,
  (contractStore: fromReducers.contract.State) => contractStore.error
);
