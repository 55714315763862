import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { ProductType } from '@appModels/core-setup/product-type/product-type';
import { User } from '@appModels/core-identity/user/user';

import { CoreMasterDataService } from '../core-masterdata.service';



@Injectable({ providedIn: 'root' })
export class ProductTypeService extends CoreMasterDataService {


  constructor(
    private http: HttpClient,

  ) {
    super(http, 'globalproducttype');
  }

  /** GET productTypes from the server */
  getProductTypes(): Observable<ProductType[]> {
    return this.http.get<ProductType[]>(this.base_url + 's', { headers: this.headers }).pipe(
      tap(productTypes => this.log(`fetched productTypes`)),
      catchError(this.handleError('getProductTypes', []))
    );
  }

  /** GET productType by id. Return `undefined` when id not found */
  getProductTypeNo404<Data>(id: number): Observable<ProductType> {
    const url = `${this.base_url}/?id=${id}`;
    return this.http.get<ProductType[]>(url).pipe(
      map(productTypes => productTypes[0]), // returns a {0|1} element array
      tap(h => {
        const outcome = h ? `fetched` : `did not find`;
        this.log(`${outcome} productType id=${id}`);
      }),
      catchError(this.handleError<ProductType>(`getProductType id=${id}`))
    );
  }

  /** GET productType by id. Will 404 if id not found */
  getProductType(id: number): Observable<ProductType> {
    const url = `${this.base_url}/${id}`;
    return this.http.get<ProductType>(url,{ headers: this.headers }).pipe(
      tap(_ => this.log(`fetched productType id=${id}`)),
      catchError(this.handleError<ProductType>(`getProductType id=${id}`))
    );
  }

    /** GET productType by module. Will 404 if id not found */
  getProductTypeBy(id: number, _module: string): Observable<User[]> {
    const url = `${this.base_url}/${id}/${_module}`;
    return this.http.get<User[]>(url, { headers: this.headers }).pipe(
      tap(_ => this.log(`fetched productType id=${id}`)),
      catchError(this.handleError<User[]>(`getProductType id=${id}`))
    );
  }

  /* GET productTypes whose name contains search term */
  searchProductTypes(term: string): Observable<ProductType[]> {
    if (!term.trim()) {
      // if not search term, return empty productType array.
      return of([]);
    }
    return this.http.get<ProductType[]>(`api/productTypes/?name=${term}`).pipe(
      tap(_ => this.log(`found productTypes matching "${term}"`)),
      catchError(this.handleError<ProductType[]>('searchProductTypes', []))
    );
  }

  //////// Save methods //////////

  /** POST: add a new productType to the server */
  addProductType(productType: ProductType): Observable<ProductType> {
    return this.http.post<ProductType>(this.base_url , productType, { headers: this.headers }).pipe(
      tap((productType: ProductType) => this.log(`added productType w/ id=${productType.id}`)),
      catchError(this.handleError<ProductType>('addProductType'))
    );
  }

  /** DELETE: delete the productType from the server */
  deleteProductType(productType: ProductType | number): Observable<ProductType> {
    const id = typeof productType === 'number' ? productType : productType.id;
    const url = `${this.base_url}/${id}`;

    return this.http.delete<ProductType>(url, { headers: this.headers }).pipe(
      tap(_ => this.log(`deleted productType id=${id}`)),
      catchError(this.handleError<ProductType>('deleteProductType'))
    );
  }

  /** PUT: update the productType on the server */
  updateProductType(productType: ProductType): Observable<any> {
    return this.http.put(`${this.base_url}/${productType.id}`, productType, { headers: this.headers }).pipe(
      tap(_ => this.log(`updated productType id=${productType.id}`)),
      catchError(this.handleError<any>('updateProductType'))
    );
  }

  /** Patch: update the productType on the server */
  editProductType(data): Observable<any> {
    return this.http.patch(`${this.base_url}/${data.id}/${data.param}/${data.value}`, {}, { headers: this.headers }).pipe(
      tap(_ => this.log(`updated productType id=${data.id}`)),
      catchError(this.handleError<any>('updateProductType'))
    );
  }

}
