import { Component, OnInit } from "@angular/core";
import { featureFlagsCRM } from "app/config/feature-flags/feature-flags-crm";

@Component({
  template: `<feature-toggle-provider [features]="featureFlagsCRM">
    <router-outlet></router-outlet>
  </feature-toggle-provider>`,
})
export class CRMComponent implements OnInit {
  featureFlagsCRM = featureFlagsCRM;
  constructor() {}

  ngOnInit() {}
}
