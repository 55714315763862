import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

import {
  AddEligibilityDefinition,
  AddEligibilityDefinitionSuccess,
  DeleteEligibilityDefinition,
  DeleteEligibilityDefinitionSuccess,
  GetEligibilityDefinitionById,
  GetEligibilityDefinitionByIdSuccess,
  GetEligibilityDefinitionByProductId,
  GetEligibilityDefinitionByProductIdSuccess,
  GetEligibilityDefinitions,
  GetEligibilityDefinitionsByTenant,
  GetEligibilityDefinitionsByTenantSuccess,
  GetEligibilityDefinitionsSuccess,
  EligibilityDefinitionActionTypes,
  EligibilityDefinitionError,
  SearchEligibilityDefinitions,
  SearchEligibilityDefinitionsSuccess,
  UpdateEligibilityDefinitionSuccess
} from '@appStore/actions/core-accounting/eligibility-definition/eligibility-definition.actions';
import {EligibilityDefinitionService} from '@appServices/core-accounting/eligibility-definition/eligibility-definition.service';
import {ToastrService} from 'ngx-toastr';
import { Router } from '@angular/router';

@Injectable()
export class EligibilityDefinitionEffects {
  constructor(
    private actions$: Actions, 
    private eligibilityDefinitionService: EligibilityDefinitionService,
    private toastr: ToastrService,
    private router: Router
    ) {}

  
  loadEligibilityDefinitions$ = createEffect(() => this.actions$.pipe(
    ofType(EligibilityDefinitionActionTypes.eligibilityDefinitionGetEligibilityDefinitions),
    mergeMap((action: GetEligibilityDefinitions) =>
      this.eligibilityDefinitionService
        .getEligibilityDefinitions(action.payload)
        .pipe(
          map(eligibilityDefinitions => new GetEligibilityDefinitionsSuccess(eligibilityDefinitions)),
          catchError(error => of(new EligibilityDefinitionError(error)))
        )
    )
  ));

  
  getEligibilityDefinitionById$ = createEffect(() => this.actions$.pipe(
    ofType(EligibilityDefinitionActionTypes.eligibilityDefinitionGetEligibilityDefinitionById),
    mergeMap((action: GetEligibilityDefinitionById) =>
      this.eligibilityDefinitionService
        .getEligibilityDefinition(action.payload)
        .pipe(
          map(eligibilityDefinition => new GetEligibilityDefinitionByIdSuccess(eligibilityDefinition)),
          catchError(error => of(new EligibilityDefinitionError(error)))
        )
    )
  ));

  
  addEligibilityDefinition$ = createEffect(() => this.actions$.pipe(
    ofType(EligibilityDefinitionActionTypes.eligibilityDefinitionAddEligibilityDefinition),
    switchMap((action: AddEligibilityDefinition) =>
      this.eligibilityDefinitionService
        .addEligibilityDefinition(action.payload)
        .pipe(
          map(
            eligibilityDefinition => {
              if (eligibilityDefinition !== undefined) {
                this.toastr.success('Eligibility Definition has been successfully added!', 'Successful!');
                this.router.navigateByUrl(`/setup/eligibility-definition`);
                return new GetEligibilityDefinitions(eligibilityDefinition)
              }
              return new EligibilityDefinitionError({ type: 500, message: 'Internal error' })
            }
            ),
          catchError(error => of(new EligibilityDefinitionError(error)))
        )
    )
  ));

  
  updateEligibilityDefinition$ = createEffect(() => this.actions$.pipe(
    ofType(EligibilityDefinitionActionTypes.eligibilityDefinitionUpdateEligibilityDefinition),
    mergeMap((action: AddEligibilityDefinition) =>
      this.eligibilityDefinitionService
        .updateEligibilityDefinition(action.payload)
        .pipe(
          map(eligibilityDefinition => {
            if (eligibilityDefinition !== undefined) {
              this.toastr.success('Eligibility Definition has been successfully updated!', 'Successful!');
              this.router.navigateByUrl(`/setup/eligibility-definition`);
              return  new UpdateEligibilityDefinitionSuccess(action.payload)
            }
            // this.toastr.error('There was an error updating this eligibilityDefinition', "Unknown error");
            return new EligibilityDefinitionError({ type: 500, message: 'Internal error' })
          }),
          catchError(error => of(new EligibilityDefinitionError(error)))
        )
    )
  ));

  
  deleteEligibilityDefinition$ = createEffect(() => this.actions$.pipe(
    ofType(EligibilityDefinitionActionTypes.eligibilityDefinitionDeleteEligibilityDefinition),
    mergeMap((action: DeleteEligibilityDefinition) =>
      this.eligibilityDefinitionService
        .deleteEligibilityDefinition(action.payload)
        .pipe(
          map(() => new DeleteEligibilityDefinitionSuccess(action.payload)),
          catchError(error => of(new EligibilityDefinitionError(error)))
        )
    )
  ));

  
  searchEligibilityDefinitions$ = createEffect(() => this.actions$.pipe(
    ofType(EligibilityDefinitionActionTypes.eligibilityDefinitionSearchEligibilityDefinitions),
    mergeMap((action: SearchEligibilityDefinitions) =>
      this.eligibilityDefinitionService
        .searchEligibilityDefinitions(action.payload)
        .pipe(
          map(eligibilityDefinitions => new SearchEligibilityDefinitionsSuccess(eligibilityDefinitions)),
          catchError(error => of(new EligibilityDefinitionError(error)))
        )
    )
  ));

  // @Effect()
  // updateEligibilityDefinitionSuccess$ = this.actions$.pipe(
  //   ofType(EligibilityDefinitionActionTypes.eligibilityDefinitionUpdateEligibilityDefinitionSuccess),
  //   map(eligibilityDefinition => new fromRouterActions.Go({ path: ['/setup/eligibility-definitions'] }))
  // );
}
