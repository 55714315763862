import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import TimeZone from "app/util/timezone";

@Component({
  selector: "kyc-image-and-attachments",
  template: `<div *ngIf="image">
      <label class="label_title">{{ "Profile Photo" | translate }}</label>
      <div>
        <img [src]="image" alt="" width="90px" />
      </div>
    </div>
    <div *ngIf="attachmentsExists">
      <div *ngIf="!imageDetailCheck && formNotValid" class="notification error">
        {{ "Please confirm all attachment details" | translate }}
      </div>
      <h4 class="m-t-20 m-b-10">{{ "Attachments Details" | translate }}</h4>
      <div class="box">
        <div class="gallery-image">
          <div class="img-box" *ngFor="let file of attachments; let i = index">
            <img src="{{ file.blob }}" alt="{{ file.fileType }}" />
            <div class="transparent-box">
              <div class="caption-check">
                <input
                  type="checkbox"
                  *ngIf="statusName !== 'BLACKLISTED'"
                  class="m-r-10"
                  [(ngModel)]="file.checked"
                  name="imageDetailCheck"
                />
              </div>
              <a
                href="{{ file.blob }}"
                (click)="openBlobInNewTab(file.blob)"
                style="cursor: pointer"
              >
                <div class="caption">
                  <p class="text-white">{{ file.fileType }}</p>
                  <p class="opacity-low">{{ formatDate(file.createdAt) }}</p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>`,
    encapsulation: ViewEncapsulation.None
})
export class NKycImageAndAttachmentsComponent implements OnInit {
  @Input() attachmentsExists: any;
  @Input() imageDetailCheck: any;
  @Input() formNotValid: any;
  @Input() image: any;
  @Input() attachments: any;
  @Input() statusName: any;

  formatDate = TimeZone.getFormatedTimezoneFromDate;
  constructor() {}

  ngOnInit() {}

  openBlobInNewTab(blob: string) {
    let win = window.open();
    win.document.write('<img src="' + blob + '">');
  }
}
