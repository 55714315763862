import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getTenantOOWRepairConditionsStore = createFeatureSelector('tenantOOwRepairCondition');

export const getTenantOOWRepairConditionsEntities = createSelector(
  getTenantOOWRepairConditionsStore,
  fromReducers.tenantOOwRepairCondition.tenantOowRepairConditionEntitySelectors.selectAll
);

export const getTenantOOWRepairConditions = createSelector(getTenantOOWRepairConditionsEntities, entities => {
  return Object.values(entities);
});

export const getTenantOOwRepairConditionsLoading = createSelector(
  getTenantOOWRepairConditionsStore,
  (tenantoowStore: fromReducers.tenantOOwRepairCondition.State) => tenantoowStore.loading
);

