import {Action} from '@ngrx/store';

import {Wallet} from '@appModels/core-accounting/wallet/wallet';

export enum WalletActionTypes {
  walletGetWallets = '[Wallet] get',
  walletGetWalletsSuccess = '[Wallet] get wallets success',
  walletGetWalletsByTenant = '[Wallet] get by tenant',
  walletGetWalletsByTenantSuccess = '[Wallet] get wallets by tenant success',
  walletAddWallet = '[Wallet] add Wallet',
  walletUploadWallet = '[Wallet] upload Wallet',
  walletUploadWalletSuccess = '[Wallet] upload Wallet success',
  walletAddWalletSuccess = '[Wallet] add wallet success',
  walletDeleteWallet = '[Wallet] delete wallet',
  walletDeleteWalletSuccess = '[Wallet] delete wallet success',
  walletGetWalletById = '[Wallet] get wallet by id',
  walletGetWalletByIdSuccess = '[Wallet] get wallet by id success',
  walletUpdateWallet = '[Wallet] update wallet',
  walletUpdateWalletSuccess = '[Wallet] update wallet success',
  walletSearchWallets = '[Wallet] search wallets',
  walletSearchWalletsSuccess = '[Wallet] search wallets success',
  walletSearchWalletsReset = '[Wallet] search wallets reset',
  walletError = '[Wallet] error'
}

export class GetWallets implements Action {
  readonly type = WalletActionTypes.walletGetWallets;
}

export class GetWalletsSuccess implements Action {
  readonly type = WalletActionTypes.walletGetWalletsSuccess;

  constructor(public payload: Wallet[]) {
  }
}

export class GetWalletsByTenant implements Action {
  readonly type = WalletActionTypes.walletGetWalletsByTenant;
  constructor(public payload?) {
  }
}

export class GetWalletsByTenantSuccess implements Action {
  readonly type = WalletActionTypes.walletGetWalletsByTenantSuccess;

  constructor(public payload: any) {
  }
}

export class AddWallet implements Action {
  readonly type = WalletActionTypes.walletAddWallet;

  constructor(public payload) {
  }
}

export class AddWalletSuccess implements Action {
  readonly type = WalletActionTypes.walletAddWalletSuccess;

  constructor(public payload: Wallet) {
  }
}

export class UploadWallet implements Action {
  readonly type = WalletActionTypes.walletUploadWallet;

  constructor(public payload) {
  }
}

export class UploadWalletSuccess implements Action {
  readonly type = WalletActionTypes.walletUploadWalletSuccess;

  constructor(public payload) {
  }
}

export class GetWalletById implements Action {
  readonly type = WalletActionTypes.walletGetWalletById;

  constructor(public payload: number) {
  }
}

export class GetWalletByIdSuccess implements Action {
  readonly type = WalletActionTypes.walletGetWalletByIdSuccess;

  constructor(public payload: Wallet) {
  }
}

export class UpdateWallet implements Action {
  readonly type = WalletActionTypes.walletUpdateWallet;

  constructor(public payload: Wallet) {
  }
}

export class UpdateWalletSuccess implements Action {
  readonly type = WalletActionTypes.walletUpdateWalletSuccess;

  constructor(public payload: Wallet) {
  }
}

export class DeleteWallet implements Action {
  readonly type = WalletActionTypes.walletDeleteWallet;

  constructor(public payload: Wallet) {
  }
}

export class DeleteWalletSuccess implements Action {
  readonly type = WalletActionTypes.walletDeleteWalletSuccess;

  constructor(public payload: Wallet) {
  }
}

export class SearchWallets implements Action {
  readonly type = WalletActionTypes.walletSearchWallets;

  constructor(public payload: string) {
  }
}

export class SearchWalletsSuccess implements Action {
  readonly type = WalletActionTypes.walletSearchWalletsSuccess;

  constructor(public payload: Wallet[]) {
  }
}

export class SearchWalletsReset implements Action {
  readonly type = WalletActionTypes.walletSearchWalletsReset;
}

export class WalletError implements Action {
  readonly type = WalletActionTypes.walletError;

  constructor(public payload: any) {
  }
}

export type WalletActions =
  | GetWallets
  | GetWalletsSuccess
  | GetWalletsByTenant
  | GetWalletsByTenantSuccess
  | AddWallet
  | AddWalletSuccess
  | UploadWallet
  | UploadWalletSuccess
  | GetWalletById
  | GetWalletByIdSuccess
  | UpdateWallet
  | UpdateWalletSuccess
  | DeleteWallet
  | DeleteWalletSuccess
  | SearchWallets
  | SearchWalletsSuccess
  | SearchWalletsReset
  | WalletError;
