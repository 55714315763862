import {Action} from '@ngrx/store';

import {ConsignmentProductAcceptReceiveStatus} from '@appModels/core-inventory/consignment-product-accept-receive-status/consignment-product-accept-receive-status';

export enum ConsignmentProductAcceptReceiveStatusActionTypes {
  consignmentProductAcceptReceiveStatusGetConsignmentProductAcceptReceiveStatus = '[ConsignmentProductAcceptReceiveStatus] get',
  consignmentProductAcceptReceiveStatusGetConsignmentProductAcceptReceiveStatusSuccess = '[ConsignmentProductAcceptReceiveStatus] get consignmentProductAcceptReceiveStatus success',
  consignmentProductAcceptReceiveStatusAddConsignmentProductAcceptReceiveStatus = '[ConsignmentProductAcceptReceiveStatus] add ConsignmentProductAcceptReceiveStatus',
  consignmentProductAcceptReceiveStatusAddConsignmentProductAcceptReceiveStatusSuccess = '[ConsignmentProductAcceptReceiveStatus] add consignmentProductAcceptReceiveStatus success',
  consignmentProductAcceptReceiveStatusAddAdditionalConsignmentProductAcceptReceiveStatusInfo = '[ConsignmentProductAcceptReceiveStatus] add additional ConsignmentProductAcceptReceiveStatus Info',
  consignmentProductAcceptReceiveStatusAddAdditionalConsignmentProductAcceptReceiveStatusInfoSuccess = '[ConsignmentProductAcceptReceiveStatus] add additional consignmentProductAcceptReceiveStatus Info success',
  consignmentProductAcceptReceiveStatusDeleteConsignmentProductAcceptReceiveStatus = '[ConsignmentProductAcceptReceiveStatus] delete consignmentProductAcceptReceiveStatus',
  consignmentProductAcceptReceiveStatusDeleteConsignmentProductAcceptReceiveStatusSuccess = '[ConsignmentProductAcceptReceiveStatus] delete consignmentProductAcceptReceiveStatus success',
  consignmentProductAcceptReceiveStatusGetConsignmentProductAcceptReceiveStatusById = '[ConsignmentProductAcceptReceiveStatus] get consignmentProductAcceptReceiveStatus by id',
  consignmentProductAcceptReceiveStatusGetConsignmentProductAcceptReceiveStatusByIdSuccess = '[ConsignmentProductAcceptReceiveStatus] get consignmentProductAcceptReceiveStatus by id success',
  consignmentProductAcceptReceiveStatusUpdateConsignmentProductAcceptReceiveStatus = '[ConsignmentProductAcceptReceiveStatus] update consignmentProductAcceptReceiveStatus',
  consignmentProductAcceptReceiveStatusUpdateConsignmentProductAcceptReceiveStatusSuccess = '[ConsignmentProductAcceptReceiveStatus] update consignmentProductAcceptReceiveStatus success',
  consignmentProductAcceptReceiveStatusSearchConsignmentProductAcceptReceiveStatus = '[ConsignmentProductAcceptReceiveStatus] search consignmentProductAcceptReceiveStatus',
  consignmentProductAcceptReceiveStatusSearchConsignmentProductAcceptReceiveStatusSuccess = '[ConsignmentProductAcceptReceiveStatus] search consignmentProductAcceptReceiveStatus success',
  consignmentProductAcceptReceiveStatusSearchConsignmentProductAcceptReceiveStatusReset = '[ConsignmentProductAcceptReceiveStatus] search consignmentProductAcceptReceiveStatus reset',
  consignmentProductAcceptReceiveStatusError = '[ConsignmentProductAcceptReceiveStatus] error'
}

export class GetConsignmentProductAcceptReceiveStatus implements Action {
  readonly type = ConsignmentProductAcceptReceiveStatusActionTypes.consignmentProductAcceptReceiveStatusGetConsignmentProductAcceptReceiveStatus;
}

export class GetConsignmentProductAcceptReceiveStatusSuccess implements Action {
  readonly type = ConsignmentProductAcceptReceiveStatusActionTypes.consignmentProductAcceptReceiveStatusGetConsignmentProductAcceptReceiveStatusSuccess;

  constructor(public payload: ConsignmentProductAcceptReceiveStatus[]) {
  }
}

export class AddConsignmentProductAcceptReceiveStatus implements Action {
  readonly type = ConsignmentProductAcceptReceiveStatusActionTypes.consignmentProductAcceptReceiveStatusAddConsignmentProductAcceptReceiveStatus;

  constructor(public payload: ConsignmentProductAcceptReceiveStatus) {
  }
}

export class AddConsignmentProductAcceptReceiveStatusSuccess implements Action {
  readonly type = ConsignmentProductAcceptReceiveStatusActionTypes.consignmentProductAcceptReceiveStatusAddConsignmentProductAcceptReceiveStatusSuccess;

  constructor(public payload: ConsignmentProductAcceptReceiveStatus) {
  }
}

export class AddAdditionalConsignmentProductAcceptReceiveStatusInfo implements Action {
  readonly type = ConsignmentProductAcceptReceiveStatusActionTypes.consignmentProductAcceptReceiveStatusAddAdditionalConsignmentProductAcceptReceiveStatusInfo;

  constructor(public payload) {
  }
}

export class AddAdditionalConsignmentProductAcceptReceiveStatusInfoSuccess implements Action {
  readonly type = ConsignmentProductAcceptReceiveStatusActionTypes.consignmentProductAcceptReceiveStatusAddAdditionalConsignmentProductAcceptReceiveStatusInfoSuccess;

  constructor(public payload) {
  }
}

export class GetConsignmentProductAcceptReceiveStatusById implements Action {
  readonly type = ConsignmentProductAcceptReceiveStatusActionTypes.consignmentProductAcceptReceiveStatusGetConsignmentProductAcceptReceiveStatusById;

  constructor(public payload: number) {
  }
}

export class GetConsignmentProductAcceptReceiveStatusByIdSuccess implements Action {
  readonly type = ConsignmentProductAcceptReceiveStatusActionTypes.consignmentProductAcceptReceiveStatusGetConsignmentProductAcceptReceiveStatusByIdSuccess;

  constructor(public payload: ConsignmentProductAcceptReceiveStatus) {
  }
}

export class UpdateConsignmentProductAcceptReceiveStatus implements Action {
  readonly type = ConsignmentProductAcceptReceiveStatusActionTypes.consignmentProductAcceptReceiveStatusUpdateConsignmentProductAcceptReceiveStatus;

  constructor(public payload: ConsignmentProductAcceptReceiveStatus) {
  }
}

export class UpdateConsignmentProductAcceptReceiveStatusSuccess implements Action {
  readonly type = ConsignmentProductAcceptReceiveStatusActionTypes.consignmentProductAcceptReceiveStatusUpdateConsignmentProductAcceptReceiveStatusSuccess;

  constructor(public payload: ConsignmentProductAcceptReceiveStatus) {
  }
}

export class DeleteConsignmentProductAcceptReceiveStatus implements Action {
  readonly type = ConsignmentProductAcceptReceiveStatusActionTypes.consignmentProductAcceptReceiveStatusDeleteConsignmentProductAcceptReceiveStatus;

  constructor(public payload: ConsignmentProductAcceptReceiveStatus) {
  }
}

export class DeleteConsignmentProductAcceptReceiveStatusSuccess implements Action {
  readonly type = ConsignmentProductAcceptReceiveStatusActionTypes.consignmentProductAcceptReceiveStatusDeleteConsignmentProductAcceptReceiveStatusSuccess;

  constructor(public payload: ConsignmentProductAcceptReceiveStatus) {
  }
}

export class SearchConsignmentProductAcceptReceiveStatus implements Action {
  readonly type = ConsignmentProductAcceptReceiveStatusActionTypes.consignmentProductAcceptReceiveStatusSearchConsignmentProductAcceptReceiveStatus;

  constructor(public payload: string) {
  }
}

export class SearchConsignmentProductAcceptReceiveStatusSuccess implements Action {
  readonly type = ConsignmentProductAcceptReceiveStatusActionTypes.consignmentProductAcceptReceiveStatusSearchConsignmentProductAcceptReceiveStatusSuccess;

  constructor(public payload: ConsignmentProductAcceptReceiveStatus[]) {
  }
}

export class SearchConsignmentProductAcceptReceiveStatusReset implements Action {
  readonly type = ConsignmentProductAcceptReceiveStatusActionTypes.consignmentProductAcceptReceiveStatusSearchConsignmentProductAcceptReceiveStatusReset;
}

export class ConsignmentProductAcceptReceiveStatusError implements Action {
  readonly type = ConsignmentProductAcceptReceiveStatusActionTypes.consignmentProductAcceptReceiveStatusError;

  constructor(public payload: any) {
  }
}

export type ConsignmentProductAcceptReceiveStatusActions =
  | GetConsignmentProductAcceptReceiveStatus
  | GetConsignmentProductAcceptReceiveStatusSuccess
  | AddConsignmentProductAcceptReceiveStatus
  | AddConsignmentProductAcceptReceiveStatusSuccess
  | AddAdditionalConsignmentProductAcceptReceiveStatusInfo
  | AddAdditionalConsignmentProductAcceptReceiveStatusInfoSuccess
  | GetConsignmentProductAcceptReceiveStatusById
  | GetConsignmentProductAcceptReceiveStatusByIdSuccess
  | UpdateConsignmentProductAcceptReceiveStatus
  | UpdateConsignmentProductAcceptReceiveStatusSuccess
  | DeleteConsignmentProductAcceptReceiveStatus
  | DeleteConsignmentProductAcceptReceiveStatusSuccess
  | SearchConsignmentProductAcceptReceiveStatus
  | SearchConsignmentProductAcceptReceiveStatusSuccess
  | SearchConsignmentProductAcceptReceiveStatusReset
  | ConsignmentProductAcceptReceiveStatusError;
