import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';

import {Contract} from '@appModels/core-accounting/contract/contract';
import {ContractActions, ContractActionTypes} from '@appStore/actions/core-accounting/contract/contract.actions';

export interface State extends EntityState<Contract> {
  loaded: boolean;
  loading: boolean;
  error: any;
  selectedContractId: number;
  searchContracts: Contract[];
  payments: any;
}

export const adapter: EntityAdapter<Contract> = createEntityAdapter<Contract>();

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
  selectedContractId: null,
  error: null,
  searchTerm: '',
  searchContracts: null,
  payments: {
    content: []
  }
});

export function reducer(state = initialState, action: ContractActions): State {
  switch (action.type) {
    case ContractActionTypes.contractGetContracts:
    case ContractActionTypes.contractAddContract:
    case ContractActionTypes.contractAddAdditionalContractInfo:
    case ContractActionTypes.contractDeleteContract:
    case ContractActionTypes.contractUpdateContract:
    case ContractActionTypes.contractSearchContracts:
    case ContractActionTypes.contractGetContractById:
      return {
        ...state,
        loading: true
      };

    case ContractActionTypes.contractGetContractsSuccess:
      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });

    case ContractActionTypes.contractGetContractPaymentsSuccess:
      return {
        ...state,
        payments: action.payload,
        loading: false,
        loaded: true
      };

    case ContractActionTypes.contractGetContractByIdSuccess:
      return { ...state, selectedContractId: action.payload.id, loading: false };

    case ContractActionTypes.contractAddContractSuccess:
      return adapter.addOne(action.payload, {
        ...state,
        selectedContractId: action.payload.id,
        loading: false,
        loaded: true
      });

    case ContractActionTypes.contractUpdateContractSuccess: {
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state,
          loading: false,
          loaded: true
        }
      );
    }

    case ContractActionTypes.contractDeleteContractSuccess: {
      return adapter.removeOne(action.payload.id, {
        ...state,
        loading: false,
        loaded: true
      });
    }

    case ContractActionTypes.contractSearchContractsSuccess:
      return {
        ...state,
        searchContracts: action.payload,
        loading: false
      };

    case ContractActionTypes.contractSearchContractsReset:
      return {
        ...state,
        searchContracts: null
      };

    case ContractActionTypes.contractError:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload
      };

    default:
      return state;
  }
}

export const contractEntitySelectors = adapter.getSelectors();
