import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "kyc-cook-stove-survey-question",
  template: ` <h4 class="m-t-20 m-b-10">{{ "Cookstove Survey Questions" }}</h4>
    <div class="row p-t-20 p-b-20 m-b-20" style="background: #f7f7fc">
      <div *ngFor="let item of cookstoveDetail" class="col-md-6">
        <span
          ><b>{{ item.tenantCustomFieldName | translate }}:</b>
          {{ item.value }}</span
        >
      </div>
    </div>
    <div class="tabletMode">
      <table style="background: #f7f7fc; border-radius: 3px">
        <thead>
          <tr>
            <th>{{ "ACCOUNT" | translate }}</th>
            <th
              *ngIf="
                contractDetails['externalSales'] !== undefined &&
                contractDetails['externalSales'] !== null &&
                contractDetails['externalSales'] == true
              "
            >
              {{ "IMEI" | translate }}
            </th>
            <th>{{ "DEPOSIT" | translate }}</th>
            <th>{{ "DEALER" | translate }}</th>
            <th>{{ "SALES AGENT" | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ accountNumber || contractDetails["accountNumber"] }}</td>
            <td
              *ngIf="
                contractDetails['externalSales'] !== undefined &&
                contractDetails['externalSales'] !== undefined &&
                contractDetails['externalSales'] == true
              "
            >
              {{ contractDetails["imei"] || "--" }}
            </td>
            <td>{{ deposit || "--" }}</td>
            <td>{{ contractDetails["stockingPointDealerCode"] || "--" }}</td>
            <td>{{ contractDetails["salesAgentDealerCode"] || "--" }}</td>
          </tr>
          <tr *ngFor="let accessoryDetail of accessoriesDetails">
            <td>{{ accessoryDetail.accountNumber || "--" }}</td>
            <td>{{ accessoryDetail.deposit || "--" }}</td>
            <td>
              {{
                contractDetails["accessoryStockingPointDealerCode"] ||
                  contractDetails["accessoryStockingPointDealerCode"]
              }}
            </td>
            <td>
              {{ contractDetails["accessorySalesAgentDealerCode"] || "--" }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="form_group_modal">
      <div class="input m-b-40">
        <label class="label_title m-r-20">{{
          "Primary Source of Cooking" | translate
        }}</label>
        <span
          [ngClass]="
            contractDetails['primarySourceOfCooking'] ==
              'Traditional Jiko for Charcoal' ||
            contractDetails['primarySourceOfCooking'] ==
              'Traditional Three Stones Firewood'
              ? 'status active'
              : 'status suspended'
          "
          >{{ contractDetails["primarySourceOfCooking"] || "--" }}</span
        >
      </div>
    </div>

    <div class="form_group_modal">
      <div class="input">
        <label class="label_title">{{
          "Preferred Means of Communication" | translate
        }}</label>
        <select
          name="preferredOption"
          [(ngModel)]="preferredOption"
          (ngModelChange)="preferredOptionChange.emit($event)"
          (change)="changeCommunication.emit()"
        >
          <option value="Phone Call">{{ "Phone Call" | translate }}</option>
          <option value="SMS">{{ "SMS" | translate }}</option>
          <option value="WhatsApp">{{ "WhatsApp" | translate }}</option>
          <option value="Other">{{ "Other" | translate }}</option>
        </select>
      </div>
      <div
        *ngIf="preferredOptionCheck && preferredOption === null"
        class="notification error"
      >
        {{ "Please select preferred means of communication" | translate }}
      </div>

      <div
        *ngIf="
          preferredOption !== 'Phone Call' &&
          preferredOption !== 'SMS' &&
          preferredOption !== 'WhatsApp' &&
          preferredOption !== null
        "
      >
        <div class="input">
          <label class="label_title">{{
            "Other Preferred Means of Communication" | translate
          }}</label>
          <input
            type="text"
            [(ngModel)]="otherPreferredOption"
            (ngModelChange)="otherPreferredOptionChange.emit($event)"
            name="otherPreferredOption"
            placeholder="{{
              'Add Preferred Means of Communication' | translate
            }}"
            (change)="changeCommunication.emit()"
          />
        </div>
        <div
          *ngIf="
            preferredOptionCheck &&
            (preferredCommunicationOption === '' ||
              preferredCommunicationOption === null)
          "
          class="notification error"
        >
          {{ "Please enter preferred means of communication" | translate }}
        </div>
      </div>

      <div class="input">
        <label class="label_title">{{
          "Preferred Time of Contact" | translate
        }}</label>
        <select
          name="preferredOption"
          [(ngModel)]="preferredOptionTime"
          (ngModelChange)="preferredOptionTimeChange.emit($event)"
          (change)="changeTime.emit()"
        >
          <option value="Any Time">{{ "Any Time" | translate }}</option>
          <option value="Before 12:00 pm">
            {{ "Before 12:00 pm" | translate }}
          </option>
          <option value="12:00 pm to 4:00 pm">
            {{ "12:00 pm to 4:00 pm" | translate }}
          </option>
          <option value="4:00 pm to 7:00 pm">
            {{ "4:00 pm to 7:00 pm" | translate }}
          </option>
          <option value="After 7:00 pm">
            {{ "After 7:00 pm" | translate }}
          </option>
        </select>
      </div>
      <div
        *ngIf="preferredTimeCheck && preferredOptionTime === null"
        class="notification error"
      >
        {{ "Please select preferred time of contact" | translate }}
      </div>
    </div>`,
    encapsulation: ViewEncapsulation.None
})
export class CookStoveSurveyQuestionComponent implements OnInit {
  @Input() preferredTimeCheck: any;
  @Input() preferredOptionCheck: any;
  @Input() preferredCommunicationOption: any;
  @Input() cookstoveDetail: any;
  @Input() contractDetails: any;
  @Input() accountNumber: any;
  @Input() deposit: any;
  @Input() accessoriesDetails: any;

  @Input() preferredOptionTime: any;
  @Output() preferredOptionTimeChange = new EventEmitter<any>();

  @Input() otherPreferredOption: any;
  @Output() otherPreferredOptionChange = new EventEmitter<any>();

  @Input() preferredOption: any;
  @Output() preferredOptionChange = new EventEmitter<any>();

  @Output() changeTime = new EventEmitter<any>();
  @Output() changeCommunication = new EventEmitter<any>();
  @Input() kycComponentModeEnum : any
  @Input() componentMode : any

  constructor() {}

  ngOnInit() {}
}
