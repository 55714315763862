import { EmailVerificationCheckService } from './services/email-verification/email-verification-check.service';
import { ToastrService } from 'ngx-toastr';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { setTheme } from 'ngx-bootstrap/utils';
import { Router, NavigationEnd } from '@angular/router';
import { GoogleAnalyticsService } from '@appServices/google-analytics.service';
import { TranslateService } from '@ngx-translate/core';
import { ResourceLoader } from '@angular/compiler';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import { VersionService } from '@appServices/version/version';
import { VersionCheckService } from '@appServices/version/version-check.service';
import { UserService } from '@appServices/core-auth/user/user.service';

import * as fromReducer from '@appStore/reducers';
import * as fromSelectors from '@appStore/selectors';

import { Store, select } from '@ngrx/store';
import { map } from 'rxjs/operators';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [GoogleAnalyticsService, TranslateService]
})
export class AppComponent implements OnInit{
  title = 'Atlas V4';
  modalRef: BsModalRef;
  modalReference: BsModalRef;
  @ViewChild('confirmation') public emailConfirmation
  @ViewChild('template') public versionModal


  config = {
    animated: true,
    keyboard: true,
    ignoreBackdropClick: true
  };

  code = null;

  user = JSON.parse(localStorage.getItem('user'))
  invalidCode: boolean;

  constructor(
    private router: Router,
    private googleAnalyticsService: GoogleAnalyticsService,
    public translate: TranslateService,
    private modalService: BsModalService,
    private versionService: VersionService,
    private versionCheckService: VersionCheckService,
    private userService: UserService,
    private toastrService: ToastrService,
    private emailVerificationCheckService: EmailVerificationCheckService,
    private store: Store<fromReducer.auth.State>
  ) {
    setTheme('bs4');

    this.router.events.subscribe(event => {
      this
      .googleAnalyticsService
      .eventEmitter("Page", "Load Complete", "", "", 10);
      // if (event instanceof NavigationEnd) {
      //   (<any>window).ga('set', 'page', event.urlAfterRedirects);
      //   (<any>window).ga('send', 'pageview');
      // }
    });
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.config);
  }

  openModalEmail(confirmation: TemplateRef<any>) {
    this.modalReference = this.modalService.show(confirmation, this.config);
  }

  reloadWindow(){
    window.location.reload();
  }

  decline(){
    this.userService.verifyEmailActivation({ skip: true }).subscribe(
      data => {
        this.toastrService.success('Email verification request cancelled.', 'Successful')
        this.getUserDetails()
      }
    )
  }

  verify(){
    this.invalidCode = false;
    if(this.code !== null && this.code !== ''){
      this.userService.verifyEmailActivation({ code: this.code }).subscribe(
        data => {
          this.toastrService.success('Email verified successfully.', 'Successful')
          console.log(data, 'data response');
          this.getUserDetails()
          this.modalReference.hide();
        },
        err => {
          console.log(err, 'err')
        }
      )
    } else {
      this.invalidCode = true;
    }

  }

  sendVerificationCode(){
      this.userService.emailActivation().subscribe(
        data => {
          this.user.emailVerificationCodeGenerated = true
          localStorage.setItem('user',  JSON.stringify(this.user))
          this.getUserDetails()
        },
        err => { console.log(err, 'err')},
        () => console.log('error is here')
      )
  }

  getUserDetails(){
    this.userService.get(`id/${this.user?.id}`).subscribe(
      data => {
        this.user.emailVerificationComplete = data.emailVerificationComplete
        this.user.emailVerified = data.emailVerified
        localStorage.setItem('user',  JSON.stringify(this.user))
      }
    )
  }

  ngOnInit(){
    this.emailVerificationCheckService.watchStatus().subscribe(
      data => {
        if(data){
          this.openModalEmail(this.emailConfirmation)
        }
      }
    )

    // this.versionService.watchVersion().subscribe((current) => {
    //   if(current){
    //     this.openModal(this.versionModal)
    //   }
    // })

    // this.versionCheckService.initVersionCheck('/assets/js/version.json')

    // this.versionCheckService.watchVersion().subscribe((current) => {
    //   if(current){
    //     this.openModal(this.versionModal)
    //   }
    // })

  }
}
