import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';

import {SalesStaffDefinition} from '@appModels/core-fieldforce/sales-staff-definition/sales-staff-definition';

import {CoreFieldforceService} from "@appServices/core-fieldforce/core-fieldforce.service";


@Injectable({providedIn: 'root'})
export class SalesStaffDefinitionService extends CoreFieldforceService {


  constructor(
    private http: HttpClient,
  ) {
    super(http, 'salesstaffdefinition');
  }

  getSalesStaffDefinitions(): Observable<SalesStaffDefinition[]> {
    return this.http.get<SalesStaffDefinition[]>(this.base_url + 'searchsssSalesStaff', {headers: this.headers}).pipe(
      tap(salesStaffDefinitions => this.log(`fetched sales Staff Definitions`)),
      catchError(this.handleError('getSalesStaffDefinitions', []))
    );
  }

  getStockingPointNo404<Data>(id: number): Observable<SalesStaffDefinition> {
    const url = `${this.base_url}/?id=${id}`;
    return this.http.get<SalesStaffDefinition[]>(url).pipe(
      map(stockingPoints => stockingPoints[0]), // returns a {0|1} element array
      tap(h => {
        const outcome = h ? `fetched` : `did not find`;
        this.log(`${outcome} stockingPoint id=${id}`);
      }),
      catchError(this.handleError<SalesStaffDefinition>(`getStockingPoint id=${id}`))
    );
  }

  /** GET sales staff definitions by id. Will 404 if id not found */
  getSalesStaffDefinition(id: number): Observable<SalesStaffDefinition> {
    const url = `${this.base_url}/${id}`;
    return this.http.get<SalesStaffDefinition>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched salesStaffDefinition id=${id}`)),
      catchError(this.handleError<SalesStaffDefinition>(`getSalesStaffDefinition id=${id}`))
    );
  }

  /** GET sales staff definitions Roles Ids by id. Will 404 if id not found */
  getSalesStaffDefinitionRolesIds(id: number): Observable<Array<Number>> {
    const url = `${this.base_url}/${id}/roles`;
    return this.http.get<Array<Number>>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched salesStaffDefinitionRoles for id=${id}`)),
      catchError(this.handleError<Array<Number>>(`getSalesStaffDefinitionRolesIds id=${id}`))
    );
  }

  /* GET sales staff definitions whose name contains search term */
  searchSalesStaffDefinition(term: string): Observable<SalesStaffDefinition[]> {
    if (!term.trim()) {
      // if not search term, return empty stockingPoint array.
      return of([]);
    }
    return this.http.get<SalesStaffDefinition[]>(`api/v1/searchSalesStaffDefinition/?searchString=${term}`).pipe(
      tap(_ => this.log(`found salesStaffDefinition matching "${term}"`)),
      catchError(this.handleError<SalesStaffDefinition[]>('salesStaffDefinition', []))
    );
  }

  //////// Save methods //////////

  /** POST: add a new sales staff definition to the server */
  addSalesStaffDefinition(salesStaffDefinition: SalesStaffDefinition): Observable<SalesStaffDefinition> {
    return this.http.post<SalesStaffDefinition>(this.base_url, salesStaffDefinition, {headers: this.headers}).pipe(
      tap((salesStaffDefinition: SalesStaffDefinition) => this.log(`added salesStaffDefinition w/ id=${salesStaffDefinition.id}`)),
      catchError(this.handleError<SalesStaffDefinition>('addSalesStaffDefinition'))
    );
  }

  /** POST: add roles for sales staff definition to the server */
  addRolesToSalesStaffDefinition(salesStaffDefinitionId: number, rolesIdList: Array<number>): Observable<{}> {
    return this.http.post<Array<number>>(`${this.base_url}/${salesStaffDefinitionId}/roles`, rolesIdList, {headers: this.headers}).pipe(
      tap(_ => this.log(`added roles for salestaff definition`)),
      catchError(this.handleError('addRolesToSalesStaffDefinition', []))
    );
  }

  /** POST: add a new sales staff definition to the server */
  addSalesStaffDefinitionAttributes(stockingPoint): Observable<{}> {
    return this.http.post(`${this.base_url}/additionalInfo/${stockingPoint.id}`, stockingPoint.value, {headers: this.headers}).pipe(
      tap((res) => this.log(`added stockingPoint  info `)),
      catchError(this.handleError('addInventoryProduct'))
    );
  }

  /** DELETE: delete the sales staff Definition from the server */
  deleteSalesStaffDefinition(salesStaffDefinition: SalesStaffDefinition | number): Observable<SalesStaffDefinition> {
    const id = typeof salesStaffDefinition === 'number' ? salesStaffDefinition : salesStaffDefinition.id;
    const url = `${this.base_url}/salesStaffDefinitions/${id}`;

    return this.http.delete<SalesStaffDefinition>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`deleted salesStaffDefinition id=${id}`)),
      catchError(this.handleError<SalesStaffDefinition>('deleteSalesStaffDefinition'))
    );
  }

  /** PUT: update the stockingPoint on the server */
  updateSalesStaffDefinition(salesStaffDefinition: SalesStaffDefinition): Observable<any> {
    return this.http.put(`${this.base_url}/update/${salesStaffDefinition.id}`, salesStaffDefinition, {headers: this.headers}).pipe(
      tap(_ => this.log(`updated stockingPoint id=${salesStaffDefinition.id}`)),
      catchError(this.handleError<any>('updateStockingPoint'))
    );
  }

  associateSalesStaffDefinitionToRole(salesStaffDefinitionId: number, roleId: number): Observable<SalesStaffDefinition> {
    return this.http.patch(`${this.base_url}/${salesStaffDefinitionId}/role/${roleId}`, '', {headers: this.headers}).pipe(
      tap((salesStaffDefinition: SalesStaffDefinition) => this.log(`updated salesStaffDefinition w/ id=${salesStaffDefinition.id}`)),
      catchError(this.handleError<SalesStaffDefinition>('addSalesStaffDefinition'))
    );
  }

}
