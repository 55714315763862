
<div class="flex flex-row justify-start my-8 mx-4">
  <div class="flex flex-row   gap-3 justify-start">
    <div class="text-xl text-black-dark-light">
      Home
    </div>
    <div class="text-xl font-black text-black-dark-light">
      /
    </div>
    <div class="text-xl text-black-dark-light  cursor-pointer hover:text-dlight ease-in-out duration-300" routerLink="/ticketing/tickettype">
      Ticket Types
    </div>
    <div class="text-xl font-black text-black-dark-light">
      /
    </div>
    <div class="text-xl text-black-dark-light text-orange-600">
      New Ticket Type
    </div>
  </div>

</div>

<div class="mt-3" *ngIf="mainLoading">
  <div class="flex flex-row justify-center items-center w-full  gap-4">
    <span class="m-auto  inline-block h-14 w-14 animate-spin rounded-full border-8 border-[#f1f2f3] border-l-dlight align-middle"></span>
  </div>
</div>


<ng-container *ngIf="!mainLoading">
  <div class="w-2/3 m-auto">
    <div style="border-radius:20px" class="bg-white box-shadow-pop p-4">
      <div class="font-semibold text-lg">General Details</div>

      <form>
        <div class="mt-6">
          <label class="label_title">Name
            <span *ngIf="ticketType.name == null || ticketType.name.length < 3" class="text-red-500">*</span>
            <span *ngIf="ticketType.name != null && ticketType.name.length >= 3" class="text-green-500">*</span>
          </label>
          <input class=" form-input" placeholder="Enter a name"  [(ngModel)]="ticketType.name" name="name"/>
        </div>

        <div class="mt-3">
          <label class="label_title">Description</label>
          <textarea class="form-input form-input-focus" rows="2" [(ngModel)]="ticketType.description" name="description"></textarea>
        </div>
      </form>

      <div class="flex flex row w-full justify-center mt-3">
        <button type="button" class="btn btn-dlight" (click)="createTicketType()">Create Ticket Type</button>
      </div>
    </div>
  </div>

</ng-container>
