
<div class="flex flex-row justify-start my-8 mx-4">
  <div class="flex flex-row   gap-3 justify-start">
    <div class="text-xl text-black-dark-light">
      Home
    </div>
    <div class="text-xl font-black text-black-dark-light">
      /
    </div>
    <div class="text-xl text-black-dark-light  cursor-pointer hover:text-dlight ease-in-out duration-300" routerLink="/ticketing/ticketstatuses">
      Ticket Statuses
    </div>
    <div class="text-xl font-black text-black-dark-light">
      /
    </div>
    <div class="text-xl text-black-dark-light text-orange-600">
      New Ticket Status
    </div>
  </div>

</div>

<div class="mt-3" *ngIf="mainLoading">
  <div class="flex flex-row justify-center items-center w-full  gap-4">
    <span class="m-auto  inline-block h-14 w-14 animate-spin rounded-full border-8 border-[#f1f2f3] border-l-dlight align-middle"></span>
  </div>
</div>


<ng-container *ngIf="!mainLoading">
  <div class="w-1/3 m-auto">
    <div style="border-radius:20px" class="bg-white box-shadow-pop p-4">
      <div class="font-semibold text-lg">General Details</div>

      <div class="gap-3 mt-6">
        <div >
          <label class="label_title">Name
            <span *ngIf="ticketStatus.name == null || ticketStatus.name.length < 3" class="text-red-500">*</span>
            <span *ngIf="ticketStatus.name != null && ticketStatus.name.length >= 3" class="text-green-500">*</span>
          </label>
          <input class=" form-input" placeholder="Enter a name"  [(ngModel)]="ticketStatus.name" name="name"/>
        </div>

        <div class="mt-3 flex flex-row justify-start gap-3">
          <label class="label_title">Background Color
            <span *ngIf="ticketStatus.colorCode == null || ticketStatus.colorCode.length < 3" class="text-red-500">*</span>
            <span *ngIf="ticketStatus.colorCode != null && ticketStatus.colorCode.length >= 3" class="text-green-500">*</span>
          </label>
          <input  type="color" class="color-picker"  [(ngModel)]="ticketStatus.colorCode" name="colorCode"/>
        </div>

        <div class="mt-3 flex flex-row justify-start gap-3">
          <label class="label_title">Text Color
            <span *ngIf="ticketStatus.textColorCode == null || ticketStatus.textColorCode.length < 3" class="text-red-500">*</span>
            <span *ngIf="ticketStatus.textColorCode != null && ticketStatus.textColorCode.length >= 3" class="text-green-500">*</span>
          </label>
          <input type="color" class="color-picker" [(ngModel)]="ticketStatus.textColorCode" name="textColorCode"/>
        </div>

        <div class="col-span-1 m-auto text-black mt-3">
          <span class="badge bg-warning rounded-full text-white" [ngStyle]="{'background-color': ticketStatus.colorCode, 'color':ticketStatus.textColorCode}">{{ticketStatus.name}}</span>
        </div>
      </div>



    </div>

    <div class="flex flex row w-full justify-center mt-6">
      <button type="button" class="btn btn-dlight" (click)="createTicketStatus()">Create Ticket Status</button>
    </div>
  </div>

</ng-container>
