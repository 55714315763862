<div class="loading" [hidden]="!loading">{{'Loading' | translate}}&#8230;</div>
<div class="app_table_layout" >

  <div class="datagrid" >
     <div class="app_header_content">
        <div class="app_header_left m-r-5">
            <div class="app_table_select w-70">
              <form action="">
                  <select class="select_input" [(ngModel)]="perPage" name="perPage" (change)="setPage({offset: 0})">
                      <option value="" disabled>{{'Per Page' | translate}}</option>
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
              </form>
            </div>
          </div>
        <div class="app_header_left m-r-10">
          <div class="app_table_select">
            <form action="">
              <select class="select_input" [(ngModel)]="category" name="category" (change)="setPage({offset: 0})">
                <option value="" disabled>{{'Category' | translate}}</option>
                <option value="">All</option>
                <option value="Diamond">Diamond</option>
                <option value="Platinum">Platinum</option>
                <option value="Gold">Gold</option>
                <option value="Silver">Silver</option>
                <option value="Bronze">Bronze</option>
                <option value="Copper">Copper</option>
                <option value="Ivory">Ivory</option>
                <option value="New">New</option>
              </select>
            </form>
          </div>
        </div>

        <div class="app_header_left m-r-10">
          <div class="app_table_select w-200">
            <form action="">
              <select class="select_input" [(ngModel)]="payment_progress" name="payment_progress" (change)="setPage({offset: 0})">
                <option value="" disabled>{{'Payment Progress' | translate}}</option>
                <option value="">{{'All' | translate}}</option>
                <option value="0-10">0-10 {{'Days Late' | translate}}</option>
                <option value="11-30">11-30 {{'Days Late' | translate}}</option>
                <option value="31">31+ {{'Days Late' | translate}}</option>
                <option value="0">{{'First Payment Default' | translate}} </option>
                <option value="2">{{'Second Payment Default' | translate}}</option>
                <option value="credit">{{'Units with Credit' | translate}}</option>
              </select>
            </form>
          </div>
        </div>

        <div class="app_header_left m-r-10">
          <div class="app_table_search w-200">
            <form action="">
              <input [(ngModel)]="ptpDateFrom" name="ptpDateFrom" type="text" onfocus="(this.type='date')" class="search_input"
                     placeholder="{{'PTP Date From' | translate}}" (change)="setPage({offset: 0})">
            </form>
          </div>
        </div>
        <div class="app_header_left m-r-10">
          <div class="app_table_search w-200">
            <form action="">
              <input [(ngModel)]="ptpDateTo" name="ptpDateTo" type="text" onfocus="(this.type='date')" class="search_input"
                     placeholder="{{'PTP Date To' | translate}}" (change)="setPage({offset: 0})">
            </form>
          </div>
        </div>
    </div>
    <div class="app_header_content">
      <div class="app_header_left m-r-5">
        <div class="app_table_search w-180 customer_input">
          <input [(ngModel)]="query" [placeholder]="'Search for Customers'" class="search_input m-r-15"
                   name="query" type="text" (change)="searchCustomer($event)">
        </div>
      </div>
      <div class="app_header_left m-r-5">
        <div class="app_table_select w-180 selector_input">
          <select class="select_input" [(ngModel)]="searchType"
                    type="submit" name="searchType" value="Search"
                    (change)="searchCustomer($event)">
              <option value="">{{'All' | translate}}</option>
              <option value="customerName">{{'Name' | translate}}</option>
              <option value="accountNumber">{{'Account Number' | translate}}</option>
              <option value="phoneNumber">{{'Phone Number' | translate}}</option>
            </select>
        </div>
      </div>
      <div class="app_header_right m-t-10">
        <div class="last_update_sticker">
          <span class="last_update_sticker_label">{{ lastLoaded}}</span>
            <span (click)="reloadTable()" class="last_update_sticker_refresh fa fa-refresh m-l-10"> </span>
        </div>
    </div>
    </div>

    <div class="isDesktop" >
      <ngx-datatable
      #table
      (page)="setPage($event)"
      [columnMode]="'force'"
      [count]="sepTable.totalElements"
      [externalPaging]="true"
      [footerHeight]="50"
      [headerHeight]="50"
      [limit]="sepTable.size"
      [offset]="sepTable.number"
      [rowHeight]="46"
      [rows]="sepTableContent"
      [sortType]="'multi'"
      class="material expandable selection-cell"
      class="tableClassName"
      id="customer_kyc"
      >

       <!-- Row Detail Template -->
       <ngx-datatable-row-detail [rowHeight]="200" #myDetailRow (toggle)="onDetailToggle($event)">
        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
          <div style="padding-left:35px;">
          <div class="rows m-t-15">
            <div class="col-md-4">
              <p><b>{{'Product Name' | translate}}: </b>{{row.productName || '--'}}</p>
              <p><b>{{'Rate Name' | translate}}: </b>{{row.rateName || '--'}}</p>
              <p><b>{{'Daily Rate' | translate}}: </b>{{row.rate || '--'}} {{row.rateInterval}}</p>
              <p><b>{{'Customer Balance' | translate}}:</b> {{row.balance || '0'}} {{currency}} </p>
              <p><b>{{'Minimum Monthly Payment' | translate}}:</b> {{row.monthlyMinimumPayment || '0'}} {{'Days' | translate}} </p>
            </div>
            <div class="col-md-4">
              <p><b>{{'Catch Up Days' | translate}}: </b>{{row.catchUpDays || '0'}} {{'Days' | translate}}</p>
              <p><b>{{'Catch Up Amount' | translate}}: </b>{{row.catchUpAmount || '0'}} {{currency}}</p>
              <p><b>{{'Total Days Withheld' | translate}}: </b>{{row.totalDaysWitheld || '0'}} {{'Days' | translate}}</p>
              <p><b>{{'Token Expiry' | translate}}: </b>{{row.daysUntilExpiry || '0'}} {{'Days' | translate}}</p>
              <p><b>{{'Total Days Behind' | translate}}:</b> {{row.accumulativeDaysBehind || '0'}} {{'Days' | translate}}</p>
            </div>
            <div class="col-md-4">
              <p><b>{{'Current Days Behind' | translate}}:</b> {{row.daysBehind || '0'}} {{'Days' | translate}} </p>
              <p><b>{{'Days Paid' | translate}}:</b> {{row.daysPaid || '0'}} {{'Days' | translate}} </p>
              <p><b>{{'Promise to Pay Date' | translate}}: </b>{{row.ptpDate || '--'}}</p>
              <p><b>{{'Alternative Phone Number' | translate}}:</b> <a class="m-l-10" href="tel:{{row.secondaryNumber}}">{{row.secondaryNumber}}</a></p>
              <p><b>{{'Category' | translate}}:</b> <span class="status active m-l-10">{{row.category || 'NONE'}}</span> </p>
            </div>
          </div>
          </div>
        </ng-template>
      </ngx-datatable-row-detail>

      <ngx-datatable-column  name="{{'ACCOUNT NUMBER' | translate}}" [width]="200">
        <ng-template
        ngx-datatable-cell-template
        let-rowIndex="rowIndex"
        let-value="value"
        let-row="row"
        let-expanded="expanded"
        >
        <a
           href="javascript:void(0)"
           [class.datatable-icon-right]="!expanded"
           [class.datatable-icon-down]="expanded"
           title="Expand/Collapse Row"
           (click)="toggleExpandRow(row, table)"
         >
         </a>
        {{row.accountNumber | slice:0:09}}

        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column  name="{{'CATEGORY' | translate}}">
      <ng-template
      ngx-datatable-cell-template
      let-rowIndex="rowIndex"
      let-value="value"
      let-row="row"
      >
      <span class="status active">
        {{row.category || 'NONE'}}
      </span>
      </ng-template>
    </ngx-datatable-column>

     <ngx-datatable-column  name="{{'NAME' | translate}}" [width]="200">
        <ng-template
        ngx-datatable-cell-template
        let-rowIndex="rowIndex"
        let-value="value"
        let-row="row"
        >
        <div style="word-break:break-all;">
          <a class="font-weight-bold clickable_a" routerLink="/crm/customers/{{row.id}}" >
            {{row.customerName | slice:0:15}}
          </a>
        </div>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column  name="{{'TELEPHONE' | translate}}" [width]="150">
          <ng-template
          ngx-datatable-cell-template
          let-rowIndex="rowIndex"
          let-value="value"
          let-row="row"
          >
          <a href="tel:{{row.phoneNumber}}">{{row.phoneNumber || "--"}}</a>

          </ng-template>
      </ngx-datatable-column>


      <ngx-datatable-column  name="DAILY RATE" [width]="150">
          <ng-template
          ngx-datatable-cell-template
          let-rowIndex="rowIndex"
          let-value="value"
          let-row="row"
          >
          {{row.rateName || '--'}}
          </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column  name="CURRENT DAYS BEHIND" [width]="150">
          <ng-template
          ngx-datatable-cell-template
          let-rowIndex="rowIndex"
          let-value="value"
          let-row="row"
          >
          <span class="status suspended">
            {{row.daysBehind || '0'}} Days
          </span>
          </ng-template>
      </ngx-datatable-column>




    </ngx-datatable>
    </div>

    <div class="isMobile">
      <ngx-datatable
      #table1
      (page)="setPage($event)"
      [columnMode]="'force'"
      [count]="sepTable.totalElements"
      [externalPaging]="true"
      [footerHeight]="50"
      [headerHeight]="50"
      [limit]="sepTable.size"
      [offset]="sepTable.number"
      [rowHeight]="46"
      [rows]="sepTableContent"
      [sortType]="'multi'"
      class="material expandable selection-cell"
      class="tableClassName"
      id="customer_kyc1"
      >

       <!-- Row Detail Template -->
       <ngx-datatable-row-detail [rowHeight]="430" #myDetailRow (toggle)="onDetailToggle($event)">
        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
          <div style="padding-left:35px;">
          <div class="rows m-t-15">
            <div class="col-md-4">
              <p class="m-t-5"><b>{{'Category' | translate}}: </b><span class="status active">{{row.category || 'NONE'}}</span> </p>
              <p class="m-t-5"><b>{{'Customer Name' | translate}}: </b>{{row.customerName | slice:0:15}} </p>
              <p class="m-t-5"><b>{{'Secondary Phone Number' | translate}}:</b> <a class="m-l-10" href="tel:{{row.secondaryNumber}}">{{row.secondaryNumber}}</a></p>
              <p class="m-t-5"><b>{{'Product Name' | translate}}: </b>{{row.productName || '--'}}</p>
              <p class="m-t-5"><b>{{'Rate Name' | translate}}: </b>{{row.rateName || '--'}}</p>
              <p class="m-t-5"><b>{{'Daily Rate' | translate}}: </b>{{row.rate || '--'}} {{row.rateInterval}}</p>
              <p class="m-t-5"><b>{{'Customer Balance' | translate}}:</b> {{row.balance || '0'}} {{currency}} </p>
              <p class="m-t-5"><b>{{'Minimum Monthly Payment' | translate}}:</b> {{row.monthlyMinimumPayment || '0'}} {{'Days' | translate}} </p>
              <p class="m-t-5"><b>{{'Catch Up Days' | translate}}: </b>{{row.catchUpDays || '0'}} {{'Days' | translate}}</p>
              <p class="m-t-5"><b>{{'Catch Up Amount' | translate}}: </b>{{row.catchUpAmount || '0'}} {{currency}}</p>
              <p class="m-t-5"><b>{{'Total Days Withheld' | translate}}: </b>{{row.totalDaysWitheld || '0'}} {{'Days' | translate}}</p>
              <p class="m-t-5"><b>{{'Promise to Pay Date' | translate}}: </b>{{row.ptpDate || '--'}}</p>
              <p class="m-t-5"><b>{{'Token Expiry' | translate}}: </b>{{row.daysUntilExpiry || '0'}} {{'Days' | translate}}</p>
              <p class="m-t-5"><b>{{'Days Paid' | translate}}:</b> {{row.daysPaid || '0'}} {{'Days' | translate}} </p>
              <p class="m-t-5"><b>{{'Total Days Behind' | translate}}:</b> {{row.accumulativeDaysBehind || '0'}} {{'Days' | translate}} </p>
            </div>
          </div>
          </div>
        </ng-template>
      </ngx-datatable-row-detail>

      <ngx-datatable-column  name="{{'ACCOUNT NO' | translate}}">
        <ng-template
        ngx-datatable-cell-template
        let-rowIndex="rowIndex"
        let-value="value"
        let-row="row"
        let-expanded="expanded"
        >
        <a
           href="javascript:void(0)"
           [class.datatable-icon-right]="!expanded"
           [class.datatable-icon-down]="expanded"
           title="Expand/Collapse Row"
           (click)="toggleExpandRow(row, table1)"
         >
         </a>
        {{row.accountNumber | slice:0:09}}

        </ng-template>
    </ngx-datatable-column>


      <ngx-datatable-column  name="{{'TELEPHONE' | translate}}">
          <ng-template
          ngx-datatable-cell-template
          let-rowIndex="rowIndex"
          let-value="value"
          let-row="row"
          >
          <a href="tel:{{row.phoneNumber}}">{{row.phoneNumber || "--"}}</a>

          </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column  name="CURRENT DAYS BEHIND" [width]="150">
        <ng-template
        ngx-datatable-cell-template
        let-rowIndex="rowIndex"
        let-value="value"
        let-row="row"
        >
        <span class="status suspended">
          {{row.daysBehind || '0'}} {{'Days' | translate}}
        </span>
        </ng-template>
    </ngx-datatable-column>


    </ngx-datatable>
    </div>
  </div>
</div>
