import { Action } from '@ngrx/store';

import { TenantCustomField } from '@appModels/core-setup/tenant-custom-field/tenant-custom-field';

export enum TenantCustomFieldActionTypes {
  tenantCustomFieldGetTenantCustomFields = '[TenantCustomField] get',
  tenantCustomFieldGetTenantCustomFieldsSuccess = '[TenantCustomField] get tenantCustomFields success',
  tenantCustomFieldAddTenantCustomField = '[TenantCustomField] add TenantCustomField',
  tenantCustomFieldAddTenantCustomFieldAdmin = '[TenantCustomField] add TenantCustomField Admin',
  tenantCustomFieldAddTenantCustomFieldSuccess = '[TenantCustomField] add tenantCustomField success',
  tenantCustomFieldDeleteTenantCustomField = '[TenantCustomField] delete tenantCustomField',
  tenantCustomFieldDeleteTenantCustomFieldSuccess = '[TenantCustomField] delete tenantCustomField success',
  tenantCustomFieldGetTenantCustomFieldById = '[TenantCustomField] get tenantCustomField by id',
  tenantCustomFieldGetTenantCustomFieldByIdSuccess = '[TenantCustomField] get tenantCustomField by id success',
  tenantCustomFieldUpdateTenantCustomField = '[TenantCustomField] update tenantCustomField',
  tenantCustomFieldUpdateTenantCustomFieldSuccess = '[TenantCustomField] update tenantCustomField success',
  tenantCustomFieldEditTenantCustomField = '[TenantCustomField] edit tenantCustomField',
  tenantCustomFieldEditTenantCustomFieldSuccess = '[TenantCustomField] edit tenantCustomField success',
  tenantCustomFieldSearchTenantCustomFields = '[TenantCustomField] search tenantCustomFields',
  tenantCustomFieldSearchTenantCustomFieldsSuccess = '[TenantCustomField] search tenantCustomFields success',
  tenantCustomFieldSearchTenantCustomFieldsReset = '[TenantCustomField] search tenantCustomFields reset',
  tenantCustomFieldError = '[TenantCustomField] error'
}

export class GetTenantCustomFields implements Action {
  readonly type = TenantCustomFieldActionTypes.tenantCustomFieldGetTenantCustomFields;
}

export class GetTenantCustomFieldsSuccess implements Action {
  readonly type = TenantCustomFieldActionTypes.tenantCustomFieldGetTenantCustomFieldsSuccess;
  constructor(public payload: TenantCustomField[]) {}
}

export class AddTenantCustomField implements Action {
  readonly type = TenantCustomFieldActionTypes.tenantCustomFieldAddTenantCustomField;
  constructor(public payload: TenantCustomField) {}
}

export class AddTenantCustomFieldAdmin implements Action {
  readonly type = TenantCustomFieldActionTypes.tenantCustomFieldAddTenantCustomFieldAdmin;
  constructor(public payload) {}
}

export class AddTenantCustomFieldSuccess implements Action {
  readonly type = TenantCustomFieldActionTypes.tenantCustomFieldAddTenantCustomFieldSuccess;
  constructor(public payload: TenantCustomField) {}
}

export class GetTenantCustomFieldById implements Action {
  readonly type = TenantCustomFieldActionTypes.tenantCustomFieldGetTenantCustomFieldById;
  constructor(public payload: number) {}
}

export class GetTenantCustomFieldByIdSuccess implements Action {
  readonly type = TenantCustomFieldActionTypes.tenantCustomFieldGetTenantCustomFieldByIdSuccess;
  constructor(public payload: TenantCustomField) {}
}

export class UpdateTenantCustomField implements Action {
  readonly type = TenantCustomFieldActionTypes.tenantCustomFieldUpdateTenantCustomField;
  constructor(public payload: TenantCustomField) {}
}

export class UpdateTenantCustomFieldSuccess implements Action {
  readonly type = TenantCustomFieldActionTypes.tenantCustomFieldUpdateTenantCustomFieldSuccess;
  constructor(public payload: TenantCustomField) {}
}

export class EditTenantCustomField implements Action {
  readonly type = TenantCustomFieldActionTypes.tenantCustomFieldEditTenantCustomField;
  constructor(public payload) {}
}

export class EditTenantCustomFieldSuccess implements Action {
  readonly type = TenantCustomFieldActionTypes.tenantCustomFieldEditTenantCustomFieldSuccess;
  constructor(public payload) {}
}

export class DeleteTenantCustomField implements Action {
  readonly type = TenantCustomFieldActionTypes.tenantCustomFieldDeleteTenantCustomField;
  constructor(public payload: TenantCustomField) {}
}

export class DeleteTenantCustomFieldSuccess implements Action {
  readonly type = TenantCustomFieldActionTypes.tenantCustomFieldDeleteTenantCustomFieldSuccess;
  constructor(public payload: TenantCustomField) {}
}

export class SearchTenantCustomFields implements Action {
  readonly type = TenantCustomFieldActionTypes.tenantCustomFieldSearchTenantCustomFields;
  constructor(public payload: string) {}
}

export class SearchTenantCustomFieldsSuccess implements Action {
  readonly type = TenantCustomFieldActionTypes.tenantCustomFieldSearchTenantCustomFieldsSuccess;
  constructor(public payload: TenantCustomField[]) {}
}

export class SearchTenantCustomFieldsReset implements Action {
  readonly type = TenantCustomFieldActionTypes.tenantCustomFieldSearchTenantCustomFieldsReset;
}

export class TenantCustomFieldError implements Action {
  readonly type = TenantCustomFieldActionTypes.tenantCustomFieldError;
  constructor(public payload: any) {}
}

export type TenantCustomFieldActions =
  | GetTenantCustomFields
  | GetTenantCustomFieldsSuccess
  | AddTenantCustomField
  | AddTenantCustomFieldAdmin
  | AddTenantCustomFieldSuccess
  | GetTenantCustomFieldById
  | GetTenantCustomFieldByIdSuccess
  | UpdateTenantCustomField
  | UpdateTenantCustomFieldSuccess
  | EditTenantCustomField
  | EditTenantCustomFieldSuccess
  | DeleteTenantCustomField
  | DeleteTenantCustomFieldSuccess
  | SearchTenantCustomFields
  | SearchTenantCustomFieldsSuccess
  | SearchTenantCustomFieldsReset
  | TenantCustomFieldError;
