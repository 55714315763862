
<div class="flex flex-row justify-start my-8 mx-4">
  <div class="flex flex-row   gap-3 justify-start">
    <div class="text-xl text-black-dark-light">
      Home
    </div>
    <div class="text-xl font-black text-black-dark-light">
      /
    </div>
    <div class="text-xl text-black-dark-light cursor-pointer hover:text-dlight ease-in-out duration-300" routerLink="/ticketing/dispositionsmaps">
      Dispositions Map
    </div>
    <div class="text-xl font-black text-black-dark-light">
      /
    </div>
    <div class="text-xl text-black-dark-light text-orange-600">
      New Map
    </div>
  </div>

</div>



<div class="mt-3" *ngIf="mainLoading">
  <div class="flex flex-row justify-center items-center w-full  gap-4">
    <span class="m-auto  inline-block h-14 w-14 animate-spin rounded-full border-8 border-[#f1f2f3] border-l-dlight align-middle"></span>
  </div>
</div>


<ng-container *ngIf="!mainLoading">
  <div class="w-3/4 m-auto">
    <div style="border-radius:20px" class="bg-white box-shadow-pop p-4">
      <div class="font-semibold text-lg">General Details</div>


      <form>
        <div class="mt-6 grid grid-cols-2 gap-3" >
          <div class="col-span-1">
            <label class="label_title">Name
              <span *ngIf="map.name == null || map.name.length < 3" class="text-red-500">*</span>
              <span *ngIf="map.name != null && map.name.length >= 3" class="text-green-500">*</span>
            </label>
            <input class=" form-input form-input-focus" placeholder="Enter a name"  [(ngModel)]="map.name" name="name"/>
          </div>

          <div class="col-span-1">
            <label class="label_title">Request Type
              <span *ngIf="map.requestTypeId == null" class="text-red-500">*</span>
              <span *ngIf="map.requestTypeId != null" class="text-green-500">*</span></label>
            <select class="select_input form-input form-input-focus" placeholder="Select Request Type..." [(ngModel)]="map.requestTypeId" name="requestTypeId">
              <option value="" disabled>{{'Please select Request Type' | translate}}</option>
              <option [value]="requestType.id" *ngFor="let requestType of requestTypes"> {{requestType.name}} </option>
            </select>
          </div>

        </div>
      </form>
    </div>
  </div>

  <div class="w-3/4 m-auto mt-3">
    <div style="border-radius:20px" class="bg-white box-shadow-pop p-4">
      <div class="flex flex-row justify-start gap-3">
        <div class="font-semibold text-lg">Disposition Mappings</div>
      </div>

      <div class="bg-white ticket-table w-full mt-3" style="border-radius:20px">
        <div class="grid grid-cols-5 py-3 ticket-row w-full">
          <div class="col-span-1 m-auto">
            GROUP
          </div>
          <div class="col-span-1 m-auto">
            LEVEL 1
          </div>
          <div class="col-span-1 m-auto">
            LEVEL 2
          </div>
          <div class="col-span-1 m-auto">
            LEVEL 3
          </div>
          <div class="col-span-1 m-auto">
            ACTIONS
          </div>
        </div>
      </div>

      <div class="grid grid-cols-5 py-2 ticket-row " *ngFor="let disposition of map.dispositionsMapDisposition">
        <div class="col-span-1 flex flex-row justify-center items-center">
          {{disposition.group}}
        </div>
        <div class="col-span-1 flex flex-row justify-center items-center">
          {{disposition.level1}}
        </div>
        <div class="col-span-1 flex flex-row justify-center items-center">
          {{disposition.level2}}
        </div>
        <div class="col-span-1 flex flex-row justify-center items-center">
          {{disposition.level3}}
        </div>
        <div class="col-span-1 flex flex-row justify-center items-center">
          <button (click)="removeDisposition(disposition)" type="button" class="btn btn-sm rounded-full btn-outline-dlight "><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" xml:space="preserve" width="24" height="24"><path fill="none" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" d="M17.25 20.25h-9c-.825 0-1.5-.675-1.5-1.5V6h12v12.75c0 .825-.675 1.5-1.5 1.5zm3-14.25h-15m5.25 0V4.5c0-.45.3-.75.75-.75h3c.45 0 .75.3.75.75V6m-2.25 11.25V9m3 8.25V9m-6 8.25V9"/></svg>&nbsp;&nbsp;Remove </button>
        </div>
      </div>
    </div>
  </div>

  <div class="w-3/4 m-auto mt-3" >
    <div style="border-radius:20px" class="bg-white box-shadow-pop p-4">

      <div class="flex flex-row justify-start gap-3">
        <div class="font-semibold text-lg">New Mapping</div>
        <div  *ngIf="searching">
          <div class="flex flex-row justify-center items-center w-full  gap-4">
            <span class="m-auto  inline-block h-6 w-6 animate-spin rounded-full border-4 border-[#f1f2f3] border-l-dlight align-middle"></span>
          </div>
        </div>
      </div>


      <form>
        <div class="mt-6 grid grid-cols-4 gap-3" >

          <div class="col-span-1" >
            <label class="label_title">Group
              <span *ngIf="newDisposition.group == null" class="text-red-500">*</span>
              <span *ngIf="newDisposition.group != null" class="text-green-500">*</span>
            </label>


            <select [disabled]="searching" (change)="selectedGroup()" class="select_input form-input form-input-focus" placeholder="Select Group..."  [(ngModel)]="newDisposition.group" [ngModelOptions]="{standalone: true}" >
              <option value="" disabled>{{'Please select Group' | translate}}</option>
              <option [value]="group" *ngFor="let group of groupList"> {{group}} </option>
            </select>

          </div>

          <div class="col-span-1">
            <label class="label_title">Level 1
              <span *ngIf="newDisposition.level1 == null" class="text-red-500">*</span>
              <span *ngIf="newDisposition.level1 != null" class="text-green-500">*</span>
            </label>
            <select [disabled]="searching" (change)="selectedLevel1()" class="select_input form-input form-input-focus" placeholder="Select Level 1..."  [(ngModel)]="newDisposition.level1" [ngModelOptions]="{standalone: true}" >
              <option value="" disabled>{{'Please select Level 1' | translate}}</option>
              <option [value]="level1" *ngFor="let level1 of level1List"> {{level1}} </option>
            </select>
          </div>

          <div class="col-span-1">
            <label class="label_title">Level 2
            </label>
            <select [disabled]="searching"  (change)="selectedLevel2()" class="select_input form-input form-input-focus" placeholder="Select Level 2..."  [(ngModel)]="newDisposition.level2" [ngModelOptions]="{standalone: true}" >
              <option value="" disabled>{{'Please select Level 2' | translate}}</option>
              <option [value]="level2" *ngFor="let level2 of level2List"> {{level2}} </option>
            </select>
          </div>

          <div class="col-span-1">
            <label class="label_title">Level 3
            </label>
            <select [disabled]="searching"  class="select_input form-input form-input-focus" placeholder="Select Level 3..."  [(ngModel)]="newDisposition.level3" [ngModelOptions]="{standalone: true}" >
              <option value="" disabled>{{'Please select Level 3' | translate}}</option>
              <option [value]="level3" *ngFor="let level3 of level3List"> {{level3}} </option>
            </select>
          </div>

        </div>


        <div class="flex flex-row mt-3 justify-center w-full">
          <div class="mt-3" *ngIf="loadingAdding">
            <div class="flex flex-row justify-center items-center w-full  gap-4">
              <span class="m-auto  inline-block h-14 w-14 animate-spin rounded-full border-8 border-[#f1f2f3] border-l-dlight align-middle"></span>
            </div>
          </div>
          <button *ngIf="!loadingAdding" class="btn btn-dlight flex-grow-0" (click)="addDisposition()">Add Disposition</button>
        </div>

      </form>
    </div>
  </div>

  <div class="mt-5 flex flex-row justify-center">
    <div class="mt-3" *ngIf="loadingCreate">
      <div class="flex flex-row justify-center items-center w-full  gap-4">
        <span class="m-auto  inline-block h-14 w-14 animate-spin rounded-full border-8 border-[#f1f2f3] border-l-dlight align-middle"></span>
      </div>
    </div>
    <button *ngIf="!loadingCreate" class="btn btn-success flex-grow-0" (click)="createMapping()">Create Mapping</button>
  </div>

</ng-container>

