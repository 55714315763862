import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getConsignmentProductStore = createFeatureSelector('consignmentProduct');

export const getConsignmentProductEntities = createSelector(
  getConsignmentProductStore,
  fromReducers.consignmentProduct.consignmentProductEntitySelectors.selectAll
);

export const getConsignmentProducts = createSelector(getConsignmentProductEntities, entities => {
  return Object.values(entities);
});

export const getTopConsignmentProducts = createSelector(getConsignmentProductEntities, entities => {
  return Object.values(entities).slice(1, 5);
});

export const getConsignmentProductsLoaded = createSelector(
  getConsignmentProductStore,
  (consignmentProductStore: fromReducers.consignmentProduct.State) => consignmentProductStore.loaded
);

export const getConsignmentProductsLoading = createSelector(
  getConsignmentProductStore,
  (consignmentProductStore: fromReducers.consignmentProduct.State) => consignmentProductStore.loading
);

export const getConsignmentProductsPaging = createSelector(
  getConsignmentProductStore,
  (consignmentProductStore: fromReducers.consignmentProduct.State) => consignmentProductStore.paging
);

export const getSelectedConsignmentProductId = createSelector(
  getConsignmentProductStore,
  (consignmentProductStore: fromReducers.consignmentProduct.State) => consignmentProductStore.selectedConsignmentProductId
);

export const getSearchConsignmentProducts = createSelector(
  getConsignmentProductStore,
  (consignmentProductStore: fromReducers.consignmentProduct.State) => consignmentProductStore.searchConsignmentProducts
);

export const getConsignmentProductById = createSelector(
  getConsignmentProductEntities,
  getSelectedConsignmentProductId,
  (entities, id) => entities.find(i => i.id === id)
);

export const getConsignmentProductsError = createSelector(
  getConsignmentProductStore,
  (consignmentProductStore: fromReducers.consignmentProduct.State) => consignmentProductStore.error
);
