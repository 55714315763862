import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, mergeMap, switchMap} from "rxjs/operators";
import {of} from "rxjs";
import {ConsignmentProductService} from "@appServices/core-inventory/consignment-product/consignment-product.service";
import {InventoryProductService} from "@appServices/core-inventory/inventory-product/inventory-product.service";
import {ProductCatalogueService} from "@appServices/core-masterdata/product-catalogue/product-catalogue.service";
import {ConsignmentProductAcceptReceiveStatusService} from "@appServices/core-inventory/consignment-product-accept-receive-status/consignment-product-accept-receive-status.service";
import {ToastrService} from "ngx-toastr";
import {
  AddConsignmentProductAcceptReceiveStatus,
  AddConsignmentProductAcceptReceiveStatusSuccess,
  ConsignmentProductAcceptReceiveStatusActionTypes,
  ConsignmentProductAcceptReceiveStatusError,
  DeleteConsignmentProductAcceptReceiveStatus,
  DeleteConsignmentProductAcceptReceiveStatusSuccess,
  GetConsignmentProductAcceptReceiveStatus,
  GetConsignmentProductAcceptReceiveStatusById,
  GetConsignmentProductAcceptReceiveStatusByIdSuccess,
  GetConsignmentProductAcceptReceiveStatusSuccess,
  UpdateConsignmentProductAcceptReceiveStatus,
  UpdateConsignmentProductAcceptReceiveStatusSuccess
} from "@appStore/actions/core-inventory/consignment-product-accept-receive-status/consignment-product-accept-receive-status.action";
import {ShippingCompanyError} from "@appStore/actions/core-inventory/shipping-company/shipping-company.actions";

@Injectable()
export class ConsignmentProductAcceptReceiveStatusEffects {

  
  getConsignmentProductAcceptReceiveStatus$ = createEffect(() => this.actions$.pipe(
    ofType(ConsignmentProductAcceptReceiveStatusActionTypes.consignmentProductAcceptReceiveStatusGetConsignmentProductAcceptReceiveStatus),
    switchMap((action: GetConsignmentProductAcceptReceiveStatus) =>
      this.consignmentProductAcceptReceiveStatusService
        .getConsignmentProductAcceptReceiveStatuses()
        .pipe(
          map(
            consignmentConsignmentProductAcceptReceiveStatuses => {
              return new GetConsignmentProductAcceptReceiveStatusSuccess(consignmentConsignmentProductAcceptReceiveStatuses);
            }
          ),
          catchError(error => of(new ConsignmentProductAcceptReceiveStatusError(error)))
        )
    )
  ));

  
  deleteConsignmentProductAcceptReceiveStatus$ = createEffect(() => this.actions$.pipe(
    ofType(ConsignmentProductAcceptReceiveStatusActionTypes.consignmentProductAcceptReceiveStatusDeleteConsignmentProductAcceptReceiveStatus),
    mergeMap((action: DeleteConsignmentProductAcceptReceiveStatus) =>
      this.consignmentProductAcceptReceiveStatusService
        .deleteConsignmentProductAcceptReceiveStatus(action.payload)
        .pipe(
          map(res => {
            this.toastr.success('Accept/Receive status deleted successfully!', "Success");
            return new DeleteConsignmentProductAcceptReceiveStatusSuccess(action.payload);

          }),
          catchError(error => of(new ConsignmentProductAcceptReceiveStatusError(error)))
        )
    )
  ));


  
  addAddConsignmentProductAcceptReceiveStatus$ = createEffect(() => this.actions$.pipe(
    ofType(ConsignmentProductAcceptReceiveStatusActionTypes.consignmentProductAcceptReceiveStatusAddConsignmentProductAcceptReceiveStatus),
    mergeMap((action: AddConsignmentProductAcceptReceiveStatus) =>
      this.consignmentProductAcceptReceiveStatusService
        .addConsignmentProductAcceptReceiveStatus(action.payload)
        .pipe(
          map(consignmentProductAcceptReceiveStatus => {
            this.toastr.success('Accept/Receive status added successfully!', "Success");
            return new AddConsignmentProductAcceptReceiveStatusSuccess(consignmentProductAcceptReceiveStatus);

          }),
          catchError(error => of(new ConsignmentProductAcceptReceiveStatusError(error)))
        )
    )
  ));

  
  addAddConsignmentProductAcceptReceiveStatusSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(ConsignmentProductAcceptReceiveStatusActionTypes.consignmentProductAcceptReceiveStatusAddConsignmentProductAcceptReceiveStatusSuccess),
    mergeMap((action: AddConsignmentProductAcceptReceiveStatusSuccess) =>
      this.consignmentProductAcceptReceiveStatusService
        .patchTenantConsignmentProductAcceptReceiveStatus(action.payload)
        .pipe(
          map(consignmentProductAcceptReceiveStatus => {
            return new UpdateConsignmentProductAcceptReceiveStatusSuccess(consignmentProductAcceptReceiveStatus);

          }),
          catchError(error => of(new ConsignmentProductAcceptReceiveStatusError(error)))
        )
    )
  ));

  
  getConsignmentProductAcceptReceiveStatusId$ = createEffect(() => this.actions$.pipe(
    ofType(ConsignmentProductAcceptReceiveStatusActionTypes.consignmentProductAcceptReceiveStatusGetConsignmentProductAcceptReceiveStatusById),
    mergeMap((action: GetConsignmentProductAcceptReceiveStatusById) =>
      this.consignmentProductAcceptReceiveStatusService
        .getConsignmentProductAcceptReceiveStatus(action.payload)
        .pipe(
          map(consignmentProductAcceptReceiveStatus => {
            return new GetConsignmentProductAcceptReceiveStatusByIdSuccess(consignmentProductAcceptReceiveStatus);

          }),
          catchError(error => of(new ConsignmentProductAcceptReceiveStatusError(error)))
        )
    )
  ));

  
  updateConsignmentProductAcceptReceiveStatus$ = createEffect(() => this.actions$.pipe(
    ofType(ConsignmentProductAcceptReceiveStatusActionTypes.consignmentProductAcceptReceiveStatusUpdateConsignmentProductAcceptReceiveStatus),
    mergeMap((action: UpdateConsignmentProductAcceptReceiveStatus) =>
      this.consignmentProductAcceptReceiveStatusService
        .updateConsignmentProductAcceptReceiveStatus(action.payload)
        .pipe(
          map(consignmentProductAcceptReceiveStatus => {
            this.toastr.success('Accept/Receive status updated successfully!', "Success");
            return new UpdateConsignmentProductAcceptReceiveStatusSuccess(consignmentProductAcceptReceiveStatus);
          }),
          catchError(error => of(new ShippingCompanyError(error)))
        )
    )
  ));


  constructor(
    private actions$: Actions,
    private consignmentProductService: ConsignmentProductService,
    private inventoryProductService: InventoryProductService,
    private productCatalogueService: ProductCatalogueService,
    private consignmentProductAcceptReceiveStatusService: ConsignmentProductAcceptReceiveStatusService,
    private toastr: ToastrService
  ) {
  }
}
