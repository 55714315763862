import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getProductStore = createFeatureSelector('product');

export const getProductEntities = createSelector(
  getProductStore,
  fromReducers.product.productEntitySelectors.selectAll
);

export const getProducts = createSelector(getProductEntities, entities => {
  return Object.values(entities);
});

export const getTopProducts = createSelector(getProductEntities, entities => {
  return Object.values(entities).slice(1, 5);
});

export const getProductsLoaded = createSelector(
  getProductStore,
  (productStore: fromReducers.product.State) => productStore.loaded
);

export const getProductsLoading = createSelector(
  getProductStore,
  (productStore: fromReducers.product.State) => productStore.loading
);

export const getSelectedProductId = createSelector(
  getProductStore,
  (productStore: fromReducers.product.State) => productStore.selectedProductId
);

export const getSearchProducts = createSelector(
  getProductStore,
  (productStore: fromReducers.product.State) => productStore.searchProducts
);

export const getProductById = createSelector(
  getProductEntities,
  getSelectedProductId,
  (entities, id) => entities.find(i => i.id === id)
);

export const getProductsError = createSelector(
  getProductStore,
  (productStore: fromReducers.product.State) => productStore.error
);


export const getProductsPaging = createSelector(
  getProductStore,
  (productStore: fromReducers.product.State) => productStore.paging
);
