import {Action} from '@ngrx/store';

import {CustomRegion} from '@appModels/core-setup/custom-region/custom-region';

export enum CustomRegionActionTypes {
  customRegionGetCustomRegions = '[CustomRegion] get',
  customRegionGetCustomRegionsSuccess = '[CustomRegion] get customRegions success',
  customRegionGetTenantCustomRegions = '[CustomRegion] get tenant',
  customRegionGetTenantCustomRegionsSuccess = '[CustomRegion] get tenant customRegions success',
  customRegionAddCustomRegion = '[CustomRegion] add CustomRegion',
  customRegionAddCustomRegionSuccess = '[CustomRegion] add customRegion success',
  customRegionDeleteCustomRegion = '[CustomRegion] delete customRegion',
  customRegionDeleteCustomRegionSuccess = '[CustomRegion] delete customRegion success',
  customRegionGetCustomRegionById = '[CustomRegion] get customRegion by id',
  customRegionGetCustomRegionByIdSuccess = '[CustomRegion] get customRegion by id success',
  customRegionUpdateCustomRegion = '[CustomRegion] update customRegion',
  customRegionUpdateCustomRegionSuccess = '[CustomRegion] update customRegion success',
  customRegionEditCustomRegion = '[CustomRegion] edit customRegion',
  customRegionEditCustomRegionSuccess = '[CustomRegion] edit customRegion success',
  customRegionSearchCustomRegions = '[CustomRegion] search customRegions',
  customRegionSearchCustomRegionsSuccess = '[CustomRegion] search customRegions success',
  customRegionSearchCustomRegionsReset = '[CustomRegion] search customRegions reset',
  customRegionGetCustomRegionsAll = '[CustomRegion] get all',
  customRegionGetCustomRegionsAllSuccess = '[CustomRegion] get all customRegions success',
  customRegionError = '[CustomRegion] error'
}

export class GetCustomRegions implements Action {
  readonly type = CustomRegionActionTypes.customRegionGetCustomRegions;
  constructor(public payload?) {
  }
}

export class GetCustomRegionsSuccess implements Action {
  readonly type = CustomRegionActionTypes.customRegionGetCustomRegionsSuccess;

  constructor(public payload) {
  }
}

export class GetCustomRegionsAll implements Action {
  readonly type = CustomRegionActionTypes.customRegionGetCustomRegionsAll;
}

export class GetCustomRegionsAllSuccess implements Action {
  readonly type = CustomRegionActionTypes.customRegionGetCustomRegionsAllSuccess;

  constructor(public payload) {
  }
}

export class GetTenantCustomRegions implements Action {
  readonly type = CustomRegionActionTypes.customRegionGetTenantCustomRegions;

  constructor(public payload) {
  }
}

export class GetTenantCustomRegionsSuccess implements Action {
  readonly type = CustomRegionActionTypes.customRegionGetTenantCustomRegionsSuccess;

  constructor(public payload) {
  }
}

export class AddCustomRegion implements Action {
  readonly type = CustomRegionActionTypes.customRegionAddCustomRegion;

  constructor(public payload: CustomRegion) {
  }
}

export class AddCustomRegionSuccess implements Action {
  readonly type = CustomRegionActionTypes.customRegionAddCustomRegionSuccess;

  constructor(public payload: CustomRegion) {
  }
}

export class GetCustomRegionById implements Action {
  readonly type = CustomRegionActionTypes.customRegionGetCustomRegionById;

  constructor(public payload: number) {
  }
}

export class GetCustomRegionByIdSuccess implements Action {
  readonly type = CustomRegionActionTypes.customRegionGetCustomRegionByIdSuccess;

  constructor(public payload: CustomRegion) {
  }
}

export class UpdateCustomRegion implements Action {
  readonly type = CustomRegionActionTypes.customRegionUpdateCustomRegion;

  constructor(public payload: CustomRegion) {
  }
}

export class UpdateCustomRegionSuccess implements Action {
  readonly type = CustomRegionActionTypes.customRegionUpdateCustomRegionSuccess;

  constructor(public payload: CustomRegion) {
  }
}

export class EditCustomRegion implements Action {
  readonly type = CustomRegionActionTypes.customRegionEditCustomRegion;

  constructor(public payload) {
  }
}

export class EditCustomRegionSuccess implements Action {
  readonly type = CustomRegionActionTypes.customRegionEditCustomRegionSuccess;

  constructor(public payload) {
  }
}

export class DeleteCustomRegion implements Action {
  readonly type = CustomRegionActionTypes.customRegionDeleteCustomRegion;

  constructor(public payload: CustomRegion) {
  }
}

export class DeleteCustomRegionSuccess implements Action {
  readonly type = CustomRegionActionTypes.customRegionDeleteCustomRegionSuccess;

  constructor(public payload: CustomRegion) {
  }
}

export class SearchCustomRegions implements Action {
  readonly type = CustomRegionActionTypes.customRegionSearchCustomRegions;

  constructor(public payload: string) {
  }
}

export class SearchCustomRegionsSuccess implements Action {
  readonly type = CustomRegionActionTypes.customRegionSearchCustomRegionsSuccess;

  constructor(public payload: CustomRegion[]) {
  }
}

export class SearchCustomRegionsReset implements Action {
  readonly type = CustomRegionActionTypes.customRegionSearchCustomRegionsReset;
}

export class CustomRegionError implements Action {
  readonly type = CustomRegionActionTypes.customRegionError;

  constructor(public payload: any) {
  }
}

export type CustomRegionActions =
  | GetCustomRegions
  | GetCustomRegionsSuccess
  | GetTenantCustomRegionsSuccess
  | GetTenantCustomRegions
  | AddCustomRegion
  | AddCustomRegionSuccess
  | GetCustomRegionById
  | GetCustomRegionByIdSuccess
  | UpdateCustomRegion
  | UpdateCustomRegionSuccess
  | EditCustomRegion
  | EditCustomRegionSuccess
  | DeleteCustomRegion
  | DeleteCustomRegionSuccess
  | SearchCustomRegions
  | SearchCustomRegionsSuccess
  | SearchCustomRegionsReset
  | GetCustomRegionsAll
  | GetCustomRegionsAllSuccess
  | CustomRegionError;
