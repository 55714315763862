import {Component} from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FAQComponent {
  isCollapsed = {
    'atlas_instance': false,
    'functionalities': true,
    'navigation': true,
    'password': true,
    'support': true,

    'rate': true,
    'payment_plan': true,
    'existing_customer': true,
    'products': true,
    'registration': true,
    'customizations': true,
    'setup_change': true,

    'new_user': true,
    'deactivate': true,
    'new_role': true,
    'edit_role': true,
    'remove_permission': true,

    'field_force': true,
    'new_agent': true,
    'staff_level': true,
    'disable_agent': true,
    'stocking_point': true,
    'commissions': true,

    'new_stocking': true,
    'consignment': true,
    'consignment_status': true,
    'upload_inventory': true,
    'bulk_action': true,
    'inventory_status': true,
    'send_stock': true,
   
    'customer_registration': true,
    'lead': true,
    'customer_status': true,
    'error_registration': true,
    'action_type': true,
    'escalated_action': true,
    'registration_report': true,

    'finance_report': true,
    'manual_payment': true,
    'payment_held': true,
    'held_payments': true,

    'token_generated': true,
    'token_entered': true,
    'token_expire': true,
    'demo_token': true,
    'token_index': true,

    'support_channel': true,
    'service_desk': true,
    'ticket_issue_type': true,
    'approval': true,
    
  };

  changeValue(key){
    Object.keys(this.isCollapsed).forEach(k => {
      if(k !== key) {
        this.isCollapsed[k] = true
      } else {
        this.isCollapsed[k] = !this.isCollapsed[k]
      }
      
    })
    
  }
}
