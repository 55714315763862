import {Injectable} from '@angular/core';
import * as _ from 'lodash';

@Injectable({providedIn: 'root'})
export class TenantPreferenceResolver {

  /**
   * Preference attribute
   */
  private preference = null;

  /**
   * Main Preference attribute
   */
  private mainPreference = null;

  constructor() {
    this.loadTenantPreference();
  }

  /**
   * Load tenant Preference from localStorage
   */
  private loadTenantPreference() {
    const preference = localStorage.getItem('tenantPreference');
    if (preference) {
      this.preference = JSON.parse(preference);
    }
  }

  /**
   * Set Tenant Preference for the attribute and update localStorage
   * @param preference
   */
  public setTenantPreference(preference) {
    this.preference = preference;
    localStorage.setItem('tenantPreference', JSON.stringify(preference));
    return this;
  }

  /**
   * groupMainPreference with preference tag
   */
  public groupMainPreference() {
    this.mainPreference = _.groupBy(this.preference?.mainPrefs, preference => preference.preferenceTag);
    return this;
  }

  /**
   * get a specific preference value as an array
   * @param key
   */
  public getPreferenceValueAsArray(key) {
    const result = []
    if (this.mainPreference.hasOwnProperty(key)) {
      this.mainPreference[key].forEach(element => {
        result.push(element.preferenceValue);
      });
    }
    return result;
  }

  /**
   * get a specific preference value as a boolean
   * @param key
   * @param defaultValue
   */
  public getPreferenceValueAsBoolean(key, defaultValue: boolean = false) {
    let result = defaultValue;
    if (this.mainPreference.hasOwnProperty(key)) {
      this.mainPreference[key].forEach(element => {
        result = element.preferenceValue === 'Y';
      });
    }
    return result;
  }

  /**
   * get a specific preference value as a boolean by key
   * @param key
   * @param defaultValue
   */
  public getPreferenceValueAsBooleanByKey(key, defaultValue: boolean = false) {
    let result = defaultValue;
    if (this.getMainPreferenceByKey(key) !== null) {
      result = this.getMainPreferenceByKey(key).preferenceValue === 'Y'
    }
    return result;
  }

  public getPreferenceValueWithKey(key) {
    if (this.mainPreference.hasOwnProperty(key)) {
      return this.mainPreference[key][0].preferenceValue;
    }
    return null;
  }

  /**
   * get Customer info label
   */
  public getGeneralCustomerInfoLabel() {
    const tenantLabel = {};
    console.log(this.mainPreference, 'this.mainPreference')
    if (this.mainPreference.hasOwnProperty('GENERAL_CUSTOMER_INFO')) {
      this.mainPreference['GENERAL_CUSTOMER_INFO'].forEach(element => {
        let pValues = JSON.parse(element.preferenceValue);
        Object.keys(pValues).forEach((k) => {
          tenantLabel[k] = pValues[k].label;
        })
      })
    }
    return tenantLabel;
  }

  /**
   * get Customer info requirement
   */
  public getGeneralCustomerInfoRequirement() {
    const tenantRequirement = {};
    if (this.mainPreference.hasOwnProperty('GENERAL_CUSTOMER_INFO')) {
      this.mainPreference['GENERAL_CUSTOMER_INFO'].forEach(element => {
        let pValues = JSON.parse(element.preferenceValue);
        Object.keys(pValues).forEach((k) => {
          tenantRequirement[k] = pValues[k].mandatory === 'Y';
        })
      })
    }
    return tenantRequirement;
  }

  /**
   * get max admin zone level
   */
  public getMaxAdminZoneLevel() {
    const object = this.getRequirementByKey('CUSTOMER_LOCATION_MAX_ADMIN_ZONE_LEVEL');
    const key = 'MAX_LEVEL';
    return object[key];
  }

  public getMaxBundledAccessories() {
    const object = this.getRequirementByKey('MAX_NUMBER_OF_BUNDLED_ACCESSORIES');
    const key = 'MAX_NUMBER_OF_BUNDLED_ACCESSORIES';
    return object[key];
  }

  public getRequirementByKey(key) {
    const requirement = {}
    if (this.mainPreference.hasOwnProperty(key)) {
      this.mainPreference[key].forEach(element => {
        requirement[element.preferenceKey] = JSON.parse(element.preferenceValue)
      })
    }
    return requirement;
  }

  /**
   * get preference by key
   * @param key
   */
  public getByKey(key) {
    if (this.preference === null) {
      return null;
    }
    return this.preference[key];
  }

  /**
   * get main preference by key
   * @param key
   */
  public getMainPreferenceByKey(key) {
    const preference = this.getByKey('mainPrefs');
    if (!preference) {
      return null;
    }

    return preference.find(o => o.preferenceKey === key);
  }

}
