import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable, of} from 'rxjs';

import {catchError, tap} from "rxjs/operators";
import {CoreNotificationService} from "@appServices/core-notification/core-notification.service";

@Injectable({ providedIn: 'root' })
export class WebNotificationService extends CoreNotificationService {

  constructor(
    private http: HttpClient,
  ) {
    super(http, 'webnotification');
    this.headers = this.headers.append('skip-cache', 'true');

  }

  getForUser(read : boolean): Observable<any> {

    let params = {};
    if (read != null && read == true) {
      params = {
        read : true
      }
    } else if (read != null && read == false) {
      params = {
        read : false
      }
    }

    const url = `${this.base_url}/getForCurrentUser`;
    return this.http.get<any>(url, {params: params, headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }

  clearForCurrentUser(): Observable<any> {
    const url = `${this.base_url}/clearForCurrentUser`;
    return this.http.post<any>(url, { headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }
}
