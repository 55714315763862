import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

import {
  AddRateValidationQualification,
  AddRateValidationQualificationSuccess,
  DeleteRateValidationQualification,
  DeleteRateValidationQualificationSuccess,
  GetRateValidationQualificationById,
  GetRateValidationQualificationByIdSuccess,
  GetRateValidationQualifications,
  GetRateValidationQualificationsSuccess,
  RateValidationQualificationActionTypes,
  RateValidationQualificationError,
  SearchRateValidationQualifications,
  SearchRateValidationQualificationsSuccess, UpdateRateValidationQualification,
  UpdateRateValidationQualificationSuccess
} from '@appStore/actions/core-accounting/rate-validation-qualification/rate-validation-qualification.actions';
import {RateValidationQualificationService} from '@appServices/core-accounting/rate-validation-qualification/rate-validation-qualification.service';
import {ToastrService} from 'ngx-toastr';

@Injectable()
export class RateValidationQualificationEffects {
  constructor(
    private actions$: Actions,
    private rateValidationQualificationService: RateValidationQualificationService,
    private toastr: ToastrService
    ) {}

  
  loadRateValidationQualifications$ = createEffect(() => this.actions$.pipe(
    ofType(RateValidationQualificationActionTypes.rateValidationQualificationGetRateValidationQualifications),
    mergeMap((action: GetRateValidationQualifications) =>
      this.rateValidationQualificationService
        .getRateValidationQualifications(action.payload)
        .pipe(
          map(rateValidationQualifications => new GetRateValidationQualificationsSuccess(rateValidationQualifications)),
          catchError(error => of(new RateValidationQualificationError(error)))
        )
    )
  ));

  
  getRateValidationQualificationById$ = createEffect(() => this.actions$.pipe(
    ofType(RateValidationQualificationActionTypes.rateValidationQualificationGetRateValidationQualificationById),
    mergeMap((action: GetRateValidationQualificationById) =>
      this.rateValidationQualificationService
        .getRateValidationQualification(action.payload)
        .pipe(
          map(rateValidationQualification => new GetRateValidationQualificationByIdSuccess(rateValidationQualification)),
          catchError(error => of(new RateValidationQualificationError(error)))
        )
    )
  ));

  
  addRateValidationQualification$ = createEffect(() => this.actions$.pipe(
    ofType(RateValidationQualificationActionTypes.rateValidationQualificationAddRateValidationQualification),
    switchMap((action: AddRateValidationQualification) =>
      this.rateValidationQualificationService
        .addRateValidationQualification(action.payload)
        .pipe(
          map(
            rateValidationQualification => {
              if (rateValidationQualification !== undefined) {
                this.toastr.success('RateValidationQualification has been successfully added!', 'Successful!');
                return new AddRateValidationQualificationSuccess(rateValidationQualification)
              }
              this.toastr.error('There was an error adding this rateValidationQualification', "Unknown error");
              return new RateValidationQualificationError({ type: 500, message: 'Internal error' })
            }
            ),
          catchError(error => of(new RateValidationQualificationError(error)))
        )
    )
  ));

  
  addAdditionalRateValidationQualificationInfo$ = createEffect(() => this.actions$.pipe(
    ofType(RateValidationQualificationActionTypes.rateValidationQualificationAddAdditionalRateValidationQualificationInfo),
    mergeMap((action: AddRateValidationQualification) =>
      this.rateValidationQualificationService
        .addRateValidationQualificationAdditionalInfo(action.payload)
        .pipe(
          map(rateValidationQualification => new GetRateValidationQualifications()),
          catchError(error => of(new RateValidationQualificationError(error)))
        )
    )
  ));

  
  updateRateValidationQualification$ = createEffect(() => this.actions$.pipe(
    ofType(RateValidationQualificationActionTypes.rateValidationQualificationUpdateRateValidationQualification),
    mergeMap((action: UpdateRateValidationQualification) =>
      this.rateValidationQualificationService
        .updateRateValidationQualification(action.payload)
        .pipe(
          map(rateValidationQualification => new UpdateRateValidationQualificationSuccess(action.payload)),
          catchError(error => of(new RateValidationQualificationError(error)))
        )
    )
  ));

  
  deleteRateValidationQualification$ = createEffect(() => this.actions$.pipe(
    ofType(RateValidationQualificationActionTypes.rateValidationQualificationDeleteRateValidationQualification),
    mergeMap((action: DeleteRateValidationQualification) =>
      this.rateValidationQualificationService
        .deleteRateValidationQualification(action.payload)
        .pipe(
          map(() => new DeleteRateValidationQualificationSuccess(action.payload)),
          catchError(error => of(new RateValidationQualificationError(error)))
        )
    )
  ));

  
  searchRateValidationQualifications$ = createEffect(() => this.actions$.pipe(
    ofType(RateValidationQualificationActionTypes.rateValidationQualificationSearchRateValidationQualifications),
    mergeMap((action: SearchRateValidationQualifications) =>
      this.rateValidationQualificationService
        .searchRateValidationQualifications(action.payload)
        .pipe(
          map(rateValidationQualifications => new SearchRateValidationQualificationsSuccess(rateValidationQualifications)),
          catchError(error => of(new RateValidationQualificationError(error)))
        )
    )
  ));
}
