import {ChangeDetectorRef, Component, Input, TemplateRef, ViewChild} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  moduleId: module.id,
  templateUrl: './ticket-details-page-component.html',
  animations: [
    trigger('toggleAnimation', [
      transition(':enter', [style({ opacity: 0, transform: 'scale(0.95)' }), animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' }))]),
      transition(':leave', [animate('75ms', style({ opacity: 0, transform: 'scale(0.95)' }))]),
    ]),
  ],
  styleUrls: ['./ticket-details-page-component.css', '../../tailwind.css']
})
export class TicketDetailsPageComponent {
  constructor(
    private route:ActivatedRoute,
    private router: Router

) {

    this.ticketId = this.route.snapshot.paramMap.get('id');
  }

  ticketId = null;

  openCustomerPage(encryptedId:any) {
    this.router.navigateByUrl(`/crm/customers/${encryptedId}`)
  }

}
