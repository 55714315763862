import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { tap, map, exhaustMap, mergeMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

import {
  Nav,
  ModuleNav,
  NavSuccess,
  ModuleNavSuccess,
  NavActionTypes,
  SubNavSuccess
} from '@appStore/actions/nav.actions';

@Injectable()
export class NavEffects {
  
  nav$ = createEffect(() => this.actions$.pipe(
    ofType(NavActionTypes.Nav),
    map((action: Nav) => action.payload),
    map(payload => {
          return (new NavSuccess(payload));
        })
  ));

  
  navModule$ = createEffect(() => this.actions$.pipe(
    ofType(NavActionTypes.ModuleNav),
    map((action: Nav) => action.payload),
    map(payload => {
          return (new ModuleNavSuccess(payload));
        })
  ));

  
  subNavModule$ = createEffect(() => this.actions$.pipe(
    ofType(NavActionTypes.SubNav),
    map((action: Nav) => action.payload),
    map(payload => {
          return (new SubNavSuccess(payload));
        })
  ));

  
  navSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(NavActionTypes.Success)
  ), { dispatch: false });

  
  navModuleSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(NavActionTypes.ModuleSuccess)
  ), { dispatch: false });

  
  subNavModuleSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(NavActionTypes.SubNavSuccess)
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
  ) {}
}
