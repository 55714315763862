import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { CollapseModule } from 'ngx-bootstrap/collapse';

import { TermsAndConditionsComponent } from './terms-and-conditions.component';

import { ModuleRoutes } from './terms-and-conditions.routing';

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(ModuleRoutes),
        CollapseModule.forRoot(),
        FormsModule
    ],
    declarations: [
      TermsAndConditionsComponent
    ]
})
export class TermsAndConditionsModule { }
