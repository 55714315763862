import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';

import {Tenant} from '@appModels/core-setup/tenant/tenant';
import {User} from '@appModels/core-identity/user/user';

import {CoreMasterDataService} from '../core-masterdata.service';

import {StockingPoint} from '@appModels/core-inventory/stocking-point/stocking-point';


@Injectable({providedIn: 'root'})
export class TenantService extends CoreMasterDataService {


  constructor(
    private http: HttpClient,
  ) {
    super(http, 'tenant');
  }

  /** GET tenants from the server */
  // getTenants(): Observable<Tenant[]> {
  //   return this.http.get<Tenant[]>(this.base_url + '/', { headers: this.headers }).pipe(
  //     tap(tenants => this.log(`fetched tenants`)),
  //     catchError(this.handleError('getTenants', []))
  //   );
  // }

  getTenants(query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        params = params.append(val, query[val]);
      });
    }
    return this.http.get<any>(this.base_url , {params, headers: this.headers}).pipe(
      tap(customers => this.log(`fetched tenants`)),
      catchError(this.handleError('getTenants', []))
    );
  }

  /** GET tenant by id. Will 404 if id not found */
  getTenantsCount(): Observable<any> {
    const url = `${this.base_url}/count`;
    return this.http.get<any>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched tenant count`)),
      catchError(this.handleError<Tenant>(`getTenantsCount`))
    );
  }


  /** GET tenant by id. Return `undefined` when id not found */
  getTenantNo404<Data>(id: number): Observable<Tenant> {
    const url = `${this.base_url}/?id=${id}`;
    return this.http.get<Tenant[]>(url).pipe(
      map(tenants => tenants[0]), // returns a {0|1} element array
      tap(h => {
        const outcome = h ? `fetched` : `did not find`;
        this.log(`${outcome} tenant id=${id}`);
      }),
      catchError(this.handleError<Tenant>(`getTenant id=${id}`))
    );
  }

  /** GET tenant by id. Will 404 if id not found */
  getTenant(id: number): Observable<Tenant> {
    const url = `${this.base_url}/${id}`;
    return this.http.get<Tenant>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched tenant id=${id}`)),
      catchError(this.handleError<Tenant>(`getTenant id=${id}`))
    );
  }

  /** GET tenant by id. Will 404 if id not found */
  getTenantPreference(id: number): Observable<any> {
    const url = `${this.base_url}preference/${id}`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched tenant id=${id}`)),
      catchError(this.handleError<any>(`getTenant id=${id}`))
    );
  }

  /** GET preference by tenant id and tag. Will 404 if tenant or preference with tag not found */
  getTenantPreferenceByTenantIdAndTag(tenantId: String, tag: String): Observable<any> {
    const url = `${this.base_url}preference/${tenantId}/preferencekey/${tag}`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched preference with tenant id=${tenantId} and tag = ${tag}`)),
      catchError(this.handleError<any>(`getTenantPreferenceByTenantIdAndTag tenant id=${tenantId}, tag = ${tag}`))
    );
  }

  /** GET tenant by module. Will 404 if id not found */
  getUserByTenant(id: number, _module: string, search?: string): Observable<User[]> {
    let url = `${this.base_url}/${id}/${_module}`;
    if (search) {
      url += `?search=${search}`;
    }
    console.log(search)
    return this.http.get<User[]>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched tenant id=${id}`)),
      catchError(this.handleError<User[]>(`getTenant id=${id}`))
    );
  }

  /** GET tenant by module. Will 404 if id not found */
  getUserByTenantPaged(id: number, query?): Observable<User[]> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        if (query[val] !== null && query[val] !== undefined && query[val] !== '') {
          params = params.append(val, query[val]);
        }
      });
    }

    const url = `${this.base_url}/${id}/userspaged`;
    return this.http.get<User[]>(url, {headers: this.headers, params: params}).pipe(
      tap(_ => this.log(`fetched tenant id=${id}`)),
      catchError(this.handleError<User[]>(`getTenant id=${id}`))
    );
  }


  /** GET tenant by module. Will 404 if id not found */
  getStockingByTenant(id: number, _module: string): Observable<StockingPoint[]> {
    const url = `${this.base_url}/${id}/${_module}`;
    return this.http.get<StockingPoint[]>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched tenant id=${id}`)),
      catchError(this.handleError<StockingPoint[]>(`getTenant id=${id}`))
    );
  }

  /** GET tenant by module. Will 404 if id not found */
  getModuleByTenant(id: number): Observable<any> {
    let params = new HttpParams();
    const url = `${this.base_url}/${id}/module`;
    return this.http.get<any>(url, {params, headers: this.headers}).pipe(
      tap(_ => this.log(`fetched tenant module id=${id}`)),
      catchError(this.handleError(`getTenantModule id=${id}`))
    );
  }


  /* GET tenants whose name contains search term */
  searchTenants(term: string): Observable<Tenant[]> {
    if (!term.trim()) {
      // if not search term, return empty tenant array.
      return of([]);
    }
    return this.http.get<Tenant[]>(`api/tenants/?name=${term}`).pipe(
      tap(_ => this.log(`found tenants matching "${term}"`)),
      catchError(this.handleError<Tenant[]>('searchTenants', []))
    );
  }

  //////// Save methods //////////

  /** POST: add a new tenant to the server */
  addTenant(tenant: Tenant): Observable<Tenant> {
    return this.http.post<Tenant>(this.base_url , tenant, {headers: this.headers}).pipe(
      tap((tenant: Tenant) => this.log(`added tenant w/ id=${tenant.id}`)),
      catchError(this.handleError<Tenant>('addTenant'))
    );
  }

  /** POST: add existing user association with new tenant to the server */
  associateUserTenant(tenant: any): Observable<any> {
    return this.http.post<any>(this.base_url + '/batch/userassociate', tenant, {headers: this.headers}).pipe(
      tap((tenant: any) => this.log(`added tenant w/ id=${tenant.id}`)),
      catchError(this.handleError<any>('addTenant'))
    );
  }

  /** POST: add a new tenant to the server */
  activateTenant(data): Observable<any> {
    let path = window.location.href;
    return this.http.post<any>(this.base_url + '/activationNotification', {
      activationUrl: path.replace(window.location.pathname, '/login?next=activation'),
      tenantId: data.id,
      userId: data.value
    }, {headers: this.headers}).pipe(
      tap(_ => this.log(`Activated`)),
      catchError(this.handleError<any>('Activate account'))
    );
  }

  /** DELETE: delete the tenant from the server */
  deleteTenant(tenant: Tenant | number): Observable<Tenant> {
    const id = typeof tenant === 'number' ? tenant : tenant.id;
    const url = `${this.base_url}/${id}`;

    return this.http.delete<Tenant>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`deleted tenant id=${id}`)),
      catchError(this.handleError<Tenant>('deleteTenant'))
    );
  }

  /** PUT: update the tenant on the server */
  updateTenant(tenant: Tenant): Observable<any> {
    return this.http.put(`${this.base_url}/${tenant.id}`, tenant, {headers: this.headers}).pipe(
      tap(_ => this.log(`updated tenant id=${tenant.id}`)),
      catchError(this.handleError<any>('updateTenant'))
    );
  }

  /** PUT: update the tenant on the server */
  updateTenantPreference(id, tenant): Observable<any> {
    return this.http.put(`${this.base_url}preference/${id}`, tenant, {headers: this.headers}).pipe(
      tap(_ => this.log(`updated tenant `)),
      catchError(this.handleError<any>('updateTenant'))
    );
  }

  /** PUT: update the tenant on the server */

  /*updateTenantPreferenceValue(id, value): Observable<any> {
    return this.http.put(`${this.base_url}preference/${id}/value/${value}`, { headers: this.headers }).pipe(
      tap(_ => this.log(`updated tenant `)),
      catchError(this.handleError<any>('updateTenant'))
    );
  }*/

  /** Patch: update the tenant on the server */
  editTenant(data): Observable<any> {
    return this.http.patch(`${this.base_url}/${data.id}/${data.param}/${data.value}`, {}, {headers: this.headers}).pipe(
      tap(_ => {
        if (data.activation) {
          this.activateTenant(data).subscribe(result => {
            console.log('Activation sent')
          });
        }
      }),
      catchError(this.handleError<any>('updateTenant'))
    );
  }

  disassociateModuleTenant(data): Observable<any> {

    return this.http.delete(`${this.base_url}/${data.id}/${data.param}/${data.value}`, {headers: this.headers}).pipe(
      tap(_ => {
        if (data.activation) {
          this.activateTenant(data).subscribe(result => {
            console.log('Activation sent')
          });
        }
      }),
      catchError(this.handleError<any>('updateTenant'))
    );
  }

  patchUserToTenant(tenantId: number, userId: number): Observable<any> {
    const url = `${this.base_url}/${tenantId}/user/${userId}`;
    return this.http.patch<any>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`patchUserToTenant`)),
      catchError(this.handleError<any>(`patchUserToTenant`))
    );
  }

  deleteUserFromTenant(tenantId: number, userId: number): Observable<any> {
    const url = `${this.base_url}/${tenantId}/user/${userId}`;
    return this.http.delete<any>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`deleteUserFromTenant`)),
      catchError(this.handleError<any>(`deleteUserFromTenant`))
    );
  }

}
