
<div class="flex flex-row justify-between my-8 mx-4">
  <div class="flex flex-row justify-start gap-3 " >
    <div class="text-xl text-black-dark-light">
      Home
    </div>
    <div class="text-xl font-black text-black-dark-light">
      /
    </div>
    <div class="text-xl text-black-dark-light text-orange-600">
      Projects
    </div>
  </div>


  <button class="btn btn-dlight flex-grow-0 gap-3" routerLink="/ticketing/newProject" >
    New Project
    <svg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="-1.667 -3.333 20 20" width="20" height="20"><path d="M14.167 3.333H7.834l-.401-1.1a1 1 0 0 0-.766-.566H2.5a.833.833 0 0 0-.833.833v8.333a.833.833 0 0 0 .833.834h11.667a.833.833 0 0 0 .833-.834V4.166a.833.833 0 0 0-.833-.833M9.033 1.667h5.133a2.5 2.5 0 0 1 2.501 2.5v6.667a2.5 2.5 0 0 1-2.5 2.499H2.5a2.5 2.5 0 0 1-2.5-2.5V2.5A2.5 2.5 0 0 1 2.5 0h4.167c1.1 0 2 .7 2.367 1.667"/></svg>
  </button>

</div>

<div class="bg-white ticket-table mt-10" style="border-radius:20px">
  <div class="grid grid-cols-4 py-3 ticket-row">
    <div class="col-span-1 pl-8 my-auto">
      NAME
    </div>
    <div class="col-span-1 my-auto">
      WORKFLOWS
    </div>
    <div class="col-span-1 my-auto">
      DATE
    </div>
    <div class="col-span-1 my-auto">
      ACTIONS
    </div>
  </div>

  <div class="mt-5 mb-5" *ngIf="loadingTable">
    <div class="flex flex-row justify-center w-full  gap-4">
      <span class="m-auto mb-10 inline-block h-14 w-14 animate-spin rounded-full border-8 border-[#f1f2f3] border-l-dlight align-middle"></span>
    </div>
  </div>
  <ng-container *ngIf="!loadingTable">
    <div  class="grid grid-cols-4 py-3 ticket-row cursor-pointer" *ngFor="let project of projects"  >
      <div class="col-span-1 my-auto pl-8 text-black">
        {{project.name}}
      </div>

      <div class="col-span-1 my-auto text-black">
        {{project.numberOfWorkflows}}
      </div>

      <div class="col-span-1 my-auto  gap-1 text-black-50">
        <div>
          {{ moment(project.date).format('DD MMMM YYYY') }},
        </div>
        <div class="text-gray-400">
          {{ moment(project.date).format('HH:mm') }}
        </div>
      </div>

      <div class="col-span-1 my-auto">

        <button  [routerLink]="'/ticketing/updateProject/' + project.id" type="button" class="btn btn-sm rounded-full btn-outline-dlight "><svg _ngcontent-ydi-c23="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="w-5 h-5 ltr:mr-1.5 rtl:ml-1.5 ng-tns-c23-15"><circle _ngcontent-ydi-c23="" cx="12" cy="12" r="3" class="ng-tns-c23-15"></circle><path _ngcontent-ydi-c23="" d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z" class="ng-tns-c23-15"></path></svg>&nbsp;&nbsp;Edit </button>
      </div>



    </div>
  </ng-container>


</div>

