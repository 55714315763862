import {Action} from '@ngrx/store';

export enum FaultActionTypes {
  faultAdd = '[Fault] Add',
  faultAddSuccess = '[Fault] Add Success',
  faultError = '[Fault] Add Error',
  faultGetAllForWorkOrder = '[Fault] Get All By Work Order',
  faultGetAllForWorkOrderSuccess = '[Fault] Get All By Work Order Success',
  faultGetFaultById = '[Fault] get fault by id',

  faultDeleteById = '[Fault] delete fault by id',
  faultGetFaultByIdSuccess = '[Fault] get fault by id success',

  faultDeleteFaultByIdSuccess = '[Fault] delete fault by id success',
  faultUpdateFault = '[Fault] update fault',
  faultUpdateFaultSuccess = '[Fault] update fault success',
}

export class AddFault implements Action {
  readonly type = FaultActionTypes.faultAdd;

  constructor(public payload) {
  }
}

export class AddFaultSuccess implements Action {
  readonly type = FaultActionTypes.faultAddSuccess;

  constructor(public payload: any) {
  }
}

export class FaultError implements Action {
  readonly type = FaultActionTypes.faultError;

  constructor(public payload: any) {
  }
}

export class GetAllFaultForWorkOrder implements Action {
  readonly type = FaultActionTypes.faultGetAllForWorkOrder;

  constructor(public payload: number) {
  }
}

export class GetAllFaultForWorkOrderSuccess implements Action {
  readonly type = FaultActionTypes.faultGetAllForWorkOrderSuccess;

  constructor(public payload: any) {
  }
}


export class GetFaultById implements Action {
  readonly type = FaultActionTypes.faultGetFaultById;
  constructor(public payload: any) {}
}

export class DeleteFaultById implements Action {
  readonly type = FaultActionTypes.faultDeleteById;
  constructor(public payload: any) {}
}

export class GetFaultByIdSuccess implements Action {
  readonly type = FaultActionTypes.faultGetFaultByIdSuccess;
  constructor(public payload: any) {}
}

export class DeleteFaultByIdSuccess implements Action {
  readonly type = FaultActionTypes.faultDeleteFaultByIdSuccess;
  constructor(public payload: any) {}
}

export class UpdateFault implements Action {
  readonly type = FaultActionTypes.faultUpdateFault;
  constructor(public payload: any) {}
}

export class UpdateFaultSuccess implements Action {
  readonly type = FaultActionTypes.faultUpdateFaultSuccess;
  constructor(public payload: any) {}
}

export type FaultsActions =
  | AddFault
  | AddFaultSuccess
  | FaultError
  | GetAllFaultForWorkOrder
  | GetAllFaultForWorkOrderSuccess
  | GetFaultById
  | GetFaultByIdSuccess
  | UpdateFault
  | UpdateFaultSuccess



