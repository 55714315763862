import {Injectable} from '@angular/core';
import {CoreAftersalesService} from '../core-aftersales.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from "rxjs";
import {catchError, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReturnTicketService extends CoreAftersalesService {

  constructor(private http: HttpClient) {
    super(http, 'returnticket');
  }

  /** GET returned tickets from the server */
  getReturnTickets(query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        params = params.append(val, query[val]);
      });
    }
    return this.http.get<any>(this.base_url, {
      params,
      headers: this.headers
    }).pipe(
      tap(tickets => this.log(`fetched tickets`)),
      catchError(this.handleError('getTickets', []))
    );
  }

  commentTicket(id, data): Observable<any> {
    const url = `${this.base_url}/${id}/notes`
    return this.http.post(url, data, {observe: 'response', responseType: 'text'})
  }

  saveReturnTicket(data): Observable<any> {
    this.setHeader(true);
    return this.http.post<any>(this.base_url , data, {headers: this.headers}).pipe(
      tap(_ => this.log(`return ticket`)),
      catchError(this.handleError('returnTicket', []))
    );
  }

  updateSalvageValue(id, data): Observable<any> {
    const url = `${this.base_url}/item/${id}/updatesalvagevalue`
    return this.http.post<any>(url, data, {headers: this.headers}).pipe(
      tap(_ => this.log(`return ticket`)),
      catchError(this.handleError('returnTicket', []))
    );
  }

  getTicketsByContractProductId(id: number): Observable<any> {
    const url = `${this.base_url}/contractproduct/${id}`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched returned tickets id=${id}`)),
      catchError(this.handleError(`getReturnedTickets id=${id}`))
    );
  }

  getProductStates(): Observable<any> {
    const url = `${this.base_url}/productstates`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched product state tickets`)),
      catchError(this.handleError(`getProductStates`))
    );
  }

  getRepossessionCategories(): Observable<any> {
    const url = `${this.base_url}/categories/repossession`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched product state tickets`)),
      catchError(this.handleError(`getProductStates`))
    );
  }

  getReturnCategories(): Observable<any> {
    const url = `${this.base_url}/categories/return`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched product state tickets`)),
      catchError(this.handleError(`getProductStates`))
    );
  }


  downloadFile(id: number): Observable<any> {
    const url = `${this.base_url}/item/${id}/image`;
    return this.http.get(url, {headers: this.headers, responseType: 'blob'}).pipe(
      tap(_ => this.log(`fetched item image`)),
      catchError(this.handleError<any>(`fetched item image`))
    );
  }

  approveTicket(id: number): Observable<any> {
    return this.http.post<any>(`${this.base_url}/${id}/approve`, {}, {headers: this.headers}).pipe(
      tap(types => this.log(`approve return ticket`)),
      catchError(this.handleError('approveReturnTicket', []))
    );
  }

  rejectTicket(id: number): Observable<any> {
    return this.http.post<any>(`${this.base_url}/${id}/reject`, {}, {headers: this.headers}).pipe(
      tap(types => this.log(`reject return ticket`)),
      catchError(this.handleError('rejectReturnTicket', []))
    );
  }

  closeTicket(id: number): Observable<any> {
    return this.http.post<any>(`${this.base_url}/${id}/close`, {}, {headers: this.headers}).pipe(
      tap(types => this.log(`close return ticket`)),
      catchError(this.handleError('rejectReturnTicket', []))
    );
  }


  assignTicket(id: number, repaidCenterId: number): Observable<any> {
    return this.http.post<any>(`${this.base_url}/${id}/reassign/${repaidCenterId}`, {}, {headers: this.headers}).pipe(
      tap(types => this.log(`reassign return ticket`)),
      catchError(this.handleError('reassignReturnTicket', []))
    );
  }

  cancelTicket(id: number): Observable<any> {
    return this.http.post<any>(`${this.base_url}/${id}/cancel`, {}, {headers: this.headers}).pipe(
      tap(cancelTicket => this.log(`cancel return ticket`)),
      catchError(this.handleError('cancelTicket', []))
    );
  }

  getTicketStates(): Observable<any> {
    const url = `${this.base_url}/ticketstates`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched ticket state`)),
      catchError(this.handleError(`getTicketStates`))
    );
  }

  getTicketDataForWorkOrderCreation(itemId: number): Observable<any> {
    const url = `${this.base_url}/${itemId}/data`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched ticket data`)),
      catchError(this.handleError(`getTicketDataForWorkOrderCreation`))
    );
  }

  getItem(itemId: number): Observable<any> {
    const url = `${this.base_url}/item/${itemId}`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched item data`)),
      catchError(this.handleError(`getItem`))
    );
  }

  updateTicketItems(ticketId: number, items): Observable<any> {
    const url = `${this.base_url}/${ticketId}/items`;
    return this.http.post(url, items, {headers: this.headers}).pipe(
      tap(_ => this.log(`updated items`)),
      catchError(this.handleError(`updateTicketItems`))
    );
  }

  assignTechnician(id: number, technicianId: number): Observable<any> {
    return this.http.patch<any>(`${this.base_url}/${id}/technician/${technicianId}`, {}, {headers: this.headers}).pipe(
      tap(_ => this.log(`assign technician ticket`)),
      catchError(this.handleError('assign technician', []))
    );
  }
}
