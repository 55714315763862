import {Injectable} from '@angular/core';
import {CoreAftersalesService} from '../core-aftersales.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {catchError, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {WorkOrder} from '@appModels/core-aftersales/work-order';
import {RepairCentre} from '@appModels/core-aftersales/repaircentre';
import {WorkOrderL3} from '@appModels/core-aftersales/work-order-l3';

@Injectable({
  providedIn: 'root'
})
export class WorkOrderL3Service extends CoreAftersalesService {

  constructor(private http: HttpClient) {
    super(http, 'workorder/module');
  }

  checkWorkOrder(moduleId: number, workOrderId: number, identification: string, value?: string) {
    let params = new HttpParams();
    params = params.append('workOrderId', workOrderId.toString());
    params = params.append('identification', identification);
    if (value) {
      params = params.append('value', value);
    }

    return this.http.get(this.base_url + '/' + moduleId + '/check',
      {params: params, headers: this.headers}).pipe(
      tap(countries => this.log(`checked work order for ${moduleId} and ${workOrderId}`)),
      catchError(this.handleError('checkWorkOrder', []))
    );
  }

  getWorkOrders(query?): Observable<any> {
    const tenantId = localStorage.getItem('tenant');
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        if (query[val] !== null && query[val] !== undefined && query[val] !== '' && val != 'repossessionWorkOrderSearch') {
          params = params.append(val, query[val]);
        }
      });
    }

    params = params.append('tenantId', tenantId);

    return this.http.get(this.base_url + '/all',
      {params: params, headers: this.headers}).pipe(
      tap(countries => this.log(`fetched work orders`)),
      catchError(this.handleError('getWorkOrders', []))
    );
  }

  /** POST: save work order to the server */
  saveWorkOrder(workOrder): Observable<any> {
    return this.http.post(this.base_url , workOrder, {headers: this.headers}).pipe(
      tap((wo: WorkOrder) => this.log(`added work order w/ id=${wo.id}`)),
      catchError(this.handleError<WorkOrderL3>('saveWorkOrder'))
    );
  }

  /** POST: close work order */
  closeAction(id: number, comment: string): Observable<any> {
    const url = `${this.base_url}/${id}/close`;
    return this.http.post(url, comment, {headers: this.headers}).pipe(
      tap(_ => this.log(`closed wo id=${id}`)),
      catchError(this.handleError(`closeAction id=${id}`))
    );
  }

  /** GET: get work order from the server */
  getWorkOrder(id): Observable<any> {
    return this.http.get(this.base_url + '/' + id, {headers: this.headers}).pipe(
      tap((wo: WorkOrderL3) => this.log(`got work order w/ id=${wo.id}`)),
      catchError(this.handleError<WorkOrderL3>('getWorkOrder'))
    );
  }

  /** GET: get previpus work orders from the server */
  getPreviousWorkOrders(id): Observable<any> {
    return this.http.get(this.base_url + '/' + id + '/previous', {headers: this.headers}).pipe(
      tap((wo: WorkOrderL3) => this.log(`got previous work orders for w/ id=${wo.id}`)),
      catchError(this.handleError<WorkOrderL3>('getPreviousWorkOrders'))
    );
  }

  assignToTechnician(id: number, technicianId: number): Observable<any> {
    const url = `${this.base_url}/${id}/technician/${technicianId}`;
    return this.http.patch(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`assigjed wo id=${id}`)),
      catchError(this.handleError(`assignToTechnician id=${id} technicianId=${technicianId}`))
    );
  }

  assignAction(id: number, actionId: number): Observable<any> {
    const url = actionId != null ? `${this.base_url}/${id}/action/${actionId}` : `${this.base_url}/${id}/action`;
    return this.http.patch(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`assigned action to wo id=${id}`)),
      catchError(this.handleError(`assignAction id=${id} actionId=${actionId}`))
    );
  }

  changeState(id: number, stateId: number): Observable<any> {
    const url = `${this.base_url}/${id}/changeState/${stateId}`;
    return this.http.patch(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`change state of wo id=${id}`)),
      catchError(this.handleError(`changeState id=${id} stateId=${stateId}`))
    );
  }

  /** POST: add note */
  addNote(id, note): Observable<any> {
    return this.http.post(this.base_url + '/' + id + '/note', note, {headers: this.headers}).pipe(
      tap((wo: WorkOrder) => this.log(`added note to work order w/ id=${wo.id}`)),
      catchError(this.handleError<RepairCentre>('addNote'))
    );
  }

  /** POST: repair action */
  repairAction(id, other): Observable<any> {
    return this.http.post(this.base_url + '/' + id + '/repair', {
      otherRepairs: other,
    }, {headers: this.headers}).pipe(
      tap((wo: WorkOrder) => this.log(`repaired work order w/ id=${wo.id}`)),
      catchError(this.handleError<RepairCentre>('repairAction'))
    );
  }
}
