import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';

import {Role} from '@appModels/core-identity/role/role';
import {RoleActions, RoleActionTypes} from '@appStore/actions/core-identity/role/role.actions';

export interface State extends EntityState<Role> {
  loaded: boolean;
  loading: boolean;
  error: any;
  selectedRoleId: number;
  selectedRole: Role;
  searchRoles: Role[];
}

export const adapter: EntityAdapter<Role> = createEntityAdapter<Role>();

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
  selectedRoleId: null,
  error: null,
  searchTerm: '',
  searchRoles: null,
  selectedRole: null
});

export function reducer(state = initialState, action: RoleActions): State {
  switch (action.type) {
    case RoleActionTypes.roleGetRoles:
    case RoleActionTypes.roleAddRole:
    case RoleActionTypes.roleDeleteRole:
    case RoleActionTypes.roleUpdateRole:
    case RoleActionTypes.roleSearchRoles:
    case RoleActionTypes.roleGetRoleById:

      return {
        ...state,
        loading: true
      };

    case RoleActionTypes.roleGetRolesSuccess:
      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });

    case RoleActionTypes.roleGetRoleByIdSuccess:
      return {
        ...state,
        selectedRoleId: action.payload.id,
        selectedRole: action.payload,
        loading: false
      };

    case RoleActionTypes.roleAddRoleSuccess:
      return adapter.addOne(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });

    case RoleActionTypes.roleUpdateRoleSuccess:
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state,
          loading: false,
          loaded: true
        }
      );


    case RoleActionTypes.roleDeleteRoleSuccess: {
      return adapter.removeOne(action.payload.id, {
        ...state,
        loading: false,
        loaded: true
      });
    }

    case RoleActionTypes.roleSearchRolesSuccess:
      return {
        ...state,
        searchRoles: action.payload,
        loading: false
      };

    case RoleActionTypes.roleSearchRolesReset:
      return {
        ...state,
        searchRoles: null
      };

    case RoleActionTypes.roleError:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload
      };

    default:
      return state;
  }
}

export const roleEntitySelectors = adapter.getSelectors();
