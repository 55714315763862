import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getPageStore = createFeatureSelector('page');

export const getPageEntities = createSelector(
  getPageStore,
  fromReducers.page.pageEntitySelectors.selectAll
);

export const getPages = createSelector(getPageEntities, entities => {
  return Object.values(entities);
});

export const getTopPages = createSelector(getPageEntities, entities => {
  return Object.values(entities).slice(1, 5);
});

export const getKnowledgePaging = createSelector(
  getPageStore,
  (pageStore: fromReducers.page.State) => pageStore.paging
);

export const getPagesLoaded = createSelector(
  getPageStore,
  (pageStore: fromReducers.page.State) => pageStore.loaded
);

export const getPagesLoading = createSelector(
  getPageStore,
  (pageStore: fromReducers.page.State) => pageStore.loading
);

export const getSelectedPageId = createSelector(
  getPageStore,
  (pageStore: fromReducers.page.State) => pageStore.selectedPageId
);

// export const getSearchPages = createSelector(
//   getPageStore,
//   (pageStore: fromReducers.page.State) => pageStore.searchPages
// );

export const getSearchPages = createSelector(getPageEntities, entities => {
  return Object.values(entities);
});

export const getPageById = createSelector(
  getPageStore,
  (pageStore: fromReducers.page.State) => pageStore.selectedPage
);

export const getPagesError = createSelector(
  getPageStore,
  (pageStore: fromReducers.page.State) => pageStore.error
);
