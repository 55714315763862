import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getTestFlowStore = createFeatureSelector('testFlow');

export const getTestflowEntities = createSelector(
  getTestFlowStore,
  fromReducers.testFlow.testFlowEntitySelectors.selectAll
);

export const getTestFlowsPaging = createSelector(
  getTestFlowStore,
  (testFlowStore: fromReducers.testFlow.State) => testFlowStore.paging
);

export const getTestFlows = createSelector(getTestflowEntities, entities => {
  return Object.values(entities);
});


export const getTestFlowsLoaded = createSelector(
  getTestFlowStore,
  (testFlowStore: fromReducers.testFlow.State) => testFlowStore.loaded
);

export const getTestFlowsLoading = createSelector(
  getTestFlowStore,
  (testFlowStore: fromReducers.testFlow.State) => testFlowStore.loading
);

export const getSelectedTestFlowId = createSelector(
  getTestFlowStore,
  (testFlowStore: fromReducers.testFlow.State) => testFlowStore.selectedTestFlowId
);


export const getTestFlowById = createSelector(
  getTestFlowStore,
  (testFlowStore: fromReducers.testFlow.State) => testFlowStore.selectedTestFlow
);

export const getTestFlowError = createSelector(
  getTestFlowStore,
  (testFlowStore: fromReducers.testFlow.State) => testFlowStore.error
);
