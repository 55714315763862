
<div class="flex flex-row justify-between my-8 mx-4">
  <div class="flex flex-row justify-start  gap-3" >
    <div class="text-xl text-black-dark-light">
      Home
    </div>
    <div class="text-xl font-black text-black-dark-light">
      /
    </div>
    <div class="text-xl text-black-dark-light text-orange-600">
      Request Types
    </div>
  </div>


  <button class="btn btn-dlight flex-grow-0 gap-3" routerLink="/ticketing/newRequestType" >
    New Request Type
    <svg width="20" height="20" viewBox="0 0 6.4 6.4" fill="white" xmlns="http://www.w3.org/2000/svg"><path d="M4.5 1.8H.9a.5.5 0 0 0-.5.5v2.8a.5.5 0 0 0 .5.5h3.6a.5.5 0 0 0 .5-.5V2.3a.5.5 0 0 0-.5-.5M4.4 5H1V2.4h3.4ZM6 1.3v3.1a.3.3 0 0 1-.6 0v-3H1.6a.3.3 0 0 1 0-.6h3.9a.5.5 0 0 1 .5.5"/></svg>
  </button>

</div>

<div class="bg-white ticket-table mt-10" style="border-radius:20px">
  <div class="grid grid-cols-6 py-3 ticket-row">
    <div class="col-span-1 my-auto pl-8">
      NAME
    </div>
    <div class="col-span-1 my-auto">
      TICKET TYPE
    </div>
    <div class="col-span-1 my-auto">
      DEFAULT TEAM
    </div>
    <div class="col-span-1 my-auto">
      DESCRIPTION
    </div>
    <div class="col-span-1 my-auto">
      DATE
    </div>
    <div class="col-span-1 my-auto">
      ACTIONS
    </div>
  </div>

  <div class="mt-5 mb-5" *ngIf="loadingTable">
    <div class="flex flex-row justify-center w-full  gap-4">
      <span class="m-auto mb-10 inline-block h-14 w-14 animate-spin rounded-full border-8 border-[#f1f2f3] border-l-dlight align-middle"></span>
    </div>
  </div>
  <ng-container *ngIf="!loadingTable">
    <div  class="grid grid-cols-6 py-3 ticket-row cursor-pointer" *ngFor="let requestType of requestTypes"  >
      <div class="col-span-1 my-auto pl-8 text-black">
        {{requestType.name}}
      </div>

      <div class="col-span-1 my-auto text-black">
        {{requestType.ticketTypeName}}
      </div>

      <div class="col-span-1 my-auto ">
        {{requestType.team}}
      </div>

      <div class="col-span-1 my-auto cursor-pointer assignee-box pr-3" [tooltip]="requestType.description" >
        {{requestType.description}}
      </div>

      <div class="col-span-1 my-auto gap-1 text-black-50">
        <div>
          {{ moment(requestType.date).format('DD MMMM YYYY') }},
        </div>
        <div class="text-gray-400">
          {{ moment(requestType.date).format('HH:mm') }}
        </div>
      </div>

      <div class="col-span-1 my-auto">
        <button  [routerLink]="'/ticketing/requestType/' + requestType.id" type="button" class="btn btn-sm rounded-full btn-outline-dlight "><svg _ngcontent-ydi-c23="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="w-5 h-5 ltr:mr-1.5 rtl:ml-1.5 ng-tns-c23-15"><circle _ngcontent-ydi-c23="" cx="12" cy="12" r="3" class="ng-tns-c23-15"></circle><path _ngcontent-ydi-c23="" d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z" class="ng-tns-c23-15"></path></svg>&nbsp;&nbsp;Edit </button>
      </div>


    </div>
  </ng-container>


</div>

