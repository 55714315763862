import {Action} from '@ngrx/store';

export enum PermissionChannelGroupActionTypes {
  assignPermissionsFromChannelGroupToRole = '[PermissionChannelGroup] get',
  assignPermissionsFromChannelGroupToRoleSuccess = '[PermissionChannelGroup] get roles success',
  assignPermissionsFromChannelGroupToRoleError = '[PermissionChannelGroup] error'
}

export class AssignPermissionsFromChannelGroupToRole implements Action {
  readonly type = PermissionChannelGroupActionTypes.assignPermissionsFromChannelGroupToRole;

  constructor(public payload: number[], payload2: number) {
  }

}

export class AssignPermissionsFromChannelGroupToRoleSuccess implements Action {
  readonly type = PermissionChannelGroupActionTypes.assignPermissionsFromChannelGroupToRoleSuccess;
}

export class AssignPermissionsFromChannelGroupToRoleError implements Action {
  readonly type = PermissionChannelGroupActionTypes.assignPermissionsFromChannelGroupToRoleError;

  constructor(public payload: any) {
  }
}

export type PermissionChannelGroupActions =
  | AssignPermissionsFromChannelGroupToRole
  | AssignPermissionsFromChannelGroupToRoleSuccess
  | AssignPermissionsFromChannelGroupToRoleError;
