import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';

import {Apollo} from 'apollo-angular';
import gql from 'graphql-tag';

import {Customer} from '@appModels/core-crm/customer/customer';

import {CoreCrmService} from '../core-crm.service';

const CustomerDetail = gql`
query CustomerDetail($uniqueIdentifier: String!, $authorizationToken: String! ) {
  customerByUniqueIdentifier(uniqueIdentifier: $uniqueIdentifier, authorizationToken: $authorizationToken)
  {
    id
    uniqueIdentifier
    firstName
    lastName
    email1
    email2
    telephone1
    telephone2
    gender
    customerProfilePhoto
    customerDelinquent
    customerStatus{
      name
    }
    language{
      languageName
    }
    physicalAddress
    identificationValue
    customerIdentificationType{
      name
    }
    telephone1Status{
      name
      description
    }
    telephone2Status{
      name
      description
    }
    createdAt
    updateUser
    personalInfoCompletePercentage
    additionalCustomerInfoCompletePercentage
    age
    dateOfBirth
    occupationOther
    currentSourcesOfLightOther
    payingNumbers
    eligibleForUpsell
    upsellEscalationExists
    preferredMeansOfCommunication
    preferredTimeOfContact
    countryAdministrativeZone{
      countryAdministrativeZoneName
      countryAdministrativeZoneTag
      countryAdministrativeZoneLevel
      parent{
        countryAdministrativeZoneName
        countryAdministrativeZoneTag
        countryAdministrativeZoneLevel
        parent{
          countryAdministrativeZoneName
          countryAdministrativeZoneTag
          countryAdministrativeZoneLevel
          parent{
            countryAdministrativeZoneName
            countryAdministrativeZoneTag
            countryAdministrativeZoneLevel
          }
        }
      }
    }
    contractProduct{
      id
      contractId
      walletBalance
      createdAt
      rate{
        name
        id
      }
      contractBlockType{
        name
      }
      creditExpirationDate
      stockingPoint{
        businessName
        id
      }
      inventoryProduct{
        accountNumber
        salvageValue
      }
      contractProductStatus{
        name
      }
      contractBlockType{
        name
      }
      tenantProductCatalogue{
        productDisplayName
        id
      }
      salesStaffName
      informationCompletenessPercentage
      balanceDue
      totalPaid
      productImageUrl
      currentTokenIndex
      cummulativeRate
      cumulativeTotalProductPrice
      paymentProfile
      paymentCategorization
      minimumMonthlyPayment
      mmpCatchUpDays
      totalNumberOfDaysWithheld
      daysToWithhold
      amountToWithhold
      accumulativeDaysBehind
      consecutiveDaysBehind
      percentWithheld
      promiseToPayDate
      anniversaryDate30
      graceDays
      additionalInfos{
        name
        value
      }
      dncDisposition
    }
  }}`;

@Injectable({providedIn: 'root'})
export class CustomerService extends CoreCrmService {


  constructor(
    private http: HttpClient,
    private apollo: Apollo
  ) {
    super(http, 'customer');
  }

  /**
   * GraphQL Version
   *
   * @param {*} [query]
   * @returns {Observable<any>}
   * @memberof CustomerService
   */
  getCustomerGraph(id: any): Observable<any> {
    return this.apollo.watchQuery({
      query: CustomerDetail,
      variables: {
        uniqueIdentifier: id,
        authorizationToken: JSON.parse(localStorage.getItem('authorization')).authorization
      },
    }).valueChanges;
  }

  /** GET customers from the server */
  getCustomers(query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        params = params.append(val, query[val]);
      });
    }
    return this.http.get<any>(this.base_url + 's/tenant/' + localStorage.getItem('tenant'), {
      params,
      headers: this.headers
    }).pipe(
      tap(customers => this.log(`fetched customers`)),
      catchError(this.handleError('getCustomers', []))
    );
  }

  /** GET search customers from the server */
  searchTenantCustomers(query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        params = params.append(val, query[val]);
      });
    }
    return this.http.get<any>(this.base_url + 's/tenant/search/' + localStorage.getItem('tenant'), {
      params,
      headers: this.headers
    }).pipe(
      tap(customers => this.log(`fetched customers`)),
      catchError(this.handleError('getCustomers', []))
    );
  }

  /** GET customer by id. Return `undefined` when id not found */
  getCustomerNo404<Data>(id: any): Observable<Customer> {
    const url = `${this.base_url}/?id=${id}`;
    return this.http.get<Customer[]>(url).pipe(
      map(customers => customers[0]), // returns a {0|1} element array
      tap(h => {
        const outcome = h ? `fetched` : `did not find`;
        this.log(`${outcome} customer id=${id}`);
      }),
      catchError(this.handleError<Customer>(`getCustomer id=${id}`))
    );
  }

  /** GET customer document attachment by id. Will 404 if id not found */
  getCustomerDocAttachment(id: number): Observable<any> {
    const url = `${this.base_url}documentattachment/customer/${id}`;
    return this.http.get<any>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched customer id=${id}`)),
      catchError(this.handleError<any>(`getCustomer`, []))
    );
  }

  /** Download customer document attachment by id. Will 404 if id not found */
  downloadCustomerDocAttachment(id: number): Observable<any> {
    const url = `${this.base_url}documentattachment/download/${id}`;
    return this.http.get(url, {headers: this.headers, responseType: 'blob'}).pipe(
      tap(_ => this.log(`fetched customer id=${id}`)),
      catchError(this.handleError<any>(`getCustomer`, []))
    );
  }

  /** Download customer photo by id. Will 404 if id not found */
  downloadCustomerPhoto(id: number): Observable<any> {
    const url = `${this.base_url}/${id}/downloadprofileimage`;
    return this.http.get(url, {headers: this.headers, responseType: 'blob'}).pipe(
      tap(_ => this.log(`fetched customer id=${id}`)),
      catchError(this.handleError<any>(`getCustomer`, []))
    );
  }

  /** Download customer photo by id. Will 404 if id not found */
  downloadImage(key: any): Observable<any> {
    const url = `${this.base_url}/downloadimage?key=${key}`;
    return this.http.get(url, {headers: this.headers, responseType: 'blob'}).pipe(
      tap(_ => this.log(`fetched customer id=${key}`)),
      catchError(this.handleError<any>(`getCustomer`, []))
    );
  }

  /** GET all customers document attachment type by tenant id. Will 404 if id not found */
  getCustomerAttachmentTypeByTenant(): Observable<any> {
    const url = `${this.base_url}documentattachmenttype/tenant/${localStorage.getItem('tenant')}`;
    return this.http.get<any>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched customer id=`)),
      catchError(this.handleError<any>(`getCustomer id=`))
    );
  }

  /** GET customer by uniqueIdentifier. Will 404 if id not found */
  getCustomer(id: any): Observable<Customer> {
    const url = `${this.base_url}/uniqueIdentifier/${id}`;
    return this.http.get<Customer>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched customer id=${id}`)),
      catchError(this.handleError<Customer>(`getCustomer id=${id}`))
    );
  }

  /** GET customer by id. Will 404 if id not found */
  getCustomerById(id: any): Observable<Customer> {
    const url = `${this.base_url}/${id}`;
    return this.http.get<Customer>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched customer id=${id}`)),
      catchError(this.handleError<Customer>(`getCustomer id=${id}`))
    );
  }

  /** GET customer by id. Will 404 if id not found */
  getCustomerDetail(id: any): Observable<any> {
    const url = `${this.base_url}/view/uniqueIdentifier/${id}`;
    return this.http.get<any>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched customer id=${id}`)),
      catchError(this.handleError<any>(`getCustomer id=${id}`))
    );
  }

  getCustomersUniqueIdentifier(identificationType: number, identificationValue: string): Observable<any> {
    const url = `${this.base_url}/getuniqueidentifier?identificationTypeId=${identificationType}&identificationValue=${identificationValue}`;
    return this.http.get<any>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`getCustomersUniqueIdentifier=${identificationValue}`)),
      catchError(this.handleError<any>(`getCustomersUniqueIdentifier=${identificationValue}`))
    );
  }

  /** GET customer by id. Will 404 if id not found */
  getCustomerDetailById(id: any): Observable<any> {
    const url = `${this.base_url}/view/${id}`;
    return this.http.get<any>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched customer id=${id}`)),
      catchError(this.handleError<any>(`getCustomer id=${id}`))
    );
  }

   /** GET customer by id. Will 404 if id not found */
   getCustomerBasic(id: any): Observable<any> {
    const url = `${this.base_url}/basic/${id}`;
    return this.http.get<any>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched customer id=${id}`)),
      catchError(this.handleError<any>(`getCustomerBasic id=${id}`))
    );
  }

  /** GET customer by id. Will 404 if id not found */
  getCustomerPayingNumbers(id: any): Observable<any> {
    const url = `${this.base_url}/payerNumbers/${id}`;
    return this.http.get<any>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched customer payer numbersid=${id}`)),
      catchError(this.handleError<any>(`getCustomerPayernumbers id=${id}`))
    );
  }

  /** GET customer activity history by id. Will 404 if id not found */
  getActivityHistory(id: number, query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      Object.keys(query).forEach(val => {
        params = params.append(val, query[val]);
      });
    }
    const url = `${this.base_url}s/activityHistory/${id}`;
    return this.http.get<any>(url, {params, headers: this.headers}).pipe(
      tap(_ => this.log(`fetched activity history log id=${id}`)),
      catchError(this.handleError(`getActivityHistoryLog id=${id}`))
    );
  }

   /** GET customer payment speed by contract id. Will 404 if id not found */
   getCustomerPaymentSpeed(contractId: number): Observable<any> {
    const url = `${this.base_url}/paymentspeed/contract/${contractId}`;
    return this.http.get<any>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched customer payment speed contractId=${contractId}`)),
      catchError(this.handleError<any>(`getCustomerPaymentSpeed contractId=${contractId}`))
    );
  }

  /** GET customer ussd info by id. Will 404 if id not found */
  getCustomerUSSDInfo(id: number): Observable<any> {
    const url = `${this.base_url}/ussdinfo/${id}`;
    return this.http.get<any>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched customer ussd=${id}`)),
      catchError(this.handleError<any>(`getCustomerUSSD`, []))
    );
  }

  /** GET customer by id. Will 404 if id not found */
  getCustomerPayGoInfo(id: number): Observable<any> {
    const url = `${this.base_url}/paygoinfo/${id}`;
    return this.http.get<any>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched customer paygo info id=${id}`)),
      catchError(this.handleError<any>(`getCustomerPayGoInfo id=${id}`))
    );
  }

  /* GET customers whose name contains search term */
  searchCustomers(term: string): Observable<Customer[]> {
    if (!term.trim()) {
      // if not search term, return empty customer array.
      return of([]);
    }
    return this.http.get<Customer[]>(this.base_url + `/search/${term}`, {headers: this.headers}).pipe(
      tap(_ => this.log(`found customers matching "${term}"`)),
      catchError(this.handleError<Customer[]>('searchCustomers', []))
    );
  }


  //////// Save methods //////////
  addCustomer(customer: Customer): Observable<Customer> {
    let customerData = Object.assign({}, customer)
    delete customerData.formData;
     return this.http.post<Customer>(this.base_url , customerData, {headers: this.headers}).pipe(
      tap((newCustomer: Customer) => {
        // if (customer.formData !== null || customer.formData !== undefined)  {
        //   this.addCustomerPhoto({formData: customer.formData, id: newCustomer.id}).subscribe();
        // }
        this.log(`added customer w/ id=${newCustomer.id}`)
      }),
      catchError(this.handleError<Customer>('addCustomer'))
    );
  }

  /** POST: add customer additional info to the server */
  addCustomerAdditionalInfo(customer): Observable<{}> {
    return this.http.post(`${this.base_url}/additionalInfo/${customer.id}`, customer.value, {headers: this.headers}).pipe(
      tap((res) => this.log(`added customer  info `)),
      catchError(this.handleError('addCustomer'))
    );
  }

  /** POST: add customer photo info to the server */
  addCustomerPhoto(customer): Observable<any> {
    this.setHeader(true);
    return this.http.post(`${this.base_url}/${customer.id}/photo`, customer.formData, {headers: this.headers}).pipe(
      tap(_ => this.log(`updated customer id=${customer.id}`)),
      catchError(this.handleError<any>('updateCustomer'))
    );
  }

  getCrbDetails(id: string): Observable<any> {
    const tenantId = localStorage.getItem('tenant');
    const url = `${this.base_url}/tenant/${tenantId}/identification/${id}/crbDetails`;
    return this.http.get<any>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched CRB details=${id}`)),
      catchError(this.handleError<any>(`getCrbDetails`, []))
    );
  }

  updateCustomerAdditionalInfo(id: number, data: any): Observable<{}> {
    return this.http.post(`${this.base_url}/additionalInfo/update/${id}`, data, {headers: this.headers}).pipe(
      tap((res) => this.log(`added customer  info `)),
      catchError(this.handleError('addCustomer'))
    );
  }

  /** POST: add customer document info to the server */
  addCustomerDocAttachment(customer): Observable<{}> {
    this.setHeader(true)
    return this.http.post(`${this.base_url}documentattachment/customer/${customer.id}/customerdocumentattachmenttype/${customer.attachmentTypeId}`, customer.value, {headers: this.headers}).pipe(
      tap((res) => this.log(`added customer doc attachment `)),
      catchError(this.handleError('addCustomer'))
    );
  }

  /** POST: add customer document type info to the server */
  addCustomerAttachmentType(customer): Observable<{}> {
    return this.http.post(`${this.base_url}documentattachmenttype`, customer, {headers: this.headers}).pipe(
      tap((res) => this.log(`added customer  info `)),
      catchError(this.handleError('addCustomer'))
    );
  }

  /** POST: add customer general info to the server */
  addCustomerGeneralInfo(data): Observable<{}> {
    return this.http.post(`${this.base_url}/generalinformationweb`, data, {headers: this.headers}).pipe(
      tap((res) => this.log(`added customer  info `)),
      catchError(this.handleError('addGeneralInfo'))
    );
  }

  /** POST: add customer living standard info to the server */
  addLivingStandardInfo(data): Observable<{}> {
    return this.http.post(`${this.base_url}/livingstandardmeasureinformationweb`, data, {headers: this.headers}).pipe(
      tap((res) => this.log(`added customer  info `)),
      catchError(this.handleError('addLivingStandard'))
    );
  }

  /** POST: add customer upsell metircs info to the server */
  addUpsellMetricsInfo(data): Observable<{}> {
    return this.http.post(`${this.base_url}/upsellmetricsinformation`, data, {headers: this.headers}).pipe(
      tap((res) => this.log(`added customer  info `)),
      catchError(this.handleError('addUpsellMetrics'))
    );
  }

  /** PUT: update customer general info to the server */
  updateCustomerGeneralInfo(data): Observable<{}> {
    return this.http.put(`${this.base_url}/generalinformationweb/${data.id}`, data, {headers: this.headers}).pipe(
      tap((res) => this.log(`updated customer  info `)),
      catchError(this.handleError('updateGeneralInfo'))
    );
  }

  /** PUT: update customer living standard info to the server */
  updateLivingStandardInfo(data): Observable<{}> {
    return this.http.put(`${this.base_url}/livingstandardmeasureinformationweb/${data.id}`, data, {headers: this.headers}).pipe(
      tap((res) => this.log(`updated customer  info `)),
      catchError(this.handleError('updateLivingStandard'))
    );
  }

  /** PUT: update customer upsell metircs info to the server */
  updateUpsellMetricsInfo(data): Observable<{}> {
    return this.http.put(`${this.base_url}/upsellmetricsinformation/${data.id}`, data, {headers: this.headers}).pipe(
      tap((res) => this.log(`updated customer  info `)),
      catchError(this.handleError('updateUpsellMetrics'))
    );
  }

  /** DELETE: delete the customer from the server */
  deleteCustomer(customer: Customer | number): Observable<Customer> {
    const id = typeof customer === 'number' ? customer : customer.id;
    const url = `${this.base_url}/${id}`;

    return this.http.delete<Customer>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`deleted customer id=${id}`)),
      catchError(this.handleError<Customer>('deleteCustomer'))
    );
  }

  /** PUT: update the customer on the server */
  updateCustomer(customer: Customer): Observable<any> {
    return this.http.put(`${this.base_url}/${customer.id}`, customer, {headers: this.headers}).pipe(
      tap(_ => this.log(`updated customer id=${customer.id}`)),
      catchError(this.handleError<any>('updateCustomer'))
    );
  }

  /** Patch: update the customer on the server */
  editCustomer(data): Observable<any> {
    return this.http.patch(`${this.base_url}/${data.id}/${data.param}/${data.value}`, {}, {headers: this.headers}).pipe(
      tap(_ => this.log(`updated customer id=${data.id}`)),
      catchError(this.handleError<any>('update customer'))
    );
  }

  /** POST: add upsell escalation on the server */
  saveUpsellEscalation(customerId: number, data): Observable<any> {
    const url = `${this.base_url}/${customerId}/upsellEscalation`;
    return this.http.post<any>(url, data, {headers: this.headers}).pipe(
      tap(_ => this.log(`saved upsell escalation for customer id=${customerId}`)),
      catchError(this.handleError(`saveUpsellEscalation id=${customerId}`))
    );
  }

  /** POST: create lead customer from sales staff */
  createLeadCustomerFromSalesStaff(salesStaffId: number) {
    const url = `${this.base_url}/createLeadCustomerFromSalesStaff/${salesStaffId}`;
    return this.http.post<any>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`created customer from sales staff with id=${salesStaffId}`)),
      catchError(this.handleError(`createLeadCustomerFromSalesStaff id=${salesStaffId}`))
    );
  }

  addCommunication(id, data): Observable<{}> {
    return this.http.post(`${this.base_url}/preferredmeansofcommunication/${id}`, data, {headers: this.headers}).pipe(
      tap((res) => this.log(`added preferred means of communication `)),
      catchError(this.handleError('addPreferredMeansOfCommunication'))
    );
  }

  addTime(id, data): Observable<{}> {
    return this.http.post(`${this.base_url}/preferredtimeofcontact/${id}`, data, {headers: this.headers}).pipe(
      tap((res) => this.log(`added preferred time of contact `)),
      catchError(this.handleError('addPreferredTimeOfContact'))
    );
  }

  getCustomerPhoneNumbers(id:number):Observable<String[]>{
    return this.http.get<String[]>(`${this.base_url}/phonenumbers/${id}`);
  }


  savePortableProduct(id, data): Observable<{}> {
    return this.http.post(`${this.base_url}/${id}/portableProduct`, data, {headers: this.headers}).pipe(
      tap((res) => this.log(`savePortableProduct`)),
      catchError(this.handleError('savePortableProduct'))
    );
  }

  getCustomerDigitalContract(contractProductId:number){
    return this.http.get(`${this.base_url}/contract/download/${contractProductId}`, {
      headers: this.headers,
      responseType: 'blob'
    });
  }

  getPhoneNumbers(uniqueIdentifier: any): Observable<any> {
    const url = `${this.base_url}/${uniqueIdentifier}/catchUpNotificationPhoneNumbers`;
    return this.http.get<any>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched customer paygo info id=${uniqueIdentifier}`)),
      catchError(this.handleError<any>(`getCustomerPayGoInfo id=${uniqueIdentifier}`))
    );
  }

  getSignatureAttachment(customerId:any):Observable<any>{
    return this.http.get(`${this.base_url}/attachment/signature/${customerId}`, {headers:this.headers}).pipe(
      tap(_=>this.log("Fetched signature attachments")),
      catchError(this.handleError<any>(`getSignatureAttachments`))
    )
  }

  downloadSignature(customerId:any):Observable<any>{
    return this.http.get(`${this.base_url}/download/signature/${customerId}`, {headers:this.headers, responseType: 'blob'}).pipe(
      tap(_=>this.log("downloadSignature")),
      catchError(this.handleError<any>(`downloadSignature`))
    )
  }

  getContractTags(id: number): Observable<any> {
    const url = `${this.base_url.replace('customer','contractTags')}/contract/${id}`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched contract id=${id}`)),
      catchError(this.handleError<any>(`getContract id=${id}`))
    );
  }

}
