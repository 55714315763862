import { Injectable } from '@angular/core';

import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import * as fromReducer from '@appStore/reducers';
import * as fromSelectors from '@appStore/selectors';
import { LoginRequired } from '@appStore/actions/core-auth/auth/auth.actions';
import { Nav, SubNav } from '@appStore/actions/nav.actions';
import { nav } from '../../config/nav';

export interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}

export const ROUTES: RouteInfo[] = nav.main.ticketing;

@Injectable({ providedIn: 'root' })
export class CoreTicketingGuard  {
  constructor(private store: Store<fromReducer.auth.State>) {}

  canActivate(): Observable<boolean> {
    return this.store.pipe(
      select(fromSelectors.isAuthenticated),
      map(authed => {
        this.store.dispatch(new SubNav([]));
        this.store.dispatch(new Nav(ROUTES));
        if (localStorage.getItem('authorization') !== null) {
          return true;
        }
        this.store.dispatch(new LoginRequired());
        return false ;
      })
    );
  }
}
