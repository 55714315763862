import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import * as fromSelectors from '@appStore/selectors';
import * as fromReducer from '@appStore/reducers';
import {modulesArray} from '../../config/nav';
import {Module} from '@appModels/core-setup/module/module';
import {TenantService} from '@appServices/core-masterdata/tenant/tenant.service';
import { PermissionResolver } from '@appServices/permission-resolver/permission-resolver.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  modules$: Observable<Module[]>;

  modules = [];

  myModules = [];

  loading = false;

  @Input()
  isCountryOpen: Boolean = false;

  @Output() isCountryOpenChange: EventEmitter<Boolean> = new EventEmitter<Boolean>();

  @Input()
  isOpen: Boolean = false;

  @Output() isOpenChange: EventEmitter<Boolean> = new EventEmitter<Boolean>();

  expandedMenu = null;

  user = JSON.parse(localStorage.getItem('user')).email;
  tenants = JSON.parse(localStorage.getItem('tenants'));

  constructor(
    private store: Store<fromReducer.tenant.State>,
    private ref: ChangeDetectorRef,
    private tenantService: TenantService,
    private permissionResolver: PermissionResolver
  ) {
  }

  countrySwitcher() {
    this.isCountryOpen = !this.isCountryOpen;
    // this.isOpen = false
    this.isOpenChange.emit(this.isOpen);
    this.isCountryOpenChange.emit(this.isCountryOpen);
  }


  logout() {
    localStorage.clear();
    location.reload();
  }

  loadModules(modules) {
      this.modules = [];

      modules.forEach(moduleSingle => {
        let filter = modulesArray.filter(e => e.name.toLowerCase() === moduleSingle.name.toLowerCase());
        if (filter.length === 1) {
          this.modules.push({...moduleSingle, ...filter[0]});
        }
      });
      if (this.modules.length > 0) {
        let myModules = [];
        let userModules = []
        // if(this.permissionResolver.isTenantAdmin() || this.permissionResolver.isSuperUser()) {
        //   const tenantPreference = localStorage.getItem('tenantPreference');
        //     if (tenantPreference !== null) {
        //         userModules = JSON.parse(tenantPreference).tenantModules;
        //     }
        // } else {
          userModules = this.permissionResolver.getModules();
        // }

          userModules.forEach(userModule => {
            let r = this.modules.filter(e => (e.id === userModule.id || e.id === userModule.parentModuleId));
            if (r.length > 0) {
              myModules.push(r[0]);
            }
          })
          this.myModules = myModules
      }
      this.ref.detectChanges();
  }

  ngOnInit() {
    this.modules$ = this.store.pipe(select(fromSelectors.getModules));
    this.modules$.subscribe(data => {
      setTimeout(() => {
        this.loadModules(data);
      },100)
    })
  }
}
