import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { ModuleAction } from '@appModels/core-setup/module-action/module-action';

import { CoreMasterDataService } from '../core-masterdata.service';



@Injectable({ providedIn: 'root' })
export class ModuleActionService extends CoreMasterDataService {


  constructor(
    private http: HttpClient,

  ) {
    super(http, 'moduleaction');
  }

  /** GET moduleActions from the server */
  getModuleActions(): Observable<ModuleAction[]> {
    return this.http.get<ModuleAction[]>(this.base_url , { headers: this.headers }).pipe(
      tap(moduleActions => this.log(`fetched moduleActions`)),
      catchError(this.handleError('getModuleActions', []))
    );
  }

  /** GET moduleAction by id. Return `undefined` when id not found */
  getModuleActionNo404<Data>(id: number): Observable<ModuleAction> {
    const url = `${this.base_url}/?id=${id}`;
    return this.http.get<ModuleAction[]>(url).pipe(
      map(moduleActions => moduleActions[0]), // returns a {0|1} element array
      tap(h => {
        const outcome = h ? `fetched` : `did not find`;
        this.log(`${outcome} moduleAction id=${id}`);
      }),
      catchError(this.handleError<ModuleAction>(`getModuleAction id=${id}`))
    );
  }

  /** GET moduleAction by id. Will 404 if id not found */
  getModuleAction(id: number): Observable<any> {
    const url = `${this.base_url}/${id}/tenant/${localStorage.getItem('tenant')}`;
    return this.http.get<any>(url,{ headers: this.headers }).pipe(
      tap(_ => this.log(`fetched moduleAction id=${id}`)),
      catchError(this.handleError<any>(`getModuleAction id=${id}`))
    );
  }

  /* GET moduleActions whose name contains search term */
  searchModuleActions(term: string): Observable<ModuleAction[]> {
    if (!term.trim()) {
      // if not search term, return empty moduleAction array.
      return of([]);
    }
    return this.http.get<ModuleAction[]>(`api/moduleActions/?name=${term}`).pipe(
      tap(_ => this.log(`found moduleActions matching "${term}"`)),
      catchError(this.handleError<ModuleAction[]>('searchModuleActions', []))
    );
  }

  //////// Save methods //////////

  /** POST: add a new moduleAction to the server */
  addModuleAction(moduleAction: ModuleAction): Observable<ModuleAction> {
    return this.http.post<ModuleAction>(this.base_url , moduleAction, { headers: this.headers }).pipe(
      tap((newModuleAction: ModuleAction) => {
        this.log(`added moduleAction w/ id=${newModuleAction.id}`);
          // patch info
          this.editModuleAction({ value: moduleAction.moduleId, id: newModuleAction.id, param: 'module' }).subscribe(data => {
          }, error => console.log(error, 'type error'));
          //
      }),
      catchError(this.handleError<ModuleAction>('addModuleAction'))
    );
  }

  /** DELETE: delete the moduleAction from the server */
  deleteModuleAction(moduleAction: ModuleAction | number): Observable<ModuleAction> {
    const id = typeof moduleAction === 'number' ? moduleAction : moduleAction.id;
    const url = `${this.base_url}/${id}`;

    return this.http.delete<ModuleAction>(url, { headers: this.headers }).pipe(
      tap(_ => this.log(`deleted moduleAction id=${id}`)),
      catchError(this.handleError<ModuleAction>('deleteModuleAction'))
    );
  }

  /** PUT: update the moduleAction on the server */
  updateModuleAction(moduleAction: ModuleAction): Observable<any> {
    return this.http.put(this.base_url, moduleAction, { headers: this.headers }).pipe(
      tap(_ => this.log(`updated moduleAction id=${moduleAction.id}`)),
      catchError(this.handleError<any>('updateModuleAction'))
    );
  }

   /** Patch: update the moduleAction on the server */
   editModuleAction(data): Observable<any> {
    return this.http.patch(`${this.base_url}/${data.id}/${data.param}/${data.value}`, {}, { headers: this.headers }).pipe(
      tap(_ => this.log(`updated moduleAction id=${data.id}`)),
      catchError(this.handleError<any>('updateModuleAction'))
    );
  }

}
