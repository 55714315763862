import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';

import {EligibilityDefinition} from '@appModels/core-accounting/eligibility-definition/eligibility-definition';
import {EligibilityDefinitionActions, EligibilityDefinitionActionTypes} from '@appStore/actions/core-accounting/eligibility-definition/eligibility-definition.actions';
import * as _ from 'lodash';

export interface State extends EntityState<EligibilityDefinition> {
  loaded: boolean;
  loading: boolean;
  error: any;
  selectedEligibilityDefinitionId: number;
  searchEligibilityDefinitions: EligibilityDefinition[];
  paging: any;
}

export const adapter: EntityAdapter<EligibilityDefinition> = createEntityAdapter<EligibilityDefinition>();

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
  selectedEligibilityDefinitionId: null,
  error: null,
  searchTerm: '',
  searchEligibilityDefinitions: null,
  paging: null
});

export function reducer(state = initialState, action: EligibilityDefinitionActions): State {
  switch (action.type) {
    case EligibilityDefinitionActionTypes.eligibilityDefinitionGetEligibilityDefinitions:
    case EligibilityDefinitionActionTypes.eligibilityDefinitionGetEligibilityDefinitionByTenant:
    case EligibilityDefinitionActionTypes.eligibilityDefinitionAddEligibilityDefinition:
    case EligibilityDefinitionActionTypes.eligibilityDefinitionAddAdditionalEligibilityDefinitionInfo:
    case EligibilityDefinitionActionTypes.eligibilityDefinitionDeleteEligibilityDefinition:
    case EligibilityDefinitionActionTypes.eligibilityDefinitionUpdateEligibilityDefinition:
    case EligibilityDefinitionActionTypes.eligibilityDefinitionSearchEligibilityDefinitions:
    case EligibilityDefinitionActionTypes.eligibilityDefinitionGetEligibilityDefinitionById:

      return {
        ...state,
        loading: true
      };

    case EligibilityDefinitionActionTypes.eligibilityDefinitionGetEligibilityDefinitionsSuccess:
      return adapter.setAll(action.payload.content, {
        ...state,
        loading: false,
        loaded: true,
        paging: _.omit(action.payload, ['content'])
      });

    case EligibilityDefinitionActionTypes.eligibilityDefinitionGetEligibilityDefinitionByTenantSuccess:
      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });

    case EligibilityDefinitionActionTypes.eligibilityDefinitionGetEligibilityDefinitionByIdSuccess:
      return {
        ...state,
        selectedEligibilityDefinitionId: action.payload.id,
        loading: false
      };

    case EligibilityDefinitionActionTypes.eligibilityDefinitionAddEligibilityDefinitionSuccess:
      return adapter.addOne(action.payload, {
        ...state,
        selectedEligibilityDefinitionId: action.payload.id,
        loading: false,
        loaded: true
      });

    case EligibilityDefinitionActionTypes.eligibilityDefinitionUpdateEligibilityDefinitionSuccess: {
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state,
          loading: false,
          loaded: true
        }
      );
    }

    case EligibilityDefinitionActionTypes.eligibilityDefinitionDeleteEligibilityDefinitionSuccess: {
      return adapter.removeOne(action.payload.id, {
        ...state,
        loading: false,
        loaded: true
      });
    }

    case EligibilityDefinitionActionTypes.eligibilityDefinitionSearchEligibilityDefinitionsSuccess:
      return {
        ...state,
        searchEligibilityDefinitions: action.payload,
        loading: false
      };

    case EligibilityDefinitionActionTypes.eligibilityDefinitionSearchEligibilityDefinitionsReset:
      return {
        ...state,
        searchEligibilityDefinitions: null
      };

    case EligibilityDefinitionActionTypes.eligibilityDefinitionError:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload
      };

    default:
      return state;
  }
}

export const eligibilityDefinitionEntitySelectors = adapter.getSelectors();
