import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getTenantHolidayStore = createFeatureSelector('tenantHoliday');

export const getTenantHolidayEntities = createSelector(
  getTenantHolidayStore,
  fromReducers.tenantHoliday.tenantHolidaySelectors.selectAll
);

export const getTenantHolidays = createSelector(getTenantHolidayEntities, entities => {
  return Object.values(entities);
});

export const getTenantHolidayLoading = createSelector(
  getTenantHolidayStore,
  (tenantHoliday: fromReducers.tenantHoliday.State) => tenantHoliday.loading
);


export const getTenantHolidayPaging = createSelector(
  getTenantHolidayStore,
  (tenantHoliday: fromReducers.tenantHoliday.State) => tenantHoliday.paging
);
