import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { PaymentCategorization } from '@appModels/core-accounting/payment-categorization/payment-categorization';

import { CoreAccountingService } from '../core-accounting.service';



@Injectable({ providedIn: 'root' })
export class PaymentCategorizationService extends CoreAccountingService {


  constructor(
    private http: HttpClient,
    
  ) {
    super(http, 'contractpaymentcategorizationdefinition');
  }

  /** GET paymentCategorizations from the server */
  getPaymentCategorizations(): Observable<PaymentCategorization[]> {
    return this.http.get<PaymentCategorization[]>(this.base_url + '/tenant/' + localStorage.getItem('tenant'), { headers: this.headers }).pipe(
      tap(paymentCategorizations => this.log(`fetched paymentCategorizations`)),
      catchError(this.handleError('getPaymentCategorizations', []))
    );
  }

  /** GET paymentCategorizations from the server */
  getPaymentCategorizationTimeIntervals(): Observable<PaymentCategorization[]> {
    return this.http.get<PaymentCategorization[]>(this.base_url + '/timeintervalvariable', { headers: this.headers }).pipe(
      tap(paymentCategorizations => this.log(`fetched paymentCategorizations`)),
      catchError(this.handleError('getPaymentCategorizations', []))
    );
  }

  /** GET paymentCategorization by id. Return `undefined` when id not found */
  getPaymentCategorizationNo404<Data>(id: number): Observable<PaymentCategorization> {
    const url = `${this.base_url}/?id=${id}`;
    return this.http.get<PaymentCategorization[]>(url).pipe(
      map(paymentCategorizations => paymentCategorizations[0]), // returns a {0|1} element array
      tap(h => {
        const outcome = h ? `fetched` : `did not find`;
        this.log(`${outcome} paymentCategorization id=${id}`);
      }),
      catchError(this.handleError<PaymentCategorization>(`getPaymentCategorization id=${id}`))
    );
  }

  /** GET paymentCategorization by id. Will 404 if id not found */
  getPaymentCategorization(id: number): Observable<PaymentCategorization> {
    const url = `${this.base_url}/${id}/`;
    return this.http.get<PaymentCategorization>(url,{ headers: this.headers }).pipe(
      tap(_ => this.log(`fetched paymentCategorization id=${id}`)),
      catchError(this.handleError<PaymentCategorization>(`getPaymentCategorization id=${id}`))
    );
  }

  /* GET paymentCategorizations whose name contains search term */
  searchPaymentCategorizations(term: string): Observable<PaymentCategorization[]> {
    if (!term.trim()) {
      // if not search term, return empty paymentCategorization array.
      return of([]);
    }
    return this.http.get<PaymentCategorization[]>(`api/paymentCategorizations/?name=${term}`).pipe(
      tap(_ => this.log(`found paymentCategorizations matching "${term}"`)),
      catchError(this.handleError<PaymentCategorization[]>('searchPaymentCategorizations', []))
    );
  }

  //////// Save methods //////////
  addPaymentCategorization(paymentCategorization: PaymentCategorization): Observable<PaymentCategorization> {
    return this.http.post<PaymentCategorization>(this.base_url + '/', paymentCategorization, { headers: this.headers }).pipe(
      tap((paymentCategorization: PaymentCategorization) => this.log(`added rate w/ id=${paymentCategorization.id}`)),
      catchError(this.handleError<PaymentCategorization>('addPaymentCategorization'))
    );
  }

  /** POST: add a new paymentCategorization to the server */
  addPaymentCategorizationAdditionalInfo(paymentCategorization): Observable<{}> {
    return this.http.post(`${this.base_url}/additionalInfo/${paymentCategorization.id}`, paymentCategorization.value, { headers: this.headers }).pipe(
      tap((res) => this.log(`added paymentCategorization  info `)),
      catchError(this.handleError('addPaymentCategorization'))
    );
  }

  /** DELETE: delete the paymentCategorization from the server */
  deletePaymentCategorization(paymentCategorization: PaymentCategorization | number): Observable<PaymentCategorization> {
    const id = typeof paymentCategorization === 'number' ? paymentCategorization : paymentCategorization.id;
    const url = `${this.base_url}/${id}`;

    return this.http.delete<PaymentCategorization>(url, { headers: this.headers }).pipe(
      tap(_ => this.log(`deleted paymentCategorization id=${id}`)),
      catchError(this.handleError<PaymentCategorization>('deletePaymentCategorization'))
    );
  }

  // /** PUT: update the paymentCategorization on the server */
  updatePaymentCategorization(paymentCategorization: PaymentCategorization): Observable<any> {
    return this.http.put(`${this.base_url}/${paymentCategorization.id}`, paymentCategorization, { headers: this.headers }).pipe(
      tap(_ => this.log(`updated paymentCategorization id=${paymentCategorization.id}`)),
      catchError(this.handleError<any>('updatePaymentCategorization'))
    );
  }

  // updatePaymentCategorization(paymentCategorization: PaymentCategorization): Observable<PaymentCategorization> {
  //   return this.http.put<PaymentCategorization>(`${this.base_url}/${paymentCategorization.id}`, paymentCategorization, { headers: this.headers }).pipe(
  //     tap((newFinancedRate: PaymentCategorization) => {
  //       this.log(`added paymentCategorization w/ id=${newFinancedRate.id}`);
  //       // patch info
  //       this.editPaymentCategorization({ value: paymentCategorization.rateId, id: newFinancedRate.id, param: 'rate' }).subscribe(data => {
  //       }, error => console.log(error, 'rate error'));
  //       //
  //       this.editPaymentCategorization({ value: paymentCategorization.timeIntervalVariableId, id: newFinancedRate.id, param: 'timeintervalvariable' }).subscribe(data => {
  //       }, error => console.log(error, 'time interval error'));
  //       //
  //     }),
  //     catchError(this.handleError<PaymentCategorization>('addPaymentCategorization'))
  //   );
  // }

  /** Patch: update the paymentCategorization on the server */
  editPaymentCategorization(data): Observable<any> {
    return this.http.patch(`${this.base_url}/${data.id}/${data.param}/${data.value}`, {}, { headers: this.headers }).pipe(
      tap(_ => this.log(`edit financed rate id=${data.id}`)),
      catchError(this.handleError<any>('editFinancedRate'))
    );
  }

}
