import { Action } from '@ngrx/store';

import { Role } from '@appModels/core-identity/role/role';

export enum RoleActionTypes {
  roleGetRoles = '[Role] get',
  roleGetRolesSuccess = '[Role] get roles success',
  roleAddRole = '[Role] add Role',
  roleAddRoleSuccess = '[Role] add role success',
  roleDeleteRole = '[Role] delete role',
  roleDeleteRoleSuccess = '[Role] delete role success',
  roleGetRoleById = '[Role] get role by id',
  roleGetRoleByIdSuccess = '[Role] get role by id success',
  roleUpdateRole = '[Role] update role',
  roleUpdateRoleSuccess = '[Role] update role success',
  roleSearchRoles = '[Role] search roles',
  roleSearchRolesSuccess = '[Role] search roles success',
  roleSearchRolesReset = '[Role] search roles reset',
  roleError = '[Role] error'
}

export class GetRoles implements Action {
  readonly type = RoleActionTypes.roleGetRoles;
}

export class GetRolesSuccess implements Action {
  readonly type = RoleActionTypes.roleGetRolesSuccess;
  constructor(public payload: Role[]) {}
}

export class AddRole implements Action {
  readonly type = RoleActionTypes.roleAddRole;
  constructor(public payload: Role) {}
}

export class AddRoleSuccess implements Action {
  readonly type = RoleActionTypes.roleAddRoleSuccess;
  constructor(public payload: any) {}
}

export class GetRoleById implements Action {
  readonly type = RoleActionTypes.roleGetRoleById;
  constructor(public payload: number) {}
}

export class GetRoleByIdSuccess implements Action {
  readonly type = RoleActionTypes.roleGetRoleByIdSuccess;
  constructor(public payload: Role) {}
}

export class UpdateRole implements Action {
  readonly type = RoleActionTypes.roleUpdateRole;
  constructor(public payload: Role) {}
}

export class UpdateRoleSuccess implements Action {
  readonly type = RoleActionTypes.roleUpdateRoleSuccess;
  constructor(public payload: Role) {}
}

export class DeleteRole implements Action {
  readonly type = RoleActionTypes.roleDeleteRole;
  constructor(public payload: Role) {}
}

export class DeleteRoleSuccess implements Action {
  readonly type = RoleActionTypes.roleDeleteRoleSuccess;
  constructor(public payload: Role) {}
}

export class SearchRoles implements Action {
  readonly type = RoleActionTypes.roleSearchRoles;
  constructor(public payload: string) {}
}

export class SearchRolesSuccess implements Action {
  readonly type = RoleActionTypes.roleSearchRolesSuccess;
  constructor(public payload: Role[]) {}
}

export class SearchRolesReset implements Action {
  readonly type = RoleActionTypes.roleSearchRolesReset;
}

export class RoleError implements Action {
  readonly type = RoleActionTypes.roleError;
  constructor(public payload: any) {}
}

export type RoleActions =
  | GetRoles
  | GetRolesSuccess
  | AddRole
  | AddRoleSuccess
  | GetRoleById
  | GetRoleByIdSuccess
  | UpdateRole
  | UpdateRoleSuccess
  | DeleteRole
  | DeleteRoleSuccess
  | SearchRoles
  | SearchRolesSuccess
  | SearchRolesReset
  | RoleError;
