import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getFaultStore = createFeatureSelector('fault');

export const getFaultEntities = createSelector(
  getFaultStore,
  fromReducers.fault.faultEntitySelectors.selectAll
);

export const getFaults = createSelector(getFaultEntities, entities => {
  return Object.values(entities);
});

export const getFaultsLoading = createSelector(
  getFaultStore,
  (repairCentreStore: fromReducers.fault.State) => repairCentreStore.loading
);

export const getSelectedFaultId = createSelector(
  getFaultStore,
  (faultStore: fromReducers.fault.State) => faultStore.selectedFaultId
);

export const getFaultById = createSelector(
  getFaultStore,
  (faultStore: fromReducers.fault.State) => faultStore.selectedFault

);
