import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {CustomerService} from "@appServices/core-crm/customer/customer.service";
import {CoreTicketingCustomerService} from "@appServices/core-ticketing/customer/core-ticketing-customer.service";
import Swal from 'sweetalert2';
import {TicketingService} from "@appServices/core-ticketing/customer/ticketing-service";
import {TicketCustomerDetails} from "@appModels/ticketing/ticket.customer.details";
import {CreateTicket} from "@appModels/ticketing/create.ticket";
import tinymce from "tinymce";
import {ToastrService} from "ngx-toastr";
import {TicketingFileService} from "@appServices/core-ticketing/file/ticketing-file-service";
import * as moment from "moment/moment";
import {ActivatedRoute, Router} from "@angular/router";
import {CurrentUserDetails} from "@appModels/ticketing/current.user.details";
import {TeamsService} from "@appServices/core-ticketing/teams-service";
import {TicketTypeService} from "@appServices/core-ticketing/ticket-type.service";
import {debounce} from 'lodash';
import {PermissionResolver} from "@appServices/permission-resolver/permission-resolver.service";
import {TicketCustomFieldService} from "@appServices/core-ticketing/ticket-custom-field-service";
import {CustomField} from "@appModels/ticketing/custom-field";
import {TicketTypeListRow} from "@appModels/ticketing/ticket-type-list-row";
import {RequestTypeService} from "@appServices/core-ticketing/request-type.service";
import {RequestTypeListRow} from "@appModels/ticketing/request-type-list-row";

@Component({
  moduleId: module.id,
  selector:'ticket-create',
  templateUrl: './ticket-create-directive.html',
  animations: [
    trigger('toggleAnimation', [
      transition(':enter', [style({ opacity: 0, transform: 'scale(0.95)' }), animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' }))]),
      transition(':leave', [animate('75ms', style({ opacity: 0, transform: 'scale(0.95)' }))]),
    ]),
  ],
  styleUrls: ['./ticket-create-directive.css', '../../tailwind.css']
})
export class TicketCreateDirective implements OnInit {

  @Output() outputCloseEvent = new EventEmitter<any>();

  // @Input() set setInitializer(initializer:any) {
  //   this.initializer = initializer;
  //   this.initialize();
  // }
  //
  // initializer = {
  //   warrantyAndServiceTicket:false
  // }
  //
  // initialize() {
  //   this.warrantyAndServiceTicket = this.initializer.warrantyAndServiceTicket;
  //   if (this.warrantyAndServiceTicket == true) {
  //     this.requestTypeService.getWarrantyAndServiceRequestType(true).subscribe(x => {
  //       this.ticket.requestTypeId = x.id;
  //     })
  //   }
  //
  // }

  constructor(
    private toastrService: ToastrService,
    private permissionResolverService: PermissionResolver,
    private ticketFileService: TicketingFileService,
    private ref: ChangeDetectorRef,
    private modalService: BsModalService,
    private ticketCustomFieldService: TicketCustomFieldService,
    private customerService: CustomerService,
    private ticketingService: TicketingService,
    private coreTicketingCustomerService: CoreTicketingCustomerService,
    private teamsService: TeamsService,
    private ticketTypeService: TicketTypeService,
    private requestTypeService: RequestTypeService,
    private route: ActivatedRoute
  ) {

    this.searchUsers = debounce(this.searchUsers, 500);
    this.searchUsersTagged = debounce(this.searchUsersTagged ,500);


    this.getTicketPriorities();
    this.getTicketSources();
    this.getPossibleAssignees();
    this.getTicketTypes();
    this.getRequestTypes();

  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(queryParams => {
      this.selectedCustomerId = queryParams['customerId'];
      if(this.selectedCustomerId) {
        this.selectCustomer(this.selectedCustomerId)
      }

      if (queryParams['contractId'] != null && queryParams['contractId'] != undefined) {
        this.ticket.contractId = queryParams['contractId'];
      }

      if (queryParams['accountNumber'] != null && queryParams['accountNumber'] != undefined) {
        this.ticket.accountNumber = queryParams['accountNumber'];
      }


      if (queryParams['warranty'] != null && queryParams['warranty'] != undefined) {
          this.getRequestTypesAndSelectWarranty();
      }

      if (queryParams['repossession'] != null && queryParams['repossession'] != undefined) {
        this.getRequestTypesAndSelectRepossession();
      }

    });
  }




  customerSearchQuery = "";
  searchType = "searchAccountNumber";
  rotateSearch=false;
  customerResults:any[] = [];
  selectedCustomerId: number = null;
  selectedCustomer : TicketCustomerDetails = null;
  customerImage: any = null;
  loading = false;
  currentUserDetails :CurrentUserDetails = {};
  possibleAssignees : CurrentUserDetails[] = [];
  ticketTypes: TicketTypeListRow[] = [];
  requestTypes: RequestTypeListRow[] = [];

  loadingTable = true;

  priorities = [];
  sources = [];

  fileUploads = [];

  ticket : CreateTicket = {
    customerId:null,
    contractId:null,
    taggedUsers: [],
    ticketPriorityId:null,
    notes:null,
    customFields: [],
    ticketChannelId:1,
    requestTypeId:null,
    markAsClosed:false,
    afterSaleCustomerTicketId:null
  }
  DefaultImg = () => 'assets/img/avatar.png';

  config: any = {

    base_url : '/tinymce',
    suffix : '.min',
    width: '100%',
    min_height: 400,
    autoresize_min_height: 600,
    autoresize_max_height: 800,
    promotion: false,
    statusbar:false,
    max_height: 600,


    skin: 'oxide',
    quickbars_insert_toolbar: false,


    plugins: 'preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap emoticons quickbars autoresize media',


    toolbar:
      "undo redo | formatselect | bold italic backcolor | table | link \
      bullist numlist outdent indent",

    menubar:'file edit custom table view format tools',

    menu: {
      custom: { title: 'Insert', items: 'attachmentmenu imageuploadmenu code pagebreak charmap emoticons' }
    },



    setup: function (editor) {


      editor.ui.registry.addIcon('imageUploadIcon', '<svg fill="#000000" width="24px" height="24px" viewBox="0 0 0.72 0.72" xmlns="http://www.w3.org/2000/svg"><path d="M0.57 0.12H0.15a0.09 0.09 0 0 0 -0.09 0.09v0.3a0.09 0.09 0 0 0 0.09 0.09h0.42a0.09 0.09 0 0 0 0.09 -0.09V0.21a0.09 0.09 0 0 0 -0.09 -0.09ZM0.15 0.54a0.03 0.03 0 0 1 -0.03 -0.03v-0.073l0.099 -0.099a0.03 0.03 0 0 1 0.042 0L0.462 0.54Zm0.45 -0.03a0.03 0.03 0 0 1 -0.03 0.03h-0.023l-0.114 -0.115 0.026 -0.026a0.03 0.03 0 0 1 0.042 0l0.099 0.099Zm0 -0.097 -0.056 -0.056a0.092 0.092 0 0 0 -0.127 0l-0.026 0.026 -0.086 -0.086a0.092 0.092 0 0 0 -0.127 0L0.12 0.353V0.21a0.03 0.03 0 0 1 0.03 -0.03h0.42a0.03 0.03 0 0 1 0.03 0.03Z"/></svg>');


      editor.ui.registry.addButton('imageUploadButton', {

        icon: 'imageUploadIcon',
        onAction: function () {

          let input = document.getElementById("imageUploadButton");
          input?.click();
        }
      });


      editor.ui.registry.addMenuItem('imageuploadmenu', {
        text: 'Attachment',
        icon: 'imageUploadIcon',
        onAction: function () {

          let input = document.getElementById("imageUploadButton");
          input?.click();
        }
      });

    },

  };

  searchCustomer() {

    this.loading = true;
    const sendQuery = {page: 0, size: 10};
    sendQuery[this.searchType] = this.customerSearchQuery?.trim();
    this.coreTicketingCustomerService.getCustomers(sendQuery).subscribe(x => {
      this.customerResults = x.content;
      this.loading = false;
    })
  }



  selectCustomer(id:number) {
    this.loading = true;
    this.customerResults = [];
    this.rotateSearch = false;
    this.selectedCustomerId = id;
    this.ticket.customerId = this.selectedCustomerId;

    this.downloadCustomerPhoto(id);

    this.coreTicketingCustomerService.getCustomerDetails(id).subscribe(x=> {
      this.selectedCustomer = x;
      console.log(this.selectedCustomer)
      this.loading = false;
    });

  }

  downloadCustomerPhoto(data) {
    this.customerService.downloadCustomerPhoto(data).subscribe((blob : any) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        this.customerImage = reader.result;
        this.ref.detectChanges()
      }
    });
  }

  showSuccessAlert(title:string) {
    Swal.fire({
      icon: 'success',
      title: title,
      padding: '10px 20px',
    });
  }

  creatingTicket = false;
  ticketCreated = false;

  createWarrantyTicketEvent(data:any) {

    this.ticket.afterSaleCustomerTicketId = data.id;
    this.ticket.markAsClosed = data.markAsClosed;
    this.createTicket();
  }

  createRepossessionTicketEvent(data:any) {

    this.ticket.returnRepossessionTicketId = data.id;
    this.createTicket();
  }

  uploadImageEvent(file:any) {
    this.uploadSingleImage(file, false);
  }

  createTicket() {


    if (!this.warrantyAndServiceTicket && !this.repossessionTicket && this.ticket.ticketSourceId == null) {
      this.toastrService.error('Please select the ticket source');
      return;
    }

    if (this.ticket.requestTypeId == null) {
      this.toastrService.error("Please select the request type");
      return;
    }

    this.ticket.customFields = this.customFields;

    if (!this.warrantyAndServiceTicket && !this.repossessionTicket && this.ticket.customFields != null) {
      for(let i = 0; i< this.ticket.customFields.length ; i++) {
        if (this.ticket.customFields[i].mandatory != null && this.ticket.customFields[i].mandatory &&
          (this.ticket.customFields[i].answer == null || this.ticket.customFields[i].answer == undefined)) {
          this.toastrService.error("Please populate the additional field " + this.ticket.customFields[i].name);
          return;
        }
      }
    }

    this.creatingTicket = true;
    this.ticketCreated = true;

    try {

      let attachments = [];
      if (this.fileUploads != null && this.fileUploads.length > 0) {
        for (let i = 0; i < this.fileUploads.length; i++) {
          attachments.push(this.fileUploads[i].id);
        }
      }
      this.ticket.attachments = attachments;


      let editor: any = tinymce.get('mainNotes');
      var myText = editor.getContent({format: 'text'});
      this.ticket.notesCleaned = myText;
      console.log(this.ticket);


    } catch (err) {
      console.log(err);
    }

    try {
      for (let i = 0; i < this.selectedCustomer.contracts.length; i++) {
        if (this.selectedCustomer.contracts[i].id == this.ticket.contractId) {
          this.ticket.accountNumber = this.selectedCustomer.contracts[i].accountNumber;

        }
      }
    } catch (err) {

    }

    this.ticketingService.createTicket(this.ticket).subscribe(x=> {
      this.creatingTicket = false;
      if (x.code == 200) {
        this.showSuccessAlert(x.message);
      }
      else {
        this.toastrService.error(x.message);
      }
    })


  }

  getPossibleAssignees() {
    this.teamsService.getPossibleAssignees(null).subscribe(x => {
      this.possibleAssignees= x;
    })
  }
  getTicketTypes() {
    this.ticketTypeService.getTicketTypes(true).subscribe(x => {
      this.ticketTypes= x;
    })
  }



  getRequestTypes() {
    this.requestTypeService.getRequestTypes(true).subscribe(x => {
      this.requestTypes = x;
    })
  }

  getRequestTypesAndSelectWarranty() {
    this.requestTypeService.getRequestTypes(true).subscribe(x => {
      this.requestTypes = x;
      for(let i = 0; i< this.requestTypes.length; i++) {
        if (this.requestTypes[i].warranty == true) {
          this.ticket.requestTypeId = this.requestTypes[i].id;
          this.changeRequestType();
        }
      }
    })
  }

  getRequestTypesAndSelectRepossession() {
    this.requestTypeService.getRequestTypes(true).subscribe(x => {
      this.requestTypes = x;
      for(let i = 0; i< this.requestTypes.length; i++) {
        if (this.requestTypes[i].repossession == true) {
          this.ticket.requestTypeId = this.requestTypes[i].id;
          this.changeRequestType();
        }
      }
    })
  }



  getTicketPriorities() {
    this.ticketingService.getPriorities().subscribe(x => {
      this.priorities = x;
    })
  }

  getTicketSources() {
    this.ticketingService.getSources().subscribe(x => {
      this.sources = x;
    })
  }




  onImageSelected(event:any) {


    Array.prototype.forEach.call(event.target.files, file =>{
      this.uploadSingleImage(file, true);
    })
  }


  uploadSingleImage(file:File, appendNotes : boolean ){

    if (file) {


      const formData = new FormData();

      formData.append("file", file);

      this.fileUploads.push({
        name: file.name,
        finished:false
      })


      this.ticketFileService.uploadFile(formData).subscribe(x=> {
        if (x.success) {
          for (let i = 0; i < this.fileUploads.length; i++) {
            if (this.fileUploads[i].name == file.name) {
              this.fileUploads[i].finished = true;
              this.fileUploads[i].image = x.image;
              this.fileUploads[i].id = x.id;
              this.fileUploads[i].uuid = x.uuid;
              this.toastrService.success("File " + file.name + " Uploaded Successfuly")

              this.ticketFileService.downloadThumbnailByUuidId(x.uuid).subscribe((blob : any) => {
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = () => {
                  this.fileUploads[i].src = reader.result;
                  this.ref.detectChanges()
                }
              });

              if (x.image && appendNotes) {
                //tinymce.activeEditor.execCommand('mceInsertContent', false,'<img src = "' + x.url + '">');
                tinymce.activeEditor.insertContent('<img src = "data:image/jpg;base64,' + x.bytes + '">');

              }

            }
          }
        } else {
          this.toastrService.error("Error uploading file" + file.name);
          this.fileUploads = this.fileUploads.filter(x=> {x.name != file.name})
        }



      });
    }
  }

  clickImageUploadButton() {
    let input = document.getElementById("imageUploadButton");
    input?.click();
  }

  downloadFile(file : any) {

    if (file == null || file.uuid == null) {
      return;
    }

    this.ticketFileService.downloadFileByUuidId(file.uuid).subscribe(blob => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      const a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.download = file.name;
      a.click();
    })
  }





  ///VIEW TICKET


  dateFormat = moment().format('YYYY-MM-DD hh:mm:ss');
  moment: any = moment;


  userSearch = "Search For Users";
  userSearchResults : CurrentUserDetails[] = [];
  loadingUserSearch = false;
  showUserDropdown = false;
  searchUsers() {
    this.userSearchResults = [];

    if (this.userSearch == null || this.userSearch.length == 0) {
      this.showUserDropdown = false;
    }
    if (this.userSearch == null || this.userSearch == undefined || this.userSearch.length < 3) {
      return;
    }
    this.showUserDropdown = true;

    this.loadingUserSearch = true;
    this.teamsService.queryUsersAndTeams(this.userSearch, true).subscribe( x => {
      this.userSearchResults = x;
      this.loadingUserSearch = false;
    })
  }

  selectUser(user: CurrentUserDetails) {
    this.showUserDropdown = false;
    this.userSearchResults = [];
    if (user.type == 'user') {
      this.ticket.assignedUserId = user.id;
      this.ticket.assignedTeamId = null;
    }
    else if (user.type == 'team') {
      this.ticket.assignedUserId = null;
      this.ticket.assignedTeamId = user.id;
    }
    this.userSearch = user.name;
  }





  userSearchTagged = "Search For Users";
  userSearchResultsTagged : CurrentUserDetails[] = [];
  loadingUserSearchTagged = false;
  showUserDropdownTagged = false;
  searchUsersTagged() {
    this.userSearchResultsTagged = [];

    if (this.userSearchTagged == null || this.userSearchTagged.length == 0) {
      this.showUserDropdownTagged = false;
    }
    if (this.userSearchTagged == null || this.userSearchTagged == undefined || this.userSearchTagged.length < 3) {
      return;
    }
    this.showUserDropdownTagged = true;

    this.loadingUserSearchTagged = true;
    this.teamsService.queryUsersAndTeams(this.userSearchTagged, true).subscribe( x => {
      this.userSearchResultsTagged = x;
      this.loadingUserSearchTagged = false;
    })
  }

  selectUserTagged(user: CurrentUserDetails) {
    this.showUserDropdownTagged = false;
    this.userSearchResultsTagged = [];

    if(!this.ticket.taggedUsers.some(x => x.id == user.id && x.type == user.type)) {
      this.ticket.taggedUsers.push(user);
    }
  }

  removeTaggedUser(user : CurrentUserDetails) {

    if (!this.can('can-tag-users-on-tickets')) {
      return;
    }

    this.ticket.taggedUsers = this.ticket.taggedUsers.filter(x => !(x.id == user.id && x.type == user.type));
  }


  can(action) {
    return this.permissionResolverService.setModuleName("Ticketing").can(action);
  }


  customFields: CustomField[] = [];

  warrantyAndServiceTicket = false;
  repossessionTicket = false;
  changeRequestType() {

    this.warrantyAndServiceTicket = false;
    this.repossessionTicket = false;

    if (this.ticket.requestTypeId != null && this.ticket.requestTypeId != undefined && this.ticket.requestTypeId != 0) {
      this.ticketCustomFieldService.getFieldsForRequestType(this.ticket.requestTypeId).subscribe(x => {
        this.customFields = x;
      })
    }
    for (let i = 0; i<this.requestTypes.length; i++) {
      if (this.requestTypes[i].id == this.ticket.requestTypeId && this.requestTypes[i].warranty == true ) {
        this.warrantyAndServiceTicket = true;
      }
    }
    for (let i = 0; i<this.requestTypes.length; i++) {
      if (this.requestTypes[i].id == this.ticket.requestTypeId && this.requestTypes[i].repossession == true ) {
        this.repossessionTicket = true;
      }
    }

  }

  closeCreateTicketModal(){
    this.outputCloseEvent.emit(true);
  }



  showSubunitSelection = false;
  selectDeviceToAction() {

    this.warrantyAndServiceTicket = false;
    this.repossessionTicket = false;

    for(let i = 0 ; i < this.selectedCustomer.contracts.length; i++) {
      if (this.ticket.contractId == this.selectedCustomer.contracts[i].id) {
        this.ticket.accountNumber = this.selectedCustomer.contracts[i].accountNumber
      }
    }

    for (let i = 0; i<this.ticketTypes.length; i++) {
      if (this.ticketTypes[i].id == this.ticket.ticketTypeId && this.ticketTypes[i].warranty == true && this.ticket.accountNumber != null) {
        this.warrantyAndServiceTicket = true;
      }
    }

    for (let i = 0; i<this.ticketTypes.length; i++) {
      if (this.ticketTypes[i].id == this.ticket.ticketTypeId && this.ticketTypes[i].repossession == true && this.ticket.accountNumber != null) {
        this.repossessionTicket = true;
      }
    }

  }



}
