import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Base } from '../interface/base';


@Injectable()
export class BaseService implements Base {

  /**
   * @var String
   */
  public base_url = environment.base_url;

   /**
    *
    *
    * @memberof BaseService
    */
  public api_version = environment.api_ver;


  /**
   * @var Object
   */
  protected headers;

  /**
   * @var String
   */
  private authorization;

  someDataObservable: Observable<any>;

  /**
   * @var JSON
   */
  private localStorage;

  public contentTypes = {
    JSON: 'application/json',
    MULTIPART: 'multipart/form-data'
  };

  /**
   *
   * @param _http
   * @param base_url
   */
  constructor(public _http: HttpClient, service_url: string, url: string) {
    this.base_url = `${this.base_url}/${service_url}/${this.api_version}/${url}`;
    this.getLocalStorage();
      if (this.localStorage !== null && this.localStorage !== undefined) {
          this.authorization = JSON.parse(this.localStorage).authorization;
         this.setHeader();
      }

    }

  /**
   * Get local storage from browser and
   * Set it to local property
   */
  private getLocalStorage() {
    this.localStorage = localStorage.getItem('authorization');
  }


  /**
   *
   */
  protected setHeader(contentTypeMultipart: boolean = false) {

    contentTypeMultipart ? this.headers = new HttpHeaders({'enctype': this.contentTypes.MULTIPART , 'upDateProgram': 'ATLAS_WEB', 'Authorization': this.authorization, 'Accept': 'application/json' }) :
                           this.headers = new HttpHeaders({'Content-Type': this.contentTypes.JSON , 'upDateProgram': 'ATLAS_WEB', 'Authorization': this.authorization }) ;


  }

  protected skipCache() {
    this.headers.append('skip-cache', 'true');
  }

  /**
   * @return String
   */
  public getBaseUrl() {
    return this.base_url;
  }

  /**
   *
   */
  public getAll(options?: string): any {
    const option = options ? options :  '';
    return this._http
      .get(this.base_url + option, { headers: this.headers })
      .pipe(map((response: Response) => response));
      // .do(data => console.log('All: ' +  JSON.stringify(data)));
  }

  /**
   *
   * @param id
   */
  public get(id: string): any {

    return this._http
      .get(this.base_url + '/' + id, { headers: this.headers })
      .pipe(
        map((response: Response) => response),);
  }

  /**
   *
   * @param data an object sent from the components
   */
  public create(data: any): any {
    const req = this._http
      .post(this.base_url + '/', data, { headers: this.headers })
      .pipe(map(response => response));
      console.log(req);
      return req;
  }

  /**
   *
   * @param data
   */
  public put(data: any): any {
    return this._http
    .put(`${this.base_url}/${data.id}/`, JSON.stringify(data), { headers: this.headers })
    .pipe(map(response => response));
  }

  /**
   *
   * @param data
   */
  public patch(data: any): any {
    return this._http
    .patch(`${this.base_url}/${data.id}`, JSON.stringify(data), { headers: this.headers })
    .pipe(map(response => response));
  }

   /**
   *
   * @param id
   */
  public delete(id: string): any {
    return this._http
      .delete(this.base_url + '/' + id, { headers: this.headers })
      .pipe(map(response => response));
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  protected handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.error}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a UserService message with the MessageService */
  protected log(message: string) {
    // this.messageService.add('UserService: ' + message);
  }
}
