import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import {mergeMap, map, catchError, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

import {
  CustomRegionActionTypes,
  GetCustomRegionsSuccess,
  GetTenantCustomRegionsSuccess,
  CustomRegionError,
  AddCustomRegion,
  AddCustomRegionSuccess,
  DeleteCustomRegionSuccess,
  DeleteCustomRegion,
  GetCustomRegionById,
  GetCustomRegionByIdSuccess,
  UpdateCustomRegionSuccess,
  SearchCustomRegions,
  SearchCustomRegionsSuccess,
  GetCustomRegions, GetCustomRegionsAllSuccess, GetTenantCustomRegions
} from '@appStore/actions/core-masterdata/custom-region/custom-region.actions';
import {CustomRegionService} from '@appServices/core-masterdata/custom-region/custom-region.service';

import * as fromRouterActions from '@appStore/actions/router.actions';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';

@Injectable()
export class CustomRegionEffects {
  constructor(private actions$: Actions, private customRegionService: CustomRegionService, private toastr: ToastrService, private router: Router) {
  }

  
  loadCustomRegions$ = createEffect(() => this.actions$.pipe(
    ofType(CustomRegionActionTypes.customRegionGetCustomRegions),
    mergeMap(() =>
      this.customRegionService
        .getCustomRegions()
        .pipe(
          map(customRegions => new GetCustomRegionsSuccess(customRegions)),
          catchError(error => of(new CustomRegionError(error)))
        )
    )
  ));

  // load CustomRegions by tenant ID
  
  loadProductCataloguesByTenantId$ = createEffect(() => this.actions$.pipe(
    ofType(CustomRegionActionTypes.customRegionGetTenantCustomRegions),
    mergeMap((action: GetTenantCustomRegions) =>
      this.customRegionService
        .getCustomRegionsByTenant(+localStorage.getItem('tenant'), action.payload)
        .pipe(
          map(customRegions => {
            console.log(customRegions);
            return new GetTenantCustomRegionsSuccess(customRegions);
          }),
          catchError(error => of(new CustomRegionError(error)))
        )
    )
  ));

  // load all CustomRegions by tenant ID
  
  loadAllCustomRegionsForTenant$ = createEffect(() => this.actions$.pipe(
    ofType(CustomRegionActionTypes.customRegionGetCustomRegionsAll),
    mergeMap(() =>
      this.customRegionService
        .getAllCustomRegionsByTenant(+localStorage.getItem('tenant'))
        .pipe(
          map(customRegions => {
            return new GetCustomRegionsAllSuccess(customRegions);
          }),
          catchError(error => of(new CustomRegionError(error)))
        )
    )
  ));

  
  getCustomRegionById$ = createEffect(() => this.actions$.pipe(
    ofType(CustomRegionActionTypes.customRegionGetCustomRegionById),
    mergeMap((action: GetCustomRegionById) =>
      this.customRegionService
        .getCustomRegion(action.payload)
        .pipe(
          map(customRegion => new GetCustomRegionByIdSuccess(customRegion)),
          catchError(error => of(new CustomRegionError(error)))
        )
    )
  ));

  
  addCustomRegion$ = createEffect(() => this.actions$.pipe(
    ofType(CustomRegionActionTypes.customRegionAddCustomRegion),
    switchMap((action: AddCustomRegion) =>

      this.customRegionService
        .addCustomRegion(action.payload)
        .pipe(
          map(
            customRegion => {
              if (customRegion !== undefined) {
                this.toastr.success('Custom Region has been successfully created!', 'Successful!');
                this.router.navigateByUrl(`/setup/custom-regions`);
                return new AddCustomRegionSuccess(customRegion);
              }
              return new CustomRegionError({type: 500, message: 'Internal error'})
            }
          ),
          catchError(error => of(new CustomRegionError(error)))
        )
    )
  ));

  
  updateCustomRegion$ = createEffect(() => this.actions$.pipe(
    ofType(CustomRegionActionTypes.customRegionUpdateCustomRegion),
    mergeMap((action: AddCustomRegion) =>
      this.customRegionService
        .updateCustomRegion(action.payload)
        .pipe(
          map(
            customRegion => {
              if (customRegion !== undefined) {
                this.toastr.success('Custom Region has been successfully updated!', 'Successful!');
                this.router.navigateByUrl(`/setup/custom-regions`);
                return new UpdateCustomRegionSuccess(action.payload)
              }
              return new CustomRegionError({type: 500, message: 'Internal error'});
            }
          ),
          catchError(error => of(new CustomRegionError(error)))
        )
    )
  ));

  
  editCustomRegion$ = createEffect(() => this.actions$.pipe(
    ofType(CustomRegionActionTypes.customRegionEditCustomRegion),
    mergeMap((action: AddCustomRegion) =>
      this.customRegionService
        .editCustomRegion(action.payload)
        .pipe(
          map(customRegion => new GetCustomRegions()),
          catchError(error => of(new CustomRegionError(error)))
        )
    )
  ));

  
  deleteCustomRegion$ = createEffect(() => this.actions$.pipe(
    ofType(CustomRegionActionTypes.customRegionDeleteCustomRegion),
    mergeMap((action: DeleteCustomRegion) =>
      this.customRegionService
        .deleteCustomRegion(action.payload)
        .pipe(
          map(() => new DeleteCustomRegionSuccess(action.payload)),
          catchError(error => of(new CustomRegionError(error)))
        )
    )
  ));

  
  searchCustomRegions$ = createEffect(() => this.actions$.pipe(
    ofType(CustomRegionActionTypes.customRegionSearchCustomRegions),
    mergeMap((action: SearchCustomRegions) =>
      this.customRegionService
        .searchCustomRegions(action.payload)
        .pipe(
          map(customRegions => new SearchCustomRegionsSuccess(customRegions)),
          catchError(error => of(new CustomRegionError(error)))
        )
    )
  ));

  
  updateCustomRegionSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(CustomRegionActionTypes.customRegionUpdateCustomRegionSuccess),
    map(customRegion => new fromRouterActions.Go({path: ['/setup/custom-regions']}))
  ));
}
