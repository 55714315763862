import {createEntityAdapter, EntityAdapter, EntityState,} from '@ngrx/entity';

import {Testflow} from "../../../../models/core-aftersales/testflow/testflow";
import {TestFlowActions, TestFlowActionTypes} from "../../../actions/core-aftersales/test-flow/test-flow.actions";

export interface State extends EntityState<Testflow> {
  loaded: boolean;
  loading: boolean;
  error: any;
  selectedTestFlow: any;
  selectedTestFlowId: number;
  searchTestFlows: Testflow[];
  paging: any;
}


export const adapter: EntityAdapter<Testflow> = createEntityAdapter<Testflow>();

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
  selectedTestFlowId: null,
  selectedTestFlow: null,
  error: null,
  searchTestFlows: null,
  paging: null
});

export function reducer(state = initialState, action: TestFlowActions): State {
  switch (action.type) {
    case TestFlowActionTypes.testflowGettestflows:
    case TestFlowActionTypes.testflowAddtestflow:
    case TestFlowActionTypes.testflowDeletetestflow:
    case TestFlowActionTypes.testflowUpdatetestflow:
    case TestFlowActionTypes.testflowSearchtestflows:
    case TestFlowActionTypes.testflowGettestflowById:
    case TestFlowActionTypes.testflowGetestflowsByTenantId:
    case TestFlowActionTypes.testflowFiltertestflow:

      return {
        ...state,
        loading: true
      };


    case TestFlowActionTypes.testflowGettestflowsSuccess:

      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true,
        //paging: _.omit(action.payload, ['content'])

      });


    case TestFlowActionTypes.testflowFiltertestflowSuccess:

      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true,
        //paging: _.omit(action.payload, ['content'])
      });


    case TestFlowActionTypes.testflowGettestflowByIdSuccess:
      return {...state, selectedTestFlowId: action.payload.id, loading: false, selectedTestFlow: action.payload};


    case TestFlowActionTypes.testflowAddStestflowSuccess:
      return {...state, loading: false};

    /*return adapter.addOne(action.payload, {
      ...state,
      //selectedTestFlowId: action.payload.id,
      loading: false,
      loaded: true
    });
    */

    case TestFlowActionTypes.testflowGetestflowsByTenantIdSuccess:

      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });

    case TestFlowActionTypes.testflowUpdatetestflowSuccess: {

      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state,
          loading: false,
          loaded: true
        }
      );
    }

    case TestFlowActionTypes.testflowDeletetestflowSuccess: {

      return adapter.removeOne(action.payload.id, {
        ...state,
        loading: false,
        loaded: true
      });
    }

    case TestFlowActionTypes.testflowSearchtestflowsSuccess:

      return {
        ...state,
        searchTestFlows: action.payload,
        loading: false
      };

    case TestFlowActionTypes.testflowSearchtestflowsReset:

      return {
        ...state,
        searchTestFlows: null
      };

    case TestFlowActionTypes.testflowsError:

      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload
      };

    default:
      return state;
  }
}

export const testFlowEntitySelectors = adapter.getSelectors();
