import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmailVerificationCheckService {

private verificationSubject$ = new BehaviorSubject<boolean>(false)

watchStatus(): Observable<boolean> {
  return this.verificationSubject$.asObservable()
}

changeStatus(value: boolean){
  this.verificationSubject$.next(value)
}

}
