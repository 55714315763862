import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { mergeMap, map, catchError, tap, debounceTime, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import {
  TenantTypeActionTypes,
  GetTenantTypesSuccess,
  TenantTypeError,
  AddTenantType,
  AddTenantTypeSuccess,
  DeleteTenantTypeSuccess,
  DeleteTenantType,
  GetTenantTypeById,
  GetTenantTypeByIdSuccess,
  UpdateTenantTypeSuccess,
  SearchTenantTypes,
  SearchTenantTypesSuccess
} from '@appStore/actions/core-masterdata/tenant-type/tenant-type.actions';
import { TenantTypeService } from '@appServices/core-masterdata/tenant-type/tenant-type.service';

import * as fromRouterActions from '@appStore/actions/router.actions';

@Injectable()
export class TenantTypeEffects {
  constructor(private actions$: Actions, private tenantTypeService: TenantTypeService) {}

  
  loadTenantTypes$ = createEffect(() => this.actions$.pipe(
    ofType(TenantTypeActionTypes.tenantTypeGetTenantTypes),
    mergeMap(() =>
      this.tenantTypeService
        .getTenantTypes()
        .pipe(
          map(tenantTypes => new GetTenantTypesSuccess(tenantTypes)),
          catchError(error => of(new TenantTypeError(error)))
        )
    )
  ));

  
  getTenantTypeById$ = createEffect(() => this.actions$.pipe(
    ofType(TenantTypeActionTypes.tenantTypeGetTenantTypeById),
    mergeMap((action: GetTenantTypeById) =>
      this.tenantTypeService
        .getTenantType(action.payload)
        .pipe(
          map(tenantType => new GetTenantTypeByIdSuccess(tenantType)),
          catchError(error => of(new TenantTypeError(error)))
        )
    )
  ));

  
  addTenantType$ = createEffect(() => this.actions$.pipe(
    ofType(TenantTypeActionTypes.tenantTypeAddTenantType),
    switchMap((action: AddTenantType) =>

      this.tenantTypeService
        .addTenantType(action.payload)
        .pipe(
          map(tenantType => new AddTenantTypeSuccess(tenantType)),
          catchError(error => of(new TenantTypeError(error)))
        )
    )
  ));

  
  updateTenantType$ = createEffect(() => this.actions$.pipe(
    ofType(TenantTypeActionTypes.tenantTypeUpdateTenantType),
    mergeMap((action: AddTenantType) =>
      this.tenantTypeService
        .updateTenantType(action.payload)
        .pipe(
          map(tenantType => new UpdateTenantTypeSuccess(action.payload)),
          catchError(error => of(new TenantTypeError(error)))
        )
    )
  ));

  
  deleteTenantType$ = createEffect(() => this.actions$.pipe(
    ofType(TenantTypeActionTypes.tenantTypeDeleteTenantType),
    mergeMap((action: DeleteTenantType) =>
      this.tenantTypeService
        .deleteTenantType(action.payload)
        .pipe(
          map(() => new DeleteTenantTypeSuccess(action.payload)),
          catchError(error => of(new TenantTypeError(error)))
        )
    )
  ));

  
  searchTenantTypes$ = createEffect(() => this.actions$.pipe(
    ofType(TenantTypeActionTypes.tenantTypeSearchTenantTypes),
    mergeMap((action: SearchTenantTypes) =>
      this.tenantTypeService
        .searchTenantTypes(action.payload)
        .pipe(
          map(tenantTypes => new SearchTenantTypesSuccess(tenantTypes)),
          catchError(error => of(new TenantTypeError(error)))
        )
    )
  ));

  
  updateTenantTypeSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(TenantTypeActionTypes.tenantTypeUpdateTenantTypeSuccess),
    map(tenantType => new fromRouterActions.Go({ path: ['/setup/tenant-types'] }))
  ));
}
