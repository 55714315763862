import { environment } from "environments/environment";

export const featureFlagsSetup: Record<string, boolean> = {
  enableIsUsedForBilling: !environment.production,
  enableLoyaltyPointsManagement: true,
  enableProductConfig: true,
  enableStreakConfig: true,
  enableLoyaltyDiscount: true,
  enableCRBProductChecks: true,
  enableCRBProductCheckDelete: true,
  enableLoyaltyTokens: true,
  enableAgedInTrade: true,
  enableLostInTrade: true,
  enableUpsellOffers: !environment.production,
  enableSmileIdProductCheck: true,
  enableSkuCheckInPeriod : true
};
