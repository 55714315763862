import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {WorkOrderL3} from '@appModels/core-aftersales/work-order-l3';
import {
  WorkOrderL3Action,
  WorkOrderL3ActionTypes
} from '../../../actions/core-aftersales/work-order/workorderl3.action';
import * as _ from 'lodash';

export interface State extends EntityState<WorkOrderL3> {
  error: any;
  loaded: boolean;
  loading: boolean;
  selectedWorkOrder: WorkOrderL3;
  selectedWorkOrderId: number;
  paging: any;
}

export const adapter: EntityAdapter<WorkOrderL3> = createEntityAdapter<WorkOrderL3>();

export const initialState: State = adapter.getInitialState({
  error: null,
  loaded: false,
  loading: false,
  selectedWorkOrder: null,
  selectedWorkOrderId: null,
  paging: null
});

export function reducer(state = initialState, action: WorkOrderL3Action): State {
  switch (action.type) {
    case WorkOrderL3ActionTypes.workOrderL3GetAll:
    case WorkOrderL3ActionTypes.workOrderL3Save:
    case WorkOrderL3ActionTypes.workOrderL3AssignTechnician:
    case WorkOrderL3ActionTypes.workOrderL3AssignAction:
    case WorkOrderL3ActionTypes.workOrderL3AddNote:
    case WorkOrderL3ActionTypes.workOrderL3PerformRepair:
    case WorkOrderL3ActionTypes.workOrderL3ChangeState:
    case WorkOrderL3ActionTypes.workOrderL3Close:
      return {
        ...state,
        loading: true,
        loaded: false
      };

    case WorkOrderL3ActionTypes.workOrderL3GetAllSuccess:
      return adapter.setAll(action.payload.content, {
        ...state,
        loading: false,
        loaded: true,
        paging: _.omit(action.payload, ['content'])
      });
    case WorkOrderL3ActionTypes.workOrderL3SaveSuccess:
    case WorkOrderL3ActionTypes.workOrderL3GetSuccess:
      return adapter.addOne(action.payload, {
        ...state,
        loading: false,
        loaded: true,
        selectedWorkOrder: action.payload,
        selectedWorkOrderId: action.payload.id
      });
    case WorkOrderL3ActionTypes.workOrderL3AssignTechnicianSuccess:
    case WorkOrderL3ActionTypes.workOrderL3AssignActionSuccess:
    case WorkOrderL3ActionTypes.workOrderL3AddNoteSuccess:
    case WorkOrderL3ActionTypes.workOrderL3PerformRepairSuccess:
    case WorkOrderL3ActionTypes.workOrderL3ChangeStateSuccess:
    case WorkOrderL3ActionTypes.workOrderL3CloseSuccess:
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state,
          selectedWorkOrder: action.payload,
          selectedWorkOrderId: action.payload.id,
          loading: false,
          loaded: true
        });
    default:
      return state;
  }
}

export const workOrderL3EntitySelectors = adapter.getSelectors();

