import { Action } from '@ngrx/store';

import { Customer } from '@appModels/core-crm/customer/customer';

export enum CustomerActionTypes {
  customerGetCustomers = '[Customer] get',
  customerGetCustomersSuccess = '[Customer] get customers success',
  customerAddCustomer = '[Customer] add Customer',
  customerAddCustomerSuccess = '[Customer] add customer success',
  customerAddAdditionalCustomerInfo = '[Customer] add additional Customer Info',
  customerAddAdditionalCustomerInfoSuccess = '[Customer] add additional customer Info success',
  customerUpdateAdditionalCustomerInfo = '[Customer] update additional Customer Info',
  customerUpdateAdditionalCustomerInfoSuccess = '[Customer] update additional customer Info success',
  customerAddPhotoInfo = '[Customer] add photo Customer Info',
  customerAddPhotoInfoSuccess = '[Customer] add photo Info success',
  customerAddCustomerGeneralInfo = '[Customer] add general Customer Info',
  customerAddCustomerGeneralInfoSuccess = '[Customer] add general customer Info success',
  customerAddLivingStandardInfo = '[Customer] add Customer living standard Info',
  customerAddLivingStandardInfoSuccess = '[Customer] add customer living standard Info success',
  customerAddUpsellMetricsInfo = '[Customer] add Customer upsell metrics Info',
  customerAddUpsellMetricsInfoSuccess = '[Customer] add customer upsell metrics Info success',
  customerUpdateCustomerGeneralInfo = '[Customer] update general Customer Info',
  customerUpdateCustomerGeneralInfoSuccess = '[Customer] update general customer Info success',
  customerUpdateLivingStandardInfo = '[Customer] update Customer living standard Info',
  customerUpdateLivingStandardInfoSuccess = '[Customer] update customer living standard Info success',
  customerUpdateUpsellMetricsInfo = '[Customer] update Customer upsell metrics Info',
  customerUpdateUpsellMetricsInfoSuccess = '[Customer] update customer upsell metrics Info success',
  customerDeleteCustomer = '[Customer] delete customer',
  customerDeleteCustomerSuccess = '[Customer] delete customer success',
  customerGetCustomerById = '[Customer] get customer by id',
  customerGetCustomerByIdSuccess = '[Customer] get customer by id success',
  customerUpdateCustomer = '[Customer] update customer',
  customerUpdateCustomerSuccess = '[Customer] update customer success',
  customerEditCustomer = '[Customer] edit customer',
  customerEditCustomerSuccess = '[Customer] edit customer success',
  customerSearchCustomers = '[Customer] search customers',
  customerSearchCustomersSuccess = '[Customer] search customers success',
  customerSearchCustomersReset = '[Customer] search customers reset',
  customerSelectedCustomersReset = '[Customer] selected customer reset',
  customerError = '[Customer] error',
  customerReset = '[Customer] reset',
  updateCustomerDigitalContractUrl = '[Customer] updateCustomerDigitalContractUrl'
}

export class GetCustomers implements Action {
  readonly type = CustomerActionTypes.customerGetCustomers;
  constructor(public payload?) {}
}

export class GetCustomersSuccess implements Action {
  readonly type = CustomerActionTypes.customerGetCustomersSuccess;
  constructor(public payload) {}
}

export class AddCustomer implements Action {
  readonly type = CustomerActionTypes.customerAddCustomer;
  constructor(public payload: Customer) {}
}

export class AddCustomerSuccess implements Action {
  readonly type = CustomerActionTypes.customerAddCustomerSuccess;
  constructor(public payload: Customer) {}
}

export class AddAdditionalCustomerInfo implements Action {
  readonly type = CustomerActionTypes.customerAddAdditionalCustomerInfo;
  constructor(public payload) {}
}

export class AddAdditionalCustomerInfoSuccess implements Action {
  readonly type = CustomerActionTypes.customerAddAdditionalCustomerInfoSuccess;
  constructor(public payload) {}
}

export class UpdateAdditionalCustomerInfo implements Action {
  readonly type = CustomerActionTypes.customerUpdateAdditionalCustomerInfo;
  constructor(public payload) {}
}

export class UpdateAdditionalCustomerInfoSuccess implements Action {
  readonly type = CustomerActionTypes.customerUpdateAdditionalCustomerInfoSuccess;
  constructor(public payload) {}
}

export class AddCustomerPhoto implements Action {
  readonly type = CustomerActionTypes.customerAddPhotoInfo;
  constructor(public payload) {}
}

export class AddCustomerPhotoSuccess implements Action {
  readonly type = CustomerActionTypes.customerAddPhotoInfoSuccess;
  constructor(public payload) {}
}

export class AddCustomerGeneralInfo implements Action {
  readonly type = CustomerActionTypes.customerAddCustomerGeneralInfo;
  constructor(public payload) {}
}

export class AddCustomerGeneralInfoSuccess implements Action {
  readonly type = CustomerActionTypes.customerAddCustomerGeneralInfoSuccess;
  constructor(public payload) {}
}

export class AddLivingStandardInfo implements Action {
  readonly type = CustomerActionTypes.customerAddLivingStandardInfo;
  constructor(public payload) {}
}

export class AddLivingStandardInfoSuccess implements Action {
  readonly type = CustomerActionTypes.customerAddLivingStandardInfoSuccess;
  constructor(public payload) {}
}

export class AddUpsellMetricsInfo implements Action {
  readonly type = CustomerActionTypes.customerAddUpsellMetricsInfo;
  constructor(public payload) {}
}

export class AddUpsellMetricsInfoSuccess implements Action {
  readonly type = CustomerActionTypes.customerAddUpsellMetricsInfoSuccess;
  constructor(public payload) {}
}

export class UpdateCustomerGeneralInfo implements Action {
  readonly type = CustomerActionTypes.customerUpdateCustomerGeneralInfo;
  constructor(public payload) {}
}

export class UpdateCustomerGeneralInfoSuccess implements Action {
  readonly type = CustomerActionTypes.customerUpdateCustomerGeneralInfoSuccess;
  constructor(public payload) {}
}

export class UpdateLivingStandardInfo implements Action {
  readonly type = CustomerActionTypes.customerUpdateLivingStandardInfo;
  constructor(public payload) {}
}

export class UpdateLivingStandardInfoSuccess implements Action {
  readonly type = CustomerActionTypes.customerUpdateLivingStandardInfoSuccess;
  constructor(public payload) {}
}

export class UpdateUpsellMetricsInfo implements Action {
  readonly type = CustomerActionTypes.customerUpdateUpsellMetricsInfo;
  constructor(public payload) {}
}

export class UpdateUpsellMetricsInfoSuccess implements Action {
  readonly type = CustomerActionTypes.customerUpdateUpsellMetricsInfoSuccess;
  constructor(public payload) {}
}

export class GetCustomerById implements Action {
  readonly type = CustomerActionTypes.customerGetCustomerById;
  constructor(public payload: any) {}
}

export class GetCustomerByIdSuccess implements Action {
  readonly type = CustomerActionTypes.customerGetCustomerByIdSuccess;
  constructor(public payload: Customer) {}
}

export class UpdateCustomer implements Action {
  readonly type = CustomerActionTypes.customerUpdateCustomer;
  constructor(public payload: Customer) {}
}

export class UpdateCustomerSuccess implements Action {
  readonly type = CustomerActionTypes.customerUpdateCustomerSuccess;
  constructor(public payload: Customer) {}
}

export class EditCustomer implements Action {
  readonly type = CustomerActionTypes.customerEditCustomer;
  constructor(public payload) {}
}

export class EditCustomerSuccess implements Action {
  readonly type = CustomerActionTypes.customerEditCustomerSuccess;
  constructor(public payload) {}
}

export class DeleteCustomer implements Action {
  readonly type = CustomerActionTypes.customerDeleteCustomer;
  constructor(public payload: Customer) {}
}

export class DeleteCustomerSuccess implements Action {
  readonly type = CustomerActionTypes.customerDeleteCustomerSuccess;
  constructor(public payload: Customer) {}
}

export class SearchCustomers implements Action {
  readonly type = CustomerActionTypes.customerSearchCustomers;
  constructor(public payload: string) {}
}

export class SearchCustomersSuccess implements Action {
  readonly type = CustomerActionTypes.customerSearchCustomersSuccess;
  constructor(public payload: Customer[]) {}
}

export class SearchCustomersReset implements Action {
  readonly type = CustomerActionTypes.customerSearchCustomersReset;
}

export class SelectedCustomerReset implements Action {
  readonly type = CustomerActionTypes.customerSelectedCustomersReset;
}

export class CustomerError implements Action {
  readonly type = CustomerActionTypes.customerError;
  constructor(public payload: any) {}
}
export class CustomerReset implements Action {
  readonly type = CustomerActionTypes.customerReset;
  constructor() {}
}


export class UpdateCustomerDigitalContractUrl implements Action {
  readonly type = CustomerActionTypes.updateCustomerDigitalContractUrl;
  constructor(public payload: any) {}
}

export type CustomerActions =
  | GetCustomers
  | GetCustomersSuccess
  | AddCustomer
  | AddCustomerSuccess
  | AddAdditionalCustomerInfo
  | AddAdditionalCustomerInfoSuccess
  | UpdateAdditionalCustomerInfo
  | UpdateAdditionalCustomerInfoSuccess
  | AddCustomerPhoto
  | AddCustomerPhotoSuccess
  | AddCustomerGeneralInfo
  | AddCustomerGeneralInfoSuccess
  | AddLivingStandardInfo
  | AddLivingStandardInfoSuccess
  | AddUpsellMetricsInfo
  | AddUpsellMetricsInfoSuccess
  | UpdateCustomerGeneralInfo
  | UpdateCustomerGeneralInfoSuccess
  | UpdateLivingStandardInfo
  | UpdateLivingStandardInfoSuccess
  | UpdateUpsellMetricsInfo
  | UpdateUpsellMetricsInfoSuccess
  | GetCustomerById
  | GetCustomerByIdSuccess
  | UpdateCustomer
  | UpdateCustomerSuccess
  | EditCustomer
  | EditCustomerSuccess
  | DeleteCustomer
  | DeleteCustomerSuccess
  | SearchCustomers
  | SearchCustomersSuccess
  | SearchCustomersReset
  | SelectedCustomerReset
  | CustomerError
  | CustomerReset
  | UpdateCustomerDigitalContractUrl;
