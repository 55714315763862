import { Action } from '@ngrx/store';
import {
  EntityAdapter,
  createEntityAdapter,
  EntityState,
  Update
} from '@ngrx/entity';

import { TenantType } from '@appModels/core-setup/tenant-type/tenant-type';
import { TenantTypeActions, TenantTypeActionTypes } from '@appStore/actions/core-masterdata/tenant-type/tenant-type.actions';

export interface State extends EntityState<TenantType> {
  loaded: boolean;
  loading: boolean;
  error: any;
  selectedTenantTypeId: number;
  searchTenantTypes: TenantType[];
}

export const adapter: EntityAdapter<TenantType> = createEntityAdapter<TenantType>();

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
  selectedTenantTypeId: null,
  error: null,
  searchTerm: '',
  searchTenantTypes: null
});

export function reducer(state = initialState, action: TenantTypeActions): State {
  switch (action.type) {
    case TenantTypeActionTypes.tenantTypeGetTenantTypes:
    case TenantTypeActionTypes.tenantTypeAddTenantType:
    case TenantTypeActionTypes.tenantTypeDeleteTenantType:
    case TenantTypeActionTypes.tenantTypeUpdateTenantType:
    case TenantTypeActionTypes.tenantTypeSearchTenantTypes:
    case TenantTypeActionTypes.tenantTypeGetTenantTypeById:
      return {
        ...state,
        loading: true
      };

    case TenantTypeActionTypes.tenantTypeGetTenantTypesSuccess:
      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });

    case TenantTypeActionTypes.tenantTypeGetTenantTypeByIdSuccess:
      return { ...state, selectedTenantTypeId: action.payload.id, loading: false };

    case TenantTypeActionTypes.tenantTypeAddTenantTypeSuccess:
      return adapter.addOne(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });

    case TenantTypeActionTypes.tenantTypeUpdateTenantTypeSuccess: {
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state,
          loading: false,
          loaded: true
        }
      );
    }

    case TenantTypeActionTypes.tenantTypeDeleteTenantTypeSuccess: {
      return adapter.removeOne(action.payload.id, {
        ...state,
        loading: false,
        loaded: true
      });
    }

    case TenantTypeActionTypes.tenantTypeSearchTenantTypesSuccess:
      return {
        ...state,
        searchTenantTypes: action.payload,
        loading: false
      };

    case TenantTypeActionTypes.tenantTypeSearchTenantTypesReset:
      return {
        ...state,
        searchTenantTypes: null
      };

    case TenantTypeActionTypes.tenantTypeError:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload
      };

    default:
      return state;
  }
}

export const tenantTypeEntitySelectors = adapter.getSelectors();
