import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getSalesStaffDefinitionStore = createFeatureSelector('salesStaffDefinition');

export const getSalesStaffDefinitionEntities = createSelector(
  getSalesStaffDefinitionStore,
  fromReducers.salesStaffDefinition.salesStaffDefinitionEntitySelectors.selectAll
);

export const getSalesStaffDefinitions = createSelector(getSalesStaffDefinitionEntities, entities => {
  return Object.values(entities);
});


export const getTopSalesStaffDefinitions = createSelector(getSalesStaffDefinitionEntities, entities => {
  return Object.values(entities).slice(1, 5);
});

export const getSalesStaffDefinitionsLoaded = createSelector(
  getSalesStaffDefinitionStore,
  (salesStaffDefinitionStore: fromReducers.salesStaffDefinition.State) => salesStaffDefinitionStore.loaded
);

export const getSalesStaffDefinitionsLoading = createSelector(
  getSalesStaffDefinitionStore,
  (salesStaffDefinitionStore: fromReducers.salesStaffDefinition.State) => salesStaffDefinitionStore.loading
);

export const getSelectedSalesStaffDefinitionId = createSelector(
  getSalesStaffDefinitionStore,
  (salesStaffDefinitionStore: fromReducers.salesStaffDefinition.State) => salesStaffDefinitionStore.selectedSalesStaffDefinitionId
);

export const getSelectedSalesStaffDefinition = createSelector(
  getSalesStaffDefinitionStore,
  (salesStaffDefinitionStore: fromReducers.salesStaffDefinition.State) => salesStaffDefinitionStore.selectedSalesStaffDefinition
);

export const getSearchSalesStaffDefinitions = createSelector(
  getSalesStaffDefinitionStore,
  (salesStaffDefinitionStore: fromReducers.salesStaffDefinition.State) => salesStaffDefinitionStore.searchSalesStaffDefinitions
);

export const getSalesStaffDefinitionById = createSelector(
  getSalesStaffDefinitionEntities,
  getSelectedSalesStaffDefinitionId,
  (entities, id) => entities.find(i => i.id === id)
);

export const getSalesStaffDefinitionError = createSelector(
  getSalesStaffDefinitionStore,
  (salesStaffDefinitionStore: fromReducers.salesStaffDefinition.State) => salesStaffDefinitionStore.error
);
