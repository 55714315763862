
<div class="flex flex-row justify-between my-8 mx-4">
  <div class="flex flex-row justify-start gap-3 " >
    <div class="text-xl text-black-dark-light">
      Home
    </div>
    <div class="text-xl font-black text-black-dark-light">
      /
    </div>
    <div class="text-xl text-black-dark-light text-orange-600">
      Call To Action Rules Mapping
    </div>
  </div>


  <button class="btn btn-dlight flex-grow-0 gap-3" routerLink="/ticketing/newCallToActionRules" >
    New Rules Mapping
    <svg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 20 20" width="20" height="20"><path d="M5.844 18.889H4.178a.556.556 0 0 1-.489-.8l3.289-6.422H3.333a.556.556 0 0 1-.466-.867L8.8 1.356a.67.67 0 0 1 .444-.267h7.467a.556.556 0 0 1 .422.933l-5.022 5.756h3a.556.556 0 0 1 .4.933l-9.244 10a.67.67 0 0 1-.4.2M5.111 17.8h.511l8.2-8.889h-2.933a.556.556 0 0 1-.422-.933l5.022-5.756H9.556l-5.245 8.334h3.578a.556.556 0 0 1 .489.8Z" class="clr-i-outline clr-i-outline-path-1"/><path d="M0 0h20v20H0z" fill="none"/></svg>
  </button>

</div>

<div class="bg-white ticket-table mt-10" style="border-radius:20px">
  <div class="grid grid-cols-4 py-3 ticket-row">
    <div class="col-span-1 my-auto pl-8">
      NAME
    </div>
    <div class="col-span-1 my-auto">
      WORKFLOW
    </div>
    <div class="col-span-1 my-auto">
      NUMBER OF RULES
    </div>
    <div class="col-span-1 my-auto">
      ACTIONS
    </div>
  </div>

  <div class="mt-5 mb-5" *ngIf="loadingTable">
    <div class="flex flex-row justify-center w-full  gap-4">
      <span class="m-auto mb-10 inline-block h-14 w-14 animate-spin rounded-full border-8 border-[#f1f2f3] border-l-dlight align-middle"></span>
    </div>
  </div>
  <ng-container *ngIf="!loadingTable">
    <div  class="grid grid-cols-4 py-3 ticket-row cursor-pointer" *ngFor="let map of maps"  >
      <div class="col-span-1 my-auto pl-8 text-black">
        {{map.name}}
      </div>

      <div class="col-span-1 my-auto  text-black">
        {{map.workflow}}
      </div>

      <div class="col-span-1 my-auto  text-black-50">
        {{map.numberOfRules}}
      </div>

      <div class="col-span-1 my-auto">

        <button  [routerLink]="'/ticketing/calltoactionrules/' + map.id" type="button" class="btn btn-sm rounded-full btn-outline-dlight "><svg _ngcontent-ydi-c23="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="w-5 h-5 ltr:mr-1.5 rtl:ml-1.5 ng-tns-c23-15"><circle _ngcontent-ydi-c23="" cx="12" cy="12" r="3" class="ng-tns-c23-15"></circle><path _ngcontent-ydi-c23="" d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z" class="ng-tns-c23-15"></path></svg>&nbsp;&nbsp;Edit </button>
      </div>



    </div>
  </ng-container>


</div>

