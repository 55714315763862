import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'crm-external-pos-and-alerts',
  template: `<div>
  <div class="modal-header" style="font-size: 30px; color: red">
    <strong>{{ "External POS Activation" | translate }}</strong>
  </div>
</div>`,
encapsulation: ViewEncapsulation.None
})

export class ExternalPosComponent implements OnInit {
  @Input() contractDetails: any
  @Input({required: true}) message: string
  constructor() { }

  ngOnInit() { }
}
