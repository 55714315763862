import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, Renderer2, TemplateRef, ViewChild, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';

import {Customer} from '@appModels/core-crm/customer/customer';

import {DatatableComponent} from '@swimlane/ngx-datatable';

import * as fromSelectors from '@appStore/selectors';
import * as fromReducer from '@appStore/reducers';

import {DeleteCustomer, GetCustomers} from '@appStore/actions/core-crm/customer/customer.actions';
import {Router} from '@angular/router';
import {Page} from '@appModels/shared/page';
import {PermissionResolver} from '@appServices/permission-resolver/permission-resolver.service';
import TimeZone from 'app/util/timezone';
import { CustomerService } from '@appServices/core-crm/customer/customer.service';
import { DashboardSEPService } from '@appServices/core-crm/dashboard-sep/dashboard-sep.service';

@Component({
  selector: 'app-sep-table',
  templateUrl: './sep-table.component.html',
  styleUrls: ['./sep-table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SEPTableComponent implements OnInit, OnDestroy {
  @ViewChild(DatatableComponent) table: DatatableComponent;
  @ViewChild('table1') table1: DatatableComponent;

  modalRef: BsModalRef;

  tableDetailOpen = false;

  statusFilter = null;

  public sepTable = new Page();

  temp = [];

  selected = [];

  customer: Customer = {
    dateOfBirth: null,
    firstName: null,
    gender: null,
    identificationValue: null,
    lastName: null,
    telephone1: null,
    telephone2: null,
    physicalAddress: null,
    email1: null,
    email2: null,
    id: null,
    preferredLanguageId: null,
    identificationTypeId: null,
    statusId: null,
    tenantID: null,
    currentSourcesOfLight: null,
    occupation: null,
    occupationOther: null,
    currentSourcesOfLightOther: null,
    secondaryContactEmailAddress: null,
    secondaryContactFirstName: null,
    secondaryContactLastName: null,
    secondaryContactTelephone: null,
    countryAdministrativeZoneId: null,
    contracts: [],
    uniqueIdentifier: null
  };

  query = null;
  perPage = 10;
  category = '';
  payment_progress = '';
  accountNumber = ''
  ptpDateFrom = ''
  ptpDateTo = ''

  searchType = 'accountNumber';

  lastLoaded = TimeZone.getFormatedTimezoneFormated();

  formatDate = TimeZone.getFormatedTimezoneFromDate;

  country = localStorage.getItem('country');
  loading: boolean = false;
  sepTableContent: any[];
  timeout: any;

  currency = localStorage.getItem('currency');
  daysMin: number = null;
  daysMax: number = null;

  searchResults = {
    content: [],
    totalElements: null
  };

  constructor(
    private store: Store<fromReducer.customer.State>,
    private modalService: BsModalService,
    private router: Router,
    private renderer: Renderer2,
    private permissionResolverService: PermissionResolver,
    private dashboardSEPService: DashboardSEPService,
    private ref: ChangeDetectorRef,
  ) { }


  onTableClick(customer) {
    this.customer = customer.selected !== undefined ? customer.selected[0] : customer;
    if (this.tableDetailOpen) {
      this.renderer.removeClass(document.body, 'table_collapse');
    } else {
      this.renderer.addClass(document.body, 'table_collapse');
    }
    this.tableDetailOpen = !this.tableDetailOpen;
  }

  toggleExpandRow(row, tableComponent) {
    tableComponent.rowDetail.toggleExpandRow(row);
  }

  onDetailToggle(event) {
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'table_collapse');
  }

  openAction(template: TemplateRef<any>, customer) {
    this.customer = customer;
    this.modalRef = this.modalService.show(template);
  }

  viewCustomer(customer) {
    this.router.navigateByUrl(`/crm/customers/${customer.uniqueIdentifier}`);
  }

  reloadTable() {
    this.lastLoaded = TimeZone.getFormatedTimezoneFormated();

    this.getCustomers({page: 0, size: this.perPage});
  }

  setPage(pageInfo) {
    this.lastLoaded = TimeZone.getFormatedTimezoneFormated();
    if(this.accountNumber !== ''){
      this.getCustomers({page: pageInfo.offset, size: this.perPage, accountNumber: this.accountNumber});
    }
    if(this.payment_progress === '0-10'){
      this.getCustomers({page: pageInfo.offset, size: this.perPage, daysMin: '0', daysMax: '10'});
    }
    if(this.payment_progress === '11-30'){
      this.getCustomers({page: pageInfo.offset, size: this.perPage, daysMin: '11', daysMax: '30'});
    }
    if(this.payment_progress === '31'){
      this.getCustomers({page: pageInfo.offset, size: this.perPage, daysMin: '31'});
    }
    if(this.payment_progress === 'credit'){
      this.getCustomers({page: pageInfo.offset, size: this.perPage, daysMin: '0'});
    }
    if(this.payment_progress === '0'){
      this.getCustomers({page: pageInfo.offset, size: this.perPage, paymentCountAfterDeposit: '0'});
    }
    if(this.payment_progress === '2'){
      this.getCustomers({page: pageInfo.offset, size: this.perPage, paymentCountAfterDeposit: '2'});
    }
    if(this.accountNumber === '' && this.payment_progress === '' && this.category === '' && this.ptpDateFrom === '' && this.ptpDateTo === '') {
      this.getCustomers({page: pageInfo.offset, size: this.perPage});
    }
    if(this.category !== '') {
      this.getCustomers({page: pageInfo.offset, size: this.perPage, category: this.category});
    }
    if(this.ptpDateFrom !== '' && this.ptpDateTo !== '') {
      this.getCustomers({page: pageInfo.offset, size: this.perPage, ptpDateFrom: this.ptpDateFrom, ptpDateTo: this.ptpDateTo});
    }
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
    }, 100);
  }

  getCustomers(query){
    this.loading = true;
    this.dashboardSEPService.getSalesAgentCustomers(query).subscribe(
      data => {
        this.sepTable = data;
        this.sepTableContent = [...data?.content];
        this.ref.detectChanges()
        this.loading = false
      },
      () => this.loading = false
    )
  }

  searchCustomer($event) {
    const sendQuery = {page: 0, size: 10};

    sendQuery[this.searchType] = (this.query)?.trim();
    this.dashboardSEPService.getSalesAgentCustomers(sendQuery).subscribe(data => {
      this.sepTable = data;
      this.sepTableContent = [...data?.content];
      this.ref.detectChanges()
      this.loading = false
    });
  }

  can(action) {
    return this.permissionResolverService.setModuleName('CRM').can(action);
  }

  ngOnInit() {
    // remove table sidebar if exist
    this.renderer.removeClass(document.body, 'table_collapse');

    // Dispatch Customers and pagination info
    this.getCustomers({page: 0, size: this.perPage})
  }

}
