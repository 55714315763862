import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getAdminZoneStore = createFeatureSelector('adminZone');

export const getAdminZoneEntities = createSelector(
  getAdminZoneStore,
  fromReducers.adminZone.adminZoneEntitySelectors.selectAll
);

export const getAdminZones = createSelector(getAdminZoneEntities, entities => {
  return Object.values(entities);
});

export const getTopAdminZones = createSelector(getAdminZoneEntities, entities => {
  return Object.values(entities).slice(1, 5);
});

export const getAdminZonesLoaded = createSelector(
  getAdminZoneStore,
  (adminZoneStore: fromReducers.adminZone.State) => adminZoneStore.loaded
);

export const getAdminZonesLoading = createSelector(
  getAdminZoneStore,
  (adminZoneStore: fromReducers.adminZone.State) => adminZoneStore.loading
);

export const getSelectedAdminZoneId = createSelector(
  getAdminZoneStore,
  (adminZoneStore: fromReducers.adminZone.State) => adminZoneStore.selectedAdminZoneId
);

export const getSearchAdminZones = createSelector(
  getAdminZoneStore,
  (adminZoneStore: fromReducers.adminZone.State) => adminZoneStore.searchAdminZones
);

export const getAdminZoneById = createSelector(
  getAdminZoneEntities,
  getSelectedAdminZoneId,
  (entities, id) => entities.find(i => i.id === id)
);

export const getAdminZonesError = createSelector(
  getAdminZoneStore,
  (adminZoneStore: fromReducers.adminZone.State) => adminZoneStore.error
);
