import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from "@angular/core";

interface ILeftModalFooterConfigProps {
  statusName: string;
  canApproveAwaitingMoreInfo: boolean;
  popoverTitle: string;
  popoverMessage: string;
  loadingApprove: any;
  disableApprove: any;
  assignedUserId: any;
  user: any;
  loadingResubmit: any;
  disableResubmit: any;
  assignedUser: any;
  loadingReject: any;
  disableReject: any;
  loadingBlacklist: any;
  disableBlacklist: any;
  loadingCallBack: any;
  disableCallBack: any;
  isAllScoreAnswered: boolean;
}

@Component({
  selector: "left-modal-footer",
  template: ` <div
      class="modal-footer"
      *ngIf="
        config.statusName !== 'BLACKLISTED' && config.canApproveAwaitingMoreInfo
      "
    >
      <button
        class="button_secondary pull-right"
        mwlConfirmationPopover
        [popoverTitle]="config.popoverTitle"
        [popoverMessage]="config.popoverMessage"
        placement="left"
        [disabled]="
          (config.loadingApprove && config.disableApprove) ||
          (config.assignedUserId && config.assignedUserId !== config.user) || (componentMode !== kycComponentModeEnum.EDIT)
        "
        (confirm)="approveVerification.emit()"
        (cancel)="cancelClicked.emit()"
      >
        {{ config.loadingApprove ? "Loading..." : ("Approve" | translate) }}
      </button>
      <input
        class="button_secondary pull-right primary text-white"
        type="submit"
        [disabled]="
          (config.loadingResubmit && config.disableResubmit) ||
          (config.assignedUser && config.assignedUserId !== config.user) || (componentMode !== kycComponentModeEnum.EDIT)
        "
        (click)="resubmitVerification.emit()"
        [value]="config.loadingResubmit ? 'Loading...' : 'More Info Required'"
      />
      <input
        class="button_secondary pull-right danger text-white"
        type="submit"
        [disabled]="
          (config.loadingReject && config.disableReject) ||
          (config.assignedUser && config.assignedUserId !== config.user)
        "
        (click)="rejectVerification.emit()"
        [value]="config.loadingReject ? 'Loading...' : 'Reject'"
      />
      <input
        class="button_secondary pull-right danger-dark text-white"
        type="submit"
        [disabled]="
          (config.loadingBlacklist && config.disableBlacklist) ||
          (config.assignedUser && config.assignedUserId !== config.user)
        "
        (click)="blacklistVerification.emit()"
        [value]="config.loadingBlacklist ? 'Loading...' : 'Blacklist'"
      />
      <input
        class="button_secondary pull-right danger-dark text-white"
        type="submit"
        [disabled]="
          (config.loadingCallBack && config.disableCallBack) ||
          (config.assignedUser && config.assignedUserId !== config.user)
        "
        (click)="requestCallBackVerification.emit()"
        [value]="config.loadingCallBack? 'Loading...' : 'Request Call Back'"
      />
    </div>
    <div class="modal-footer" *ngIf="config.statusName === 'BLACKLISTED'">
      <button
        class="button_secondary pull-right"
        type="button"
        (click)="closeClicked.emit()"
      >
        {{ "Close" | translate }}
      </button>
    </div>`,
  encapsulation: ViewEncapsulation.None,
})

export class LeftModalFooterComponent implements OnInit {
  @Input() config: ILeftModalFooterConfigProps;

  @Output() approveVerification = new EventEmitter<any>();
  @Output() resubmitVerification = new EventEmitter<any>();
  @Output() rejectVerification = new EventEmitter<any>();
  @Output() blacklistVerification = new EventEmitter<any>();
  @Output() requestCallBackVerification = new EventEmitter<any>();
  @Output() cancelClicked = new EventEmitter<any>();
  @Output() closeClicked = new EventEmitter<any>();
  @Input() kycComponentModeEnum: any;
  @Input() componentMode: any;
  constructor() {}

  ngOnInit() {}
}
