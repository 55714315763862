import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';

import {VatConfiguration} from '@appModels/core-accounting/vat-configuration/vat-configuration';
import {VatConfigurationActions, VatConfigurationActionTypes} from '@appStore/actions/core-accounting/vat-configuration/vat-configuration.actions';
import * as _ from 'lodash';

export interface State extends EntityState<VatConfiguration> {
  loaded: boolean;
  loading: boolean;
  error: any;
  selectedVatConfigurationId: number;
  searchVatConfigurations: VatConfiguration[];
  paging: any;
}

export const adapter: EntityAdapter<VatConfiguration> = createEntityAdapter<VatConfiguration>();

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
  selectedVatConfigurationId: null,
  error: null,
  searchTerm: '',
  searchVatConfigurations: null,
  paging: null
});

export function reducer(state = initialState, action: VatConfigurationActions): State {
  switch (action.type) {
    case VatConfigurationActionTypes.vatConfigurationGetVatConfigurations:
    case VatConfigurationActionTypes.vatConfigurationGetVatConfigurationByTenant:
    case VatConfigurationActionTypes.vatConfigurationAddVatConfiguration:
    case VatConfigurationActionTypes.vatConfigurationAddAdditionalVatConfigurationInfo:
    case VatConfigurationActionTypes.vatConfigurationDeleteVatConfiguration:
    case VatConfigurationActionTypes.vatConfigurationUpdateVatConfiguration:
    case VatConfigurationActionTypes.vatConfigurationSearchVatConfigurations:
    case VatConfigurationActionTypes.vatConfigurationGetVatConfigurationById:
      return {
        ...state,
        loading: true
      };

    case VatConfigurationActionTypes.vatConfigurationGetVatConfigurationsSuccess:
      return adapter.setAll(action.payload.content, {
        ...state,
        loading: false,
        loaded: true,
        paging: _.omit(action.payload, ['content'])
      });

    case VatConfigurationActionTypes.vatConfigurationGetVatConfigurationByTenantSuccess:
      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });

    case VatConfigurationActionTypes.vatConfigurationGetVatConfigurationByIdSuccess:
      return { ...state, selectedVatConfigurationId: action.payload.id, loading: false };

    case VatConfigurationActionTypes.vatConfigurationAddVatConfigurationSuccess:
      return adapter.addOne(action.payload, {
        ...state,
        selectedVatConfigurationId: action.payload.id,
        loading: false,
        loaded: true
      });

    case VatConfigurationActionTypes.vatConfigurationUpdateVatConfigurationSuccess: {
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state,
          loading: false,
          loaded: true
        }
      );
    }

    case VatConfigurationActionTypes.vatConfigurationDeleteVatConfigurationSuccess: {
      return adapter.removeOne(action.payload.id, {
        ...state,
        loading: false,
        loaded: true
      });
    }

    case VatConfigurationActionTypes.vatConfigurationSearchVatConfigurationsSuccess:
      return {
        ...state,
        searchVatConfigurations: action.payload,
        loading: false
      };

    case VatConfigurationActionTypes.vatConfigurationSearchVatConfigurationsReset:
      return {
        ...state,
        searchVatConfigurations: null
      };

    case VatConfigurationActionTypes.vatConfigurationError:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload
      };

    default:
      return state;
  }
}

export const vatConfigurationEntitySelectors = adapter.getSelectors();
