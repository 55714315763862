import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

import {
  AddProduct,
  AddProductSuccess,
  DeleteProduct,
  DeleteProductSuccess,
  GetProductById,
  GetProductByIdSuccess,
  GetProducts,
  GetProductsSuccess,
  ProductActionTypes,
  ProductError,
  SearchProducts,
  SearchProductsSuccess,
  UpdateProductSuccess
} from '@appStore/actions/core-masterdata/product/product.actions';
import {ProductService} from '@appServices/core-masterdata/product/product.service';

import * as fromRouterActions from '@appStore/actions/router.actions';
import {GetProductsByType, GetProductsByTypeSuccess} from '../../../actions/core-masterdata/product/product.actions';

@Injectable()
export class ProductEffects {
  constructor(private actions$: Actions, private productService: ProductService) {}

  
  loadProducts$ = createEffect(() => this.actions$.pipe(
    ofType(ProductActionTypes.productGetProducts),
    mergeMap((action: GetProducts) =>
      this.productService
        .getProducts(action.payload)
        .pipe(
          map(products => new GetProductsSuccess(products)),
          catchError(error => of(new ProductError(error)))
        )
    )
  ));

  
  getProductById$ = createEffect(() => this.actions$.pipe(
    ofType(ProductActionTypes.productGetProductById),
    mergeMap((action: GetProductById) =>
      this.productService
        .getProduct(action.payload)
        .pipe(
          map(product => new GetProductByIdSuccess(product)),
          catchError(error => of(new ProductError(error)))
        )
    )
  ));

  
  getProductsByType$ = createEffect(() => this.actions$.pipe(
    ofType(ProductActionTypes.productGetProductsByType),
    mergeMap((action: GetProductsByType) =>
      this.productService
        .getProductsByType(action.payload)
        .pipe(
          map(product => new GetProductsByTypeSuccess(product)),
          catchError(error => of(new ProductError(error)))
        )
    )
  ));

  
  addProduct$ = createEffect(() => this.actions$.pipe(
    ofType(ProductActionTypes.productAddProduct),
    switchMap((action: AddProduct) =>
      this.productService
        .addProduct(action.payload)
        .pipe(
          map(product => new AddProductSuccess(product)),
          catchError(error => of(new ProductError(error)))
        )
    )
  ));

  
  updateProduct$ = createEffect(() => this.actions$.pipe(
    ofType(ProductActionTypes.productUpdateProduct),
    mergeMap((action: AddProduct) =>
      this.productService
        .updateProduct(action.payload)
        .pipe(
          map(product => new UpdateProductSuccess(action.payload)),
          catchError(error => of(new ProductError(error)))
        )
    )
  ));

  
  editProduct$ = createEffect(() => this.actions$.pipe(
    ofType(ProductActionTypes.productEditProduct),
    mergeMap((action: AddProduct) =>
      this.productService
        .editProduct(action.payload)
        .pipe(
          map(product => new GetProducts()),
          catchError(error => of(new ProductError(error)))
        )
    )
  ));

  
  deleteProduct$ = createEffect(() => this.actions$.pipe(
    ofType(ProductActionTypes.productDeleteProduct),
    mergeMap((action: DeleteProduct) =>
      this.productService
        .deleteProduct(action.payload)
        .pipe(
          map(() => new DeleteProductSuccess(action.payload)),
          catchError(error => of(new ProductError(error)))
        )
    )
  ));

  
  searchProducts$ = createEffect(() => this.actions$.pipe(
    ofType(ProductActionTypes.productSearchProducts),
    mergeMap((action: SearchProducts) =>
      this.productService
        .searchProducts(action.payload)
        .pipe(
          map(products => new SearchProductsSuccess(products)),
          catchError(error => of(new ProductError(error)))
        )
    )
  ));

  
  updateProductSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(ProductActionTypes.productUpdateProductSuccess),
    map(product => new fromRouterActions.Go({ path: ['/setup/products'] }))
  ));
}
