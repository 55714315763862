import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import {catchError, map, mergeMap, switchMap, tap} from 'rxjs/operators';
import {of} from 'rxjs';

import {ConsignmentService} from '@appServices/core-inventory/consignment/consignment-service';
import {TenantStockingPointService} from '@appServices/core-inventory/stocking-point/tenant-stocking-point.service';
import {ToastrService} from 'ngx-toastr';
import {
  AddConsignment,
  AddConsignmentSuccess, AddConsignmentWithProduct,
  ConsignmentActionTypes,
  ConsignmentDoNothing,
  ConsignmentError,
  ConsignmentPatchShippingCompany,
  ConsignmentPatchStockingPointFromSuccess,
  GetConsignmentById,
  GetConsignmentByIdSuccess,
  GetConsignmentByTenantId,
  GetConsignmentByTenantIdSuccess,
  UpdateConsignment,
  UpdateConsignmentSuccess,
  ConsignmentOk
} from '../../../actions/core-inventory/consignment/consignment.action';
import {Router} from '@angular/router';
import {ConsignmentAddInventoryProductToConsignment} from '../../../actions/core-inventory/consignment-product/consignment-product.action';
import {Store} from '@ngrx/store';
import * as fromReducer from '../../../reducers';

@Injectable()
export class ConsignmentEffects {
  
  loadConsignmentsByStockingPointId$ = createEffect(() => this.actions$.pipe(
    ofType(ConsignmentActionTypes.consignmentGetConsignmentByTenantId),
    mergeMap((action: GetConsignmentByTenantId) =>
      this.consignmentService
        .getConsignmentByTenantId(action.payload)
        .pipe(
          map(consignments => new GetConsignmentByTenantIdSuccess(consignments)),
          catchError(error => of(new ConsignmentError(error)))
        )
    )
  ));


  
  addConsignment$ = createEffect(() => this.actions$.pipe(
    ofType(ConsignmentActionTypes.consignmentAddConsignment),
    switchMap((action: AddConsignment) =>
      this.consignmentService
        .addConsignment(action.payload)
        .pipe(
          map(
            consignment => {
              this.toastr.success('Consignment added successfully!', 'Success');
              this.router.navigate([`inventory/consignment-products/consignment/${consignment.id}/status/0`]);
              return new ConsignmentOk();
            }
          ),
          catchError(error => of(new ConsignmentError(error)))
        )
    )
  ));

  
  addConsignmentWithProduct$ = createEffect(() => this.actions$.pipe(
    ofType(ConsignmentActionTypes.consignmentAddWithProduct),
    switchMap((action: AddConsignmentWithProduct) =>
      this.consignmentService
        .addConsignment(action.payload.consingment)
        .pipe(
          map(
            consignment => {
              if (consignment) {
                this.toastr.success('Consignment added successfully!', 'Success');
                if(!action.payload.isNonSerialized){
                  this.store.dispatch(new ConsignmentAddInventoryProductToConsignment(consignment.id, action.payload.productId));
                } else {
                  this.consignmentService.addQuantityGlobalProductVersionToConsignment(
                    consignment.id,
                    action.payload.productId,
                    action.payload.tenantProductCatalogueQuantity
                  ).subscribe(
                    data => {
                      this.toastr.success('Parts added successfully', 'Success');
                    }
                  )
                }
              }
              return new ConsignmentDoNothing();

            }
          ),
          catchError(error => of(new ConsignmentError(error)))
        )
    )
  ));


  
  consignmentPatchShippingCompany$ = createEffect(() => this.actions$.pipe(
    ofType(ConsignmentActionTypes.consignmentPatchShippingCompany),
    switchMap((action: ConsignmentPatchShippingCompany) =>
      this.consignmentService
        .patchShippingCompanyConsignment(action.payload)
        .pipe(
          map(
            consignment => {
              this.toastr.success('Consignment patched to shipping company successfully!', 'Success');
              if (action.payload.parentStockingPointFromId != null) {
                return new AddConsignmentSuccess(action.payload);
              } else if (action.payload.parentStockingPointToId != null) {
                return new ConsignmentPatchStockingPointFromSuccess(action.payload);
              }
            }
          ),
          catchError(error => of(new ConsignmentError(error)))
        )
    )
  ));


  
  addConsignmentSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(ConsignmentActionTypes.consignmentAddConsignmentSuccess),
    switchMap((action: AddConsignmentSuccess) =>
      this.consignmentService
        .patchStockingPointFromConsignment(action.payload)
        .pipe(
          map(
            consignment => {
              this.toastr.success('Stocking point from added successfully!', 'Success');
              return new ConsignmentPatchStockingPointFromSuccess(action.payload);

            }
          ),
          catchError(error => of(new ConsignmentError(error)))
        )
    )
  ));

  
  patchStockingPointFromSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(ConsignmentActionTypes.consignmentPatchStockingPointFromSuccess),
    switchMap((action: ConsignmentPatchStockingPointFromSuccess) =>
      this.consignmentService
        .patchStockingPointToConsignment(action.payload)
        .pipe(
          map(
            consignment => {
              this.toastr.success('Stocking point to added successfully!', 'Success');
              action.payload.parentStockingPointToId = consignment.parentStockingPointToId;
              return new ConsignmentDoNothing();
            }
          ),
          catchError(error => of(new ConsignmentError(error)))
        )
    )
  ));

  
  consignmentDoNothing$ = createEffect(() => this.actions$.pipe(
    ofType(ConsignmentActionTypes.consignmentDoNothing),
    tap(() => this.router.navigate([`/inventory/consignments`]))
  ), {dispatch: false});

  
  consignmentOk$ = createEffect(() => this.actions$.pipe(
    ofType(ConsignmentActionTypes.consignmentOk)
  ), {dispatch: false});


  
  getConsignmentById$ = createEffect(() => this.actions$.pipe(
    ofType(ConsignmentActionTypes.consignmentGetConsignmentById),
    switchMap((action: GetConsignmentById) =>
      this.consignmentService
        .getConsignmentById(action.payload)
        .pipe(
          map(
            consignment => {
              return new GetConsignmentByIdSuccess(consignment);
            }
          ),
          catchError(error => of(new ConsignmentError(error)))
        )
    )
  ));


  
  updateConsignment$ = createEffect(() => this.actions$.pipe(
    ofType(ConsignmentActionTypes.consignmentUpdateConsignment),
    mergeMap((action: UpdateConsignment) =>
      this.consignmentService
        .updateConsignment(action.payload)
        .pipe(
          map(consignment => new UpdateConsignmentSuccess(action.payload)),
          catchError(error => of(new ConsignmentError(error)))
        )
    )
  ));


  constructor(
    private actions$: Actions,
    private consignmentService: ConsignmentService,
    private tenantStockingPointService: TenantStockingPointService,
    private toastr: ToastrService,
    private router: Router,
    private store: Store<fromReducer.consignmentProduct.State>,
  ) {
  }


}



