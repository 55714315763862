import { Injectable, Injector } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, } from '@angular/common/http';

import { Observable, throwError} from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/browser';
import { Severity } from '@sentry/browser';

import 'rxjs/add/operator/catch'

export const InterceptorSkipHeader = 'X-Skip-Interceptor';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private injector: Injector) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.has(InterceptorSkipHeader)) {
      const headers = req.headers.delete(InterceptorSkipHeader);
      return next.handle(req.clone({ headers }));
    }

    const token = localStorage.getItem('authorization');
    if (token !== null && token !== undefined) {
      req = req.clone({
        setHeaders: {
          Authorization: JSON.parse(token).authorization
        }
      });
    }
    return next.handle(req).catch(err => this.handleError(err));
  }

  private handleError(err: HttpErrorResponse): Observable<any> {
    let errorMsg;


    if (err.error instanceof Error) {

      // A client-side or network error occurred. Handle it accordingly.
        errorMsg = `An error occurred: ${err?.error?.message}`
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMsg = `${err?.error?.message || err?.error}` ;

      if(!errorMsg || errorMsg == 'undefined'){
        errorMsg = err.error;
      }
      Sentry.captureException(err.error)
    }

    if (err && err.status === 401) {
      localStorage.clear();
      if(location.pathname !== `/login`){
        localStorage.setItem('redirectUrl', location.pathname )
      }
      this.injector.get(Router).navigateByUrl(`/login`);
      return throwError(errorMsg);
      } else {
        console.log(err, 'err...')
        Sentry.withScope(function (scope) {
          let eventId = null
          scope.setLevel(Severity.Error);
          Sentry.setExtra('extra', {
            code: err.status,
            api_url: err.url,
            api_message: err.message,
            name: err.name
          })
          Sentry.setTag('generic_name', err?.name)
          Sentry.setTag('server_error_type', err?.error?.status)
          Sentry.captureException(err)
        })
        if (errorMsg.includes('JWT')) {
          if (localStorage.getItem('JwtErrorShown') === null) {
            localStorage.setItem('JwtErrorShown', '1');

            this.injector
              .get(ToastrService)
              .error("Your session has expired. Please login again");

            setTimeout(() => {
              localStorage.clear();
              location.reload();
            }, 3200);
          }
        } else {
          this.injector
            .get(ToastrService)
            .error(errorMsg);
        }

        return throwError(errorMsg);
    }
  }
}
