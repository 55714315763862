import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import * as _ from 'lodash';

import {Wallet} from '@appModels/core-accounting/wallet/wallet';
import {WalletActions, WalletActionTypes} from '@appStore/actions/core-accounting/wallet/wallet.actions';

export interface State extends EntityState<Wallet> {
  loaded: boolean;
  loading: boolean;
  error: any;
  selectedWalletId: number;
  searchWallets: Wallet[];
  paging: any;
}

export const adapter: EntityAdapter<Wallet> = createEntityAdapter<Wallet>();

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
  selectedWalletId: null,
  error: null,
  searchTerm: '',
  searchWallets: null,
  paging: null
});

export function reducer(state = initialState, action: WalletActions): State {
  switch (action.type) {
    case WalletActionTypes.walletGetWallets:
    case WalletActionTypes.walletGetWalletsByTenant:
    case WalletActionTypes.walletAddWallet:
    case WalletActionTypes.walletUploadWallet:
    case WalletActionTypes.walletUploadWalletSuccess:
    case WalletActionTypes.walletDeleteWallet:
    case WalletActionTypes.walletUpdateWallet:
    case WalletActionTypes.walletSearchWallets:
    case WalletActionTypes.walletGetWalletById:
      return {
        ...state,
        loading: true
      };

    case WalletActionTypes.walletGetWalletsSuccess:
      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });

    case WalletActionTypes.walletGetWalletsByTenantSuccess:
      console.log(action.payload.content, 'action.payload.content');
      return adapter.setAll(action.payload.content, {
        ...state,
        loading: false,
        loaded: true,
        paging: _.omit(action.payload, ['content'])
      });

    case WalletActionTypes.walletGetWalletByIdSuccess:
      return {...state, selectedWalletId: action.payload.id, loading: false};

    case WalletActionTypes.walletAddWalletSuccess:
      return adapter.addOne(action.payload, {
        ...state,
        error: null,
        loading: false,
        loaded: true
      });
    case WalletActionTypes.walletUpdateWalletSuccess: {
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state,
          loading: false,
          loaded: true
        }
      );
    }

    case WalletActionTypes.walletDeleteWalletSuccess: {
      return adapter.removeOne(action.payload.id, {
        ...state,
        loading: false,
        loaded: true
      });
    }

    case WalletActionTypes.walletSearchWalletsSuccess:
      return {
        ...state,
        searchWallets: action.payload,
        loading: false
      };

    case WalletActionTypes.walletSearchWalletsReset:
      return {
        ...state,
        searchWallets: null
      };

    case WalletActionTypes.walletError:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload
      };

    default:
      return state;
  }
}

export const walletEntitySelectors = adapter.getSelectors();
