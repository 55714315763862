import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from "@angular/core";

@Component({
  selector: "kyc-spe-detail",
  template: ` <div
      *ngIf="!sepDetailCheck && formNotValid"
      class="notification error"
    >
      {{ "Please confirm SEP Details" | translate }}
    </div>

    <h4 class="m-t-20 m-b-10">
      <input
  
        type="checkbox"
        *ngIf="statusName !== 'BLACKLISTED' "
        class="m-r-10"
        [ngModel]="sepDetailCheck"
        (ngModelChange)="sepDetailCheckChange.emit($event)"
        

        name="sepDetailCheck"
      />
      {{ "SEP Details" | translate }}
    </h4>
    <table style="background: #f7f7fc; border-radius: 3px">
      <thead>
        <tr>
          <th>{{ "NAME" | translate }}</th>
          <th>{{ "PHONE NO" | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <b> {{ salesAgent || "--" }} </b>
          </td>
          <td>
            <a href="javascript:void(0)"> {{ salesAgentPhone || "--" }} </a>
          </td>
        </tr>
      </tbody>
    </table>`,
  encapsulation: ViewEncapsulation.None,
})
export class KycSpeDetailComponent implements OnInit {
  @Input() salesAgent: any;
  @Input() salesAgentPhone: any;
  @Input() statusName: any;
  @Input() formNotValid: any;
  @Input() sepDetailCheck: any;
  @Input() kycComponentModeEnum: any;
  @Input() componentMode: any;
  @Output() sepDetailCheckChange = new EventEmitter<any>();
  constructor() {}

  ngOnInit() {}
}
