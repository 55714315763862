import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

import {
  AddPart,
  AddPartSuccess,
  DeletePart,
  DeletePartSuccess,
  GetPartById,
  GetPartByIdSuccess,
  GetParts,
  GetPartsByTenantId,
  GetPartsByTenantIdSuccess,
  PartActionTypes,
  PartsError,
  SearchParts,
  SearchPartsSuccess,
  UpdatePart,
  UpdatePartSuccess,
  FilterParts
} from '@appStore/actions/core-aftersales/part/part.actions';
import {TenantService} from '@appServices/core-masterdata/tenant/tenant.service';

import * as fromRouterActions from '@appStore/actions/router.actions';
import {ToastrService} from 'ngx-toastr';
import {TenantSalesStaffService} from "@appServices/core-fieldforce/sales-staff/tenant-sales-staff.service";
import {FilterSalesStaffSuccess} from "../../../actions/core-fieldforce/sales-staff/sales-staff.actions";
import {TicketService} from "../../../../services/core-aftersales/ticket/ticket.service";
import {FilterTicketsSuccess} from "../../../actions/core-aftersales/ticket/ticket.actions";
import {FilterPartsSuccess} from "../../../actions/core-aftersales/part/part.actions";
import {PartService} from "../../../../services/core-masterdata/part/part.service";
import {Router} from "@angular/router";

@Injectable()
export class PartEffects {
  
  loadParts$ = createEffect(() => this.actions$.pipe(
    ofType(PartActionTypes.partGePartByTenantId),
    mergeMap((action: GetPartsByTenantId) =>
      this.partService
        .getTenantParts(action.payload)
        .pipe(
          map(data => new GetPartsByTenantIdSuccess(data)),
          catchError(error => of(new PartsError(error)))
        )
    )
  ));

  
  updatePart$ = createEffect(() => this.actions$.pipe(
    ofType(PartActionTypes.partUpdatePart),
    mergeMap((action: UpdatePart) =>
      this.partService
        .updatePart(action.payload)
        .pipe(
          map(rate => {
            if (rate !== undefined) {
              this.toastr.success('Part has been successfully updated!', 'Successful!');
              this.router.navigate(['/setup/parts']);
              return new UpdatePartSuccess(action.payload);
            }
            // this.toastr.error('There was an error updating this rate', "Unknown error");
            return new PartsError({type: 500, message: 'Internal error'});
          }),
          catchError(error => of(new PartsError(error)))
        )
    )
  ));


  
  addTicket$ = createEffect(() => this.actions$.pipe(
    ofType(PartActionTypes.partAddPart),
    switchMap((action: AddPart) =>
      this.partService
        .addPart(action.payload)
        .pipe(
          map(
            stockingPoint => {
              if (stockingPoint !== undefined) {
                this.toastr.success('Part has been successfully added!', 'Successful!');
                return new AddPartSuccess(stockingPoint)
              }
              this.toastr.error('There was an error creating part!', "Unknown error");
              return new PartsError({type: 500, message: 'Internal error'})
            }
          ),
          catchError(error => of(new PartsError(error)))
        )
    )
  ));



  
  filterParts$ = createEffect(() => this.actions$.pipe(
    ofType(PartActionTypes.partFilterPartsStaff),
    mergeMap((action: FilterParts) =>
      this.partService
        .filterParts(action.payload)
        .pipe(
          map(customers => new FilterPartsSuccess(customers)),
          catchError(error => of(new PartsError(error)))
        )
    )
  ));

  
  part$ = createEffect(() => this.actions$.pipe(
    ofType(PartActionTypes.partGetPartById),
    mergeMap((action: GetPartById) =>
      this.partService
        .getPart(action.payload)
        .pipe(
          map(ticket => new GetPartByIdSuccess(ticket)),
          catchError(error => of(new PartsError(error)))
        )
    )
  ));


  constructor(
    private actions$: Actions,
    private partService: PartService,
    private toastr: ToastrService,
    private router: Router
  ) {
  }
}
