import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';

import {
  ProductWarrantyTypesActions,
  ProductWarrantyTypesActionTypes
} from '@appStore/actions/core-masterdata/product-warranty-types/product-warranty-types.actions';
import {ProductWarrantyType} from '@appModels/core-setup/product-warranty-type/product-warranty-type';

export interface State extends EntityState<ProductWarrantyType> {
  loaded: boolean;
  loading: boolean;
  error: any;
  searchProductWarrantyTypes: ProductWarrantyType[];
}

export const adapter: EntityAdapter<ProductWarrantyType> = createEntityAdapter<ProductWarrantyType>();

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
  error: null,
  searchProductWarrantyTypes: null
});

export function reducer(state = initialState, action: ProductWarrantyTypesActions): State {
  switch (action.type) {
    case ProductWarrantyTypesActionTypes.productWarrantyGetProductWarrantyTypes:
      return {
        ...state,
        loading: true
      };
    case ProductWarrantyTypesActionTypes.productWarrantyGetProductWarrantyTypesSuccess:
      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });

    default:
      return state;
  }
}

export const productWarrantyTypesEntitySelectors = adapter.getSelectors();
