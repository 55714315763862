import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import {catchError, map, mergeMap} from 'rxjs/operators';
import {of} from 'rxjs';

import {InventoryProductService} from "@appServices/core-inventory/inventory-product/inventory-product.service";

import {
  AddInventoryProduct,
  DeleteInventoryProduct,
  DeleteInventoryProductSuccess,
  GetInventoryProductById,
  GetInventoryProductByIdSuccess,
  GetInventoryProducts,
  GetInventoryProductsSuccess,
  InventoryProductActionTypes,
  InventoryProductError,
  PatchInventoryProduct,
  PatchInventoryProductSuccess,
  UpdateInventoryProduct,
  UploadInventoryProduct
} from "@appStore/actions/core-inventory/inventory-product/inventory-product.actions";
import {ToastrService} from "ngx-toastr";


@Injectable()
export class InventoryProductEffects {

  
  loadInventoryProducts$ = createEffect(() => this.actions$.pipe(
    ofType(InventoryProductActionTypes.inventoryProductGetInventoryProducts),
    mergeMap((action: GetInventoryProducts) =>
      this.inventoryProductService
        .getInventoryProducts(action.payload)
        .pipe(
          map(inventoryProducts => new GetInventoryProductsSuccess(inventoryProducts)),
          catchError(error => of(new InventoryProductError(error)))
        )
    )
  ));

  
  uploadInventoryProduct$ = createEffect(() => this.actions$.pipe(
    ofType(InventoryProductActionTypes.inventoryProductUploadInventoryProduct),
    mergeMap((action: UploadInventoryProduct) =>
      this.inventoryProductService
        .uploadInventoryProduct(action.payload)
        .pipe(
          map(res => new GetInventoryProducts(action.payload)),
          catchError(error => of(new InventoryProductError(error)))
        )
    )
  ));

  
  addInventoryProduct = createEffect(() => this.actions$.pipe(
    ofType(InventoryProductActionTypes.inventoryProductAddInventoryProduct),
    mergeMap((action: AddInventoryProduct) =>
      this.inventoryProductService
        .addInventoryProduct(action.payload)
        .pipe(
          map(inventoryProduct => {
            this.toastr.success('InventoryProduct added successfully!', "Success");
            action.payload.id = inventoryProduct.id;
            return new PatchInventoryProduct(action.payload)
          }),
          catchError(error => of(new InventoryProductError(error)))
        )
    )
  ));

  
  patchInventoryProduct = createEffect(() => this.actions$.pipe(
    ofType(InventoryProductActionTypes.inventoryProductPatchProduct),
    mergeMap((action: PatchInventoryProduct) =>
      this.inventoryProductService
        .patchProductInventoryProduct(action.payload)
        .pipe(
          map(inventoryProduct => {
            this.toastr.success('InventoryProduct updated successfully!', "Success");
            return new PatchInventoryProductSuccess(inventoryProduct)
          }),
          catchError(error => of(new InventoryProductError(error)))
        )
    )
  ));

  
  getInventoryProductById = createEffect(() => this.actions$.pipe(
    ofType(InventoryProductActionTypes.inventoryProductGetInventoryProductById),
    mergeMap((action: GetInventoryProductById) =>
      this.inventoryProductService
        .getInventoryProduct(action.payload)
        .pipe(
          map(inventoryProduct => {
            return new GetInventoryProductByIdSuccess(inventoryProduct)
          }),
          catchError(error => of(new InventoryProductError(error)))
        )
    )
  ));

  
  updateInventoryProduct = createEffect(() => this.actions$.pipe(
    ofType(InventoryProductActionTypes.inventoryProductUpdateInventoryProduct),
    mergeMap((action: UpdateInventoryProduct) =>
      this.inventoryProductService
        .updateInventoryProduct(action.payload)
        .pipe(
          map(inventoryProduct => {
            this.toastr.success('InventoryProduct updated successfully!', "Success");
            return new PatchInventoryProduct(action.payload)
          }),
          catchError(error => of(new InventoryProductError(error)))
        )
    )
  ));

  
  deleteInventoryProduct = createEffect(() => this.actions$.pipe(
    ofType(InventoryProductActionTypes.inventoryProductDeleteInventoryProduct),
    mergeMap((action: DeleteInventoryProduct) =>
      this.inventoryProductService
        .deleteInventoryProduct(action.payload)
        .pipe(
          map(res => {
            this.toastr.success('InventoryProduct deleted successfully!', "Success");
            return new DeleteInventoryProductSuccess(action.payload)
          }),
          catchError(error => of(new InventoryProductError(error)))
        )
    )
  ));

  constructor(
    private actions$: Actions,
    private toastr: ToastrService,
    private inventoryProductService: InventoryProductService,
  ) {
  }


}



