import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getWorkOrderStatusStore = createFeatureSelector('workOrderStatus');

export const getWorkOrderStatusEntities = createSelector(
  getWorkOrderStatusStore,
  fromReducers.workOrderStatus.workOrderStatusEntitySelectors.selectAll
);

export const getWorkOrderStatusLoading = createSelector(
  getWorkOrderStatusStore,
  (workOrderStatusStore: fromReducers.workOrderStatus.State) => workOrderStatusStore.loading
);


export const getWorkOrderStates = createSelector(getWorkOrderStatusEntities, entities => {
  return Object.values(entities);
});
