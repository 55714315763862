import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {RepairCentre} from '../../../models/core-aftersales/repaircentre';
import {User} from '../../../models/core-identity/user/user';
import {CoreSoftLaunchService} from '../core-soft-launch.service';
import {SoftLaunchProject} from '../../../models/core-soft-launch/soft-launch-project';

@Injectable({
  providedIn: 'root'
})
export class SoftLaunchProjectService extends CoreSoftLaunchService {

  constructor(private http: HttpClient) {
    super(http, 'project');
  }

  /** GET repair centre */
  getSoftLaunchProject(id: number): Observable<any> {
    return this.http.get<SoftLaunchProject>(this.base_url + '/' + id,
      {headers: this.headers}).pipe(
      tap(softLaunchProject => this.log(`fetched soft launch project`)),
      catchError(this.handleError('getSoftLaunchProject', []))
    );
  }

  getAllSoftLaunchProjects(query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        if (query[val] !== null && query[val] !== undefined && query[val] !== '') {
          params = params.append(val, query[val]);
        }
      });
    }

    return this.http.get(this.base_url + 's',
      {params: params, headers: this.headers}).pipe(
      tap(softLaunchProjects => this.log(`fetched soft launch projects`)),
      catchError(this.handleError('getAllSoftLaunchProjects', []))
    );
  }

  /** POST: save soft launch to the server */
  saveSoftLaunchProject(softLaunchProject, csvFile): Observable<any> {
    const formData = new FormData();

    if (csvFile) {
      formData.append('file', csvFile);
    }
    formData.append('data', new Blob([JSON.stringify(softLaunchProject)], {
      type: 'application/json'
    }));

    this.setHeader(true);

    return this.http.post(this.base_url, formData, {headers: this.headers}).pipe(
      tap((softLaunchProject1: SoftLaunchProject) => this.log(`added soft launch project w/ id=${softLaunchProject1.id}`)),
      catchError(this.handleError<SoftLaunchProject>('saveSoftLaunchProject'))
    );
  }

 
  deactivateStatus(projectId: number): Observable<any> {
    const url = `${this.base_url}/${projectId}/deactivate`;
    return this.http.put<any>(url, { headers: this.headers }).pipe(
      tap(_ => this.log(`fetched updateStatus `)),
      catchError(this.handleError<any>(`updateStatus`))
    );
  }
}
