import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';

import {CoreMasterDataService} from '../core-masterdata.service';
import {ProductCustomerComplaint} from '../../../models/core-setup/product-customer-complaint/product-customer-complaint';


@Injectable({providedIn: 'root'})
export class ProductCustomerComplaintService extends CoreMasterDataService {


  constructor(
    private http: HttpClient,
  ) {
    super(http, 'globalproductlevel1customercomplaint');
  }

  /** GET productAttributes from the server */
  getProductComplaints(productId: number): Observable<ProductCustomerComplaint[]> {
    return this.http.get<ProductCustomerComplaint[]>(this.base_url + '/product/' + productId, {headers: this.headers}).pipe(
      tap(productAttributes => this.log(`fetched product customer complaints`)),
      catchError(this.handleError('getProductComplaints', []))
    );
  }

  /** GET subunit complaints from the server */
  getSubunitComplaints(productId: number): Observable<any[]> {
    return this.http.get<any[]>(this.base_url + '/subunit/' + productId, {headers: this.headers}).pipe(
      tap(complaints => this.log(`fetched subunit complaints`)),
      catchError(this.handleError('getSubunitComplaints', []))
    );
  }

  /** POST save complaints from the server */
  saveComplaints(productId: number, complaint): Observable<any> {
    return this.http.post(this.base_url + '/saveComplaints/' + productId, complaint, {headers: this.headers}).pipe(
      tap(complaints => this.log(`added complaints`)),
      catchError(this.handleError('addComplaints', []))
    );
  }

}
