import { Action } from '@ngrx/store';

import { Module } from '@appModels/core-setup/module/module';

export enum ModuleActionTypes {
  moduleGetModules = '[Module] get',
  moduleGetModulesSuccess = '[Module] get modules success',
  moduleAddModule = '[Module] add Module',
  moduleAddModuleSuccess = '[Module] add module success',
  moduleDeleteModule = '[Module] delete module',
  moduleDeleteModuleSuccess = '[Module] delete module success',
  moduleGetModuleById = '[Module] get module by id',
  moduleGetModuleByIdSuccess = '[Module] get module by id success',
  moduleUpdateModule = '[Module] update module',
  moduleUpdateModuleSuccess = '[Module] update module success',
  moduleSearchModules = '[Module] search modules',
  moduleSearchModulesSuccess = '[Module] search modules success',
  moduleSearchModulesReset = '[Module] search modules reset',
  moduleError = '[Module] error'
}

export class GetModules implements Action {
  readonly type = ModuleActionTypes.moduleGetModules;
}

export class GetModulesSuccess implements Action {
  readonly type = ModuleActionTypes.moduleGetModulesSuccess;
  constructor(public payload: Module[]) {}
}

export class AddModule implements Action {
  readonly type = ModuleActionTypes.moduleAddModule;
  constructor(public payload: Module) {}
}

export class AddModuleSuccess implements Action {
  readonly type = ModuleActionTypes.moduleAddModuleSuccess;
  constructor(public payload: Module) {}
}

export class GetModuleById implements Action {
  readonly type = ModuleActionTypes.moduleGetModuleById;
  constructor(public payload: number) {}
}

export class GetModuleByIdSuccess implements Action {
  readonly type = ModuleActionTypes.moduleGetModuleByIdSuccess;
  constructor(public payload: Module) {}
}

export class UpdateModule implements Action {
  readonly type = ModuleActionTypes.moduleUpdateModule;
  constructor(public payload: Module) {}
}

export class UpdateModuleSuccess implements Action {
  readonly type = ModuleActionTypes.moduleUpdateModuleSuccess;
  constructor(public payload: Module) {}
}

export class DeleteModule implements Action {
  readonly type = ModuleActionTypes.moduleDeleteModule;
  constructor(public payload: Module) {}
}

export class DeleteModuleSuccess implements Action {
  readonly type = ModuleActionTypes.moduleDeleteModuleSuccess;
  constructor(public payload: Module) {}
}

export class SearchModules implements Action {
  readonly type = ModuleActionTypes.moduleSearchModules;
  constructor(public payload: string) {}
}

export class SearchModulesSuccess implements Action {
  readonly type = ModuleActionTypes.moduleSearchModulesSuccess;
  constructor(public payload: Module[]) {}
}

export class SearchModulesReset implements Action {
  readonly type = ModuleActionTypes.moduleSearchModulesReset;
}

export class ModuleError implements Action {
  readonly type = ModuleActionTypes.moduleError;
  constructor(public payload: any) {}
}

export type ModuleActions =
  | GetModules
  | GetModulesSuccess
  | AddModule
  | AddModuleSuccess
  | GetModuleById
  | GetModuleByIdSuccess
  | UpdateModule
  | UpdateModuleSuccess
  | DeleteModule
  | DeleteModuleSuccess
  | SearchModules
  | SearchModulesSuccess
  | SearchModulesReset
  | ModuleError;
