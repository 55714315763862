import {Action} from '@ngrx/store';

import {Escalation} from '@appModels/core-crm/escalation/escalation';

export enum EscalationActionTypes {
  escalationGetEscalations = '[Escalation] get',
  escalationGetEscalationsSuccess = '[Escalation] get escalations success',
  escalationAddEscalation = '[Escalation] add Escalation',
  escalationAddEscalationSuccess = '[Escalation] add escalation success',
  escalationAddProductEscalation = '[Escalation] add product Escalation Info',
  escalationAddProductEscalationSuccess = '[Escalation] add product escalation Info success',
  escalationDeleteEscalation = '[Escalation] delete escalation',
  escalationDeleteEscalationSuccess = '[Escalation] delete escalation success',
  escalationGetEscalationById = '[Escalation] get escalation by id',
  escalationGetEscalationByIdSuccess = '[Escalation] get escalation by id success',
  escalationUpdateEscalation = '[Escalation] update escalation',
  escalationUpdateEscalationSuccess = '[Escalation] update escalation success',
  escalationSearchEscalations = '[Escalation] search escalations',
  escalationSearchEscalationsSuccess = '[Escalation] search escalations success',
  escalationSearchEscalationsReset = '[Escalation] search escalations reset',
  escalationError = '[Escalation] error'
}

export class GetEscalations implements Action {
  readonly type = EscalationActionTypes.escalationGetEscalations;

  constructor(public payload?) {
  }
}

export class GetEscalationsSuccess implements Action {
  readonly type = EscalationActionTypes.escalationGetEscalationsSuccess;

  constructor(public payload) {
  }
}

export class AddEscalation implements Action {
  readonly type = EscalationActionTypes.escalationAddEscalation;
  constructor(public payload: Escalation) {}
}

export class AddEscalationSuccess implements Action {
  readonly type = EscalationActionTypes.escalationAddEscalationSuccess;
  constructor(public payload: Escalation) {}
}

export class AddProductEscalation implements Action {
  readonly type = EscalationActionTypes.escalationAddProductEscalation;
  constructor(public payload) {}
}

export class AddProductEscalationSuccess implements Action {
  readonly type = EscalationActionTypes.escalationAddProductEscalationSuccess;
  constructor(public payload) {}
}

export class GetEscalationById implements Action {
  readonly type = EscalationActionTypes.escalationGetEscalationById;
  constructor(public payload: number) {}
}

export class GetEscalationByIdSuccess implements Action {
  readonly type = EscalationActionTypes.escalationGetEscalationByIdSuccess;
  constructor(public payload: Escalation) {}
}

export class UpdateEscalation implements Action {
  readonly type = EscalationActionTypes.escalationUpdateEscalation;
  constructor(public payload: Escalation) {}
}

export class UpdateEscalationSuccess implements Action {
  readonly type = EscalationActionTypes.escalationUpdateEscalationSuccess;
  constructor(public payload: Escalation) {}
}

export class DeleteEscalation implements Action {
  readonly type = EscalationActionTypes.escalationDeleteEscalation;
  constructor(public payload: Escalation) {}
}

export class DeleteEscalationSuccess implements Action {
  readonly type = EscalationActionTypes.escalationDeleteEscalationSuccess;
  constructor(public payload: Escalation) {}
}

export class SearchEscalations implements Action {
  readonly type = EscalationActionTypes.escalationSearchEscalations;
  constructor(public payload: string) {}
}

export class SearchEscalationsSuccess implements Action {
  readonly type = EscalationActionTypes.escalationSearchEscalationsSuccess;
  constructor(public payload: Escalation[]) {}
}

export class SearchEscalationsReset implements Action {
  readonly type = EscalationActionTypes.escalationSearchEscalationsReset;
}

export class EscalationError implements Action {
  readonly type = EscalationActionTypes.escalationError;
  constructor(public payload: any) {}
}

export type EscalationActions =
  | GetEscalations
  | GetEscalationsSuccess
  | AddEscalation
  | AddEscalationSuccess
  | AddProductEscalation
  | AddProductEscalationSuccess
  | GetEscalationById
  | GetEscalationByIdSuccess
  | UpdateEscalation
  | UpdateEscalationSuccess
  | DeleteEscalation
  | DeleteEscalationSuccess
  | SearchEscalations
  | SearchEscalationsSuccess
  | SearchEscalationsReset
  | EscalationError;
