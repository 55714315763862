import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';

import {CoreAftersalesService} from '../core-aftersales.service';


@Injectable({providedIn: 'root'})
export class AftersaleFollowupDispositionService extends CoreAftersalesService {


  constructor(
    private http: HttpClient,
  ) {
    super(http, 'followupdispositions');
  }

  getFollowupCalls(ticketId: number): Observable<any[]> {
    return this.http.get<any[]>(this.base_url + `/${ticketId}`, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched followupCalls`)),
      catchError(this.handleError('getFollowupCalls', []))
    );
  }

  addFollowupCall(ticketId: number, data: any): Observable<any> {
    return this.http.post<any>(this.base_url + '/' + ticketId, data, {headers: this.headers}).pipe(
      tap((_: any) => this.log(`added followupCall ${_}`)),
      catchError(this.handleError<any>('addFollowupCall'))
    );
  }

}
