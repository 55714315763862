import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getCountryStore = createFeatureSelector('country');

export const getCountryEntities = createSelector(
  getCountryStore,
  fromReducers.country.countryEntitySelectors.selectAll
);

export const getCountries = createSelector(getCountryEntities, entities => {
  return Object.values(entities);
});

export const getTopCountries = createSelector(getCountryEntities, entities => {
  return Object.values(entities).slice(1, 5);
});

export const getCountriesLoaded = createSelector(
  getCountryStore,
  (countryStore: fromReducers.country.State) => countryStore.loaded
);

export const getCountriesLoading = createSelector(
  getCountryStore,
  (countryStore: fromReducers.country.State) => countryStore.loading
);

export const getSelectedCountryId = createSelector(
  getCountryStore,
  (countryStore: fromReducers.country.State) => countryStore.selectedCountryId
);

export const getSearchCountries = createSelector(
  getCountryStore,
  (countryStore: fromReducers.country.State) => countryStore.searchCountries
);

export const getCountryById = createSelector(
  getCountryEntities,
  getSelectedCountryId,
  (entities, id) => entities.find(i => i.id === id)
);

export const getCountriesError = createSelector(
  getCountryStore,
  (countryStore: fromReducers.country.State) => countryStore.error
);


export const getCountriesPaging = createSelector(
  getCountryStore,
  (countryStore: fromReducers.country.State) => countryStore.paging
);
