import {createEntityAdapter, EntityAdapter} from '@ngrx/entity';

import * as auth from '@appStore/actions/core-auth/auth/auth.actions';

import {Authentication} from '@appModels/core-auth/auth/auth';


// Here is the final state required by the app
export interface State {
  loading: boolean;

    isAuthenticated: boolean;
  // Name is what we passed in "Actions" as consignmentId, thus it can be a model if needed
    token: string | '';
    // This is used for showing usage when login is failed
    attemptLoginFailed: boolean;

    errorMsg: string | '';
}

export const adapter: EntityAdapter<Authentication> = createEntityAdapter<Authentication>();


export const initialState: State = adapter.getInitialState({
    isAuthenticated: false,
  loading: false,
    token: '',
    errorMsg: '',
    attemptLoginFailed: false,
});

export function reducer(state = initialState, action: auth.AuthActions): State {
    switch (action.type) {
        // Case can be more complex
      case auth.AuthActionTypes.Login:
        return {
          ...state,
          loading: true
        };
        case auth.AuthActionTypes.Success:
            return {
                isAuthenticated: true,
                loading: false,
                // The token is retrieved from the "Action"
                // In production, user data should be defined as a model
               // Retrieving user data like, `user: action.consignmentId.user <- a model`
                token: action.payload.jwttoken,
                errorMsg: '',
                attemptLoginFailed: false,
            };
        case auth.AuthActionTypes.Failed:
            return {
                isAuthenticated: false,
              loading: false,
                token: '',
                errorMsg: action.payload,
                attemptLoginFailed: true,
            };
        default:
            return state;
    }
}


export const authEntitySelectors = adapter.getSelectors();
