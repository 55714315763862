import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getCustomerStore = createFeatureSelector('customer');

export const getCustomerEntities = createSelector(
  getCustomerStore,
  fromReducers.customer.customerEntitySelectors.selectAll
);

export const getCustomers = createSelector(getCustomerEntities, entities => {
  return Object.values(entities);
});

export const getTopCustomers = createSelector(getCustomerEntities, entities => {
  return Object.values(entities).slice(1, 5);
});

export const getPaging = createSelector(
  getCustomerStore,
  (customerStore: fromReducers.customer.State) => customerStore.paging
);

export const getCustomersLoaded = createSelector(
  getCustomerStore,
  (customerStore: fromReducers.customer.State) => customerStore.loaded
);

export const getCustomersLoading = createSelector(
  getCustomerStore,
  (customerStore: fromReducers.customer.State) => customerStore.loading
);

export const getSelectedCustomerId = createSelector(
  getCustomerStore,
  (customerStore: fromReducers.customer.State) => customerStore.selectedCustomerId
);

export const getCustomerGeneralInfoId = createSelector(
  getCustomerStore,
  (customerStore: fromReducers.customer.State) => customerStore.customerGeneralInfoId
);

export const getCustomerLivingStandardInfoId = createSelector(
  getCustomerStore,
  (customerStore: fromReducers.customer.State) => customerStore.customerLivingStandardInfoId
);

export const getCustomerUpsellMetricsInfoId = createSelector(
  getCustomerStore,
  (customerStore: fromReducers.customer.State) => customerStore.customerUpsellMetricsInfoId
);


export const getSearchCustomers = createSelector(
  getCustomerStore,
  (customerStore: fromReducers.customer.State) => customerStore.searchCustomers
);

export const getCustomerById = createSelector(
  getCustomerStore,
  (customerStore: fromReducers.customer.State) => customerStore.selectedCustomer
);
export const selectedUniqueId = createSelector(
  getCustomerStore,
  (customerStore: fromReducers.customer.State) => customerStore.selectedUniqueId
);

export const getCustomersError = createSelector(
  getCustomerStore,
  (customerStore: fromReducers.customer.State) => customerStore.error
);
