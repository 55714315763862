import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from "@angular/core";

@Component({
  selector: "kyc-product-detail",
  template: ` <div
      *ngIf="!productDetailCheck && formNotValid"
      class="notification error"
    >
      {{ "Please confirm Product Details" | translate }}
    </div>
    <h4 class="m-t-20 m-b-10">
      <input
        type="checkbox"
        *ngIf="
          statusName !== 'BLACKLISTED'
        "
        class="m-r-10"
        [ngModel]="productDetailCheck"
        (ngModelChange)="productDetailCheckChange.emit($event)"
        
        name="productDetailCheck"
      />
      {{ "Product Details" | translate }}
    </h4>
    <span>{{ "Total payment made" | translate }}: {{ totalPaid }} </span>
    <table style="background: #f7f7fc; border-radius: 3px">
      <thead>
        <tr>
          <th>{{ "NAME" | translate }}</th>
          <th>{{ "RATE" | translate }}</th>
          <th>{{ "DAILY RATE" | translate }}</th>
          <th>{{ "RATE/UNIT" | translate }}</th>
          <th>{{ "LENGTH" | translate }}</th>
          <th class="hide_data">{{ "ACCOUNT" | translate }}</th>
          <th
            class="hide_data"
            *ngIf="
              contractDetails['externalSales'] !== undefined &&
              contractDetails['externalSales'] !== undefined &&
              contractDetails['externalSales'] == true
            "
          >
            {{ "IMEI" | translate }}
          </th>
          <th class="hide_data">{{ "DEPOSIT" | translate }}</th>
          <th class="hide_data">{{ "DEALER" | translate }}</th>
          <th class="hide_data">{{ "SALES AGENT" | translate }}</th>
          <th class="hide_data" *ngIf="tenantId === 125 || tenantId === 1004">
            {{ "Smile ID Status" | translate }}
          </th>
          <th class="hide_data" *ngIf="tenantId === 125 || tenantId === 1004">
            {{ "Smile ID Required" | translate }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <b> {{ product || "--" }} </b>
          </td>
          <td>{{ rate || "--" }}</td>
          <td>{{ contractDetails["dailyRate"] || "--" }}</td>
          <td>{{ contractDetails["ratePerUnit"] || "--" }}</td>
          <td>{{ contractDetails["contractLength"] || "--" }}</td>
          <td class="hide_data">
            {{ accountNumber || contractDetails["accountNumber"] }}
          </td>
          <td
            class="hide_data"
            *ngIf="
              contractDetails['externalSales'] !== undefined &&
              contractDetails['externalSales'] !== undefined &&
              contractDetails['externalSales'] == true
            "
          >
            {{ contractDetails["imei"] || "--" }}
          </td>
          <td class="hide_data">{{ deposit || "--" }}</td>
          <td class="hide_data">
            {{
              contractDetails["stockingPointDealerCode"] ||
                contractDetails["accessoryStockingPointDealerCode"]
            }}
          </td>
          <td class="hide_data">
            {{ contractDetails["salesAgentDealerCode"] || "--" }}
          </td>
          <td class="hide_data" *ngIf="tenantId === 125 || tenantId === 1004">
            {{ idVerificationPassed || "--" }}
          </td>
          <td class="hide_data" *ngIf="tenantId === 125 || tenantId === 1004">
            {{ primaryProductSmileIdRequired || "--" }}
          </td>
        </tr>
        <tr *ngFor="let accessoryDetail of accessoriesDetails">
          <td>
            <b> {{ accessoryDetail.name || "--" }} </b>
          </td>
          <td>{{ accessoryDetail.rateName || "--" }}</td>
          <td>{{ accessoryDetail.perUnitAmount || "--" }}</td>
          <td>{{ accessoryDetail.perUnitAmount || "--" }}</td>
          <td>{{ accessoryDetail.length || "--" }}</td>
          <td class="hide_data">
            {{ accessoryDetail.accountNumber || "--" }}
          </td>
          <td class="hide_data">{{ accessoryDetail.deposit || "--" }}</td>
          <td class="hide_data">
            {{ contractDetails["accessoryStockingPointDealerCode"] || "--" }}
          </td>
          <td class="hide_data">
            {{ contractDetails["accessorySalesAgentDealerCode"] || "--" }}
          </td>
          <td class="hide_data" *ngIf="tenantId === 125 || tenantId === 1004">
            {{ idVerificationPassed || "--" }}
          </td>
          <td class="hide_data" *ngIf="tenantId === 125 || tenantId === 1004">
            {{ accessoryDetail.smileIdRequired || "--" }}
          </td>
        </tr>
      </tbody>
    </table>`,
  encapsulation: ViewEncapsulation.None,
})
export class KycProductDetailComponent implements OnInit {
  @Input() accessoriesDetails: any[] = [];
  @Input() contractDetails: any;
  @Input() idVerificationPassed: any;
  @Input() tenantId: any;
  @Input() primaryProductSmileIdRequired: any;
  @Input() formNotValid: any;
  @Input() statusName: any;
  @Input() totalPaid: any;
  @Input() product: any;
  @Input() rate: any;
  @Input() accountNumber: any;
  @Input() deposit: any;
  @Input() productDetailCheck: any;
  @Input() kycComponentModeEnum: any;
  @Input() componentMode: any;

  @Output() productDetailCheckChange = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {}
}
