import {ChangeDetectorRef, Component, EventEmitter, Input, Output, TemplateRef, ViewChild} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {CustomerService} from "@appServices/core-crm/customer/customer.service";
import {CoreTicketingCustomerService} from "@appServices/core-ticketing/customer/core-ticketing-customer.service";
import Swal from 'sweetalert2';
import { environment } from 'environments/environment';


import {TicketingService} from "@appServices/core-ticketing/customer/ticketing-service";

import tinymce from "tinymce";
import {ToastrService} from "ngx-toastr";
import {TicketingFileService} from "@appServices/core-ticketing/file/ticketing-file-service";
import * as moment from "moment/moment";
import {ActivatedRoute, Router} from "@angular/router";
import {TicketComment} from "@appModels/ticketing/ticket.comment";
import {CurrentUserDetails} from "@appModels/ticketing/current.user.details";
import {TeamsService} from "@appServices/core-ticketing/teams-service";
import {TicketingStatusService} from "@appServices/core-ticketing/ticketing-status.service";
import {debounce} from 'lodash';
import {PermissionResolver} from "@appServices/permission-resolver/permission-resolver.service";
import {TicketCustomFieldService} from "@appServices/core-ticketing/ticket-custom-field-service";
import {CustomField} from "@appModels/ticketing/custom-field";
import {Clipboard} from "@angular/cdk/clipboard";
import {RepairCentreService} from "@appServices/core-aftersales/repaircentre/repair-centre.service";
import {TicketClosingDisposition} from "@appModels/core-aftersales/ticket-closing-disposition";
import {
  AftersaleFollowupDispositionService
} from "@appServices/core-aftersales/aftersale-followup-dispositions/aftersale-followup-dispositions.service";
import {TicketService} from "@appServices/core-aftersales/ticket/ticket.service";
import {CloseTicket, ReassignTicket} from "@appStore/actions/core-aftersales/ticket/ticket.actions";
import {Store} from "@ngrx/store";
import * as fromReducer from "@appStore/reducers";
import {TicketView} from "@appModels/ticketing/ticket-view";
import {ReturnTicketService} from "@appServices/core-aftersales/return-ticket/return-ticket.service";

@Component({
  moduleId: module.id,
  selector: 'ticket-view',
  templateUrl: './ticket-view-directive.html',
  animations: [
    trigger('toggleAnimation', [
      transition(':enter', [style({ opacity: 0, transform: 'scale(0.95)' }), animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' }))]),
      transition(':leave', [animate('75ms', style({ opacity: 0, transform: 'scale(0.95)' }))]),
    ]),
  ],
  styleUrls: ['./ticket-view-directive.css', '../../tailwind.css']
})
export class TicketViewDirective {
  constructor(
    private store: Store<fromReducer.ticket.State>,
    private toastrService: ToastrService,
    private permissionResolver: PermissionResolver,
    private clipboard: Clipboard,
    private permissionResolverService: PermissionResolver,
    private ticketFileService: TicketingFileService,
    private ref: ChangeDetectorRef,
    private modalService: BsModalService,
    private customerService: CustomerService,
    private router: Router,
    private ticketCustomFieldService: TicketCustomFieldService,
    private aftersaleFollowupDispositionService: AftersaleFollowupDispositionService,
    private ticketingService: TicketingService,
    private coreTicketingCustomerService: CoreTicketingCustomerService,
    private ticketStatusService: TicketingStatusService,
    private teamsService: TeamsService,
    private repairCentreService: RepairCentreService,
    private ticketService: TicketService,
    private returnTicketService : ReturnTicketService,
    private route: ActivatedRoute
  ) {


    this.searchUsers = debounce(this.searchUsers, 500)
    this.searchUsersTagged = debounce(this.searchUsersTagged, 500)

    this.getTicketPriorities();
    this.getTicketSources();
    this.getCurrentUserDetails();
    this.getPossibleAssignees();


  }

  reassignWarrantyTicketReference: BsModalRef;
  @ViewChild('reassignWarrantyTicket') public reassignWarrantyTicket;
  followUpCallReference: BsModalRef;
  @ViewChild('followUpCall') public followUpCall;
  closeWarrantyTicketReference: BsModalRef;
  @ViewChild('closeWarrantyTicketModal') public closeWarrantyTicketModal;

  userId:number = null;
  editingCommentId = null;
  updatingComment = false;


  selectedTicketId:number = null;
  @Input() set ticketId(id:any) {
    this.selectedTicketId = id;
    this.selectTicket(id);

    this.route.params.subscribe(val => {
      if (this.route.snapshot.params.id != undefined && this.route.snapshot.params.id != null) {
        this.selectedTicketId = this.route.snapshot.params.id;
        this.selectTicket(this.selectedTicketId);
      }
    });
  }


  @Output() outputOpenTicketEvent = new EventEmitter<any>();
  @Output() outputOpenCustomerEvent = new EventEmitter<any>();
  @Output() closeEvent = new EventEmitter<any>();

  notesEditable : boolean = false;
  @Input() set setNotesEditable(notesEditable:any) {
    this.notesEditable = notesEditable;

  }


  currentUserDetails :CurrentUserDetails = {};
  possibleAssignees : CurrentUserDetails[] = [];
  customerImage: any = null;


  priorities = [];
  ticketStatuses = [];
  sources = [];
  fileUploads = [];

  DefaultImg = () => 'assets/img/avatar.png';


  newCommentConfig: any = {

    base_url : '/tinymce',
    suffix : '.min',
    width: '100%',
    min_height: 400,
    autoresize_min_height: 600,
    autoresize_max_height: 800,
    promotion: false,
    statusbar:false,
    max_height: 600,


    skin: 'oxide',
    quickbars_insert_toolbar: false,


    plugins: 'preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap emoticons quickbars autoresize media',


    toolbar:
      "undo redo | formatselect | bold italic backcolor | table | link \
      bullist numlist outdent indent",

    menubar:'file edit custom table view format tools',

    menu: {
      custom: { title: 'Insert', items: 'attachmentmenu imageuploadmenu code pagebreak charmap emoticons' }
    },



    setup: function (editor) {


      editor.ui.registry.addIcon('imageUploadIcon', '<svg fill="#000000" width="24px" height="24px" viewBox="0 0 0.72 0.72" xmlns="http://www.w3.org/2000/svg"><path d="M0.57 0.12H0.15a0.09 0.09 0 0 0 -0.09 0.09v0.3a0.09 0.09 0 0 0 0.09 0.09h0.42a0.09 0.09 0 0 0 0.09 -0.09V0.21a0.09 0.09 0 0 0 -0.09 -0.09ZM0.15 0.54a0.03 0.03 0 0 1 -0.03 -0.03v-0.073l0.099 -0.099a0.03 0.03 0 0 1 0.042 0L0.462 0.54Zm0.45 -0.03a0.03 0.03 0 0 1 -0.03 0.03h-0.023l-0.114 -0.115 0.026 -0.026a0.03 0.03 0 0 1 0.042 0l0.099 0.099Zm0 -0.097 -0.056 -0.056a0.092 0.092 0 0 0 -0.127 0l-0.026 0.026 -0.086 -0.086a0.092 0.092 0 0 0 -0.127 0L0.12 0.353V0.21a0.03 0.03 0 0 1 0.03 -0.03h0.42a0.03 0.03 0 0 1 0.03 0.03Z"/></svg>');


      editor.ui.registry.addButton('imageUploadButton', {

        icon: 'imageUploadIcon',
        onAction: function () {

          let input = document.getElementById("imageUploadButtonNewComment");
          input?.click();
        }
      });


      editor.ui.registry.addMenuItem('imageuploadmenu', {
        text: 'Attachment',
        icon: 'imageUploadIcon',
        onAction: function () {

          let input = document.getElementById("imageUploadButtonNewComment");
          input?.click();
        }
      });

    },

  };


  emptyConfig : any = {

    base_url : '/tinymce',
    suffix : '.min',
    width: '100%',
    min_height: 400,
    autoresize_min_height: 600,
    autoresize_max_height: 800,
    promotion: false,
    statusbar:false,
    max_height: 600,


    // skin: 'oxide',
    quickbars_insert_toolbar: false,


    toolbar: false,

    menubar: false,


  };


  downloadCustomerPhoto(data) {
    this.customerService.downloadCustomerPhoto(data).subscribe((blob : any) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        this.customerImage = reader.result;
        this.ref.detectChanges()
      }
    });
  }

  showSuccessAlert(title:string) {
    Swal.fire({
      icon: 'success',
      title: title,
      padding: '10px 20px',
    });
  }

  getPossibleAssignees() {
    this.teamsService.getPossibleAssignees(null).subscribe(x => {
      this.possibleAssignees= x;
    })
  }

  getTicketPriorities() {
    this.ticketingService.getPriorities().subscribe(x => {
      this.priorities = x;
    })
  }

  getTicketSources() {
    this.ticketingService.getSources().subscribe(x => {
      this.sources = x;
    })
  }

  getCurrentUserDetails() {
    this.ticketingService.getCurrentUserDetails().subscribe(x => {
      this.currentUserDetails = x;
      this.newComment.colorCode = x.colorCode;
      this.newComment.firstName = x.firstName;
      this.newComment.lastName = x.lastName;
      this.newComment.initials = x.initials;
    })
  }


  downloadFile(file : any) {

    if (file == null || file.uuid == null) {
      return;
    }

    this.ticketFileService.downloadFileByUuidId(file.uuid).subscribe(blob => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      const a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.download = file.name;
      a.click();
    })
  }



  ///VIEW TICKET
  dateFormat = moment().format('YYYY-MM-DD hh:mm:ss');
  moment: any = moment;

  selectedTicket:TicketView =  {};
  receivedItems : any[]= [];
  newAssignedUserId = null;
  showAssignees = false;
  detailsTab = 1;
  loadingDetails = false;
  mainLoading = true;

  selectTicket(ticketId:number) {

    this.ticketingService.getTicket(ticketId).subscribe(x => {

      this.selectedTicket = x;


      try {
        //check if user can edit ticket notes
        this.userId = +JSON.parse(localStorage.getItem("user")).id;
        if (this.selectedTicket.reporterId == this.userId) {
          this.notesEditable = true;
        }
      } catch (err) {
        console.log("error determining user id");
      }


      this.getComments(this.selectedTicketId);

      this.ticketStatusService.getNextAvailableTicketStatuses(this.selectedTicketId).subscribe(x => {
        this.ticketStatuses = x;
      })

      this.mainLoading = false;
      // this.ticketStatusService.get
      if (this.selectedTicket.assignedId == null) {
        if (this.can('can-manage-ticket-assignees')) {
          this.showAssignees = true;
        }
      }


      if (this.selectedTicket.attachments != null && this.selectedTicket.attachments.length > 0) {
        for (let i = 0; i < this.selectedTicket.attachments.length; i++) {

          this.ticketFileService.downloadThumbnailByUuidId(this.selectedTicket.attachments[i].uuid).subscribe((blob: any) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
              this.selectedTicket.attachments[i].src = reader.result;
              this.selectedTicket.attachments[i].finished = true;

              this.ref.detectChanges()
            }
          });
        }
      }

    })
  }

  onReadyTinyMainNotes($event:any){
    if (!this.notesEditable) {
      let editor: any = tinymce.get('mainNotes');
      editor.getBody().setAttribute('contenteditable', 'false');
      editor.mode.set("readonly");
    }
  }

  onReadyTinyExistingComments($event:any, id:number){
    let thisEditor : any = tinymce.get('comment' + id);
    thisEditor.getBody().setAttribute('contenteditable','false');
    thisEditor.mode.set("readonly");
  }

  onReadyTinyMyComments($event:any, id:number) {
    for(let i = 0; i < this.selectedTicket.comments.length; i++) {
      if(this.selectedTicket.comments[i].id != id) {
        let commentEditor : any = tinymce.get('comment' + id);
        commentEditor.getBody().setAttribute('contenteditable','false');
        commentEditor.mode.set("readonly");
      }
    }
  }

  downloadTicketActivity(id:number){
    this.loadingDetails = true;

    this.ticketingService.getActivityHistory(id).subscribe(x => {
      this.selectedTicket.activityList = x;
      this.loadingDetails = false;
    })

  }

  getComments(id:number) {

    this.loadingDetails = true;
    this.ticketingService.getComments(this.selectedTicketId).subscribe(x => {
      this.selectedTicket.comments = x;
      this.loadingDetails = false;
      if (this.selectedTicket.comments != null && this.selectedTicket.comments.length > 0) {
        for(let i = 0 ; i < this.selectedTicket.comments.length ; i ++) {

          if (this.selectedTicket.comments[i].attachments != null && this.selectedTicket.comments[i].attachments.length > 0) {

            for (let j = 0; j < this.selectedTicket.comments[i].attachments.length; j++) {

              this.ticketFileService.downloadThumbnailByUuidId(this.selectedTicket.comments[i].attachments[j].uuid).subscribe((blob: any) => {
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = () => {
                  this.selectedTicket.comments[i].attachments[j].src = reader.result;
                  this.selectedTicket.comments[i].attachments[j].finished = true;

                  this.ref.detectChanges()
                }
              });
            }
          }


        }
      }

    })


  }

  downloadTicketActions(id:number){
    this.loadingDetails = true;

    this.ticketingService.getActionHistory(id).subscribe(x => {
      this.selectedTicket.actionList = x;
      this.loadingDetails = false;
    })

  }


  newComment : TicketComment = {
    id:0,
    content:"",
    attachments : []
  }


  onImageSelectedNewComment(event:any) {
    Array.prototype.forEach.call(event.target.files, file =>{
      this.uploadSingleImageNewComment(file);
    })
  }
  uploadSingleImageNewComment(file:File ){

    if (file) {

      console.log("uploading file to new comment");

      const formData = new FormData();
      formData.append("file", file);
      this.newComment.attachments.push({
        name: file.name,
        finished:false
      })


      this.ticketFileService.uploadFile(formData).subscribe(x=> {

        if (x.success) {
          for (let i = 0; i < this.newComment.attachments.length; i++) {
            if (this.newComment.attachments[i].name == file.name) {
              this.newComment.attachments[i].finished = true;
              this.newComment.attachments[i].image = x.image;
              this.newComment.attachments[i].id = x.id;
              this.newComment.attachments[i].uuid = x.uuid;
              this.newComment.attachments[i].bytes = x.bytes;
              this.toastrService.success("File " + file.name + " Uploaded Successfuly")



              if (x.image) {

                this.ticketFileService.downloadThumbnailByUuidId(x.uuid).subscribe((blob : any) => {
                  const reader = new FileReader();
                  reader.readAsDataURL(blob);
                  reader.onloadend = () => {
                    this.newComment.attachments[i].src = reader.result;
                    this.ref.detectChanges()

                    console.log("new comment done")
                    console.log(this.newComment)
                  }
                });

                let newCommentEditor : any = tinymce.get('newCommentContent');
                newCommentEditor.insertContent('<img src = "data:image/jpg;base64,' + x.bytes + '">');
                //newCommentEditor.execCommand('mceInsertContent', false,'<img src = "' + x.url + '">');


              }

            }
          }
        } else {
          this.toastrService.error("Error uploading file" + file.name);
          this.newComment.attachments = this.newComment.attachments.filter(x=> x.name != file.name)
        }

      });
    }
  }



  addingComment = false;
  createComment(){

    if (this.newComment.content == null || this.newComment.content.length == 0) {
      this.toastrService.error("Please add a longer comment");
      return;
    }
    this.addingComment = true;

    let editor : any = tinymce.get('newCommentContent');
    var cleanedComment = editor.getContent({ format: 'text' });
    this.newComment.contentCleaned = cleanedComment;

    this.ticketingService.createComment(this.newComment, this.selectedTicket.id).subscribe(x => {
      this.addingComment = false;
      let newList = [];

      this.newComment.userId = x.userId;
      this.newComment.id = x.id;

      let commentCopy = JSON.parse(JSON.stringify(this.newComment));

      newList.push(commentCopy);
      this.selectedTicket.comments = this.selectedTicket.comments.concat(newList);
      this.toastrService.success("Comment Added");
      this.newComment.contentCleaned = "";
      this.newComment.content = "";
      this.newComment.attachments = [];

      console.log("new comment alert")
      console.log(this.selectedTicket.comments)
    })
  }

  changeAssignedUser(ticketId:number) {
    this.ticketingService.changeAssignedUser(ticketId, this.newAssignedUserId).subscribe(x => {
      this.toastrService.success("Ticket Assigned");
      this.showAssignees = false;

      for(let i = 0 ; i< this.possibleAssignees.length; i++) {
        if (this.possibleAssignees[i].id == this.newAssignedUserId) {
          this.selectedTicket.assignedUserId = this.possibleAssignees[i].id;
          this.selectedTicket.assignedUserFirstName = this.possibleAssignees[i].firstName;
          this.selectedTicket.assignedUserLastName = this.possibleAssignees[i].lastName;
          this.selectedTicket.assignedUserColorCode = this.possibleAssignees[i].colorCode;
          this.selectedTicket.assignedUserInitials = this.possibleAssignees[i].initials;
        }

      }

      this.newAssignedUserId = null;
    })
  }


  editableConfigMainNotes: any = {

    base_url : '/tinymce',
    suffix : '.min',
    width: '100%',
    min_height: 400,
    autoresize_min_height: 600,
    autoresize_max_height: 800,
    promotion: false,
    statusbar:false,
    max_height: 600,
    skin: 'oxide',
    quickbars_insert_toolbar: false,
    plugins: 'preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap emoticons quickbars autoresize media',
    toolbar:
      "undo redo | formatselect | bold italic backcolor | table | link \
      bullist numlist outdent indent",

    menubar:'file edit custom table view format tools',

    menu: {
      custom: { title: 'Insert', items: 'attachmentmenu imageuploadmenu code pagebreak charmap emoticons' }
    },



    setup: function (editor) {


      editor.ui.registry.addIcon('imageUploadIcon', '<svg fill="#000000" width="24px" height="24px" viewBox="0 0 0.72 0.72" xmlns="http://www.w3.org/2000/svg"><path d="M0.57 0.12H0.15a0.09 0.09 0 0 0 -0.09 0.09v0.3a0.09 0.09 0 0 0 0.09 0.09h0.42a0.09 0.09 0 0 0 0.09 -0.09V0.21a0.09 0.09 0 0 0 -0.09 -0.09ZM0.15 0.54a0.03 0.03 0 0 1 -0.03 -0.03v-0.073l0.099 -0.099a0.03 0.03 0 0 1 0.042 0L0.462 0.54Zm0.45 -0.03a0.03 0.03 0 0 1 -0.03 0.03h-0.023l-0.114 -0.115 0.026 -0.026a0.03 0.03 0 0 1 0.042 0l0.099 0.099Zm0 -0.097 -0.056 -0.056a0.092 0.092 0 0 0 -0.127 0l-0.026 0.026 -0.086 -0.086a0.092 0.092 0 0 0 -0.127 0L0.12 0.353V0.21a0.03 0.03 0 0 1 0.03 -0.03h0.42a0.03 0.03 0 0 1 0.03 0.03Z"/></svg>');


      editor.ui.registry.addButton('imageUploadButton', {

        icon: 'imageUploadIcon',
        onAction: function () {

          let input = document.getElementById("imageUploadButton");
          input?.click();
        }
      });


      editor.ui.registry.addMenuItem('imageuploadmenu', {
        text: 'Attachment',
        icon: 'imageUploadIcon',
        onAction: function () {

          let input = document.getElementById("imageUploadButton");
          input?.click();
        }
      });

    },

  };


  editableConfigComment: any = {

    base_url : '/tinymce',
    suffix : '.min',
    width: '100%',
    min_height: 400,
    autoresize_min_height: 600,
    autoresize_max_height: 800,
    promotion: false,
    statusbar:false,
    max_height: 600,
    skin: 'oxide',
    quickbars_insert_toolbar: false,
    plugins: 'preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap emoticons quickbars autoresize media',
    toolbar:
      "undo redo | formatselect | bold italic backcolor | table | link \
      bullist numlist outdent indent",

    menubar:'file edit custom table view format tools',

    menu: {
      custom: { title: 'Insert', items: 'attachmentmenu imageuploadmenu code pagebreak charmap emoticons' }
    },



    setup: function (editor) {


      editor.ui.registry.addIcon('imageUploadIcon', '<svg fill="#000000" width="24px" height="24px" viewBox="0 0 0.72 0.72" xmlns="http://www.w3.org/2000/svg"><path d="M0.57 0.12H0.15a0.09 0.09 0 0 0 -0.09 0.09v0.3a0.09 0.09 0 0 0 0.09 0.09h0.42a0.09 0.09 0 0 0 0.09 -0.09V0.21a0.09 0.09 0 0 0 -0.09 -0.09ZM0.15 0.54a0.03 0.03 0 0 1 -0.03 -0.03v-0.073l0.099 -0.099a0.03 0.03 0 0 1 0.042 0L0.462 0.54Zm0.45 -0.03a0.03 0.03 0 0 1 -0.03 0.03h-0.023l-0.114 -0.115 0.026 -0.026a0.03 0.03 0 0 1 0.042 0l0.099 0.099Zm0 -0.097 -0.056 -0.056a0.092 0.092 0 0 0 -0.127 0l-0.026 0.026 -0.086 -0.086a0.092 0.092 0 0 0 -0.127 0L0.12 0.353V0.21a0.03 0.03 0 0 1 0.03 -0.03h0.42a0.03 0.03 0 0 1 0.03 0.03Z"/></svg>');


      editor.ui.registry.addButton('existingCommentUploadButton', {

        icon: 'imageUploadIcon',
        onAction: function () {

          let input = document.getElementById("existingCommentUploadButton");
          input?.click();
        }
      });


      editor.ui.registry.addMenuItem('imageuploadmenu', {
        text: 'Attachment',
        icon: 'imageUploadIcon',
        onAction: function () {

          let input = document.getElementById("existingCommentUploadButton");
          input?.click();
        }
      });

    },

  };

  onImageSelected(event:any) {


    Array.prototype.forEach.call(event.target.files, file =>{
      this.uploadSingleImage(file);
    })
  }


  uploadSingleImage(file:File ){

    if (file) {


      const formData = new FormData();

      formData.append("file", file);

      this.selectedTicket.attachments.push({
        name: file.name,
        finished:false
      })


      this.ticketFileService.uploadFile(formData).subscribe(x=> {
        if (x.success) {
          for (let i = 0; i < this.selectedTicket.attachments.length; i++) {
            if (this.selectedTicket.attachments[i].name == file.name) {
              this.selectedTicket.attachments[i].finished = true;
              this.selectedTicket.attachments[i].image = x.image;
              this.selectedTicket.attachments[i].id = x.id;
              this.selectedTicket.attachments[i].uuid = x.uuid;
              this.toastrService.success("File " + file.name + " Uploaded Successfuly")

              this.ticketFileService.downloadThumbnailByUuidId(x.uuid).subscribe((blob : any) => {
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = () => {
                  this.selectedTicket.attachments[i].src = reader.result;
                  this.ref.detectChanges()
                }
              });

              if (x.image) {
                //tinymce.activeEditor.execCommand('mceInsertContent', false,'<img src = "' + x.url + '">');
                let notesEditor: any = tinymce.get('mainNotes');
                notesEditor.insertContent('<img src = "data:image/jpg;base64,' + x.bytes + '">');

              }

            }
          }
        } else {
          this.toastrService.error("Error uploading file" + file.name);
          this.selectedTicket.attachments = this.fileUploads.filter(x=> {x.name != file.name})
        }



      });
    }
  }


  savingNotes = false;
  saveNotes(){

    let editor : any = tinymce.get('mainNotes');
    var cleanedNotes = editor.getContent({ format: 'text' });
    this.selectedTicket.notesCleaned = cleanedNotes;

    this.savingNotes = true;
    let saveNotesBody = {
      notes: this.selectedTicket.notes,
      notesCleaned : this.selectedTicket.notesCleaned,
      attachments : []
    }
    if (this.selectedTicket.attachments != null) {
      for (let i = 0; i< this.selectedTicket.attachments.length; i++) {
        saveNotesBody.attachments.push(this.selectedTicket.attachments[i].id)
      }
    }

    this.ticketingService.saveNotes(this.selectedTicketId, saveNotesBody).subscribe(x => {
      this.toastrService.success("Notes Saved");
      this.savingNotes = false;
    })
  }

  openCustomerProfile(encryptedId:string) {
    this.outputOpenCustomerEvent.emit(encryptedId);
    // this.router.navigateByUrl(`/crm/customers/${encryptedId}`)
  }



  userSearch = "Search Users and Teams";
  userSearchResults : CurrentUserDetails[] = [];
  loadingUserSearch = false;
  showUserDropdown = false;
  searchUsers() {
    this.userSearchResults = [];

    if (this.userSearch == null || this.userSearch.length == 0) {
      this.showUserDropdown = false;
    }
    if (this.userSearch == null || this.userSearch == undefined || this.userSearch.length < 3) {
      return;
    }
    this.showUserDropdown = true;

    this.loadingUserSearch = true;

    if (this.selectedTicket.afterSaleCustomerTicketId == null && this.selectedTicket.returnRepossessionTicketId == null) {
      this.teamsService.queryUsersAndTeams(this.userSearch, true).subscribe(x => {
        this.userSearchResults = x;
        this.loadingUserSearch = false;
      })
    }

    if (this.selectedTicket.afterSaleCustomerTicketId != null) {
      this.teamsService.queryUsersAndTeams(this.userSearch, true, true).subscribe(x => {
        this.userSearchResults = x;
        this.loadingUserSearch = false;
      })
    }

    if (this.selectedTicket.returnRepossessionTicketId != null) {
        this.teamsService.queryUsersAndTeams(this.userSearch, true, true, true).subscribe(x => {
          this.userSearchResults = x;
          this.loadingUserSearch = false;
        })

    }
  }

  selectUser(user: CurrentUserDetails) {
    this.showUserDropdown = false;
    this.userSearchResults = [];
    this.userSearch = user.name;

    if (this.selectedTicket.afterSaleCustomerTicketId == null && this.selectedTicket.returnRepossessionTicketId == null) {
      if (user.type == 'user') {
        this.ticketingService.changeAssignedUser(this.selectedTicket.id, user.id).subscribe(x => {
          this.toastrService.success("Ticket Assigned To " + user.name);
        })
      } else if (user.type == 'team') {
        this.ticketingService.changeAssignedTeam(this.selectedTicket.id, user.id).subscribe(x => {
          this.toastrService.success("Ticket Assigned To " + user.name);
        })
      }
    }
    else if(this.selectedTicket.afterSaleCustomerTicketId != null) {
      this.store.dispatch(new ReassignTicket({
        id: this.selectedTicket.afterSaleCustomerTicketId,
        repairCentreId: user.repairCentreId
      }));
      this.selectedTicket.assignedInitials = user.initials;
      this.selectedTicket.assignedName = user.name;
    }
    else if (this.selectedTicket.returnRepossessionTicketId != null) {
      if (user.repairCentreTechnicianId != null) {
          this.returnTicketService.assignTechnician(this.selectedTicket.returnRepossessionTicketId, user.repairCentreTechnicianId).subscribe(
            data => {
              this.toastrService.success('Assigned successfully!', 'Successful')
            }
          )
        }
      else {
        this.returnTicketService.assignTicket(this.selectedTicket.returnRepossessionTicketId, user.repairCentreId).subscribe(
          data => {
            if (data) {
              this.toastrService.success('Assigned successfully!', 'Successful')
            }
          }
        )
      }
    }

  }


  showTicketStatuses = false;

  changeTicketStatus() {



      this.ticketingService.changeStatus(this.selectedTicketId, this.selectedTicket.statusId).subscribe(x => {
        this.toastrService.success("Status changed !");
        for (let i = 0; i < this.ticketStatuses.length; i++) {
          if (this.ticketStatuses[i].id == this.selectedTicket.statusId) {
            this.selectedTicket.ticketStatusColorCode = this.ticketStatuses[i].colorCode;
            this.selectedTicket.ticketStatusTextColor = this.ticketStatuses[i].textColorCode;
            this.selectedTicket.ticketStatusName = this.ticketStatuses[i].name;
          }
        }
        this.ticketStatusService.getNextAvailableTicketStatuses(this.selectedTicketId).subscribe(x => {
          this.ticketStatuses = x;
          this.showTicketStatuses = false;
        })
      })

  }

  openTicketDetailsPage() {
    this.outputOpenTicketEvent.emit(this.selectedTicketId);
  }

  close() {
    this.closeEvent.emit();
  }




  userSearchTagged = "Search For Users";
  userSearchResultsTagged : CurrentUserDetails[] = [];
  loadingUserSearchTagged = false;
  showUserDropdownTagged = false;
  searchUsersTagged() {
    this.userSearchResultsTagged = [];

    if (this.userSearchTagged == null || this.userSearchTagged.length == 0) {
      this.showUserDropdownTagged = false;
    }
    if (this.userSearchTagged == null || this.userSearchTagged == undefined || this.userSearchTagged.length < 3) {
      return;
    }
    this.showUserDropdownTagged = true;

    this.loadingUserSearchTagged = true;
    this.teamsService.queryUsersAndTeams(this.userSearchTagged, true).subscribe( x => {
      this.userSearchResultsTagged = x;
      this.loadingUserSearchTagged = false;
    })
  }

  selectUserTagged(user: CurrentUserDetails) {
    this.showUserDropdownTagged = false;
    this.userSearchResultsTagged = [];

    if (user.type == 'user') {
      if (!this.selectedTicket.taggedUsers.some(x => (x.id == user.id && x.type == 'user'))) {
        this.selectedTicket.taggedUsers.push(user);

        this.ticketingService.addUserTag(this.selectedTicketId, user.id).subscribe(x => {
          this.toastrService.success("Tagged " + user.name)
        })
      }
    }
    else if (user.type == 'team') {
      if (!this.selectedTicket.taggedUsers.some(x => x.id == user.id)) {
        this.selectedTicket.taggedUsers.push(user);

        this.ticketingService.addTeamTag(this.selectedTicketId, user.id).subscribe(x => {
          this.toastrService.success("Tagged " + user.name)
        })
      }
    }
  }

   removeTaggedUser(user: CurrentUserDetails) {

    if (!this.can('can-tag-users-on-tickets')) {
      return;
    }

    if (user.type == 'user') {
      this.ticketingService.removeUserTag(this.selectedTicketId, user.id).subscribe(x => {
        this.toastrService.success("Untagged " + user.name);
      })
      this.selectedTicket.taggedUsers = this.selectedTicket.taggedUsers.filter(x => !(x.id == user.id && x.type == 'user'));
    }
    else if (user.type == 'team') {
      this.ticketingService.removeTeamTag(this.selectedTicketId, user.id).subscribe(x => {
        this.toastrService.success("Untagged " + user.name);
      })
      this.selectedTicket.taggedUsers = this.selectedTicket.taggedUsers.filter(x => !(x.id == user.id && x.type == 'team'));
    }
  }

  clickShowAssignees() {
    if (!this.can('can-manage-ticket-assignees')) {
      return;
    }
    if (this.selectedTicket.afterSaleCustomerTicketId != null || this.selectedTicket.returnRepossessionTicketId != null) {
      return;
    }
    console.log("showing assignees");
    this.showAssignees = !this.showAssignees;
  }
  can(action) {
    return this.permissionResolverService.setModuleName("Ticketing").can(action);
  }

  closeViewModal() {
    this.closeEvent.emit();
  }

  canWarrantyAndService(action) {
    return this.permissionResolverService.setModuleName("Warranty And Service").can(action);
  }


  showPriorityPicker = false;
  selectedPriority = null;
  clickShowPriorities() {

    if (!this.can('can-manage-ticket-priority')) {
      return;
    }
    this.showPriorityPicker = true;
  }

  changeTicketPriority() {
    if (!this.can('can-manage-ticket-priority')) {
      return;
    }

    this.ticketingService.changePriority(this.selectedTicketId, this.selectedPriority).subscribe(x => {

      this.toastrService.success("Priority changed !")
      this.showPriorityPicker = false;

      for (let i = 0; i < this.priorities.length; i++) {
        if (this.priorities[i].id == this.selectedPriority) {
          this.selectedTicket.priority = this.priorities[i].name
        }
      }

    })
  }



showingLinkButton = false;
  showLinkButton() {
    // if (this.showingLinkButton == false) {
    //   let button = document.getElementById("linkButton");
    //   button.classList.toggle("invisible");
    //   button.classList.toggle("slide-animation-1");
    //   this.showingLinkButton = true;
    // }

  }

updateCustomField(customField: CustomField) {
    if (customField.answer != null && customField.answer != undefined) {
      this.ticketingService.updateTicketCustomField(this.selectedTicketId, customField).subscribe(x => {
        this.toastrService.success("Updated " + customField.name);
      })
    }
}


// START UPDATING COMMENT

  editingComment : any = {
    id:0,
    content:"",
    attachments : []
  }

  updateComment() {

    if (this.editingComment.content == null && this.editingComment.content.length == 0) {
      this.toastrService.error("Please add a longer comment");
      return;
    }


    this.updatingComment = true;

    let editor : any = tinymce.get('editingComment' + this.editingComment.id);
    var cleanedComment = editor.getContent({ format: 'text' });
    this.editingComment.contentCleaned = cleanedComment;

    this.ticketingService.updateComment(this.editingComment, this.editingComment.id).subscribe(x => {
      this.editingComment = {
        id: 0,
        content: "",
        attachments: []
      }
      this.updatingComment = false;
      this.toastrService.success("Comment Updated");

    })
  }


  onImageSelectedExistingComment(event:any) {
    Array.prototype.forEach.call(event.target.files, file =>{
      this.uploadSingleImageExistingComment(file);
    })
  }
  uploadSingleImageExistingComment(file:File ){

    if (file) {

      console.log("uploading file to new comment");

      const formData = new FormData();
      formData.append("file", file);
      this.editingComment.attachments.push({
        name: file.name,
        finished:false
      })


      this.ticketFileService.uploadFile(formData).subscribe(x=> {

        if (x.success) {
          for (let i = 0; i < this.editingComment.attachments.length; i++) {
            if (this.editingComment.attachments[i].name == file.name) {
              this.editingComment.attachments[i].finished = true;
              this.editingComment.attachments[i].image = x.image;
              this.editingComment.attachments[i].id = x.id;
              this.editingComment.attachments[i].uuid = x.uuid;
              this.editingComment.attachments[i].bytes = x.bytes;
              this.toastrService.success("File " + file.name + " Uploaded Successfuly")



              if (x.image) {

                this.ticketFileService.downloadThumbnailByUuidId(x.uuid).subscribe((blob : any) => {
                  const reader = new FileReader();
                  reader.readAsDataURL(blob);
                  reader.onloadend = () => {
                    this.editingComment.attachments[i].src = reader.result;
                    this.ref.detectChanges()

                    console.log("new comment done")
                    console.log(this.newComment)
                  }
                });

                let newCommentEditor : any = tinymce.get('editingComment' + this.editingComment.id);
                newCommentEditor.insertContent('<img src = "data:image/jpg;base64,' + x.bytes + '">');
                //newCommentEditor.execCommand('mceInsertContent', false,'<img src = "' + x.url + '">');


              }

            }
          }
        } else {
          this.toastrService.error("Error uploading file" + file.name);
          this.editingComment.attachments = this.editingComment.attachments.filter(x=> x.name != file.name)
        }

      });
    }
  }


  editedCommentBackup = "";
  selectCommentForEditing(comment : any) {
    this.editingComment = comment;
    this.editedCommentBackup = comment.content;
  }
  cancelUpdatingComment() {
    for(let i = 0; i< this.selectedTicket.comments.length; i++) {
      if (this.selectedTicket.comments[i].id == this.editingComment.id) {
        this.selectedTicket.comments[i].content = this.editedCommentBackup;
      }
    }

    this.editingComment = {
      id: 0,
      content: "",
      attachments: []
    }
    this.updatingComment = false;
  }


  copyTicketLink() {


    this.clipboard.copy(`${environment.base_web_url}/ticketing/ticket/${this.selectedTicketId}`);
    this.toastrService.success(`Ticket Link copied to clipboard!`)

  }

  showTicketStatusesOnClick() {
    if (this.selectedTicket.afterSaleCustomerTicketId != null || this.selectedTicket.returnRepossessionTicketId != null) {
      return;
    }
    if (this.can('can-change-ticket-status')) {
      this.showTicketStatuses = true;
    }
  }



  canCloseTicket(ticket) {
    return this.hasItems(ticket) && this.canWarrantyAndService('can-close-ticket');
  }

  getKeys(ticket) {
    return Object.keys(ticket.workOrderNumbers);
  }
  hasItems(ticket) {
    return (!this.selectedTicket.workOrderNumbers || !this.getKeys(ticket) || this.getKeys(ticket).length === 0) &&
      (!this.receivedItems[ticket.id] || this.receivedItems[ticket.id].length === 0)
  }

  canReassignTicket(ticket) {
    return this.hasItems(ticket) && this.canWarrantyAndService('can-reassign-ticket');
  }



  dispositionId: number = null;
  repairCentreNames = [];
  closing = false;
  reassigning = false;
  closingComment: string = null;
  ticketClosingDispositions: TicketClosingDisposition[] = [];
  newRepairCentreId = null;
  repairCentres = [];
  followUp: boolean = false;
  followupDispositions = ['Already at the SC', 'Cannot visit(Financial Constraints)', 'Gave to Agent', 'In Transit', 'Lost/Stolen', 'Resolved', 'To revisit SC', 'To visit SC', 'Customer to call back', 'Agent/Technician to call back']
  followUpError: boolean;
  selectWarrantyAction(option) {
    console.log("selecting action")
      this.dispositionId = null;
      this.closing = false;
      this.followUp = false;
      this.reassigning = false;
      if (option === 'work_order') {
        //this.modalRef.hide();
        this.closeEvent.emit();
        this.createWorkOrder(this.selectedTicket.afterSaleCustomerTicketId);
      } else if (option === 'receive') {
        // this.modalRef.hide();
        this.closeEvent.emit();
        this.receiveItems(this.selectedTicket.afterSaleCustomerTicketId);
      } else if (option === 'close') {
        this.loadWarrantyTicketClosingDispositions();
        this.closing = true;
        let input = document.getElementById("openCloseWarrantyTicketModalButton");
        input?.click();
      } else if (option === 'reassign') {
        this.newRepairCentreId = null;
        this.reassigning = true;
        let input = document.getElementById("openReassignWarrantyTicketModalButton");
        input?.click();
      } else if (option === 'followUp') {
        let input = document.getElementById("openFollowUpCallButton");
        input?.click();
        this.followUp = true;
      }
  }

  createWorkOrder(id) {
    this.router.navigateByUrl(`/warranty-and-service/work_order/register/${id}`);
  }

  receiveItems(id) {
    this.router.navigateByUrl(`/warranty-and-service/work_order/receive/${id}`);
  }


  openReassignWarrantyTicketModal(confirmation: TemplateRef<any>) {
    if (this.repairCentreNames.length == 0) {
      this.loadRepairCentres();
    }
    this.reassignWarrantyTicketReference = this.modalService.show(confirmation, { class: 'modal-md' });
  }

  openFollowUpCallModalModal(confirmation: TemplateRef<any>) {
    this.followUpCallReference = this.modalService.show(confirmation, { class: 'modal-md' });
  }

  openCloseWarrantyTicketModal(confirmation: TemplateRef<any>) {
    this.closeWarrantyTicketReference = this.modalService.show(confirmation, { class: 'modal-md' });
  }


  loadRepairCentres() {
    this.repairCentreService.getEnabledRepairCentres().subscribe(repairCentres => {
      this.repairCentres = repairCentres;
      this.repairCentreNames = [...repairCentres].map(item=>{
        return {id: item.id, text: `${item.name} (L${item.type.level})`};
      })
    });
  }

  followupDisposition = null;
  followupNotes = null;

  followupTicket(){
    this.followUpError = false;
    if(this.followupDisposition !== null && (this.followupNotes !== '' && this.followupNotes !== null)){
      this.aftersaleFollowupDispositionService.addFollowupCall(this.selectedTicket.afterSaleCustomerTicketId, {disposition: this.followupDisposition, notes: this.followupNotes}).subscribe(
        data => {
          this.toastrService.success("Follow up call saved!");
          this.followUpCallReference.hide();
        }
      )
    } else {
      this.followUpError = true;
    }
  }

  loadWarrantyTicketClosingDispositions() {
    this.ticketService.getTicketClosingDispositions().subscribe(dispositions => {
      this.ticketClosingDispositions = dispositions;
    });
  }

  selectClosingDisposition(id) {
    this.dispositionId = id;
  }

  closeWarrantyTicket() {
    if (this.closingComment) {
      this.closing = false;
      this.store.dispatch(new CloseTicket({
        id: this.selectedTicket.afterSaleCustomerTicketId,
        closeComment: this.closingComment,
        dispositionId: this.dispositionId
      }));

      this.closeWarrantyTicketReference.hide();

      for (let i = 0; i< this.ticketStatuses.length; i++) {
        if (this.ticketStatuses[i].name == 'Completed') {
          this.selectedTicket.ticketStatusColorCode = this.ticketStatuses[i].colorCode;
          this.selectedTicket.ticketStatusTextColor = this.ticketStatuses[i].textColorCode;
          this.selectedTicket.ticketStatusName = this.ticketStatuses[i].name;
        }
      }

    }
  }


}
