import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { CoreCrmService } from '../core-crm.service';



@Injectable({ providedIn: 'root' })
export class CallScriptService extends CoreCrmService {


  constructor(
    private http: HttpClient,

  ) {
    super(http, 'customerCallScript');
  }


  getCallScoreCardType(): Observable<any> {
    const url = `${this.base_url}/callScoreCardType/all`;
    return this.http.get<any>(url,{ headers: this.headers }).pipe(
      tap(_ => this.log(`getCallScoreCardType`)),
      catchError(this.handleError<any>(`getCallScoreCardType`))
    );
  }

  getCallQuestions(cardType: string): Observable<any> {
    const url = `${this.base_url}/tenant/${localStorage.getItem('tenant')}/questions?cardType=${cardType}`;
    return this.http.get<any>(url,{ headers: this.headers }).pipe(
      tap(_ => this.log(`getCallQuestions`)),
      catchError(this.handleError<any>(`getCallQuestions`))
    );
  }

  getCallAnswers(customerId: number, cardType: string): Observable<any> {
    const url = `${this.base_url}/customer/${customerId}/answers?cardType=${cardType}`;
    return this.http.get<any>(url,{ headers: this.headers }).pipe(
      tap(_ => this.log(`getCallAnswers`)),
      catchError(this.handleError<any>(`getCallAnswers`))
    );
  }

  getTotalScore(customerId: number, cardType: string): Observable<any> {
    const url = `${this.base_url}/customer/${customerId}/totalScore?cardType=${cardType}`;
    return this.http.get<any>(url,{ headers: this.headers }).pipe(
      tap(_ => this.log(`getTotalScore`)),
      catchError(this.handleError<any>(`getTotalScore`))
    );
  }

  saveScore(customerId: number, data: any): Observable<any> {
    const url = `${this.base_url}/customer/${customerId}/score`;
    return this.http.post<any>(url, data, { headers: this.headers }).pipe(
      tap(_ => this.log(`saveScore`)),
      catchError(this.handleError<any>(`saveScore`))
    );
  }

  saveBulkScore(customerId: number, data: any): Observable<any> {
    const url = `${this.base_url}/customer/${customerId}/score/bulk`;
    return this.http.post<any>(url, data, { headers: this.headers }).pipe(
      tap(_ => this.log(`saveBulkScore`)),
      catchError(this.handleError<any>(`saveBulkScore`))
    );
  }

  updateScore(customerCallScriptQuestionId: number, data: any): Observable<any> {
    const url = `${this.base_url}/customerCallScriptQuestion/${customerCallScriptQuestionId}/score`;
    return this.http.put<any>(url, data, { headers: this.headers }).pipe(
      tap(_ => this.log(`updateScore`)),
      catchError(this.handleError<any>(`updateScore`))
    );
  }


}
