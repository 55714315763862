import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getManualPaymentStore = createFeatureSelector('manualPayment');

export const getManualPaymentEntities = createSelector(
  getManualPaymentStore,
  fromReducers.manualPayment.manualPaymentEntitySelectors.selectAll
);

export const getManualPayments = createSelector(getManualPaymentEntities, entities => {
  return Object.values(entities);
});

export const getTopManualPayments = createSelector(getManualPaymentEntities, entities => {
  return Object.values(entities).slice(1, 5);
});

export const getManualPaymentPaging = createSelector(
  getManualPaymentStore,
  (manualPaymentStore: fromReducers.manualPayment.State) => manualPaymentStore.paging
);

export const getManualPaymentsLoaded = createSelector(
  getManualPaymentStore,
  (manualPaymentStore: fromReducers.manualPayment.State) => manualPaymentStore.loaded
);

export const getManualPaymentsLoading = createSelector(
  getManualPaymentStore,
  (manualPaymentStore: fromReducers.manualPayment.State) => manualPaymentStore.loading
);

export const getSelectedManualPaymentId = createSelector(
  getManualPaymentStore,
  (manualPaymentStore: fromReducers.manualPayment.State) => manualPaymentStore.selectedManualPaymentId
);

export const getSearchManualPayments = createSelector(
  getManualPaymentStore,
  (manualPaymentStore: fromReducers.manualPayment.State) => manualPaymentStore.searchManualPayments
);

export const getManualPaymentById = createSelector(
  getManualPaymentEntities,
  getSelectedManualPaymentId,
  (entities, id) => entities.find(i => i.id === id)
);

export const getManualPaymentsError = createSelector(
  getManualPaymentStore,
  (manualPaymentStore: fromReducers.manualPayment.State) => manualPaymentStore.error
);
