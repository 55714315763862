import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';

import {ProductWarranty} from '@appModels/core-setup/product-warranty/product-warranty';
import {
  ProductWarrantyActions,
  ProductWarrantyActionTypes
} from '@appStore/actions/core-masterdata/product-warranty/product-warranty.actions';


export interface State extends EntityState<ProductWarranty> {
  loaded: boolean;
  loading: boolean;
  error: any;
  selectedProductWarrantyId: number;
  searchProductWarranties: ProductWarranty[];
}

export const adapter: EntityAdapter<ProductWarranty> = createEntityAdapter<ProductWarranty>();

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
  selectedProductWarrantyId: null,
  error: null,
  searchTerm: '',
  searchProductWarranties: null
});

export function reducer(state = initialState, action: ProductWarrantyActions): State {
  switch (action.type) {
    case ProductWarrantyActionTypes.productWarrantyGetProductWarranties:
    case ProductWarrantyActionTypes.productWarrantyAddProductWarranties:
    case ProductWarrantyActionTypes.productWarrantyDeleteProductWarranty:
    case ProductWarrantyActionTypes.productWarrantyUpdateProductWarranty:
    case ProductWarrantyActionTypes.productWarrantySearchProductWarranties:
    case ProductWarrantyActionTypes.productWarrantyGetProductWarrantyById:
      return {
        ...state,
        loading: true
      };

    case ProductWarrantyActionTypes.productWarrantyGetProductWarrantiesSuccess:
      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });

    case ProductWarrantyActionTypes.productWarrantyGetProductWarrantyByIdSuccess:
      return {...state, selectedProductWarrantyId: action.payload.id, loading: false};

    case ProductWarrantyActionTypes.productWarrantyAddProductWarrantiesSuccess:
      return adapter.setAll(action.payload, {
        ...state,
        selectedProductWarrantyId: null,
        loading: false,
        loaded: true
      });

    case ProductWarrantyActionTypes.productWarrantyUpdateProductWarrantySuccess: {
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state,
          loading: false,
          loaded: true
        }
      );
    }

    case ProductWarrantyActionTypes.productWarrantyDeleteProductWarrantySuccess: {
      return adapter.removeOne(action.payload.id, {
        ...state,
        loading: false,
        loaded: true
      });
    }

    case ProductWarrantyActionTypes.productWarrantySearchProductWarrantiesSuccess:
      return {
        ...state,
        searchProductWarranties: action.payload,
        loading: false
      };

    case ProductWarrantyActionTypes.productWarrantySearchProductWarrantiesReset:
      return {
        ...state,
        searchProductWarranties: null
      };

    case ProductWarrantyActionTypes.productWarrantyError:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload
      };

    default:
      return state;
  }
}

export const productWarrantyEntitySelectors = adapter.getSelectors();
