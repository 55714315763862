import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { AccessoryRate } from '@appModels/core-accounting/accessory-rate/accessory-rate';
import { AccessoryRateActions, AccessoryRateActionTypes } from '@appStore/actions/core-accounting/accessory-rate/accessory-rate.actions';
import * as _ from 'lodash';

export interface State extends EntityState<AccessoryRate> {
  loaded: boolean;
  loading: boolean;
  error: any;
  selectedAccessoryRateId: number;
  searchAccessoryRates: AccessoryRate[];
  selectedAccessoryRate: AccessoryRate;
  paging: any;
}

export const adapter: EntityAdapter<AccessoryRate> = createEntityAdapter<AccessoryRate>();

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
  selectedAccessoryRateId: null,
  error: null,
  searchTerm: '',
  searchAccessoryRates: null,
  selectedAccessoryRate: null,
  paging: null
});

export function reducer(state = initialState, action: AccessoryRateActions): State {
  switch (action.type) {
    case AccessoryRateActionTypes.accessoryRateGetAccessoryRates:
    case AccessoryRateActionTypes.accessoryRateGetAccessoryRateByTenant:
    case AccessoryRateActionTypes.accessoryRateAddAccessoryRate:
    case AccessoryRateActionTypes.accessoryRateAddAdditionalAccessoryRateInfo:
    case AccessoryRateActionTypes.accessoryRateDeleteAccessoryRate:
    case AccessoryRateActionTypes.accessoryRateUpdateAccessoryRate:
    case AccessoryRateActionTypes.accessoryRateSearchAccessoryRates:
    case AccessoryRateActionTypes.accessoryRateGetAccessoryRateById:
      return {
        ...state,
        loading: true
      };

    case AccessoryRateActionTypes.accessoryRateGetAccessoryRatesSuccess:
      return adapter.setAll(action.payload.content, {
        ...state,
        loading: false,
        loaded: true,
        paging: _.omit(action.payload, ['content'])
      });

    case AccessoryRateActionTypes.accessoryRateGetAccessoryRateByTenantSuccess:
      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });

    case AccessoryRateActionTypes.accessoryRateGetAccessoryRateByIdSuccess:
      return {
        ...state,
        selectedAccessoryRateId: action.payload.id,
        selectedAccessoryRate: action.payload,
        loading: false
      };

    case AccessoryRateActionTypes.accessoryRateAddAccessoryRateSuccess:
      return adapter.addOne(action.payload, {
        ...state,
        selectedAccessoryRateId: action.payload.id,
        loading: false,
        loaded: true
      });

    case AccessoryRateActionTypes.accessoryRateUpdateAccessoryRateSuccess: {
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state,
          loading: false,
          loaded: true
        }
      );
    }

    case AccessoryRateActionTypes.accessoryRateDeleteAccessoryRateSuccess: {
      return adapter.removeOne(action.payload.id, {
        ...state,
        loading: false,
        loaded: true
      });
    }

    case AccessoryRateActionTypes.accessoryRateSearchAccessoryRatesSuccess:
      return {
        ...state,
        searchAccessoryRates: action.payload,
        loading: false
      };

    case AccessoryRateActionTypes.accessoryRateSearchAccessoryRatesReset:
      return {
        ...state,
        searchAccessoryRates: null
      };

    case AccessoryRateActionTypes.accessoryRateError:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload
      };

    default:
      return state;
  }
}

export const accessoryRateEntitySelectors = adapter.getSelectors();
