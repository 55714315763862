import {Injectable} from '@angular/core';
import {HttpClient, HttpEvent, HttpParams, HttpRequest} from '@angular/common/http';

import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';

import {CoreTicketingService} from "@appServices/core-ticketing/core-ticketing.service";

@Injectable({ providedIn: 'root' })
export class TicketingFileService  extends CoreTicketingService {


  constructor(
    private http: HttpClient,
  ) {
    super(http, 'file');
  }


  uploadFile(formData:any): Observable<any>  {

    const options = {
      reportProgress: true,
      headers:this.headers
    };
    this.setHeader(true);
    return this.http.post(`${this.base_url}/upload`, formData, {headers: this.headers}).pipe(
      tap((res) => this.log(`added contract image `)),
      catchError(this.handleError('addContractImage'))
    );
  }

  downloadThumbnail(id: number): Observable<any> {
    const url = `${this.base_url}/thumbnail/${id}`;
    return this.http.get(url, {headers: this.headers,  responseType: 'blob'}).pipe(
      tap(_ => this.log(`fetched customer id=${id}`)),
      catchError(this.handleError<any>(`getCustomer`, []))
    );
  }


  downloadFileByUuidId(id: string): Observable<any> {
    const url = this.base_url + '/ticket/download/' + id;
    return this.http.get(url, {headers: this.headers, responseType: 'blob'}).pipe(
      tap(_ => this.log(`fetched customer id=${id}`)),
      catchError(this.handleError<any>(`getCustomer`, []))
    );
  }

  downloadThumbnailByUuidId(id: string): Observable<any> {
    const url = this.base_url + '/thumbnail/' + id;
    return this.http.get(url, {headers: this.headers, responseType: 'blob'}).pipe(
      tap(_ => this.log(`fetched customer id=${id}`)),
      catchError(this.handleError<any>(`getCustomer`, []))
    );
  }

  downloadFileByUuid(tenantSpaceId:number, uuid: string): Observable<any> {
    const url = this.base_url + 'fileupload/download/' + uuid;
    return this.http.get(url, {headers: this.headers, responseType: 'blob'}).pipe(
      tap(_ => this.log(`fetched customer id=${uuid}`)),
      catchError(this.handleError<any>(`getCustomer`, []))
    );
  }

  downloadFileByUuidTrackProgress(tenantSpaceId:number,uuid:string, ): Observable<HttpEvent<any>> {

    const options = {
      reportProgress: true,
      headers:this.headers,
      responseType: 'blob',
      observe: 'events'

    };

    const req = new HttpRequest('GET', this.base_url + 'fileupload/download/' + uuid, options);
    return this.http.request(req);

  }


  getFileMetadata(body:any): Observable<any> {
    return this.http.post<any>(this.base_url + 'fileupload/tenantSpaceId/fileMetadata' , body , {headers: this.headers}).pipe(
      tap(accessoryRates => this.log(`fetched accessoryRates`)),
      catchError(this.handleError('getAccessoryRates', []))
    );
  }

  deleteFile(id:number, tenantSpaceId:number): Observable<any> {
    return this.http.post<any>(this.base_url + 'fileupload/delete/tenantSpaceId/' + tenantSpaceId + '/file/' + id  , {headers: this.headers}).pipe(
      tap(accessoryRates => this.log(`fetched accessoryRates`)),
      catchError(this.handleError('getAccessoryRates', []))
    );
  }

}
