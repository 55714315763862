import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getTimezoneStore = createFeatureSelector('timezone');

export const getTimezoneEntities = createSelector(
  getTimezoneStore,
  fromReducers.timezone.timezoneEntitySelectors.selectAll
);

export const getTimezones = createSelector(getTimezoneEntities, entities => {
  return Object.values(entities);
});

export const getTopTimezones = createSelector(getTimezoneEntities, entities => {
  return Object.values(entities).slice(1, 5);
});

export const getTimezonesLoaded = createSelector(
  getTimezoneStore,
  (timezoneStore: fromReducers.timezone.State) => timezoneStore.loaded
);

export const getTimezonesLoading = createSelector(
  getTimezoneStore,
  (timezoneStore: fromReducers.timezone.State) => timezoneStore.loading
);

export const getSelectedTimezoneId = createSelector(
  getTimezoneStore,
  (timezoneStore: fromReducers.timezone.State) => timezoneStore.selectedTimezoneId
);

export const getSearchTimezones = createSelector(
  getTimezoneStore,
  (timezoneStore: fromReducers.timezone.State) => timezoneStore.searchTimezones
);

export const getTimezoneById = createSelector(
  getTimezoneEntities,
  getSelectedTimezoneId,
  (entities, id) => entities.find(i => i.id === id)
);

export const getTimezonesError = createSelector(
  getTimezoneStore,
  (timezoneStore: fromReducers.timezone.State) => timezoneStore.error
);
