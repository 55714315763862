import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

import {
  AddAttributes,
  AddSalesStaff,
  AddSalesStaffSuccess,
  DeleteSalesStaff,
  DeleteSalesStaffSuccess,
  FilterSalesStaff,
  GetSalesStaffById,
  GetSalesStaffByIdSuccess,
  GetSalesStaffs,
  GetSalesStaffsByTenantId,
  GetSalesStaffsByTenantIdIdSuccess,
  SalesStaffActionTypes,
  SalesStaffsError,
  SearchSalesStaffs,
  SearchSalesStaffsSuccess,
  UpdateSalesStaff,
  UpdateSalesStaffSuccess
} from '@appStore/actions/core-fieldforce/sales-staff/sales-staff.actions';
import {SalesStaffService} from '@appServices/core-fieldforce/sales-staff/sales-staff.service';
import {TenantService} from '@appServices/core-masterdata/tenant/tenant.service';
import {ToastrService} from 'ngx-toastr';
import {TenantSalesStaffService} from "@appServices/core-fieldforce/sales-staff/tenant-sales-staff.service";
import {FilterSalesStaffSuccess} from "../../../actions/core-fieldforce/sales-staff/sales-staff.actions";
import {Router} from "@angular/router";

@Injectable()
export class SalesStaffEffects {
  
  loadSalesStaffs$ = createEffect(() => this.actions$.pipe(
    ofType(SalesStaffActionTypes.salesStaffGeStaffsByTenantId),
    mergeMap((action: GetSalesStaffsByTenantId) =>
      this.tenantSalesStaffService
        .getTenantSalesStaffs(action.payload)
        .pipe(
          map(data => new GetSalesStaffsByTenantIdIdSuccess(data)),
          catchError(error => of(new SalesStaffsError(error)))
        )
    )
  ));
  
  addSalesStaff = createEffect(() => this.actions$.pipe(
    ofType(SalesStaffActionTypes.salesStaffAddStaff),
    switchMap((action: AddSalesStaff) =>
      this.tenantSalesStaffService
        .addSalesStaff(action.payload)
        .pipe(
          map(
            stockingPoint => {
              this.toastr.success('Field Force Staff has been successfully added!', 'Successful!');
              this.router.navigate(['/fieldforce/sales-staff/staff']);
              if (stockingPoint !== undefined) {
                this.toastr.success('Sales Staff has been successfully added!', 'Successful!');
                return new AddSalesStaffSuccess(stockingPoint)
              }
              this.toastr.error('There was an error creating sales staff!', "Unknown error");
              return new SalesStaffsError({type: 500, message: 'Internal error'})
            }
          ),
          catchError(error => of(new SalesStaffsError(error)))
        )
    )
  ));


  
  filterSalesStaffs$ = createEffect(() => this.actions$.pipe(
    ofType(SalesStaffActionTypes.salesStaffFilterSalesStaff),
    mergeMap((action: FilterSalesStaff) =>
      this.salesStaffService
        .filterSalesStaff(action.payload)
        .pipe(
          map(customers => new FilterSalesStaffSuccess(customers)),
          catchError(error => of(new SalesStaffsError(error)))
        )
    )
  ));

  
  getSalesStaffById$ = createEffect(() => this.actions$.pipe(
    ofType(SalesStaffActionTypes.salesStaffGetStaffById),
    mergeMap((action: GetSalesStaffById) =>
      this.salesStaffService
        .getSalesStaff(action.payload)
        .pipe(
          map(salesStaff => new GetSalesStaffByIdSuccess(salesStaff)),
          catchError(error => of(new SalesStaffsError(error)))
        )
    )
  ));
  
  addSalesStaffAttributes = createEffect(() => this.actions$.pipe(
    ofType(SalesStaffActionTypes.salesStaffAddStaffAttributes),
    mergeMap((action: AddAttributes) =>
      this.salesStaffService
        .addSalesStaffAttributes(action.payload)
        .pipe(
          map(stockingPoint => new GetSalesStaffs()),
          catchError(error => of(new SalesStaffsError(error)))
        )
    )
  ));
  /*
  @Effect()
  updateSalesStaff = this.actions$.pipe(
    ofType(SalesStaffActionTypes.salesStaffUpdateStaff),
    mergeMap((action: AddSalesStaff) =>
      this.salesStaffService
        .updateSalesStaff(action.payload)
        .pipe(
          map(stockingPoint => new UpdateSalesStaffSuccess(action.payload)),
          catchError(error => of(new SalesStaffsError(error)))
        )
    )
  );
  */

  
  updateSalesStaff$ = createEffect(() => this.actions$.pipe(
    ofType(SalesStaffActionTypes.salesStaffUpdateStaff),
    mergeMap((action: AddSalesStaff) =>
      this.salesStaffService
        .updateSalesStaff(action.payload)
        .pipe(
          map(rate => {
            if (rate !== undefined) {
              this.toastr.success('Sales Staff has been successfully updated!', 'Successful!');
              this.router.navigate(['/fieldforce/sales-staff/staff']);
              return new UpdateSalesStaffSuccess(action.payload);
            }
            // this.toastr.error('There was an error updating this rate', "Unknown error");
            return new SalesStaffsError({type: 500, message: 'Internal error'});
          }),
          catchError(error => of(new SalesStaffsError(error)))
        )
    )
  ));
  
  deleteSalesStaff = createEffect(() => this.actions$.pipe(
    ofType(SalesStaffActionTypes.salesStaffDeleteStaff),
    mergeMap((action: DeleteSalesStaff) =>
      this.salesStaffService
        .deleteSalesStaff(action.payload)
        .pipe(
          map(() => new DeleteSalesStaffSuccess(action.payload)),
          catchError(error => of(new SalesStaffsError(error)))
        )
    )
  ));
  
  searchSalesStaff$ = createEffect(() => this.actions$.pipe(
    ofType(SalesStaffActionTypes.salesStaffSearchStaffs),
    mergeMap((action: SearchSalesStaffs) =>
      this.salesStaffService
        .searchSalesStaff(action.payload)
        .pipe(
          map(stockingPoints => new SearchSalesStaffsSuccess(stockingPoints)),
          catchError(error => of(new SalesStaffsError(error)))
        )
    )
  ));

  /*
  @Effect()
  updateSalesStaffSuccess$ = this.actions$.pipe(
    ofType(SalesStaffActionTypes.salesStaffUpdateStaffSuccess),
    map(stockingPoint => new fromRouterActions.Go({path: ['/fieldforce/sales-staff/staff']}))
  );
  */

  constructor(
    private actions$: Actions,
    private salesStaffService: SalesStaffService,
    private tenantSalesStaffService: TenantSalesStaffService,
    private tenantService: TenantService,
    private router: Router,
    private toastr: ToastrService
  ) {
  }
}
