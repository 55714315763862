import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';

import { HttpClient } from '@angular/common/http';


@Injectable()
export class CoreServiceMpesaPull extends BaseService {

  constructor(_http: HttpClient, url) {

        super(_http, `coreservicempesapull`, url);
   }

}

