import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

import {
  AccessoryRateActionTypes,
  AccessoryRateError,
  AddAccessoryRate,
  AddAccessoryRateSuccess,
  DeleteAccessoryRate,
  DeleteAccessoryRateSuccess,
  GetAccessoryRateById,
  GetAccessoryRateByIdSuccess,
  GetAccessoryRateByProductId,
  GetAccessoryRateByProductIdSuccess,
  GetAccessoryRates,
  GetAccessoryRatesByTenant,
  GetAccessoryRatesByTenantSuccess,
  GetAccessoryRatesSuccess,
  SearchAccessoryRates,
  SearchAccessoryRatesSuccess,
  UpdateAccessoryRateSuccess
} from '@appStore/actions/core-accounting/accessory-rate/accessory-rate.actions';
import {AccessoryRateService} from '@appServices/core-accounting/accessory-rate/accessory-rate.service';
import {ToastrService} from 'ngx-toastr';

@Injectable()
export class AccessoryRateEffects {
  constructor(
    private actions$: Actions, 
    private accessoryRateService: AccessoryRateService,
    private toastr: ToastrService
    ) {}

  
  loadAccessoryRates$ = createEffect(() => this.actions$.pipe(
    ofType(AccessoryRateActionTypes.accessoryRateGetAccessoryRates),
    mergeMap((action: GetAccessoryRates) =>
      this.accessoryRateService
        .getAccessoryRates(action.payload)
        .pipe(
          map(accessoryRates => new GetAccessoryRatesSuccess(accessoryRates)),
          catchError(error => of(new AccessoryRateError(error)))
        )
    )
  ));

  // load accessoryRate by tenant product catalogue ID
  // @Effect()
  // loadAccessoryRateByProductId$ = this.actions$.pipe(
  //   ofType(AccessoryRateActionTypes.accessoryRateGetAccessoryRateByProductId),
  //   mergeMap(() =>
  //     this.accessoryRateService
  //       .getAccessoryRateBy(+localStorage.getItem('tenant'))
  //       .pipe(
  //         map(accessoryRate => new GetAccessoryRateByProductIdSuccess(accessoryRate)),
  //         catchError(error => of(new AccessoryRateError(error)))
  //       )
  //   )
  // );

  // @Effect()
  // loadAccessoryRatesByTenant$ = this.actions$.pipe(
  //   ofType(AccessoryRateActionTypes.accessoryRateGetAccessoryRates),
  //   mergeMap(() =>
  //     this.accessoryRateService
  //       .getAccessoryRateByTenant(+localStorage.getItem('tenant'))
  //       .pipe(
  //         map(accessoryRates => new GetAccessoryRatesSuccess(accessoryRates)),
  //         catchError(error => of(new AccessoryRateError(error)))
  //       )
  //   )
  // );

  
  getAccessoryRateById$ = createEffect(() => this.actions$.pipe(
    ofType(AccessoryRateActionTypes.accessoryRateGetAccessoryRateById),
    mergeMap((action: GetAccessoryRateById) =>
      this.accessoryRateService
        .getAccessoryRate(action.payload)
        .pipe(
          map(accessoryRate => new GetAccessoryRateByIdSuccess(accessoryRate)),
          catchError(error => of(new AccessoryRateError(error)))
        )
    )
  ));

  
  addAccessoryRate$ = createEffect(() => this.actions$.pipe(
    ofType(AccessoryRateActionTypes.accessoryRateAddAccessoryRate),
    switchMap((action: AddAccessoryRate) =>
      this.accessoryRateService
        .addAccessoryRate(action.payload)
        .pipe(
          map(
            accessoryRate => {
              if (accessoryRate !== undefined) {
                if (+localStorage.getItem('tenant') === 1003 || +localStorage.getItem('tenant') === 105) {
                  this.toastr.success('Accessory Rate has been successfully created! Please fill in tax details to activate rate.', 'Successful!');
                } else {
                  this.toastr.success('Accessory Rate has been successfully created!', 'Successful!');
                }
                
                return new AddAccessoryRateSuccess(accessoryRate)
              }
              this.toastr.error('There was an error adding this accessoryRate', "Unknown error");
              return new AccessoryRateError({ type: 500, message: 'Internal error' })
            }
            ),
          catchError(error => of(new AccessoryRateError(error)))
        )
    )
  ));

  
  addAdditionalAccessoryRateInfo$ = createEffect(() => this.actions$.pipe(
    ofType(AccessoryRateActionTypes.accessoryRateAddAdditionalAccessoryRateInfo),
    mergeMap((action: AddAccessoryRate) =>
      this.accessoryRateService
        .addAccessoryRateAdditionalInfo(action.payload)
        .pipe(
          map(accessoryRate => new GetAccessoryRates()),
          catchError(error => of(new AccessoryRateError(error)))
        )
    )
  ));

  
  updateAccessoryRate$ = createEffect(() => this.actions$.pipe(
    ofType(AccessoryRateActionTypes.accessoryRateUpdateAccessoryRate),
    mergeMap((action: AddAccessoryRate) =>
      this.accessoryRateService
        .updateAccessoryRate(action.payload)
        .pipe(
          map(accessoryRate => new UpdateAccessoryRateSuccess(action.payload)),
          catchError(error => of(new AccessoryRateError(error)))
        )
    )
  ));

  
  deleteAccessoryRate$ = createEffect(() => this.actions$.pipe(
    ofType(AccessoryRateActionTypes.accessoryRateDeleteAccessoryRate),
    mergeMap((action: DeleteAccessoryRate) =>
      this.accessoryRateService
        .deleteAccessoryRate(action.payload)
        .pipe(
          map(() => new DeleteAccessoryRateSuccess(action.payload)),
          catchError(error => of(new AccessoryRateError(error)))
        )
    )
  ));

  
  searchAccessoryRates$ = createEffect(() => this.actions$.pipe(
    ofType(AccessoryRateActionTypes.accessoryRateSearchAccessoryRates),
    mergeMap((action: SearchAccessoryRates) =>
      this.accessoryRateService
        .searchAccessoryRates(action.payload)
        .pipe(
          map(accessoryRates => new SearchAccessoryRatesSuccess(accessoryRates)),
          catchError(error => of(new AccessoryRateError(error)))
        )
    )
  ));

  // @Effect()
  // updateAccessoryRateSuccess$ = this.actions$.pipe(
  //   ofType(AccessoryRateActionTypes.accessoryRateUpdateAccessoryRateSuccess),
  //   map(accessoryRate => new fromRouterActions.Go({ path: ['/setup/accessoryRates'] }))
  // );
}
