import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getStockingPointStore = createFeatureSelector('stockingPoint');

export const getStockingPointEntities = createSelector(
  getStockingPointStore,
  fromReducers.stockingPoint.stockingPointEntitySelectors.selectAll
);

export const getStockingPoints = createSelector(getStockingPointEntities, entities => {
  return Object.values(entities);
});

export const getTopStockingPoints = createSelector(getStockingPointEntities, entities => {
  return Object.values(entities).slice(1, 5);
});

export const getStockingPointsLoaded = createSelector(
  getStockingPointStore,
  (stockingPointStore: fromReducers.stockingPoint.State) => stockingPointStore.loaded
);

export const getStockingPointsLoading = createSelector(
  getStockingPointStore,
  (stockingPointStore: fromReducers.stockingPoint.State) => stockingPointStore.loading
);

export const getStockingPointsCount = createSelector(
  getStockingPointStore,
  (stockingPointStore: fromReducers.stockingPoint.State) => stockingPointStore.count
);

export const getStockingPointsStartPage = createSelector(
  getStockingPointStore,
  (stockingPointStore: fromReducers.stockingPoint.State) => stockingPointStore.startPage
);

export const getStockingPointsNumberPerPage = createSelector(
  getStockingPointStore,
  (stockingPointStore: fromReducers.stockingPoint.State) => stockingPointStore.numberPerPage
);

export const getStockingPointsPaging = createSelector(
  getStockingPointStore,
  (stockingPointStore: fromReducers.stockingPoint.State) => stockingPointStore.paging
);

export const getSelectedStockingPointId = createSelector(
  getStockingPointStore,
  (stockingPointStore: fromReducers.stockingPoint.State) => stockingPointStore.selectedStockingPointId
);

export const getSelectedStockingPoint = createSelector(
  getStockingPointStore,
  (stockingPointStore: fromReducers.stockingPoint.State) => stockingPointStore.selectedStockingPoint
);

export const getSearchStockingPoints = createSelector(
  getStockingPointStore,
  (stockingPointStore: fromReducers.stockingPoint.State) => stockingPointStore.searchStockingPoints
);

export const getStockingPointById = createSelector(
  getStockingPointEntities,
  getSelectedStockingPointId,
  (entities, id) => entities.find(i => i.id === id)
);

export const getStockingPointsError = createSelector(
  getStockingPointStore,
  (stockingPointStore: fromReducers.stockingPoint.State) => stockingPointStore.error
);
