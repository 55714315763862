import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';

import {CoreServiceMpesaPull} from '../../core-service-mpesapull/core-service-mpesa-pull.service';


@Injectable({providedIn: 'root'})
export class MaktabaService extends CoreServiceMpesaPull {

  constructor(
    private http: HttpClient,
  ) {
    super(http, 'mpesa');
  }



  getMpesaStatement(phoneNumber: string): Observable<any> {

     const url = `${this.base_url}/customer?phoneNumber=${phoneNumber}`;
     return this.http.get<any>(url).pipe(
       tap(_ => this.log(`getMpesaStatement`)),
       catchError(this.handleError<any>(`getMpesaStatement`))
     );
   }



}
