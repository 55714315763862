import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getRepairCentreTypeStore = createFeatureSelector('repairCentreType');

export const getRepairCentreTypeEntities = createSelector(
  getRepairCentreTypeStore,
  fromReducers.repairCentreType.repairCentreTypeEntitySelectors.selectAll
);

export const getRepairCentreTypes = createSelector(getRepairCentreTypeEntities, entities => {
  return Object.values(entities);
});

export const getRepairCentreTypesLoading = createSelector(
  getRepairCentreTypeStore,
  (repairCentreStore: fromReducers.repairCentreType.State) => repairCentreStore.loading
);
