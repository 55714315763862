import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getModuleStore = createFeatureSelector('module');

export const getModuleEntities = createSelector(
  getModuleStore,
  fromReducers.module.moduleEntitySelectors.selectAll
);

export const getModules = createSelector(getModuleEntities, entities => {
  return Object.values(entities);
});

export const getTopModules = createSelector(getModuleEntities, entities => {
  return Object.values(entities).slice(1, 5);
});

export const getModulesLoaded = createSelector(
  getModuleStore,
  (moduleStore: fromReducers.module.State) => moduleStore.loaded
);

export const getModulesLoading = createSelector(
  getModuleStore,
  (moduleStore: fromReducers.module.State) => moduleStore.loading
);

export const getSelectedModuleId = createSelector(
  getModuleStore,
  (moduleStore: fromReducers.module.State) => moduleStore.selectedModuleId
);

export const getSearchModules = createSelector(
  getModuleStore,
  (moduleStore: fromReducers.module.State) => moduleStore.searchModules
);

export const getModuleById = createSelector(
  getModuleEntities,
  getSelectedModuleId,
  (entities, id) => entities.find(i => i.id === id)
);

export const getModulesError = createSelector(
  getModuleStore,
  (moduleStore: fromReducers.module.State) => moduleStore.error
);
