import {ChangeDetectorRef, Component, EventEmitter, Input, Output, TemplateRef, ViewChild} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {CustomerService} from "@appServices/core-crm/customer/customer.service";
import {CoreTicketingCustomerService} from "@appServices/core-ticketing/customer/core-ticketing-customer.service";
import {TicketingService} from "@appServices/core-ticketing/customer/ticketing-service";
import {ToastrService} from "ngx-toastr";
import {TicketingFileService} from "@appServices/core-ticketing/file/ticketing-file-service";
import {Router} from "@angular/router";
import {TeamsService} from "@appServices/core-ticketing/teams-service";
import {TicketingStatusService} from "@appServices/core-ticketing/ticketing-status.service";
import {PermissionResolver} from "@appServices/permission-resolver/permission-resolver.service";
import {TicketCustomFieldService} from "@appServices/core-ticketing/ticket-custom-field-service";
import {Clipboard} from "@angular/cdk/clipboard";
import {RepairCentreService} from "@appServices/core-aftersales/repaircentre/repair-centre.service";
import {TicketClosingDisposition} from "@appModels/core-aftersales/ticket-closing-disposition";
import {
  AftersaleFollowupDispositionService
} from "@appServices/core-aftersales/aftersale-followup-dispositions/aftersale-followup-dispositions.service";
import {TicketService} from "@appServices/core-aftersales/ticket/ticket.service";
import {CloseTicket, ReassignTicket} from "@appStore/actions/core-aftersales/ticket/ticket.actions";
import {Store} from "@ngrx/store";
import * as fromReducer from "@appStore/reducers";
import {TicketView} from "@appModels/ticketing/ticket-view";

@Component({
  moduleId: module.id,
  selector: 'warranty-ticket-view',
  templateUrl: './warranty-ticket-view-directive.html',
  animations: [
    trigger('toggleAnimation', [
      transition(':enter', [style({ opacity: 0, transform: 'scale(0.95)' }), animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' }))]),
      transition(':leave', [animate('75ms', style({ opacity: 0, transform: 'scale(0.95)' }))]),
    ]),
  ],
  styleUrls: ['./warranty-ticket-view-directive.css', '../../../tailwind.css']
})
export class WarrantyTicketViewDirective {
  constructor(
    private store: Store<fromReducer.ticket.State>,
    private toastrService: ToastrService,
    private permissionResolver: PermissionResolver,
    private clipboard: Clipboard,
    private permissionResolverService: PermissionResolver,
    private ticketFileService: TicketingFileService,
    private ref: ChangeDetectorRef,
    private modalService: BsModalService,
    private customerService: CustomerService,
    private router: Router,
    private ticketCustomFieldService: TicketCustomFieldService,
    private aftersaleFollowupDispositionService: AftersaleFollowupDispositionService,
    private ticketingService: TicketingService,
    private coreTicketingCustomerService: CoreTicketingCustomerService,
    private ticketStatusService: TicketingStatusService,
    private teamsService: TeamsService,
    private repairCentreService: RepairCentreService,
    private ticketService: TicketService
  ) {

  }

  @Output() closeEvent = new EventEmitter<any>();

  reassignWarrantyTicketReference: BsModalRef;
  @ViewChild('reassignWarrantyTicket') public reassignWarrantyTicket;
  followUpCallReference: BsModalRef;
  @ViewChild('followUpCall') public followUpCall;
  closeWarrantyTicketReference: BsModalRef;
  @ViewChild('closeWarrantyTicketModal') public closeWarrantyTicketModal;


  selectedTicket: TicketView = {
    id:null
  };
  @Input() set setTicket(ticket:any) {
    this.selectedTicket = ticket;
    this.ticketStatusService.getNextAvailableTicketStatuses(this.selectedTicket.id).subscribe(x => {
      this.ticketStatuses = x;
    });
    if (this.selectedTicket.afterSaleCustomerTicketId != null && this.selectedTicket.repairCentre != null) {
      this.repairCentreService.getRepairCentreItemsByTicket(this.selectedTicket.afterSaleCustomerTicketId, this.selectedTicket.repairCentre.id).subscribe(receivedItems => {
        this.receivedItems = receivedItems;
        console.log("received items");
        console.log(this.receivedItems)
      })
    }
  }

  canWarrantyAndService(action) {
    return this.permissionResolverService.setModuleName("Warranty And Service").can(action);
  }


  canCloseTicket(ticket) {
    return this.hasItems(ticket) && this.canWarrantyAndService('can-close-ticket');
  }

  getKeys(ticket) {
    return Object.keys(ticket.workOrderNumbers);
  }
  hasItems(ticket) {
    return (!this.selectedTicket.workOrderNumbers || !this.getKeys(ticket) || this.getKeys(ticket).length === 0) &&
      (!this.receivedItems[ticket.id] || this.receivedItems[ticket.id].length === 0)
  }

  canReassignTicket(ticket) {
    return this.hasItems(ticket) && this.canWarrantyAndService('can-reassign-ticket');
  }

  ticketStatuses = [];
  receivedItems : any[]= [];
  dispositionId: number = null;
  repairCentreNames = [];
  closing = false;
  reassigning = false;
  closingComment: string = null;
  ticketClosingDispositions: TicketClosingDisposition[] = [];
  newRepairCentreId = null;
  repairCenterId: number = null;
  repairCenterName: string = null;
  repairCentres = [];
  followUp: boolean = false;
  followupDispositions = ['Already at the SC', 'Cannot visit(Financial Constraints)', 'Gave to Agent', 'In Transit', 'Lost/Stolen', 'Resolved', 'To revisit SC', 'To visit SC', 'Customer to call back', 'Agent/Technician to call back']
  followUpError: boolean;
  selectWarrantyAction(option) {
    console.log("selecting action")
    this.dispositionId = null;
    this.closing = false;
    this.followUp = false;
    this.reassigning = false;
    if (option === 'work_order') {
      //this.modalRef.hide();
      this.closeEvent.emit();
      this.createWorkOrder(this.selectedTicket.afterSaleCustomerTicketId);
    } else if (option === 'receive') {
      // this.modalRef.hide();
      this.closeEvent.emit();
      this.receiveItems(this.selectedTicket.afterSaleCustomerTicketId);
    } else if (option === 'close') {
      this.loadWarrantyTicketClosingDispositions();
      this.closing = true;
      let input = document.getElementById("openCloseWarrantyTicketModalButton");
      input?.click();
    } else if (option === 'reassign') {
      this.newRepairCentreId = null;
      this.reassigning = true;
      let input = document.getElementById("openReassignWarrantyTicketModalButton");
      input?.click();
    } else if (option === 'followUp') {
      let input = document.getElementById("openFollowUpCallButton");
      input?.click();
      this.followUp = true;
    }
  }

  createWorkOrder(id) {
    this.router.navigateByUrl(`/warranty-and-service/work_order/register/${id}`);
  }

  receiveItems(id) {
    this.router.navigateByUrl(`/warranty-and-service/work_order/receive/${id}`);
  }

  goToWorkOrder(id:any) {
    this.closeEvent.emit();
    this.router.navigateByUrl('/warranty-and-service/work_order/' + id);
  }


  openReassignWarrantyTicketModal(confirmation: TemplateRef<any>) {
    if (this.repairCentreNames.length == 0) {
      this.loadRepairCentres();
    }
    this.reassignWarrantyTicketReference = this.modalService.show(confirmation, { class: 'modal-md' });
  }

  openFollowUpCallModalModal(confirmation: TemplateRef<any>) {
    this.followUpCallReference = this.modalService.show(confirmation, { class: 'modal-md' });
  }

  openCloseWarrantyTicketModal(confirmation: TemplateRef<any>) {
    this.closeWarrantyTicketReference = this.modalService.show(confirmation, { class: 'modal-md' });
  }


  loadRepairCentres() {
    this.repairCentreService.getEnabledRepairCentres().subscribe(repairCentres => {
      this.repairCentres = repairCentres;
      this.repairCentreNames = [...repairCentres].map(item=>{
        return {id: item.id, text: `${item.name} (L${item.type.level})`};
      })
    });
  }

  followupDisposition = null;
  followupNotes = null;

  followupTicket(){
    this.followUpError = false;
    if(this.followupDisposition !== null && (this.followupNotes !== '' && this.followupNotes !== null)){
      this.aftersaleFollowupDispositionService.addFollowupCall(this.selectedTicket.afterSaleCustomerTicketId, {disposition: this.followupDisposition, notes: this.followupNotes}).subscribe(
        data => {
          this.toastrService.success("Follow up call saved!");
          this.followUpCallReference.hide();
        }
      )
    } else {
      this.followUpError = true;
    }
  }

  loadWarrantyTicketClosingDispositions() {
    this.ticketService.getTicketClosingDispositions().subscribe(dispositions => {
      this.ticketClosingDispositions = dispositions;
    });
  }

  selectClosingDisposition(id) {
    this.dispositionId = id;
  }

  closeWarrantyTicket() {
    if (this.closingComment) {
      this.closing = false;
      this.store.dispatch(new CloseTicket({
        id: this.selectedTicket.afterSaleCustomerTicketId,
        closeComment: this.closingComment,
        dispositionId: this.dispositionId
      }));



      this.closeWarrantyTicketReference.hide();

      this.selectedTicket.ticketStatusName = "Completed";
      this.selectedTicket.ticketStatusColorCode = "#bafdbb";
      this.selectedTicket.ticketStatusTextColor = "#1a8118";


    }
  }

  changeRepairCentre(event){
    console.log("event");
    console.log(event);
    this.repairCenterId = event.id;
    this.repairCenterName = event.text;
  }

  reassignTicket() {
    if (this.newRepairCentreId) {
      this.reassignWarrantyTicketReference.hide();
      this.reassigning = false;
      this.store.dispatch(new ReassignTicket({
        id: this.selectedTicket.afterSaleCustomerTicketId,
        repairCentreId: this.newRepairCentreId
      }));
      this.selectedTicket.assignedInitials = this.repairCenterName.substring(0,1);
      this.selectedTicket.assignedName = this.repairCenterName;
    }
  }



}
