import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {ReportSchema} from '@appModels/core-reporting/report-schema/report-schema';
import {
  ReportSchemaActions,
  ReportSchemaActionTypes
} from '../../../actions/core-reporting/report-schema/report-schema.actions';
import * as _ from 'lodash';

export interface State extends EntityState<ReportSchema> {
  error: any;
  loaded: boolean;
  loading: boolean;
  paging: any;
}

export const adapter: EntityAdapter<ReportSchema> = createEntityAdapter<ReportSchema>();

export const initialState: State = adapter.getInitialState({
  error: null,
  loaded: false,
  loading: false,
  paging: null
});

export function reducer(state = initialState, action: ReportSchemaActions): State {
  switch (action.type) {
    case ReportSchemaActionTypes.reportSchemaGetByTenant:
    case ReportSchemaActionTypes.reportSchemaGetForUser:
      return {
        ...state,
        loading: true,
        loaded: false
      };

    case ReportSchemaActionTypes.reportSchemaGetByTenantSuccess:
    case ReportSchemaActionTypes.reportSchemaGetForUserSuccess:
      return adapter.setAll(action.payload.content, {
        ...state,
        loading: false,
        loaded: true,
        paging: _.omit(action.payload, ['content'])
      });
    default:
      return state;
  }
}

export const reportSchemaEntitySelectors = adapter.getSelectors();

