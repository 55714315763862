import {createEntityAdapter, EntityAdapter, EntityState,} from '@ngrx/entity';

import {ShippingCompany} from '@appModels/core-inventory/shipping-company/shipping-company';
import {
  ShippingCompanyActions,
  ShippingCompanyActionTypes
} from '@appStore/actions/core-inventory/shipping-company/shipping-company.actions';
import * as _ from 'lodash';

export interface State extends EntityState<ShippingCompany> {
  loaded: boolean;
  loading: boolean;
  error: any;
  selectedShippingCompanyId: number;
  selectedShippingCompany: ShippingCompany;
  searchShippingCompanies: ShippingCompany[];
  paging: any;
}

export const adapter: EntityAdapter<ShippingCompany> = createEntityAdapter<ShippingCompany>();

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
  selectedShippingCompanyId: null,
  selectedShippingCompany: null,
  error: null,
  searchTerm: '',
  searchShippingCompanies: null,
  paging: null
});

export function reducer(state = initialState, action: ShippingCompanyActions): State {
  switch (action.type) {
    case ShippingCompanyActionTypes.shippingCompanyGetShippingCompanies:
    case ShippingCompanyActionTypes.shippingCompanyAddShippingCompany:
    case ShippingCompanyActionTypes.shippingCompanyAddAdditionalShippingCompanyInfo:
    case ShippingCompanyActionTypes.shippingCompanyDeleteShippingCompany:
    case ShippingCompanyActionTypes.shippingCompanyUpdateShippingCompany:
    case ShippingCompanyActionTypes.shippingCompanySearchShippingCompanies:
    case ShippingCompanyActionTypes.shippingCompanyGetShippingCompanyById:
    case ShippingCompanyActionTypes.shippingCompanyGetShippingCompaniesByTenantId:
    case ShippingCompanyActionTypes.shippingCompanyAddTenantShippingCompanies:
    case ShippingCompanyActionTypes.shippingCompanyGetShippingCompaniesByTenantIdPaged:
      return {
        ...state,
        loading: true
      };

    case ShippingCompanyActionTypes.shippingCompanyGetShippingCompaniesSuccess:
    case ShippingCompanyActionTypes.shippingCompanyGetShippingCompaniesByTenantIdSuccess:
      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });

    case ShippingCompanyActionTypes.shippingCompanyGetShippingCompanyByIdSuccess:
      return {
        ...state,
        selectedShippingCompanyId: action.payload.id,
        selectedShippingCompany: action.payload,
        loading: false
      };

    case ShippingCompanyActionTypes.shippingCompanyAddShippingCompanySuccess:
      return adapter.addOne(action.payload, {
        ...state,
        selectedShippingCompanyId: action.payload.id,
        loading: false,
        loaded: true
      });

    case ShippingCompanyActionTypes.shippingCompanyUpdateShippingCompanySuccess:
    case ShippingCompanyActionTypes.shippingCompanyAddTenantShippingCompaniesSuccess: {
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state,
          loading: false,
          loaded: true
        }
      );
    }

    case ShippingCompanyActionTypes.shippingCompanyDeleteShippingCompanySuccess: {
      return adapter.removeOne(action.payload.id, {
        ...state,
        loading: false,
        loaded: true
      });
    }

    case ShippingCompanyActionTypes.shippingCompanySearchShippingCompaniesSuccess:
      return {
        ...state,
        searchShippingCompanies: action.payload,
        loading: false
      };

    case ShippingCompanyActionTypes.shippingCompanySearchShippingCompaniesReset:
      return {
        ...state,
        searchShippingCompanies: null
      };

    case ShippingCompanyActionTypes.shippingCompanyError:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload
      };

    case ShippingCompanyActionTypes.shippingCompanyGetShippingCompaniesByTenantIdPagedSuccess:
      return adapter.setAll(action.payload.content, {
        ...state,
        loading: false,
        loaded: true,
        paging: _.omit(action.payload, ['content'])
      });

    default:
      return state;
  }
}

export const shippingCompanyEntitySelectors = adapter.getSelectors();
