import { Action } from '@ngrx/store';

import { Page } from '@appModels/core-knowledge-base/pages/page';

export enum PageActionTypes {
  pageGetPages = '[Page] get',
  pageGetPagesSuccess = '[Page] get pages success',
  pageAddPage = '[Page] add Page',
  pageAddPageSuccess = '[Page] add page success',
  pageDeletePage = '[Page] delete page',
  pageDeletePageSuccess = '[Page] delete page success',
  pageGetPageById = '[Page] get page by id',
  pageGetPageByIdSuccess = '[Page] get page by id success',
  pageUpdatePage = '[Page] update page',
  pageUpdatePageSuccess = '[Page] update page success',
  pageEditPage = '[Page] edit page',
  pageEditPageSuccess = '[Page] edit page success',
  pageSearchPages = '[Page] search pages',
  pageSearchPagesSuccess = '[Page] search pages success',
  pageSearchPagesReset = '[Page] search pages reset',
  pageSelectedPagesReset = '[Page] selected page reset',
  pageError = '[Page] error'
}

export class GetPages implements Action {
  readonly type = PageActionTypes.pageGetPages;
  constructor(public payload?) {}
}

export class GetPagesSuccess implements Action {
  readonly type = PageActionTypes.pageGetPagesSuccess;
  constructor(public payload) {}
}

export class AddPage implements Action {
  readonly type = PageActionTypes.pageAddPage;
  constructor(public payload: Page) {}
}

export class AddPageSuccess implements Action {
  readonly type = PageActionTypes.pageAddPageSuccess;
  constructor(public payload: Page) {}
}

export class GetPageById implements Action {
  readonly type = PageActionTypes.pageGetPageById;
  constructor(public payload: number) {}
}

export class GetPageByIdSuccess implements Action {
  readonly type = PageActionTypes.pageGetPageByIdSuccess;
  constructor(public payload: Page) {}
}

export class UpdatePage implements Action {
  readonly type = PageActionTypes.pageUpdatePage;
  constructor(public payload: Page) {}
}

export class UpdatePageSuccess implements Action {
  readonly type = PageActionTypes.pageUpdatePageSuccess;
  constructor(public payload: Page) {}
}

export class EditPage implements Action {
  readonly type = PageActionTypes.pageEditPage;
  constructor(public payload) {}
}

export class EditPageSuccess implements Action {
  readonly type = PageActionTypes.pageEditPageSuccess;
  constructor(public payload) {}
}

export class DeletePage implements Action {
  readonly type = PageActionTypes.pageDeletePage;
  constructor(public payload: Page) {}
}

export class DeletePageSuccess implements Action {
  readonly type = PageActionTypes.pageDeletePageSuccess;
  constructor(public payload: Page) {}
}

export class SearchPages implements Action {
  readonly type = PageActionTypes.pageSearchPages;
  constructor(public payload?) {}
}

export class SearchPagesSuccess implements Action {
  readonly type = PageActionTypes.pageSearchPagesSuccess;
  constructor(public payload: Page[]) {}
}

export class SearchPagesReset implements Action {
  readonly type = PageActionTypes.pageSearchPagesReset;
}

export class SelectedPageReset implements Action {
  readonly type = PageActionTypes.pageSelectedPagesReset;
}

export class PageError implements Action {
  readonly type = PageActionTypes.pageError;
  constructor(public payload: any) {}
}

export type PageActions =
  | GetPages
  | GetPagesSuccess
  | AddPage
  | AddPageSuccess
  | GetPageById
  | GetPageByIdSuccess
  | UpdatePage
  | UpdatePageSuccess
  | EditPage
  | EditPageSuccess
  | DeletePage
  | DeletePageSuccess
  | SearchPages
  | SearchPagesSuccess
  | SearchPagesReset
  | SelectedPageReset
  | PageError;
