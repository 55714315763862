import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import {of} from "rxjs";
import {ToastrService} from 'ngx-toastr';
import {
  AddFault,
  AddFaultSuccess, DeleteFaultById,
  FaultActionTypes,
  FaultError, GetAllFaultForWorkOrderSuccess, GetFaultById, GetFaultByIdSuccess, UpdateFault, UpdateFaultSuccess,
} from '../../../actions/core-aftersales/faults/fault.actions';
import {FaultService} from '../../../../services/core-aftersales/fault/fault.service';
import {Router} from '@angular/router';

@Injectable()
export class FaultEffects {

  addFault = createEffect(() => this.actions$.pipe(
    ofType(FaultActionTypes.faultAdd),
    switchMap((action: AddFault) =>
      this.faultService
        .saveFault(action.payload)
        .pipe(
          map(
            fault => {
              if (fault !== undefined) {
                this.toastr.success('Faults added successfully!', 'Successful!');
                this.router.navigateByUrl('/global-settings');
                return new AddFaultSuccess(fault);
              }
              this.toastr.error('There was an error creating fault!', 'Unknown error');
              return new FaultError({type: 500, message: 'Internal error'})
            }
          ),
          catchError(error => of(new FaultError(error)))
        )
    )
  ));



  faults = createEffect(() => this.actions$.pipe(
    ofType(FaultActionTypes.faultGetAllForWorkOrder),
    mergeMap((action: GetAllFaultForWorkOrderSuccess) =>
      this.faultService
        .getWorkOrderFaults(action.payload)
        .pipe(
          map(faults => {
            return new GetAllFaultForWorkOrderSuccess(faults);
          }),
          catchError(error => of(new FaultError(error)))
        )
    )
  ));


  getFaultById$ = createEffect(() => this.actions$.pipe(
    ofType(FaultActionTypes.faultGetFaultById),
    mergeMap((action: GetFaultById) =>
      this.faultService
        .getFault(action.payload)
        .pipe(
          map(fault => new GetFaultByIdSuccess(fault)),
          catchError(error => of(new FaultError(error)))
        )
    )));

  deleteFaultById$ = this.actions$.pipe(
    ofType(FaultActionTypes.faultDeleteById),
    mergeMap((action: DeleteFaultById) =>
      this.faultService
        .getFault(action.payload)
        .pipe(
          map(fault => new DeleteFaultById(fault)),
          catchError(error => of(new FaultError(error)))
        )
    ));


  updateFault$ = createEffect(() => this.actions$.pipe(
    ofType(FaultActionTypes.faultUpdateFault),
    mergeMap((action: UpdateFault) =>
      this.faultService
        .updateFault(action.payload)
        .pipe(
          map(
            fault => {
              if (fault !== undefined) {
                this.toastr.success('Fault has been successfully updated!', 'Successful!');
                return new UpdateFaultSuccess(action.payload);
              }
              return new FaultError({ type: 500, message: 'Internal error' })
            }
            ),
          catchError(error => of(new FaultError(error)))
        )
    )
  ));

  constructor(
    private actions$: Actions,
    private faultService: FaultService,
    private toastr: ToastrService,
    private router: Router
  ) {
  }
}
