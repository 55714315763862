import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getConsignmentProductAcceptReceiveStatusStore = createFeatureSelector('consignmentProductAcceptReceiveStatus');

export const getConsignmentProductAcceptReceiveStatusEntities = createSelector(
  getConsignmentProductAcceptReceiveStatusStore,
  fromReducers.consignmentProductAcceptReceiveStatus.consignmentProductAcceptReceiveStatusEntitySelectors.selectAll
);

export const getConsignmentProductAcceptReceiveStatus = createSelector(getConsignmentProductAcceptReceiveStatusEntities, entities => {
  return Object.values(entities);
});

export const getTopConsignmentProductAcceptReceiveStatuses = createSelector(getConsignmentProductAcceptReceiveStatusEntities, entities => {
  return Object.values(entities).slice(1, 5);
});

export const getConsignmentProductAcceptReceiveStatusesLoaded = createSelector(
  getConsignmentProductAcceptReceiveStatusStore,
  (getConsignmentProductAcceptReceiveStatusStore: fromReducers.consignmentProductAcceptReceiveStatus.State) => getConsignmentProductAcceptReceiveStatusStore.loaded
);

export const getSelectedConsignmentProductAcceptReceiveStatus = createSelector(
  getConsignmentProductAcceptReceiveStatusStore,
  (getConsignmentProductAcceptReceiveStatusStore: fromReducers.consignmentProductAcceptReceiveStatus.State) => getConsignmentProductAcceptReceiveStatusStore.selectedConsignmentProductAcceptReceiveStatus
);

export const getConsignmentProductAcceptReceiveStatusesLoading = createSelector(
  getConsignmentProductAcceptReceiveStatusStore,
  (getConsignmentProductAcceptReceiveStatusStore: fromReducers.consignmentProductAcceptReceiveStatus.State) => getConsignmentProductAcceptReceiveStatusStore.loading
);

export const getSearchConsignmentProductAcceptReceiveStatuses = createSelector(
  getConsignmentProductAcceptReceiveStatusStore,
  (getConsignmentProductAcceptReceiveStatusStore: fromReducers.consignmentProductAcceptReceiveStatus.State) => getConsignmentProductAcceptReceiveStatusStore.searchConsignmentProductAcceptReceiveStatus
);

export const getConsignmentProductAcceptReceiveStatusesError = createSelector(
  getConsignmentProductAcceptReceiveStatusStore,
  (getConsignmentProductAcceptReceiveStatusStore: fromReducers.consignmentProductAcceptReceiveStatus.State) => getConsignmentProductAcceptReceiveStatusStore.error
);
