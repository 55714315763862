import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getConsignmentProductInventoryProductStore = createFeatureSelector('consignmentProductInventoryProduct');

export const getConsignmentProductInventoryProductEntities = createSelector(
  getConsignmentProductInventoryProductStore,
  fromReducers.consignmentProductInventoryProduct.consignmentProductInventoryProductEntitySelectors.selectAll
);

export const getConsignmentProductsInventoryProducts = createSelector(getConsignmentProductInventoryProductEntities, entities => {
  return Object.values(entities);
});

export const getConsignmentPaging = createSelector(
  getConsignmentProductInventoryProductStore,
  (consignmentProductInventoryProductStore: fromReducers.consignmentProductInventoryProduct.State) => consignmentProductInventoryProductStore.paging
);

export const getConsignmentProductLoading = createSelector(
  getConsignmentProductInventoryProductStore,
  (consignmentProductInventoryProductStore: fromReducers.consignmentProductInventoryProduct.State) => consignmentProductInventoryProductStore.loading
);
