import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { TenantCustomField } from '@appModels/core-setup/tenant-custom-field/tenant-custom-field';

import { CoreMasterDataService } from '../core-masterdata.service';



@Injectable({ providedIn: 'root' })
export class TenantCustomFieldService extends CoreMasterDataService {


  constructor(
    private http: HttpClient,

  ) {
    super(http, 'tenantcustomfield');
  }

  /** GET tenantCustomFields from the server */
  getTenantCustomFields(): Observable<TenantCustomField[]> {
    return this.http.get<TenantCustomField[]>(this.base_url + '/tenant/' + localStorage.getItem('tenant') , { headers: this.headers }).pipe(
      tap(tenantCustomFields => this.log(`fetched tenantCustomFields`)),
      catchError(this.handleError('getTenantCustomFields', []))
    );
  }

  /** GET tenantCustomField by id. Return `undefined` when id not found */
  getTenantCustomFieldNo404<Data>(id: number): Observable<TenantCustomField> {
    const url = `${this.base_url}/?id=${id}`;
    return this.http.get<TenantCustomField[]>(url).pipe(
      map(tenantCustomFields => tenantCustomFields[0]), // returns a {0|1} element array
      tap(h => {
        const outcome = h ? `fetched` : `did not find`;
        this.log(`${outcome} tenantCustomField id=${id}`);
      }),
      catchError(this.handleError<TenantCustomField>(`getTenantCustomField id=${id}`))
    );
  }

  /** GET tenantCustomField by id. Will 404 if id not found */
  getTenantCustomField(id: number): Observable<TenantCustomField> {
    const url = `${this.base_url}/${id}`;
    return this.http.get<TenantCustomField>(url,{ headers: this.headers }).pipe(
      tap(_ => this.log(`fetched tenantCustomField id=${id}`)),
      catchError(this.handleError<TenantCustomField>(`getTenantCustomField id=${id}`))
    );
  }

  /* GET tenantCustomFields whose name contains search term */
  searchTenantCustomFields(term: string): Observable<TenantCustomField[]> {
    if (!term.trim()) {
      // if not search term, return empty tenantCustomField array.
      return of([]);
    }
    return this.http.get<TenantCustomField[]>(`api/tenantCustomFields/?name=${term}`).pipe(
      tap(_ => this.log(`found tenantCustomFields matching "${term}"`)),
      catchError(this.handleError<TenantCustomField[]>('searchTenantCustomFields', []))
    );
  }

  //////// Save methods //////////

  /** POST: add a new tenantCustomField to the server */
  addTenantCustomField(tenantCustomField: TenantCustomField): Observable<TenantCustomField> {
    return this.http.post<TenantCustomField>(this.base_url , tenantCustomField, { headers: this.headers }).pipe(
      tap((tenantCustomField: TenantCustomField) => this.log(`added TenantCustomField w/ id=${tenantCustomField.id}`)),
      catchError(this.handleError<TenantCustomField>('addTenantCustomField'))
    );
  }

  /** DELETE: delete the tenantCustomField from the server */
  deleteTenantCustomField(tenantCustomField: TenantCustomField | number): Observable<TenantCustomField> {
    const id = typeof tenantCustomField === 'number' ? tenantCustomField : tenantCustomField.id;
    const url = `${this.base_url}/${id}`;

    return this.http.delete<TenantCustomField>(url, { headers: this.headers }).pipe(
      tap(_ => this.log(`deleted tenantCustomField id=${id}`)),
      catchError(this.handleError<TenantCustomField>('deleteTenantCustomField'))
    );
  }

  /** PUT: update the tenantCustomField on the server */
  updateTenantCustomField(tenantCustomField: TenantCustomField): Observable<any> {
    return this.http.put(`${this.base_url}/${tenantCustomField.id}`, tenantCustomField, { headers: this.headers }).pipe(
      tap(_ => this.log(`updated tenantCustomField id=${tenantCustomField.id}`)),
      catchError(this.handleError<any>('updateTenantCustomField'))
    );
  }

  /** Patch: update the tenantCustomField on the server */
  editTenantCustomField(data): Observable<any> {
    return this.http.patch(`${this.base_url}/${data.id}/${data.param}/${data.value}`, {}, { headers: this.headers }).pipe(
      tap(_ => this.log(`updated tenantCustomField id=${data.id}`)),
      catchError(this.handleError<any>('updateTenantCustomField'))
    );
  }

}
