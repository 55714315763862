<router-outlet></router-outlet>

<!-- reload modal -->
<ng-template #template>
  <div class="modal-header">
    <strong>{{'New Atlas Release' | translate}}</strong>
  </div>
  <div class="app_setup_layout">
    <p>Notice: Atlas upgrade is currently in progress. Kindly reload Atlas after completion.</p>
  </div>

  <div class="modal-footer">
    <button class="button_success pull-right" type="button" (click)="reloadWindow()">{{'Reload' | translate}}</button>
  </div>

</ng-template>
<!-- end reload modal -->


<!-- email confirmation modal -->
<ng-template #confirmation>
  <div class="modal-header">
    <strong>{{'Email Confirmation' | translate}}</strong>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalReference.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <p class="app_setup_layout">{{'Please verify your email address if you want to receive emails from d.light in the future' | translate}}. </p>
  <div class="app_setup_layout" style="margin-top: -40px;" *ngIf="user?.emailVerificationCodeGenerated">
    <form>
      <div class="form_group_modal">
        <div class="input">
          <label class="label_title">{{'Code' | translate}}</label>
          <input type="text" [(ngModel)]="code" name="code" placeholder="Insert code received from your email" >
          <div class="notification error" *ngIf="invalidCode">
            {{'Code is Invalid' | translate}}
          </div>
        </div>

      </div>
    </form>
  </div>

  <div class="modal-footer">
    <button class="button_success pull-right" type="button" (click)="sendVerificationCode()" *ngIf="!user?.emailVerificationCodeGenerated">{{'Send Verification Code' | translate}}</button>
    <button class="button_success pull-right" type="button" (click)="verify()" *ngIf="user?.emailVerificationCodeGenerated">{{'Verify' | translate}}</button>
    <button class="button_success pull-right" type="button" (click)="decline();modalReference.hide()">{{'Decline' | translate}}</button>
  </div>

</ng-template>
<!-- end email confirmation modal -->
