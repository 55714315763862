import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getTicketStore = createFeatureSelector('ticket');

export const getTicketEntities = createSelector(
  getTicketStore,
  fromReducers.ticket.ticketEntitySelectors.selectAll
);

export const getTicketPaging = createSelector(
  getTicketStore,
  (ticketStore: fromReducers.ticket.State) => ticketStore.paging
);


export const getTickets = createSelector(getTicketEntities, entities => {
  return Object.values(entities);
});


export const getTicketsLoaded = createSelector(
  getTicketStore,
  (ticketStore: fromReducers.ticket.State) => ticketStore.loaded
);

export const getTicketsLoading = createSelector(
  getTicketStore,
  (ticketStore: fromReducers.ticket.State) => ticketStore.loading
);

export const getSelectedTicketId = createSelector(
  getTicketStore,
  (ticketStore: fromReducers.ticket.State) => ticketStore.selectedTicketId
);

export const getSearchTickets = createSelector(
  getTicketStore,
  (ticketStore: fromReducers.ticket.State) => ticketStore.searchTickets
);

export const getTicketById = createSelector(
  getTicketStore,
  (ticketStore: fromReducers.ticket.State) => ticketStore.selectedTicket
);

export const getTicketError = createSelector(
  getTicketStore,
  (ticketStore: fromReducers.ticket.State) => ticketStore.error
);
