import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";

@Component({
  selector: "app-right-modal-page",
  templateUrl: "./right-modal-page.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RightModalPageComponent implements OnInit {
  @Input() customer;
  @Input() awaitingEscalation;

  @Output() scoreSaved = new EventEmitter<void>();
  isAllScoreAnswered: boolean;

  constructor() {}

  ngOnInit() {}

  onAllScoreSaved(): void {
    this.isAllScoreAnswered = true;
  }
}
