import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {
  RepairCentreTechnicianActions,
  RepairCentreTechnicianActionTypes
} from '../../../actions/core-aftersales/repaircentres/repaircentretechnican.action';
import {RepairCentreTechnician} from '../../../../models/core-aftersales/repair-centre-technician';
import * as _ from 'lodash';

export interface State extends EntityState<RepairCentreTechnician> {
  error: any;
  loaded: boolean;
  loading: boolean;
  selectedTechnicianId: number;
  paging: any;
}

export const adapter: EntityAdapter<RepairCentreTechnician> = createEntityAdapter<RepairCentreTechnician>();

export const initialState: State = adapter.getInitialState({
  error: null,
  loaded: false,
  loading: false,
  selectedTechnicianId: null,
  paging: null
});

export function reducer(state = initialState, action: RepairCentreTechnicianActions): State {
  switch (action.type) {
    case RepairCentreTechnicianActionTypes.repairCentreTechnicianGetAll:
    case RepairCentreTechnicianActionTypes.repairCentreTechnicianGetByUser:
    case RepairCentreTechnicianActionTypes.repairCentreTechnicianSave:
    case RepairCentreTechnicianActionTypes.repairCentreTechnicianGetPaged:
    case RepairCentreTechnicianActionTypes.repairCentreTechnicianDeactivate:
    case RepairCentreTechnicianActionTypes.repairCentreTechnicianActivate:
    case RepairCentreTechnicianActionTypes.repairCentreTechnicianGetByTenant:
      return {
        ...state,
        loading: true,
        loaded: false,
        selectedTechnicianId: null
      };

    case RepairCentreTechnicianActionTypes.repairCentreTechnicianError:
      return adapter.addOne(action.payload, {
        ...state,
        error: action.payload.id,
        loading: false,
        loaded: true
      });

    case RepairCentreTechnicianActionTypes.repairCentreTechnicianGetAllSuccess:
    case RepairCentreTechnicianActionTypes.repairCentreTechnicianGetByTenantSuccess:
    case RepairCentreTechnicianActionTypes.repairCentreTechnicianGetByUserSuccess:
      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true,
        selectedTechnicianId: null,
      });
    case RepairCentreTechnicianActionTypes.repairCentreTechnicianGetPagedSuccess:
      return adapter.setAll(action.payload.content, {
        ...state,
        loading: false,
        loaded: true,
        selectedTechnicianId: null,
        paging: _.omit(action.payload, ['content'])
      });
    case RepairCentreTechnicianActionTypes.repairCentreTechnicianDeactivateSuccess:
    case RepairCentreTechnicianActionTypes.repairCentreTechnicianActivateSuccess:
      return adapter.updateOne({
        id: action.payload.id,
        changes: action.payload
      }, {
        ...state,
        loading: false,
        loaded: true,
        selectedTechnicianId: action.payload ? action.payload.id : null
      });
    default:
      return state;
  }
}

export const repairCentreTechnicianEntitySelectors = adapter.getSelectors();

