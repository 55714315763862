import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getDelinquencyDefinitionStore = createFeatureSelector('delinquencyDefinition');

export const getDelinquencyDefinitionEntities = createSelector(
  getDelinquencyDefinitionStore,
  fromReducers.delinquencyDefinition.delinquencyDefinitionEntitySelectors.selectAll
);

export const getDelinquencyDefinitions = createSelector(getDelinquencyDefinitionEntities, entities => {
  return Object.values(entities);
});

export const getTopDelinquencyDefinitions = createSelector(getDelinquencyDefinitionEntities, entities => {
  return Object.values(entities).slice(1, 5);
});

export const getDelinquencyDefinitionPaging = createSelector(
  getDelinquencyDefinitionStore,
  (delinquencyDefinitionStore: fromReducers.delinquencyDefinition.State) => delinquencyDefinitionStore.paging
);

export const getDelinquencyDefinitionsLoaded = createSelector(
  getDelinquencyDefinitionStore,
  (delinquencyDefinitionStore: fromReducers.delinquencyDefinition.State) => delinquencyDefinitionStore.loaded
);

export const getDelinquencyDefinitionsLoading = createSelector(
  getDelinquencyDefinitionStore,
  (delinquencyDefinitionStore: fromReducers.delinquencyDefinition.State) => delinquencyDefinitionStore.loading
);

export const getSelectedDelinquencyDefinitionId = createSelector(
  getDelinquencyDefinitionStore,
  (delinquencyDefinitionStore: fromReducers.delinquencyDefinition.State) => delinquencyDefinitionStore.selectedDelinquencyDefinitionId
);

export const getSearchDelinquencyDefinitions = createSelector(
  getDelinquencyDefinitionStore,
  (delinquencyDefinitionStore: fromReducers.delinquencyDefinition.State) => delinquencyDefinitionStore.searchDelinquencyDefinitions
);

export const getDelinquencyDefinitionById = createSelector(
  getDelinquencyDefinitionEntities,
  getSelectedDelinquencyDefinitionId,
  (entities, id) => entities.find(i => i.id === id)
);

export const getDelinquencyDefinitionsError = createSelector(
  getDelinquencyDefinitionStore,
  (delinquencyDefinitionStore: fromReducers.delinquencyDefinition.State) => delinquencyDefinitionStore.error
);


