import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import * as _ from 'lodash';

import { Customer } from '@appModels/core-crm/customer/customer';
import { CustomerActions, CustomerActionTypes } from '@appStore/actions/core-crm/customer/customer.actions';

export interface State extends EntityState<Customer> {
  loaded: boolean;
  loading: boolean;
  error: any;
  selectedCustomerId: number;
  selectedUniqueId: string;
  customerGeneralInfoId: number;
  customerLivingStandardInfoId: number;
  customerUpsellMetricsInfoId: number;
  selectedCustomer: any;
  searchCustomers: Customer[];
  paging: any;
}

export const adapter: EntityAdapter<Customer> = createEntityAdapter<Customer>();

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
  selectedCustomerId: null,
  selectedUniqueId:null,
  customerGeneralInfoId: null,
  customerLivingStandardInfoId: null,
  customerUpsellMetricsInfoId: null,
  selectedCustomer: null,
  error: null,
  searchTerm: '',
  searchCustomers: null,
  paging: null
});

export function reducer(state = initialState, action: CustomerActions): State {
  switch (action.type) {
    case CustomerActionTypes.customerGetCustomers:
    case CustomerActionTypes.customerAddCustomer:
    case CustomerActionTypes.customerAddAdditionalCustomerInfo:
    case CustomerActionTypes.customerAddPhotoInfo:
    case CustomerActionTypes.customerAddCustomerGeneralInfo:
    case CustomerActionTypes.customerAddLivingStandardInfo:
    case CustomerActionTypes.customerAddUpsellMetricsInfo:
    case CustomerActionTypes.customerUpdateCustomerGeneralInfo:
    case CustomerActionTypes.customerUpdateLivingStandardInfo:
    case CustomerActionTypes.customerUpdateUpsellMetricsInfo:
    case CustomerActionTypes.customerDeleteCustomer:
    case CustomerActionTypes.customerUpdateCustomer:
    case CustomerActionTypes.customerEditCustomer:
    case CustomerActionTypes.customerSearchCustomers:
    case CustomerActionTypes.customerGetCustomerById:
      return {
        ...state,
        loading: true
      };

    case CustomerActionTypes.customerGetCustomersSuccess:
      return adapter.setAll(action.payload.content, {
        ...state,
        loading: false,
        loaded: true,
        paging: _.omit(action.payload, ['content'])
      });

    case CustomerActionTypes.customerGetCustomerByIdSuccess:
      return {
        ...state,
        selectedCustomerId: action?.payload?.id,
        selectedUniqueId: action?.payload?.uniqueIdentifier,
        selectedCustomer: action.payload,
        loading: false
      };

    case CustomerActionTypes.customerAddCustomerSuccess:
      return adapter.addOne(action.payload, {
        ...state,
        selectedCustomerId: action?.payload?.id,
        loading: false,
        loaded: true
      });

    case CustomerActionTypes.customerAddCustomerGeneralInfoSuccess:
      return adapter.addOne(action.payload, {
        ...state,
        customerGeneralInfoId: action.payload.id,
        loading: false,
        loaded: true
    });

    case CustomerActionTypes.customerAddLivingStandardInfoSuccess:
      return adapter.addOne(action.payload, {
        ...state,
        customerLivingStandardInfoId: action.payload.id,
        loading: false,
        loaded: true
    });

    case CustomerActionTypes.customerAddUpsellMetricsInfoSuccess:
      return adapter.addOne(action.payload, {
        ...state,
        customerUpsellMetricsInfoId: action.payload.id,
        loading: false,
        loaded: true
    });

    case CustomerActionTypes.customerUpdateCustomerGeneralInfoSuccess: {
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state,
          loading: false,
          loaded: true
        }
      );
    }

    case CustomerActionTypes.customerUpdateLivingStandardInfoSuccess: {
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state,
          loading: false,
          loaded: true
        }
      );
    }

    case CustomerActionTypes.customerUpdateUpsellMetricsInfoSuccess: {
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state,
          loading: false,
          loaded: true
        }
      );
    }

    case CustomerActionTypes.customerUpdateCustomerSuccess: {
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state,
          loading: false,
          loaded: true
        }
      );
    }

    case CustomerActionTypes.customerEditCustomerSuccess: {
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state,
          loading: false,
          loaded: true
        }
      );
    }

    case CustomerActionTypes.customerDeleteCustomerSuccess: {
      return adapter.removeOne(action.payload.id, {
        ...state,
        loading: false,
        loaded: true
      });
    }

    case CustomerActionTypes.customerSearchCustomersSuccess:
      return adapter.setAll(action.payload, {
        ...state,
        searchCustomers: action.payload,
        loading: false,
        loaded: true
      });

    case CustomerActionTypes.customerSearchCustomersReset:
      return {
        ...state,
        searchCustomers: null
      };

    case CustomerActionTypes.customerSelectedCustomersReset:
      return {
        ...state,
        selectedCustomerId: null,
        selectedCustomer: null
      };

    case CustomerActionTypes.customerError:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload
      };
    case CustomerActionTypes.customerReset:
      return adapter.removeAll({
        ...state,
        paging: null
      })
    case CustomerActionTypes.updateCustomerDigitalContractUrl:
      return {
          ...state,
          selectedCustomer: action.payload
      }

    default:
      return state;
  }
}

export const customerEntitySelectors = adapter.getSelectors();
