import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {StockingPointUser} from '../../../../models/core-inventory/stocking-point-user/stocking-point-user';
import {
  StockingPointUserActions,
  StockingPointUserActionTypes
} from '../../../actions/core-inventory/stocking-point-user/stocking-point-user.actions';
import * as _ from 'lodash';

export interface State extends EntityState<StockingPointUser> {
  error: any;
  loaded: boolean;
  loading: boolean;
  selectedUserId: number;
  paging: any;
}

export const adapter: EntityAdapter<StockingPointUser> = createEntityAdapter<StockingPointUser>();

export const initialState: State = adapter.getInitialState({
  error: null,
  loaded: false,
  loading: false,
  selectedUserId: null,
  paging: null
});

export function reducer(state = initialState, action: StockingPointUserActions): State {
  switch (action.type) {
    case StockingPointUserActionTypes.stockingPointUserGetByUser:
    case StockingPointUserActionTypes.stockingPointUserGetAll:
    case StockingPointUserActionTypes.stockingPointUserActivate:
    case StockingPointUserActionTypes.stockingPointUserDeactivate:
    case StockingPointUserActionTypes.stockingPointUserSave:
      return {
        ...state,
        loading: true,
        loaded: false,
        selectedUserId: null
      };

    case StockingPointUserActionTypes.stockingPointUserError:
      return adapter.addOne(action.payload, {
        ...state,
        error: action.payload.id,
        loading: false,
        loaded: true
      });

    case StockingPointUserActionTypes.stockingPointUserGetByUserSuccess:
      return adapter.addOne(action.payload, {
        ...state,
        loading: false,
        loaded: true,
        selectedUserId: action.payload ? action.payload.id : null
      });
    case StockingPointUserActionTypes.stockingPointUserGetAllSuccess:
      return adapter.setAll(action.payload.content, {
        ...state,
        loading: false,
        loaded: true,
        selectedUserId: null,
        paging: _.omit(action.payload, ['content'])
      });
    case StockingPointUserActionTypes.stockingPointUserActivateSuccess:
    case StockingPointUserActionTypes.stockingPointUserDeactivateSuccess:
      return adapter.updateOne({
        id: action.payload.id,
        changes: action.payload
      }, {
        ...state,
        loading: false,
        loaded: true,
        selectedUserId: action.payload ? action.payload.id : null
      });
    default:
      return state;
  }
}

export const StockingPointUserEntitySelectors = adapter.getSelectors();

