import { KycCustomerDetailComponent } from "./kyc-shared-modal/left-modal-components/kyc-customer-detail.component";
import { NgModule } from "@angular/core";
import { FlexmonsterPivotModule } from "ngx-flexmonster";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";

import {
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
} from "ng-pick-datetime-ex";
import { TranslateModule } from "@ngx-translate/core";
import { AutocompleteLibModule } from "angular-ng-autocomplete";
import { KYCSharedModalComponent } from "./kyc-shared-modal/kyc-shared-modal.component";
import { ExternalPosComponent } from "./kyc-shared-modal/left-modal-components/external-pos.component";
import { CRMKycLeftModalHeaderComponent } from "./kyc-shared-modal/left-modal-components/left-modal-header.component";
import { LeftModalFooterComponent } from "./kyc-shared-modal/left-modal-components/left-modal-footer.component";
import { SharedComponentModule } from "@appContainers/shared-component/shared-component.module";
import { KycAdditionalCommentComponent } from "./kyc-shared-modal/left-modal-components/additional-comment.component";
import { NKycImageAndAttachmentsComponent } from "./kyc-shared-modal/left-modal-components/kyc-image-and-attachments.component";
import { CookStoveSurveyQuestionComponent } from "./kyc-shared-modal/left-modal-components/cook-stove-survey-question.component";
import { KycProductDetailComponent } from "./kyc-shared-modal/left-modal-components/kyc-product-detail.component";
import { KycSpeDetailComponent } from "./kyc-shared-modal/left-modal-components/spe-detail.component";
import { KycTopAlertsComponent } from "./kyc-shared-modal/left-modal-components/kyc-top-alerts.component";
import { PopoverModule } from "ngx-bootstrap/popover";
import { ConfirmationPopoverModule } from "angular-confirmation-popover";
import { RightModalPageComponent } from "./kyc-shared-modal/right-modal-components/right-modal-page.component";
import { CallScriptContainerComponent } from "./kyc-shared-modal/right-modal-components/call-script-container/call-script-container.component";
import { leadSmileIdProductComponent } from "./kyc-shared-modal/left-modal-components/lead-smileId-product.component";
import { MpesaStatementSummaryComponent } from './kyc-shared-modal/left-modal-components/mpesa-statement-summary.component'
import { CrbChecksComponent } from "./kyc-shared-modal/crb-checks/crb-checks.component";

@NgModule({
  declarations: [
    KYCSharedModalComponent,
    ExternalPosComponent,
    CRMKycLeftModalHeaderComponent,
    LeftModalFooterComponent,
    KycAdditionalCommentComponent,
    NKycImageAndAttachmentsComponent,
    CookStoveSurveyQuestionComponent,
    KycProductDetailComponent,
    KycCustomerDetailComponent,
    KycSpeDetailComponent,
    KycTopAlertsComponent,
    RightModalPageComponent,
    CallScriptContainerComponent,
    leadSmileIdProductComponent,
    MpesaStatementSummaryComponent,
    CrbChecksComponent
  ],
  imports: [
    FlexmonsterPivotModule,
    FormsModule,
    RouterModule,
    CommonModule,
    NgxDatatableModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    TranslateModule,
    AutocompleteLibModule,
    SharedComponentModule,
    PopoverModule.forRoot(),
    ConfirmationPopoverModule.forRoot({
      confirmButtonType: "danger",
    }),
  ],
  exports: [KYCSharedModalComponent, LeftModalFooterComponent],
})
export class KYCSharedComponentModule {}
