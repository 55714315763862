import { Action } from '@ngrx/store';
import {
  EntityAdapter,
  createEntityAdapter,
  EntityState,
  Update
} from '@ngrx/entity';

import { CustomerStatus } from '@appModels/core-crm/customer-status/customer-status';
import { CustomerStatusActions, CustomerStatusActionTypes } from '@appStore/actions/core-crm/customer-status/customer-status.actions';

export interface State extends EntityState<CustomerStatus> {
  loaded: boolean;
  loading: boolean;
  error: any;
  selectedCustomerStatusId: number;
  searchCustomerStatuses: CustomerStatus[];
}

export const adapter: EntityAdapter<CustomerStatus> = createEntityAdapter<CustomerStatus>();

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
  selectedCustomerStatusId: null,
  error: null,
  searchTerm: '',
  searchCustomerStatuses: null
});

export function reducer(state = initialState, action: CustomerStatusActions): State {
  switch (action.type) {
    case CustomerStatusActionTypes.customerStatusGetCustomerStatuses:
    case CustomerStatusActionTypes.customerStatusAddCustomerStatus:
    case CustomerStatusActionTypes.customerStatusDeleteCustomerStatus:
    case CustomerStatusActionTypes.customerStatusUpdateCustomerStatus:
    case CustomerStatusActionTypes.customerStatusSearchCustomerStatuses:
    case CustomerStatusActionTypes.customerStatusGetCustomerStatusById:
      return {
        ...state,
        loading: true
      };

    case CustomerStatusActionTypes.customerStatusGetCustomerStatusesSuccess:
      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });

    case CustomerStatusActionTypes.customerStatusGetCustomerStatusByIdSuccess:
      return { ...state, selectedCustomerStatusId: action.payload.id, loading: false };

    case CustomerStatusActionTypes.customerStatusAddCustomerStatusSuccess:
      return adapter.addOne(action.payload, {
        ...state,
        selectedCustomerStatusId: action.payload.id,
        loading: false,
        loaded: true
      });

    case CustomerStatusActionTypes.customerStatusUpdateCustomerStatusSuccess: {
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state,
          loading: false,
          loaded: true
        }
      );
    }

    case CustomerStatusActionTypes.customerStatusDeleteCustomerStatusSuccess: {
      return adapter.removeOne(action.payload.id, {
        ...state,
        loading: false,
        loaded: true
      });
    }

    case CustomerStatusActionTypes.customerStatusSearchCustomerStatusesSuccess:
        return adapter.setAll(action.payload, {
          ...state,
          searchCustomerStatuses: action.payload,
          loading: false,
          loaded: true
        });

    case CustomerStatusActionTypes.customerStatusSearchCustomerStatusesReset:
      return {
        ...state,
        searchCustomerStatuses: null
      };

    case CustomerStatusActionTypes.customerStatusError:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload
      };

    default:
      return state;
  }
}

export const customerStatusEntitySelectors = adapter.getSelectors();
