import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import {catchError, map, mergeMap} from 'rxjs/operators';
import {of} from 'rxjs';

import {
  CommissionReportActionTypes,
  CommissionsReportError, FilterCommissionMonhtlyReport, FilterCommissionMonthlyReportSuccess,
  FilterCommissionReport,
  FilterCommissionReportSuccess,
  GetCommissionReportById,
  GetCommissionReportByIdSuccess,
  SearchCommissionsReport,
  SearchCommissionsReportSuccess
} from '@appStore/actions/core-fieldforce/commission-report/commission-report.actions';
import {ToastrService} from 'ngx-toastr';
import {CommissionReportService} from '../../../../services/core-incentivemanagement/commission-report/commission-report.service';

@Injectable()
export class CommissionReportEffects {


  
  getCommissionReportById$ = createEffect(() => this.actions$.pipe(
    ofType(CommissionReportActionTypes.commissionReportGetCommissionById),
    mergeMap((action: GetCommissionReportById) =>
      this.commissionReportService
        .getcommission(action.payload)
        .pipe(
          map(stockingPoint => new GetCommissionReportByIdSuccess(stockingPoint)),
          catchError(error => of(new CommissionsReportError(error)))
        )
    )
  ));

  
  filterCommissionReports$ = createEffect(() => this.actions$.pipe(
    ofType(CommissionReportActionTypes.commissionReportFilterCommission),
    mergeMap((action: FilterCommissionReport) =>
      this.commissionReportService
        .filterCommissions(action.payload)
        .pipe(
          map(customers => new FilterCommissionReportSuccess(customers)),
          catchError(error => of(new CommissionsReportError(error)))
        )
    )
  ));

  
  filterMonthlyCommissionReports$ = createEffect(() => this.actions$.pipe(
    ofType(CommissionReportActionTypes.commissionReportMonthlyFilterCommission),
    mergeMap((action: FilterCommissionMonhtlyReport) =>
      this.commissionReportService
        .filterMonthlyCommissions(action.payload)
        .pipe(
          map(customers => new FilterCommissionMonthlyReportSuccess(customers)),
          catchError(error => of(new CommissionsReportError(error)))
        )
    )
  ));

  
  searchCommissions$ = createEffect(() => this.actions$.pipe(
    ofType(CommissionReportActionTypes.commissionReportSearchCommissions),
    mergeMap((action: SearchCommissionsReport) =>
      this.commissionReportService
        .searchcommissionbytenantid(Number(action.payload))
        .pipe(
          map(stockingPoints => new SearchCommissionsReportSuccess(stockingPoints)),
          catchError(error => of(new CommissionsReportError(error)))
        )
    )
  ));

  constructor(
    private actions$: Actions,
    private commissionReportService: CommissionReportService,
    private toastr: ToastrService
  ) {
  }
}
