import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';


import {CoreFieldforceService} from "@appServices/core-fieldforce/core-fieldforce.service";
import {CoreAftersalesService} from "../core-aftersales.service";
import {Testflow} from "../../../models/core-aftersales/testflow/testflow";


@Injectable({providedIn: 'root'})
export class TestFlowService extends CoreAftersalesService {


  constructor(
    private http: HttpClient,
  ) {
    super(http, 'testflow');
  }


  getTestFlowById(id: number): Observable<Testflow> {
    const url = `${this.base_url}/${id}`;
    return this.http.get<Testflow>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched salesStaffDefinition id=${id}`)),
      catchError(this.handleError<Testflow>(`getTestflowDefinition id=${id}`))
    );
  }


  getTestflowtNo404<Data>(id: number): Observable<Testflow> {
    const url = `${this.base_url}/?id=${id}`;
    return this.http.get<Testflow[]>(url).pipe(
      map(salesStaff => salesStaff[0]), // returns a {0|1} element array
      tap(h => {
        const outcome = h ? `fetched` : `did not find`;
        this.log(`${outcome} salesStaff id=${id}`);
      }),
      catchError(this.handleError<Testflow>(`getStockingPoint id=${id}`))
    );
  }


  filterTestflow(query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        if (query[val] !== null && query[val] !== undefined && query[val] !== '')
          params = params.append(val, query[val]);
      });
    }

    console.log("filtering test flows");

    params = params.append('tenantId', localStorage.getItem('tenant'));
    return this.http.get<any>(this.base_url, {params, headers: this.headers}).pipe(
      tap(customers => this.log(`fetched customers`))
      ,
      catchError(this.handleError<Testflow[]>('getTestflow')
      )
    );
  }


  /** GET sales staff definitions by id. Will 404 if id not found */
  getTestflow(id: number): Observable<Testflow> {
    const url = `${this.base_url}/${id}`;
    return this.http.get<Testflow>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched salesStaff id=${id}`)),
      catchError(this.handleError<Testflow>(`getTestflow id=${id}`))
    );
  }

  getTestflowByProduct(id: number): Observable<Testflow> {
    console.log("getting test flow product id " + id);
    const url = `${this.base_url}/globalproduct/${id}`;
    return this.http.get<Testflow>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched salesStaff id=${id}`)),
      catchError(this.handleError<Testflow>(`getTestflow id=${id}`))
    );
  }


  addTestflow(entity: Testflow): Observable<Testflow> {
    return this.http.post<Testflow>(this.base_url, entity, {headers: this.headers}).pipe(
      tap(),
      catchError(this.handleError<Testflow>('addTestflowDefinition'))
    );
  }


  deleteTestflow(salesStaff: Testflow | number): Observable<Testflow> {
    const id = typeof salesStaff === 'number' ? salesStaff : salesStaff.id;
    const url = `${this.base_url}/salesStaff/${id}`;

    return this.http.delete<Testflow>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`deleted salesStaff id=${id}`)),
      catchError(this.handleError<Testflow>('deleteTestflow'))
    );
  }

  updateTestflow(salesStaff: Testflow): Observable<any> {
    return this.http.put(`${this.base_url}/${salesStaff.id}`, salesStaff, {headers: this.headers}).pipe(
      tap(_ => this.log(`updated testflow id=${salesStaff.id}`)),
      catchError(this.handleError<any>('updateTestflow'))
    );
  }


}
