import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getTenantProductWarrantyStore = createFeatureSelector('tenantProductWarranty');

export const getTenantProductWarrantyEntities = createSelector(
  getTenantProductWarrantyStore,
  fromReducers.tenantProductWarranty.tenantProductWarrantyEntitySelectors.selectAll
);

export const getTenantProductWarranties = createSelector(getTenantProductWarrantyEntities, entities => {
  return Object.values(entities);
});

export const getTopTenantProductWarranties = createSelector(getTenantProductWarrantyEntities, entities => {
  return Object.values(entities).slice(1, 5);
});

export const getTenantProductWarrantiesLoaded = createSelector(
  getTenantProductWarrantyStore,
  (productStore: fromReducers.tenantProductWarranty.State) => productStore.loaded
);

export const getTenantProductWarrantiesLoading = createSelector(
  getTenantProductWarrantyStore,
  (productStore: fromReducers.tenantProductWarranty.State) => productStore.loading
);

export const getSelectedTenantProductWarrantyId = createSelector(
  getTenantProductWarrantyStore,
  (productStore: fromReducers.tenantProductWarranty.State) => productStore.selectedTenantProductWarrantyId
);

export const getSearchTenantProductWarranties = createSelector(
  getTenantProductWarrantyStore,
  (productStore: fromReducers.tenantProductWarranty.State) => productStore.searchTenantProductWarranties
);

export const getTenantProductWarrantyById = createSelector(
  getTenantProductWarrantyEntities,
  getSelectedTenantProductWarrantyId,
  (entities, id) => entities.find(i => i.id === id)
);

export const getTenantProductWarrantiesError = createSelector(
  getTenantProductWarrantyStore,
  (productStore: fromReducers.tenantProductWarranty.State) => productStore.error
);
