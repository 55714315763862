import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getTenantCustomFieldStore = createFeatureSelector('tenantCustomField');

export const getTenantCustomFieldEntities = createSelector(
  getTenantCustomFieldStore,
  fromReducers.tenantCustomField.tenantCustomFieldEntitySelectors.selectAll
);

export const getTenantCustomFields = createSelector(getTenantCustomFieldEntities, entities => {
  return Object.values(entities);
});

export const getTopTenantCustomFields = createSelector(getTenantCustomFieldEntities, entities => {
  return Object.values(entities).slice(1, 5);
});

export const getTenantCustomFieldsLoaded = createSelector(
  getTenantCustomFieldStore,
  (tenantCustomFieldStore: fromReducers.tenantCustomField.State) => tenantCustomFieldStore.loaded
);

export const getTenantCustomFieldsLoading = createSelector(
  getTenantCustomFieldStore,
  (tenantCustomFieldStore: fromReducers.tenantCustomField.State) => tenantCustomFieldStore.loading
);

export const getSelectedTenantCustomFieldId = createSelector(
  getTenantCustomFieldStore,
  (tenantCustomFieldStore: fromReducers.tenantCustomField.State) => tenantCustomFieldStore.selectedTenantCustomFieldId
);

export const getSearchTenantCustomFields = createSelector(
  getTenantCustomFieldStore,
  (tenantCustomFieldStore: fromReducers.tenantCustomField.State) => tenantCustomFieldStore.searchTenantCustomFields
);

export const getTenantCustomFieldById = createSelector(
  getTenantCustomFieldEntities,
  getSelectedTenantCustomFieldId,
  (entities, id) => entities.find(i => i.id === id)
);

export const getTenantCustomFieldsError = createSelector(
  getTenantCustomFieldStore,
  (tenantCustomFieldStore: fromReducers.tenantCustomField.State) => tenantCustomFieldStore.error
);
