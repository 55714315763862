import {ChangeDetectorRef, Component, TemplateRef, ViewChild} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';

import {ToastrService} from "ngx-toastr";
import * as moment from "moment/moment";

import {CallToActionRuleService} from "@appServices/core-ticketing/call-to-action-rule-service";
import {CallToActionMappingListRow} from "@appModels/ticketing/call-to-action-mapping-list-row";

@Component({
  moduleId: module.id,
  templateUrl: './call-to-action-rules-table-component.html',
  animations: [
    trigger('toggleAnimation', [
      transition(':enter', [style({ opacity: 0, transform: 'scale(0.95)' }), animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' }))]),
      transition(':leave', [animate('75ms', style({ opacity: 0, transform: 'scale(0.95)' }))]),
    ]),
  ],
  styleUrls: ['./call-to-action-rules-table-component.css', '../../../tailwind.css']
})
export class CallToActionRulesTableComponent {
  constructor(
    private toastrService: ToastrService,
    private ref: ChangeDetectorRef,
    private callToActionService: CallToActionRuleService
  ) {
    this.getMappings();
  }

  loadingTable = true;
  dateFormat = moment().format('YYYY-MM-DD hh:mm:ss');
  moment: any = moment;

  getMappings() {
    this.callToActionService.getListRow(true).subscribe(x=> {
      this.maps = x;
      this.loadingTable = false;
    })
  }

  maps : CallToActionMappingListRow[] = [];

}
