import {Action} from '@ngrx/store';
import {RepairCentreType} from '@appModels/core-aftersales/repaircentretype';

export enum RepairCentreTypesActionTypes {
  repairCentreTypeGetAll = '[Repair Centre Type] Get All',
  repairCentreTypeGetAllSuccess = '[Repair Centre Type] Get All Success',
  repairCentreTypeError = '[Repair Centre Type] Error',
  repairCentreTypeSave = '[Repair Centre Type] Save',
  repairCentreTypeSaveSuccess = '[Repair Centre Type] Save Success',
}

export class GetAllRepairCentreTypes implements Action {
  readonly type = RepairCentreTypesActionTypes.repairCentreTypeGetAll;

  constructor() {
  }
}

export class SaveRepairCentreTypes implements Action {
  readonly type = RepairCentreTypesActionTypes.repairCentreTypeSave;

  constructor(public payload: RepairCentreType[]) {
  }
}

export class SaveRepairCentreTypesSuccess implements Action {
  readonly type = RepairCentreTypesActionTypes.repairCentreTypeSaveSuccess;

  constructor(public payload: RepairCentreType[]) {
  }
}


export class GetAllRepairCentreTypeSuccess implements Action {
  readonly type = RepairCentreTypesActionTypes.repairCentreTypeGetAllSuccess;

  constructor(public payload: RepairCentreType[]) {
  }
}

export class RepairCentreTypeError implements Action {
  readonly type = RepairCentreTypesActionTypes.repairCentreTypeError;

  constructor(public payload: any) {
  }
}

export type RepairCentreTypesActions =
  | RepairCentreTypeError
  | GetAllRepairCentreTypes
  | GetAllRepairCentreTypeSuccess
  | SaveRepairCentreTypes
  | SaveRepairCentreTypesSuccess;




