import {Action} from '@ngrx/store';

import {Language} from '@appModels/core-setup/language/language';

export enum LanguageActionTypes {
  languageGetLanguages = '[Language] get language',
  languageGetLanguagesSuccess = '[Language] get languages success',
  languageAddLanguage = '[Language] add Language',
  languageAddLanguageSuccess = '[Language] add language success',
  languageDeleteLanguage = '[Language] delete language',
  languageDeleteLanguageSuccess = '[Language] delete language success',
  languageGetLanguageById = '[Language] get language by id',
  languageGetLanguageByIdSuccess = '[Language] get language by id success',
  languageUpdateLanguage = '[Language] update language',
  languageUpdateLanguageSuccess = '[Language] update language success',
  languageSearchLanguages = '[Language] search languages',
  languageSearchLanguagesSuccess = '[Language] search languages success',
  languageSearchLanguagesReset = '[Language] search languages reset',
  languageError = '[Language] error'
}

export class GetLanguages implements Action {
  readonly type = LanguageActionTypes.languageGetLanguages;
}

export class GetLanguagesSuccess implements Action {
  readonly type = LanguageActionTypes.languageGetLanguagesSuccess;
  constructor(public payload: Language[]) {}
}

export class AddLanguage implements Action {
  readonly type = LanguageActionTypes.languageAddLanguage;
  constructor(public payload: Language) {}
}

export class AddLanguageSuccess implements Action {
  readonly type = LanguageActionTypes.languageAddLanguageSuccess;
  constructor(public payload: Language) {}
}

export class GetLanguageById implements Action {
  readonly type = LanguageActionTypes.languageGetLanguageById;
  constructor(public payload: number) {}
}

export class GetLanguageByIdSuccess implements Action {
  readonly type = LanguageActionTypes.languageGetLanguageByIdSuccess;
  constructor(public payload: Language) {}
}

export class UpdateLanguage implements Action {
  readonly type = LanguageActionTypes.languageUpdateLanguage;
  constructor(public payload: Language) {}
}

export class UpdateLanguageSuccess implements Action {
  readonly type = LanguageActionTypes.languageUpdateLanguageSuccess;
  constructor(public payload: Language) {}
}

export class DeleteLanguage implements Action {
  readonly type = LanguageActionTypes.languageDeleteLanguage;
  constructor(public payload: Language) {}
}

export class DeleteLanguageSuccess implements Action {
  readonly type = LanguageActionTypes.languageDeleteLanguageSuccess;
  constructor(public payload: Language) {}
}

export class SearchLanguages implements Action {
  readonly type = LanguageActionTypes.languageSearchLanguages;
  constructor(public payload: string) {}
}

export class SearchLanguagesSuccess implements Action {
  readonly type = LanguageActionTypes.languageSearchLanguagesSuccess;
  constructor(public payload: Language[]) {}
}

export class SearchLanguagesReset implements Action {
  readonly type = LanguageActionTypes.languageSearchLanguagesReset;
}

export class LanguageError implements Action {
  readonly type = LanguageActionTypes.languageError;
  constructor(public payload: any) {}
}

export type LanguageActions =
  | GetLanguages
  | GetLanguagesSuccess
  | AddLanguage
  | AddLanguageSuccess
  | GetLanguageById
  | GetLanguageByIdSuccess
  | UpdateLanguage
  | UpdateLanguageSuccess
  | DeleteLanguage
  | DeleteLanguageSuccess
  | SearchLanguages
  | SearchLanguagesSuccess
  | SearchLanguagesReset
  | LanguageError;
