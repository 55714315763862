import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getProductPartPriceStore = createFeatureSelector('productPartPrice');

export const getProductPartPriceEntities = createSelector(
  getProductPartPriceStore,
  fromReducers.productPartPrice.productPricePartEntitySelectors.selectAll
);

export const getProductPrices = createSelector(getProductPartPriceEntities, entities => {
  return Object.values(entities);
});

export const getProductPartPriceLoading = createSelector(
  getProductPartPriceStore,
  (productPartPriceStore: fromReducers.productPartPrice.State) => productPartPriceStore.loading
);

export const getProductPartPricePaging = createSelector(
  getProductPartPriceStore,
  (productPartPriceStore: fromReducers.productPartPrice.State) => productPartPriceStore.paging
);
