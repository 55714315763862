import {createEntityAdapter, EntityAdapter, EntityState,} from '@ngrx/entity';
import {
  TenantInventoryProductLocationStatusAction,
  TenantInventoryProductLocationStatusActionTypes
} from "../../../actions/core-inventory/inventory-product/tenant-inventory-product-location-status.actions";
import {TenantInventoryProductLocationStatus} from "@appModels/core-inventory/inventory-product/tenant-inventory-product-location-status";

export interface State extends EntityState<TenantInventoryProductLocationStatus> {
  count: number,
  startPage: number,
  numberPerPage: number,
  loaded: boolean;
  loading: boolean;
  error: any;
  selectedTenantInventoryProductLocationId: number;
}

export const adapter: EntityAdapter<TenantInventoryProductLocationStatus> = createEntityAdapter<TenantInventoryProductLocationStatus>({
  selectId: (tenantInventoryProductLocationStatus: TenantInventoryProductLocationStatus) => tenantInventoryProductLocationStatus.inventoryProductId,
});

export const initialState: State = adapter.getInitialState({
  count: null,
  startPage: null,
  numberPerPage: null,
  loaded: false,
  loading: false,
  selectedTenantInventoryProductLocationId: null,
  error: null
});

export function reducer(state = initialState, action: TenantInventoryProductLocationStatusAction): State {
  switch (action.type) {
    case TenantInventoryProductLocationStatusActionTypes.inventoryProductGetTenantInventoryProductLocationStatus:
      return {
        ...state,
        loading: true
      };


    case TenantInventoryProductLocationStatusActionTypes.inventoryProductGetTenantInventoryProductLocationStatusSuccess:
      return adapter.setAll(action.payload.content, {
        ...state,
        loading: false,
        loaded: true,
        count: action.payload.totalElements,
        startPage: action.payload.number,
        numberPerPage: action.payload.size,
      });
    default:
      return state;
  }
}

export const tenantInventoryProductLocationStatusSelectors = adapter.getSelectors();
