import {createEntityAdapter, EntityAdapter, EntityState,} from '@ngrx/entity';

import {InventoryProduct} from '@appModels/core-inventory/inventory-product/inventory-product';
import {
  InventoryProductActions,
  InventoryProductActionTypes
} from '@appStore/actions/core-inventory/inventory-product/inventory-product.actions';

export interface State extends EntityState<InventoryProduct> {
  count: number,
  startPage: number,
  numberPerPage: number,
  loaded: boolean;
  loading: boolean;
  error: any;
  selectedInventoryProductId: number;
  selectedInventoryProduct: InventoryProduct;
  searchInventoryProducts: InventoryProduct[];
}

export const adapter: EntityAdapter<InventoryProduct> = createEntityAdapter<InventoryProduct>();

export const initialState: State = adapter.getInitialState({
  count: null,
  startPage: null,
  numberPerPage: null,
  loaded: false,
  loading: false,
  selectedInventoryProductId: null,
  selectedInventoryProduct: null,
  error: null,
  searchTerm: '',
  searchInventoryProducts: null
});

export function reducer(state = initialState, action: InventoryProductActions): State {
  switch (action.type) {
    case InventoryProductActionTypes.inventoryProductGetInventoryProducts:
    case InventoryProductActionTypes.inventoryProductAddInventoryProduct:
    case InventoryProductActionTypes.inventoryProductAddAdditionalInventoryProductInfo:
    case InventoryProductActionTypes.inventoryProductDeleteInventoryProduct:
    case InventoryProductActionTypes.inventoryProductUploadInventoryProduct:
    case InventoryProductActionTypes.inventoryProductUploadInventoryProductSuccess:
    case InventoryProductActionTypes.inventoryProductUpdateInventoryProduct:
    case InventoryProductActionTypes.inventoryProductSearchInventoryProducts:
    case InventoryProductActionTypes.inventoryProductGetInventoryProductById:
    case InventoryProductActionTypes.inventoryProductPatchProduct:

      return {
        ...state,
        loading: true
      };

    case InventoryProductActionTypes.inventoryProductGetInventoryProductsSuccess:
      return adapter.setAll(action.payload.content, {
        ...state,
        loading: false,
        loaded: true,
        count: action.payload.totalElements,
        startPage: action.payload.number,
        numberPerPage: action.payload.size,
      });

    case InventoryProductActionTypes.inventoryProductGetInventoryProductByIdSuccess:
      return {
        ...state,
        selectedInventoryProductId: action.payload.id,
        selectedInventoryProduct: action.payload,
        loading: false
      };

    case InventoryProductActionTypes.inventoryProductAddInventoryProductSuccess:
      return adapter.addOne(action.payload, {
        ...state,
        selectedInventoryProductId: action.payload.id,
        loading: false,
        loaded: true
      });

    case InventoryProductActionTypes.inventoryProductUpdateInventoryProductSuccess: {
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state,
          loading: false,
          loaded: true
        }
      );
    }
    case InventoryProductActionTypes.inventoryProductPatchProductSuccess: {
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state,
          loading: false,
          loaded: true
        }
      );
    }

    case InventoryProductActionTypes.inventoryProductDeleteInventoryProductSuccess: {
      return adapter.removeOne(action.payload.id, {
        ...state,
        loading: false,
        loaded: true
      });
    }

    case InventoryProductActionTypes.inventoryProductSearchInventoryProductsSuccess:
      return {
        ...state,
        searchInventoryProducts: action.payload,
        loading: false
      };

    case InventoryProductActionTypes.inventoryProductSearchInventoryProductsReset:
      return {
        ...state,
        searchInventoryProducts: null
      };

    case InventoryProductActionTypes.inventoryProductError:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload
      };

    default:
      return state;
  }
}

export const inventoryProductEntitySelectors = adapter.getSelectors();
