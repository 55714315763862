import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

import {
  AddAttributes,
  AddSalesStaffDefinition,
  AddSalesStaffDefinitionSuccess,
  DeleteSalesStaffDefinition,
  DeleteSalesStaffDefinitionSuccess,
  GetSalesStaffDefinitionById,
  GetSalesStaffDefinitionByIdSuccess,
  GetSalesStaffDefinitions,
  GetSalesStaffDefinitionsByTenantId,
  GetSalesStaffDefinitionsByTenantIdIdSuccess, GetSalesStaffDefinitionsSuccess,
  SalesStaffDefinitionActionTypes,
  SalesStaffDefinitionsError,
  SearchSalesStaffDefinitions,
  SearchSalesStaffDefinitionsSuccess,
  UpdateSalesStaffDefinition,
  UpdateSalesStaffDefinitionSuccess
} from '@appStore/actions/core-fieldforce/sales-staff-definition/sales-staff-definition.actions';
import {SalesStaffDefinitionService} from '@appServices/core-fieldforce/sales-staff-definition/sales-staff-definition.service';
import {TenantService} from '@appServices/core-masterdata/tenant/tenant.service';

import * as fromRouterActions from '@appStore/actions/router.actions';
import {ToastrService} from 'ngx-toastr';
import {TenantSalesStaffDefinitionService} from "@appServices/core-fieldforce/sales-staff-definition/tenant-sales-staff-definition.service";

@Injectable()
export class SalesStaffDefinitionEffects {
  
  loadSalesStaffDefinitions$ = createEffect(() => this.actions$.pipe(
    ofType(SalesStaffDefinitionActionTypes.salesStaffGetStaffDefinitions),
    mergeMap((action: GetSalesStaffDefinitions) =>
      this.tenantSalesStaffDefinitionService
        .getTenantSalesStaffDefinitions(Number(localStorage.getItem('tenant')), '')
        .pipe(
          map(data => new GetSalesStaffDefinitionsSuccess(data)),
          catchError(error => of(new SalesStaffDefinitionsError(error)))
        )
    )
  ));

  
  loadSalesStaffDefinitionsByTenant$ = createEffect(() => this.actions$.pipe(
    ofType(SalesStaffDefinitionActionTypes.salesStaffGeStaffDefinitionsByTenantId),
    mergeMap((action: GetSalesStaffDefinitionsByTenantId) =>
      this.tenantSalesStaffDefinitionService
        .getTenantSalesStaffDefinitions(action.payload)
        .pipe(
          map(data => new GetSalesStaffDefinitionsByTenantIdIdSuccess(data)),
          catchError(error => of(new SalesStaffDefinitionsError(error)))
        )
    )
  ));
  
  addSalesStaffDefinition = createEffect(() => this.actions$.pipe(
    ofType(SalesStaffDefinitionActionTypes.salesStaffAddStaffDefinition),
    switchMap((action: AddSalesStaffDefinition) =>
      this.tenantSalesStaffDefinitionService
        .addSalesStaffDefinition(action.payload)
        .pipe(
          map(
            stockingPoint => {
              if (stockingPoint !== undefined) {
                this.toastr.success('Sales Staff Hierarchy Level has been successfully added!', 'Successful!');
                return new AddSalesStaffDefinitionSuccess(stockingPoint)
              }
              this.toastr.error('There was an error creating sales staff hierarchy level!', "Unknown error");
              return new SalesStaffDefinitionsError({type: 500, message: 'Internal error'})
            }
          ),
          catchError(error => of(new SalesStaffDefinitionsError(error)))
        )
    )
  ));


  
  getSalesStaffDefinitionById$ = createEffect(() => this.actions$.pipe(
    ofType(SalesStaffDefinitionActionTypes.salesStaffGetStaffDefinitionById),
    mergeMap((action: GetSalesStaffDefinitionById) =>
      this.salesStaffDefinitionService
        .getSalesStaffDefinition(action.payload)
        .pipe(
          map(stockingPoint => new GetSalesStaffDefinitionByIdSuccess(stockingPoint)),
          catchError(error => of(new SalesStaffDefinitionsError(error)))
        )
    )
  ));
  
  addSalesStaffDefinitionAttributes = createEffect(() => this.actions$.pipe(
    ofType(SalesStaffDefinitionActionTypes.salesStaffAddStaffDefinitiontAttributes),
    mergeMap((action: AddAttributes) =>
      this.salesStaffDefinitionService
        .addSalesStaffDefinitionAttributes(action.payload)
        .pipe(
          map(stockingPoint => new GetSalesStaffDefinitions()),
          catchError(error => of(new SalesStaffDefinitionsError(error)))
        )
    )
  ));
  
  updateSalesStaffDefinition = createEffect(() => this.actions$.pipe(
    ofType(SalesStaffDefinitionActionTypes.salesStaffUpdateStaffDefinition),
    mergeMap((action: UpdateSalesStaffDefinition) =>
      this.salesStaffDefinitionService
        .updateSalesStaffDefinition(action.payload)
        .pipe(
          map(stockingPoint => new UpdateSalesStaffDefinitionSuccess(action.payload)),
          catchError(error => of(new SalesStaffDefinitionsError(error)))
        )
    )
  ));
  
  deleteSalesStaffDefinition = createEffect(() => this.actions$.pipe(
    ofType(SalesStaffDefinitionActionTypes.salesStaffDeleteStaffDefinition),
    mergeMap((action: DeleteSalesStaffDefinition) =>
      this.salesStaffDefinitionService
        .deleteSalesStaffDefinition(action.payload)
        .pipe(
          map(() => new DeleteSalesStaffDefinitionSuccess(action.payload)),
          catchError(error => of(new SalesStaffDefinitionsError(error)))
        )
    )
  ));
  
  searchSalesStaffDefinition$ = createEffect(() => this.actions$.pipe(
    ofType(SalesStaffDefinitionActionTypes.salesStaffSearchStaffDefinitions),
    mergeMap((action: SearchSalesStaffDefinitions) =>
      this.salesStaffDefinitionService
        .searchSalesStaffDefinition(action.payload)
        .pipe(
          map(stockingPoints => new SearchSalesStaffDefinitionsSuccess(stockingPoints)),
          catchError(error => of(new SalesStaffDefinitionsError(error)))
        )
    )
  ));
  
  updateSalesStaffDefinitionSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(SalesStaffDefinitionActionTypes.salesStaffUpdateStaffDefinitionSuccess),
    map(_ => new fromRouterActions.Go({path: ['/setup/field-force-definitions']}))
  ));

  constructor(
    private actions$: Actions,
    private salesStaffDefinitionService: SalesStaffDefinitionService,
    private tenantSalesStaffDefinitionService: TenantSalesStaffDefinitionService,
    private tenantService: TenantService,
    private toastr: ToastrService
  ) {
  }
}
