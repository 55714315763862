import { Action } from '@ngrx/store';

import { ModuleAction } from '@appModels/core-setup/module-action/module-action';
import { CustomFieldType } from '@appModels/core-setup/module-action/custom-field-type';

export enum ModuleActionActionTypes {
  moduleActionGetModuleActions = '[ModuleAction] get',
  moduleActionGetModuleActionsSuccess = '[ModuleAction] get moduleActions success',
  moduleActionGetCustomFieldTypeModule = '[CustomFieldTypeModule] get customFieldType',
  moduleActionGetModuleCustomFieldTypeSuccess = '[ModuleAction] get customFieldType success',
  moduleActionAddModuleAction = '[ModuleAction] add ModuleAction',
  moduleActionAddModuleActionSuccess = '[ModuleAction] add moduleAction success',
  moduleActionDeleteModuleAction = '[ModuleAction] delete moduleAction',
  moduleActionDeleteModuleActionSuccess = '[ModuleAction] delete moduleAction success',
  moduleActionGetModuleActionById = '[ModuleAction] get moduleAction by id',
  moduleActionGetModuleActionByIdSuccess = '[ModuleAction] get moduleAction by id success',
  moduleActionUpdateModuleAction = '[ModuleAction] update moduleAction',
  moduleActionUpdateModuleActionSuccess = '[ModuleAction] update moduleAction success',
  moduleActionSearchModuleActions = '[ModuleAction] search moduleActions',
  moduleActionSearchModuleActionsSuccess = '[ModuleAction] search moduleActions success',
  moduleActionSearchModuleActionsReset = '[ModuleAction] search moduleActions reset',
  moduleActionError = '[ModuleAction] error'
}

export class GetModuleActions implements Action {
  readonly type = ModuleActionActionTypes.moduleActionGetModuleActions;
}

export class GetModuleActionsSuccess implements Action {
  readonly type = ModuleActionActionTypes.moduleActionGetModuleActionsSuccess;
  constructor(public payload: ModuleAction[]) {}
}

export class GetCustomFieldTypeModule implements Action {
  readonly type = ModuleActionActionTypes.moduleActionGetCustomFieldTypeModule;
}

export class GetModuleCustomFieldTypeSuccess implements Action {
  readonly type = ModuleActionActionTypes.moduleActionGetModuleCustomFieldTypeSuccess;
  constructor(public payload: CustomFieldType[]) {}
}

export class AddModuleAction implements Action {
  readonly type = ModuleActionActionTypes.moduleActionAddModuleAction;
  constructor(public payload: ModuleAction) {}
}

export class AddModuleActionSuccess implements Action {
  readonly type = ModuleActionActionTypes.moduleActionAddModuleActionSuccess;
  constructor(public payload: ModuleAction) {}
}

export class GetModuleActionById implements Action {
  readonly type = ModuleActionActionTypes.moduleActionGetModuleActionById;
  constructor(public payload: number) {}
}

export class GetModuleActionByIdSuccess implements Action {
  readonly type = ModuleActionActionTypes.moduleActionGetModuleActionByIdSuccess;
  constructor(public payload: ModuleAction) {}
}

export class UpdateModuleAction implements Action {
  readonly type = ModuleActionActionTypes.moduleActionUpdateModuleAction;
  constructor(public payload: ModuleAction) {}
}

export class UpdateModuleActionSuccess implements Action {
  readonly type = ModuleActionActionTypes.moduleActionUpdateModuleActionSuccess;
  constructor(public payload: ModuleAction) {}
}

export class DeleteModuleAction implements Action {
  readonly type = ModuleActionActionTypes.moduleActionDeleteModuleAction;
  constructor(public payload: ModuleAction) {}
}

export class DeleteModuleActionSuccess implements Action {
  readonly type = ModuleActionActionTypes.moduleActionDeleteModuleActionSuccess;
  constructor(public payload: ModuleAction) {}
}

export class SearchModuleActions implements Action {
  readonly type = ModuleActionActionTypes.moduleActionSearchModuleActions;
  constructor(public payload: string) {}
}

export class SearchModuleActionsSuccess implements Action {
  readonly type = ModuleActionActionTypes.moduleActionSearchModuleActionsSuccess;
  constructor(public payload: ModuleAction[]) {}
}

export class SearchModuleActionsReset implements Action {
  readonly type = ModuleActionActionTypes.moduleActionSearchModuleActionsReset;
}

export class ModuleActionError implements Action {
  readonly type = ModuleActionActionTypes.moduleActionError;
  constructor(public payload: any) {}
}

export type ModuleActionActions =
  | GetModuleActions
  | GetModuleActionsSuccess
  | GetCustomFieldTypeModule
  | GetModuleCustomFieldTypeSuccess
  | AddModuleAction
  | AddModuleActionSuccess
  | GetModuleActionById
  | GetModuleActionByIdSuccess
  | UpdateModuleAction
  | UpdateModuleActionSuccess
  | DeleteModuleAction
  | DeleteModuleActionSuccess
  | SearchModuleActions
  | SearchModuleActionsSuccess
  | SearchModuleActionsReset
  | ModuleActionError;
