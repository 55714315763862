import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getStockingPointInventoryProductStore = createFeatureSelector('stockingPointInventoryProduct');

export const getStockingPointInventoryProductEntities = createSelector(
  getStockingPointInventoryProductStore,
  fromReducers.stockingPointInventoryProduct.stockingPointInventoryProductEntitySelectors.selectAll
);

export const getStockingPointInventoryProducts = createSelector(getStockingPointInventoryProductEntities, entities => {
  return Object.values(entities);
});

export const getTopStockingPointInventoryProducts = createSelector(getStockingPointInventoryProductEntities, entities => {
  return Object.values(entities).slice(1, 5);
});

export const getStockingPointInventoryProductsLoaded = createSelector(
  getStockingPointInventoryProductStore,
  (stockingPointInventoryProductStore: fromReducers.stockingPointInventoryProduct.State) => stockingPointInventoryProductStore.loaded
);

export const getStockingPointInventoryProductsLoading = createSelector(
  getStockingPointInventoryProductStore,
  (stockingPointInventoryProductStore: fromReducers.stockingPointInventoryProduct.State) => stockingPointInventoryProductStore.loading
);

export const getSelectedStockingPointInventoryProductId = createSelector(
  getStockingPointInventoryProductStore,
  (stockingPointInventoryProductStore: fromReducers.stockingPointInventoryProduct.State) => stockingPointInventoryProductStore.selectedStockingPointInventoryProductId
);

export const getSearchStockingPointInventoryProducts = createSelector(
  getStockingPointInventoryProductStore,
  (stockingPointInventoryProductStore: fromReducers.stockingPointInventoryProduct.State) => stockingPointInventoryProductStore.searchStockingPointInventoryProducts
);

export const getStockingPointInventoryProductById = createSelector(
  getStockingPointInventoryProductEntities,
  getSelectedStockingPointInventoryProductId,
  (entities, id) => entities.find(i => i.id === id)
);

export const getStockingPointInventoryProductsError = createSelector(
  getStockingPointInventoryProductStore,
  (stockingPointInventoryProductStore: fromReducers.stockingPointInventoryProduct.State) => stockingPointInventoryProductStore.error
);
