import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

import {
  AddCustomer,
  AddCustomerSuccess,
  CustomerActionTypes,
  CustomerError,
  DeleteCustomer,
  DeleteCustomerSuccess,
  EditCustomer,
  GetCustomerById,
  GetCustomerByIdSuccess,
  GetCustomers,
  GetCustomersSuccess,
  SearchCustomers,
  SearchCustomersSuccess,
  UpdateCustomerSuccess,
  AddAdditionalCustomerInfo,
  AddCustomerGeneralInfo,
  AddLivingStandardInfo,
  AddUpsellMetricsInfo,
  UpdateCustomerGeneralInfo,
  UpdateLivingStandardInfo,
  UpdateUpsellMetricsInfo,
  AddLivingStandardInfoSuccess,
  AddUpsellMetricsInfoSuccess,
  AddCustomerGeneralInfoSuccess,
  UpdateLivingStandardInfoSuccess,
  UpdateUpsellMetricsInfoSuccess,
  UpdateCustomerGeneralInfoSuccess,
  AddCustomerPhoto
} from '@appStore/actions/core-crm/customer/customer.actions';
import {CustomerService} from '@appServices/core-crm/customer/customer.service';
import {ToastrService} from 'ngx-toastr';

@Injectable()
export class CustomerEffects {
  constructor(
    private actions$: Actions,
    private customerService: CustomerService,
    private toastr: ToastrService
  ) { }


  loadCustomers$ = createEffect(() => this.actions$.pipe(
    ofType(CustomerActionTypes.customerGetCustomers),
    mergeMap((action: GetCustomers) =>
      this.customerService
        .getCustomers(action.payload)
        .pipe(
          map(customers => new GetCustomersSuccess(customers)),
          catchError(error => of(new CustomerError(error)))
        )
    )
  ));


  getCustomerById$ = createEffect(() => this.actions$.pipe(
    ofType(CustomerActionTypes.customerGetCustomerById),
    mergeMap((action: GetCustomerById) =>
      this.customerService
        .getCustomer(action.payload)
        .pipe(
          map(customer => new GetCustomerByIdSuccess(customer)),
          catchError(error => of(new CustomerError(error)))
        )
    )
  ));

  // @TODO Migrate to graph after full implementation of the backend
  // @Effect()
  // getCustomerById$ = this.actions$.pipe(
  //   ofType(CustomerActionTypes.customerGetCustomerById),
  //   mergeMap((action: GetCustomerById) =>
  //     this.customerService
  //       .getCustomerGraph(action.payload)
  //       .pipe(
  //         map(customer => {
  //           console.log(customer, 'customer')
  //          return  new GetCustomerByIdSuccess(customer.data.customer)
  //         }),
  //         catchError(error => of(new CustomerError(error)))
  //       )
  //   )
  // );


  addCustomer$ = createEffect(() => this.actions$.pipe(
    ofType(CustomerActionTypes.customerAddCustomer),
    switchMap((action: AddCustomer) =>
       this.customerService
        .addCustomer(action.payload)
        .pipe(
          map(
            customer => {
              if (customer !== undefined) {
                if(action.payload.hasRequiredAttachment) {
                  this.toastr.warning('Please attach the required documents' , 'Info');
                } else {
                  this.toastr.success( 'Lead Customer has been successfully registered!', 'Successful!');
                }
                return new AddCustomerSuccess(customer)
              }
              return new CustomerError({ type: 500, message: 'Internal error' })
            }
          ),
          catchError(error => of(new CustomerError(error)))
        )
    )
  ));


  addAdditionalCustomerInfo$ = createEffect(() => this.actions$.pipe(
    ofType(CustomerActionTypes.customerAddAdditionalCustomerInfo),
    switchMap((action: AddCustomer) =>
      this.customerService
        .addCustomerAdditionalInfo(action.payload)
        .pipe(
          map(
            customer => {
              if (customer !== undefined) {
                console.log(action.payload, 'action.payload')
                if (action.payload['customer']['customerStatus']['name'] == 'LEAD' || action.payload['customer']['customerStatus']['name'] == 'HOT_LEAD') {
                  this.toastr.success('Additional Info has been successfully added!' , 'Successful');
                  return new GetCustomers();
                } else {
                  this.toastr.success( 'An escalation has been logged for to effect the update!', 'Successful!');
                }
              }
              return new CustomerError({ type: 500, message: 'Internal error' })
            }
            ),
          catchError(error => of(new CustomerError(error)))
        )
    )
  ));


  addCustomerPhotoInfo$ = createEffect(() => this.actions$.pipe(
    ofType(CustomerActionTypes.customerAddPhotoInfo),
    switchMap((action: AddCustomerPhoto) =>
      this.customerService
        .addCustomerPhoto(action.payload)
        .pipe(
          map(
            customer => {
              if (customer !== undefined) {
                this.toastr.success('Customer Photo has been successfully added!', 'Successful!');
                return new GetCustomers();
              }
              return new CustomerError({ type: 500, message: 'Internal error' });
            }
            ),
          catchError(error => of(new CustomerError(error)))
        )
    )
  ));


  addCustomerGeneralInfo$ = createEffect(() => this.actions$.pipe(
    ofType(CustomerActionTypes.customerAddCustomerGeneralInfo),
    switchMap((action: AddCustomerGeneralInfo) =>
      this.customerService
        .addCustomerGeneralInfo(action.payload)
        .pipe(
          map(
            customer => {
              if (customer !== undefined) {
                this.toastr.success('General Info has been successfully added!', 'Successful!');
                return new AddCustomerGeneralInfoSuccess(action.payload);
              }
              return new CustomerError({ type: 500, message: 'Internal error' });
            }
            ),
          catchError(error => of(new CustomerError(error)))
        )
    )
  ));


  addLivingStandardInfo$ = createEffect(() => this.actions$.pipe(
    ofType(CustomerActionTypes.customerAddLivingStandardInfo),
    switchMap((action: AddLivingStandardInfo) =>
      this.customerService
        .addLivingStandardInfo(action.payload)
        .pipe(
          map(
            customer => {
              if (customer !== undefined) {
                this.toastr.success('Living standard info has been successfully added!', 'Successful!');
                return new AddLivingStandardInfoSuccess(action.payload);
              }
              return new CustomerError({ type: 500, message: 'Internal error' });
            }
            ),
          catchError(error => of(new CustomerError(error)))
        )
    )
  ));


  addUpsellMetricsInfo$ = createEffect(() => this.actions$.pipe(
    ofType(CustomerActionTypes.customerAddUpsellMetricsInfo),
    switchMap((action: AddUpsellMetricsInfo) =>
      this.customerService
        .addUpsellMetricsInfo(action.payload)
        .pipe(
          map(
            customer => {
              if (customer !== undefined) {
                this.toastr.success('Upsell metrics info has been successfully added!', 'Successful!');
                return new AddUpsellMetricsInfoSuccess(action.payload);
              }
              return new CustomerError({ type: 500, message: 'Internal error' });
            }
            ),
          catchError(error => of(new CustomerError(error)))
        )
    )
  ));


  updateCustomer$ = createEffect(() => this.actions$.pipe(
    ofType(CustomerActionTypes.customerUpdateCustomer),
    mergeMap((action: AddCustomer) =>
      this.customerService
        .updateCustomer(action.payload)
        .pipe(
          map(
            customer => {
              if (customer !== undefined) {
                if(action.payload.customerStatus['name'] == 'LEAD') {
                  this.toastr.success('Successfully updated profile!' , 'Successful');
                } else {
                  this.toastr.success( 'An escalation has been logged for to effect the update!', 'Successful!');
                }
                return new UpdateCustomerSuccess(action.payload)
              }
              return new CustomerError({ type: 500, message: 'Internal error' })
            }
            ),
          catchError(error => of(new CustomerError(error)))
        )
    )
  ));


  updateCustomerGeneralInfo$ = createEffect(() => this.actions$.pipe(
    ofType(CustomerActionTypes.customerUpdateCustomerGeneralInfo),
    switchMap((action: AddCustomerGeneralInfo) =>
      this.customerService
        .updateCustomerGeneralInfo(action.payload)
        .pipe(
          map(
            customer => {
              if (customer !== undefined) {
                this.toastr.success('General Info has been successfully updated!', 'Successful!');
                return new UpdateCustomerGeneralInfoSuccess(action.payload);
              }
              return new CustomerError({ type: 500, message: 'Internal error' });
            }
            ),
          catchError(error => of(new CustomerError(error)))
        )
    )
  ));


  updateLivingStandardInfo$ = createEffect(() => this.actions$.pipe(
    ofType(CustomerActionTypes.customerUpdateLivingStandardInfo),
    switchMap((action: AddLivingStandardInfo) =>
      this.customerService
        .updateLivingStandardInfo(action.payload)
        .pipe(
          map(
            customer => {
              if (customer !== undefined) {
                this.toastr.success('Living standard info has been successfully updated!', 'Successful!');
                return new UpdateLivingStandardInfoSuccess(action.payload);
              }
              return new CustomerError({ type: 500, message: 'Internal error' });
            }
            ),
          catchError(error => of(new CustomerError(error)))
        )
    )
  ));


  updateUpsellMetricsInfo$ = createEffect(() => this.actions$.pipe(
    ofType(CustomerActionTypes.customerUpdateUpsellMetricsInfo),
    switchMap((action: AddUpsellMetricsInfo) =>
      this.customerService
        .updateUpsellMetricsInfo(action.payload)
        .pipe(
          map(
            customer => {
              if (customer !== undefined) {
                this.toastr.success('Upsell metrics info has been successfully updated!', 'Successful!');
                return new UpdateUpsellMetricsInfoSuccess(action.payload);
              }
              return new CustomerError({ type: 500, message: 'Internal error' });
            }
            ),
          catchError(error => of(new CustomerError(error)))
        )
    )
  ));


  editCustomer$ = createEffect(() => this.actions$.pipe(
    ofType(CustomerActionTypes.customerEditCustomer),
    mergeMap((action: AddCustomer) =>
      this.customerService
        .editCustomer(action.payload)
        .pipe(
          map(customer => new GetCustomers()),
          catchError(error => of(new CustomerError(error)))
        )
    )
  ));


  deleteCustomer$ = createEffect(() => this.actions$.pipe(
    ofType(CustomerActionTypes.customerDeleteCustomer),
    mergeMap((action: DeleteCustomer) =>
      this.customerService
        .deleteCustomer(action.payload)
        .pipe(
          map(() => new DeleteCustomerSuccess(action.payload)),
          catchError(error => of(new CustomerError(error)))
        )
    )
  ));


  searchCustomers$ = createEffect(() => this.actions$.pipe(
    ofType(CustomerActionTypes.customerSearchCustomers),
    mergeMap((action: SearchCustomers) =>
      this.customerService
        .searchCustomers(action.payload)
        .pipe(
          map(customers => new SearchCustomersSuccess(customers)),
          catchError(error => of(new CustomerError(error)))
        )
    )
  ));

  // @Effect()
  // updateCustomerSuccess$ = this.actions$.pipe(
  //   ofType(CustomerActionTypes.customerUpdateCustomerSuccess),
  //   map(customer => new fromRouterActions.Go({ path: ['/crm/customers'] }))
  // );
}
