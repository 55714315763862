import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { mergeMap, map, catchError, tap, debounceTime, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import {
  ProductAttributeActionTypes,
  GetProductAttributes,
  GetProductAttributesSuccess,
  ProductAttributeError,
  AddProductAttribute,
  AddProductAttributeSuccess,
  DeleteProductAttributeSuccess,
  DeleteProductAttribute,
  GetProductAttributeById,
  GetProductAttributeByIdSuccess,
  UpdateProductAttributeSuccess,
  SearchProductAttributes,
  SearchProductAttributesSuccess,
  EditProductAttributeSuccess
} from '@appStore/actions/core-masterdata/product-attribute/product-attribute.actions';
import { ProductAttributeService } from '@appServices/core-masterdata/product-attribute/product-attribute.service';

import * as fromRouterActions from '@appStore/actions/router.actions';

@Injectable()
export class ProductAttributeEffects {
  constructor(private actions$: Actions, private productAttributeService: ProductAttributeService) {}

  
  loadProductAttributes$ = createEffect(() => this.actions$.pipe(
    ofType(ProductAttributeActionTypes.productAttributeGetProductAttributes),
    mergeMap(() =>
      this.productAttributeService
        .getProductAttributes()
        .pipe(
          map(productAttributes => new GetProductAttributesSuccess(productAttributes)),
          catchError(error => of(new ProductAttributeError(error)))
        )
    )
  ));

  
  getProductAttributeById$ = createEffect(() => this.actions$.pipe(
    ofType(ProductAttributeActionTypes.productAttributeGetProductAttributeById),
    mergeMap((action: GetProductAttributeById) =>
      this.productAttributeService
        .getProductAttribute(action.payload)
        .pipe(
          map(productAttribute => new GetProductAttributeByIdSuccess(productAttribute)),
          catchError(error => of(new ProductAttributeError(error)))
        )
    )
  ));

  
  addProductAttribute$ = createEffect(() => this.actions$.pipe(
    ofType(ProductAttributeActionTypes.productAttributeAddProductAttribute),
    switchMap((action: AddProductAttribute) =>
      this.productAttributeService
        .addProductAttribute(action.payload)
        .pipe(
          map(productAttribute => new AddProductAttributeSuccess(productAttribute)),
          catchError(error => of(new ProductAttributeError(error)))
        )
    )
  ));

  
  updateProductAttribute$ = createEffect(() => this.actions$.pipe(
    ofType(ProductAttributeActionTypes.productAttributeUpdateProductAttribute),
    mergeMap((action: AddProductAttribute) =>
      this.productAttributeService
        .updateProductAttribute(action.payload)
        .pipe(
          map(productAttribute => new UpdateProductAttributeSuccess(action.payload)),
          catchError(error => of(new ProductAttributeError(error)))
        )
    )
  ));

  
  editProductAttribute$ = createEffect(() => this.actions$.pipe(
    ofType(ProductAttributeActionTypes.productAttributeEditProductAttribute),
    mergeMap((action: AddProductAttribute) =>
      this.productAttributeService
        .editProductAttribute(action.payload)
        .pipe(
          map(productAttribute => new GetProductAttributes()),
          catchError(error => of(new ProductAttributeError(error)))
        )
    )
  ));

  
  deleteProductAttribute$ = createEffect(() => this.actions$.pipe(
    ofType(ProductAttributeActionTypes.productAttributeDeleteProductAttribute),
    mergeMap((action: DeleteProductAttribute) =>
      this.productAttributeService
        .deleteProductAttribute(action.payload)
        .pipe(
          map(() => new DeleteProductAttributeSuccess(action.payload)),
          catchError(error => of(new ProductAttributeError(error)))
        )
    )
  ));

  
  searchProductAttributes$ = createEffect(() => this.actions$.pipe(
    ofType(ProductAttributeActionTypes.productAttributeSearchProductAttributes),
    mergeMap((action: SearchProductAttributes) =>
      this.productAttributeService
        .searchProductAttributes(action.payload)
        .pipe(
          map(productAttributes => new SearchProductAttributesSuccess(productAttributes)),
          catchError(error => of(new ProductAttributeError(error)))
        )
    )
  ));

  
  updateProductAttributeSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(ProductAttributeActionTypes.productAttributeUpdateProductAttributeSuccess),
    map(productAttribute => new fromRouterActions.Go({ path: ['/setup/product-attributes'] }))
  ));
}
