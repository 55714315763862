import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {ProductPartPrice} from '../../../../models/core-setup/part-prices/product-part-price';
import {
  ProductPartPriceActions,
  ProductPartPriceActionTypes
} from '../../../actions/core-masterdata/product-part-price/product-part-price.actions';
import * as _ from 'lodash';

export interface State extends EntityState<ProductPartPrice> {
  error: any;
  loaded: boolean;
  loading: boolean;
  paging: any;
}

export const adapter: EntityAdapter<ProductPartPrice> = createEntityAdapter<ProductPartPrice>();

export const initialState: State = adapter.getInitialState({
  error: null,
  loaded: false,
  loading: false,
  paging: null
});

export function reducer(state = initialState, action: ProductPartPriceActions): State {
  switch (action.type) {
    case ProductPartPriceActionTypes.productPriceGetAll:
    case ProductPartPriceActionTypes.productPriceSave:
    case ProductPartPriceActionTypes.productPriceUpdate:
      return {
        ...state,
        loading: true,
        loaded: false
      };

    case ProductPartPriceActionTypes.productPriceError:
      return adapter.addOne(action.payload, {
        ...state,
        error: action.payload.id,
        loading: false,
        loaded: true
      });

    case ProductPartPriceActionTypes.productPriceGetAllSuccess:
      return adapter.setAll(action.payload.content, {
        ...state,
        loading: false,
        loaded: true,
        paging: _.omit(action.payload, ['content'])
      });
    case ProductPartPriceActionTypes.productPriceSaveSuccess:
      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });
    case ProductPartPriceActionTypes.productPriceUpdateSuccess:
      return adapter.setAll(action.payload,
        {
          ...state,
          loaded: true,
          loading: false
        });
    default:
      return state;
  }
}

export const productPricePartEntitySelectors = adapter.getSelectors();

