import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

import {
  AddLanguage,
  AddLanguageSuccess,
  DeleteLanguage,
  DeleteLanguageSuccess,
  GetLanguageById,
  GetLanguageByIdSuccess,
  GetLanguagesSuccess,
  LanguageActionTypes,
  LanguageError,
  SearchLanguages,
  SearchLanguagesSuccess,
  UpdateLanguageSuccess
} from '@appStore/actions/core-masterdata/language/language.actions';
import {LanguageService} from '@appServices/core-masterdata/language/language.service';

import * as fromRouterActions from '@appStore/actions/router.actions';

@Injectable()
export class LanguageEffects {
  constructor(private actions$: Actions, private languageService: LanguageService) {}


  
  loadLanguages$ = createEffect(() => this.actions$.pipe(
    ofType(LanguageActionTypes.languageGetLanguages),
    switchMap(() =>
      this.languageService
        .getLanguages()
        .pipe(
          map(languages => new GetLanguagesSuccess(languages)),
          catchError(error => of(new LanguageError(error)))
        )
    )
  ));

  
  getLanguageById$ = createEffect(() => this.actions$.pipe(
    ofType(LanguageActionTypes.languageGetLanguageById),
    mergeMap((action: GetLanguageById) =>
      this.languageService
        .getLanguage(action.payload)
        .pipe(
          map(language => new GetLanguageByIdSuccess(language)),
          catchError(error => of(new LanguageError(error)))
        )
    )
  ));

  
  addLanguage$ = createEffect(() => this.actions$.pipe(
    ofType(LanguageActionTypes.languageAddLanguage),
    switchMap((action: AddLanguage) => this.languageService
        .addLanguage(action.payload)
        .pipe(
          map(language => new AddLanguageSuccess(language)),
          catchError(error => of(new LanguageError(error)))
        )
    )
  ));

  
  updateLanguage$ = createEffect(() => this.actions$.pipe(
    ofType(LanguageActionTypes.languageUpdateLanguage),
    mergeMap((action: AddLanguage) =>
      this.languageService
        .updateLanguage(action.payload)
        .pipe(
          map(language => new UpdateLanguageSuccess(action.payload)),
          catchError(error => of(new LanguageError(error)))
        )
    )
  ));

  
  deleteLanguage$ = createEffect(() => this.actions$.pipe(
    ofType(LanguageActionTypes.languageDeleteLanguage),
    mergeMap((action: DeleteLanguage) =>
      this.languageService
        .deleteLanguage(action.payload)
        .pipe(
          map(() => new DeleteLanguageSuccess(action.payload)),
          catchError(error => of(new LanguageError(error)))
        )
    )
  ));

  
  searchLanguages$ = createEffect(() => this.actions$.pipe(
    ofType(LanguageActionTypes.languageSearchLanguages),
    mergeMap((action: SearchLanguages) =>
      this.languageService
        .searchLanguages(action.payload)
        .pipe(
          map(languages => new SearchLanguagesSuccess(languages)),
          catchError(error => of(new LanguageError(error)))
        )
    )
  ));

  
  updateLanguageSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(LanguageActionTypes.languageUpdateLanguageSuccess),
    map(language => new fromRouterActions.Go({ path: ['/setup/languages'] }))
  ));

  // @Effect()
  // $init = this.actions$.pipe(
  //   ofType(ROOT_EFFECTS_INIT),
  //   map(() => new GetLanguages())
  // );

}
