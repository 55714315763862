<!-- <div *ngIf="can('can-view-crm-dashboard')" class="container">
  <div class="row">
    <div class="col-sm-4">
      <h3>{{'Today' | translate}} <a href="javascript:" style="font-size:15px"><span aria-hidden="true" class="glyphicon glyphicon-info-sign"></span></a>
      </h3>
      <span>{{ lastLoaded}}T </span>
    </div>

    <div class="col-sm-4">
      <h3>{{'Potential Earning' | translate}} <a href="javascript:" style="font-size:15px"><span aria-hidden="true" class="glyphicon glyphicon-info-sign"></span></a>
      </h3>
      <span>20%</span>
    </div>

    <div class="col-sm-4">
      <h3>{{'Actual Earning' | translate}} <a href="javascript:" style="font-size:15px"><span aria-hidden="true" class="glyphicon glyphicon-info-sign"></span></a>
      </h3>
      <span>30%</span>
    </div>
  </div>
</div> -->

<div class="container">
  <div class="row">
    <div class="col-md-6 col-sm-12 m-t-30">
      <h3>Actual Sales</h3>
      <div>
        <input type="date" class="form-group m-r-10" [(ngModel)]="dateFrom" name="dateFrom" value="2019-01-01 00:00:00" (change)="getSalesCount()">
        <input type="date" class="form-group" [(ngModel)]="dateTo" name="dateTo" value="2021-01-01 00:00:00" (change)="getSalesCount()">
      </div>
      <div *ngIf="salesCount?.length > 0">
        <canvas #actualVsPotentialCharts width="400" height="200"></canvas>
      </div>
      <div *ngIf="salesCount?.length === 0">
        <p class="m-t-20">No result found </p>
      </div>
    </div>
    <div class="col-md-6 col-sm-12 m-t-30">
      <h3>Payment progress of Customers</h3>
      <canvas #customerCharts width="400" height="200"></canvas>
    </div>
  </div>
</div>



<div class="container">
  <div class="row">
    <div class="col-md-12">
      <app-sep-table></app-sep-table>
    </div>
  </div>
</div>

