import {Injectable} from '@angular/core';
import {Actions} from '@ngrx/effects';

import {
  GetStockingPointInventoryProductsByStockingPointId,
  GetStockingPointInventoryProductsByStockingPointIdSuccess,
  StockingPointInventoryProductActionTypes,
  StockingPointInventoryProductError,
} from '@appStore/actions/core-inventory/stocking-point/stocking-point-inventory-product.actions';
import {StockingPointInventoryProductService} from "@appServices/core-inventory/stocking-point/stocking-point-inventory-product.service";


@Injectable()
export class StockingPointInventoryProductsEffects {


  constructor(
    private actions$: Actions,
    private stockingPointInventoryProductService: StockingPointInventoryProductService,
  ) {
  }


}



