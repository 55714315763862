import {Injectable} from '@angular/core';
import {CoreAftersalesService} from '../core-aftersales.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from "rxjs";
import {catchError, tap} from 'rxjs/operators';
import {RepairCentreTechnician} from '../../../models/core-aftersales/repair-centre-technician';

@Injectable({
  providedIn: 'root'
})
export class RepairCentreTechnicianService extends CoreAftersalesService {

  constructor(private http: HttpClient) {
    super(http, 'repaircentretechnician');
  }

  /** GET repair centre technicians from the server */
  getRepairCentreTechnicians(repairCentreId: number): Observable<RepairCentreTechnician[]> {
    return this.http.get<RepairCentreTechnician[]>(this.base_url + '/repaircentre/' + repairCentreId, {headers: this.headers}).pipe(
      tap(types => this.log(`fetched repair centre technicians`)),
      catchError(this.handleError('getRepairCentreTechnicians', []))
    );
  }

  /** GET all repair centre technicians from the server */
  getAllRepairCentreTechnicians(query?): Observable<RepairCentreTechnician[]> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        if (query[val] !== null && query[val] !== undefined && query[val] !== '') {
          params = params.append(val, query[val]);
        }
      });
    }

    return this.http.get<RepairCentreTechnician[]>(this.base_url, {params: params, headers: this.headers}).pipe(
      tap(types => this.log(`fetched repair centre technicians`)),
      catchError(this.handleError('getRepairCentreTechnicians', []))
    );
  }

  /** GET repair centre technicians from the server */
  getRepairCentreTechnicianForUser(userId: number): Observable<RepairCentreTechnician[]> {
    const tenantId = localStorage.getItem('tenant');
    return this.http.get<RepairCentreTechnician[]>(this.base_url + '/tenant/' + tenantId + '/user/' + userId, {headers: this.headers}).pipe(
      tap(types => this.log(`fetched repair centre technician for user ` + userId)),
      catchError(this.handleError('getRepairCentreTechnicianForUser', null))
    );
  }

  /** GET repair centre technicians from the server */
  getRepairCentreTechniciansForTenant(): Observable<RepairCentreTechnician[]> {
    const tenantId = localStorage.getItem('tenant');
    return this.http.get<RepairCentreTechnician[]>(this.base_url + '/tenant/' + tenantId, {headers: this.headers}).pipe(
      tap(types => this.log(`fetched repair centre technician for tenant ` + tenantId)),
      catchError(this.handleError('getRepairCentreTechniciansForTenant', null))
    );
  }

  saveRepairCentreTechnician(technician: RepairCentreTechnician) {
    const userId = technician.user.id;
    const repairCentreId = technician.repairCentre.id;
    return this.http.post(this.base_url, {
      userId: userId,
      repairCentreId: repairCentreId
    }, {headers: this.headers}).pipe(
      tap(types => this.log(`saved repair technnician `)),
      catchError(this.handleError('saveRepairCentreTechnician', null))
    );
  }

  activateRepairCentreTechnician(id: number) {
    return this.http.patch(this.base_url + '/' + id + '/activate', {headers: this.headers}).pipe(
      tap(types => this.log(`activated repair technnician `)),
      catchError(this.handleError('activateRepairCentreTechnician', null))
    );
  }

  deactivateRepairCentreTechnician(id: number) {
    return this.http.patch(this.base_url + '/' + id + '/deactivate', {headers: this.headers}).pipe(
      tap(types => this.log(`deactivated repair technnician `)),
      catchError(this.handleError('deactivateRepairCentreTechnician', null))
    );
  }
}

