import {Action} from '@ngrx/store';

import {Product} from '@appModels/core-setup/product/product';

export enum ProductActionTypes {
  productGetProducts = '[Product] get',
  productGetProductsSuccess = '[Product] get products success',
  productGetProductsByType = '[Product] get products by type',
  productGetProductsByTypeSuccess = '[Product] get products by type success',
  productAddProduct = '[Product] add Product',
  productAddProductSuccess = '[Product] add product success',
  productDeleteProduct = '[Product] delete product',
  productDeleteProductSuccess = '[Product] delete product success',
  productGetProductById = '[Product] get product by id',
  productGetProductByIdSuccess = '[Product] get product by id success',
  productUpdateProduct = '[Product] update product',
  productUpdateProductSuccess = '[Product] update product success',
  productEditProduct = '[Product] edit product',
  productEditProductSuccess = '[Product] edit product success',
  productSearchProducts = '[Product] search products',
  productSearchProductsSuccess = '[Product] search products success',
  productSearchProductsReset = '[Product] search products reset',
  productError = '[Product] error'
}

export class GetProducts implements Action {
  readonly type = ProductActionTypes.productGetProducts;
  constructor(public payload?: any) {}
}

export class GetProductsSuccess implements Action {
  readonly type = ProductActionTypes.productGetProductsSuccess;
  constructor(public payload: any) {}
}

export class GetProductsByType implements Action {
  readonly type = ProductActionTypes.productGetProductsByType;

  constructor(public payload: number) {
  }
}

export class GetProductsByTypeSuccess implements Action {
  readonly type = ProductActionTypes.productGetProductsByTypeSuccess;

  constructor(public payload: Product[]) {
  }
}

export class AddProduct implements Action {
  readonly type = ProductActionTypes.productAddProduct;
  constructor(public payload: Product) {}
}

export class AddProductSuccess implements Action {
  readonly type = ProductActionTypes.productAddProductSuccess;
  constructor(public payload: Product) {}
}

export class GetProductById implements Action {
  readonly type = ProductActionTypes.productGetProductById;
  constructor(public payload: number) {}
}

export class GetProductByIdSuccess implements Action {
  readonly type = ProductActionTypes.productGetProductByIdSuccess;
  constructor(public payload: Product) {}
}

export class UpdateProduct implements Action {
  readonly type = ProductActionTypes.productUpdateProduct;
  constructor(public payload: Product) {}
}

export class UpdateProductSuccess implements Action {
  readonly type = ProductActionTypes.productUpdateProductSuccess;
  constructor(public payload: Product) {}
}

export class EditProduct implements Action {
  readonly type = ProductActionTypes.productEditProduct;
  constructor(public payload) {}
}

export class EditProductSuccess implements Action {
  readonly type = ProductActionTypes.productEditProductSuccess;
  constructor(public payload) {}
}

export class DeleteProduct implements Action {
  readonly type = ProductActionTypes.productDeleteProduct;
  constructor(public payload: Product) {}
}

export class DeleteProductSuccess implements Action {
  readonly type = ProductActionTypes.productDeleteProductSuccess;
  constructor(public payload: Product) {}
}

export class SearchProducts implements Action {
  readonly type = ProductActionTypes.productSearchProducts;
  constructor(public payload: string) {}
}

export class SearchProductsSuccess implements Action {
  readonly type = ProductActionTypes.productSearchProductsSuccess;
  constructor(public payload: Product[]) {}
}

export class SearchProductsReset implements Action {
  readonly type = ProductActionTypes.productSearchProductsReset;
}

export class ProductError implements Action {
  readonly type = ProductActionTypes.productError;
  constructor(public payload: any) {}
}

export type ProductActions =
  | GetProducts
  | GetProductsSuccess
  | GetProductsByType
  | GetProductsByTypeSuccess
  | AddProduct
  | AddProductSuccess
  | GetProductById
  | GetProductByIdSuccess
  | UpdateProduct
  | UpdateProductSuccess
  | EditProduct
  | EditProductSuccess
  | DeleteProduct
  | DeleteProductSuccess
  | SearchProducts
  | SearchProductsSuccess
  | SearchProductsReset
  | ProductError;
