import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

import {
  AddStockingPoint,
  AddStockingPointSuccess,
  DeleteStockingPoint,
  DeleteStockingPointSuccess,
  GetStockingPointById,
  GetStockingPointByIdSuccess,
  GetStockingPointByTenantId,
  GetStockingPointByTenantIdIdSuccess,
  GetStockingPoints,
  SearchStockingPoints,
  SearchStockingPointsSuccess,
  StockingPointActionTypes,
  StockingPointError,
  UpdateStockingPointSuccess
} from '@appStore/actions/core-inventory/stocking-point/stocking-point.actions';
import {StockingPointService} from '@appServices/core-inventory/stocking-point/stocking-point.service';
import {TenantService} from '@appServices/core-masterdata/tenant/tenant.service';

import * as fromRouterActions from '@appStore/actions/router.actions';
import {ToastrService} from 'ngx-toastr';
import {TenantStockingPointService} from "@appServices/core-inventory/stocking-point/tenant-stocking-point.service";

@Injectable()
export class StockingPointEffects {
  
  loadStockingPoints$ = createEffect(() => this.actions$.pipe(
    ofType(StockingPointActionTypes.stockingPointGetStockingPointsByTenantId),
    mergeMap((action: GetStockingPointByTenantId) =>
      this.tenantStockingPointService
        .getTenantStockingPoint(action.payload)
        .pipe(
          map(stockingPoints => new GetStockingPointByTenantIdIdSuccess(stockingPoints)),
          catchError(error => of(new StockingPointError(error)))
        )
    )
  ));
  
  addStockingPoint$ = createEffect(() => this.actions$.pipe(
    ofType(StockingPointActionTypes.stockingPointAddStockingPoint),
    switchMap((action: AddStockingPoint) =>
      this.stockingPointService
        .addStockingPoint(action.payload)
        .pipe(
          map(
            stockingPoint => {
              if (stockingPoint !== undefined) {
                return new AddStockingPointSuccess(stockingPoint)
              }
              this.toastr.error('There was an error creating stocking point!', "Unknown error");
              return new StockingPointError({ type: 500, message: 'Internal error' })
            }
            ),
          catchError(error => of(new StockingPointError(error)))
        )
    )
  ));


  
  addStockingPointSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(StockingPointActionTypes.stockingPointAddStockingPointSuccess),
    switchMap((action: AddStockingPoint) =>
      this.stockingPointService
        .patchTenantStockingPoint(action.payload)
        .pipe(
          map(
            stockingPoint => {
              if (stockingPoint !== undefined) {
                this.toastr.success('Stocking point has been successfully added!', 'Successful!');
                return;
              } else {
                this.toastr.error('There was an error creating stocking point!', "Unknown error");
                return new StockingPointError({type: 500, message: 'Internal error'})
              }
            }
          ),
          catchError(error => of(new StockingPointError(error)))
        )
    )
  ));

  
  getStockingPointById$ = createEffect(() => this.actions$.pipe(
    ofType(StockingPointActionTypes.stockingPointGetStockingPointById),
    mergeMap((action: GetStockingPointById) =>
      this.stockingPointService
        .getStockingPoint(action.payload)
        .pipe(
          map(stockingPoint => new GetStockingPointByIdSuccess(stockingPoint)),
          catchError(error => of(new StockingPointError(error)))
        )
    )
  ));
  
  addAdditionalStockingPointInfo$ = createEffect(() => this.actions$.pipe(
    ofType(StockingPointActionTypes.stockingPointAddAdditionalStockingPointInfo),
    mergeMap((action: AddStockingPoint) =>
      this.stockingPointService
        .addStockingPointAdditionalInfo(action.payload)
        .pipe(
          map(stockingPoint => new GetStockingPoints()),
          catchError(error => of(new StockingPointError(error)))
        )
    )
  ));

  constructor(
    private actions$: Actions,
    private stockingPointService: StockingPointService,
    private tenantStockingPointService: TenantStockingPointService,
    private tenantService: TenantService,
    private toastr: ToastrService
  ) {
  }

  
  updateStockingPoint$ = createEffect(() => this.actions$.pipe(
    ofType(StockingPointActionTypes.stockingPointUpdateStockingPoint),
    mergeMap((action: AddStockingPoint) =>
      this.stockingPointService
        .updateStockingPoint(action.payload)
        .pipe(
          map(stockingPoint => new UpdateStockingPointSuccess(action.payload)),
          catchError(error => of(new StockingPointError(error)))
        )
    )
  ));

  
  deleteStockingPoint$ = createEffect(() => this.actions$.pipe(
    ofType(StockingPointActionTypes.stockingPointDeleteStockingPoint),
    mergeMap((action: DeleteStockingPoint) =>
      this.stockingPointService
        .deleteStockingPoint(action.payload)
        .pipe(
          map(() => new DeleteStockingPointSuccess(action.payload)),
          catchError(error => of(new StockingPointError(error)))
        )
    )
  ));

  
  searchStockingPoints$ = createEffect(() => this.actions$.pipe(
    ofType(StockingPointActionTypes.stockingPointSearchStockingPoints),
    mergeMap((action: SearchStockingPoints) =>
      this.stockingPointService
        .searchStockingPoints(action.payload)
        .pipe(
          map(stockingPoints => new SearchStockingPointsSuccess(stockingPoints)),
          catchError(error => of(new StockingPointError(error)))
        )
    )
  ));

  
  updateStockingPointSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(StockingPointActionTypes.stockingPointUpdateStockingPointSuccess),
    map(stockingPoint => new fromRouterActions.Go({path: ['/setup/stockingPoints']}))
  ));
}
