import {createEntityAdapter, EntityAdapter, EntityState,} from '@ngrx/entity';

import {InventoryProduct} from '@appModels/core-inventory/inventory-product/inventory-product';
import {
  StockingPointInventoryProductActions,
  StockingPointInventoryProductActionTypes
} from '@appStore/actions/core-inventory/stocking-point/stocking-point-inventory-product.actions';

export interface State extends EntityState<InventoryProduct> {
  loaded: boolean;
  loading: boolean;
  error: any;
  selectedStockingPointInventoryProductId: number;
  searchStockingPointInventoryProducts: InventoryProduct[];
}

export const adapter: EntityAdapter<InventoryProduct> = createEntityAdapter<InventoryProduct>();

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
  selectedStockingPointInventoryProductId: null,
  error: null,
  searchTerm: '',
  searchStockingPointInventoryProducts: null
});

export function reducer(state = initialState, action: StockingPointInventoryProductActions): State {
  switch (action.type) {
    case StockingPointInventoryProductActionTypes.stockingPointGetStockingPointInventoryProducts:
      return {
        ...state,
        loading: true
      };

    case StockingPointInventoryProductActionTypes.stockingPointGetStockingPointInventoryProductsSuccess:
      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });
    default:
      return state;
  }
}

export const stockingPointInventoryProductEntitySelectors = adapter.getSelectors();
