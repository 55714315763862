import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap, tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {of} from 'rxjs';

import {AuthService} from '@appServices/core-auth/auth/auth.service';
import {Authentication} from '@appModels/core-auth/auth/auth';
import {AuthActionTypes, Login, LoginFailed, LoginSuccess,} from '@appStore/actions/core-auth/auth/auth.actions';
import { EmailVerificationCheckService } from '@appServices/email-verification/email-verification-check.service';

@Injectable()
export class AuthEffects {
  
  login$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActionTypes.Login),
    map((action: Login) => action.payload),
    mergeMap((auth: Authentication) => this.authService.login(auth)
    .pipe(
        map(res => (new LoginSuccess(res))),
        catchError((errorMessage) => of(new LoginFailed(errorMessage)))
      ))
  ));

  
  // If the user is logged in, let it goes to "Team App"
  loginSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActionTypes.Success),
    tap((action: LoginSuccess) => {

      this.emailVerificationCheckService.changeStatus(!action.payload?.emailVerificationComplete)
      if(action.payload?.tenants_.length === 1 && (action.payload?.tenants_[0]?.roles_[0]?.name === 'Sales Agents' || action.payload?.tenants_[0]?.roles_[0]?.name === 'Solar Energy Promoter' )){
        return this.router.navigateByUrl('/dashboard-sep')
      }
      const redirectUrl = localStorage.getItem('redirectUrl')
      if ( redirectUrl !== null) {
          localStorage.removeItem('redirectUrl');
          return this.router.navigateByUrl(redirectUrl)
      }
      else {
        return this.router.navigateByUrl('/home')
      }


      // let next = localStorage.getItem('nextUrl');
      // if (next !== null) {
      //   localStorage.removeItem('nextUrl');
      //   console.log(window.location, 'next...')
      //   // window.location.href = `${window.location.origin}${next}`;
      //  return this.router.navigate([`/${next}`])
      // } else {
      //   // setTimeout(() => {
      //   //   return window.location.href = '/home'
      //   // }, 1500);
      //   // window.location.href = `${window.location.origin}/home`;
      //   return this.router.navigate([`/home`])

      // }

    })
  ), { dispatch: false });

  
  // Probably the user enter some routes directly, and we require it to login
  // As for permission, we can do the same thing to redirect it to somewhere for requesting the permissions
  loginRedirect$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActionTypes.Required),
    tap(() => {
      this.router.navigate(['/login']);
    })
  ), { dispatch: false });

  constructor(
    private router: Router,
    private actions$: Actions,
    private authService: AuthService,
    private emailVerificationCheckService: EmailVerificationCheckService

  ) {}
}
