import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getCommissionReportStore = createFeatureSelector('commissionReport');

export const getCommissionReportEntities = createSelector(
  getCommissionReportStore,
  fromReducers.commissionReport.commissionReportEntitySelectors.selectAll
);


export const getCommissionsReport = createSelector(getCommissionReportEntities, entities => {
  return Object.values(entities);
});


export const getCommissionsReportLoaded = createSelector(
  getCommissionReportStore,
  (commissionReportStore: fromReducers.commissionReport.State) => commissionReportStore.loaded
);

export const getCommissionsReportLoading = createSelector(
  getCommissionReportStore,
  (commissionReportStore: fromReducers.commissionReport.State) => commissionReportStore.loading
);

export const getSelectedCommissionReportId = createSelector(
  getCommissionReportStore,
  (commissionReportStore: fromReducers.commissionReport.State) => commissionReportStore.selectedCommissionReportId
);

export const getSearchCommissionsReport = createSelector(
  getCommissionReportStore,
  (commissionReportStore: fromReducers.commissionReport.State) => commissionReportStore.searchCommissionsReport
);

export const getCommissionReportById = createSelector(
  getCommissionReportEntities,
  getSelectedCommissionReportId,
  (entities, id) => entities.find(i => i.id === id)
);

export const getCommissionReportError = createSelector(
  getCommissionReportStore,
  (commissionReportStore: fromReducers.commissionReport.State) => commissionReportStore.error
);

export const getCommissionReportPaging = createSelector(
  getCommissionReportStore,
  (commissionReportStore: fromReducers.commissionReport.State) => commissionReportStore.paging
);
