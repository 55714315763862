import { Action } from '@ngrx/store';
import {
  EntityAdapter,
  createEntityAdapter,
  EntityState,
  Update
} from '@ngrx/entity';
import * as _ from 'lodash';

import { IdentificationType } from '@appModels/core-crm/identification-type/identification-type';
import { IdentificationTypeActions, IdentificationTypeActionTypes } from '@appStore/actions/core-crm/identification-type/identification-type.actions';

export interface State extends EntityState<IdentificationType> {
  loaded: boolean;
  loading: boolean;
  error: any;
  selectedIdentificationTypeId: number;
  searchIdentificationTypes: IdentificationType[];
  paging: any;
}

export const adapter: EntityAdapter<IdentificationType> = createEntityAdapter<IdentificationType>();

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
  selectedIdentificationTypeId: null,
  error: null,
  searchTerm: '',
  searchIdentificationTypes: null,
  paging: null
});

export function reducer(state = initialState, action: IdentificationTypeActions): State {
  switch (action.type) {
    case IdentificationTypeActionTypes.identificationTypeGetIdentificationTypes:
    case IdentificationTypeActionTypes.identificationTypeAddIdentificationType:
    case IdentificationTypeActionTypes.identificationTypeDeleteIdentificationType:
    case IdentificationTypeActionTypes.identificationTypeUpdateIdentificationType:
    case IdentificationTypeActionTypes.identificationTypeSearchIdentificationTypes:
    case IdentificationTypeActionTypes.identificationTypeGetIdentificationTypeById:
      return {
        ...state,
        loading: true
      };

    case IdentificationTypeActionTypes.identificationTypeGetIdentificationTypesSuccess:
      return adapter.setAll(action.payload.content, {
        ...state,
        loading: false,
        loaded: true,
        paging: _.omit(action.payload,['content'])
      });

    case IdentificationTypeActionTypes.identificationTypeGetIdentificationTypeByIdSuccess:
      return { ...state, selectedIdentificationTypeId: action.payload.id, loading: false };

    case IdentificationTypeActionTypes.identificationTypeAddIdentificationTypeSuccess:
      return adapter.addOne(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });

    case IdentificationTypeActionTypes.identificationTypeUpdateIdentificationTypeSuccess: {
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state,
          loading: false,
          loaded: true
        }
      );
    }

    case IdentificationTypeActionTypes.identificationTypeDeleteIdentificationTypeSuccess: {
      return adapter.removeOne(action.payload.id, {
        ...state,
        loading: false,
        loaded: true
      });
    }

    case IdentificationTypeActionTypes.identificationTypeSearchIdentificationTypesSuccess:
      return {
        ...state,
        searchIdentificationTypes: action.payload,
        loading: false
      };

    case IdentificationTypeActionTypes.identificationTypeSearchIdentificationTypesReset:
      return {
        ...state,
        searchIdentificationTypes: null
      };

    case IdentificationTypeActionTypes.identificationTypeError:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload
      };

    default:
      return state;
  }
}

export const identificationTypeEntitySelectors = adapter.getSelectors();
