import {Action} from '@ngrx/store';

import {TenantProductWarranty} from '@appModels/core-setup/tenant-product-warranty/tenant-product-warranty';

export enum TenantProductWarrantyActionTypes {
  tenantProductWarrantyGetTenantProductWarranties = '[TenantProductWarranty] get',
  tenantProductWarrantyGetTenantProductWarrantiesSuccess = '[TenantProductWarranty] get tenantProductWarranties success',
  tenantProductWarrantyAddTenantProductWarranty = '[TenantProductWarranty] add tenantProductWarranty',
  tenantProductWarrantyAddTenantProductWarrantySuccess = '[TenantProductWarranty] add tenantProductWarranty success',
  tenantProductWarrantyAddTenantProductWarranties = '[TenantProductWarranty] add tenantProductWarranties',
  tenantProductWarrantyAddTenantProductWarrantiesSuccess = '[TenantProductWarranty] add tenantProductWarranties success',
  tenantProductWarrantyDeleteTenantProductWarranty = '[TenantProductWarranty] delete tenantProductWarranty',
  tenantProductWarrantyDeleteTenantProductWarrantySuccess = '[TenantProductWarranty] delete tenantProductWarranty success',
  tenantProductWarrantyGetTenantProductWarrantyById = '[TenantProductWarranty] get tenantProductWarranty by id',
  tenantProductWarrantyGetTenantProductWarrantyByIdSuccess = '[TenantProductWarranty] get tenantProductWarranty by id success',
  tenantProductWarrantyUpdateTenantProductWarranty = '[TenantProductWarranty] update tenantProductWarranty',
  tenantProductWarrantyUpdateTenantProductWarrantySuccess = '[TenantProductWarranty] update tenantProductWarranty success',
  tenantProductWarrantyEditTenantProductWarranty = '[TenantProductWarranty] edit tenantProductWarranty',
  tenantProductWarrantyEditTenantProductWarrantySuccess = '[TenantProductWarranty] edit tenantProductWarranty success',
  tenantProductWarrantySearchTenantProductWarranties = '[TenantProductWarranty] search tenantProductWarranties',
  tenantProductWarrantySearchTenantProductWarrantiesSuccess = '[TenantProductWarranty] search tenantProductWarranties success',
  tenantProductWarrantySearchTenantProductWarrantiesReset = '[TenantProductWarranty] search tenantProductWarranties reset',
  tenantProductWarrantyError = '[TenantProductWarranty] error'
}

export class GetTenantProductWarranties implements Action {
  readonly type = TenantProductWarrantyActionTypes.tenantProductWarrantyGetTenantProductWarranties;
}

export class GetTenantProductWarrantiesSuccess implements Action {
  readonly type = TenantProductWarrantyActionTypes.tenantProductWarrantyGetTenantProductWarrantiesSuccess;

  constructor(public payload: TenantProductWarranty[]) {
  }
}

export class AddTenantProductWarranty implements Action {
  readonly type = TenantProductWarrantyActionTypes.tenantProductWarrantyAddTenantProductWarranty;

  constructor(public payload: TenantProductWarranty) {
  }
}

export class AddTenantProductWarrantySuccess implements Action {
  readonly type = TenantProductWarrantyActionTypes.tenantProductWarrantyAddTenantProductWarrantySuccess;

  constructor(public payload: TenantProductWarranty) {
  }
}

export class AddTenantProductWarranties implements Action {
  readonly type = TenantProductWarrantyActionTypes.tenantProductWarrantyAddTenantProductWarranties;

  constructor(public payload: TenantProductWarranty[]) {
  }
}

export class AddTenantProductWarrantiesSuccess implements Action {
  readonly type = TenantProductWarrantyActionTypes.tenantProductWarrantyAddTenantProductWarrantiesSuccess;

  constructor(public payload: TenantProductWarranty[]) {
  }
}

export class GetTenantProductWarrantyById implements Action {
  readonly type = TenantProductWarrantyActionTypes.tenantProductWarrantyDeleteTenantProductWarranty;

  constructor(public payload: number) {
  }
}

export class GetTenantProductWarrantyByIdSuccess implements Action {
  readonly type = TenantProductWarrantyActionTypes.tenantProductWarrantyDeleteTenantProductWarrantySuccess;

  constructor(public payload: TenantProductWarranty) {
  }
}

export class UpdateTenantProductWarranty implements Action {
  readonly type = TenantProductWarrantyActionTypes.tenantProductWarrantyGetTenantProductWarrantyById;

  constructor(public payload: TenantProductWarranty) {
  }
}

export class UpdateTenantProductWarrantySuccess implements Action {
  readonly type = TenantProductWarrantyActionTypes.tenantProductWarrantyGetTenantProductWarrantyByIdSuccess;

  constructor(public payload: TenantProductWarranty) {
  }
}

export class EditTenantProductWarranty implements Action {
  readonly type = TenantProductWarrantyActionTypes.tenantProductWarrantyUpdateTenantProductWarranty;

  constructor(public payload) {
  }
}

export class EditTenantProductWarrantySuccess implements Action {
  readonly type = TenantProductWarrantyActionTypes.tenantProductWarrantyUpdateTenantProductWarrantySuccess;

  constructor(public payload) {
  }
}

export class DeleteTenantProductWarranty implements Action {
  readonly type = TenantProductWarrantyActionTypes.tenantProductWarrantyDeleteTenantProductWarranty;

  constructor(public payload: TenantProductWarranty) {
  }
}

export class DeleteTenantProductWarrantySuccess implements Action {
  readonly type = TenantProductWarrantyActionTypes.tenantProductWarrantyDeleteTenantProductWarrantySuccess;

  constructor(public payload: TenantProductWarranty) {
  }
}

export class SearchTenantProductWarranties implements Action {
  readonly type = TenantProductWarrantyActionTypes.tenantProductWarrantySearchTenantProductWarranties;

  constructor(public payload: string) {
  }
}

export class SearchTenantProductWarrantiesSuccess implements Action {
  readonly type = TenantProductWarrantyActionTypes.tenantProductWarrantySearchTenantProductWarrantiesSuccess;

  constructor(public payload: TenantProductWarranty[]) {
  }
}

export class SearchTenantProductWarrantiesReset implements Action {
  readonly type = TenantProductWarrantyActionTypes.tenantProductWarrantySearchTenantProductWarrantiesReset;
}

export class TenantProductWarrantyError implements Action {
  readonly type = TenantProductWarrantyActionTypes.tenantProductWarrantyError;

  constructor(public payload: any) {
  }
}

export type TenantProductWarrantyActions =
  | GetTenantProductWarranties
  | GetTenantProductWarrantiesSuccess
  | AddTenantProductWarranty
  | AddTenantProductWarranties
  | AddTenantProductWarrantiesSuccess
  | AddTenantProductWarrantySuccess
  | GetTenantProductWarrantyById
  | GetTenantProductWarrantyByIdSuccess
  | UpdateTenantProductWarranty
  | UpdateTenantProductWarrantySuccess
  | EditTenantProductWarranty
  | EditTenantProductWarrantySuccess
  | DeleteTenantProductWarranty
  | DeleteTenantProductWarrantySuccess
  | SearchTenantProductWarranties
  | SearchTenantProductWarrantiesSuccess
  | SearchTenantProductWarrantiesReset
  | TenantProductWarrantyError;
