import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { TimeInterval } from '@appModels/core-setup/time-interval/time-interval';

import { CoreMasterDataService } from '../core-masterdata.service';



@Injectable({ providedIn: 'root' })
export class TimeIntervalService extends CoreMasterDataService {


  constructor(
    private http: HttpClient,

  ) {
    super(http, 'timeintervalvariable');
  }

  /** GET timeIntervals from the server */
  getTimeIntervals(): Observable<TimeInterval[]> {
    return this.http.get<TimeInterval[]>(this.base_url , { headers: this.headers }).pipe(
      tap(timeIntervals => this.log(`fetched timeIntervals`)),
      catchError(this.handleError('getTimeIntervals', []))
    );
  }

  /** GET timeInterval by id. Return `undefined` when id not found */
  getTimeIntervalNo404<Data>(id: number): Observable<TimeInterval> {
    const url = `${this.base_url}/?id=${id}`;
    return this.http.get<TimeInterval[]>(url).pipe(
      map(timeIntervals => timeIntervals[0]), // returns a {0|1} element array
      tap(h => {
        const outcome = h ? `fetched` : `did not find`;
        this.log(`${outcome} timeInterval id=${id}`);
      }),
      catchError(this.handleError<TimeInterval>(`getTimeInterval id=${id}`))
    );
  }

  /** GET timeInterval by id. Will 404 if id not found */
  getTimeInterval(id: number): Observable<TimeInterval> {
    const url = `${this.base_url}/${id}`;
    return this.http.get<TimeInterval>(url,{ headers: this.headers }).pipe(
      tap(_ => this.log(`fetched timeInterval id=${id}`)),
      catchError(this.handleError<TimeInterval>(`getTimeInterval id=${id}`))
    );
  }

  /* GET timeIntervals whose name contains search term */
  searchTimeIntervals(term: string): Observable<TimeInterval[]> {
    if (!term.trim()) {
      // if not search term, return empty timeInterval array.
      return of([]);
    }
    return this.http.get<TimeInterval[]>(`api/timeIntervals/?name=${term}`).pipe(
      tap(_ => this.log(`found timeIntervals matching "${term}"`)),
      catchError(this.handleError<TimeInterval[]>('searchTimeIntervals', []))
    );
  }

  //////// Save methods //////////

  /** POST: add a new timeInterval to the server */
  addTimeInterval(timeInterval: TimeInterval): Observable<TimeInterval> {
    return this.http.post<TimeInterval>(this.base_url , timeInterval, { headers: this.headers }).pipe(
      tap((timeInterval: TimeInterval) => this.log(`added timeInterval w/ id=${timeInterval.id}`)),
      catchError(this.handleError<TimeInterval>('addTimeInterval'))
    );
  }

  /** DELETE: delete the timeInterval from the server */
  deleteTimeInterval(timeInterval: TimeInterval | number): Observable<TimeInterval> {
    const id = typeof timeInterval === 'number' ? timeInterval : timeInterval.id;
    const url = `${this.base_url}/${id}`;

    return this.http.delete<TimeInterval>(url, { headers: this.headers }).pipe(
      tap(_ => this.log(`deleted timeInterval id=${id}`)),
      catchError(this.handleError<TimeInterval>('deleteTimeInterval'))
    );
  }

  /** PUT: update the timeInterval on the server */
  updateTimeInterval(timeInterval: TimeInterval): Observable<any> {
    return this.http.put(this.base_url, timeInterval, { headers: this.headers }).pipe(
      tap(_ => this.log(`updated timeInterval id=${timeInterval.id}`)),
      catchError(this.handleError<any>('updateTimeInterval'))
    );
  }

}
