import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { CollapseModule } from 'ngx-bootstrap/collapse';

import { FAQComponent } from './faq.component';

import { ModuleRoutes } from './faq.routing';

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(ModuleRoutes),
        CollapseModule.forRoot(),
        FormsModule
    ],
    declarations: [
      FAQComponent
    ]
})
export class FaqModule { }
