import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

import {
  AddCountry,
  AddCountrySuccess,
  CountryActionTypes,
  CountryError,
  DeleteCountry,
  DeleteCountrySuccess, GetCountriesPaged, GetCountriesPagedSuccess,
  GetCountriesSuccess,
  GetCountryById,
  GetCountryByIdSuccess,
  SearchCountries,
  SearchCountriesSuccess,
  UpdateCountrySuccess
} from '@appStore/actions/core-masterdata/country/country.actions';
import {CountryService} from '@appServices/core-masterdata/country/country.service';

import * as fromRouterActions from '@appStore/actions/router.actions';

@Injectable()
export class CountryEffects {
  constructor(private actions$: Actions, private countryService: CountryService) {}

  
  loadCountries$ = createEffect(() => this.actions$.pipe(
    ofType(CountryActionTypes.countryGetCountries),
    mergeMap(() =>
      this.countryService
        .getCountries()
        .pipe(
          map(countries => new GetCountriesSuccess(countries)),
          catchError(error => of(new CountryError(error)))
        )
    )
  ));

  
  loadCountriesPaged$ = createEffect(() => this.actions$.pipe(
    ofType(CountryActionTypes.countryGetCountriesPaged),
    mergeMap((action: GetCountriesPaged) =>
      this.countryService
        .getCountriesPaged(action.payload)
        .pipe(
          map(countries => new GetCountriesPagedSuccess(countries)),
          catchError(error => of(new CountryError(error)))
        )
    )
  ));

  
  getCountryById$ = createEffect(() => this.actions$.pipe(
    ofType(CountryActionTypes.countryGetCountryById),
    mergeMap((action: GetCountryById) =>
      this.countryService
        .getCountry(action.payload)
        .pipe(
          map(country => new GetCountryByIdSuccess(country)),
          catchError(error => of(new CountryError(error)))
        )
    )
  ));

  
  addCountry$ = createEffect(() => this.actions$.pipe(
    ofType(CountryActionTypes.countryAddCountry),
    switchMap((action: AddCountry) =>
      this.countryService
        .addCountry(action.payload)
        .pipe(
          map(country => new AddCountrySuccess(country)),
          catchError(error => of(new CountryError(error)))
        )
    )
  ));

  
  updateCountry$ = createEffect(() => this.actions$.pipe(
    ofType(CountryActionTypes.countryUpdateCountry),
    mergeMap((action: AddCountry) =>
      this.countryService
        .updateCountry(action.payload)
        .pipe(
          map(country => new UpdateCountrySuccess(action.payload)),
          catchError(error => of(new CountryError(error)))
        )
    )
  ));

  
  deleteCountry$ = createEffect(() => this.actions$.pipe(
    ofType(CountryActionTypes.countryDeleteCountry),
    mergeMap((action: DeleteCountry) =>
      this.countryService
        .deleteCountry(action.payload)
        .pipe(
          map(() => new DeleteCountrySuccess(action.payload)),
          catchError(error => of(new CountryError(error)))
        )
    )
  ));

  
  searchCountries$ = createEffect(() => this.actions$.pipe(
    ofType(CountryActionTypes.countrySearchCountries),
    mergeMap((action: SearchCountries) =>
      this.countryService
        .searchCountries(action.payload)
        .pipe(
          map(countries => new SearchCountriesSuccess(countries)),
          catchError(error => of(new CountryError(error)))
        )
    )
  ));

  
  updateCountrySuccess$ = createEffect(() => this.actions$.pipe(
    ofType(CountryActionTypes.countryUpdateCountrySuccess),
    map(country => new fromRouterActions.Go({ path: ['/setup/countries'] }))
  ));


  /**
   *
   *
   * @memberof CountryEffects
   */
  // @Effect()
  // $init = this.actions$.pipe(
  //   ofType(ROOT_EFFECTS_INIT),
  //   map(() => new GetCountries())
  // );
}
