<div [ngClass]="!showComponent ? '' : 'border'">
  <div *ngIf="!startQuestion">
    <div class="detail-container">
      <p [ngClass]="!showComponent ? 'sub-title p-t-60' : 'sub-title' ">{{ "Opening Call" | translate }}:</p>
      <p class="">
        {{ "Hello my name is" | translate }} {{ agentName }},
        {{
          "I'm calling from d.light. May I have a moment of your time to complete your registration by asking you a few questions"
            | translate
        }}?
      </p>
      <p class="note">
        {{ "Please note that the phone is recorded" | translate }}.
      </p>
      <button
        class="button next"
        value="next"
        type="button"
        (click)="displayQuestions()"
      >
        {{ "Next" | translate
        }}<i class="fa-solid fa-arrow-right-long" style="color: #ffffff"></i>
      </button>
    </div>
  </div>
  <div *ngIf="startQuestion">
    <div class="detail-container" *ngIf="!showTotalScore">
      <div class="rows p-t-20">
        <div class="col-9 p-t-20">
          <p class="index">
            {{ "Question" | translate }}
            {{ callScoreCardQuestions[currentQuestionIndex]?.questionOrder }}
            <span>/15 </span>
          </p>
        </div>
  
        <div class="col-3">
          <button class="button next">
            {{ callScoreCardQuestions[currentQuestionIndex]?.maxScoreValue }}%
          </button>
        </div>
      </div>
      <div style="min-height: 200px">
        <p
          class="content question p-b-10"
          [innerHTML]="callScoreCardQuestions[currentQuestionIndex]?.questionText"
        ></p>
        <b>
          <p class="description" *ngIf="currentQuestionIndex === 0">
            {{
              "Agent Guide (Agent to verify customer Details Name, Home address National ID, Primary Phone No & Alternative No)"
                | translate
            }}.
          </p>
          <p class="description" *ngIf="currentQuestionIndex === 2">
            {{
              "Agent Guide: (Agent to educate the customer about stolen, lost and damaged product policy)"
                | translate
            }}.
          </p>
          <p class="description" *ngIf="currentQuestionIndex === 3">
            {{
              "Agent Guide: (Agent to confirm the current area of residence of the customer)"
                | translate
            }}.
          </p>
          <p class="description" *ngIf="currentQuestionIndex === 4">
            {{
              "Agent Guide: (Agent to Confirm product components - Account number & Deposit paid and other additional payment)"
                | translate
            }}.
          </p>
          <p class="description" *ngIf="currentQuestionIndex === 14">
            {{
              "Agent Guide: (Please be informed that the product has a warranty of 2yrs for SHS and 1yr for Phones. The warranty does not cover broken screens, tampered units, liquid damage, burnt PCBA, or lost or stolen products)"
                | translate
            }}.
          </p>
        </b>
  
        <div class="p-b-20 p-t-20">
          <label class="score-field" for="inputId">{{
            "Score" | translate
          }}</label>
          <input
            type="number"
            id="inputId"
            name="inputScore"
            [(ngModel)]="
              callScoreCardQuestionsAnswer[
                callScoreCardQuestions[currentQuestionIndex]?.questionOrder
              ].score
            "
            class="blank-input"
            value="_"
            [max]="callScoreCardQuestions[currentQuestionIndex]?.maxScoreValue"
          />
          <p
            *ngIf="
              callScoreCardQuestionsAnswer[
                callScoreCardQuestions[currentQuestionIndex]?.questionOrder
              ].score >
              callScoreCardQuestions[currentQuestionIndex]?.maxScoreValue
            "
            class="error-message"
          >
            <i class="fa fa-exclamation-triangle"></i>
            {{ "Score not allowed, check your input" | translate }}.
          </p>
        </div>
      </div>
  
      <div class="rows">
        <div class="col-5">
          <button
            class="button previous"
            value="previous"
            type="button"
            (click)="previousQuestion()"
            [disabled]="currentQuestionIndex === 0"
            [ngClass]="{
              'disabled-button-previous': currentQuestionIndex === 0
            }"
          >
            <div class="rows">
              <div class="col-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-arrow-left"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                  />
                </svg>
              </div>
  
              <div class="col-5">{{ "Previous" | translate }}</div>
            </div>
          </button>
        </div>
        <div class="col-7">
          <button
            *ngIf="
              !(
                this.currentQuestionIndex ===
                this.callScoreCardQuestions.length - 1
              )
            "
            class="button next"
            [disabled]="
              callScoreCardQuestionsAnswer[
                callScoreCardQuestions[currentQuestionIndex]?.questionOrder
              ].score >
              callScoreCardQuestions[currentQuestionIndex]?.maxScoreValue
            "
            value="next"
            type="button"
            (click)="saveScore(); nextQuestion()"
            [ngClass]="{
              'disabled-button-next':
                this.currentQuestionIndex ===
                this.callScoreCardQuestions.length - 1
            }"
          >
            {{ "Next" | translate }}
            <svg
              style="color: white"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-arrow-right"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                fill="white"
              ></path>
            </svg>
          </button>
          <button
            *ngIf="
              this.currentQuestionIndex === this.callScoreCardQuestions.length - 1
            "
            (click)="saveTotalScore(); saveScore()"
            class="button next"
          >
            {{ "Finish" | translate }}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-check-lg"
              viewBox="0 0 16 16"
            >
              <path
                d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="detail-container" *ngIf="showTotalScore">
    <div class="last-page">
      <div class="result m-b-10 m-t-40">
        <p>{{ "SCORE" | translate }}</p>
        <p class="number p-b-20">{{ scoreSum }}%</p>
      </div>
      <div class="m-b-10 ending">
        <p>
          {{
            "Thank you for your time and patience and for choosing d.light"
              | translate
          }}.<br />
          {{ "Have a wonderful day" | translate }}.
        </p>
      </div>
      <div class="m-t-40">
        <button class="button next" value="next" type="button">
          {{ "Finish" | translate }}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-check-lg"
            viewBox="0 0 16 16"
          >
            <path
              d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="showMessage">
  <p class="notification error m-t-10 m-l-10 m-r-5">
    <i class="fa fa-info-circle m-r-5"> </i
    >{{
      "Please Fill All Scores Before Proceeding to Perform Action On Customer Details Page"
        | translate
    }}
  </p>
</div>
