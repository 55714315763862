import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { mergeMap, map, catchError, tap, debounceTime, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import {
  TimeIntervalActionTypes,
  GetTimeIntervalsSuccess,
  TimeIntervalError,
  AddTimeInterval,
  AddTimeIntervalSuccess,
  DeleteTimeIntervalSuccess,
  DeleteTimeInterval,
  GetTimeIntervalById,
  GetTimeIntervalByIdSuccess,
  UpdateTimeIntervalSuccess,
  SearchTimeIntervals,
  SearchTimeIntervalsSuccess
} from '@appStore/actions/core-masterdata/time-interval/time-interval.actions';
import { TimeIntervalService } from '@appServices/core-masterdata/time-interval/time-interval.service';

import * as fromRouterActions from '@appStore/actions/router.actions';

@Injectable()
export class TimeIntervalEffects {
  constructor(private actions$: Actions, private timeIntervalService: TimeIntervalService) {}

  
  loadTimeIntervals$ = createEffect(() => this.actions$.pipe(
    ofType(TimeIntervalActionTypes.timeIntervalGetTimeIntervals),
    mergeMap(() =>
      this.timeIntervalService
        .getTimeIntervals()
        .pipe(
          map(timeIntervals => new GetTimeIntervalsSuccess(timeIntervals)),
          catchError(error => of(new TimeIntervalError(error)))
        )
    )
  ));

  
  getTimeIntervalById$ = createEffect(() => this.actions$.pipe(
    ofType(TimeIntervalActionTypes.timeIntervalGetTimeIntervalById),
    mergeMap((action: GetTimeIntervalById) =>
      this.timeIntervalService
        .getTimeInterval(action.payload)
        .pipe(
          map(timeInterval => new GetTimeIntervalByIdSuccess(timeInterval)),
          catchError(error => of(new TimeIntervalError(error)))
        )
    )
  ));

  
  addTimeInterval$ = createEffect(() => this.actions$.pipe(
    ofType(TimeIntervalActionTypes.timeIntervalAddTimeInterval),
    switchMap((action: AddTimeInterval) =>
      this.timeIntervalService
        .addTimeInterval(action.payload)
        .pipe(
          map(timeInterval => new AddTimeIntervalSuccess(timeInterval)),
          catchError(error => of(new TimeIntervalError(error)))
        )
    )
  ));

  
  updateTimeInterval$ = createEffect(() => this.actions$.pipe(
    ofType(TimeIntervalActionTypes.timeIntervalUpdateTimeInterval),
    mergeMap((action: AddTimeInterval) =>
      this.timeIntervalService
        .updateTimeInterval(action.payload)
        .pipe(
          map(timeInterval => new UpdateTimeIntervalSuccess(action.payload)),
          catchError(error => of(new TimeIntervalError(error)))
        )
    )
  ));

  
  deleteTimeInterval$ = createEffect(() => this.actions$.pipe(
    ofType(TimeIntervalActionTypes.timeIntervalDeleteTimeInterval),
    mergeMap((action: DeleteTimeInterval) =>
      this.timeIntervalService
        .deleteTimeInterval(action.payload)
        .pipe(
          map(() => new DeleteTimeIntervalSuccess(action.payload)),
          catchError(error => of(new TimeIntervalError(error)))
        )
    )
  ));

  
  searchTimeIntervals$ = createEffect(() => this.actions$.pipe(
    ofType(TimeIntervalActionTypes.timeIntervalSearchTimeIntervals),
    mergeMap((action: SearchTimeIntervals) =>
      this.timeIntervalService
        .searchTimeIntervals(action.payload)
        .pipe(
          map(timeIntervals => new SearchTimeIntervalsSuccess(timeIntervals)),
          catchError(error => of(new TimeIntervalError(error)))
        )
    )
  ));

  
  updateTimeIntervalSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(TimeIntervalActionTypes.timeIntervalUpdateTimeIntervalSuccess),
    map(timeInterval => new fromRouterActions.Go({ path: ['/setup/timeIntervals'] }))
  ));
}
