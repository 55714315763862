import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';


import {CoreFieldforceService} from "@appServices/core-fieldforce/core-fieldforce.service";
import {CoreMasterDataService} from "../core-masterdata.service";
import {TicketCreationFields} from "../../../models/core-setup/ticket-creation-fields/ticket-creation-fields";


@Injectable({providedIn: 'root'})
export class TicketCreationFieldsService extends CoreMasterDataService {


  constructor(
    private http: HttpClient,
  ) {
    super(http, 'ticketcreationfields');
  }


  getTicketCreationFieldsById(id: number): Observable<TicketCreationFields> {
    const url = `${this.base_url}/${id}`;
    return this.http.get<TicketCreationFields>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched salesStaffDefinition id=${id}`)),
      catchError(this.handleError<TicketCreationFields>(`getTicketCreationFieldsDefinition id=${id}`))
    );
  }


  getTicketCreationFieldstNo404<Data>(id: number): Observable<TicketCreationFields> {
    const url = `${this.base_url}/?id=${id}`;
    return this.http.get<TicketCreationFields[]>(url).pipe(
      map(salesStaff => salesStaff[0]), // returns a {0|1} element array
      tap(h => {
        const outcome = h ? `fetched` : `did not find`;
        this.log(`${outcome} salesStaff id=${id}`);
      }),
      catchError(this.handleError<TicketCreationFields>(`getStockingPoint id=${id}`))
    );
  }


  filterTicketCreationFields(query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        if (query[val] !== null && query[val] !== undefined && query[val] !== '')
          params = params.append(val, query[val]);
      });
    }

    console.log("filtering ticket creation fields");

    return this.http.get<any>(this.base_url + '/tenant/' + localStorage.getItem('tenant'), {
      params,
      headers: this.headers
    }).pipe(
      tap(customers => this.log(`fetched customers`))
      ,
      catchError(this.handleError<TicketCreationFields[]>('getTicketCreationFields')
      )
    );
  }


  /** GET sales staff definitions by id. Will 404 if id not found */
  getTicketCreationFields(id: number): Observable<TicketCreationFields> {
    const url = `${this.base_url}/${id}`;
    return this.http.get<TicketCreationFields>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched salesStaff id=${id}`)),
      catchError(this.handleError<TicketCreationFields>(`getTicketCreationFields id=${id}`))
    );
  }


  addTicketCreationFields(entity: TicketCreationFields): Observable<TicketCreationFields> {
    return this.http.post<TicketCreationFields>(this.base_url, entity, {headers: this.headers}).pipe(
      tap(),
      catchError(this.handleError<TicketCreationFields>('addTicketCreationFieldsDefinition'))
    );
  }


}
