import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable, of} from 'rxjs';

import {CoreTicketingService} from "@appServices/core-ticketing/core-ticketing.service";
import {catchError, tap} from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class TicketCustomFieldService extends CoreTicketingService {

  constructor(
    private http: HttpClient,
  ) {
    super(http, 'customfield');
    this.headers = this.headers.append('skip-cache', 'true');
  }

  getCustomFieldTypes(): Observable<any> {

    const url = `${this.base_url}/types`;
    return this.http.get<any>(url, {headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }

  getFieldsForRequestType(requestTypeId:number): Observable<any> {
    const url = `${this.base_url}/getFieldsForRequestType/${requestTypeId}`;
    return this.http.get<any>(url, {headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }

  getCustomFieldsListRow(): Observable<any> {
    const url = `${this.base_url}/getCustomFieldsListRow`;
    return this.http.get<any>(url, {headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }

  createCustomField(body:any): Observable<any> {

    const url = `${this.base_url}/createCustomField`;
    return this.http.post<any>(url, body , { headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }

  getCustomField(id:number): Observable<any> {
    const url = `${this.base_url}/getCustomField/${id}`;
    return this.http.get<any>(url, {headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }

  updateCustomField(body:any , id:number): Observable<any> {
    const url = `${this.base_url}/updateCustomField/${id}`;
    return this.http.post<any>(url, body, {headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }



}
