import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getProductAttributeStore = createFeatureSelector('productAttribute');

export const getProductAttributeEntities = createSelector(
  getProductAttributeStore,
  fromReducers.productAttribute.productAttributeEntitySelectors.selectAll
);

export const getProductAttributes = createSelector(getProductAttributeEntities, entities => {
  return Object.values(entities);
});

export const getTopProductAttributes = createSelector(getProductAttributeEntities, entities => {
  return Object.values(entities).slice(1, 5);
});

export const getProductAttributesLoaded = createSelector(
  getProductAttributeStore,
  (productAttributeStore: fromReducers.productAttribute.State) => productAttributeStore.loaded
);

export const getProductAttributesLoading = createSelector(
  getProductAttributeStore,
  (productAttributeStore: fromReducers.productAttribute.State) => productAttributeStore.loading
);

export const getSelectedProductAttributeId = createSelector(
  getProductAttributeStore,
  (productAttributeStore: fromReducers.productAttribute.State) => productAttributeStore.selectedProductAttributeId
);

export const getSearchProductAttributes = createSelector(
  getProductAttributeStore,
  (productAttributeStore: fromReducers.productAttribute.State) => productAttributeStore.searchProductAttributes
);

export const getProductAttributeById = createSelector(
  getProductAttributeEntities,
  getSelectedProductAttributeId,
  (entities, id) => entities.find(i => i.id === id)
);

export const getProductAttributesError = createSelector(
  getProductAttributeStore,
  (productAttributeStore: fromReducers.productAttribute.State) => productAttributeStore.error
);
