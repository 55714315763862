import {Action} from '@ngrx/store';

import {DelinquencyDefinition} from '@appModels/core-accounting/delinquency-definition/delinquency-definition';

export enum DelinquencyDefinitionActionTypes {
  delinquencyDefinitionGetDelinquencyDefinitions = '[DelinquencyDefinition] get',
  delinquencyDefinitionGetDelinquencyDefinitionsSuccess = '[DelinquencyDefinition] get delinquencyDefinitions success',
  delinquencyDefinitionGetDelinquencyDefinitionByTenant = '[DelinquencyDefinition] get delinquencyDefinitions by tenant id',
  delinquencyDefinitionGetDelinquencyDefinitionByTenantSuccess = '[DelinquencyDefinition] get delinquencyDefinitions by tenant id success',
  delinquencyDefinitionGetDelinquencyDefinitionByProductId = '[DelinquencyDefinition] get delinquencyDefinition by product id',
  delinquencyDefinitionGetDelinquencyDefinitionByProductIdSuccess = '[DelinquencyDefinition] get delinquencyDefinition by product id success',
  delinquencyDefinitionAddDelinquencyDefinition = '[DelinquencyDefinition] add DelinquencyDefinition',
  delinquencyDefinitionAddDelinquencyDefinitionSuccess = '[DelinquencyDefinition] add delinquencyDefinition success',
  delinquencyDefinitionAddAdditionalDelinquencyDefinitionInfo = '[DelinquencyDefinition] add additional DelinquencyDefinition Info',
  delinquencyDefinitionAddAdditionalDelinquencyDefinitionInfoSuccess = '[DelinquencyDefinition] add additional delinquencyDefinition Info success',
  delinquencyDefinitionDeleteDelinquencyDefinition = '[DelinquencyDefinition] delete delinquencyDefinition',
  delinquencyDefinitionDeleteDelinquencyDefinitionSuccess = '[DelinquencyDefinition] delete delinquencyDefinition success',
  delinquencyDefinitionGetDelinquencyDefinitionById = '[DelinquencyDefinition] get delinquencyDefinition by id',
  delinquencyDefinitionGetDelinquencyDefinitionByIdSuccess = '[DelinquencyDefinition] get delinquencyDefinition by id success',
  delinquencyDefinitionUpdateDelinquencyDefinition = '[DelinquencyDefinition] update delinquencyDefinition',
  delinquencyDefinitionUpdateDelinquencyDefinitionSuccess = '[DelinquencyDefinition] update delinquencyDefinition success',
  delinquencyDefinitionSearchDelinquencyDefinitions = '[DelinquencyDefinition] search delinquencyDefinitions',
  delinquencyDefinitionSearchDelinquencyDefinitionsSuccess = '[DelinquencyDefinition] search delinquencyDefinitions success',
  delinquencyDefinitionSearchDelinquencyDefinitionsReset = '[DelinquencyDefinition] search delinquencyDefinitions reset',
  delinquencyDefinitionError = '[DelinquencyDefinition] error',
  delinquencyDefinitionGetDelinquencyDefinitionsByTenantId = '[DelinquencyDefinition] get delinquencyDefinitions by tenant id',
  delinquencyDefinitionGetDelinquencyDefinitionsByTenantIdSuccess = '[DelinquencyDefinition] get delinquencyDefinitions by tenant id success',

}

export class GetDelinquencyDefinitions implements Action {
  readonly type = DelinquencyDefinitionActionTypes.delinquencyDefinitionGetDelinquencyDefinitions;
  constructor(public payload?) {}
}

export class GetDelinquencyDefinitionsSuccess implements Action {
  readonly type = DelinquencyDefinitionActionTypes.delinquencyDefinitionGetDelinquencyDefinitionsSuccess;
  constructor(public payload) {}
}

export class GetDelinquencyDefinitionsByTenantId implements Action {
  readonly type = DelinquencyDefinitionActionTypes.delinquencyDefinitionGetDelinquencyDefinitionsByTenantId;
}

export class GetDelinquencyDefinitionsByTenantIdSuccess implements Action {
  readonly type = DelinquencyDefinitionActionTypes.delinquencyDefinitionGetDelinquencyDefinitionsByTenantIdSuccess;

  constructor(public payload: DelinquencyDefinition[]) {
  }
}

export class AddDelinquencyDefinition implements Action {
  readonly type = DelinquencyDefinitionActionTypes.delinquencyDefinitionAddDelinquencyDefinition;
  constructor(public payload: DelinquencyDefinition) {}
}

export class GetDelinquencyDefinitionsByTenant implements Action {
  readonly type = DelinquencyDefinitionActionTypes.delinquencyDefinitionGetDelinquencyDefinitionByTenant;

  constructor(public payload) {
  }
}

export class GetDelinquencyDefinitionsByTenantSuccess implements Action {
  readonly type = DelinquencyDefinitionActionTypes.delinquencyDefinitionGetDelinquencyDefinitionByTenantSuccess;

  constructor(public payload: DelinquencyDefinition[]) {
  }
}

export class AddDelinquencyDefinitionSuccess implements Action {
  readonly type = DelinquencyDefinitionActionTypes.delinquencyDefinitionAddDelinquencyDefinitionSuccess;
  constructor(public payload: DelinquencyDefinition) {}
}

export class AddAdditionalDelinquencyDefinitionInfo implements Action {
  readonly type = DelinquencyDefinitionActionTypes.delinquencyDefinitionAddAdditionalDelinquencyDefinitionInfo;
  constructor(public payload) {}
}

export class AddAdditionalDelinquencyDefinitionInfoSuccess implements Action {
  readonly type = DelinquencyDefinitionActionTypes.delinquencyDefinitionAddAdditionalDelinquencyDefinitionInfoSuccess;
  constructor(public payload) {}
}

export class GetDelinquencyDefinitionById implements Action {
  readonly type = DelinquencyDefinitionActionTypes.delinquencyDefinitionGetDelinquencyDefinitionById;
  constructor(public payload: number) {}
}

export class GetDelinquencyDefinitionByIdSuccess implements Action {
  readonly type = DelinquencyDefinitionActionTypes.delinquencyDefinitionGetDelinquencyDefinitionByIdSuccess;
  constructor(public payload: DelinquencyDefinition) {}
}

export class GetDelinquencyDefinitionByProductId implements Action {
  readonly type = DelinquencyDefinitionActionTypes.delinquencyDefinitionGetDelinquencyDefinitionByProductId;
  constructor(public payload: number) {}
}

export class GetDelinquencyDefinitionByProductIdSuccess implements Action {
  readonly type = DelinquencyDefinitionActionTypes.delinquencyDefinitionGetDelinquencyDefinitionByProductIdSuccess;
  constructor(public payload: DelinquencyDefinition) {}
}

export class UpdateDelinquencyDefinition implements Action {
  readonly type = DelinquencyDefinitionActionTypes.delinquencyDefinitionUpdateDelinquencyDefinition;
  constructor(public payload: DelinquencyDefinition) {}
}

export class UpdateDelinquencyDefinitionSuccess implements Action {
  readonly type = DelinquencyDefinitionActionTypes.delinquencyDefinitionUpdateDelinquencyDefinitionSuccess;
  constructor(public payload: DelinquencyDefinition) {}
}

export class DeleteDelinquencyDefinition implements Action {
  readonly type = DelinquencyDefinitionActionTypes.delinquencyDefinitionDeleteDelinquencyDefinition;
  constructor(public payload: DelinquencyDefinition) {}
}

export class DeleteDelinquencyDefinitionSuccess implements Action {
  readonly type = DelinquencyDefinitionActionTypes.delinquencyDefinitionDeleteDelinquencyDefinitionSuccess;
  constructor(public payload: DelinquencyDefinition) {}
}

export class SearchDelinquencyDefinitions implements Action {
  readonly type = DelinquencyDefinitionActionTypes.delinquencyDefinitionSearchDelinquencyDefinitions;
  constructor(public payload: string) {}
}

export class SearchDelinquencyDefinitionsSuccess implements Action {
  readonly type = DelinquencyDefinitionActionTypes.delinquencyDefinitionSearchDelinquencyDefinitionsSuccess;
  constructor(public payload: DelinquencyDefinition[]) {}
}

export class SearchDelinquencyDefinitionsReset implements Action {
  readonly type = DelinquencyDefinitionActionTypes.delinquencyDefinitionSearchDelinquencyDefinitionsReset;
}

export class DelinquencyDefinitionError implements Action {
  readonly type = DelinquencyDefinitionActionTypes.delinquencyDefinitionError;
  constructor(public payload: any) {}
}

export type DelinquencyDefinitionActions =
  | GetDelinquencyDefinitions
  | GetDelinquencyDefinitionsSuccess
  | AddDelinquencyDefinition
  | AddDelinquencyDefinitionSuccess
  | GetDelinquencyDefinitionsByTenant
  | GetDelinquencyDefinitionsByTenantSuccess
  | AddAdditionalDelinquencyDefinitionInfo
  | AddAdditionalDelinquencyDefinitionInfoSuccess
  | GetDelinquencyDefinitionById
  | GetDelinquencyDefinitionByIdSuccess
  | GetDelinquencyDefinitionByProductId
  | GetDelinquencyDefinitionByProductIdSuccess
  | UpdateDelinquencyDefinition
  | UpdateDelinquencyDefinitionSuccess
  | DeleteDelinquencyDefinition
  | DeleteDelinquencyDefinitionSuccess
  | SearchDelinquencyDefinitions
  | SearchDelinquencyDefinitionsSuccess
  | SearchDelinquencyDefinitionsReset
  | DelinquencyDefinitionError
  | GetDelinquencyDefinitionsByTenantId
  | GetDelinquencyDefinitionsByTenantIdSuccess;
