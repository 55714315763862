import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';

import {Product} from '@appModels/core-setup/product/product';
import {User} from '@appModels/core-identity/user/user';

import {CoreMasterDataService} from '../core-masterdata.service';
import {ProductVersion} from '@appModels/core-setup/product/product-version';
import {Part} from '@appModels/core-aftersales/part';


@Injectable({providedIn: 'root'})
export class ProductService extends CoreMasterDataService {


  constructor(
    private http: HttpClient,
  ) {
    super(http, 'globalproduct');
  }

  /** GET products from the server */
  getProducts(query?): Observable<Product[]> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        if (query[val] !== null && query[val] !== undefined && query[val] !== '') {
          params = params.append(val, query[val]);
        }
      });
    }

    return this.http.get<Product[]>(this.base_url + 's', {params: params, headers: this.headers}).pipe(
      tap(products => this.log(`fetched products`)),
      catchError(this.handleError('getProducts', []))
    );
  }

  getProductCount(): Observable<any> {
    return this.http.get<number>(this.base_url + '/count', {headers: this.headers}).pipe(
      tap(products => this.log(`fetched product counts`)),
      catchError(this.handleError('getProductCount', []))
    );
  }

  getProductFamilies(): Observable<any> {
    return this.http.get<number>(this.base_url + 's/families', {headers: this.headers}).pipe(
      tap(products => this.log(`fetched product families`)),
      catchError(this.handleError('getProductFamilies', []))
    );
  }

  /** GET products by type from the server */
  getProductsByType(globalProductTypeId: number): Observable<Product[]> {
    return this.http.get<Product[]>(this.base_url + `s/globalproductype/${globalProductTypeId}`, {headers: this.headers}).pipe(
      tap(products => this.log(`fetched products by type`)),
      catchError(this.handleError('getProductsByType', []))
    );
  }

  /** GET products by type from the server */
  getProductVersionsByType(globalProductTypeId: number): Observable<any[]> {
    return this.http.get<Product[]>(this.base_url + `versions/globalproductype/${globalProductTypeId}`, {headers: this.headers}).pipe(
      tap(products => this.log(`fetched products by type`)),
      catchError(this.handleError('getProductsByType', []))
    );
  }

  /** GET products subunits from the server */
  getProductVersionSubUnits(globalProductTypeId: number): Observable<ProductVersion[]> {
    const tenantId = localStorage.getItem('tenant');
    return this.http.get<ProductVersion[]>(this.base_url + `sversion/${globalProductTypeId}/subunits/${tenantId}`, {headers: this.headers}).pipe(
      tap(products => this.log(`fetched products subunits`)),
      catchError(this.handleError('getProductVersionSubUnits', []))
    );
  }

  /** GET products subunits from the server */
  getProductVersionSubUnitsByContractProduct(contractProductId: number): Observable<ProductVersion[]> {
    const tenantId = localStorage.getItem('tenant');
    return this.http.get<ProductVersion[]>(this.base_url + `sversion/components/${contractProductId}/${tenantId}`, {headers: this.headers}).pipe(
      tap(products => this.log(`fetched products subunits`)),
      catchError(this.handleError('getProductVersionSubUnits', []))
    );
  }

  /** GET products subunits from the server */
  getProductVersionSubUnitsByAccountNumber(accountNumber: string): Observable<ProductVersion[]> {
    const tenantId = localStorage.getItem('tenant');
    return this.http.get<ProductVersion[]>(this.base_url + `sversion/components/accountNumber/${accountNumber}/${tenantId}`, {headers: this.headers}).pipe(
      tap(products => this.log(`fetched products subunits`)),
      catchError(this.handleError('getProductVersionSubUnits', []))
    );
  }

  /** GET products from the server */
  getProductSubUnits(globalProductTypeId: number): Observable<ProductVersion[]> {
    const tenantId = localStorage.getItem('tenant');
    return this.http.get<ProductVersion[]>(this.base_url + `s/${globalProductTypeId}/subunits/${tenantId}`, {headers: this.headers}).pipe(
      tap(products => this.log(`fetched products subunits`)),
      catchError(this.handleError('getProductSubUnits', []))
    );
  }

  /** GET subunits for family from the server */
  getSubUnitsForFamily(globalProductFamilyId): Observable<any[]> {
    return this.http.get<any[]>(this.base_url + `s/subunits/family/${globalProductFamilyId}`, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched SubUnits For Family`)),
      catchError(this.handleError('getSubUnitsForFamily', []))
    );
  }

  /** GET components for subunits from the server */
  getComponentsForSubUnits(globalProductId): Observable<any[]> {
    return this.http.get<any[]>(this.base_url + `s/components/subunit/${globalProductId}`, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched Components For SubUnits`)),
      catchError(this.handleError('getComponentsForSubUnits', []))
    );
  }

  /** GET products parts from the server */
  getProductParts(globalProductTypeId: number): Observable<Part[]> {
    const tenantId = localStorage.getItem('tenant');
    return this.http.get<Part[]>(this.base_url + `s/${globalProductTypeId}/parts/tenant/${tenantId}`, {headers: this.headers}).pipe(
      tap(products => this.log(`fetched products subunits`)),
      catchError(this.handleError('getProductParts', []))
    );
  }

  /** GET products parts from the server */
  getPortableProducts(): Observable<any> {
    const tenantId = localStorage.getItem('tenant');
    return this.http.get<any>(this.base_url.replace("/globalproduct","") + `/globalPortableProductVersions/${tenantId}`, {headers: this.headers}).pipe(
      tap(portableProducts => this.log(`fetched portable products`)),
      catchError(this.handleError('getPortableProducts', []))
    );
  }

  /** GET products parts from the server */
  filterProductParts(versionId: number, level: number, pn: string): Observable<Part[]> {
    const tenantId = localStorage.getItem('tenant');

    let params = new HttpParams();
    params = params.append('level', level.toString());
    params = params.append('pn', pn);

    return this.http.get<Part[]>(this.base_url+ `s/${versionId}/filterparts/tenant/${tenantId}`, {params: params, headers: this.headers}).pipe(
      tap(products => this.log(`fetched products subunits`)),
      catchError(this.handleError('getProductParts', []))
    );
  }

  /** GET tenant parts from the server */
  filterTenantParts(pn: string): Observable<Part[]> {
    const tenantId = localStorage.getItem('tenant');

    let params = new HttpParams();
    params = params.append('pn', pn);

    return this.http.get<Part[]>(this.base_url+ `s/filterparts/tenant/${tenantId}`, {params: params, headers: this.headers}).pipe(
      tap(_ => this.log(`fetched tenant parts`)),
      catchError(this.handleError('getTenantParts', []))
    );
  }


  /** GET part versions from the server */
  getPartVersions(levels: string[]): Observable<Part[]> {
    const tenantId = localStorage.getItem('tenant');
    return this.http.get<Part[]>(this.base_url + `s/partVersion/tenant/${tenantId}`, {
      params: {'levels': levels.join(',')},
      headers: this.headers
    }).pipe(
      tap(products => this.log(`fetched part versions`)),
      catchError(this.handleError('getPartVersions', []))
    );
  }

  /** GET product firmware from the server */
  getProductFirmware(globalProductId: number): Observable<any[]> {
    return this.http.get<Product[]>(this.base_url + `s/firmwares/${globalProductId}`, {headers: this.headers}).pipe(
      tap(products => this.log(`fetched products by type`)),
      catchError(this.handleError('getProductFirmware', []))
    );
  }

  /** GET product firmware from the server */
  getProductVersionFirmware(versionId: number): Observable<any[]> {
    return this.http.get<Product[]>(this.base_url + `s/firmwares/version/${versionId}`, {headers: this.headers}).pipe(
      tap(products => this.log(`fetched product firmware version by version`)),
      catchError(this.handleError('getProductFirmware', []))
    );
  }

  /** GET product hardware from the server */
  getProductHardware(globalProductId: number): Observable<any[]> {
    return this.http.get<Product[]>(this.base_url + `s/hardwares/${globalProductId}`, {headers: this.headers}).pipe(
      tap(products => this.log(`fetched product hardware versions`)),
      catchError(this.handleError('getProductFirmware', []))
    );
  }


  /** GET products by type from the server */
  findAllAvailableForNewTestFlowByType(globalProductTypeId: number): Observable<Product[]> {
    return this.http.get<Product[]>(this.base_url + `s/globalproductype/newtestflow/${globalProductTypeId}`, {headers: this.headers}).pipe(
      tap(products => this.log(`fetched products by type`)),
      catchError(this.handleError('getProductsByType', []))
    );
  }

  /** GET products by type from the server */
  findAllAvailableForNewWorkOrderTestFlowByType(globalProductTypeId: number): Observable<Product[]> {
    return this.http.get<Product[]>(this.base_url + `s/globalproductype/newworkordertestflow/${globalProductTypeId}`, {headers: this.headers}).pipe(
      tap(products => this.log(`fetched products by type`)),
      catchError(this.handleError('getProductsByType', []))
    );
  }

  /** GET product by id. Return `undefined` when id not found */
  getProductNo404<Data>(id: number): Observable<Product> {
    const url = `${this.base_url}/?id=${id}`;
    return this.http.get<Product[]>(url).pipe(
      map(products => products[0]), // returns a {0|1} element array
      tap(h => {
        const outcome = h ? `fetched` : `did not find`;
        this.log(`${outcome} product id=${id}`);
      }),
      catchError(this.handleError<Product>(`getProduct id=${id}`))
    );
  }

  /** GET product by id. Will 404 if id not found */
  getProduct(id: number): Observable<Product> {
    const url = `${this.base_url}/${id}`;
    return this.http.get<Product>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched product id=${id}`)),
      catchError(this.handleError<Product>(`getProduct id=${id}`))
    );
  }

  /** GET product by module. Will 404 if id not found */
  getProductBy(id: number, _module: string): Observable<User[]> {
    const url = `${this.base_url}/${id}/${_module}`;
    return this.http.get<User[]>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched product id=${id}`)),
      catchError(this.handleError<User[]>(`getProduct id=${id}`))
    );
  }

  /* GET products whose name contains search term */
  searchProducts(term: string): Observable<Product[]> {
    if (!term.trim()) {
      // if not search term, return empty product array.
      return of([]);
    }
    return this.http.get<Product[]>(`api/products/?name=${term}`).pipe(
      tap(_ => this.log(`found products matching "${term}"`)),
      catchError(this.handleError<Product[]>('searchProducts', []))
    );
  }

  //////// Save methods //////////

  /** POST: add a new product to the server */
  addProduct(product: Product): Observable<Product> {
    return this.http.post<Product>(this.base_url , product, {headers: this.headers}).pipe(
      tap((product: Product) => this.log(`added product w/ id=${product.id}`)),
      catchError(this.handleError<Product>('addProduct'))
    );
  }

  /** DELETE: delete the product from the server */
  deleteProduct(product: Product | number): Observable<Product> {
    const id = typeof product === 'number' ? product : product.id;
    const url = `${this.base_url}/${id}`;

    return this.http.delete<Product>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`deleted product id=${id}`)),
      catchError(this.handleError<Product>('deleteProduct'))
    );
  }

  /** PUT: update the product on the server */
  updateProduct(product: Product): Observable<any> {
    return this.http.put(`${this.base_url}/${product.id}`, product, {headers: this.headers}).pipe(
      tap(_ => this.log(`updated product id=${product.id}`)),
      catchError(this.handleError<any>('updateProduct'))
    );
  }

  /** Patch: update the product on the server */
  editProduct(data): Observable<any> {
    return this.http.patch(`${this.base_url}/${data.id}/${data.param}/${data.value}`, {}, {headers: this.headers}).pipe(
      tap(_ => this.log(`updated product id=${data.id}`)),
      catchError(this.handleError<any>('updateProduct'))
    );
  }

  getProductsByPaygoType(query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        if (query[val] !== null && query[val] !== undefined && query[val] !== '')
          params = params.append(val, query[val]);
      });
    }
    params = params.append('tenantId', localStorage.getItem('tenant'));
    return this.http.get<any>(this.base_url + 's/payGo', {params, headers: this.headers}).pipe(
      tap(customers => this.log(`fetched customers`))
      ,
      catchError(this.handleError<any>('getSalesStaff')
      )
    );
  }

  getProductsByPaygoTypeWithoutSubunits(query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        if (query[val] !== null && query[val] !== undefined && query[val] !== '')
          params = params.append(val, query[val]);
      });
    }
    params = params.append('tenantId', localStorage.getItem('tenant'));
    return this.http.get<any>(this.base_url + 's/payGo/notSubunits', {params, headers: this.headers}).pipe(
      tap(_ => this.log(`fetched products by paygo type filtered without subunits`))
      ,
      catchError(this.handleError<any>('getSalesStaff')
      )
    );
  }

  getVersionsByLevel(query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        if (query[val] !== null && query[val] !== undefined && query[val] !== '')
          params = params.append(val, query[val]);
      });
    }
    return this.http.get<any>(this.base_url + 'versions', {params, headers: this.headers}).pipe(
      tap(customers => this.log(`fetched customers`))
      ,
      catchError(this.handleError<any>('getSalesStaff')
      )
    );
  }


  getAllParentVersionsForVersion(versionId): Observable<any> {

    return this.http.get<any>(this.base_url + 's/parents/' + versionId, {headers: this.headers}).pipe(
      tap(customers => this.log(`fetched customers`))
      ,
      catchError(this.handleError<any>('getSalesStaff')
      )
    );
  }
}
