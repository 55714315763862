import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import * as _ from 'lodash';

import {CustomRegion} from '@appModels/core-setup/custom-region/custom-region';
import {
  CustomRegionActions,
  CustomRegionActionTypes
} from '@appStore/actions/core-masterdata/custom-region/custom-region.actions';

export interface State extends EntityState<CustomRegion> {
  loaded: boolean;
  loading: boolean;
  error: any;
  selectedCustomRegionId: number;
  searchCustomRegions: CustomRegion[];
  paging: any;
}

export const adapter: EntityAdapter<CustomRegion> = createEntityAdapter<CustomRegion>();

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
  selectedCustomRegionId: null,
  error: null,
  searchTerm: '',
  searchCustomRegions: null,
  paging: null
});

export function reducer(state = initialState, action: CustomRegionActions): State {
  switch (action.type) {
    case CustomRegionActionTypes.customRegionGetCustomRegions:
    case CustomRegionActionTypes.customRegionAddCustomRegion:
    case CustomRegionActionTypes.customRegionDeleteCustomRegion:
    case CustomRegionActionTypes.customRegionUpdateCustomRegion:
    case CustomRegionActionTypes.customRegionSearchCustomRegions:
    case CustomRegionActionTypes.customRegionGetCustomRegionById:
    case CustomRegionActionTypes.customRegionGetCustomRegionsAll:
      return {
        ...state,
        loading: true
      };

    case CustomRegionActionTypes.customRegionGetCustomRegionsSuccess:
    case CustomRegionActionTypes.customRegionGetTenantCustomRegionsSuccess:
      return adapter.setAll(action.payload.content, {
        ...state,
        loading: false,
        loaded: true,
        paging: _.omit(action.payload, ['content'])
      });

    case CustomRegionActionTypes.customRegionGetCustomRegionByIdSuccess:
      return {
        ...state,
        selectedCustomRegionId: action.payload.id,
        loading: false
      };

    case CustomRegionActionTypes.customRegionGetCustomRegionsAllSuccess:
      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true,
        paging: null
      });

    case CustomRegionActionTypes.customRegionAddCustomRegionSuccess:
      return adapter.addOne(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });

    case CustomRegionActionTypes.customRegionUpdateCustomRegionSuccess: {
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state,
          loading: false,
          loaded: true
        }
      );
    }

    case CustomRegionActionTypes.customRegionDeleteCustomRegionSuccess: {
      return adapter.removeOne(action.payload.id, {
        ...state,
        loading: false,
        loaded: true
      });
    }

    case CustomRegionActionTypes.customRegionSearchCustomRegionsSuccess:
      return {
        ...state,
        searchCustomRegions: action.payload,
        loading: false
      };

    case CustomRegionActionTypes.customRegionSearchCustomRegionsReset:
      return {
        ...state,
        searchCustomRegions: null
      };

    case CustomRegionActionTypes.customRegionError:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload
      };

    default:
      return state;
  }
}

export const customRegionEntitySelectors = adapter.getSelectors();
