import {createEntityAdapter, EntityAdapter, EntityState,} from '@ngrx/entity';
import {ConsignmentProductAcceptReceiveStatus} from "@appModels/core-inventory/consignment-product-accept-receive-status/consignment-product-accept-receive-status";
import {
  ConsignmentProductAcceptReceiveStatusActions,
  ConsignmentProductAcceptReceiveStatusActionTypes
} from "@appStore/actions/core-inventory/consignment-product-accept-receive-status/consignment-product-accept-receive-status.action";


export interface State extends EntityState<ConsignmentProductAcceptReceiveStatus> {
  loaded: boolean;
  loading: boolean;
  error: any;
  selectedConsignmentProductAcceptReceiveStatusId: number;
  selectedConsignmentProductAcceptReceiveStatus: ConsignmentProductAcceptReceiveStatus;
  searchConsignmentProductAcceptReceiveStatus: ConsignmentProductAcceptReceiveStatus[];
}

export const adapter: EntityAdapter<ConsignmentProductAcceptReceiveStatus> = createEntityAdapter<ConsignmentProductAcceptReceiveStatus>();

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
  selectedConsignmentProductAcceptReceiveStatusId: null,
  selectedConsignmentProductAcceptReceiveStatus: null,
  error: null,
  searchTerm: '',
  searchConsignmentProductAcceptReceiveStatus: null
});

export function reducer(state = initialState, action: ConsignmentProductAcceptReceiveStatusActions): State {
  switch (action.type) {
    case ConsignmentProductAcceptReceiveStatusActionTypes.consignmentProductAcceptReceiveStatusGetConsignmentProductAcceptReceiveStatus:
    case ConsignmentProductAcceptReceiveStatusActionTypes.consignmentProductAcceptReceiveStatusAddConsignmentProductAcceptReceiveStatus:
    case ConsignmentProductAcceptReceiveStatusActionTypes.consignmentProductAcceptReceiveStatusAddAdditionalConsignmentProductAcceptReceiveStatusInfo:
    case ConsignmentProductAcceptReceiveStatusActionTypes.consignmentProductAcceptReceiveStatusDeleteConsignmentProductAcceptReceiveStatus:
    case ConsignmentProductAcceptReceiveStatusActionTypes.consignmentProductAcceptReceiveStatusUpdateConsignmentProductAcceptReceiveStatus:
    case ConsignmentProductAcceptReceiveStatusActionTypes.consignmentProductAcceptReceiveStatusSearchConsignmentProductAcceptReceiveStatus:
    case ConsignmentProductAcceptReceiveStatusActionTypes.consignmentProductAcceptReceiveStatusGetConsignmentProductAcceptReceiveStatusById:

      return {
        ...state,
        loading: true
      };

    case ConsignmentProductAcceptReceiveStatusActionTypes.consignmentProductAcceptReceiveStatusGetConsignmentProductAcceptReceiveStatusSuccess:
      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });

    case ConsignmentProductAcceptReceiveStatusActionTypes.consignmentProductAcceptReceiveStatusGetConsignmentProductAcceptReceiveStatusByIdSuccess:
      return {
        ...state,
        selectedConsignmentProductAcceptReceiveStatusId: action.payload.id,
        selectedConsignmentProductAcceptReceiveStatus: action.payload,
        loading: false
      };

    case ConsignmentProductAcceptReceiveStatusActionTypes.consignmentProductAcceptReceiveStatusAddConsignmentProductAcceptReceiveStatusSuccess:
      return adapter.addOne(action.payload, {
        ...state,
        selectedConsignmentProductAcceptReceiveStatusId: action.payload.id,
        loading: false,
        loaded: true
      });

    case ConsignmentProductAcceptReceiveStatusActionTypes.consignmentProductAcceptReceiveStatusUpdateConsignmentProductAcceptReceiveStatusSuccess: {
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state,
          loading: false,
          loaded: true
        }
      );
    }

    case ConsignmentProductAcceptReceiveStatusActionTypes.consignmentProductAcceptReceiveStatusDeleteConsignmentProductAcceptReceiveStatusSuccess: {
      return adapter.removeOne(action.payload.id, {
        ...state,
        loading: false,
        loaded: true
      });
    }

    case ConsignmentProductAcceptReceiveStatusActionTypes.consignmentProductAcceptReceiveStatusSearchConsignmentProductAcceptReceiveStatusSuccess:
      return {
        ...state,
        searchConsignmentProductAcceptReceiveStatus: action.payload,
        loading: false
      };

    case ConsignmentProductAcceptReceiveStatusActionTypes.consignmentProductAcceptReceiveStatusSearchConsignmentProductAcceptReceiveStatusReset:
      return {
        ...state,
        searchConsignmentProductAcceptReceiveStatus: null
      };

    case ConsignmentProductAcceptReceiveStatusActionTypes.consignmentProductAcceptReceiveStatusError:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload
      };

    default:
      return state;
  }
}

export const consignmentProductAcceptReceiveStatusEntitySelectors = adapter.getSelectors();
