import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getPartStore = createFeatureSelector('part');

export const getPartEntities = createSelector(
  getPartStore,
  fromReducers.part.partEntitySelectors.selectAll
);

export const getPartPaging = createSelector(
  getPartStore,
  (partStore: fromReducers.part.State) => partStore.paging
);


export const getParts = createSelector(getPartEntities, entities => {
  return Object.values(entities);
});


export const getPartsLoaded = createSelector(
  getPartStore,
  (partStore: fromReducers.part.State) => partStore.loaded
);

export const getPartsLoading = createSelector(
  getPartStore,
  (partStore: fromReducers.part.State) => partStore.loading
);

export const getSelectedPartId = createSelector(
  getPartStore,
  (partStore: fromReducers.part.State) => partStore.selectedPartId
);

export const getSearchParts = createSelector(
  getPartStore,
  (partStore: fromReducers.part.State) => partStore.searchParts
);

export const getPartById = createSelector(
  getPartStore,
  (partStore: fromReducers.part.State) => partStore.selectedPart
);

export const getPartError = createSelector(
  getPartStore,
  (partStore: fromReducers.part.State) => partStore.error
);
