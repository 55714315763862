import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {
  ReportSchemaActionTypes,
  ReportSchemaError,
  ReportSchemaGetForUser,
  ReportSchemaGetForUserSuccess,
  ReportSchemaGetByTenantSuccess,
  ReportSchemaGetByTenant
} from '../../../actions/core-reporting/report-schema/report-schema.actions';
import {ReportSchemaService} from '@appServices/core-reporting/report-schema/report-schema.service';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';

@Injectable()
export class ReportSchemaEffects {

  
  reportSchemasByTenant$ = createEffect(() => this.actions$.pipe(
    ofType(ReportSchemaActionTypes.reportSchemaGetByTenant),
    mergeMap((action: ReportSchemaGetByTenant) =>
      this.reportSchemaService
        .getReportSchemasByTenant(action.payload)
        .pipe(
          map(response => new ReportSchemaGetByTenantSuccess(response)),
          catchError(error => of(new ReportSchemaError(error)))
        )
    )
  ));

  
  reportSchemasForUser$ = createEffect(() => this.actions$.pipe(
    ofType(ReportSchemaActionTypes.reportSchemaGetForUser),
    mergeMap((action: ReportSchemaGetForUser) =>
      this.reportSchemaService
        .getReportSchemasForUser(action.payload)
        .pipe(
          map(response => new ReportSchemaGetForUserSuccess(response)),
          catchError(error => of(new ReportSchemaError(error)))
        )
    )
  ));


  constructor(
    private actions$: Actions,
    private reportSchemaService: ReportSchemaService,
    private toastr: ToastrService,
    private router: Router
  ) {
  }
}
