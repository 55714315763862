
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import { CoreCrmService } from '../core-crm.service';
import { Injectable } from '@angular/core';


@Injectable({ providedIn: 'root'})
export class CookstoveAdditionalInfoService extends CoreCrmService {
  constructor(
    private http: HttpClient,
    ) {
    super(http, 'cookstoveAccessoryInfo');
  }


  getCookstoveAdditionalInfo(customerId:number): Observable<any> {
    const url = `${this.base_url}/customer/${customerId}`;
    return this.http.get<any>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched CookstoveAdditionalInfo`)),
      catchError(this.handleError<any>(`getCookstoveAdditionalInfo`))
    );
  }

}
