import {Action} from '@ngrx/store';

import {Route} from '@appModels/core-fieldforce/route/route';

export enum RouteActionTypes {
  routeGetRoutes = '[Route] get',
  routeGetRoutesSuccess = '[Route] get routes success',
  routeGetRouteByTenant = '[Route] get routes by tenant id',
  routeGetRouteByTenantSuccess = '[Route] get routes by tenant id success',
  routeGetRouteByProductId = '[Route] get route by product id',
  routeGetRouteByProductIdSuccess = '[Route] get route by product id success',
  routeAddRoute = '[Route] add Route',
  routeAddRouteSuccess = '[Route] add route success',
  routeAddAdditionalRouteInfo = '[Route] add additional Route Info',
  routeAddAdditionalRouteInfoSuccess = '[Route] add additional route Info success',
  routeDeleteRoute = '[Route] delete route',
  routeDeleteRouteSuccess = '[Route] delete route success',
  routeGetRouteById = '[Route] get route by id',
  routeGetRouteByIdSuccess = '[Route] get route by id success',
  routeUpdateRoute = '[Route] update route',
  routeUpdateRouteSuccess = '[Route] update route success',
  routeSearchRoutes = '[Route] search routes',
  routeSearchRoutesSuccess = '[Route] search routes success',
  routeSearchRoutesReset = '[Route] search routes reset',
  routeError = '[Route] error',
  routeGetRoutesByTenantId = '[Route] get routes by tenant id',
  routeGetRoutesByTenantIdSuccess = '[Route] get routes by tenant id success',

}

export class GetRoutes implements Action {
  readonly type = RouteActionTypes.routeGetRoutes;
  constructor(public payload?) {}
}

export class GetRoutesSuccess implements Action {
  readonly type = RouteActionTypes.routeGetRoutesSuccess;
  constructor(public payload) {}
}

export class GetRoutesByTenantId implements Action {
  readonly type = RouteActionTypes.routeGetRoutesByTenantId;
}

export class GetRoutesByTenantIdSuccess implements Action {
  readonly type = RouteActionTypes.routeGetRoutesByTenantIdSuccess;

  constructor(public payload: Route[]) {
  }
}

export class AddRoute implements Action {
  readonly type = RouteActionTypes.routeAddRoute;
  constructor(public payload: Route) {}
}

export class GetRoutesByTenant implements Action {
  readonly type = RouteActionTypes.routeGetRouteByTenant;

  constructor(public payload) {
  }
}

export class GetRoutesByTenantSuccess implements Action {
  readonly type = RouteActionTypes.routeGetRouteByTenantSuccess;

  constructor(public payload: Route[]) {
  }
}

export class AddRouteSuccess implements Action {
  readonly type = RouteActionTypes.routeAddRouteSuccess;
  constructor(public payload: Route) {}
}

export class AddAdditionalRouteInfo implements Action {
  readonly type = RouteActionTypes.routeAddAdditionalRouteInfo;
  constructor(public payload) {}
}

export class AddAdditionalRouteInfoSuccess implements Action {
  readonly type = RouteActionTypes.routeAddAdditionalRouteInfoSuccess;
  constructor(public payload) {}
}

export class GetRouteById implements Action {
  readonly type = RouteActionTypes.routeGetRouteById;
  constructor(public payload: number) {}
}

export class GetRouteByIdSuccess implements Action {
  readonly type = RouteActionTypes.routeGetRouteByIdSuccess;
  constructor(public payload: Route) {}
}

export class GetRouteByProductId implements Action {
  readonly type = RouteActionTypes.routeGetRouteByProductId;
  constructor(public payload: number) {}
}

export class GetRouteByProductIdSuccess implements Action {
  readonly type = RouteActionTypes.routeGetRouteByProductIdSuccess;
  constructor(public payload: Route) {}
}

export class UpdateRoute implements Action {
  readonly type = RouteActionTypes.routeUpdateRoute;
  constructor(public payload: Route) {}
}

export class UpdateRouteSuccess implements Action {
  readonly type = RouteActionTypes.routeUpdateRouteSuccess;
  constructor(public payload: Route) {}
}

export class DeleteRoute implements Action {
  readonly type = RouteActionTypes.routeDeleteRoute;
  constructor(public payload: Route) {}
}

export class DeleteRouteSuccess implements Action {
  readonly type = RouteActionTypes.routeDeleteRouteSuccess;
  constructor(public payload: Route) {}
}

export class SearchRoutes implements Action {
  readonly type = RouteActionTypes.routeSearchRoutes;
  constructor(public payload: string) {}
}

export class SearchRoutesSuccess implements Action {
  readonly type = RouteActionTypes.routeSearchRoutesSuccess;
  constructor(public payload: Route[]) {}
}

export class SearchRoutesReset implements Action {
  readonly type = RouteActionTypes.routeSearchRoutesReset;
}

export class RouteError implements Action {
  readonly type = RouteActionTypes.routeError;
  constructor(public payload: any) {}
}

export type RouteActions =
  | GetRoutes
  | GetRoutesSuccess
  | AddRoute
  | AddRouteSuccess
  | GetRoutesByTenant
  | GetRoutesByTenantSuccess
  | AddAdditionalRouteInfo
  | AddAdditionalRouteInfoSuccess
  | GetRouteById
  | GetRouteByIdSuccess
  | GetRouteByProductId
  | GetRouteByProductIdSuccess
  | UpdateRoute
  | UpdateRouteSuccess
  | DeleteRoute
  | DeleteRouteSuccess
  | SearchRoutes
  | SearchRoutesSuccess
  | SearchRoutesReset
  | RouteError
  | GetRoutesByTenantId
  | GetRoutesByTenantIdSuccess;
