import {ChangeDetectorRef, Component, TemplateRef, ViewChild} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import {ToastrService} from "ngx-toastr";
import * as moment from "moment/moment";
import {Router} from "@angular/router";
import {TicketTypeService} from "@appServices/core-ticketing/ticket-type.service";
import {TicketTypeListRow} from "@appModels/ticketing/ticket-type-list-row";

@Component({
  moduleId: module.id,
  templateUrl: './ticket-type-table.component.html',
  animations: [
    trigger('toggleAnimation', [
      transition(':enter', [style({ opacity: 0, transform: 'scale(0.95)' }), animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' }))]),
      transition(':leave', [animate('75ms', style({ opacity: 0, transform: 'scale(0.95)' }))]),
    ]),
  ],
  styleUrls: ['./ticket-type-table.component.css', '../../../tailwind.css']
})
export class TicketTypeTableComponent {
  constructor(
    private toastrService: ToastrService,
    private ref: ChangeDetectorRef,
    private ticketTypeService: TicketTypeService,
    private router : Router
  ) {
    this.getMaps();
  }

  loadingTable = true;
  dateFormat = moment().format('YYYY-MM-DD hh:mm:ss');
  moment: any = moment;

  getMaps() {
    this.ticketTypeService.getTicketTypes(true).subscribe(x=> {
      this.ticketTypes = x;
      this.loadingTable = false;
    })
  }

  ticketTypes : TicketTypeListRow[] = [];

  goToUpdateTicketType(id:any) {
    this.router.navigateByUrl(`/ticketing/ticketType/` + id);

  }

}
