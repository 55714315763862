import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import {catchError, map, mergeMap} from 'rxjs/operators';
import {of} from 'rxjs';

import {InventoryProductService} from "@appServices/core-inventory/inventory-product/inventory-product.service";

import {
  AddInventoryProduct,
  DeleteInventoryProduct,
  DeleteInventoryProductSuccess,
  GetInventoryProductById,
  GetInventoryProductByIdSuccess,
  GetInventoryProducts,
  GetInventoryProductsSuccess,
  InventoryProductActionTypes,
  InventoryProductError,
  PatchInventoryProduct,
  PatchInventoryProductSuccess,
  UpdateInventoryProduct,
  UploadInventoryProduct
} from "@appStore/actions/core-inventory/inventory-product/inventory-product.actions";
import {ToastrService} from "ngx-toastr";
import {
  GetTenantInventoryProductLocationStatus,
  GetTenantInventoryProductLocationStatusSuccess,
  TenantInventoryProductLocationStatusActionTypes,
  TenantInventoryProductLocationStatusError
} from "../../../actions/core-inventory/inventory-product/tenant-inventory-product-location-status.actions";


@Injectable()
export class TenantInventoryProductLocationStatusEffects {

  
  loadTenantInventoryProductsLocationStatus$ = createEffect(() => this.actions$.pipe(
    ofType(TenantInventoryProductLocationStatusActionTypes.inventoryProductGetTenantInventoryProductLocationStatus),
    mergeMap((action: GetTenantInventoryProductLocationStatus) =>
      this.inventoryProductService
        .getTenantInventoryProductLocationStatus(action.payload)
        .pipe(
          map(tenantInventoryProductLocationStatus => new GetTenantInventoryProductLocationStatusSuccess(tenantInventoryProductLocationStatus)),
          catchError(error => of(new TenantInventoryProductLocationStatusError(error)))
        )
    )
  ));


  constructor(
    private actions$: Actions,
    private toastr: ToastrService,
    private inventoryProductService: InventoryProductService,
  ) {
  }


}



