import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import {InventoryProduct} from "@appModels/core-inventory/inventory-product/inventory-product";
import {
  ConsignmentProductInventoryProductActions,
  ConsignmentProductInventoryProductActionTypes
} from "@appStore/actions/core-inventory/consignment-product/consignment-product-inventory-product.action";
import * as _ from 'lodash';

export interface State extends EntityState<InventoryProduct> {
  loaded: boolean;
  loading: boolean;
  error: any;
  paging: any;
}

export const adapter: EntityAdapter<InventoryProduct> = createEntityAdapter<InventoryProduct>();

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
  error: null,
  paging: null
});

export function reducer(state = initialState, action: ConsignmentProductInventoryProductActions): State {
  switch (action.type) {
    case ConsignmentProductInventoryProductActionTypes.consignmentProductGetStockingPointConsignmentProductsNotBooked:
    case ConsignmentProductInventoryProductActionTypes.consignmentProductNotInStockingPoints:
      return {
        ...state,
        loading: true
      };

    case ConsignmentProductInventoryProductActionTypes.consignmentProductGetStockingPointConsignmentProductsNotBookedSuccess: {
      return adapter.setAll(action.payload.content, {
        ...state,
        loading: false,
        loaded: true,
        paging: _.omit(action.payload, ['content'])
      });
    }

    case ConsignmentProductInventoryProductActionTypes.consignmentProductNotInStockingPointsSuccess: {
      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });
    }

    case ConsignmentProductInventoryProductActionTypes.consignmentProductRemoveStockingPointConsignmentProductsNotBooked:
      return adapter.removeOne(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });

    default:
      return state;
  }
}

export const consignmentProductInventoryProductEntitySelectors = adapter.getSelectors();
