import {createEntityAdapter, EntityAdapter, EntityState,} from '@ngrx/entity';

import {WorkorderTestflow} from '../../../../models/core-aftersales/workorder-testflow/workorder-testflow';
import {
  WorkorderTestFlowActions,
  WorkorderTestFlowActionTypes
} from '../../../actions/core-aftersales/workorder-test-flow/workorder-test-flow.actions';

export interface State extends EntityState<WorkorderTestflow> {
  loaded: boolean;
  loading: boolean;
  error: any;
  selectedWorkOrderTestFlow: any;
  selectedWorkOrderTestFlowId: number;
  searchWorkOrderTestFlows: WorkorderTestflow[];
  paging: any;
}


export const adapter: EntityAdapter<WorkorderTestflow> = createEntityAdapter<WorkorderTestflow>();

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
  selectedWorkOrderTestFlowId: null,
  selectedWorkOrderTestFlow: null,
  error: null,
  searchWorkOrderTestFlows: null,
  paging: null
});

export function reducer(state = initialState, action: WorkorderTestFlowActions): State {
  switch (action.type) {
    case WorkorderTestFlowActionTypes.workordertestflowGettestflows:
    case WorkorderTestFlowActionTypes.workordertestflowAddtestflow:
    case WorkorderTestFlowActionTypes.workordertestflowDeletetestflow:
    case WorkorderTestFlowActionTypes.workordertestflowUpdatetestflow:
    case WorkorderTestFlowActionTypes.workordertestflowSearchtestflows:
    case WorkorderTestFlowActionTypes.workordertestflowGettestflowById:
    case WorkorderTestFlowActionTypes.workordertestflowGetestflowsByTenantId:
    case WorkorderTestFlowActionTypes.workordertestflowFiltertestflow:
    case WorkorderTestFlowActionTypes.workordertestflowGettestflowsByProduct:
      return {
        ...state,
        loading: true
      };


    case WorkorderTestFlowActionTypes.workordertestflowGettestflowsSuccess:
      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true,
        //paging: _.omit(action.payload, ['content'])

      });


    case WorkorderTestFlowActionTypes.workordertestflowFiltertestflowSuccess:
      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true,
        //paging: _.omit(action.payload, ['content'])
      });


    case WorkorderTestFlowActionTypes.workordertestflowGettestflowByIdSuccess:
      return {
        ...state,
        selectedWorkOrderTestFlowId: action.payload.id,
        loading: false,
        selectedWorkOrderTestFlow: action.payload
      };

    case WorkorderTestFlowActionTypes.workordertestflowGettestflowsByProductSuccess:
      return {
        ...state,
        selectedWorkOrderTestFlowId: action.payload.id,
        loading: false,
        selectedWorkOrderTestFlow: action.payload
      };


    case WorkorderTestFlowActionTypes.workordertestflowAddStestflowSuccess:
      return {...state, loading: false};

    /*return adapter.addOne(action.payload, {
      ...state,
      //selectedTestFlowId: action.payload.id,
      loading: false,
      loaded: true
    });
    */

    case WorkorderTestFlowActionTypes.workordertestflowGetestflowsByTenantIdSuccess:

      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });

    case WorkorderTestFlowActionTypes.workordertestflowUpdatetestflowSuccess: {

      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state,
          loading: false,
          loaded: true
        }
      );
    }

    case WorkorderTestFlowActionTypes.workordertestflowDeletetestflowSuccess: {

      return adapter.removeOne(action.payload.id, {
        ...state,
        loading: false,
        loaded: true
      });
    }

    case WorkorderTestFlowActionTypes.workordertestflowSearchtestflowsSuccess:

      return {
        ...state,
        searchWorkOrderTestFlows: action.payload,
        loading: false
      };

    case WorkorderTestFlowActionTypes.workordertestflowSearchtestflowsReset:

      return {
        ...state,
        searchWorkOrderTestFlows: null
      };

    case WorkorderTestFlowActionTypes.workordertestflowsError:

      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload
      };

    default:
      return state;
  }
}

export const workordertestFlowEntitySelectors = adapter.getSelectors();
