import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { RateFinancedDefinition } from '@appModels/core-accounting/rate-financed-definition/rate-financed-definition';

import { CoreAccountingService } from '../core-accounting.service';



@Injectable({ providedIn: 'root' })
export class RateFinancedDefinitionService extends CoreAccountingService {


  constructor(
    private http: HttpClient,
    
  ) {
    super(http, 'ratefinanceddefinition');
  }

  /** GET rateFinancedDefinitions from the server */
  getRateFinancedDefinitions(): Observable<RateFinancedDefinition[]> {
    return this.http.get<RateFinancedDefinition[]>(this.base_url + '/', { headers: this.headers }).pipe(
      tap(rateFinancedDefinitions => this.log(`fetched rateFinancedDefinitions`)),
      catchError(this.handleError('getRateFinancedDefinitions', []))
    );
  }

  /** GET rateFinancedDefinitions from the server */
  getRateFinancedDefinitionTimeIntervals(): Observable<RateFinancedDefinition[]> {
    return this.http.get<RateFinancedDefinition[]>(this.base_url + '/timeintervalvariable', { headers: this.headers }).pipe(
      tap(rateFinancedDefinitions => this.log(`fetched rateFinancedDefinitions`)),
      catchError(this.handleError('getRateFinancedDefinitions', []))
    );
  }

  /** GET rateFinancedDefinition by id. Return `undefined` when id not found */
  getRateFinancedDefinitionNo404<Data>(id: number): Observable<RateFinancedDefinition> {
    const url = `${this.base_url}/?id=${id}`;
    return this.http.get<RateFinancedDefinition[]>(url).pipe(
      map(rateFinancedDefinitions => rateFinancedDefinitions[0]), // returns a {0|1} element array
      tap(h => {
        const outcome = h ? `fetched` : `did not find`;
        this.log(`${outcome} rateFinancedDefinition id=${id}`);
      }),
      catchError(this.handleError<RateFinancedDefinition>(`getRateFinancedDefinition id=${id}`))
    );
  }

  /** GET rateFinancedDefinition by id. Will 404 if id not found */
  getRateFinancedDefinition(id: number): Observable<RateFinancedDefinition> {
    const url = `${this.base_url}/${id}/`;
    return this.http.get<RateFinancedDefinition>(url,{ headers: this.headers }).pipe(
      tap(_ => this.log(`fetched rateFinancedDefinition id=${id}`)),
      catchError(this.handleError<RateFinancedDefinition>(`getRateFinancedDefinition id=${id}`))
    );
  }

  /* GET rateFinancedDefinitions whose name contains search term */
  searchRateFinancedDefinitions(term: string): Observable<RateFinancedDefinition[]> {
    if (!term.trim()) {
      // if not search term, return empty rateFinancedDefinition array.
      return of([]);
    }
    return this.http.get<RateFinancedDefinition[]>(`api/rateFinancedDefinitions/?name=${term}`).pipe(
      tap(_ => this.log(`found rateFinancedDefinitions matching "${term}"`)),
      catchError(this.handleError<RateFinancedDefinition[]>('searchRateFinancedDefinitions', []))
    );
  }

  //////// Save methods //////////
  addRateFinancedDefinition(rateFinancedDefinition: RateFinancedDefinition): Observable<RateFinancedDefinition> {
    return this.http.post<RateFinancedDefinition>(this.base_url + '/', rateFinancedDefinition, { headers: this.headers }).pipe(
      tap((rateFinancedDefinition: RateFinancedDefinition) => this.log(`added rate w/ id=${rateFinancedDefinition.id}`)),
      catchError(this.handleError<RateFinancedDefinition>('addRateFinancedDefinition'))
    );
  }

  /** POST: add a new rateFinancedDefinition to the server */
  addRateFinancedDefinitionAdditionalInfo(rateFinancedDefinition): Observable<{}> {
    return this.http.post(`${this.base_url}/additionalInfo/${rateFinancedDefinition.id}`, rateFinancedDefinition.value, { headers: this.headers }).pipe(
      tap((res) => this.log(`added rateFinancedDefinition  info `)),
      catchError(this.handleError('addRateFinancedDefinition'))
    );
  }

  /** DELETE: delete the rateFinancedDefinition from the server */
  deleteRateFinancedDefinition(rateFinancedDefinition: RateFinancedDefinition | number): Observable<RateFinancedDefinition> {
    const id = typeof rateFinancedDefinition === 'number' ? rateFinancedDefinition : rateFinancedDefinition.id;
    const url = `${this.base_url}/${id}`;

    return this.http.delete<RateFinancedDefinition>(url, { headers: this.headers }).pipe(
      tap(_ => this.log(`deleted rateFinancedDefinition id=${id}`)),
      catchError(this.handleError<RateFinancedDefinition>('deleteRateFinancedDefinition'))
    );
  }

  // /** PUT: update the rateFinancedDefinition on the server */
  updateRateFinancedDefinition(rateFinancedDefinition: RateFinancedDefinition): Observable<any> {
    return this.http.put(`${this.base_url}/${rateFinancedDefinition.id}`, rateFinancedDefinition, { headers: this.headers }).pipe(
      tap(_ => this.log(`updated rateFinancedDefinition id=${rateFinancedDefinition.id}`)),
      catchError(this.handleError<any>('updateRateFinancedDefinition'))
    );
  }

    /** PUT: update the rateFinancedDefinition on the server */
    financedRateUpdate(rateFinancedDefinition): Observable<any> {
      return this.http.put(`${this.base_url}/${rateFinancedDefinition.id}`, rateFinancedDefinition, { headers: this.headers }).pipe(
        tap(_ => this.log(`updated rateFinancedDefinition id=${rateFinancedDefinition.id}`)),
        catchError(this.handleError<any>('updateRateFinancedDefinition'))
      );
    }

  // updateRateFinancedDefinition(rateFinancedDefinition: RateFinancedDefinition): Observable<RateFinancedDefinition> {
  //   return this.http.put<RateFinancedDefinition>(`${this.base_url}/${rateFinancedDefinition.id}`, rateFinancedDefinition, { headers: this.headers }).pipe(
  //     tap((newFinancedRate: RateFinancedDefinition) => {
  //       this.log(`added rateFinancedDefinition w/ id=${newFinancedRate.id}`);
  //       // patch info
  //       this.editRateFinancedDefinition({ value: rateFinancedDefinition.rateId, id: newFinancedRate.id, param: 'rate' }).subscribe(data => {
  //       }, error => console.log(error, 'rate error'));
  //       //
  //       this.editRateFinancedDefinition({ value: rateFinancedDefinition.timeIntervalVariableId, id: newFinancedRate.id, param: 'timeintervalvariable' }).subscribe(data => {
  //       }, error => console.log(error, 'time interval error'));
  //       //
  //     }),
  //     catchError(this.handleError<RateFinancedDefinition>('addRateFinancedDefinition'))
  //   );
  // }

  /** Patch: update the rateFinancedDefinition on the server */
  editRateFinancedDefinition(data): Observable<any> {
    return this.http.patch(`${this.base_url}/${data.id}/${data.param}/${data.value}`, {}, { headers: this.headers }).pipe(
      tap(_ => this.log(`edit financed rate id=${data.id}`)),
      catchError(this.handleError<any>('editFinancedRate'))
    );
  }

}
