import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable, of} from 'rxjs';

import {CoreTicketingService} from "@appServices/core-ticketing/core-ticketing.service";
import {catchError, tap} from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class WorkflowService extends CoreTicketingService {

  constructor(
    private http: HttpClient,
  ) {
    super(http, 'workflow');
    this.headers = this.headers.append('skip-cache', 'true');
  }

  getListRows(useTenant: boolean): Observable<any> {
    let tenantId = localStorage.getItem('tenant');
    let params = {};
    if (useTenant) {
      params = {
        tenantId: tenantId
      }
    }

    const url = `${this.base_url}/getListRows`;
    return this.http.get<any>(url, {params: params,headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }

  createWorkflow(workflow:any, useTenant: boolean): Observable<any> {
    let tenantId = localStorage.getItem('tenant');
    let params = {};
    if (useTenant) {
      params = {
        tenantId: tenantId
      }
    }

    const url = `${this.base_url}/createWorkflow`;
    return this.http.post<any>(url, workflow, {params: params,headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }

  updateWorkflow(workflow:any, useTenant: boolean): Observable<any> {
    let tenantId = localStorage.getItem('tenant');
    let params = {};
    if (useTenant) {
      params = {
        tenantId: tenantId
      }
    }

    const url = `${this.base_url}/updateWorkflow`;
    return this.http.post<any>(url, workflow, {params: params,headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }

  getWorkflowDetails(id:number): Observable<any> {
    const url = `${this.base_url}/getWorkflowDetails/${id}`;
    return this.http.get<any>(url, {headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }

}
