import { Action } from '@ngrx/store';
import {
  EntityAdapter,
  createEntityAdapter,
  EntityState,
  Update
} from '@ngrx/entity';

import { RateFinancedDefinition } from '@appModels/core-accounting/rate-financed-definition/rate-financed-definition';
import { RateFinancedDefinitionActions, RateFinancedDefinitionActionTypes } from '@appStore/actions/core-accounting/rate-financed-definition/rate-financed-definition.actions';

export interface State extends EntityState<RateFinancedDefinition> {
  loaded: boolean;
  loading: boolean;
  error: any;
  selectedRateFinancedDefinitionId: number;
  searchRateFinancedDefinitions: RateFinancedDefinition[];
}

export const adapter: EntityAdapter<RateFinancedDefinition> = createEntityAdapter<RateFinancedDefinition>();

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
  selectedRateFinancedDefinitionId: null,
  error: null,
  searchTerm: '',
  searchRateFinancedDefinitions: null
});

export function reducer(state = initialState, action: RateFinancedDefinitionActions): State {
  switch (action.type) {
    case RateFinancedDefinitionActionTypes.rateFinancedDefinitionGetRateFinancedDefinitions:
    case RateFinancedDefinitionActionTypes.rateFinancedDefinitionAddRateFinancedDefinition:
    case RateFinancedDefinitionActionTypes.rateFinancedDefinitionAddFinancedRate:
    case RateFinancedDefinitionActionTypes.rateFinancedDefinitionAddAdditionalRateFinancedDefinitionInfo:
    case RateFinancedDefinitionActionTypes.rateFinancedDefinitionEditRateFinancedDefinition:
    case RateFinancedDefinitionActionTypes.rateFinancedDefinitionDeleteRateFinancedDefinition:
    case RateFinancedDefinitionActionTypes.rateFinancedDefinitionUpdateRateFinancedDefinition:
    case RateFinancedDefinitionActionTypes.rateFinancedDefinitionSearchRateFinancedDefinitions:
    case RateFinancedDefinitionActionTypes.rateFinancedDefinitionGetRateFinancedDefinitionById:
      return {
        ...state,
        loading: true
      };

    case RateFinancedDefinitionActionTypes.rateFinancedDefinitionGetRateFinancedDefinitionsSuccess:
      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });

    case RateFinancedDefinitionActionTypes.rateFinancedDefinitionGetRateFinancedDefinitionByIdSuccess:
      return { ...state, selectedRateFinancedDefinitionId: action.payload.id, loading: false };

    case RateFinancedDefinitionActionTypes.rateFinancedDefinitionAddRateFinancedDefinitionSuccess:
      return adapter.addOne(action.payload, {
        ...state,
        selectedRateFinancedDefinitionId: action.payload.id,
        loading: false,
        loaded: true
      });

    case RateFinancedDefinitionActionTypes.rateFinancedDefinitionUpdateRateFinancedDefinitionSuccess: {
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state,
          loading: false,
          loaded: true
        }
      );
    }

    case RateFinancedDefinitionActionTypes.rateFinancedDefinitionEditRateFinancedDefinitionSuccess: {
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state,
          loading: false,
          loaded: true
        }
      );
    }

    case RateFinancedDefinitionActionTypes.rateFinancedDefinitionDeleteRateFinancedDefinitionSuccess: {
      return adapter.removeOne(action.payload.id, {
        ...state,
        loading: false,
        loaded: true
      });
    }

    case RateFinancedDefinitionActionTypes.rateFinancedDefinitionSearchRateFinancedDefinitionsSuccess:
      return {
        ...state,
        searchRateFinancedDefinitions: action.payload,
        loading: false
      };

    case RateFinancedDefinitionActionTypes.rateFinancedDefinitionSearchRateFinancedDefinitionsReset:
      return {
        ...state,
        searchRateFinancedDefinitions: null
      };

    case RateFinancedDefinitionActionTypes.rateFinancedDefinitionError:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload
      };

    default:
      return state;
  }
}

export const rateFinancedDefinitionEntitySelectors = adapter.getSelectors();
