import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getRateFinancedDefinitionStore = createFeatureSelector('rateFinancedDefinition');

export const getRateFinancedDefinitionEntities = createSelector(
  getRateFinancedDefinitionStore,
  fromReducers.rateFinancedDefinition.rateFinancedDefinitionEntitySelectors.selectAll
);

export const getRateFinancedDefinitions = createSelector(getRateFinancedDefinitionEntities, entities => {
  return Object.values(entities);
});

export const getTopRateFinancedDefinitions = createSelector(getRateFinancedDefinitionEntities, entities => {
  return Object.values(entities).slice(1, 5);
});

export const getRateFinancedDefinitionsLoaded = createSelector(
  getRateFinancedDefinitionStore,
  (rateFinancedDefinitionStore: fromReducers.rateFinancedDefinition.State) => rateFinancedDefinitionStore.loaded
);

export const getRateFinancedDefinitionsLoading = createSelector(
  getRateFinancedDefinitionStore,
  (rateFinancedDefinitionStore: fromReducers.rateFinancedDefinition.State) => rateFinancedDefinitionStore.loading
);

export const getSelectedRateFinancedDefinitionId = createSelector(
  getRateFinancedDefinitionStore,
  (rateFinancedDefinitionStore: fromReducers.rateFinancedDefinition.State) => rateFinancedDefinitionStore.selectedRateFinancedDefinitionId
);

export const getSearchRateFinancedDefinitions = createSelector(
  getRateFinancedDefinitionStore,
  (rateFinancedDefinitionStore: fromReducers.rateFinancedDefinition.State) => rateFinancedDefinitionStore.searchRateFinancedDefinitions
);

export const getRateFinancedDefinitionById = createSelector(
  getRateFinancedDefinitionEntities,
  getSelectedRateFinancedDefinitionId,
  (entities, id) => entities.find(i => i.id === id)
);

export const getRateFinancedDefinitionsError = createSelector(
  getRateFinancedDefinitionStore,
  (rateFinancedDefinitionStore: fromReducers.rateFinancedDefinition.State) => rateFinancedDefinitionStore.error
);
