import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

import {
  AddDelinquencyDefinition,
  AddDelinquencyDefinitionSuccess,
  DeleteDelinquencyDefinition,
  DeleteDelinquencyDefinitionSuccess,
  GetDelinquencyDefinitionById,
  GetDelinquencyDefinitionByIdSuccess,
  GetDelinquencyDefinitionByProductId,
  GetDelinquencyDefinitionByProductIdSuccess,
  GetDelinquencyDefinitions,
  GetDelinquencyDefinitionsByTenant,
  GetDelinquencyDefinitionsByTenantSuccess,
  GetDelinquencyDefinitionsSuccess,
  DelinquencyDefinitionActionTypes,
  DelinquencyDefinitionError,
  SearchDelinquencyDefinitions,
  SearchDelinquencyDefinitionsSuccess,
  UpdateDelinquencyDefinitionSuccess
} from '@appStore/actions/core-accounting/delinquency-definition/delinquency-definition.actions';
import {DelinquencyDefinitionService} from '@appServices/core-accounting/delinquency-definition/delinquency-definition.service';
import {ToastrService} from 'ngx-toastr';
import { Router } from '@angular/router';

@Injectable()
export class DelinquencyDefinitionEffects {
  constructor(
    private actions$: Actions, 
    private delinquencyDefinitionService: DelinquencyDefinitionService,
    private toastr: ToastrService,
    private router: Router
    ) {}

  
  loadDelinquencyDefinitions$ = createEffect(() => this.actions$.pipe(
    ofType(DelinquencyDefinitionActionTypes.delinquencyDefinitionGetDelinquencyDefinitions),
    mergeMap((action: GetDelinquencyDefinitions) =>
      this.delinquencyDefinitionService
        .getDelinquencyDefinitions(action.payload)
        .pipe(
          map(delinquencyDefinitions => new GetDelinquencyDefinitionsSuccess(delinquencyDefinitions)),
          catchError(error => of(new DelinquencyDefinitionError(error)))
        )
    )
  ));

  // load delinquencyDefinition by tenant product catalogue ID
  // @Effect()
  // loadDelinquencyDefinitionByProductId$ = this.actions$.pipe(
  //   ofType(DelinquencyDefinitionActionTypes.delinquencyDefinitionGetDelinquencyDefinitionByProductId),
  //   mergeMap(() =>
  //     this.delinquencyDefinitionService
  //       .getDelinquencyDefinitionBy(+localStorage.getItem('tenant'))
  //       .pipe(
  //         map(delinquencyDefinition => new GetDelinquencyDefinitionByProductIdSuccess(delinquencyDefinition)),
  //         catchError(error => of(new DelinquencyDefinitionError(error)))
  //       )
  //   )
  // );

  // @Effect()
  // loadDelinquencyDefinitionsByTenant$ = this.actions$.pipe(
  //   ofType(DelinquencyDefinitionActionTypes.delinquencyDefinitionGetDelinquencyDefinitions),
  //   mergeMap(() =>
  //     this.delinquencyDefinitionService
  //       .getDelinquencyDefinitionByTenant(+localStorage.getItem('tenant'))
  //       .pipe(
  //         map(delinquencyDefinitions => new GetDelinquencyDefinitionsSuccess(delinquencyDefinitions)),
  //         catchError(error => of(new DelinquencyDefinitionError(error)))
  //       )
  //   )
  // );

  
  getDelinquencyDefinitionById$ = createEffect(() => this.actions$.pipe(
    ofType(DelinquencyDefinitionActionTypes.delinquencyDefinitionGetDelinquencyDefinitionById),
    mergeMap((action: GetDelinquencyDefinitionById) =>
      this.delinquencyDefinitionService
        .getDelinquencyDefinition(action.payload)
        .pipe(
          map(delinquencyDefinition => new GetDelinquencyDefinitionByIdSuccess(delinquencyDefinition)),
          catchError(error => of(new DelinquencyDefinitionError(error)))
        )
    )
  ));

  
  addDelinquencyDefinition$ = createEffect(() => this.actions$.pipe(
    ofType(DelinquencyDefinitionActionTypes.delinquencyDefinitionAddDelinquencyDefinition),
    switchMap((action: AddDelinquencyDefinition) =>
      this.delinquencyDefinitionService
        .addDelinquencyDefinition(action.payload)
        .pipe(
          map(
            delinquencyDefinition => {
              if (delinquencyDefinition !== undefined) {
                this.toastr.success('Delinquency Definition has been successfully added!', 'Successful!');
                this.router.navigateByUrl(`/setup/delinquency-definition`);
                return new GetDelinquencyDefinitions(delinquencyDefinition)
              }
              return new DelinquencyDefinitionError({ type: 500, message: 'Internal error' })
            }
            ),
          catchError(error => of(new DelinquencyDefinitionError(error)))
        )
    )
  ));

  
  addAdditionalDelinquencyDefinitionInfo$ = createEffect(() => this.actions$.pipe(
    ofType(DelinquencyDefinitionActionTypes.delinquencyDefinitionAddAdditionalDelinquencyDefinitionInfo),
    mergeMap((action: AddDelinquencyDefinition) =>
      this.delinquencyDefinitionService
        .addDelinquencyDefinitionAdditionalInfo(action.payload)
        .pipe(
          map(delinquencyDefinition => new GetDelinquencyDefinitions()),
          catchError(error => of(new DelinquencyDefinitionError(error)))
        )
    )
  ));

  
  updateDelinquencyDefinition$ = createEffect(() => this.actions$.pipe(
    ofType(DelinquencyDefinitionActionTypes.delinquencyDefinitionUpdateDelinquencyDefinition),
    mergeMap((action: AddDelinquencyDefinition) =>
      this.delinquencyDefinitionService
        .updateDelinquencyDefinition(action.payload)
        .pipe(
          map(delinquencyDefinition => {
            if (delinquencyDefinition !== undefined) {
              this.toastr.success('DelinquencyDefinition has been successfully updated!', 'Successful!');
              this.router.navigateByUrl(`/setup/delinquency-definition`);
              return  new UpdateDelinquencyDefinitionSuccess(action.payload)
            }
            // this.toastr.error('There was an error updating this delinquencyDefinition', "Unknown error");
            return new DelinquencyDefinitionError({ type: 500, message: 'Internal error' })
          }),
          catchError(error => of(new DelinquencyDefinitionError(error)))
        )
    )
  ));

  
  deleteDelinquencyDefinition$ = createEffect(() => this.actions$.pipe(
    ofType(DelinquencyDefinitionActionTypes.delinquencyDefinitionDeleteDelinquencyDefinition),
    mergeMap((action: DeleteDelinquencyDefinition) =>
      this.delinquencyDefinitionService
        .deleteDelinquencyDefinition(action.payload)
        .pipe(
          map(() => new DeleteDelinquencyDefinitionSuccess(action.payload)),
          catchError(error => of(new DelinquencyDefinitionError(error)))
        )
    )
  ));

  
  searchDelinquencyDefinitions$ = createEffect(() => this.actions$.pipe(
    ofType(DelinquencyDefinitionActionTypes.delinquencyDefinitionSearchDelinquencyDefinitions),
    mergeMap((action: SearchDelinquencyDefinitions) =>
      this.delinquencyDefinitionService
        .searchDelinquencyDefinitions(action.payload)
        .pipe(
          map(delinquencyDefinitions => new SearchDelinquencyDefinitionsSuccess(delinquencyDefinitions)),
          catchError(error => of(new DelinquencyDefinitionError(error)))
        )
    )
  ));

  // @Effect()
  // updateDelinquencyDefinitionSuccess$ = this.actions$.pipe(
  //   ofType(DelinquencyDefinitionActionTypes.delinquencyDefinitionUpdateDelinquencyDefinitionSuccess),
  //   map(delinquencyDefinition => new fromRouterActions.Go({ path: ['/setup/delinquencyDefinitions'] }))
  // );
}
