import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { mergeMap, map, catchError, tap, debounceTime, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import {
  ProductTypeActionTypes,
  GetProductTypes,
  GetProductTypesSuccess,
  ProductTypeError,
  AddProductType,
  AddProductTypeSuccess,
  DeleteProductTypeSuccess,
  DeleteProductType,
  GetProductTypeById,
  GetProductTypeByIdSuccess,
  UpdateProductTypeSuccess,
  SearchProductTypes,
  SearchProductTypesSuccess,
  EditProductTypeSuccess
} from '@appStore/actions/core-masterdata/product-type/product-type.actions';
import { ProductTypeService } from '@appServices/core-masterdata/product-type/product-type.service';

import * as fromRouterActions from '@appStore/actions/router.actions';

@Injectable()
export class ProductTypeEffects {
  constructor(private actions$: Actions, private productTypeService: ProductTypeService) {}

  
  loadProductTypes$ = createEffect(() => this.actions$.pipe(
    ofType(ProductTypeActionTypes.productTypeGetProductTypes),
    mergeMap(() =>
      this.productTypeService
        .getProductTypes()
        .pipe(
          map(productTypes => new GetProductTypesSuccess(productTypes)),
          catchError(error => of(new ProductTypeError(error)))
        )
    )
  ));

  
  getProductTypeById$ = createEffect(() => this.actions$.pipe(
    ofType(ProductTypeActionTypes.productTypeGetProductTypeById),
    mergeMap((action: GetProductTypeById) =>
      this.productTypeService
        .getProductType(action.payload)
        .pipe(
          map(productType => new GetProductTypeByIdSuccess(productType)),
          catchError(error => of(new ProductTypeError(error)))
        )
    )
  ));

  
  addProductType$ = createEffect(() => this.actions$.pipe(
    ofType(ProductTypeActionTypes.productTypeAddProductType),
    switchMap((action: AddProductType) =>
      this.productTypeService
        .addProductType(action.payload)
        .pipe(
          map(productType => new AddProductTypeSuccess(productType)),
          catchError(error => of(new ProductTypeError(error)))
        )
    )
  ));

  
  updateProductType$ = createEffect(() => this.actions$.pipe(
    ofType(ProductTypeActionTypes.productTypeUpdateProductType),
    mergeMap((action: AddProductType) =>
      this.productTypeService
        .updateProductType(action.payload)
        .pipe(
          map(productType => new UpdateProductTypeSuccess(action.payload)),
          catchError(error => of(new ProductTypeError(error)))
        )
    )
  ));

  
  editProductType$ = createEffect(() => this.actions$.pipe(
    ofType(ProductTypeActionTypes.productTypeEditProductType),
    mergeMap((action: AddProductType) =>
      this.productTypeService
        .editProductType(action.payload)
        .pipe(
          map(productType => new GetProductTypes()),
          catchError(error => of(new ProductTypeError(error)))
        )
    )
  ));

  
  deleteProductType$ = createEffect(() => this.actions$.pipe(
    ofType(ProductTypeActionTypes.productTypeDeleteProductType),
    mergeMap((action: DeleteProductType) =>
      this.productTypeService
        .deleteProductType(action.payload)
        .pipe(
          map(() => new DeleteProductTypeSuccess(action.payload)),
          catchError(error => of(new ProductTypeError(error)))
        )
    )
  ));

  
  searchProductTypes$ = createEffect(() => this.actions$.pipe(
    ofType(ProductTypeActionTypes.productTypeSearchProductTypes),
    mergeMap((action: SearchProductTypes) =>
      this.productTypeService
        .searchProductTypes(action.payload)
        .pipe(
          map(productTypes => new SearchProductTypesSuccess(productTypes)),
          catchError(error => of(new ProductTypeError(error)))
        )
    )
  ));

  
  updateProductTypeSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(ProductTypeActionTypes.productTypeUpdateProductTypeSuccess),
    map(productType => new fromRouterActions.Go({ path: ['/setup/product-types'] }))
  ));
}
