import {Action} from '@ngrx/store';
import {RepairCentre} from '@appModels/core-aftersales/repaircentre';

export enum RepairCentresActionTypes {
  repairCentreAdd = '[RepairCentre] Add',
  repairCentreAddSuccess = '[Repair Centre] Add Success',
  repairCentreError = '[Repair Centre] Add Error',
  repairCentreGetAll = '[Repair Centre] Get All',
  repairCentreGetAllSuccess = '[Repair Centre] Get All Success',
  repairCentreChangeState = '[Repair Centre] Change State',
  repairCentreChangeStateSuccess = '[Repair Centre] Change State Success',
  repairCentreUpdate = '[RepairCentre] Update',
  repairCentreUpdateSuccess = '[Repair Centre] Update Success',
  repairCentreGet = '[Repair Centre] Get',
  repairCentreGetSuccess = '[Repair Centre] Get Success',
  repairCentreGetAllByUser = '[Repair Centre] Get All By User',
  repairCentreGetAllByUserSuccess = '[Repair Centre] Get All By User Success',
}

export class AddRepairCentre implements Action {
  readonly type = RepairCentresActionTypes.repairCentreAdd;

  constructor(public payload) {
  }
}

export class AddRepairCentreSuccess implements Action {
  readonly type = RepairCentresActionTypes.repairCentreAddSuccess;

  constructor(public payload: any) {
  }
}

export class UpdateRepairCentre implements Action {
  readonly type = RepairCentresActionTypes.repairCentreUpdate;

  constructor(public payload) {
  }
}

export class UpdateRepairCentreSuccess implements Action {
  readonly type = RepairCentresActionTypes.repairCentreUpdateSuccess;

  constructor(public payload: any) {
  }
}


export class RepairCentreError implements Action {
  readonly type = RepairCentresActionTypes.repairCentreError;

  constructor(public payload: any) {
  }
}

export class GetAllRepairCentre implements Action {
  readonly type = RepairCentresActionTypes.repairCentreGetAll;

  constructor(public payload: any) {
  }
}

export class GetAllRepairCentreSuccess implements Action {
  readonly type = RepairCentresActionTypes.repairCentreGetAllSuccess;

  constructor(public payload: any) {
  }
}

export class GetAllRepairCentreByUser implements Action {
  readonly type = RepairCentresActionTypes.repairCentreGetAllByUser;

  constructor() {
  }
}

export class GetAllRepairCentreByUserSuccess implements Action {
  readonly type = RepairCentresActionTypes.repairCentreGetAllByUserSuccess;

  constructor(public payload: any[]) {
  }
}

export class ChangeRepairCentreState implements Action {
  readonly type = RepairCentresActionTypes.repairCentreChangeState;

  constructor(public payload) {
  }
}

export class ChangeRepairCentreStateSuccess implements Action {
  readonly type = RepairCentresActionTypes.repairCentreChangeStateSuccess;

  constructor(public payload: any) {
  }
}

export class GetRepairCentre implements Action {
  readonly type = RepairCentresActionTypes.repairCentreGet;

  constructor(public payload: any) {
  }
}

export class GetRepairCentreSuccess implements Action {
  readonly type = RepairCentresActionTypes.repairCentreGetSuccess;

  constructor(public payload: RepairCentre) {
  }
}

export type RepairCentresActions =
  | AddRepairCentre
  | AddRepairCentreSuccess
  | RepairCentreError
  | GetAllRepairCentre
  | GetAllRepairCentreSuccess
  | ChangeRepairCentreState
  | ChangeRepairCentreStateSuccess
  | UpdateRepairCentre
  | UpdateRepairCentreSuccess
  | GetRepairCentre
  | GetRepairCentreSuccess
  | GetAllRepairCentreByUser
  | GetAllRepairCentreByUserSuccess;



