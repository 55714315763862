import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

import {
  AddProductCatalogue,
  DeleteProductCatalogue,
  DeleteProductCatalogueSuccess,
  EditProductCatalogue,
  GetProductCatalogueById,
  GetProductCatalogueByIdSuccess,
  GetProductCatalogues,
  GetProductCataloguesSuccess,
  ProductCatalogueActionTypes,
  ProductCatalogueError,
  SearchProductCatalogues,
  SearchProductCataloguesSuccess,
  UpdateProductCatalogueSuccess
} from '@appStore/actions/core-masterdata/product-catalogue/product-catalogue.actions';
import {ProductCatalogueService} from '@appServices/core-masterdata/product-catalogue/product-catalogue.service';
import {TenantService} from '@appServices/core-masterdata/tenant/tenant.service';

import * as fromRouterActions from '@appStore/actions/router.actions';
import {
  GetProductCataloguesByType,
  GetProductCataloguesByTypeSuccess
} from '../../../actions/core-masterdata/product-catalogue/product-catalogue.actions';
import { Router } from '@angular/router';

@Injectable()
export class ProductCatalogueEffects {
  constructor(
    private actions$: Actions,
    private productCatalogueService: ProductCatalogueService,
    private tenantService: TenantService,
    private router: Router
    ) {}

  // @Effect()
  // loadProductCatalogues$ = this.actions$.pipe(
  //   ofType(ProductCatalogueActionTypes.productCatalogueGetProductCatalogues),
  //   mergeMap(() =>
  //     this.productCatalogueService
  //       .getProductCatalogues()
  //       .pipe(
  //         map(productCatalogues => new GetProductCataloguesSuccess(productCatalogues)),
  //         catchError(error => of(new ProductCatalogueError(error)))
  //       )
  //   )
  // );

   // load productCatalogues by tenant ID

   loadProductCataloguesByTenantId$ = createEffect(() => this.actions$.pipe(
     ofType(ProductCatalogueActionTypes.productCatalogueGetProductCatalogues),
     mergeMap(() =>
       this.productCatalogueService
         .getProductCataloguesByTenantAndType(+localStorage.getItem('tenant'), 1)
         .pipe(
           map(productCatalogues => new GetProductCataloguesSuccess(productCatalogues)),
           catchError(error => of(new ProductCatalogueError(error)))
         )
     )
   ));


  loadProductCataloguesByTenantAndType$ = createEffect(() => this.actions$.pipe(
    ofType(ProductCatalogueActionTypes.productCatalogueGetProductCataloguesByType),
    mergeMap((action: GetProductCataloguesByType) =>
      this.productCatalogueService
        .getProductCataloguesByTenantAndType(action.tenantId, action.typeId, action.query)
        .pipe(
          map(productCatalogues => new GetProductCataloguesByTypeSuccess(productCatalogues)),
          catchError(error => of(new ProductCatalogueError(error)))
        )
    )
  ));


  getProductCatalogueById$ = createEffect(() => this.actions$.pipe(
    ofType(ProductCatalogueActionTypes.productCatalogueGetProductCatalogueById),
    mergeMap((action: GetProductCatalogueById) =>
      this.productCatalogueService
        .getProductCatalogue(action.payload)
        .pipe(
          map(productCatalogue => new GetProductCatalogueByIdSuccess(productCatalogue)),
          catchError(error => of(new ProductCatalogueError(error)))
        )
    )
  ));



  addProductCatalogue$ = createEffect(() => this.actions$.pipe(
    ofType(ProductCatalogueActionTypes.productCatalogueAddProductCatalogue),
    switchMap((action: AddProductCatalogue) =>
      this.productCatalogueService
        .addProductCatalogue(action.payload)
        .pipe(
          map(productCatalogue => new EditProductCatalogue({
            id: productCatalogue['id'],
            value: action.payload.parentGlobalProductId,
            param: 'GlobalProduct'
          })),
          catchError(error => of(new ProductCatalogueError(error)))
        )
    )
  ));


  updateProductCatalogue$ = createEffect(() => this.actions$.pipe(
    ofType(ProductCatalogueActionTypes.productCatalogueUpdateProductCatalogue),
    mergeMap((action: AddProductCatalogue) =>
      this.productCatalogueService
        .updateProductCatalogue(action.payload)
        .pipe(
          map(productCatalogue => new EditProductCatalogue({
            id: productCatalogue.id,
            value: action.payload.parentGlobalProductId,
            param: 'GlobalProduct'
          }),
          this.router.navigateByUrl(`/setup/product-catalogues`)),

          catchError(error => of(new ProductCatalogueError(error)))
        )
    )
  ));


  editProductCatalogue$ = createEffect(() => this.actions$.pipe(
    ofType(ProductCatalogueActionTypes.productCatalogueEditProductCatalogue),
    mergeMap((action: AddProductCatalogue) =>
      this.productCatalogueService
        .editProductCatalogue(action.payload)
        .pipe(
          map(productCatalogue => new GetProductCatalogues()),
          catchError(error => of(new ProductCatalogueError(error)))
        )
    )
  ));


  deleteProductCatalogue$ = createEffect(() => this.actions$.pipe(
    ofType(ProductCatalogueActionTypes.productCatalogueDeleteProductCatalogue),
    mergeMap((action: DeleteProductCatalogue) =>
      this.productCatalogueService
        .deleteProductCatalogue(action.payload)
        .pipe(
          map(() => new DeleteProductCatalogueSuccess(action.payload)),
          catchError(error => of(new ProductCatalogueError(error)))
        )
    )
  ));


  searchProductCatalogues$ = createEffect(() => this.actions$.pipe(
    ofType(ProductCatalogueActionTypes.productCatalogueSearchProductCatalogues),
    mergeMap((action: SearchProductCatalogues) =>
      this.productCatalogueService
        .searchProductCatalogues(action.payload)
        .pipe(
          map(productCatalogues => new SearchProductCataloguesSuccess(productCatalogues)),
          catchError(error => of(new ProductCatalogueError(error)))
        )
    )
  ));


  updateProductCatalogueSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(ProductCatalogueActionTypes.productCatalogueUpdateProductCatalogueSuccess),
    map(productCatalogue => new fromRouterActions.Go({ path: ['/setup/product-catalogues'] }))
  ));
}
