import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getWorkOrderL3Store = createFeatureSelector('workOrderL3');

export const getWorkOrderL3Entities = createSelector(
  getWorkOrderL3Store,
  fromReducers.workOrderL3.workOrderL3EntitySelectors.selectAll
);

export const getWorkOrderL3ById = createSelector(
  getWorkOrderL3Store,
  (workOrderStore: fromReducers.workOrderL3.State) => workOrderStore.selectedWorkOrder
);

export const getWorkOrderL3Loading = createSelector(
  getWorkOrderL3Store,
  (workOrderStore: fromReducers.workOrderL3.State) => workOrderStore.loading
);

export const getWorkOrderL3Paging = createSelector(
  getWorkOrderL3Store,
  (workOrderStore: fromReducers.workOrderL3.State) => workOrderStore.paging
);

export const getWorkOrderL3s = createSelector(getWorkOrderL3Entities, entities => {
  return Object.values(entities);
});
