import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable, of} from 'rxjs';

import {CoreTicketingService} from "@appServices/core-ticketing/core-ticketing.service";
import {catchError, map, tap} from "rxjs/operators";
import { __convertCallStatus__ } from "../../util/format-name"
@Injectable({ providedIn: 'root' })
export class DispositionsmapService extends CoreTicketingService {

  constructor(
    private http: HttpClient,
  ) {
    super(http, 'dispositionsmap');
    this.headers = this.headers.append('skip-cache', 'true');
  }

  getListRow(useTenant: boolean): Observable<any> {
    let tenantId = localStorage.getItem('tenant');
    let params = {};
    if (useTenant) {
      params = {
        tenantId: tenantId
      }
    }

    const url = `${this.base_url}/getDispositionMaps`;
    return this.http.get<any>(url, {params: params,headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }

  getMap(id: any): Observable<any> {

    const url = `${this.base_url}/getMap/${id}`;
    return this.http.get<any>(url, {headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }

  validateAddDisposition(disposition: any, currentMapId?: number): Observable<any> {

    let params = {};
    if (currentMapId != null) {
      params = {
        currentMapId: currentMapId
      }
    }


    let tenantId = localStorage.getItem('tenant');

    const url = `${this.base_url}/validateAddDisposition/${tenantId}`;
    return this.http.post<any>(url, disposition, {params: params, headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }




  getCallDispositionTypesTelesales(): Observable<any[]> {
    return this.http.get<any[]>(
      `${this.base_url}/types/telesales?tenantId=${localStorage.getItem('tenant')}`,
      {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched getCallDispositionTypesTelesales`)),
      catchError(this.handleError('getCallDispositionTypesTelesales', []))
    );
  }

  getCallDispositionCategoriesByDispositionTypeId(typeId: number): Observable<any[]> {
    return this.http.get<any[]>(
      `${this.base_url}/categories/?callDispositionTypeId=${typeId}`,
      {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched getCallDispositionCategoriesByDispositionTypeId`)),
      catchError(this.handleError('getCallDispositionCategoriesByDispositionTypeId', []))
    );
  }

  getCallDispositionSubCategoriesByDispositionCategoryId(categoryId: number): Observable<any[]> {
    return this.http.get<any[]>(
      `${this.base_url}/subcategories/?callDispositionCategoryId=${categoryId}`,
      {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched getCallDispositionSubCategoriesByDispositionCategoryId`)),
      catchError(this.handleError('getCallDispositionSubCategoriesByDispositionCategoryId', []))
    );
  }

  getCallGroups(): Observable<any[]> {
    return this.http.get<any[]>(`${this.base_url}/groups`,  {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched CallGroups`)),
      catchError(this.handleError('getCallGroups', []))
    );
  }

  getLevel1Calls(group: string): Observable<any[]> {
    let params = new HttpParams();
    params = params.append('group', group);

    return this.http.get<any[]>(`${this.base_url}/level1`,  {params, headers: this.headers}).pipe(
      tap(_ => this.log(`fetched Level1Calls`)),
      catchError(this.handleError('getLevel1Calls', []))
    );
  }

  getLevel2Calls(group: string, level1: string): Observable<any[]> {
    let params = new HttpParams();
    params = params.append('group', group);
    params = params.append('level1', level1);

    return this.http.get<any[]>(`${this.base_url}/level2`,  {params, headers: this.headers}).pipe(
      tap(_ => this.log(`fetched Level2Calls`)),
      catchError(this.handleError('getLevel2Calls', []))
    );
  }

  getLevel3Calls(group: string, level1: string, level2: string, contractId: number): Observable<any[]> {
    let params = new HttpParams();
    params = params.append('group', group);
    params = params.append('level1', level1);
    params = params.append('level2', level2);
    if (contractId !== null) {
      params = params.append('contractId', contractId.toString());
    }

    return this.http.get<any[]>(`${this.base_url}/level3`,  {params, headers: this.headers}).pipe(
      tap(_ => this.log(`fetched Level3Calls`)),
      catchError(this.handleError('getLevel3Calls', []))
    );
  }

  createMap(map: any, useTenant: boolean): Observable<any> {
    let tenantId = localStorage.getItem('tenant');
    let params = {};
    if (useTenant) {
      params = {
        tenantId: tenantId
      }
    }

    const url = `${this.base_url}/createMap`;
    return this.http.post<any>(url, map, {params: params,headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }

  updateMap(id:number, map: any): Observable<any> {

    const url = `${this.base_url}/updateMap/${id}`;
    return this.http.post<any>(url, map, {headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }

  toggleStatus(id:number): Observable<any> {
    const url = `${this.base_url}/toggleStatus/${id}`;
    return this.http.post<any>(url, null ,{ headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }

  loadAndTransformSurveyParameters(useTenant: boolean): Observable<any> {
    return this.getListRow(useTenant).pipe(
      map(response => response.map((item: any) => __convertCallStatus__(item))),
      catchError(error => {
        this.log(`Network error occurred: ${error.message}`);
        return of([]); 
      })
    );
  }
}
