import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";

import { catchError, map, mergeMap, switchMap } from "rxjs/operators";
import { of } from "rxjs";

import {
  AddUser,
  AddUserSuccess,
  DeleteUser,
  DeleteUserSuccess,
  GetUserById,
  GetUserByIdSuccess,
  GetUsers,
  GetUsersSuccess,
  SearchUsers,
  SearchUsersSuccess,
  UpdateUserSuccess,
  UploadUser,
  UserActionTypes,
  UserError,
  UploadUserRole,
} from "@appStore/actions/core-identity/user/user.actions";
import { UserService } from "@appServices/core-auth/user/user.service";
import { TenantService } from "@appServices/core-masterdata/tenant/tenant.service";

import * as fromRouterActions from "@appStore/actions/router.actions";
import { ToastrService } from "ngx-toastr";

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private userService: UserService,
    private tenantService: TenantService,
    private toastr: ToastrService
  ) {}

  loadUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.userGetUsers),
      mergeMap(() =>
        this.userService.getUsers().pipe(
          map((users) => new GetUsersSuccess(users)),
          catchError((error) => of(new UserError(error)))
        )
      )
    )
  );

  // load users by tenant ID

  loadUsersByTenantId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.userGetTenantUsers),
      mergeMap(() =>
        this.tenantService
          .getUserByTenant(+localStorage.getItem("tenant"), "users")
          .pipe(
            map((users) => new GetUsersSuccess(users)),
            catchError((error) => of(new UserError(error)))
          )
      )
    )
  );

  getUserById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.userGetUserById),
      mergeMap((action: GetUserById) =>
        this.userService.getUser(action.payload).pipe(
          map((user) => new GetUserByIdSuccess(user)),
          catchError((error) => of(new UserError(error)))
        )
      )
    )
  );

  addUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.userAddUser),
      switchMap((action: AddUser) =>
        this.userService.addUser(action.payload).pipe(
          map((user) => {
            if (user !== undefined) {
              this.toastr.success(
                "User has been successfully added!",
                "Successful!"
              );
              //
              this.tenantService
                .editTenant({
                  id: localStorage.getItem("tenant"),
                  param: "user",
                  value: user.id,
                })
                .subscribe((data) => {});
              return new AddUserSuccess(user);
            }
            this.toastr.error(
              "There was an error creating this user",
              "Unknown error"
            );
            return new UserError({ type: 500, message: "Internal error" });
          }),
          catchError((error) => {
            this.toastr.error(error.message, error.error);
            return of(new UserError(error));
          })
        )
      )
    )
  );

  uploadUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.userUploadUser),
      mergeMap((action: UploadUser) =>
        this.userService.uploadUser(action.payload).pipe(
          map((user) => new GetUsers()),
          catchError((error) => of(new UserError(error)))
        )
      )
    )
  );

  uploadUserRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.userRoleUploadUser),
      mergeMap((action: UploadUserRole) =>
        this.userService.uploadUserRole(action.payload).pipe(
          map((user) => new GetUsers()),
          catchError((error) => of(new UserError(error)))
        )
      )
    )
  );

  updateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.userUpdateUser),
      mergeMap((action: AddUser) =>
        this.userService.updateUser(action.payload).pipe(
          map((user) => new UpdateUserSuccess(action.payload)),
          catchError((error) => of(new UserError(error)))
        )
      )
    )
  );

  deleteUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.userDeleteUser),
      mergeMap((action: DeleteUser) =>
        this.userService.deleteUser(action.payload).pipe(
          map(() => new DeleteUserSuccess(action.payload)),
          catchError((error) => of(new UserError(error)))
        )
      )
    )
  );

  searchUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.userSearchUsers),
      mergeMap((action: SearchUsers) =>
        this.userService.searchUsers(action.payload).pipe(
          map((users) => new SearchUsersSuccess(users)),
          catchError((error) => of(new UserError(error)))
        )
      )
    )
  );

  updateUserSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.userUpdateUserSuccess),
      map(
        (user) =>
          new fromRouterActions.Go({ path: ["/identity-management/users"] })
      )
    )
  );
}
