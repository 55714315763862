import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from "@angular/core";

@Component({
  selector: "kyc-customer-detail",
  template: `
    <div
      *ngIf="!customerDetailCheck && formNotValid"
      class="notification error"
    >
      {{ "Please confirm Customer Details" | translate }}
    </div>
    <h4 class="m-t-20 m-b-10">
      <input
        type="checkbox"
        *ngIf="
          statusName !== 'BLACKLISTED'
        "
        class="m-r-10"
        [ngModel]="customerDetailCheck"
        (ngModelChange)="customerDetailCheckChange.emit($event)"
        name="customerDetailCheck"
        
      />
      {{ "Customer Details" | translate }}
      <span class="m-l-10 status active">{{
        upsellCustomer ? "Upsell" : ("New Customer" | translate)
      }}</span>
    </h4>
    <table style="background: #f7f7fc; border-radius: 3px">
      <thead>
        <tr>
          <th>{{ "NAME" | translate }}</th>
          <th>{{ "DOB" | translate }}</th>
          <th>{{ "ID TYPE" | translate }}</th>
          <th>{{ "ID" | translate }}</th>
          <th>{{ "PHONE NO" | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <b>
              {{ awaitingEscalation.firstName || "--" }}
              {{ awaitingEscalation.lastName }}</b
            >
          </td>
          <td>{{ awaitingEscalation.dateOfBirth || "--" }}</td>
          <td>
            <b>{{ identificationType || "--" }}</b>
          </td>
          <td>
            <b>{{ awaitingEscalation.identificationValue || "--" }}</b>
          </td>
          <td>
            <a href="javascript:void(0)"
              >{{ awaitingEscalation.telephone1 || "--" }}
              <i *ngIf="awaitingEscalation.telephone2">/</i>
              {{ awaitingEscalation.telephone2 }}</a
            >
          </td>
        </tr>
      </tbody>
    </table>

    <table style="background: #f7f7fc; border-radius: 3px">
      <thead>
        <tr>
          <th>{{ "REGION" | translate }}</th>
          <th>{{ "GENDER" | translate }}</th>
          <th>{{ "SECONDARY CONTACT NAME" | translate }}</th>
          <th>{{ "SECONDARY CONTACT PHONE" | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ location || "--" }}</td>
          <td>{{ awaitingEscalation.gender || "--" }}</td>
          <td>
            <b>
              {{ awaitingEscalation.secondaryContactFirstName || "--" }}
              {{ awaitingEscalation.secondaryContactLastName }}</b
            >
          </td>
          <td>
            <a href="javascript:void(0)">
              {{ secondaryContactTelephone || "--" }}</a
            >
          </td>
        </tr>
      </tbody>
    </table>

    <h4 class="m-t-20 m-b-10" *ngIf="resubmissionCount">
      {{ "History" | translate }}
    </h4>

    <div *ngIf="resubmissionCount" style="display: flex">
      <span style="height: fit-content" class="m-r-20 status dormant"
        >{{ "Resubmission Count:" | translate }}
        <b>{{ resubmissionCount }}</b>
      </span>
      <div style="word-wrap: break-word; width: 70%">
        <p>
          <b> {{ "Last customer rejection reason" | translate }}</b> :
        </p>
        <p>
          {{ rejectionInfo || "--" }}
        </p>
      </div>
    </div>
    
   
  `,
  encapsulation: ViewEncapsulation.None,
})
export class KycCustomerDetailComponent implements OnInit {
  @Input() formNotValid: any;
  @Input() statusName: any;
  @Input() upsellCustomer: any;
  @Input() awaitingEscalation: any;
  @Input() identificationType: any;
  @Input() location: any;
  @Input() secondaryContactTelephone: any;
  @Input() resubmissionCount: any;
  @Input() rejectionInfo: any;
  @Input() customerDetailCheck: any;
  @Input() kycComponentModeEnum: any;
  @Input() componentMode: any;
  @Output() customerDetailCheckChange = new EventEmitter<any>();
  dateTime: string = '2024-01-01T12:00';

  constructor() {}

  ngOnInit() {
  }
}
