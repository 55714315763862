import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getModuleActionStore = createFeatureSelector('moduleAction');

export const getModuleActionEntities = createSelector(
  getModuleActionStore,
  fromReducers.moduleAction.moduleActionEntitySelectors.selectAll
);

export const getModuleActions = createSelector(getModuleActionEntities, entities => {
  return Object.values(entities);
});

export const getCustomFieldTypes = createSelector(
  getModuleActionStore,
  (moduleActionStore: fromReducers.moduleAction.State) => moduleActionStore.customFieldType
);

export const getTopModuleActions = createSelector(getModuleActionEntities, entities => {
  return Object.values(entities).slice(1, 5);
});

export const getModuleActionsLoaded = createSelector(
  getModuleActionStore,
  (moduleActionStore: fromReducers.moduleAction.State) => moduleActionStore.loaded
);

export const getModuleActionsLoading = createSelector(
  getModuleActionStore,
  (moduleActionStore: fromReducers.moduleAction.State) => moduleActionStore.loading
);

export const getSelectedModuleActionId = createSelector(
  getModuleActionStore,
  (moduleActionStore: fromReducers.moduleAction.State) => moduleActionStore.selectedModuleActionId
);

export const getSearchModuleActions = createSelector(
  getModuleActionStore,
  (moduleActionStore: fromReducers.moduleAction.State) => moduleActionStore.searchModuleActions
);

export const getModuleActionById = createSelector(
  getModuleActionEntities,
  getSelectedModuleActionId,
  (entities, id) => entities.find(i => i.id === id)
);

export const getModuleActionsError = createSelector(
  getModuleActionStore,
  (moduleActionStore: fromReducers.moduleAction.State) => moduleActionStore.error
);
