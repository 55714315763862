import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

import {
  AddEscalation,
  AddEscalationSuccess,
  DeleteEscalation,
  DeleteEscalationSuccess,
  EscalationActionTypes,
  EscalationError,
  GetEscalationById,
  GetEscalationByIdSuccess,
  GetEscalations,
  GetEscalationsSuccess,
  SearchEscalations,
  SearchEscalationsSuccess,
  UpdateEscalationSuccess
} from '@appStore/actions/core-crm/escalation/escalation.actions';
import {EscalationService} from '@appServices/core-crm/escalation/escalation.service';
import {TenantService} from '@appServices/core-masterdata/tenant/tenant.service';

import * as fromRouterActions from '@appStore/actions/router.actions';
import {ToastrService} from 'ngx-toastr';

@Injectable()
export class EscalationEffects {
  constructor(
    private actions$: Actions, 
    private escalationService: EscalationService,
    private toastr: ToastrService
    ) {}

  
  loadEscalations$ = createEffect(() => this.actions$.pipe(
    ofType(EscalationActionTypes.escalationGetEscalations),
    mergeMap((action: GetEscalations) =>
      this.escalationService
        .getEscalations(action.payload)
        .pipe(
          map(escalations => new GetEscalationsSuccess(escalations)),
          catchError(error => of(new EscalationError(error)))
        )
    )
  ));

  
  getEscalationById$ = createEffect(() => this.actions$.pipe(
    ofType(EscalationActionTypes.escalationGetEscalationById),
    mergeMap((action: GetEscalationById) =>
      this.escalationService
        .getEscalation(action.payload)
        .pipe(
          map(escalation => new GetEscalationByIdSuccess(escalation)),
          catchError(error => of(new EscalationError(error)))
        )
    )
  ));

  
  addEscalation$ = createEffect(() => this.actions$.pipe(
    ofType(EscalationActionTypes.escalationAddEscalation),
    switchMap((action: AddEscalation) =>
      this.escalationService
        .addEscalation(action.payload)
        .pipe(
          map(
            escalation => {
              if (escalation !== undefined) {
                this.toastr.success('Escalation has been successfully added!', 'Successful!');
                return new AddEscalationSuccess(escalation)
              }
              this.toastr.error('There was an error adding escalation!', "Unknown error");
              return new EscalationError({ type: 500, message: 'Internal error' })

            }),
          catchError(error => of(new EscalationError(error)))
        )
    )
  ));

  
  addProductEscalation$ = createEffect(() => this.actions$.pipe(
    ofType(EscalationActionTypes.escalationAddProductEscalation),
    mergeMap((action: AddEscalation) =>
      this.escalationService
        .addProductEscalation(action.payload)
        .pipe(
          map(escalation => new GetEscalations()),
          catchError(error => of(new EscalationError(error)))
        )
    )
  ));

  
  updateEscalation$ = createEffect(() => this.actions$.pipe(
    ofType(EscalationActionTypes.escalationUpdateEscalation),
    mergeMap((action: AddEscalation) =>
      this.escalationService
        .updateEscalation(action.payload)
        .pipe(
          map(escalation => new UpdateEscalationSuccess(action.payload)),
          catchError(error => of(new EscalationError(error)))
        )
    )
  ));

  
  deleteEscalation$ = createEffect(() => this.actions$.pipe(
    ofType(EscalationActionTypes.escalationDeleteEscalation),
    mergeMap((action: DeleteEscalation) =>
      this.escalationService
        .deleteEscalation(action.payload)
        .pipe(
          map(() => new DeleteEscalationSuccess(action.payload)),
          catchError(error => of(new EscalationError(error)))
        )
    )
  ));

  
  searchEscalations$ = createEffect(() => this.actions$.pipe(
    ofType(EscalationActionTypes.escalationSearchEscalations),
    mergeMap((action: SearchEscalations) =>
      this.escalationService
        .searchEscalations(action.payload)
        .pipe(
          map(escalations => new SearchEscalationsSuccess(escalations)),
          catchError(error => of(new EscalationError(error)))
        )
    )
  ));

  
  updateEscalationSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(EscalationActionTypes.escalationUpdateEscalationSuccess),
    map(escalation => new fromRouterActions.Go({ path: ['/crm/customer/escalations'] }))
  ));
}
