import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import * as _ from 'lodash';

import {TicketActions, TicketActionTypes} from '@appStore/actions/core-aftersales/ticket/ticket.actions';
import {Ticket} from '../../../../models/core-aftersales/ticket';

export interface State extends EntityState<Ticket> {
  loaded: boolean;
  loading: boolean;
  error: any;
  selectedTicketId: number;
  searchTickets: Ticket[];
  paging: any;
  selectedTicket: Ticket;
}


export const adapter: EntityAdapter<Ticket> = createEntityAdapter<Ticket>();

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
  selectedTicketId: null,
  error: null,
  searchTickets: null,
  paging: null,
  selectedTicket: null
});

export function reducer(state = initialState, action: TicketActions): State {
  switch (action.type) {
    case TicketActionTypes.ticketGetTickets:
    case TicketActionTypes.ticketAddTicket:
    case TicketActionTypes.ticketDeleteTicket:
    case TicketActionTypes.ticketUpdateTicket:
    case TicketActionTypes.ticketSearchTickets:
    case TicketActionTypes.ticketGetTicketById:
    case TicketActionTypes.ticketGeTicketByTenantId:
    case TicketActionTypes.ticketFilterTicketsStaff:
    case TicketActionTypes.ticketClose:
    case TicketActionTypes.reassignTicket:
      return {
        ...state,
        loading: true
      };

    case TicketActionTypes.ticketGetTicketsSuccess:
      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true,
        paging: _.omit(action.payload, ['content'])

      });

    case TicketActionTypes.ticketFilterTicketSuccess:
      return adapter.setAll(action.payload.content, {
        ...state,
        loading: false,
        loaded: true,
        paging: _.omit(action.payload, ['content'])
      });


    case TicketActionTypes.ticketGetTicketByIdSuccess:
      return {
        ...state,
        selectedTicket: action.payload,
        selectedTicketId: action.payload.id,
        loading: false
      };

    case TicketActionTypes.ticketAddTicketSuccess:
      return adapter.addOne(action.payload, {
        ...state,
        selectedTicketId: action.payload.id,
        loading: false,
        loaded: true
      });

    case TicketActionTypes.ticketGeTicketByTenantIdSuccess:
      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });

    case TicketActionTypes.ticketUpdateTicketSuccess:
    case TicketActionTypes.ticketCloseSuccess:
    case TicketActionTypes.reassignTicketSuccess:
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state,
          loading: false,
          loaded: true
        }
      );

    case TicketActionTypes.ticketDeleteTicketSuccess: {
      return adapter.removeOne(action.payload.id, {
        ...state,
        loading: false,
        loaded: true
      });
    }

    case TicketActionTypes.ticketSearchTicketsSuccess:
      return {
        ...state,
        searchTickets: action.payload,
        loading: false
      };


    case TicketActionTypes.ticketsError:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload
      };

    default:
      return state;
  }
}

export const ticketEntitySelectors = adapter.getSelectors();
