import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import * as _ from 'lodash';

import {
  PartActions,
  PartActionTypes
} from '@appStore/actions/core-aftersales/part/part.actions';
import {Part} from '../../../../models/core-aftersales/part';

export interface State extends EntityState<Part> {
  loaded: boolean;
  loading: boolean;
  error: any;
  selectedPartId: number;
  searchParts: Part[];
  paging: any;
  selectedPart: Part;
}


export const adapter: EntityAdapter<Part> = createEntityAdapter<Part>();

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
  selectedPartId: null,
  error: null,
  searchParts: null,
  paging: null,
  selectedPart: null
});

export function reducer(state = initialState, action: PartActions): State {
  switch (action.type) {
    case PartActionTypes.partGetParts:
    case PartActionTypes.partAddPart:
    case PartActionTypes.partDeletePart:
    case PartActionTypes.partUpdatePart:
    case PartActionTypes.partSearchParts:
    case PartActionTypes.partGetPartById:
    case PartActionTypes.partGePartByTenantId:
    case PartActionTypes.partFilterPartsStaff:


      return {
        ...state,
        loading: true
      };

    case PartActionTypes.partAddParttSuccess:
      return {
        ...state,
        loading: false
      };

    case PartActionTypes.partGetPartsSuccess:
      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true,
        paging: _.omit(action.payload, ['content'])

      });

    case PartActionTypes.partFilterPartSuccess:
      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true,
        //paging: _.omit(action.payload, ['content'])
      });


    case PartActionTypes.partGetPartByIdSuccess:
      return {
        ...state,
        selectedPart: action.payload,
        selectedPartId: action.payload.id,
        loading: false
      };

    /*case PartActionTypes.partAddParttSuccess:
      console.log("add success");
      console.log(action);
      return adapter.addOne(action.payload, {
        ...state,
        selectedPartId: action.payload.id,
        loading: false,
        loaded: true
      });*/

    case PartActionTypes.partGPartByTenantIdSuccess:
      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });

    case PartActionTypes.partUpdatePartSuccess: {
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state,
          loading: false,
          loaded: true
        }
      );
    }

    case PartActionTypes.partDeletePartSuccess: {
      return adapter.removeOne(action.payload.id, {
        ...state,
        loading: false,
        loaded: true
      });
    }

    case PartActionTypes.partSearchPartsSuccess:
      return {
        ...state,
        searchParts: action.payload,
        loading: false
      };

    case PartActionTypes.partsError:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload
      };

    default:
      return state;
  }
}

export const partEntitySelectors = adapter.getSelectors();
