import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getVatConfigurationStore = createFeatureSelector('vatConfiguration');

export const getVatConfigurationEntities = createSelector(
  getVatConfigurationStore,
  fromReducers.vatConfiguration.vatConfigurationEntitySelectors.selectAll
);

export const getVatConfigurations = createSelector(getVatConfigurationEntities, entities => {
  return Object.values(entities);
});

export const getTopVatConfigurations = createSelector(getVatConfigurationEntities, entities => {
  return Object.values(entities).slice(1, 5);
});

export const getVatConfigurationPaging = createSelector(
  getVatConfigurationStore,
  (vatConfigurationStore: fromReducers.vatConfiguration.State) => vatConfigurationStore.paging
);

export const getVatConfigurationsLoaded = createSelector(
  getVatConfigurationStore,
  (vatConfigurationStore: fromReducers.vatConfiguration.State) => vatConfigurationStore.loaded
);

export const getVatConfigurationsLoading = createSelector(
  getVatConfigurationStore,
  (vatConfigurationStore: fromReducers.vatConfiguration.State) => vatConfigurationStore.loading
);

export const getSelectedVatConfigurationId = createSelector(
  getVatConfigurationStore,
  (vatConfigurationStore: fromReducers.vatConfiguration.State) => vatConfigurationStore.selectedVatConfigurationId
);

export const getSearchVatConfigurations = createSelector(
  getVatConfigurationStore,
  (vatConfigurationStore: fromReducers.vatConfiguration.State) => vatConfigurationStore.searchVatConfigurations
);

export const getVatConfigurationById = createSelector(
  getVatConfigurationEntities,
  getSelectedVatConfigurationId,
  (entities, id) => entities.find(i => i.id === id)
);

export const getVatConfigurationsError = createSelector(
  getVatConfigurationStore,
  (vatConfigurationStore: fromReducers.vatConfiguration.State) => vatConfigurationStore.error
);


