import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

import {
  AddRoute,
  AddRouteSuccess,
  DeleteRoute,
  DeleteRouteSuccess,
  GetRouteById,
  GetRouteByIdSuccess,
  GetRouteByProductId,
  GetRouteByProductIdSuccess,
  GetRoutes,
  GetRoutesByTenant,
  GetRoutesByTenantSuccess,
  GetRoutesSuccess,
  RouteActionTypes,
  RouteError,
  SearchRoutes,
  SearchRoutesSuccess,
  UpdateRouteSuccess
} from '@appStore/actions/core-fieldforce/route/route.actions';
import {RouteService} from '@appServices/core-fieldforce/route/route.service';
import {ToastrService} from 'ngx-toastr';
import { Router } from '@angular/router';

@Injectable()
export class RouteEffects {
  constructor(
    private actions$: Actions, 
    private routeService: RouteService,
    private toastr: ToastrService,
    private router: Router
    ) {}

  
  loadRoutes$ = createEffect(() => this.actions$.pipe(
    ofType(RouteActionTypes.routeGetRoutes),
    mergeMap((action: GetRoutes) =>
      this.routeService
        .getRoutes(action.payload)
        .pipe(
          map(routes => new GetRoutesSuccess(routes)),
          catchError(error => of(new RouteError(error)))
        )
    )
  ));

  
  getRouteById$ = createEffect(() => this.actions$.pipe(
    ofType(RouteActionTypes.routeGetRouteById),
    mergeMap((action: GetRouteById) =>
      this.routeService
        .getRoute(action.payload)
        .pipe(
          map(route => new GetRouteByIdSuccess(route)),
          catchError(error => of(new RouteError(error)))
        )
    )
  ));

  
  addRoute$ = createEffect(() => this.actions$.pipe(
    ofType(RouteActionTypes.routeAddRoute),
    switchMap((action: AddRoute) =>
      this.routeService
        .addRoute(action.payload)
        .pipe(
          map(
            route => {
              if (route !== undefined) {
                this.toastr.success('Route Management has been successfully added!', 'Successful!');
                this.router.navigateByUrl(`/fieldforce/route-management`);
                return new GetRoutes(route)
              }
              return new RouteError({ type: 500, message: 'Internal error' })
            }
            ),
          catchError(error => of(new RouteError(error)))
        )
    )
  ));

  
  updateRoute$ = createEffect(() => this.actions$.pipe(
    ofType(RouteActionTypes.routeUpdateRoute),
    mergeMap((action: AddRoute) =>
      this.routeService
        .updateRoute(action.payload)
        .pipe(
          map(route => {
            if (route !== undefined) {
              this.toastr.success('Route Management has been successfully updated!', 'Successful!');
                this.router.navigateByUrl(`/fieldforce/route-management`);
              return  new UpdateRouteSuccess(action.payload)
            }
            // this.toastr.error('There was an error updating this route', "Unknown error");
            return new RouteError({ type: 500, message: 'Internal error' })
          }),
          catchError(error => of(new RouteError(error)))
        )
    )
  ));

  
  deleteRoute$ = createEffect(() => this.actions$.pipe(
    ofType(RouteActionTypes.routeDeleteRoute),
    mergeMap((action: DeleteRoute) =>
      this.routeService
        .deleteRoute(action.payload)
        .pipe(
          map(() => new DeleteRouteSuccess(action.payload)),
          catchError(error => of(new RouteError(error)))
        )
    )
  ));

  
  searchRoutes$ = createEffect(() => this.actions$.pipe(
    ofType(RouteActionTypes.routeSearchRoutes),
    mergeMap((action: SearchRoutes) =>
      this.routeService
        .searchRoutes(action.payload)
        .pipe(
          map(routes => new SearchRoutesSuccess(routes)),
          catchError(error => of(new RouteError(error)))
        )
    )
  ));

  // @Effect()
  // updateRouteSuccess$ = this.actions$.pipe(
  //   ofType(RouteActionTypes.routeUpdateRouteSuccess),
  //   map(route => new fromRouterActions.Go({ path: ['/fieldforce/route-management'] }))
  // );
}
