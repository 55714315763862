import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getFaultWarrantyDispositionStore = createFeatureSelector('faultWarrantyDisposition');

export const getFaultWarrantyDispositionEntities = createSelector(
  getFaultWarrantyDispositionStore,
  fromReducers.faultWarrantyDisposition.faultWarrantyDispositionEntitySelectors.selectAll
);

export const getFaultWarrantyDispositions = createSelector(getFaultWarrantyDispositionEntities, entities => {
  return Object.values(entities);
});
