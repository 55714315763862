import {Action} from '@ngrx/store';
import {StockingPointUser} from '../../../../models/core-inventory/stocking-point-user/stocking-point-user';

export enum StockingPointUserActionTypes {
  stockingPointUserGetByUser = '[Stocking Point User] Get By User',
  stockingPointUserGetByUserSuccess = '[Stocking Point User] Get By User Success',
  stockingPointUserGetAll = '[Stocking Point User] Get All',
  stockingPointUserGetAllSuccess = '[Stocking Point User] Get All Success',
  stockingPointUserSave = '[Stocking Point User] Save',
  stockingPointUserSaveSuccess = '[Stocking Point User] Save Success',
  stockingPointUserActivate = '[Stocking Point User] Activate',
  stockingPointUserActivateSuccess = '[Stocking Point User] Activate Success',
  stockingPointUserDeactivate = '[Stocking Point User] Deactivate',
  stockingPointUserDeactivateSuccess = '[Stocking Point User] Deactivate Success',
  stockingPointUserError = '[Stocking Point User] Error',
}

export class GetStockingPointUserByUser implements Action {
  readonly type = StockingPointUserActionTypes.stockingPointUserGetByUser;

  constructor(public payload: number) {
  }
}

export class GetStockingPointUserByUserSuccess implements Action {
  readonly type = StockingPointUserActionTypes.stockingPointUserGetByUserSuccess;

  constructor(public payload: StockingPointUser) {
  }
}

export class StockingPointUserGetAll implements Action {
  readonly type = StockingPointUserActionTypes.stockingPointUserGetAll;

  constructor(public payload: any) {
  }
}

export class StockingPointUserGetAllSuccess implements Action {
  readonly type = StockingPointUserActionTypes.stockingPointUserGetAllSuccess;

  constructor(public payload: any) {
  }
}

export class StockingPointUserSave implements Action {
  readonly type = StockingPointUserActionTypes.stockingPointUserSave;

  constructor(public payload: any) {
  }
}

export class StockingPointUserSaveSuccess implements Action {
  readonly type = StockingPointUserActionTypes.stockingPointUserSaveSuccess;

  constructor(public payload: any) {
  }
}

export class StockingPointUserActivate implements Action {
  readonly type = StockingPointUserActionTypes.stockingPointUserActivate;

  constructor(public payload: number) {
  }
}

export class StockingPointUserActivateSuccess implements Action {
  readonly type = StockingPointUserActionTypes.stockingPointUserActivateSuccess;

  constructor(public payload: StockingPointUser) {
  }
}

export class StockingPointUserDeactivate implements Action {
  readonly type = StockingPointUserActionTypes.stockingPointUserDeactivate;

  constructor(public payload: number) {
  }
}

export class StockingPointUserDeactivateSuccess implements Action {
  readonly type = StockingPointUserActionTypes.stockingPointUserDeactivateSuccess;

  constructor(public payload: StockingPointUser) {
  }
}

export class StockingPointUserError implements Action {
  readonly type = StockingPointUserActionTypes.stockingPointUserError;

  constructor(public payload: any) {
  }
}


export type StockingPointUserActions =
  | StockingPointUserError
  | GetStockingPointUserByUser
  | GetStockingPointUserByUserSuccess
  | StockingPointUserGetAll
  | StockingPointUserGetAllSuccess
  | StockingPointUserSave
  | StockingPointUserSaveSuccess
  | StockingPointUserActivate
  | StockingPointUserActivateSuccess
  | StockingPointUserDeactivate
  | StockingPointUserDeactivateSuccess;




