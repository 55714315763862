import {Action} from '@ngrx/store';

import {ConsignmentProduct} from '@appModels/core-inventory/consignment-product/consignment-product';
import {ConsignmentProductAcceptReceiveStatus} from '@appModels/core-inventory/consignment-product-accept-receive-status/consignment-product-accept-receive-status';

export enum ConsignmentProductActionTypes {
  consignmentProductGetConsignmentProducts = '[ConsignmentProduct] get',
  consignmentProductGetConsignmentProductsSuccess = '[ConsignmentProduct] get consignmentProducts success',
  consignmentAddInventoryProductToConsignment = '[Consignment] add InventoryProduct To Consignment',
  consignmentAddInventoryProductToConsignmentSuccess = '[Consignment] add InventoryProduct To Consignment success',
  consignmentRemoveInventoryProductFromConsignment = '[Consignment] remove InventoryProduct from Consignment',
  consignmentRemoveInventoryProductFromConsignmentSuccess = '[Consignment] remove InventoryProduct from Consignment success',
  consignmentProductAddConsignmentProduct = '[ConsignmentProduct] add ConsignmentProduct',
  consignmentProductAddConsignmentProductSuccess = '[ConsignmentProduct] add consignmentProduct success',
  consignmentProductAddAdditionalConsignmentProductInfo = '[ConsignmentProduct] add additional ConsignmentProduct Info',
  consignmentProductAddAdditionalConsignmentProductInfoSuccess = '[ConsignmentProduct] add additional consignmentProduct Info success',
  consignmentProductDeleteConsignmentProduct = '[ConsignmentProduct] delete consignmentProduct',
  consignmentProductDeleteConsignmentProductSuccess = '[ConsignmentProduct] delete consignmentProduct success',
  consignmentProductGetConsignmentProductById = '[ConsignmentProduct] get consignmentProduct by id',
  consignmentProductGetConsignmentProductByConsignmentId = '[ConsignmentProduct] get consignmentProduct by consignmentProduct id',
  consignmentProductGetConsignmentProductByConsignmentIdSuccess = '[ConsignmentProduct] get consignmentProduct by consignmentProduct id success',
  consignmentProductGetConsignmentProductByIdSuccess = '[ConsignmentProduct] get consignmentProduct by id success',
  consignmentProductUpdateConsignmentProduct = '[ConsignmentProduct] update consignmentProduct',
  consignmentProductUpdateConsignmentProductSuccess = '[ConsignmentProduct] update consignmentProduct success',
  consignmentProductSearchConsignmentProducts = '[ConsignmentProduct] search consignmentProducts',
  consignmentProductSearchConsignmentProductsSuccess = '[ConsignmentProduct] search consignmentProducts success',
  consignmentProductSearchConsignmentProductsReset = '[ConsignmentProduct] search consignmentProducts reset',
  consignmentProductError = '[ConsignmentProduct] error'
}

export class GetConsignmentProducts implements Action {
  readonly type = ConsignmentProductActionTypes.consignmentProductGetConsignmentProducts;
}

export class GetConsignmentProductsSuccess implements Action {
  readonly type = ConsignmentProductActionTypes.consignmentProductGetConsignmentProductsSuccess;

  constructor(public payload: ConsignmentProduct[]) {
  }
}

export class AddConsignmentProduct implements Action {
  readonly type = ConsignmentProductActionTypes.consignmentProductAddConsignmentProduct;

  constructor(public payload: ConsignmentProduct) {
  }
}

export class AddConsignmentProductSuccess implements Action {
  readonly type = ConsignmentProductActionTypes.consignmentProductAddConsignmentProductSuccess;

  constructor(public payload: ConsignmentProduct) {
  }
}

export class AddAdditionalConsignmentProductInfo implements Action {
  readonly type = ConsignmentProductActionTypes.consignmentProductAddAdditionalConsignmentProductInfo;

  constructor(public payload) {
  }
}

export class AddAdditionalConsignmentProductInfoSuccess implements Action {
  readonly type = ConsignmentProductActionTypes.consignmentProductAddAdditionalConsignmentProductInfoSuccess;

  constructor(public payload) {
  }
}

export class GetConsignmentProductById implements Action {
  readonly type = ConsignmentProductActionTypes.consignmentProductGetConsignmentProductById;

  constructor(public payload: number) {
  }
}

export class GetConsignmentProductByIdSuccess implements Action {
  readonly type = ConsignmentProductActionTypes.consignmentProductGetConsignmentProductByIdSuccess;

  constructor(public payload: ConsignmentProduct) {
  }
}

export class GetConsignmentProductByConsignmentId implements Action {
  readonly type = ConsignmentProductActionTypes.consignmentProductGetConsignmentProductByConsignmentId;

  constructor(public payload: any) {
  }
}

export class GetConsignmentProductByConsignmentIdSuccess implements Action {
  readonly type = ConsignmentProductActionTypes.consignmentProductGetConsignmentProductByConsignmentIdSuccess;

  constructor(public payload: any) {
  }
}

export class UpdateConsignmentProduct implements Action {
  readonly type = ConsignmentProductActionTypes.consignmentProductUpdateConsignmentProduct;

  constructor(public consignmentProductId: number, public acceptReceiveStatus: ConsignmentProductAcceptReceiveStatus) {
  }
}

export class UpdateConsignmentProductSuccess implements Action {
  readonly type = ConsignmentProductActionTypes.consignmentProductUpdateConsignmentProductSuccess;

  constructor(public payload: ConsignmentProduct) {
  }
}

export class DeleteConsignmentProduct implements Action {
  readonly type = ConsignmentProductActionTypes.consignmentProductDeleteConsignmentProduct;

  constructor(public payload: ConsignmentProduct) {
  }
}

export class DeleteConsignmentProductSuccess implements Action {
  readonly type = ConsignmentProductActionTypes.consignmentProductDeleteConsignmentProductSuccess;

  constructor(public payload: ConsignmentProduct) {
  }
}

export class SearchConsignmentProducts implements Action {
  readonly type = ConsignmentProductActionTypes.consignmentProductSearchConsignmentProducts;

  constructor(public payload: string) {
  }
}

export class SearchConsignmentProductsSuccess implements Action {
  readonly type = ConsignmentProductActionTypes.consignmentProductSearchConsignmentProductsSuccess;

  constructor(public payload: ConsignmentProduct[]) {
  }
}

export class SearchConsignmentProductsReset implements Action {
  readonly type = ConsignmentProductActionTypes.consignmentProductSearchConsignmentProductsReset;
}

export class ConsignmentProductError implements Action {
  readonly type = ConsignmentProductActionTypes.consignmentProductError;

  constructor(public payload: any) {
  }
}

export class ConsignmentAddInventoryProductToConsignment implements Action {
  readonly type = ConsignmentProductActionTypes.consignmentAddInventoryProductToConsignment;

  constructor(public payload: number, public payload2: number) {
  }
}

export class ConsignmentAddInventoryProductToConsignmentSuccess implements Action {
  readonly type = ConsignmentProductActionTypes.consignmentAddInventoryProductToConsignmentSuccess;

  constructor(public payload: ConsignmentProduct) {
  }
}

export class ConsignmentRemoveInventoryProductFromConsignment implements Action {
  readonly type = ConsignmentProductActionTypes.consignmentRemoveInventoryProductFromConsignment;

  constructor(public consignmentId: number, public inventoryProductId: number, public consignmentProductId: number) {
  }
}

export class ConsignmentRemoveInventoryProductFromConsignmentSuccess implements Action {
  readonly type = ConsignmentProductActionTypes.consignmentRemoveInventoryProductFromConsignmentSuccess;

  constructor(public payload: number) {
  }
}


export type ConsignmentProductActions =
  | GetConsignmentProducts
  | GetConsignmentProductsSuccess
  | AddConsignmentProduct
  | AddConsignmentProductSuccess
  | AddAdditionalConsignmentProductInfo
  | AddAdditionalConsignmentProductInfoSuccess
  | GetConsignmentProductById
  | GetConsignmentProductByIdSuccess
  | UpdateConsignmentProduct
  | UpdateConsignmentProductSuccess
  | DeleteConsignmentProduct
  | DeleteConsignmentProductSuccess
  | SearchConsignmentProducts
  | SearchConsignmentProductsSuccess
  | SearchConsignmentProductsReset
  | GetConsignmentProductByConsignmentId
  | GetConsignmentProductByConsignmentIdSuccess
  | ConsignmentAddInventoryProductToConsignment
  | ConsignmentAddInventoryProductToConsignmentSuccess
  | ConsignmentRemoveInventoryProductFromConsignment
  | ConsignmentRemoveInventoryProductFromConsignmentSuccess
  | ConsignmentProductError;
