import {Action} from '@ngrx/store';

import {InventoryProduct} from '@appModels/core-inventory/inventory-product/inventory-product';

export enum InventoryProductActionTypes {
  inventoryProductGetInventoryProducts = '[InventoryProduct] get',
  inventoryProductGetInventoryProductsSuccess = '[InventoryProduct] get inventoryProducts success',
  inventoryProductAddInventoryProduct = '[InventoryProduct] add InventoryProduct',
  inventoryProductAddInventoryProductSuccess = '[InventoryProduct] add inventoryProduct success',
  inventoryProductAddAdditionalInventoryProductInfo = '[InventoryProduct] add additional InventoryProduct Info',
  inventoryProductAddAdditionalInventoryProductInfoSuccess = '[InventoryProduct] add additional inventoryProduct Info success',
  inventoryProductDeleteInventoryProduct = '[InventoryProduct] delete inventoryProduct',
  inventoryProductDeleteInventoryProductSuccess = '[InventoryProduct] delete inventoryProduct success',
  inventoryProductGetInventoryProductById = '[InventoryProduct] get inventoryProduct by id',
  inventoryProductGetInventoryProductByIdSuccess = '[InventoryProduct] get inventoryProduct by id success',
  inventoryProductUpdateInventoryProduct = '[InventoryProduct] update inventoryProduct',
  inventoryProductUpdateInventoryProductSuccess = '[InventoryProduct] update inventoryProduct success',
  inventoryProductSearchInventoryProducts = '[InventoryProduct] search inventoryProducts',
  inventoryProductSearchInventoryProductsSuccess = '[InventoryProduct] search inventoryProducts success',
  inventoryProductSearchInventoryProductsReset = '[InventoryProduct] search inventoryProducts reset',
  inventoryProductUploadInventoryProduct = '[InventoryProduct] upload InventoryProduct',
  inventoryProductUploadInventoryProductSuccess = '[InventoryProduct] upload InventoryProduct success',
  inventoryProductPatchProduct = '[InventoryProduct] patch product',
  inventoryProductPatchProductSuccess = '[InventoryProduct] patch product success',
  inventoryProductError = '[InventoryProduct] error'
}

export class GetInventoryProducts implements Action {
  readonly type = InventoryProductActionTypes.inventoryProductGetInventoryProducts;

  constructor(public payload: any) {
  }
}

export class GetInventoryProductsSuccess implements Action {
  readonly type = InventoryProductActionTypes.inventoryProductGetInventoryProductsSuccess;

  constructor(public payload: any) {
  }
}

export class AddInventoryProduct implements Action {
  readonly type = InventoryProductActionTypes.inventoryProductAddInventoryProduct;

  constructor(public payload: InventoryProduct) {
  }
}

export class AddInventoryProductSuccess implements Action {
  readonly type = InventoryProductActionTypes.inventoryProductAddInventoryProductSuccess;

  constructor(public payload: InventoryProduct) {
  }
}

export class AddAdditionalInventoryProductInfo implements Action {
  readonly type = InventoryProductActionTypes.inventoryProductAddAdditionalInventoryProductInfo;

  constructor(public payload) {
  }
}

export class AddAdditionalInventoryProductInfoSuccess implements Action {
  readonly type = InventoryProductActionTypes.inventoryProductAddAdditionalInventoryProductInfoSuccess;

  constructor(public payload) {
  }
}

export class GetInventoryProductById implements Action {
  readonly type = InventoryProductActionTypes.inventoryProductGetInventoryProductById;

  constructor(public payload: number) {
  }
}

export class GetInventoryProductByIdSuccess implements Action {
  readonly type = InventoryProductActionTypes.inventoryProductGetInventoryProductByIdSuccess;

  constructor(public payload: InventoryProduct) {
  }
}

export class UpdateInventoryProduct implements Action {
  readonly type = InventoryProductActionTypes.inventoryProductUpdateInventoryProduct;

  constructor(public payload: InventoryProduct) {
  }
}

export class UpdateInventoryProductSuccess implements Action {
  readonly type = InventoryProductActionTypes.inventoryProductUpdateInventoryProductSuccess;

  constructor(public payload: InventoryProduct) {
  }
}

export class DeleteInventoryProduct implements Action {
  readonly type = InventoryProductActionTypes.inventoryProductDeleteInventoryProduct;

  constructor(public payload: InventoryProduct) {
  }
}

export class DeleteInventoryProductSuccess implements Action {
  readonly type = InventoryProductActionTypes.inventoryProductDeleteInventoryProductSuccess;

  constructor(public payload: InventoryProduct) {
  }
}

export class SearchInventoryProducts implements Action {
  readonly type = InventoryProductActionTypes.inventoryProductSearchInventoryProducts;

  constructor(public payload: string) {
  }
}

export class SearchInventoryProductsSuccess implements Action {
  readonly type = InventoryProductActionTypes.inventoryProductSearchInventoryProductsSuccess;

  constructor(public payload: InventoryProduct[]) {
  }
}

export class SearchInventoryProductsReset implements Action {
  readonly type = InventoryProductActionTypes.inventoryProductSearchInventoryProductsReset;
}

export class UploadInventoryProduct implements Action {
  readonly type = InventoryProductActionTypes.inventoryProductUploadInventoryProduct;

  constructor(public payload) {
  }
}

export class PatchInventoryProduct implements Action {
  readonly type = InventoryProductActionTypes.inventoryProductPatchProduct;

  constructor(public payload: InventoryProduct) {
  }
}

export class PatchInventoryProductSuccess implements Action {
  readonly type = InventoryProductActionTypes.inventoryProductPatchProductSuccess;

  constructor(public payload: InventoryProduct) {
  }
}

export class UploadInventoryProductSuccess implements Action {
  readonly type = InventoryProductActionTypes.inventoryProductUploadInventoryProductSuccess;

  constructor(public payload) {
  }
}


export class InventoryProductError implements Action {
  readonly type = InventoryProductActionTypes.inventoryProductError;

  constructor(public payload: any) {
  }
}

export type InventoryProductActions =
  | GetInventoryProducts
  | GetInventoryProductsSuccess
  | AddInventoryProduct
  | AddInventoryProductSuccess
  | AddAdditionalInventoryProductInfo
  | AddAdditionalInventoryProductInfoSuccess
  | GetInventoryProductById
  | GetInventoryProductByIdSuccess
  | UpdateInventoryProduct
  | UpdateInventoryProductSuccess
  | DeleteInventoryProduct
  | DeleteInventoryProductSuccess
  | SearchInventoryProducts
  | SearchInventoryProductsSuccess
  | SearchInventoryProductsReset
  | UploadInventoryProduct
  | UploadInventoryProductSuccess
  | PatchInventoryProduct
  | PatchInventoryProductSuccess
  | InventoryProductError;
