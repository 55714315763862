import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';

import {Role} from '@appModels/core-identity/role/role';
import {CoreAuthService} from '../core-auth.service';


@Injectable({ providedIn: 'root' })
export class RoleService extends CoreAuthService {

  constructor(
    private http: HttpClient,

  ) {
     super(http, 'role');
  }

  /** GET roles from the server */
  getRoles(): Observable<Role[]> {
    return this.http.get<Role[]>(this.base_url + `/tenant/${localStorage.getItem('tenant')}`, {headers: this.headers}).pipe(
      tap(roles => this.log(`fetched roles`)),
      catchError(this.handleError('getRoles', []))
    );
  }

  getRolesByTenant(id): Observable<Role[]> {
    return this.http.get<Role[]>(this.base_url + `/tenant/${id}`, {headers: this.headers}).pipe(
      tap(roles => this.log(`fetched roles`)),
      catchError(this.handleError('getRoles', []))
    );
  }

  /** GET role by id. Return `undefined` when id not found */
  getRoleNo404<Data>(id: number): Observable<Role> {
    const url = `${this.base_url}/?id=${id}`;
    return this.http.get<Role[]>(url).pipe(
      map(roles => roles[0]), // returns a {0|1} element array
      tap(h => {
        const outcome = h ? `fetched` : `did not find`;
        this.log(`${outcome} role id=${id}`);
      }),
      catchError(this.handleError<Role>(`getRole id=${id}`))
    );
  }

  /** GET role by id. Will 404 if id not found */
  getRole(id: number): Observable<Role> {
    const url = `${this.base_url}/id/${id}`;
    return this.http.get<Role>(url).pipe(
      tap(_ => this.log(`fetched role id=${id}`)),
      catchError(this.handleError<Role>(`getRole id=${id}`))
    );
  }

  /* GET roles whose name contains search term */
  searchRoles(term: string): Observable<Role[]> {
    if (!term.trim()) {
      // if not search term, return empty role array.
      return of([]);
    }
    return this.http.get<Role[]>(`api/roles/?name=${term}`).pipe(
      tap(_ => this.log(`found roles matching "${term}"`)),
      catchError(this.handleError<Role[]>('searchRoles', []))
    );
  }

  //////// Save methods //////////

  /** POST: add a new role to the server */
  addRole(role: Role): Observable<Role> {
    return this.http.post<Role>(this.base_url + `/tenant/${localStorage.getItem('tenant')}`, role, {headers: this.headers}).pipe(
      tap((role: Role) => this.log(`added role w/ id=${role.id}`)),
      catchError(this.handleError<Role>('addRole'))
    );
  }

  /** DELETE: delete the role from the server */
  deleteRole(role: Role | number): Observable<Role> {
    const id = typeof role === 'number' ? role : role.id;
    const url = `${this.base_url}/${id}`;

    return this.http.delete<Role>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`deleted role id=${id}`)),
      catchError(this.handleError<Role>('deleteRole'))
    );
  }

  /** PUT: update the role on the server */
  updateRole(role: Role): Observable<Role> {
    return this.http.put<Role>(this.base_url + '/' + role.id, role, {headers: this.headers}).pipe(
      tap(_ => this.log(`updated role id=${role.id}`)),
      catchError(this.handleError<any>('updateRole'))
    );
  }

  /** GET role by id. Will 404 if id not found */
  getRoleByUserId(userId: number, tenantId: number): Observable<Role[]> {
    const url = `${this.base_url}/user/${userId}/tenant/${tenantId}`;
    return this.http.get<Role[]>(url).pipe(
      tap(_ => this.log(`fetched roles`)),
      catchError(this.handleError<Role[]>(`getRole by user id=${userId}`))
    );
  }

  /** GET role by id. Will 404 if id not found */
  getRoleByName(roleName: string): Observable<Role> {
    const url = `${this.base_url}/name/${roleName}`;
    return this.http.get<Role>(url).pipe(
      tap(_ => this.log(`fetched roles`)),
      catchError(this.handleError<Role>(`getRole by user name=${roleName}`))
    );
  }

}
