<div class="font-semibold text-lg mt-8">Aftersale Ticket Information</div>

<div class="text-dlight">
  <hr class="h-px border-t-0 bg-transparent bg-gradient-to-r from-red-500 via-red-500 to-transparent opacity-25">
</div>

<label class="label_title mt-3">Actions</label>
<select (change)="selectWarrantyAction($event.target.value)"  class="select_input form-input form-input-focus" placeholder="Select Action..." name="selectedWarrantyAction">
  <option value="" selected disabled>{{'Please select an Action' | translate}}</option>
  <option *ngIf="selectedTicket.productId && canWarrantyAndService('can-receive-items-for-work-order')"
          value="receive">{{'Receive Items' | translate}}
  </option>
  <option *ngIf="selectedTicket.productId && canWarrantyAndService('can-create-work-order') && receivedItems.length > 0"
          value="work_order">{{'Create Work Order' | translate}}
  </option>
  <option  *ngIf="canReassignTicket(selectedTicket)" value="reassign">{{'Reassign Ticket' | translate}}
  </option>
  <option *ngIf="selectedTicket.productId && receivedItems.length == 0" value="followUp">{{'Follow Up Call' | translate}}
  </option>
  <option
    *ngIf="canCloseTicket(selectedTicket)"
    value="close">{{'Close Ticket' | translate}}
  </option>
</select>



<div class="mt-3">
  <div class="font-semibold ">Customer Complaints</div>
  <div class="flex flex-row justify-start gap-3">
    <div class="text-dlight" *ngFor="let complaint of selectedTicket.customerComplaints">
      <span class="badge bg-warning rounded-full">{{complaint.complaint}}</span>
    </div>
  </div>
</div>
<div class="mt-3">
  <div class="font-semibold ">Sub Units</div>
  <div class="flex flex-row justify-start gap-3">
    <div class="text-dlight" *ngFor="let subUnit of selectedTicket.ticketSubUnits">
          <span  class="badge bg-dlight rounded-full">
                  {{subUnit.productVersion.globalProduct.name}}
                </span>
    </div>
  </div>
</div>
<div class="mt-3">
  <div class="font-semibold ">Problem Description</div>
  <div>{{selectedTicket.productProblemDescription}}</div>
</div>

<div class="mt-3" *ngIf="receivedItems != null && receivedItems.length > 0">
  <div class="font-semibold ">Received Items</div>
  <div class="flex flex-row justify-start gap-3">
    <div class="text-dlight" *ngFor="let item of receivedItems">
      <span class="badge bg-dlight rounded-full">{{item.productName}}</span>
    </div>
  </div>
</div>

<div class="mt-3" *ngIf="receivedItems == null || receivedItems.length == 0">
  <div class="font-semibold ">Received Items</div>
  <div class="flex flex-row justify-start gap-3">
    No Items Received
  </div>
</div>

<div class="mt-3">
  <div class="font-semibold ">Work Orders</div>
  <div class="flex flex-row justify-start gap-3 text-dlight" *ngIf="(selectedTicket.workOrderNumbers | keyvalue)?.length">
    <li *ngFor="let wo of selectedTicket.workOrderNumbers | keyvalue">
      <a (click)="goToWorkOrder(wo.key)"
         href="javascript:void(0)">{{wo.value}}</a></li>
  </div>
  <div class="flex flex-row justify-start gap-3" *ngIf="(selectedTicket.workOrderNumbers | keyvalue)?.length == 0">
    No Work Orders Created
  </div>
</div>

<div class="mt-3">
  <div class="font-semibold ">Follow Up Calls</div>
  <div >
    <div *ngFor="let call of selectedTicket.followUpCalls" class="box-shadow-pop mt-2 py-2 px-3 w-auto" style="background-color: #f3f4f8;border-radius: 10px">
      <div  class="flex flex-row justify-start gap-3 pt-2 items-center">
        <img  src="assets/images/question.png" alt="" class="h-4 w-4">
        <div class="font-semibold">Disposition:</div>
        <div >{{call.disposition}}</div>
      </div>
      <div  class="flex flex-row justify-start gap-3 pt-2 items-center">
        <img  src="assets/images/notepad.png" alt="" class="h-4 w-4">
        <div class="font-semibold">Notes:</div>
        <div >{{call.notes}}</div>
      </div>
      <div  class="flex flex-row justify-start gap-3 pt-2 items-center">
        <img  src="assets/images/timer.png" alt="" class="h-4 w-4">
        <div class="font-semibold">Timestamp:</div>
        <div >{{call.createdAt}}</div>
      </div>
    </div>

  </div>
</div>


<button id="openReassignWarrantyTicketModalButton"  [hidden]="true"
        data-toggle="modal"  (click)="openReassignWarrantyTicketModal(reassignWarrantyTicketModal)" ></button>
<button id="openFollowUpCallButton"  [hidden]="true"
        data-toggle="modal"  (click)="openFollowUpCallModalModal(followUpCallModal)" ></button>
<button id="openCloseWarrantyTicketModalButton"  [hidden]="true"
        data-toggle="modal"  (click)="openCloseWarrantyTicketModal(closeWarrantyTicketModal)" ></button>
<ng-template #reassignWarrantyTicketModal>

  <div class="m-auto w-auto pt-8">

    <div class="mt-5 mb-5" *ngIf="repairCentreNames == null || repairCentreNames.length == 0">
      <div class="flex flex-row justify-center w-full  gap-4">
        <span class="m-auto mb-10 inline-block h-14 w-14 animate-spin rounded-full border-8 border-[#f1f2f3] border-l-dlight align-middle"></span>
      </div>
    </div>

    <ng-container *ngIf="repairCentreNames != null && repairCentreNames.length > 0">
      <label class="label_title">{{'Repair Centre' | translate}} *</label>
      <select2
        [options]="repairCentreNames"
        [(ngModel)]="newRepairCentreId"
        (onSelect)="changeRepairCentre($event)"
        name="newRepairCentreId">
      </select2>
    </ng-container>
  </div>

  <div class="mt-3 w-full flex flex-row justify-center pb-4">
    <button type="button" class="btn btn-success" (click)="reassignTicket()">Reassign</button>
  </div>
</ng-template>

<ng-template #closeWarrantyTicketModal>

  <div class="w-auto m-auto pt-5">
    <div class="input">
      <label style="font-weight: bold">{{'Closing Comment' | translate}} *</label>
      <textarea class="form-input form-input-focus" [(ngModel)]="closingComment" name="closingComment"></textarea>
    </div>

    <div class="input">
      <label class="label_title">{{'Disposition' | translate}}:</label>
      <select class="form-input form-input-focus" (change)="selectClosingDisposition($event.target.value)" name="dispositionClosing">
        <option selected value="">{{'Select one' | translate}}</option>
        <option value="{{disposition.id}}"
                *ngFor="let disposition of ticketClosingDispositions">{{disposition.name}}
        </option>
      </select>
    </div>
  </div>

  <div class="mt-3 w-full flex flex-row justify-center pb-4">
    <button type="button" class="btn btn-success" (click)="closeWarrantyTicket()">Close Ticket</button>
  </div>

</ng-template>

<ng-template #followUpCallModal>

  <div class="w-auto m-auto pt-5">
    <div class="input ">
      <label class="label_title">{{'Disposition' | translate}}:</label>
      <select [(ngModel)]="followupDisposition" class="form-input form-input-focus" name="followupDisposition">
        <option selected value="">{{'Select one' | translate}}</option>
        <option value="{{disposition}}"
                *ngFor="let disposition of followupDispositions">{{disposition}}
        </option>
      </select>
    </div>

    <div class="input mt-3" >
      <label class="label_title">{{'Notes' | translate}}:</label>
      <textarea class="form-input form-input-focus" [(ngModel)]="followupNotes" name="followupNotes" rows="3"></textarea>
    </div>
  </div>

  <div class="mt-3 w-full flex flex-row justify-center pb-4">
    <button type="button" class="btn btn-success" (click)="followupTicket()">Follow Up</button>
  </div>

</ng-template>
