import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';

import {Rate} from '@appModels/core-accounting/rate/rate';
import {RateActions, RateActionTypes} from '@appStore/actions/core-accounting/rate/rate.actions';
import * as _ from 'lodash';

export interface State extends EntityState<Rate> {
  loaded: boolean;
  loading: boolean;
  error: any;
  selectedRateId: number;
  selectedRate: Rate;
  searchRates: Rate[];
  paging: any;
}

export const adapter: EntityAdapter<Rate> = createEntityAdapter<Rate>();

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
  selectedRateId: null,
  selectedRate: null,
  error: null,
  searchTerm: '',
  searchRates: null,
  paging: null
});

export function reducer(state = initialState, action: RateActions): State {
  switch (action.type) {
    case RateActionTypes.rateGetRates:
    case RateActionTypes.rateGetRateByTenant:
    case RateActionTypes.rateAddRate:
    case RateActionTypes.rateAddAdditionalRateInfo:
    case RateActionTypes.rateDeleteRate:
    case RateActionTypes.rateUpdateRate:
    case RateActionTypes.rateSearchRates:
    case RateActionTypes.rateGetRateById:

      return {
        ...state,
        loading: true
      };

    case RateActionTypes.rateGetRatesSuccess:
      return adapter.setAll(action.payload.content, {
        ...state,
        loading: false,
        loaded: true,
        paging: _.omit(action.payload, ['content'])
      });

    case RateActionTypes.rateGetRateByTenantSuccess:
      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });

    case RateActionTypes.rateGetRateByIdSuccess:
      return {
        ...state,
        selectedRateId: action.payload.id,
        selectedRate: action.payload,
        loading: false
      };

    case RateActionTypes.rateAddRateSuccess:
      return adapter.addOne(action.payload, {
        ...state,
        selectedRateId: action.payload.id,
        loading: false,
        loaded: true
      });

    case RateActionTypes.rateUpdateRateSuccess: {
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state,
          loading: false,
          loaded: true
        }
      );
    }

    case RateActionTypes.rateDeleteRateSuccess: {
      return adapter.removeOne(action.payload.id, {
        ...state,
        loading: false,
        loaded: true
      });
    }

    case RateActionTypes.rateSearchRatesSuccess:
      return {
        ...state,
        searchRates: action.payload,
        loading: false
      };

    case RateActionTypes.rateSearchRatesReset:
      return {
        ...state,
        searchRates: null
      };

    case RateActionTypes.rateError:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload
      };

    default:
      return state;
  }
}

export const rateEntitySelectors = adapter.getSelectors();
