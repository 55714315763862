import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable, of} from 'rxjs';

import {CoreTicketingService} from "@appServices/core-ticketing/core-ticketing.service";
import {catchError, tap} from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class RequestTypeService extends CoreTicketingService {

  constructor(
    private http: HttpClient,
  ) {
    super(http, 'requesttype');
    this.headers = this.headers.append('skip-cache', 'true');
  }

  getRequestTypes(useTenant: boolean): Observable<any> {

    let tenantId = localStorage.getItem('tenant');
    let params = {};
    if (useTenant) {
      params = {
        tenantId: tenantId
      }
    }

    const url = `${this.base_url}/getRequestTypes`;
    return this.http.get<any>(url, {params: params, headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }

  createRequestType(body: any): Observable<any> {

    let tenantId = localStorage.getItem('tenant');

    const url = `${this.base_url}/createRequestType/${tenantId}`;
    return this.http.post<any>(url, body,{headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }

  updateRequestType(id:number, body: any): Observable<any> {
    const url = `${this.base_url}/updateRequestType/${id}`;
    return this.http.post<any>(url, body,{headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }

  getRequestType(id:number): Observable<any> {
    const url = `${this.base_url}/getRequestType/${id}`;
    return this.http.get<any>(url,{headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }

  validateAddNewTicketType(issueTypeId:number, ticketTypeId:number): Observable<any> {

    let params = {};
    if (issueTypeId != null) {
      params = {
        issueTypeId: issueTypeId
      }
    }
    console.log("validating add new ticket type for issue type " + issueTypeId)
    console.log(params);

    const url = `${this.base_url}/validateAddNewTicketType/${ticketTypeId}`;
    return this.http.get<any>(url,{params: params, headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }

  getSlaTypes(): Observable<any> {
    const url = `${this.base_url}/getSlaTypes`;
    return this.http.get<any>(url, {headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }

  getWarrantyAndServiceRequestType(useTenant:boolean): Observable<any> {
    let tenantId = localStorage.getItem('tenant')
    const url = `${this.base_url}/getWarrantyAndServiceRequestType/${tenantId}`;

    return this.http.get<any>(url, { headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }




}
