import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable, of} from 'rxjs';

import {CoreTicketingService} from "@appServices/core-ticketing/core-ticketing.service";
import {catchError, tap} from "rxjs/operators";


@Injectable({ providedIn: 'root' })
export class CallToActionRuleService extends CoreTicketingService {

  constructor(
    private http: HttpClient,
  ) {
    super(http, 'calltoactionrules');
    this.headers = this.headers.append('skip-cache', 'true');
  }

  createMapping(body:any): Observable<any> {
    let tenantId = localStorage.getItem('tenant');
    const url = `${this.base_url}/create/${tenantId}`;
    return this.http.post<any>(url, body,{headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }

  updateMapping(body:any): Observable<any> {
    const url = `${this.base_url}/update`;
    return this.http.post<any>(url, body,{headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }

  getCallToActionTypes(): Observable<any> {

    const url = `${this.base_url}/getCallToActionTypes`;
    return this.http.get<any>(url,{headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }

  getListRow(useTenant:boolean): Observable<any> {

    let tenantId = localStorage.getItem('tenant');
    let params = {};
    if (useTenant) {
      params = {
        tenantId: tenantId
      }
    }
    const url = `${this.base_url}/getListRow`;
    return this.http.get<any>(url,{params:params,headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }

  validateAddNewRule(body:any, workflowId?:number, currentMappingId?:number): Observable<any> {

    let tenantId = localStorage.getItem('tenant');
    let params = {}

    if (currentMappingId != null) {
      params = {
        tenantId : tenantId,
        currentMappingId : currentMappingId
      }
    } else {
      params = {
        tenantId : tenantId
      }
    }

    if (workflowId != null && workflowId != undefined) {
      Object.assign(params, {workflowId: workflowId});
    }

    const url = `${this.base_url}/validateAddNewRule`;
    return this.http.post<any>(url,body, {params:params,headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }



  getCallToActionRulesMapping(id:number): Observable<any> {

    const url = `${this.base_url}/getCallToActionRulesMapping/${id}`;
    return this.http.get<any>(url, {headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }




}
