import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {of} from "rxjs";
import {
  WorkOrderStateActionTypes, WorkOrderStatusError, WorkOrderStatusGetAll,
  WorkOrderStatusGetAllSuccess, WorkOrderStatusGetForTenant, WorkOrderStatusGetForTenantSuccess
} from '../../../actions/core-aftersales/work-order/workorderstate.action';
import {WorkOrderStatusService} from '../../../../services/core-aftersales/work-order/work-order-status.service';

@Injectable()
export class WorkorderStateEffects {

  
  workOrderStates$ = createEffect(() => this.actions$.pipe(
    ofType(WorkOrderStateActionTypes.workOrderStatusGetAll),
    mergeMap((action: WorkOrderStatusGetAll) =>
      this.workOrderStatusService
        .getWorkOrderStates(action.payload)
        .pipe(
          map(wo => new WorkOrderStatusGetAllSuccess(wo)),
          catchError(error => of(new WorkOrderStatusError(error)))
        )
    )
  ));

  
  workOrderStatesForTenant$ = createEffect(() => this.actions$.pipe(
    ofType(WorkOrderStateActionTypes.workOrderStatusGetForTenant),
    mergeMap((action: WorkOrderStatusGetForTenant) =>
      this.workOrderStatusService
        .getWorkOrderStatesForTenant()
        .pipe(
          map(wo => new WorkOrderStatusGetForTenantSuccess(wo)),
          catchError(error => of(new WorkOrderStatusError(error)))
        )
    )
  ));


  constructor(
    private actions$: Actions,
    private workOrderStatusService: WorkOrderStatusService,
  ) {
  }
}
