import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { SmsTemplate } from '@appModels/core-setup/sms-template/sms-template';

import { CoreMasterDataService } from '../core-masterdata.service';



@Injectable({ providedIn: 'root' })
export class SmsTemplateService extends CoreMasterDataService {


  constructor(
    private http: HttpClient,

  ) {
    super(http, 'smstemplate');
  }

  /** GET smsTemplates from the server */
  getSmsTemplates(query?): Observable<any[]> {
    let params = new HttpParams();
    if (query !== undefined){
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        params = params.append(val, query[val]);
      });
    }
    return this.http.get<any[]>(this.base_url + '/tenant/' + localStorage.getItem('tenant'), { params, headers: this.headers }).pipe(
      tap(smsTemplates => this.log(`fetched smsTemplates`)),
      catchError(this.handleError('getSmsTemplates', []))
    );
  }

  /** GET smsTemplate by id. Return `undefined` when id not found */
  getSmsTemplateNo404<Data>(id: number): Observable<SmsTemplate> {
    const url = `${this.base_url}/?id=${id}`;
    return this.http.get<SmsTemplate[]>(url).pipe(
      map(smsTemplates => smsTemplates[0]), // returns a {0|1} element array
      tap(h => {
        const outcome = h ? `fetched` : `did not find`;
        this.log(`${outcome} smsTemplate id=${id}`);
      }),
      catchError(this.handleError<SmsTemplate>(`getSmsTemplate id=${id}`))
    );
  }

  /** GET smsTemplate by id. Will 404 if id not found */
  getSmsTemplate(id: number): Observable<SmsTemplate> {
    const url = `${this.base_url}/${id}`;
    return this.http.get<SmsTemplate>(url,{ headers: this.headers }).pipe(
      tap(_ => this.log(`fetched smsTemplate id=${id}`)),
      catchError(this.handleError<SmsTemplate>(`getSmsTemplate id=${id}`))
    );
  }

  /* GET smsTemplates whose name contains search term */
  searchSmsTemplates(term: string): Observable<SmsTemplate[]> {
    if (!term.trim()) {
      // if not search term, return empty smsTemplate array.
      return of([]);
    }
    return this.http.get<SmsTemplate[]>(`api/smsTemplates/?name=${term}`).pipe(
      tap(_ => this.log(`found smsTemplates matching "${term}"`)),
      catchError(this.handleError<SmsTemplate[]>('searchSmsTemplates', []))
    );
  }

  //////// Save methods //////////

  /** POST: add a new smsTemplate to the server */
  addSmsTemplate(smsTemplate: SmsTemplate): Observable<SmsTemplate> {
    return this.http.post<SmsTemplate>(this.base_url , smsTemplate, { headers: this.headers }).pipe(
      tap((smsTemplate: SmsTemplate) => this.log(`added smsTemplate w/ id=${smsTemplate.id}`)),
      catchError(this.handleError<SmsTemplate>('addSmsTemplate'))
    );
  }

  /** DELETE: delete the smsTemplate from the server */
  deleteSmsTemplate(smsTemplate: SmsTemplate | number): Observable<SmsTemplate> {
    const id = typeof smsTemplate === 'number' ? smsTemplate : smsTemplate.id;
    const url = `${this.base_url}/${id}`;

    return this.http.delete<SmsTemplate>(url, { headers: this.headers }).pipe(
      tap(_ => this.log(`deleted smsTemplate id=${id}`)),
      catchError(this.handleError<SmsTemplate>('deleteSmsTemplate'))
    );
  }

  /** PUT: update the smsTemplate on the server */
  updateSmsTemplate(smsTemplate: SmsTemplate): Observable<any> {
    return this.http.put(this.base_url + '/' + smsTemplate.id, smsTemplate, { headers: this.headers }).pipe(
      tap(_ => this.log(`updated smsTemplate id=${smsTemplate.id}`)),
      catchError(this.handleError<any>('updateSmsTemplate'))
    );
  }

    /** Patch: update the smsTemplate on the server */
    editSmsTemplate(data): Observable<any> {
      return this.http.patch(`${this.base_url}/${data.id}/${data.param}/${data.value}`, {}, {headers: this.headers}).pipe(
        tap(_ => this.log(`edit smsTemplate id=${data.id}`)),
        catchError(this.handleError<any>('editSmsTemplate'))
      );
    }

}
