import { Action } from '@ngrx/store';

import { IdentificationType } from '@appModels/core-crm/identification-type/identification-type';

export enum IdentificationTypeActionTypes {
  identificationTypeGetIdentificationTypes = '[IdentificationType] get',
  identificationTypeGetIdentificationTypesSuccess = '[IdentificationType] get identificationTypes success',
  identificationTypeAddIdentificationType = '[IdentificationType] add IdentificationType',
  identificationTypeAddIdentificationTypeSuccess = '[IdentificationType] add identificationType success',
  identificationTypeDeleteIdentificationType = '[IdentificationType] delete identificationType',
  identificationTypeDeleteIdentificationTypeSuccess = '[IdentificationType] delete identificationType success',
  identificationTypeGetIdentificationTypeById = '[IdentificationType] get identificationType by id',
  identificationTypeGetIdentificationTypeByIdSuccess = '[IdentificationType] get identificationType by id success',
  identificationTypeUpdateIdentificationType = '[IdentificationType] update identificationType',
  identificationTypeUpdateIdentificationTypeSuccess = '[IdentificationType] update identificationType success',
  identificationTypeEditIdentificationType = '[IdentificationType] edit identificationType',
  identificationTypeEditIdentificationTypeSuccess = '[IdentificationType] edit identificationType success',
  identificationTypeSearchIdentificationTypes = '[IdentificationType] search identificationTypes',
  identificationTypeSearchIdentificationTypesSuccess = '[IdentificationType] search identificationTypes success',
  identificationTypeSearchIdentificationTypesReset = '[IdentificationType] search identificationTypes reset',
  identificationTypeError = '[IdentificationType] error'
}

export class GetIdentificationTypes implements Action {
  readonly type = IdentificationTypeActionTypes.identificationTypeGetIdentificationTypes;
  constructor(public payload?) {}
}

export class GetIdentificationTypesSuccess implements Action {
  readonly type = IdentificationTypeActionTypes.identificationTypeGetIdentificationTypesSuccess;
  constructor(public payload) {}
}

export class AddIdentificationType implements Action {
  readonly type = IdentificationTypeActionTypes.identificationTypeAddIdentificationType;
  constructor(public payload: IdentificationType) {}
}

export class AddIdentificationTypeSuccess implements Action {
  readonly type = IdentificationTypeActionTypes.identificationTypeAddIdentificationTypeSuccess;
  constructor(public payload: IdentificationType) {}
}

export class GetIdentificationTypeById implements Action {
  readonly type = IdentificationTypeActionTypes.identificationTypeGetIdentificationTypeById;
  constructor(public payload: number) {}
}

export class GetIdentificationTypeByIdSuccess implements Action {
  readonly type = IdentificationTypeActionTypes.identificationTypeGetIdentificationTypeByIdSuccess;
  constructor(public payload: IdentificationType) {}
}

export class UpdateIdentificationType implements Action {
  readonly type = IdentificationTypeActionTypes.identificationTypeUpdateIdentificationType;
  constructor(public payload: IdentificationType) {}
}

export class UpdateIdentificationTypeSuccess implements Action {
  readonly type = IdentificationTypeActionTypes.identificationTypeUpdateIdentificationTypeSuccess;
  constructor(public payload: IdentificationType) {}
}

export class EditIdentificationType implements Action {
  readonly type = IdentificationTypeActionTypes.identificationTypeEditIdentificationType;
  constructor(public payload) {}
}

export class EditIdentificationTypeSuccess implements Action {
  readonly type = IdentificationTypeActionTypes.identificationTypeEditIdentificationTypeSuccess;
  constructor(public payload) {}
}

export class DeleteIdentificationType implements Action {
  readonly type = IdentificationTypeActionTypes.identificationTypeDeleteIdentificationType;
  constructor(public payload: IdentificationType) {}
}

export class DeleteIdentificationTypeSuccess implements Action {
  readonly type = IdentificationTypeActionTypes.identificationTypeDeleteIdentificationTypeSuccess;
  constructor(public payload: IdentificationType) {}
}

export class SearchIdentificationTypes implements Action {
  readonly type = IdentificationTypeActionTypes.identificationTypeSearchIdentificationTypes;
  constructor(public payload: string) {}
}

export class SearchIdentificationTypesSuccess implements Action {
  readonly type = IdentificationTypeActionTypes.identificationTypeSearchIdentificationTypesSuccess;
  constructor(public payload: IdentificationType[]) {}
}

export class SearchIdentificationTypesReset implements Action {
  readonly type = IdentificationTypeActionTypes.identificationTypeSearchIdentificationTypesReset;
}

export class IdentificationTypeError implements Action {
  readonly type = IdentificationTypeActionTypes.identificationTypeError;
  constructor(public payload: any) {}
}

export type IdentificationTypeActions =
  | GetIdentificationTypes
  | GetIdentificationTypesSuccess
  | AddIdentificationType
  | AddIdentificationTypeSuccess
  | GetIdentificationTypeById
  | GetIdentificationTypeByIdSuccess
  | UpdateIdentificationType
  | UpdateIdentificationTypeSuccess
  | EditIdentificationType
  | EditIdentificationTypeSuccess
  | DeleteIdentificationType
  | DeleteIdentificationTypeSuccess
  | SearchIdentificationTypes
  | SearchIdentificationTypesSuccess
  | SearchIdentificationTypesReset
  | IdentificationTypeError;
