import {Action} from '@ngrx/store';

export enum ReportSchemaActionTypes {
  reportSchemaGetByTenant = '[Report Schema] Get by tenant',
  reportSchemaGetForUser = '[Report Schema] Get For User',
  reportSchemaGetByTenantSuccess = '[Report Schema] Get by tenant Success',
  reportSchemaGetForUserSuccess = '[Report Schema] Get For User Success',
  reportSchemaError = '[Report Schema] Error'
}

export class ReportSchemaGetByTenant implements Action {
  readonly type = ReportSchemaActionTypes.reportSchemaGetByTenant;

  constructor(public payload: any) {

  }
}

export class ReportSchemaGetByTenantSuccess implements Action {
  readonly type = ReportSchemaActionTypes.reportSchemaGetByTenantSuccess;

  constructor(public payload: any) {

  }
}

export class ReportSchemaGetForUser implements Action {
  readonly type = ReportSchemaActionTypes.reportSchemaGetForUser;

  constructor(public payload: any) {

  }
}

export class ReportSchemaGetForUserSuccess implements Action {
  readonly type = ReportSchemaActionTypes.reportSchemaGetForUserSuccess;

  constructor(public payload: any) {

  }
}

export class ReportSchemaError implements Action {
  readonly type = ReportSchemaActionTypes.reportSchemaError;

  constructor(public payload: any) {

  }
}


export type ReportSchemaActions =
  | ReportSchemaGetForUser
  | ReportSchemaGetForUserSuccess
  | ReportSchemaGetByTenant
  | ReportSchemaGetByTenantSuccess
  | ReportSchemaError;


