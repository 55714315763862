import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';

import {CustomRegion} from '@appModels/core-setup/custom-region/custom-region';

import {CoreMasterDataService} from '../core-masterdata.service';


@Injectable({providedIn: 'root'})
export class CustomRegionService extends CoreMasterDataService {


  constructor(
    private http: HttpClient,
  ) {
    super(http, 'tenantcustomregion');
  }

  /** GET customRegions from the server */
  getCustomRegions(): Observable<CustomRegion[]> {
    return this.http.get<CustomRegion[]>(this.base_url + '/tenant/' + localStorage.getItem('tenant'), {headers: this.headers}).pipe(
      tap(customRegions => this.log(`fetched customRegions`)),
      catchError(this.handleError('getCustomRegions', []))
    );
  }

  /** GET customRegions by module. Will 404 if id not found */
  getCustomRegionsByTenant(id: number, query?): Observable<CustomRegion[]> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        if (query[val] !== null && query[val] !== undefined && query[val] !== '') {
          params = params.append(val, query[val]);
        }
      });
    }

    const url = `${this.base_url}/tenant/${id}`;
    return this.http.get<CustomRegion[]>(url, {headers: this.headers, params: params}).pipe(
      tap(_ => this.log(`fetched tenant id=${id}`)),
      catchError(this.handleError<CustomRegion[]>(`getTenant id=${id}`))
    );
  }

  /** GET customRegions by module. Will 404 if id not found */
  getAllCustomRegionsByTenant(id: number): Observable<CustomRegion[]> {
    const url = `${this.base_url}/all/tenant/${id}`;
    return this.http.get<CustomRegion[]>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched tenant id=${id}`)),
      catchError(this.handleError<CustomRegion[]>(`getTenant id=${id}`))
    );
  }


  /** GET customRegion by id. Return `undefined` when id not found */
  getCustomRegionNo404<Data>(id: number): Observable<CustomRegion> {
    const url = `${this.base_url}/?id=${id}`;
    return this.http.get<CustomRegion[]>(url).pipe(
      map(customRegions => customRegions[0]), // returns a {0|1} element array
      tap(h => {
        const outcome = h ? `fetched` : `did not find`;
        this.log(`${outcome} customRegion id=${id}`);
      }),
      catchError(this.handleError<CustomRegion>(`getCustomRegion id=${id}`))
    );
  }

  /** GET customRegion by id. Will 404 if id not found */
  getCustomRegion(id: number): Observable<CustomRegion> {
    const url = `${this.base_url}/${id}`;
    return this.http.get<CustomRegion>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched customRegion id=${id}`)),
      catchError(this.handleError<CustomRegion>(`getCustomRegion id=${id}`))
    );
  }

  /** GET adminZone by id. Will 404 if id not found */
  getAdminZoneByRegionId(id: number): Observable<any> {
    const url = `${this.base_url}/countryadminzone/${id}`;
    return this.http.get<any>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched customRegionAdminZone id=${id}`)),
      catchError(this.handleError<any>(`getCustomRegionAdminZone id=${id}`))
    );
  }

  /* GET customRegions whose name contains search term */
  searchCustomRegions(term: string): Observable<CustomRegion[]> {
    if (!term.trim()) {
      // if not search term, return empty customRegion array.
      return of([]);
    }
    return this.http.get<CustomRegion[]>(`api/customRegions/?name=${term}`).pipe(
      tap(_ => this.log(`found customRegions matching "${term}"`)),
      catchError(this.handleError<CustomRegion[]>('searchCustomRegions', []))
    );
  }

  //////// Save methods //////////

  /** POST: add a new customRegion to the server */
  addCustomRegion(customRegion: CustomRegion): Observable<CustomRegion> {
    return this.http.post<CustomRegion>(this.base_url , customRegion, {headers: this.headers}).pipe(
      tap((customRegion: CustomRegion) => this.log(`added customRegion w/ id=${customRegion.id}`)),
      catchError(this.handleError<CustomRegion>('addCustomRegion'))
    );
  }

  /** DELETE: delete the customRegion from the server */
  deleteCustomRegion(customRegion: CustomRegion | number): Observable<CustomRegion> {
    const id = typeof customRegion === 'number' ? customRegion : customRegion.id;
    const url = `${this.base_url}/${id}`;

    return this.http.delete<CustomRegion>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`deleted customRegion id=${id}`)),
      catchError(this.handleError<CustomRegion>('deleteCustomRegion'))
    );
  }

  /** PUT: update the customRegion on the server */
  updateCustomRegion(customRegion: any): Observable<any> {
    return this.http.put(`${this.base_url}/${customRegion.id}`, customRegion, {headers: this.headers}).pipe(
      tap(_ => this.log(`updated customRegion id=${customRegion.id}`)),
      catchError(this.handleError<any>('updateCustomRegion'))
    );
  }

  /** Patch: update the customRegion on the server */
  editCustomRegion(data): Observable<any> {
    return this.http.patch(`${this.base_url}/tenant/${data.value}/${data.param}/${data.id}`, {}, {headers: this.headers}).pipe(
      tap(_ => this.log(`updated customRegion id=${data.id}`)),
      catchError(this.handleError<any>('updateCustomRegion'))
    );
  }

  getMappedCaz(tenantCustomRegionId:number): Observable<any> {
    return this.http.get(`${this.base_url}/cazmappings/${tenantCustomRegionId}`, {headers: this.headers});
  }

  deleteMapping(mappingId:number): Observable<any> {
    return this.http.delete(`${this.base_url}/cazmappings/${mappingId}`, {headers: this.headers});
  }

  updateMapping(updateData:any): Observable<any> {
    return this.http.put(`${this.base_url}/cazmappings/update`, updateData, {headers: this.headers});
  }

}
