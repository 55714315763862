import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { mergeMap, map, catchError, tap, debounceTime, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import {
  IdentificationTypeActionTypes,
  GetIdentificationTypes,
  GetIdentificationTypesSuccess,
  IdentificationTypeError,
  AddIdentificationType,
  AddIdentificationTypeSuccess,
  DeleteIdentificationTypeSuccess,
  DeleteIdentificationType,
  GetIdentificationTypeById,
  GetIdentificationTypeByIdSuccess,
  UpdateIdentificationTypeSuccess,
  SearchIdentificationTypes,
  SearchIdentificationTypesSuccess,
  EditIdentificationTypeSuccess
} from '@appStore/actions/core-crm/identification-type/identification-type.actions';
import { IdentificationTypeService } from '@appServices/core-crm/identification-type/identification-type.service';

import * as fromRouterActions from '@appStore/actions/router.actions';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class IdentificationTypeEffects {
  constructor(
    private actions$: Actions, 
    private identificationTypeService: IdentificationTypeService,
    private toastr: ToastrService
    ) {}

  
  loadIdentificationTypes$ = createEffect(() => this.actions$.pipe(
    ofType(IdentificationTypeActionTypes.identificationTypeGetIdentificationTypes),
    mergeMap((action: GetIdentificationTypes) =>
      this.identificationTypeService
        .getIdentificationTypes(action.payload)
        .pipe(
          map(identificationTypes => new GetIdentificationTypesSuccess(identificationTypes)),
          catchError(error => of(new IdentificationTypeError(error)))
        )
    )
  ));

  
  getIdentificationTypeById$ = createEffect(() => this.actions$.pipe(
    ofType(IdentificationTypeActionTypes.identificationTypeGetIdentificationTypeById),
    mergeMap((action: GetIdentificationTypeById) =>
      this.identificationTypeService
        .getIdentificationType(action.payload)
        .pipe(
          map(identificationType => new GetIdentificationTypeByIdSuccess(identificationType)),
          catchError(error => of(new IdentificationTypeError(error)))
        )
    )
  ));

  
  addIdentificationType$ = createEffect(() => this.actions$.pipe(
    ofType(IdentificationTypeActionTypes.identificationTypeAddIdentificationType),
    switchMap((action: AddIdentificationType) =>
      this.identificationTypeService
        .addIdentificationType(action.payload)
        .pipe(
          map(
            identificationType => {
              if (identificationType !== undefined){
                this.toastr.success('Identification type has been successfully added!', 'Successful!')
                return new AddIdentificationTypeSuccess(identificationType)
              }
              this.toastr.error('There was an error adding this identification type!', "Unknown error")
              return new IdentificationTypeError({ type: 500, message: 'Internal error' })
            }
          ),
          catchError(error => of(new IdentificationTypeError(error)))
        )
    )
  ));

  
  updateIdentificationType$ = createEffect(() => this.actions$.pipe(
    ofType(IdentificationTypeActionTypes.identificationTypeUpdateIdentificationType),
    mergeMap((action: AddIdentificationType) =>
      this.identificationTypeService
        .updateIdentificationType(action.payload)
        .pipe(
          map(identificationType => new UpdateIdentificationTypeSuccess(action.payload)),
          catchError(error => of(new IdentificationTypeError(error)))
        )
    )
  ));

  
  editIdentificationType$ = createEffect(() => this.actions$.pipe(
    ofType(IdentificationTypeActionTypes.identificationTypeEditIdentificationType),
    mergeMap((action: AddIdentificationType) =>
      this.identificationTypeService
        .editIdentificationType(action.payload)
        .pipe(
          map(identificationType => new GetIdentificationTypes()),
          catchError(error => of(new IdentificationTypeError(error)))
        )
    )
  ));

  
  deleteIdentificationType$ = createEffect(() => this.actions$.pipe(
    ofType(IdentificationTypeActionTypes.identificationTypeDeleteIdentificationType),
    mergeMap((action: DeleteIdentificationType) =>
      this.identificationTypeService
        .deleteIdentificationType(action.payload)
        .pipe(
          map(() => new DeleteIdentificationTypeSuccess(action.payload)),
          catchError(error => of(new IdentificationTypeError(error)))
        )
    )
  ));

  
  searchIdentificationTypes$ = createEffect(() => this.actions$.pipe(
    ofType(IdentificationTypeActionTypes.identificationTypeSearchIdentificationTypes),
    mergeMap((action: SearchIdentificationTypes) =>
      this.identificationTypeService
        .searchIdentificationTypes(action.payload)
        .pipe(
          map(identificationTypes => new SearchIdentificationTypesSuccess(identificationTypes)),
          catchError(error => of(new IdentificationTypeError(error)))
        )
    )
  ));

  
  updateIdentificationTypeSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(IdentificationTypeActionTypes.identificationTypeUpdateIdentificationTypeSuccess),
    map(identificationType => new fromRouterActions.Go({ path: ['/setup/identification-type'] }))
  ));
}
