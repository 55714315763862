import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getConsignmentStore = createFeatureSelector('consignment');

export const getConsignmentEntities = createSelector(
  getConsignmentStore,
  fromReducers.consignment.consignmentEntitySelectors.selectAll
);

export const getConsignments = createSelector(getConsignmentEntities, entities => {
  return Object.values(entities);
});

export const getTopConsignments = createSelector(getConsignmentEntities, entities => {
  return Object.values(entities).slice(1, 5);
});

export const getConsignmentsLoaded = createSelector(
  getConsignmentStore,
  (consignmentStore: fromReducers.consignment.State) => consignmentStore.loaded
);

export const getConsignmentsCount = createSelector(
  getConsignmentStore,
  (consignmentStore: fromReducers.consignment.State) => consignmentStore.count
);

export const getConsignmentsStartPage = createSelector(
  getConsignmentStore,
  (consignmentStore: fromReducers.consignment.State) => consignmentStore.startPage
);


export const getConsignmentsNumberPerPage = createSelector(
  getConsignmentStore,
  (consignmentStore: fromReducers.consignment.State) => consignmentStore.numberPerPage
);

export const getConsignmentsLoading = createSelector(
  getConsignmentStore,
  (consignmentStore: fromReducers.consignment.State) => consignmentStore.loading
);

export const getSelectedConsignmentId = createSelector(
  getConsignmentStore,
  (consignmentStore: fromReducers.consignment.State) => consignmentStore.selectedConsignmentId
);

export const getSearchConsignments = createSelector(
  getConsignmentStore,
  (consignmentStore: fromReducers.consignment.State) => consignmentStore.searchConsignments
);


export const getConsignmentsError = createSelector(
  getConsignmentStore,
  (consignmentStore: fromReducers.consignment.State) => consignmentStore.error
);
