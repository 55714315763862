import {createEntityAdapter, EntityAdapter, EntityState,} from '@ngrx/entity';

import {SalesStaffDefinition} from '@appModels/core-fieldforce/sales-staff-definition/sales-staff-definition';
import {
  SalesStaffDefinitionActions,
  SalesStaffDefinitionActionTypes
} from '@appStore/actions/core-fieldforce/sales-staff-definition/sales-staff-definition.actions';

export interface State extends EntityState<SalesStaffDefinition> {
  loaded: boolean;
  loading: boolean;
  error: any;
  selectedSalesStaffDefinitionId: number;
  selectedSalesStaffDefinition: any;
  searchSalesStaffDefinitions: SalesStaffDefinition[];
}

export const adapter: EntityAdapter<SalesStaffDefinition> = createEntityAdapter<SalesStaffDefinition>();

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
  selectedSalesStaffDefinitionId: null,
  error: null,
  searchTerm: '',
  selectedSalesStaffDefinition: null,
  searchSalesStaffDefinitions: null
});

export function reducer(state = initialState, action: SalesStaffDefinitionActions): State {
  switch (action.type) {
    case SalesStaffDefinitionActionTypes.salesStaffGetStaffDefinitions:
    case SalesStaffDefinitionActionTypes.salesStaffAddStaffDefinition:
    case SalesStaffDefinitionActionTypes.salesStaffAddStaffDefinitiontAttributes:
    case SalesStaffDefinitionActionTypes.salesStaffDeleteStaffDefinition:
    case SalesStaffDefinitionActionTypes.salesStaffUpdateStaffDefinition:
    case SalesStaffDefinitionActionTypes.salesStaffSearchStaffDefinitions:
    case SalesStaffDefinitionActionTypes.salesStaffGetStaffDefinitionById:
    case SalesStaffDefinitionActionTypes.salesStaffGeStaffDefinitionsByTenantId:

      return {
        ...state,
        loading: true
      };

    case SalesStaffDefinitionActionTypes.salesStaffGetStaffDefinitionsSuccess:
      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });

    case SalesStaffDefinitionActionTypes.salesStaffGetStaffDefinitionByIdSuccess:
      console.log("get by id ");
      console.log(action.payload);
      return {...state, selectedSalesStaffDefinitionId: action.payload.id, loading: false};



    case SalesStaffDefinitionActionTypes.salesStaffAddSStaffDefinitionSuccess:
      return adapter.addOne(action.payload, {
        ...state,
        selectedSalesStaffDefinitionId: action.payload.id,
        loading: false,
        loaded: true
      });

    case SalesStaffDefinitionActionTypes.salesStaffGeStaffDefinitionsByTenantIdSuccess:
      console.log("get by tenant id success");
      console.log(action.payload);
      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });

    case SalesStaffDefinitionActionTypes.salesStaffUpdateStaffDefinitionSuccess: {
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state,
          loading: false,
          loaded: true
        }
      );
    }

    case SalesStaffDefinitionActionTypes.salesStaffDeleteStaffDefinitionSuccess: {
      return adapter.removeOne(action.payload.id, {
        ...state,
        loading: false,
        loaded: true
      });
    }

    case SalesStaffDefinitionActionTypes.salesStaffSearchStaffDefinitionsSuccess:
      return {
        ...state,
        searchSalesStaffDefinitions: action.payload,
        loading: false
      };

    case SalesStaffDefinitionActionTypes.salesStaffSearchStaffDefinitionsReset:
      return {
        ...state,
        searchSalesStaffDefinitions: null
      };

    case SalesStaffDefinitionActionTypes.salesStaffError:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload
      };

    default:
      return state;
  }
}

export const salesStaffDefinitionEntitySelectors = adapter.getSelectors();
