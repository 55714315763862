import {Action} from '@ngrx/store';

import {User} from '@appModels/core-identity/user/user';

export enum UserActionTypes {
  userGetUsers = '[User] get',
  userGetTenantUsers = '[User] getTenantUsers',
  userGetUsersSuccess = '[User] get users success',
  userAddUser = '[User] add User',
  userUploadUser = '[User] upload User',
  userUploadUserSuccess = '[User] upload User success',
  userRoleUploadUser = '[User] role upload User',
  userRoleUploadUserSuccess = '[User] role upload User success',
  userAddUserSuccess = '[User] add user success',
  userDeleteUser = '[User] delete user',
  userDeleteUserSuccess = '[User] delete user success',
  userGetUserById = '[User] get user by id',
  userGetUserByIdSuccess = '[User] get user by id success',
  userUpdateUser = '[User] update user',
  userUpdateUserSuccess = '[User] update user success',
  userSearchUsers = '[User] search users',
  userSearchUsersSuccess = '[User] search users success',
  userSearchUsersReset = '[User] search users reset',
  userError = '[User] error'
}

export class GetUsers implements Action {
  readonly type = UserActionTypes.userGetUsers;
}

export class GetTenantUsers implements Action {
  readonly type = UserActionTypes.userGetTenantUsers;
}

export class GetUsersSuccess implements Action {
  readonly type = UserActionTypes.userGetUsersSuccess;
  constructor(public payload: User[]) {}
}

export class AddUser implements Action {
  readonly type = UserActionTypes.userAddUser;

  constructor(public payload) {
  }
}

export class AddUserSuccess implements Action {
  readonly type = UserActionTypes.userAddUserSuccess;
  constructor(public payload: User) {}
}

export class UploadUser implements Action {
  readonly type = UserActionTypes.userUploadUser;
  constructor(public payload) {}
}

export class UploadUserSuccess implements Action {
  readonly type = UserActionTypes.userUploadUserSuccess;
  constructor(public payload) {}
}

export class UploadUserRole implements Action {
  readonly type = UserActionTypes.userRoleUploadUser;
  constructor(public payload) {}
}

export class UploadUserRoleSuccess implements Action {
  readonly type = UserActionTypes.userRoleUploadUserSuccess;
  constructor(public payload) {}
}

export class GetUserById implements Action {
  readonly type = UserActionTypes.userGetUserById;
  constructor(public payload: number) {}
}

export class GetUserByIdSuccess implements Action {
  readonly type = UserActionTypes.userGetUserByIdSuccess;
  constructor(public payload: User) {}
}

export class UpdateUser implements Action {
  readonly type = UserActionTypes.userUpdateUser;
  constructor(public payload: User) {}
}

export class UpdateUserSuccess implements Action {
  readonly type = UserActionTypes.userUpdateUserSuccess;
  constructor(public payload: User) {}
}

export class DeleteUser implements Action {
  readonly type = UserActionTypes.userDeleteUser;
  constructor(public payload: User) {}
}

export class DeleteUserSuccess implements Action {
  readonly type = UserActionTypes.userDeleteUserSuccess;
  constructor(public payload: User) {}
}

export class SearchUsers implements Action {
  readonly type = UserActionTypes.userSearchUsers;
  constructor(public payload: string) {}
}

export class SearchUsersSuccess implements Action {
  readonly type = UserActionTypes.userSearchUsersSuccess;
  constructor(public payload: User[]) {}
}

export class SearchUsersReset implements Action {
  readonly type = UserActionTypes.userSearchUsersReset;
}

export class UserError implements Action {
  readonly type = UserActionTypes.userError;
  constructor(public payload: any) {}
}

export type UserActions =
  GetUsers
  | GetTenantUsers
  | GetUsersSuccess
  | AddUser
  | AddUserSuccess
  | UploadUser
  | UploadUserSuccess
  | UploadUserRole
  | UploadUserRoleSuccess
  | GetUserById
  | GetUserByIdSuccess
  | UpdateUser
  | UpdateUserSuccess
  | DeleteUser
  | DeleteUserSuccess
  | SearchUsers
  | SearchUsersSuccess
  | SearchUsersReset
  | UserError;
