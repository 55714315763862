import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getRateStore = createFeatureSelector('rate');

export const getRateEntities = createSelector(
  getRateStore,
  fromReducers.rate.rateEntitySelectors.selectAll
);

export const getRates = createSelector(getRateEntities, entities => {
  return Object.values(entities);
});

export const getTopRates = createSelector(getRateEntities, entities => {
  return Object.values(entities).slice(1, 5);
});

export const getRatePaging = createSelector(
  getRateStore,
  (rateStore: fromReducers.rate.State) => rateStore.paging
);

export const getRatesLoaded = createSelector(
  getRateStore,
  (rateStore: fromReducers.rate.State) => rateStore.loaded
);

export const getRatesLoading = createSelector(
  getRateStore,
  (rateStore: fromReducers.rate.State) => rateStore.loading
);

export const getSelectedRateId = createSelector(
  getRateStore,
  (rateStore: fromReducers.rate.State) => rateStore.selectedRateId
);

export const getSearchRates = createSelector(
  getRateStore,
  (rateStore: fromReducers.rate.State) => rateStore.searchRates
);

export const getRateById = createSelector(
  getRateStore,
  (rateStore: fromReducers.rate.State) => rateStore.selectedRate
);

export const getRatesError = createSelector(
  getRateStore,
  (rateStore: fromReducers.rate.State) => rateStore.error
);


