import { Action } from '@ngrx/store';

import { ProductType } from '@appModels/core-setup/product-type/product-type';

export enum ProductTypeActionTypes {
  productTypeGetProductTypes = '[ProductType] get',
  productTypeGetProductTypesSuccess = '[ProductType] get productTypes success',
  productTypeAddProductType = '[ProductType] add ProductType',
  productTypeAddProductTypeSuccess = '[ProductType] add productType success',
  productTypeDeleteProductType = '[ProductType] delete productType',
  productTypeDeleteProductTypeSuccess = '[ProductType] delete productType success',
  productTypeGetProductTypeById = '[ProductType] get productType by id',
  productTypeGetProductTypeByIdSuccess = '[ProductType] get productType by id success',
  productTypeUpdateProductType = '[ProductType] update productType',
  productTypeUpdateProductTypeSuccess = '[ProductType] update productType success',
  productTypeEditProductType = '[ProductType] edit productType',
  productTypeEditProductTypeSuccess = '[ProductType] edit productType success',
  productTypeSearchProductTypes = '[ProductType] search productTypes',
  productTypeSearchProductTypesSuccess = '[ProductType] search productTypes success',
  productTypeSearchProductTypesReset = '[ProductType] search productTypes reset',
  productTypeError = '[ProductType] error'
}

export class GetProductTypes implements Action {
  readonly type = ProductTypeActionTypes.productTypeGetProductTypes;
}

export class GetProductTypesSuccess implements Action {
  readonly type = ProductTypeActionTypes.productTypeGetProductTypesSuccess;
  constructor(public payload: ProductType[]) {}
}

export class AddProductType implements Action {
  readonly type = ProductTypeActionTypes.productTypeAddProductType;
  constructor(public payload: ProductType) {}
}

export class AddProductTypeSuccess implements Action {
  readonly type = ProductTypeActionTypes.productTypeAddProductTypeSuccess;
  constructor(public payload: ProductType) {}
}

export class GetProductTypeById implements Action {
  readonly type = ProductTypeActionTypes.productTypeGetProductTypeById;
  constructor(public payload: number) {}
}

export class GetProductTypeByIdSuccess implements Action {
  readonly type = ProductTypeActionTypes.productTypeGetProductTypeByIdSuccess;
  constructor(public payload: ProductType) {}
}

export class UpdateProductType implements Action {
  readonly type = ProductTypeActionTypes.productTypeUpdateProductType;
  constructor(public payload: ProductType) {}
}

export class UpdateProductTypeSuccess implements Action {
  readonly type = ProductTypeActionTypes.productTypeUpdateProductTypeSuccess;
  constructor(public payload: ProductType) {}
}

export class EditProductType implements Action {
  readonly type = ProductTypeActionTypes.productTypeEditProductType;
  constructor(public payload) {}
}

export class EditProductTypeSuccess implements Action {
  readonly type = ProductTypeActionTypes.productTypeEditProductTypeSuccess;
  constructor(public payload) {}
}

export class DeleteProductType implements Action {
  readonly type = ProductTypeActionTypes.productTypeDeleteProductType;
  constructor(public payload: ProductType) {}
}

export class DeleteProductTypeSuccess implements Action {
  readonly type = ProductTypeActionTypes.productTypeDeleteProductTypeSuccess;
  constructor(public payload: ProductType) {}
}

export class SearchProductTypes implements Action {
  readonly type = ProductTypeActionTypes.productTypeSearchProductTypes;
  constructor(public payload: string) {}
}

export class SearchProductTypesSuccess implements Action {
  readonly type = ProductTypeActionTypes.productTypeSearchProductTypesSuccess;
  constructor(public payload: ProductType[]) {}
}

export class SearchProductTypesReset implements Action {
  readonly type = ProductTypeActionTypes.productTypeSearchProductTypesReset;
}

export class ProductTypeError implements Action {
  readonly type = ProductTypeActionTypes.productTypeError;
  constructor(public payload: any) {}
}

export type ProductTypeActions =
  | GetProductTypes
  | GetProductTypesSuccess
  | AddProductType
  | AddProductTypeSuccess
  | GetProductTypeById
  | GetProductTypeByIdSuccess
  | UpdateProductType
  | UpdateProductTypeSuccess
  | EditProductType
  | EditProductTypeSuccess
  | DeleteProductType
  | DeleteProductTypeSuccess
  | SearchProductTypes
  | SearchProductTypesSuccess
  | SearchProductTypesReset
  | ProductTypeError;
