import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CRMComponent } from "./crm.component";
import { FeatureToggleModule } from "ngx-feature-toggle";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { KYCSharedComponentModule } from "./shared/kyc-shared-component.module";

export const routes: Routes = [
  {
    path: "",
    component: CRMComponent,
    children: [
      {
        path: "",
        redirectTo: "dashboard",
        pathMatch: "full",
      },
      {
        path: "dashboard",
        loadChildren: () =>
          import("./crm-dashboard/crm-dashboard.module").then(
            (m) => m.CrmDashboardModule
          ),
      },
      {
        path: "customers",
        loadChildren: () =>
          import("./customers/customer.module").then((m) => m.CustomerModule),
      },
      {
        path: "escalations",
        loadChildren: () =>
          import("./escalations/escalation.module").then(
            (m) => m.EscalationModule
          ),
      },
      {
        path: "held-payments",
        loadChildren: () =>
          import("@appContainers/crm/held-payment/held-payment.module").then(
            (m) => m.HeldPaymentModule
          ),
      },
      {
        path: "failed-payments",
        loadChildren: () =>
          import("@appContainers/crm/failed-payment/failed-payment.module").then(
            (m) => m.FailedPaymentModule
          ),
      },
      {
        path: "rekey-token",
        loadChildren: () =>
          import("@appContainers/crm/rekey-token/rekey-token.module").then(
            (m) => m.RekeyTokenModule
          ),
      },

      {
        path: "phone-management",
        loadChildren: () =>
          import(
            "@appContainers/crm/phone-management/phone-management.module"
          ).then((m) => m.PhoneManagementModule),
      },
      {
        path: "product-activation",
        loadChildren: () =>
          import(
            "@appContainers/crm/product-activation/product-activation.module"
          ).then((m) => m.ProductActivationModule),
      },
      {
        path: "call-logs",
        loadChildren: () =>
          import(
            "@appContainers/crm/customer-call-logs/customer-call-logs.module"
          ).then((m) => m.CustomerCallLogModule),
      },
      {
        path: "payment-search",
        loadChildren: () =>
          import(
            "@appContainers/crm/payment-search/payment-search.module"
          ).then((m) => m.PaymentSearchModule),
      },
      {
        path: "reports",
        loadChildren: () =>
          import("@appContainers/crm/reports/crm-reports.module").then(
            (m) => m.CrmReportsModule
          ),
      },
      {
        path: "reprocess-payments",
        loadChildren: () =>
          import(
            "@appContainers/crm/reprocess-payment/reprocess-payment.module"
          ).then((m) => m.ReprocessPaymentModule),
      },
      {
        path: "nps-survey",
        loadChildren: () =>
          import("@appContainers/crm/nps-survey/nps-survey.module").then(
            (m) => m.NPSSurveyModule
          ),
      },
      {
        path: "portable-customers",
        loadChildren: () =>
          import(
            "@appContainers/crm/portable-customers/portable-customer.module"
          ).then((m) => m.PortableCustomerModule),
      },
      {
        path: "payoff-candidates",
        loadChildren: () =>
          import(
            "@appContainers/crm/payoff-candidates/payoff-candidates.module"
          ).then((m) => m.PayOffCandidatesModule),
      },
      {
        path: "cco-leads",
        loadChildren: () =>
          import("@appContainers/crm/cco-leads/cco-leads.module").then(
            (m) => m.CCOLeadsModule
          ),
      },
      {
        path: "crm-upsell-eligibility",
        loadComponent: () => import('@appContainers/crm/crm-upsell-eligibity/crm-upsell-eligibity.component').then(m => m.CrmUpsellEligibityComponent),
      },
    ],
  },
];

@NgModule({
  declarations: [
    // Components / Directives/ Pipes
    CRMComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    FeatureToggleModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    KYCSharedComponentModule,
    RouterModule.forChild(routes),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CrmModule {
  static routes = routes;
}
