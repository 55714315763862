import {Action} from '@ngrx/store';

import {InventoryProduct} from "@appModels/core-inventory/inventory-product/inventory-product";

export enum ConsignmentProductInventoryProductActionTypes {

  consignmentProductGetStockingPointConsignmentProductsNotBooked = '[ConsignmentProductsNotBooked ] get stocking point Consignment Products not Booked',
  consignmentProductGetStockingPointConsignmentProductsNotBookedSuccess = '[ConsignmentProductsNotBooked ] get stocking point Consignment Products Booked not success',
  consignmentProductNotInStockingPoints = '[ConsignmentProductsNotBooked ] get stocking point Consignment Products not in stocking points',
  consignmentProductNotInStockingPointsSuccess = '[ConsignmentProductsNotBooked ] get stocking point Consignment Products not in stocking points success',
  consignmentProductRemoveStockingPointConsignmentProductsNotBooked = '[ConsignmentProductsNotBooked ] remove stocking point Consignment Products not Booked',
  consignmentProductGetStockingPointConsignmentProductsNotBookedError = '[ConsignmentProductsNotBooked ] error'
}


export class ConsignmentProductStockingPointNotBooked implements Action {
  readonly type = ConsignmentProductInventoryProductActionTypes.consignmentProductGetStockingPointConsignmentProductsNotBooked;
  constructor(public payload?) { }
}

export class ConsignmentProductStockingPointNotBookedSuccess implements Action {
  readonly type = ConsignmentProductInventoryProductActionTypes.consignmentProductGetStockingPointConsignmentProductsNotBookedSuccess;

  constructor(public payload: any) {
  }
}


export class ConsignmentProductNotInStockingPoints implements Action {
  readonly type = ConsignmentProductInventoryProductActionTypes.consignmentProductNotInStockingPoints;
}

export class ConsignmentProductNotInStockingPointsSuccess implements Action {
  readonly type = ConsignmentProductInventoryProductActionTypes.consignmentProductNotInStockingPointsSuccess;

  constructor(public payload: InventoryProduct[]) {
  }
}

export class ConsignmentProductRemoveStockingPointConsignmentProductsNotBooked implements Action {
  readonly type = ConsignmentProductInventoryProductActionTypes.consignmentProductRemoveStockingPointConsignmentProductsNotBooked;
  constructor(public payload?) { }
}

export class consignmentProductGetStockingPointConsignmentProductsNotBookedError implements Action {
  readonly type = ConsignmentProductInventoryProductActionTypes.consignmentProductGetStockingPointConsignmentProductsNotBookedError;

  constructor(public payload: any) {
  }
}


export type ConsignmentProductInventoryProductActions =
  | ConsignmentProductStockingPointNotBooked
  | ConsignmentProductStockingPointNotBookedSuccess
  | ConsignmentProductRemoveStockingPointConsignmentProductsNotBooked
  | ConsignmentProductNotInStockingPoints
  | ConsignmentProductNotInStockingPointsSuccess
  | consignmentProductGetStockingPointConsignmentProductsNotBookedError


