import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {pick} from 'lodash';

import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';

import { CoreCrmService } from '../core-crm.service';


@Injectable({ providedIn: 'root' })
export class PortableService extends CoreCrmService {


  constructor(
    private http: HttpClient,

  ) {
    super(http, 'portable');
  }


  getPortableCustomers(query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        if (query[val] !== null && query[val] !== undefined && query[val] !== '') {
          params = params.append(val, query[val]);
        }
      });
    }

    const url = `${this.base_url}Customers/tenant/${localStorage.getItem('tenant')}`;
    return this.http.get<any>(url, {headers: this.headers, params: params}).pipe(
      tap(getPortableCustomers => this.log(`getPortableCustomers`)),
      catchError(this.handleError('getPortableCustomers', []))
    );
  }


  getPortableProducts(customerId: number): Observable<any> {
    const url = `${this.base_url}Products/customerId/${customerId}`;
    return this.http.get<any>(url, {headers: this.headers}).pipe(
      tap(res => this.log(`res`)),
      catchError(this.handleError('getPortableProducts', []))
    );
  }

  getPortableCustomerFields(): Observable<any> {
    const url = `${this.base_url}Customers/tenantCustomFields/tenant/${localStorage.getItem('tenant')}`;
    return this.http.get<any>(url, {headers: this.headers}).pipe(
      tap(res => this.log(`res`)),
      catchError(this.handleError('getPortableCustomerFields', []))
    );
  }

  getSourceOfLights(): Observable<any> {
    const url = `${this.base_url}Customers/sourceOfLights`;
    return this.http.get<any>(url, {headers: this.headers}).pipe(
      tap(res => this.log(`res`)),
      catchError(this.handleError('getSourceOfLights', []))
    );
  }


  verifyOtp(customerId: number, portableActivationCode: number, otp: string): Observable<any> {
    const url = `${this.base_url}Products/customer/${customerId}/portableActivationCode/${portableActivationCode}/verifyOtp`;
    return this.http.post<any>(url, otp, {headers: this.headers, observe: 'response'})
  }

  checkOtpVerification(customerId: number, portableActivationCode: number): Observable<any> {
    const url = `${this.base_url}Products/customer/${customerId}/portableActivationCode/${portableActivationCode}/checkOtpVerification"`;
    return this.http.get<any>(url, {headers: this.headers}).pipe(
      tap(res => this.log(`res`)),
      catchError(this.handleError('checkOtpVerification', []))
    );
  }

  sendOtpVerificationCode(customerId: number, portableActivationCode: number): Observable<any> {
    const url = `${this.base_url}Products/customer/${customerId}/portableActivationCode/${portableActivationCode}/sendOtpVerificationCode`;
    return this.http.post<any>(url, {headers: this.headers}).pipe(
      tap(res => this.log(`res`)),
      catchError(this.handleError('sendOtpVerificationCode', []))
    );
  }

  savePortableCustomer(data): Observable<{}> {
    return this.http.post(`${this.base_url}Customer/full`, data, {headers: this.headers}).pipe(
      tap((res) => this.log(`savePortableCustomer`)),
      catchError(this.handleError('savePortableCustomer'))
    );
  }


}
