import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

import {Addtestflow, AddtestflowSuccess,} from '@appStore/actions/core-aftersales/test-flow/test-flow.actions';
import {TenantService} from '@appServices/core-masterdata/tenant/tenant.service';
import {ToastrService} from 'ngx-toastr';
import {
  Deletetestflow,
  DeletetestflowSuccess,
  Filtertestflow,
  FiltertestflowSuccess,
  GettestflowById,
  GettestflowByIdSuccess,
  TestFlowActionTypes,
  testflowsError,
  UpdatetestflowSuccess
} from "../../../actions/core-aftersales/test-flow/test-flow.actions";
import {TestFlowService} from "../../../../services/core-aftersales/test-flow/test-flow.service";
import { Router } from '@angular/router';

@Injectable()
export class TestFlowEffects {

  
  addTestFlow = createEffect(() => this.actions$.pipe(
    ofType(TestFlowActionTypes.testflowAddtestflow),
    switchMap((action: Addtestflow) =>
      this.testFlowService
        .addTestflow(action.payload)
        .pipe(
          map(
            testFlow => {
              if (testFlow !== undefined) {
                this.toastr.success('Test flow has been successfully added!', 'Successful!');
                this.router.navigateByUrl(`/global-settings/testflow`);
                return new AddtestflowSuccess(testFlow)
              }
              this.toastr.error('There was an error creating sales staff!', "Unknown error");
              return new testflowsError({type: 500, message: 'Internal error'})
            }
          ),
          catchError(error => of(new testflowsError(error)))
        )
    )
  ));


  
  filterTestFlows$ = createEffect(() => this.actions$.pipe(
    ofType(TestFlowActionTypes.testflowFiltertestflow),
    mergeMap((action: Filtertestflow) =>
      this.testFlowService
        .filterTestflow(action.payload)
        .pipe(
          map(customers => new FiltertestflowSuccess(customers)),
          catchError(error => of(new testflowsError(error)))
        )
    )
  ));

  
  getTestFlowById$ = createEffect(() => this.actions$.pipe(
    ofType(TestFlowActionTypes.testflowGettestflowById),
    mergeMap((action: GettestflowById) =>
      this.testFlowService
        .getTestFlowById(action.payload)
        .pipe(
          map(salesStaff => new GettestflowByIdSuccess(salesStaff)),
          catchError(error => of(new testflowsError(error)))
        )
    )
  ));

  
  updateTestFlow$ = createEffect(() => this.actions$.pipe(
    ofType(TestFlowActionTypes.testflowUpdatetestflow),
    mergeMap((action: Addtestflow) =>
      this.testFlowService
        .updateTestflow(action.payload)
        .pipe(
          map(rate => {
            if (rate !== undefined) {
              this.toastr.success('Test Flow has been successfully updated!', 'Successful!');
              return new UpdatetestflowSuccess(action.payload)
            }
            // this.toastr.error('There was an error updating this rate', "Unknown error");
            return new testflowsError({type: 500, message: 'Internal error'})
          }),
          catchError(error => of(new testflowsError(error)))
        )
    )
  ));
  
  deleteTestFlow$ = createEffect(() => this.actions$.pipe(
    ofType(TestFlowActionTypes.testflowDeletetestflow),
    mergeMap((action: Deletetestflow) =>
      this.testFlowService
        .deleteTestflow(action.payload)
        .pipe(
          map(() => new DeletetestflowSuccess(action.payload)),
          catchError(error => of(new testflowsError(error)))
        )
    )
  ));

  constructor(
    private actions$: Actions,
    private testFlowService: TestFlowService,
    private toastr: ToastrService,
    private router: Router
  ) {
  }
}
