import parsePhoneNumberFromString, { getExampleNumber, CountryCode, PhoneNumber } from 'libphonenumber-js/min';
import examples from 'libphonenumber-js/examples.mobile.json'
import * as _ from 'lodash';
export default class Helpers {

    static optional(value) {
        if (value === null || value === undefined) {
            return {};
          }
          return value;
    }

    static getPhoneNumberPatternPrefixCountryCode () {
      let pattern = '000-000-000';
      let prefix = null;
      let countryCode =  ''

      if (localStorage.getItem('countryCodeNumeric')) {
        countryCode = '+' + localStorage.getItem('countryCodeNumeric');
        prefix = `(+${localStorage.getItem('countryCodeNumeric')}) `;
        const code = localStorage.getItem('country');
        const phoneNumber = Helpers.getExamplePhoneNumber(code);
        if (phoneNumber) {
          const chunk = _.chunk(phoneNumber.nationalNumber, 3);
          pattern = '';
          _.sortBy(chunk, (o) => o.length).forEach((element, index) => {
            element.forEach(e => {
              pattern += '0';
            });
            if (index !== (chunk.length - 1)) {
              pattern += '-';
            }
          });

        }
      }
      return {
        pattern,
        prefix,
        countryCode
      }
    }
    static getPhoneValidatorLength() {
      let tenant = +localStorage.getItem('countryCodeNumeric');
      let length = 9; // default

      let lookup = {
        267: 8, // Botswana
        229: 8, // Benin
        675: 8, // PNG
      };

      return lookup[tenant] ?? length;
    }

    static getExamplePhoneNumber(countryCode: string) {
      if (countryCode === 'SO')// override for Somalia because idk Lirette asked for it
        return parsePhoneNumberFromString('252711234567');
      else
        return getExampleNumber(countryCode as CountryCode, examples);
    }

  static addDays(date, days) {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  static datediff(first, second) {
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
  }

  /**
   * Function to format Kenyan phone numbers.
   * If the number starts with '0', it replaces it with '254'.
   *
   * @param phoneNumber The phone number to format.
   * @returns The formatted phone number.
   */
  static formatKenyanPhoneNumber(phoneNumber: string): string {
    if (!phoneNumber) {
      throw new Error('Phone number is required');
    }

    // Remove any whitespace or special characters
    phoneNumber = phoneNumber.replace(/\s+/g, '');

    // Check if the phone number starts with '0'
    if (phoneNumber.startsWith('0')) {
      // Replace the '0' with '254'
      phoneNumber = '254' + phoneNumber.substring(1);
    }

    return phoneNumber;
  }
}
