import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';

import {PermissionResolver} from '@appServices/permission-resolver/permission-resolver.service';
import {Chart} from 'chart.js';
import {Store} from '@ngrx/store';
import * as moment from 'moment'
import * as fromReducer from '@appStore/reducers';
import { GetCustomers } from '@appStore/actions/core-crm/customer/customer.actions';
import { Customer } from '@appModels/core-crm/customer/customer';
import { Observable } from 'rxjs';

import {Page} from '@appModels/shared/page';
import TimeZone from 'app/util/timezone';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import { DashboardSEPService } from '@appServices/core-crm/dashboard-sep/dashboard-sep.service';

@Component({
  selector: 'app-sep-dashboard',
  templateUrl: './sep-dashboard.component.html',
  styleUrls: ['./sep-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class SepDashboardComponent implements OnInit {
  @ViewChild(DatatableComponent) table: DatatableComponent;

  private actualVsPotentialCharts: ElementRef;
  private customerCharts: ElementRef;
  perPage = 10;
  status = 'LEAD';

  customers$: Observable<Customer[]>;
  paging$: Observable<any>;
  paging = new Page();

  loading$: Observable<Boolean>;

  dateFrom = moment().set('date', 1).format('YYYY-MM-DD');
  dateTo =  moment().format('YYYY-MM-DD');

  lastLoaded = TimeZone.getFormatedTimezoneFormated();

  formatDate = TimeZone.getFormatedTimezoneFromDate;
  
  temp = [];
  rows = [];
  statusFilter = null;
  salesCount = [];
  productCount = [];
  productName = [];
  customerPaymentProgress: any;

  constructor(
    private renderer: Renderer2,
    private permissionResolverService: PermissionResolver,
    private ref: ChangeDetectorRef,
    private store: Store<fromReducer.customer.State>,
    private dashboardSEPService: DashboardSEPService
  ) {}

  @ViewChild('actualVsPotentialCharts') set actualVsPotentialContent(actualVsPotentialContent: ElementRef) {
    this.actualVsPotentialCharts = actualVsPotentialContent;
  }

  @ViewChild('customerCharts') set customerContent(customerContent: ElementRef) {
    this.customerCharts = customerContent;
  }

  setPage(pageInfo) {
    this.lastLoaded = TimeZone.getFormatedTimezoneFormated();
    this.store.dispatch(new GetCustomers({page: pageInfo.offset, size: this.perPage, customerStatus:this.status}));
  }

  filterStatus($event) {
    this.statusFilter = $event.target.value;
    let temp = this.temp;
    if (this.statusFilter !== null && this.statusFilter !== '') {
      temp = this.temp.filter((d) => {
        // console.log(d, this.statusFilter == 1);
            return parseInt(this.statusFilter, 10) === d.statusId;
          });
    }
    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;

  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    // filter our data
    const temp = this.temp.filter(function(d) {
      return d.firstName.toLowerCase().indexOf(val) !== -1 ||
             d.lastName.toLowerCase().indexOf(val) !== -1 ||
             d.email1.toLowerCase().indexOf(val) !== -1 ||
             d.telephone1.toLowerCase().indexOf(val) !== -1 ||
             !val;
    });
    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }


  actualVsPotentialChart() {
    const ctx = this.actualVsPotentialCharts?.nativeElement.getContext('2d');
    const actualVsPotentialCharts = new Chart(ctx, {
      type: 'doughnut',
      data: {
        datasets: [{
          data: this.productCount,
          backgroundColor: [ 'rgba(54, 163, 235, 0.8)', 'rgba(76, 191, 192, 0.8)', 'rgba(255, 206, 86, 0.8)', 'rgba(255, 114, 144, 0.8)'],
          borderColor: [ 'rgba(54, 163, 235, 0.8)', 'rgba(76, 191, 192, 0.8)', 'rgba(255, 206, 86, 0.8)', 'rgba(255, 114, 144, 0.8)' ]
        }],

        // These labels appear in the legend and in the tooltips when hovering different arcs
        labels: this.productName
    },
    });
  }

  customerChart() {
    const ctx = this.customerCharts.nativeElement.getContext('2d');
    const customerCharts = new Chart(ctx, {
      type: 'doughnut',
      data: {
        datasets: [
          {
            data: this.customerPaymentProgress,
            backgroundColor: [ 'rgba(18, 150, 24, 0.8)','rgba(104, 181, 109, 0.8)', 'rgba(255, 165, 3, 0.8)' ,'rgba(255, 2, 2, 0.8)' ],
            borderColor: [ 'rgba(18, 150, 24, 0.8)', 'rgba(104, 181, 109, 0.8)','rgba(255, 165, 3, 0.8)','rgba(255, 2, 2, 0.8)']
        },
      ],
        // These labels appear in the legend and in the tooltips when hovering different arcs
        labels: [
            'Units with Credit',
            '0-10 Days Behind',
            '11-30 Days Behind',
            '31+ Days Behind'
        ]
    },
    });
  }

  can(action) {
    return this.permissionResolverService.setModuleName('CRM').can(action);
  }

  loadTemp(temp) {
    this.temp = temp;
    this.rows = temp;
  }

  getSalesCount(){
    this.dashboardSEPService.getSalesCountProduct(this.dateFrom, this.dateTo).subscribe(
      data => {
        this.salesCount = data
        this.productName = data?.map(name => name.productName)
        this.productCount = data?.map(count => count.productCount)
        this.ref.detectChanges()
        if(this.salesCount.length > 0){
          setTimeout(() => {
            this.actualVsPotentialChart()
          }, 200);
        }
      }
    )
  }

  getCustomerPaymentProgress(){
    this.dashboardSEPService.getPaymentProgress().subscribe(
      data => {
        this.customerPaymentProgress = data;
        setTimeout(() => {
          this.customerChart()
        }, 200);
      }
    )
  }

  ngOnInit() {
    this.getSalesCount()
    this.getCustomerPaymentProgress()
  }

}
