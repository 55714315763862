import { Action } from '@ngrx/store';
import {
  EntityAdapter,
  createEntityAdapter,
  EntityState,
  Update
} from '@ngrx/entity';

import { Timezone } from '@appModels/core-setup/timezone/timezone';
import { TimezoneActions, TimezoneActionTypes } from '@appStore/actions/core-masterdata/timezone/timezone.actions';

export interface State extends EntityState<Timezone> {
  loaded: boolean;
  loading: boolean;
  error: any;
  selectedTimezoneId: number;
  searchTimezones: Timezone[];
}

export const adapter: EntityAdapter<Timezone> = createEntityAdapter<Timezone>();

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
  selectedTimezoneId: null,
  error: null,
  searchTerm: '',
  searchTimezones: null
});

export function reducer(state = initialState, action: TimezoneActions): State {
  switch (action.type) {
    case TimezoneActionTypes.timezoneGetTimezones:
    case TimezoneActionTypes.timezoneAddTimezone:
    case TimezoneActionTypes.timezoneDeleteTimezone:
    case TimezoneActionTypes.timezoneUpdateTimezone:
    case TimezoneActionTypes.timezoneSearchTimezones:
    case TimezoneActionTypes.timezoneGetTimezoneById:
      return {
        ...state,
        loading: true
      };

    case TimezoneActionTypes.timezoneGetTimezonesSuccess:
      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });

    case TimezoneActionTypes.timezoneGetTimezoneByIdSuccess:
      return { ...state, selectedTimezoneId: action.payload.id, loading: false };

    case TimezoneActionTypes.timezoneAddTimezoneSuccess:
      return adapter.addOne(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });

    case TimezoneActionTypes.timezoneUpdateTimezoneSuccess: {
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state,
          loading: false,
          loaded: true
        }
      );
    }

    case TimezoneActionTypes.timezoneDeleteTimezoneSuccess: {
      return adapter.removeOne(action.payload.id, {
        ...state,
        loading: false,
        loaded: true
      });
    }

    case TimezoneActionTypes.timezoneSearchTimezonesSuccess:
      return {
        ...state,
        searchTimezones: action.payload,
        loading: false
      };

    case TimezoneActionTypes.timezoneSearchTimezonesReset:
      return {
        ...state,
        searchTimezones: null
      };

    case TimezoneActionTypes.timezoneError:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload
      };

    default:
      return state;
  }
}

export const timezoneEntitySelectors = adapter.getSelectors();
