import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { SepDashboardComponent } from './sep-dashboard.component';
import { SEPTableComponent } from './sep-table/sep-table.component';
import { ModuleRoutes } from './sep.dashboard.routing';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(ModuleRoutes),
    FormsModule,
    TranslateModule,
    NgxDatatableModule,
    ProgressbarModule,
    TooltipModule
  ],
  declarations: [
    SepDashboardComponent,
    SEPTableComponent
  ]
})

export class SepDashboardModule { }
