<div
*ngIf="!crbDetailCheck && formNotValid"
class="notification error"
>
{{ "Please confirm Customer Details" | translate }}
</div>
<h4 class="m-t-20 m-b-10">
<input
  type="checkbox"
  *ngIf="
    statusName !== 'BLACKLISTED'
  "
  class="m-r-10"
  [ngModel]="crbDetailCheck"
  (ngModelChange)="crbDetailCheckChange.emit($event)"
  name="crbDetailCheck"
  
/>
{{ "CRB Details" | translate }}
</h4>

@if (!isUpdating()){
<table style="background: #f7f7fc; border-radius: 3px">
  <thead>
    <tr>
      <th>{{ "PHONE TYPE" | translate }}</th>
      <th>{{ "PHONE NO" | translate }}</th>
      <th>{{ "PHONE EXCHANGE" | translate }}</th>
      <th>{{ "CREDIT HISTORY" | translate }}</th>
      <th>{{ "CREATE DATE" | translate }}</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>
        <b>
          {{ crbCheckData?.phoneList[0]?.phoneType || "--" }}
        </b>
      </td>
      <td>{{ crbCheckData?.phoneList[0]?.phoneNo || "--" }}</td>
      <td>
        <b>{{ crbCheckData?.phoneList[0]?.phoneExchange || "--" }}</b>
      </td>
      <td>
        <b>{{ crbCheckData?.creditHistory?.mySector || "--" }}</b>
      </td>

      <td>
        <b>{{
          convertToDateTime(crbCheckData?.phoneList[0]?.createDate) || "--"
        }}</b>
      </td>
    </tr>
  </tbody>
</table>
<table style="background: #f7f7fc; border-radius: 3px">
    <thead>
      <tr>
        <th>{{ "MIN MOBILOAN PRINCIPAL AMOUNT" | translate }}</th>
        <th>{{ "MAX MOBILOAN PRINCIPAL AMOUNT" | translate }}</th>
        <th>{{ "MOBILOANS SCORE" | translate }}</th>
        <th>{{ "GRADE" | translate }}</th>
        <th>{{ "COUNT OF NPA OPEN ACCOUNTS" | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          <b>
            {{ crbCheckData?.minMobiLoanPrincipalAmount?.mySector || "--" }}
          </b>
        </td>
        <td>{{ crbCheckData?.maxMobiLoanPrincipalAmount?.mySector || "--" }}</td>
        <td>
          <b>{{ crbCheckData?.mobiLoansScore|| "--" }}</b>
        </td>
        <td>
          <b>{{ crbCheckData?.grade|| "--" }}</b>
        </td>
  
        <td>
          <b>{{
            crbCheckData?.npaOpenAccounts?.mySector || "--"
          }}</b>
        </td>
      </tr>
    </tbody>
  </table>

} @else {
<p>Loading..</p>
}
