import {createEntityAdapter, EntityAdapter, EntityState,} from '@ngrx/entity';

import {TicketCreationFields} from "../../../../models/core-setup/ticket-creation-fields/ticket-creation-fields";
import {
  TicketCreationFieldsActions,
  TicketCreationFieldsActionTypes
} from "../../../actions/core-masterdata/ticket-creation-fields/ticket-creation-fields.actions";

export interface State extends EntityState<TicketCreationFields> {
  loaded: boolean;
  loading: boolean;
  error: any;
  selectedTicketCreationFields: any;
  selectedTicketCreationFieldsId: number;
  searchTicketCreationFields: TicketCreationFields[];
  paging: any;
}


export const adapter: EntityAdapter<TicketCreationFields> = createEntityAdapter<TicketCreationFields>();

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
  selectedTicketCreationFieldsId: null,
  selectedTicketCreationFields: null,
  error: null,
  searchTicketCreationFields: null,
  paging: null
});

export function reducer(state = initialState, action: TicketCreationFieldsActions): State {
  switch (action.type) {
    case TicketCreationFieldsActionTypes.ticketcreationfieldsGetticketcreationfieldss:
    case TicketCreationFieldsActionTypes.ticketcreationfieldsAddticketcreationfields:
    case TicketCreationFieldsActionTypes.ticketcreationfieldsDeleteticketcreationfields:
    case TicketCreationFieldsActionTypes.ticketcreationfieldsUpdateticketcreationfields:
    case TicketCreationFieldsActionTypes.ticketcreationfieldsSearchticketcreationfieldss:
    case TicketCreationFieldsActionTypes.ticketcreationfieldsGetticketcreationfieldsById:
    case TicketCreationFieldsActionTypes.ticketcreationfieldsGeticketcreationfieldssByTenantId:
    case TicketCreationFieldsActionTypes.ticketcreationfieldsFilterticketcreationfields:

      return {
        ...state,
        loading: true
      };


    case TicketCreationFieldsActionTypes.ticketcreationfieldsGetticketcreationfieldssSuccess:

      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true,
        //paging: _.omit(action.payload, ['content'])

      });


    case TicketCreationFieldsActionTypes.ticketcreationfieldsFilterticketcreationfieldsSuccess:

      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true,
        //paging: _.omit(action.payload, ['content'])
      });


    case TicketCreationFieldsActionTypes.ticketcreationfieldsGetticketcreationfieldsByIdSuccess:
      console.log(state);
      console.log(action);
      return {
        ...state,
        selectedTicketCreationFieldsId: action.payload.id,
        loading: false,
        selectedTicketCreationFields: action.payload
      };


    case TicketCreationFieldsActionTypes.ticketcreationfieldsAddSticketcreationfieldsSuccess:
      return {...state, loading: false};

    /*return adapter.addOne(action.payload, {
      ...state,
      //selectedTestFlowId: action.payload.id,
      loading: false,
      loaded: true
    });
    */

    case TicketCreationFieldsActionTypes.ticketcreationfieldsGeticketcreationfieldssByTenantIdSuccess:

      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });


    case TicketCreationFieldsActionTypes.ticketcreationfieldsSearchticketcreationfieldssSuccess:

      return {
        ...state,
        searchTicketCreationFields: action.payload,
        loading: false
      };

    case TicketCreationFieldsActionTypes.ticketcreationfieldsSearchticketcreationfieldssReset:

      return {
        ...state,
        searchTicketCreationFields: null
      };

    case TicketCreationFieldsActionTypes.ticketcreationfieldssError:

      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload
      };

    default:
      return state;
  }
}

export const ticketCreationFieldsEntitySelectors = adapter.getSelectors();
