import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {CoreMasterDataService} from '../core-masterdata.service';
import {TenantWorkingHours} from '../../../models/core-setup/tenant-calendar/tenant-working-hours';
import {TenantHoliday} from '@appModels/core-setup/tenant-calendar/tenant-holiday';

@Injectable({
  providedIn: 'root'
})
export class TenantCalendarService extends CoreMasterDataService {

  constructor(private http: HttpClient) {
    super(http, 'tenantcalendar');
  }

  /** GET existing working hours configuration */
  getExistingWorkHoursConfiguration(): Observable<TenantWorkingHours[]> {
    const tenantId = localStorage.getItem('tenant');
    return this.http.get<TenantWorkingHours[]>(this.base_url + '/workinghours/' + tenantId, {headers: this.headers}).pipe(
      tap(types => this.log(`fetched working hours`)),
      catchError(this.handleError('getExistingWorkHoursConfiguration', []))
    );
  }

  /** POST existing working hours configuration */
  saveWorkingHoursConfiguration(workingHours: TenantWorkingHours[]): Observable<TenantWorkingHours[]> {
    const tenantId = localStorage.getItem('tenant');
    return this.http.post<TenantWorkingHours[]>(this.base_url + '/workinghours/' + tenantId, workingHours, {headers: this.headers}).pipe(
      tap(types => this.log(`saved working hours`)),
      catchError(this.handleError('saveWorkingHoursConfiguration', []))
    );
  }

  /** POST holidays for tenant */
  saveTenantHolidays(holidays: TenantHoliday[]): Observable<any> {
    const tenantId = localStorage.getItem('tenant');
    return this.http.post<TenantWorkingHours[]>(this.base_url + '/holidays/' + tenantId, holidays, {headers: this.headers}).pipe(
      tap(types => this.log(`saved tenant holidays`)),
      catchError(this.handleError('saveTenantHolidays', []))
    );
  }

  /** DELETE holiday for tenant */
  deleteTenantHoliday(id: number): Observable<any> {
    return this.http.delete<any>(this.base_url + '/holidays/' + id, {headers: this.headers}).pipe(
      tap(types => this.log(`delete tenant holidays`)),
      catchError(this.handleError('deleteTenantHoliday', []))
    );
  }

  /** GET tenant holidays*/
  getTenantHolidays(data?): Observable<any> {
    const tenantId = localStorage.getItem('tenant');

    let params = new HttpParams();
    if (data !== undefined) {
      Object.keys(data).forEach(val => {
        if (data[val] !== null && data[val] !== undefined && data[val] !== '') {
          params = params.append(val, data[val]);
        }
      });
    }
    return this.http.get<any>(this.base_url + '/holidays/' + tenantId, {params: params, headers: this.headers}).pipe(
      tap(types => this.log(`fetched tenant holidays`)),
      catchError(this.handleError('getTenantHolidays', []))
    );
  }

}

