import {ChangeDetectorRef, Component, TemplateRef, ViewChild} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import {ToastrService} from "ngx-toastr";
import * as moment from "moment/moment";
import {WorkflowService} from "@appServices/core-ticketing/workflow-service";
import {WorkflowListRow} from "@appModels/ticketing/workflow.list.row";

@Component({
  moduleId: module.id,
  templateUrl: './workflow-table-component.html',
  animations: [
    trigger('toggleAnimation', [
      transition(':enter', [style({ opacity: 0, transform: 'scale(0.95)' }), animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' }))]),
      transition(':leave', [animate('75ms', style({ opacity: 0, transform: 'scale(0.95)' }))]),
    ]),
  ],
  styleUrls: ['./workflow-table.css', '../../../tailwind.css']
})
export class WorkflowTableComponent {
  constructor(
    private toastrService: ToastrService,
    private ref: ChangeDetectorRef,
    private workflowService: WorkflowService
  ) {
    this.getWorkflows();
  }

  loadingTable = true;
  dateFormat = moment().format('YYYY-MM-DD hh:mm:ss');
  moment: any = moment;

  getWorkflows() {
    this.workflowService.getListRows(true).subscribe(x=> {
      this.workflows = x;
      this.loadingTable = false;
    })
  }

  workflows : WorkflowListRow[] = [];

}
