import {Action} from '@ngrx/store';

export enum TenantInventoryProductLocationStatusActionTypes {

  inventoryProductGetTenantInventoryProductLocationStatus = '[InventoryProduct] get tenant inventory product location',
  inventoryProductGetTenantInventoryProductLocationStatusSuccess = '[InventoryProduct] get tenant inventory product location success',
  inventoryProductTenantError = '[InventoryTenantProduct] error'
}


export class GetTenantInventoryProductLocationStatus implements Action {
  readonly type = TenantInventoryProductLocationStatusActionTypes.inventoryProductGetTenantInventoryProductLocationStatus;

  constructor(public payload: any) {
  }
}

export class GetTenantInventoryProductLocationStatusSuccess implements Action {
  readonly type = TenantInventoryProductLocationStatusActionTypes.inventoryProductGetTenantInventoryProductLocationStatusSuccess;

  constructor(public payload: any) {
  }
}


export class TenantInventoryProductLocationStatusError implements Action {
  readonly type = TenantInventoryProductLocationStatusActionTypes.inventoryProductTenantError;

  constructor(public payload: any) {
  }
}

export type TenantInventoryProductLocationStatusAction =
  | GetTenantInventoryProductLocationStatus
  | GetTenantInventoryProductLocationStatusSuccess
  | TenantInventoryProductLocationStatusError;
