import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import * as _ from 'lodash';

import { Page } from '@appModels/core-knowledge-base/pages/page';
import { PageActions, PageActionTypes } from '@appStore/actions/core-knowledge/page/page.actions';

export interface State extends EntityState<Page> {
  loaded: boolean;
  loading: boolean;
  error: any;
  selectedPageId: number;
  pageGeneralInfoId: number;
  pageLivingStandardInfoId: number;
  pageUpsellMetricsInfoId: number;
  selectedPage: any;
  searchPages: Page[];
  paging: any;
}

export const adapter: EntityAdapter<Page> = createEntityAdapter<Page>();

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
  selectedPageId: null,
  pageGeneralInfoId: null,
  pageLivingStandardInfoId: null,
  pageUpsellMetricsInfoId: null,
  selectedPage: null,
  error: null,
  searchTerm: '',
  searchPages: null,
  paging: null
});

export function reducer(state = initialState, action: PageActions): State {
  switch (action.type) {
    case PageActionTypes.pageGetPages:
    case PageActionTypes.pageAddPage:
    case PageActionTypes.pageDeletePage:
    case PageActionTypes.pageUpdatePage:
    case PageActionTypes.pageEditPage:
    case PageActionTypes.pageSearchPages:
    case PageActionTypes.pageGetPageById:
      return {
        ...state,
        loading: true
      };

    case PageActionTypes.pageGetPagesSuccess:
      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });

    case PageActionTypes.pageGetPageByIdSuccess:
      return {
        ...state,
        selectedPageId: action.payload.id,
        selectedPage: action.payload,
        loading: false
      };

    case PageActionTypes.pageAddPageSuccess:
      return adapter.addOne(action.payload, {
        ...state,
        selectedPageId: action.payload.id,
        loading: false,
        loaded: true
      });

    case PageActionTypes.pageUpdatePageSuccess: {
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state,
          loading: false,
          loaded: true
        }
      );
    }

    case PageActionTypes.pageEditPageSuccess: {
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state,
          loading: false,
          loaded: true
        }
      );
    }

    case PageActionTypes.pageDeletePageSuccess: {
      return adapter.removeOne(action.payload.id, {
        ...state,
        loading: false,
        loaded: true
      });
    }

    case PageActionTypes.pageSearchPagesSuccess:
      return adapter.setAll(action.payload, {
        ...state,
        searchPages: action.payload,
        loading: false,
        loaded: true
      });

    case PageActionTypes.pageSearchPagesReset:
      return {
        ...state,
        searchPages: null
      };

    case PageActionTypes.pageSelectedPagesReset:
      return {
        ...state,
        selectedPageId: null,
        selectedPage: null
      };

    case PageActionTypes.pageError:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload
      };

    default:
      return state;
  }
}

export const pageEntitySelectors = adapter.getSelectors();
