import {createEntityAdapter, EntityAdapter, EntityState,} from '@ngrx/entity';

import {Commission} from '@appModels/core-fieldforce/commission/commission';
import {
  CommissionActions,
  CommissionActionTypes
} from '@appStore/actions/core-fieldforce/commission/commission.actions';
import * as _ from 'lodash';

export interface State extends EntityState<Commission> {
  loaded: boolean;
  loading: boolean;
  error: any;
  selectedCommissionId: number;
  selectedCommission: Commission;
  searchCommissions: Commission[];
  paging: any;
}

export const adapter: EntityAdapter<Commission> = createEntityAdapter<Commission>();

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
  selectedCommissionId: null,
  error: null,
  searchTerm: '',
  searchCommissions: null,
  selectedCommission: null,
  paging: null
});

export function reducer(state = initialState, action: CommissionActions): State {
  switch (action.type) {
    case CommissionActionTypes.commissionGetCommissions:
    case CommissionActionTypes.commissionAddCommission:
    case CommissionActionTypes.commissionDeleteCommission:
    case CommissionActionTypes.commissionUpdateCommission:
    case CommissionActionTypes.commissionSearchCommissions:
    case CommissionActionTypes.commissionGetCommissionById:
    case CommissionActionTypes.commissionGetCommissionsByTenantId:

      return {
        ...state,
        loading: true
      };

    case CommissionActionTypes.commissionGetCommissionsSuccess:
      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });

    case CommissionActionTypes.commissionGetCommissionByIdSuccess:
      return {
        ...state,
        selectedCommissionId: action.payload.id,
        selectedCommission: action.payload,
        loading: false
      };

    case CommissionActionTypes.commissionAddSCommissionSuccess:
      return adapter.addOne(action.payload, {
        ...state,
        selectedCommissionId: action.payload.id,
        loading: false,
        loaded: true
      });

    case CommissionActionTypes.commissionGetCommissionsByTenantIdSuccess:
      return adapter.setAll(action.payload.content, {
        ...state,
        loading: false,
        loaded: true,
        paging: _.omit(action.payload, ['content'])
      });

    case CommissionActionTypes.commissionUpdateCommissionSuccess: {
      return {
          ...state,
          loading: false,
          loaded: true,
          selectedCommissionId: null,
          selectedCommission: null
        }
      ;
    }

    case CommissionActionTypes.commissionDeleteCommissionSuccess: {
      return adapter.removeOne(action.payload.id, {
        ...state,
        loading: false,
        loaded: true
      });
    }

    case CommissionActionTypes.commissionSearchCommissionsSuccess:
      return {
        ...state,
        searchCommissions: action.payload,
        loading: false
      };

    case CommissionActionTypes.commissionSearchCommissionsReset:
      return {
        ...state,
        searchCommissions: null
      };

    case CommissionActionTypes.commissionsError:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload
      };

    default:
      return state;
  }
}

export const commissionEntitySelectors = adapter.getSelectors();
