import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

import {
  AddTenantProductWarranty,
  AddTenantProductWarrantySuccess,
  DeleteTenantProductWarranty,
  DeleteTenantProductWarrantySuccess,
  EditTenantProductWarrantySuccess,
  GetTenantProductWarranties,
  GetTenantProductWarrantiesSuccess,
  GetTenantProductWarrantyById,
  GetTenantProductWarrantyByIdSuccess,
  SearchTenantProductWarranties,
  SearchTenantProductWarrantiesSuccess,
  TenantProductWarrantyActionTypes,
  TenantProductWarrantyError,
  UpdateTenantProductWarrantySuccess
} from '@appStore/actions/core-masterdata/tenant-product-warranty/tenant-product-warranty';
import {ProductService} from '@appServices/core-masterdata/product/product.service';

import * as fromRouterActions from '@appStore/actions/router.actions';
import {TenantProductWarrantyService} from '../../../../services/core-masterdata/tenant-product-warranty/tenant-product-warranty.service';
import {
  AddTenantProductWarranties,
  AddTenantProductWarrantiesSuccess
} from '../../../actions/core-masterdata/tenant-product-warranty/tenant-product-warranty';
import {ToastrService} from 'ngx-toastr';

@Injectable()
export class TenantProductWarrantyEffects {
  
  loadProductWarranties$ = createEffect(() => this.actions$.pipe(
    ofType(TenantProductWarrantyActionTypes.tenantProductWarrantyGetTenantProductWarranties),
    mergeMap(() =>
      this.tenantPoductWarrantyService
        .getTenantProductWarranties()
        .pipe(
          map(productWarranties => new GetTenantProductWarrantiesSuccess(productWarranties)),
          catchError(error => of(new TenantProductWarrantyError(error)))
        )
    )
  ));
  
  getProductWarrantyById$ = createEffect(() => this.actions$.pipe(
    ofType(TenantProductWarrantyActionTypes.tenantProductWarrantyGetTenantProductWarrantyById),
    mergeMap((action: GetTenantProductWarrantyById) =>
      this.tenantPoductWarrantyService
        .getTenantProductWarranty(action.payload)
        .pipe(
          map(productWarranty => new GetTenantProductWarrantyByIdSuccess(productWarranty)),
          catchError(error => of(new TenantProductWarrantyError(error)))
        )
    )
  ));
  
  addProductWarranty$ = createEffect(() => this.actions$.pipe(
    ofType(TenantProductWarrantyActionTypes.tenantProductWarrantyAddTenantProductWarranty),
    switchMap((action: AddTenantProductWarranty) =>
      this.tenantPoductWarrantyService
        .addTenantProductWarranty(action.payload)
        .pipe(
          map(productWarranty => new AddTenantProductWarrantySuccess(productWarranty)),
          catchError(error => of(new TenantProductWarrantyError(error)))
        )
    )
  ));
  
  addProductWarranties$ = createEffect(() => this.actions$.pipe(
    ofType(TenantProductWarrantyActionTypes.tenantProductWarrantyAddTenantProductWarranties),
    switchMap((action: AddTenantProductWarranties) =>
      this.tenantPoductWarrantyService
        .addTenantProductWarranties(action.payload)
        .pipe(
          map(productWarranty => {
              this.toastr.success('Successfully added product warranties', 'Successful!');
              return new AddTenantProductWarrantiesSuccess(productWarranty)
            }
          ),
          catchError(error => of(new TenantProductWarrantyError(error)))
        )
    )
  ));
  
  updateProductWarranty$ = createEffect(() => this.actions$.pipe(
    ofType(TenantProductWarrantyActionTypes.tenantProductWarrantyUpdateTenantProductWarranty),
    mergeMap((action: AddTenantProductWarranty) =>
      this.tenantPoductWarrantyService
        .updateTenantProductWarranty(action.payload)
        .pipe(
          map(productWarranty => new UpdateTenantProductWarrantySuccess(action.payload)),
          catchError(error => of(new TenantProductWarrantyError(error)))
        )
    )
  ));
  
  editProductWarranty$ = createEffect(() => this.actions$.pipe(
    ofType(TenantProductWarrantyActionTypes.tenantProductWarrantyEditTenantProductWarranty),
    mergeMap((action: AddTenantProductWarranty) =>
      this.tenantPoductWarrantyService
        .editTenantProductWarranty(action.payload)
        .pipe(
          map(product => new GetTenantProductWarranties()),
          catchError(error => of(new TenantProductWarrantyError(error)))
        )
    )
  ));
  
  deleteProductWarranty$ = createEffect(() => this.actions$.pipe(
    ofType(TenantProductWarrantyActionTypes.tenantProductWarrantyDeleteTenantProductWarranty),
    mergeMap((action: DeleteTenantProductWarranty) =>
      this.tenantPoductWarrantyService
        .deleteProductWarranty(action.payload)
        .pipe(
          map(() => new DeleteTenantProductWarrantySuccess(action.payload)),
          catchError(error => of(new TenantProductWarrantyError(error)))
        )
    )
  ));
  
  searchProductWarranties$ = createEffect(() => this.actions$.pipe(
    ofType(TenantProductWarrantyActionTypes.tenantProductWarrantySearchTenantProductWarranties),
    mergeMap((action: SearchTenantProductWarranties) =>
      this.tenantPoductWarrantyService
        .searchTenantProductWarranties(action.payload)
        .pipe(
          map(products => new SearchTenantProductWarrantiesSuccess(products)),
          catchError(error => of(new TenantProductWarrantyError(error)))
        )
    )
  ));
  
  updateProductWarrantySuccess$ = createEffect(() => this.actions$.pipe(
    ofType(TenantProductWarrantyActionTypes.tenantProductWarrantyUpdateTenantProductWarrantySuccess),
    map(product => new fromRouterActions.Go({path: ['/setup/tenant-product-warranty']}))
  ));

  constructor(private actions$: Actions, private toastr: ToastrService, private tenantPoductWarrantyService: TenantProductWarrantyService) {
  }
}
