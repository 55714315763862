import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';

import {Country} from '@appModels/core-setup/country/country';

import {CoreMasterDataService} from '../core-masterdata.service';


@Injectable({providedIn: 'root'})
export class CountryService extends CoreMasterDataService {


  constructor(
    private http: HttpClient,
  ) {
    super(http, 'country');
  }

  /** GET countries from the server */
  getCountries(): Observable<Country[]> {
    return this.http.get<Country[]>(this.base_url , {headers: this.headers}).pipe(
      tap(countries => this.log(`fetched countries`)),
      catchError(this.handleError('getCountries', []))
    );
  }

  /** GET countries from the server */
  getCountriesPaged(query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        if (query[val] !== null && query[val] !== undefined && query[val] !== '') {
          params = params.append(val, query[val]);
        }
      });
    }

    return this.http.get<any>(this.base_url + 'paged', {params: params, headers: this.headers}).pipe(
      tap(countries => this.log(`fetched countries`)),
      catchError(this.handleError('getCountries', []))
    );
  }

  /** GET country by id. Return `undefined` when id not found */
  getCountryNo404<Data>(id: number): Observable<Country> {
    const url = `${this.base_url}/?id=${id}`;
    return this.http.get<Country[]>(url).pipe(
      map(countries => countries[0]), // returns a {0|1} element array
      tap(h => {
        const outcome = h ? `fetched` : `did not find`;
        this.log(`${outcome} country id=${id}`);
      }),
      catchError(this.handleError<Country>(`getCountry id=${id}`))
    );
  }

  /** GET country by id. Will 404 if id not found */
  getCountry(id: number): Observable<Country> {
    const url = `${this.base_url}/${id}`;
    return this.http.get<Country>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched country id=${id}`)),
      catchError(this.handleError<Country>(`getCountry id=${id}`))
    );
  }

  /* GET countries whose name contains search term */
  searchCountries(term: string): Observable<Country[]> {
    if (!term.trim()) {
      // if not search term, return empty country array.
      return of([]);
    }
    return this.http.get<Country[]>(`api/countries/?name=${term}`).pipe(
      tap(_ => this.log(`found countries matching "${term}"`)),
      catchError(this.handleError<Country[]>('searchCountries', []))
    );
  }

  //////// Save methods //////////

  /** POST: add a new country to the server */
  addCountry(country: Country): Observable<Country> {
    return this.http.post<Country>(this.base_url , country, {headers: this.headers}).pipe(
      tap((country: Country) => this.log(`added country w/ id=${country.id}`)),
      catchError(this.handleError<Country>('addCountry'))
    );
  }

  /** DELETE: delete the country from the server */
  deleteCountry(country: Country | number): Observable<Country> {
    const id = typeof country === 'number' ? country : country.id;
    const url = `${this.base_url}/${id}`;

    return this.http.delete<Country>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`deleted country id=${id}`)),
      catchError(this.handleError<Country>('deleteCountry'))
    );
  }

  /** PUT: update the country on the server */
  updateCountry(country: Country): Observable<any> {
    return this.http.put(this.base_url, country, {headers: this.headers}).pipe(
      tap(_ => this.log(`updated country id=${country.id}`)),
      catchError(this.handleError<any>('updateCountry'))
    );
  }

}
