import { Action } from '@ngrx/store';

import { PaymentCategorization } from '@appModels/core-accounting/payment-categorization/payment-categorization';

export enum PaymentCategorizationActionTypes {
  paymentCategorizationGetPaymentCategorizations = '[PaymentCategorization] get',
  paymentCategorizationGetPaymentCategorizationsSuccess = '[PaymentCategorization] get paymentCategorizations success',
  paymentCategorizationAddPaymentCategorization = '[PaymentCategorization] add PaymentCategorization',
  paymentCategorizationAddPaymentCategorizationSuccess = '[PaymentCategorization] add paymentCategorization success',
  paymentCategorizationAddFinancedRate = '[PaymentCategorization] add FinancedRateDefinition',
  paymentCategorizationAddAdditionalPaymentCategorizationInfo = '[PaymentCategorization] add additional PaymentCategorization Info',
  paymentCategorizationAddAdditionalPaymentCategorizationInfoSuccess = '[PaymentCategorization] add additional paymentCategorization Info success',
  paymentCategorizationDeletePaymentCategorization = '[PaymentCategorization] delete paymentCategorization',
  paymentCategorizationDeletePaymentCategorizationSuccess = '[PaymentCategorization] delete paymentCategorization success',
  paymentCategorizationGetPaymentCategorizationById = '[PaymentCategorization] get paymentCategorization by id',
  paymentCategorizationGetPaymentCategorizationByIdSuccess = '[PaymentCategorization] get paymentCategorization by id success',
  paymentCategorizationUpdatePaymentCategorization = '[PaymentCategorization] update paymentCategorization',
  paymentCategorizationUpdatePaymentCategorizationSuccess = '[PaymentCategorization] update paymentCategorization success',
  paymentCategorizationEditPaymentCategorization = '[PaymentCategorization] edit paymentCategorization',
  paymentCategorizationEditPaymentCategorizationSuccess = '[PaymentCategorization] edit paymentCategorization success',
  paymentCategorizationSearchPaymentCategorizations = '[PaymentCategorization] search paymentCategorizations',
  paymentCategorizationSearchPaymentCategorizationsSuccess = '[PaymentCategorization] search paymentCategorizations success',
  paymentCategorizationSearchPaymentCategorizationsReset = '[PaymentCategorization] search paymentCategorizations reset',
  paymentCategorizationError = '[PaymentCategorization] error'
}

export class GetPaymentCategorizations implements Action {
  readonly type = PaymentCategorizationActionTypes.paymentCategorizationGetPaymentCategorizations;
}

export class GetPaymentCategorizationsSuccess implements Action {
  readonly type = PaymentCategorizationActionTypes.paymentCategorizationGetPaymentCategorizationsSuccess;
  constructor(public payload: PaymentCategorization[]) {}
}

export class AddPaymentCategorization implements Action {
  readonly type = PaymentCategorizationActionTypes.paymentCategorizationAddPaymentCategorization;
  constructor(public payload: PaymentCategorization) {}
}

export class AddPaymentCategorizationSuccess implements Action {
  readonly type = PaymentCategorizationActionTypes.paymentCategorizationAddPaymentCategorizationSuccess;
  constructor(public payload: PaymentCategorization) {}
}

export class AddFinancedRate implements Action {
  readonly type = PaymentCategorizationActionTypes.paymentCategorizationAddFinancedRate;
  constructor(public payload) {}
}

export class AddAdditionalPaymentCategorizationInfo implements Action {
  readonly type = PaymentCategorizationActionTypes.paymentCategorizationAddAdditionalPaymentCategorizationInfo;
  constructor(public payload) {}
}

export class AddAdditionalPaymentCategorizationInfoSuccess implements Action {
  readonly type = PaymentCategorizationActionTypes.paymentCategorizationAddAdditionalPaymentCategorizationInfoSuccess;
  constructor(public payload) {}
}

export class GetPaymentCategorizationById implements Action {
  readonly type = PaymentCategorizationActionTypes.paymentCategorizationGetPaymentCategorizationById;
  constructor(public payload: number) {}
}

export class GetPaymentCategorizationByIdSuccess implements Action {
  readonly type = PaymentCategorizationActionTypes.paymentCategorizationGetPaymentCategorizationByIdSuccess;
  constructor(public payload: PaymentCategorization) {}
}

export class UpdatePaymentCategorization implements Action {
  readonly type = PaymentCategorizationActionTypes.paymentCategorizationUpdatePaymentCategorization;
  constructor(public payload: PaymentCategorization) {}
}

export class UpdatePaymentCategorizationSuccess implements Action {
  readonly type = PaymentCategorizationActionTypes.paymentCategorizationUpdatePaymentCategorizationSuccess;
  constructor(public payload: PaymentCategorization) {}
}

export class EditPaymentCategorization implements Action {
  readonly type = PaymentCategorizationActionTypes.paymentCategorizationEditPaymentCategorization;
  constructor(public payload) {}
}

export class EditPaymentCategorizationSuccess implements Action {
  readonly type = PaymentCategorizationActionTypes.paymentCategorizationEditPaymentCategorizationSuccess;
  constructor(public payload) {}
}

export class DeletePaymentCategorization implements Action {
  readonly type = PaymentCategorizationActionTypes.paymentCategorizationDeletePaymentCategorization;
  constructor(public payload: PaymentCategorization) {}
}

export class DeletePaymentCategorizationSuccess implements Action {
  readonly type = PaymentCategorizationActionTypes.paymentCategorizationDeletePaymentCategorizationSuccess;
  constructor(public payload: PaymentCategorization) {}
}

export class SearchPaymentCategorizations implements Action {
  readonly type = PaymentCategorizationActionTypes.paymentCategorizationSearchPaymentCategorizations;
  constructor(public payload: string) {}
}

export class SearchPaymentCategorizationsSuccess implements Action {
  readonly type = PaymentCategorizationActionTypes.paymentCategorizationSearchPaymentCategorizationsSuccess;
  constructor(public payload: PaymentCategorization[]) {}
}

export class SearchPaymentCategorizationsReset implements Action {
  readonly type = PaymentCategorizationActionTypes.paymentCategorizationSearchPaymentCategorizationsReset;
}

export class PaymentCategorizationError implements Action {
  readonly type = PaymentCategorizationActionTypes.paymentCategorizationError;
  constructor(public payload: any) {}
}

export type PaymentCategorizationActions =
  | GetPaymentCategorizations
  | GetPaymentCategorizationsSuccess
  | AddPaymentCategorization
  | AddPaymentCategorizationSuccess
  | AddFinancedRate
  | AddAdditionalPaymentCategorizationInfo
  | AddAdditionalPaymentCategorizationInfoSuccess
  | GetPaymentCategorizationById
  | GetPaymentCategorizationByIdSuccess
  | UpdatePaymentCategorization
  | UpdatePaymentCategorizationSuccess
  | EditPaymentCategorization
  | EditPaymentCategorizationSuccess
  | DeletePaymentCategorization
  | DeletePaymentCategorizationSuccess
  | SearchPaymentCategorizations
  | SearchPaymentCategorizationsSuccess
  | SearchPaymentCategorizationsReset
  | PaymentCategorizationError;
