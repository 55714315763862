import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';

import {Language} from '@appModels/core-setup/language/language';
import {LanguageActions, LanguageActionTypes} from '@appStore/actions/core-masterdata/language/language.actions';

export interface State extends EntityState<Language> {
  loaded: boolean;
  loading: boolean;
  error: any;
  selectedLanguageId: number;
  searchLanguages: Language[];
}

export const adapter: EntityAdapter<Language> = createEntityAdapter<Language>();

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
  selectedLanguageId: null,
  error: null,
  searchTerm: '',
  searchLanguages: null
});

export function reducer(state = initialState, action: LanguageActions): State {
  switch (action.type) {
    case LanguageActionTypes.languageGetLanguages:
    case LanguageActionTypes.languageAddLanguage:
    case LanguageActionTypes.languageDeleteLanguage:
    case LanguageActionTypes.languageUpdateLanguage:
    case LanguageActionTypes.languageSearchLanguages:
    case LanguageActionTypes.languageGetLanguageById:
      return {
        ...state,
        loading: true
      };

    case LanguageActionTypes.languageGetLanguagesSuccess:
      console.log(action, 'payload');
      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });

    case LanguageActionTypes.languageGetLanguageByIdSuccess:
      return { ...state, selectedLanguageId: action.payload.id, loading: false };

    case LanguageActionTypes.languageAddLanguageSuccess:
      return adapter.addOne(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });

    case LanguageActionTypes.languageUpdateLanguageSuccess: {
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state,
          loading: false,
          loaded: true
        }
      );
    }

    case LanguageActionTypes.languageDeleteLanguageSuccess: {
      return adapter.removeOne(action.payload.id, {
        ...state,
        loading: false,
        loaded: true
      });
    }

    case LanguageActionTypes.languageSearchLanguagesSuccess:
      return {
        ...state,
        searchLanguages: action.payload,
        loading: false
      };

    case LanguageActionTypes.languageSearchLanguagesReset:
      return {
        ...state,
        searchLanguages: null
      };

    case LanguageActionTypes.languageError:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload
      };

    default:
      return state;
  }
}

export const languageEntitySelectors = adapter.getSelectors();
