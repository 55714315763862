import { Action } from '@ngrx/store';
import {
  EntityAdapter,
  createEntityAdapter,
  EntityState,
  Update
} from '@ngrx/entity';

import { PaymentCategorization } from '@appModels/core-accounting/payment-categorization/payment-categorization';
import { PaymentCategorizationActions, PaymentCategorizationActionTypes } from '@appStore/actions/core-accounting/payment-categorization/payment-categorization.actions';

export interface State extends EntityState<PaymentCategorization> {
  loaded: boolean;
  loading: boolean;
  error: any;
  selectedPaymentCategorizationId: number;
  searchPaymentCategorizations: PaymentCategorization[];
}

export const adapter: EntityAdapter<PaymentCategorization> = createEntityAdapter<PaymentCategorization>();

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
  selectedPaymentCategorizationId: null,
  error: null,
  searchTerm: '',
  searchPaymentCategorizations: null
});

export function reducer(state = initialState, action: PaymentCategorizationActions): State {
  switch (action.type) {
    case PaymentCategorizationActionTypes.paymentCategorizationGetPaymentCategorizations:
    case PaymentCategorizationActionTypes.paymentCategorizationAddPaymentCategorization:
    case PaymentCategorizationActionTypes.paymentCategorizationAddFinancedRate:
    case PaymentCategorizationActionTypes.paymentCategorizationAddAdditionalPaymentCategorizationInfo:
    case PaymentCategorizationActionTypes.paymentCategorizationEditPaymentCategorization:
    case PaymentCategorizationActionTypes.paymentCategorizationDeletePaymentCategorization:
    case PaymentCategorizationActionTypes.paymentCategorizationUpdatePaymentCategorization:
    case PaymentCategorizationActionTypes.paymentCategorizationSearchPaymentCategorizations:
    case PaymentCategorizationActionTypes.paymentCategorizationGetPaymentCategorizationById:
      return {
        ...state,
        loading: true
      };

    case PaymentCategorizationActionTypes.paymentCategorizationGetPaymentCategorizationsSuccess:
      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });

    case PaymentCategorizationActionTypes.paymentCategorizationGetPaymentCategorizationByIdSuccess:
      return { ...state, selectedPaymentCategorizationId: action.payload.id, loading: false };

    case PaymentCategorizationActionTypes.paymentCategorizationAddPaymentCategorizationSuccess:
      return adapter.addOne(action.payload, {
        ...state,
        selectedPaymentCategorizationId: action.payload.id,
        loading: false,
        loaded: true
      });

    case PaymentCategorizationActionTypes.paymentCategorizationUpdatePaymentCategorizationSuccess: {
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state,
          loading: false,
          loaded: true
        }
      );
    }

    case PaymentCategorizationActionTypes.paymentCategorizationEditPaymentCategorizationSuccess: {
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state,
          loading: false,
          loaded: true
        }
      );
    }

    case PaymentCategorizationActionTypes.paymentCategorizationDeletePaymentCategorizationSuccess: {
      return adapter.removeOne(action.payload.id, {
        ...state,
        loading: false,
        loaded: true
      });
    }

    case PaymentCategorizationActionTypes.paymentCategorizationSearchPaymentCategorizationsSuccess:
      return {
        ...state,
        searchPaymentCategorizations: action.payload,
        loading: false
      };

    case PaymentCategorizationActionTypes.paymentCategorizationSearchPaymentCategorizationsReset:
      return {
        ...state,
        searchPaymentCategorizations: null
      };

    case PaymentCategorizationActionTypes.paymentCategorizationError:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload
      };

    default:
      return state;
  }
}

export const paymentCategorizationEntitySelectors = adapter.getSelectors();
