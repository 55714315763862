import {Action} from '@ngrx/store';

export enum WorkOrderActionTypes {
  workOrderAdd = '[WorkOrder] Add',
  workOrderAddSuccess = '[Work Order] Add Success',
  workOrderError = '[Work Order] Error',
  workOrderGetById = '[Work Order] GetById',
  workOrderGetByIdSuccess = '[Work Order] GetByIdSuccess',
  workOrderAssignTechnician = '[Work Order] Assign To Technician',
  workOrderAssignTechnicianSuccess = '[Work Order] Assign To Technician Success',
  workOrderAssignAction = '[Work Order] Assign Action',
  workOrderAssignActionSuccess = '[Work Order] Assign Action Success',
  workOrderClose = '[Work Order] Close',
  workOrderCloseSuccess = '[Work Order] Close Success',
  workOrderCancel = '[Work Order] Cancel',
  workOrderCancelSuccess = '[Work Order] Cancel Success',
  workOrderEscalate = '[Work Order] Escalate',
  workOrderEscalateSuccess = '[Work Order] Escalate Success',
  workOrderReplace = '[Work Order] Replace',
  workOrderReplaceSuccess = '[Work Order] Replace Success',
  workOrderReceive = '[Work Order] Receive',
  workOrderReceiveSuccess = '[Work Order] Receive Success',
  workOrderGetAll = '[Work Order] Get All',
  workOrderGetAllSuccess = '[Work Order] Get All Success',
  workOrderChangeState = '[Work Order] Change State',
  workOrderChangeStateSuccess = '[Work Order] Change State Success',
  workOrderGenerateQuoatation = '[Work Order] Generate Quoatation',
  workOrderGenerateQuoatationSuccess = '[Work Order] Generate Quoatation Success',
  workOrderInvalidateQuoatation = '[Work Order] Invalidate Quoatation',
  workOrderInvalidateQuoatationSuccess = '[Work Order] Invalidate Quoatation Success',
  workOrderRepair = '[Work Order] Repair',
  workOrderRepairSuccess = '[Work Order] Repair Success',
  workOrderAddNote = '[Work Order] Add Note',
  workOrderAddNoteSuccess = '[Work Order] Add Note Success',
  workOrderSaveTestCases = '[Work Order] Save Test Cases',
  workOrderSaveTestCasesSuccess = '[Work Order] Save Test Cases Success',
  workOrderAddAmountToContract = '[Work Order] Add Amount To Contract',
  workOrderAddAmountToContractSuccess = '[Work Order] Add Amount To Contract Success',
  workOrderAddTokens = '[Work Order] Add Tokens',
  workOrderAddTokensSuccess = '[Work Order] Add Tokens Success',
  workOrderAddOrRemoveWORepair = '[Work Order] Add WO Repair',
  workOrderAddOrRemoveWORepairSuccess = '[Work Order] Add WO Repair Success',
}

export class AddWorkOrder implements Action {
  readonly type = WorkOrderActionTypes.workOrderAdd;

  constructor(public payload) {
  }
}

export class AddWorkOrderSuccess implements Action {
  readonly type = WorkOrderActionTypes.workOrderAddSuccess;

  constructor(public payload: any) {
  }
}

export class WorkOrderError implements Action {
  readonly type = WorkOrderActionTypes.workOrderError;

  constructor(public payload: any) {
  }
}

export class WorkOrderGetById implements Action {
  readonly type = WorkOrderActionTypes.workOrderGetById;

  constructor(public payload: any) {
  }
}

export class WorkOrderGetByIdSuccess implements Action {
  readonly type = WorkOrderActionTypes.workOrderGetByIdSuccess;

  constructor(public payload: any) {
  }
}

export class WorkOrderAssignTechnician implements Action {
  readonly type = WorkOrderActionTypes.workOrderAssignTechnician;

  constructor(public payload: any) {

  }
}

export class WorkOrderAssignTechnicianSuccess implements Action {
  readonly type = WorkOrderActionTypes.workOrderAssignTechnicianSuccess;

  constructor(public payload: any) {

  }
}

export class WorkOrderAssignAction implements Action {
  readonly type = WorkOrderActionTypes.workOrderAssignAction;

  constructor(public payload: any) {

  }
}

export class WorkOrderAssignActionSuccess implements Action {
  readonly type = WorkOrderActionTypes.workOrderAssignActionSuccess;

  constructor(public payload: any) {

  }
}

export class WorkOrderCloseAction implements Action {
  readonly type = WorkOrderActionTypes.workOrderClose;

  constructor(public payload: any) {

  }
}

export class WorkOrderCloseActionSuccess implements Action {
  readonly type = WorkOrderActionTypes.workOrderCloseSuccess;

  constructor(public payload: any) {

  }
}

export class WorkOrderCancelAction implements Action {
  readonly type = WorkOrderActionTypes.workOrderCancel;

  constructor(public payload: any) {

  }
}

export class WorkOrderCancelActionSuccess implements Action {
  readonly type = WorkOrderActionTypes.workOrderCancelSuccess;

  constructor(public payload: any) {

  }
}

export class WorkOrderEscalateAction implements Action {
  readonly type = WorkOrderActionTypes.workOrderEscalate;

  constructor(public payload: any) {

  }
}

export class WorkOrderEscalateSuccess implements Action {
  readonly type = WorkOrderActionTypes.workOrderEscalateSuccess;

  constructor(public payload: any) {

  }
}

export class WorkOrderReplace implements Action {
  readonly type = WorkOrderActionTypes.workOrderReplace;

  constructor(public payload: any) {

  }
}

export class WorkOrderReplaceSuccess implements Action {
  readonly type = WorkOrderActionTypes.workOrderReplaceSuccess;

  constructor(public payload: any) {

  }
}

export class WorkOrderRecieve implements Action {
  readonly type = WorkOrderActionTypes.workOrderReceive;

  constructor(public payload: any) {

  }
}

export class WorkOrderReceiveSuccess implements Action {
  readonly type = WorkOrderActionTypes.workOrderReceiveSuccess;

  constructor() {

  }
}

export class WorkOrderGetAll implements Action {
  readonly type = WorkOrderActionTypes.workOrderGetAll;

  constructor(public payload: any) {

  }
}

export class WorkOrderGetAllSuccess implements Action {
  readonly type = WorkOrderActionTypes.workOrderGetAllSuccess;

  constructor(public payload: any) {

  }
}

export class WorkOrderChangeState implements Action {
  readonly type = WorkOrderActionTypes.workOrderChangeState;

  constructor(public payload: any) {

  }
}

export class WorkOrderChangeStateSuccess implements Action {
  readonly type = WorkOrderActionTypes.workOrderChangeStateSuccess;

  constructor(public payload: any) {

  }
}

export class WorkOrderGenerateQuoatation implements Action {
  readonly type = WorkOrderActionTypes.workOrderGenerateQuoatation;

  constructor(public payload: any) {

  }
}

export class WorkOrderGenerateQuoatationSuccess implements Action {
  readonly type = WorkOrderActionTypes.workOrderGenerateQuoatationSuccess;

  constructor(public payload: any) {

  }
}

export class WorkOrderInvalidateQuoatation implements Action {
  readonly type = WorkOrderActionTypes.workOrderInvalidateQuoatation;

  constructor(public payload: any) {

  }
}

export class WorkOrderInvalidateQuoatationSuccess implements Action {
  readonly type = WorkOrderActionTypes.workOrderInvalidateQuoatationSuccess;

  constructor(public payload: any) {

  }
}

export class WorkOrderRepairAction implements Action {
  readonly type = WorkOrderActionTypes.workOrderRepair;

  constructor(public payload: any) {

  }
}

export class WorkOrderRepairSuccess implements Action {
  readonly type = WorkOrderActionTypes.workOrderRepairSuccess;

  constructor(public payload: any) {

  }
}

export class WorkOrderAddNote implements Action {
  readonly type = WorkOrderActionTypes.workOrderAddNote;

  constructor(public payload: any) {

  }
}

export class WorkOrderAddNoteSuccess implements Action {
  readonly type = WorkOrderActionTypes.workOrderAddNoteSuccess;

  constructor(public payload: any) {

  }
}

export class WorkOrderSaveTestCases implements Action {
  readonly type = WorkOrderActionTypes.workOrderSaveTestCases;

  constructor(public payload: any) {

  }
}

export class WorkOrderSaveTestCasesSuccess implements Action {
  readonly type = WorkOrderActionTypes.workOrderSaveTestCasesSuccess;

  constructor(public payload: any) {

  }
}

export class WorkOrderAddAmountToContract implements Action {
  readonly type = WorkOrderActionTypes.workOrderAddAmountToContract;

  constructor(public payload: any) {

  }
}

export class WorkOrderAddAmountToContractSuccess implements Action {
  readonly type = WorkOrderActionTypes.workOrderAddAmountToContractSuccess;

  constructor(public payload: any) {

  }
}

export class WorkOrderAddTokens implements Action {
  readonly type = WorkOrderActionTypes.workOrderAddTokens;

  constructor(public payload: any) {

  }
}

export class WorkOrderAddTokensSuccess implements Action {
  readonly type = WorkOrderActionTypes.workOrderAddTokensSuccess;

  constructor(public payload: any) {

  }
}

export class WorkOrderAddOrRemoveWORepair implements Action {
  readonly type = WorkOrderActionTypes.workOrderAddOrRemoveWORepair;

  constructor(public payload: any) {

  }
}

export class WorkOrderAddOrRemoveWORepairSuccess implements Action {
  readonly type = WorkOrderActionTypes.workOrderAddOrRemoveWORepairSuccess;

  constructor(public payload: any) {

  }
}


export type WorkOrderActions =
  | AddWorkOrder
  | AddWorkOrderSuccess
  | WorkOrderError
  | WorkOrderGetById
  | WorkOrderGetByIdSuccess
  | WorkOrderAssignTechnician
  | WorkOrderAssignTechnicianSuccess
  | WorkOrderAssignAction
  | WorkOrderAssignActionSuccess
  | WorkOrderCloseAction
  | WorkOrderCloseActionSuccess
  | WorkOrderCancelAction
  | WorkOrderCancelActionSuccess
  | WorkOrderEscalateAction
  | WorkOrderEscalateSuccess
  | WorkOrderReplace
  | WorkOrderReplaceSuccess
  | WorkOrderRecieve
  | WorkOrderReceiveSuccess
  | WorkOrderGetAll
  | WorkOrderGetAllSuccess
  | WorkOrderChangeState
  | WorkOrderChangeStateSuccess
  | WorkOrderGenerateQuoatation
  | WorkOrderGenerateQuoatationSuccess
  | WorkOrderInvalidateQuoatation
  | WorkOrderInvalidateQuoatationSuccess
  | WorkOrderRepairAction
  | WorkOrderRepairSuccess
  | WorkOrderAddNote
  | WorkOrderAddNoteSuccess
  | WorkOrderSaveTestCases
  | WorkOrderSaveTestCasesSuccess
  | WorkOrderAddTokens
  | WorkOrderAddTokensSuccess
  | WorkOrderAddAmountToContract
  | WorkOrderAddAmountToContractSuccess
  | WorkOrderAddOrRemoveWORepair
  | WorkOrderAddOrRemoveWORepairSuccess;



