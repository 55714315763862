import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getRoleStore = createFeatureSelector('role');

export const getRoleEntities = createSelector(
  getRoleStore,
  fromReducers.role.roleEntitySelectors.selectAll
);

export const getRoles = createSelector(getRoleEntities, entities => {
  return Object.values(entities);
});

export const getTopRoles = createSelector(getRoleEntities, entities => {
  return Object.values(entities).slice(1, 5);
});

export const getRolesLoaded = createSelector(
  getRoleStore,
  (roleStore: fromReducers.role.State) => roleStore.loaded
);

export const getRolesLoading = createSelector(
  getRoleStore,
  (roleStore: fromReducers.role.State) => roleStore.loading
);

export const getSelectedRoleId = createSelector(
  getRoleStore,
  (roleStore: fromReducers.role.State) => roleStore.selectedRoleId
);

export const getSelectedRole = createSelector(
  getRoleStore,
  (roleStore: fromReducers.role.State) => roleStore.selectedRole
);


export const getSearchRoles = createSelector(
  getRoleStore,
  (roleStore: fromReducers.role.State) => roleStore.searchRoles
);

export const getRoleById = createSelector(
  getRoleEntities,
  getSelectedRoleId,
  (entities, id) => entities.find(i => i.id === id)
);

export const getRolesError = createSelector(
  getRoleStore,
  (roleStore: fromReducers.role.State) => roleStore.error
);
