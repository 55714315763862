import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";

import { catchError, map, mergeMap, switchMap } from "rxjs/operators";
import { of } from "rxjs";

import {
  AddRole,
  AddRoleSuccess,
  DeleteRole,
  DeleteRoleSuccess,
  GetRoleById,
  GetRoleByIdSuccess,
  GetRolesSuccess,
  RoleActionTypes,
  RoleError,
  SearchRoles,
  SearchRolesSuccess,
  UpdateRoleSuccess,
} from "@appStore/actions/core-identity/role/role.actions";
import { RoleService } from "@appServices/core-auth/role/role.service";

import * as fromRouterActions from "@appStore/actions/router.actions";
import { ToastrService } from "ngx-toastr";
import { PermissionChannelGroupService } from "../../../../services/core-auth/permission-channel-group/permission-channel-group.service";
import {
  AssignPermissionsFromChannelGroupToRoleError,
  AssignPermissionsFromChannelGroupToRoleSuccess,
} from "../../../actions/core-identity/permission-channel-group/permission-channel-group.actions";

@Injectable()
export class RoleEffects {
  constructor(
    private actions$: Actions,
    private roleService: RoleService,
    private permissionChannelGroupService: PermissionChannelGroupService,
    private toastr: ToastrService
  ) {}

  addRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoleActionTypes.roleAddRole),
      switchMap((action: AddRole) =>
        this.roleService.addRole(action.payload).pipe(
          map((role) => {
            if (role !== undefined) {
              // action.payload.id = role.id;
              this.toastr.success(
                "Role has been successfully added!",
                "Successful!"
              );

              return new AddRoleSuccess({
                permissionChannelGroupsIds:
                  action.payload.permissionChannelGroupsIds,
                id: role.id,
              });
            }
            this.toastr.error(
              "There was an error adding this role",
              "Unknown error"
            );
            return new RoleError({ type: 500, message: "Internal error" });
          }),
          catchError((error) => {
            this.toastr.error(error.message, error.error);
            return of(new RoleError(error));
          })
        )
      )
    )
  );

  loadRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoleActionTypes.roleGetRoles),
      mergeMap(() =>
        this.roleService.getRoles().pipe(
          map((roles) => new GetRolesSuccess(roles)),
          catchError((error) => of(new RoleError(error)))
        )
      )
    )
  );

  getRoleById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoleActionTypes.roleGetRoleById),
      mergeMap((action: GetRoleById) =>
        this.roleService.getRole(action.payload).pipe(
          map((role) => new GetRoleByIdSuccess(role)),
          catchError((error) => of(new RoleError(error)))
        )
      )
    )
  );

  addRoleSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoleActionTypes.roleAddRoleSuccess),
      switchMap((action: AddRoleSuccess) => {
        return this.permissionChannelGroupService
          .assignPermissionsFromChannelGroupToRole(
            action.payload.permissionChannelGroupsIds,
            action.payload.id
          )
          .pipe(
            map((res) => {
              this.toastr.success(
                "Permissions added successfully!",
                "Successful!"
              );
              return new AssignPermissionsFromChannelGroupToRoleSuccess();
            }),
            catchError((error) => {
              this.toastr.error(error.message, error.error);
              return of(
                new AssignPermissionsFromChannelGroupToRoleError(error)
              );
            })
          );
      })
    )
  );

  updateRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoleActionTypes.roleUpdateRole),
      mergeMap((action: AddRole) =>
        this.roleService.updateRole(action.payload).pipe(
          map((role) => {
            if (role !== undefined) {
              this.toastr.success(
                "Role has been successfully updated!",
                "Successful!"
              );
              return new UpdateRoleSuccess(action.payload);
            }
            this.toastr.error(
              "There was an error updating this role",
              "Unknown error"
            );
            return new RoleError({ type: 500, message: "Internal error" });
          }),
          catchError((error) => of(new RoleError(error)))
        )
      )
    )
  );

  deleteRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoleActionTypes.roleDeleteRole),
      mergeMap((action: DeleteRole) =>
        this.roleService.deleteRole(action.payload).pipe(
          map(() => new DeleteRoleSuccess(action.payload)),
          catchError((error) => of(new RoleError(error)))
        )
      )
    )
  );

  searchRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoleActionTypes.roleSearchRoles),
      mergeMap((action: SearchRoles) =>
        this.roleService.searchRoles(action.payload).pipe(
          map((roles) => new SearchRolesSuccess(roles)),
          catchError((error) => of(new RoleError(error)))
        )
      )
    )
  );
}
