import { Action } from '@ngrx/store';

import { TenantType } from '@appModels/core-setup/tenant-type/tenant-type';

export enum TenantTypeActionTypes {
  tenantTypeGetTenantTypes = '[TenantType] get',
  tenantTypeGetTenantTypesSuccess = '[TenantType] get tenantTypes success',
  tenantTypeAddTenantType = '[TenantType] add TenantType',
  tenantTypeAddTenantTypeSuccess = '[TenantType] add tenantType success',
  tenantTypeDeleteTenantType = '[TenantType] delete tenantType',
  tenantTypeDeleteTenantTypeSuccess = '[TenantType] delete tenantType success',
  tenantTypeGetTenantTypeById = '[TenantType] get tenantType by id',
  tenantTypeGetTenantTypeByIdSuccess = '[TenantType] get tenantType by id success',
  tenantTypeUpdateTenantType = '[TenantType] update tenantType',
  tenantTypeUpdateTenantTypeSuccess = '[TenantType] update tenantType success',
  tenantTypeSearchTenantTypes = '[TenantType] search tenantTypes',
  tenantTypeSearchTenantTypesSuccess = '[TenantType] search tenantTypes success',
  tenantTypeSearchTenantTypesReset = '[TenantType] search tenantTypes reset',
  tenantTypeError = '[TenantType] error'
}

export class GetTenantTypes implements Action {
  readonly type = TenantTypeActionTypes.tenantTypeGetTenantTypes;
}

export class GetTenantTypesSuccess implements Action {
  readonly type = TenantTypeActionTypes.tenantTypeGetTenantTypesSuccess;
  constructor(public payload: TenantType[]) {}
}

export class AddTenantType implements Action {
  readonly type = TenantTypeActionTypes.tenantTypeAddTenantType;
  constructor(public payload: TenantType) {}
}

export class AddTenantTypeSuccess implements Action {
  readonly type = TenantTypeActionTypes.tenantTypeAddTenantTypeSuccess;
  constructor(public payload: TenantType) {}
}

export class GetTenantTypeById implements Action {
  readonly type = TenantTypeActionTypes.tenantTypeGetTenantTypeById;
  constructor(public payload: number) {}
}

export class GetTenantTypeByIdSuccess implements Action {
  readonly type = TenantTypeActionTypes.tenantTypeGetTenantTypeByIdSuccess;
  constructor(public payload: TenantType) {}
}

export class UpdateTenantType implements Action {
  readonly type = TenantTypeActionTypes.tenantTypeUpdateTenantType;
  constructor(public payload: TenantType) {}
}

export class UpdateTenantTypeSuccess implements Action {
  readonly type = TenantTypeActionTypes.tenantTypeUpdateTenantTypeSuccess;
  constructor(public payload: TenantType) {}
}

export class DeleteTenantType implements Action {
  readonly type = TenantTypeActionTypes.tenantTypeDeleteTenantType;
  constructor(public payload: TenantType) {}
}

export class DeleteTenantTypeSuccess implements Action {
  readonly type = TenantTypeActionTypes.tenantTypeDeleteTenantTypeSuccess;
  constructor(public payload: TenantType) {}
}

export class SearchTenantTypes implements Action {
  readonly type = TenantTypeActionTypes.tenantTypeSearchTenantTypes;
  constructor(public payload: string) {}
}

export class SearchTenantTypesSuccess implements Action {
  readonly type = TenantTypeActionTypes.tenantTypeSearchTenantTypesSuccess;
  constructor(public payload: TenantType[]) {}
}

export class SearchTenantTypesReset implements Action {
  readonly type = TenantTypeActionTypes.tenantTypeSearchTenantTypesReset;
}

export class TenantTypeError implements Action {
  readonly type = TenantTypeActionTypes.tenantTypeError;
  constructor(public payload: any) {}
}

export type TenantTypeActions =
  | GetTenantTypes
  | GetTenantTypesSuccess
  | AddTenantType
  | AddTenantTypeSuccess
  | GetTenantTypeById
  | GetTenantTypeByIdSuccess
  | UpdateTenantType
  | UpdateTenantTypeSuccess
  | DeleteTenantType
  | DeleteTenantTypeSuccess
  | SearchTenantTypes
  | SearchTenantTypesSuccess
  | SearchTenantTypesReset
  | TenantTypeError;
