import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from "rxjs";
import {catchError, tap} from 'rxjs/operators';
import {CoreMasterDataService} from '../core-masterdata.service';
import {OowRepairCondition} from '../../../models/core-setup/oow-repair-conditions/oow-repair-condition';
import {TenantOowRepairCondition} from '../../../models/core-setup/oow-repair-conditions/tenant-oow-repair-condition';

@Injectable({
  providedIn: 'root'
})
export class OOWRepairConditionService extends CoreMasterDataService {

  constructor(private http: HttpClient) {
    super(http, 'oowrepaircondition');
  }

  /** GET oow repair conditions from the server */
  getAllOOwRepairConditions(): Observable<OowRepairCondition[]> {
    return this.http.get<OowRepairCondition[]>(this.base_url, {headers: this.headers}).pipe(
      tap(types => this.log(`fetched oow repair conditions`)),
      catchError(this.handleError('getAllOOwRepairConditions', []))
    );
  }

  /** GET active oow repair conditions for tenant from the server */
  getActiveTenantOOwRepairConditions(): Observable<TenantOowRepairCondition[]> {
    const tenantId = localStorage.getItem('tenant');
    return this.http.get<TenantOowRepairCondition[]>(this.base_url + '/active/tenant/' + tenantId, {headers: this.headers}).pipe(
      tap(types => this.log(`fetched tenant oow repair conditions`)),
      catchError(this.handleError('getActiveTenantOOwRepairConditions', []))
    );
  }

  /** GET oow repair conditions for tenant from the server */
  getTenantOOwRepairConditions(): Observable<TenantOowRepairCondition[]> {
    const tenantId = localStorage.getItem('tenant');
    return this.http.get<TenantOowRepairCondition[]>(this.base_url + '/tenant/' + tenantId, {headers: this.headers}).pipe(
      tap(types => this.log(`fetched tenant oow repair conditions`)),
      catchError(this.handleError('getTenantOOwRepairConditions', []))
    );
  }

  /** POST applied oow repair conditions for tenant to the server */
  saveTenantOOwRepairConditions(conditions: TenantOowRepairCondition[]): Observable<TenantOowRepairCondition[]> {
    const tenantId = localStorage.getItem('tenant');
    return this.http.post<TenantOowRepairCondition[]>(this.base_url + '/tenant/' + tenantId, conditions, {headers: this.headers}).pipe(
      tap(types => this.log(`fetched tenant oow repair conditions`)),
      catchError(this.handleError('getTenantOOwRepairConditions', []))
    );
  }

  /** PATCH disable condition */
  disableTenantOOwRepairCondition(id: number): Observable<TenantOowRepairCondition> {
    return this.http.patch<TenantOowRepairCondition>(this.base_url + '/disable/' + id, {headers: this.headers}).pipe(
      tap(types => this.log(`disable oow repair conditions`)),
      catchError(this.handleError('disableTenantOOwRepairCondition', null))
    );
  }
}

