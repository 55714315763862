import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {WorkOrderRepair} from '../../../../models/core-aftersales/workorderrepair';
import {
  WorkOrderRepairActions,
  WorkOrderRepairActionTypes
} from '../../../actions/core-aftersales/work-order/workorderepair.action';

export interface State extends EntityState<WorkOrderRepair> {
  error: any;
  loaded: boolean;
  loading: boolean;
}

export const adapter: EntityAdapter<WorkOrderRepair> = createEntityAdapter<WorkOrderRepair>();

export const initialState: State = adapter.getInitialState({
  error: null,
  loaded: false,
  loading: false
});

export function reducer(state = initialState, action: WorkOrderRepairActions): State {
  switch (action.type) {
    case WorkOrderRepairActionTypes.workOrderRepairGetAll:
      return {
        ...state,
        loading: true,
        loaded: false
      };

    case WorkOrderRepairActionTypes.workOrderRepairGetAllSuccess:
      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });
    default:
      return state;
  }
}

export const workOrderRepairEntitySelectors = adapter.getSelectors();

