import {ChangeDetectorRef, Component, Input, TemplateRef, ViewChild} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  moduleId: module.id,
  templateUrl: './ticket-create-page.component.html',
  animations: [
    trigger('toggleAnimation', [
      transition(':enter', [style({ opacity: 0, transform: 'scale(0.95)' }), animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' }))]),
      transition(':leave', [animate('75ms', style({ opacity: 0, transform: 'scale(0.95)' }))]),
    ]),
  ],
  styleUrls: ['../../tailwind.css']
})
export class TicketCreatePageComponent {
  constructor(
    private route:ActivatedRoute,
    private router: Router

  ) {

  }



}
