import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getLanguageStore = createFeatureSelector('language');

export const getLanguageEntities = createSelector(
  getLanguageStore,
  fromReducers.language.languageEntitySelectors.selectAll
);

export const getLanguages = createSelector(getLanguageEntities, entities => {
  return Object.values(entities);
});

export const getTopLanguages = createSelector(getLanguageEntities, entities => {
  return Object.values(entities).slice(1, 5);
});

export const getLanguagesLoaded = createSelector(
  getLanguageStore,
  (languageStore: fromReducers.language.State) => languageStore.loaded
);

export const getLanguagesLoading = createSelector(
  getLanguageStore,
  (languageStore: fromReducers.language.State) => languageStore.loading
);

export const getSelectedLanguageId = createSelector(
  getLanguageStore,
  (languageStore: fromReducers.language.State) => languageStore.selectedLanguageId
);

export const getSearchLanguages = createSelector(
  getLanguageStore,
  (languageStore: fromReducers.language.State) => languageStore.searchLanguages
);

export const getLanguageById = createSelector(
  getLanguageEntities,
  getSelectedLanguageId,
  (entities, id) => entities.find(i => i.id === id)
);

export const getLanguagesError = createSelector(
  getLanguageStore,
  (languageStore: fromReducers.language.State) => languageStore.error
);
