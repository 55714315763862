import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getSoftLaunchProjectStore = createFeatureSelector('softLaunchProject');

export const getSoftLaunchProjectEntities = createSelector(
  getSoftLaunchProjectStore,
  fromReducers.softLaunchProject.softLaunchProjectEntitySelectors.selectAll
);

export const getSoftLaunchProjectPaging = createSelector(
  getSoftLaunchProjectStore,
  (softLaunchProject: fromReducers.softLaunchProject.State) => softLaunchProject.paging
);

export const getSoftLaunchProjects = createSelector(getSoftLaunchProjectEntities, entities => {
  return Object.values(entities);
});

export const getSoftLaunchProjectLoading = createSelector(
  getSoftLaunchProjectStore,
  (softLaunchProject: fromReducers.softLaunchProject.State) => softLaunchProject.loading
);

export const getSoftLaunchProjectSelectedId = createSelector(
  getSoftLaunchProjectStore,
  (softLaunchProject: fromReducers.softLaunchProject.State) => softLaunchProject.selectedId
);


export const getSoftLaunchProjectById = createSelector(
  getSoftLaunchProjectEntities,
  getSoftLaunchProjectSelectedId,
  (entities, id) => entities.find(i => i.id === id)
);

