import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';

import {AdminZone} from '@appModels/core-setup/admin-zone/admin-zone';

import {CoreMasterDataService} from '../core-masterdata.service';


@Injectable({ providedIn: 'root' })
export class AdminZoneService extends CoreMasterDataService {


  constructor(
    private http: HttpClient,

  ) {
    super(http, 'countryadminzone');
  }

  /** GET adminZones from the server */
  getAdminZones(): Observable<AdminZone[]> {
    return this.http.get<AdminZone[]>(this.base_url , { headers: this.headers }).pipe(
      tap(adminZones => this.log(`fetched adminZones`)),
      catchError(this.handleError('getAdminZones', []))
    );
  }

  /** GET adminZone by id. Return `undefined` when id not found */
  getAdminZoneNo404<Data>(id: number): Observable<AdminZone> {
    const url = `${this.base_url}/?id=${id}`;
    return this.http.get<AdminZone[]>(url).pipe(
      map(adminZones => adminZones[0]), // returns a {0|1} element array
      tap(h => {
        const outcome = h ? `fetched` : `did not find`;
        this.log(`${outcome} adminZone id=${id}`);
      }),
      catchError(this.handleError<AdminZone>(`getAdminZone id=${id}`))
    );
  }

  /** GET adminZone by id. Will 404 if id not found */
  getAdminZone(id: number): Observable<AdminZone> {
    const url = `${this.base_url}/${id}`;
    return this.http.get<AdminZone>(url,{ headers: this.headers }).pipe(
      tap(_ => this.log(`fetched adminZone id=${id}`)),
      catchError(this.handleError<AdminZone>(`getAdminZone id=${id}`))
    );
  }

  /** GET adminZone by id. Will 404 if id not found */
  getAdminZoneByRegionId(id: number): Observable<any> {
    const url = `${this.base_url}/${id}`;
    return this.http.get<any>(url,{ headers: this.headers }).pipe(
      tap(_ => this.log(`fetched adminZone id=${id}`)),
      catchError(this.handleError<any>(`getAdminZone id=${id}`))
    );
  }

  /** GET adminZone by id. Will 404 if id not found */
  getAdminZoneByCountry(id: number, search?: string): Observable<AdminZone[]> {
    let url = `${this.base_url}/country/${id}`;
    if (search) {
      url += `?search=${search}`;
    }
    return this.http.get<AdminZone[]>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched adminZone id=${id}`)),
      catchError(this.handleError<AdminZone[]>(`getAdminZone id=${id}`))
    );
  }

  /** GET adminZone by id. Will 404 if id not found */
  getAdminZoneRegion(data: {id, parentId, levelId}): Observable<any[]> {
    const url = `${this.base_url}/country/${data.id}/parentCountryAdminZone/${data.parentId}/level/${data.levelId}`;
    return this.http.get<any>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched adminZone id=${data.levelId}`)),
      catchError(this.handleError<[]>(`getAdminZone id=${data.levelId}`))
    );
  }

  /* GET adminZones whose name contains search term */
  searchAdminZones(term: string): Observable<AdminZone[]> {
    if (!term.trim()) {
      // if not search term, return empty adminZone array.
      return of([]);
    }
    return this.http.get<AdminZone[]>(`api/adminZones/?name=${term}`).pipe(
      tap(_ => this.log(`found adminZones matching "${term}"`)),
      catchError(this.handleError<AdminZone[]>('searchAdminZones', []))
    );
  }

  //////// Save methods //////////

  /** POST: add a new adminZone to the server */
  addAdminZone(adminZone: AdminZone): Observable<AdminZone> {
    return this.http.post<AdminZone>(this.base_url , adminZone, { headers: this.headers }).pipe(
      tap((adminZone: AdminZone) => this.log(`added adminZone w/ id=${adminZone.id}`)),
      catchError(this.handleError<AdminZone>('addAdminZone'))
    );
  }

  /** DELETE: delete the adminZone from the server */
  deleteAdminZone(adminZone: AdminZone | number): Observable<AdminZone> {
    const id = typeof adminZone === 'number' ? adminZone : adminZone.id;
    const url = `${this.base_url}/${id}`;

    return this.http.delete<AdminZone>(url, { headers: this.headers }).pipe(
      tap(_ => this.log(`deleted adminZone id=${id}`)),
      catchError(this.handleError<AdminZone>('deleteAdminZone'))
    );
  }

  /** PUT: update the adminZone on the server */
  updateAdminZone(adminZone: AdminZone): Observable<any> {
    return this.http.put(this.base_url, adminZone, { headers: this.headers }).pipe(
      tap(_ => this.log(`updated adminZone id=${adminZone.id}`)),
      catchError(this.handleError<any>('updateAdminZone'))
    );
  }

}
