import {Action} from '@ngrx/store';
import {RepairCentreTechnician} from '../../../../models/core-aftersales/repair-centre-technician';

export enum RepairCentreTechnicianActionTypes {
  repairCentreTechnicianGetAll = '[Repair Centre Technician] Get All',
  repairCentreTechnicianGetAllSuccess = '[Repair Centre Technician] Get All Success',
  repairCentreTechnicianGetPaged = '[Repair Centre Technician] Get Paged',
  repairCentreTechnicianGetPagedSuccess = '[Repair Centre Technician] Get Paged Success',
  repairCentreTechnicianGetByUser = '[Repair Centre Technician] Get By User',
  repairCentreTechnicianGetByUserSuccess = '[Repair Centre Technician] Get By User Success',
  repairCentreTechnicianGetByTenant = '[Repair Centre Technician] Get By Tenant',
  repairCentreTechnicianGetByTenantSuccess = '[Repair Centre Technician] Get By Tenant Success',
  repairCentreTechnicianError = '[Repair Centre Technician] Error',
  repairCentreTechnicianSave = '[Repair Centre Technician] Save',
  repairCentreTechnicianSaveSuccess = '[Repair Centre Technician] Save Success',
  repairCentreTechnicianActivate = '[Repair Centre Technician] Activate',
  repairCentreTechnicianActivateSuccess = '[Repair Centre Technician] Activate Success',
  repairCentreTechnicianDeactivate = '[Repair Centre Technician] Deactivate',
  repairCentreTechnicianDeactivateSuccess = '[Repair Centre Technician] Deactivate Success'
}

export class GetAllRepairCentreTechnician implements Action {
  readonly type = RepairCentreTechnicianActionTypes.repairCentreTechnicianGetAll;

  constructor(public payload: number) {
  }
}

export class GetAllRepairCentreTechnicianSuccess implements Action {
  readonly type = RepairCentreTechnicianActionTypes.repairCentreTechnicianGetAllSuccess;

  constructor(public payload: RepairCentreTechnician[]) {
  }
}

export class GetRepairCentreTechniciansByUser implements Action {
  readonly type = RepairCentreTechnicianActionTypes.repairCentreTechnicianGetByUser;

  constructor(public payload: number) {
  }
}

export class GetRepairCentreTechnicianByUserSuccess implements Action {
  readonly type = RepairCentreTechnicianActionTypes.repairCentreTechnicianGetByUserSuccess;

  constructor(public payload: RepairCentreTechnician[]) {
  }
}

export class GetRepairCentreTechnicianByTenant implements Action {
  readonly type = RepairCentreTechnicianActionTypes.repairCentreTechnicianGetByTenant;

  constructor() {
  }
}

export class GetRepairCentreTechnicianByTenantSuccess implements Action {
  readonly type = RepairCentreTechnicianActionTypes.repairCentreTechnicianGetByTenantSuccess;

  constructor(public payload: RepairCentreTechnician[]) {
  }
}

export class GetRepairCentreTechnicianPaged implements Action {
  readonly type = RepairCentreTechnicianActionTypes.repairCentreTechnicianGetPaged;

  constructor(public payload: any) {
  }
}

export class GetRepairCentreTechnicianPagedSuccess implements Action {
  readonly type = RepairCentreTechnicianActionTypes.repairCentreTechnicianGetPagedSuccess;

  constructor(public payload: any) {
  }
}

export class RepairCentreTechnicianError implements Action {
  readonly type = RepairCentreTechnicianActionTypes.repairCentreTechnicianError;

  constructor(public payload: any) {
  }
}


export class RepairCentreTechnicianSave implements Action {
  readonly type = RepairCentreTechnicianActionTypes.repairCentreTechnicianSave;

  constructor(public payload: RepairCentreTechnician) {
  }
}

export class RepairCentreTechnicianSaveSuccess implements Action {
  readonly type = RepairCentreTechnicianActionTypes.repairCentreTechnicianSaveSuccess;

  constructor(public payload: RepairCentreTechnician) {
  }
}

export class RepairCentreTechnicianActivate implements Action {
  readonly type = RepairCentreTechnicianActionTypes.repairCentreTechnicianActivate;

  constructor(public payload: number) {
  }
}

export class RepairCentreTechnicianActivateSuccess implements Action {
  readonly type = RepairCentreTechnicianActionTypes.repairCentreTechnicianActivateSuccess;

  constructor(public payload: RepairCentreTechnician) {
  }
}

export class RepairCentreTechnicianDeactivate implements Action {
  readonly type = RepairCentreTechnicianActionTypes.repairCentreTechnicianDeactivate;

  constructor(public payload: number) {
  }
}

export class RepairCentreTechnicianDeactivateSuccess implements Action {
  readonly type = RepairCentreTechnicianActionTypes.repairCentreTechnicianDeactivateSuccess;

  constructor(public payload: RepairCentreTechnician) {
  }
}


export type RepairCentreTechnicianActions =
  | RepairCentreTechnicianError
  | GetAllRepairCentreTechnician
  | GetRepairCentreTechniciansByUser
  | GetRepairCentreTechnicianByUserSuccess
  | GetRepairCentreTechnicianByTenant
  | GetRepairCentreTechnicianByTenantSuccess
  | GetAllRepairCentreTechnicianSuccess
  | GetRepairCentreTechnicianPaged
  | GetRepairCentreTechnicianPagedSuccess
  | RepairCentreTechnicianSave
  | RepairCentreTechnicianSaveSuccess
  | RepairCentreTechnicianActivate
  | RepairCentreTechnicianActivateSuccess
  | RepairCentreTechnicianDeactivate
  | RepairCentreTechnicianDeactivateSuccess;




