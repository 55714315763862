import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getWorkorderTestFlowStore = createFeatureSelector('workordertestFlow');

export const getWorkOrderTestflowEntities = createSelector(
  getWorkorderTestFlowStore,
  fromReducers.workordertestFlow.workordertestFlowEntitySelectors.selectAll
);

export const getWorkOrderTestFlowsPaging = createSelector(
  getWorkorderTestFlowStore,
  (workordertestFlowStore: fromReducers.workordertestFlow.State) => workordertestFlowStore.paging
);

export const getWorkOrderTestFlows = createSelector(getWorkOrderTestflowEntities, entities => {
  return Object.values(entities);
});


export const getWorkOrderTestFlowsLoaded = createSelector(
  getWorkorderTestFlowStore,
  (workordertestFlowStore: fromReducers.workordertestFlow.State) => workordertestFlowStore.loaded
);

export const getWorkOrderTestFlowsLoading = createSelector(
  getWorkorderTestFlowStore,
  (workordertestFlowStore: fromReducers.workordertestFlow.State) => workordertestFlowStore.loading
);

export const getSelectedWorkOrderTestFlowId = createSelector(
  getWorkorderTestFlowStore,
  (workordertestFlowStore: fromReducers.workordertestFlow.State) => workordertestFlowStore.selectedWorkOrderTestFlowId
);


export const getWorkOrderTestFlowById = createSelector(
  getWorkorderTestFlowStore,
  (workordertestFlowStore: fromReducers.workordertestFlow.State) => workordertestFlowStore.selectedWorkOrderTestFlow
);

export const getWorkOrderTestFlowError = createSelector(
  getWorkorderTestFlowStore,
  (workordertestFlowStore: fromReducers.workordertestFlow.State) => workordertestFlowStore.error
);
