import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import * as _ from 'lodash';
import {RepairCentre} from '../../../../models/core-aftersales/repaircentre';
import {
  RepairCentresActions,
  RepairCentresActionTypes
} from '../../../actions/core-aftersales/repaircentres/repaircentres.actions';

export interface State extends EntityState<RepairCentre> {
  error: any;
  loaded: boolean;
  loading: boolean;
  selectedRepairCentreId: number;
  paging: any;
  usersRepairCentres: RepairCentre[];
}

export const adapter: EntityAdapter<RepairCentre> = createEntityAdapter<RepairCentre>();

export const initialState: State = adapter.getInitialState({
  error: null,
  loaded: false,
  loading: false,
  selectedRepairCentreId: null,
  paging: null,
  usersRepairCentres: null
});

export function reducer(state = initialState, action: RepairCentresActions): State {
  switch (action.type) {
    case RepairCentresActionTypes.repairCentreAdd:
    case RepairCentresActionTypes.repairCentreGetAll:
    case RepairCentresActionTypes.repairCentreChangeState:
    case RepairCentresActionTypes.repairCentreUpdate:
    case RepairCentresActionTypes.repairCentreGet:
    case RepairCentresActionTypes.repairCentreGetAllByUser:
      return {
        ...state,
        loading: true,
        loaded: false
      };

    case RepairCentresActionTypes.repairCentreAddSuccess:
    case RepairCentresActionTypes.repairCentreGetSuccess:
      return adapter.addOne(action.payload, {
        ...state,
        selectedRepairCentreId: action.payload.id,
        loading: false,
        loaded: true
      });

    case RepairCentresActionTypes.repairCentreChangeStateSuccess:
    case RepairCentresActionTypes.repairCentreUpdateSuccess:
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state,
          selectedRepairCentreId: action.payload.id,
          loading: false,
          loaded: true
        }
      );
    case RepairCentresActionTypes.repairCentreError:
      return adapter.addOne(action.payload, {
        ...state,
        error: action.payload.id,
        loading: false,
        loaded: true
      });

    case RepairCentresActionTypes.repairCentreGetAllSuccess:
      return adapter.setAll(action.payload.content, {
        ...state,
        loading: false,
        loaded: true,
        paging: _.omit(action.payload, ['content'])
      });
    case RepairCentresActionTypes.repairCentreGetAllByUserSuccess:
      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true,
        usersRepairCentres: action.payload
      });
    default:
      return state;
  }
}

export const repairCentreEntitySelectors = adapter.getSelectors();

