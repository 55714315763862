import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getInventoryProductStore = createFeatureSelector('inventoryProduct');

export const getInventoryProductEntities = createSelector(
  getInventoryProductStore,
  fromReducers.inventoryProduct.inventoryProductEntitySelectors.selectAll
);

export const getInventoryProducts = createSelector(getInventoryProductEntities, entities => {
  return Object.values(entities);
});

export const getTopInventoryProducts = createSelector(getInventoryProductEntities, entities => {
  return Object.values(entities).slice(1, 5);
});

export const getInventoryProductsLoaded = createSelector(
  getInventoryProductStore,
  (inventoryProductStore: fromReducers.inventoryProduct.State) => inventoryProductStore.loaded
);

export const getInventoryProductsCount = createSelector(
  getInventoryProductStore,
  (inventoryProductStore: fromReducers.inventoryProduct.State) => inventoryProductStore.count
);

export const getInventoryProductsStartPage = createSelector(
  getInventoryProductStore,
  (inventoryProductStore: fromReducers.inventoryProduct.State) => inventoryProductStore.startPage
);


export const getInventoryProductsNumberPerPage = createSelector(
  getInventoryProductStore,
  (inventoryProductStore: fromReducers.inventoryProduct.State) => inventoryProductStore.numberPerPage
);



export const getInventoryProductsLoading = createSelector(
  getInventoryProductStore,
  (inventoryProductStore: fromReducers.inventoryProduct.State) => inventoryProductStore.loading
);

export const getSelectedInventoryProductId = createSelector(
  getInventoryProductStore,
  (inventoryProductStore: fromReducers.inventoryProduct.State) => inventoryProductStore.selectedInventoryProductId
);

export const getSelectedInventoryProduct = createSelector(
  getInventoryProductStore,
  (inventoryProductStore: fromReducers.inventoryProduct.State) => inventoryProductStore.selectedInventoryProduct
);


export const getSearchInventoryProducts = createSelector(
  getInventoryProductStore,
  (inventoryProductStore: fromReducers.inventoryProduct.State) => inventoryProductStore.searchInventoryProducts
);

export const getInventoryProductById = createSelector(
  getInventoryProductEntities,
  getSelectedInventoryProductId,
  (entities, id) => entities.find(i => i.id === id)
);

export const getInventoryProductsError = createSelector(
  getInventoryProductStore,
  (inventoryProductStore: fromReducers.inventoryProduct.State) => inventoryProductStore.error
);
