import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { IdentificationType } from '@appModels/core-crm/identification-type/identification-type';
import { User } from '@appModels/core-identity/user/user';

import { CoreCrmService } from '../core-crm.service';



@Injectable({ providedIn: 'root' })
export class IdentificationTypeService extends CoreCrmService {


  constructor(
    private http: HttpClient,

  ) {
    super(http, 'customeridentificationtype');
  }

  /** GET identificationTypes from the server */
  getIdentificationTypes(query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        params = params.append(val, query[val]);
      });
    }
    return this.http.get<any>(this.base_url + '/tenant/' + localStorage.getItem('tenant') , {
      params,
      headers: this.headers
    }).pipe(
      tap(identificationTypes => this.log(`fetched identificationTypes`)),
      catchError(this.handleError('getIdentificationTypes', []))
    );
  }

  /** GET identificationType by id. Return `undefined` when id not found */
  getIdentificationTypeNo404<Data>(id: number): Observable<IdentificationType> {
    const url = `${this.base_url}/?id=${id}`;
    return this.http.get<IdentificationType[]>(url).pipe(
      map(identificationTypes => identificationTypes[0]), // returns a {0|1} element array
      tap(h => {
        const outcome = h ? `fetched` : `did not find`;
        this.log(`${outcome} identificationType id=${id}`);
      }),
      catchError(this.handleError<IdentificationType>(`getIdentificationType id=${id}`))
    );
  }

  /** GET identificationType by id. Will 404 if id not found */
  getIdentificationType(id: number): Observable<IdentificationType> {
    const url = `${this.base_url}/${id}`;
    return this.http.get<IdentificationType>(url,{ headers: this.headers }).pipe(
      tap(_ => this.log(`fetched identificationType id=${id}`)),
      catchError(this.handleError<IdentificationType>(`getIdentificationType id=${id}`))
    );
  }

    /** GET identificationType by module. Will 404 if id not found */
  getIdentificationTypeBy(id: number, _module: string): Observable<User[]> {
    const url = `${this.base_url}/${id}/${_module}`;
    return this.http.get<User[]>(url, { headers: this.headers }).pipe(
      tap(_ => this.log(`fetched identificationType id=${id}`)),
      catchError(this.handleError<User[]>(`getIdentificationType id=${id}`))
    );
  }

  /* GET identificationTypes whose name contains search term */
  searchIdentificationTypes(term: string): Observable<IdentificationType[]> {
    if (!term.trim()) {
      // if not search term, return empty identificationType array.
      return of([]);
    }
    return this.http.get<IdentificationType[]>(`api/identificationTypes/?name=${term}`).pipe(
      tap(_ => this.log(`found identificationTypes matching "${term}"`)),
      catchError(this.handleError<IdentificationType[]>('searchIdentificationTypes', []))
    );
  }

  //////// Save methods //////////

  /** POST: add a new identificationType to the server */
  addIdentificationType(identificationType: IdentificationType): Observable<IdentificationType> {
    return this.http.post<IdentificationType>(this.base_url , identificationType, { headers: this.headers }).pipe(
      tap((identificationType: IdentificationType) => this.log(`added identificationType w/ id=${identificationType.id}`)),
      catchError(this.handleError<IdentificationType>('addIdentificationType'))
    );
  }

  /** DELETE: delete the identificationType from the server */
  deleteIdentificationType(identificationType: IdentificationType | number): Observable<IdentificationType> {
    const id = typeof identificationType === 'number' ? identificationType : identificationType.id;
    const url = `${this.base_url}/${id}`;

    return this.http.delete<IdentificationType>(url, { headers: this.headers }).pipe(
      tap(_ => this.log(`deleted identificationType id=${id}`)),
      catchError(this.handleError<IdentificationType>('deleteIdentificationType'))
    );
  }

  /** PUT: update the identificationType on the server */
  updateIdentificationType(identificationType: IdentificationType): Observable<any> {
    return this.http.put(`${this.base_url}/${identificationType.id}`, identificationType, { headers: this.headers }).pipe(
      tap(_ => this.log(`updated identificationType id=${identificationType.id}`)),
      catchError(this.handleError<any>('updateIdentificationType'))
    );
  }

  /** Patch: update the identificationType on the server */
  editIdentificationType(data): Observable<any> {
    return this.http.patch(`${this.base_url}/${data.id}/${data.param}/${data.value}`, {}, { headers: this.headers }).pipe(
      tap(_ => this.log(`updated identificationType id=${data.id}`)),
      catchError(this.handleError<any>('updateIdentificationType'))
    );
  }

  /** validate identificationType by id. Will 404 if id not found */
  validateIdentification(id: number, data: string): Observable<any> {
    const url = `${this.base_url}/${id}/validate`;
    return this.http.post(url, data, { headers: this.headers, responseType: 'json'})
  }

}
