import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

import {
  AddProductWarranty,
  AddProductWarrantySuccess,
  DeleteProductWarranty,
  DeleteProductWarrantySuccess,
  EditProductWarrantySuccess,
  GetProductWarranties,
  GetProductWarrantiesSuccess,
  GetProductWarrantyById,
  GetProductWarrantyByIdSuccess,
  ProductWarrantyActionTypes,
  ProductWarrantyError,
  SearchProductWarranties,
  SearchProductWarrantiesSuccess,
  UpdateProductWarrantySuccess
} from '@appStore/actions/core-masterdata/product-warranty/product-warranty.actions';
import {ProductService} from '@appServices/core-masterdata/product/product.service';

import * as fromRouterActions from '@appStore/actions/router.actions';
import {ProductWarrantyService} from '../../../../services/core-masterdata/product-warranty/product-warranty.service';
import {
  AddProductWarranties,
  AddProductWarrantiesSuccess,
  UpdateProductWarranty
} from '../../../actions/core-masterdata/product-warranty/product-warranty.actions';
import {ToastrService} from 'ngx-toastr';

@Injectable()
export class ProductWarrantyEffects {
  
  loadProductWarranties$ = createEffect(() => this.actions$.pipe(
    ofType(ProductWarrantyActionTypes.productWarrantyGetProductWarranties),
    mergeMap(() =>
      this.productWarrantyService
        .getProductWarranties()
        .pipe(
          map(productWarranties => {
            return new GetProductWarrantiesSuccess(productWarranties)
          }),
          catchError(error => of(new ProductWarrantyError(error)))
        )
    )
  ));
  
  getProductWarrantyById$ = createEffect(() => this.actions$.pipe(
    ofType(ProductWarrantyActionTypes.productWarrantyGetProductWarrantyById),
    mergeMap((action: GetProductWarrantyById) =>
      this.productWarrantyService
        .getProductWarranty(action.payload)
        .pipe(
          map(productWarranty => new GetProductWarrantyByIdSuccess(productWarranty)),
          catchError(error => of(new ProductWarrantyError(error)))
        )
    )
  ));
  
  addProductWarranties$ = createEffect(() => this.actions$.pipe(
    ofType(ProductWarrantyActionTypes.productWarrantyAddProductWarranties),
    switchMap((action: AddProductWarranties) =>
      this.productWarrantyService
        .addProductWarranties(action.payload)
        .pipe(
          map(productWarranty => {
            this.toastr.success('Successfully added product warranties', 'Successful!');
            return new AddProductWarrantiesSuccess(productWarranty);
          }),
          catchError(error => of(new ProductWarrantyError(error)))
        )
    )
  ));
  
  updateProductWarranty$ = createEffect(() => this.actions$.pipe(
    ofType(ProductWarrantyActionTypes.productWarrantyUpdateProductWarranty),
    mergeMap((action: UpdateProductWarranty) =>
      this.productWarrantyService
        .updateProductWarranty(action.payload)
        .pipe(
          map(productWarranty => new UpdateProductWarrantySuccess(action.payload)),
          catchError(error => of(new ProductWarrantyError(error)))
        )
    )
  ));
  
  editProductWarranty$ = createEffect(() => this.actions$.pipe(
    ofType(ProductWarrantyActionTypes.productWarrantyEditProductWarranty),
    mergeMap((action: AddProductWarranty) =>
      this.productWarrantyService
        .editProductWarranty(action.payload)
        .pipe(
          map(product => new GetProductWarranties()),
          catchError(error => of(new ProductWarrantyError(error)))
        )
    )
  ));
  
  deleteProductWarranty$ = createEffect(() => this.actions$.pipe(
    ofType(ProductWarrantyActionTypes.productWarrantyDeleteProductWarranty),
    mergeMap((action: DeleteProductWarranty) =>
      this.productWarrantyService
        .deleteProductWarranty(action.payload)
        .pipe(
          map(() => new DeleteProductWarrantySuccess(action.payload)),
          catchError(error => of(new ProductWarrantyError(error)))
        )
    )
  ));
  
  updateProductWarrantySuccess$ = createEffect(() => this.actions$.pipe(
    ofType(ProductWarrantyActionTypes.productWarrantyUpdateProductWarrantySuccess),
    map(product => new fromRouterActions.Go({path: ['/setup/product-warranty']}))
  ));

  constructor(private actions$: Actions, private toastr: ToastrService, private productWarrantyService: ProductWarrantyService) {
  }
}
