import {Action} from '@ngrx/store';

import {StockingPoint} from '@appModels/core-inventory/stocking-point/stocking-point';

export enum StockingPointActionTypes {
  stockingPointGetStockingPoints = '[StockingPoint] get',
  stockingPointGetStockingPointsSuccess = '[StockingPoint] get stockingPoints success',
  stockingPointGetStockingPointsByTenantId = '[StockingPoint] get stocking points by tenant id',
  stockingPointGetStockingPointsByTenantIdSuccess = '[StockingPoint] get stocking points by tenant id success',
  stockingPointAddStockingPoint = '[StockingPoint] add StockingPoint',
  stockingPointAddStockingPointSuccess = '[StockingPoint] add stockingPoint success',
  stockingPointAddAdditionalStockingPointInfo = '[StockingPoint] add additional StockingPoint Info',
  stockingPointAddAdditionalStockingPointInfoSuccess = '[StockingPoint] add additional stockingPoint Info success',
  stockingPointDeleteStockingPoint = '[StockingPoint] delete stockingPoint',
  stockingPointDeleteStockingPointSuccess = '[StockingPoint] delete stockingPoint success',
  stockingPointGetStockingPointById = '[StockingPoint] get stockingPoint by id',
  stockingPointGetStockingPointByIdSuccess = '[StockingPoint] get stockingPoint by id success',
  stockingPointUpdateStockingPoint = '[StockingPoint] update stockingPoint',
  stockingPointUpdateStockingPointSuccess = '[StockingPoint] update stockingPoint success',
  stockingPointSearchStockingPoints = '[StockingPoint] search stockingPoints',
  stockingPointSearchStockingPointsSuccess = '[StockingPoint] search stockingPoints success',
  stockingPointSearchStockingPointsReset = '[StockingPoint] search stockingPoints reset',
  stockingPointError = '[StockingPoint] error'
}

export class GetStockingPoints implements Action {
  readonly type = StockingPointActionTypes.stockingPointGetStockingPoints;
}

export class GetStockingPointsSuccess implements Action {
  readonly type = StockingPointActionTypes.stockingPointGetStockingPointsSuccess;
  constructor(public payload: StockingPoint[]) {}
}

export class GetStockingPointByTenantId implements Action {
  readonly type = StockingPointActionTypes.stockingPointGetStockingPointsByTenantId;

  constructor(public payload: number) {
  }
}

export class GetStockingPointByTenantIdIdSuccess implements Action {
  readonly type = StockingPointActionTypes.stockingPointGetStockingPointsByTenantIdSuccess;

  constructor(public payload: any) {
  }
}

export class AddStockingPoint implements Action {
  readonly type = StockingPointActionTypes.stockingPointAddStockingPoint;
  constructor(public payload: StockingPoint) {}
}

export class AddStockingPointSuccess implements Action {
  readonly type = StockingPointActionTypes.stockingPointAddStockingPointSuccess;
  constructor(public payload: StockingPoint) {}
}

export class AddAdditionalStockingPointInfo implements Action {
  readonly type = StockingPointActionTypes.stockingPointAddAdditionalStockingPointInfo;
  constructor(public payload) {}
}

export class AddAdditionalStockingPointInfoSuccess implements Action {
  readonly type = StockingPointActionTypes.stockingPointAddAdditionalStockingPointInfoSuccess;
  constructor(public payload) {}
}

export class GetStockingPointById implements Action {
  readonly type = StockingPointActionTypes.stockingPointGetStockingPointById;
  constructor(public payload: number) {}
}

export class GetStockingPointByIdSuccess implements Action {
  readonly type = StockingPointActionTypes.stockingPointGetStockingPointByIdSuccess;
  constructor(public payload: StockingPoint) {}
}

export class UpdateStockingPoint implements Action {
  readonly type = StockingPointActionTypes.stockingPointUpdateStockingPoint;
  constructor(public payload: StockingPoint) {}
}

export class UpdateStockingPointSuccess implements Action {
  readonly type = StockingPointActionTypes.stockingPointUpdateStockingPointSuccess;
  constructor(public payload: StockingPoint) {}
}

export class DeleteStockingPoint implements Action {
  readonly type = StockingPointActionTypes.stockingPointDeleteStockingPoint;
  constructor(public payload: StockingPoint) {}
}

export class DeleteStockingPointSuccess implements Action {
  readonly type = StockingPointActionTypes.stockingPointDeleteStockingPointSuccess;
  constructor(public payload: StockingPoint) {}
}

export class SearchStockingPoints implements Action {
  readonly type = StockingPointActionTypes.stockingPointSearchStockingPoints;
  constructor(public payload: string) {}
}

export class SearchStockingPointsSuccess implements Action {
  readonly type = StockingPointActionTypes.stockingPointSearchStockingPointsSuccess;
  constructor(public payload: StockingPoint[]) {}
}

export class SearchStockingPointsReset implements Action {
  readonly type = StockingPointActionTypes.stockingPointSearchStockingPointsReset;
}

export class StockingPointError implements Action {
  readonly type = StockingPointActionTypes.stockingPointError;
  constructor(public payload: any) {}
}

export type StockingPointActions =
  | GetStockingPoints
  | GetStockingPointsSuccess
  | AddStockingPoint
  | AddStockingPointSuccess
  | AddAdditionalStockingPointInfo
  | AddAdditionalStockingPointInfoSuccess
  | GetStockingPointById
  | GetStockingPointByIdSuccess
  | UpdateStockingPoint
  | UpdateStockingPointSuccess
  | DeleteStockingPoint
  | DeleteStockingPointSuccess
  | SearchStockingPoints
  | SearchStockingPointsSuccess
  | SearchStockingPointsReset
  | GetStockingPointByTenantId
  | GetStockingPointByTenantIdIdSuccess
  | StockingPointError;
