
<div class="font-semibold mt-10">Product Information And Troubleshooting</div>

<div class="text-dlight">
  <hr class="h-px border-t-0 bg-transparent bg-gradient-to-r from-red-500 via-red-500 to-transparent opacity-25">
</div>

<form>

  <div class="form_group" *ngIf="!comingFromCRM && !nonPayGoSelected ">
    <div class="input mt-3">
      <label>{{'Pay Go Product' | translate}}</label>
      <select class="form-input form-input-focus" placeholder="Select Product" [(ngModel)]="selectedProduct" name="selectedProduct" (change)="changeCustomerProduct()">
        <option [ngValue]="product" *ngFor="let product of customerProductList">
          {{product.productName}} - {{product.accountNumber}}
        </option>
      </select>
    </div>
  </div>


  <div class="form_group" style="background-color: inherit" *ngIf="(comingFromCRM && !nonPayGoSelected)">
    <label>{{'Or select a Non Pay-Go Product' | translate}}</label>
  </div>

  <div class="form_group"
       *ngIf="(comingFromCRM) || (!nonPayGoSelected && selectedProduct == null) || (nonPayGoSelected && selectedProduct != null)">
    <div class="input">
      <label>{{'Non Pay Go Product' | translate}}</label>
      <select class="form-input form-input-focus" [(ngModel)]="selectedProduct" name="selectedNonPayGoProductVersion"
              (change)="changeCustomerNonPayGoProduct()">
        <option [ngValue]="product" *ngFor="let product of nonPayGoList">
          {{product.versionName}}
        </option>
      </select>
    </div>
  </div>



  <div class="datagrid p-b-10" style="margin:20px; padding: 0; position: relative"
       *ngIf="selectedProduct != null && testFlow != null && testFlow != undefined && testFlow.testCases != undefined">
    <table class="expandable-table">
      <tr class="expandable-table__header-row">
        <th class="expandable-table__header-heading expandable-table__header-heading--sortable"
            style="width: 48%">
          {{'Test Case' | translate}}
        </th>
        <th class="expandable-table__header-heading expandable-table__header-heading--sortable"
            style="width: 40%">
          {{'Result' | translate}}
        </th>
      </tr>
      <ng-container *ngFor="let testCase of testFlow.testCases">

        <tr class="expandable-table__row" id="{{testCase.id}}">
          <td class="expandable-table__cell question" (click)="onClickRow(testCase.id)">{{testCase.question}}</td>
          <td class="expandable-table__cell">
            <a href="javascript:void(0)" class="status active m-l-10"
               style="background-color: #97D870;color: white" (click)="answerTestCase('pass',testCase.id)"
               id="{{'pass' + testCase.id}}">
              <i class="fa fa-check"></i> {{'Pass' | translate}}
            </a>
            <a href="javascript:void(0)" class="status active m-l-10"
               style="background-color: #ED8A8F;color: white;" (click)="answerTestCase('fail',testCase.id)"
               id="{{'fail' + testCase.id}}">
              <i class="fa fa-times"></i> {{'Fail' | translate}}
            </a>
            <a href="javascript:void(0)" class="status active m-l-10"
               style="background-color: #D0D0D0;color:white" (click)="answerTestCase('blocked',testCase.id)"
               id="{{'blocked' + testCase.id}}">
              <i class="fas fa-hand-paper"></i>{{'Blocked' | translate}}
            </a>
          </td>
          <td class="expandable-table__cell expandable-table__cell--expander"></td>
        </tr>
        <tr class="expandable-table__details-row">
          <td colspan="42">
            <div class="expandable-table__details">
              <h3>{{'Description' | translate}}:</h3>
              <p>
                {{testCase.description}}
              </p>
              <br>
              <h3>{{'Expected Result' | translate}}:</h3>
              <p>
                {{testCase.expectedResult}}
              </p>
              <br>
              <h3>{{'Advice if Failed' | translate}}:</h3>
              <p>
                {{testCase.advice}}
              </p>
            </div>
          </td>
        </tr>

      </ng-container>

    </table>
  </div>

  <div style="margin-top: 30px;clear: both;"></div>

  <form>
    <ng-container *ngFor="let advice of combinedAdvice; index as i">
      <div *ngIf="i == 0" style="margin-left: 10px">
        <h3>{{'Advice' | translate}}</h3>
      </div>
      <div class="form_group">
        <input type="text" value="{{advice.advice}}" disabled>
      </div>
    </ng-container>
  </form>




  <div class="form_group" >
    <div class="input mt-3" *ngIf="selectedProduct != null">
      <label>{{'Sub Units' | translate}}</label>
      <div *ngIf="selectedProduct != null">
        <div class="text-dlight">
          <hr class="h-px border-t-0 bg-transparent bg-gradient-to-r from-red-500 via-red-500 to-transparent opacity-25">
        </div>
      </div>
      <select  [(ngModel)]="customerSubUnit" class="form-input form-input-focus mt-3" placeholder="Select Sub Unit" name="customerSubUnit" (change)="onChangeCustomerSubUnits($event);showComplaints()">
        <option [ngValue]=null>{{'Select Sub Unit' | translate}}</option>
        <option [ngValue]="subUnit" *ngFor="let subUnit of customerSubUnitsList">{{subUnit.name}}</option>
      </select>
    </div>
    <div class="input mt-3" *ngIf="selectedProduct && customerSubUnit">
      <label>{{'Customer Complaint' | translate}}</label>
      <select [(ngModel)]="customerComplaint" class="form-input form-input-focus" placeholder="Select Customer Complaint"  name="customerComplaint" (change)="addComplaint()">
        <option [ngValue]=null>{{'Select Complaint' | translate}}</option>
        <option [ngValue]="complaint" *ngFor="let complaint of complaints">
          {{complaint.complaint}}
        </option>
      </select>
    </div>
  </div>

  <button class="button_secondary" *ngIf="customerSubUnit && customerComplaint" (click)="addToLists()">{{'Add Complaint' | translate}} </button>

  <div class="datagrid m-l-20 m-r-20" *ngIf="selectedProduct != null">
    <table id="customer_kyc" class="tableClassName3">
      <thead>
      <tr>
        <th>{{'Name' | translate}}</th>
        <th>{{'Warranty Status' | translate}}</th>
        <th>{{'Serial Number' | translate}}</th>
        <th>{{'Customer Complaints' | translate}}</th>
        <th>{{'Action' | translate}}</th>
      </tr>
      </thead>
      <tbody>
      <tr [ngClass]="{'already-has-ticket' : !subUnitsValid[subunit['id']]}" role="row"
          *ngFor="let subunit of selectedSubUnitsList; let subunitIndex = index">
        <td>{{ subunit.name }}</td>
        <td>{{ subunit.warrantyStatus }}</td>
        <!-- <td>{{ subunit.serialNumber }}</td> -->
        <td *ngIf="nonPayGoSelected">
          <div class="form_group" style="width: 90%; padding:0px;">
            <input style="padding: 0px" type="text"
                   name="serialNumber"
                   [(ngModel)]="subunit.serialNumber" [ngModelOptions]="{standalone: true}"
                   placeholder="Enter Serial"></div>
        </td>
        <td *ngIf="!nonPayGoSelected">
          <input style="padding: 0px"
                 type="text"
                 name="{{subunit.id}}"
                 [disabled]="isBaseUnitOrPCBA(subunit)"
                 [(ngModel)]="subunit.serialNumber" [ngModelOptions]="{standalone: true}"
                 placeholder="Enter Serial">
        </td>
        <td>
          <ng-container *ngFor="let complaint of subunit.complaints; let complaintIndex = index">
            <p class="p-r-5">
              {{ complaint.complaint }}  <a (click)="removeComplaintFromSubunit(subunitIndex, complaintIndex)" class="fa fa-remove">  </a>
            </p>
          </ng-container>
        </td>
        <td>
                  <span (click)="removeSubunit(subunitIndex)">
                    <a href="javascript:void(0)">  {{'Remove' | translate}}</a></span>
        </td>
      </tr>

      </tbody>
    </table>
  </div>




  <div *ngIf="!formValid.subUnits"
       class="notification error m-r-20 m-l-20">
          <span
            *ngIf="selectedCustomerSubUnits && selectedCustomerSubUnits.length>0"> {{'There is already an open ticket for some of these subunits' | translate}} </span>
    <span
      *ngIf="!selectedCustomerSubUnits || selectedCustomerSubUnits.length==0"> {{'You need to select a subunit' | translate}} </span>
  </div>


  <div *ngIf="!this.formValid.customerComplaint || (complaints?.length > 0 && customerComplaints?.length == 0)"
       class="notification error m-r-20 m-l-20">
    <span> {{'You need to select a complaint' | translate}} </span>
  </div>




  <div class="text-dlight">
    <hr class="h-px border-t-0 bg-transparent bg-gradient-to-r from-red-500 via-red-500 to-transparent opacity-25">
  </div>

  <div class="form_group mt-3" *ngIf=" selectedProduct != null && !nonPayGoSelected">
    <div class="input">
      <label>{{'Date of Purchase' | translate}}</label>
      <input class="form-input form-input-focus" type="text" [(ngModel)]="selectedProduct.contractCreateDate" name="productDateOfPurchase"
             placeholder="MM/DD/YYYY" disabled>
    </div>
  </div>

  <div class="form_group" *ngIf=" selectedProduct != null && nonPayGoSelected">
    <div class="input">
      <label>{{'Date of Purchase' | translate}}</label>
      <input class="form-input form-input-focus" type="date" [(ngModel)]="selectedProduct.contractCreateDate" name="productDateOfPurchase"
             placeholder="MM/DD/YYYY">
    </div>
  </div>

  <div class="form_group" *ngIf="selectedProduct != null">
    <div class="input">
      <label>{{'Problem Description' | translate}}</label>
      <textarea class="form-input form-input-focus" cols="40" rows="5" [(ngModel)]="ticket.productProblemDescription"
                name="productProblemDescription"></textarea>
    </div>
  </div>

  <div class="form_group" *ngIf="comingFromCRM && selectedProduct != null">
    <div class="input">
      <label>{{'Internal Notes' | translate}}</label>
      <textarea class="form-input form-input-focus" cols="40" rows="5" [(ngModel)]="ticket.callAgentNotes" name="callAgentNotes"></textarea>
    </div>
  </div>

</form>



<ng-container *ngIf="showAssignRepairCenters">
  <div class="form_header mt-3">
    {{'Repair Center' | translate}}
  </div>

  <div class="flex flex-row justify-center gap-4 mt-3">

    <div class="grid grid-cols-2 w-full">
      <div class="flex flex-row justify-center gap-3 items-baseline col-span-1" >
        <input  type="checkbox" class="form-input " name="ascNearest" (click)="changeASCMethod($event)" [(ngModel)]="ascNearest">
        <p >{{'ASCs Nearest To Customer' | translate}}</p>
      </div>

      <div class="flex flex-row justify-center gap-3 items-baseline col-span-1">
        <input  type="checkbox" class="form-input " name="ascAll" (click)="changeASCMethod($event)" [(ngModel)]="ascAll">
        <p>{{'All ASCs' | translate}}</p>
      </div>
    </div>

  </div>

  <div class="form_group mt-3">
    <div class="input">
      <label>{{'Repair Center' | translate}}</label>
      <select2
        [settings]="{
              width: '100%'
            }"
        [options]="repairCentreNames"
        (onSelect)="selectASC($event)"
        [(ngModel)]="selectedRepairCenterId"
        name="selectedRepairCenterId">
      </select2>
    </div>
  </div>

  <div class="form_group mt-3" *ngIf="(ascNearest || ascAll ) && selectedRepairCenter != null">
    <div class="input">
      <label>{{'ASC Name' | translate}}</label>
      <input class="form-input form-input-focus" type="text" placeholder="Repair Center" name="repairCentreName" [(ngModel)]="selectedRepairCenter.name" disabled>
    </div>
  </div>

  <div class="form_group mt-3" *ngIf="(ascNearest || ascAll ) && selectedRepairCenter != null">
    <div class="input">
      <label>{{'ASC Phone Number' | translate}}</label>
      <input class="form-input form-input-focus" type="text" placeholder="12345" name="repairCentrePhoneNumber" [(ngModel)]="selectedRepairCenter.mainPhoneNumber" disabled>
    </div>
  </div>

  <div class="form_group mt-3" *ngIf="(ascNearest || ascAll ) && selectedRepairCenter != null">
    <div class="input">
      <label>{{'ASC Location' | translate}}</label>
      <input class="form-input form-input-focus" type="text" placeholder="12345" name="repairCentreZoneName"
             [(ngModel)]="selectedRepairCenter.location.countryAdministrativeZoneName" disabled>
    </div>
  </div>

  <div class="form_group mt-3" *ngIf="(ascNearest || ascAll ) && selectedRepairCenter != null">
    <div class="input">
      <label>{{'ASC Location Details' | translate}}</label>
      <input class="form-input form-input-focus" type="text" placeholder="Street name, building name, floor number ..." name="repairCentreLocation"
             [(ngModel)]="selectedRepairCenter.locationDetails" disabled>
    </div>
  </div>
</ng-container>

<div *ngIf="formValid.noOpenTickets  else openTickets" class="mt-3 flex flex-row justify-center w-full">
  <ng-container *ngIf="!showAssignRepairCenters && selectedProduct != null && !ticketCreated">
    <div class="grid grid-cols-3 gap-4">
      <button class="btn btn-success col-span-1 cursor-pointer" type="button" (click)="markAsClosed('No trouble found')"
      >No Trouble found - Close Ticket</button>

      <button  class="btn btn-dlight col-span-1 cursor-pointer" type="button" (click)="markAsClosed('Resolved on call')"
      >Resolved on call - Close Ticket</button>

      <button  class="btn btn-dlight col-span-1 cursor-pointer" type="button" (click)="showAssignRepairCenters = true" [disabled]="complaints?.length > 0 && customerComplaints?.length == 0"
      >Assign Repair Centre</button>
    </div>
  </ng-container>
</div>

<div class="flex flex-row justify-center w-full mt-3" *ngIf="showAssignRepairCenters && !loading && !loadingCreate">
  <button type="button" class="btn btn-success" (click)="saveTicket()">Create Ticket</button>
</div>

<div class="mt-5 mb-5" *ngIf="loadingCreate">
  <div class="flex flex-row justify-center w-full  gap-4">
    <span class="m-auto mb-10 inline-block h-14 w-14 animate-spin rounded-full border-8 border-[#f1f2f3] border-l-dlight align-middle"></span>
  </div>
</div>




<ng-template #openTickets>
  <div class="notification error">
    {{'There are already open tickets for this product:' | translate}}
    <br>
    <div *ngFor="let tick of getOpenTickets() ">
      {{tick.ticketNumber}}
      <br>
    </div>
    {{'You can add the subunits to this open ticket' | translate}}
  </div>
</ng-template>


