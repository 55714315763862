import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import {of} from "rxjs";
import {ToastrService} from 'ngx-toastr';
import {RepairCentreService} from '../../../../services/core-aftersales/repaircentre/repair-centre.service';
import {
  AddRepairCentre,
  AddRepairCentreSuccess,
  ChangeRepairCentreState,
  ChangeRepairCentreStateSuccess,
  GetAllRepairCentre, GetAllRepairCentreByUser, GetAllRepairCentreByUserSuccess,
  GetAllRepairCentreSuccess,
  GetRepairCentre,
  GetRepairCentreSuccess,
  RepairCentreError,
  RepairCentresActionTypes,
  UpdateRepairCentre,
  UpdateRepairCentreSuccess
} from '../../../actions/core-aftersales/repaircentres/repaircentres.actions';
import {GetStockingPointUserByUser} from "../../../actions/core-inventory/stocking-point-user/stocking-point-user.actions";

@Injectable()
export class RepairCentreEffects {
  
  addRepairCentre = createEffect(() => this.actions$.pipe(
    ofType(RepairCentresActionTypes.repairCentreAdd),
    switchMap((action: AddRepairCentre) =>
      this.repairCentreService
        .saveRepairCentre(action.payload.repairCentre, action.payload.images)
        .pipe(
          map(
            repairCentre => {
              if (repairCentre !== undefined) {
                this.toastr.success('Repair center added successfully!', 'Successful!');
                return new AddRepairCentreSuccess(repairCentre);
              }
              this.toastr.error('There was an error creating repair centre!', 'Unknown error');
              return new RepairCentreError({type: 500, message: 'Internal error'})
            }
          ),
          catchError(error => of(new RepairCentreError(error)))
        )
    )
  ));

  
  changeRepairCentreState = createEffect(() => this.actions$.pipe(
    ofType(RepairCentresActionTypes.repairCentreChangeState),
    switchMap((action: ChangeRepairCentreState) =>
      this.repairCentreService
        .changeState(action.payload.id, action.payload.state)
        .pipe(
          map(
            repairCentre => {
              if (repairCentre !== undefined) {
                this.toastr.success('Repair center state changed successfully!', 'Successful!');
                return new ChangeRepairCentreStateSuccess(repairCentre);
              }
              this.toastr.error('There was an error updating repair centre!', 'Unknown error');
              return new RepairCentreError({type: 500, message: 'Internal error'})
            }
          ),
          catchError(error => of(new RepairCentreError(error)))
        )
    )
  ));

  
  updateRepairCentre = createEffect(() => this.actions$.pipe(
    ofType(RepairCentresActionTypes.repairCentreUpdate),
    switchMap((action: UpdateRepairCentre) =>
      this.repairCentreService
        .updateRepairCentre(action.payload.repairCentre, action.payload.images)
        .pipe(
          map(
            repairCentre => {
              if (repairCentre !== undefined) {
                this.toastr.success('Repair center updated successfully!', 'Successful!');
                return new UpdateRepairCentreSuccess(repairCentre);
              }
              this.toastr.error('There was an error updating repair centre!', 'Unknown error');
              return new RepairCentreError({type: 500, message: 'Internal error'})
            }
          ),
          catchError(error => of(new RepairCentreError(error)))
        )
    )
  ));


  
  repairCentres = createEffect(() => this.actions$.pipe(
    ofType(RepairCentresActionTypes.repairCentreGetAll),
    mergeMap((action: GetAllRepairCentre) =>
      this.repairCentreService
        .getAllRepairCentres(action.payload)
        .pipe(
          map(repairCentres => {
            return new GetAllRepairCentreSuccess(repairCentres);
          }),
          catchError(error => of(new RepairCentreError(error)))
        )
    )
  ));

  
  repairCentresByUser = createEffect(() => this.actions$.pipe(
    ofType(RepairCentresActionTypes.repairCentreGetAllByUser),
    switchMap((action: GetAllRepairCentreByUser) =>
      this.repairCentreService
        .getByUser()
        .pipe(
          map(repairCentres => {
            return new GetAllRepairCentreByUserSuccess(repairCentres);
          }),
          catchError(error => of(new RepairCentreError(error)))
        )
    )
  ));

  
  repairCentre = createEffect(() => this.actions$.pipe(
    ofType(RepairCentresActionTypes.repairCentreGet),
    mergeMap((action: GetRepairCentre) =>
      this.repairCentreService
        .getRepairCentre(action.payload)
        .pipe(
          map(repairCentre => {
            return new GetRepairCentreSuccess(repairCentre);
          }),
          catchError(error => of(new RepairCentreError(error)))
        )
    )
  ));

  constructor(
    private actions$: Actions,
    private repairCentreService: RepairCentreService,
    private toastr: ToastrService
  ) {
  }
}
