import {ChangeDetectorRef, Component, TemplateRef, ViewChild, ViewEncapsulation} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';


import {ToastrService} from "ngx-toastr";

import {Router} from "@angular/router";
import {TicketTypeService} from "@appServices/core-ticketing/ticket-type.service";
import {BasePojo} from "@appModels/ticketing/base-pojo";
import {TicketingStatusService} from "@appServices/core-ticketing/ticketing-status.service";

@Component({
  moduleId: module.id,
  templateUrl: './ticket-status-create.component.html',
  animations: [
    trigger('toggleAnimation', [
      transition(':enter', [style({ opacity: 0, transform: 'scale(0.95)' }), animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' }))]),
      transition(':leave', [animate('75ms', style({ opacity: 0, transform: 'scale(0.95)' }))]),
    ]),
  ],
  styleUrls: ['../../../tailwind.css'],
  encapsulation: ViewEncapsulation.None

})
export class TicketStatusCreateComponent {



  constructor(
    private toastrService: ToastrService,
    private ref: ChangeDetectorRef,
    private ticketStatusService: TicketingStatusService,
    private router: Router,

  ) {

  }

  mainLoading = false;


  ticketStatus : BasePojo = {
    name:"New Status",
    colorCode:"#FDD8BB",
    textColorCode:'#FA8224'
  }


  createTicketStatus() {
    if (this.ticketStatus.name == null || this.ticketStatus.name == undefined || this.ticketStatus.name.length < 3) {
      this.toastrService.error("Please enter a longer name");
      return;
    }

    this.mainLoading = true;



    this.ticketStatusService.createStatus(this.ticketStatus).subscribe(x => {
      if (x.code == 200) {
        this.toastrService.success("New Ticket Status Created");
        this.mainLoading = false;
        this.router.navigateByUrl(`/ticketing/ticketstatuses`);
      } else {
        this.toastrService.error(x.message);
        this.mainLoading = false;
      }

    })
  }
}
