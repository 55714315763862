import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getSmsProviderStore = createFeatureSelector('smsProvider');

export const getSmsProviderEntities = createSelector(
  getSmsProviderStore,
  fromReducers.smsProvider.smsProviderEntitySelectors.selectAll
);

export const getSmsProviders = createSelector(getSmsProviderEntities, entities => {
  return Object.values(entities);
});

export const getTopSmsProviders = createSelector(getSmsProviderEntities, entities => {
  return Object.values(entities).slice(1, 5);
});

export const getSmsProvidersLoaded = createSelector(
  getSmsProviderStore,
  (smsProviderStore: fromReducers.smsProvider.State) => smsProviderStore.loaded
);

export const getSmsProvidersLoading = createSelector(
  getSmsProviderStore,
  (smsProviderStore: fromReducers.smsProvider.State) => smsProviderStore.loading
);

export const getSelectedSmsProviderId = createSelector(
  getSmsProviderStore,
  (smsProviderStore: fromReducers.smsProvider.State) => smsProviderStore.selectedSmsProviderId
);

export const getSearchSmsProviders = createSelector(
  getSmsProviderStore,
  (smsProviderStore: fromReducers.smsProvider.State) => smsProviderStore.searchSmsProviders
);

export const getSmsProviderById = createSelector(
  getSmsProviderEntities,
  getSelectedSmsProviderId,
  (entities, id) => entities.find(i => i.id === id)
);

export const getSmsProvidersError = createSelector(
  getSmsProviderStore,
  (smsProviderStore: fromReducers.smsProvider.State) => smsProviderStore.error
);
