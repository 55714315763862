import { createFeatureSelector, createSelector } from '@ngrx/store';

import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import * as fromReducers from '@appStore/reducers';

export const getRouterStore = createFeatureSelector('router');

export const getRouterUrl = createSelector(
  getRouterStore,
  (state: RouterReducerState) => state.state && state.state.url
);
