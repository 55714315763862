import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getAuthStore = createFeatureSelector('auth');

export const getAuthEntities = createSelector(
  getAuthStore,
  fromReducers.auth.authEntitySelectors.selectAll
);

export const getToken = createSelector(
  getAuthStore,
  (authStore: fromReducers.auth.State) => authStore.token
);

export const getAuthLoading = createSelector(
  getAuthStore,
  (authStore: fromReducers.auth.State) => authStore.loading
);

export const isRisky = createSelector(
  getAuthStore,
  (authStore: fromReducers.auth.State) => authStore.attemptLoginFailed
);

export const errMsg = createSelector(
  getAuthStore,
  (authStore: fromReducers.auth.State) => authStore.errorMsg
);

export const isAuthenticated = createSelector(
  getAuthStore,
  (authStore: fromReducers.auth.State) => authStore.isAuthenticated
);
