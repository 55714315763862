import {ChangeDetectorRef, Component, TemplateRef, ViewChild, ViewEncapsulation} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';


import {Router} from "@angular/router";


@Component({
  moduleId: module.id,
  templateUrl: './my-issues.component.html',
  animations: [
    trigger('toggleAnimation', [
      transition(':enter', [style({ opacity: 0, transform: 'scale(0.95)' }), animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' }))]),
      transition(':leave', [animate('75ms', style({ opacity: 0, transform: 'scale(0.95)' }))]),
    ]),
  ],
  styleUrls: ['../../tailwind.css'],
  encapsulation: ViewEncapsulation.None

})
export class MyIssuesComponent {


  //this component is here just to route
  constructor(
    private router: Router,
  ) {
    this.router.navigate(
      ['/ticketing/issues'],
      {
        queryParams: {
          assignedToMe: true
        }
      })
  }

}
