import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from "@angular/core";

@Component({
  selector: "crm-kyc-left-modal-header",
  template: ` <div
      style="display: flex; justify-content: space-between; flex-grow:1;"
    >
      <strong>{{ "Customer Details" | translate }}</strong>
      <div [hidden]="assignedUser === null || !havePermission">
        <input
          class="button_secondary primary text-white"
          type="submit"
          (click)="unassignUser.emit()"
          [value]="'Unnasign user'"
        />
      </div>
    </div>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="unassignVerification.emit()"
    ></button>
    <span aria-hidden="true" style="font-size:30px;" (click)="closeModal.emit()"
      >&times;</span
    >`,
  encapsulation: ViewEncapsulation.None,
  host: {
    style: "width:100%;display: flex;",
  },
})
export class CRMKycLeftModalHeaderComponent implements OnInit {
  @Input() assignedUser: any;
  @Input() havePermission = false;
  @Output() unassignVerification = new EventEmitter<any>();
  @Output() unassignUser = new EventEmitter<any>();
  @Output() closeModal = new EventEmitter<any>();
  constructor() {}

  ngOnInit() {}
}
