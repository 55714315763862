import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { SmsProvider } from '@appModels/core-messaging/sms-provider/sms-provider';

import { CoreMessagingService } from '../core-messaging.service';



@Injectable({ providedIn: 'root' })
export class SmsProviderService extends CoreMessagingService {


  constructor(
    private http: HttpClient,
    
  ) {
    super(http, 'provider');
  }

  /** GET smsProviders from the server */
  getSmsProviders(): Observable<SmsProvider[]> {
    return this.http.get<SmsProvider[]>(this.base_url + 's/' + localStorage.getItem('tenant'), { headers: this.headers }).pipe(
      tap(smsProviders => this.log(`fetched smsProviders`)),
      catchError(this.handleError('getSmsProviders', []))
    );
  }

  /** GET smsProvider by id. Return `undefined` when id not found */
  getSmsProviderNo404<Data>(id: number): Observable<SmsProvider> {
    const url = `${this.base_url}/?id=${id}`;
    return this.http.get<SmsProvider[]>(url).pipe(
      map(smsProviders => smsProviders[0]), // returns a {0|1} element array
      tap(h => {
        const outcome = h ? `fetched` : `did not find`;
        this.log(`${outcome} smsProvider id=${id}`);
      }),
      catchError(this.handleError<SmsProvider>(`getSmsProvider id=${id}`))
    );
  }

  /** GET smsProvider by id. Will 404 if id not found */
  getSmsProvider(id: number): Observable<SmsProvider> {
    const url = `${this.base_url}/${id}/`;
    return this.http.get<SmsProvider>(url,{ headers: this.headers }).pipe(
      tap(_ => this.log(`fetched smsProvider id=${id}`)),
      catchError(this.handleError<SmsProvider>(`getSmsProvider id=${id}`))
    );
  }

  /* GET smsProviders whose name contains search term */
  searchSmsProviders(term: string): Observable<SmsProvider[]> {
    if (!term.trim()) {
      // if not search term, return empty smsProvider array.
      return of([]);
    }
    return this.http.get<SmsProvider[]>(`api/smsProviders/?name=${term}`).pipe(
      tap(_ => this.log(`found smsProviders matching "${term}"`)),
      catchError(this.handleError<SmsProvider[]>('searchSmsProviders', []))
    );
  }

  //////// Save methods //////////

  /** POST: add a new smsProvider to the server */
  addSmsProvider(smsProvider: SmsProvider): Observable<SmsProvider> {
    return this.http.post<SmsProvider>(this.base_url + '/', smsProvider, { headers: this.headers }).pipe(
      tap((smsProvider: SmsProvider) => this.log(`added smsProvider w/ id=${smsProvider.id}`)),
      catchError(this.handleError<SmsProvider>('addSmsProvider'))
    );
  }

  /** POST: add a new smsProvider to the server */
  addSmsProviderAdditionalInfo(smsProvider): Observable<{}> {
    return this.http.post(`${this.base_url}/additionalInfo/${smsProvider.id}`, smsProvider.value, { headers: this.headers }).pipe(
      tap((res) => this.log(`added smsProvider  info `)),
      catchError(this.handleError('addSmsProvider'))
    );
  }

  /** DELETE: delete the smsProvider from the server */
  deleteSmsProvider(smsProvider: SmsProvider | number): Observable<SmsProvider> {
    const id = typeof smsProvider === 'number' ? smsProvider : smsProvider.id;
    const url = `${this.base_url}/${id}`;

    return this.http.delete<SmsProvider>(url, { headers: this.headers }).pipe(
      tap(_ => this.log(`deleted smsProvider id=${id}`)),
      catchError(this.handleError<SmsProvider>('deleteSmsProvider'))
    );
  }

  /** PUT: update the smsProvider on the server */
  updateSmsProvider(smsProvider: SmsProvider): Observable<any> {
    return this.http.put(`${this.base_url}/${smsProvider.id}`, smsProvider, { headers: this.headers }).pipe(
      tap(_ => this.log(`updated smsProvider id=${smsProvider.id}`)),
      catchError(this.handleError<any>('updateSmsProvider'))
    );
  }

}
