import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {of} from "rxjs";
import {
  GetStockingPointUserByUser,
  GetStockingPointUserByUserSuccess,
  StockingPointUserActionTypes,
  StockingPointUserActivate,
  StockingPointUserActivateSuccess,
  StockingPointUserDeactivate, StockingPointUserDeactivateSuccess,
  StockingPointUserError,
  StockingPointUserGetAll,
  StockingPointUserGetAllSuccess,
  StockingPointUserSave,
  StockingPointUserSaveSuccess
} from '../../../actions/core-inventory/stocking-point-user/stocking-point-user.actions';
import {StockingPointUserService} from '../../../../services/core-inventory/stocking-point-user/stocking-point-user.service';
import {ToastrService} from "ngx-toastr";

@Injectable()
export class StockingPointUserEffects {

  
  stockingPointUser$ = createEffect(() => this.actions$.pipe(
    ofType(StockingPointUserActionTypes.stockingPointUserGetByUser),
    mergeMap((action: GetStockingPointUserByUser) =>
      this.stockingPointUserService
        .getStockingPointUserByUser(action.payload)
        .pipe(
          map(stockingPointUser => {
            if (stockingPointUser) {
              return new GetStockingPointUserByUserSuccess(stockingPointUser);
            }
            return new StockingPointUserError({type: 404, message: 'User not found'});
          }),
          catchError(error => of(new StockingPointUserError(error)))
        )
    )
  ));

  
  stockingPointUsers$ = createEffect(() => this.actions$.pipe(
    ofType(StockingPointUserActionTypes.stockingPointUserGetAll),
    mergeMap((action: StockingPointUserGetAll) =>
      this.stockingPointUserService
        .getAllStockingPointUsers(action.payload)
        .pipe(
          map(stockingPointUser => {
            if (stockingPointUser) {
              return new StockingPointUserGetAllSuccess(stockingPointUser);
            }
            return new StockingPointUserError({type: 404, message: 'Users not found'});
          }),
          catchError(error => of(new StockingPointUserError(error)))
        )
    )
  ));

  
  stockingPointUserSave$ = createEffect(() => this.actions$.pipe(
    ofType(StockingPointUserActionTypes.stockingPointUserSave),
    mergeMap((action: StockingPointUserSave) =>
      this.stockingPointUserService
        .saveStockingPointUser(action.payload)
        .pipe(
          map(stockingPointUser => {
            this.toastr.success('Stocking point user assigned successfully');
            return new StockingPointUserSaveSuccess(stockingPointUser);
          }),
          catchError(error => of(new StockingPointUserError(error)))
        )
    )
  ));

  
  stockingPointUserActivate$ = createEffect(() => this.actions$.pipe(
    ofType(StockingPointUserActionTypes.stockingPointUserActivate),
    mergeMap((action: StockingPointUserActivate) =>
      this.stockingPointUserService
        .activateStockingPointUser(action.payload)
        .pipe(
          map(stockingPointUser => {
            if (stockingPointUser) {
              this.toastr.success('Stocking point user activate successfully');
              return new StockingPointUserActivateSuccess(stockingPointUser);
            }
            return new StockingPointUserError({type: 404, message: 'Error activating user at stocking point'});
          }),
          catchError(error => of(new StockingPointUserError(error)))
        )
    )
  ));

  
  stockingPointUserDeactivate$ = createEffect(() => this.actions$.pipe(
    ofType(StockingPointUserActionTypes.stockingPointUserDeactivate),
    mergeMap((action: StockingPointUserDeactivate) =>
      this.stockingPointUserService
        .deactivateStockingPointUser(action.payload)
        .pipe(
          map(stockingPointUser => {
            if (stockingPointUser) {
              this.toastr.success('Stocking point user deactivated successfully');
              return new StockingPointUserDeactivateSuccess(stockingPointUser);
            }
            return new StockingPointUserError({type: 404, message: 'Error deactivating user at stocking point'});
          }),
          catchError(error => of(new StockingPointUserError(error)))
        )
    )
  ));


  constructor(
    private actions$: Actions,
    private stockingPointUserService: StockingPointUserService,
    private toastr: ToastrService
  ) {
  }
}
