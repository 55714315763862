import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {TenantOowRepairCondition} from '../../../../models/core-setup/oow-repair-conditions/tenant-oow-repair-condition';
import {
  TenantOowRepairConditionsActions,
  TenantOowRepairConditionsActionTypes
} from '../../../actions/core-masterdata/oow-repair-conditions/tenant-oow-repair-conditions.actions';

export interface State extends EntityState<TenantOowRepairCondition> {
  error: any;
  loaded: boolean;
  loading: boolean;
}

export const adapter: EntityAdapter<TenantOowRepairCondition> = createEntityAdapter<TenantOowRepairCondition>();

export const initialState: State = adapter.getInitialState({
  error: null,
  loaded: false,
  loading: false
});

export function reducer(state = initialState, action: TenantOowRepairConditionsActions): State {
  switch (action.type) {
    case TenantOowRepairConditionsActionTypes.tenantOowRepairConditionsGetAll:
    case TenantOowRepairConditionsActionTypes.tenantOowRepairConditionsSave:
    case TenantOowRepairConditionsActionTypes.tenantOowRepairConditionsDisable:
      return {
        ...state,
        loading: true,
        loaded: false
      };

    case TenantOowRepairConditionsActionTypes.tenantOowRepairConditionsError:
      return adapter.addOne(action.payload, {
        ...state,
        error: action.payload.id,
        loading: false,
        loaded: true
      });

    case TenantOowRepairConditionsActionTypes.tenantOowRepairConditionsDisableSuccess:
      return adapter.updateOne({
        id: action.payload.id,
        changes: action.payload
      }, {
        ...state,
        loading: false,
        loaded: true
      });

    case TenantOowRepairConditionsActionTypes.tenantOowRepairConditionsGetAllSuccess:
    case TenantOowRepairConditionsActionTypes.tenantOowRepairConditionsSaveSuccess:
      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });
    default:
      return state;
  }
}

export const tenantOowRepairConditionEntitySelectors = adapter.getSelectors();

