import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';

import {Part} from '@appModels/core-aftersales/part';
import {CoreMasterDataService} from '../core-masterdata.service';
import {stockingPoint} from "@appStore/reducers";


@Injectable({providedIn: 'root'})
export class PartService extends CoreMasterDataService {


  constructor(
    private http: HttpClient,
  ) {
    super(http, 'globalpart');
  }

  getTenantParts(id: number): Observable<any> {

    const url = `${this.base_url}s/${id}`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched tickets id=${id}`)),
      catchError(this.handleError(`getTickets id=${id}`))
    );
  }

  filterParts(query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        if (query[val] !== null && query[val] !== undefined && query[val] !== '')
          params = params.append(val, query[val]);
      });
    }

    console.log("filtering parts");

    params = params.append('tenantId', localStorage.getItem('tenant'));
    return this.http.get<any>(this.base_url + 's', {headers: this.headers}).pipe(
      tap(customers => this.log(`fetched customers`))
      ,
      catchError(this.handleError<Part[]>('getSalesStaff')
      )
    );
  }


  addPart(entity: Part): Observable<Part> {
    return this.http.post<Part>(this.base_url + 's', entity, {headers: this.headers}).pipe(
      tap(),
      catchError(this.handleError<Part>('addPart'))
    );
  }

  updatePart(entity: Part): Observable<Part> {
    return this.http.put<Part>(this.base_url + 's', entity, {headers: this.headers}).pipe(
      tap(),
      catchError(this.handleError<Part>('addPart'))
    );
  }

  getPart(id: number): Observable<any> {
    const url = `${this.base_url}/${id}`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched ticket id=${id}`)),
      catchError(this.handleError(`getPart id=${id}`))
    );
  }

  getPartPricesForSubUnit(subUnitId: number): Observable<any> {
    const tenantId = localStorage.getItem('tenant');
    const url = `${this.base_url}/tenant/${tenantId}/subunitpartprices/${subUnitId}`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched prices for subunit=${subUnitId}`)),
      catchError(this.handleError(`getPartPricesForSubUnit id=${subUnitId}`))
    );
  }

  getFinancedPartPricesForSubUnit(subUnitId: number): Observable<any> {
    const tenantId = localStorage.getItem('tenant');
    const url = `${this.base_url}/tenant/${tenantId}/subunitpartprices/${subUnitId}/financed`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched financed prices for subunit=${subUnitId}`)),
      catchError(this.handleError(`getFinancedPartPricesForSubUnit id=${subUnitId}`))
    );
  }

  getPartQuantitiesForSubUnit(subUnitId: number, stockingPointId: number): Observable<any> {
    const url = `${this.base_url}/stockingpoint/${stockingPointId}/subunitpartqunatities/${subUnitId}`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched quantities for subunit=${subUnitId}`)),
      catchError(this.handleError(`getPartQuantitiesForSubUnit id=${subUnitId}`))
    );
  }

  getFaultyModules(repairCentreId: number): Observable<any> {
    const url = `${this.base_url}/faultymodules/repaircentre/${repairCentreId}`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched faulty modules for repaircentre=${repairCentreId}`)),
      catchError(this.handleError(`getFaultyModules id=${repairCentreId}`))
    );
  }

}
