import * as moment from "moment"

export default class TimeZone {

    private static now = moment();

    constructor() {}

    static getFormatedTimezone() {
      if (localStorage.getItem('timezone')) {
        this.now = this.now.utcOffset(localStorage.getItem('timezone'));
          }

      return this.now;
    }

  static getFormatedTimezoneFormated() {
    this.now = moment();
      // TimeZone.getFormatedTimezone();
    return this.now.format('ddd, MMM Do, HH:mm:ss');
    }

  static getFormatedTimezoneFromDate(date) {
    this.now = moment.utc(date, 'DD-MM-YYYY HH:mm:ss').local();
    // TimeZone.getFormatedTimezone();
    return this.now.format('DD-MM-YYYY HH:mm:ss');
  }

  static getFormattedTimezoneFromDate(date) {
    this.now = moment.utc(date, 'YYYY-MM-DD HH:mm:ss').local();
    // TimeZone.getFormatedTimezone();
    return this.now.format('DD-MM-YYYY HH:mm:ss');
  }

  static getDateFromDateTime(date) {
    this.now = moment.utc(date, 'YYYY-MM-DD HH:mm:ss').local();
    return this.now.format('YYYY-MM-DD');
  }

  static convertUnixToTimestamp(date: number){
    return moment(date).format('DD-MM-YYYY HH:mm:ss');
  }
}
