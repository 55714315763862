import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getEligibilityDefinitionStore = createFeatureSelector('eligibilityDefinition');

export const getEligibilityDefinitionEntities = createSelector(
  getEligibilityDefinitionStore,
  fromReducers.eligibilityDefinition.eligibilityDefinitionEntitySelectors.selectAll
);

export const getEligibilityDefinitions = createSelector(getEligibilityDefinitionEntities, entities => {
  return Object.values(entities);
});

export const getTopEligibilityDefinitions = createSelector(getEligibilityDefinitionEntities, entities => {
  return Object.values(entities).slice(1, 5);
});

export const getEligibilityDefinitionPaging = createSelector(
  getEligibilityDefinitionStore,
  (eligibilityDefinitionStore: fromReducers.eligibilityDefinition.State) => eligibilityDefinitionStore.paging
);

export const getEligibilityDefinitionsLoaded = createSelector(
  getEligibilityDefinitionStore,
  (eligibilityDefinitionStore: fromReducers.eligibilityDefinition.State) => eligibilityDefinitionStore.loaded
);

export const getEligibilityDefinitionsLoading = createSelector(
  getEligibilityDefinitionStore,
  (eligibilityDefinitionStore: fromReducers.eligibilityDefinition.State) => eligibilityDefinitionStore.loading
);

export const getSelectedEligibilityDefinitionId = createSelector(
  getEligibilityDefinitionStore,
  (eligibilityDefinitionStore: fromReducers.eligibilityDefinition.State) => eligibilityDefinitionStore.selectedEligibilityDefinitionId
);

export const getSearchEligibilityDefinitions = createSelector(
  getEligibilityDefinitionStore,
  (eligibilityDefinitionStore: fromReducers.eligibilityDefinition.State) => eligibilityDefinitionStore.searchEligibilityDefinitions
);

export const getEligibilityDefinitionById = createSelector(
  getEligibilityDefinitionEntities,
  getSelectedEligibilityDefinitionId,
  (entities, id) => entities.find(i => i.id === id)
);

export const getEligibilityDefinitionsError = createSelector(
  getEligibilityDefinitionStore,
  (eligibilityDefinitionStore: fromReducers.eligibilityDefinition.State) => eligibilityDefinitionStore.error
);


