import {ChangeDetectorRef, Component, TemplateRef, ViewChild} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {ToastrService} from "ngx-toastr";
import {ActivatedRoute, Router} from "@angular/router";
import {ProjectService} from "@appServices/core-ticketing/project-service";
import {TicketingStatusService} from "@appServices/core-ticketing/ticketing-status.service";
import {WorkflowService} from "@appServices/core-ticketing/workflow-service";
import {TicketCustomFieldService} from "@appServices/core-ticketing/ticket-custom-field-service";
import {TicketWorkflow} from "@appModels/ticketing/ticket.workflow";
import {ProjectListRow} from "@appModels/ticketing/project.list.row";
import {TicketStatus} from "@appModels/ticketing/ticket.status";
import {LibraryComponent} from "@appContainers/ticketing/components/projects/library-component/library-component";
import {CustomFieldType} from "@appModels/ticketing/custom-field-type";
import {TicketStatusTransition} from "@appModels/ticketing/ticket.status.transition";
import {CustomField} from "@appModels/ticketing/custom-field";
import {TicketTypeService} from "@appServices/core-ticketing/ticket-type.service";
import {BasePojo} from "@appModels/ticketing/base-pojo";
import {environment} from "../../../../../../environments/environment";
import {CustomFieldListRow} from "@appModels/ticketing/custom-field-list-row";
import {PermissionResolver} from "@appServices/permission-resolver/permission-resolver.service";

@Component({
  moduleId: module.id,
  templateUrl: './update-workflow-component.html',
  animations: [
    trigger('toggleAnimation', [
      transition(':enter', [style({ opacity: 0, transform: 'scale(0.95)' }), animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' }))]),
      transition(':leave', [animate('75ms', style({ opacity: 0, transform: 'scale(0.95)' }))]),
    ]),
  ],
  styleUrls: ['./update-workflow-component.css', '../../../tailwind.css']
})
export class UpdateWorkflowComponent {

  @ViewChild(LibraryComponent) graphComponent: LibraryComponent;

  @ViewChild('newCustomFieldModal') public newCustomFieldModal;
  @ViewChild('updateCustomFieldModal') public updateCustomFieldModal;
  @ViewChild('updateStatusModal') public updateStatusModal;


  constructor(
    private toastrService: ToastrService,
    private ticketingStatusService: TicketingStatusService,
    private modalService: BsModalService,
    private ref: ChangeDetectorRef,
    private workflowService: WorkflowService,
    private customFieldService : TicketCustomFieldService,
    private projectService: ProjectService,
    private router: Router,
    private ticketTypeService: TicketTypeService,
    private route:ActivatedRoute,
    private permissionResolverService: PermissionResolver,
  ) {

    this.workflowId = +this.route.snapshot.paramMap.get('id');
    this.workflowService.getWorkflowDetails(this.workflowId).subscribe(x => {
      this.workflow = x;
      this.workflow.statusesToRemove = [];
      this.workflow.statusesToAdd = [];
      this.workflow.statusesToUpdate = [];
      this.workflow.customFieldsToUpdate = [];
      this.workflow.customFieldsToAdd = [];
      this.workflow.customFieldsToRemove = [];




      let transitions = [];
      for (let i = 0; i < this.workflow.statuses.length; i++) {
        for (let j = 0; j < this.workflow.statuses.length; j++) {
          if (this.workflow.statuses[i].id == this.workflow.statuses[j].id) {
            continue;
          }

          let enabled = false;
          enabled = this.workflow.transitions.some(x => x.fromId == this.workflow.statuses[i].id && x.toId == this.workflow.statuses[j].id)

          let firstTransition : TicketStatusTransition = {
            fromId: this.workflow.statuses[i].id,
            fromName: this.workflow.statuses[i].name,
            fromColorCode: this.workflow.statuses[i].colorCode,
            fromTextColorCode: this.workflow.statuses[i].textColorCode,
            toId: this.workflow.statuses[j].id,
            toName:this.workflow.statuses[j].name,
            toColorCode:this.workflow.statuses[j].colorCode,
            toTextColorCode:this.workflow.statuses[j].textColorCode,
            enabled : enabled
          }
          transitions.push(firstTransition);

        }
      }
      this.workflow.transitions = transitions;
      this.workflow.transitions.sort((a, b) => a.fromName.localeCompare(b.fromName));




        console.log("node list")
      console.log(this.nodeList)
      this.mainLoading = false;

      this.ref.detectChanges();


      let listOfNodes = JSON.parse(x.diagram).nodeDataArray;
      let listOfLinks = JSON.parse(x.diagram).linkDataArray;

      listOfNodes.forEach(x => {
        console.log("adding node ")
        console.log(x);
        this.graphComponent.addNewNode(x)
      });
      listOfLinks.forEach(x => this.graphComponent.addLink(x));



    })

    this.projectService.getProjects(true).subscribe(x => {
      this.projects = x;
    })
    this.ticketTypeService.getTicketTypes(true).subscribe(x => {
      this.ticketTypes = x;
    })

    this.customFieldService.getCustomFieldsListRow().subscribe(x => {
      this.customFields = x;
    })
    this.ticketingStatusService.getStatuses().subscribe(x => {
      this.statuses = x;
    })
  }


  workflowId:number = null;
  projects : ProjectListRow[] = []

  statuses : BasePojo[] = [];
  ticketTypes: BasePojo[] = [];
  customFields: CustomFieldListRow[] = [];


  workflow : TicketWorkflow = {
    name:null,
    statuses:[],
    transitions:[],
    customFieldList:[],
    statusesToAdd:[],
    statusesToRemove:[],
    statusesToUpdate:[],
    ticketTypeId:null

  }

  customFieldTypes : CustomFieldType[] = [
    {
      id:1,
      name:"Text"
    },
    {
      id:2,
      name:"Date"
    },
    {
      id:3,
      name:"Select Option"
    }

  ]

  predefinedLocationsList = [
    {"loc":"190 15", "text":"Shopping"},
    { "loc":"353 32", "text":"Browse Items"},
    {"id":2, "loc":"353 166", "text":"Search Items"},
    {"id":3, "loc":"512 12", "text":"View Item"},
    {"id":4, "loc":"661 17", "text":"View Cart"},
    {"id":5, "loc":"644 171", "text":"Update Cart"},
    {"id":6, "loc":"800 96", "text":"Checkout"},
    {"id":-2, "loc":"757 229", "category":"End"}
  ]

  mainLoading = false;

  selectedWorkflow: TicketWorkflow = {name:"", statuses : [], transitions : []};

  newStatus = {
    id:0,
    name:"New Status",
    colorCode:"#FDD8BB",
    textColorCode:'#FA8224'
  }

  changeLink(from:number, to:number,  value: boolean) {


    console.log("changing link from " + from + " to " + to + " checked " + value)

    if (value) {
      this.graphComponent.addLink({from: from, to: to, "text": "New Link Text"});
    }
    else {
      this.graphComponent.removeLink(from, to);
    }

  }

  loadingAddStatus = false;
  addStatusToWorkflow(workflow: TicketWorkflow){

    if (this.newStatus.name == null || this.newStatus.name.length < 3) {
      this.toastrService.error("Please enter a longer status name");
      return;
    }

    this.loadingAddStatus = true;

    //create status and get it's id. This status will then be linked to the workflow when it's saved
    this.ticketingStatusService.createStatus(this.newStatus).subscribe(x => {
      this.newStatus.id = x.id;
      this.toastrService.success("New Status Added");
      this.loadingAddStatus = false;


      workflow.statusesToAdd.push({id:x.id});

      workflow.statuses.push(
        JSON.parse(JSON.stringify(this.newStatus))
      );

      for (let i = 0 ; i< workflow.statuses.length; i++) {

        //dont create associations between the same statuses
        if (workflow.statuses[i].name == this.newStatus.name) {
          continue;
        }

        let firstTransition : TicketStatusTransition = {
          fromId: workflow.statuses[i].id,
          fromName: workflow.statuses[i].name,
          fromColorCode: workflow.statuses[i].colorCode,
          fromTextColorCode: workflow.statuses[i].textColorCode,
          toId: this.newStatus.id,
          toName:this.newStatus.name,
          toColorCode:this.newStatus.colorCode,
          toTextColorCode:this.newStatus.textColorCode,
          enabled : false
        }
        workflow.transitions.push(firstTransition);

        let secondSTransition : TicketStatusTransition = {
          fromId: this.newStatus.id,
          fromName: this.newStatus.name,
          fromColorCode: this.newStatus.colorCode,
          fromTextColorCode: this.newStatus.textColorCode,
          toId: workflow.statuses[i].id,
          toName: workflow.statuses[i].name,
          toColorCode: workflow.statuses[i].colorCode,
          toTextColorCode: workflow.statuses[i].textColorCode,
          enabled : false
        }
        workflow.transitions.push(secondSTransition);
      }

      workflow.transitions.sort((a, b) => a.fromName.localeCompare(b.fromName));

      const randHi = Math.floor(Math.random() * 1200) + 10;
      const randLo = Math.floor(Math.random() * 1200) + 10;


      let nodeData =  {"id":this.newStatus.id, "loc":"" + randHi + " " + randLo, "text":this.newStatus.name, "color":this.newStatus.colorCode}

      this.graphComponent.addNewNode(nodeData);

      this.newStatus.name = "New Status";
      this.newStatus.id = 0;
      this.newStatus.colorCode="#FDD8BB";
      this.newStatus.textColorCode="#FA8224"



    })


  }


  editing = false;



  showingChart = false;
  add() {
    this.showingChart = true;

    this.ref.detectChanges();
  }

  nodeList = [
  ];
  linkList = [
  ];

  removeStatus(status: TicketStatus){

    this.ticketingStatusService.validateDelete(this.workflow.id, status.id).subscribe(x => {
      if (x.code == 200) {

        this.workflow.statusesToRemove.push({
          id:status.id
        });


        this.workflow.statuses = this.workflow.statuses.filter(x => x.name != status.name);
        console.log("removing status " + status.name);
        this.graphComponent.removeNode(status.id);



        this.workflow.transitions = this.workflow.transitions.filter(x=> x.fromId != status.id && x.toId != status.id);

      }

      else {
        this.toastrService.error(x.message);
      }
    })


  }


  loadingCreate = false;

  setDiagramJson(jsonDiagram:string) {

    // MIND THIS
    //this.workflow.diagram = JSON.parse(jsonDiagram);
    this.workflow.diagram = jsonDiagram;

  }
  updateWorkflow() {


    if (this.workflow.name == null || this.workflow.name == undefined || this.workflow.name.length < 3) {
      this.toastrService.error("Please enter a longer workflow name");
      return;
    }

    if (this.workflow.statuses == null || this.workflow.statuses == undefined || this.workflow.statuses.length == 0) {
      this.toastrService.error("Please add at least one status");
      return;
    }


    let numberOfDefault = this.workflow.statuses.filter(x => x.defaultStatus).length;
    if (numberOfDefault == 0) {
      this.toastrService.error("Please mark one status as default");
      return;
    }
    if (numberOfDefault > 1) {
      this.toastrService.error("You have selected multiple default statuses, please choose just one");
      return;
    }

    this.loadingCreate = true;
    this.graphComponent.outputDiagramJson();
    console.log(this.workflow)
    //this.workflow.graph = this.graphComponent.

    this.ticketTypeService.validateNewWorkflow(this.workflow.ticketTypeId, this.workflowId)

    this.workflowService.updateWorkflow(this.workflow, true).subscribe(x => {

      this.loadingCreate = false;
      this.toastrService.success("Workflow Updated!");
      this.router.navigateByUrl(`/ticketing/workflows`);
    })
  }






  selectedCustomField : CustomField = {
    id:null,
    customFieldOptions : [],
    customFieldTypeId : 1,
    mandatory:false
  }

  newCustomField : CustomField = {
    id:null,
    customFieldOptions : [],
    customFieldTypeId : 1,
    mandatory:false
  }

  newFieldOption? :string = null;

  addOptionToField(customField: CustomField) {
    if (this.newFieldOption == null || this.newFieldOption == undefined || this.newFieldOption.length < 2) {
      this.toastrService.error("Please enter a longer name")
      return;
    }
    customField.customFieldOptions.push({id:0, value:this.newFieldOption});
    this.newFieldOption = null;
  }



  removeCustomField(customField: CustomField) {


    this.workflow.customFieldList = this.workflow.customFieldList.filter(x => x.id != customField.id);
  }


  updatingFieldName = ""


  markStatusAsDefault(status : TicketStatus) {}




  selectedStatus : TicketStatus = {
    id:null,
    name: null,
    colorCode: null,
    textColorCode:null,
    defaultStatus:false
  }


  selectStatus() {
    if (!this.workflow.statuses.some(x => x.id == this.selectedStatus.id)) {


      this.workflow.statuses.push(
        JSON.parse(JSON.stringify(this.selectedStatus))
      );

      let newTransitionArray = [];
      for (let i = 0 ; i< this.workflow.statuses.length; i++) {

        //dont create associations between the same statuses
        if (this.workflow.statuses[i].name == this.selectedStatus.name) {
          continue;
        }

        let firstTransition : TicketStatusTransition = {
          fromId: this.workflow.statuses[i].id,
          fromName: this.workflow.statuses[i].name,
          fromColorCode: this.workflow.statuses[i].colorCode,
          fromTextColorCode: this.workflow.statuses[i].textColorCode,
          toId: this.selectedStatus.id,
          toName:this.selectedStatus.name,
          toColorCode:this.selectedStatus.colorCode,
          toTextColorCode:this.selectedStatus.textColorCode,
          enabled : false
        }
        this.workflow.transitions.push(firstTransition);

        let secondSTransition : TicketStatusTransition = {
          fromId: this.selectedStatus.id,
          fromName: this.selectedStatus.name,
          fromColorCode: this.selectedStatus.colorCode,
          fromTextColorCode: this.selectedStatus.textColorCode,
          toId: this.workflow.statuses[i].id,
          toName: this.workflow.statuses[i].name,
          toColorCode: this.workflow.statuses[i].colorCode,
          toTextColorCode: this.workflow.statuses[i].textColorCode,
          enabled : false
        }
        this.workflow.transitions.push(secondSTransition);
      }

      this.workflow.transitions.sort((a, b) => a.fromName.localeCompare(b.fromName));

      //let loc :go.Point =null;
      let loc = "";

      loc = "190 15";


      let nodeData =  {"id":this.selectedStatus.id, "loc": loc, "text":this.selectedStatus.name, "color":this.selectedStatus.colorCode}

      this.graphComponent.addNewNode(nodeData);


    }
  }

  can(action) {
    return this.permissionResolverService.setModuleName("Ticketing").can(action);
  }

  goToCreateNewStatusPage() {
    const url = `${environment.base_web_url}/ticketing/ticketstatuses/newTicketStatus`;
    window.open(url, '_blank');
  }

  refreshStatusList() {
    this.ticketingStatusService.getStatuses().subscribe(x => {
      this.statuses = x;
      this.toastrService.success("Ticket Status List Refreshed");
    })
  }

  selectCustomField() {
    if (!this.workflow.customFieldList.some(x => x.id == this.selectedCustomField)) {
      for(let i = 0; i< this.customFields.length; i++) {
        if (this.customFields[i].id == this.selectedCustomField) {
          console.log(this.customFields[i] )
          this.workflow.customFieldList.push(JSON.parse(JSON.stringify(this.customFields[i])))
        }
      }
    }
    this.selectedCustomField = null;
  }

  refreshCustomFieldsList() {
    this.customFieldService.getCustomFieldsListRow().subscribe(x => {
      this.customFields = x;
      this.toastrService.success("Custom Fields List Refreshed");
    })
  }

  goToCreateNewCustomFieldPage() {
    const url = `${environment.base_web_url}/ticketing/newCustomField`;
    window.open(url, '_blank');
  }

}
