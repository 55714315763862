import { Action } from '@ngrx/store';
import {
  EntityAdapter,
  createEntityAdapter,
  EntityState,
  Update
} from '@ngrx/entity';
import * as _ from 'lodash';

import { Tenant } from '@appModels/core-setup/tenant/tenant';
import { TenantActions, TenantActionTypes } from '@appStore/actions/core-masterdata/tenant/tenant.actions';

export interface State extends EntityState<Tenant> {
  loaded: boolean;
  loading: boolean;
  error: any;
  selectedTenantId: number;
  searchTenants: Tenant[];
  paging: any;
}

export const adapter: EntityAdapter<Tenant> = createEntityAdapter<Tenant>();

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
  selectedTenantId: null,
  error: null,
  searchTerm: '',
  searchTenants: null,
  paging: null
});

export function reducer(state = initialState, action: TenantActions): State {
  switch (action.type) {
    case TenantActionTypes.tenantGetTenants:
    case TenantActionTypes.tenantAddTenant:
    case TenantActionTypes.tenantAddTenantAdmin:
    case TenantActionTypes.tenantDeleteTenant:
    case TenantActionTypes.tenantUpdateTenant:
    case TenantActionTypes.tenantEditTenant:
    case TenantActionTypes.tenantSearchTenants:
    case TenantActionTypes.tenantGetTenantById:
      return {
        ...state,
        loading: true
      };

    case TenantActionTypes.tenantGetTenantsSuccess:
      return adapter.setAll(action.payload.content, {
        ...state,
        loading: false,
        loaded: true,
        paging: _.omit(action.payload, ['content'])
      });

    case TenantActionTypes.tenantGetTenantByIdSuccess:
      return { ...state, selectedTenantId: action.payload.id, loading: false };

    case TenantActionTypes.tenantAddTenantSuccess:
      return adapter.addOne(action.payload, {
        ...state,
        selectedTenantId: action.payload.id,
        loading: false,
        loaded: true
      });

    case TenantActionTypes.tenantUpdateTenantSuccess: {
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state,
          loading: false,
          loaded: true
        }
      );
    }
    case TenantActionTypes.tenantEditTenantSuccess: {
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state,
          loading: false,
          loaded: true
        }
      );
    }

    case TenantActionTypes.tenantDeleteTenantSuccess: {
      return adapter.removeOne(action.payload.id, {
        ...state,
        loading: false,
        loaded: true
      });
    }

    case TenantActionTypes.tenantSearchTenantsSuccess:
      return {
        ...state,
        searchTenants: action.payload,
        loading: false
      };

    case TenantActionTypes.tenantSearchTenantsReset:
      return {
        ...state,
        searchTenants: null
      };

    case TenantActionTypes.tenantError:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload
      };

    default:
      return state;
  }
}

export const tenantEntitySelectors = adapter.getSelectors();
