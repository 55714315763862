
<div class="flex flex-row justify-between my-8 mx-4">
  <div class="flex flex-row justify-start  gap-3" >
    <div class="text-xl text-black-dark-light">
      Home
    </div>
    <div class="text-xl font-black text-black-dark-light">
      /
    </div>
    <div class="text-xl text-black-dark-light text-orange-600">
      Ticket Statuses
    </div>
  </div>


  <button class="btn btn-dlight flex-grow-0 gap-3" routerLink="/ticketing/ticketstatuses/newTicketStatus" >
    New Ticket Status
    <svg  xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 0.6 0.6">
      <path opacity=".4" d="M.3.569A.02.02 0 0 1 .281.55V.5Q.283.483.3.481C.317.479.319.49.319.5v.05A.02.02 0 0 1 .3.569m0-.1A.02.02 0 0 1 .281.45V.4Q.283.383.3.381C.317.379.319.39.319.4v.05A.02.02 0 0 1 .3.469" fill="#292D32"/><path d="M.3.369A.02.02 0 0 1 .281.35V.275c0-.107.087-.194.194-.194H.55C.56.081.569.09.569.1S.56.119.55.119H.475a.156.156 0 0 0-.156.156V.35A.02.02 0 0 1 .3.369" fill="currentColor"/><path d="M.3.369A.02.02 0 0 1 .281.35V.275A.156.156 0 0 0 .125.119H.05Q.033.117.031.1C.029.083.04.081.05.081h.075c.107 0 .194.087.194.194V.35A.02.02 0 0 1 .3.369" fill="currentColor"/><path d="M.1.169A.02.02 0 0 1 .087.163l-.05-.05a.02.02 0 0 1 0-.027l.05-.05c.007-.007.019-.007.027 0s.007.019 0 .027L.077.1l.037.037c.007.007.007.019 0 .027A.02.02 0 0 1 .101.17M.5.169A.02.02 0 0 1 .487.163a.02.02 0 0 1 0-.027L.524.1.487.063a.02.02 0 0 1 0-.027c.007-.007.019-.007.027 0l.05.05c.007.007.007.019 0 .027l-.05.05a.02.02 0 0 1-.013.006" fill="currentColor"/>
    </svg>
  </button>

</div>

<div class="bg-white ticket-table mt-10" style="border-radius:20px">
  <div class="grid grid-cols-4 py-3 ticket-row">
    <div class="col-span-1 my-auto pl-8">
      NAME
    </div>
    <div class="col-span-1 my-auto">
      DATE
    </div>
    <div class="col-span-1 my-auto">
      COLOR
    </div>
    <div class="col-span-1 my-auto">
      ACTIONS
    </div>
  </div>

  <div class="mt-5 mb-5" *ngIf="loadingTable">
    <div class="flex flex-row justify-center w-full  gap-4">
      <span class="m-auto mb-10 inline-block h-14 w-14 animate-spin rounded-full border-8 border-[#f1f2f3] border-l-dlight align-middle"></span>
    </div>
  </div>
  <ng-container *ngIf="!loadingTable">
    <div  class="grid grid-cols-4 py-3 ticket-row cursor-pointer" *ngFor="let status of ticketStatuses"  >
      <div class="col-span-1 my-auto pl-8 text-black">
        {{status.name}}
      </div>

      <div class="col-span-1 my-auto text-black">
        <div>
          {{ moment(status.date).format('DD MMMM YYYY') }}
        </div>
      </div>

      <div class="col-span-1 my-auto text-black">
        <span class="badge bg-warning rounded-full text-white" [ngStyle]="{'background-color': status.colorCode, 'color':status.textColorCode}">{{status.name}}</span>
      </div>

      <div class="col-span-1 my-auto">
        <button  [routerLink]="'/ticketing/ticketStatus/' + status.id" type="button" class="btn btn-sm rounded-full btn-outline-dlight "><svg _ngcontent-ydi-c23="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="w-5 h-5 ltr:mr-1.5 rtl:ml-1.5 ng-tns-c23-15"><circle _ngcontent-ydi-c23="" cx="12" cy="12" r="3" class="ng-tns-c23-15"></circle><path _ngcontent-ydi-c23="" d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z" class="ng-tns-c23-15"></path></svg>&nbsp;&nbsp;Edit </button>
      </div>


    </div>
  </ng-container>


</div>

