import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

import {
  AddCommission,
  AddCommissionSuccess,
  CommissionActionTypes,
  CommissionsError,
  DeleteCommission,
  DeleteCommissionSuccess,
  GetCommissionById,
  GetCommissionByIdSuccess,
  GetCommissions,
  GetCommissionsByTenantId,
  GetCommissionsByTenantIdIdSuccess,
  SearchCommissions,
  SearchCommissionsSuccess,
  UpdateCommission,
  UpdateCommissionSuccess
} from '@appStore/actions/core-fieldforce/commission/commission.actions';
import {TenantService} from '@appServices/core-masterdata/tenant/tenant.service';

import * as fromRouterActions from '@appStore/actions/router.actions';
import {ToastrService} from 'ngx-toastr';
import {TenantCommissionService} from "../../../../services/core-incentivemanagement/commission/tenant-commission.service";
import {CommissionService} from "../../../../services/core-incentivemanagement/commission/commission.service";

@Injectable()
export class CommissionEffects {
  
  loadCommissions$ = createEffect(() => this.actions$.pipe(
    ofType(CommissionActionTypes.commissionGetCommissionsByTenantId),
    mergeMap((action: GetCommissionsByTenantId) =>
      this.tenantCommissionService
        .getTenantCommissions(action.payload.id, action.payload.query)
        .pipe(
          map(data => new GetCommissionsByTenantIdIdSuccess(data)),
          catchError(error => of(new CommissionsError(error)))
        )
    )
  ));
  
  addCommission = createEffect(() => this.actions$.pipe(
    ofType(CommissionActionTypes.commissionAddCommission),
    switchMap((action: AddCommission) =>
      this.tenantCommissionService
        .addCommission(action.payload)
        .pipe(
          map(
            stockingPoint => {
              if (stockingPoint !== undefined) {
                this.toastr.success('Commission has been successfully added!', 'Successful!');
                return new AddCommissionSuccess(action.payload)
              }
              this.toastr.error('There was an error creating commission!', "Unknown error");
              return new CommissionsError({type: 500, message: 'Internal error'})
            }
          ),
          catchError(error => of(new CommissionsError(error)))
        )
    )
  ));


  
  getCommissionById$ = createEffect(() => this.actions$.pipe(
    ofType(CommissionActionTypes.commissionGetCommissionById),
    mergeMap((action: GetCommissionById) =>
      this.commissionService
        .getcommission(action.payload)
        .pipe(
          map(commission => new GetCommissionByIdSuccess(commission)),
          catchError(error => of(new CommissionsError(error)))
        )
    )
  ));


  
  updateCommission = createEffect(() => this.actions$.pipe(
    ofType(CommissionActionTypes.commissionUpdateCommission),
    mergeMap((action: UpdateCommission) =>
      this.commissionService
        .updatecommission(action.payload)
        .pipe(
          map(commissionWrapper => {
            if (commissionWrapper != null) {
              this.toastr.success('Commission has been successfully updated!', 'Successful!');
              return new UpdateCommissionSuccess(action.payload);
            } else {
              this.toastr.error('There was an error updating commission!', "Error");
              return new CommissionsError({type: 500, message: 'Internal error'});
            }
          }),
          catchError(error => of(new CommissionsError(error)))
        )
    )
  ));
  
  deleteCommission = createEffect(() => this.actions$.pipe(
    ofType(CommissionActionTypes.commissionDeleteCommission),
    mergeMap((action: DeleteCommission) =>
      this.commissionService
        .deletecommission(action.payload)
        .pipe(
          map(() => new DeleteCommissionSuccess(action.payload)),
          catchError(error => of(new CommissionsError(error)))
        )
    )
  ));
  
  searchCommissions$ = createEffect(() => this.actions$.pipe(
    ofType(CommissionActionTypes.commissionSearchCommissions),
    mergeMap((action: SearchCommissions) =>
      this.commissionService
        .searchcommission(action.payload)
        .pipe(
          map(stockingPoints => new SearchCommissionsSuccess(stockingPoints)),
          catchError(error => of(new CommissionsError(error)))
        )
    )
  ));
  
  updateCommissionSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(CommissionActionTypes.commissionUpdateCommissionSuccess),
    map(stockingPoint => new fromRouterActions.Go({path: ['/fieldforce/incentivemanagement/commission-list']}))
  ));

  constructor(
    private actions$: Actions,
    private commissionService: CommissionService,
    private tenantCommissionService: TenantCommissionService,
    private tenantService: TenantService,
    private toastr: ToastrService
  ) {
  }
}
