import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getTimeIntervalStore = createFeatureSelector('timeInterval');

export const getTimeIntervalEntities = createSelector(
  getTimeIntervalStore,
  fromReducers.timeInterval.timeIntervalEntitySelectors.selectAll
);

export const getTimeIntervals = createSelector(getTimeIntervalEntities, entities => {
  return Object.values(entities);
});

export const getTopTimeIntervals = createSelector(getTimeIntervalEntities, entities => {
  return Object.values(entities).slice(1, 5);
});

export const getTimeIntervalsLoaded = createSelector(
  getTimeIntervalStore,
  (timeIntervalStore: fromReducers.timeInterval.State) => timeIntervalStore.loaded
);

export const getTimeIntervalsLoading = createSelector(
  getTimeIntervalStore,
  (timeIntervalStore: fromReducers.timeInterval.State) => timeIntervalStore.loading
);

export const getSelectedTimeIntervalId = createSelector(
  getTimeIntervalStore,
  (timeIntervalStore: fromReducers.timeInterval.State) => timeIntervalStore.selectedTimeIntervalId
);

export const getSearchTimeIntervals = createSelector(
  getTimeIntervalStore,
  (timeIntervalStore: fromReducers.timeInterval.State) => timeIntervalStore.searchTimeIntervals
);

export const getTimeIntervalById = createSelector(
  getTimeIntervalEntities,
  getSelectedTimeIntervalId,
  (entities, id) => entities.find(i => i.id === id)
);

export const getTimeIntervalsError = createSelector(
  getTimeIntervalStore,
  (timeIntervalStore: fromReducers.timeInterval.State) => timeIntervalStore.error
);
