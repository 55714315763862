import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getCommissionStore = createFeatureSelector('commission');

export const getCommissionEntities = createSelector(
  getCommissionStore,
  fromReducers.commission.commissionEntitySelectors.selectAll
);


export const getCommissions = createSelector(getCommissionEntities, entities => {
  return Object.values(entities);
});


export const getCommissionsLoaded = createSelector(
  getCommissionStore,
  (commissionStore: fromReducers.commission.State) => commissionStore.loaded
);

export const getCommissionsLoading = createSelector(
  getCommissionStore,
  (commissionStore: fromReducers.commission.State) => commissionStore.loading
);

export const getCommissionsPaging = createSelector(
  getCommissionStore,
  (commissionStore: fromReducers.commission.State) => commissionStore.paging
);

export const getSelectedCommissionId = createSelector(
  getCommissionStore,
  (commissionStore: fromReducers.commission.State) => commissionStore.selectedCommissionId
);

export const getSearchCommissions = createSelector(
  getCommissionStore,
  (commissionStore: fromReducers.commission.State) => commissionStore.searchCommissions
);

export const getCommissionByIds = createSelector(
  getCommissionEntities,
  getSelectedCommissionId,
  (entities, id) => entities.find(i => i.id === id)
);

export const getCommissionById = createSelector(
  getCommissionStore,
  (commissionStore: fromReducers.commission.State) => commissionStore.selectedCommission

);

export const getCommissionError = createSelector(
  getCommissionStore,
  (commissionStore: fromReducers.commission.State) => commissionStore.error
);

export const getCommissionPaging = createSelector(
  getCommissionStore,
  (commissionStore: fromReducers.commission.State) => commissionStore.paging
);
