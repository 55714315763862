import {Action} from '@ngrx/store';

import {InventoryProduct} from "@appModels/core-inventory/inventory-product/inventory-product";

export enum StockingPointInventoryProductActionTypes {
  stockingPointGetStockingPointInventoryProducts = '[StockingPointInventoryProduct] get',
  stockingPointGetStockingPointInventoryProductsSuccess = '[StockingPointInventoryProduct] get StockingPointInventoryProduct success',
  stockingPointError = '[StockingPointInventoryProduct] error'
}

export class GetStockingPointInventoryProductsByStockingPointId implements Action {
  readonly type = StockingPointInventoryProductActionTypes.stockingPointGetStockingPointInventoryProducts;

  constructor(public payload: number) {
  }
}

export class GetStockingPointInventoryProductsByStockingPointIdSuccess implements Action {
  readonly type = StockingPointInventoryProductActionTypes.stockingPointGetStockingPointInventoryProductsSuccess;

  constructor(public payload: InventoryProduct[]) {
  }
}


export class StockingPointInventoryProductError implements Action {
  readonly type = StockingPointInventoryProductActionTypes.stockingPointError;

  constructor(public payload: any) {
  }
}

export type StockingPointInventoryProductActions =
  | GetStockingPointInventoryProductsByStockingPointId
  | GetStockingPointInventoryProductsByStockingPointIdSuccess
  | StockingPointInventoryProductError;
