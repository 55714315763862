import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';

import {InventoryProduct} from '@appModels/core-inventory/inventory-product/inventory-product';

import {CoreInventoryService} from '../core-inventory.service';
import {TenantInventoryProductLocationStatus} from '@appModels/core-inventory/inventory-product/tenant-inventory-product-location-status';
import * as moment from 'moment';


@Injectable({providedIn: 'root'})
export class InventoryProductService extends CoreInventoryService {


  constructor(
    private http: HttpClient,
  ) {
    super(http, 'inventoryproduct');
  }

  /** GET inventoryProducts from the server */
  getInventoryProducts(filters: any): Observable<InventoryProduct[]> {
    const url = `${this.base_url}s`;
    const params = {};
    if (filters !== '') {
      if (filters.searchParam != null) {
        params['search'] = filters.searchParam;
      }
      if (filters.startPage != null) {
        params['page'] = Number(filters.startPage);
      }
      if (filters.numberPerPage != null) {
        params['size'] = Number(filters.numberPerPage);
      }
      if (filters.accountNumber != null) {
        params['accountNumber'] = filters.accountNumber;
      }
      if (filters.serialNumber != null) {
        params['serialNumber'] = filters.serialNumber;
      }
    }

    return this.http.get<InventoryProduct[]>(url, {headers: this.headers, params: params}).pipe(
      tap(inventoryProducts => this.log(`fetched inventoryProducts`)),
      catchError(this.handleError('getInventoryProducts', []))
    );
  }

  /** GET stocking point inventoryProducts from the server */
  getStockingPointInventoryProducts(data): Observable<any> {
    let apiCall = `${this.base_url}/stockingpoint/${data.stockingPointId}/globalproduct/${data.parentGlobalProductId}/accountnumberserial/${data.accountNumberSerial}`;
    if (data.salesAgentId && data.salesAgentId != null) {
      apiCall += `?salesAgentId=${data.salesAgentId}`;
    }
    return this.http.get(apiCall, {headers: this.headers}).pipe(
      tap(inventoryProducts => this.log(`fetched inventoryProducts`))
    );
  }

  /** GET inventoryProduct by id. Return `undefined` when id not found */
  getInventoryProductNo404<Data>(id: number): Observable<InventoryProduct> {
    const url = `${this.base_url}/?id=${id}`;
    return this.http.get<InventoryProduct[]>(url).pipe(
      map(inventoryProducts => inventoryProducts[0]), // returns a {0|1} element array
      tap(h => {
        const outcome = h ? `fetched` : `did not find`;
        this.log(`${outcome} inventoryProduct id=${id}`);
      }),
      catchError(this.handleError<InventoryProduct>(`getInventoryProduct id=${id}`))
    );
  }

  /** GET inventoryProduct by id. Will 404 if id not found */
  getInventoryProduct(id: number): Observable<InventoryProduct> {
    const url = `${this.base_url}/${id}`;
    return this.http.get<InventoryProduct>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched inventoryProduct id=${id}`)),
      catchError(this.handleError<InventoryProduct>(`getInventoryProduct id=${id}`))
    );
  }

  /** GET inventoryProductAttribute by id. Will 404 if id not found */
  checkIfProductIsNonSerialized(tenantProductCatalogueId: number): Observable<any> {
    const url = `${this.base_url}attribute/isnonserialized/tenantproductcatalogue/${tenantProductCatalogueId}`;
    return this.http.get<any>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched inventoryProductAttribute id=${tenantProductCatalogueId}`)),
      catchError(this.handleError<any>(`getInventoryProductAttribute id=${tenantProductCatalogueId}`))
    );
  }

  /** GET inventoryProduct children */
  getInventoryProductChildren(id: number): Observable<InventoryProduct[]> {
    const url = `${this.base_url}/${id}/children`;
    return this.http.get<InventoryProduct[]>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched inventoryProduct children id=${id}`)),
      catchError(this.handleError<InventoryProduct[]>(`getInventoryProduct children id=${id}`))
    );
  }

  /** GET inventoryProduct by product id, serial and account number */
  validateInventoryProduct(productVersionId: number, parentId: number, serial: string, accountNumber: string): Observable<InventoryProduct> {
    const url = `${this.base_url}/productversion/${productVersionId}`;
    let params = new HttpParams();
    params = params.append('serial', serial);
    params = params.append('account', accountNumber);
    if (parentId) {
      params = params.append('parentId', parentId.toString());
    }


    return this.http.get<InventoryProduct>(url, {params: params, headers: this.headers}).pipe(
      tap(_ => this.log(`fetched inventoryProduct product id=${productVersionId}`)),
      catchError(this.handleError<InventoryProduct>(`validateInventoryProduct product id=${productVersionId}`))
    );
  }

  /* GET inventoryProducts whose name contains search term */
  searchInventoryProducts(term: string): Observable<InventoryProduct[]> {
    if (!term.trim()) {
      // if not search term, return empty inventoryProduct array.
      return of([]);
    }
    return this.http.get<InventoryProduct[]>(`api/inventoryProducts/?name=${term}`).pipe(
      tap(_ => this.log(`found inventoryProducts matching "${term}"`)),
      catchError(this.handleError<InventoryProduct[]>('searchInventoryProducts', []))
    );
  }

  //////// Save methods //////////

  /** POST: add a new inventoryProduct to the server */
  addInventoryProduct(inventoryProduct: any): Observable<any> {
    const tenantId = localStorage.getItem('tenant');
    return this.http.post<any>(this.base_url + '/tenant/' + tenantId, inventoryProduct, {headers: this.headers}).pipe(
      tap((inventoryProduct: any) => this.log(`added inventoryProduct w/ id=${inventoryProduct.id}`)),
      catchError(this.handleError<any>('addInventoryProduct'))
    );
  }

  /** POST: add a new inventoryProduct to the server */
  addInventoryProductAdditionalInfo(inventoryProduct): Observable<{}> {
    return this.http.post(`${this.base_url}/additionalInfo/${inventoryProduct.id}`, inventoryProduct.value, {headers: this.headers}).pipe(
      tap((res) => this.log(`added inventoryProduct  info `)),
      catchError(this.handleError('addInventoryProduct'))
    );
  }

  /** DELETE: delete the inventoryProduct from the server */
  deleteInventoryProduct(inventoryProduct: InventoryProduct | number): Observable<InventoryProduct> {
    const id = typeof inventoryProduct === 'number' ? inventoryProduct : inventoryProduct.id;
    const url = `${this.base_url}/${id}`;

    return this.http.delete<InventoryProduct>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`deleted inventoryProduct id=${id}`)),
      catchError(this.handleError<InventoryProduct>('deleteInventoryProduct'))
    );
  }

  /** PUT: update the inventoryProduct on the server */
  updateInventoryProduct(inventoryProduct: InventoryProduct): Observable<InventoryProduct> {
    return this.http.put<InventoryProduct>(`${this.base_url}/${inventoryProduct.id}`, inventoryProduct, {headers: this.headers}).pipe(
      tap(_ => this.log(`updated inventoryProduct id=${inventoryProduct.id}`)),
      catchError(this.handleError<InventoryProduct>('updateInventoryProduct'))
    );
  }

  uploadInventoryProduct(inventoryProducts): Observable<{}> {
    this.setHeader(true);
    const tenantId = localStorage.getItem('tenant');
    return this.http.post<{}>(this.base_url + '/tenant/' + tenantId + '/batchupload', inventoryProducts, {headers: this.headers}).pipe(
      tap((user) => this.log(`uploaded inventoryProducts `)),
      catchError(this.handleError<{}>('inventoryProducts'))
    );
  }

  patchProductInventoryProduct(inventoryProduct: InventoryProduct): Observable<InventoryProduct> {
    return this.http.patch<InventoryProduct>(this.base_url + '/' + inventoryProduct.id + '/globalproduct/' + inventoryProduct.parentGlobalProductId, '', {headers: this.headers}).pipe(
      tap((res) => this.log(`patch inventoryProduct w/ inventoryProductId=${inventoryProduct.id}`)),
      catchError(this.handleError<InventoryProduct>('inventoryProduct'))
    );
  }


  getGraphCountGroupAllProductsInTransitToTenantStockingPoint(): Observable<any> {
    const tenantId = localStorage.getItem('tenant');
    const url = `${this.base_url}/tenant/${tenantId}/getGraphCountGroupAllProductsInTransitToTenantStockingPoint`;
    return this.http.get<InventoryProduct[]>(url, {headers: this.headers}).pipe(
      tap(res => this.log(`res`)),
      catchError(this.handleError('res', []))
    );
  }

  getTenantInventoryProductLocationStatus(filters: any): Observable<TenantInventoryProductLocationStatus[]> {
    const tenantId = localStorage.getItem('tenant');
    const url = `${this.base_url}/tenant/${tenantId}/inventoryProductLocationStatusInTenant`;

    const params = {};
    if (filters != '') {

      if (filters.consignmentCreateDate != null) {
        params['consignmentCreateDate'] = moment(filters.consignmentCreateDate).format('YYYY-MM-DD');
      }


      if (filters.searchParam != null) {
        params['searchParam'] = filters.searchParam;
      }

      if (filters.location != null) {
        params['location'] = filters.location;
      }

      if (filters.inventoryStatus != null) {
        params['status'] = filters.inventoryStatus;
      }

      if (filters.productType != null) {
        params['productName'] = filters.productType;
      }

      if (filters.consignmentStatus != null) {
        params['consignmentStatus'] = filters.consignmentStatus;
      }

      if (filters.physicalStatus != null) {
        params['physicalStatus'] = filters.physicalStatus;
      }

      if (filters.startPage != null) {
        params['startPage'] = Number(filters.startPage);
      }
      if (filters.numberPerPage != null) {
        params['numberPerPage'] = Number(filters.numberPerPage);
      }
    }
    return this.http.get<TenantInventoryProductLocationStatus[]>(url, {headers: this.headers, params: params}).pipe(
      tap(inventoryProducts => this.log(`fetched tenant inventoryProducts`)),
      catchError(this.handleError('tenant inventoryProducts', []))
    );
  }


  getInventoryProductsChildren(inventoryProductId: number): Observable<any> {
    const tenantId = localStorage.getItem('tenant');
    const url = `${this.base_url}/${inventoryProductId}/tenant/${tenantId}/getchildren`;
    return this.http.get<any>(url, {headers: this.headers}).pipe(
      tap(inventoryProducts => this.log(`fetched inventoryProducts`)),
      catchError(this.handleError('getInventoryProducts', []))
    );
  }

  getInventoryProductAttributes(serial: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('serial', serial);
    const url = `${this.base_url}/attributes`;
    return this.http.get<any>(url, {params: params, headers: this.headers}).pipe(
      tap(inventoryProducts => this.log(`fetched inventoryProduct attributes`)),
      catchError(this.handleError('getInventoryProductAttributes', []))
    );
  }

  saveInventoryProductAttributes(serial: string, attributeName: string, attributeValue: string): Observable<any> {
    const url = `${this.base_url}/` + serial + '/attributes/' + attributeName;
    return this.http.post<any>(url, attributeValue, {headers: this.headers}).pipe(
      tap(inventoryProducts => this.log(`fetched inventoryProducts`)),
      catchError(this.handleError('getInventoryProducts', []))
    );
  }

  saveInventoryProductAttributesFromWO(serial: string, attributeName: string, attributeValue: string, workOrderId): Observable<any> {
    const url = `${this.base_url}/` + serial + '/attributes/' + attributeName + '/workorder/' + workOrderId;
    return this.http.post<any>(url, attributeValue, {headers: this.headers}).pipe(
      tap(inventoryProducts => this.log(`saved inventoryProduct attributes`)),
      catchError(this.handleError('saveInventoryProductAttributesFromWO', []))
    );
  }

  /** GET consignment by id. Will 404 if id not found */
  getLevel3AndLevel4InventoryProducts(): Observable<any[]> {
    const tenantId = localStorage.getItem('tenant');
    const url = `${this.base_url}/getLevel3AndLevel4GlobalProductVersion/tenant/${tenantId}`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap((res: any) => this.log(`level 3 and 4 global product version`)),
      catchError(this.handleError<any>('level 3 and 4 global product version`'))
    );
  }

  getAllProductNamesByTenantId(): Observable<any[]> {
    const tenantId = localStorage.getItem('tenant');
    const url = `${this.base_url}/getAllProductNamesByTenantId/${tenantId}`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap((res: any) => this.log(`Get all product names by tenantId`)),
      catchError(this.handleError<any>(`Get all product names by tenantId`))
    );
  }


  getByAccountNumberOrSerial(accountOrSerial): Observable<any[]> {
    const url = `${this.base_url}/accountorserial/${accountOrSerial}`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap((res: any) => this.log(`Get by account number or serial`)),
      catchError(this.handleError<any>(`Get by account number or serial`))
    );
  }

  breakDownToSubUnits(inventoryProductId): Observable<any[]> {
    const url = `${this.base_url}/${inventoryProductId}/breakdowntosubunits`;
    return this.http.post(url, '', {headers: this.headers}).pipe(
      tap((res: any) => this.log(`Break Down To Subunits`)),
      catchError(this.handleError<any>(`Break Down To Subunits`))
    );
  }

  /** GET stocking point inventoryProducts from the server */
  getInventoryProductByContractProduct(contractProductId: number): Observable<any> {
    return this.http.get(`${this.base_url}/contractproduct/${contractProductId}`, {headers: this.headers}).pipe(
      tap(inventoryProduct => this.log(`fetched inventoryProduct`)),
      catchError(this.handleError('getInventoryProduct', []))
    );
  }

  getInventoryProductWithAttribute(search: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('search', search);
    return this.http.get(`${this.base_url}/withattributes`, {params: params, headers: this.headers}).pipe(
      tap(inventoryProduct => this.log(`fetched inventoryProduct with attribute`)),
      catchError(this.handleError('getInventoryProductWithAttribute', []))
    );
  }

}
