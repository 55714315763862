import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {WorkOrder} from '../../../../models/core-aftersales/work-order';
import {WorkOrderActions, WorkOrderActionTypes} from '../../../actions/core-aftersales/work-order/workorder.action';
import * as _ from 'lodash';

export interface State extends EntityState<WorkOrder> {
  error: any;
  loaded: boolean;
  loading: boolean;
  selectedWorkOrderId: number;
  selectedWorkOrder: WorkOrder;
  paging: any;
}

export const adapter: EntityAdapter<WorkOrder> = createEntityAdapter<WorkOrder>();

export const initialState: State = adapter.getInitialState({
  error: null,
  loaded: false,
  loading: false,
  selectedWorkOrderId: null,
  selectedWorkOrder: null,
  paging: null
});

export function reducer(state = initialState, action: WorkOrderActions): State {
  switch (action.type) {
    case WorkOrderActionTypes.workOrderAdd:
    case WorkOrderActionTypes.workOrderGetById:
    case WorkOrderActionTypes.workOrderAssignTechnician:
    case WorkOrderActionTypes.workOrderAssignAction:
    case WorkOrderActionTypes.workOrderClose:
    case WorkOrderActionTypes.workOrderCancel:
    case WorkOrderActionTypes.workOrderEscalate:
    case WorkOrderActionTypes.workOrderReplace:
    case WorkOrderActionTypes.workOrderReceive:
    case WorkOrderActionTypes.workOrderReceiveSuccess:
    case WorkOrderActionTypes.workOrderGetAll:
    case WorkOrderActionTypes.workOrderChangeState:
    case WorkOrderActionTypes.workOrderGenerateQuoatation:
    case WorkOrderActionTypes.workOrderInvalidateQuoatation:
    case WorkOrderActionTypes.workOrderRepair:
    case WorkOrderActionTypes.workOrderAddNote:
    case WorkOrderActionTypes.workOrderSaveTestCases:
    case WorkOrderActionTypes.workOrderAddAmountToContract:
    case WorkOrderActionTypes.workOrderAddTokens:
      return {
        ...state,
        loading: true,
        loaded: false
      };

    case WorkOrderActionTypes.workOrderAddSuccess:
      return adapter.addOne(action.payload, {
        ...state,
        selectedWorkOrderId: action.payload.id,
        loading: false,
        loaded: true
      });

    case WorkOrderActionTypes.workOrderError:
      return adapter.addOne(action.payload, {
        ...state,
        error: action.payload.id,
        loading: false,
        loaded: true
      });
    case WorkOrderActionTypes.workOrderGetByIdSuccess:
      return {
        ...state,
        selectedWorkOrderId: action.payload.id,
        selectedWorkOrder: action.payload,
        loading: false
      };
    case WorkOrderActionTypes.workOrderAssignTechnicianSuccess:
    case WorkOrderActionTypes.workOrderAssignActionSuccess:
    case WorkOrderActionTypes.workOrderCloseSuccess:
    case WorkOrderActionTypes.workOrderCancelSuccess:
    case WorkOrderActionTypes.workOrderEscalateSuccess:
    case WorkOrderActionTypes.workOrderReplaceSuccess:
    case WorkOrderActionTypes.workOrderChangeStateSuccess:
    case WorkOrderActionTypes.workOrderGenerateQuoatationSuccess:
    case WorkOrderActionTypes.workOrderInvalidateQuoatationSuccess:
    case WorkOrderActionTypes.workOrderRepairSuccess:
    case WorkOrderActionTypes.workOrderAddNoteSuccess:
    case WorkOrderActionTypes.workOrderSaveTestCasesSuccess:
    case WorkOrderActionTypes.workOrderAddAmountToContractSuccess:
    case WorkOrderActionTypes.workOrderAddTokensSuccess:
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state,
          selectedWorkOrder: action.payload,
          selectedWorkOrderId: action.payload.id,
          loading: false,
          loaded: true
        });
    case WorkOrderActionTypes.workOrderGetAllSuccess:
      return adapter.setAll(action.payload.content, {
        ...state,
        loading: false,
        loaded: true,
        paging: _.omit(action.payload, ['content'])
      });
    default:
      return state;
  }
}

export const workOrderEntitySelectors = adapter.getSelectors();

