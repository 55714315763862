import {Injectable} from '@angular/core';

import {Store, select} from '@ngrx/store';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import * as fromReducer from '@appStore/reducers';
import * as fromSelectors from '@appStore/selectors';
import {LoginRequired} from '@appStore/actions/core-auth/auth/auth.actions';
import {Nav, SubNav} from '@appStore/actions/nav.actions';
import {nav} from '../../config/nav';
import {GetAllRepairCentreByUser} from '../../store/actions/core-aftersales/repaircentres/repaircentres.actions';

export interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}

export const ROUTES: RouteInfo[] = nav.main.inventory;

@Injectable({providedIn: 'root'})
export class CoreInventoryGuard  {
  constructor(private store: Store<fromReducer.auth.State>) {
  }

  canActivate(): Observable<boolean> {
    return this.store.pipe(
      select(fromSelectors.isAuthenticated),
      map(authed => {
        this.store.dispatch(new SubNav([]));
        this.store.dispatch(new Nav(ROUTES));
        if (localStorage.getItem('authorization') !== null) {

          const user = JSON.parse(localStorage.getItem('user'));
          if (user) {
            this.store.dispatch(new GetAllRepairCentreByUser());
          }
          return true;
        }
        this.store.dispatch(new LoginRequired());
        return false;
      })
    );
  }
}
