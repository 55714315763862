import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import {of} from "rxjs";
import {ToastrService} from 'ngx-toastr';
import {
  AddSoftLaunchProject,
  AddSoftLaunchProjectSuccess,
  GetAllSoftLaunchProject,
  GetAllSoftLaunchProjectSuccess,
  GetSoftLaunchProject, GetSoftLaunchProjectSuccess,
  SoftLaunchProjectActionTypes,
  SoftLaunchProjectError
} from '../../../actions/core-soft-launch/project/soft-launch-project.actions';
import {SoftLaunchProjectService} from '../../../../services/core-soft-launch/project/soft-launch-project.service';
import {Router} from '@angular/router';

@Injectable()
export class SoftLaunchProjectEffects {
  
  saveSoftLaunch = createEffect(() => this.actions$.pipe(
    ofType(SoftLaunchProjectActionTypes.softLaunchProjectAdd),
    switchMap((action: AddSoftLaunchProject) =>
      this.softLaunchProjectService
        .saveSoftLaunchProject(action.payload.softLaunchProject, action.payload.file)
        .pipe(
          map(
            softLaunchProject => {
              if (softLaunchProject) {
                this.toastr.success('Soft Launch Project added successfully!', 'Successful!');
                this.router.navigateByUrl('/soft-launch/project/' + softLaunchProject.id);
                return new AddSoftLaunchProjectSuccess(softLaunchProject);
              }
              this.toastr.error('There was an error creating soft launch project!', 'Unknown error');
              return new SoftLaunchProjectError({type: 500, message: 'Internal error'});
            }
          ),
          catchError(error => of(new SoftLaunchProjectError(error)))
        )
    )
  ));

  
  softLaunchProjects = createEffect(() => this.actions$.pipe(
    ofType(SoftLaunchProjectActionTypes.softLaunchProjectGetAll),
    mergeMap((action: GetAllSoftLaunchProject) =>
      this.softLaunchProjectService
        .getAllSoftLaunchProjects(action.payload)
        .pipe(
          map(softLaunchProjects => {
            return new GetAllSoftLaunchProjectSuccess(softLaunchProjects);
          }),
          catchError(error => of(new SoftLaunchProjectError(error)))
        )
    )
  ));

  
  softLaunchProject = createEffect(() => this.actions$.pipe(
    ofType(SoftLaunchProjectActionTypes.softLaunchProjectGet),
    mergeMap((action: GetSoftLaunchProject) =>
      this.softLaunchProjectService
        .getSoftLaunchProject(action.payload)
        .pipe(
          map(softLaunchProject => {
            if (softLaunchProject) {
              return new GetSoftLaunchProjectSuccess(softLaunchProject);
            }
          }),
          catchError(error => of(new SoftLaunchProjectError(error)))
        )
    )
  ));

  constructor(
    private actions$: Actions,
    private softLaunchProjectService: SoftLaunchProjectService,
    private toastr: ToastrService,
    private router: Router
  ) {
  }
}
