import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from "rxjs";
import {catchError, tap} from 'rxjs/operators';
import {CoreMasterDataService} from '../core-masterdata.service';
import {ProductPartPrice} from '../../../models/core-setup/part-prices/product-part-price';
import {Part} from '@appModels/core-aftersales/part';

@Injectable({
  providedIn: 'root'
})
export class ProductPartPriceService extends CoreMasterDataService {

  constructor(private http: HttpClient) {
    super(http, 'tenantproductprice');
  }

  /** GET repair centre types from the server */
  getProductPartPrices(query?): Observable<Part[]> {
    const tenantId = localStorage.getItem('tenant');

    let params = new HttpParams();

    if (query !== undefined) {
      Object.keys(query).forEach(val => {
        if (query[val] !== null && query[val] !== undefined && query[val] !== '') {
          params = params.append(val, query[val]);
        }
      });
    }

    params = params.append('tenantId', tenantId);

    return this.http.get<Part[]>(this.base_url, {params: params, headers: this.headers}).pipe(
      tap(types => this.log(`fetched product prices`)),
      catchError(this.handleError('getProductPartPrices', []))
    );
  }

  /** GET repair centre types from the server */
  getAllProductPartPrices(): Observable<ProductPartPrice[]> {
    const tenantId = localStorage.getItem('tenant');

    return this.http.get<ProductPartPrice[]>(this.base_url + '/all?tenantId=' + tenantId, {headers: this.headers}).pipe(
      tap(types => this.log(`fetched all product prices`)),
      catchError(this.handleError('getProductPartPrices', []))
    );
  }


  /** POST repair centre types */
  saveProductPrices(prices: ProductPartPrice[]): Observable<ProductPartPrice[]> {
    const tenantId = localStorage.getItem('tenant');
    return this.http.post<ProductPartPrice[]>(this.base_url + '?tenantId=' + tenantId, prices, {headers: this.headers}).pipe(
      tap(newPrices => this.log(`saved product prices types`)),
      catchError(this.handleError('saveProductPrices', [])));
  }

  /** PUT repair centre types */
  updateProductPrices(prices: ProductPartPrice[]): Observable<ProductPartPrice[]> {
    const tenantId = localStorage.getItem('tenant');
    return this.http.put<ProductPartPrice[]>(this.base_url + '?tenantId=' + tenantId, prices, {headers: this.headers}).pipe(
      tap(newPrices => this.log(`updated product prices types`)),
      catchError(this.handleError('updateProductPrices', [])));
  }
}

