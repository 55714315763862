import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable, } from 'rxjs';
import {catchError, tap} from 'rxjs/operators';


import {CoreInventoryService} from '../core-inventory.service';


@Injectable({providedIn: 'root'})
export class TenantStockingPointService extends CoreInventoryService {

  constructor(
    private http: HttpClient,
  ) {
    super(http, 'tenant');
  }

  /** GET stockingPoint by id. Will 404 if id not found */
  getTenantStockingPoint(filters: any): Observable<any> {
    const id = localStorage.getItem('tenant');
    const params = {};
    if (filters) {

      if (filters.dealerId != null) {
        params['dealerId'] = filters.dealerId;
      }
      if (filters.regionName != null) {
        params['regionName'] = filters.regionName;
      }
      if (filters.searchParam != null) {
        params['searchParam'] = filters.searchParam;
      }
      if (filters.startPage != null) {
        params['page'] = Number(filters.startPage);
      }
      if (filters.numberPerPage != null) {
        params['size'] = Number(filters.numberPerPage);
      }
    }

    const url = `${this.base_url}/${id}/stockingpoints`;
    return this.http.get(url, {headers: this.headers, params: params}).pipe(
      tap(_ => this.log(`fetched tenantStockingPoint id=${id}`)),
      catchError(this.handleError(`getTenantStockingPoints id=${id}`))
    );
  }


  /** GET stockingPoint by id. Will 404 if id not found */
  getTenantStockingPointLinked(filters: any): Observable<any> {
    const id = localStorage.getItem('tenant');
    const params = {};
    if (filters) {

      if (filters.dealerId != null) {
        params['dealerId'] = filters.dealerId;
      }
      if (filters.regionName != null) {
        params['regionName'] = filters.regionName;
      }
      if (filters.searchParam != null) {
        params['searchParam'] = filters.searchParam;
      }
      if (filters.startPage != null) {
        params['page'] = Number(filters.startPage);
      }
      if (filters.numberPerPage != null) {
        params['size'] = Number(filters.numberPerPage);
      }
      params['userId'] = Number(JSON.parse(localStorage.getItem("user")).id);
    }

    const url = `${this.base_url}/${id}/stockingpoints/linkedToUser`;
    return this.http.get(url, {headers: this.headers, params: params}).pipe(
      tap(_ => this.log(`fetched tenantStockingPoint id=${id}`)),
      catchError(this.handleError(`getTenantStockingPoints id=${id}`))
    );
  }

  /** GET stockingPoint by id. Will 404 if id not found */
  getTenantStockingPointWithPreference(search: string, preferenceTag?: string): Observable<any> {
    const id = localStorage.getItem('tenant');

    let params = new HttpParams();
    if (preferenceTag) {
      params = params.append('preferenceTag', preferenceTag);
    }

    if (search) {
      params = params.append('search', search);
    }


    const url = `${this.base_url}/${id}/dealers`;
    return this.http.get(url, {headers: this.headers, params: params}).pipe(
      tap(_ => this.log(`fetched dealers id=${id}`)),
      catchError(this.handleError(`getTenantStockingPointWithPreference id=${id}`))
    );
  }

  /** GET stockingPoint by tenant id and search */
  getTenantStockingPointWithoutPreference(search: string): Observable<any> {
    const id = localStorage.getItem('tenant');

    let params = new HttpParams();

    if (search) {
      params = params.append('search', search);
    }


    const url = `${this.base_url}/${id}/search`;
    return this.http.get(url, {headers: this.headers, params: params}).pipe(
      tap(_ => this.log(`fetched dealers id=${id}`)),
      catchError(this.handleError(`getTenantStockingPointWithoutPreference id=${id}`))
    );
  }

  /** GET stockingPoint by tenant id and search */
  getTenantStockingPointWithoutPreferenceByTenant(id, search: string): Observable<any> {
    let params = new HttpParams();

    if (search) {
      params = params.append('search', search);
    }


    const url = `${this.base_url}/${id}/search`;
    return this.http.get(url, {headers: this.headers, params: params}).pipe(
      tap(_ => this.log(`fetched dealers id=${id}`)),
      catchError(this.handleError(`getTenantStockingPointWithoutPreference id=${id}`))
    );
  }


  /** GET warehouse by id. Will 404 if id not found */
  getTenantWarehouses(): Observable<any> {
    const id = localStorage.getItem('tenant');
    const url = `${this.base_url}/${id}/warehouses`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched tenantWarehouse id=${id}`)),
      catchError(this.handleError(`getTenantWarehouse id=${id}`))
    );
  }
}
