import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getRepairCentreStateStore = createFeatureSelector('repairCentreState');

export const getRepairCentreStateEntities = createSelector(
  getRepairCentreStateStore,
  fromReducers.repairCentreState.repairCentreStateEntitySelectors.selectAll
);

export const getRepairCentreStates = createSelector(getRepairCentreStateEntities, entities => {
  return Object.values(entities);
});
