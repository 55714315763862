import { Action } from '@ngrx/store';

import { AdminZone } from '@appModels/core-setup/admin-zone/admin-zone';

export enum AdminZoneActionTypes {
  adminZoneGetAdminZones = '[AdminZone] get',
  adminZoneGetAdminZonesSuccess = '[AdminZone] get adminZones success',
  adminZoneAddAdminZone = '[AdminZone] add AdminZone',
  adminZoneAddAdminZoneSuccess = '[AdminZone] add adminZone success',
  adminZoneDeleteAdminZone = '[AdminZone] delete adminZone',
  adminZoneDeleteAdminZoneSuccess = '[AdminZone] delete adminZone success',
  adminZoneGetAdminZoneById = '[AdminZone] get adminZone by id',
  adminZoneGetAdminZoneByIdSuccess = '[AdminZone] get adminZone by id success',
  adminZoneUpdateAdminZone = '[AdminZone] update adminZone',
  adminZoneUpdateAdminZoneSuccess = '[AdminZone] update adminZone success',
  adminZoneSearchAdminZones = '[AdminZone] search adminZones',
  adminZoneSearchAdminZonesSuccess = '[AdminZone] search adminZones success',
  adminZoneSearchAdminZonesReset = '[AdminZone] search adminZones reset',
  adminZoneError = '[AdminZone] error'
}

export class GetAdminZones implements Action {
  readonly type = AdminZoneActionTypes.adminZoneGetAdminZones;
}

export class GetAdminZonesSuccess implements Action {
  readonly type = AdminZoneActionTypes.adminZoneGetAdminZonesSuccess;
  constructor(public payload: AdminZone[]) {}
}

export class AddAdminZone implements Action {
  readonly type = AdminZoneActionTypes.adminZoneAddAdminZone;
  constructor(public payload: AdminZone) {}
}

export class AddAdminZoneSuccess implements Action {
  readonly type = AdminZoneActionTypes.adminZoneAddAdminZoneSuccess;
  constructor(public payload: AdminZone) {}
}

export class GetAdminZoneById implements Action {
  readonly type = AdminZoneActionTypes.adminZoneGetAdminZoneById;
  constructor(public payload: number) {}
}

export class GetAdminZoneByIdSuccess implements Action {
  readonly type = AdminZoneActionTypes.adminZoneGetAdminZoneByIdSuccess;
  constructor(public payload: AdminZone) {}
}

export class UpdateAdminZone implements Action {
  readonly type = AdminZoneActionTypes.adminZoneUpdateAdminZone;
  constructor(public payload: AdminZone) {}
}

export class UpdateAdminZoneSuccess implements Action {
  readonly type = AdminZoneActionTypes.adminZoneUpdateAdminZoneSuccess;
  constructor(public payload: AdminZone) {}
}

export class DeleteAdminZone implements Action {
  readonly type = AdminZoneActionTypes.adminZoneDeleteAdminZone;
  constructor(public payload: AdminZone) {}
}

export class DeleteAdminZoneSuccess implements Action {
  readonly type = AdminZoneActionTypes.adminZoneDeleteAdminZoneSuccess;
  constructor(public payload: AdminZone) {}
}

export class SearchAdminZones implements Action {
  readonly type = AdminZoneActionTypes.adminZoneSearchAdminZones;
  constructor(public payload: string) {}
}

export class SearchAdminZonesSuccess implements Action {
  readonly type = AdminZoneActionTypes.adminZoneSearchAdminZonesSuccess;
  constructor(public payload: AdminZone[]) {}
}

export class SearchAdminZonesReset implements Action {
  readonly type = AdminZoneActionTypes.adminZoneSearchAdminZonesReset;
}

export class AdminZoneError implements Action {
  readonly type = AdminZoneActionTypes.adminZoneError;
  constructor(public payload: any) {}
}

export type AdminZoneActions =
  | GetAdminZones
  | GetAdminZonesSuccess
  | AddAdminZone
  | AddAdminZoneSuccess
  | GetAdminZoneById
  | GetAdminZoneByIdSuccess
  | UpdateAdminZone
  | UpdateAdminZoneSuccess
  | DeleteAdminZone
  | DeleteAdminZoneSuccess
  | SearchAdminZones
  | SearchAdminZonesSuccess
  | SearchAdminZonesReset
  | AdminZoneError;
