import {Action} from '@ngrx/store';
import {TenantOowRepairCondition} from '../../../../models/core-setup/oow-repair-conditions/tenant-oow-repair-condition';

export enum TenantOowRepairConditionsActionTypes {
  tenantOowRepairConditionsGetAll = '[Tenant Out of Warranty Repair Condition] Get All',
  tenantOowRepairConditionsGetAllSuccess = '[Tenant Out of Warranty Repair Condition] Get All Success',
  tenantOowRepairConditionsSave = '[Tenant Out of Warranty Repair Condition] Save',
  tenantOowRepairConditionsSaveSuccess = '[Tenant Out of Warranty Repair Condition] Save Success',
  tenantOowRepairConditionsDisable = '[Tenant Out of Warranty Repair Condition] Disable',
  tenantOowRepairConditionsDisableSuccess = '[Tenant Out of Warranty Repair Condition] Disable Success',
  tenantOowRepairConditionsError = '[Tenant Out of Warranty Repair Condition] Error',
}

export class GetAllTenantOowRepairConditions implements Action {
  readonly type = TenantOowRepairConditionsActionTypes.tenantOowRepairConditionsGetAll;

  constructor() {
  }
}

export class GetAllTenantOowRepairConditionsSuccess implements Action {
  readonly type = TenantOowRepairConditionsActionTypes.tenantOowRepairConditionsGetAllSuccess;

  constructor(public payload: TenantOowRepairCondition[]) {
  }
}

export class SaveTenantOowRepairConditions implements Action {
  readonly type = TenantOowRepairConditionsActionTypes.tenantOowRepairConditionsSave;

  constructor(public payload: TenantOowRepairCondition[]) {
  }
}

export class SaveTenantOowRepairConditionsSuccess implements Action {
  readonly type = TenantOowRepairConditionsActionTypes.tenantOowRepairConditionsSaveSuccess;

  constructor(public payload: TenantOowRepairCondition[]) {
  }
}

export class DisableTenantOowRepairConditions implements Action {
  readonly type = TenantOowRepairConditionsActionTypes.tenantOowRepairConditionsDisable;

  constructor(public payload: number) {
  }
}

export class DisableTenantOowRepairConditionsSuccess implements Action {
  readonly type = TenantOowRepairConditionsActionTypes.tenantOowRepairConditionsDisableSuccess;

  constructor(public payload: TenantOowRepairCondition) {
  }
}

export class TenantOowRepairConditionsError implements Action {
  readonly type = TenantOowRepairConditionsActionTypes.tenantOowRepairConditionsError;

  constructor(public payload: any) {
  }
}


export type TenantOowRepairConditionsActions =
  | GetAllTenantOowRepairConditions
  | GetAllTenantOowRepairConditionsSuccess
  | TenantOowRepairConditionsError
  | SaveTenantOowRepairConditions
  | SaveTenantOowRepairConditionsSuccess
  | DisableTenantOowRepairConditions
  | DisableTenantOowRepairConditionsSuccess;




