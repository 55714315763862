import { Directive, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { markets } from './__utils__/markets';

@Directive({
  selector: '[hasViewAccess]',
  standalone: true,
  providers: [],
})
export class AccessControlDirective  implements OnInit {
  tenantId = localStorage.getItem('tenant');
  commonMarkets =  markets;

  constructor(private templateRef: TemplateRef<any>,
              private viewContainer: ViewContainerRef) {}

  ngOnInit(): void {
    if (markets.has(this.tenantId)) {
      this.addTemplate(); 
    } else {
      this.clearTemplate(); 
    }
  }

  private addTemplate() {
    this.viewContainer.clear();
    this.viewContainer.createEmbeddedView(this.templateRef);
  }

  private clearTemplate() {
    this.viewContainer.clear();
  }
}


