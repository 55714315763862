import {Action} from '@ngrx/store';

import {Testflow} from "../../../../models/core-aftersales/testflow/testflow";

export enum TestFlowActionTypes {
  testflowGettestflows = '[Testflow] get',
  testflowGettestflowsSuccess = '[Testflow] get testflow success',
  testflowGetestflowsByTenantId = '[Testflow] get sales testflow  by tenant id',
  testflowGetestflowsByTenantIdSuccess = '[Testflow] get sales testflow  by tenant id success',
  testflowAddtestflow = '[Testflow] add testflow',
  testflowAddStestflowSuccess = '[Testflow] add testflow success',
  testflowAddtestflowAttributes = '[Testflow] add testflow Attributesd',
  testflowAddtestflowAttributesSuccess = '[Testflow] add additional testflow Info success',
  testflowDeletetestflow = '[Testflow] delete testflow',
  testflowDeletetestflowSuccess = '[Testflow] delete testflow success',
  testflowGettestflowById = '[Testflow] get testflow by id',
  testflowGettestflowByIdSuccess = '[Testflow] get testflow by id success',
  testflowUpdatetestflow = '[Testflow] update testflow',
  testflowUpdatetestflowSuccess = '[Testflow] update testflow success',
  testflowSearchtestflows = '[Testflow] search testflow',
  testflowSearchtestflowsSuccess = '[Testflow] search testflow success',
  testflowSearchtestflowsReset = '[Testflow] search testflow reset',
  testflowFiltertestflow = '[Testflow] filter testflow ',
  testflowFiltertestflowSuccess = '[Testflow] filter testflow success',
  testflowsError = '[Testflow] error'
}

export class Gettestflows implements Action {
  readonly type = TestFlowActionTypes.testflowGettestflows;
}

export class GettestflowsSuccess implements Action {
  readonly type = TestFlowActionTypes.testflowGettestflowsSuccess;

  constructor(public payload: Testflow[]) {
  }
}

export class Addtestflow implements Action {
  readonly type = TestFlowActionTypes.testflowAddtestflow;

  constructor(public payload: Testflow) {
  }
}

export class AddtestflowSuccess implements Action {
  readonly type = TestFlowActionTypes.testflowAddStestflowSuccess;

  constructor(public payload: Testflow) {
  }
}


export class Filtertestflow implements Action {
  readonly type = TestFlowActionTypes.testflowFiltertestflow;

  constructor(public payload?) {
  }
}

export class FiltertestflowSuccess implements Action {
  readonly type = TestFlowActionTypes.testflowFiltertestflowSuccess;

  constructor(public payload) {
  }
}


export class GettestflowById implements Action {
  readonly type = TestFlowActionTypes.testflowGettestflowById;

  constructor(public payload) {
  }
}

export class GettestflowByIdSuccess implements Action {
  readonly type = TestFlowActionTypes.testflowGettestflowByIdSuccess;

  constructor(public payload) {
  }
}

export class Updatetestflow implements Action {
  readonly type = TestFlowActionTypes.testflowUpdatetestflow;

  constructor(public payload: Testflow) {
  }
}

export class UpdatetestflowSuccess implements Action {
  readonly type = TestFlowActionTypes.testflowUpdatetestflowSuccess;

  constructor(public payload: Testflow) {
  }
}

export class Deletetestflow implements Action {
  readonly type = TestFlowActionTypes.testflowDeletetestflow;

  constructor(public payload: Testflow) {
  }
}

export class DeletetestflowSuccess implements Action {
  readonly type = TestFlowActionTypes.testflowDeletetestflowSuccess;

  constructor(public payload: Testflow) {
  }
}

export class Searchtestflows implements Action {
  readonly type = TestFlowActionTypes.testflowSearchtestflows;

  constructor(public payload: string) {
  }
}

export class SearchtestflowsSuccess implements Action {
  readonly type = TestFlowActionTypes.testflowSearchtestflowsSuccess;

  constructor(public payload: Testflow[]) {
  }
}

export class GettestflowsByTenantId implements Action {
  readonly type = TestFlowActionTypes.testflowGetestflowsByTenantId;

  constructor(public payload: number) {
  }
}

export class GettestflowsByTenantIdIdSuccess implements Action {
  readonly type = TestFlowActionTypes.testflowGetestflowsByTenantIdSuccess;

  constructor(public payload: Testflow[]) {
  }
}

export class SearchtestflowsReset implements Action {
  readonly type = TestFlowActionTypes.testflowSearchtestflowsReset;
}

export class testflowsError implements Action {
  readonly type = TestFlowActionTypes.testflowsError;

  constructor(public payload: any) {
  }
}

export type TestFlowActions =
  | Gettestflows
  | GettestflowsSuccess
  | Addtestflow
  | AddtestflowSuccess
  | GettestflowById
  | GettestflowByIdSuccess
  | Updatetestflow
  | UpdatetestflowSuccess
  | Deletetestflow
  | DeletetestflowSuccess
  | Searchtestflows
  | SearchtestflowsSuccess
  | SearchtestflowsReset
  | GettestflowsByTenantId
  | GettestflowsByTenantIdIdSuccess
  | Filtertestflow
  | FiltertestflowSuccess
  | testflowsError;
