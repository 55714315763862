import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { mergeMap, map, catchError, tap, debounceTime, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import {
  SmsProviderActionTypes,
  GetSmsProviders,
  GetSmsProvidersSuccess,
  SmsProviderError,
  AddSmsProvider,
  AddSmsProviderSuccess,
  DeleteSmsProviderSuccess,
  DeleteSmsProvider,
  GetSmsProviderById,
  GetSmsProviderByIdSuccess,
  UpdateSmsProviderSuccess,
  SearchSmsProviders,
  SearchSmsProvidersSuccess
} from '@appStore/actions/core-messaging/sms-provider/sms-provider.actions';
import { SmsProviderService } from '@appServices/core-messaging/sms-provider/sms-provider.service';

import * as fromRouterActions from '@appStore/actions/router.actions';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Injectable()
export class SmsProviderEffects {
  constructor(
    private actions$: Actions, 
    private smsProviderService: SmsProviderService,
    private toastr: ToastrService,
    private router: Router
    ) {}

  
  loadSmsProviders$ = createEffect(() => this.actions$.pipe(
    ofType(SmsProviderActionTypes.smsProviderGetSmsProviders),
    mergeMap(() =>
      this.smsProviderService
        .getSmsProviders()
        .pipe(
          map(smsProviders => new GetSmsProvidersSuccess(smsProviders)),
          catchError(error => of(new SmsProviderError(error)))
        )
    )
  ));

  
  getSmsProviderById$ = createEffect(() => this.actions$.pipe(
    ofType(SmsProviderActionTypes.smsProviderGetSmsProviderById),
    mergeMap((action: GetSmsProviderById) =>
      this.smsProviderService
        .getSmsProvider(action.payload)
        .pipe(
          map(smsProvider => new GetSmsProviderByIdSuccess(smsProvider)),
          catchError(error => of(new SmsProviderError(error)))
        )
    )
  ));

  
  addSmsProvider$ = createEffect(() => this.actions$.pipe(
    ofType(SmsProviderActionTypes.smsProviderAddSmsProvider),
    switchMap((action: AddSmsProvider) =>
      this.smsProviderService
        .addSmsProvider(action.payload)
        .pipe(
          map(
            smsProvider => {
              if (smsProvider !== undefined) {
                this.toastr.success('Stocking point has been successfully added!', 'Successful!')
                this.router.navigateByUrl(`/setup/sms-providers`);
                return new AddSmsProviderSuccess(smsProvider)
              }
              this.toastr.error('There was an error creating stocking point!', "Unknown error")
              return new SmsProviderError({ type: 500, message: 'Internal error' })
            }
            ),
          catchError(error => of(new SmsProviderError(error)))
        )
    )
  ));

    
  addAdditionalSmsProviderInfo$ = createEffect(() => this.actions$.pipe(
    ofType(SmsProviderActionTypes.smsProviderAddAdditionalSmsProviderInfo),
    mergeMap((action: AddSmsProvider) =>
      this.smsProviderService
        .addSmsProviderAdditionalInfo(action.payload)
        .pipe(
          map(smsProvider => new GetSmsProviders()),
          catchError(error => of(new SmsProviderError(error)))
        )
    )
  ));

  
  updateSmsProvider$ = createEffect(() => this.actions$.pipe(
    ofType(SmsProviderActionTypes.smsProviderUpdateSmsProvider),
    mergeMap((action: AddSmsProvider) =>
      this.smsProviderService
        .updateSmsProvider(action.payload)
        .pipe(
          map(smsProvider => new UpdateSmsProviderSuccess(action.payload)),
          catchError(error => of(new SmsProviderError(error)))
        )
    )
  ));

  
  deleteSmsProvider$ = createEffect(() => this.actions$.pipe(
    ofType(SmsProviderActionTypes.smsProviderDeleteSmsProvider),
    mergeMap((action: DeleteSmsProvider) =>
      this.smsProviderService
        .deleteSmsProvider(action.payload)
        .pipe(
          map(() => new DeleteSmsProviderSuccess(action.payload)),
          catchError(error => of(new SmsProviderError(error)))
        )
    )
  ));

  
  searchSmsProviders$ = createEffect(() => this.actions$.pipe(
    ofType(SmsProviderActionTypes.smsProviderSearchSmsProviders),
    mergeMap((action: SearchSmsProviders) =>
      this.smsProviderService
        .searchSmsProviders(action.payload)
        .pipe(
          map(smsProviders => new SearchSmsProvidersSuccess(smsProviders)),
          catchError(error => of(new SmsProviderError(error)))
        )
    )
  ));

  
  updateSmsProviderSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(SmsProviderActionTypes.smsProviderUpdateSmsProviderSuccess),
    map(smsProvider => new fromRouterActions.Go({ path: ['/setup/sms-providers'] }))
  ));
}
