import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getAccessoryRateStore = createFeatureSelector('accessoryRate');

export const getAccessoryRateEntities = createSelector(
  getAccessoryRateStore,
  fromReducers.accessoryRate.accessoryRateEntitySelectors.selectAll
);

export const getAccessoryRates = createSelector(getAccessoryRateEntities, entities => {
  return Object.values(entities);
});

export const getTopAccessoryRates = createSelector(getAccessoryRateEntities, entities => {
  return Object.values(entities).slice(1, 5);
});

export const getAccessoryRatePaging = createSelector(
  getAccessoryRateStore,
  (accessoryRateStore: fromReducers.accessoryRate.State) => accessoryRateStore.paging
);

export const getAccessoryRatesLoaded = createSelector(
  getAccessoryRateStore,
  (accessoryRateStore: fromReducers.accessoryRate.State) => accessoryRateStore.loaded
);

export const getAccessoryRatesLoading = createSelector(
  getAccessoryRateStore,
  (accessoryRateStore: fromReducers.accessoryRate.State) => accessoryRateStore.loading
);

export const getSelectedAccessoryRateId = createSelector(
  getAccessoryRateStore,
  (accessoryRateStore: fromReducers.accessoryRate.State) => accessoryRateStore.selectedAccessoryRateId
);

export const getSearchAccessoryRates = createSelector(
  getAccessoryRateStore,
  (accessoryRateStore: fromReducers.accessoryRate.State) => accessoryRateStore.searchAccessoryRates
);

export const getAccessoryRateById = createSelector(
  getAccessoryRateStore,
  (accessoryRateStore: fromReducers.accessoryRate.State) => accessoryRateStore.selectedAccessoryRate

);

export const getAccessoryRatesError = createSelector(
  getAccessoryRateStore,
  (accessoryRateStore: fromReducers.accessoryRate.State) => accessoryRateStore.error
);


