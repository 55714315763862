import {Action} from '@ngrx/store';

import {SalesStaffDefinition} from '@appModels/core-fieldforce/sales-staff-definition/sales-staff-definition';

export enum SalesStaffDefinitionActionTypes {
  salesStaffGetStaffDefinitions = '[SalesStaffDefinition] get sales staff definitions',
  salesStaffGetStaffDefinitionsSuccess = '[SalesStaffDefinition] get salesStaffDefinitions success',
  salesStaffGeStaffDefinitionsByTenantId = '[SalesStaffDefinition] get sales staff definition by tenant id',
  salesStaffGeStaffDefinitionsByTenantIdSuccess = '[SalesStaffDefinition] get sales staff definitions by tenant id success',
  salesStaffAddStaffDefinition = '[SalesStaffDefinition] add SalesStaffDefinition',
  salesStaffAddSStaffDefinitionSuccess = '[SalesStaffDefinition] add salesStaffDefinition success',
  salesStaffAddStaffDefinitiontAttributes = '[SalesStaffDefinition] add SalesStaffDefinition Attributesd',
  salesStaffAddStaffDefinitionAttributesSuccess = '[SalesStaffDefinition] add additional salesStaffDefinition Info success',
  salesStaffDeleteStaffDefinition = '[SalesStaffDefinition] delete salesStaffDefinition',
  salesStaffDeleteStaffDefinitionSuccess = '[SalesStaffDefinition] delete salesStaffDefinition success',
  salesStaffGetStaffDefinitionById = '[SalesStaffDefinition] get salesStaffDefinition by id',
  salesStaffGetStaffDefinitionByIdSuccess = '[SalesStaffDefinition] get salesStaffDefinition by id success',
  salesStaffUpdateStaffDefinition = '[SalesStaffDefinition] update salesStaffDefinition',
  salesStaffUpdateStaffDefinitionSuccess = '[SalesStaffDefinition] update salesStaffDefinition success',
  salesStaffSearchStaffDefinitions = '[SalesStaffDefinition] search salesStaffDefinitions',
  salesStaffSearchStaffDefinitionsSuccess = '[SalesStaffDefinition] search salesStaffDefinitions success',
  salesStaffSearchStaffDefinitionsReset = '[SalesStaffDefinition] search salesStaffDefinitions reset',
  salesStaffError = '[SalesStaffDefinition] error'
}


export class GetSalesStaffDefinitions implements Action {
  readonly type = SalesStaffDefinitionActionTypes.salesStaffGetStaffDefinitions;
}

export class GetSalesStaffDefinitionsSuccess implements Action {
  readonly type = SalesStaffDefinitionActionTypes.salesStaffGetStaffDefinitionsSuccess;

  constructor(public payload: SalesStaffDefinition[]) {
  }
}

export class AddSalesStaffDefinition implements Action {
  readonly type = SalesStaffDefinitionActionTypes.salesStaffAddStaffDefinition;

  constructor(public payload: SalesStaffDefinition) {
  }
}

export class AddSalesStaffDefinitionSuccess implements Action {
  readonly type = SalesStaffDefinitionActionTypes.salesStaffAddSStaffDefinitionSuccess;

  constructor(public payload: SalesStaffDefinition) {
  }
}

export class AddAttributes implements Action {
  readonly type = SalesStaffDefinitionActionTypes.salesStaffAddStaffDefinitiontAttributes;

  constructor(public payload: SalesStaffDefinition) {
  }
}

export class AddAttributesSuccess implements Action {
  readonly type = SalesStaffDefinitionActionTypes.salesStaffAddStaffDefinitionAttributesSuccess;

  constructor(public payload: SalesStaffDefinition) {
  }
}

export class GetSalesStaffDefinitionById implements Action {
  readonly type = SalesStaffDefinitionActionTypes.salesStaffGetStaffDefinitionById;

  constructor(public payload: number) {
  }
}

export class GetSalesStaffDefinitionByIdSuccess implements Action {
  readonly type = SalesStaffDefinitionActionTypes.salesStaffGetStaffDefinitionByIdSuccess;

  constructor(public payload: SalesStaffDefinition) {
  }
}

export class UpdateSalesStaffDefinition implements Action {
  readonly type = SalesStaffDefinitionActionTypes.salesStaffUpdateStaffDefinition;

  constructor(public payload: SalesStaffDefinition) {
  }
}

export class UpdateSalesStaffDefinitionSuccess implements Action {
  readonly type = SalesStaffDefinitionActionTypes.salesStaffUpdateStaffDefinitionSuccess;

  constructor(public payload: SalesStaffDefinition) {
  }
}

export class DeleteSalesStaffDefinition implements Action {
  readonly type = SalesStaffDefinitionActionTypes.salesStaffDeleteStaffDefinition;

  constructor(public payload: SalesStaffDefinition) {
  }
}

export class DeleteSalesStaffDefinitionSuccess implements Action {
  readonly type = SalesStaffDefinitionActionTypes.salesStaffDeleteStaffDefinitionSuccess;

  constructor(public payload: SalesStaffDefinition) {
  }
}

export class SearchSalesStaffDefinitions implements Action {
  readonly type = SalesStaffDefinitionActionTypes.salesStaffSearchStaffDefinitions;

  constructor(public payload: string) {
  }
}

export class SearchSalesStaffDefinitionsSuccess implements Action {
  readonly type = SalesStaffDefinitionActionTypes.salesStaffSearchStaffDefinitionsSuccess;

  constructor(public payload: SalesStaffDefinition[]) {
  }
}

export class GetSalesStaffDefinitionsByTenantId implements Action {
  readonly type = SalesStaffDefinitionActionTypes.salesStaffGeStaffDefinitionsByTenantId;

  constructor(public payload?) {
  }
}

export class GetSalesStaffDefinitionsByTenantIdIdSuccess implements Action {
  readonly type = SalesStaffDefinitionActionTypes.salesStaffGeStaffDefinitionsByTenantIdSuccess;

  constructor(public payload: SalesStaffDefinition[]) {
  }
}

export class SearchSalesStaffDefinitionsReset implements Action {
  readonly type = SalesStaffDefinitionActionTypes.salesStaffSearchStaffDefinitionsReset;
}

export class SalesStaffDefinitionsError implements Action {
  readonly type = SalesStaffDefinitionActionTypes.salesStaffError;

  constructor(public payload: any) {
  }
}

export type SalesStaffDefinitionActions =
  | GetSalesStaffDefinitions
  | GetSalesStaffDefinitionsSuccess
  | AddSalesStaffDefinition
  | AddSalesStaffDefinitionSuccess
  | AddAttributes
  | AddAttributesSuccess
  | GetSalesStaffDefinitionById
  | GetSalesStaffDefinitionByIdSuccess
  | UpdateSalesStaffDefinition
  | UpdateSalesStaffDefinitionSuccess
  | DeleteSalesStaffDefinition
  | DeleteSalesStaffDefinitionSuccess
  | SearchSalesStaffDefinitions
  | SearchSalesStaffDefinitionsSuccess
  | SearchSalesStaffDefinitionsReset
  | GetSalesStaffDefinitionsByTenantId
  | GetSalesStaffDefinitionsByTenantIdIdSuccess
  | SalesStaffDefinitionsError;
