import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { mergeMap, map, catchError, tap, debounceTime, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import {
  ModuleActionActionTypes,
  GetModuleActionsSuccess,
  ModuleActionError,
  AddModuleAction,
  AddModuleActionSuccess,
  DeleteModuleActionSuccess,
  DeleteModuleAction,
  GetModuleActionById,
  GetModuleActionByIdSuccess,
  UpdateModuleActionSuccess,
  SearchModuleActions,
  SearchModuleActionsSuccess,
  GetModuleCustomFieldTypeSuccess
} from '@appStore/actions/core-masterdata/module-action/module-action.actions';
import { ModuleActionService } from '@appServices/core-masterdata/module-action/module-action.service';
import { CustomFieldTypeService } from '@appServices/core-masterdata/custom-field-type/custom-field-type.service';

import * as fromRouterActions from '@appStore/actions/router.actions';

@Injectable()
export class ModuleActionEffects {
  constructor(private actions$: Actions, private moduleActionService: ModuleActionService,
  private customFieldTypeService: CustomFieldTypeService) {}

  
  loadModuleActions$ = createEffect(() => this.actions$.pipe(
    ofType(ModuleActionActionTypes.moduleActionGetModuleActions),
    mergeMap(() =>
      this.moduleActionService
        .getModuleActions()
        .pipe(
          map(moduleActions => new GetModuleActionsSuccess(moduleActions)),
          catchError(error => of(new ModuleActionError(error)))
        )
    )
  ));

  
  loadCustomFieldTypes$ = createEffect(() => this.actions$.pipe(
    ofType(ModuleActionActionTypes.moduleActionGetCustomFieldTypeModule),
    mergeMap(() =>
      this.customFieldTypeService
        .getCustomFieldTypes()
        .pipe(
          map(moduleActions => new GetModuleCustomFieldTypeSuccess(moduleActions)),
          catchError(error => of(new ModuleActionError(error)))
        )
    )
  ));

  
  getModuleActionById$ = createEffect(() => this.actions$.pipe(
    ofType(ModuleActionActionTypes.moduleActionGetModuleActionById),
    mergeMap((action: GetModuleActionById) =>
      this.moduleActionService
        .getModuleAction(action.payload)
        .pipe(
          map(moduleAction => new GetModuleActionByIdSuccess(moduleAction)),
          catchError(error => of(new ModuleActionError(error)))
        )
    )
  ));

  
  addModuleAction$ = createEffect(() => this.actions$.pipe(
    ofType(ModuleActionActionTypes.moduleActionAddModuleAction),
    switchMap((action: AddModuleAction) =>
      this.moduleActionService
        .addModuleAction(action.payload)
        .pipe(
          map(moduleAction => new AddModuleActionSuccess(moduleAction)),
          catchError(error => of(new ModuleActionError(error)))
        )
    )
  ));

  
  updateModuleAction$ = createEffect(() => this.actions$.pipe(
    ofType(ModuleActionActionTypes.moduleActionUpdateModuleAction),
    mergeMap((action: AddModuleAction) =>
      this.moduleActionService
        .updateModuleAction(action.payload)
        .pipe(
          map(moduleAction => new UpdateModuleActionSuccess(action.payload)),
          catchError(error => of(new ModuleActionError(error)))
        )
    )
  ));

  
  deleteModuleAction$ = createEffect(() => this.actions$.pipe(
    ofType(ModuleActionActionTypes.moduleActionDeleteModuleAction),
    mergeMap((action: DeleteModuleAction) =>
      this.moduleActionService
        .deleteModuleAction(action.payload)
        .pipe(
          map(() => new DeleteModuleActionSuccess(action.payload)),
          catchError(error => of(new ModuleActionError(error)))
        )
    )
  ));

  
  searchModuleActions$ = createEffect(() => this.actions$.pipe(
    ofType(ModuleActionActionTypes.moduleActionSearchModuleActions),
    mergeMap((action: SearchModuleActions) =>
      this.moduleActionService
        .searchModuleActions(action.payload)
        .pipe(
          map(moduleActions => new SearchModuleActionsSuccess(moduleActions)),
          catchError(error => of(new ModuleActionError(error)))
        )
    )
  ));

  
  updateModuleActionSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(ModuleActionActionTypes.moduleActionUpdateModuleActionSuccess),
    map(moduleAction => new fromRouterActions.Go({ path: ['/setup/module-actions'] }))
  ));
}
