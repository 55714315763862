import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getWorkOrderStore = createFeatureSelector('workOrder');

export const getWorkOrderEntities = createSelector(
  getWorkOrderStore,
  fromReducers.workOrder.workOrderEntitySelectors.selectAll
);

export const getWorkOrderById = createSelector(
  getWorkOrderStore,
  (workOrderStore: fromReducers.workOrder.State) => workOrderStore.selectedWorkOrder
);

export const getWorkOrderLoading = createSelector(
  getWorkOrderStore,
  (workOrderStore: fromReducers.workOrder.State) => workOrderStore.loading
);

export const getWorkOrderPaging = createSelector(
  getWorkOrderStore,
  (workOrderStore: fromReducers.workOrder.State) => workOrderStore.paging
);

export const getWorkOrders = createSelector(getWorkOrderEntities, entities => {
  return Object.values(entities);
});
