import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

import {Addtestflow, AddtestflowSuccess,} from '@appStore/actions/core-aftersales/test-flow/test-flow.actions';
import {SalesStaffService} from '@appServices/core-fieldforce/sales-staff/sales-staff.service';
import {TenantService} from '@appServices/core-masterdata/tenant/tenant.service';
import {ToastrService} from 'ngx-toastr';
import {TenantSalesStaffService} from "@appServices/core-fieldforce/sales-staff/tenant-sales-staff.service";
import {
  Deleteworkordertestflow,
  DeleteworkordertestflowSuccess,
  Filterworkordertestflow,
  FilterworkordertestflowSuccess,
  GetworkordertestflowById,
  GetworkordertestflowByIdSuccess,
  WorkorderTestFlowActionTypes,
  workordertestflowsError,
  UpdateworkordertestflowSuccess,
  AddworkordertestflowSuccess,
  Addworkordertestflow,
  GetWorkOrderTestFlowByProduct,
  GetWorkOrderTestFlowByProductSuccess
} from "../../../actions/core-aftersales/workorder-test-flow/workorder-test-flow.actions";
import {TestFlowService} from "../../../../services/core-aftersales/test-flow/test-flow.service";
import {WorkorderTestFlowService} from "../../../../services/core-aftersales/workorder-test-flow/workorder-test-flow.service";
import {
  WorkOrderError,
  WorkOrderSaveTestCasesSuccess
} from "../../../actions/core-aftersales/work-order/workorder.action";
import { Router } from '@angular/router';

@Injectable()
export class WorkorderTestFlowEffects {

  
  addWorkOrderTestFlow = createEffect(() => this.actions$.pipe(
    ofType(WorkorderTestFlowActionTypes.workordertestflowAddtestflow),
    switchMap((action: Addworkordertestflow) =>
      this.workOrderTestFlowService
        .addWorkOrderTestflow(action.payload)
        .pipe(
          map(
            workOrderTestFlow => {
              if (workOrderTestFlow !== undefined) {
                this.toastr.success('Work Order Test Flow has been successfully added!', 'Successful!');
                this.router.navigateByUrl(`/global-settings/workordertestflow`);
                return new AddworkordertestflowSuccess(workOrderTestFlow)
              }
              this.toastr.error('There was an error creating test flow!', "Unknown error");
              return new workordertestflowsError({type: 500, message: 'Internal error'})
            }
          ),
          catchError(error => of(new workordertestflowsError(error)))
        )
    )
  ));


  
  filterWorkOrderTestFlows$ = createEffect(() => this.actions$.pipe(
    ofType(WorkorderTestFlowActionTypes.workordertestflowFiltertestflow),
    mergeMap((action: Filterworkordertestflow) =>
      this.workOrderTestFlowService
        .filterWorkOrderTestflow(action.payload)
        .pipe(
          map(workOrderTestFlow => new FilterworkordertestflowSuccess(workOrderTestFlow)),
          catchError(error => of(new workordertestflowsError(error)))
        )
    )
  ));

  
  getWorkOrderTestFlowById$ = createEffect(() => this.actions$.pipe(
    ofType(WorkorderTestFlowActionTypes.workordertestflowGettestflowById),
    mergeMap((action: GetworkordertestflowById) =>
      this.workOrderTestFlowService
        .getWorkOrderTestFlowById(action.payload)
        .pipe(
          map(workOrderTestFlow => new GetworkordertestflowByIdSuccess(workOrderTestFlow)),
          catchError(error => of(new workordertestflowsError(error)))
        )
    )
  ));

  
  getWorkOrderTestFlowByProduct$ = createEffect(() => this.actions$.pipe(
    ofType(WorkorderTestFlowActionTypes.workordertestflowGettestflowsByProduct),
    mergeMap((action: GetWorkOrderTestFlowByProduct) =>
      this.workOrderTestFlowService
        .getWorkOrderTestflowByProduct(action.payload)
        .pipe(
          map(flow => {
            if (flow) {
              return new GetWorkOrderTestFlowByProductSuccess(flow);
            }
            return new workordertestflowsError({type: 500, message: 'Internal error'});
          }),
          catchError(error => of(new workordertestflowsError(error)))
        )
    )
  ));

  
  updateWorkOrderTestFlow$ = createEffect(() => this.actions$.pipe(
    ofType(WorkorderTestFlowActionTypes.workordertestflowUpdatetestflow),
    mergeMap((action: Addtestflow) =>
      this.workOrderTestFlowService
        .updateWorkOrderTestflow(action.payload)
        .pipe(
          map(rate => {
            if (rate !== undefined) {
              this.toastr.success('Work Order Test Flow has been successfully updated!', 'Successful!');
              return new UpdateworkordertestflowSuccess(action.payload)
            }
            // this.toastr.error('There was an error updating this rate', "Unknown error");
            return new workordertestflowsError({type: 500, message: 'Internal error'})
          }),
          catchError(error => of(new workordertestflowsError(error)))
        )
    )
  ));
  
  deleteWorkOrderTestFlow$ = createEffect(() => this.actions$.pipe(
    ofType(WorkorderTestFlowActionTypes.workordertestflowDeletetestflow),
    mergeMap((action: Deleteworkordertestflow) =>
      this.workOrderTestFlowService
        .deleteWorkOrderTestflow(action.payload)
        .pipe(
          map(() => new DeleteworkordertestflowSuccess(action.payload)),
          catchError(error => of(new workordertestflowsError(error)))
        )
    )
  ));

  constructor(
    private actions$: Actions,
    private workOrderTestFlowService: WorkorderTestFlowService,
    private toastr: ToastrService,
    private router: Router
  ) {
  }
}
