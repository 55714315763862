import { Action } from '@ngrx/store';

import { Tenant } from '@appModels/core-setup/tenant/tenant';

export enum TenantActionTypes {
  tenantGetTenants = '[Tenant] get',
  tenantGetTenantsSuccess = '[Tenant] get tenants success',
  tenantAddTenant = '[Tenant] add Tenant',
  tenantAddTenantAdmin = '[Tenant] add Tenant Admin',
  tenantAddTenantSuccess = '[Tenant] add tenant success',
  tenantDeleteTenant = '[Tenant] delete tenant',
  tenantDeleteTenantSuccess = '[Tenant] delete tenant success',
  tenantGetTenantById = '[Tenant] get tenant by id',
  tenantGetTenantByIdSuccess = '[Tenant] get tenant by id success',
  tenantUpdateTenant = '[Tenant] update tenant',
  tenantUpdateTenantSuccess = '[Tenant] update tenant success',
  tenantEditTenant = '[Tenant] edit tenant',
  tenantEditTenantSuccess = '[Tenant] edit tenant success',
  tenantSearchTenants = '[Tenant] search tenants',
  tenantSearchTenantsSuccess = '[Tenant] search tenants success',
  tenantSearchTenantsReset = '[Tenant] search tenants reset',
  tenantError = '[Tenant] error'
}

export class GetTenants implements Action {
  readonly type = TenantActionTypes.tenantGetTenants;
  constructor(public payload?) {}
}

export class GetTenantsSuccess implements Action {
  readonly type = TenantActionTypes.tenantGetTenantsSuccess;
  constructor(public payload) {}
}

export class AddTenant implements Action {
  readonly type = TenantActionTypes.tenantAddTenant;
  constructor(public payload: Tenant) {}
}

export class AddTenantAdmin implements Action {
  readonly type = TenantActionTypes.tenantAddTenantAdmin;
  constructor(public payload) {}
}

export class AddTenantSuccess implements Action {
  readonly type = TenantActionTypes.tenantAddTenantSuccess;
  constructor(public payload: Tenant) {}
}

export class GetTenantById implements Action {
  readonly type = TenantActionTypes.tenantGetTenantById;
  constructor(public payload: number) {}
}

export class GetTenantByIdSuccess implements Action {
  readonly type = TenantActionTypes.tenantGetTenantByIdSuccess;
  constructor(public payload: Tenant) {}
}

export class UpdateTenant implements Action {
  readonly type = TenantActionTypes.tenantUpdateTenant;
  constructor(public payload: Tenant) {}
}

export class UpdateTenantSuccess implements Action {
  readonly type = TenantActionTypes.tenantUpdateTenantSuccess;
  constructor(public payload: Tenant) {}
}

export class EditTenant implements Action {
  readonly type = TenantActionTypes.tenantEditTenant;
  constructor(public payload) {}
}

export class EditTenantSuccess implements Action {
  readonly type = TenantActionTypes.tenantEditTenantSuccess;
  constructor(public payload) {}
}

export class DeleteTenant implements Action {
  readonly type = TenantActionTypes.tenantDeleteTenant;
  constructor(public payload: Tenant) {}
}

export class DeleteTenantSuccess implements Action {
  readonly type = TenantActionTypes.tenantDeleteTenantSuccess;
  constructor(public payload: Tenant) {}
}

export class SearchTenants implements Action {
  readonly type = TenantActionTypes.tenantSearchTenants;
  constructor(public payload: string) {}
}

export class SearchTenantsSuccess implements Action {
  readonly type = TenantActionTypes.tenantSearchTenantsSuccess;
  constructor(public payload: Tenant[]) {}
}

export class SearchTenantsReset implements Action {
  readonly type = TenantActionTypes.tenantSearchTenantsReset;
}

export class TenantError implements Action {
  readonly type = TenantActionTypes.tenantError;
  constructor(public payload: any) {}
}

export type TenantActions =
  | GetTenants
  | GetTenantsSuccess
  | AddTenant
  | AddTenantAdmin
  | AddTenantSuccess
  | GetTenantById
  | GetTenantByIdSuccess
  | UpdateTenant
  | UpdateTenantSuccess
  | EditTenant
  | EditTenantSuccess
  | DeleteTenant
  | DeleteTenantSuccess
  | SearchTenants
  | SearchTenantsSuccess
  | SearchTenantsReset
  | TenantError;
