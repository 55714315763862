
<div class="modal-header">
  <strong>{{'New Ticket' | translate}}</strong>
  <div>
      <svg style="cursor:pointer" (click)="closeCreateTicketModal()" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 0.96 0.96" xml:space="preserve"><path d="M.87.48a.39.39 0 0 1-.39.39.39.39 0 0 1-.39-.39.39.39 0 0 1 .78 0" style="fill:#e54d2e"/><path d="M.48.09v.78a.39.39 0 0 0 0-.78" style="fill:#f27261"/><path d="M.48.09a.39.39 0 1 1-.001.781A.39.39 0 0 1 .48.09m0-.09a.48.48 0 1 0 0 .96.48.48 0 0 0 0-.96m.064.48L.618.406A.045.045 0 1 0 .554.342L.48.416.406.342a.045.045 0 1 0-.064.064L.416.48.342.554a.045.045 0 1 0 .064.064L.48.544l.074.074q.014.013.032.013C.604.631.609.627.618.618a.045.045 0 0 0 0-.064z" style="fill:#f4d6b0"/></svg>
  </div>
</div>
<div class="px-8 py-5">
  <form>

    <div class="mb-5">
      <form>
        <div class="dropdown">
          <label for="btnRight">Select Customer</label>
          <div class="flex">
            <input
              id="btnRight"
              type="text"
              placeholder="Search for Customers"
              class="form-input flex-1 rounded-r-none form-input-focus"
              [(ngModel)]="customerSearchQuery"
              [ngModelOptions]="{standalone: true}"
            />
            <div hlMenu>
              <btn
                hlMenuButton
                type="button"
                class="btn btn-dlight gap-2 flex h-full cursor-pointer items-center justify-center border border-dlight bg-dlight px-3 font-semibold text-white rounded-l-none"
                (click)="rotateSearch=true"
              >
                <div>
                  Search By
                </div>
                <svg class="ease-in-out duration-300" [ngClass]="{'rotate-90' : rotateSearch == true}" width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9 5L15 12L9 19" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
              </btn>
              <ul *hlMenuItems @toggleAnimation class="ltr:right-0 rtl:left-0">
                <li><a href="javascript:;" (click)="searchType = 'searchAccountNumber';searchCustomer()" *hlMenuItem="let menuItem">Account Number</a></li>
                <li><a href="javascript:;" (click)="searchType = 'searchIdentificationType';searchCustomer()" *hlMenuItem="let menuItem">Identification Value</a></li>
                <li><a href="javascript:;" (click)="searchType = 'searchCustomerName';searchCustomer()" *hlMenuItem="let menuItem">Name</a></li>
                <li><a href="javascript:;" (click)="searchType = 'searchPhoneNumber';searchCustomer()" *hlMenuItem="let menuItem">Phone Number</a></li>
              </ul>
            </div>

          </div>
        </div>

        <div class="mt-5 mb-5" *ngIf="loading">
          <div class="flex flex-row justify-center w-full  gap-4">
            <span class="m-auto mb-10 inline-block h-14 w-14 animate-spin rounded-full border-8 border-[#f1f2f3] border-l-dlight align-middle"></span>
          </div>
        </div>

        <ng-container *ngIf="customerResults && customerResults.length > 0 && !loading">
          <div class="grid grid-cols-4 py-3 mt-5 ticket-row ">
            <div class="col-span-1 m-auto">
              CUSTOMER
            </div>
            <div class="col-span-1 m-auto">
              IDENTIFICATION
            </div>
            <div class="col-span-1 m-auto">
              ACCOUNT NUMBER
            </div>
            <div class="col-span-1 m-auto">
              PHONE NUMBER
            </div>


          </div>
          <div *ngFor="let customer of customerResults" (click)="selectCustomer(customer.id)" class="cursor-pointer grid grid-cols-4 py-3 ticket-row small-box-shadow" >
            <div class="col-span-1 m-auto font-semibold">
              {{customer.firstName}} {{customer.lastName}}
            </div>
            <div class="col-span-1 m-auto">
              {{customer.identification}}
            </div>
            <div class="col-span-1 m-auto">
              {{customer.accountNumber}}
            </div>
            <div class="col-span-1 m-auto">
              {{customer.telephone1}}
            </div>
          </div>
        </ng-container>

      </form>
    </div>



    <div *ngIf="this.selectedCustomer" class="grid grid-cols-6 gap-1 px-4 py-4 rounded box-shadow-pop" style="background-color:#f3f4f8">

      <div class="items-center col-span-1">
        <img
          [src]="customerImage || DefaultImg()" class="flex-grow-0 cursor-pointer rounded"
          alt="user" width="90px">
      </div>
      <!-- CUSTOMER INFO -->
      <div class="col-span-2">
        <div class="flex flex-col justify-center">
          <div class="flex flex-row items-center justify-start gap-2">
            <div class="font-semibold text-lg">
              {{this.selectedCustomer.firstName}} {{this.selectedCustomer.lastName}}
            </div>
            <div>
              <span class="badge rounded-full bg-success/20 text-success hover:top-0">{{ this.selectedCustomer.customerStatus }}</span>

            </div>
          </div>
        </div>

        <div class="flex flex-row justify-start gap-2 items-center">
          <div class="font-semibold">ID Number:</div>
          <div>{{this.selectedCustomer.identificationValue}}</div>
        </div>

        <div class="flex flex-row justify-start gap-2 items-center">
          <div class="font-semibold">Phone Number:</div>
          <div>{{this.selectedCustomer.telephone1}}</div>
        </div>

      </div>

      <!-- PRODUCT INFO -->
      <div class="col-span-3 bg-white rounded-lg" style="border:2px solid #edeff1">
        <div class="grid grid-cols-4 flex-grow-0 py-2">
          <div class="col-span-1 m-auto">Device</div>
          <div class="col-span-1 m-auto">A/C Number</div>
          <div class="col-span-1 m-auto">Warranty Status</div>
          <div class="col-span-1 m-auto">Payment Speed</div>
        </div>

        <div class="grid grid-cols-4 flex-grow-0 ticket-row" *ngFor="let contract of this.selectedCustomer.contracts">
          <div class="col-span-1 m-auto font-semibold">{{contract.name}}</div>
          <div class="col-span-1 m-auto font-semibold">{{contract.accountNumber}}</div>
          <div class="col-span-1 m-auto font-semibold">--</div>
          <div class="col-span-1 m-auto" *ngIf="contract.paymentSpeed != null && contract.paymentSpeed == 'Blue'"><span class="badge rounded-full bg-info/20 text-info hover:top-0">Blue</span></div>
          <div class="col-span-1 m-auto" *ngIf="contract.paymentSpeed != null && contract.paymentSpeed == 'Green'"><span class="badge rounded-full bg-success/20 text-success hover:top-0">Red</span></div>
          <div class="col-span-1 m-auto" *ngIf="contract.paymentSpeed != null && contract.paymentSpeed == 'Yellow'"><span class="badge rounded-full bg-warning/20 text-warning hover:top-0">Red</span></div>
          <div class="col-span-1 m-auto" *ngIf="contract.paymentSpeed != null && contract.paymentSpeed == 'Red'"><span class="badge rounded-full bg-danger/20 text-danger hover:top-0">Red</span></div>
          <div class="col-span-1 m-auto" *ngIf="contract.paymentSpeed != null && contract.paymentSpeed == 'Grey'"><span class="badge rounded-full bg-gray-300 text-gray-500 hover:top-0">Grey</span></div>
          <div class="col-span-1 m-auto" *ngIf="contract.paymentSpeed == null">None</div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="this.selectedCustomer">

      <div class="grid grid-cols-3 gap-3 mt-3">
        <div class="col-span-2">
          <label class="label_title">Device To Action</label>

          <select (change)="selectDeviceToAction()" class="select_input form-input form-input-focus" placeholder="Select a Device..."  [(ngModel)]="ticket.contractId" name="selectedTokenId">
            <option value="" disabled>{{'Please select an account number' | translate}}</option>
            <option [value]="contract.id" *ngFor="let contract of selectedCustomer.contracts"> {{contract.name}} -- {{contract.accountNumber}}</option>
          </select>
        </div>
        <div class="col-span-1">
          <label class="label_title">Priority</label>

          <select [disabled]="!can('can-manage-ticket-priority')" class="select_input form-input form-input-focus" placeholder="Select a Priority..."  [(ngModel)]="ticket.ticketPriorityId" name="priorityId">
            <option value="" disabled>{{'Please select a priority' | translate}}</option>
            <option [value]="priority.id" *ngFor="let priority of priorities"> {{priority.name}}</option>
          </select>
        </div>
      </div>

      <div class="grid grid-cols-3 gap-3 mt-3">

        <div class="col-span-1">
          <label class="label_title">Ticket Source
            <span *ngIf="ticket.ticketSourceId == null || ticket.ticketSourceId == undefined" style="color:red">*</span>
            <span *ngIf="ticket.ticketSourceId != null && ticket.ticketSourceId != undefined" style="color:green">*</span>
          </label>

          <select class="select_input form-input form-input-focus" placeholder="Select Source..."  [(ngModel)]="ticket.ticketSourceId" name="sourceId">
            <option value="" disabled>{{'Please select Source' | translate}}</option>
            <option [value]="source.id" *ngFor="let source of sources"> {{source.name}}</option>
          </select>
        </div>

        <div class="col-span-1">
          <label class="label_title">Request Type
            <span *ngIf="ticket.requestTypeId == null || ticket.requestTypeId == undefined" style="color:red">*</span>
            <span *ngIf="ticket.requestTypeId != null && ticket.requestTypeId != undefined" style="color:green">*</span>
          </label>

          <select (change)="changeRequestType()" class="select_input form-input form-input-focus" placeholder="Select Type..."  [(ngModel)]="ticket.requestTypeId" name="requestTypeId">
            <option value="" disabled>{{'Please select Type' | translate}}</option>
            <option [value]="type.id" *ngFor="let type of requestTypes"> {{type.name}}</option>
          </select>
        </div>

        <div class="col-span-1" >
          <ng-container *ngIf="!warrantyAndServiceTicket && !repossessionTicket">
            <label class="label_title">Assignee</label>

            <input [disabled]="!can('can-manage-ticket-assignees')" type="text"  (keyup)="searchUsers()" [(ngModel)]="userSearch" name="userSearch" class="form-input form-input-focus" >

            <div class="relative">
            <div  *ngIf="showUserDropdown" class="box-shadow-pop" style="border-radius:10px;position:absolute;z-index:999;top:0px;min-width:100px;min-height:30px;background-color: white">
              <div class="mt-5 mb-5" *ngIf="loadingUserSearch">
                <div class="flex flex-row justify-center w-full  gap-4">
                  <span class="m-auto mb-10 inline-block h-14 w-14 animate-spin rounded-full border-8 border-[#f1f2f3] border-l-dlight align-middle"></span>
                </div>
              </div>
              <ng-container *ngIf="!loadingUserSearch">
                <div (click)="selectUser(user)" *ngFor="let user of userSearchResults" style="padding:3px;padding-right:6px; border-radius:20px"
                     class="cursor-pointer ease-in-out duration-300 hover:bg-gray-100 grid grid-cols-5 items-center justify-start gap-1">
                  <div class="col-span-1">
                    <span  class="flex h-8 w-8 items-center justify-center rounded-full bg-primary object-cover text-center text-base text-white"
                           [ngStyle]="{'background':user.colorCode}">
                      {{user.initials}}
                    </span>
                  </div>
                  <span class="col-span-4 assignee-box" >
                      {{user.name}}
                    </span>
                </div>
              </ng-container>
            </div>
            </div>
          </ng-container>
        </div>
      </div>

      <div class="grid grid-cols-3 gap-5 mt-3">

          <div class="col-span-2 relative">
            <label class="label_title">{{'Tagged Users and Teams' | translate}}</label>
            <input [disabled]="!can('can-tag-users-on-tickets')" type="text"  (keyup)="searchUsersTagged()" [(ngModel)]="userSearchTagged" name="userSearchTagged" class="form-input form-input-focus" >
            <div class="relative">
              <div  *ngIf="showUserDropdownTagged" class="box-shadow-pop" style="border-radius:10px;position:absolute;z-index:999;top:5px;width:300px;min-height:30px;background-color: white">
                <div class="mt-5 mb-5" *ngIf="loadingUserSearchTagged">
                  <div class="flex flex-row justify-center w-full  gap-4">
                    <span class="m-auto mb-10 inline-block h-14 w-14 animate-spin rounded-full border-8 border-[#f1f2f3] border-l-dlight align-middle"></span>
                  </div>
                </div>
                <ng-container *ngIf="!loadingUserSearchTagged">
                  <div (click)="selectUserTagged(user)" *ngFor="let user of userSearchResultsTagged" style="padding:3px;padding-right:6px; border-radius:20px"
                       class="cursor-pointer ease-in-out duration-300 hover:bg-gray-100 grid grid-cols-5 items-center justify-start gap-1">
                  <span  class="flex h-8 w-8 col-span-1 items-center justify-center rounded-full bg-primary object-cover text-center text-base text-white"
                         [ngStyle]="{'background':user.colorCode}">
                    {{user.initials}}
                  </span>
                    <span  class="col-span-4 assignee-box">
                    {{user.name}}
                  </span>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="col-span-1 flex flex-col justify-end">
            <div>
              <div *ngFor="let user of ticket.taggedUsers" class="avatar-box stacked " style="position:relative;margin-left:-8px;display:inline-block;cursor:pointer;"  [tooltip]="user.name" >
                <span id="example-avatar" (click)="removeTaggedUser(user)" class="cursor-pointer avatar avatar-sm avatar-shadow" style="outline-style:solid;outline-width: 1px;" [ngStyle]="{'background': user.colorCode, 'color':'white'}">
                    {{user.initials}}
                </span>
              </div>
            </div>
          </div>
      </div>

      <ng-container *ngIf="warrantyAndServiceTicket">
        <warranty-ticket-directive  (outputCreateWarrantyTicketEvent)="createWarrantyTicketEvent($event)" [setAccountNumber]="ticket.accountNumber" [setCustomerId]="ticket.customerId"></warranty-ticket-directive>
      </ng-container>

      <ng-container *ngIf="repossessionTicket">
        <repossession-ticket-directive (outputAddAttachment)="uploadImageEvent($event)" (outputCreateRepossessionTicket)="createRepossessionTicketEvent($event)"   [setCustomerId]="ticket.customerId" [setContractId]="ticket.contractId"  ></repossession-ticket-directive>
      </ng-container>

      <div *ngIf="customFields.length > 0" class="mt-8">
        <div class="font-semibold text-lg">Additional Fields</div>
        <div class="text-dlight">
          <hr class="h-px border-t-0 bg-transparent bg-gradient-to-r from-red-500 via-red-500 to-transparent opacity-25">
        </div>

        <div class="grid grid-cols-1 gap-3">
          <div *ngFor="let customField of customFields" >
            <div class="col-span-1 mt-3" *ngIf="customField.customFieldTypeId == 1">
              <div class="flex flex-row justify-start gap-3 items-baseline mt-3">
                <label class="label_title">{{customField.name}}</label>
<!--                <div *ngIf="customField.description" class="cursor-pointer" [tooltip]="customField.description"><svg fill="#FF4613" width="18" height="18" viewBox="-0.045 -0.045 0.54 0.54" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin" class="jam jam-info"><path d="M0.225 0.45C0.101 0.45 0 0.349 0 0.225S0.101 0 0.225 0s0.225 0.101 0.225 0.225 -0.101 0.225 -0.225 0.225m0 -0.045a0.18 0.18 0 1 0 0 -0.36 0.18 0.18 0 0 0 0 0.36m0 -0.225a0.022 0.022 0 0 1 0.022 0.022v0.112a0.022 0.022 0 0 1 -0.045 0V0.202a0.022 0.022 0 0 1 0.022 -0.022m0 -0.022a0.022 0.022 0 1 1 0 -0.045 0.022 0.022 0 0 1 0 0.045"/></svg></div>-->
                <div *ngIf="customField.description" class="text-sm">({{customField.description}})</div>
              </div>
              <input type="text" class="form-input form-input-focus" placeholder="Input Answer" [ngModelOptions]="{standalone: true}" [(ngModel)]="customField.answer">
            </div>

            <div class="col-span-1 mt-3" *ngIf="customField.customFieldTypeId == 2">
              <div class="flex flex-row justify-start gap-3 items-baseline mt-3">
                <label class="label_title">{{customField.name}}</label>
                <div *ngIf="customField.description" class="text-sm">({{customField.description}})</div>
              </div>
              <input type="date" placeholder="Select Date" class="form-input form-input-focus" [ngModelOptions]="{standalone: true}" [(ngModel)]="customField.answer">
            </div>

            <div class="col-span-1 mt-3" *ngIf="customField.customFieldTypeId == 3">
              <div class="flex flex-row justify-start gap-3 items-baseline mt-3">
                <label class="label_title">{{customField.name}}</label>
                <div *ngIf="customField.description" class="text-sm">({{customField.description}})</div>
              </div>
              <select class="select_input form-input form-input-focus" placeholder="Select an Option..."  [(ngModel)]="customField.answer"  [ngModelOptions]="{standalone: true}">
                <option value="" disabled>{{'Please select an account number' | translate}}</option>
                <option [value]="option.value" *ngFor="let option of customField.customFieldOptions"> {{option.value}}</option>
              </select>
            </div>

          </div>
        </div>

        <ng-container *ngFor="let customField of customFields">
          <div *ngIf="customField.customFieldTypeId == 4 && customField.name == 'Promise To Pay Amount'" >
            <div class="flex flex-row justify-start gap-3 items-baseline mt-3">
              <label class="label_title">{{customField.name}}</label>
              <div *ngIf="customField.description" class="cursor-pointer" [tooltip]="customField.description"><svg fill="#FF4613" width="18" height="18" viewBox="-0.045 -0.045 0.54 0.54" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin" class="jam jam-info"><path d="M0.225 0.45C0.101 0.45 0 0.349 0 0.225S0.101 0 0.225 0s0.225 0.101 0.225 0.225 -0.101 0.225 -0.225 0.225m0 -0.045a0.18 0.18 0 1 0 0 -0.36 0.18 0.18 0 0 0 0 0.36m0 -0.225a0.022 0.022 0 0 1 0.022 0.022v0.112a0.022 0.022 0 0 1 -0.045 0V0.202a0.022 0.022 0 0 1 0.022 -0.022m0 -0.022a0.022 0.022 0 1 1 0 -0.045 0.022 0.022 0 0 1 0 0.045"/></svg></div>
            </div>
            <input type="text" class="form-input form-input-focus" placeholder="Input Answer" [ngModelOptions]="{standalone: true}" [(ngModel)]="customField.answer">
          </div>

          <div *ngIf="customField.customFieldTypeId == 4 && customField.name == 'Promise To Pay Date'" >
            <div class="flex flex-row justify-start gap-1 items-top mt-3">
              <label class="label_title">{{customField.name}}</label>
              <div *ngIf="customField.description" class="cursor-pointer" [tooltip]="customField.description"><svg fill="#FF4613" width="18" height="18" viewBox="-0.045 -0.045 0.54 0.54" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin" class="jam jam-info"><path d="M0.225 0.45C0.101 0.45 0 0.349 0 0.225S0.101 0 0.225 0s0.225 0.101 0.225 0.225 -0.101 0.225 -0.225 0.225m0 -0.045a0.18 0.18 0 1 0 0 -0.36 0.18 0.18 0 0 0 0 0.36m0 -0.225a0.022 0.022 0 0 1 0.022 0.022v0.112a0.022 0.022 0 0 1 -0.045 0V0.202a0.022 0.022 0 0 1 0.022 -0.022m0 -0.022a0.022 0.022 0 1 1 0 -0.045 0.022 0.022 0 0 1 0 0.045"/></svg></div>
            </div>
            <input type="date" class="form-input form-input-focus" placeholder="Input Answer" [ngModelOptions]="{standalone: true}" [(ngModel)]="customField.answer">
          </div>
        </ng-container>

      </div>



      <input type="file" hidden  multiple="multiple"  id="imageUploadButton" style="display:none;" (change)="onImageSelected($event)">

      <div *ngIf="fileUploads && fileUploads.length > 0" class="mt-3">
        <div class="font-semibold">File Attachments </div>
        <div class="grid grid-cols-3 gap-2">

          <div *ngFor="let file of fileUploads" (click)="downloadFile(file)" [ngClass]="{'cursor-pointer' : file.finished}" class=" col-span-1 grid grid-cols-4 gap-1 border border-sky-100 rounded-xl p-1 hover:bg-gray-100 ease-in-out duration-300 " style="max-height:120px">
            <div class="col-span-2 items-center flex flex-row justify-center ">
              <div class="flex items-center">
                <ng-container *ngIf="file.finished">
                  <svg *ngIf="!file.image" width="40" height="40" viewBox="0 0 1.2 1.2" xmlns="http://www.w3.org/2000/svg"><path d="M1 0.447 0.997 0.433V0.428L0.987 0.415l-0.3 -0.3L0.673 0.105H0.668L0.653 0.1H0.35a0.15 0.15 0 0 0 -0.15 0.15v0.7a0.15 0.15 0 0 0 0.15 0.15h0.5A0.15 0.15 0 0 0 1 0.95zM0.7 0.27 0.83 0.4H0.7ZM0.9 0.95A0.05 0.05 0 0 1 0.85 1h-0.5A0.05 0.05 0 0 1 0.3 0.95V0.25A0.05 0.05 0 0 1 0.35 0.2h0.25v0.25A0.05 0.05 0 0 0 0.65 0.5h0.25Z"/></svg>
                  <img style="max-height:120px" *ngIf="file.image" [src]="file.src">

                </ng-container>
                <div *ngIf="!file.finished" class="flex flex-row justify-center items-center">
                  <span class="m-auto w-8 h-8  inline-block animate-spin rounded-full border-8 border-[#f1f2f3] border-l-dlight align-middle"></span>
                </div>
              </div>

            </div>

            <div class="col-span-1 flex items-center break-all	" style="text-overflow: ellipsis;">
              {{file.name}}
            </div>

            <div class="col-span-1 flex flex-row justify-end items-center">
                <span  class="badge bg-success rounded-full ng-tns-c282470920-0" style="background-color: #fbd9d4; color: #ed6754;">
                  {{file.name.split('.').pop()}}
                </span>
            </div>

          </div>
        </div>
      </div>
      <div *ngIf="!warrantyAndServiceTicket && !repossessionTicket" class="grid grid-cols-3 gap-3 mt-3">
        <div class="form_group col-span-2">



          <div class="input mt-2">
            <label class="label_title">{{'Content' | translate}}</label>
            <editor
              [(ngModel)]="ticket.notes"
              name="mainNotes" id="mainNotes"
              [init]="config"></editor>

          </div>
        </div>

        <div class="col-span-1 small-box-shadow flex justif-center cursor-pointer" (click)="clickImageUploadButton()" style="background-color: #f3f4f8">
          <div class="m-auto">
            <div class="flex justify-center">
              <svg width="32" height="32" viewBox="0 0 0.6 0.6" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M.1.04h.4A.06.06 0 0 1 .56.1v.4A.06.06 0 0 1 .5.56H.1A.06.06 0 0 1 .04.5V.1A.06.06 0 0 1 .1.04m0 .04A.02.02 0 0 0 .08.1v.235L.147.268a.02.02 0 0 1 .026 0L.315.42.427.308a.02.02 0 0 1 .025 0L.52.375V.1A.02.02 0 0 0 .5.08zM.08.5V.385l.08-.08.142.152.056.063H.1A.02.02 0 0 1 .08.5M.5.52H.406L.339.446.44.345l.08.08V.5A.02.02 0 0 1 .5.52M.266.22a.034.034 0 1 1 .068 0 .034.034 0 0 1-.068 0M.3.15a.07.07 0 1 0 0 .14.07.07 0 0 0 0-.14" fill="#000"/></svg>
            </div>
            <div class="text-white-dark flex justify-center">Drag & Drop</div>
            <div class="text-white-dark flex justify-center">OR</div>
            <div class="text-red-500 flex justify-center">Browse Photo</div>
            <div class="text-white-dark flex justify-center">Supports *.png, *.jpg and *.jpeg</div>
          </div>
        </div>
      </div>

    </ng-container>

  </form>
</div>

<div class="modal-footer" *ngIf="!warrantyAndServiceTicket && !repossessionTicket">
  <div class="flex flex-row w-full justify-center">
    <ng-container *ngIf="creatingTicket">
      <div class="flex flex-row justify-center w-full  gap-4">
        <span class="m-auto mb-10 inline-block h-14 w-14 animate-spin rounded-full border-8 border-[#f1f2f3] border-l-dlight align-middle"></span>
      </div>
    </ng-container>
    <button *ngIf="!creatingTicket && !ticketCreated" (click)="createTicket()" type="button" class="mt-10 btn btn-success btn-lg">Create Ticket</button>
  </div>
</div>
