import {Action} from '@ngrx/store';

import {SalesStaff} from '@appModels/core-fieldforce/sales-staff/sales-staff';

export enum SalesStaffActionTypes {
  salesStaffGetStaffs = '[SalesStaff] get',
  salesStaffGetStaffsSuccess = '[SalesStaff] get salesStaff success',
  salesStaffGeStaffsByTenantId = '[SalesStaff] get sales staff  by tenant id',
  salesStaffGeStaffsByTenantIdSuccess = '[SalesStaff] get sales staff  by tenant id success',
  salesStaffAddStaff = '[SalesStaff] add salesStaff',
  salesStaffAddSStaffSuccess = '[SalesStaff] add salesStaff success',
  salesStaffAddStaffAttributes = '[SalesStaff] add salesStaff Attributesd',
  salesStaffAddStaffAttributesSuccess = '[SalesStaff] add additional salesStaff Info success',
  salesStaffDeleteStaff = '[SalesStaff] delete salesStaff',
  salesStaffDeleteStaffSuccess = '[SalesStaff] delete salesStaff success',
  salesStaffGetStaffById = '[SalesStaff] get salesStaff by id',
  salesStaffGetStaffByIdSuccess = '[SalesStaff] get salesStaff by id success',
  salesStaffUpdateStaff = '[SalesStaff] update salesStaff',
  salesStaffUpdateStaffSuccess = '[SalesStaff] update salesStaff success',
  salesStaffSearchStaffs = '[SalesStaff] search salesStaff',
  salesStaffSearchStaffsSuccess = '[SalesStaff] search salesStaff success',
  salesStaffSearchStaffsReset = '[SalesStaff] search salesStaff reset',
  salesStaffFilterSalesStaff = '[SalesStaff] filter salesStaff ',
  salesStaffFilterSalesStaffSuccess = '[SalesStaff] filter salesStaff success',
  salesStaffsError = '[SalesStaff] error',
  salesStaffGenderUpdate = '[SalesStaff] genderUpdate'
}

export class GetSalesStaffs implements Action {
  readonly type = SalesStaffActionTypes.salesStaffGetStaffs;
}

export class GetSalesStaffsSuccess implements Action {
  readonly type = SalesStaffActionTypes.salesStaffGetStaffsSuccess;

  constructor(public payload: SalesStaff[]) {
  }
}

export class AddSalesStaff implements Action {
  readonly type = SalesStaffActionTypes.salesStaffAddStaff;

  constructor(public payload: SalesStaff) {
  }
}

export class AddSalesStaffSuccess implements Action {
  readonly type = SalesStaffActionTypes.salesStaffAddSStaffSuccess;

  constructor(public payload: SalesStaff) {
  }
}



export class FilterSalesStaff implements Action {
  readonly type = SalesStaffActionTypes.salesStaffFilterSalesStaff;

  constructor(public payload?) {
  }
}

export class FilterSalesStaffSuccess implements Action {
  readonly type = SalesStaffActionTypes.salesStaffFilterSalesStaffSuccess;

  constructor(public payload) {
  }
}

export class AddAttributes implements Action {
  readonly type = SalesStaffActionTypes.salesStaffAddStaffAttributes;

  constructor(public payload: SalesStaff) {
  }
}

export class AddAttributesSuccess implements Action {
  readonly type = SalesStaffActionTypes.salesStaffAddStaffAttributesSuccess;

  constructor(public payload: SalesStaff) {
  }
}

export class GetSalesStaffById implements Action {
  readonly type = SalesStaffActionTypes.salesStaffGetStaffById;

  constructor(public payload: number) {
  }
}

export class GetSalesStaffByIdSuccess implements Action {
  readonly type = SalesStaffActionTypes.salesStaffGetStaffByIdSuccess;

  constructor(public payload: SalesStaff) {
  }
}

export class UpdateSalesStaff implements Action {
  readonly type = SalesStaffActionTypes.salesStaffUpdateStaff;

  constructor(public payload: SalesStaff) {
  }
}

export class UpdateSalesStaffSuccess implements Action {
  readonly type = SalesStaffActionTypes.salesStaffUpdateStaffSuccess;

  constructor(public payload: SalesStaff) {
  }
}

export class DeleteSalesStaff implements Action {
  readonly type = SalesStaffActionTypes.salesStaffDeleteStaff;

  constructor(public payload: SalesStaff) {
  }
}

export class DeleteSalesStaffSuccess implements Action {
  readonly type = SalesStaffActionTypes.salesStaffDeleteStaffSuccess;

  constructor(public payload: SalesStaff) {
  }
}

export class SearchSalesStaffs implements Action {
  readonly type = SalesStaffActionTypes.salesStaffSearchStaffs;

  constructor(public payload: string) {
  }
}

export class SearchSalesStaffsSuccess implements Action {
  readonly type = SalesStaffActionTypes.salesStaffSearchStaffsSuccess;

  constructor(public payload: SalesStaff[]) {
  }
}

export class GetSalesStaffsByTenantId implements Action {
  readonly type = SalesStaffActionTypes.salesStaffGeStaffsByTenantId;

  constructor(public payload: number) {
  }
}

export class GetSalesStaffsByTenantIdIdSuccess implements Action {
  readonly type = SalesStaffActionTypes.salesStaffGeStaffsByTenantIdSuccess;

  constructor(public payload: SalesStaff[]) {
  }
}

export class SearchSalesStaffsReset implements Action {
  readonly type = SalesStaffActionTypes.salesStaffSearchStaffsReset;
}

export class SalesStaffsError implements Action {
  readonly type = SalesStaffActionTypes.salesStaffsError;

  constructor(public payload: any) {
  }
}

export class SalesStaffGenderUpdate implements Action {
  readonly type = SalesStaffActionTypes.salesStaffGenderUpdate;

  constructor(public payload: {gender:string}) {
  }
}

export type SalesStaffActions =
  | GetSalesStaffs
  | GetSalesStaffsSuccess
  | AddSalesStaff
  | AddSalesStaffSuccess
  | AddAttributes
  | AddAttributesSuccess
  | GetSalesStaffById
  | GetSalesStaffByIdSuccess
  | UpdateSalesStaff
  | UpdateSalesStaffSuccess
  | DeleteSalesStaff
  | DeleteSalesStaffSuccess
  | SearchSalesStaffs
  | SearchSalesStaffsSuccess
  | SearchSalesStaffsReset
  | GetSalesStaffsByTenantId
  | GetSalesStaffsByTenantIdIdSuccess
  | FilterSalesStaff
  | FilterSalesStaffSuccess
  | SalesStaffsError
  | SalesStaffGenderUpdate;
