import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';

import {Route} from '@appModels/core-fieldforce/route/route';
import {RouteActions, RouteActionTypes} from '@appStore/actions/core-fieldforce/route/route.actions';
import * as _ from 'lodash';

export interface State extends EntityState<Route> {
  loaded: boolean;
  loading: boolean;
  error: any;
  selectedRouteId: number;
  searchRoutes: Route[];
  paging: any;
}

export const adapter: EntityAdapter<Route> = createEntityAdapter<Route>();

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
  selectedRouteId: null,
  error: null,
  searchTerm: '',
  searchRoutes: null,
  paging: null
});

export function reducer(state = initialState, action: RouteActions): State {
  switch (action.type) {
    case RouteActionTypes.routeGetRoutes:
    case RouteActionTypes.routeGetRouteByTenant:
    case RouteActionTypes.routeAddRoute:
    case RouteActionTypes.routeAddAdditionalRouteInfo:
    case RouteActionTypes.routeDeleteRoute:
    case RouteActionTypes.routeUpdateRoute:
    case RouteActionTypes.routeSearchRoutes:
    case RouteActionTypes.routeGetRouteById:

      return {
        ...state,
        loading: true
      };

    case RouteActionTypes.routeGetRoutesSuccess:
      return adapter.setAll(action.payload.content, {
        ...state,
        loading: false,
        loaded: true,
        paging: _.omit(action.payload, ['content'])
      });

    case RouteActionTypes.routeGetRouteByTenantSuccess:
      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });

    case RouteActionTypes.routeGetRouteByIdSuccess:
      return {
        ...state,
        selectedRouteId: action.payload.id,
        loading: false
      };

    case RouteActionTypes.routeAddRouteSuccess:
      return adapter.addOne(action.payload, {
        ...state,
        selectedRouteId: action.payload.id,
        loading: false,
        loaded: true
      });

    case RouteActionTypes.routeUpdateRouteSuccess: {
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state,
          loading: false,
          loaded: true
        }
      );
    }

    case RouteActionTypes.routeDeleteRouteSuccess: {
      return adapter.removeOne(action.payload.id, {
        ...state,
        loading: false,
        loaded: true
      });
    }

    case RouteActionTypes.routeSearchRoutesSuccess:
      return {
        ...state,
        searchRoutes: action.payload,
        loading: false
      };

    case RouteActionTypes.routeSearchRoutesReset:
      return {
        ...state,
        searchRoutes: null
      };

    case RouteActionTypes.routeError:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload
      };

    default:
      return state;
  }
}

export const routeEntitySelectors = adapter.getSelectors();
