import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getProductTypeStore = createFeatureSelector('productType');

export const getProductTypeEntities = createSelector(
  getProductTypeStore,
  fromReducers.productType.productTypeEntitySelectors.selectAll
);

export const getProductTypes = createSelector(getProductTypeEntities, entities => {
  return Object.values(entities);
});

export const getTopProductTypes = createSelector(getProductTypeEntities, entities => {
  return Object.values(entities).slice(1, 5);
});

export const getProductTypesLoaded = createSelector(
  getProductTypeStore,
  (productTypeStore: fromReducers.productType.State) => productTypeStore.loaded
);

export const getProductTypesLoading = createSelector(
  getProductTypeStore,
  (productTypeStore: fromReducers.productType.State) => productTypeStore.loading
);

export const getSelectedProductTypeId = createSelector(
  getProductTypeStore,
  (productTypeStore: fromReducers.productType.State) => productTypeStore.selectedProductTypeId
);

export const getSearchProductTypes = createSelector(
  getProductTypeStore,
  (productTypeStore: fromReducers.productType.State) => productTypeStore.searchProductTypes
);

export const getProductTypeById = createSelector(
  getProductTypeEntities,
  getSelectedProductTypeId,
  (entities, id) => entities.find(i => i.id === id)
);

export const getProductTypesError = createSelector(
  getProductTypeStore,
  (productTypeStore: fromReducers.productType.State) => productTypeStore.error
);
