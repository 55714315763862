import { Action } from '@ngrx/store';

import { TimeInterval } from '@appModels/core-setup/time-interval/time-interval';

export enum TimeIntervalActionTypes {
  timeIntervalGetTimeIntervals = '[TimeInterval] get',
  timeIntervalGetTimeIntervalsSuccess = '[TimeInterval] get timeIntervals success',
  timeIntervalAddTimeInterval = '[TimeInterval] add TimeInterval',
  timeIntervalAddTimeIntervalSuccess = '[TimeInterval] add timeInterval success',
  timeIntervalDeleteTimeInterval = '[TimeInterval] delete timeInterval',
  timeIntervalDeleteTimeIntervalSuccess = '[TimeInterval] delete timeInterval success',
  timeIntervalGetTimeIntervalById = '[TimeInterval] get timeInterval by id',
  timeIntervalGetTimeIntervalByIdSuccess = '[TimeInterval] get timeInterval by id success',
  timeIntervalUpdateTimeInterval = '[TimeInterval] update timeInterval',
  timeIntervalUpdateTimeIntervalSuccess = '[TimeInterval] update timeInterval success',
  timeIntervalSearchTimeIntervals = '[TimeInterval] search timeIntervals',
  timeIntervalSearchTimeIntervalsSuccess = '[TimeInterval] search timeIntervals success',
  timeIntervalSearchTimeIntervalsReset = '[TimeInterval] search timeIntervals reset',
  timeIntervalError = '[TimeInterval] error'
}

export class GetTimeIntervals implements Action {
  readonly type = TimeIntervalActionTypes.timeIntervalGetTimeIntervals;
}

export class GetTimeIntervalsSuccess implements Action {
  readonly type = TimeIntervalActionTypes.timeIntervalGetTimeIntervalsSuccess;
  constructor(public payload: TimeInterval[]) {}
}

export class AddTimeInterval implements Action {
  readonly type = TimeIntervalActionTypes.timeIntervalAddTimeInterval;
  constructor(public payload: TimeInterval) {}
}

export class AddTimeIntervalSuccess implements Action {
  readonly type = TimeIntervalActionTypes.timeIntervalAddTimeIntervalSuccess;
  constructor(public payload: TimeInterval) {}
}

export class GetTimeIntervalById implements Action {
  readonly type = TimeIntervalActionTypes.timeIntervalGetTimeIntervalById;
  constructor(public payload: number) {}
}

export class GetTimeIntervalByIdSuccess implements Action {
  readonly type = TimeIntervalActionTypes.timeIntervalGetTimeIntervalByIdSuccess;
  constructor(public payload: TimeInterval) {}
}

export class UpdateTimeInterval implements Action {
  readonly type = TimeIntervalActionTypes.timeIntervalUpdateTimeInterval;
  constructor(public payload: TimeInterval) {}
}

export class UpdateTimeIntervalSuccess implements Action {
  readonly type = TimeIntervalActionTypes.timeIntervalUpdateTimeIntervalSuccess;
  constructor(public payload: TimeInterval) {}
}

export class DeleteTimeInterval implements Action {
  readonly type = TimeIntervalActionTypes.timeIntervalDeleteTimeInterval;
  constructor(public payload: TimeInterval) {}
}

export class DeleteTimeIntervalSuccess implements Action {
  readonly type = TimeIntervalActionTypes.timeIntervalDeleteTimeIntervalSuccess;
  constructor(public payload: TimeInterval) {}
}

export class SearchTimeIntervals implements Action {
  readonly type = TimeIntervalActionTypes.timeIntervalSearchTimeIntervals;
  constructor(public payload: string) {}
}

export class SearchTimeIntervalsSuccess implements Action {
  readonly type = TimeIntervalActionTypes.timeIntervalSearchTimeIntervalsSuccess;
  constructor(public payload: TimeInterval[]) {}
}

export class SearchTimeIntervalsReset implements Action {
  readonly type = TimeIntervalActionTypes.timeIntervalSearchTimeIntervalsReset;
}

export class TimeIntervalError implements Action {
  readonly type = TimeIntervalActionTypes.timeIntervalError;
  constructor(public payload: any) {}
}

export type TimeIntervalActions =
  | GetTimeIntervals
  | GetTimeIntervalsSuccess
  | AddTimeInterval
  | AddTimeIntervalSuccess
  | GetTimeIntervalById
  | GetTimeIntervalByIdSuccess
  | UpdateTimeInterval
  | UpdateTimeIntervalSuccess
  | DeleteTimeInterval
  | DeleteTimeIntervalSuccess
  | SearchTimeIntervals
  | SearchTimeIntervalsSuccess
  | SearchTimeIntervalsReset
  | TimeIntervalError;
