import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getTenantInventoryProductLocationStatusStore = createFeatureSelector('tenantInventoryProductLocationStatus');

export const getTenantInventoryProductLocationStatusEntities = createSelector(
  getTenantInventoryProductLocationStatusStore,
  fromReducers.tenantInventoryProductLocationStatus.tenantInventoryProductLocationStatusSelectors.selectAll
);

export const getInventoryProductLocationStatuses = createSelector(getTenantInventoryProductLocationStatusEntities, entities => {
  return Object.values(entities);
});

export const getInventoryProductLocationStatusLoaded = createSelector(
  getTenantInventoryProductLocationStatusStore,
  (inventoryProductStore: fromReducers.tenantInventoryProductLocationStatus.State) => inventoryProductStore.loaded
);

export const getInventoryProductLocationStatusLoading = createSelector(
  getTenantInventoryProductLocationStatusStore,
  (inventoryProductStore: fromReducers.tenantInventoryProductLocationStatus.State) => inventoryProductStore.loading
);


export const getInventoryProductLocationStatusCount = createSelector(
  getTenantInventoryProductLocationStatusStore,
  (inventoryProductStore: fromReducers.tenantInventoryProductLocationStatus.State) => inventoryProductStore.count
);

export const getInventoryProductLocationStatusStartPage = createSelector(
  getTenantInventoryProductLocationStatusStore,
  (inventoryProductStore: fromReducers.tenantInventoryProductLocationStatus.State) => inventoryProductStore.startPage
);


export const getInventoryProductLocationStatusNumberPerPage = createSelector(
  getTenantInventoryProductLocationStatusStore,
  (inventoryProductStore: fromReducers.tenantInventoryProductLocationStatus.State) => inventoryProductStore.numberPerPage
);
