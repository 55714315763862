import {Action} from '@ngrx/store';
import {SoftLaunchProject} from '../../../../models/core-soft-launch/soft-launch-project';

export enum SoftLaunchProjectActionTypes {
  softLaunchProjectAdd = '[Soft Launch] Add',
  softLaunchProjectAddSuccess = '[Soft Launch] Add Success',
  softLaunchProjectError = '[Soft Launch] Add Error',
  softLaunchProjectGetAll = '[Soft Launch] Get All',
  softLaunchProjectGetAllSuccess = '[Soft Launch] Get All Success',
  softLaunchProjectGet = '[Soft Launch] Get',
  softLaunchProjectGetSuccess = '[Soft Launch] Get Success'
}

export class AddSoftLaunchProject implements Action {
  readonly type = SoftLaunchProjectActionTypes.softLaunchProjectAdd;

  constructor(public payload) {
  }
}

export class AddSoftLaunchProjectSuccess implements Action {
  readonly type = SoftLaunchProjectActionTypes.softLaunchProjectAddSuccess;

  constructor(public payload: any) {
  }
}

export class SoftLaunchProjectError implements Action {
  readonly type = SoftLaunchProjectActionTypes.softLaunchProjectError;

  constructor(public payload: any) {
  }
}

export class GetAllSoftLaunchProject implements Action {
  readonly type = SoftLaunchProjectActionTypes.softLaunchProjectGetAll;

  constructor(public payload: any) {
  }
}

export class GetAllSoftLaunchProjectSuccess implements Action {
  readonly type = SoftLaunchProjectActionTypes.softLaunchProjectGetAllSuccess;

  constructor(public payload: any) {
  }
}

export class GetSoftLaunchProject implements Action {
  readonly type = SoftLaunchProjectActionTypes.softLaunchProjectGet;

  constructor(public payload: any) {
  }
}

export class GetSoftLaunchProjectSuccess implements Action {
  readonly type = SoftLaunchProjectActionTypes.softLaunchProjectGetSuccess;

  constructor(public payload: SoftLaunchProject) {
  }
}

export type SoftLaunchProjectActions =
  | AddSoftLaunchProject
  | AddSoftLaunchProjectSuccess
  | SoftLaunchProjectError
  | GetAllSoftLaunchProject
  | GetAllSoftLaunchProjectSuccess
  | GetSoftLaunchProject
  | GetSoftLaunchProjectSuccess;



