import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap} from "rxjs/operators";
import {of} from "rxjs";
import {OOWRepairConditionService} from '../../../../services/core-masterdata/oow-repair-condition/oow-repair-condition.service';
import {
  DisableTenantOowRepairConditions, DisableTenantOowRepairConditionsSuccess,
  GetAllTenantOowRepairConditions,
  GetAllTenantOowRepairConditionsSuccess,
  SaveTenantOowRepairConditions,
  SaveTenantOowRepairConditionsSuccess,
  TenantOowRepairConditionsActionTypes,
  TenantOowRepairConditionsError
} from '../../../actions/core-masterdata/oow-repair-conditions/tenant-oow-repair-conditions.actions';
import {ToastrService} from "ngx-toastr";
import {Router} from "@angular/router";

@Injectable()
export class TenantOowRepairConditionsEffects {

  
  oowRepairConditions = createEffect(() => this.actions$.pipe(
    ofType(TenantOowRepairConditionsActionTypes.tenantOowRepairConditionsGetAll),
    mergeMap((action: GetAllTenantOowRepairConditions) =>
      this.oowRepairConditionService
        .getTenantOOwRepairConditions()
        .pipe(
          map(oowRepairConditions => new GetAllTenantOowRepairConditionsSuccess(oowRepairConditions)),
          catchError(error => of(new TenantOowRepairConditionsError(error)))
        )
    )
  ));

  
  saveOowRepairConditions = createEffect(() => this.actions$.pipe(
    ofType(TenantOowRepairConditionsActionTypes.tenantOowRepairConditionsSave),
    mergeMap((action: SaveTenantOowRepairConditions) =>
      this.oowRepairConditionService
        .saveTenantOOwRepairConditions(action.payload)
        .pipe(
          map(oowRepairConditions => {
            this.toastr.success('Condition added successfully!', 'Successful!');
            this.router.navigateByUrl('/setup/oow-repair-conditions');
            return new SaveTenantOowRepairConditionsSuccess(oowRepairConditions);
          }),
          catchError(error => of(new TenantOowRepairConditionsError(error)))
        )
    )
  ));

  
  disableOowRepairConditions = createEffect(() => this.actions$.pipe(
    ofType(TenantOowRepairConditionsActionTypes.tenantOowRepairConditionsDisable),
    mergeMap((action: DisableTenantOowRepairConditions) =>
      this.oowRepairConditionService
        .disableTenantOOwRepairCondition(action.payload)
        .pipe(
          map(oowRepairConditions => {
            this.toastr.success('Condition disabled successfully!', 'Successful!');
            return new DisableTenantOowRepairConditionsSuccess(oowRepairConditions);
          }),
          catchError(error => of(new TenantOowRepairConditionsError(error)))
        )
    )
  ));


  constructor(
    private actions$: Actions,
    private toastr: ToastrService,
    private router: Router,
    private oowRepairConditionService: OOWRepairConditionService
  ) {
  }
}
