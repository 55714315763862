import { Action } from '@ngrx/store';
import {
  EntityAdapter,
  createEntityAdapter,
  EntityState,
  Update
} from '@ngrx/entity';

import { ModuleAction } from '@appModels/core-setup/module-action/module-action';
import { ModuleActionActions, ModuleActionActionTypes } from '@appStore/actions/core-masterdata/module-action/module-action.actions';

export interface State extends EntityState<ModuleAction> {
  loaded: boolean;
  loading: boolean;
  error: any;
  customFieldType: any;
  selectedModuleActionId: number;
  searchModuleActions: ModuleAction[];
}

export const adapter: EntityAdapter<ModuleAction> = createEntityAdapter<ModuleAction>();

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
  selectedModuleActionId: null,
  customFieldType: [],
  error: null,
  searchTerm: '',
  searchModuleActions: null
});

export function reducer(state = initialState, action: ModuleActionActions): State {
  switch (action.type) {
    case ModuleActionActionTypes.moduleActionGetModuleActions:
    case ModuleActionActionTypes.moduleActionAddModuleAction:
    case ModuleActionActionTypes.moduleActionDeleteModuleAction:
    case ModuleActionActionTypes.moduleActionUpdateModuleAction:
    case ModuleActionActionTypes.moduleActionSearchModuleActions:
    case ModuleActionActionTypes.moduleActionGetModuleActionById:
      return {
        ...state,
        loading: true
      };

    case ModuleActionActionTypes.moduleActionGetModuleActionsSuccess:
      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });
    case ModuleActionActionTypes.moduleActionGetCustomFieldTypeModule:
      return {
        ...state,
        loading: true,
    };
    case ModuleActionActionTypes.moduleActionGetModuleCustomFieldTypeSuccess:
      return {
        ...state,
        customFieldType: action.payload,
        loading: false,
        loaded: true
      };

    case ModuleActionActionTypes.moduleActionGetModuleActionByIdSuccess:
      return { ...state, selectedModuleActionId: action.payload.id, loading: false };

    case ModuleActionActionTypes.moduleActionAddModuleActionSuccess:
      return adapter.addOne(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });

    case ModuleActionActionTypes.moduleActionUpdateModuleActionSuccess: {
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state,
          loading: false,
          loaded: true
        }
      );
    }

    case ModuleActionActionTypes.moduleActionDeleteModuleActionSuccess: {
      return adapter.removeOne(action.payload.id, {
        ...state,
        loading: false,
        loaded: true
      });
    }

    case ModuleActionActionTypes.moduleActionSearchModuleActionsSuccess:
      return {
        ...state,
        searchModuleActions: action.payload,
        loading: false
      };

    case ModuleActionActionTypes.moduleActionSearchModuleActionsReset:
      return {
        ...state,
        searchModuleActions: null
      };

    case ModuleActionActionTypes.moduleActionError:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload
      };

    default:
      return state;
  }
}

export const moduleActionEntitySelectors = adapter.getSelectors();
