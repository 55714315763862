import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable,} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';


import {CoreFieldforceService} from '../core-fieldforce.service';
import {SalesStaffDefinition} from '@appModels/core-fieldforce/sales-staff-definition/sales-staff-definition';


@Injectable({providedIn: 'root'})
export class TenantSalesStaffDefinitionService extends CoreFieldforceService {

  constructor(
    private http: HttpClient,
  ) {
    super(http, '');
  }

  getTenantSalesStaffDefinitions(id: number, search?: string, seniority?: number): Observable<any> {

    let params = new HttpParams();
    params = params.append('search', search ? search : '');
    if (seniority) {
      params = params.append('seniority', String(seniority));
    }

    const url = `${this.base_url}salesStaffDefinitions/tenant/${id}`;
    return this.http.get(url, {headers: this.headers, params: params}).pipe(
      tap(_ => this.log(`fetched tenantSalesDefinitions id=${id}`)),
      catchError(this.handleError(`getTenantSalesDefinitions id=${id}`))
    );
  }

  /** POST: add a new sales staff definition to the server */
  addSalesStaffDefinition(salesStaffDefinition: SalesStaffDefinition): Observable<SalesStaffDefinition> {
    salesStaffDefinition.tenantId = Number(localStorage.getItem('tenant'));
    return this.http.post<SalesStaffDefinition>(this.base_url + 'salesstaffdefinition', salesStaffDefinition, {headers: this.headers}).pipe(
      catchError(this.handleError<SalesStaffDefinition>('addSalesStaffDefinition'))
    );
  }

}
