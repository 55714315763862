import {ChangeDetectorRef, Component, TemplateRef, ViewChild, ViewEncapsulation} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';

import {ToastrService} from "ngx-toastr";
import { environment } from 'environments/environment';

import {Router} from "@angular/router";
import {ProjectService} from "@appServices/core-ticketing/project-service";
import {TicketWorkflow} from "@appModels/ticketing/ticket.workflow";
import {TicketStatusTransition} from "@appModels/ticketing/ticket.status.transition";
import {WorkflowService} from "@appServices/core-ticketing/workflow-service";
import {LibraryComponent} from "@appContainers/ticketing/components/projects/library-component/library-component";
import {TicketStatus} from "@appModels/ticketing/ticket.status";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {TicketCustomFieldService} from "@appServices/core-ticketing/ticket-custom-field-service";
import {CustomFieldType} from "@appModels/ticketing/custom-field-type";
import {CustomField} from "@appModels/ticketing/custom-field";
import {CustomFieldOption} from "@appModels/ticketing/custom-field-option";
import {TicketingStatusService} from "@appServices/core-ticketing/ticketing-status.service";
import {ProjectListRow} from "@appModels/ticketing/project.list.row";
import {BasePojo} from "@appModels/ticketing/base-pojo";
import {TicketTypeService} from "@appServices/core-ticketing/ticket-type.service";
import {CustomFieldListRow} from "@appModels/ticketing/custom-field-list-row";
import {PermissionResolver} from "@appServices/permission-resolver/permission-resolver.service";

@Component({
  moduleId: module.id,
  templateUrl: './create-workflow-component.html',
  animations: [
    trigger('toggleAnimation', [
      transition(':enter', [style({ opacity: 0, transform: 'scale(0.95)' }), animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' }))]),
      transition(':leave', [animate('75ms', style({ opacity: 0, transform: 'scale(0.95)' }))]),
    ]),
  ],
  styleUrls: ['./create-workflow-component.css', '../../../tailwind.css'],
  encapsulation: ViewEncapsulation.None

})
export class CreateWorkflowComponent {


  newCustomFieldModalReference: BsModalRef;
  @ViewChild('newCustomFieldModal') public newCustomFieldModal;


  constructor(
    private toastrService: ToastrService,
    private ticketingStatusService: TicketingStatusService,
    private modalService: BsModalService,
    private ref: ChangeDetectorRef,
    private workflowService: WorkflowService,
    private projectService: ProjectService,
    private ticketTypeSerice: TicketTypeService,
    private router: Router,
    private customFieldService: TicketCustomFieldService,
    private permissionResolverService: PermissionResolver,


  ) {

    this.projectService.getProjects(true).subscribe(x => {
      this.projects = x;
    })

    this.ticketTypeSerice.getTicketTypes(true).subscribe(x => {
      this.ticketTypes = x;
    })

    this.customFieldService.getCustomFieldsListRow().subscribe(x => {
      this.customFields = x;
    })

    this.ticketingStatusService.getStatuses().subscribe(x => {
      this.statuses = x;
    })
  }

  projects : ProjectListRow[] = []
  statuses : BasePojo[] = [];
  ticketTypes: BasePojo[] = [];
  customFields: CustomFieldListRow[] = [];

  workflow : TicketWorkflow = {
    name:null,
    statuses:[],
    transitions:[],
    ticketTypeId:null,
    customFieldList:[]
  }

  customFieldTypes : CustomFieldType[] = [
    {
      id:1,
      name:"Text"
    },
    {
      id:2,
      name:"Date"
    },
    {
      id:3,
      name:"Select Option"
    }

  ]

  predefinedLocationsList = [
{"loc":"190 15", "text":"Shopping"},
{ "loc":"353 32", "text":"Browse Items"},
{"id":2, "loc":"353 166", "text":"Search Items"},
{"id":3, "loc":"512 12", "text":"View Item"},
{"id":4, "loc":"661 17", "text":"View Cart"},
{"id":5, "loc":"644 171", "text":"Update Cart"},
{"id":6, "loc":"800 96", "text":"Checkout"},
{"id":-2, "loc":"757 229", "category":"End"}
  ]

  mainLoading = false;

  selectedWorkflow: TicketWorkflow = {name:"", statuses : [], transitions : []};

  newStatus = {
    id:0,
    name:"New Status",
    colorCode:"#FDD8BB",
    textColorCode:'#FA8224'
  }

  changeLink(from:number, to:number,  value: boolean) {


    console.log("changing link from " + from + " to " + to + " checked " + value)


    if (value) {
      this.graphComponent.addLink({from: from, to: to, "text": "New Link Text"});
    }
    else {
      this.graphComponent.removeLink(from, to);
    }

  }

  loadingAddStatus = false;


  counter = 0;
  addStatusToWorkflow(workflow: TicketWorkflow){

    if (this.newStatus.name == null || this.newStatus.name.length < 3) {
      this.toastrService.error("Please enter a longer status name");
      return;
    }

    this.loadingAddStatus = true;

    //create status and get it's id. This status will then be linked to the workflow when it's saved
    this.ticketingStatusService.createStatus(this.newStatus).subscribe(x => {
      this.newStatus.id = x.id;
      this.toastrService.success("New Status Added");
      this.loadingAddStatus = false;



      workflow.statuses.push(
        JSON.parse(JSON.stringify(this.newStatus))
      );

      let newTransitionArray = [];
      for (let i = 0 ; i< workflow.statuses.length; i++) {

        //dont create associations between the same statuses
        if (workflow.statuses[i].name == this.newStatus.name) {
          continue;
        }

        let firstTransition : TicketStatusTransition = {
          fromId: workflow.statuses[i].id,
          fromName: workflow.statuses[i].name,
          fromColorCode: workflow.statuses[i].colorCode,
          fromTextColorCode: workflow.statuses[i].textColorCode,
          toId: this.newStatus.id,
          toName:this.newStatus.name,
          toColorCode:this.newStatus.colorCode,
          toTextColorCode:this.newStatus.textColorCode,
          enabled : false
        }
        workflow.transitions.push(firstTransition);

        let secondSTransition : TicketStatusTransition = {
          fromId: this.newStatus.id,
          fromName: this.newStatus.name,
          fromColorCode: this.newStatus.colorCode,
          fromTextColorCode: this.newStatus.textColorCode,
          toId: workflow.statuses[i].id,
          toName: workflow.statuses[i].name,
          toColorCode: workflow.statuses[i].colorCode,
          toTextColorCode: workflow.statuses[i].textColorCode,
          enabled : false
        }
        workflow.transitions.push(secondSTransition);
      }

      workflow.transitions.sort((a, b) => a.fromName.localeCompare(b.fromName));

      const randHi = Math.floor(Math.random() * 1200) + 10;
      const randLo = Math.floor(Math.random() * 1200) + 10;


      //let loc :go.Point =null;
      let loc = "";
      if (this.counter == 0) {
        loc = "190 15";
        //loc = new go.Point(190, 15);
      } else {
        loc = "353 32";
        //loc = new go.Point(353, 32);
      }
      this.counter++;

      let nodeData =  {"id":this.newStatus.id, "loc": loc, "text":this.newStatus.name, "color":this.newStatus.colorCode}

      this.graphComponent.addNewNode(nodeData);

      this.newStatus.name = "New Status";
      this.newStatus.id = 0;
      this.newStatus.colorCode="#FDD8BB";
      this.newStatus.textColorCode="#FA8224"



    })


  }





  editing = false;


  @ViewChild(LibraryComponent) graphComponent: LibraryComponent;

  showingChart = false;
  add() {
    this.showingChart = true;

    this.ref.detectChanges();
  }
  nodeList = [
    //{"id":-1, "loc":"155 -138", "category":"Start", "color":"#FDD8BB"},
    // {"id":0, "loc":"190 15", "text":"Shopping", "color":"#FDD8BB"},
    // {"id":1, "loc":"353 32", "text":"Browse Items", "color":"#FDD8BB"},
    // {"id":2, "loc":"353 166", "text":"Search Items"},
    // {"id":3, "loc":"512 12", "text":"View Item"},
    // {"id":4, "loc":"661 17", "text":"View Cart"},
    // {"id":5, "loc":"644 171", "text":"Update Cart"},
    // {"id":6, "loc":"800 96", "text":"Checkout"},
    // {"id":-2, "loc":"757 229", "category":"End"}
  ];
  linkList = [
    // { "from": -1, "to": 0, "text": "Visit online store" },
    // { "from": 0, "to": 1,  "progress": "true", "text": "Browse" },
    // { "from": 0, "to": 2,  "progress": "true", "text": "Use search bar" },
    // { "from": 1, "to": 2,  "progress": "true", "text": "Use search bar" },
    // { "from": 2, "to": 3,  "progress": "true", "text": "Click item" },
    // { "from": 2, "to": 2,  "text": "Another search", "curviness": 20 },
    // { "from": 1, "to": 3,  "progress": "true", "text": "Click item" },
    // { "from": 3, "to": 0,  "text": "Not interested", "curviness": -100 },
    // { "from": 3, "to": 4,  "progress": "true", "text": "Add to cart" },
    // { "from": 4, "to": 0,  "text": "More shopping", "curviness": -150 },
    // { "from": 4, "to": 5,  "text": "Update needed", "curviness": -50 },
    // { "from": 5, "to": 4,  "text": "Update made" },
    // { "from": 4, "to": 6,  "progress": "true", "text": "Proceed" },
    // { "from": 6, "to": 5,  "text": "Update needed" },
    // { "from": 6, "to": -2, "progress": "true", "text": "Purchase made" }
  ];

  removeStatus(status: TicketStatus){

    this.workflow.statuses = this.workflow.statuses.filter(x => x.name != status.name);
    console.log("removing status " + status.name);
    this.graphComponent.removeNode(status.id);

    this.workflow.transitions = this.workflow.transitions.filter(x=> x.fromId != status.id && x.toId != status.id);

  }


  loadingCreate = false;

  setDiagramJson(jsonDiagram:string) {

    // MIND THIS
    //this.workflow.diagram = JSON.parse(jsonDiagram);
    this.workflow.diagram = jsonDiagram;

  }
  createWorkflow() {

    if (this.workflow.name == null || this.workflow.name == undefined || this.workflow.name.length < 3) {
      this.toastrService.error("Please enter a longer workflow name");
      return;
    }

    if (this.workflow.statuses == null || this.workflow.statuses == undefined || this.workflow.statuses.length == 0) {
      this.toastrService.error("Please add at least one status");
      return;
    }

    if (!this.workflow.statuses.some(x => x.defaultStatus)) {
      this.toastrService.error("Please mark one status as default");
      return;
    }

    this.loadingCreate = true;
    this.graphComponent.outputDiagramJson();
    console.log(this.workflow)
    //this.workflow.graph = this.graphComponent.

    this.ticketTypeSerice.validateNewWorkflow(this.workflow.ticketTypeId, null).subscribe(x => {
      if (x.code == 200) {
        this.workflowService.createWorkflow(this.workflow, true).subscribe(x => {

          this.loadingCreate = false;
          this.toastrService.success("Workflow Created!");
          this.router.navigateByUrl(`/ticketing/workflows`);
        })
      }
      else {
        this.toastrService.error(x.message);
        this.loadingCreate = false;
      }
    })


  }


  openNewCustomFieldModal(templateRef: TemplateRef<any>,){
      this.newCustomFieldModalReference = this.modalService.show( templateRef, { class: 'modal-md' });

  }

  newCustomField : CustomField = {
    id:null,
    customFieldOptions : [],
    customFieldTypeId : 1,
    mandatory:false
  }

  newFieldOption? :string = null;

  addOptionToField(customField: CustomField) {
    if (this.newFieldOption == null || this.newFieldOption == undefined || this.newFieldOption.length < 2) {
      this.toastrService.error("Please enter a longer name")
      return;
    }
    customField.customFieldOptions.push({id:0, value:this.newFieldOption});
    this.newFieldOption = null;
  }

  removeCustomFieldOption(customField:CustomField, option:CustomFieldOption) {

    customField.customFieldOptions = customField.customFieldOptions.filter(x => x.value != option.value)

  }



  removeCustomField(customField: CustomField) {
    this.workflow.customFieldList = this.workflow.customFieldList.filter(x => x.id != customField.id);
  }

  markStatusAsDefault(status : TicketStatus) {
    this.workflow.statuses.forEach(x => {
      if (x.name != status.name) {
        x.defaultStatus = false;
      }
    })
  }


  unselectCustomField(customField: CustomFieldListRow) {
    this.workflow.customFieldList = this.workflow.customFieldList.filter(x => x.id != customField.id);
  }


  selectedStatus : BasePojo = null;
  selectStatus() {
    if (!this.workflow.statuses.some(x => x.id == this.selectedStatus.id)) {


      this.workflow.statuses.push(
        JSON.parse(JSON.stringify(this.selectedStatus))
      );

      let newTransitionArray = [];
      for (let i = 0 ; i< this.workflow.statuses.length; i++) {

        //dont create associations between the same statuses
        if (this.workflow.statuses[i].name == this.selectedStatus.name) {
          continue;
        }

        let firstTransition : TicketStatusTransition = {
          fromId: this.workflow.statuses[i].id,
          fromName: this.workflow.statuses[i].name,
          fromColorCode: this.workflow.statuses[i].colorCode,
          fromTextColorCode: this.workflow.statuses[i].textColorCode,
          toId: this.selectedStatus.id,
          toName:this.selectedStatus.name,
          toColorCode:this.selectedStatus.colorCode,
          toTextColorCode:this.selectedStatus.textColorCode,
          enabled : false
        }
        this.workflow.transitions.push(firstTransition);

        let secondSTransition : TicketStatusTransition = {
          fromId: this.selectedStatus.id,
          fromName: this.selectedStatus.name,
          fromColorCode: this.selectedStatus.colorCode,
          fromTextColorCode: this.selectedStatus.textColorCode,
          toId: this.workflow.statuses[i].id,
          toName: this.workflow.statuses[i].name,
          toColorCode: this.workflow.statuses[i].colorCode,
          toTextColorCode: this.workflow.statuses[i].textColorCode,
          enabled : false
        }
        this.workflow.transitions.push(secondSTransition);
      }

      this.workflow.transitions.sort((a, b) => a.fromName.localeCompare(b.fromName));

      //let loc :go.Point =null;
      let loc = "";
      if (this.counter == 0) {
        loc = "190 15";
        //loc = new go.Point(190, 15);
      } else {
        loc = "353 32";
        //loc = new go.Point(353, 32);
      }
      this.counter++;

      let nodeData =  {"id":this.selectedStatus.id, "loc": loc, "text":this.selectedStatus.name, "color":this.selectedStatus.colorCode}

      this.graphComponent.addNewNode(nodeData);


    }
  }

  can(action) {
    return this.permissionResolverService.setModuleName("Ticketing").can(action);
  }

  goToCreateNewStatusPage() {
    const url = `${environment.base_web_url}/ticketing/ticketstatuses/newTicketStatus`;
    window.open(url, '_blank');
  }

  refreshStatusList() {
    this.ticketingStatusService.getStatuses().subscribe(x => {
      this.statuses = x;
      this.toastrService.success("Ticket Status List Refreshed");
    })
  }

  selectedCustomField: BasePojo = null;
  selectCustomField() {
    if (!this.workflow.customFieldList.some(x => x.id == this.selectedCustomField)) {
      for(let i = 0; i< this.customFields.length; i++) {
        if (this.customFields[i].id == this.selectedCustomField) {
          console.log(this.customFields[i] )
          this.workflow.customFieldList.push(JSON.parse(JSON.stringify(this.customFields[i])))
        }
      }
    }
    this.selectedCustomField = null;
  }

  refreshCustomFieldsList() {
    this.customFieldService.getCustomFieldsListRow().subscribe(x => {
      this.customFields = x;
      this.toastrService.success("Custom Fields List Refreshed");
    })
  }

  goToCreateNewCustomFieldPage() {
    const url = `${environment.base_web_url}/ticketing/newCustomField`;
    window.open(url, '_blank');
  }

}
