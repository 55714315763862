import { Action } from '@ngrx/store';
import {
  EntityAdapter,
  createEntityAdapter,
  EntityState,
  Update
} from '@ngrx/entity';

import { AdminZone } from '@appModels/core-setup/admin-zone/admin-zone';
import { AdminZoneActions, AdminZoneActionTypes } from '@appStore/actions/core-masterdata/admin-zone/admin-zone.actions';

export interface State extends EntityState<AdminZone> {
  loaded: boolean;
  loading: boolean;
  error: any;
  selectedAdminZoneId: number;
  searchAdminZones: AdminZone[];
}

export const adapter: EntityAdapter<AdminZone> = createEntityAdapter<AdminZone>();

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
  selectedAdminZoneId: null,
  error: null,
  searchTerm: '',
  searchAdminZones: null
});

export function reducer(state = initialState, action: AdminZoneActions): State {
  switch (action.type) {
    case AdminZoneActionTypes.adminZoneGetAdminZones:
    case AdminZoneActionTypes.adminZoneAddAdminZone:
    case AdminZoneActionTypes.adminZoneDeleteAdminZone:
    case AdminZoneActionTypes.adminZoneUpdateAdminZone:
    case AdminZoneActionTypes.adminZoneSearchAdminZones:
    case AdminZoneActionTypes.adminZoneGetAdminZoneById:
      return {
        ...state,
        loading: true
      };

    case AdminZoneActionTypes.adminZoneGetAdminZonesSuccess:
      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });

    case AdminZoneActionTypes.adminZoneGetAdminZoneByIdSuccess:
      return { ...state, selectedAdminZoneId: action.payload.id, loading: false };

    case AdminZoneActionTypes.adminZoneAddAdminZoneSuccess:
      return adapter.addOne(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });

    case AdminZoneActionTypes.adminZoneUpdateAdminZoneSuccess: {
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state,
          loading: false,
          loaded: true
        }
      );
    }

    case AdminZoneActionTypes.adminZoneDeleteAdminZoneSuccess: {
      return adapter.removeOne(action.payload.id, {
        ...state,
        loading: false,
        loaded: true
      });
    }

    case AdminZoneActionTypes.adminZoneSearchAdminZonesSuccess:
      return {
        ...state,
        searchAdminZones: action.payload,
        loading: false
      };

    case AdminZoneActionTypes.adminZoneSearchAdminZonesReset:
      return {
        ...state,
        searchAdminZones: null
      };

    case AdminZoneActionTypes.adminZoneError:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload
      };

    default:
      return state;
  }
}

export const adminZoneEntitySelectors = adapter.getSelectors();
