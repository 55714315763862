import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { KYC_COMPONENT_MODE } from "../kyc-shared-modal.component";

@Component({
  selector: "kyc-additional-comment",
  template: ` <form>
    <div class="form_group_modal">
      <div class="input m-t-20">
        <label class="label_title">{{
          "Additional Comments" | translate
        }}</label>
        <textarea
          name="additionalComments"
          [disabled]="
                statusName === 'BLACKLISTED' ||
                statusName === 'KYC_AWAITING_MORE_INFORMATION'
          "
          [(ngModel)]="additionalComments"
          (ngModelChange)="additionalCommentsChange.emit($event)"
          name="additionalComments"
          placeholder="{{ 'Comments...' | translate }}..."
        ></textarea>
      </div>

      <div
        class="input"
        *ngIf="
          resubmissionCheck &&
          (statusName === 'KYC_PENDING' || statusName !== 'BLACKLISTED')
        "
      >
        <label class="label_title">{{
          "Additional KYC Information" | translate
        }}</label>
        <select
          name="additionalKycInfo"
          [(ngModel)]="additionalKycInfo"
          (ngModelChange)="additionalKycInfoChange.emit($event)"
          [disabled]="statusName === 'KYC_AWAITING_MORE_INFORMATION'"
        >
          <option value="" disabled selected>
            {{ "Please select reason for resubmission" | translate }}
          </option>
          <option value="{{ reason }}" *ngFor="let reason of reasonObject">
            {{ reason }}
          </option>
        </select>
        <div
          *ngIf="
            resubmissionCheck &&
            (additionalKycInfo === '' || additionalKycInfo === null)
          "
          class="notification error"
        >
          {{ "Please select reason for resubmission" | translate }}
        </div>
      </div>

     

      <div
        class="input m-t-10"
        *ngIf="
          rejectionCheck &&
          (statusName === 'KYC_PENDING' ||
            statusName === 'LEAD' ||
            statusName === 'HOT_LEAD' ||
            statusName !== 'KYC_AWAITING_MORE_INFORMATION')
        "
      >
        <label class="label_title">{{
          "Customer Rejection Reason" | translate
        }}</label>
        <select
          name="rejectionInfo"
          [(ngModel)]="rejectionInfo"
          (ngModelChange)="rejectionInfoChange.emit($event)"
          [disabled]="(statusName === 'LEAD' || statusName === 'HOT_LEAD')"
        >
          <option value="" disabled selected>
            {{ "Please select reason for rejection" | translate }}
          </option>
          <option
            value="{{ reject.reason }}"
            *ngFor="let reject of rejectedReasons"
          >
            {{ reject.reason }}
          </option>
        </select>
        <div
          *ngIf="
            rejectionCheck && (rejectionInfo === '' || rejectionInfo === null)
          "
          class="notification error"
        >
          {{ "Please select reason for rejection" | translate }}
        </div>
      </div>

      <div
        class="input m-t-10"
        *ngIf="
          blacklistCheck &&
          (statusName === 'KYC_PENDING' ||
            statusName === 'BLACKLISTED' ||
            statusName !== 'KYC_AWAITING_MORE_INFORMATION')
        "
      >
        <label class="label_title">{{
          "Customer Blacklist Reason" | translate
        }}</label>
        <select
          name="blacklistInfo"
          [(ngModel)]="blacklistInfo"
          (ngModelChange)="blacklistInfoChange.emit($event)"
          [disabled]="statusName === 'BLACKLISTED'"
        >
          <option value="" disabled selected>
            {{ "Please select blacklist reason" | translate }}
          </option>
          <option
            value="{{ blacklist.reason }}"
            *ngFor="let blacklist of blacklistReasons"
          >
            {{ blacklist.reason }}
          </option>
        </select>
        <div
          *ngIf="
            blacklistCheck && (blacklistInfo === '' || blacklistInfo === null)
          "
          class="notification error"
        >
          {{ "Please select blacklist reason" | translate }}
        </div>
      </div>

    </div>
  </form>`,
  encapsulation: ViewEncapsulation.None,
})
export class KycAdditionalCommentComponent implements OnInit {
  reasonObject =  [
    "Sales Person identity details were not provided i.e. SEP Code, Name & Phone",
    "Sales person phone number or ID provided are same as that in KYC form",
    "Next of kin did not conscent of his/ her details to be used",
    "Customer provided a conflicting maiden name from that in the ID provided",
    "KYC Form not clear",
    "Old KYC Form submitted w/o updated MMP Clause",
    "Customer National ID Photo not submitted",
    "Customer Has Not Received the Product yet",
    "Customer does not understand correct contract terms: term length, rate amount",
    "Next of kin is unreachable",

    "Wrong Profile Image Mapping",
    "Unclear Attachments",
    "Missing attachments (KYC form, Customer photo, ID)",
    "Initial payment made by unknown number /NOK",
    "Attachments taken from another phone",
    "Primary number does not belong to the customer",
    "Secondary number belongs to the customer",
    "SEP details missing",
    "SEP directing customer on what to say on Call",
    "SEP pretending to be the customer",
    "Customer not Aligned on MMP/Product usage/Cost/Warranty",
    "Invalid NIN",
    "Does not qualify",
    "NOK is Unreachable",
    "Customer is Unreachable",
    "SEP is Unreachable",
    "Call back request",
    "Customer changed their mind",
    "Missing Orginal National ID and supporting documents",
    "Customer using someone else's details",
    "Pending NIN Verification",
    "Unit not Installed ",

    "Guarantor does not qualify",
    "Primary number/name same as secondary contact",
    "Location wrongly Captured",
    "KYC not clear -",
    "Unclear attachments",
    "Invalid Phone IMEI",
  ];
  @Input() statusName: any;
  @Input() resubmissionCheck: any;

  @Input() rejectedReasons: any[];
  @Input() blacklistCheck: any;
  @Input() blacklistReasons: any[];
  @Input() rejectionCheck: any;

  @Input() blacklistInfo: any;
  @Output() blacklistInfoChange = new EventEmitter<any>();

  @Input() rejectionInfo: any;
  @Output() rejectionInfoChange = new EventEmitter<any>();

  @Input() callbackCheck: any
  @Input() callBackInfo: any;

  @Output() callBackChange = new EventEmitter<any>();

  @Input() additionalKycInfo: any;
  @Output() additionalKycInfoChange = new EventEmitter<any>();

  @Input() additionalComments: any;
  @Output() additionalCommentsChange = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {
  }
}
