import {Action} from '@ngrx/store';

export enum WorkOrderL3ActionTypes {
  workOrderL3Get = '[Work Order L3] Get',
  workOrderL3GetSuccess = '[Work Order L3] Get Success',
  workOrderL3GetAll = '[Work Order L3] Get All',
  workOrderL3GetAllSuccess = '[Work Order L3] Get All Success',
  workOrderL3Save = '[Work Order L3] Save',
  workOrderL3SaveSuccess = '[Work Order L3] Get Save Success',
  workOrderL3AssignTechnician = '[Work Order L3] Assign To Technician',
  workOrderL3AssignTechnicianSuccess = '[Work Order L3] Assign To Technician Success',
  workOrderL3AssignAction = '[Work Order L3] Assign Action',
  workOrderL3AssignActionSuccess = '[Work Order L3] Assign Action Success',
  workOrderL3AddNote = '[Work Order L3] Add Note',
  workOrderL3AddNoteSuccess = '[Work Order L3] Add Note Success',
  workOrderL3ChangeState = '[Work Order L3] Change State',
  workOrderL3ChangeStateSuccess = '[Work Order L3] Change State Success',
  workOrderL3PerformRepair = '[Work Order L3] Perform Repair',
  workOrderL3PerformRepairSuccess = '[Work Order L3] Perform Repair Success',
  workOrderL3Close = '[Work Order L3] Close',
  workOrderL3CloseSuccess = '[Work Order L3] Close Success',
  workOrderL3rError = '[Work Order L3] Error'
}

export class WorkOrderL3Save implements Action {
  readonly type = WorkOrderL3ActionTypes.workOrderL3Save;

  constructor(public payload: any) {

  }
}

export class WorkOrderL3SaveSuccess implements Action {
  readonly type = WorkOrderL3ActionTypes.workOrderL3SaveSuccess;

  constructor(public payload: any) {

  }
}

export class WorkOrderL3Get implements Action {
  readonly type = WorkOrderL3ActionTypes.workOrderL3Get;

  constructor(public payload: number) {

  }
}

export class WorkOrderL3GetSuccess implements Action {
  readonly type = WorkOrderL3ActionTypes.workOrderL3GetSuccess;

  constructor(public payload: any) {

  }
}

export class WorkOrderL3GetAll implements Action {
  readonly type = WorkOrderL3ActionTypes.workOrderL3GetAll;

  constructor(public payload: any) {

  }
}

export class WorkOrderL3GetAllSuccess implements Action {
  readonly type = WorkOrderL3ActionTypes.workOrderL3GetAllSuccess;

  constructor(public payload: any) {

  }
}

export class WorkOrderL3Error implements Action {
  readonly type = WorkOrderL3ActionTypes.workOrderL3rError;

  constructor(public payload: any) {

  }
}

export class WorkOrderL3AssignTechnician implements Action {
  readonly type = WorkOrderL3ActionTypes.workOrderL3AssignTechnician;

  constructor(public payload: any) {

  }
}

export class WorkOrderL3AssignTechnicianSuccess implements Action {
  readonly type = WorkOrderL3ActionTypes.workOrderL3AssignTechnicianSuccess;

  constructor(public payload: any) {

  }
}

export class WorkOrderL3AssignAction implements Action {
  readonly type = WorkOrderL3ActionTypes.workOrderL3AssignAction;

  constructor(public payload: any) {

  }
}

export class WorkOrderL3AssignActionSuccess implements Action {
  readonly type = WorkOrderL3ActionTypes.workOrderL3AssignActionSuccess;

  constructor(public payload: any) {

  }
}

export class WorkOrderL3AddNote implements Action {
  readonly type = WorkOrderL3ActionTypes.workOrderL3AddNote;

  constructor(public payload: any) {

  }
}

export class WorkOrderL3AddNoteSuccess implements Action {
  readonly type = WorkOrderL3ActionTypes.workOrderL3AddNoteSuccess;

  constructor(public payload: any) {

  }
}

export class WorkOrderL3ChangeState implements Action {
  readonly type = WorkOrderL3ActionTypes.workOrderL3ChangeState;

  constructor(public payload: any) {

  }
}

export class WorkOrderL3ChangeStateSuccess implements Action {
  readonly type = WorkOrderL3ActionTypes.workOrderL3ChangeStateSuccess;

  constructor(public payload: any) {

  }
}

export class WorkOrderL3ActionPerformRepair implements Action {
  readonly type = WorkOrderL3ActionTypes.workOrderL3PerformRepair;

  constructor(public payload: any) {

  }
}

export class WorkOrderL3ActionPerformRepairSuccess implements Action {
  readonly type = WorkOrderL3ActionTypes.workOrderL3PerformRepairSuccess;

  constructor(public payload: any) {

  }
}

export class WorkOrderL3ActionClose implements Action {
  readonly type = WorkOrderL3ActionTypes.workOrderL3Close;

  constructor(public payload: any) {

  }
}

export class WorkOrderL3ActionCloseSuccess implements Action {
  readonly type = WorkOrderL3ActionTypes.workOrderL3CloseSuccess;

  constructor(public payload: any) {

  }
}

export type WorkOrderL3Action =
  | WorkOrderL3GetAll
  | WorkOrderL3GetAllSuccess
  | WorkOrderL3Error
  | WorkOrderL3Save
  | WorkOrderL3SaveSuccess
  | WorkOrderL3AssignTechnician
  | WorkOrderL3AssignTechnicianSuccess
  | WorkOrderL3AssignAction
  | WorkOrderL3AssignActionSuccess
  | WorkOrderL3AddNote
  | WorkOrderL3AddNoteSuccess
  | WorkOrderL3ChangeState
  | WorkOrderL3ChangeStateSuccess
  | WorkOrderL3ActionPerformRepair
  | WorkOrderL3ActionPerformRepairSuccess
  | WorkOrderL3ActionClose
  | WorkOrderL3ActionCloseSuccess
  | WorkOrderL3Get
  | WorkOrderL3GetSuccess;

