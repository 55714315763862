<div class="mt-3" *ngIf="mainLoading">
  <div class="flex flex-row justify-center items-center w-full  gap-4">
    <span class="m-auto  inline-block h-14 w-14 animate-spin rounded-full border-8 border-[#f1f2f3] border-l-dlight align-middle"></span>
  </div>
</div>

<ng-container *ngIf="!mainLoading">

  <div class="font-semibold text-lg mt-8">Return/Repossession Ticket Information</div>

  <div class="text-dlight">
    <hr class="h-px border-t-0 bg-transparent bg-gradient-to-r from-red-500 via-red-500 to-transparent opacity-25">
  </div>

    <div class="mt-3" >
      <div class="font-semibold ">Product Information</div>
      <div class="flex flex-row justify-start gap-3">
        {{returnTicket['productVersionName']}}
      </div>
    </div>

    <div class="mt-3" >
      <div class="font-semibold ">Ticket Type</div>
      <div>
        {{returnTicket['ticketType']}}
      </div>
    </div>

    <div class="mt-3" *ngIf="returnTicket['refund']!=null">
      <div class="font-semibold ">Refund</div>
      <div>
        {{returnTicket.refund === true ? 'REFUND' : 'NO REFUND' | translate}}
      </div>
    </div>

    <div class="mt-3" *ngIf="returnTicket['collectedItems']">
      <div class="font-semibold ">Collected Items</div>
      <div class="flex flex-row justify-start gap-3">
        <div class="text-dlight" *ngFor="let item of returnTicket['collectedItems']">
          <span class="badge rounded-full" [ngClass]="item.status == 'New' ? 'bg-success' : 'bg-warning'">
            {{item.productName}}
          </span>
        </div>
      </div>
    </div>


  <div class="mt-5 flex flex-row justify-center gap-3 w-full">
    <button class="btn btn-dlight flex gap-2" (click)="openAction(actionTemplate)">Actions
      <svg width="16" height="16" fill="white" viewBox="0 0 0.72 0.72" xmlns="http://www.w3.org/2000/svg"><path d="M.21.68H.15A.02.02 0 0 1 .132.651L.251.42H.12A.02.02 0 0 1 .103.389l.214-.34A.02.02 0 0 1 .333.04h.269a.02.02 0 0 1 .015.034L.436.28h.108a.02.02 0 0 1 .015.033l-.333.36A.02.02 0 0 1 .211.68M.184.64h.018L.497.32H.392A.02.02 0 0 1 .377.286L.558.08H.344l-.189.3h.129a.02.02 0 0 1 .018.029Z" class="clr-i-outline clr-i-outline-path-1"/><path d="M0 0h.72v.72H0z" fill="none"/></svg>
    </button>
    <button class="btn btn-info flex gap-2" (click)="openModal(assignTemplate)">Reassign
      <svg width="16" height="16" viewBox="0 0 0.48 0.48" fill="none" xmlns="http://www.w3.org/2000/svg"><path stroke="white" stroke-width=".04" d="M.34.14a.1.1 0 0 1-.1.1.1.1 0 0 1-.1-.1.1.1 0 0 1 .2 0z"/><path d="M.34.28h.007a.06.06 0 0 1 .06.053l.008.062a.04.04 0 0 1-.04.045h-.27a.04.04 0 0 1-.04-.045L.073.333A.06.06 0 0 1 .133.28H.14" stroke="white" stroke-width=".04" stroke-linecap="round" stroke-linejoin="round"/></svg>
    </button>
  </div>

</ng-container>

<ng-template #actionTemplate>
  <div *ngIf="loadingModal" class="flex flex-row justify-center items-center w-full  gap-4 mt-3">
    <span class="m-auto  inline-block h-14 w-14 animate-spin rounded-full border-8 border-[#f1f2f3] border-l-dlight align-middle"></span>
  </div>

  <ng-container *ngIf="!loadingModal">
  <div class="modal-header">
    <strong> {{'Action' | translate}}</strong>
    <button type="button" class="close pull-right" aria-label="Close" (click)="actionTemplateReference.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div >
    <div class="image-modal" *ngIf="image" (click)="closeAttachment()">
      <p>{{'Note: Click anywhere in the modal to close image' | translate}}</p>
      <img
        [src]="image" style="cursor: pointer"
        alt="reposession-item">
    </div>
    <div >
      <div  [ngClass]="{'open': openProductStatus === 1}" (click)="openProduct(1)">
        <div >

<!--          <ul class="product_tabs_menu">-->
<!--            <li class="nav-item " [ngClass]="{'active': activeTab === 1}">-->
<!--              <a class="nav-link" id="one-tab" data-toggle="tab"-->
<!--                 href="javascript:void(0)">{{returnTicket['ticketType']}} {{'Ticket Items' | translate}}</a>-->
<!--            </li>-->
<!--            <li class="nav-item" [ngClass]="{'active': activeTab === 2}" >-->
<!--              <a class="nav-link" id="two-tab" data-toggle="tab"-->
<!--                 href="javascript:void(0)">{{'Comment History' | translate}}</a>-->
<!--            </li>-->
<!--          </ul>-->

          <div >
            <div  id="versions" [ngClass]="{'show': activeTab === 1, 'active': activeTab === 1}">
              <div class="p0" style="overflow: hidden">
                <form>
                  <div class="datagrid">
                    <table id="customer_kyc" class="tableClassName" style="width: 100%;">
                      <col style="width:12.5%">
                      <col style="width:13.5%">
                      <col style="width:17.5%">
                      <col style="width:6%">
                      <col style="width:12.5%">
                      <col style="width:11.5%">
                      <col style="width:12.5%" *ngIf="returnTicket.actionsEnabled">
                      <col style="width:13.5%" *ngIf="returnTicket.actionsEnabled && returnTicket.l4RepairCenter">
                      <thead>
                      <tr>
                        <th>{{'Product Name' | translate}}</th>
                        <th>{{'Serial Number' | translate}}</th>
                        <th>{{'Status' | translate}}</th>
                        <th>{{'Count' | translate}}</th>
                        <th>{{'File' | translate}}</th>
                        <th>{{'Consignment Id' | translate}}</th>
                        <th>{{'Consignment Status' | translate}}</th>
                        <th *ngIf="returnTicket.actionsEnabled">{{'Salvage Value' | translate}}</th>
                        <th *ngIf="returnTicket.actionsEnabled && returnTicket.l4RepairCenter">{{'Action' | translate}}</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr *ngFor="let item of ticketItems; let i = index" class="ticket-row">
                        <td>{{item.productName}}</td>
                        <td>{{item.serialNumber}}</td>
                        <td>
                          <span *ngIf="!item.editing"
                                [ngClass]="item.status === 'New' ? 'status active' : 'status dormant'"> {{item.status}}</span>
                          <form *ngIf="item.editing">
                            <div class="form_group_modal">
                              <div class="input">
                                <select style="font-size: 12px; width: 60%" name="status" [(ngModel)]="item.status">
                                  <option [ngValue]="status" *ngFor="let status of productStates"> {{status}}</option>
                                </select>
                              </div>
                            </div>
                          </form>
                          <a *ngIf="!returnTicket.actionsEnabled && !item.editing" href="javascript:void(0)"
                             (click)="item.editing=true">
                            <span class="fa fa-edit m-l-10"></span>
                          </a>
                        </td>
                        <td>
                          <span>{{item.count}}</span>
                        </td>
                        <td><a *ngIf="item.fileName" href="javascript:void(0)"
                               (click)="downloadAttachment(item)"> {{'VIEW' | translate}}</a> <span *ngIf="!item.fileName">--</span>
                        </td>
                        <td>
                          <span *ngIf="item.consingmentId" class="font-weight-bold clickable_a"
                                [routerLink]="['/inventory/consignment-products/consignment/' + item.consingmentId + '/status/0']"
                                (click)="actionTemplateReference.hide()">{{item.consingmentId}}</span>
                          <span *ngIf="!item.consingmentId" class="font-weight-bold clickable_a pointer"
                                [routerLink]="['/inventory/consignments/registration/stockingpoint/' + returnTicket.stockingPointId + '/product/' + item.inventoryProductId]"
                                (click)="actionTemplateReference.hide()"> {{'Create Consignment' | translate}}</span>
                        </td>
                        <td>
                          <span>{{item.consingmentStatus}}</span>
                        </td>
                        <td *ngIf="returnTicket.actionsEnabled">
                          <span *ngIf="!item.editValue" > {{item.salvageValue}}</span>
                          <form *ngIf="item.editValue">
                            <div class="form_group_modal">
                              <div class="input">
                                <input type="number" style="font-size: 12px; width: 100%" name="salvageValue" [(ngModel)]="item.salvageValue" (blur)="openUpdateSalvageValueModal(template, item);">
                              </div>
                            </div>
                          </form>
                          <a *ngIf="!item.editValue && item.salvageValue !== null" href="javascript:void(0)" (click)="item.editValue=true;item.oldValue=item.salvageValue">
                            <span class="fa fa-edit m-l-10"></span>
                          </a>
                        </td>
                        <td>
                          <span *ngIf="returnTicket.actionsEnabled && returnTicket.l4RepairCenter" class="status active pointer"
                                [routerLink]="['/warranty-and-service/work_order/returnrepossession/' + item.id]"
                                (click)="actionTemplateReference.hide()"> <i  placement="left" tooltip="Create Work Order" class="fa fa-plus"></i></span>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>

                </form>
              </div>


              <ng-template #template>
                <div>
                  <div class="modal-header">
                    <strong>{{'Edit Salvage Value' | translate}}</strong>
                    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <p>{{'Are you sure you want to update salvage Value of' | translate}} {{selectedItem.productName}} {{'from' | translate}} {{selectedItem.oldValue}} {{'to' | translate}} {{selectedItem.salvageValue}}</p>
                  </div>

                  <div class="modal-footer">
                    <div class="">
                      <button class="button_secondary pull-right" type="button"
                              (click)="updateSalvageValue(selectedItem.id, selectedItem.salvageValue)">{{'Save' | translate}}</button>
                      <button class="button_secondary pull-right" type="button"
                              (click)="selectedItem.salvageValue = selectedItem.oldValue; salvageModalReference.hide()">{{'Cancel' | translate}}</button>
                    </div>
                  </div>
                </div>
              </ng-template>

              <div class="modal-footer flex flex-row justify-end gap-3"
                   *ngIf="!isClosed(returnTicket) && (canWarrantyAndService('can-reject-return-repossession-tickets') || canWarrantyAndService('can-reject-return-repossession-tickets'))">
                <button class="btn btn-danger pull-right" type="submit"
                        *ngIf="returnTicket.actionsEnabled && canWarrantyAndService('can-reject-return-repossession-tickets')"
                        (click)="rejectTicket(returnTicket.id);actionTemplateReference.hide()"> {{'REJECT' | translate}}
                </button>
                <button class="btn btn-success pull-right" type="submit"
                        *ngIf="returnTicket.actionsEnabled && canWarrantyAndService('can-approve-return-repossession-tickets')"
                        (click)="approveTicket(returnTicket.id);actionTemplateReference.hide()">{{'APPROVE' | translate}}
                </button>
                <button class="btn btn-info pull-right" type="submit"
                        *ngIf="!returnTicket.actionsEnabled"
                        (click)="moveToInProgress(returnTicket.id)">{{'Move to In Progress' | translate}}
                </button>
                <button class="btn btn-danger pull-right" type="submit"
                        *ngIf="canWarrantyAndService('can-cancel-repossession-ticket') && returnTicket.statusName === 'Open'"
                        (click)="cancelTicket(returnTicket.id);actionTemplateReference.hide()">{{'CANCEL' | translate}}
                </button>
                <button class="btn btn-success pull-right" type="submit"
                        *ngIf="returnTicket.actionsEnabled && returnTicket.l4RepairCenter"
                        (click)="closeTicket(returnTicket.id)">{{'Close Ticket' | translate}}
                </button>
              </div>
            </div>
            <div class="tab_pane fade" id="parent" [ngClass]="{'show': activeTab === 2, 'active': activeTab === 2}" >

              <div class="app_setup_layout p0" style="overflow: hidden">
                <form>
                  <div class="datagrid" *ngIf="ticketComments?.length > 0">
                    <table id="customer_kyc" class="tableClassName" style="width: 100%;">
                      <col style="width:33%">
                      <col style="width:33%">
                      <col style="width:33%">
                      <thead>
                      <tr>
                        <th>{{'Comment' | translate}}</th>
                        <th>{{'Created By' | translate}}</th>
                        <th>{{'Create At' | translate}}</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr *ngFor="let note of ticketComments; let i = index">
                        <td>{{note.comment}}</td>
                        <td>{{note.createdBy}}</td>
                        <td>{{note.dateCreated}}</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="form_group_modal">
                    <div class="input">
                      <label class="label_title">{{'Comments' | translate}}:</label>
                      <textarea rows="2" name="comment" [(ngModel)]="comment"> </textarea>
                    </div>
                  </div>
                </form>
              </div>


            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </ng-container>
</ng-template>

<ng-template #assignTemplate>
  <div class="modal-header">
    <strong>{{ 'Assign Ticket' | translate}}</strong>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="app_customer_layout">
    <div class="customer_products">
      <div class="product" [ngClass]="{'open': openProductStatus === 1}" (click)="openProduct(1)">
        <div class="product_tabs">
          <ul class="product_tabs_menu">
            <li class="nav-item " [ngClass]="{'active': activeTab === 1}" (click)="clickTab(1)">
              <a class="nav-link" id="one-tab" data-toggle="tab"
                 href="javascript:void(0)">{{'Assign Technician' | translate}}</a>
            </li>
            <li class="nav-item" [ngClass]="{'active': activeTab === 2}" (click)="clickTab(2)"
                *ngIf="returnTicket.actionsEnabled && !returnTicket.canCreateWorkOrders">
              <a class="nav-link" id="two-tab" data-toggle="tab"
                 href="javascript:void(0)">{{'Assign Ticket to L4 Service Center' | translate}}</a>
            </li>
          </ul>

          <div class="tab-content">
            <div class="tab_pane fade" id="versions" [ngClass]="{'show': activeTab === 1, 'active': activeTab === 1}">
              <div class="app_setup_layout p0" style="overflow: hidden">
                <form>
                  <div class="form_group_modal">
                    <div class="input">
                      <label class="label_title">{{'Repair Centre Technician' | translate}}:</label>
                      <select [(ngModel)]="technicianId"
                              name="selectedTechnician">
                        <option [ngValue]="technician.id"
                                *ngFor="let technician of technicians">{{technician.user.firstName}} {{technician.user.lastName}}</option>
                      </select>
                    </div>
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                <button class="btn btn-dlight" type="button"
                        (click)="assignTechnician(returnTicket.id); modalRef.hide()"> {{'Assign' | translate}}
                </button>
              </div>
            </div>
            <div class="tab_pane fade" id="parent" [ngClass]="{'show': activeTab === 2, 'active': activeTab === 2}"
                 *ngIf="returnTicket.actionsEnabled && !returnTicket.canCreateWorkOrders">

              <div class="app_setup_layout p0" style="overflow: hidden">
                <form>
                  <div class="form_group_modal">
                    <div class="input">
                      <label class="label_title">{{'L4 Repair Centre' | translate}}:</label>
                      <select name="status" [(ngModel)]="selectedRepairCentre">
                        <option [ngValue]="repair.id" *ngFor="let repair of l4RepairCenters"> {{repair.name}}</option>
                      </select>
                    </div>
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                <button class="btn btn-dlight" type="button"
                        (click)="assign(returnTicket.id); modalRef.hide()"> {{'Assign' | translate}}
                </button>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</ng-template>


