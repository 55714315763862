import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getProductWarrantyStore = createFeatureSelector('productWarranty');

export const getProductWarrantyEntities = createSelector(
  getProductWarrantyStore,
  fromReducers.productWarranty.productWarrantyEntitySelectors.selectAll
);

export const getProductWarranties = createSelector(getProductWarrantyEntities, entities => {
  return Object.values(entities);
});

export const getTopProductWarranties = createSelector(getProductWarrantyEntities, entities => {
  return Object.values(entities).slice(1, 5);
});

export const getProductWarrantiesLoaded = createSelector(
  getProductWarrantyStore,
  (productStore: fromReducers.productWarranty.State) => productStore.loaded
);

export const getProductWarrantiesLoading = createSelector(
  getProductWarrantyStore,
  (productStore: fromReducers.productWarranty.State) => productStore.loading
);

export const getSelectedProductWarrantyId = createSelector(
  getProductWarrantyStore,
  (productStore: fromReducers.productWarranty.State) => productStore.selectedProductWarrantyId
);

export const getSearchProductWarranties = createSelector(
  getProductWarrantyStore,
  (productStore: fromReducers.productWarranty.State) => productStore.searchProductWarranties
);

export const getProductWarrantyById = createSelector(
  getProductWarrantyEntities,
  getSelectedProductWarrantyId,
  (entities, id) => entities.find(i => i.id === id)
);

export const getProductWarrantiesError = createSelector(
  getProductWarrantyStore,
  (productStore: fromReducers.productWarranty.State) => productStore.error
);
