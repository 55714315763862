import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap} from "rxjs/operators";
import {of} from "rxjs";
import {OOWRepairConditionService} from '../../../../services/core-masterdata/oow-repair-condition/oow-repair-condition.service';
import {
  GetAllOowRepairConditions,
  GetAllOowRepairConditionsSuccess, OowRepairConditionsActionTypes, OowRepairConditionsError
} from '../../../actions/core-masterdata/oow-repair-conditions/oow-repair-conditions.actions';

@Injectable()
export class OowRepairConditionsEffects {

  
  oowRepairConditions = createEffect(() => this.actions$.pipe(
    ofType(OowRepairConditionsActionTypes.oowRepairConditionsGetAll),
    mergeMap((action: GetAllOowRepairConditions) =>
      this.oowRepairConditionService
        .getAllOOwRepairConditions()
        .pipe(
          map(oowRepairConditions => new GetAllOowRepairConditionsSuccess(oowRepairConditions)),
          catchError(error => of(new OowRepairConditionsError(error)))
        )
    )
  ));

  constructor(
    private actions$: Actions,
    private oowRepairConditionService: OOWRepairConditionService
  ) {
  }
}
