import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { mergeMap, map, catchError, tap, debounceTime, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import {
  RateFinancedDefinitionActionTypes,
  GetRateFinancedDefinitions,
  GetRateFinancedDefinitionsSuccess,
  RateFinancedDefinitionError,
  AddRateFinancedDefinition,
  AddRateFinancedDefinitionSuccess,
  EditRateFinancedDefinition,
  DeleteRateFinancedDefinitionSuccess,
  DeleteRateFinancedDefinition,
  GetRateFinancedDefinitionById,
  GetRateFinancedDefinitionByIdSuccess,
  UpdateRateFinancedDefinitionSuccess,
  SearchRateFinancedDefinitions,
  SearchRateFinancedDefinitionsSuccess,
  AddFinancedRate
} from '@appStore/actions/core-accounting/rate-financed-definition/rate-financed-definition.actions';
import { RateFinancedDefinitionService } from '@appServices/core-accounting/rate-financed-definition/rate-financed-definition.service';

import * as fromRouterActions from '@appStore/actions/router.actions';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class RateFinancedDefinitionEffects {
  constructor(
    private actions$: Actions,
    private rateFinancedDefinitionService: RateFinancedDefinitionService,
    private toastr: ToastrService
    ) {}


  loadRateFinancedDefinitions$ = createEffect(() => this.actions$.pipe(
    ofType(RateFinancedDefinitionActionTypes.rateFinancedDefinitionGetRateFinancedDefinitions),
    mergeMap(() =>
      this.rateFinancedDefinitionService
        .getRateFinancedDefinitions()
        .pipe(
          map(rateFinancedDefinitions => new GetRateFinancedDefinitionsSuccess(rateFinancedDefinitions)),
          catchError(error => of(new RateFinancedDefinitionError(error)))
        )
    )
  ));


  getRateFinancedDefinitionById$ = createEffect(() => this.actions$.pipe(
    ofType(RateFinancedDefinitionActionTypes.rateFinancedDefinitionGetRateFinancedDefinitionById),
    mergeMap((action: GetRateFinancedDefinitionById) =>
      this.rateFinancedDefinitionService
        .getRateFinancedDefinition(action.payload)
        .pipe(
          map(rateFinancedDefinition => new GetRateFinancedDefinitionByIdSuccess(rateFinancedDefinition)),
          catchError(error => of(new RateFinancedDefinitionError(error)))
        )
    )
  ));


  addRateFinancedDefinition$ = createEffect(() => this.actions$.pipe(
    ofType(RateFinancedDefinitionActionTypes.rateFinancedDefinitionAddRateFinancedDefinition),
    switchMap((action: AddRateFinancedDefinition) =>
      this.rateFinancedDefinitionService
        .addRateFinancedDefinition(action.payload)
        .pipe(
          map(
            rateFinancedDefinition => {
              if (rateFinancedDefinition !== undefined) {
                this.toastr.success('Financed rate has been successfully added!', 'Successful!')
                return new AddRateFinancedDefinitionSuccess(rateFinancedDefinition)
              }
              this.toastr.error('There was an error adding financed rate', "Unknown error")
              return new RateFinancedDefinitionError({ type: 500, message: 'Internal error' })
            }
            ),
          catchError(error => of(new RateFinancedDefinitionError(error)))
        )
    )
  ));


  addFinancedRate$ = createEffect(() => this.actions$.pipe(
    ofType(RateFinancedDefinitionActionTypes.rateFinancedDefinitionAddFinancedRate),
    switchMap((action: AddFinancedRate) =>
      this.rateFinancedDefinitionService
        .addRateFinancedDefinition(action.payload)
        .pipe(
          map(financedRate => new EditRateFinancedDefinition({ value: financedRate.id, id: action.payload.rateId, param: 'rate' })),
          catchError(error => of(new RateFinancedDefinitionError(error)))
        )
    )
  ));


  addAdditionalRateFinancedDefinitionInfo$ = createEffect(() => this.actions$.pipe(
    ofType(RateFinancedDefinitionActionTypes.rateFinancedDefinitionAddAdditionalRateFinancedDefinitionInfo),
    mergeMap((action: AddRateFinancedDefinition) =>
      this.rateFinancedDefinitionService
        .addRateFinancedDefinitionAdditionalInfo(action.payload)
        .pipe(
          map(rateFinancedDefinition => new GetRateFinancedDefinitions()),
          catchError(error => of(new RateFinancedDefinitionError(error)))
        )
    )
  ));


  updateRateFinancedDefinition$ = createEffect(() => this.actions$.pipe(
    ofType(RateFinancedDefinitionActionTypes.rateFinancedDefinitionUpdateRateFinancedDefinition),
    mergeMap((action: AddRateFinancedDefinition) =>
      this.rateFinancedDefinitionService
        .updateRateFinancedDefinition(action.payload)
        .pipe(
          map(rateFinancedDefinition => new UpdateRateFinancedDefinitionSuccess(action.payload)),
          catchError(error => of(new RateFinancedDefinitionError(error)))
        )
    )
  ));


  deleteRateFinancedDefinition$ = createEffect(() => this.actions$.pipe(
    ofType(RateFinancedDefinitionActionTypes.rateFinancedDefinitionDeleteRateFinancedDefinition),
    mergeMap((action: DeleteRateFinancedDefinition) =>
      this.rateFinancedDefinitionService
        .deleteRateFinancedDefinition(action.payload)
        .pipe(
          map(() => new DeleteRateFinancedDefinitionSuccess(action.payload)),
          catchError(error => of(new RateFinancedDefinitionError(error)))
        )
    )
  ));


  searchRateFinancedDefinitions$ = createEffect(() => this.actions$.pipe(
    ofType(RateFinancedDefinitionActionTypes.rateFinancedDefinitionSearchRateFinancedDefinitions),
    mergeMap((action: SearchRateFinancedDefinitions) =>
      this.rateFinancedDefinitionService
        .searchRateFinancedDefinitions(action.payload)
        .pipe(
          map(rateFinancedDefinitions => new SearchRateFinancedDefinitionsSuccess(rateFinancedDefinitions)),
          catchError(error => of(new RateFinancedDefinitionError(error)))
        )
    )
  ));


  // updateRateFinancedDefinitionSuccess$ = createEffect(() => this.actions$.pipe(
  //   ofType(RateFinancedDefinitionActionTypes.rateFinancedDefinitionUpdateRateFinancedDefinitionSuccess),
  //   map(rateFinancedDefinition => rateFinancedDefinition)
  // ));
}
