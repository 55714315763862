import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import * as _ from 'lodash';

import {Escalation} from '@appModels/core-crm/escalation/escalation';
import {EscalationActions, EscalationActionTypes} from '@appStore/actions/core-crm/escalation/escalation.actions';

export interface State extends EntityState<Escalation> {
  loaded: boolean;
  loading: boolean;
  error: any;
  selectedEscalationId: number;
  searchEscalations: Escalation[];
  paging: any;
}

export const adapter: EntityAdapter<Escalation> = createEntityAdapter<Escalation>();

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
  selectedEscalationId: null,
  error: null,
  searchTerm: '',
  searchEscalations: null,
  paging: null
});

export function reducer(state = initialState, action: EscalationActions): State {
  switch (action.type) {
    case EscalationActionTypes.escalationGetEscalations:
    case EscalationActionTypes.escalationAddEscalation:
    case EscalationActionTypes.escalationDeleteEscalation:
    case EscalationActionTypes.escalationUpdateEscalation:
    case EscalationActionTypes.escalationAddProductEscalation:
    case EscalationActionTypes.escalationSearchEscalations:
    case EscalationActionTypes.escalationGetEscalationById:
      return {
        ...state,
        loading: true
      };

    case EscalationActionTypes.escalationGetEscalationsSuccess:
      return adapter.setAll(action.payload.content, {
        ...state,
        loading: false,
        loaded: true,
        paging: _.omit(action.payload, ['content'])
      });

    case EscalationActionTypes.escalationGetEscalationByIdSuccess:
      return { ...state, selectedEscalationId: action.payload.id, loading: false };

    case EscalationActionTypes.escalationAddEscalationSuccess:
      return adapter.addOne(action.payload, {
        ...state,
        selectedEscalationId: action.payload.id,
        loading: false,
        loaded: true
      });

    case EscalationActionTypes.escalationUpdateEscalationSuccess: {
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state,
          loading: false,
          loaded: true
        }
      );
    }

    case EscalationActionTypes.escalationAddProductEscalationSuccess: {
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state,
          loading: false,
          loaded: true
        }
      );
    }

    case EscalationActionTypes.escalationDeleteEscalationSuccess: {
      return adapter.removeOne(action.payload.id, {
        ...state,
        loading: false,
        loaded: true
      });
    }

    case EscalationActionTypes.escalationSearchEscalationsSuccess:
        return adapter.setAll(action.payload, {
          ...state,
          searchEscalations: action.payload,
          loading: false,
          loaded: true
        });

    case EscalationActionTypes.escalationSearchEscalationsReset:
      return {
        ...state,
        searchEscalations: null
      };

    case EscalationActionTypes.escalationError:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload
      };

    default:
      return state;
  }
}

export const escalationEntitySelectors = adapter.getSelectors();
