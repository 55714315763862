import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { mergeMap, map, catchError, tap, debounceTime, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import {
  TimezoneActionTypes,
  GetTimezonesSuccess,
  TimezoneError,
  AddTimezone,
  AddTimezoneSuccess,
  DeleteTimezoneSuccess,
  DeleteTimezone,
  GetTimezoneById,
  GetTimezoneByIdSuccess,
  UpdateTimezoneSuccess,
  SearchTimezones,
  SearchTimezonesSuccess
} from '@appStore/actions/core-masterdata/timezone/timezone.actions';
import { TimezoneService } from '@appServices/core-masterdata/timezone/timezone.service';

import * as fromRouterActions from '@appStore/actions/router.actions';

@Injectable()
export class TimezoneEffects {
  constructor(private actions$: Actions, private timezoneService: TimezoneService) {}

  
  loadTimezones$ = createEffect(() => this.actions$.pipe(
    ofType(TimezoneActionTypes.timezoneGetTimezones),
    mergeMap(() =>
      this.timezoneService
        .getTimezones()
        .pipe(
          map(timezones => new GetTimezonesSuccess(timezones)),
          catchError(error => of(new TimezoneError(error)))
        )
    )
  ));

  
  getTimezoneById$ = createEffect(() => this.actions$.pipe(
    ofType(TimezoneActionTypes.timezoneGetTimezoneById),
    mergeMap((action: GetTimezoneById) =>
      this.timezoneService
        .getTimezone(action.payload)
        .pipe(
          map(timezone => new GetTimezoneByIdSuccess(timezone)),
          catchError(error => of(new TimezoneError(error)))
        )
    )
  ));

  
  addTimezone$ = createEffect(() => this.actions$.pipe(
    ofType(TimezoneActionTypes.timezoneAddTimezone),
    switchMap((action: AddTimezone) =>
      this.timezoneService
        .addTimezone(action.payload)
        .pipe(
          map(timezone => new AddTimezoneSuccess(timezone)),
          catchError(error => of(new TimezoneError(error)))
        )
    )
  ));

  
  updateTimezone$ = createEffect(() => this.actions$.pipe(
    ofType(TimezoneActionTypes.timezoneUpdateTimezone),
    mergeMap((action: AddTimezone) =>
      this.timezoneService
        .updateTimezone(action.payload)
        .pipe(
          map(timezone => new UpdateTimezoneSuccess(action.payload)),
          catchError(error => of(new TimezoneError(error)))
        )
    )
  ));

  
  deleteTimezone$ = createEffect(() => this.actions$.pipe(
    ofType(TimezoneActionTypes.timezoneDeleteTimezone),
    mergeMap((action: DeleteTimezone) =>
      this.timezoneService
        .deleteTimezone(action.payload)
        .pipe(
          map(() => new DeleteTimezoneSuccess(action.payload)),
          catchError(error => of(new TimezoneError(error)))
        )
    )
  ));

  
  searchTimezones$ = createEffect(() => this.actions$.pipe(
    ofType(TimezoneActionTypes.timezoneSearchTimezones),
    mergeMap((action: SearchTimezones) =>
      this.timezoneService
        .searchTimezones(action.payload)
        .pipe(
          map(timezones => new SearchTimezonesSuccess(timezones)),
          catchError(error => of(new TimezoneError(error)))
        )
    )
  ));

  
  updateTimezoneSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(TimezoneActionTypes.timezoneUpdateTimezoneSuccess),
    map(timezone => new fromRouterActions.Go({ path: ['/setup/timezones'] }))
  ));
}
