import { Action } from '@ngrx/store';
import {
  EntityAdapter,
  createEntityAdapter,
  EntityState,
  Update
} from '@ngrx/entity';

import { TenantCustomField } from '@appModels/core-setup/tenant-custom-field/tenant-custom-field';
import { TenantCustomFieldActions, TenantCustomFieldActionTypes } from '@appStore/actions/core-masterdata/tenant-custom-field/tenant-custom-field.actions';

export interface State extends EntityState<TenantCustomField> {
  loaded: boolean;
  loading: boolean;
  error: any;
  selectedTenantCustomFieldId: number;
  searchTenantCustomFields: TenantCustomField[];
}

export const adapter: EntityAdapter<TenantCustomField> = createEntityAdapter<TenantCustomField>();

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
  selectedTenantCustomFieldId: null,
  error: null,
  searchTerm: '',
  searchTenantCustomFields: null
});

export function reducer(state = initialState, action: TenantCustomFieldActions): State {
  switch (action.type) {
    case TenantCustomFieldActionTypes.tenantCustomFieldGetTenantCustomFields:
    case TenantCustomFieldActionTypes.tenantCustomFieldAddTenantCustomField:
    case TenantCustomFieldActionTypes.tenantCustomFieldAddTenantCustomFieldAdmin:
    case TenantCustomFieldActionTypes.tenantCustomFieldDeleteTenantCustomField:
    case TenantCustomFieldActionTypes.tenantCustomFieldUpdateTenantCustomField:
    case TenantCustomFieldActionTypes.tenantCustomFieldEditTenantCustomField:
    case TenantCustomFieldActionTypes.tenantCustomFieldSearchTenantCustomFields:
    case TenantCustomFieldActionTypes.tenantCustomFieldGetTenantCustomFieldById:
      return {
        ...state,
        loading: true
      };

    case TenantCustomFieldActionTypes.tenantCustomFieldGetTenantCustomFieldsSuccess:
      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });

    case TenantCustomFieldActionTypes.tenantCustomFieldGetTenantCustomFieldByIdSuccess:
      return { ...state, selectedTenantCustomFieldId: action.payload.id, loading: false };

    case TenantCustomFieldActionTypes.tenantCustomFieldAddTenantCustomFieldSuccess:
      return adapter.addOne(action.payload, {
        ...state,
        selectedTenantCustomFieldId: action.payload.id,
        loading: false,
        loaded: true
      });

    case TenantCustomFieldActionTypes.tenantCustomFieldUpdateTenantCustomFieldSuccess: {
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state,
          loading: false,
          loaded: true
        }
      );
    }
    case TenantCustomFieldActionTypes.tenantCustomFieldEditTenantCustomFieldSuccess: {
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state,
          loading: false,
          loaded: true
        }
      );
    }

    case TenantCustomFieldActionTypes.tenantCustomFieldDeleteTenantCustomFieldSuccess: {
      return adapter.removeOne(action.payload.id, {
        ...state,
        loading: false,
        loaded: true
      });
    }

    case TenantCustomFieldActionTypes.tenantCustomFieldSearchTenantCustomFieldsSuccess:
      return {
        ...state,
        searchTenantCustomFields: action.payload,
        loading: false
      };

    case TenantCustomFieldActionTypes.tenantCustomFieldSearchTenantCustomFieldsReset:
      return {
        ...state,
        searchTenantCustomFields: null
      };

    case TenantCustomFieldActionTypes.tenantCustomFieldError:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload
      };

    default:
      return state;
  }
}

export const tenantCustomFieldEntitySelectors = adapter.getSelectors();
