import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import {CoreTicketingCustomerService} from "@appServices/core-ticketing/customer/core-ticketing-customer.service";

import {ToastrService} from "ngx-toastr";
import {ActivatedRoute, Router} from "@angular/router";
import {RepairCentreService} from "@appServices/core-aftersales/repaircentre/repair-centre.service";
import {ReturnTicket} from "@appModels/core-aftersales/return-ticket";
import {select, Store} from "@ngrx/store";
import * as fromReducer from "@appStore/reducers";
import {StockingPointService} from "@appServices/core-inventory/stocking-point/stocking-point.service";
import {ReturnTicketService} from "@appServices/core-aftersales/return-ticket/return-ticket.service";
import {ContractService} from "@appServices/core-accounting/contract/contract.service";
import {Observable} from "rxjs";
import {Customer} from "@appModels/core-crm/customer/customer";

@Component({
  moduleId: module.id,
  selector: 'repossession-ticket-directive',
  templateUrl: './repossession-ticket-directive.html',
  animations: [
    trigger('toggleAnimation', [
      transition(':enter', [style({ opacity: 0, transform: 'scale(0.95)' }), animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' }))]),
      transition(':leave', [animate('75ms', style({ opacity: 0, transform: 'scale(0.95)' }))]),
    ]),
  ],
  styleUrls: [ './repossession-ticket-directive.css', '../../../tailwind.css']
})
export class RepossessionTicketDirective implements OnChanges {

  customer$: Observable<Customer>;
  selectedCustomerId$: Observable<number>;

  constructor(
    private toastrService: ToastrService,
    private customerService: CoreTicketingCustomerService,
    private store: Store<fromReducer.customer.State>,
    private router: Router,
    private stockingPointService: StockingPointService,
    private returnTicketService: ReturnTicketService,
    private contractService: ContractService,
    private routeContract: ActivatedRoute,
    private ref: ChangeDetectorRef,
    private repairCentreService: RepairCentreService
  ) {


  }

  mainLoading = true;
  contractId : number;
  customerId:number;
  customer:any;
  countryAdminZone:any = null;

  @Input()
  set setInitialize(body:any) {
    this.contractId = body.contractId;
    this.customerId = body.customerId;

    console.log("initialized repossession ticket flow");
    console.log(this.contractId);
    console.log(this.customerId);
    this.initialize();
  }

  @Input()
  set setContractId(contractId:any) {
    this.customerService.getPrimaryContractProductByContractId(contractId).subscribe(x => {
      this.contractId = x.id;
      if (this.contractId != null && this.customerId != null) {
        this.initialize();
      }
    })

  }
  @Input()
  set setCustomerId(customerId:any) {
    this.customerId = customerId;
  }



  ngOnChanges(simpleChange: any) {

  }



  @Output() outputCreateRepossessionTicket = new EventEmitter<any>();
  @Output() outputAddAttachment = new EventEmitter<any>();


  loading = false;
  show = true;

  stockingPoints = [];
  stockingPointsSelect = [];
  returnType = ['Voluntary Return', 'Repossession'];
  productStates = ['New', 'Used Good', 'Faulty/Escalation Needed', 'Missing'];

  returnTicket: ReturnTicket = {
    tenantId: null,
    stockingPointId: null,
    contractProductId: null,
    serial: null,
    customerId: null,
    repairCentreId: null,
    notes: null,
    reasonForReturn: null,
    repossession: false,
    items: [],
    surrenderForm: null,
    countryAdminZoneId: null
  };

  formValid = {
    reasonForReturn: true,
    stockingPointId: true,
    repairCentreId: true,
    subunits: true,
    surrenderForm: true
  };
  disableSaveButton = false;

  remainingDays: number = null;
  enterDaysManually = false;
  tokenRequestReason: string;

  checkDealerLoading = false;
  dealerQuery = '';


  files = [];
  formFile: any;
  repairCenters: any;
  categories = [];
  validFile = {};

  surrenderFormFile: any;

  isValidFormInput(returnTicket) {
    this.formValid.stockingPointId = returnTicket.stockingPointId != null;
    this.formValid.repairCentreId = returnTicket.repairCentreId != null;
    this.formValid.reasonForReturn = returnTicket.reasonForReturn != null;
    this.formValid.subunits = returnTicket.items.every(item => item.checked);
    this.formValid.surrenderForm = this.surrenderFormFile != null;
    return Object.values(this.formValid).every(value => value === true);
  }

  isValidFiles(){
    this.returnTicket.items.forEach(v => {
      if(v.status !== 'Missing' && !v.fileName) {
        this.validFile[v.id] = false
      } else {
        this.validFile[v.id] = true;
      }
    })
    return Object.values(this.validFile).reduce((p,n) => p && n, true)
  }

  returnProduct() {
    // this.clearForm();
    this.disableSaveButton = true;
    this.loading = true;
    if (this.isValidFormInput(this.returnTicket) && this.isValidFiles()) {
      const formData = new FormData();

      const returnTicket = Object.assign({}, this.returnTicket);
      const customerId = this.customerId;
      returnTicket.tenantId = +localStorage.getItem('tenant');
      returnTicket.contractProductId = this.contractId;
      returnTicket.countryAdminZoneId = this.countryAdminZone.id;
      returnTicket.customerId = this.customerId;
      this.files.forEach(file => {
        formData.append('files', file);
      });
      formData.append('surrenderForm', this.surrenderFormFile[0]);

      formData.append('data', new Blob([JSON.stringify(returnTicket)], {
        type: 'application/json'
      }));

      this.returnTicketService.saveReturnTicket(formData).subscribe(data => {
        this.loading = false;
        if (data && data.id) {
          this.toastrService.success('Returned product successfully', 'Successful!');
          this.outputCreateRepossessionTicket.emit(data);
          this.disableSaveButton = false;
        } else {
          this.loading = false;
          this.disableSaveButton = false;
        }
      });
    } else {
      this.loading = false;
      this.disableSaveButton = false;
    }
  }

  // get dealers by tenant ID
  getStockingPoints($event) {
    const val = $event.target.value;
    this.checkDealerLoading = false;
    if (val.length > 2) {
      this.checkDealerLoading = true;
      this.stockingPointService.getUserStockingPoints().subscribe(
        data => {
          this.stockingPoints = data['data'];
          this.checkDealerLoading = false;
          this.ref.detectChanges();
        }, error => console.log('error', error));
    }
  }

  clearForm() {
    this.formValid.reasonForReturn = true;
    this.formValid.stockingPointId = true;
  }

  selectDealer(dealer) {
    this.returnTicket.stockingPointId = dealer.id;
    this.dealerQuery = dealer.businessName + ' (Dealer id: ' + dealer.dealerId + ' )';
    this.stockingPoints = [];
  }

  setReturnType(value) {
    this.returnTicket['repossession'] = value === 'Repossession';
    this.returnTicket['categoryId'] = null;
    this.getCategories();
  }

  onFileChange($event, subunit) {
    const file = $event.srcElement.files[0];
    this.outputAddAttachment.emit(file);

    if (this.files.filter(f => f.name === file.name).length === 0) {
      this.files.push((file))
    }



    subunit['fileName'] = file.name;
  }

  onSurrenderFormChange(event) {
    this.surrenderFormFile = event.srcElement.files
    const file = event.srcElement.files[0];
    this.outputAddAttachment.emit(file);
  };

  getProductComponents(id) {
    this.contractService.getProductComponents(id).subscribe(
      data => {
        if (data) {
          this.returnTicket.items = [...data];
          this.validFile = this.returnTicket.items.reduce((p,n)=>({...p,[n.id]:true}),{})
          for (let i = 0; i < this.returnTicket.items.length; i++) {
            this.returnTicket.items[i]['internalId'] = i;
          }
          this.setStates();
          this.ref.detectChanges();
          this.mainLoading = false;
        }
      }, error => {
        console.log(error, 'error......');
      });
  }

  getProductStates() {
    this.returnTicketService.getProductStates().subscribe(
      data => {
        this.productStates = data
      }
    )
  }

  getL3RepairCenter() {
    this.repairCentreService.getRepairCenters(3).subscribe(
      data => {
        this.repairCenters = data
      }
    )
  }

  getL3RepairCenterByMappedUser() {
    this.repairCentreService.getRepairCentersByLevelAndMappedUser(3).subscribe(
      data => {
        this.repairCenters = data
      }
    )
  }

  getCategories() {
    if (this.returnTicket['repossession']) {
      this.returnTicketService.getRepossessionCategories().subscribe(
        data => {
          this.categories = data;
        }
      );
    } else {
      this.returnTicketService.getReturnCategories().subscribe(
        data => {
          this.categories = data;
        }
      );
    }
  }

  setStates() {
    this.returnTicket.items.forEach(item => item.status = this.productStates[0]);
    this.returnTicket.items.forEach(item => item.productId = item.id);
  }

  initialize() {
    // this.selectedCustomerId$ = this.store.pipe(select(fromSelectors.getSelectedCustomerId));
    // this.customer$ = this.store.pipe(select(fromSelectors.getCustomerById));

    this.customerService.getCountryAdminZoneForCustomer(this.customerId).subscribe(x => {
      this.countryAdminZone = x;
    })
    this.getProductComponents(this.contractId);
    this.getProductStates();
    this.getL3RepairCenterByMappedUser();
    this.getCategories();

    this.contractService.getRemainingDays(null, this.contractId).subscribe(data => {
      this.remainingDays = data;
    });
  }


  subunitFiles = [];

  triggerFileUploadButton(subunit:any) {
    console.log(subunit)
    let btn = document.getElementById('fileupload' + subunit.internalId);
    btn?.click();
  }
}
