import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import {catchError, map, mergeMap} from 'rxjs/operators';
import {of} from 'rxjs';


import {ProductCatalogueService} from '@appServices/core-masterdata/product-catalogue/product-catalogue.service';
import {TenantService} from '@appServices/core-masterdata/tenant/tenant.service';

import {ProductWarrantyTypesService} from '../../../../services/core-masterdata/product-warranty-types/product-warranty-types.service';
import {
  GetProductWarrantyTypesSuccess,
  ProductWarrantyTypesActionTypes,
  ProductWarrantyTypesError
} from '../../../actions/core-masterdata/product-warranty-types/product-warranty-types.actions';

@Injectable()
export class ProductWarrantyTypesEffects {
  
  getProductWarrantyTypes = createEffect(() => this.actions$.pipe(
    ofType(ProductWarrantyTypesActionTypes.productWarrantyGetProductWarrantyTypes),
    mergeMap(() =>
      this.productWarrantyTypesService
        .getProductWarrantyTypes()
        .pipe(
          map(productWarrantyTypes => new GetProductWarrantyTypesSuccess(productWarrantyTypes)),
          catchError(error => of(new ProductWarrantyTypesError(error)))
        )
    )
  ));

  constructor(private actions$: Actions, private productWarrantyTypesService: ProductWarrantyTypesService) {
  }
}
