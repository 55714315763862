import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {of} from "rxjs";
import {
  WorkOrderRepairActionTypes
} from '../../../actions/core-aftersales/work-order/workorderepair.action';
import {WorkOrderRepairService} from '../../../../services/core-aftersales/work-order/work-order-repair.service';
import {
  WorkOrderRepairError, WorkOrderRepairGetAll,
  WorkOrderRepairGetAllSuccess
} from '../../../actions/core-aftersales/work-order/workorderepair.action';

@Injectable()
export class WorkOrderRepairEffects {

  
  workOrderRepairs$ = createEffect(() => this.actions$.pipe(
    ofType(WorkOrderRepairActionTypes.workOrderRepairGetAll),
    mergeMap((action: WorkOrderRepairGetAll) =>
      this.workOrderRepairService
        .getWorkOrderRepairs()
        .pipe(
          map(wo => new WorkOrderRepairGetAllSuccess(wo)),
          catchError(error => of(new WorkOrderRepairError(error)))
        )
    )
  ));


  constructor(
    private actions$: Actions,
    private workOrderRepairService: WorkOrderRepairService,
  ) {
  }
}
