import {Action} from '@ngrx/store';
import {OowRepairCondition} from '../../../../models/core-setup/oow-repair-conditions/oow-repair-condition';

export enum OowRepairConditionsActionTypes {
  oowRepairConditionsGetAll = '[Out of Warranty Repair Condition] Get All',
  oowRepairConditionsGetAllSuccess = '[Out of Warranty Repair Condition] Get All Success',
  oowRepairConditionsError = '[Out of Warranty Repair Condition] Error',
}

export class GetAllOowRepairConditions implements Action {
  readonly type = OowRepairConditionsActionTypes.oowRepairConditionsGetAll;

  constructor() {
  }
}

export class GetAllOowRepairConditionsSuccess implements Action {
  readonly type = OowRepairConditionsActionTypes.oowRepairConditionsGetAllSuccess;

  constructor(public payload: OowRepairCondition[]) {
  }
}

export class OowRepairConditionsError implements Action {
  readonly type = OowRepairConditionsActionTypes.oowRepairConditionsError;

  constructor(public payload: any) {
  }
}


export type OowRepairConditionsActions =
  | GetAllOowRepairConditions
  | GetAllOowRepairConditionsSuccess
  | OowRepairConditionsError;




