import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable, of} from 'rxjs';

import {CoreTicketingService} from "@appServices/core-ticketing/core-ticketing.service";
import {catchError, tap} from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class ProjectService extends CoreTicketingService {

  constructor(
    private http: HttpClient,
  ) {
    super(http, 'project');
    this.headers = this.headers.append('skip-cache', 'true');
  }

  getProjects(useTenant: boolean): Observable<any> {
    let tenantId = localStorage.getItem('tenant');
    let params = {};
    if (useTenant) {
      params = {
        tenantId: tenantId
      }
    }

    const url = `${this.base_url}/getProjects`;
    return this.http.get<any>(url, {params: params,headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }

  getProjectsForTeamCreation(useTenant: boolean): Observable<any> {
    let tenantId = localStorage.getItem('tenant');
    let params = {};
    if (useTenant) {
      params = {
        tenantId: tenantId
      }
    }

    const url = `${this.base_url}/getProjectsForTeamCreation`;
    return this.http.get<any>(url, {params: params,headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }



  getProjectsForTeam(useTenant: boolean, teamId:number): Observable<any> {
    let tenantId = localStorage.getItem('tenant');
    let params = {};
    if (useTenant) {
      params = {
        tenantId: tenantId
      }
    }

    const url = `${this.base_url}/getProjectsForTeam/${teamId}`;
    return this.http.get<any>(url, {params: params,headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }

}
