import { Action } from '@ngrx/store';
import {
  EntityAdapter,
  createEntityAdapter,
  EntityState,
  Update
} from '@ngrx/entity';

import { ProductAttribute } from '@appModels/core-setup/product-attribute/product-attribute';
import { ProductAttributeActions, ProductAttributeActionTypes } from '@appStore/actions/core-masterdata/product-attribute/product-attribute.actions';

export interface State extends EntityState<ProductAttribute> {
  loaded: boolean;
  loading: boolean;
  error: any;
  selectedProductAttributeId: number;
  searchProductAttributes: ProductAttribute[];
}

export const adapter: EntityAdapter<ProductAttribute> = createEntityAdapter<ProductAttribute>();

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
  selectedProductAttributeId: null,
  error: null,
  searchTerm: '',
  searchProductAttributes: null
});

export function reducer(state = initialState, action: ProductAttributeActions): State {
  switch (action.type) {
    case ProductAttributeActionTypes.productAttributeGetProductAttributes:
    case ProductAttributeActionTypes.productAttributeAddProductAttribute:
    case ProductAttributeActionTypes.productAttributeDeleteProductAttribute:
    case ProductAttributeActionTypes.productAttributeUpdateProductAttribute:
    case ProductAttributeActionTypes.productAttributeSearchProductAttributes:
    case ProductAttributeActionTypes.productAttributeGetProductAttributeById:
      return {
        ...state,
        loading: true
      };

    case ProductAttributeActionTypes.productAttributeGetProductAttributesSuccess:
      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });

    case ProductAttributeActionTypes.productAttributeGetProductAttributeByIdSuccess:
      return { ...state, selectedProductAttributeId: action.payload.id, loading: false };

    case ProductAttributeActionTypes.productAttributeAddProductAttributeSuccess:
      return adapter.addOne(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });

    case ProductAttributeActionTypes.productAttributeUpdateProductAttributeSuccess: {
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state,
          loading: false,
          loaded: true
        }
      );
    }

    case ProductAttributeActionTypes.productAttributeDeleteProductAttributeSuccess: {
      return adapter.removeOne(action.payload.id, {
        ...state,
        loading: false,
        loaded: true
      });
    }

    case ProductAttributeActionTypes.productAttributeSearchProductAttributesSuccess:
      return {
        ...state,
        searchProductAttributes: action.payload,
        loading: false
      };

    case ProductAttributeActionTypes.productAttributeSearchProductAttributesReset:
      return {
        ...state,
        searchProductAttributes: null
      };

    case ProductAttributeActionTypes.productAttributeError:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload
      };

    default:
      return state;
  }
}

export const productAttributeEntitySelectors = adapter.getSelectors();
