import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

import {
  AddRate,
  AddRateSuccess,
  DeleteRate,
  DeleteRateSuccess,
  GetRateById,
  GetRateByIdSuccess,
  GetRateByProductId,
  GetRateByProductIdSuccess,
  GetRates,
  GetRatesByTenant,
  GetRatesByTenantSuccess,
  GetRatesSuccess,
  RateActionTypes,
  RateError,
  SearchRates,
  SearchRatesSuccess,
  UpdateRateSuccess
} from '@appStore/actions/core-accounting/rate/rate.actions';
import {RateService} from '@appServices/core-accounting/rate/rate.service';
import {ToastrService} from 'ngx-toastr';

@Injectable()
export class RateEffects {
  constructor(
    private actions$: Actions, 
    private rateService: RateService,
    private toastr: ToastrService
    ) {}

  
  loadRates$ = createEffect(() => this.actions$.pipe(
    ofType(RateActionTypes.rateGetRates),
    mergeMap((action: GetRates) =>
      this.rateService
        .getRates(action.payload)
        .pipe(
          map(rates => new GetRatesSuccess(rates)),
          catchError(error => of(new RateError(error)))
        )
    )
  ));

  // load rate by tenant product catalogue ID
  // @Effect()
  // loadRateByProductId$ = this.actions$.pipe(
  //   ofType(RateActionTypes.rateGetRateByProductId),
  //   mergeMap(() =>
  //     this.rateService
  //       .getRateBy(+localStorage.getItem('tenant'))
  //       .pipe(
  //         map(rate => new GetRateByProductIdSuccess(rate)),
  //         catchError(error => of(new RateError(error)))
  //       )
  //   )
  // );

  // @Effect()
  // loadRatesByTenant$ = this.actions$.pipe(
  //   ofType(RateActionTypes.rateGetRates),
  //   mergeMap(() =>
  //     this.rateService
  //       .getRateByTenant(+localStorage.getItem('tenant'))
  //       .pipe(
  //         map(rates => new GetRatesSuccess(rates)),
  //         catchError(error => of(new RateError(error)))
  //       )
  //   )
  // );

  
  getRateById$ = createEffect(() => this.actions$.pipe(
    ofType(RateActionTypes.rateGetRateById),
    mergeMap((action: GetRateById) =>
      this.rateService
        .getRate(action.payload)
        .pipe(
          map(rate => new GetRateByIdSuccess(rate)),
          catchError(error => of(new RateError(error)))
        )
    )
  ));

  
  addRate$ = createEffect(() => this.actions$.pipe(
    ofType(RateActionTypes.rateAddRate),
    switchMap((action: AddRate) =>
      this.rateService
        .addRate(action.payload)
        .pipe(
          map(
            rate => {
              if (rate !== undefined) {
                this.toastr.success('Rate has been successfully added!', 'Successful!');
                return new AddRateSuccess(rate)
              }
              this.toastr.error('There was an error adding this rate', "Unknown error");
              return new RateError({ type: 500, message: 'Internal error' })
            }
            ),
          catchError(error => of(new RateError(error)))
        )
    )
  ));

  
  addAdditionalRateInfo$ = createEffect(() => this.actions$.pipe(
    ofType(RateActionTypes.rateAddAdditionalRateInfo),
    mergeMap((action: AddRate) =>
      this.rateService
        .addRateAdditionalInfo(action.payload)
        .pipe(
          map(rate => new GetRates()),
          catchError(error => of(new RateError(error)))
        )
    )
  ));

  
  updateRate$ = createEffect(() => this.actions$.pipe(
    ofType(RateActionTypes.rateUpdateRate),
    mergeMap((action: AddRate) =>
      this.rateService
        .updateRate(action.payload)
        .pipe(
          map(rate => {
            if (rate !== undefined) {
              this.toastr.success('Rate has been successfully updated!', 'Successful!');
              return  new UpdateRateSuccess(action.payload)
            }
            // this.toastr.error('There was an error updating this rate', "Unknown error");
            return new RateError({ type: 500, message: 'Internal error' })
          }),
          catchError(error => of(new RateError(error)))
        )
    )
  ));

  
  deleteRate$ = createEffect(() => this.actions$.pipe(
    ofType(RateActionTypes.rateDeleteRate),
    mergeMap((action: DeleteRate) =>
      this.rateService
        .deleteRate(action.payload)
        .pipe(
          map(() => new DeleteRateSuccess(action.payload)),
          catchError(error => of(new RateError(error)))
        )
    )
  ));

  
  searchRates$ = createEffect(() => this.actions$.pipe(
    ofType(RateActionTypes.rateSearchRates),
    mergeMap((action: SearchRates) =>
      this.rateService
        .searchRates(action.payload)
        .pipe(
          map(rates => new SearchRatesSuccess(rates)),
          catchError(error => of(new RateError(error)))
        )
    )
  ));

  // @Effect()
  // updateRateSuccess$ = this.actions$.pipe(
  //   ofType(RateActionTypes.rateUpdateRateSuccess),
  //   map(rate => new fromRouterActions.Go({ path: ['/setup/rates'] }))
  // );
}
