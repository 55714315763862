import {Action} from '@ngrx/store';

import {Rate} from '@appModels/core-accounting/rate/rate';

export enum RateActionTypes {
  rateGetRates = '[Rate] get',
  rateGetRatesSuccess = '[Rate] get rates success',
  rateGetRateByTenant = '[Rate] get rates by tenant id',
  rateGetRateByTenantSuccess = '[Rate] get rates by tenant id success',
  rateGetRateByProductId = '[Rate] get rate by product id',
  rateGetRateByProductIdSuccess = '[Rate] get rate by product id success',
  rateAddRate = '[Rate] add Rate',
  rateAddRateSuccess = '[Rate] add rate success',
  rateAddAdditionalRateInfo = '[Rate] add additional Rate Info',
  rateAddAdditionalRateInfoSuccess = '[Rate] add additional rate Info success',
  rateDeleteRate = '[Rate] delete rate',
  rateDeleteRateSuccess = '[Rate] delete rate success',
  rateGetRateById = '[Rate] get rate by id',
  rateGetRateByIdSuccess = '[Rate] get rate by id success',
  rateUpdateRate = '[Rate] update rate',
  rateUpdateRateSuccess = '[Rate] update rate success',
  rateSearchRates = '[Rate] search rates',
  rateSearchRatesSuccess = '[Rate] search rates success',
  rateSearchRatesReset = '[Rate] search rates reset',
  rateError = '[Rate] error',
  rateGetRatesByTenantId = '[Rate] get rates by tenant id',
  rateGetRatesByTenantIdSuccess = '[Rate] get rates by tenant id success',

}

export class GetRates implements Action {
  readonly type = RateActionTypes.rateGetRates;
  constructor(public payload?) {}
}

export class GetRatesSuccess implements Action {
  readonly type = RateActionTypes.rateGetRatesSuccess;
  constructor(public payload) {}
}

export class GetRatesByTenantId implements Action {
  readonly type = RateActionTypes.rateGetRatesByTenantId;
}

export class GetRatesByTenantIdSuccess implements Action {
  readonly type = RateActionTypes.rateGetRatesByTenantIdSuccess;

  constructor(public payload: Rate[]) {
  }
}

export class AddRate implements Action {
  readonly type = RateActionTypes.rateAddRate;
  constructor(public payload: Rate) {}
}

export class GetRatesByTenant implements Action {
  readonly type = RateActionTypes.rateGetRateByTenant;

  constructor(public payload) {
  }
}

export class GetRatesByTenantSuccess implements Action {
  readonly type = RateActionTypes.rateGetRateByTenantSuccess;

  constructor(public payload: Rate[]) {
  }
}

export class AddRateSuccess implements Action {
  readonly type = RateActionTypes.rateAddRateSuccess;
  constructor(public payload: Rate) {}
}

export class AddAdditionalRateInfo implements Action {
  readonly type = RateActionTypes.rateAddAdditionalRateInfo;
  constructor(public payload) {}
}

export class AddAdditionalRateInfoSuccess implements Action {
  readonly type = RateActionTypes.rateAddAdditionalRateInfoSuccess;
  constructor(public payload) {}
}

export class GetRateById implements Action {
  readonly type = RateActionTypes.rateGetRateById;
  constructor(public payload: number) {}
}

export class GetRateByIdSuccess implements Action {
  readonly type = RateActionTypes.rateGetRateByIdSuccess;
  constructor(public payload: Rate) {}
}

export class GetRateByProductId implements Action {
  readonly type = RateActionTypes.rateGetRateByProductId;
  constructor(public payload: number) {}
}

export class GetRateByProductIdSuccess implements Action {
  readonly type = RateActionTypes.rateGetRateByProductIdSuccess;
  constructor(public payload: Rate) {}
}

export class UpdateRate implements Action {
  readonly type = RateActionTypes.rateUpdateRate;
  constructor(public payload: Rate) {}
}

export class UpdateRateSuccess implements Action {
  readonly type = RateActionTypes.rateUpdateRateSuccess;
  constructor(public payload: Rate) {}
}

export class DeleteRate implements Action {
  readonly type = RateActionTypes.rateDeleteRate;
  constructor(public payload: Rate) {}
}

export class DeleteRateSuccess implements Action {
  readonly type = RateActionTypes.rateDeleteRateSuccess;
  constructor(public payload: Rate) {}
}

export class SearchRates implements Action {
  readonly type = RateActionTypes.rateSearchRates;
  constructor(public payload: string) {}
}

export class SearchRatesSuccess implements Action {
  readonly type = RateActionTypes.rateSearchRatesSuccess;
  constructor(public payload: Rate[]) {}
}

export class SearchRatesReset implements Action {
  readonly type = RateActionTypes.rateSearchRatesReset;
}

export class RateError implements Action {
  readonly type = RateActionTypes.rateError;
  constructor(public payload: any) {}
}

export type RateActions =
  | GetRates
  | GetRatesSuccess
  | AddRate
  | AddRateSuccess
  | GetRatesByTenant
  | GetRatesByTenantSuccess
  | AddAdditionalRateInfo
  | AddAdditionalRateInfoSuccess
  | GetRateById
  | GetRateByIdSuccess
  | GetRateByProductId
  | GetRateByProductIdSuccess
  | UpdateRate
  | UpdateRateSuccess
  | DeleteRate
  | DeleteRateSuccess
  | SearchRates
  | SearchRatesSuccess
  | SearchRatesReset
  | RateError
  | GetRatesByTenantId
  | GetRatesByTenantIdSuccess;
