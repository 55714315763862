import {Action} from '@ngrx/store';

import {VatConfiguration} from '@appModels/core-accounting/vat-configuration/vat-configuration';

export enum VatConfigurationActionTypes {
  vatConfigurationGetVatConfigurations = '[VatConfiguration] get',
  vatConfigurationGetVatConfigurationsSuccess = '[VatConfiguration] get vatConfigurations success',
  vatConfigurationGetVatConfigurationByTenant = '[VatConfiguration] get vatConfigurations by tenant id',
  vatConfigurationGetVatConfigurationByTenantSuccess = '[VatConfiguration] get vatConfigurations by tenant id success',
  vatConfigurationGetVatConfigurationByProductId = '[VatConfiguration] get vatConfiguration by product id',
  vatConfigurationGetVatConfigurationByProductIdSuccess = '[VatConfiguration] get vatConfiguration by product id success',
  vatConfigurationAddVatConfiguration = '[VatConfiguration] add VatConfiguration',
  vatConfigurationAddVatConfigurationSuccess = '[VatConfiguration] add vatConfiguration success',
  vatConfigurationAddAdditionalVatConfigurationInfo = '[VatConfiguration] add additional VatConfiguration Info',
  vatConfigurationAddAdditionalVatConfigurationInfoSuccess = '[VatConfiguration] add additional vatConfiguration Info success',
  vatConfigurationDeleteVatConfiguration = '[VatConfiguration] delete vatConfiguration',
  vatConfigurationDeleteVatConfigurationSuccess = '[VatConfiguration] delete vatConfiguration success',
  vatConfigurationGetVatConfigurationById = '[VatConfiguration] get vatConfiguration by id',
  vatConfigurationGetVatConfigurationByIdSuccess = '[VatConfiguration] get vatConfiguration by id success',
  vatConfigurationUpdateVatConfiguration = '[VatConfiguration] update vatConfiguration',
  vatConfigurationUpdateVatConfigurationSuccess = '[VatConfiguration] update vatConfiguration success',
  vatConfigurationSearchVatConfigurations = '[VatConfiguration] search vatConfigurations',
  vatConfigurationSearchVatConfigurationsSuccess = '[VatConfiguration] search vatConfigurations success',
  vatConfigurationSearchVatConfigurationsReset = '[VatConfiguration] search vatConfigurations reset',
  vatConfigurationError = '[VatConfiguration] error',
  vatConfigurationGetVatConfigurationsByTenantId = '[VatConfiguration] get vatConfigurations by tenant id',
  vatConfigurationGetVatConfigurationsByTenantIdSuccess = '[VatConfiguration] get vatConfigurations by tenant id success',

}

export class GetVatConfigurations implements Action {
  readonly type = VatConfigurationActionTypes.vatConfigurationGetVatConfigurations;
  constructor(public payload?) {}
}

export class GetVatConfigurationsSuccess implements Action {
  readonly type = VatConfigurationActionTypes.vatConfigurationGetVatConfigurationsSuccess;
  constructor(public payload) {}
}

export class GetVatConfigurationsByTenantId implements Action {
  readonly type = VatConfigurationActionTypes.vatConfigurationGetVatConfigurationsByTenantId;
}

export class GetVatConfigurationsByTenantIdSuccess implements Action {
  readonly type = VatConfigurationActionTypes.vatConfigurationGetVatConfigurationsByTenantIdSuccess;

  constructor(public payload: VatConfiguration[]) {
  }
}

export class AddVatConfiguration implements Action {
  readonly type = VatConfigurationActionTypes.vatConfigurationAddVatConfiguration;
  constructor(public payload: VatConfiguration) {}
}

export class GetVatConfigurationsByTenant implements Action {
  readonly type = VatConfigurationActionTypes.vatConfigurationGetVatConfigurationByTenant;

  constructor(public payload) {
  }
}

export class GetVatConfigurationsByTenantSuccess implements Action {
  readonly type = VatConfigurationActionTypes.vatConfigurationGetVatConfigurationByTenantSuccess;

  constructor(public payload: VatConfiguration[]) {
  }
}

export class AddVatConfigurationSuccess implements Action {
  readonly type = VatConfigurationActionTypes.vatConfigurationAddVatConfigurationSuccess;
  constructor(public payload: VatConfiguration) {}
}

export class AddAdditionalVatConfigurationInfo implements Action {
  readonly type = VatConfigurationActionTypes.vatConfigurationAddAdditionalVatConfigurationInfo;
  constructor(public payload) {}
}

export class AddAdditionalVatConfigurationInfoSuccess implements Action {
  readonly type = VatConfigurationActionTypes.vatConfigurationAddAdditionalVatConfigurationInfoSuccess;
  constructor(public payload) {}
}

export class GetVatConfigurationById implements Action {
  readonly type = VatConfigurationActionTypes.vatConfigurationGetVatConfigurationById;
  constructor(public payload: number) {}
}

export class GetVatConfigurationByIdSuccess implements Action {
  readonly type = VatConfigurationActionTypes.vatConfigurationGetVatConfigurationByIdSuccess;
  constructor(public payload: VatConfiguration) {}
}

export class GetVatConfigurationByProductId implements Action {
  readonly type = VatConfigurationActionTypes.vatConfigurationGetVatConfigurationByProductId;
  constructor(public payload: number) {}
}

export class GetVatConfigurationByProductIdSuccess implements Action {
  readonly type = VatConfigurationActionTypes.vatConfigurationGetVatConfigurationByProductIdSuccess;
  constructor(public payload: VatConfiguration) {}
}

export class UpdateVatConfiguration implements Action {
  readonly type = VatConfigurationActionTypes.vatConfigurationUpdateVatConfiguration;
  constructor(public payload: VatConfiguration) {}
}

export class UpdateVatConfigurationSuccess implements Action {
  readonly type = VatConfigurationActionTypes.vatConfigurationUpdateVatConfigurationSuccess;
  constructor(public payload: VatConfiguration) {}
}

export class DeleteVatConfiguration implements Action {
  readonly type = VatConfigurationActionTypes.vatConfigurationDeleteVatConfiguration;
  constructor(public payload: VatConfiguration) {}
}

export class DeleteVatConfigurationSuccess implements Action {
  readonly type = VatConfigurationActionTypes.vatConfigurationDeleteVatConfigurationSuccess;
  constructor(public payload: VatConfiguration) {}
}

export class SearchVatConfigurations implements Action {
  readonly type = VatConfigurationActionTypes.vatConfigurationSearchVatConfigurations;
  constructor(public payload: string) {}
}

export class SearchVatConfigurationsSuccess implements Action {
  readonly type = VatConfigurationActionTypes.vatConfigurationSearchVatConfigurationsSuccess;
  constructor(public payload: VatConfiguration[]) {}
}

export class SearchVatConfigurationsReset implements Action {
  readonly type = VatConfigurationActionTypes.vatConfigurationSearchVatConfigurationsReset;
}

export class VatConfigurationError implements Action {
  readonly type = VatConfigurationActionTypes.vatConfigurationError;
  constructor(public payload: any) {}
}

export type VatConfigurationActions =
  | GetVatConfigurations
  | GetVatConfigurationsSuccess
  | AddVatConfiguration
  | AddVatConfigurationSuccess
  | GetVatConfigurationsByTenant
  | GetVatConfigurationsByTenantSuccess
  | AddAdditionalVatConfigurationInfo
  | AddAdditionalVatConfigurationInfoSuccess
  | GetVatConfigurationById
  | GetVatConfigurationByIdSuccess
  | GetVatConfigurationByProductId
  | GetVatConfigurationByProductIdSuccess
  | UpdateVatConfiguration
  | UpdateVatConfigurationSuccess
  | DeleteVatConfiguration
  | DeleteVatConfigurationSuccess
  | SearchVatConfigurations
  | SearchVatConfigurationsSuccess
  | SearchVatConfigurationsReset
  | VatConfigurationError
  | GetVatConfigurationsByTenantId
  | GetVatConfigurationsByTenantIdSuccess;
