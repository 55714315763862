import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';

import {TenantCustomFieldOption} from '@appModels/core-setup/tenant-custom-field-option/tenant-custom-field-option';

import {CoreMasterDataService} from '../core-masterdata.service';


@Injectable({providedIn: 'root'})
export class TenantCustomFieldOptionService extends CoreMasterDataService {


  constructor(
    private http: HttpClient,
  ) {
    super(http, 'tenantcustomfieldoption');
  }

  /** GET tenantCustomFields from the server */
  getTenantCustomFieldOptions(): Observable<TenantCustomFieldOption[]> {
    return this.http.get<TenantCustomFieldOption[]>(this.base_url , {headers: this.headers}).pipe(
      tap(tenantCustomFields => this.log(`fetched tenantCustomFields`)),
      catchError(this.handleError('getTenantCustomFields', []))
    );
  }

  /** GET tenantCustomField by id. Return `undefined` when id not found */
  getTenantCustomFieldOptionNo404<Data>(id: number): Observable<TenantCustomFieldOption> {
    const url = `${this.base_url}/?id=${id}`;
    return this.http.get<TenantCustomFieldOption[]>(url).pipe(
      map(tenantCustomFields => tenantCustomFields[0]), // returns a {0|1} element array
      tap(h => {
        const outcome = h ? `fetched` : `did not find`;
        this.log(`${outcome} tenantCustomFieldOption id=${id}`);
      }),
      catchError(this.handleError<TenantCustomFieldOption>(`getTenantCustomFieldOption id=${id}`))
    );
  }

  /** GET tenantCustomFieldOption by id. Will 404 if id not found */
  getTenantCustomFieldOption(id: number): Observable<TenantCustomFieldOption> {
    const url = `${this.base_url}/${id}`;
    return this.http.get<TenantCustomFieldOption>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched tenantCustomFieldOption id=${id}`)),
      catchError(this.handleError<TenantCustomFieldOption>(`getTenantCustomField id=${id}`))
    );
  }

  /* GET tenantCustomFieldOptions whose name contains search term */
  searchTenantCustomFieldOptions(term: string): Observable<TenantCustomFieldOption[]> {
    if (!term.trim()) {
      // if not search term, return empty tenantCustomField array.
      return of([]);
    }
    return this.http.get<TenantCustomFieldOption[]>(`api/tenantCustomFields/?name=${term}`).pipe(
      tap(_ => this.log(`found tenantCustomFields matching "${term}"`)),
      catchError(this.handleError<TenantCustomFieldOption[]>('searchTenantCustomFields', []))
    );
  }

  //////// Save methods //////////

  /** POST: add a new tenantCustomField to the server */
  addTenantCustomFieldOption(tenantCustomFieldOption: TenantCustomFieldOption): Observable<TenantCustomFieldOption> {
    return this.http.post<TenantCustomFieldOption>(this.base_url , tenantCustomFieldOption, {headers: this.headers}).pipe(
      tap((newTenantCustomField: TenantCustomFieldOption) => {
        this.log(`added TenantCustomField w/ id=${newTenantCustomField.id}`);
        // this.editTenantCustomFieldOption({
        //   value: tenantCustomFieldOption.tenantCustomFieldId,
        //   id: newTenantCustomField.id,
        //   param: 'tenantcustomfield'
        // }).subscribe(data => {
        // }, error => console.log(error, 'custom field error'));
        //
      }),
      catchError(this.handleError<TenantCustomFieldOption>('addTenantCustomField'))
    );
  }

  /** DELETE: delete the tenantCustomField from the server */
  deleteTenantCustomField(tenantCustomFieldOption: TenantCustomFieldOption | number): Observable<TenantCustomFieldOption> {
    const id = typeof tenantCustomFieldOption === 'number' ? tenantCustomFieldOption : tenantCustomFieldOption.id;
    const url = `${this.base_url}/${id}`;

    return this.http.delete<TenantCustomFieldOption>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`deleted tenantCustomFieldOption id=${id}`)),
      catchError(this.handleError<TenantCustomFieldOption>('deleteTenantCustomFieldOption'))
    );
  }

  /** PUT: update the tenantCustomFieldOption on the server */
  updateTenantCustomFieldOption(tenantCustomFieldOption: TenantCustomFieldOption): Observable<any> {
    return this.http.put(`${this.base_url}/${tenantCustomFieldOption.id}`, tenantCustomFieldOption, {headers: this.headers}).pipe(
      tap(_ => this.log(`updated tenantCustomFieldOption id=${tenantCustomFieldOption.id}`)),
      catchError(this.handleError<any>('updateTenantCustomFieldOption'))
    );
  }

  /** Patch: update the tenantCustomFieldOption on the server */
  editTenantCustomFieldOption(data): Observable<any> {
    return this.http.patch(`${this.base_url}/${data.id}/${data.param}/${data.value}`, {}, {headers: this.headers}).pipe(
      tap(_ => this.log(`updated tenantCustomFieldOption id=${data.id}`)),
      catchError(this.handleError<any>('updateTenantCustomFieldOption'))
    );
  }

}
