import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable, of} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';


import {Commission} from '@appModels/core-fieldforce/commission/commission';
import {CoreIncentivemanagementService} from '../core-incentivemanagement.service';


@Injectable({providedIn: 'root'})
export class TenantCommissionService extends CoreIncentivemanagementService {

  constructor(
    private http: HttpClient,
  ) {
    super(http, '');
  }

  getTenantCommissions(id: number, query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        if (query[val] !== null && query[val] !== undefined && query[val] !== '') {
          params = params.append(val, query[val]);
        }
      });
    }

    const url = `${this.base_url}commissions?tenantId=${id}`;
    return this.http.get(url, {headers: this.headers, params: params}).pipe(
      tap(_ => this.log(`fetched tenantcommissions id=${id}`)),
      catchError(this.handleError(`getTenantCommissions id=${id}`))
    );
  }

  /* GET sales staff definitions whose name contains search term */
  searchCommissions(term: string): Observable<Commission[]> {
    if (!term.trim()) {
      // if not search term, return empty stockingPoint array.
      return of([]);
    }
    return this.http.get<Commission[]>(`${this.base_url}salesstaff/searchSalesStaff?searchString=${term}&tenantId=${localStorage.getItem('tenant')}`).pipe(
      tap(_ => this.log(`found salesStaff matching "${term}"`)),
      catchError(this.handleError<Commission[]>('salesStaff', []))
    );
  }


  addCommission(commission: Commission): Observable<Commission> {
    const com = Object.assign({}, commission)
    com.tenantId = Number(localStorage.getItem('tenant'));
    return this.http.post<Commission>(this.base_url + 'commission', commission, {headers: this.headers}).pipe(
      catchError(this.handleError<Commission>('addCommission'))
    );
  }

}
