import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';

import {Consignment} from '@appModels/core-inventory/consignment/consignment';
import {
  ConsignmentActions,
  ConsignmentActionTypes
} from '@appStore/actions/core-inventory/consignment/consignment.action';

export interface State extends EntityState<Consignment> {
  count: number,
  startPage: number,
  numberPerPage: number,
  loaded: boolean;
  loading: boolean;
  error: any;
  selectedConsignmentId: Consignment;
  searchConsignments: Consignment[];
  stockingPointConsignments: Consignment[];
}

export const adapter: EntityAdapter<Consignment> = createEntityAdapter<Consignment>();

export const initialState: State = adapter.getInitialState({
  count: null,
  startPage: null,
  numberPerPage: null,
  loaded: false,
  loading: false,
  selectedConsignmentId: null,
  error: null,
  searchTerm: '',
  searchConsignments: null,
  stockingPointConsignments: null,
  addedInventoryProduct: null

});

export function reducer(state = initialState, action: ConsignmentActions): State {
  switch (action.type) {
    case ConsignmentActionTypes.consignmentGetConsignments:
    case ConsignmentActionTypes.consignmentAddConsignment:
    case ConsignmentActionTypes.consignmentAddAdditionalConsignmentInfo:
    case ConsignmentActionTypes.consignmentDeleteConsignment:
    case ConsignmentActionTypes.consignmentUpdateConsignment:
    case ConsignmentActionTypes.consignmentSearchConsignments:
    case ConsignmentActionTypes.consignmentGetConsignmentById:
    case ConsignmentActionTypes.consignmentGetConsignmentByStockingPointId:
    case ConsignmentActionTypes.consignmentGetConsignmentByTenantId:
    case ConsignmentActionTypes.consignmentPatchShippingCompany:
      return {
        ...state,
        loading: true
      };

    case ConsignmentActionTypes.consignmentGetConsignmentsSuccess:
      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });

    case ConsignmentActionTypes.consignmentGetConsignmentByIdSuccess:
      return {
        ...state,
        selectedConsignmentId: action.payload,
        loading: false
      };

    case ConsignmentActionTypes.consignmentAddConsignmentSuccess:
      return adapter.addOne(action.payload, {
        ...state,
        selectedConsignmentId: action.payload,
        loading: false,
        loaded: true
      });

    case ConsignmentActionTypes.consignmentUpdateConsignmentSuccess: {
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state,
          loading: false,
          loaded: true
        }
      );
    }

    case ConsignmentActionTypes.consignmentDeleteConsignmentSuccess: {
      return adapter.removeOne(action.payload.id, {
        ...state,
        loading: false,
        loaded: true
      });
    }

    case ConsignmentActionTypes.consignmentGetConsignmentByStockingPointIdSuccess: {
      return adapter.setAll(action.payload.data, {
        ...state,
        loading: false,
        loaded: true,
        count: action.payload.count,
        startPage: action.payload.startPage,
        numberPerPage: action.payload.numberPerPage,
      });
    }

    case ConsignmentActionTypes.consignmentGetConsignmentByTenantIdSuccess: {
      return adapter.setAll(action.payload.data, {
        ...state,
        loading: false,
        loaded: true,
        count: action.payload.count,
        startPage: action.payload.startPage,
        numberPerPage: action.payload.numberPerPage,
      });
    }

    case ConsignmentActionTypes.consignmentSearchConsignmentsSuccess:
      return {
        ...state,
        searchConsignments: action.payload,
        loading: false
      };

    case ConsignmentActionTypes.consignmentSearchConsignmentsReset:
      return {
        ...state,
        searchConsignments: null
      };

    case ConsignmentActionTypes.consignmentError:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload
      };


    case ConsignmentActionTypes.consignmentDoNothing:
      return {
        ...state,
        loading: false,
        loaded: false
      };

    case ConsignmentActionTypes.consignmentOk:
      return {
        ...state,
        loading: false,
        loaded: false
      };

    case ConsignmentActionTypes.consignmentPatchStockingPointFrom:
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state
        }
      );

    case ConsignmentActionTypes.consignmentPatchStockingPointFromSuccess:
      return {
        ...state,
        error: action.payload
      };

    case ConsignmentActionTypes.consignmentPatchStockingPointTo:
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state
        }
      );

    case ConsignmentActionTypes.consignmentPatchShippingCompanySuccess:
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state
        }
      );

    case ConsignmentActionTypes.consignmentPatchStockingPointToSuccess:
      return {
        ...state,
        error: action.payload
      };

    default:
      return state;
  }
}

export const consignmentEntitySelectors = adapter.getSelectors();
