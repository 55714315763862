import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';

import {CoreInventoryService} from '../core-inventory.service';
import {ConsignmentProductAcceptReceiveStatus} from '@appModels/core-inventory/consignment-product-accept-receive-status/consignment-product-accept-receive-status';
import {ShippingCompany} from "@appModels/core-inventory/shipping-company/shipping-company";


@Injectable({providedIn: 'root'})
export class ConsignmentProductAcceptReceiveStatusService extends CoreInventoryService {


  constructor(
    private http: HttpClient,
  ) {
    super(http, 'consignmentproduct');
  }

  /** GET getConsignmentProductAcceptReceiveStatuses */
  getConsignmentProductAcceptReceiveStatuses(): Observable<ConsignmentProductAcceptReceiveStatus[]> {
    let tenantId = localStorage.getItem('tenant');
    let url = `${this.base_url}acceptreceivestatuses/tenant/${tenantId}`;
    return this.http.get<ConsignmentProductAcceptReceiveStatus[]>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched consignmentProductAcceptReceiveStatuses`)),
      catchError(this.handleError<ConsignmentProductAcceptReceiveStatus[]>(`consignmentProductAcceptReceiveStatuses`))
    );
  }

  deleteConsignmentProductAcceptReceiveStatus(consignmentProductAcceptReceiveStatus: ConsignmentProductAcceptReceiveStatus): Observable<any> {
    const url = `${this.base_url}acceptreceivestatus/`;
    return this.http.patch(url + consignmentProductAcceptReceiveStatus.id, {headers: this.headers}).pipe(
      tap((res) => this.log(`patch consignmentProductAcceptReceiveStatuses w/ consignmentProductAcceptReceiveStatuses=${consignmentProductAcceptReceiveStatus.id}`)),
      catchError(this.handleError<ShippingCompany>('consignmentProductAcceptReceiveStatuses'))
    );
  }

  addConsignmentProductAcceptReceiveStatus(consignmentProductAcceptReceiveStatus: ConsignmentProductAcceptReceiveStatus): Observable<ConsignmentProductAcceptReceiveStatus> {
    const url = `${this.base_url}acceptreceivestatus`;
    return this.http.post<ConsignmentProductAcceptReceiveStatus>(url, consignmentProductAcceptReceiveStatus, {headers: this.headers}).pipe(
      tap((consignmentProductAcceptReceiveStatus: ConsignmentProductAcceptReceiveStatus) => this.log(`added ConsignmentProductAcceptReceiveStatus w/ id=${consignmentProductAcceptReceiveStatus.id}`)),
      catchError(this.handleError<ConsignmentProductAcceptReceiveStatus>('ConsignmentProductAcceptReceiveStatus'))
    );
  }

  getConsignmentProductAcceptReceiveStatus(id: number): Observable<ConsignmentProductAcceptReceiveStatus> {
    return this.http.get<ConsignmentProductAcceptReceiveStatus>(this.base_url + 'acceptreceivestatus/' + id, {headers: this.headers}).pipe(
      tap((res) => this.log(`get consignmentProductAcceptReceiveStatus w/ consignmentProductAcceptReceiveStatus=${id}`)),
      catchError(this.handleError<ConsignmentProductAcceptReceiveStatus>('consignmentProductAcceptReceiveStatus'))
    );
  }

  updateConsignmentProductAcceptReceiveStatus(consignmentProductAcceptReceiveStatus: ConsignmentProductAcceptReceiveStatus): Observable<ConsignmentProductAcceptReceiveStatus> {
    return this.http.put<ConsignmentProductAcceptReceiveStatus>(this.base_url + 'acceptreceivestatus/' + consignmentProductAcceptReceiveStatus.id, consignmentProductAcceptReceiveStatus, {headers: this.headers}).pipe(
      tap((res) => this.log(`updated consignmentProductAcceptReceiveStatus w/ consignmentProductAcceptReceiveStatus=${consignmentProductAcceptReceiveStatus.id}`)),
      catchError(this.handleError<ConsignmentProductAcceptReceiveStatus>('consignmentProductAcceptReceiveStatus'))
    );
  }

  patchTenantConsignmentProductAcceptReceiveStatus(consignmentProductAcceptReceiveStatus: ConsignmentProductAcceptReceiveStatus): Observable<ConsignmentProductAcceptReceiveStatus> {
    let tenantId = localStorage.getItem('tenant');
    const url = `${this.base_url}acceptreceivestatus/${consignmentProductAcceptReceiveStatus.id}/tenant/${tenantId}`;
    return this.http.patch<ConsignmentProductAcceptReceiveStatus>(url, {}, {headers: this.headers}).pipe(
      tap((consignmentProductAcceptReceiveStatus: ConsignmentProductAcceptReceiveStatus) => this.log(`patched ConsignmentProductAcceptReceiveStatus to tenant w/ id=${consignmentProductAcceptReceiveStatus.id}`)),
      catchError(this.handleError<ConsignmentProductAcceptReceiveStatus>('ConsignmentProductAcceptReceiveStatus'))
    );
  }

}
