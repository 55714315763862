import {Injectable} from '@angular/core';
import {Actions} from '@ngrx/effects';
import {ToastrService} from 'ngx-toastr';

@Injectable()
export class PermissionChannelGroupEffects {
  constructor(
    private actions$: Actions,
    private toastr: ToastrService
  ) {
  }


}
