import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';

import {CoreTicketingCustomerService} from "@appServices/core-ticketing/customer/core-ticketing-customer.service";
import {ToastrService} from "ngx-toastr";
import * as moment from "moment/moment";
import {ProductService} from "@appServices/core-masterdata/product/product.service";
import {TicketService} from "@appServices/core-aftersales/ticket/ticket.service";
import {
  ProductCustomerComplaintService
} from "@appServices/core-masterdata/product-customer-complaint/product-customer-complaint.service";
import {InventoryProductService} from "@appServices/core-inventory/inventory-product/inventory-product.service";
import {InventoryProduct} from "@appModels/core-inventory/inventory-product/inventory-product";
import {TestFlowService} from "@appServices/core-aftersales/test-flow/test-flow.service";
import {Ticket} from "@appModels/core-aftersales/ticket";
import {RepairCentreService} from "@appServices/core-aftersales/repaircentre/repair-centre.service";

@Component({
  moduleId: module.id,
  selector: 'warranty-ticket-directive',
  templateUrl: './warranty-ticket-directive.html',
  animations: [
    trigger('toggleAnimation', [
      transition(':enter', [style({ opacity: 0, transform: 'scale(0.95)' }), animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' }))]),
      transition(':leave', [animate('75ms', style({ opacity: 0, transform: 'scale(0.95)' }))]),
    ]),
  ],
  styleUrls: ['./warranty-ticket-directive.css', '../../../tailwind.css']
})
export class WarrantyTicketDirective implements OnChanges {
  constructor(
    private toastrService: ToastrService,
    private productService: ProductService,
    private ticketService: TicketService,
    private productCustomerComplaintService: ProductCustomerComplaintService,
    private inventoryProductService: InventoryProductService,
    private ref: ChangeDetectorRef,
    private testFlowService: TestFlowService,
    private repairCenterService: RepairCentreService,
    private customerService: CoreTicketingCustomerService
  ) {

    this.getNonPayGoProductVersions();
    this.getRepairCenters();
  }


  ngOnChanges(simpleChange: any) {
    // this.selectAccountNumber();
  }


  @Output() outputCreateWarrantyTicketEvent = new EventEmitter<any>();



  accountNumber:string = null;
  customerId:number = null;
  customerSelected = false;
  customerSubUnitsList = [];
  customerSubUnit = null;
  complaints = [];
  customerComplaint: any = null;
  customerComplaints = [];
  selectedSubUnitsList = [];
  selectedCustomerSubUnits = [];
  selectedProduct = null;
  inventoryProduct = null;
  customerProductList = [];
  testCaseResult = [];
  combinedAdvice = [];
  combinedAdviceText = '';

  nonPayGoSelected = false;
  loading = false;
  loadingCreate = false;
  ticketCreated = false;
  inventoryProductChildren: InventoryProduct[] = [];
  complaintsList = [];
  testFlow:any = null;

  formValid = {
    taxIdValue: true,
    emailValue: true,
    dateOfBirth: true,
    firstName: true,
    gender: true,
    identificationValue: true,
    lastName: true,
    phoneNumber: true,
    telephone2: true,
    physicalAddress: true,
    email: true,
    id: true,
    taxId: true,
    preferredLanguageId: true,
    identificationTypeId: true,
    statusId: true,
    tenantID: true,
    currentSourcesOfLight: true,
    occupation: true,
    secondaryContactEmailAddress: true,
    secondaryContactFirstName: true,
    secondaryContactLastName: true,
    secondaryContactTelephone: true,
    tenantCustomRegionId: true,
    stockingPointPhoneNumberDuplicate: true,


    stockingPointDuplicatePrimaryPhone: true,
    salesStaffWithStockingPointPhone: true,
    salesStaffDuplicatePrimaryPhone: true,
    stockingPointDuplicateName: true,
    stockingpointWithSalesStaffPhone: true,
    customerCountryAdministrativeZoneId: true,


    duplicateCustomerPhone: true,
    duplicateSalesStaffPhone: true,

    customerIdentificationValue: true,
    customerFirstName: true,
    customerLastName: true,
    customerPrimaryPhoneNumber: true,
    customerEmail: true,
    customerGender: true,


    salesStaffFirstName: true,
    salesStaffLastName: true,
    salesStaffPhoneNumber: true,
    customerEntryMethod: true,


    callPersonPhoneNumber: true,
    callPersonFirstName: true,
    callPersonLastName: true,


    asc: true,
    noOpenTickets: true,
    subUnits: true,
    customerComplaint: true

  };

  ticket: Ticket = {
    id: null,
    ticketNumber: null,

    salesStaffId: null,
    selectFromExistingSalesStaff: false,
    enterSalesStaffInformationManually: false,

    salesStaffFirstName: null,
    salesStaffLastName: null,
    salesStaffPhoneNumber: null,

    apiSourceName: null,

    canBeReachedByPrimaryOrSecondaryPhone : true,

    warrantyCallOrigin: false,
    selectFromExistingCustomer: false,
    enterCustomerInformationManually: false,

    customerId: null,
    customerFirstName: null,
    customerLastName: null,

    customerIdentificationValue: null,
    customerPrimaryPhoneNumber: null,
    customerAdditionalPhoneNumber: null,
    customerEmail: null,
    customerCountryAdministrativeZoneId: null,
    customerGender: null,
    customerAddress: null,

    productId: null,
    productName: null,
    productAccountNumber: null,
    productSerialNumber: null,
    productVersion: null,
    productDateOfPurchase: null,
    productProblemDescription: null,

    createdBy: null,
    dateCreated: null,

    callPersonFirstName: null,
    callPersonLastName: null,
    callPersonPhoneNumber: null,

    testCaseResults: null,
    repairCentreId: null,
    callAgentNotes: null,
    markAsClosed: false,
    closingComment: null,

    comingFromCRM: false,

    contractProductId: null,

    ticketSubUnitWrappers: [],
    afterSaleCustomerTicketSourceId: null,
    afterSaleCustomerTicketTypeId: null,
    channel: 'Web',

    alternativePhoneNumbers: []
  };


  existingTickets: Ticket[] = [];

  checkOpenTickets() {
    this.formValid.noOpenTickets =
      this.existingTickets == null ||
      this.existingTickets.filter(ticket => !ticket.status.triggeredByWoCompletion).length === 0;
    return this.formValid.noOpenTickets;
  }


  subUnitsValid: { [key: number]: boolean; } = {};

  @Input() set setAccountNumber(accountNumber:any) {
    console.log("setting account number" + accountNumber);
    this.accountNumber = accountNumber;
  }

  @Input() set setCustomerId(customerId:any) {
    this.customerId = +customerId;
    this.selectCustomer(this.customerId);

    this.ticketService.generateTicketNumber().subscribe((data) => {
      this.loading = false;
      this.ticket.ticketNumber = data.ticketNumber;
      this.ref.detectChanges();
    });
  }

  selectCustomer(customerId: number) {

    console.log("changing customer id to " + customerId );

    this.customerSelected = true;

    this.customerService.getCountryAdminZoneForCustomer(customerId).subscribe(x => {
      this.ticket.customerCountryAdministrativeZoneId = x.id;
    })

    this.ticket.customerId = customerId;

    this.selectedProduct = null;
    this.selectedCustomerSubUnits = [];
    this.customerSubUnit = null;
    this.customerSubUnitsList = [];
    this.complaintsList = [];
    this.customerComplaint = null;


    this.ticketService.getCustomerProducts(customerId).subscribe(data => {
      this.customerProductList = data;
      if (this.accountNumber != null && this.accountNumber != undefined) {
        for (let i = 0; i< this.customerProductList.length; i++) {
          if (this.customerProductList[i].accountNumber == this.accountNumber) {
            this.selectedProduct = this.customerProductList[i];
            this.changeCustomerProduct();
          }
        }
      }
      this.ref.detectChanges();
    });
    this.ref.detectChanges();


  }



  selectAccountNumber() {
    this.ticketService.getCustomerProducts(this.customerId).subscribe(data => {
      this.customerProductList = data;
      for(let i  = 0; i< this.customerProductList.length; i++) {
        if (this.customerProductList[i].accountNumber = this.accountNumber) {
          this.selectedProduct = this.customerProductList[i];

          this.changeCustomerProduct();
        }
      }
      this.ref.detectChanges();
    });


  }
  changeCustomerProduct() {
    this.selectedCustomerSubUnits = [];
    this.customerSubUnitsList = [];
    this.loading = true;
    this.nonPayGoSelected = false;
    this.ref.detectChanges();

    this.ticketService.getTicketsForAccountNumber(this.selectedProduct.accountNumber).subscribe(tickets => {
      this.existingTickets = tickets;
      this.checkOpenTickets();
    });

    // HERE WE FIND PRODUCT IN INVENTORY
    this.inventoryProductService.validateInventoryProduct(this.selectedProduct.globalProductVersionId,
      null,
      this.selectedProduct.serialNumber,
      this.selectedProduct.accountNumber)
      .subscribe(inventoryProduct => {
        this.inventoryProduct = inventoryProduct;
        if (this.inventoryProduct) {
          this.inventoryProductService.getInventoryProductChildren(inventoryProduct.id).subscribe(data => this.inventoryProductChildren = data);
        }
      });

    if (this.selectedProduct.accountNumber != undefined && this.selectedProduct.accountNumber != null) {
      this.productService.getProductVersionSubUnitsByAccountNumber(this.selectedProduct.accountNumber).subscribe(data => {
        this.handleSubunitListPopulation(data);
      });
    } else {
      this.productService.getProductVersionSubUnits(this.selectedProduct.globalProductVersionId).subscribe(data => {
        this.handleSubunitListPopulation(data);
      });
    }


    this.productCustomerComplaintService.getProductComplaints(this.selectedProduct.globalProductId).subscribe(data => {
      this.complaintsList = data;
      this.ref.detectChanges();
    });

    this.testFlowService.getTestflowByProduct(this.selectedProduct.globalProductId).subscribe(data => {
      this.testFlow = data;
      this.ref.detectChanges();
    }, error => {
      this.ref.detectChanges();
    });

  }

  handleSubunitListPopulation(data) {
    console.log("calling handle subunit list population")
    const filteredResults = [];
    data.forEach(x => {
      filteredResults.push({
        id: x.versionId,
        globalProductId: x.product.id,
        name: x.product.name,
        description: x.product.description
      });
    });

    this.customerSubUnitsList = this.customerSubUnitsList.concat(filteredResults);

    this.ticketService.getCustomerAccessories(this.customerId, this.accountNumber).subscribe(result => {

      result.forEach(x => {

        this.customerSubUnitsList = this.customerSubUnitsList.filter(item =>
          item.id !== x.globalProductId || item.contractProductId != null);

        this.customerSubUnitsList.push({
          id: x.globalProductVersionId,
          globalProductId: x.globalProductId,
          name: x.productName,
          description: null,
          contractProductId: x.contractProductId
        });

        let finalCustomerSubUnitsList = [];

        this.customerSubUnitsList.forEach(subunit => {
          if (subunit.id != x.globalProductVersionId) {
            finalCustomerSubUnitsList.push(subunit);
          } else if (subunit.id == x.globalProductVersionId && subunit.contractProductId == x.contractProductId) {
            finalCustomerSubUnitsList.push(subunit);
          }
        });

        this.customerSubUnitsList = finalCustomerSubUnitsList;
      });
    });
  }


  showComplaints() {
    this.productCustomerComplaintService.getSubunitComplaints(this.customerSubUnit.globalProductId).subscribe(
      data => {
        this.complaints = data
      }
    )
  }


  addComplaint() {
    if (this.customerComplaint != null) {
      this.customerComplaints.push(this.customerComplaint);
      this.addToLists();
    }
  }


  getCorrectSerialNumberForSubUnit(selectedSubUnit) {
    let serialNumber = null;
    if (!selectedSubUnit.contractProductId) {
      const invProd = this.inventoryProductChildren.find(child => child.parentGlobalProductVersionId === selectedSubUnit.id);
      if (invProd) {
        serialNumber = this.isBaseUnitOrPCBA(selectedSubUnit) ?
          this.selectedProduct.serialNumber
          : invProd.serialNumber;
      } else if (this.isBaseUnitOrPCBA(selectedSubUnit)) {
        serialNumber = this.selectedProduct.serialNumber;
      }
    } else {
      this.loading = true;
      this.inventoryProductService.getInventoryProductByContractProduct(selectedSubUnit.contractProductId).subscribe(prod => {
        this.loading = false;
        if (prod) {
          serialNumber = prod.serialNumber;
        }
      });
    }
    return serialNumber
  }

  getCorrectWarrantyStatusForSubUnit(selectedSubunit, accessoryPurchaseDate) {
    let warrantyStatus = selectedSubunit.warrantyStatus
    if (!this.nonPayGoSelected) {
      this.ticketService.calculateWarranty(selectedSubunit.id).subscribe(warranty => {
        warrantyStatus = this.calculateWarrantyExpiration(warranty, accessoryPurchaseDate);
        this.ref.detectChanges();
      }), error => {
        this.ref.detectChanges();
      };
    }
    return warrantyStatus
  }


  addToLists() {
    const newCustomerComplaint = JSON.parse(JSON.stringify(this.customerComplaint))
    const newCustomerSubUnit = JSON.parse(JSON.stringify(this.customerSubUnit))

    const existingIndex = this.selectedSubUnitsList.findIndex(value => value.globalProductId === newCustomerSubUnit.globalProductId)

    if (existingIndex === -1) {
      this.ticketService.getAccessoryPurchaseDate(this.customerSubUnit.id, this.accountNumber).subscribe(accessoryProductPurchaseDateResult => {
        this.selectedSubUnitsList.push({
          id: newCustomerSubUnit.id,
          name: newCustomerSubUnit.name,
          serialNumber: this.getCorrectSerialNumberForSubUnit(newCustomerSubUnit),
          warrantyStatus: this.getCorrectWarrantyStatusForSubUnit(newCustomerSubUnit, accessoryProductPurchaseDateResult),
          globalProductId: newCustomerSubUnit.globalProductId,
          complaints:  [newCustomerComplaint]
        })
      });
    } else {
      this.selectedSubUnitsList[existingIndex].complaints.push(newCustomerComplaint)
    }
  }

  isBaseUnitOrPCBA(product) {
    return product.description === 'BASE_UNIT' || product.description === 'PAYGO_PHONE' || product.description === 'PCBA';
  }

  calculateWarrantyExpiration(warrantyPeriod, accessoryPurchaseDate) {
    let purchaseDate = null;

    if (this.customerSubUnit && this.selectedProduct?.accountNumber) {
      if (accessoryPurchaseDate != null) {
        purchaseDate = new Date(accessoryPurchaseDate);
      } else {
        purchaseDate = moment(this.selectedProduct.contractCreateDate != null ?
          this.selectedProduct.contractCreateDate : this.selectedProduct.createDate, 'YYYY-MM-DD').toDate();
      }
    }

    const months = Number.parseInt(warrantyPeriod, null);

    purchaseDate.setMonth(purchaseDate.getMonth() + months);
    const now = new Date(Date.now());
    if (now <= purchaseDate) {
      return 'IW';
    } else {
      return 'OOW - By date';
    }
  }

  removeComplaintFromSubunit(subunitIndex: number, complaintIndex: number) {
    this.selectedSubUnitsList[subunitIndex].complaints.splice(complaintIndex, 1)
    if(this.selectedSubUnitsList[subunitIndex].complaints.length == 0) {
      //invalid form
    }
  }

  removeSubunit(subunitIndex: number) {
    this.selectedSubUnitsList.splice(subunitIndex, 1)
    if(this.selectedSubUnitsList.length == 0) {
      //invalid form
    }
  }

  comingFromCRM = false;
  nonPayGoList = [];
  getNonPayGoProductVersions() {
    const searchObject = {
      isPayGo: '0'
    };
    this.productService.getProductsByPaygoTypeWithoutSubunits(searchObject).subscribe(data => {
      this.nonPayGoList = data;
      this.ref.detectChanges();
    });
  }

  changeCustomerNonPayGoProduct() {
    this.nonPayGoSelected = true;
    this.ref.detectChanges();

    this.productService.getProductVersionSubUnits(this.selectedProduct.id).subscribe(data => {
      const filteredResults = [];
      data.forEach(x => {
        filteredResults.push({
          id: x.versionId,
          globalProductId: x.product.id,
          name: x.product.name,
          description: x.product.description
        });
      });
      this.customerSubUnitsList = filteredResults;
      this.loading = false;
      this.ref.detectChanges();


      this.productCustomerComplaintService.getProductComplaints(this.selectedProduct.parentGlobalProductId).subscribe(data => {
        this.complaintsList = data;
        this.ref.detectChanges();
      });

      this.testFlowService.getTestflowByProduct(this.selectedProduct.parentGlobalProductId).subscribe(data => {
        this.testFlow = data;
        this.ref.detectChanges();
      }, error => {
        this.ref.detectChanges();
      });


    });
  }

  onClickRow(id) {
    const row = <HTMLElement>document.getElementById(id);
    row.nextElementSibling
      .classList
      .toggle('expandable-table__details-row--visible');
  }

  answerTestCase(result, id) {
    const passElement = document.getElementById('pass' + id);
    const failElement = document.getElementById('fail' + id);
    const blockedElement = document.getElementById('blocked' + id);


    const index = this.testCaseResult.findIndex(x => x.testCaseId == id);
    if (index == -1) {
      this.testCaseResult.push(
        {
          testCaseId: id,
          result: result
        }
      );

    } else {
      this.testCaseResult[index].result = result;
    }

    passElement.style.backgroundColor = '#97D870';
    failElement.style.backgroundColor = '#ED8A8F';
    blockedElement.style.backgroundColor = '#D0D0D0';

    const resultElement = document.getElementById(result + id.toString());
    if (result == 'pass') {
      resultElement.style.backgroundColor = 'green';
    } else if (result == 'fail') {
      resultElement.style.backgroundColor = 'red';
    } else if (result == 'blocked') {
      resultElement.style.backgroundColor = 'grey';
    }


    const adviceIndex = this.combinedAdvice.findIndex(x => x.testCaseId == id);
    const adviceElementIndex = this.testFlow.testCases.findIndex(x => x.id == id);
    if (adviceElementIndex != -1) {

      if (result == 'fail') {
        if (adviceIndex == -1) {
          this.combinedAdvice.push({
            testCaseId: id,
            advice: this.testFlow.testCases[adviceElementIndex].advice
          });
        }
      } else {
        if (adviceIndex != -1) {
          this.combinedAdvice.splice(adviceIndex, 1);
        }
      }
    }

    const that = this;
    that.combinedAdviceText = '';
    this.combinedAdvice.forEach(x => {
      that.combinedAdviceText = that.combinedAdviceText + '\n' + '-' + x.advice;
    });

  }

  checkExistingTickets(subUnit) {
    return this.existingTickets === null || this.existingTickets.find(ticket => !ticket.status.triggeredByWoCompletion &&
      ticket.ticketSubUnits.find(ticketSubUnit => ticketSubUnit.productVersion.id === subUnit.id) != null) != null;
  }

  onChangeCustomerSubUnits(event) {
    this.loading = true;
    this.formValid.subUnits = true;
    this.ref.detectChanges();

    this.subUnitsValid[this.customerSubUnit.id] = !this.checkExistingTickets(this.customerSubUnit);

    if (this.selectedCustomerSubUnits.filter(e => e.id === this.customerSubUnit.id).length === 0) {
      // HERE WE CHECK SUBUNITS FROM INVENTORY
      if (!this.customerSubUnit.contractProductId) {
        const invProd = this.inventoryProductChildren.find(child => child.parentGlobalProductVersionId === this.customerSubUnit.id);
        if (invProd) {
          this.customerSubUnit.serialNumber = this.isBaseUnitOrPCBA(this.customerSubUnit) ?
            this.selectedProduct.serialNumber
            : invProd.serialNumber;
        } else if (this.isBaseUnitOrPCBA(this.customerSubUnit)) {
          this.customerSubUnit.serialNumber = this.selectedProduct.serialNumber;
        }
      } else {
        this.loading = true;
        this.inventoryProductService.getInventoryProductByContractProduct(this.customerSubUnit.contractProductId).subscribe(prod => {
          this.loading = false;
          if (prod) {
            this.customerSubUnit.serialNumber = prod.serialNumber;
          }
        });
      }

      if (!this.nonPayGoSelected) {
        this.ticketService.getAccessoryPurchaseDate(this.customerSubUnit.id, this.selectedProduct.accountNumber).subscribe(accessoryPurchaseDateResult => {
          this.ticketService.calculateWarranty(this.customerSubUnit.id).subscribe(warranty => {
            this.customerSubUnit.warrantyStatus = this.calculateWarrantyExpiration(warranty, accessoryPurchaseDateResult);
            this.selectedCustomerSubUnits.push(this.customerSubUnit);
            this.loading = false;
            this.ref.detectChanges();
          }), error => {
            this.loading = false;
            this.ref.detectChanges();
          };
        });
      } else {
        this.selectedCustomerSubUnits.push(this.customerSubUnit);
        this.loading = false;
        this.ref.detectChanges();
      }

    } else {
      this.loading = false;
      this.ref.detectChanges();
    }
  }


  getOpenTickets() {
    return !this.checkOpenTickets() ? this.existingTickets.filter(ticket => !ticket.status.triggeredByWoCompletion) : [];
  }


  markAsClosed(closingComment) {
    if (this.formValid.noOpenTickets) {
      this.ticket.markAsClosed = true;
      this.ticket.closingComment = closingComment;
      this.saveTicket();
    }
  }

  hasInvalidSubUnits() {
    return this.selectedSubUnitsList.length === 0 || !this.formValid.subUnits;
  }

  hasInvalidComplaints() {
    // this.formValid.customerComplaint = this.complaints?.length > 0 && this.customerComplaints?.length > 0 ;
    const result = this.selectedSubUnitsList.reduce((acc, subunit) => {
      return acc && subunit.complaints.length > 0
    }, true)
    return !result;
  }

  saveTicket() {




    if (this.hasInvalidSubUnits()) {
      this.toastrService.error("Please Select Sub Units");
      return false;
    }

    if (this.hasInvalidComplaints()) {
      this.toastrService.error("Invalid Complaints");
      return false;
    }

    if (!this.checkOpenTickets()) {
      this.toastrService.error("There Already Are Open Tickets");
      return false;
    }

    this.ticket.testCaseResults = this.testCaseResult;


    if (this.selectedProduct != null) {
      this.ticket.productAccountNumber = this.selectedProduct.accountNumber;
      this.ticket.productVersion = this.selectedProduct.globalProductVersionId ? this.selectedProduct.globalProductVersionId : this.selectedProduct.id;
      this.ticket.productSerialNumber = this.selectedProduct.serialNumber;
      this.ticket.productDateOfPurchase = this.selectedProduct.contractCreateDate;
    }
    this.ticket.repairCentreId = this.selectedRepairCenter != null ? this.selectedRepairCenter.id : null;

    this.ticket.comingFromCRM = this.comingFromCRM;
    this.ticket.customerComplaintId = null;

    this.ticket.customerComplaintIds = this.selectedSubUnitsList.map(subunit => subunit.complaints.map(complaint => complaint.id)).flat()
    this.ticket.customerComplaints = null;


    // this.ticket.ticketSubUnitWrappers = this.selectedCustomerSubUnits;
    this.ticket.ticketSubUnitWrappers = this.selectedSubUnitsList.map(subunit => ({
      id: subunit.id,
      name: subunit.name,
      globalProductId: subunit.globalProductId,
      serialNumber: subunit.serialNumber,
      warrantyStatus: subunit.warrantyStatus,
    }))

    this.loading = true;
    this.loadingCreate = true;

    this.ticketService.addTicket(this.ticket).subscribe(data => {
      if (data && data.id) {
        this.ticket.id = data.id;
        console.log("emitting aftersale ticket created event");
        console.log(this.ticket);
        this.outputCreateWarrantyTicketEvent.emit(this.ticket);
        this.loadingCreate = false;
        this.ticketCreated = true;
      }
    });
  }

  showAssignRepairCenters = false;
  ascChangeCounter = 0;
  ascNearest = false;
  ascAll = false;
  repairCenterList = [];
  repairCenterListFull = [];
  selectedRepairCenter = null;
  repairCentreNames = [];
  selectedRepairCenterId=null;

  selectASC(e) {
    this.repairCenterListFull.forEach(item => {
      if (+item.id === +e.id) {
        this.selectedRepairCenter = item;
        this.selectedRepairCenterId = item.id;
      }
    });
  }
  changeASCMethod(item) {

    let searchObject = null;
    if (item.target.name === 'ascNearest') {
      searchObject = {
        zoneId: this.ticket.customerCountryAdministrativeZoneId
      };
    } else {
      searchObject = {};
    }

    if (this.ascChangeCounter == 0) {
      this.ascChangeCounter++;
      if (item.target.name == 'ascNearest') {
        searchObject = {
          zoneId: this.ticket.customerCountryAdministrativeZoneId
        };
      } else {
        searchObject = {};
      }
    }

    this.loading = true;
    this.ref.detectChanges();
    this.repairCenterService.getRepairCentresForLocation(searchObject).subscribe(data => {
      this.repairCenterList = data;
      this.loading = false;
      if (data.length > 0) {
        this.selectedRepairCenter = data[0];
        this.selectedRepairCenterId = data[0].id;
      }
      this.ref.detectChanges();
    });
    this.ascNearest = false;
    this.ascAll = false;


    this.ref.detectChanges();
  }

  getRepairCenters() {
    const searchObject = {};
    this.repairCenterService.getRepairCentresForLocation(searchObject).subscribe(data => {
      this.repairCenterList = data;
      this.repairCenterListFull = data;
      this.repairCentreNames = [...this.repairCenterList].map(item=>{
        return {id: item.id, text: `${item.name} in ${item?.location?.countryAdministrativeZoneName}`};
      });
      this.ref.detectChanges();
    });
  }



}
