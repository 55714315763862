import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';

import {DelinquencyDefinition} from '@appModels/core-accounting/delinquency-definition/delinquency-definition';
import {DelinquencyDefinitionActions, DelinquencyDefinitionActionTypes} from '@appStore/actions/core-accounting/delinquency-definition/delinquency-definition.actions';
import * as _ from 'lodash';

export interface State extends EntityState<DelinquencyDefinition> {
  loaded: boolean;
  loading: boolean;
  error: any;
  selectedDelinquencyDefinitionId: number;
  searchDelinquencyDefinitions: DelinquencyDefinition[];
  paging: any;
}

export const adapter: EntityAdapter<DelinquencyDefinition> = createEntityAdapter<DelinquencyDefinition>();

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
  selectedDelinquencyDefinitionId: null,
  error: null,
  searchTerm: '',
  searchDelinquencyDefinitions: null,
  paging: null
});

export function reducer(state = initialState, action: DelinquencyDefinitionActions): State {
  switch (action.type) {
    case DelinquencyDefinitionActionTypes.delinquencyDefinitionGetDelinquencyDefinitions:
    case DelinquencyDefinitionActionTypes.delinquencyDefinitionGetDelinquencyDefinitionByTenant:
    case DelinquencyDefinitionActionTypes.delinquencyDefinitionAddDelinquencyDefinition:
    case DelinquencyDefinitionActionTypes.delinquencyDefinitionAddAdditionalDelinquencyDefinitionInfo:
    case DelinquencyDefinitionActionTypes.delinquencyDefinitionDeleteDelinquencyDefinition:
    case DelinquencyDefinitionActionTypes.delinquencyDefinitionUpdateDelinquencyDefinition:
    case DelinquencyDefinitionActionTypes.delinquencyDefinitionSearchDelinquencyDefinitions:
    case DelinquencyDefinitionActionTypes.delinquencyDefinitionGetDelinquencyDefinitionById:

      return {
        ...state,
        loading: true
      };

    case DelinquencyDefinitionActionTypes.delinquencyDefinitionGetDelinquencyDefinitionsSuccess:
      return adapter.setAll(action.payload.content, {
        ...state,
        loading: false,
        loaded: true,
        paging: _.omit(action.payload, ['content'])
      });

    case DelinquencyDefinitionActionTypes.delinquencyDefinitionGetDelinquencyDefinitionByTenantSuccess:
      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });

    case DelinquencyDefinitionActionTypes.delinquencyDefinitionGetDelinquencyDefinitionByIdSuccess:
      return {
        ...state,
        selectedDelinquencyDefinitionId: action.payload.id,
        loading: false
      };

    case DelinquencyDefinitionActionTypes.delinquencyDefinitionAddDelinquencyDefinitionSuccess:
      return adapter.addOne(action.payload, {
        ...state,
        selectedDelinquencyDefinitionId: action.payload.id,
        loading: false,
        loaded: true
      });

    case DelinquencyDefinitionActionTypes.delinquencyDefinitionUpdateDelinquencyDefinitionSuccess: {
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state,
          loading: false,
          loaded: true
        }
      );
    }

    case DelinquencyDefinitionActionTypes.delinquencyDefinitionDeleteDelinquencyDefinitionSuccess: {
      return adapter.removeOne(action.payload.id, {
        ...state,
        loading: false,
        loaded: true
      });
    }

    case DelinquencyDefinitionActionTypes.delinquencyDefinitionSearchDelinquencyDefinitionsSuccess:
      return {
        ...state,
        searchDelinquencyDefinitions: action.payload,
        loading: false
      };

    case DelinquencyDefinitionActionTypes.delinquencyDefinitionSearchDelinquencyDefinitionsReset:
      return {
        ...state,
        searchDelinquencyDefinitions: null
      };

    case DelinquencyDefinitionActionTypes.delinquencyDefinitionError:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload
      };

    default:
      return state;
  }
}

export const delinquencyDefinitionEntitySelectors = adapter.getSelectors();
