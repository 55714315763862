import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { CustomFieldType } from '@appModels/core-setup/module-action/custom-field-type';

import { CoreMasterDataService } from '../core-masterdata.service';



@Injectable({ providedIn: 'root' })
export class CustomFieldTypeService extends CoreMasterDataService {


  constructor(
    private http: HttpClient,

  ) {
    super(http, 'customfieldtype');
  }

  /** GET moduleActions from the server */
  getCustomFieldTypes(): Observable<CustomFieldType[]> {
    return this.http.get<CustomFieldType[]>(this.base_url , { headers: this.headers }).pipe(
      tap(moduleActions => this.log(`fetched moduleActions`)),
      catchError(this.handleError('getCustomFieldTypes', []))
    );
  }

  /** GET moduleAction by id. Return `undefined` when id not found */
  getCustomFieldTypeNo404<Data>(id: number): Observable<CustomFieldType> {
    const url = `${this.base_url}/?id=${id}`;
    return this.http.get<CustomFieldType[]>(url).pipe(
      map(moduleActions => moduleActions[0]), // returns a {0|1} element array
      tap(h => {
        const outcome = h ? `fetched` : `did not find`;
        this.log(`${outcome} moduleAction id=${id}`);
      }),
      catchError(this.handleError<CustomFieldType>(`getCustomFieldType id=${id}`))
    );
  }

  /** GET moduleAction by id. Will 404 if id not found */
  getCustomFieldType(id: number): Observable<CustomFieldType> {
    const url = `${this.base_url}/${id}`;
    return this.http.get<CustomFieldType>(url,{ headers: this.headers }).pipe(
      tap(_ => this.log(`fetched moduleAction id=${id}`)),
      catchError(this.handleError<CustomFieldType>(`getCustomFieldType id=${id}`))
    );
  }

  /* GET moduleActions whose name contains search term */
  searchCustomFieldTypes(term: string): Observable<CustomFieldType[]> {
    if (!term.trim()) {
      // if not search term, return empty moduleAction array.
      return of([]);
    }
    return this.http.get<CustomFieldType[]>(`api/moduleActions/?name=${term}`).pipe(
      tap(_ => this.log(`found moduleActions matching "${term}"`)),
      catchError(this.handleError<CustomFieldType[]>('searchCustomFieldTypes', []))
    );
  }

  //////// Save methods //////////

  /** POST: add a new moduleAction to the server */
  addCustomFieldType(moduleAction: CustomFieldType): Observable<CustomFieldType> {
    return this.http.post<CustomFieldType>(this.base_url , moduleAction, { headers: this.headers }).pipe(
      tap((moduleAction: CustomFieldType) => this.log(`added moduleAction w/ id=${moduleAction.id}`)),
      catchError(this.handleError<CustomFieldType>('addCustomFieldType'))
    );
  }

  /** DELETE: delete the moduleAction from the server */
  deleteCustomFieldType(moduleAction: CustomFieldType | number): Observable<CustomFieldType> {
    const id = typeof moduleAction === 'number' ? moduleAction : moduleAction.id;
    const url = `${this.base_url}/${id}`;

    return this.http.delete<CustomFieldType>(url, { headers: this.headers }).pipe(
      tap(_ => this.log(`deleted moduleAction id=${id}`)),
      catchError(this.handleError<CustomFieldType>('deleteCustomFieldType'))
    );
  }

  /** PUT: update the moduleAction on the server */
  updateCustomFieldType(moduleAction: CustomFieldType): Observable<any> {
    return this.http.put(this.base_url, moduleAction, { headers: this.headers }).pipe(
      tap(_ => this.log(`updated moduleAction id=${moduleAction.id}`)),
      catchError(this.handleError<any>('updateCustomFieldType'))
    );
  }

}
