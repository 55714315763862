import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';

import {CoreInventoryService} from '../core-inventory.service';
import {InventoryProduct} from "@appModels/core-inventory/inventory-product/inventory-product";
import {ConsignmentProductAcceptReceiveStatus} from "@appModels/core-inventory/consignment-product-accept-receive-status/consignment-product-accept-receive-status";
import {ConsignmentProduct} from "@appModels/core-inventory/consignment-product/consignment-product";


@Injectable({providedIn: 'root'})
export class ConsignmentProductService extends CoreInventoryService {


  constructor(
    private http: HttpClient,
  ) {
    super(http, '');
  }

  /** GET consignmentProduct by id. Will 404 if id not found */
  getConsignmentProduct(id: number): Observable<any> {
    const url = `${this.base_url}${id}`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched consignmentProduct id=${id}`)),
      catchError(this.handleError(`getConsignmentProduct id=${id}`))
    );
  }

  canUserAcceptRejectConsignment(id: number): Observable<any> {
    const url = `${this.base_url}consignment/canacceptreject/${id}`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`canUserAcceptRejectConsignment id=${id}`)),
      catchError(this.handleError(`canUserAcceptRejectConsignment id=${id}`))
    );
  }

  /** GET consignmentProduct by consignment id. Will 404 if id not found */
  getConsignmentProductByConsignment(id: number, query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        if (query[val] !== null && query[val] !== undefined && query[val] !== '') {
          params = params.append(val, query[val]);
        }
      });
    }

    const url = `${this.base_url}` + `consignment/${id}/consignmentproductsinventoryproducts`;
    return this.http.get(url, {headers: this.headers, params: params}).pipe(
      tap(_ => this.log(`fetched consignmentProduct id=${id}`)),
      catchError(this.handleError(`getConsignmentProduct id=${id}`))
    );
  }

  /** GET consignmentProduct count by consignment id*/
  getConsignmentProductByConsignmentCount(id: number): Observable<any> {
    const url = `${this.base_url}` + `consignment/${id}/consignmentproductsinventoryproductcount`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched consignmentProduct count for consignment id=${id}`)),
      catchError(this.handleError(`getConsignmentProductByConsignmentCount id=${id}`))
    );
  }


  postUpdateConsignmentProductMultiple(consignmentProductId: number, acceptReceiveStatus: number, body, query?) {
    const url = `${this.base_url}` + `consignmentproduct/${consignmentProductId}/acceptReceiveStatus/${acceptReceiveStatus}`;
    let params = new HttpParams();
    if (query !== null) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        params = params.append(val, query[val]);
      });
    }
    return this.http.post(url, body, {params, headers: this.headers}).pipe(
      tap(_ => this.log(`updated Consignment Product id=${consignmentProductId}`)),
      catchError(this.handleError<any>('update Consignment Product'))
    );
  }


  /** GET consignmentProduct not booked by stocking point id. Will 404 if id not found */
  getConsignmentProductByNotBookedInStockingPoint(id, query?): Observable<any> {
    const tenantId = localStorage.getItem('tenant');
    let url = `${this.base_url}` + `consignment/${id}/productsInStockingPointNotBooked/tenant/${tenantId}`;
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        params = params.append(val, query[val]);
      });
    }
    return this.http.get<any>(url, {params, headers: this.headers}).pipe(
      tap(InventoryProduct => this.log(`fetched not booked consignmentProducts`)),
      catchError(this.handleError('consignmentProducts', []))
    );
  }

  getConsignmentProductByNotBookedInStockingPointByProductId(id, globalProductVersionId, query?): Observable<any> {
    const tenantId = localStorage.getItem('tenant');
    let url = `${this.base_url}` + `consignment/${id}/productsInStockingPointNotBooked/tenant/${tenantId}?globalProductVersionId=${globalProductVersionId}`;
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        params = params.append(val, query[val]);
      });
    }
    return this.http.get<any>(url, {params, headers: this.headers}).pipe(
      tap(InventoryProduct => this.log(`fetched not booked consignmentProducts`)),
      catchError(this.handleError('consignmentProducts', []))
    );
  }

  /** GET consignmentProduct not in stocking points. Will 404 if id not found */
  getConsignmentProductNotInStockingPoints(): Observable<InventoryProduct[]> {
    const tenantId = localStorage.getItem('tenant');
    let url = `${this.base_url}inventoryproductsnotinstockingpointsandnotsold/tenant/${tenantId}`;
    return this.http.get<InventoryProduct[]>(url, {headers: this.headers}).pipe(
      tap(InventoryProduct => this.log(`fetched not booked consignmentProducts`)),
      catchError(this.handleError('consignmentProducts', []))
    );
  }

  /** POST: add a new consignment product to the server */
  postUpdateConsignmentProduct(consignmentProductId: number, acceptReceiveStatus: ConsignmentProductAcceptReceiveStatus): Observable<ConsignmentProduct> {
    let url = `${this.base_url}` + `consignmentproduct/${consignmentProductId}/acceptReceiveStatus/${acceptReceiveStatus.id}`;
    return this.http.post(url, "", {headers: this.headers}).pipe(
      tap(_ => this.log(`updated Consignment Product id=${consignmentProductId}`)),
      catchError(this.handleError<any>('update Consignment Product'))
    );
  }

  /** PUT: consignment product on the server */
  updateConsignmentProduct(consignmentProductId: number, acceptReceiveStatus: ConsignmentProductAcceptReceiveStatus): Observable<ConsignmentProduct> {
    let url = `${this.base_url}` + `consignmentproduct/${consignmentProductId}/acceptReceiveStatus/${acceptReceiveStatus.id}`;
    return this.http.put(url, "", {headers: this.headers}).pipe(
      tap(_ => this.log(`updated Consignment Product id=${consignmentProductId}`)),
      catchError(this.handleError<any>('update Consignment Product'))
    );
  }


}
