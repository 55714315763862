import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { TenantType } from '@appModels/core-setup/tenant-type/tenant-type';

import { CoreMasterDataService } from '../core-masterdata.service';



@Injectable({ providedIn: 'root' })
export class TenantTypeService extends CoreMasterDataService {


  constructor(
    private http: HttpClient,

  ) {
    super(http, 'tenanttype');
  }

  /** GET tenantTypes from the server */
  getTenantTypes(): Observable<TenantType[]> {
    return this.http.get<TenantType[]>(this.base_url , { headers: this.headers }).pipe(
      tap(tenantTypes => this.log(`fetched tenantTypes`)),
      catchError(this.handleError('getTenantTypes', []))
    );
  }

  /** GET tenantType by id. Return `undefined` when id not found */
  getTenantTypeNo404<Data>(id: number): Observable<TenantType> {
    const url = `${this.base_url}/?id=${id}`;
    return this.http.get<TenantType[]>(url).pipe(
      map(tenantTypes => tenantTypes[0]), // returns a {0|1} element array
      tap(h => {
        const outcome = h ? `fetched` : `did not find`;
        this.log(`${outcome} tenantType id=${id}`);
      }),
      catchError(this.handleError<TenantType>(`getTenantType id=${id}`))
    );
  }

  /** GET tenantType by id. Will 404 if id not found */
  getTenantType(id: number): Observable<TenantType> {
    const url = `${this.base_url}/${id}`;
    return this.http.get<TenantType>(url,{ headers: this.headers }).pipe(
      tap(_ => this.log(`fetched tenantType id=${id}`)),
      catchError(this.handleError<TenantType>(`getTenantType id=${id}`))
    );
  }

  /* GET tenantTypes whose name contains search term */
  searchTenantTypes(term: string): Observable<TenantType[]> {
    if (!term.trim()) {
      // if not search term, return empty tenantType array.
      return of([]);
    }
    return this.http.get<TenantType[]>(`api/tenantTypes/?name=${term}`).pipe(
      tap(_ => this.log(`found tenantTypes matching "${term}"`)),
      catchError(this.handleError<TenantType[]>('searchTenantTypes', []))
    );
  }

  //////// Save methods //////////

  /** POST: add a new tenantType to the server */
  addTenantType(tenantType: TenantType): Observable<TenantType> {
    return this.http.post<TenantType>(this.base_url , tenantType, { headers: this.headers }).pipe(
      tap((tenantType: TenantType) => this.log(`added tenantType w/ id=${tenantType.id}`)),
      catchError(this.handleError<TenantType>('addTenantType'))
    );
  }

  /** DELETE: delete the tenantType from the server */
  deleteTenantType(tenantType: TenantType | number): Observable<TenantType> {
    const id = typeof tenantType === 'number' ? tenantType : tenantType.id;
    const url = `${this.base_url}/${id}`;

    return this.http.delete<TenantType>(url, { headers: this.headers }).pipe(
      tap(_ => this.log(`deleted tenantType id=${id}`)),
      catchError(this.handleError<TenantType>('deleteTenantType'))
    );
  }

  /** PUT: update the tenantType on the server */
  updateTenantType(tenantType: TenantType): Observable<any> {
    return this.http.put(`${this.base_url}/${tenantType.id}`, tenantType, { headers: this.headers }).pipe(
      tap(_ => this.log(`updated tenantType id=${tenantType.id}`)),
      catchError(this.handleError<any>('updateTenantType'))
    );
  }

  /** Patch: update the tenantType on the server */
  editTenantType(data): Observable<any> {
    return this.http.patch(`${this.base_url}/${data.id}/${data.param}/${data.value}`, {}, { headers: this.headers }).pipe(
      tap(_ => this.log(`updated tenantType id=${data.id}`)),
      catchError(this.handleError<any>('updateTenantType'))
    );
  }

}
