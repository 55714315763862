import {Action} from '@ngrx/store';
import {ProductPartPrice} from '../../../../models/core-setup/part-prices/product-part-price';

export enum ProductPartPriceActionTypes {
  productPriceGetAll = '[Product Price] Get All',
  productPriceGetAllSuccess = '[Product Price] Get All Success',
  productPriceSave = '[Product Price] Save',
  productPriceSaveSuccess = '[Product Price] Save Success',
  productPriceError = '[Product Price] Error',
  productPriceUpdate = '[Product Price] Update',
  productPriceUpdateSuccess = '[Product Price] Update Success'
}

export class GetProductPrices implements Action {
  readonly type = ProductPartPriceActionTypes.productPriceGetAll;

  constructor(public payload) {
  }
}

export class GetProductPricesSuccess implements Action {
  readonly type = ProductPartPriceActionTypes.productPriceGetAllSuccess;

  constructor(public payload) {
  }
}

export class SaveProductPrice implements Action {
  readonly type = ProductPartPriceActionTypes.productPriceSave;

  constructor(public payload: ProductPartPrice[]) {
  }
}

export class SaveProductPriceSuccess implements Action {
  readonly type = ProductPartPriceActionTypes.productPriceSaveSuccess;

  constructor(public payload: ProductPartPrice[]) {
  }
}

export class UpdateProductPrice implements Action {
  readonly type = ProductPartPriceActionTypes.productPriceUpdate;

  constructor(public payload: ProductPartPrice[]) {
  }
}

export class UpdateProductPriceSuccess implements Action {
  readonly type = ProductPartPriceActionTypes.productPriceUpdateSuccess;

  constructor(public payload: ProductPartPrice[]) {
  }
}

export class ProductPriceError implements Action {
  readonly type = ProductPartPriceActionTypes.productPriceError;

  constructor(public payload: any) {
  }
}

export type ProductPartPriceActions =
  | GetProductPrices
  | GetProductPricesSuccess
  | SaveProductPrice
  | SaveProductPriceSuccess
  | ProductPriceError
  | UpdateProductPrice
  | UpdateProductPriceSuccess;
