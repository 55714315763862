import {Action} from '@ngrx/store';

import {AccessoryRate} from '@appModels/core-accounting/accessory-rate/accessory-rate';

export enum AccessoryRateActionTypes {
  accessoryRateGetAccessoryRates = '[AccessoryRate] get',
  accessoryRateGetAccessoryRatesSuccess = '[AccessoryRate] get accessoryRates success',
  accessoryRateGetAccessoryRateByTenant = '[AccessoryRate] get accessoryRates by tenant id',
  accessoryRateGetAccessoryRateByTenantSuccess = '[AccessoryRate] get accessoryRates by tenant id success',
  accessoryRateGetAccessoryRateByProductId = '[AccessoryRate] get accessoryRate by product id',
  accessoryRateGetAccessoryRateByProductIdSuccess = '[AccessoryRate] get accessoryRate by product id success',
  accessoryRateAddAccessoryRate = '[AccessoryRate] add AccessoryRate',
  accessoryRateAddAccessoryRateSuccess = '[AccessoryRate] add accessoryRate success',
  accessoryRateAddAdditionalAccessoryRateInfo = '[AccessoryRate] add additional AccessoryRate Info',
  accessoryRateAddAdditionalAccessoryRateInfoSuccess = '[AccessoryRate] add additional accessoryRate Info success',
  accessoryRateDeleteAccessoryRate = '[AccessoryRate] delete accessoryRate',
  accessoryRateDeleteAccessoryRateSuccess = '[AccessoryRate] delete accessoryRate success',
  accessoryRateGetAccessoryRateById = '[AccessoryRate] get accessoryRate by id',
  accessoryRateGetAccessoryRateByIdSuccess = '[AccessoryRate] get accessoryRate by id success',
  accessoryRateUpdateAccessoryRate = '[AccessoryRate] update accessoryRate',
  accessoryRateUpdateAccessoryRateSuccess = '[AccessoryRate] update accessoryRate success',
  accessoryRateSearchAccessoryRates = '[AccessoryRate] search accessoryRates',
  accessoryRateSearchAccessoryRatesSuccess = '[AccessoryRate] search accessoryRates success',
  accessoryRateSearchAccessoryRatesReset = '[AccessoryRate] search accessoryRates reset',
  accessoryRateError = '[AccessoryRate] error',
  accessoryRateGetAccessoryRatesByTenantId = '[AccessoryRate] get accessoryRates by tenant id',
  accessoryRateGetAccessoryRatesByTenantIdSuccess = '[AccessoryRate] get accessoryRates by tenant id success',

}

export class GetAccessoryRates implements Action {
  readonly type = AccessoryRateActionTypes.accessoryRateGetAccessoryRates;
  constructor(public payload?) {}
}

export class GetAccessoryRatesSuccess implements Action {
  readonly type = AccessoryRateActionTypes.accessoryRateGetAccessoryRatesSuccess;
  constructor(public payload) {}
}

export class GetAccessoryRatesByTenantId implements Action {
  readonly type = AccessoryRateActionTypes.accessoryRateGetAccessoryRatesByTenantId;
}

export class GetAccessoryRatesByTenantIdSuccess implements Action {
  readonly type = AccessoryRateActionTypes.accessoryRateGetAccessoryRatesByTenantIdSuccess;

  constructor(public payload: AccessoryRate[]) {
  }
}

export class AddAccessoryRate implements Action {
  readonly type = AccessoryRateActionTypes.accessoryRateAddAccessoryRate;
  constructor(public payload: AccessoryRate) {}
}

export class GetAccessoryRatesByTenant implements Action {
  readonly type = AccessoryRateActionTypes.accessoryRateGetAccessoryRateByTenant;

  constructor(public payload) {
  }
}

export class GetAccessoryRatesByTenantSuccess implements Action {
  readonly type = AccessoryRateActionTypes.accessoryRateGetAccessoryRateByTenantSuccess;

  constructor(public payload: AccessoryRate[]) {
  }
}

export class AddAccessoryRateSuccess implements Action {
  readonly type = AccessoryRateActionTypes.accessoryRateAddAccessoryRateSuccess;
  constructor(public payload: AccessoryRate) {}
}

export class AddAdditionalAccessoryRateInfo implements Action {
  readonly type = AccessoryRateActionTypes.accessoryRateAddAdditionalAccessoryRateInfo;
  constructor(public payload) {}
}

export class AddAdditionalAccessoryRateInfoSuccess implements Action {
  readonly type = AccessoryRateActionTypes.accessoryRateAddAdditionalAccessoryRateInfoSuccess;
  constructor(public payload) {}
}

export class GetAccessoryRateById implements Action {
  readonly type = AccessoryRateActionTypes.accessoryRateGetAccessoryRateById;
  constructor(public payload: number) {}
}

export class GetAccessoryRateByIdSuccess implements Action {
  readonly type = AccessoryRateActionTypes.accessoryRateGetAccessoryRateByIdSuccess;
  constructor(public payload: AccessoryRate) {}
}

export class GetAccessoryRateByProductId implements Action {
  readonly type = AccessoryRateActionTypes.accessoryRateGetAccessoryRateByProductId;
  constructor(public payload: number) {}
}

export class GetAccessoryRateByProductIdSuccess implements Action {
  readonly type = AccessoryRateActionTypes.accessoryRateGetAccessoryRateByProductIdSuccess;
  constructor(public payload: AccessoryRate) {}
}

export class UpdateAccessoryRate implements Action {
  readonly type = AccessoryRateActionTypes.accessoryRateUpdateAccessoryRate;
  constructor(public payload: AccessoryRate) {}
}

export class UpdateAccessoryRateSuccess implements Action {
  readonly type = AccessoryRateActionTypes.accessoryRateUpdateAccessoryRateSuccess;
  constructor(public payload: AccessoryRate) {}
}

export class DeleteAccessoryRate implements Action {
  readonly type = AccessoryRateActionTypes.accessoryRateDeleteAccessoryRate;
  constructor(public payload: AccessoryRate) {}
}

export class DeleteAccessoryRateSuccess implements Action {
  readonly type = AccessoryRateActionTypes.accessoryRateDeleteAccessoryRateSuccess;
  constructor(public payload: AccessoryRate) {}
}

export class SearchAccessoryRates implements Action {
  readonly type = AccessoryRateActionTypes.accessoryRateSearchAccessoryRates;
  constructor(public payload: string) {}
}

export class SearchAccessoryRatesSuccess implements Action {
  readonly type = AccessoryRateActionTypes.accessoryRateSearchAccessoryRatesSuccess;
  constructor(public payload: AccessoryRate[]) {}
}

export class SearchAccessoryRatesReset implements Action {
  readonly type = AccessoryRateActionTypes.accessoryRateSearchAccessoryRatesReset;
}

export class AccessoryRateError implements Action {
  readonly type = AccessoryRateActionTypes.accessoryRateError;
  constructor(public payload: any) {}
}

export type AccessoryRateActions =
  | GetAccessoryRates
  | GetAccessoryRatesSuccess
  | AddAccessoryRate
  | AddAccessoryRateSuccess
  | GetAccessoryRatesByTenant
  | GetAccessoryRatesByTenantSuccess
  | AddAdditionalAccessoryRateInfo
  | AddAdditionalAccessoryRateInfoSuccess
  | GetAccessoryRateById
  | GetAccessoryRateByIdSuccess
  | GetAccessoryRateByProductId
  | GetAccessoryRateByProductIdSuccess
  | UpdateAccessoryRate
  | UpdateAccessoryRateSuccess
  | DeleteAccessoryRate
  | DeleteAccessoryRateSuccess
  | SearchAccessoryRates
  | SearchAccessoryRatesSuccess
  | SearchAccessoryRatesReset
  | AccessoryRateError
  | GetAccessoryRatesByTenantId
  | GetAccessoryRatesByTenantIdSuccess;
