<h1>Data Privacy Protection Policy</h1>

<section class="row">
    <div class="template">
        <div class="m-b-10 border">
            <h2>Policy Statement</h2>
            <p>
                d.light Inc. and its subsidiaries (“<b>d.light</b>” or the “<b>Company</b>”) are committed to protecting
                the privacy and
                confidentiality of Personal Information about its Employees, customers, business partners and other
                identifiable
                individuals. Each Employee bears a personal responsibility for complying with this Policy in the
                fulfillment of
                their responsibilities at d.light. The purpose of this Policy is to establish the ethics and conduct
                expected of all
                individuals who carry out work for or on behalf of d.light.
            </p>
        </div>

        <div class="m-t-20 m-b-10 border">
            <h2>
                Scope and Applicability
            </h2>
            <p>
                d.light respects the privacy of individuals and is committed to managing Personal Information in a
                professional,
                lawful and ethical manner. This Policy sets the minimum standard and shall guide all Employees,
                customers,
                business partners and other identifiable individuals even if local laws are less restrictive.
            </p>
            <p>
                Data privacy laws vary greatly from country to country and, in some countries, are non-existent.
                Consequently,
                d.light has adopted legislation such as the EU General Data Protection Regulations (“GDPR”) and the UK
                Data
                Protection Act 2018. Personal Information processed by d.light in any location is subject to the local
                requirements applicable in the specific location, the GDPR and the UK Data Protection Act 2018, where
                applicable. These requirements apply even if local data privacy laws are less stringent or where there
                are no local
                data privacy laws. Where local law has stricter requirements then these must be met in addition to those
                in this
                Policy.
            </p>
            <p>
                The Policy basically comprises the internationally accepted data privacy principles without replacing
                the
                existing local laws; the Policy is in fact supplemental to local data privacy laws. For the avoidance of
                doubt, the
                relevant local law will take precedence in the event that it conflicts with this Policy or where local
                law has stricter
                requirements than this Policy.
            </p>
            <p>
                This Policy applies to all Employees, Data Subjects and all third parties responsible for the processing
                of Personal
                Information on behalf of d.light. This policy therefore seeks to ensure that we:
            </p>
            <div class="m-l-20">
                <p>
                    1. are clear about how Personal Information must be processed and d.light’s expectations for all
                    those who
                    process Personal Information on its behalf;
                </p>
                <p>
                    2. comply with all applicable and relevant data protection legislation and with good practice;
                </p>
                <p>
                    3. protect d.light’s reputation by ensuring the Personal Information entrusted to us is processed in
                    accordance with all applicable and relevant data protection legislation; and
                </p>
                <p>
                    4. protect d.light from risks of Personal Information breaches and other breaches of data protection
                    law.
                </p>
            </div>
            <p>
                The Policy will familiarize you with the basics of data privacy and protection, but it is not a complete
                or definitive
                statement. Any specific questions related to compliance with data privacy and protection not addressed
                in this
                Policy should be forwarded to the Group CFO, copying in d.light’s Group Legal and Compliance Manager.
            </p>
        </div>

        <div class="m-b-10 border">
            <h2>Acronyms and Definitions </h2>
            <ul class="m-l-40">
                <li>
                    <p>
                        <b>Audit and Risk Committee:</b> means the committee constituted by the audit and risk committee
                        of the
                        company in accordance with applicable law, and where the claim is against any of the members of
                        the
                        audit and risk committee, such member will be excluded from that sitting.
                    </p>
                </li>
                <li>
                    <p>
                        <b>Chairman:</b> means the chairman of the audit and risk committee. The chairman of this
                        committee shall
                        be the Group CFO of the Company or such other person as may be appointed in writing by the audit
                        and risk committee.
                    </p>
                </li>
                <li>
                    <p>
                        <b>Data Subject:</b> means a living, identified or identifiable individual about whom d.light
                        hold’s Personal
                        Information, including but not limited to Employees and any third parties.
                    </p>
                </li>
                <li>
                    <p>
                        <b>Employee(s):</b> means employees, directors and officers of d.light.
                    </p>
                </li>
                <li>
                    <p>
                        <b>Executive Committee:</b> means the CEO, President, CFO, Managing Director – Africa and
                        Managing
                        Director – India.
                    </p>
                </li>
                <li>
                    <p>
                        <b>Policy:</b> refers to this Data Privacy Protection Policy.
                    </p>
                </li>
                <li>
                    <p>
                        <b>Personal Information:</b> means any data related to an individual who can be identified from
                        that data
                        and other information. Personal Information includes, but is not limited to:
                    </p>
                    <ol type="a" class="m-l-20">
                        <li>
                            <p>
                                biographical data (biodata) such as name, sex, marital status, date and place of birth,
                                religion,
                                national, ethnic or social origin;
                            </p>
                        </li>
                        <li>
                            <p>information related to education, medical, criminal or employment history, financial
                                information, tax records; </p>
                        </li>
                        <li>
                            <p>
                                identifying number;
                            </p>
                        </li>
                        <li>
                            <p>
                                identifying number;
                            </p>
                        </li>
                        <li>
                            <p>
                                fingerprints, blood type of the person;
                            </p>
                        </li>
                        <li>
                            <p>
                                contact details – such as telephone number and address;
                            </p>
                        </li>
                        <li>
                            <p>
                                biometric information such as a photograph, fingerprint, facial or iris image.
                            </p>
                        </li>
                    </ol>
                </li>
                <li>
                    <p>
                        <b>Processing:</b> in relation to Personal Information means an operation or set of operations
                        which is
                        performed on Personal Information, including but not limited to:
                    </p>
                    <ol type="a" class="m-l-20">
                        <li>
                            <p>
                                collection, recording, organization, structuring or storage;
                            </p>
                        </li>
                        <li>
                            <p>adaptation or alteration; </p>
                        </li>
                        <li>
                            <p>
                                retrieval, consultation or use;
                            </p>
                        </li>
                        <li>
                            <p>
                                disclosure by transmission, dissemination or otherwise making available;
                            </p>
                        </li>
                        <li>
                            <p>
                                alignment or combination;
                            </p>
                        </li>
                        <li>
                            <p>
                                restriction, erasure or destruction
                            </p>
                        </li>

                    </ol>
                </li>
            </ul>
        </div>

        <h2>Data Privacy Protection Policy</h2>

        <div class="m-b-10 border">
            <h2>0001.1 Policy Details</h2>
            <p>
                d.light respects the privacy of its Employees and third parties (including but not limited to)
                customers, business
                partners, suppliers, etc and recognizes the need for appropriate protection and management of Personal
                Information. All instances of Processing Personal Information must be identified, whether in IT systems,
                applications, mobile applications, websites, campaigns or otherwise. When Processing Personal
                Information,
                d.light is obliged to fulfil the Data Subject’s reasonable expectations of privacy by complying with
                relevant and
                applicable data protection legislation.
            </p>
        </div>

        <div class="m-b-10 border">
            <h2>0001.2 Principles of processing Personal Information </h2>
            <p>
                When Processing Personal Information, the following principles should provide guidance on what ought to
                be
                followed (these are set out comprehensively in the GDPR). d.light is responsible for, and must be able
                to
                demonstrate compliance with, the data protection principles listed below. The principles require
                Personal
                Information to be:
            </p>
            <ol class="m-l-40">
                <li>
                    <p>
                        processed lawfully, fairly and in a transparent manner (<b>Lawfulness, fairness and
                            transparency</b>);
                    </p>
                </li>
                <li>
                    <p>
                        collected only for specified, explicit and legitimate purposes and not further processed in a
                        manner
                        incompatible with those purposes (<b>Purpose limitation</b>);
                    </p>
                </li>
                <li>
                    <p>
                        adequate, relevant and limited to what is necessary in relation to the purposes for which it is
                        processed
                        (<b>Data minimization</b>);
                    </p>
                </li>
                <li>
                    <p>
                        accurate and where necessary kept up to date (<b>Accuracy</b>);
                    </p>
                </li>
                <li>
                    <p>
                        not kept in a form which permits identification of individuals for longer than is necessary for
                        the
                        purposes for which the Personal Information is processed (<b>Storage limitation</b>); and
                    </p>
                </li>
                <li>
                    <p>processed in a manner that ensures the security of the Personal Information, using appropriate
                        technical
                        and organizational measures to protect against unauthorized or unlawful processing and against
                        accidental loss, destruction or damage (<b>Security, integrity and confidentiality</b>). </p>
                </li>
            </ol>
        </div>

        <div class="m-b-10 border">
            <h2>0001.3 Data Protection and Privacy Rights of Employees and Third
                Parties </h2>
            <p>
                Data Subjects have rights in relation to the way d.light handles their Personal Information. These
                include the
                following rights:
            </p>
            <ol class="m-l-40">
                <li>
                    <p>where the legal basis of Processing and Personal Information is consent, to withdraw that consent
                        at
                        any time; </p>
                </li>
                <li>
                    <p>
                        to ask for access to the Personal Information that d.light holds;
                    </p>
                </li>
                <li>
                    <p>
                        to prevent d.light’s use of the Personal Information for direct marketing purposes, unless
                        consent has
                        been specifically obtained;
                    </p>
                </li>
                <li>
                    <p>
                        to request d.light to erase Personal Information without delay:
                    </p>
                    <ol type="a" class="m-l-40">
                        <li>
                            <p> if it is no longer necessary in relation to the purposes for which it was collected or
                                otherwise
                                processed;</p>
                        </li>
                        <li>
                            <p>if the only legal basis of Processing is consent and that consent has been withdrawn and
                                there
                                is no other legal basis on which d.light can process that Personal Information;</p>
                        </li>
                        <li>
                            <p>if the Employee and/or third party objects to d.light Processing the Personal Information
                                where
                                the legal basis is the pursuit of a legitimate interest or the public interest and
                                d.light can show
                                no overriding legitimate grounds or interest;</p>
                        </li>
                        <li>
                            <p>if the Employee and/or third party has objected to d.light Processing the Personal
                                Information
                                for direct marketing purposes, without specific consent; and</p>
                        </li>
                        <li>
                            if the Processing is unlawful.
                        </li>
                    </ol>
                </li>
                <li>
                    <p>to ask d.light to rectify inaccurate Personal Information or to complete incomplete Personal
                        Information;</p>
                </li>
                <li>
                    <p>to restrict Processing in specific circumstances, such as where there is a complaint about the
                        accuracy
                        of the Personal Information;</p>
                </li>
                <li>
                    <p>
                        to ask d.light for a copy of the safeguards under which Personal Information is transferred
                        outside of the
                        country;
                    </p>
                </li>
                <li>
                    <p>
                        the right not to be subject to decisions based solely on automated Processing, including
                        profiling,
                        except where necessary for entering into, or performing, a contract, with d.light; it is based
                        on the Data
                        Subjects’ explicit consent and is subject to safeguards; or is authorized by law and is also
                        subject to
                        safeguards;
                    </p>
                </li>
                <li>
                    <p>
                        to prevent Processing that is likely to cause damage or distress to the Data Subject or anyone
                        else;
                    </p>
                </li>
                <li>
                    <p>
                        to be notified of a Personal Information breach which is likely to result in high risk to the
                        rights and
                        freedoms of the Data Subject; and
                    </p>
                </li>
                <li>
                    <p>
                        in limited circumstances, receive or ask for their Personal Information to be transferred to a
                        third party
                        – for example, another country, in a structured, commonly used and machine-readable format.
                    </p>
                </li>
            </ol>
            <p>
                At all times, d.light must verify the identity of an individual requesting for Personal Information
                under any of the
                rights listed above. Requests must be complied with, usually within one (1) month of receipt. Any
                requests
                received, must immediately be forwarded to the head of marketing in each of the markets. Any enquiries
                that
                are sensitive in nature, should be forwarded by the respective head of marketing to the Executive
                Committee.
            </p>
        </div>

        <div class="m-b-10 border">
            <h2>0001.4 d.light’s Responsibilities</h2>
            <p>
                Any Employees who process Personal Information relating to other Employees, Data Subjects, third parties
                or
                any other individual must ensure that:
            </p>
            <ol type="a" class="m-l-40">
                <li>
                    <p>all Personal Information is kept securely and confidentially;</p>
                </li>
                <li>
                    <p>no Personal Information is disclosed either verbally or in writing, accidentally or otherwise, to
                        any
                        unauthorized third party;;</p>
                </li>
                <li>
                    <p>Personal Information is kept in accordance with d.light’s practices; </p>
                </li>
                <li>
                    <p>any queries regarding data protection, including subject access requests and complaints, are
                        promptly
                        directed to Group Legal and Compliance Manager; </p>
                </li>
                <li>
                    <p>any data protection breaches are swiftly brought to the attention of the Global CFO and the Group
                        Legal
                        and Compliance Manager and that any such breaches are resolved; and </p>
                </li>
                <li>
                    <p>where there is uncertainty around a data protection matter; advice is sought from Group Legal and
                        Compliance Manager. </p>
                </li>
            </ol>
            <p>Employees who are unsure about who are the authorized third parties to whom they can legitimately
                disclose
                Personal Information should seek advice from the head of marketing in each of the markets. </p>
        </div>

        <div class="m-b-10 border">
            <h2>0001.5 Third Party Data Processors</h2>
            <p>
                Where external companies are used to process Personal Information on behalf of d.light, responsibility
                for the
                security and appropriate use of that Personal Information remains with d.light. Where a third-party data
                processor is used:
            </p>
            <ol class="m-l-40">
                <li>
                    <p>a data processor must be chosen who provides sufficient guarantees regarding the security
                        measures it
                        uses in protecting the Processing of Personal Information; </p>
                </li>
                <li>
                    <p>reasonable steps must be taken that such security measures are in place; </p>
                </li>
                <li>
                    <p>Personal Information is kept in accordance with d.light’s practices; </p>
                </li>
                <li>
                    <p>a written contract establishing what Personal Information will be processed and for what purpose
                        must
                        be clearly set out; </p>
                </li>
                <li>
                    <p>all Personal Information is returned to d.light on completion of any work, including any copies
                        that may
                        have been made. Alternatively, the data processor must ensure that the Personal Information is
                        securely
                        destroyed and that d.light receives notification in this regard; and </p>
                </li>
                <li>
                    <p>d.light receives prior notification of any reasonable disclosure of Personal Information to any
                        other
                        organization or any person. </p>
                </li>
            </ol>
            <p>d.light is responsible for the use made of Personal Information by anyone working on its behalf. </p>
        </div>

        <div class="m-b-10 border">
            <h2>0001.6 Information Management </h2>
            <div class="m-t-10">
                <h4 class="m-b-10">1.6.1 Personal Information</h4>
                <p>
                    From time to time, Employees may become privy to Personal Information during the course of their
                    employment. Personal Information is subject to specific legislation governing how it can be kept,
                    handled and
                    processed, particularly if the content of that data is deemed “sensitive.” Reasonable safeguards
                    should be in
                    place to protect such data against loss, theft, unauthorized access or inappropriate use.
                </p>
            </div>

            <div class="m-t-20">
                <h4 class="m-b-10">1.6.2 Use and Security of Digital Information </h4>
                <p>
                    Increasingly, d.light relies upon information which is stored and processed on digital systems and
                    networks.
                    Such information is critical to the operation of d.light’s business and is, accordingly, a valuable
                    Company asset.
                    All Employees must ensure that data is accessed in accordance with current IT security policies and
                    processes
                    and that confidential information relating to the Company, its Employees, Data Subjects or any third
                    parties
                    including but not limited to its customers, is not downloaded or transmitted without appropriate
                    authorization.
                </p>
                <p>
                    Where an Employee identifies that data has been lost, misused, intercepted or otherwise
                    misappropriated, this
                    should be immediately reported to their manager.
                </p>
            </div>

            <div class="m-t-20">
                <h4 class="m-b-10">1.5.3 Dealing with the Media </h4>
                <p>
                    All communications with the press or media on behalf of the Company must be handled by those
                    delegated to
                    do so by the Executive Committee, such as the marketing team in each of the markets. All Employees
                    who
                    receive an enquiry from the press or media directed to or on behalf of the Company have a duty to
                    immediately
                    forward any such enquiries to the head of marketing in each of the markets. Any enquiries that are
                    sensitive in
                    nature, should be forwarded by the respective head of marketing to the Executive Committee.
                </p>

            </div>

        </div>

        <div class="m-b-10 border">
            <h2>0001.7 Reporting a Breach of Personal Information </h2>
            <div class="m-t-10">
                <h4 class="m-b-10">1.3.1 Complaint Handling</h4>
                <p>
                    Those with a complaint (“<b>Complainant</b>”) about the Processing of their Personal Information,
                    should put forward
                    the matter in writing to the Global CFO with a copy to the Group Legal and Compliance Manager. An
                    investigation of the complaint will be carried out to the extent that is appropriate based on the
                    merits of the
                    specific case. The Group Legal and Compliance Manager will inform the Complainant of the progress
                    and the
                    outcome of the complaint within a reasonable period. If the issue cannot be resolved through
                    consultation
                    between the Complainant and the Group Legal and Compliance Manager, then the Complainant may, at
                    their
                    option, seek redress through mediation, binding arbitration, litigation, or via complaint to the
                    applicable and
                    relevant data protection authority within the applicable jurisdiction.
                </p>
            </div>

            <div class="m-t-20">
                <h4 class="m-b-10">1.3.2 Reporting a Breach </h4>
                <p>
                    Any Personal Information breach where there is a risk to the rights and freedoms of a Data Subject
                    must be
                    immediately reported to the Global CFO with a copy to the Group Legal and Compliance Manager. Where
                    the
                    Personal Information breach results in a high risk to the Data Subject, he/she also must be notified
                    unless
                    subsequent steps have been taken to ensure that:
                </p>
                <ol class="m-l-40" type="a">
                    <li>
                        <p>
                            the risk is unlikely to materialize;
                        </p>
                    </li>
                    <li>
                        <p>
                            security measures were applied to render the Personal Information unintelligible (e.g.
                            encryption); or
                        </p>
                    </li>
                    <li>
                        <p>
                            it would amount to disproportionate effort to inform the Data Subject directly. In such
                            circumstances, a
                            public communication must be made, or an equally effective alternative measure must be
                            adopted to
                            inform the Data Subject, so that he/she can take any remedial action.
                        </p>
                    </li>
                </ol>
                <p>
                    If you know or suspect that a Personal Information breach has occurred, you should immediately
                    contact the
                    Global CFO with a copy to the Group Legal and Compliance Manager. You must retain all evidence
                    relating to
                    Personal Information breaches to enable d.light to maintain a record of such breaches.
                </p>
            </div>



        </div>

        <div class="m-b-10 border">
            <h2>0001.8 Record Keeping </h2>
            <p>
                d.light shall keep full and accurate records of all its data Processing activities and shall keep and
                maintain
                accurate corporate records reflecting the Processing procedure, including, where applicable, records of
                all
                consents and procedures for obtaining consents. These records will include, but not be limited to:
            </p>
            <div class="m-t-10">
                <ol type="a" class="m-l-40">
                    <li>
                        <p>
                            the name and contact details of d.light as the Personal Information controller;
                        </p>
                    </li>
                    <li>
                        <p>
                            clear descriptions of the types of Personal Information;
                        </p>
                    </li>
                    <li>
                        <p>
                            types of the Data Subjects (i.e.) Employees and/or third parties;
                        </p>
                    </li>
                    <li>
                        <p>
                            Processing activities;
                        </p>
                    </li>
                    <li>
                        <p>
                            Purposes of Processing purposes;
                        </p>
                    </li>
                    <li>
                        <p>
                            any third-party recipients of the Personal Information;
                        </p>
                    </li>
                    <li>
                        <p>
                            any storage locations for the Personal Information;
                        </p>
                    </li>
                    <li>
                        <p>
                            any Personal Information transfers, where applicable,
                        </p>
                    </li>
                    <li>
                        <p>
                            the retention period with respect to the Personal Information; and
                        </p>
                    </li>
                    <li>
                        <p>
                            a description of the security measures in place for safeguarding the Personal Information.
                        </p>
                    </li>
                </ol>

                <p>
                    Records of any Personal Information breaches must also be kept, setting out:
                </p>

                <ol type="a" class="m-l-40">
                    <li>
                        <p>
                            the facts surrounding the breach;
                        </p>
                    </li>
                    <li>
                        <p>
                            its effects; and
                        </p>
                    </li>
                    <li>
                        <p>
                            the remedial action taken, if any, or that will be taken.
                        </p>
                    </li>
                </ol>
            </div>
        </div>

        <div class="m-b-10 border">
            <h2>0001.9 Training </h2>
            <div class="m-t-10">
                <p>
                    d.light is required to ensure that all its Employees undergo adequate training to enable them to
                    comply with all
                    relevant and applicable data protection law. Further detailed information will be provided by HR in
                    each of the
                    markets about the training available. You must regularly review all the systems and processes under
                    your control
                    to ensure they comply with this policy. Employees who are unsure should seek advice from their
                    respective
                    team leaders and/or HR in the relevant markets.
                </p>
            </div>
        </div>

        <div class="m-b-10 border">
            <h2>0001.10 Sharing Personal Information </h2>
            <div class="m-t-10">
                <p>
                    In the absence of (a) consent, (b) a legal obligation or (c) other legal basis of Processing;
                    Personal Information
                    should not be disclosed to any third parties that are unrelated to d.light. Some sharing of Personal
                    Information
                    for research purposes may be permissible, subject to certain safeguards. However, before such
                    disclosure, seek
                    approval from your manager.
                </p>
                <p>
                    Notwithstanding the above, if you need any guidance, please contact the head of marketing in each of
                    the
                    markets with a copy to the Group Legal and Compliance Manager.
                </p>
            </div>
        </div>


        <div class="m-b-10 border">
            <h2>0001.11 Breach of Policy </h2>
            <div class="m-t-10">
                <p>
                    Non‐compliance with this Policy may result in disciplinary action being taken in accordance with
                    d.light’s
                    disciplinary procedures. With respect to any third parties, it will be up to their employing
                    organization to take
                    action in accordance with their own internal policies and procedures. d.light expects such employing
                    organizations to take effective disciplinary action and will not continue to work with organizations
                    that fail to
                    do so.
                </p>
            </div>
        </div>

        <div class="m-b-10 border">
            <h2>0001.12 Changes to the Policy </h2>
            <div class="m-t-10">
                <p>
                    d.light reserves the right to change/amend/revise this Policy at any time without notice to you
                    so please check
                    the intranet regularly to view and obtain the latest copy of the Policy.
                </p>
            </div>
        </div>

    </div>
</section>