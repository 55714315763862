import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';

import {RateValidationQualification} from '@appModels/core-accounting/rate-validation-qualification/rate-validation-qualification';

import {CoreAccountingService} from '../core-accounting.service';


@Injectable({ providedIn: 'root' })
export class RateValidationQualificationService extends CoreAccountingService {


  constructor(
    private http: HttpClient,

  ) {
    super(http, 'ratevalidationqualification');
  }

  getRateValidationQualifications(query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        params = params.append(val, query[val]);
      });
    }
    return this.http.get<any>(this.base_url + '/tenant/' + localStorage.getItem('tenant'), {
      params,
      headers: this.headers
    }).pipe(
      tap(rateValidationQualifications => this.log(`fetched rateValidationQualifications`)),
      catchError(this.handleError('getRateValidationQualifications', []))
    );
  }

  getAdminZoneByRateId(id: number): Observable<any> {
    return this.http.get<any>(this.base_url + '/countryAdministrativeZones/rate/' + id, {headers: this.headers}).pipe(
      tap(rateValidationQualifications => this.log(`fetched rateValidationQualifications`)),
      catchError(this.handleError('getRateValidationQualifications'))
    );
  }

  getPaymentCategoriesByRateId(id: number): Observable<any> {
    return this.http.get<any>(this.base_url + '/paymentCategorizationDefinitions/rate/' + id, {headers: this.headers}).pipe(
      tap(rateValidationQualifications => this.log('fetched rateValidatioNQualifications')),
      catchError(this.handleError('getRateValidationQualifications'))
    );
  }

  getUsersRateId(id: number): Observable<any> {
    return this.http.get<any>(this.base_url + '/users/rate/' + id, {headers: this.headers}).pipe(
      tap(rateValidationQualifications => this.log(`fetched rateValidationQualifications`)),
      catchError(this.handleError('getRateValidationQualifications'))
    );
  }

  getRolesRateId(id: number): Observable<any> {
    return this.http.get<any>(this.base_url + '/role/rate/' + id, {headers: this.headers}).pipe(
      tap(rateValidationQualifications => this.log(`fetched rateValidationQualifications`)),
      catchError(this.handleError('getRateValidationQualifications'))
    );
  }

  getContractProductStatusByRateId(id: number): Observable<any> {
    return this.http.get<any>(this.base_url + '/contractproductstatus/rate/' + id, {headers: this.headers}).pipe(
      tap(rateValidationQualifications => this.log(`fetched rateValidationQualifications`)),
      catchError(this.handleError('getRateValidationQualifications'))
    );
  }

  /** GET rateValidationQualification by id. Return `undefined` when id not found */
  getRateValidationQualificationNo404<Data>(id: number): Observable<RateValidationQualification> {
    const url = `${this.base_url}/?id=${id}`;
    return this.http.get<RateValidationQualification[]>(url).pipe(
      map(rateValidationQualifications => rateValidationQualifications[0]), // returns a {0|1} element array
      tap(h => {
        const outcome = h ? `fetched` : `did not find`;
        this.log(`${outcome} rateValidationQualification id=${id}`);
      }),
      catchError(this.handleError<RateValidationQualification>(`getRateValidationQualification id=${id}`))
    );
  }

  /** GET rateValidationQualification by id. Will 404 if id not found */
  getRateValidationQualification(id: number): Observable<RateValidationQualification> {
    const url = `${this.base_url}/rate/${id}/`;
    return this.http.get<RateValidationQualification>(url,{ headers: this.headers }).pipe(
      tap(_ => this.log(`fetched rateValidationQualification id=${id}`)),
      catchError(this.handleError<RateValidationQualification>(`getRateValidationQualification id=${id}`))
    );
  }

  /** GET rateValidationQualification by tenant id. Will 404 if id not found */
  getRateValidationQualificationByTenant(query?): Observable<RateValidationQualification[]> {
    let params = new HttpParams();
    if (query !== undefined){
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        params = params.append(val, query[val]);
      });
    }
    const url = `${this.base_url}/tenant/` + localStorage.getItem('tenant');
    return this.http.get<any>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched rateValidationQualification id=`)),
      catchError(this.handleError(`getRateValidationQualification `, []))
    );
  }

  /** GET rateValidationQualification by product id. Will 404 if id not found */
  getRateValidationQualificationProductCatalogue(id): Observable<any> {
    // const id = localStorage.getItem('tenant');
    const url = `${this.base_url}/tenant/productcatalogue/${id}/`;
    return this.http.get(url,{ headers: this.headers }).pipe(
      tap(_ => this.log(`fetched rateValidationQualifications by product catalogue id=${id}`)),
      catchError(this.handleError(`getRateValidationQualificationByProductCatalogue id=${id}`))
    );
  }

  /* GET rateValidationQualifications whose name contains search term */
  searchRateValidationQualifications(term: string): Observable<RateValidationQualification[]> {
    if (!term.trim()) {
      // if not search term, return empty rateValidationQualification array.
      return of([]);
    }
    return this.http.get<RateValidationQualification[]>(`api/rateValidationQualifications/?name=${term}`).pipe(
      tap(_ => this.log(`found rateValidationQualifications matching "${term}"`)),
      catchError(this.handleError<RateValidationQualification[]>('searchRateValidationQualifications', []))
    );
  }

  //////// Save methods //////////

  /** POST: add a new rateValidationQualification to the server */
  addRateValidationQualification(rateValidationQualification: RateValidationQualification): Observable<RateValidationQualification> {
    return this.http.post<RateValidationQualification>(this.base_url + '/', rateValidationQualification, { headers: this.headers }).pipe(
      tap((rateValidationQualification: RateValidationQualification) => this.log(`added rateValidationQualification w/ id=${rateValidationQualification.id}`)),
      catchError(this.handleError<RateValidationQualification>('addRateValidationQualification'))
    );
  }

  // addRateValidationQualification(rateValidationQualification: RateValidationQualification): Observable<RateValidationQualification> {
  //   return this.http.post<RateValidationQualification>(this.base_url + '/', rateValidationQualification, { headers: this.headers }).pipe(
  //     tap((newRateValidationQualification: RateValidationQualification) => {
  //       this.log(`added rateValidationQualification w/ id=${newRateValidationQualification.tenantId}`);
  //       // patch info
  //       this.editRateValidationQualification({ value: rateValidationQualification.id, id: newRateValidationQualification.tenantId, param: 'tenant' }).subscribe(data => {
  //       }, error => console.log(error, 'rateValidationQualification error'));
  //     }),
  //     catchError(this.handleError<RateValidationQualification>('addRateValidationQualification'))
  //   );
  // }

  /** POST: add a new rateValidationQualification to the server */
  addRateValidationQualificationAdditionalInfo(rateValidationQualification): Observable<{}> {
    return this.http.post(`${this.base_url}/additionalInfo/${rateValidationQualification.id}`, rateValidationQualification.value, { headers: this.headers }).pipe(
      tap((res) => this.log(`added rateValidationQualification  info `)),
      catchError(this.handleError('addRateValidationQualification'))
    );
  }

  /** DELETE: delete the rateValidationQualification from the server */
  deleteRateValidationQualification(rateValidationQualification: RateValidationQualification | number): Observable<RateValidationQualification> {
    const id = typeof rateValidationQualification === 'number' ? rateValidationQualification : rateValidationQualification.id;
    const url = `${this.base_url}/${id}`;

    return this.http.delete<RateValidationQualification>(url, { headers: this.headers }).pipe(
      tap(_ => this.log(`deleted rateValidationQualification id=${id}`)),
      catchError(this.handleError<RateValidationQualification>('deleteRateValidationQualification'))
    );
  }

  /** PUT: update the rateValidationQualification on the server */
  updateRateValidationQualification(rtv: RateValidationQualification): Observable<any> {
    return this.http.put(`${this.base_url}/${rtv.id}`, rtv, { headers: this.headers }).pipe(
      tap(_ => this.log(`updated rateValidationQualification id=${rtv.id}`)),
      catchError(this.handleError<any>('updateRateValidationQualification'))
    );
  }

  /** Patch: update the rateValidationQualification on the server */
  editRateValidationQualification(data, visible?): Observable<any> {
    let url = `${this.base_url}/rate/${data.id}/${data.param}/${data.value}`;

    if (visible) {
      url += `?visible=${visible === 'visible'}`
    }
    return this.http.patch(url, {}, {headers: this.headers}).pipe(
      tap(_ => this.log(`edit rateValidationQualification id=${data.id}`)),
      catchError(this.handleError<any>('editRateValidationQualification'))
    );
  }

  /** Patch: update the rateValidationQualification on the server */
  removeRateValidationQualification(data): Observable<any> {
    return this.http.delete(`${this.base_url}/rate/${data.id}/${data.param}/${data.value}`, {headers: this.headers}).pipe(
      tap(_ => this.log(`delete rateValidationQualification id=${data.id}`)),
      catchError(this.handleError<any>('deleteRateValidationQualification'))
    );
  }

}
