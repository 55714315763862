
<div class="flex flex-row justify-start my-8 mx-4">
  <div class="flex flex-row   gap-3 justify-start">
    <div class="text-xl text-black-dark-light">
      Home
    </div>
    <div class="text-xl font-black text-black-dark-light">
      /
    </div>
    <div class="text-xl text-black-dark-light   cursor-pointer hover:text-dlight ease-in-out duration-300" routerLink="/ticketing/workflows">
      Workflows
    </div>
    <div class="text-xl font-black text-black-dark-light">
      /
    </div>
    <div class="text-xl text-black-dark-light text-orange-600">
      View Workflow
    </div>
  </div>

</div>

<div class="mt-3" *ngIf="mainLoading">
  <div class="flex flex-row justify-center items-center w-full  gap-4">
    <span class="m-auto  inline-block h-14 w-14 animate-spin rounded-full border-8 border-[#f1f2f3] border-l-dlight align-middle"></span>
  </div>
</div>


<ng-container *ngIf="!mainLoading">
  <div class="w-2/3 m-auto">
    <div style="border-radius:20px" class="bg-white box-shadow-pop p-4">
      <div class="font-semibold text-lg">General Details</div>
      <div class="text-dlight">
        <hr class="h-px border-t-0 bg-transparent bg-gradient-to-r from-red-500 via-red-500 to-transparent opacity-25">
      </div>

      <form>
        <div class="mt-6">
          <label class="label_title">Name
            <span *ngIf="workflow.name == null || workflow.name.length < 3" class="text-red-500">*</span>
            <span *ngIf="workflow.name != null && workflow.name.length >= 3" class="text-green-500">*</span>
          </label>
          <input class=" form-input form-input-focus" placeholder="Enter a name"  [(ngModel)]="workflow.name" name="name"/>

          <label class="label_title mt-3">Ticket Type
            <span *ngIf="workflow.ticketTypeId == null || workflow.ticketTypeId == undefined" class="text-red-500">*</span>
            <span *ngIf="workflow.ticketTypeId != null && workflow.ticketTypeId != undefined" class="text-green-500">*</span>
          </label>
          <select class="select_input form-input form-input-focus" placeholder="Select Ticket Type..."  [(ngModel)]="workflow.ticketTypeId" name="ticketTypeId">
            <option value="" disabled>{{'Please select Ticket Type' | translate}}</option>
            <option [value]="ticketType.id" *ngFor="let ticketType of ticketTypes"> {{ticketType.name}} </option>
          </select>
        </div>


        <div class="font-semibold mt-6 text-xl">Statuses:</div>

        <div class="flex flex-row justify-start items-end gap-4 mt-5">

          <div>
            <label for="selectedStatus">Choose Ticket Statuses For Workflow</label>
            <select (change)="selectStatus()" class="select_input form-input form-input-focus" placeholder="Select Status..."
                    [(ngModel)]="selectedStatus" id="selectedStatus" name="selectedStatus">
              <option value="" disabled>{{'Please select a Status' | translate}}</option>
              <option [ngValue]="status" *ngFor="let status of statuses"> {{status.name}} </option>
            </select>
          </div>
          <div>
            <button (click)="refreshStatusList()" tooltip="Refresh Status List" class="btn btn-dlight w-8 h-8 p-0 rounded-full"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xml:space="preserve" width="20" height="20"><path fill="none" stroke="currentColor" stroke-width="1.25" stroke-miterlimit="10" d="M16.063 6.813A6.83 6.83 0 0 0 10 3.125c-2.938 0-5.375 1.813-6.375 4.375m.25 5.625C5 15.313 7.313 16.875 10 16.875c2.938 0 5.375-1.813 6.375-4.375"/><path fill="none" stroke="currentColor" stroke-width="1.25" stroke-miterlimit="10" d="M16.25 3.125v3.75H12.5m-8.75 10v-3.75H7.5"/></svg></button>
          </div>

          <button *ngIf="can('can-manage-ticket-status')" class="btn btn-dlight flex-grow-0" (click)="goToCreateNewStatusPage()" >Create A New Status</button>
        </div>

        <div  *ngIf="workflow.statuses.length > 0">

          <div class="bg-white ticket-table w-full mt-3" style="border-radius:20px">
            <div class="grid grid-cols-5 py-3 ticket-row w-full">
              <div class="col-span-1 m-auto">
                NAME
              </div>
              <div class="col-span-1 m-auto">
                BACKGROUND COLOR
              </div>
              <div class="col-span-1 m-auto">
                TEXT COLOR
              </div>
              <div class="col-span-1 m-auto flex flex-row gap-1 items-center">
                <div>IS DEFAULT</div>
                <div class="cursor-pointer" tooltip="A default status is the status in which a newly created or escalated ticket will be placed"><svg fill="#FF4613" width="18" height="18" viewBox="-0.045 -0.045 0.54 0.54" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin" class="jam jam-info"><path d="M0.225 0.45C0.101 0.45 0 0.349 0 0.225S0.101 0 0.225 0s0.225 0.101 0.225 0.225 -0.101 0.225 -0.225 0.225m0 -0.045a0.18 0.18 0 1 0 0 -0.36 0.18 0.18 0 0 0 0 0.36m0 -0.225a0.022 0.022 0 0 1 0.022 0.022v0.112a0.022 0.022 0 0 1 -0.045 0V0.202a0.022 0.022 0 0 1 0.022 -0.022m0 -0.022a0.022 0.022 0 1 1 0 -0.045 0.022 0.022 0 0 1 0 0.045"/></svg></div>
              </div>
              <div class="col-span-1 m-auto">
                REMOVE
              </div>
            </div>
          </div>


          <div class="grid grid-cols-5 py-2 ticket-row " *ngFor="let status of workflow.statuses">
            <div class="col-span-1 flex flex-row justify-center items-center">
              <span class="badge bg-warning rounded-full text-white" [ngStyle]="{'background-color': status.colorCode, 'color':status.textColorCode}">{{status.name}}</span>

            </div>
            <div class="col-span-1 flex flex-row justify-center items-center">
              <input type="color" disabled [(ngModel)]="status.colorCode" [ngModelOptions]="{standalone: true}">
            </div>
            <div class="col-span-1 flex flex-row justify-center items-center">
              <input type="color" disabled [(ngModel)]="status.textColorCode" [ngModelOptions]="{standalone: true}">
            </div>
            <div class="col-span-1 flex flex-row justify-center items-center">
              <input type="checkbox"  [(ngModel)]="status.defaultStatus" [ngModelOptions]="{standalone: true}">
            </div>
            <div class="col-span-1 flex flex-row gap-2 justify-center items-center">
              <button (click)="removeStatus(status)" type="button" class="btn btn-sm rounded-full btn-outline-dlight "><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" xml:space="preserve" width="24" height="24"><path fill="none" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" d="M17.25 20.25h-9c-.825 0-1.5-.675-1.5-1.5V6h12v12.75c0 .825-.675 1.5-1.5 1.5zm3-14.25h-15m5.25 0V4.5c0-.45.3-.75.75-.75h3c.45 0 .75.3.75.75V6m-2.25 11.25V9m3 8.25V9m-6 8.25V9"/></svg>&nbsp;&nbsp;Remove </button>
            </div>
          </div>
        </div>

      </form>

      <div class="mt-6">
        <div class="font-semibold">Transitions:</div>

        <div class="bg-white ticket-table w-full mt-3" style="border-radius:20px">
          <div class="grid grid-cols-3 py-3 ticket-row">
            <div class="col-span-1 m-auto">
              FROM
            </div>
            <div class="col-span-1 m-auto">
              TO
            </div>
            <div class="col-span-1 m-auto">
              TRANSITION ENABLED
            </div>
          </div>
        </div>


        <div class="grid grid-cols-3 py-2 ticket-row " *ngFor="let transition of workflow.transitions">
          <div class="col-span-1 flex flex-row justify-center items-center">
            <span class="badge bg-warning rounded-full text-white" [ngStyle]="{'background-color': transition.fromColorCode, 'color':transition.fromTextColorCode}">{{transition.fromName}}</span>

          </div>
          <div class="col-span-1 flex flex-row justify-center items-center">
            <span class="badge bg-warning rounded-full text-white" [ngStyle]="{'background-color': transition.toColorCode, 'color':transition.toTextColorCode}">{{transition.toName}}</span>
          </div>
          <div class="col-span-1 flex flex-row justify-center items-center">
            <input type="checkbox" (change)="changeLink(transition.fromId, transition.toId, transition.enabled)" [(ngModel)]="transition.enabled" [ngModelOptions]="{standalone: true}">
          </div>
        </div>
      </div>

    </div>
  </div>

</ng-container>

<div *ngIf="!mainLoading" class="w-2/3 m-auto mt-6">
  <div style="border-radius:20px" class="bg-white box-shadow-pop">

    <div class="p-4">
      <div class="font-semibold text-xl">Diagram</div>
      <div class="text-dlight">
        <hr class="h-px border-t-0 bg-transparent bg-gradient-to-r from-red-500 via-red-500 to-transparent opacity-25">
      </div>
    </div>
    <app-library (outputJsonEvent)="setDiagramJson($event)" [jsonDiagram]="workflow.diagram"  [nodeList]="nodeList" [linkList]="linkList"></app-library>
  </div>
</div>

<div class="w-2/3 m-auto mt-6">
  <div style="border-radius:20px" class="bg-white box-shadow-pop p-4">

    <div class="font-semibold text-lg">Custom Fields</div>
    <div class="text-dlight">
      <hr class="h-px border-t-0 bg-transparent bg-gradient-to-r from-red-500 via-red-500 to-transparent opacity-25">
    </div>


    <form>
      <div class="mt-6">

        <div class="flex flex-row justify-start items-end gap-4 mt-5">
          <div>
            <label class="label_title">Choose Custom Fields</label>
            <select (change)="selectCustomField()" class="select_input form-input form-input-focus" placeholder="Select Custom Fields..."
                    [(ngModel)]="selectedCustomField" name="selectedCustomField">
              <option value="" disabled>{{'Please select Field' | translate}}</option>
              <option [value]="customField.id" *ngFor="let customField of customFields"> {{customField.name}} </option>
            </select>
          </div>

          <div>
            <button (click)="refreshCustomFieldsList()" tooltip="Refresh Custom Fields List" class="btn btn-dlight w-8 h-8 p-0 rounded-full"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xml:space="preserve" width="20" height="20"><path fill="none" stroke="currentColor" stroke-width="1.25" stroke-miterlimit="10" d="M16.063 6.813A6.83 6.83 0 0 0 10 3.125c-2.938 0-5.375 1.813-6.375 4.375m.25 5.625C5 15.313 7.313 16.875 10 16.875c2.938 0 5.375-1.813 6.375-4.375"/><path fill="none" stroke="currentColor" stroke-width="1.25" stroke-miterlimit="10" d="M16.25 3.125v3.75H12.5m-8.75 10v-3.75H7.5"/></svg></button>
          </div>

          <button *ngIf="can('can-manage-ticket-custom-fields')" class="btn btn-dlight flex-grow-0" (click)="goToCreateNewCustomFieldPage()" >Create A New Custom Field</button>
        </div>

        <div class="bg-white ticket-table mt-3  mx-auto" style="border-radius:20px">
          <div class="grid grid-cols-3 py-3 ticket-row">
            <div class="col-span-1 m-auto">
              NAME
            </div>
            <div class="col-span-1 m-auto">
              TYPE
            </div>
            <div class="col-span-1 m-auto">
              ACTIONS
            </div>
          </div>
          <div class="grid grid-cols-3 py-3 ticket-row" *ngFor="let customField of workflow.customFieldList">
            <div class="col-span-1 my-auto pl-8">
              {{ customField.name }}
            </div>
            <div class="col-span-1 m-auto">
              <span *ngIf="customField.customFieldTypeId == 1">Text</span>
              <span *ngIf="customField.customFieldTypeId == 2">Date</span>
              <span *ngIf="customField.customFieldTypeId == 3">Select Option</span>
              <span *ngIf="customField.customFieldTypeId == 4">System Field</span>
            </div>
            <div class="col-span-1 m-auto">
              <button (click)="removeCustomField(customField)" type="button" class="btn btn-sm rounded-full btn-outline-dlight "><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" xml:space="preserve" width="24" height="24"><path fill="none" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" d="M17.25 20.25h-9c-.825 0-1.5-.675-1.5-1.5V6h12v12.75c0 .825-.675 1.5-1.5 1.5zm3-14.25h-15m5.25 0V4.5c0-.45.3-.75.75-.75h3c.45 0 .75.3.75.75V6m-2.25 11.25V9m3 8.25V9m-6 8.25V9"/></svg>&nbsp;&nbsp;Remove </button>
            </div>
          </div>
        </div>

        <div class="mt-3" style="min-height:1px"></div>

      </div>
    </form>

  </div>
</div>


<div class="flex flex-row justify-center w-full mt-6" *ngIf="!loadingCreate">
  <button  (click)="updateWorkflow()" type="button" class="btn btn-success">Update Workflow</button>
</div>
<div *ngIf="loadingCreate" class="flex flex-row justify-center items-center w-full  gap-4">
  <span class="m-auto  inline-block h-14 w-14 animate-spin rounded-full border-8 border-[#f1f2f3] border-l-dlight align-middle"></span>
</div>
<div style="min-height:150px"></div>

