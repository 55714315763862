import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getNavStore = createFeatureSelector('nav');

export const getNavEntities = createSelector(
  getNavStore,
  fromReducers.nav.navEntitySelectors.selectAll
);

export const getNav = createSelector(
  getNavStore,
  (navStore: fromReducers.nav.State) => navStore.main
);

export const getModuleNav = createSelector(
  getNavStore,
  (navStore: fromReducers.nav.State) => navStore.moduleNav
);

export const getSubNav = createSelector(
  getNavStore,
  (navStore: fromReducers.nav.State) => navStore.subNav
);
