import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {pick} from 'lodash'

import {Tenant} from '@appModels/core-setup/tenant/tenant';
import {Timezone} from '@appModels/core-setup/timezone/timezone';

import * as fromSelectors from '@appStore/selectors';
import * as fromReducer from '@appStore/reducers';

import {UpdateTenant} from '@appStore/actions/core-masterdata/tenant/tenant.actions';

import {Router} from '@angular/router';
import {TenantService} from '@appServices/core-masterdata/tenant/tenant.service';
import {ToastrService} from 'ngx-toastr';
import {CurrencyService} from '@appServices/core-masterdata/currency/currency.service';
import {LanguageService} from '@appServices/core-masterdata/language/language.service';

@Component({
  selector: 'app-tenant-activation',
  templateUrl: './tenant-activation.component.html',
  styleUrls: ['./tenant-activation.component.scss']
})
export class TenantActivationComponent implements OnInit {
  tenant$: Observable<Tenant>;
  timezones$: Observable<Timezone[]>;

  selectedTenantId$: Observable<number>;
  wizard = [];
  wizardState = 0;
  activeTab = 0;

  loading = false;
  currencies: Array<any> = [];
  languages: Array<any> = [];

  tenant: Tenant = {
    physicalAddress: null,
    postalAddress: null,
    status: 1,
    email1: null,
    email2: null,
    name: null,
    optimisticLock: 0,
    telephone1: null,
    telephone2: null,
    updateProgram: null,
    countryId: null,
    languageId: null,
    currencyId: null,
    timeZoneId: null,
    id: null,
    tenantTypeId: null,
    tenantPreferences: [],
    createdAt: null,
    country: null,
    timezone: null,
    currencyname: null,
    languagename: null
  };

  formValid = {
    physicalAddress: true,
    postalAddress: true,
    status: true,
    email1: true,
    telephone1: true,
  };

  country = null;

  userRole = null;

  selectedTenantId = null;

  prefix = '(+254) ';
  pattern = '000-000-000';
  cleanPrefix = '';

  constructor(
    private store: Store<fromReducer.tenant.State>,
    private router: Router,
    private tenantService: TenantService,
    private currencyService: CurrencyService,
    private languageService: LanguageService,
    private toastr: ToastrService
  ) { }

  countryChanged(countries) {
    const country = countries.find(o => o.id === this.tenant.countryId);
    if (country) {
      this.prefix = `(+${country.telephoneCode})`;
      this.cleanPrefix = `+${country.telephoneCode}`;
    }
  }

  onNext() {
    this.loading = true;
    this.activeTab++;
    this.loading = false;
  }

  onPrevious() {
    // this.loading =  true;
    this.activeTab--;
  }

  viewTenant() {
    this.router.navigateByUrl(`/setup/tenants`);
  }

  clickTab(index) {
    this.activeTab = index;
  }

  addInfo() {
    if (this.tenant.languageId !== null && this.tenant.currencyId !== null && this.tenant.timeZoneId !== null) {
      this.tenantService.editTenant({
        id: this.tenant.id,
        param: 'language',
        value: +this.tenant.languageId
      }).subscribe(v => {
        this.tenantService.editTenant({
          id: this.tenant.id,
          param: 'timezone',
          value: +this.tenant.timeZoneId
        }).subscribe(v1 => {
          this.tenantService.editTenant({
            id: this.tenant.id,
            param: 'currency',
            value: +this.tenant.currencyId
          }).subscribe();
        });
      });
      this.clickTab(1);
    }
  }

  updateTenant() {
    let valid = true;
    this.loading = true;
    Object.keys(this.formValid).forEach(index => {
      this.formValid[index] = (this.tenant[index] !== '' && this.tenant[index] != null);
      if (!this.formValid[index]) {
        valid = false;
      }
    });
    if (valid) {
      let tenant = Object.assign({}, this.tenant);
      tenant.telephone1 = this.cleanPrefix + tenant.telephone1;
      tenant.telephone2 = (tenant.telephone2 !== null && tenant.telephone2 !== '') ? this.cleanPrefix + tenant.telephone2 : null;
      tenant.status = 1;

      this.store.dispatch(new UpdateTenant({
        ...tenant,
        languageId: +this.tenant.languageId,
        currencyId: +this.tenant.currencyId,
        timeZoneId: +this.tenant.timeZoneId
      }));
      if (this.selectedTenantId$ !== null) {
        this.loading = false;
        this.onNext();
      }
    }
  }

  ngOnInit() {
    // this.countries$ = this.store.pipe(select(fromSelectors.getCountries));
    // this.currencies$ = this.store.pipe(select(fromSelectors.getCurrencies));
    // this.languages$ = this.store.pipe(select(fromSelectors.getLanguages));
    this.timezones$ = this.store.pipe(select(fromSelectors.getTimezones));
    this.selectedTenantId$ = this.store.pipe(select(fromSelectors.getSelectedTenantId));
    // this.roles$ = this.store.pipe(select(fromSelectors.getRoles));
    // this.loading = true;
    this.tenantService.getTenant(+localStorage.getItem('tenant')).subscribe(
      data => {
        this.tenant = data;
        this.country = data.countryId;

        this.currencyService.getCurrencyCountry(this.country).subscribe(
          currency => {
            this.currencies = currency;
            this.languageService.getCountryLanguage(this.country).subscribe(
              language => {
                this.languages = language;
                this.loading = false;
              })
          });
        this.loading = false;
      },
      err => this.loading = false
    )

  }
}
