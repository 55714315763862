import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';

import {Currency} from '@appModels/core-setup/currency/currency';

import {CoreMasterDataService} from '../core-masterdata.service';


@Injectable({providedIn: 'root'})
export class CurrencyService extends CoreMasterDataService {


  constructor(
    private http: HttpClient,
  ) {
    super(http, 'currency');
  }

  /** GET currencys from the server */
  getCurrencies(): Observable<Currency[]> {
    return this.http.get<Currency[]>(this.base_url , {headers: this.headers}).pipe(
      tap(currencys => this.log(`fetched currencys`)),
      catchError(this.handleError('getCurrencys', []))
    );
  }

  /** GET currencies from the server paged */
  getCurrenciesPaged(query?): Observable<Currency[]> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        if (query[val] !== null && query[val] !== undefined && query[val] !== '') {
          params = params.append(val, query[val]);
        }
      });
    }

    return this.http.get<Currency[]>(this.base_url + 'paged', {headers: this.headers, params: params}).pipe(
      tap(currencys => this.log(`fetched currencys`)),
      catchError(this.handleError('getCurrenciesPaged', []))
    );
  }

  /** GET currency by id. Return `undefined` when id not found */
  getCurrencyNo404<Data>(id: number): Observable<Currency> {
    const url = `${this.base_url}/?id=${id}`;
    return this.http.get<Currency[]>(url).pipe(
      map(currencys => currencys[0]), // returns a {0|1} element array
      tap(h => {
        const outcome = h ? `fetched` : `did not find`;
        this.log(`${outcome} currency id=${id}`);
      }),
      catchError(this.handleError<Currency>(`getCurrency id=${id}`))
    );
  }

  /** GET currency by id. Will 404 if id not found */
  getCurrency(id: number): Observable<Currency> {
    const url = `${this.base_url}/${id}`;
    return this.http.get<Currency>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched currency id=${id}`)),
      catchError(this.handleError<Currency>(`getCurrency id=${id}`))
    );
  }

  /** GET currency by id. Will 404 if id not found */
  getCurrencyCountry(id: number): Observable<any> {
    const url = `${this.base_url}/country/${id}`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched currency id=`)),
      catchError(this.handleError<any>(`getCurrency`))
    );
  }

  /* GET currencys whose name contains search term */
  searchCurrencies(term: string): Observable<Currency[]> {
    if (!term.trim()) {
      // if not search term, return empty currency array.
      return of([]);
    }
    return this.http.get<Currency[]>(`api/currencys/?name=${term}`).pipe(
      tap(_ => this.log(`found currencys matching "${term}"`)),
      catchError(this.handleError<Currency[]>('searchCurrencys', []))
    );
  }

  //////// Save methods //////////

  /** POST: add a new currency to the server */
  addCurrency(currency: Currency): Observable<Currency> {
    return this.http.post<Currency>(this.base_url , currency, {headers: this.headers}).pipe(
      tap((currency: Currency) => this.log(`added currency w/ id=${currency.id}`)),
      catchError(this.handleError<Currency>('addCurrency'))
    );
  }

  /** DELETE: delete the currency from the server */
  deleteCurrency(currency: Currency | number): Observable<Currency> {
    const id = typeof currency === 'number' ? currency : currency.id;
    const url = `${this.base_url}/${id}`;

    return this.http.delete<Currency>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`deleted currency id=${id}`)),
      catchError(this.handleError<Currency>('deleteCurrency'))
    );
  }

  /** PUT: update the currency on the server */
  updateCurrency(currency: Currency): Observable<any> {
    return this.http.put(`${this.base_url}/${currency.id}`, currency, {headers: this.headers}).pipe(
      tap(_ => this.log(`updated currency id=${currency.id}`)),
      catchError(this.handleError<any>('updateCurrency'))
    );
  }

}
