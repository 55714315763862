import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {pick} from 'lodash';

import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';

import {Wallet} from '@appModels/core-accounting/wallet/wallet';

import {CoreAccountingService} from '../core-accounting.service';


@Injectable({ providedIn: 'root' })
export class WalletService extends CoreAccountingService {


  constructor(
    private http: HttpClient,

  ) {
    super(http, 'wallet');
  }

 
  /** GET wallets from the server */
  getWallets(): Observable<Wallet[]> {
    return this.http.get<Wallet[]>(this.base_url + 'searchSuspense/', { headers: this.headers }).pipe(
      tap(wallets => this.log(`fetched wallets`)),
      catchError(this.handleError('getWallets', []))
    );
  }

  /** GET customer wallets from the server */
  getCustomerWallet(id: number): Observable<Wallet> {
    const url = `${this.base_url}/customerWallet/${id}`;
    return this.http.get<any>(url).pipe(
      tap(_ => this.log(`fetched wallet id=${id}`)),
      catchError(this.handleError<any>(`getWallet id=${id}`))
    );
  }

  /** GET wallets payment search from the server */
  getPaymentSearch(query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        params = params.append(val, query[val]);
      });
    }
    return this.http.get<any>(this.base_url + '/paymentsearch/tenant/' + localStorage.getItem('tenant'), {
      params,
      headers: this.headers
    }).pipe(
      tap(paymentSearch => this.log(`fetched paymentSearch`)),
      catchError(this.handleError('getPaymentSearch', []))
    );
  }

  /** Download wallets payment search from the server */
  downloadPaymentSearch(query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        params = params.append(val, query[val]);
      });
    }
    return this.http.get(this.base_url + '/paymentsearch/downloadcsv/tenant/' + localStorage.getItem('tenant'), {
      params,
      headers: this.headers,
      responseType: 'blob'
    }).pipe(
      tap(paymentSearch => this.log(`fetched paymentSearch`)),
      catchError(this.handleError('getPaymentSearch', []))
    );
  }




  /** GET wallet by id. Return `undefined` when id not found */
  getWalletNo404<Data>(id: number): Observable<Wallet> {
    const url = `${this.base_url}/?id=${id}`;
    return this.http.get<Wallet[]>(url).pipe(
      map(wallets => wallets[0]), // returns a {0|1} element array
      tap(h => {
        const outcome = h ? `fetched` : `did not find`;
        this.log(`${outcome} wallet id=${id}`);
      }),
      catchError(this.handleError<Wallet>(`getWallet id=${id}`))
    );
  }

  /** GET wallet by id. Will 404 if id not found */
  getWallet(id: number): Observable<Wallet> {
    const url = `${this.base_url}/id/${id}`;
    return this.http.get<Wallet>(url).pipe(
      tap(_ => this.log(`fetched wallet id=${id}`)),
      catchError(this.handleError<Wallet>(`getWallet id=${id}`))
    );
  }

  /** GET Wallet by tenantId. Will 404 if id not found */
  getWalletByTenantId(id: number, query?): Observable<Wallet[]> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        params = params.append(val, query[val]);
      });
    }
    const url = `${this.base_url}/suspense/${id}`;
    return this.http.get<Wallet[]>(url, {params, headers: this.headers}).pipe(
      tap(_ => this.log(`fetched Wallet id=${id}`)),
      catchError(this.handleError<Wallet[]>(`getWallet id=${id}`))
    );
  }

  /** GET Wallet by tenantId. Will 404 if id not found */
  heldPaymentsCsv(query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        params = params.append(val, query[val]);
      });
    }

    const tenantId = localStorage.getItem('tenant');

    const url = `${this.base_url}/suspense/downloadcsv/tenant/${tenantId}`;
    return this.http.get(url, {params, headers: this.headers, responseType: 'blob'}).pipe(
      tap(_ => this.log(`fetched Wallet id=${tenantId}`)),
      catchError(this.handleError<Wallet[]>(`getWallet id=${tenantId}`))
    );
  }

  /** GET contract product wallets by id. Will 404 if id not found */
  getContractProdWallets(id: number, query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        params = params.append(val, query[val]);
      });
    }
    const url = `${this.base_url}/contract/${id}/`;
    return this.http.get<any>(url, {params, headers: this.headers}).pipe(
      tap(_ => this.log(`fetched contract product wallets id=${id}`)),
      catchError(this.handleError(`getContractProduct id=${id}`))
    );
  }

   /** GET wallet by tenant by id. Will 404 if id not found */
   getWalletByTenant(id): Observable<any> {
    const tenantId = localStorage.getItem('tenant');
    const url = `${this.base_url}/searchSuspense/${tenantId}/${id}/`;
    return this.http.get(url,{ headers: this.headers }).pipe(
      tap(_ => this.log(`fetched transactions using accountNumber id=${id}`)),
      catchError(this.handleError(`get wallet transaction by tenant id=${id}`))
    );
  }

  // Get Wallet by unique identifier: Account Number/ Phone Number.
  getWalletByIdentifier(payload): Observable<any> {
    let params = new HttpParams();

    if (payload) {
      Object.keys(payload).forEach(key => {
        if (key !== 'search') {
          params = params.append(key, payload[key]);
        }
      });
    }
      const tenantId = localStorage.getItem('tenant');
      const url = `${this.base_url}/searchSuspense/${tenantId}/`;
      return this.http.get(url,{ params, headers: this.headers }).pipe(
        tap(_ => this.log(`fetched transactions using accountNumber/paymentReference id=${payload}`)),
        catchError(this.handleError(`get wallet transaction by tenant id=${payload}`))
      );
  }

  /** GET failed contract product wallets by id. Will 404 if id not found */
  getFailedProcessing(query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        params = params.append(val, query[val]);
      });
    }
    const url = `${this.base_url}/failed-processing/${localStorage.getItem('tenant')}`;
    return this.http.get<any>(url, {params, headers: this.headers}).pipe(
      tap(_ => this.log(`fetched contract product wallets id=`)),
      catchError(this.handleError(`getContractProduct id=`))
    );
  }

  /* GET wallets whose name contains search term */
  searchWallets(term: string): Observable<Wallet[]> {
    if (!term.trim()) {
      // if not search term, return empty wallet array.
      return of([]);
    }
    return this.http.get<Wallet[]>(`api/wallets/?name=${term}`).pipe(
      tap(_ => this.log(`found wallets matching "${term}"`)),
      catchError(this.handleError<Wallet[]>('searchWallets', []))
    );
  }

  //////// Save methods //////////

  /** POST: add a new wallet to the server */
  addWallet(wallet): Observable<Wallet> {
    return this.http.post(this.base_url + '/', wallet, {headers: this.headers}).pipe(
      tap((newWallet: Wallet) => {
        this.log(`added wallet w/ id=${newWallet.id}`);
      }),
      catchError(this.handleError<Wallet>('addWallet'))
    );
  }

  /** POST: add a new wallet to the server */
  refundWallet(id): Observable<Wallet> {
    return this.http.post(`${this.base_url}/transaction/${id}/refund`, {}, {headers: this.headers}).pipe(
      tap((refundWallet: Wallet) => {
        this.log(`added wallet w/ id=${refundWallet.id}`);
      }),
      catchError(this.handleError<Wallet>('refundWallet'))
    );
  }

  /** POST: add a new wallet to the server */
  approveRejectWallet(wallet): Observable<any> {
    return this.http.post(this.base_url + '/suspense/approveReject', wallet, {headers: this.headers}).pipe(
      tap((newWallet: any) => {
        this.log(`added wallet w/ id=${newWallet.id}`);
      })
    );
  }

  uploadWallet(wallet): Observable<{}> {
    this.setHeader(true);
    return this.http.post<{}>(this.base_url + '/batchupload/', wallet, {headers: this.headers}).pipe(
      tap((wallet) => this.log(`uploaded wallets `)),
      catchError(this.handleError<Wallet>('addWallet'))
    );
  }

  /** DELETE: delete the wallet from the server */
  deleteWallet(wallet: Wallet | number): Observable<Wallet> {
    const id = typeof wallet === 'number' ? wallet : wallet.id;
    const url = `${this.base_url}/${id}`;

    return this.http.delete<Wallet>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`deleted wallet id=${id}`)),
      catchError(this.handleError<Wallet>('deleteWallet'))
    );
  }

  /** PUT: update the wallet on the server */
  updateWallet(wallet: Wallet): Observable<any> {
    return this.http.put(`${this.base_url}/${wallet.id}/`, wallet, {headers: this.headers}).pipe(
      tap(_ => this.log(`updated wallet id=${wallet.id}`)),
      catchError(this.handleError<any>('updateWallet'))
    );
  }

  /** Patch: update the wallet on the server */
  editWallet(data): Observable<any> {
    return this.http.patch(`${this.base_url}/${data.id}/${data.param}/${data.value}`, {}, {headers: this.headers}).pipe(
      tap(_ => this.log(`updated wallet id=${data.id}`)),
      catchError(this.handleError<any>('update wallet'))
    );
  }

 /** POST: add a new wallet to the server */
 reprocessPayment(dateCreatedStart, dateCreatedEnd): Observable<any> {
    return this.http.post(this.base_url + `/failed-processing/${localStorage.getItem('tenant')}/reprocess?dateCreatedStart=${dateCreatedStart}&dateCreatedEnd=${dateCreatedEnd}`, {headers: this.headers}).pipe(
      tap((newWallet: any) => {
        this.log(`reprocess payment w/ id=${newWallet.id}`);
      }),
      catchError(this.handleError<any>('reprocess payment'))
    );
  }

  getOowPaymentSearch(query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        params = params.append(val, query[val]);
      });
    }
    return this.http.get<any>(this.base_url + '/oowpaymentsearch/tenant/' + localStorage.getItem('tenant'), {
      params,
      headers: this.headers
    }).pipe(
      tap(paymentSearch => this.log(`fetched paymentSearch`)),
      catchError(this.handleError('getPaymentSearch', []))
    );
  }
}
