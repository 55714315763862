import {ChangeDetectorRef, Component, TemplateRef, ViewChild, ViewEncapsulation} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';


import {ToastrService} from "ngx-toastr";

import {ActivatedRoute, Router} from "@angular/router";
import {BasePojo} from "@appModels/ticketing/base-pojo";
import {TicketingStatusService} from "@appServices/core-ticketing/ticketing-status.service";

@Component({
  moduleId: module.id,
  templateUrl: './ticket-status-update.component.html',
  animations: [
    trigger('toggleAnimation', [
      transition(':enter', [style({ opacity: 0, transform: 'scale(0.95)' }), animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' }))]),
      transition(':leave', [animate('75ms', style({ opacity: 0, transform: 'scale(0.95)' }))]),
    ]),
  ],
  styleUrls: [ '../../../tailwind.css'],
  encapsulation: ViewEncapsulation.None

})
export class TicketStatusUpdateComponent {



  constructor(
    private toastrService: ToastrService,
    private ticketStatusService: TicketingStatusService,
    private router: Router,
    private route: ActivatedRoute

  ) {
    this.ticketStatusId = +this.route.snapshot.paramMap.get('id');

    this.ticketStatusService.getTicketStatus(this.ticketStatusId).subscribe(x => {
      this.ticketStatus = x;
      this.mainLoading = false;
    })
  }

  mainLoading = true;
  ticketStatusId: number = null;


  ticketStatus : BasePojo = {
    name:"",
  }


  updateTicketStatus() {
    this.mainLoading = true;
    if (this.ticketStatus.name == null || this.ticketStatus.name == undefined || this.ticketStatus.name.length < 3) {
      this.toastrService.error("Please enter a longer name");
      return;
    }


    this.ticketStatusService.updateTicketStatus(this.ticketStatusId, this.ticketStatus).subscribe(x => {
      if (x.code == 200) {
        this.toastrService.success("Updated Ticket Status");
        this.mainLoading = false;
        this.router.navigateByUrl(`/ticketing/ticketstatuses`);
      } else {
        this.toastrService.error(x.message);
        this.mainLoading = false;
      }

    })
  }
}
