import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";

import { catchError, map, mergeMap, switchMap } from "rxjs/operators";
import { of } from "rxjs";

import {
  AddWallet,
  AddWalletSuccess,
  DeleteWallet,
  DeleteWalletSuccess,
  GetWalletById,
  GetWalletByIdSuccess,
  GetWallets,
  GetWalletsSuccess,
  GetWalletsByTenantSuccess,
  SearchWallets,
  SearchWalletsSuccess,
  UpdateWalletSuccess,
  UploadWallet,
  WalletActionTypes,
  WalletError,
  GetWalletsByTenant,
} from "@appStore/actions/core-accounting/wallet/wallet.actions";
import { WalletService } from "@appServices/core-accounting/wallet/wallet.service";
import { TenantService } from "@appServices/core-masterdata/tenant/tenant.service";

import * as fromRouterActions from "@appStore/actions/router.actions";
import { ToastrService } from "ngx-toastr";

@Injectable()
export class WalletEffects {
  // load wallets by tenant ID

  loadWalletsByTenantId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WalletActionTypes.walletGetWalletsByTenant),
      mergeMap((action: GetWalletsByTenant) =>
        this.walletService
          .getWalletByTenantId(+localStorage.getItem("tenant"), action.payload)
          .pipe(
            map((wallets) => new GetWalletsByTenantSuccess(wallets)),
            catchError((error) => of(new WalletError(error)))
          )
      )
    )
  );

  loadWallets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WalletActionTypes.walletGetWallets),
      mergeMap(() =>
        this.walletService.getWallets().pipe(
          map((roles) => new GetWalletsSuccess(roles)),
          catchError((error) => of(new WalletError(error)))
        )
      )
    )
  );

  getWalletById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WalletActionTypes.walletGetWalletById),
      mergeMap((action: GetWalletById) =>
        this.walletService.getWallet(action.payload).pipe(
          map((wallet) => new GetWalletByIdSuccess(wallet)),
          catchError((error) => of(new WalletError(error)))
        )
      )
    )
  );

  addWallet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WalletActionTypes.walletAddWallet),
      switchMap((action: AddWallet) =>
        this.walletService.addWallet(action.payload).pipe(
          map((wallet) => {
            if (wallet !== undefined) {
              this.toastr.success(
                "Held Payment has been successfully added!",
                "Successful!"
              );
              return new AddWalletSuccess(wallet);
            }
            this.toastr.error(
              "There was an error creating this manual Payment",
              "Unknown error"
            );
            return new WalletError({ type: 500, message: "Internal error" });
          }),
          catchError((error) => {
            this.toastr.error(error.message, error.error);
            return of(new WalletError(error));
          })
        )
      )
    )
  );

  uploadWallet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WalletActionTypes.walletUploadWallet),
      mergeMap((action: UploadWallet) =>
        this.walletService.uploadWallet(action.payload).pipe(
          map((wallet) => new GetWallets()),
          catchError((error) => of(new WalletError(error)))
        )
      )
    )
  );

  updateWallet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WalletActionTypes.walletUpdateWallet),
      mergeMap((action: AddWallet) =>
        this.walletService.updateWallet(action.payload).pipe(
          map((wallet) => new UpdateWalletSuccess(action.payload)),
          catchError((error) => of(new WalletError(error)))
        )
      )
    )
  );

  deleteWallet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WalletActionTypes.walletDeleteWallet),
      mergeMap((action: DeleteWallet) =>
        this.walletService.deleteWallet(action.payload).pipe(
          map(() => new DeleteWalletSuccess(action.payload)),
          catchError((error) => of(new WalletError(error)))
        )
      )
    )
  );

  searchWallets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WalletActionTypes.walletSearchWallets),
      mergeMap((action: SearchWallets) =>
        this.walletService.searchWallets(action.payload).pipe(
          map((wallets) => new SearchWalletsSuccess(wallets)),
          catchError((error) => of(new WalletError(error)))
        )
      )
    )
  );

  updateWalletSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WalletActionTypes.walletUpdateWalletSuccess),
      map((wallet) => new fromRouterActions.Go({ path: ["/finance/wallet"] }))
    )
  );

  constructor(
    private actions$: Actions,
    private walletService: WalletService,
    private tenantService: TenantService,
    private toastr: ToastrService
  ) {}
}
