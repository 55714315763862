import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { CoreCrmService } from '@appServices/core-crm/core-crm.service';


@Injectable({ providedIn: 'root' })
export class TenantCallService extends CoreCrmService {


  constructor(
    private http: HttpClient,

  ) {
    super(http, 'call/types');
  }

  /** GET tenant calls from the server */
  getTenantCalls(query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        params = params.append(val, query[val]);
      });
    }
    return this.http.get<any>(this.base_url + '/tenant/' + localStorage.getItem('tenant'), {
      params,
      headers: this.headers
    }).pipe(
      tap(tenantCalls => this.log(`fetched TenantCalls`)),
      catchError(this.handleError('getTenantCalls', []))
    );
  }


  

}
