import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {CoreAuthService} from '../core-auth.service';
import {PermissionChannelGroup} from "../../../models/core-identity/permission-channel-group/permission-channel-group";
import {Role} from "@appModels/core-identity/role/role";


@Injectable({providedIn: 'root'})
export class PermissionChannelGroupService extends CoreAuthService {

  constructor(
    private http: HttpClient,
  ) {
    super(http, 'permissionchannelgroup');
  }

  /** GET PermissionChannelGroup from the server */
  getPermissionChannelGroupByChannelAndModule(permissionChannelId: number, moduleId: number): Observable<PermissionChannelGroup[]> {
    return this.http.get<PermissionChannelGroup[]>(this.base_url + `/permissionChannel/${permissionChannelId}/module/${moduleId}`, {headers: this.headers}).pipe(
      tap(PermissionChannelGroup => this.log(`fetched PermissionChannelGroup`)),
      catchError(this.handleError('PermissionChannelGroups', []))
    );
  }

  assignPermissionsFromChannelGroupToRole(permissionchannelgroupIds, roleId): Observable<any> {
    return this.http.patch<any>(this.base_url + `/${permissionchannelgroupIds}/role/${roleId}`, {headers: this.headers}).pipe(
      tap(res => this.log(res)),
      catchError(this.handleError<any>('error'))
    );
  }

  revokePermissionsFromChannelGroupToRole(permissionchannelgroupIds, roleId): Observable<any> {
    let url = `${this.base_url}/${permissionchannelgroupIds}/role/${roleId}`;
    return this.http.delete(url, {headers: this.headers}).pipe(
      tap(_ => this.log(``)),
      catchError(this.handleError<any>(''))
    );
  }

  getPermissionChannelGroupsByRole(role: Role): Observable<PermissionChannelGroup[]> {
    return this.http.get<PermissionChannelGroup[]>(this.base_url + `/role/${role.id}`, {headers: this.headers}).pipe(
      tap(PermissionChannelGroup => this.log(`fetched PermissionChannelGroup`)),
      catchError(this.handleError('PermissionChannelGroups', []))
    );
  }

  /** GET PermissionsFromChannelGroup from the server */
  getPermissionsChannelGroups(): Observable<PermissionChannelGroup[]> {
    return this.http.get<PermissionChannelGroup[]>(this.base_url + `s/`, {headers: this.headers}).pipe(
      tap(permissionChannelGroup => this.log(`fetched PermissionChannelGroup`)),
      catchError(this.handleError('PermissionChannelGroup', []))
    );
  }

  /** GET PermissionsChannelGroup by id. Will 404 if id not found */
  getPermissionsChannelGroup(id: number): Observable<any> {
    const url = `${this.base_url}/${id}`;
    return this.http.get<any>(url).pipe(
      tap(_ => this.log(`fetched PermissionsChannelGroup id=${id}`)),
      catchError(this.handleError<any>(`getPermissionsChannelGroup id=${id}`))
    );
  }

  /** POST: add a new PermissionChannelGroup to the server */
  addPermissionChannelGroup(permissionChannelGroup): Observable<PermissionChannelGroup> {
    return this.http.post<PermissionChannelGroup>(this.base_url + '/', permissionChannelGroup, {headers: this.headers}).pipe(
      tap((permissionChannelGroup: PermissionChannelGroup) => this.log(`added permissionChannelGroup w/ id=${permissionChannelGroup.id}`)),
      catchError(this.handleError<PermissionChannelGroup>('permissionChannelGroup'))
    );
  }

  /** Patch: update the permissionChannelGroup on the server */
  updatePermissionChannel(data): Observable<any> {
    return this.http.put(`${this.base_url}/${data.id}`, data, {headers: this.headers}).pipe(
      tap(_ => this.log(`updated permissionChannelGroup id=${data.id}`)),
      catchError(this.handleError<any>('update permissionChannelGroup'))
    );
  }


  removePermissionChannel(data) {
    return this.http.delete(`${this.base_url}/${data.id}/${data.param}/${data.value}`, {headers: this.headers}).pipe(
      tap(_ => this.log(`delete permissionChannelGroup id=${data.id}`)),
      catchError(this.handleError<any>('delete permissionChannelGroup'))
    );
  }
  /** Patch: update the permissionChannelGroup on the server */
  editPermissionChannel(data): Observable<any> {
    return this.http.patch(`${this.base_url}/${data.id}/${data.param}/${data.value}`, {}, {headers: this.headers}).pipe(
      tap(_ => this.log(`updated permissionChannelGroup id=${data.id}`)),
      catchError(this.handleError<any>('update permissionChannelGroup'))
    );
  }


  /** Patch: update the permissionChannelGroup on the server */
  getPermissionChannelGroupByNameAndModuleNameAndPermissionChannelName(permissionChannelGroupName: String,
                                                                       moduleName: String,
                                                                       permissionChannelName: String): Observable<any> {
    return this.http.get(`${this.base_url}/name/${permissionChannelGroupName}/modulename/${moduleName}/permissionChannelname/${permissionChannelName}`, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched PCG`)),
      catchError(this.handleError<any>('fetched PCG'))
    );
  }

}
