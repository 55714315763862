import {Action} from '@ngrx/store';

export enum WorkOrderRepairActionTypes {
  workOrderRepairGetAll = '[Work Order Repair] Get All',
  workOrderRepairGetAllSuccess = '[Work Order Repair] Get All Success',
  workOrderRepairError = '[Work Order Repair] Error'
}

export class WorkOrderRepairGetAll implements Action {
  readonly type = WorkOrderRepairActionTypes.workOrderRepairGetAll;

  constructor() {

  }
}

export class WorkOrderRepairGetAllSuccess implements Action {
  readonly type = WorkOrderRepairActionTypes.workOrderRepairGetAllSuccess;

  constructor(public payload: any) {

  }
}

export class WorkOrderRepairError implements Action {
  readonly type = WorkOrderRepairActionTypes.workOrderRepairError;

  constructor(public payload: any) {

  }
}


export type WorkOrderRepairActions =
  | WorkOrderRepairGetAll
  | WorkOrderRepairGetAllSuccess
  | WorkOrderRepairError;


