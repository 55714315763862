import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getTenantTypeStore = createFeatureSelector('tenantType');

export const getTenantTypeEntities = createSelector(
  getTenantTypeStore,
  fromReducers.tenantType.tenantTypeEntitySelectors.selectAll
);

export const getTenantTypes = createSelector(getTenantTypeEntities, entities => {
  return Object.values(entities);
});

export const getTopTenantTypes = createSelector(getTenantTypeEntities, entities => {
  return Object.values(entities).slice(1, 5);
});

export const getTenantTypesLoaded = createSelector(
  getTenantTypeStore,
  (tenantTypeStore: fromReducers.tenantType.State) => tenantTypeStore.loaded
);

export const getTenantTypesLoading = createSelector(
  getTenantTypeStore,
  (tenantTypeStore: fromReducers.tenantType.State) => tenantTypeStore.loading
);

export const getSelectedTenantTypeId = createSelector(
  getTenantTypeStore,
  (tenantTypeStore: fromReducers.tenantType.State) => tenantTypeStore.selectedTenantTypeId
);

export const getSearchTenantTypes = createSelector(
  getTenantTypeStore,
  (tenantTypeStore: fromReducers.tenantType.State) => tenantTypeStore.searchTenantTypes
);

export const getTenantTypeById = createSelector(
  getTenantTypeEntities,
  getSelectedTenantTypeId,
  (entities, id) => entities.find(i => i.id === id)
);

export const getTenantTypesError = createSelector(
  getTenantTypeStore,
  (tenantTypeStore: fromReducers.tenantType.State) => tenantTypeStore.error
);
