import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "lead-smileId",
  template: ` <div>{{'Smile ID Product'}}</div>    <table style="background: #f7f7fc; border-radius: 3px">
  <thead>
    <tr>
      <th>{{ "PRODUCT NAME" | translate }}</th>
      <th>{{ "DESCRIPTION" | translate }}</th>
      <th>{{ "SMILE ID REQUIRED" | translate }}</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>
        <b>
          {{ leadSmileIdProduct?.productDisplayName || "--" }}
</b>
      </td>
      <td>{{ leadSmileIdProduct?.tenantProductDescription || "--" }}</td>
      <td>
      <b>
    {{ leadSmileIdProduct?.smileIdRequired === true ? 'Yes' : (leadSmileIdProduct?.smileIdRequired === false ? 'No' : '--') }}
  </b>
      </td>
    </tr>
  </tbody>
</table>`,
    encapsulation: ViewEncapsulation.None
})
export class leadSmileIdProductComponent implements OnInit {
  @Input() leadSmileIdProduct: any;
  
  constructor() {}

  ngOnInit() {}
}
