<div [hidden]="!loading" class="loading">{{'Loading' | translate}}&#8230;</div>
<div class="app_breadcrumb">
  <div class="app_header_left">
    <ul class="app_breadcrumb_item">
      <li>
        {{'Welcome to Atlas' | translate}} - {{tenant.name}}
      </li>
    </ul>
  </div>
</div>
<div class="app_setup_layout">
  <ul class="setup_tab_menu">
    <li class="nav-item" [ngClass]="{'active': activeTab === 0}" (click)="clickTab(0)">
      <a class="nav-link" id="one-tab" data-toggle="tab" href="javascript:void(0)">
        <span class="item-icon">
          <svg class="sr_heading_title_icon">
            <use xlink:href="#template_icon"></use>
          </svg>
        </span>
        <div class="item-title">
          <h4>{{'General Info' | translate}}</h4>
          <p>{{'Language, Currency & Timezone Information' | translate}}</p>
        </div>
      </a>
    </li>
    <li class="nav-item" [ngClass]="{'show': activeTab === 1, 'active': activeTab === 1}" (click)="clickTab(1)">
      <a class="nav-link" id="two-tab" data-toggle="tab" href="javascript:void(0)">
        <span class="item-icon">
          <svg class="sr_heading_title_icon">
            <use xlink:href="#register_icon"></use>
          </svg>
        </span>
        <div class="item-title">
          <h4>{{'Business Info' | translate}}</h4>
          <p>{{'Update Tenancy Information' | translate}}</p>
        </div>
      </a>
    </li>
  </ul>

  <div class="tab-content">
    <div class="tab_pane fade" [ngClass]="{'show': activeTab === 0, 'active': activeTab === 0}">
      <div class="form_header">
        {{'Currency, Language & Timezone Information' | translate}}

      </div>
      <form>
        <div class="form_group">
          <div class="input">
            <label>{{'Currency' | translate}} *</label>
            <select [(ngModel)]="tenant.currencyId" name="currencyId">
              <option [ngValue]="currency.id" *ngFor="let currency of currencies">{{currency.currencyName}}</option>
            </select>
          </div>
        </div>
        <div class="form_group">
          <div class="input">
            <label>{{'Default Language' | translate}} *</label>
            <select [(ngModel)]="tenant.languageId" name="languageId">
              <option [ngValue]="language.id" *ngFor="let language of languages">{{language.languageName}}</option>
            </select>
          </div>
        </div>
        <div class="form_group">
          <div class="input" *ngIf="timezones$ | async as timezones">
            <label>{{'UTC Hour Offset' | translate}} *</label>
            <select [(ngModel)]="tenant.timeZoneId" name="timeZoneId">
              <option [ngValue]="timezone.id" *ngFor="let timezone of timezones">{{timezone.timeZoneUtcOffset}}</option>
            </select>
          </div>
        </div>
        <div class="input">
          <input class="button_secondary" (click)="addInfo();" type="submit" value="Next">
        </div>
      </form>
    </div>

    <div class="tab_pane fade" [ngClass]="{'show': activeTab === 1, 'active': activeTab === 1}">
      <div class="form_header">
        {{'Tenant Information' | translate}}

      </div>
      <form>
        <div class="form_group">
          <div class="input">
            <label>{{'Primary Phone Number' | translate}}</label>
            <input type="text" name="telephone1" placeholder="+255-022-123-456" mask="{{pattern}}"
                   prefix="{{prefix}} " [showMaskTyped]="true" [(ngModel)]="tenant.telephone1"
                   name="telephone1">
            <div class="notification error" [hidden]="formValid.telephone1">
              {{'Primary Phone Number is Invalid' | translate}}
            </div>
          </div>
          <div class="input">
            <label>{{'Alternative Phone Number' | translate}}</label>
            <input type="text" name="telephone2" placeholder="+255-022-123-456" mask="{{pattern}}"
                   prefix="{{prefix}} " [showMaskTyped]="true" [(ngModel)]="tenant.telephone2">
          </div>
        </div>
        <div class="form_group">
          <div class="input">
            <label>{{'Primary Email' | translate}}</label>
            <input type="email" [(ngModel)]="tenant.email1" name="email1" placeholder="info@company">
            <div class="notification error" [hidden]="formValid.email1">
              {{'Email is Invalid' | translate}}
            </div>
          </div>
          <div class="input">
            <label>{{'Secondary Email' | translate}}</label>
            <input type="email" [(ngModel)]="tenant.email2" name="email2" placeholder="info2@company">
          </div>
        </div>
        <div class="form_group">
          <div class="input">
            <label>{{'Postal Address' | translate}}</label>
            <textarea [(ngModel)]="tenant.postalAddress" name="postalAddress" placeholder="12345"></textarea>
            <div class="notification error" [hidden]="formValid.postalAddress">
              {{'Postal Address is Invalid' | translate}}
            </div>
          </div>
        </div>
        <div class="form_group">
          <div class="input">
            <label>{{'Physical Address' | translate}}</label>
            <textarea [(ngModel)]="tenant.physicalAddress" name="physicalAddress"
                      placeholder="2nd Floor, Unit B Business Park Ngong Road"></textarea>
            <div class="notification error" [hidden]="formValid.physicalAddress">
              {{'Physical Address is Invalid' | translate}}
            </div>
          </div>
        </div>
        <div class="input">
          <input class="button_secondary" (click)="updateTenant();" type="submit" value="Save & Activate">
        </div>
      </form>
    </div>
  </div>
</div>
