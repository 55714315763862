import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';

import { EligibilityDefinition } from '@appModels/core-accounting/eligibility-definition/eligibility-definition';

import {CoreAccountingService} from '../core-accounting.service';


@Injectable({ providedIn: 'root' })
export class EligibilityDefinitionService extends CoreAccountingService {


  constructor(
    private http: HttpClient,

  ) {
    super(http, 'contracteligibilitydefinition');
  }

  /** GET eligibilityDefinitions from the server */
  // getEligibilityDefinitions(query?): Observable<EligibilityDefinition[]> {
  //   let params = new HttpParams();
  //   if (query !== undefined){
  //     // {page:1, size:10, sort: '' }
  //     Object.keys(query).forEach(val => {
  //       params = params.append(val, query[val]);
  //     });
  //   }
  //   return this.http.get<any>(this.base_url + '/', { headers: this.headers }).pipe(
  //     tap(eligibilityDefinitions => this.log(`fetched eligibilityDefinitions`)),
  //     catchError(this.handleError('getEligibilityDefinitions', []))
  //   );
  // }

  getEligibilityDefinitions(query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        params = params.append(val, query[val]);
      });
    }
    return this.http.get<any>(this.base_url + '/tenant/' + localStorage.getItem('tenant'), {
      params,
      headers: this.headers
    }).pipe(
      tap(eligibilityDefinitions => this.log(`fetched eligibilityDefinitions`)),
      catchError(this.handleError('getEligibilityDefinitions', []))
    );
  }

  /** GET eligibilityDefinition by id. Return `undefined` when id not found */
  getEligibilityDefinitionNo404<Data>(id: number): Observable<EligibilityDefinition> {
    const url = `${this.base_url}/?id=${id}`;
    return this.http.get<EligibilityDefinition[]>(url).pipe(
      map(eligibilityDefinitions => eligibilityDefinitions[0]), // returns a {0|1} element array
      tap(h => {
        const outcome = h ? `fetched` : `did not find`;
        this.log(`${outcome} eligibilityDefinition id=${id}`);
      }),
      catchError(this.handleError<EligibilityDefinition>(`getEligibilityDefinition id=${id}`))
    );
  }

  /** GET eligibilityDefinition by id. Will 404 if id not found */
  getEligibilityDefinition(id: number): Observable<EligibilityDefinition> {
    const url = `${this.base_url}/${id}/`;
    return this.http.get<EligibilityDefinition>(url,{ headers: this.headers }).pipe(
      tap(_ => this.log(`fetched eligibilityDefinition id=${id}`)),
      catchError(this.handleError<EligibilityDefinition>(`getEligibilityDefinition id=${id}`))
    );
  }

  /* GET eligibilityDefinitions whose name contains search term */
  searchEligibilityDefinitions(term: string): Observable<EligibilityDefinition[]> {
    if (!term.trim()) {
      // if not search term, return empty eligibilityDefinition array.
      return of([]);
    }
    return this.http.get<EligibilityDefinition[]>(`api/eligibilityDefinitions/?name=${term}`).pipe(
      tap(_ => this.log(`found eligibilityDefinitions matching "${term}"`)),
      catchError(this.handleError<EligibilityDefinition[]>('searchEligibilityDefinitions', []))
    );
  }

  //////// Save methods //////////

  /** POST: add a new eligibilityDefinition to the server */
  addEligibilityDefinition(eligibilityDefinition: EligibilityDefinition): Observable<EligibilityDefinition> {
    return this.http.post<EligibilityDefinition>(this.base_url + '/', eligibilityDefinition, { headers: this.headers }).pipe(
      tap((eligibilityDefinition: EligibilityDefinition) => this.log(`added eligibilityDefinition w/ id=${eligibilityDefinition.id}`)),
      catchError(this.handleError<EligibilityDefinition>('addEligibilityDefinition'))
    );
  }

  /** DELETE: delete the eligibilityDefinition from the server */
  deleteEligibilityDefinition(eligibilityDefinition: EligibilityDefinition | number): Observable<EligibilityDefinition> {
    const id = typeof eligibilityDefinition === 'number' ? eligibilityDefinition : eligibilityDefinition.id;
    const url = `${this.base_url}/${id}`;

    return this.http.delete<EligibilityDefinition>(url, { headers: this.headers }).pipe(
      tap(_ => this.log(`deleted eligibilityDefinition id=${id}`)),
      catchError(this.handleError<EligibilityDefinition>('deleteEligibilityDefinition'))
    );
  }

  /** PUT: update the eligibilityDefinition on the server */
  updateEligibilityDefinition(eligibilityDefinition: EligibilityDefinition): Observable<any> {
    return this.http.put(`${this.base_url}/${eligibilityDefinition.id}`, eligibilityDefinition, { headers: this.headers }).pipe(
      tap(_ => this.log(`updated eligibilityDefinition id=${eligibilityDefinition.id}`)),
      catchError(this.handleError<any>('updateEligibilityDefinition'))
    );
  }

  /** Patch: update the eligibilityDefinition on the server */
  editEligibilityDefinition(data): Observable<any> {
    return this.http.patch(`${this.base_url}/${data.id}/${data.param}/${data.value}`, {}, {headers: this.headers}).pipe(
      tap(_ => this.log(`edit eligibilityDefinition id=${data.id}`)),
      catchError(this.handleError<any>('editEligibilityDefinition'))
    );
  }

}
