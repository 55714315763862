import {Injectable} from '@angular/core';
import {CoreAftersalesService} from '@appServices/core-aftersales/core-aftersales.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WorkOrderRepairService extends CoreAftersalesService {

  constructor(private http: HttpClient) {
    super(http, 'workorderrepairs');
  }

  getWorkOrderRepairs(): Observable<any> {
    const url = `${this.base_url}`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched work order repairs}`)),
      catchError(this.handleError(`getWorkOrderRepairs`))
    );
  }
}
