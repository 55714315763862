import {ChangeDetectorRef, Component, EventEmitter, Input, Output, TemplateRef, ViewChild} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {CustomerService} from "@appServices/core-crm/customer/customer.service";
import {CoreTicketingCustomerService} from "@appServices/core-ticketing/customer/core-ticketing-customer.service";
import {TicketingService} from "@appServices/core-ticketing/customer/ticketing-service";
import {ToastrService} from "ngx-toastr";
import {TicketingFileService} from "@appServices/core-ticketing/file/ticketing-file-service";
import {Router} from "@angular/router";
import {TeamsService} from "@appServices/core-ticketing/teams-service";
import {TicketingStatusService} from "@appServices/core-ticketing/ticketing-status.service";
import {PermissionResolver} from "@appServices/permission-resolver/permission-resolver.service";
import {TicketCustomFieldService} from "@appServices/core-ticketing/ticket-custom-field-service";
import {Clipboard} from "@angular/cdk/clipboard";
import {RepairCentreService} from "@appServices/core-aftersales/repaircentre/repair-centre.service";
import {
  AftersaleFollowupDispositionService
} from "@appServices/core-aftersales/aftersale-followup-dispositions/aftersale-followup-dispositions.service";
import {TicketService} from "@appServices/core-aftersales/ticket/ticket.service";
import {Store} from "@ngrx/store";
import * as fromReducer from "@appStore/reducers";
import {ReturnTicket} from "@appModels/core-aftersales/return-ticket";
import TimeZone from "../../../../../util/timezone";
import {Page} from "@appModels/shared/page";
import {ReturnTicketService} from "@appServices/core-aftersales/return-ticket/return-ticket.service";
import {
  RepairCentreTechnicianService
} from "@appServices/core-aftersales/repaircentre/repair-centre-technician.service";

@Component({
  moduleId: module.id,
  selector: 'repossession-ticket-view',
  templateUrl: './repossession-ticket-view-directive.html',
  animations: [
    trigger('toggleAnimation', [
      transition(':enter', [style({ opacity: 0, transform: 'scale(0.95)' }), animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' }))]),
      transition(':leave', [animate('75ms', style({ opacity: 0, transform: 'scale(0.95)' }))]),
    ]),
  ],
  styleUrls: [ './repossession-ticket-view-directive.css', '../../../tailwind.css']
})
export class RepossessionTicketViewDirective {
  constructor(
    private store: Store<fromReducer.ticket.State>,
    private toastrService: ToastrService,
    private permissionResolver: PermissionResolver,
    private clipboard: Clipboard,
    private permissionResolverService: PermissionResolver,
    private ticketFileService: TicketingFileService,
    private ref: ChangeDetectorRef,
    private modalService: BsModalService,
    private customerService: CustomerService,
    private router: Router,
    private ticketCustomFieldService: TicketCustomFieldService,
    private aftersaleFollowupDispositionService: AftersaleFollowupDispositionService,
    private ticketingService: TicketingService,
    private coreTicketingCustomerService: CoreTicketingCustomerService,
    private ticketStatusService: TicketingStatusService,
    private teamsService: TeamsService,
    private repairCentreService: RepairCentreService,
    private ticketService: TicketService,
    private returnTicketService: ReturnTicketService,
    private repairCentreTechnicianService: RepairCentreTechnicianService,
    private repairCenterService: RepairCentreService
  ) {
    this.getProductStates();
  }

  getProductStates() {
    this.returnTicketService.getProductStates().subscribe(
      data => {
        this.productStates = data;
      }
    )
  }

  actionTemplateReference: BsModalRef;
  @ViewChild('actionTemplate') public actionTemplate;

  salvageModalReference: BsModalRef;
  @ViewChild('salvageModal') public salvageModal;

  mainLoading = true;
  loadingModal = false;

  returnTicket: ReturnTicket = {
    tenantId: null,
    stockingPointId: null,
    contractProductId: null,
    serial: null,
    customerId: null,
    repairCentreId: null,
    notes: null,
    reasonForReturn: null,
    repossession: false,
    items: [],
    id: null,
    surrenderForm: null
  };

  @Output() closeEvent = new EventEmitter<any>();

  @Input() set setReturnTicket(returnTicket:any) {
    this.returnTicket = returnTicket;
    this.mainLoading = false;
  }

  formatDate = TimeZone.getFormatedTimezoneFromDate;

  productStates = [];
  country = localStorage.getItem('country');
  loading: boolean;
  returnTickets = new Page();
  returnTicketContent: any[];
  advancedSearch: boolean;
  products: any[];
  repairCenters: any;
  ticketStatuses: any;
  ticketItems = [];
  counties: any[];
  countyTag: any;
  l4RepairCenters: any[];
  selectedRepairCentre = null;
  image:any;

  modalRef: BsModalRef;

  getTicketItems(id, template) {
    this.returnTicketService.get(`${id}/items`).subscribe(
      data => {
        this.ticketItems = data;
        this.actionTemplateReference = this.modalService.show(template, {class: 'modal-xl'});
        this.loadingModal = false;
      }
    )
  }

  openAction(template: TemplateRef<any>) {
    this.loadingModal = true;
    this.getTicketItems(this.returnTicket.id, template);
  }

  closeAttachment(){
    this.image=null;
  }

  activeTab: Number = 1;
  openProductStatus: Number = 1;
  technicianId: number;
  technicians = []
  comment = null;
  ticketComments = [];
  selectedItem = null;

  openProduct(val) {
    this.openProductStatus = val;
  }

  openUpdateSalvageValueModal(template: TemplateRef<any>, item) {
    this.selectedItem = item;
    this.salvageModalReference = this.modalService.show(template, {class: 'modal-xl'});
  }

  approveTicket(id) {
    this.returnTicketService.approveTicket(id).subscribe(
      data => {
        this.toastrService.success('Approved successfully!', 'Successful')
      }
    )
  }

  rejectTicket(id) {
    this.returnTicketService.rejectTicket(id).subscribe(
      data => {
        this.toastrService.success('Rejected successfully!', 'Successful')
      }
    )
  }

  cancelTicket(id) {
    this.returnTicketService.cancelTicket(id).subscribe(
      data => {
        this.toastrService.success('Cancelled successfully!', 'Successful')
      }
    )
  }

  updateSalvageValue(id, salvageValue){
    this.returnTicketService.updateSalvageValue(id, salvageValue).subscribe(
      data => {
        this.toastrService.success('Updated successfully!', 'Successful')
      }
    )
  }

  moveToInProgress(id) {
    this.returnTicketService.updateTicketItems(id, this.ticketItems).subscribe(
      data => {
        if (data) {
          this.toastrService.success('Updated successfully!', 'Successful');
          this.actionTemplateReference.hide();
        }
      }
    );
  }

  closeTicket(id) {
    this.returnTicketService.closeTicket(id).subscribe(
      data => {
        if (data) {
          this.toastrService.success('Closed successfully!', 'Successful');
          this.actionTemplateReference.hide();
        }
      }
    )
  }

  canWarrantyAndService(action) {
    return this.permissionResolverService.setModuleName("Warranty And Service").can(action);
  }

  isClosed(ticket) {
    return ticket.statusName === 'Closed';
  }

  downloadAttachment(data) {
    this.returnTicketService.downloadFile(data.id).subscribe((d) => {
      const file = new Blob([d], {type: d.type});
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.image = reader.result;
      }
    });
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {class: 'modal-xl'});
    this.getRepairCenters();
    this.getRepairCenterTechnicians()
  }

  getRepairCenters() {
    this.repairCenterService.getRepairCenters(4).subscribe(
      data => {
        this.l4RepairCenters = data
      }
    )
  }

  getRepairCenterTechnicians() {
    this.repairCentreTechnicianService.getRepairCentreTechniciansForTenant().subscribe(
      data => {
        this.technicians = data
      }
    )
  }


  clickTab(index) {
    this.activeTab = index;
  }

  assignTechnician(id){
    this.returnTicketService.assignTechnician(id, this.technicianId).subscribe(
      data => {
        this.toastrService.success('Assigned successfully!', 'Successful')
      }
    )
  }

  assign(id) {
    if (this.selectedRepairCentre) {
      this.returnTicketService.assignTicket(id, this.selectedRepairCentre).subscribe(
        data => {
          if (data) {
            this.toastrService.success('Assigned successfully!', 'Successful')
          }
        }
      )
    } else {
      this.toastrService.error('Please choose repair centre');
    }
  }


}
