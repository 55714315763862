import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';

import {TenantProductWarranty} from '@appModels/core-setup/tenant-product-warranty/tenant-product-warranty';
import {
  TenantProductWarrantyActions,
  TenantProductWarrantyActionTypes
} from '@appStore/actions/core-masterdata/tenant-product-warranty/tenant-product-warranty';


export interface State extends EntityState<TenantProductWarranty> {
  loaded: boolean;
  loading: boolean;
  error: any;
  selectedTenantProductWarrantyId: number;
  searchTenantProductWarranties: TenantProductWarranty[];
}

export const adapter: EntityAdapter<TenantProductWarranty> = createEntityAdapter<TenantProductWarranty>();

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
  selectedTenantProductWarrantyId: null,
  error: null,
  searchTerm: '',
  searchTenantProductWarranties: null
});

export function reducer(state = initialState, action: TenantProductWarrantyActions): State {
  switch (action.type) {
    case TenantProductWarrantyActionTypes.tenantProductWarrantyGetTenantProductWarranties:
    case TenantProductWarrantyActionTypes.tenantProductWarrantyAddTenantProductWarranty:
    case TenantProductWarrantyActionTypes.tenantProductWarrantyAddTenantProductWarranties:
    case TenantProductWarrantyActionTypes.tenantProductWarrantyDeleteTenantProductWarranty:
    case TenantProductWarrantyActionTypes.tenantProductWarrantyUpdateTenantProductWarranty:
    case TenantProductWarrantyActionTypes.tenantProductWarrantySearchTenantProductWarranties:
    case TenantProductWarrantyActionTypes.tenantProductWarrantyGetTenantProductWarrantyById:
      return {
        ...state,
        loading: true
      };

    case TenantProductWarrantyActionTypes.tenantProductWarrantyGetTenantProductWarrantiesSuccess:
      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });

    case TenantProductWarrantyActionTypes.tenantProductWarrantyGetTenantProductWarrantyByIdSuccess:
      return {...state, selectedTenantProductWarrantyId: action.payload.id, loading: false};

    case TenantProductWarrantyActionTypes.tenantProductWarrantyAddTenantProductWarrantySuccess:
      return adapter.addOne(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });
    case TenantProductWarrantyActionTypes.tenantProductWarrantyAddTenantProductWarrantiesSuccess:
      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });
    case TenantProductWarrantyActionTypes.tenantProductWarrantyUpdateTenantProductWarrantySuccess: {
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state,
          loading: false,
          loaded: true
        }
      );
    }

    case TenantProductWarrantyActionTypes.tenantProductWarrantyDeleteTenantProductWarrantySuccess: {
      return adapter.removeOne(action.payload.id, {
        ...state,
        loading: false,
        loaded: true
      });
    }

    case TenantProductWarrantyActionTypes.tenantProductWarrantySearchTenantProductWarrantiesSuccess:
      return {
        ...state,
        searchTenantProductWarranties: action.payload,
        loading: false
      };

    case TenantProductWarrantyActionTypes.tenantProductWarrantySearchTenantProductWarrantiesReset:
      return {
        ...state,
        searchTenantProductWarranties: null
      };

    case TenantProductWarrantyActionTypes.tenantProductWarrantyError:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload
      };

    default:
      return state;
  }
}

export const tenantProductWarrantyEntitySelectors = adapter.getSelectors();
