import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {ToastrService} from "ngx-toastr";
import {catchError, map, mergeMap, tap} from "rxjs/operators";
import {of} from "rxjs";
import {
  AddShippingCompany,
  DeleteShippingCompany,
  DeleteShippingCompanySuccess,
  GetShippingCompanyById,
  GetShippingCompanyByIdSuccess, GetShippingCompanyByTenantId, GetShippingCompanyByTenantIdIdPagedSuccess,
  GetShippingCompanyByTenantIdIdSuccess, GetShippingCompanyByTenantIdPaged,
  ShippingCompanyActionTypes,
  ShippingCompanyAddTenant,
  ShippingCompanyAddTenantSuccess,
  ShippingCompanyError,
  UpdateShippingCompany,
  UpdateShippingCompanySuccess
} from "@appStore/actions/core-inventory/shipping-company/shipping-company.actions";
import {ShippingCompanyService} from "@appServices/core-inventory/shipping-company/shipping-company.service";
import {Router} from "@angular/router";


@Injectable()
export class ShippingCompanyEffects {

  
  loadTenantShippingCompanies$ = createEffect(() => this.actions$.pipe(
    ofType(ShippingCompanyActionTypes.shippingCompanyGetShippingCompaniesByTenantId),
    mergeMap((action: GetShippingCompanyByTenantId) =>
      this.shippingCompanyService
        .getTenantShippingCompany(action.payload)
        .pipe(
          map(shippingCompanies => new GetShippingCompanyByTenantIdIdSuccess(shippingCompanies)),
          catchError(error => of(new ShippingCompanyError(error)))
        )
    )
  ));

  
  loadPagedTenantShippingCompanies$ = createEffect(() => this.actions$.pipe(
    ofType(ShippingCompanyActionTypes.shippingCompanyGetShippingCompaniesByTenantIdPaged),
    mergeMap((action: GetShippingCompanyByTenantIdPaged) =>
      this.shippingCompanyService
        .getPagedTenantShippingCompany(action.payload.id, action.payload.query)
        .pipe(
          map(shippingCompanies => new GetShippingCompanyByTenantIdIdPagedSuccess(shippingCompanies)),
          catchError(error => of(new ShippingCompanyError(error)))
        )
    )
  ));


  
  addShippingCompanies$ = createEffect(() => this.actions$.pipe(
    ofType(ShippingCompanyActionTypes.shippingCompanyAddShippingCompany),
    mergeMap((action: AddShippingCompany) =>
      this.shippingCompanyService
        .addShippingCompany(action.payload)
        .pipe(
          map(shippingCompany => {
            this.toastr.success('Shipping company added successfully!', "Success");
            return new ShippingCompanyAddTenant(shippingCompany);
          }),
          catchError(error => of(new ShippingCompanyError(error)))
        )
    )
  ));


  
  addShippingTenantCompany$ = createEffect(() => this.actions$.pipe(
    ofType(ShippingCompanyActionTypes.shippingCompanyAddTenantShippingCompanies),
    mergeMap((action: ShippingCompanyAddTenant) =>
      this.shippingCompanyService
        .patchTenantShippingCompany(action.payload)
        .pipe(
          map(shippingCompany => {
            this.toastr.success('Shipping company added to tenant successfully!', "Success");
            return new ShippingCompanyAddTenantSuccess(shippingCompany);

          }),
          catchError(error => of(new ShippingCompanyError(error)))
        )
    )
  ));

  
  shippingCompanyAddTenantSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(ShippingCompanyActionTypes.shippingCompanyAddTenantShippingCompaniesSuccess),
    tap(() => this.router.navigate([`/inventory/shipping-companies`]))
  ), {dispatch: false});


  
  deleteShippingCompany$ = createEffect(() => this.actions$.pipe(
    ofType(ShippingCompanyActionTypes.shippingCompanyDeleteShippingCompany),
    mergeMap((action: DeleteShippingCompany) =>
      this.shippingCompanyService
        .deleteShippingCompany(action.payload)
        .pipe(
          map(res => {
            this.toastr.success('Shipping company deleted successfully!', "Success");
            return new DeleteShippingCompanySuccess(action.payload);

          }),
          catchError(error => of(new ShippingCompanyError(error)))
        )
    )
  ));


  
  getShippingCompanyById$ = createEffect(() => this.actions$.pipe(
    ofType(ShippingCompanyActionTypes.shippingCompanyGetShippingCompanyById),
    mergeMap((action: GetShippingCompanyById) =>
      this.shippingCompanyService
        .getShippingCompany(action.payload)
        .pipe(
          map(shippingCompany => {
            return new GetShippingCompanyByIdSuccess(shippingCompany);

          }),
          catchError(error => of(new ShippingCompanyError(error)))
        )
    )
  ));

  
  updateShippingCompany$ = createEffect(() => this.actions$.pipe(
    ofType(ShippingCompanyActionTypes.shippingCompanyUpdateShippingCompany),
    mergeMap((action: UpdateShippingCompany) =>
      this.shippingCompanyService
        .updateShippingCompany(action.payload)
        .pipe(
          map(shippingCompany => {
            this.toastr.success('Shipping company updated successfully!', "Success");
            return new UpdateShippingCompanySuccess(shippingCompany);
          }),
          catchError(error => of(new ShippingCompanyError(error)))
        )
    )
  ));

  
  consignmentDoNothing$ = createEffect(() => this.actions$.pipe(
    ofType(ShippingCompanyActionTypes.shippingCompanyUpdateShippingCompanySuccess),
    tap(() => this.router.navigateByUrl(`/inventory/shipping-companies`))
  ), {dispatch: false});

  constructor(
    private toastr: ToastrService,
    private actions$: Actions,
    private shippingCompanyService: ShippingCompanyService,
    private router: Router,

  ) {
  }


}
