import {Action} from '@ngrx/store';

import {Contract} from '@appModels/core-accounting/contract/contract';

export enum ContractActionTypes {
  contractGetContracts = '[Contract] get',
  contractGetContractsSuccess = '[Contract] get contracts success',
  contractGetContractPayments = '[Contract] get contract Payment',
  contractGetContractPaymentsSuccess = '[Contract] get contract payment success',
  contractAddContract = '[Contract] add Contract',
  contractAddContractSuccess = '[Contract] add contract success',
  contractAddAdditionalContractInfo = '[Contract] add additional Contract Info',
  contractAddAdditionalContractInfoSuccess = '[Contract] add additional contract Info success',
  contractDeleteContract = '[Contract] delete contract',
  contractDeleteContractSuccess = '[Contract] delete contract success',
  contractGetContractById = '[Contract] get contract by id',
  contractGetContractByIdSuccess = '[Contract] get contract by id success',
  contractUpdateContract = '[Contract] update contract',
  contractUpdateContractSuccess = '[Contract] update contract success',
  contractSearchContracts = '[Contract] search contracts',
  contractSearchContractsSuccess = '[Contract] search contracts success',
  contractSearchContractsReset = '[Contract] search contracts reset',
  contractError = '[Contract] error'
}

export class GetContracts implements Action {
  readonly type = ContractActionTypes.contractGetContracts;
}

export class GetContractsSuccess implements Action {
  readonly type = ContractActionTypes.contractGetContractsSuccess;
  constructor(public payload: Contract[]) {}
}

export class GetContractPayments implements Action {
  readonly type = ContractActionTypes.contractGetContractPayments;

  constructor(public payload) {
  }
}

export class GetContractPaymentsSuccess implements Action {
  readonly type = ContractActionTypes.contractGetContractPaymentsSuccess;

  constructor(public payload: []) {
  }
}

export class AddContract implements Action {
  readonly type = ContractActionTypes.contractAddContract;
  constructor(public payload) {}
}

export class AddContractSuccess implements Action {
  readonly type = ContractActionTypes.contractAddContractSuccess;
  constructor(public payload: Contract) {}
}

export class AddAdditionalContractInfo implements Action {
  readonly type = ContractActionTypes.contractAddAdditionalContractInfo;
  constructor(public payload) {}
}

export class AddAdditionalContractInfoSuccess implements Action {
  readonly type = ContractActionTypes.contractAddAdditionalContractInfoSuccess;
  constructor(public payload) {}
}

export class GetContractById implements Action {
  readonly type = ContractActionTypes.contractGetContractById;
  constructor(public payload: number) {}
}

export class GetContractByIdSuccess implements Action {
  readonly type = ContractActionTypes.contractGetContractByIdSuccess;
  constructor(public payload: Contract) {}
}

export class UpdateContract implements Action {
  readonly type = ContractActionTypes.contractUpdateContract;
  constructor(public payload: Contract) {}
}

export class UpdateContractSuccess implements Action {
  readonly type = ContractActionTypes.contractUpdateContractSuccess;
  constructor(public payload: Contract) {}
}

export class DeleteContract implements Action {
  readonly type = ContractActionTypes.contractDeleteContract;
  constructor(public payload: Contract) {}
}

export class DeleteContractSuccess implements Action {
  readonly type = ContractActionTypes.contractDeleteContractSuccess;
  constructor(public payload: Contract) {}
}

export class SearchContracts implements Action {
  readonly type = ContractActionTypes.contractSearchContracts;
  constructor(public payload: string) {}
}

export class SearchContractsSuccess implements Action {
  readonly type = ContractActionTypes.contractSearchContractsSuccess;
  constructor(public payload: Contract[]) {}
}

export class SearchContractsReset implements Action {
  readonly type = ContractActionTypes.contractSearchContractsReset;
}

export class ContractError implements Action {
  readonly type = ContractActionTypes.contractError;
  constructor(public payload: any) {}
}

export type ContractActions =
  | GetContracts
  | GetContractsSuccess
  | GetContractPayments
  | GetContractPaymentsSuccess
  | AddContract
  | AddContractSuccess
  | AddAdditionalContractInfo
  | AddAdditionalContractInfoSuccess
  | GetContractById
  | GetContractByIdSuccess
  | UpdateContract
  | UpdateContractSuccess
  | DeleteContract
  | DeleteContractSuccess
  | SearchContracts
  | SearchContractsSuccess
  | SearchContractsReset
  | ContractError;
