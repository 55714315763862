import {ChangeDetectorRef, Component, TemplateRef, ViewChild} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import {ToastrService} from "ngx-toastr";
import * as moment from "moment/moment";
import {BasePojo} from "@appModels/ticketing/base-pojo";
import {TicketingStatusService} from "@appServices/core-ticketing/ticketing-status.service";

@Component({
  moduleId: module.id,
  templateUrl: './ticket-status-table.component.html',
  animations: [
    trigger('toggleAnimation', [
      transition(':enter', [style({ opacity: 0, transform: 'scale(0.95)' }), animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' }))]),
      transition(':leave', [animate('75ms', style({ opacity: 0, transform: 'scale(0.95)' }))]),
    ]),
  ],
  styleUrls: ['./ticket-status-table.component.css', '../../../tailwind.css']
})
export class TicketStatusTableComponent {
  constructor(
    private toastrService: ToastrService,
    private ref: ChangeDetectorRef,
    private ticketStatusService: TicketingStatusService
  ) {
    this.getTicketStatuses();
  }

  loadingTable = true;
  dateFormat = moment().format('YYYY-MM-DD hh:mm:ss');
  moment: any = moment;

  getTicketStatuses() {
    this.ticketStatusService.getStatuses().subscribe(x=> {
      this.ticketStatuses = x;
      this.loadingTable = false;
    })
  }

  ticketStatuses : BasePojo[] = [];

}
