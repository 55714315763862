import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';

import {CoreInventoryService} from '../core-inventory.service';
import {ShippingCompany} from "@appModels/core-inventory/shipping-company/shipping-company";


@Injectable({providedIn: 'root'})
export class ShippingCompanyService extends CoreInventoryService {


  constructor(
    private http: HttpClient,
  ) {
    super(http, '');
  }


  /** GET shipping company by id. Will 404 if id not found */
  getTenantShippingCompany(id: number, query?): Observable<ShippingCompany[]> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        if (query[val] !== null && query[val] !== undefined && query[val] !== '') {
          params = params.append(val, query[val]);
        }
      });
    }

    const url = `${this.base_url}shippingcompanies/tenant/${id}`;
    return this.http.get<ShippingCompany[]>(url, {headers: this.headers}).pipe(
      tap(shippingCompanies => this.log(`fetched shippingCompanies`)),
      catchError(this.handleError('getShippingCompanies', []))
    );
  }


  /** GET shipping company by tenant id paged. Will 404 if id not found */
  getPagedTenantShippingCompany(id: number, query?): Observable<ShippingCompany[]> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        if (query[val] !== null && query[val] !== undefined && query[val] !== '') {
          params = params.append(val, query[val]);
        }
      });
    }

    const url = `${this.base_url}shippingcompaniespaged/tenant/${id}`;
    return this.http.get<ShippingCompany[]>(url, {headers: this.headers, params: params}).pipe(
      tap(shippingCompanies => this.log(`fetched shippingCompanies`)),
      catchError(this.handleError('getShippingCompanies', []))
    );
  }


  /** Add shipping company by id. Will 404 if id not found */
  addShippingCompany(shippingCompany: ShippingCompany): Observable<ShippingCompany> {
    const url = `${this.base_url}shippingcompany`;
    return this.http.post<ShippingCompany>(url, shippingCompany, {headers: this.headers}).pipe(
      tap((shippingCompany: ShippingCompany) => this.log(`added ShippingCompany w/ id=${shippingCompany.id}`)),
      catchError(this.handleError<ShippingCompany>('addShippingCompany'))
    );
  }


  /** POST: patch a shipping company to the server */
  patchTenantShippingCompany(shippingCompany: ShippingCompany): Observable<any> {
    const tenantId = localStorage.getItem('tenant');
    return this.http.patch(this.base_url + 'shippingcompany/' + shippingCompany.id + '/tenant/' + tenantId, "", {headers: this.headers}).pipe(
      tap((res) => this.log(`patch shippingCompany w/ tenantId=${shippingCompany.id}`)),
      catchError(this.handleError<ShippingCompany>('shippingCompany'))
    );
  }

  deleteShippingCompany(shippingCompany: ShippingCompany): Observable<any> {
    return this.http.delete(this.base_url + 'shippingcompany/' + shippingCompany.id, {headers: this.headers}).pipe(
      tap((res) => this.log(`patch shippingCompany w/ shippingcompany=${shippingCompany.id}`)),
      catchError(this.handleError<ShippingCompany>('shippingCompany'))
    );
  }

  getShippingCompany(id: number): Observable<ShippingCompany> {
    return this.http.get<ShippingCompany>(this.base_url + 'shippingcompany/' + id, {headers: this.headers}).pipe(
      tap((res) => this.log(`get shippingCompany w/ shippingcompany=${id}`)),
      catchError(this.handleError<ShippingCompany>('shippingCompany'))
    );
  }

  updateShippingCompany(shippingCompany: ShippingCompany): Observable<ShippingCompany> {
    return this.http.put<ShippingCompany>(this.base_url + 'shippingcompany/' + shippingCompany.id, shippingCompany, {headers: this.headers}).pipe(
      tap((res) => this.log(`updated shippingCompany w/ shippingcompany=${shippingCompany.id}`)),
      catchError(this.handleError<ShippingCompany>('shippingCompany'))
    );
  }

  /** GET shipping company by id. Will 404 if id not found */
  findByTelephone1OrNameOrEmail1OrPostalAddress(nameTelephone1Email1PostalAddress: string): Observable<ShippingCompany[]> {
    const url = `${this.base_url}shippingcompanies/findByNameTelephone1Email1PostalAddress/${nameTelephone1Email1PostalAddress}`;
    return this.http.get<ShippingCompany[]>(url, {headers: this.headers}).pipe(
      tap(shippingCompanies => this.log(`fetched shippingCompanies`)),
      catchError(this.handleError('getShippingCompanies', []))
    );
  }

}
