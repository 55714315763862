import {Action} from '@ngrx/store';
import {FaultWarrantyDisposition} from '../../../../models/core-setup/fault-action-setup/fault-warranty-disposition';

export enum FaultWarrantyDispositionActionTypes {
  faultWarrantyDispositionGetAll = '[Fault Warranty Disposition] Get All',
  faultWarrantyDispositionGetAllSuccess = '[Fault Warranty Disposition] Get All Success',
  faultWarrantyDispositionError = '[Fault Warranty Disposition] Error',
}

export class GetAllFaultWarrantyDispositions implements Action {
  readonly type = FaultWarrantyDispositionActionTypes.faultWarrantyDispositionGetAll;

  constructor() {
  }
}

export class GetAllFaultWarrantyDispositionsSuccess implements Action {
  readonly type = FaultWarrantyDispositionActionTypes.faultWarrantyDispositionGetAllSuccess;

  constructor(public payload: FaultWarrantyDisposition[]) {
  }
}

export class FaultWarrantyDispositionError implements Action {
  readonly type = FaultWarrantyDispositionActionTypes.faultWarrantyDispositionError;

  constructor(public payload: any) {
  }
}


export type FaultWarrantyDispositionActions =
  | GetAllFaultWarrantyDispositions
  | GetAllFaultWarrantyDispositionsSuccess
  | FaultWarrantyDispositionError;




