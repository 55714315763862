import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getIdentificationTypeStore = createFeatureSelector('identificationType');

export const getIdentificationTypeEntities = createSelector(
  getIdentificationTypeStore,
  fromReducers.identificationType.identificationTypeEntitySelectors.selectAll
);

export const getIdentificationTypes = createSelector(getIdentificationTypeEntities, entities => {
  return Object.values(entities);
});

export const getTopIdentificationTypes = createSelector(getIdentificationTypeEntities, entities => {
  return Object.values(entities).slice(1, 5);
});

export const getIdentificationPaging = createSelector(
  getIdentificationTypeStore,
  (identificationTypeStore: fromReducers.identificationType.State) => identificationTypeStore.paging
);

export const getIdentificationTypesLoaded = createSelector(
  getIdentificationTypeStore,
  (identificationTypeStore: fromReducers.identificationType.State) => identificationTypeStore.loaded
);

export const getIdentificationTypesLoading = createSelector(
  getIdentificationTypeStore,
  (identificationTypeStore: fromReducers.identificationType.State) => identificationTypeStore.loading
);

export const getSelectedIdentificationTypeId = createSelector(
  getIdentificationTypeStore,
  (identificationTypeStore: fromReducers.identificationType.State) => identificationTypeStore.selectedIdentificationTypeId
);

export const getSearchIdentificationTypes = createSelector(
  getIdentificationTypeStore,
  (identificationTypeStore: fromReducers.identificationType.State) => identificationTypeStore.searchIdentificationTypes
);

export const getIdentificationTypeById = createSelector(
  getIdentificationTypeEntities,
  getSelectedIdentificationTypeId,
  (entities, id) => entities.find(i => i.id === id)
);

export const getIdentificationTypesError = createSelector(
  getIdentificationTypeStore,
  (identificationTypeStore: fromReducers.identificationType.State) => identificationTypeStore.error
);
