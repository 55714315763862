import { Action } from '@ngrx/store';

import { SmsTemplate } from '@appModels/core-setup/sms-template/sms-template';

export enum SmsTemplateActionTypes {
  smsTemplateGetSmsTemplates = '[SmsTemplate] get',
  smsTemplateGetSmsTemplatesSuccess = '[SmsTemplate] get smsTemplates success',
  smsTemplateAddSmsTemplate = '[SmsTemplate] add SmsTemplate',
  smsTemplateAddSmsTemplateSuccess = '[SmsTemplate] add smsTemplate success',
  smsTemplateDeleteSmsTemplate = '[SmsTemplate] delete smsTemplate',
  smsTemplateDeleteSmsTemplateSuccess = '[SmsTemplate] delete smsTemplate success',
  smsTemplateGetSmsTemplateById = '[SmsTemplate] get smsTemplate by id',
  smsTemplateGetSmsTemplateByIdSuccess = '[SmsTemplate] get smsTemplate by id success',
  smsTemplateUpdateSmsTemplate = '[SmsTemplate] update smsTemplate',
  smsTemplateUpdateSmsTemplateSuccess = '[SmsTemplate] update smsTemplate success',
  smsTemplateSearchSmsTemplates = '[SmsTemplate] search smsTemplates',
  smsTemplateSearchSmsTemplatesSuccess = '[SmsTemplate] search smsTemplates success',
  smsTemplateSearchSmsTemplatesReset = '[SmsTemplate] search smsTemplates reset',
  smsTemplateError = '[SmsTemplate] error'
}

export class GetSmsTemplates implements Action {
  readonly type = SmsTemplateActionTypes.smsTemplateGetSmsTemplates;
  constructor(public payload?) {}
}

export class GetSmsTemplatesSuccess implements Action {
  readonly type = SmsTemplateActionTypes.smsTemplateGetSmsTemplatesSuccess;
  constructor(public payload) {}
}

export class AddSmsTemplate implements Action {
  readonly type = SmsTemplateActionTypes.smsTemplateAddSmsTemplate;
  constructor(public payload: SmsTemplate) {}
}

export class AddSmsTemplateSuccess implements Action {
  readonly type = SmsTemplateActionTypes.smsTemplateAddSmsTemplateSuccess;
  constructor(public payload: SmsTemplate) {}
}

export class GetSmsTemplateById implements Action {
  readonly type = SmsTemplateActionTypes.smsTemplateGetSmsTemplateById;
  constructor(public payload: number) {}
}

export class GetSmsTemplateByIdSuccess implements Action {
  readonly type = SmsTemplateActionTypes.smsTemplateGetSmsTemplateByIdSuccess;
  constructor(public payload: SmsTemplate) {}
}

export class UpdateSmsTemplate implements Action {
  readonly type = SmsTemplateActionTypes.smsTemplateUpdateSmsTemplate;
  constructor(public payload: SmsTemplate) {}
}

export class UpdateSmsTemplateSuccess implements Action {
  readonly type = SmsTemplateActionTypes.smsTemplateUpdateSmsTemplateSuccess;
  constructor(public payload: SmsTemplate) {}
}

export class DeleteSmsTemplate implements Action {
  readonly type = SmsTemplateActionTypes.smsTemplateDeleteSmsTemplate;
  constructor(public payload: SmsTemplate) {}
}

export class DeleteSmsTemplateSuccess implements Action {
  readonly type = SmsTemplateActionTypes.smsTemplateDeleteSmsTemplateSuccess;
  constructor(public payload: SmsTemplate) {}
}

export class SearchSmsTemplates implements Action {
  readonly type = SmsTemplateActionTypes.smsTemplateSearchSmsTemplates;
  constructor(public payload: string) {}
}

export class SearchSmsTemplatesSuccess implements Action {
  readonly type = SmsTemplateActionTypes.smsTemplateSearchSmsTemplatesSuccess;
  constructor(public payload: SmsTemplate[]) {}
}

export class SearchSmsTemplatesReset implements Action {
  readonly type = SmsTemplateActionTypes.smsTemplateSearchSmsTemplatesReset;
}

export class SmsTemplateError implements Action {
  readonly type = SmsTemplateActionTypes.smsTemplateError;
  constructor(public payload: any) {}
}

export type SmsTemplateActions =
  | GetSmsTemplates
  | GetSmsTemplatesSuccess
  | AddSmsTemplate
  | AddSmsTemplateSuccess
  | GetSmsTemplateById
  | GetSmsTemplateByIdSuccess
  | UpdateSmsTemplate
  | UpdateSmsTemplateSuccess
  | DeleteSmsTemplate
  | DeleteSmsTemplateSuccess
  | SearchSmsTemplates
  | SearchSmsTemplatesSuccess
  | SearchSmsTemplatesReset
  | SmsTemplateError;
