import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { CustomerStatus } from '@appModels/core-crm/customer-status/customer-status';

import { CoreCrmService } from '../core-crm.service';
import { __convertCallStatus__ } from 'app/util/format-name';



@Injectable({ providedIn: 'root' })
export class CustomerStatusService extends CoreCrmService {


  constructor(
    private http: HttpClient,

  ) {
    super(http, 'customerstatus');
  }

  /** GET customerStatuses from the server */
  getCustomerStatuses(): Observable<CustomerStatus[]> {
    const tenantId = localStorage.getItem('tenant');
    const url = `${this.base_url}/tenant/${tenantId}`;
    return this.http.get<CustomerStatus[]>(url , { headers: this.headers }).pipe(
      tap(customerStatuses => this.log(`fetched customerStatuses`)),
      catchError(this.handleError('getCustomerStatuses', []))
    );
  }

  /** GET customerStatus by id. Return `undefined` when id not found */
  getCustomerStatusNo404<Data>(id: number): Observable<CustomerStatus> {
    const url = `${this.base_url}/?id=${id}`;
    return this.http.get<CustomerStatus[]>(url).pipe(
      map(customerStatuses => customerStatuses[0]), // returns a {0|1} element array
      tap(h => {
        const outcome = h ? `fetched` : `did not find`;
        this.log(`${outcome} customerStatus id=${id}`);
      }),
      catchError(this.handleError<CustomerStatus>(`getCustomerStatus id=${id}`))
    );
  }

  /** GET customerStatus by id. Will 404 if id not found */
  getCustomerStatus(id: number): Observable<CustomerStatus> {
    const url = `${this.base_url}/${id}`;
    return this.http.get<CustomerStatus>(url,{ headers: this.headers }).pipe(
      tap(_ => this.log(`fetched customerStatus id=${id}`)),
      catchError(this.handleError<CustomerStatus>(`getCustomerStatus id=${id}`))
    );
  }

  /* GET customerStatuses whose name contains search term */
  searchCustomerStatuses(term: string): Observable<CustomerStatus[]> {
    if (!term.trim()) {
      // if not search term, return empty customerStatus array.
      return of([]);
    }
    return this.http.get<CustomerStatus[]>(this.base_url + `/search/${term}`,{ headers: this.headers }).pipe(
      tap(_ => this.log(`found customerStatuses matching "${term}"`)),
      catchError(this.handleError<CustomerStatus[]>('searchCustomerStatuses', []))
    );
  }

  //////// Save methods //////////

  /** POST: add a new customerStatus to the server */
  addCustomerStatus(customerStatus: CustomerStatus): Observable<CustomerStatus> {
    return this.http.post<CustomerStatus>(this.base_url , customerStatus, { headers: this.headers }).pipe(
      tap((customerStatus: CustomerStatus) => this.log(`added customerStatus w/ id=${customerStatus.id}`)),
      catchError(this.handleError<CustomerStatus>('addCustomerStatus'))
    );
  }

  /** POST: add a new customerStatus to the server */
  addCustomerStatusAdditionalInfo(customerStatus): Observable<{}> {
    return this.http.post(`${this.base_url}/additionalInfo/${customerStatus.id}`, customerStatus.value, { headers: this.headers }).pipe(
      tap((res) => this.log(`added customerStatus  info `)),
      catchError(this.handleError('addCustomerStatus'))
    );
  }

  /** DELETE: delete the customerStatus from the server */
  deleteCustomerStatus(customerStatus: CustomerStatus | number): Observable<CustomerStatus> {
    const id = typeof customerStatus === 'number' ? customerStatus : customerStatus.id;
    const url = `${this.base_url}/${id}`;

    return this.http.delete<CustomerStatus>(url, { headers: this.headers }).pipe(
      tap(_ => this.log(`deleted customerStatus id=${id}`)),
      catchError(this.handleError<CustomerStatus>('deleteCustomerStatus'))
    );
  }

  /** PUT: update the customerStatus on the server */
  updateCustomerStatus(customerStatus: CustomerStatus): Observable<any> {
    return this.http.put(`${this.base_url}/${customerStatus.id}`, customerStatus, { headers: this.headers }).pipe(
      tap(_ => this.log(`updated customerStatus id=${customerStatus.id}`)),
      catchError(this.handleError<any>('updateCustomerStatus'))
    );
  }

  transformCustomerStatusForSurvey()
  {
    return this.getCustomerStatuses().pipe(
      map(response => response.map((item: any) => __convertCallStatus__(item))),
      catchError(error => {
        this.log(`Network error occurred: ${error.message}`);
        return of([]); 
      })
    );
  }

}
