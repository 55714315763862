import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getRepairCentreStore = createFeatureSelector('repairCentre');

export const getRepairCentreEntities = createSelector(
  getRepairCentreStore,
  fromReducers.repairCentre.repairCentreEntitySelectors.selectAll
);

export const getRepairCentrePaging = createSelector(
  getRepairCentreStore,
  (repairCentreStore: fromReducers.repairCentre.State) => repairCentreStore.paging
);

export const getRepairCentres = createSelector(getRepairCentreEntities, entities => {
  return Object.values(entities);
});

export const getRepairCentresLoading = createSelector(
  getRepairCentreStore,
  (repairCentreStore: fromReducers.repairCentre.State) => repairCentreStore.loading
);

export const getRepairCentreSelectedId = createSelector(
  getRepairCentreStore,
  (repairCentreStore: fromReducers.repairCentre.State) => repairCentreStore.selectedRepairCentreId
);


export const getRepairCentreById = createSelector(
  getRepairCentreEntities,
  getRepairCentreSelectedId,
  (entities, id) => entities.find(i => i.id === id)
);

export const getUserRepairCentres = createSelector(
  getRepairCentreStore,
  (repairCentreStore: fromReducers.repairCentre.State) => repairCentreStore.usersRepairCentres
);
