import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, mergeMap} from "rxjs/operators";
import {of} from "rxjs";
import {RepaircentrestatusService} from '../../../../services/core-aftersales/repaircentre/repaircentrestatus.service';
import {
  GetAllRepairCentreStatus,
  GetAllRepairCentreStatusSuccess,
  RepairCentreStateActionTypes,
  RepairCentreStateError
} from '../../../actions/core-aftersales/repaircentres/repaircentrestate.actions';

@Injectable()
export class RepairCentreStateEffects {

  
  repairCentreStates = createEffect(() => this.actions$.pipe(
    ofType(RepairCentreStateActionTypes.repairCentreStatusGetAll),
    mergeMap((action: GetAllRepairCentreStatus) =>
      this.repairCentreStatusService
        .getRepairCentreStates()
        .pipe(
          map(repairStates => new GetAllRepairCentreStatusSuccess(repairStates)),
          catchError(error => of(new RepairCentreStateError(error)))
        )
    )
  ));

  constructor(
    private actions$: Actions,
    private repairCentreStatusService: RepaircentrestatusService
  ) {
  }
}
