import {ChangeDetectorRef, Component} from "@angular/core";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {ToastrService} from "ngx-toastr";
import {DispositionsmapService} from "@appServices/core-ticketing/dispositionsmap-service";
import {CallToActionRule} from "@appModels/ticketing/call-to-action-rule";
import {CallToActionRuleMapping} from "@appModels/ticketing/call-to-action-rule-mapping";
import {TicketStatus} from "@appModels/ticketing/ticket.status";
import {BasePojo} from "@appModels/ticketing/base-pojo";
import {WorkflowService} from "@appServices/core-ticketing/workflow-service";
import {WorkflowListRow} from "@appModels/ticketing/workflow.list.row";
import {TicketingStatusService} from "@appServices/core-ticketing/ticketing-status.service";
import {CallToActionRuleService} from "@appServices/core-ticketing/call-to-action-rule-service";
import {Router} from "@angular/router";

@Component({
  moduleId: module.id,
  templateUrl: './call-to-action-rules-create-component.html',
  animations: [
    trigger('toggleAnimation', [
      transition(':enter', [style({ opacity: 0, transform: 'scale(0.95)' }), animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' }))]),
      transition(':leave', [animate('75ms', style({ opacity: 0, transform: 'scale(0.95)' }))]),
    ]),

    trigger('slideDownUp', [
      state('true', style({ height: '0', opacity: '0' })),
      state('false', style({ height: '*', opacity: '1' })),
      transition('true => false', animate(300)),
      transition('false => true', [animate(300)]),
    ])
  ],
  styleUrls: ['./call-to-action-rules-create-component.css', '../../../tailwind.css']
})
export class CallToActionRulesCreateComponent {
  constructor(
    private toastrService: ToastrService,
    private ref: ChangeDetectorRef,
    private router: Router,
    private dispositionService: DispositionsmapService,
    private workflowService: WorkflowService,
    private ticketStatusService: TicketingStatusService,
    private callToActionRuleService: CallToActionRuleService
  ) {
    this.workflowService.getListRows(true).subscribe(x => {
      this.workflows = x;
    })

    this.callToActionRuleService.getCallToActionTypes().subscribe(x => {
      this.callToActionTypes = x;
    })
  }


  mainLoading = true;

  workflows : WorkflowListRow[];
  placeholders =  ['{CUSTOMER_FIRST_NAME}','{CUSTOMER_LAST_NAME}', '{CUSTOMER_NAME}', '{TICKET_NUMBER}', '{TICKET_STATUS_NAME}', '{ACCOUNT_NUMBER}', '{GROUP}', '{LEVEL_1}', '{LEVEL_2}', '{LEVEL_3}' ];

   callToActionTypes: BasePojo[] = [
     {id:1, name:"Send Customer SMS"}
   ]

  ticketStatusList: TicketStatus[] = [

  ]

  callToActionRuleMapping: CallToActionRuleMapping = {
    id:null,
    name:null,
    workflowId:null,
    rules: []
  }

  newCallToActionRule : CallToActionRule = {
     id:0,
    ticketStatusId:null,
    expanded:false
  }

  expandRow(id:number) {
    for (let i = 0 ; i < this.callToActionRuleMapping.rules.length; i++) {
      if (this.callToActionRuleMapping.rules[i].id != id) {
        this.callToActionRuleMapping.rules[i].expanded = false;
      }
      else {
        this.callToActionRuleMapping.rules[i].expanded = !this.callToActionRuleMapping.rules[i].expanded;
      }
    }
  }

  selectedWorkflow(){
     this.loadingAdding = true;
     this.ticketStatusService.getByWorkflow(this.callToActionRuleMapping.workflowId).subscribe(x => {
       this.ticketStatusList = x;
       this.loadingAdding = false;
     })
  }

  loadingAdding = false;

   addRule(){

     if (this.newCallToActionRule.callToActionTypeId == null || this.newCallToActionRule.callToActionTypeId == undefined){
       this.toastrService.error("Please select the call to action type");
       return;
     }
     if (this.newCallToActionRule.ticketStatusId == null || this.newCallToActionRule.ticketStatusId == undefined) {
       this.toastrService.error("Please select the ticket status");
       return;
     }
     if (this.newCallToActionRule.messageTemplate == null || this.newCallToActionRule.messageTemplate == undefined || this.newCallToActionRule.messageTemplate.length < 5) {
       this.toastrService.error("Please enter a longer message template");
       return;
     }

     for (let i = 0; i< this.callToActionRuleMapping.rules.length; i++) {
       if (this.callToActionRuleMapping.rules[i].callToActionTypeId == this.newCallToActionRule.callToActionTypeId
       && this.callToActionRuleMapping.rules[i].ticketStatusId == this.newCallToActionRule.ticketStatusId) {
         this.toastrService.error("You have already added a rule with this status and action");
         return;
       }
     }

     this.loadingAdding = true;

     this.callToActionRuleService.validateAddNewRule(this.newCallToActionRule, this.callToActionRuleMapping.workflowId, null).subscribe(x => {
       if (x.code == 200) {
         this.loadingAdding = false;
         for(let i = 0; i< this.ticketStatusList.length; i++) {
           if (this.ticketStatusList[i].id == this.newCallToActionRule.ticketStatusId) {
             this.newCallToActionRule.ticketStatusName = this.ticketStatusList[i].name;
             this.newCallToActionRule.ticketStatusColorCode = this.ticketStatusList[i].colorCode;
             this.newCallToActionRule.ticketStatusTextColor = this.ticketStatusList[i].textColorCode;
             break;
           }
         }
         for(let i = 0; i < this.callToActionTypes.length; i++) {
           if (this.callToActionTypes[i].id == this.newCallToActionRule.callToActionTypeId) {
             this.newCallToActionRule.callToActionTypeName = this.callToActionTypes[i].name;
             break;
           }
         }
         this.callToActionRuleMapping.rules.push(JSON.parse(JSON.stringify(this.newCallToActionRule)));

         this.newCallToActionRule = {
           id:0,
           ticketStatusId:null,
           expanded : false
         }
         console.log(this.callToActionRuleMapping.rules)
       } else {
         this.toastrService.error(x.message);
         this.loadingAdding = false;
       }
     })


   }

   removeRule(rule: CallToActionRule) {
     this.callToActionRuleMapping.rules = this.callToActionRuleMapping.rules.filter(x =>
       x.callToActionTypeId != rule.callToActionTypeId
     || x.ticketStatusId != rule.ticketStatusId)
   }


   loadingCreate = false;
  createRulesMapping() {

    this.loadingCreate = true;
    this.callToActionRuleService.createMapping(this.callToActionRuleMapping).subscribe(x => {
      this.toastrService.success("New Rules Added");
      this.router.navigateByUrl(`/ticketing/calltoactionrules`);
      this.loadingCreate = false;
    })
  }
}
