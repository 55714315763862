import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';

import {Country} from '@appModels/core-setup/country/country';
import {CountryActions, CountryActionTypes} from '@appStore/actions/core-masterdata/country/country.actions';
import * as _ from 'lodash';

export interface State extends EntityState<Country> {
  loaded: boolean;
  loading: boolean;
  error: any;
  selectedCountryId: number;
  searchCountries: Country[];
  paging: any;
}

export const adapter: EntityAdapter<Country> = createEntityAdapter<Country>();

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
  selectedCountryId: null,
  error: null,
  searchTerm: '',
  searchCountries: null,
  paging: null
});

export function reducer(state = initialState, action: CountryActions): State {
  switch (action.type) {
    case CountryActionTypes.countryGetCountries:
    case CountryActionTypes.countryAddCountry:
    case CountryActionTypes.countryDeleteCountry:
    case CountryActionTypes.countryUpdateCountry:
    case CountryActionTypes.countrySearchCountries:
    case CountryActionTypes.countryGetCountryById:
    case CountryActionTypes.countryGetCountriesPaged:
      return {
        ...state,
        loading: true
      };

    case CountryActionTypes.countryGetCountriesSuccess:
      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });

    case CountryActionTypes.countryGetCountryByIdSuccess:
      return {...state, selectedCountryId: action.payload.id, loading: false};

    case CountryActionTypes.countryAddCountrySuccess:
      return adapter.addOne(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });

    case CountryActionTypes.countryUpdateCountrySuccess: {
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state,
          loading: false,
          loaded: true
        }
      );
    }

    case CountryActionTypes.countryDeleteCountrySuccess: {
      return adapter.removeOne(action.payload.id, {
        ...state,
        loading: false,
        loaded: true
      });
    }

    case CountryActionTypes.countrySearchCountriesSuccess:
      return {
        ...state,
        searchCountries: action.payload,
        loading: false
      };

    case CountryActionTypes.countrySearchCountriesReset:
      return {
        ...state,
        searchCountries: null
      };

    case CountryActionTypes.countryError:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload
      };
    case CountryActionTypes.countryGetCountriesPagedSuccess:
      return adapter.setAll(action.payload.content, {
        ...state,
        loading: false,
        loaded: true,
        paging: _.omit(action.payload, ['content'])
      });
    default:
      return state;
  }
}

export const countryEntitySelectors = adapter.getSelectors();
