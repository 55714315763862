import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';

import {StockingPoint} from '@appModels/core-inventory/stocking-point/stocking-point';

import {CoreInventoryService} from '../core-inventory.service';


@Injectable({providedIn: 'root'})
export class StockingPointService extends CoreInventoryService {


  constructor(
    private http: HttpClient,
  ) {
    super(http, 'stockingpoint');
  }

  /** GET stockingPoints from the server */
  getStockingPoints(): Observable<StockingPoint[]> {
    return this.http.get<StockingPoint[]>(this.base_url + 's', {headers: this.headers}).pipe(
      tap(stockingPoints => this.log(`fetched stockingPoints`)),
      catchError(this.handleError('getStockingPoints', []))
    );
  }

   /** GET stockingPoints from the server */
   getSKUStockingPoints(id: number): Observable<any[]> {
    return this.http.get<any[]>(`${this.base_url}/${id}/products/count`, {headers: this.headers}).pipe(
      tap(SKUstockingPoints => this.log(`fetched SKUstockingPoints`)),
      catchError(this.handleError('getSKUstockingPoints', []))
    );
  }

  /** GET tenant stockingPoints for user from the server */
  getUserStockingPoints(): Observable<any[]> {
    return this.http.get<any[]>(this.base_url + '/user', {headers: this.headers}).pipe(
      tap(stockingPoints => this.log(`fetched userStockingPoints`)),
      catchError(this.handleError('getUserStockingPoints', []))
    );
  }

  getStockingPointUser(userId: any): Observable<any> {
    return this.http.get<any>(`${this.base_url}users/${userId}`, {headers: this.headers}).pipe(
      tap(stockingPoints => this.log(`fetched userStockingPoints`)),
      catchError(this.handleError('getStockingPointUser', []))
    );
  }

  getSuppliers(salesStaffId: number): Observable<any> {
    return this.http.get<any>(`${this.base_url}/${salesStaffId}/suppliers`, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched suppliers`)),
      catchError(this.handleError('getSuppliers', []))
    );
  }

   getLinkedStockingPointReturn(id: number): Observable<any[]> {
    return this.http.get<any[]>(`${this.base_url}s/linked/${id}/return`, {headers: this.headers}).pipe(
      tap(getLinkedStockingPointReturn => this.log(`fetched LinkedStockingPointReturn`)),
      catchError(this.handleError('getLinkedStockingPointReturn', []))
    );
  }

  freezeOrders(stockingPointId: number): Observable<any[]> {
    return this.http.get<any[]>(`${this.base_url}/${stockingPointId}/freezeorders`, {headers: this.headers}).pipe(
      tap(_ => this.log(`freeze orders`)),
      catchError(this.handleError('', []))
    );
  }

  unfreezeOrders(stockingPointId: number): Observable<any[]> {
    return this.http.get<any[]>(`${this.base_url}/${stockingPointId}/unfreezeorders`, {headers: this.headers}).pipe(
      tap(_ => this.log(`unfreeze orders`)),
      catchError(this.handleError('', []))
    );
  }

  /** GET linked stockingPoints from the server */
  getLinkedStockingPoints(id: number, search?: string): Observable<StockingPoint[]> {
    let params = new HttpParams();

    if (search) {
      params = params.append('search', search);
    }

    return this.http.get<StockingPoint[]>(this.base_url + 's/linked/' + id, {
      headers: this.headers,
      params: params
    }).pipe(
      tap(stockingPoints => this.log(`fetched stockingPoints`)),
      catchError(this.handleError('getStockingPoints', []))
    );
  }


  addTransitConsignmentPictures(id: number, search?: string): Observable<StockingPoint[]> {
    let params = new HttpParams();

    if (search) {
      params = params.append('search', search);
    }

    return this.http.get<StockingPoint[]>(this.base_url + 's/linked/' + id + '/return', {
      headers: this.headers,
      params: params
    }).pipe(
      tap(stockingPoints => this.log(`fetched LinkedStockingPointsReturn`)),
      catchError(this.handleError('getLinkedStockingPointsReturn', []))
    );
  }



  getNonSerializedProduct(filters): Observable<any> {
    const params = {};
    if (filters !== '') {
      if (filters.searchParam != null) {
        params['search'] = filters.searchParam;
      }
      if (filters.startPage != null) {
        params['page'] = Number(filters.startPage);
      }
      if (filters.numberPerPage != null) {
        params['size'] = Number(filters.numberPerPage);
      }
    }

    return this.http.get<any>(this.base_url + 'part/nonserialized/globalmanifest', {params, headers: this.headers}).pipe(
      tap(_ => this.log(`fetched NonSerializedProduct`)),
      catchError(this.handleError('getNonSerializedProduct', []))
    );
  }

  getNonSerializedProductByStockingPoint(filters): Observable<any> {
    const params = {};
    if (filters !== '') {
      if (filters.searchParam != null) {
        params['search'] = filters.searchParam;
      }
      if (filters.startPage != null) {
        params['page'] = Number(filters.startPage);
      }
      if (filters.numberPerPage != null) {
        params['size'] = Number(filters.numberPerPage);
      }

      if (filters.stockingPointId == null) {
        filters.stockingPointId = 0;
      }
    }

    return this.http.get<any>(this.base_url + 'part/nonserialized/stockingpoint/' + filters.stockingPointId, {params, headers: this.headers}).pipe(
      tap(_ => this.log(`fetched NonSerializedProduct`)),
      catchError(this.handleError('getNonSerializedProduct', []))
    );
  }

  /** GET linked stockingPoints from the server */
  getUserLinkedStockingPoints(search?: string, stockingPointFrom?: number, isGlobalManifest?: boolean): Observable<StockingPoint[]> {
    let params = new HttpParams();
    params = params.append('tenantId', localStorage.getItem('tenant'));

    if (search) {
      params = params.append('search', search);
    }

    if (stockingPointFrom) {
      params = params.append('stockingPointFrom', String(stockingPointFrom));
    }

    if (isGlobalManifest) {
      params = params.append('isGlobalManifest', String(isGlobalManifest));
    }

    return this.http.get<StockingPoint[]>(this.base_url + 's/user/linked', {
      headers: this.headers,
      params: params
    }).pipe(
      tap(stockingPoints => this.log(`fetched stockingPoints`)),
      catchError(this.handleError('getStockingPoints', []))
    );
  }


  /** GET stockingPoint by id. Return `undefined` when id not found */
  getStockingPointNo404<Data>(id: number): Observable<StockingPoint> {
    const url = `${this.base_url}/?id=${id}`;
    return this.http.get<StockingPoint[]>(url).pipe(
      map(stockingPoints => stockingPoints[0]), // returns a {0|1} element array
      tap(h => {
        const outcome = h ? `fetched` : `did not find`;
        this.log(`${outcome} stockingPoint id=${id}`);
      }),
      catchError(this.handleError<StockingPoint>(`getStockingPoint id=${id}`))
    );
  }

  /** GET stockingPoint by id. Will 404 if id not found */
  getStockingPoint(id: number): Observable<StockingPoint> {
    const url = `${this.base_url}/${id}`;
    return this.http.get<StockingPoint>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched stockingPoint id=${id}`)),
      catchError(this.handleError<StockingPoint>(`getStockingPoint id=${id}`))
    );
  }

  /* GET stockingPoints whose name contains search term */
  searchStockingPoints(term: string): Observable<StockingPoint[]> {
    if (!term.trim()) {
      // if not search term, return empty stockingPoint array.
      return of([]);
    }
    return this.http.get<StockingPoint[]>(`api/stockingPoints/?name=${term}`).pipe(
      tap(_ => this.log(`found stockingPoints matching "${term}"`)),
      catchError(this.handleError<StockingPoint[]>('searchStockingPoints', []))
    );
  }

  /* GET stockingPoints whose name, dealer or sales staff contains search term */
  searchStockingPointsByNameDealerAndSalesStaff(search: string): Observable<any> {
    const tenantId = localStorage.getItem('tenant');

    let params = new HttpParams();

    if (search) {
      params = params.append('search', search);
    }
    return this.http.get<any>(this.base_url + `/search/${tenantId}`, {headers: this.headers, params: params}).pipe(
      tap(_ => this.log(`found stockingPoints matching "${search}"`)),
      catchError(this.handleError<any>('searchStockingPointsByNameDealerAndSalesStaff', []))
    );
  }

  //////// Save methods //////////

  /** POST: add a new stockingPoint to the server */
  addStockingPoint(stockingPoint: StockingPoint): Observable<StockingPoint> {
    return this.http.post<StockingPoint>(this.base_url , stockingPoint, {headers: this.headers}).pipe(
      tap((stockingPoint: StockingPoint) => this.log(`added stockingPoint w/ id=${stockingPoint.id}`)),
      catchError(this.handleError<StockingPoint>('addStockingPoint'))
    );
  }

  /** POST: upload stockingPoint to the server */
  uploadPartToManifest(data): Observable<any> {
    return this.http.post<any>(this.base_url + 'part/uploadtomanifest', data, {headers: this.headers}).pipe(
      tap((_: any) => this.log(`added stockingPoint`)),
      catchError(this.handleError<any>('uploadPartsToGlobalManifest'))
    );
  }

  /** POST: upload bulk parts to global manifest to the server */
  uploadPartToManifestBulk(data): Observable<any> {
    return this.http.post<any>(`${this.base_url}part/uploadtomanifest/bulk`, data, {headers: this.headers}).pipe(
      tap((_: any) => this.log(`upload global parts to global manifest`)),
      catchError(this.handleError<any>('uploadBulkPartsToGlobalManifest'))
    );
  }

  /** POST: upload non serialized product to global manifest to the server */
  uploadNonSerializedProductToManifest(data): Observable<any> {
    return this.http.post<any>(`${this.base_url}part/uploadnonserializedproducttomanifest`, data, {headers: this.headers}).pipe(
      tap((_: any) => this.log(`upload global parts to global manifest`)),
      catchError(this.handleError<any>('UploadNonSerializedProductToGlobalManifest'))
    );
  }

  getPartsAtGlobalManifest(filters): Observable<any> {
    const params = {};
    if (filters !== '') {
      if (filters.searchParam != null) {
        params['search'] = filters.searchParam;
      }
      if (filters.startPage != null) {
        params['page'] = Number(filters.startPage);
      }
      if (filters.numberPerPage != null) {
        params['size'] = Number(filters.numberPerPage);
      }
    }

    return this.http.get<any>(this.base_url + 'part/globalmanifest', {params, headers: this.headers}).pipe(
      tap(() => this.log(`get global manifest parts`)),
      catchError(this.handleError<any>('getGlobalManifestParts'))
    );
  }

  /** POST: patch a stockingPoint to the server */
  patchTenantStockingPoint(stockingPoint: StockingPoint): Observable<any> {
    const tenantId = localStorage.getItem('tenant');
    return this.http.patch(this.base_url + '/' + stockingPoint.id + '/tenant/' + tenantId, "", {headers: this.headers}).pipe(
      tap((res) => this.log(`patch stockingPoint w/ tenantId=${stockingPoint.id}`)),
      catchError(this.handleError<StockingPoint>('patchStockingPoint'))
    );
  }

  /** POST: add a new stockingPoint to the server */
  addStockingPointAdditionalInfo(stockingPoint): Observable<{}> {
    return this.http.post(`${this.base_url}/additionalInfo/${stockingPoint.id}`, stockingPoint.value, {headers: this.headers}).pipe(
      tap((res) => this.log(`added stockingPoint  info `)),
      catchError(this.handleError('addStockingPoint'))
    );
  }

  /** DELETE: delete the stockingPoint from the server */
  deleteStockingPoint(stockingPoint: StockingPoint | number): Observable<StockingPoint> {
    const id = typeof stockingPoint === 'number' ? stockingPoint : stockingPoint.id;
    const url = `${this.base_url}/${id}`;

    return this.http.delete<StockingPoint>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`deleted stockingPoint id=${id}`)),
      catchError(this.handleError<StockingPoint>('deleteStockingPoint'))
    );
  }

  /** PUT: update the stockingPoint on the server */
  updateStockingPoint(stockingPoint: StockingPoint): Observable<any> {
    return this.http.put(`${this.base_url}/${stockingPoint.id}`, stockingPoint, {headers: this.headers}).pipe(
      tap(_ => this.log(`updated stockingPoint id=${stockingPoint.id}`)),
      catchError(this.handleError<any>('updateStockingPoint'))
    );
  }


  activateProduct(accountNumber: String): Observable<any> {
    const tenantId = localStorage.getItem('tenant');
    let baseurl = `${this.base_url}`;
    baseurl = baseurl.replace('stockingpoint', '');
    return this.http.patch(`${baseurl}activateproduct/tenant/${tenantId}/accountnumber/${accountNumber}`, '', {headers: this.headers}).pipe(
      tap(_ => this.log(`activated product`)),
      catchError(this.handleError<any>('failed to activate product'))
    );
  }


  activateBulkProduct(fileUpload): Observable<any> {
    this.setHeader(true);
    const tenantId = localStorage.getItem('tenant');
    let baseurl = `${this.base_url}`;
    baseurl = baseurl.replace('stockingpoint', '');
    return this.http.post<{}>(`${baseurl}bulkactivateproduct/tenant/${tenantId}`, fileUpload, {headers: this.headers}).pipe(
      tap(_ => this.log(`activated bulk product`)),
      catchError(this.handleError<any>('failed to activate bulk products'))
    );
  }

  getInventoryProductsCountByStockingPointIdGroupByProductNameAndPhysicalStatus(stockingPointId): Observable<any> {
    const tenantId = localStorage.getItem('tenant');
    return this.http.get(`${this.base_url}/tenant/${tenantId}/getcountinventoryproductsbystockingpointidgroupbyproductnameandphysicalstatus`, {headers: this.headers}).pipe(
      tap(_ => this.log(``)),
      catchError(this.handleError<any>(''))
    );
  }

  /** GET productsAvailableForSale from the server */
  getAvailableProducts(id): Observable<any[]> {
    return this.http.get<any[]>(this.base_url + '/productsAvailableForSale/aggregatedByGlobalProductVersion/' + id, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched productsAvailableForSale`)),
      catchError(this.handleError('getProductsAvailableForSale', []))
    );
  }


  /** POST: change product status */
  changeProductStatus(payload, file?): Observable<any> {
    const tenantId = localStorage.getItem('tenant');

    const formData = new FormData();

    if (file) {
      formData.append('file', file);
    }
    formData.append('data', new Blob([JSON.stringify(payload)], {
      type: 'application/json'
    }));

    this.setHeader(true);


    return this.http.post(`${this.base_url}/tenant/${tenantId}/changeproductstatus`, formData, {headers: this.headers}).pipe(
      tap((res) => this.log(`change product status`)),
      catchError(this.handleError('changeProductStatus'))
    );
  }

  updateProductStatus(formData){
    this.setHeader(true);
    const tenantId = localStorage.getItem('tenant');
    const url = `${this.base_url}/tenant/${tenantId}/changeproductstatus`;

    return this.http.post<any>(url, formData, {headers: this.headers}).pipe(
      tap(_ => this.log(`changeProductStatus`)),
      catchError(this.handleError<any>(`changeProductStatus`))
    );
  }
}
