
<div class="flex flex-row justify-between my-8 mx-4">
  <div class="flex flex-row  justify-center  gap-3" >
    <div class="text-xl text-black-dark-light">
      Home
    </div>
    <div class="text-xl font-black text-black-dark-light">
      /
    </div>
    <div class="text-xl text-black-dark-light text-orange-600">
      Workflows
    </div>
  </div>


  <button class="btn btn-dlight flex-grow-0 gap-3" routerLink="/ticketing/newWorkflow" >
    New Workflow
    <svg fill="white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="20" height="20"><path d="M6.267 12.933H2.1a.833.833 0 0 0-.833.833v4.167a.833.833 0 0 0 .833.833h4.167a.833.833 0 0 0 .833-.833v-1.266H10A.833.833 0 0 0 10 15H7.1v-1.267a.833.833 0 0 0-.833-.833m-.834 4.167h-2.5v-2.5h2.5Zm-2.1-9.7v3.467a.833.833 0 0 0 1.667 0V7.367a3.333 3.333 0 1 0-1.667 0M4.167 2.5A1.667 1.667 0 1 1 2.5 4.167 1.667 1.667 0 0 1 4.167 2.5m12.5 10.133V10A.833.833 0 0 0 15 10v2.633a3.333 3.333 0 1 0 1.667 0m-.834 4.867a1.667 1.667 0 1 1 1.667-1.667 1.667 1.667 0 0 1-1.667 1.667M13.767 7.1h4.167a.833.833 0 0 0 .833-.833V2.1a.833.833 0 0 0-.834-.833h-4.166a.833.833 0 0 0-.833.833v1.233H10A.833.833 0 0 0 10 5h2.933v1.267a.833.833 0 0 0 .833.833m.834-4.167h2.5v2.5h-2.5Z"/></svg>
  </button>

</div>

<div class="bg-white ticket-table mt-10" style="border-radius:20px">
  <div class="grid grid-cols-5 py-3 ticket-row">
    <div class="col-span-1 my-auto pl-8">
      NAME
    </div>
    <div class="col-span-1 my-auto">
      NUMBER OF STATUSES
    </div>
    <div class="col-span-1 my-auto">
      TICKET TYPE
    </div>
    <div class="col-span-1 my-auto">
      DATE
    </div>
    <div class="col-span-1 my-auto">
      ACTIONS
    </div>
  </div>

  <div class="mt-5 mb-5" *ngIf="loadingTable">
    <div class="flex flex-row justify-center w-full  gap-4">
      <span class="m-auto mb-10 inline-block h-14 w-14 animate-spin rounded-full border-8 border-[#f1f2f3] border-l-dlight align-middle"></span>
    </div>
  </div>
  <ng-container *ngIf="!loadingTable">
    <div  class="grid grid-cols-5 py-3 ticket-row cursor-pointer" *ngFor="let workflow of workflows"  >
      <div class="col-span-1 my-auto text-black pl-8">
        {{workflow.name}}
      </div>

      <div class="col-span-1 my-auto text-black">
        {{workflow.numberOfStatuses}}
      </div>

      <div class="col-span-1 my-auto text-black">
        {{workflow.ticketType}}
      </div>


      <div class="col-span-1 my-auto  text-black-50">
        <div>
          {{ moment(workflow.date).format('DD MMMM YYYY') }},
        </div>
        <div class="text-gray-400">
          {{ moment(workflow.date).format('HH:mm') }}
        </div>
      </div>

      <div class="col-span-1 my-auto">

        <button  [routerLink]="'/ticketing/workflow/' + workflow.id" type="button" class="btn btn-sm rounded-full btn-outline-dlight "><svg _ngcontent-ydi-c23="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="w-5 h-5 ltr:mr-1.5 rtl:ml-1.5 ng-tns-c23-15"><circle _ngcontent-ydi-c23="" cx="12" cy="12" r="3" class="ng-tns-c23-15"></circle><path _ngcontent-ydi-c23="" d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z" class="ng-tns-c23-15"></path></svg>&nbsp;&nbsp;Edit </button>
      </div>



    </div>
  </ng-container>


</div>

