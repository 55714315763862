import {Action} from '@ngrx/store';
import {RepairCentreStatus} from '@appModels/core-aftersales/repaircentrestatus';

export enum RepairCentreStateActionTypes {
  repairCentreStatusGetAll = '[Repair Centre Status] Get All',
  repairCentreStatusGetAllSuccess = '[Repair Centre Stauts] Get All Success',
  repairCentreStatusError = '[Repair Centre Status] Error',
}

export class GetAllRepairCentreStatus implements Action {
  readonly type = RepairCentreStateActionTypes.repairCentreStatusGetAll;

  constructor() {
  }
}

export class GetAllRepairCentreStatusSuccess implements Action {
  readonly type = RepairCentreStateActionTypes.repairCentreStatusGetAllSuccess;

  constructor(public payload: RepairCentreStatus[]) {
  }
}

export class RepairCentreStateError implements Action {
  readonly type = RepairCentreStateActionTypes.repairCentreStatusError;

  constructor(public payload: any) {
  }
}


export type RepairCentreStateActions =
  | RepairCentreStateError
  | GetAllRepairCentreStatus
  | GetAllRepairCentreStatusSuccess;




