import { Action } from '@ngrx/store';
import {
  EntityAdapter,
  createEntityAdapter,
  EntityState,
  Update
} from '@ngrx/entity';
import * as _ from 'lodash';

import { SmsTemplate } from '@appModels/core-setup/sms-template/sms-template';
import { SmsTemplateActions, SmsTemplateActionTypes } from '@appStore/actions/core-masterdata/sms-template/sms-template.actions';

export interface State extends EntityState<SmsTemplate> {
  loaded: boolean;
  loading: boolean;
  error: any;
  selectedSmsTemplateId: number;
  selectedSmsTemplate: any;
  searchSmsTemplates: SmsTemplate[];
  paging: any;
}

export const adapter: EntityAdapter<SmsTemplate> = createEntityAdapter<SmsTemplate>();

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
  selectedSmsTemplateId: null,
  selectedSmsTemplate: null,
  error: null,
  searchTerm: '',
  searchSmsTemplates: null,
  paging: null
});

export function reducer(state = initialState, action: SmsTemplateActions): State {
  switch (action.type) {
    case SmsTemplateActionTypes.smsTemplateGetSmsTemplates:
    case SmsTemplateActionTypes.smsTemplateAddSmsTemplate:
    case SmsTemplateActionTypes.smsTemplateDeleteSmsTemplate:
    case SmsTemplateActionTypes.smsTemplateUpdateSmsTemplate:
    case SmsTemplateActionTypes.smsTemplateSearchSmsTemplates:
    case SmsTemplateActionTypes.smsTemplateGetSmsTemplateById:
      return {
        ...state,
        loading: true
      };

    case SmsTemplateActionTypes.smsTemplateGetSmsTemplatesSuccess:
      return adapter.setAll(action.payload.content, {
        ...state,
        loading: false,
        loaded: true,
        paging: _.omit(action.payload, ['content'])
      });

    case SmsTemplateActionTypes.smsTemplateGetSmsTemplateByIdSuccess:
      return { ...state, selectedSmsTemplateId: action.payload.id,
        selectedSmsTemplate: action.payload, loading: false };

    case SmsTemplateActionTypes.smsTemplateAddSmsTemplateSuccess:
      return adapter.addOne(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });

    case SmsTemplateActionTypes.smsTemplateUpdateSmsTemplateSuccess: {
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state,
          loading: false,
          loaded: true
        }
      );
    }

    case SmsTemplateActionTypes.smsTemplateDeleteSmsTemplateSuccess: {
      return adapter.removeOne(action.payload.id, {
        ...state,
        loading: false,
        loaded: true
      });
    }

    case SmsTemplateActionTypes.smsTemplateSearchSmsTemplatesSuccess:
      return {
        ...state,
        searchSmsTemplates: action.payload,
        loading: false
      };

    case SmsTemplateActionTypes.smsTemplateSearchSmsTemplatesReset:
      return {
        ...state,
        searchSmsTemplates: null
      };

    case SmsTemplateActionTypes.smsTemplateError:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload
      };

    default:
      return state;
  }
}

export const smsTemplateEntitySelectors = adapter.getSelectors();
