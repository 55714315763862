import { Action } from '@ngrx/store';
import {
  EntityAdapter,
  createEntityAdapter,
  EntityState,
  Update
} from '@ngrx/entity';

import { TimeInterval } from '@appModels/core-setup/time-interval/time-interval';
import { TimeIntervalActions, TimeIntervalActionTypes } from '@appStore/actions/core-masterdata/time-interval/time-interval.actions';

export interface State extends EntityState<TimeInterval> {
  loaded: boolean;
  loading: boolean;
  error: any;
  selectedTimeIntervalId: number;
  searchTimeIntervals: TimeInterval[];
}

export const adapter: EntityAdapter<TimeInterval> = createEntityAdapter<TimeInterval>();

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
  selectedTimeIntervalId: null,
  error: null,
  searchTerm: '',
  searchTimeIntervals: null
});

export function reducer(state = initialState, action: TimeIntervalActions): State {
  switch (action.type) {
    case TimeIntervalActionTypes.timeIntervalGetTimeIntervals:
    case TimeIntervalActionTypes.timeIntervalAddTimeInterval:
    case TimeIntervalActionTypes.timeIntervalDeleteTimeInterval:
    case TimeIntervalActionTypes.timeIntervalUpdateTimeInterval:
    case TimeIntervalActionTypes.timeIntervalSearchTimeIntervals:
    case TimeIntervalActionTypes.timeIntervalGetTimeIntervalById:
      return {
        ...state,
        loading: true
      };

    case TimeIntervalActionTypes.timeIntervalGetTimeIntervalsSuccess:
      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });

    case TimeIntervalActionTypes.timeIntervalGetTimeIntervalByIdSuccess:
      return { ...state, selectedTimeIntervalId: action.payload.id, loading: false };

    case TimeIntervalActionTypes.timeIntervalAddTimeIntervalSuccess:
      return adapter.addOne(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });

    case TimeIntervalActionTypes.timeIntervalUpdateTimeIntervalSuccess: {
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state,
          loading: false,
          loaded: true
        }
      );
    }

    case TimeIntervalActionTypes.timeIntervalDeleteTimeIntervalSuccess: {
      return adapter.removeOne(action.payload.id, {
        ...state,
        loading: false,
        loaded: true
      });
    }

    case TimeIntervalActionTypes.timeIntervalSearchTimeIntervalsSuccess:
      return {
        ...state,
        searchTimeIntervals: action.payload,
        loading: false
      };

    case TimeIntervalActionTypes.timeIntervalSearchTimeIntervalsReset:
      return {
        ...state,
        searchTimeIntervals: null
      };

    case TimeIntervalActionTypes.timeIntervalError:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload
      };

    default:
      return state;
  }
}

export const timeIntervalEntitySelectors = adapter.getSelectors();
