import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';

import { HttpClient } from '@angular/common/http';
import {Observable} from "rxjs";
import {catchError, tap} from "rxjs/operators";


@Injectable()
export class CoreTicketingService extends BaseService {

  constructor(_http: HttpClient, url) {
    super(_http, `coreserviceticketing`, url);
  }


}

