import {Action} from '@ngrx/store';

import {EligibilityDefinition} from '@appModels/core-accounting/eligibility-definition/eligibility-definition';

export enum EligibilityDefinitionActionTypes {
  eligibilityDefinitionGetEligibilityDefinitions = '[EligibilityDefinition] get',
  eligibilityDefinitionGetEligibilityDefinitionsSuccess = '[EligibilityDefinition] get eligibilityDefinitions success',
  eligibilityDefinitionGetEligibilityDefinitionByTenant = '[EligibilityDefinition] get eligibilityDefinitions by tenant id',
  eligibilityDefinitionGetEligibilityDefinitionByTenantSuccess = '[EligibilityDefinition] get eligibilityDefinitions by tenant id success',
  eligibilityDefinitionGetEligibilityDefinitionByProductId = '[EligibilityDefinition] get eligibilityDefinition by product id',
  eligibilityDefinitionGetEligibilityDefinitionByProductIdSuccess = '[EligibilityDefinition] get eligibilityDefinition by product id success',
  eligibilityDefinitionAddEligibilityDefinition = '[EligibilityDefinition] add EligibilityDefinition',
  eligibilityDefinitionAddEligibilityDefinitionSuccess = '[EligibilityDefinition] add eligibilityDefinition success',
  eligibilityDefinitionAddAdditionalEligibilityDefinitionInfo = '[EligibilityDefinition] add additional EligibilityDefinition Info',
  eligibilityDefinitionAddAdditionalEligibilityDefinitionInfoSuccess = '[EligibilityDefinition] add additional eligibilityDefinition Info success',
  eligibilityDefinitionDeleteEligibilityDefinition = '[EligibilityDefinition] delete eligibilityDefinition',
  eligibilityDefinitionDeleteEligibilityDefinitionSuccess = '[EligibilityDefinition] delete eligibilityDefinition success',
  eligibilityDefinitionGetEligibilityDefinitionById = '[EligibilityDefinition] get eligibilityDefinition by id',
  eligibilityDefinitionGetEligibilityDefinitionByIdSuccess = '[EligibilityDefinition] get eligibilityDefinition by id success',
  eligibilityDefinitionUpdateEligibilityDefinition = '[EligibilityDefinition] update eligibilityDefinition',
  eligibilityDefinitionUpdateEligibilityDefinitionSuccess = '[EligibilityDefinition] update eligibilityDefinition success',
  eligibilityDefinitionSearchEligibilityDefinitions = '[EligibilityDefinition] search eligibilityDefinitions',
  eligibilityDefinitionSearchEligibilityDefinitionsSuccess = '[EligibilityDefinition] search eligibilityDefinitions success',
  eligibilityDefinitionSearchEligibilityDefinitionsReset = '[EligibilityDefinition] search eligibilityDefinitions reset',
  eligibilityDefinitionError = '[EligibilityDefinition] error',
  eligibilityDefinitionGetEligibilityDefinitionsByTenantId = '[EligibilityDefinition] get eligibilityDefinitions by tenant id',
  eligibilityDefinitionGetEligibilityDefinitionsByTenantIdSuccess = '[EligibilityDefinition] get eligibilityDefinitions by tenant id success',

}

export class GetEligibilityDefinitions implements Action {
  readonly type = EligibilityDefinitionActionTypes.eligibilityDefinitionGetEligibilityDefinitions;
  constructor(public payload?) {}
}

export class GetEligibilityDefinitionsSuccess implements Action {
  readonly type = EligibilityDefinitionActionTypes.eligibilityDefinitionGetEligibilityDefinitionsSuccess;
  constructor(public payload) {}
}

export class GetEligibilityDefinitionsByTenantId implements Action {
  readonly type = EligibilityDefinitionActionTypes.eligibilityDefinitionGetEligibilityDefinitionsByTenantId;
}

export class GetEligibilityDefinitionsByTenantIdSuccess implements Action {
  readonly type = EligibilityDefinitionActionTypes.eligibilityDefinitionGetEligibilityDefinitionsByTenantIdSuccess;

  constructor(public payload: EligibilityDefinition[]) {
  }
}

export class AddEligibilityDefinition implements Action {
  readonly type = EligibilityDefinitionActionTypes.eligibilityDefinitionAddEligibilityDefinition;
  constructor(public payload: EligibilityDefinition) {}
}

export class GetEligibilityDefinitionsByTenant implements Action {
  readonly type = EligibilityDefinitionActionTypes.eligibilityDefinitionGetEligibilityDefinitionByTenant;

  constructor(public payload) {
  }
}

export class GetEligibilityDefinitionsByTenantSuccess implements Action {
  readonly type = EligibilityDefinitionActionTypes.eligibilityDefinitionGetEligibilityDefinitionByTenantSuccess;

  constructor(public payload: EligibilityDefinition[]) {
  }
}

export class AddEligibilityDefinitionSuccess implements Action {
  readonly type = EligibilityDefinitionActionTypes.eligibilityDefinitionAddEligibilityDefinitionSuccess;
  constructor(public payload: EligibilityDefinition) {}
}

export class AddAdditionalEligibilityDefinitionInfo implements Action {
  readonly type = EligibilityDefinitionActionTypes.eligibilityDefinitionAddAdditionalEligibilityDefinitionInfo;
  constructor(public payload) {}
}

export class AddAdditionalEligibilityDefinitionInfoSuccess implements Action {
  readonly type = EligibilityDefinitionActionTypes.eligibilityDefinitionAddAdditionalEligibilityDefinitionInfoSuccess;
  constructor(public payload) {}
}

export class GetEligibilityDefinitionById implements Action {
  readonly type = EligibilityDefinitionActionTypes.eligibilityDefinitionGetEligibilityDefinitionById;
  constructor(public payload: number) {}
}

export class GetEligibilityDefinitionByIdSuccess implements Action {
  readonly type = EligibilityDefinitionActionTypes.eligibilityDefinitionGetEligibilityDefinitionByIdSuccess;
  constructor(public payload: EligibilityDefinition) {}
}

export class GetEligibilityDefinitionByProductId implements Action {
  readonly type = EligibilityDefinitionActionTypes.eligibilityDefinitionGetEligibilityDefinitionByProductId;
  constructor(public payload: number) {}
}

export class GetEligibilityDefinitionByProductIdSuccess implements Action {
  readonly type = EligibilityDefinitionActionTypes.eligibilityDefinitionGetEligibilityDefinitionByProductIdSuccess;
  constructor(public payload: EligibilityDefinition) {}
}

export class UpdateEligibilityDefinition implements Action {
  readonly type = EligibilityDefinitionActionTypes.eligibilityDefinitionUpdateEligibilityDefinition;
  constructor(public payload: EligibilityDefinition) {}
}

export class UpdateEligibilityDefinitionSuccess implements Action {
  readonly type = EligibilityDefinitionActionTypes.eligibilityDefinitionUpdateEligibilityDefinitionSuccess;
  constructor(public payload: EligibilityDefinition) {}
}

export class DeleteEligibilityDefinition implements Action {
  readonly type = EligibilityDefinitionActionTypes.eligibilityDefinitionDeleteEligibilityDefinition;
  constructor(public payload: EligibilityDefinition) {}
}

export class DeleteEligibilityDefinitionSuccess implements Action {
  readonly type = EligibilityDefinitionActionTypes.eligibilityDefinitionDeleteEligibilityDefinitionSuccess;
  constructor(public payload: EligibilityDefinition) {}
}

export class SearchEligibilityDefinitions implements Action {
  readonly type = EligibilityDefinitionActionTypes.eligibilityDefinitionSearchEligibilityDefinitions;
  constructor(public payload: string) {}
}

export class SearchEligibilityDefinitionsSuccess implements Action {
  readonly type = EligibilityDefinitionActionTypes.eligibilityDefinitionSearchEligibilityDefinitionsSuccess;
  constructor(public payload: EligibilityDefinition[]) {}
}

export class SearchEligibilityDefinitionsReset implements Action {
  readonly type = EligibilityDefinitionActionTypes.eligibilityDefinitionSearchEligibilityDefinitionsReset;
}

export class EligibilityDefinitionError implements Action {
  readonly type = EligibilityDefinitionActionTypes.eligibilityDefinitionError;
  constructor(public payload: any) {}
}

export type EligibilityDefinitionActions =
  | GetEligibilityDefinitions
  | GetEligibilityDefinitionsSuccess
  | AddEligibilityDefinition
  | AddEligibilityDefinitionSuccess
  | GetEligibilityDefinitionsByTenant
  | GetEligibilityDefinitionsByTenantSuccess
  | AddAdditionalEligibilityDefinitionInfo
  | AddAdditionalEligibilityDefinitionInfoSuccess
  | GetEligibilityDefinitionById
  | GetEligibilityDefinitionByIdSuccess
  | GetEligibilityDefinitionByProductId
  | GetEligibilityDefinitionByProductIdSuccess
  | UpdateEligibilityDefinition
  | UpdateEligibilityDefinitionSuccess
  | DeleteEligibilityDefinition
  | DeleteEligibilityDefinitionSuccess
  | SearchEligibilityDefinitions
  | SearchEligibilityDefinitionsSuccess
  | SearchEligibilityDefinitionsReset
  | EligibilityDefinitionError
  | GetEligibilityDefinitionsByTenantId
  | GetEligibilityDefinitionsByTenantIdSuccess;
