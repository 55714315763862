import {createEntityAdapter, EntityAdapter} from '@ngrx/entity';

import * as nav from '@appStore/actions/nav.actions';


// Here is the final state required by the app
export interface State {
    main;
    moduleNav;
    subNav;
}

export const adapter: EntityAdapter<{}> = createEntityAdapter<{}>();


export const initialState: State = adapter.getInitialState({
    main: [],
    moduleNav:  [],
    subNav:  []
});

export function reducer(state = initialState, action: nav.NavActions): State {
    switch (action.type) {
        // Case can be more complex
        case nav.NavActionTypes.Success:
          return {
              ...state,
              main : action.payload
          };

        case nav.NavActionTypes.ModuleSuccess:
        return {
                ...state,
               moduleNav : action.payload
            };

        case nav.NavActionTypes.SubNavSuccess:
          return {
                ...state,
               subNav : action.payload
            };
        default:
            return state;
    }
}


export const navEntitySelectors = adapter.getSelectors();
