import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap} from "rxjs/operators";
import {of} from "rxjs";
import {FaultService} from '../../../../services/core-aftersales/fault/fault.service';
import {
  FaultWarrantyDispositionActionTypes, FaultWarrantyDispositionError,
  GetAllFaultWarrantyDispositions, GetAllFaultWarrantyDispositionsSuccess
} from '../../../actions/core-masterdata/fault-action-setup/fault-warranty-disposition.actions';

@Injectable()
export class FaultWarrantDispositionEffects {

  
  repairCentreTechnician = createEffect(() => this.actions$.pipe(
    ofType(FaultWarrantyDispositionActionTypes.faultWarrantyDispositionGetAll),
    mergeMap((action: GetAllFaultWarrantyDispositions) =>
      this.faultService
        .getAllFaultWarrantyDispositions()
        .pipe(
          map(faultWarrantyDispositions => new GetAllFaultWarrantyDispositionsSuccess(faultWarrantyDispositions)),
          catchError(error => of(new FaultWarrantyDispositionError(error)))
        )
    )
  ));

  constructor(
    private actions$: Actions,
    private faultService: FaultService
  ) {
  }
}
