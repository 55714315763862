
<div class="flex flex-row justify-between my-8 mx-4">
  <div class="flex flex-row justify-start gap-3 " >
    <div class="text-xl text-black-dark-light">
      Home
    </div>
    <div class="text-xl font-black text-black-dark-light">
      /
    </div>
    <div class="text-xl text-black-dark-light text-orange-600">
      Custom Fields
    </div>
  </div>


  <button class="btn btn-dlight flex-grow-0 gap-3" routerLink="/ticketing/newCustomField" >
    New Custom Field
    <svg version="1.1" id="_x32_" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xml:space="preserve" width="20" height="20"><style>.st0{fill:white}</style><path class="st0" d="M15.563 1.268h-2.312A1.354 1.354 0 0 0 11.902 0H8.098c-.72 0-1.302.561-1.349 1.268H4.437a2.083 2.083 0 0 0-2.081 2.081v14.677c0 .538.223 1.059.612 1.429.369.351.852.545 1.361.545q.048 0 .095-.002l10.157-.492a3.083 3.083 0 0 0 3.061-3.089V3.349a2.084 2.084 0 0 0-2.081-2.081m.486 15.148a1.5 1.5 0 0 1-.117.582 1.5 1.5 0 0 1-1.378.914 1.2 1.2 0 0 1-.558-.138c-.301-.153-.562-.424-.754-.784-.229-.431-.351-.979-.351-1.584v-.313a.59.59 0 0 0-.266-.494.59.59 0 0 0-.558-.049l-7.903 3.405a.2.2 0 0 1-.062.013.154.154 0 0 1-.153-.154V3.442c0-.32.26-.579.579-.579h2.355c.222.449.68.76 1.214.76h3.804c.534 0 .992-.311 1.214-.76h2.354c.32 0 .58.26.58.579z"/><path class="st0" d="M6.021 5.368h7.958v1.051H6.021zm0 3.229h7.958v1.051H6.021zm0 3.228h7.958v1.051H6.021z"/></svg>
  </button>

</div>

<div class="bg-white ticket-table mt-10" style="border-radius:20px">
  <div class="grid grid-cols-5 py-3 ticket-row">
    <div class="col-span-1 my-auto pl-8">
      NAME
    </div>
    <div class="col-span-1 my-auto pl-8">
      DESCRIPTION
    </div>
    <div class="col-span-1 my-auto">
      TYPE
    </div>
    <div class="col-span-1 my-auto">
      DATE
    </div>
    <div class="col-span-1 my-auto">
      ACTIONS
    </div>
  </div>

  <div class="mt-5 mb-5" *ngIf="loadingTable">
    <div class="flex flex-row justify-center w-full  gap-4">
      <span class="m-auto mb-10 inline-block h-14 w-14 animate-spin rounded-full border-8 border-[#f1f2f3] border-l-dlight align-middle"></span>
    </div>
  </div>
  <ng-container *ngIf="!loadingTable">
    <div  class="grid grid-cols-5 py-3 ticket-row cursor-pointer" *ngFor="let customField of customFields"  >
      <div class="col-span-1 my-auto pl-8 text-black">
        {{customField.name}}
      </div>

      <div class="col-span-1 my-auto cursor-pointer assignee-box pr-3" [tooltip]="customField.description" >
        {{customField.description}}
      </div>

      <div class="col-span-1 my-auto text-black">
        {{customField.customFieldTypeName}}
      </div>

      <div class="col-span-1 my-auto gap-1 text-black-50">
        <div>
          {{ moment(customField.date).format('DD MMMM YYYY') }},
        </div>
        <div class="text-gray-400">
          {{ moment(customField.date).format('HH:mm') }}
        </div>
      </div>

      <div class="col-span-1 my-auto">

        <button  [routerLink]="'/ticketing/customField/' + customField.id" type="button" class="btn btn-sm rounded-full btn-outline-dlight "><svg _ngcontent-ydi-c23="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="w-5 h-5 ltr:mr-1.5 rtl:ml-1.5 ng-tns-c23-15"><circle _ngcontent-ydi-c23="" cx="12" cy="12" r="3" class="ng-tns-c23-15"></circle><path _ngcontent-ydi-c23="" d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z" class="ng-tns-c23-15"></path></svg>&nbsp;&nbsp;Edit </button>
      </div>



    </div>
  </ng-container>


</div>

