import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";

import { catchError, map, mergeMap, switchMap } from "rxjs/operators";
import { of } from "rxjs";

import {
  AddManualPayment,
  AddManualPaymentSuccess,
  DeleteManualPayment,
  DeleteManualPaymentSuccess,
  GetManualPaymentById,
  GetManualPaymentByIdSuccess,
  GetManualPayments,
  GetManualPaymentsSuccess,
  ManualPaymentActionTypes,
  ManualPaymentError,
  SearchManualPayments,
  SearchManualPaymentsSuccess,
  UpdateManualPaymentSuccess,
  UploadManualPayment,
} from "@appStore/actions/core-accounting/manual-payment/manual-payment.actions";
import { ManualPaymentService } from "@appServices/core-accounting/manual-payment/manual-payment.service";
import { TenantService } from "@appServices/core-masterdata/tenant/tenant.service";

import * as fromRouterActions from "@appStore/actions/router.actions";
import { ToastrService } from "ngx-toastr";

@Injectable()
export class ManualPaymentEffects {
  constructor(
    private actions$: Actions,
    private manualPaymentService: ManualPaymentService,
    private tenantService: TenantService,
    private toastr: ToastrService
  ) {}

  loadManualPayments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManualPaymentActionTypes.manualPaymentGetManualPayments),
      mergeMap((action: GetManualPayments) =>
        this.manualPaymentService.getManualPayments(action.payload).pipe(
          map((manualPayments) => new GetManualPaymentsSuccess(manualPayments)),
          catchError((error) => of(new ManualPaymentError(error)))
        )
      )
    )
  );

  // load manualPayments by tenant ID
  // @Effect()
  // loadManualPaymentsByTenantId$ = this.actions$.pipe(
  //   ofType(ManualPaymentActionTypes.manualPaymentGetManualPayments),
  //   mergeMap(() =>
  //     this.manualPaymentService
  //       .getManualPaymentByTenant(+localStorage.getItem('tenant'))
  //       .pipe(
  //         map(manualPayments => new GetManualPaymentsSuccess(manualPayments)),
  //         catchError(error => of(new ManualPaymentError(error)))
  //       )
  //   )
  // );

  getManualPaymentById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManualPaymentActionTypes.manualPaymentGetManualPaymentById),
      mergeMap((action: GetManualPaymentById) =>
        this.manualPaymentService.getManualPayment(action.payload).pipe(
          map(
            (manualPayment) => new GetManualPaymentByIdSuccess(manualPayment)
          ),
          catchError((error) => of(new ManualPaymentError(error)))
        )
      )
    )
  );

  addManualPayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManualPaymentActionTypes.manualPaymentAddManualPayment),
      switchMap((action: AddManualPayment) =>
        this.manualPaymentService.addManualPayment(action.payload).pipe(
          map((manualPayment) => {
            if (manualPayment !== undefined) {
              this.toastr.success(
                "Manual Payment has been successfully added!",
                "Successful!"
              );
              return new AddManualPaymentSuccess(manualPayment);
            }
            return new ManualPaymentError({
              type: 500,
              message: "Internal error",
            });
          }),
          catchError((error) => {
            this.toastr.error(error.message, error.error);
            return of(new ManualPaymentError(error));
          })
        )
      )
    )
  );

  uploadManualPayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManualPaymentActionTypes.manualPaymentUploadManualPayment),
      mergeMap((action: UploadManualPayment) =>
        this.manualPaymentService.uploadManualPayment(action.payload).pipe(
          map((manualPayment) => {
            if (manualPayment !== undefined) {
              this.toastr.success(
                "Manual Payment has been successfully uploaded!",
                "Successful!"
              );
              return new GetManualPayments({
                page: 0,
                size: 10,
                warranty: false,
                stockRecovery: false,
              });
            }
            return new ManualPaymentError({
              type: 500,
              message: "Internal error",
            });
          }),
          catchError((error) => of(new ManualPaymentError(error)))
        )
      )
    )
  );

  updateManualPayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManualPaymentActionTypes.manualPaymentUpdateManualPayment),
      mergeMap((action: AddManualPayment) =>
        this.manualPaymentService.updateManualPayment(action.payload).pipe(
          map(
            (manualPayment) => new UpdateManualPaymentSuccess(action.payload)
          ),
          catchError((error) => of(new ManualPaymentError(error)))
        )
      )
    )
  );

  deleteManualPayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManualPaymentActionTypes.manualPaymentDeleteManualPayment),
      mergeMap((action: DeleteManualPayment) =>
        this.manualPaymentService.deleteManualPayment(action.payload).pipe(
          map(() => new DeleteManualPaymentSuccess(action.payload)),
          catchError((error) => of(new ManualPaymentError(error)))
        )
      )
    )
  );

  searchManualPayments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManualPaymentActionTypes.manualPaymentSearchManualPayments),
      mergeMap((action: SearchManualPayments) =>
        this.manualPaymentService.searchManualPayments(action.payload).pipe(
          map(
            (manualPayments) => new SearchManualPaymentsSuccess(manualPayments)
          ),
          catchError((error) => of(new ManualPaymentError(error)))
        )
      )
    )
  );

  updateManualPaymentSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManualPaymentActionTypes.manualPaymentUpdateManualPaymentSuccess),
      map(
        (manualPayment) =>
          new fromRouterActions.Go({ path: ["/finance/manual-payment"] })
      )
    )
  );
}
