import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';

import {CoreTicketingService} from "@appServices/core-ticketing/core-ticketing.service";

@Injectable({ providedIn: 'root' })
export class CoreTicketingCustomerService extends CoreTicketingService {

  constructor(
    private http: HttpClient,
  ) {
    super(http, 'ticketing');
    this.headers = this.headers.append('skip-cache', 'true');
  }

  getCustomerDetails( id : number): Observable<any> {
    const url = `${this.base_url}/customer/customerDetails/` + id;
    return this.http.get<any>(url, {headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }

  getCountryAdminZoneForCustomer( id : number): Observable<any> {
    const url = `${this.base_url}/customer/getCountryAdminZoneForCustomer/` + id;
    return this.http.get<any>(url, {headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }

  getCustomers(query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        params = params.append(val, query[val]);
      });
    }
    return this.http.get<any>(this.base_url + '/customer/customers/tenant/' + localStorage.getItem('tenant'), {
      params,
      headers: this.headers
    }).pipe(
      tap(customers => this.log(`fetched customers`)),
      catchError(this.handleError('getCustomers', []))
    );
  }


  getPrimaryContractProductByContractId( id : number): Observable<any> {
    const url = `${this.base_url}/customer/getPrimaryContractProductByContractId/` + id;
    return this.http.get<any>(url, {headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }




}
