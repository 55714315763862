import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap} from "rxjs/operators";
import {of} from "rxjs";
import {RepairCentreTechnicianService} from '../../../../services/core-aftersales/repaircentre/repair-centre-technician.service';
import {
  GetAllRepairCentreTechnician,
  GetAllRepairCentreTechnicianSuccess, GetRepairCentreTechnicianByTenant, GetRepairCentreTechnicianByTenantSuccess,
  GetRepairCentreTechnicianByUserSuccess,
  GetRepairCentreTechnicianPaged,
  GetRepairCentreTechnicianPagedSuccess,
  RepairCentreTechnicianActionTypes,
  RepairCentreTechnicianActivate,
  RepairCentreTechnicianActivateSuccess,
  RepairCentreTechnicianDeactivate, RepairCentreTechnicianDeactivateSuccess,
  RepairCentreTechnicianError,
  RepairCentreTechnicianSave,
  RepairCentreTechnicianSaveSuccess
} from '../../../actions/core-aftersales/repaircentres/repaircentretechnican.action';
import {ToastrService} from 'ngx-toastr';

@Injectable()
export class RepairCentreTechnicianEffects {

  
  repairCentreTechnicians$ = createEffect(() => this.actions$.pipe(
    ofType(RepairCentreTechnicianActionTypes.repairCentreTechnicianGetAll),
    mergeMap((action: GetAllRepairCentreTechnician) =>
      this.repairCentreTechnicianService
        .getRepairCentreTechnicians(action.payload)
        .pipe(
          map(repairCentreTechnicians => new GetAllRepairCentreTechnicianSuccess(repairCentreTechnicians)),
          catchError(error => of(new RepairCentreTechnicianError(error)))
        )
    )
  ));

  
  repairCentreTechniciansPaged$ = createEffect(() => this.actions$.pipe(
    ofType(RepairCentreTechnicianActionTypes.repairCentreTechnicianGetPaged),
    mergeMap((action: GetRepairCentreTechnicianPaged) =>
      this.repairCentreTechnicianService
        .getAllRepairCentreTechnicians(action.payload)
        .pipe(
          map(repairCentreTechnicians => new GetRepairCentreTechnicianPagedSuccess(repairCentreTechnicians)),
          catchError(error => of(new RepairCentreTechnicianError(error)))
        )
    )
  ));

  
  repairCentreTechniciansByTenant$ = createEffect(() => this.actions$.pipe(
    ofType(RepairCentreTechnicianActionTypes.repairCentreTechnicianGetByTenant),
    mergeMap((action: GetRepairCentreTechnicianByTenant) =>
      this.repairCentreTechnicianService
        .getRepairCentreTechniciansForTenant()
        .pipe(
          map(repairCentreTechnicians => new GetRepairCentreTechnicianByTenantSuccess(repairCentreTechnicians)),
          catchError(error => of(new RepairCentreTechnicianError(error)))
        )
    )
  ));


  
  repairCentreTechnician$ = createEffect(() => this.actions$.pipe(
    ofType(RepairCentreTechnicianActionTypes.repairCentreTechnicianGetByUser),
    mergeMap((action: GetAllRepairCentreTechnician) =>
      this.repairCentreTechnicianService
        .getRepairCentreTechnicianForUser(action.payload)
        .pipe(
          map(repairCentreTechnician => {
            if (repairCentreTechnician) {
              return new GetRepairCentreTechnicianByUserSuccess(repairCentreTechnician);
            }
            return new RepairCentreTechnicianError({type: 404, message: 'User not found'});
          }),
          catchError(error => of(new RepairCentreTechnicianError(error)))
        )
    )
  ));

  
  repairCentreTechnicianSave = createEffect(() => this.actions$.pipe(
    ofType(RepairCentreTechnicianActionTypes.repairCentreTechnicianSave),
    mergeMap((action: RepairCentreTechnicianSave) =>
      this.repairCentreTechnicianService
        .saveRepairCentreTechnician(action.payload)
        .pipe(
          map(repairCentreTechnician => {
            this.toastr.success('Repair center user assigned successfully!', 'Successful!');
            return new RepairCentreTechnicianSaveSuccess(repairCentreTechnician);
          }),
          catchError(error => of(new RepairCentreTechnicianError(error)))
        )
    )
  ));

  
  repairCentreTechnicianActivate = createEffect(() => this.actions$.pipe(
    ofType(RepairCentreTechnicianActionTypes.repairCentreTechnicianActivate),
    mergeMap((action: RepairCentreTechnicianActivate) =>
      this.repairCentreTechnicianService
        .activateRepairCentreTechnician(action.payload)
        .pipe(
          map(repairCentreTechnician => {
            this.toastr.success('Repair center user activated successfully!', 'Successful!');
            return new RepairCentreTechnicianActivateSuccess(repairCentreTechnician);
          }),
          catchError(error => of(new RepairCentreTechnicianError(error)))
        )
    )
  ));

  
  repairCentreTechnicianDeactivate = createEffect(() => this.actions$.pipe(
    ofType(RepairCentreTechnicianActionTypes.repairCentreTechnicianDeactivate),
    mergeMap((action: RepairCentreTechnicianDeactivate) =>
      this.repairCentreTechnicianService
        .deactivateRepairCentreTechnician(action.payload)
        .pipe(
          map(repairCentreTechnician => {
            this.toastr.success('Repair center user deactivated successfully!', 'Successful!');
            return new RepairCentreTechnicianDeactivateSuccess(repairCentreTechnician);
          }),
          catchError(error => of(new RepairCentreTechnicianError(error)))
        )
    )
  ));

  constructor(
    private actions$: Actions,
    private toastr: ToastrService,
    private repairCentreTechnicianService: RepairCentreTechnicianService
  ) {
  }
}
