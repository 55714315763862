import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

import {Addtestflow, AddtestflowSuccess,} from '@appStore/actions/core-aftersales/test-flow/test-flow.actions';
import {SalesStaffService} from '@appServices/core-fieldforce/sales-staff/sales-staff.service';
import {TenantService} from '@appServices/core-masterdata/tenant/tenant.service';
import {ToastrService} from 'ngx-toastr';
import {TenantSalesStaffService} from "@appServices/core-fieldforce/sales-staff/tenant-sales-staff.service";
import {TestFlowService} from "../../../../services/core-aftersales/test-flow/test-flow.service";
import {
  Addticketcreationfields,
  AddticketcreationfieldsSuccess,
  Filterticketcreationfields,
  FilterticketcreationfieldsSuccess,
  GetticketcreationfieldsById,
  GetticketcreationfieldsByIdSuccess,
  TicketCreationFieldsActionTypes,
  ticketcreationfieldssError
} from "../../../actions/core-masterdata/ticket-creation-fields/ticket-creation-fields.actions";
import {TicketCreationFieldsService} from "../../../../services/core-masterdata/ticket-creation-fields/ticket-creation-fields.service";

@Injectable()
export class TicketCreationFieldsEffects {

  
  addTestCreationFields$ = createEffect(() => this.actions$.pipe(
    ofType(TicketCreationFieldsActionTypes.ticketcreationfieldsAddticketcreationfields),
    switchMap((action: Addticketcreationfields) =>
      this.ticketCreationFieldsService
        .addTicketCreationFields(action.payload)
        .pipe(
          map(
            stockingPoint => {
              if (stockingPoint !== undefined) {
                this.toastr.success('Sales Staff has been successfully added!', 'Successful!');
                return new AddticketcreationfieldsSuccess(stockingPoint)
              }
              this.toastr.error('There was an error creating sales staff!', "Unknown error");
              return new ticketcreationfieldssError({type: 500, message: 'Internal error'})
            }
          ),
          catchError(error => of(new ticketcreationfieldssError(error)))
        )
    )
  ));


  
  filterTicketCreationFields$ = createEffect(() => this.actions$.pipe(
    ofType(TicketCreationFieldsActionTypes.ticketcreationfieldsFilterticketcreationfields),
    mergeMap((action: Filterticketcreationfields) =>
      this.ticketCreationFieldsService
        .filterTicketCreationFields(action.payload)
        .pipe(
          map(customers => new FilterticketcreationfieldsSuccess(customers)),
          catchError(error => of(new ticketcreationfieldssError(error)))
        )
    )
  ));

  
  getTicketCreationFieldsById$ = createEffect(() => this.actions$.pipe(
    ofType(TicketCreationFieldsActionTypes.ticketcreationfieldsGetticketcreationfieldsById),
    mergeMap((action: GetticketcreationfieldsById) =>
      this.ticketCreationFieldsService
        .getTicketCreationFieldsById(action.payload)
        .pipe(
          map(salesStaff => new GetticketcreationfieldsByIdSuccess(salesStaff)),
          catchError(error => of(new ticketcreationfieldssError(error)))
        )
    )
  ));


  constructor(
    private actions$: Actions,
    private testFlowService: TestFlowService,
    private tenantService: TenantService,
    private toastr: ToastrService,
    private ticketCreationFieldsService: TicketCreationFieldsService
  ) {
  }
}
