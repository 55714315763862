import {createEntityAdapter, EntityAdapter, EntityState,} from '@ngrx/entity';
import * as _ from 'lodash';

import {SalesStaff} from '@appModels/core-fieldforce/sales-staff/sales-staff';
import {
  SalesStaffActions,
  SalesStaffActionTypes
} from '@appStore/actions/core-fieldforce/sales-staff/sales-staff.actions';

export interface State extends EntityState<SalesStaff> {
  loaded: boolean;
  loading: boolean;
  error: any;
  selectedSalesStaff: any;
  selectedSalesStaffId: number;
  searchSalesStaffs: SalesStaff[];
  paging: any;
}




export const adapter: EntityAdapter<SalesStaff> = createEntityAdapter<SalesStaff>();

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
  selectedSalesStaffId: null,
  selectedSalesStaff: null,
  error: null,
  searchSalesStaffs: null,
  paging: null
});

export function reducer(state = initialState, action: SalesStaffActions): State {
  switch (action.type) {
    case SalesStaffActionTypes.salesStaffGetStaffs:
    case SalesStaffActionTypes.salesStaffAddStaff:
    case SalesStaffActionTypes.salesStaffAddStaffAttributes:
    case SalesStaffActionTypes.salesStaffDeleteStaff:
    case SalesStaffActionTypes.salesStaffUpdateStaff:
    case SalesStaffActionTypes.salesStaffSearchStaffs:
    case SalesStaffActionTypes.salesStaffGetStaffById:
    case SalesStaffActionTypes.salesStaffGeStaffsByTenantId:
    case SalesStaffActionTypes.salesStaffFilterSalesStaff:


      return {
        ...state,
        loading: true
      };


    case SalesStaffActionTypes.salesStaffGetStaffsSuccess:

      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true,
        paging: _.omit(action.payload, ['content'])

      });


    case SalesStaffActionTypes.salesStaffFilterSalesStaffSuccess:

      return adapter.setAll(action.payload.content, {
        ...state,
        loading: false,
        loaded: true,
       paging: _.omit(action.payload, ['content'])
       });


    case SalesStaffActionTypes.salesStaffGetStaffByIdSuccess:
      return {...state, selectedSalesStaffId: action.payload.id, selectedSalesStaff: action.payload, loading: false};


    case SalesStaffActionTypes.salesStaffAddSStaffSuccess:

      return adapter.addOne(action.payload, {
        ...state,
        selectedSalesStaffId: action.payload.id,
        loading: false,
        loaded: true
      });

    case SalesStaffActionTypes.salesStaffGeStaffsByTenantIdSuccess:

      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });

    case SalesStaffActionTypes.salesStaffUpdateStaffSuccess: {

      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state,
          loading: false,
          loaded: true
        }
      );
    }

    case SalesStaffActionTypes.salesStaffDeleteStaffSuccess: {

      return adapter.removeOne(action.payload.id, {
        ...state,
        loading: false,
        loaded: true
      });
    }

    case SalesStaffActionTypes.salesStaffSearchStaffsSuccess:

      return {
        ...state,
        searchSalesStaffs: action.payload,
        loading: false
      };

    case SalesStaffActionTypes.salesStaffSearchStaffsReset:

      return {
        ...state,
        searchSalesStaffs: null
      };

    case SalesStaffActionTypes.salesStaffsError:

      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload
      };

    case SalesStaffActionTypes.salesStaffGenderUpdate:

      return {
        ...state,
        loading: false,
        loaded: false,
        selectedSalesStaff: {
          ...state.selectedSalesStaff,
          gender:action.payload.gender
        }
      };

    default:
      return state;
  }
}

export const salesStaffEntitySelectors = adapter.getSelectors();
