import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {
  OowRepairConditionsActions,
  OowRepairConditionsActionTypes
} from '../../../actions/core-masterdata/oow-repair-conditions/oow-repair-conditions.actions';
import {OowRepairCondition} from '../../../../models/core-setup/oow-repair-conditions/oow-repair-condition';

export interface State extends EntityState<OowRepairCondition> {
  error: any;
  loaded: boolean;
  loading: boolean;
}

export const adapter: EntityAdapter<OowRepairCondition> = createEntityAdapter<OowRepairCondition>();

export const initialState: State = adapter.getInitialState({
  error: null,
  loaded: false,
  loading: false
});

export function reducer(state = initialState, action: OowRepairConditionsActions): State {
  switch (action.type) {
    case OowRepairConditionsActionTypes.oowRepairConditionsGetAll:
      return {
        ...state,
        loading: true,
        loaded: false
      };

    case OowRepairConditionsActionTypes.oowRepairConditionsError:
      return adapter.addOne(action.payload, {
        ...state,
        error: action.payload.id,
        loading: false,
        loaded: true
      });

    case OowRepairConditionsActionTypes.oowRepairConditionsGetAllSuccess:
      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });
    default:
      return state;
  }
}

export const oowRepairConditionEntitySelectors = adapter.getSelectors();

