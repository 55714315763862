import {Injectable} from '@angular/core';
import {CoreAftersalesService} from "@appServices/core-aftersales/core-aftersales.service";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {catchError, tap} from "rxjs/operators";
import {RepairCentreStatus} from "@appModels/core-aftersales/repaircentrestatus";

@Injectable({
  providedIn: 'root'
})
export class RepaircentrestatusService extends CoreAftersalesService {

  constructor(private http: HttpClient) {
    super(http, 'repaircentrestatus');
  }

  /** GET repair centre states from the server */
  getRepairCentreStates(): Observable<RepairCentreStatus[]> {
    return this.http.get<RepairCentreStatus[]>(this.base_url , {headers: this.headers}).pipe(
      tap(types => this.log(`fetched repair centre states`)),
      catchError(this.handleError('getRepairCentreStates', []))
    );
  }
}
