
<div class="flex flex-row justify-start my-8 mx-4">
  <div class="flex flex-row   gap-3 justify-start">
    <div class="text-xl text-black-dark-light">
      Home
    </div>
    <div class="text-xl font-black text-black-dark-light">
      /
    </div>
    <div class="text-xl text-black-dark-light  cursor-pointer hover:text-dlight ease-in-out duration-300" routerLink="/ticketing/requesttypes">
      Request Types
    </div>
    <div class="text-xl font-black text-black-dark-light">
      /
    </div>
    <div class="text-xl text-black-dark-light text-orange-600">
      View Request Type
    </div>
  </div>

</div>

<div class="mt-3" *ngIf="mainLoading">
  <div class="flex flex-row justify-center items-center w-full  gap-4">
    <span class="m-auto  inline-block h-14 w-14 animate-spin rounded-full border-8 border-[#f1f2f3] border-l-dlight align-middle"></span>
  </div>
</div>


<ng-container *ngIf="!mainLoading">
  <div class="m-auto" style="width:90%">
    <div style="border-radius:20px" class="bg-white box-shadow-pop p-4">
      <div class="font-semibold text-lg">General Details</div>
      <div class="text-dlight">
        <hr class="h-px border-t-0 bg-transparent bg-gradient-to-r from-red-500 via-red-500 to-transparent opacity-25">
      </div>

      <form>
        <div class="mt-6">
          <label class="label_title">Name
            <span *ngIf="requestType.name == null || requestType.name.length < 3" class="text-red-500">*</span>
            <span *ngIf="requestType.name != null && requestType.name.length >= 3" class="text-green-500">*</span>
          </label>
          <input class=" form-input form-input-focus" placeholder="Enter a name" [(ngModel)]="requestType.name" name="name"/>
        </div>

        <div class="mt-3">
          <label class="label_title">Description</label>
          <textarea class="form-input form-input-focus" rows="2" [(ngModel)]="requestType.description" name="description"></textarea>
        </div>

        <div >
          <div class="flex flex-row justify-start gap-2 items-center">
            <label class="label_title mt-3">Default Team
              <span *ngIf="requestType.teamId == null || requestType.teamId == undefined" class="text-red-500">*</span>
              <span *ngIf="requestType.teamId != null && requestType.teamId != undefined" class="text-green-500">*</span>
            </label>
            <div class="cursor-pointer" tooltip="The deault team the ticket will be assigned to, if no other assignee is specified"><svg fill="#FF4613" width="18" height="18" viewBox="-0.045 -0.045 0.54 0.54" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin" class="jam jam-info"><path d="M0.225 0.45C0.101 0.45 0 0.349 0 0.225S0.101 0 0.225 0s0.225 0.101 0.225 0.225 -0.101 0.225 -0.225 0.225m0 -0.045a0.18 0.18 0 1 0 0 -0.36 0.18 0.18 0 0 0 0 0.36m0 -0.225a0.022 0.022 0 0 1 0.022 0.022v0.112a0.022 0.022 0 0 1 -0.045 0V0.202a0.022 0.022 0 0 1 0.022 -0.022m0 -0.022a0.022 0.022 0 1 1 0 -0.045 0.022 0.022 0 0 1 0 0.045"/></svg></div>
          </div>
          <select class="select_input form-input form-input-focus" placeholder="Select Default Team..." [(ngModel)]="requestType.teamId" name="teamId">
            <option value="" disabled>{{'Please select a Team' | translate}}</option>
            <option [value]="team.id" *ngFor="let team of teams"> {{team.name}} </option>
          </select>
        </div>

        <div >
          <div class="flex flex-row justify-start gap-2 items-center">
            <label class="label_title mt-3">Ticket Type
              <span *ngIf="requestType.ticketTypeId == null || requestType.ticketTypeId == undefined" class="text-red-500">*</span>
              <span *ngIf="requestType.ticketTypeId != null && requestType.ticketTypeId != undefined" class="text-green-500">*</span>
            </label>
          </div>
          <select class="select_input form-input form-input-focus" placeholder="Select Ticket Type..." [(ngModel)]="requestType.ticketTypeId" name="ticketTypeId">
            <option value="" disabled>{{'Please select a Type' | translate}}</option>
            <option [value]="ticketType.id" *ngFor="let ticketType of ticketTypes"> {{ticketType.name}} </option>
          </select>
        </div>

      </form>


      <div class="font-semibold text-lg mt-8">SLA Configuration</div>
      <div class="text-dlight">
        <hr class="h-px border-t-0 bg-transparent bg-gradient-to-r from-red-500 via-red-500 to-transparent opacity-25">
      </div>

      <div class="grid grid-cols-12 gap-3 items-end">
        <div class="col-span-2">
          <label class="label_title mt-3">Criteria
            <span *ngIf="slaConfiguration.slaTypeId == null || slaConfiguration.slaTypeId == undefined" class="text-red-500">*</span>
            <span *ngIf="slaConfiguration.slaTypeId != null && slaConfiguration.slaTypeId != undefined" class="text-green-500">*</span>
          </label>
          <select class="select_input form-input form-input-focus" placeholder="Select Criteria..."  [(ngModel)]="slaConfiguration.slaTypeId" name="slaTypes">
            <option value="" disabled>{{'Please select Criteria' | translate}}</option>
            <option [value]="slaType.id" *ngFor="let slaType of slaTypes"> {{slaType.name}} </option>
          </select>
        </div>
        <div class="col-span-2">
          <label class="label_title mt-3">Priority Level
            <span *ngIf="slaConfiguration.ticketPriorityId == null || slaConfiguration.ticketPriorityId == undefined" class="text-red-500">*</span>
            <span *ngIf="slaConfiguration.ticketPriorityId != null && slaConfiguration.ticketPriorityId != undefined" class="text-green-500">*</span>
          </label>
          <select class="select_input form-input form-input-focus" placeholder="Select Priority..."  [(ngModel)]="slaConfiguration.ticketPriorityId" name="ticketPriorityId">
            <option value="" disabled>{{'Please select Priority' | translate}}</option>
            <option [value]="ticketPriority.id" *ngFor="let ticketPriority of ticketPriorities"> {{ticketPriority.name}} </option>
          </select>
        </div>
        <div class="col-span-3">
          <label class="label_title mt-3">Time To Complete Days</label>
          <input type="number" class="form-input form-input-focus" name="days" placeholder="0" [(ngModel)]="slaConfiguration.days">
        </div>
        <div class="col-span-2">
          <label class="label_title mt-3">Hours</label>
          <input type="number" class="form-input form-input-focus" name="hours" placeholder="0" [(ngModel)]="slaConfiguration.hours">
        </div>
        <div class="col-span-2">
          <label class="label_title mt-3">Minutes</label>
          <input type="number" class="form-input form-input-focus" name="minutes" placeholder="0" [(ngModel)]="slaConfiguration.minutes">
        </div>
        <div class="col-span-1">
          <button (click)="addSlaConfiguration()" type="button" class="btn btn-dlight">Add</button>
        </div>
      </div>


      <div *ngIf="requestType.slaConfigurations.length > 0" class="bg-white ticket-table mt-3  mx-auto" style="border-radius:20px">
        <div class="grid grid-cols-4 py-3 ticket-row">
          <div class="col-span-1 m-auto">
            CRITERIA
          </div>
          <div class="col-span-1 m-auto">
            PRIORITY LEVEL
          </div>
          <div class="col-span-1 m-auto">
            TIME TO COMPLETE
          </div>
          <div class="col-span-1 m-auto">
            ACTIONS
          </div>
        </div>
        <div class="grid grid-cols-4 py-3 ticket-row" *ngFor="let slaConfiguration of requestType.slaConfigurations">
          <div class="col-span-1 my-auto pl-8">
            {{ slaConfiguration.slaTypeName }}
          </div>
          <div class="col-span-1 m-auto pl-8">
            {{ slaConfiguration.ticketPriorityName }}
          </div>
          <div class="col-span-1 my-auto pl-8">
            {{ slaConfiguration.timeToCompleteString }}
          </div>
          <div class="col-span-1 m-auto">
            <button (click)="removeSlaConfiguration(slaConfiguration)" type="button" class="btn btn-sm rounded-full btn-outline-dlight "><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" xml:space="preserve" width="24" height="24"><path fill="none" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" d="M17.25 20.25h-9c-.825 0-1.5-.675-1.5-1.5V6h12v12.75c0 .825-.675 1.5-1.5 1.5zm3-14.25h-15m5.25 0V4.5c0-.45.3-.75.75-.75h3c.45 0 .75.3.75.75V6m-2.25 11.25V9m3 8.25V9m-6 8.25V9"/></svg>&nbsp;&nbsp;Remove </button>
          </div>
        </div>
      </div>

    </div>
  </div>


  <div class="flex flex-row w-full justify-center mt-6" *ngIf="!loadingCreate">
    <button type="button" class="btn btn-success" (click)="updateRequestType()">Update Request Type</button>
  </div>
  <div *ngIf="loadingCreate" class="flex flex-row justify-center items-center w-full mt-6 gap-4">
    <span class="m-auto  inline-block h-14 w-14 animate-spin rounded-full border-8 border-[#f1f2f3] border-l-dlight align-middle"></span>
  </div>

</ng-container>
