import {ChangeDetectorRef, Component, TemplateRef, ViewChild, ViewEncapsulation} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';


import {ToastrService} from "ngx-toastr";

import {ActivatedRoute, Router} from "@angular/router";
import {TicketTypeService} from "@appServices/core-ticketing/ticket-type.service";
import {BasePojo} from "@appModels/ticketing/base-pojo";

@Component({
  moduleId: module.id,
  templateUrl: './ticket-type-update-component.html',
  animations: [
    trigger('toggleAnimation', [
      transition(':enter', [style({ opacity: 0, transform: 'scale(0.95)' }), animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' }))]),
      transition(':leave', [animate('75ms', style({ opacity: 0, transform: 'scale(0.95)' }))]),
    ]),
  ],
  styleUrls: ['./ticket-type-update-component.css', '../../../tailwind.css'],
  encapsulation: ViewEncapsulation.None

})
export class TicketTypeUpdateComponent {



  constructor(
    private toastrService: ToastrService,
    private ticketTypeService: TicketTypeService,
    private router: Router,
    private route: ActivatedRoute

  ) {
    this.ticketTypeId = +this.route.snapshot.paramMap.get('id');

    this.ticketTypeService.getTicketType(this.ticketTypeId).subscribe(x => {
      this.ticketType = x;
      this.mainLoading = false;
    })
  }

  mainLoading = true;
  ticketTypeId: number = null;


  ticketType : BasePojo = {
    name:"",
  }


  updateTicketType() {
    this.mainLoading = true;
    if (this.ticketType.name == null || this.ticketType.name == undefined || this.ticketType.name.length < 3) {
      this.toastrService.error("Please enter a longer name");
      return;
    }


    this.ticketTypeService.updateTicketType(this.ticketTypeId, this.ticketType).subscribe(x => {
      this.toastrService.success("Updated Ticket Type");
      this.mainLoading = false;
      this.router.navigateByUrl(`/ticketing/tickettype`);

    })
  }
}
