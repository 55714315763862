import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {TenantHoliday} from '../../../../models/core-setup/tenant-calendar/tenant-holiday';
import {
  TenantHolidayActions,
  TenantHolidayActionTypes
} from '../../../actions/core-masterdata/tenant-calendar/tenant-holiday.actions';
import * as _ from 'lodash';

export interface State extends EntityState<TenantHoliday> {
  error: any;
  loaded: boolean;
  loading: boolean;
  paging: any;
}

export const adapter: EntityAdapter<TenantHoliday> = createEntityAdapter<TenantHoliday>();

export const initialState: State = adapter.getInitialState({
  error: null,
  loaded: false,
  loading: false,
  paging: null
});

export function reducer(state = initialState, action: TenantHolidayActions): State {
  switch (action.type) {
    case TenantHolidayActionTypes.tenantHolidayGetAll:
    case TenantHolidayActionTypes.tenantHolidayRemove:
      return {
        ...state,
        loading: true,
        loaded: false
      };

    case TenantHolidayActionTypes.tenantHolidayRemoveSuccess:
      return adapter.removeOne(action.payload, {
        ...state,
        error: action.payload,
        loading: false,
        loaded: true
      });
    case TenantHolidayActionTypes.tenantHolidayError:
      return adapter.addOne(action.payload, {
        ...state,
        error: action.payload.id,
        loading: false,
        loaded: true
      });

    case TenantHolidayActionTypes.tenantHolidayGetAllSuccess:
      return adapter.setAll(action.payload.content, {
        ...state,
        loading: false,
        loaded: true,
        paging: _.omit(action.payload, ['content'])
      });
    default:
      return state;
  }
}

export const tenantHolidaySelectors = adapter.getSelectors();

