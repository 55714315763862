
<div class="flex flex-row justify-start my-8 mx-4">
  <div class="flex flex-row   gap-3 justify-start">
    <div class="text-xl text-black-dark-light">
      Home
    </div>
    <div class="text-xl font-black text-black-dark-light">
      /
    </div>
    <div class="text-xl text-black-dark-light cursor-pointer hover:text-dlight ease-in-out duration-300" routerLink="/ticketing/projects">
      Projects
    </div>
    <div class="text-xl font-black text-black-dark-light">
      /
    </div>
    <div class="text-xl text-black-dark-light text-orange-600">
      New Project
    </div>
  </div>

</div>

<div class="mt-3" *ngIf="mainLoading">
  <div class="flex flex-row justify-center items-center w-full  gap-4">
    <span class="m-auto  inline-block h-14 w-14 animate-spin rounded-full border-8 border-[#f1f2f3] border-l-dlight align-middle"></span>
  </div>
</div>


<ng-container *ngIf="!mainLoading">
  <div class="w-2/3 m-auto">
    <div style="border-radius:20px" class="bg-white box-shadow-pop p-4">
      <div class="font-semibold text-lg">General Details</div>

      <form>
        <div class="mt-6">
          <label class="label_title">Name
            <span *ngIf="project.name == null || project.name.length < 3" class="text-red-500">*</span>
            <span *ngIf="project.name != null && project.name.length >= 3" class="text-green-500">*</span>
          </label>
          <input class=" form-input form-input-focus" placeholder="Enter a name"  [(ngModel)]="project.name" name="name"/>
        </div>
      </form>
    </div>
  </div>


  <div class="w-2/3 m-auto mt-6">
    <div style="border-radius:20px" class="bg-white box-shadow-pop p-4">
      <div class="font-semibold text-lg">Workflows</div>

      <div class="mt-5">
        <div class="flex flex-row justify-start items-center w-full gap-3 mt-2" *ngFor="let workflow of project.workflows" >
          <div class="font-semibold"> {{workflow.name}} </div>
          <div >
            <button (click)="startEditingWorkflow(workflow)"  type="button" class="btn btn-sm rounded-full btn-outline-dlight "><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="w-5 h-5 ltr:mr-1.5 rtl:ml-1.5 ng-tns-c23-15"><circle _ngcontent-ydi-c23="" cx="12" cy="12" r="3" class="ng-tns-c23-15"></circle><path _ngcontent-ydi-c23="" d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z" class="ng-tns-c23-15"></path></svg>&nbsp;&nbsp;Edit </button>
          </div>
        </div>
      </div>

      <div class="flex flex-row justify-center w-full" *ngIf="!editingWorkflow">
        <button class="btn btn-dlight flex-grow-0" (click)="editingWorkflow=true" >New Workflow</button>
      </div>

      <div class="box-shadow-pop mt-3 p-4" *ngIf="editingWorkflow">
        <div class="font-semibold">Workflow: {{selectedWorkflow.name}}</div>
        <form>
          <div class="mt-6">
            <label class="label_title">Name
              <span *ngIf="selectedWorkflow.name == null || selectedWorkflow.name.length < 3" class="text-red-500">*</span>
              <span *ngIf="selectedWorkflow.name != null && selectedWorkflow.name.length >= 3" class="text-green-500">*</span>
            </label>
            <input class=" form-input form-input-focus" placeholder="Enter a name"  [(ngModel)]="selectedWorkflow.name" name="name"/>
          </div>
          <div class="font-semibold mt-6">Statuses:</div>
          <div *ngFor="let status of selectedWorkflow.statuses" class="mt-1">
            <div class="flex flex-row justify-start items-center gap-2">
              <input type="color" [(ngModel)]="status.colorCode" [ngModelOptions]="{standalone: true}">
              <input class="form-input w-auto" type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="status.name">
              <button   type="button" class="btn btn-sm rounded-full btn-outline-dlight "><svg width="24" height="24" viewBox="0 0 30 30" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path d="M23.01 10.08H6.99q-.36 0-.63.27t-.27.63l1.14 15.15q.03.39.36.645t.9.255h13.02q.54 0 .87-.255t.39-.645l1.14-15.15q0-.36-.27-.63t-.63-.27M10.32 25.23q-.3 0-.54-.27t-.24-.63l-.78-11.58q0-.36.27-.615t.63-.255.63.255.27.615l.54 11.58q0 .36-.225.63t-.555.27m5.46-.93q0 .39-.225.66t-.555.27-.555-.27-.225-.66l-.12-11.55q0-.36.27-.615t.63-.255.63.255.27.615zm4.68.03q0 .36-.24.63t-.54.27q-.33 0-.555-.27t-.225-.63l.54-11.58q0-.36.27-.615t.63-.255.63.255.27.615zm3.03-18.15-5.37-.9q-.36-.06-.45-.45l-.24-.99q-.12-.6-.42-.78-.18-.09-.66-.09h-2.7q-.48 0-.69.09-.3.18-.39.78l-.24.99q-.06.39-.45.45l-5.37.9q-.57.09-.945.435t-.375.855v.84q0 .27.195.45t.465.18h18.3q.27 0 .465-.18t.195-.45v-.84q0-.51-.375-.855t-.945-.435"/></svg>&nbsp;&nbsp;Delete </button>
            </div>
          </div>


          <div class="flex flex-row justify-start items-center gap-2 mt-3">
            <input type="color" [(ngModel)]="newStatus.colorCode" [ngModelOptions]="{standalone: true}">
            <input class="form-input w-auto" type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="newStatus.name">
            <button class="btn btn-dlight flex-grow-0" (click)="addStatusToWorkflow(selectedWorkflow)" >Add New Status</button>
          </div>

        </form>


        <div class="mt-6">
          <div class="font-semibold">Transitions:</div>
          <div class="grid grid-cols-3" *ngFor="let transition of selectedWorkflow.transitions">
            <div class="col-span-1">
              <span class="badge bg-warning rounded-full text-white" [ngStyle]="{'background-color': transition.fromColorCode, 'color': transition.fromTextColorCode}">{{transition.fromName}}</span>

            </div>
            <div class="col-span-1">
              <span class="badge bg-warning rounded-full text-white" [ngStyle]="{'background-color': transition.toColorCode, 'color' : transition.toTextColorCode}">{{transition.toName}}</span>
            </div>
            <div class="col-span-1">
              <input type="checkbox" [(ngModel)]="transition.enabled" [ngModelOptions]="{standalone: true}">
            </div>
          </div>
        </div>

        <div class="flex flex-row justify-center w-full mt-5">
          <button  (click)="addWorkflow()" type="button" class=" btn btn-success btn-lg">Save</button>
        </div>
      </div>

    </div>
  </div>


</ng-container>

<ng-container *ngIf="showingChart">
  <app-library [nodeList]="nodeList" [linkList]="linkList"></app-library>

</ng-container>

<div style="min-height:150px"></div>
<button class="w-full btn btn-success" (click)="add()"></button>
