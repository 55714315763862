import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';

import {ContractVerificationReason} from '@appModels/core-setup/contract-verification-reason/contract-verification-reason';

import {CoreMasterDataService} from '../core-masterdata.service';


@Injectable({providedIn: 'root'})
export class ContractVerificationReasonService extends CoreMasterDataService {


  constructor(
    private http: HttpClient,
  ) {
    super(http, 'reasons');
  }

  /** GET contractVerificationReasons from the server */
  getBlacklistedReasons(): Observable<ContractVerificationReason[]> {
    return this.http.get<ContractVerificationReason[]>(this.base_url + '/blacklisted/' + localStorage.getItem('tenant'), {headers: this.headers}).pipe(
      tap(contractBlacklistedVerificationReasons => this.log(`fetched contractBlacklistedVerificationReasons`)),
      catchError(this.handleError('getContractBlacklistedVerificationReasons', []))
    );
  }

  /** GET contractVerificationReasons from the server */
  getRejectedReasons(): Observable<ContractVerificationReason[]> {
    return this.http.get<ContractVerificationReason[]>(this.base_url + '/rejected/' + localStorage.getItem('tenant'), {headers: this.headers}).pipe(
      tap(contractRejectedVerificationReasons => this.log(`fetched contractRejectedVerificationReasons`)),
      catchError(this.handleError('getContractRejectedVerificationReasons', []))
    );
  }


  /** GET contractVerificationReason by id. Will 404 if id not found */
  getContractVerificationReason(id: number): Observable<ContractVerificationReason> {
    const url = `${this.base_url}/${id}`;
    return this.http.get<ContractVerificationReason>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched contractVerificationReason id=${id}`)),
      catchError(this.handleError<ContractVerificationReason>(`getContractVerificationReason id=${id}`))
    );
  }


  //////// Save methods //////////

  /** POST: add a new blacklistedContractVerificationReason to the server */
  addBlacklistedReason(contractVerificationReason: ContractVerificationReason): Observable<ContractVerificationReason> {
    return this.http.post<ContractVerificationReason>(this.base_url + '/blacklisted/' + localStorage.getItem('tenant'), contractVerificationReason, {headers: this.headers}).pipe(
      tap((contractVerificationReason: ContractVerificationReason) => this.log(`added blacklistedContractVerificationReason w/ id=${contractVerificationReason.id}`)),
      catchError(this.handleError<ContractVerificationReason>('blacklistedContractVerificationReason'))
    );
  }

   /** POST: add a new rejectedContractVerificationReason to the server */
   addRejectedReason(contractVerificationReason: ContractVerificationReason): Observable<ContractVerificationReason> {
    return this.http.post<ContractVerificationReason>(this.base_url + '/rejected/' + localStorage.getItem('tenant'), contractVerificationReason, {headers: this.headers}).pipe(
      tap((contractVerificationReason: ContractVerificationReason) => this.log(`added rejectedContractVerificationReason w/ id=${contractVerificationReason.id}`)),
      catchError(this.handleError<ContractVerificationReason>('rejectedContractVerificationReason'))
    );
  }

  /** DELETE: delete the contractVerificationReason from the server */
  deleteContractVerificationReason(contractVerificationReason: ContractVerificationReason | number): Observable<ContractVerificationReason> {
    const id = typeof contractVerificationReason === 'number' ? contractVerificationReason : contractVerificationReason.id;
    const url = `${this.base_url}/${id}`;

    return this.http.delete<ContractVerificationReason>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`deleted contractVerificationReason id=${id}`)),
      catchError(this.handleError<ContractVerificationReason>('deleteContractVerificationReason'))
    );
  }

  /** PUT: update the contractVerificationReason on the server */
  updateContractVerificationReason(contractVerificationReason: any): Observable<any> {
    return this.http.put(`${this.base_url}/${contractVerificationReason.id}`, contractVerificationReason, {headers: this.headers}).pipe(
      tap(_ => this.log(`updated contractVerificationReason id=${contractVerificationReason.id}`)),
      catchError(this.handleError<any>('updateContractVerificationReason'))
    );
  }


}
