import {createEntityAdapter, EntityAdapter, EntityState,} from '@ngrx/entity';

import {CommissionReport} from '@appModels/core-fieldforce/commission-report/commission-report';
import {
  CommissionReportActions,
  CommissionReportActionTypes
} from '@appStore/actions/core-fieldforce/commission-report/commission-report.actions';
import * as _ from 'lodash';

export interface State extends EntityState<CommissionReport> {
  loaded: boolean;
  loading: boolean;
  error: any;
  selectedCommissionReportId: number;
  searchCommissionsReport: CommissionReport[];
  paging: any;
}

export const adapter: EntityAdapter<CommissionReport> = createEntityAdapter<CommissionReport>();

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
  selectedCommissionReportId: null,
  error: null,
  searchTerm: '',
  searchCommissionsReport: null,
  paging: null
});

export function reducer(state = initialState, action: CommissionReportActions): State {
  switch (action.type) {
    case CommissionReportActionTypes.commissionReportGetCommissions:
    case CommissionReportActionTypes.commissionReportSearchCommissions:
    case CommissionReportActionTypes.commissionReportGetCommissionById:
    case CommissionReportActionTypes.commissionReportGetCommissionsByTenantId:
    case CommissionReportActionTypes.commissionReportFilterCommission:
    case CommissionReportActionTypes.commissionReportMonthlyFilterCommission:

      return {
        ...state,
        loading: true
      };

    case CommissionReportActionTypes.commissionReportGetCommissionsSuccess:
      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });

    case CommissionReportActionTypes.commissionReportFilterCommissionSuccess:
    case CommissionReportActionTypes.commissionReportMonthlyFilterCommissionSuccess:
      return adapter.setAll(action.payload.content, {
        ...state,
        loading: false,
        loaded: true,
        paging: _.omit(action.payload, ['content'])
      });

    case CommissionReportActionTypes.commissionReportSearchCommissionsSuccess:
      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });

    case CommissionReportActionTypes.commissionReportGetCommissionByIdSuccess:
      return {
        ...state,
        selectedCommissionReportId: action.payload.id,
        loading: false
      };


    case CommissionReportActionTypes.commissionReportGetCommissionsByTenantIdSuccess:
      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });


    case CommissionReportActionTypes.commissionReportSearchCommissionsReset:
      return {
        ...state,
        searchCommissionsReport: null
      };

    case CommissionReportActionTypes.commissionsReportError:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload
      };

    default:
      return state;
  }
}

export const commissionReportEntitySelectors = adapter.getSelectors();
