import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { Module } from '@appModels/core-setup/module/module';

import { CoreMasterDataService } from '../core-masterdata.service';



@Injectable({ providedIn: 'root' })
export class ModuleService extends CoreMasterDataService {


  constructor(
    private http: HttpClient,

  ) {
    super(http, 'module');
  }

  /** GET modules from the server */
  getModules(): Observable<Module[]> {
    return this.http.get<Module[]>(this.base_url , { headers: this.headers }).pipe(
      tap(modules => this.log(`fetched modules`)),
      catchError(this.handleError('getModules', []))
    );
  }

  /** GET module by id. Return `undefined` when id not found */
  getModuleNo404<Data>(id: number): Observable<Module> {
    const url = `${this.base_url}/?id=${id}`;
    return this.http.get<Module[]>(url).pipe(
      map(modules => modules[0]), // returns a {0|1} element array
      tap(h => {
        const outcome = h ? `fetched` : `did not find`;
        this.log(`${outcome} module id=${id}`);
      }),
      catchError(this.handleError<Module>(`getModule id=${id}`))
    );
  }

  /** GET module by id. Will 404 if id not found */
  getModule(id: number): Observable<Module> {
    const url = `${this.base_url}/${id}`;
    return this.http.get<Module>(url,{ headers: this.headers }).pipe(
      tap(_ => this.log(`fetched module id=${id}`)),
      catchError(this.handleError<Module>(`getModule id=${id}`))
    );
  }

  /* GET modules whose name contains search term */
  searchModules(term: string): Observable<Module[]> {
    if (!term.trim()) {
      // if not search term, return empty module array.
      return of([]);
    }
    return this.http.get<Module[]>(`api/modules/?name=${term}`).pipe(
      tap(_ => this.log(`found modules matching "${term}"`)),
      catchError(this.handleError<Module[]>('searchModules', []))
    );
  }

  //////// Save methods //////////

  /** POST: add a new module to the server */
  addModule(module: Module): Observable<Module> {
    return this.http.post<Module>(this.base_url , module, { headers: this.headers }).pipe(
      tap((module: Module) => this.log(`added module w/ id=${module.id}`)),
      catchError(this.handleError<Module>('addModule'))
    );
  }

  /** DELETE: delete the module from the server */
  deleteModule(module: Module | number): Observable<Module> {
    const id = typeof module === 'number' ? module : module.id;
    const url = `${this.base_url}/${id}`;

    return this.http.delete<Module>(url, { headers: this.headers }).pipe(
      tap(_ => this.log(`deleted module id=${id}`)),
      catchError(this.handleError<Module>('deleteModule'))
    );
  }

  /** PUT: update the module on the server */
  updateModule(module: Module): Observable<any> {
    return this.http.put(this.base_url, module, { headers: this.headers }).pipe(
      tap(_ => this.log(`updated module id=${module.id}`)),
      catchError(this.handleError<any>('updateModule'))
    );
  }

}
