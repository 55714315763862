import {Action} from '@ngrx/store';

import {ProductCatalogue} from '@appModels/core-setup/product-catalogue/product-catalogue';

export enum ProductCatalogueActionTypes {
  productCatalogueGetProductCatalogues = '[ProductCatalogue] get',
  productCatalogueGetProductCataloguesSuccess = '[ProductCatalogue] get productCatalogues success',
  productCatalogueGetTenantProductCatalogues = '[ProductCatalogue] get tenantProductCatalogues',
  productCatalogueGetTenantProductCataloguesSuccess = '[ProductCatalogue] get tenantProductCatalogues success',
  productCatalogueGetProductCataloguesByType = '[ProductCatalogue] get productCatalogues by type',
  productCatalogueGetProductCataloguesByTypeSuccess = '[ProductCatalogue] get productCatalogues by type success',
  productCatalogueAddProductCatalogue = '[ProductCatalogue] add ProductCatalogue',
  productCatalogueAddProductCatalogueSuccess = '[ProductCatalogue] add productCatalogue success',
  productCatalogueDeleteProductCatalogue = '[ProductCatalogue] delete productCatalogue',
  productCatalogueDeleteProductCatalogueSuccess = '[ProductCatalogue] delete productCatalogue success',
  productCatalogueGetProductCatalogueById = '[ProductCatalogue] get productCatalogue by id',
  productCatalogueGetProductCatalogueByIdSuccess = '[ProductCatalogue] get productCatalogue by id success',
  productCatalogueUpdateProductCatalogue = '[ProductCatalogue] update productCatalogue',
  productCatalogueUpdateProductCatalogueSuccess = '[ProductCatalogue] update productCatalogue success',
  productCatalogueEditProductCatalogue = '[ProductCatalogue] edit productCatalogue',
  productCatalogueEditProductCatalogueSuccess = '[ProductCatalogue] edit productCatalogue success',
  productCatalogueSearchProductCatalogues = '[ProductCatalogue] search productCatalogues',
  productCatalogueSearchProductCataloguesSuccess = '[ProductCatalogue] search productCatalogues success',
  productCatalogueSearchProductCataloguesReset = '[ProductCatalogue] search productCatalogues reset',
  productCatalogueError = '[ProductCatalogue] error'
}

export class GetProductCatalogues implements Action {
  readonly type = ProductCatalogueActionTypes.productCatalogueGetProductCatalogues;
}
export class GetProductCataloguesSuccess implements Action {
  readonly type = ProductCatalogueActionTypes.productCatalogueGetProductCataloguesSuccess;
  constructor(public payload: ProductCatalogue[]) {}
}

export class GetTenantProductCatalogues implements Action {
  readonly type = ProductCatalogueActionTypes.productCatalogueGetTenantProductCatalogues;
}
export class GetTenantProductCataloguesSuccess implements Action {
  readonly type = ProductCatalogueActionTypes.productCatalogueGetTenantProductCataloguesSuccess;
  constructor(public payload: ProductCatalogue[]) {}
}

export class GetProductCataloguesByType implements Action {
  readonly type = ProductCatalogueActionTypes.productCatalogueGetProductCataloguesByType;

  constructor(public tenantId: number, public typeId, public query?) {
  }
}

export class GetProductCataloguesByTypeSuccess implements Action {
  readonly type = ProductCatalogueActionTypes.productCatalogueGetProductCataloguesByTypeSuccess;

  constructor(public payload: ProductCatalogue[]) {
  }
}


export class AddProductCatalogue implements Action {
  readonly type = ProductCatalogueActionTypes.productCatalogueAddProductCatalogue;
  constructor(public payload) {}
}

export class AddProductCatalogueSuccess implements Action {
  readonly type = ProductCatalogueActionTypes.productCatalogueAddProductCatalogueSuccess;
  constructor(public payload: ProductCatalogue) {}
}

export class GetProductCatalogueById implements Action {
  readonly type = ProductCatalogueActionTypes.productCatalogueGetProductCatalogueById;
  constructor(public payload: number) {}
}

export class GetProductCatalogueByIdSuccess implements Action {
  readonly type = ProductCatalogueActionTypes.productCatalogueGetProductCatalogueByIdSuccess;
  constructor(public payload: ProductCatalogue) {}
}

export class UpdateProductCatalogue implements Action {
  readonly type = ProductCatalogueActionTypes.productCatalogueUpdateProductCatalogue;
  constructor(public payload) {
  }
}

export class UpdateProductCatalogueSuccess implements Action {
  readonly type = ProductCatalogueActionTypes.productCatalogueUpdateProductCatalogueSuccess;
  constructor(public payload: ProductCatalogue) {}
}

export class EditProductCatalogue implements Action {
  readonly type = ProductCatalogueActionTypes.productCatalogueEditProductCatalogue;
  constructor(public payload) {}
}

export class EditProductCatalogueSuccess implements Action {
  readonly type = ProductCatalogueActionTypes.productCatalogueEditProductCatalogueSuccess;
  constructor(public payload) {}
}

export class DeleteProductCatalogue implements Action {
  readonly type = ProductCatalogueActionTypes.productCatalogueDeleteProductCatalogue;
  constructor(public payload: ProductCatalogue) {}
}

export class DeleteProductCatalogueSuccess implements Action {
  readonly type = ProductCatalogueActionTypes.productCatalogueDeleteProductCatalogueSuccess;
  constructor(public payload: ProductCatalogue) {}
}

export class SearchProductCatalogues implements Action {
  readonly type = ProductCatalogueActionTypes.productCatalogueSearchProductCatalogues;
  constructor(public payload: string) {}
}

export class SearchProductCataloguesSuccess implements Action {
  readonly type = ProductCatalogueActionTypes.productCatalogueSearchProductCataloguesSuccess;
  constructor(public payload: ProductCatalogue[]) {}
}

export class SearchProductCataloguesReset implements Action {
  readonly type = ProductCatalogueActionTypes.productCatalogueSearchProductCataloguesReset;
}

export class ProductCatalogueError implements Action {
  readonly type = ProductCatalogueActionTypes.productCatalogueError;
  constructor(public payload: any) {}
}

export type ProductCatalogueActions =
  | GetProductCatalogues
  | GetProductCataloguesSuccess
  | GetTenantProductCatalogues
  | GetTenantProductCataloguesSuccess
  | GetProductCataloguesByType
  | GetProductCataloguesByTypeSuccess
  | AddProductCatalogue
  | AddProductCatalogueSuccess
  | GetProductCatalogueById
  | GetProductCatalogueByIdSuccess
  | UpdateProductCatalogue
  | UpdateProductCatalogueSuccess
  | EditProductCatalogue
  | EditProductCatalogueSuccess
  | DeleteProductCatalogue
  | DeleteProductCatalogueSuccess
  | SearchProductCatalogues
  | SearchProductCataloguesSuccess
  | SearchProductCataloguesReset
  | ProductCatalogueError;
