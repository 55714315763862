import { Action } from '@ngrx/store';

import { Timezone } from '@appModels/core-setup/timezone/timezone';

export enum TimezoneActionTypes {
  timezoneGetTimezones = '[Timezone] get',
  timezoneGetTimezonesSuccess = '[Timezone] get timezones success',
  timezoneAddTimezone = '[Timezone] add Timezone',
  timezoneAddTimezoneSuccess = '[Timezone] add timezone success',
  timezoneDeleteTimezone = '[Timezone] delete timezone',
  timezoneDeleteTimezoneSuccess = '[Timezone] delete timezone success',
  timezoneGetTimezoneById = '[Timezone] get timezone by id',
  timezoneGetTimezoneByIdSuccess = '[Timezone] get timezone by id success',
  timezoneUpdateTimezone = '[Timezone] update timezone',
  timezoneUpdateTimezoneSuccess = '[Timezone] update timezone success',
  timezoneSearchTimezones = '[Timezone] search timezones',
  timezoneSearchTimezonesSuccess = '[Timezone] search timezones success',
  timezoneSearchTimezonesReset = '[Timezone] search timezones reset',
  timezoneError = '[Timezone] error'
}

export class GetTimezones implements Action {
  readonly type = TimezoneActionTypes.timezoneGetTimezones;
}

export class GetTimezonesSuccess implements Action {
  readonly type = TimezoneActionTypes.timezoneGetTimezonesSuccess;
  constructor(public payload: Timezone[]) {}
}

export class AddTimezone implements Action {
  readonly type = TimezoneActionTypes.timezoneAddTimezone;
  constructor(public payload: Timezone) {}
}

export class AddTimezoneSuccess implements Action {
  readonly type = TimezoneActionTypes.timezoneAddTimezoneSuccess;
  constructor(public payload: Timezone) {}
}

export class GetTimezoneById implements Action {
  readonly type = TimezoneActionTypes.timezoneGetTimezoneById;
  constructor(public payload: number) {}
}

export class GetTimezoneByIdSuccess implements Action {
  readonly type = TimezoneActionTypes.timezoneGetTimezoneByIdSuccess;
  constructor(public payload: Timezone) {}
}

export class UpdateTimezone implements Action {
  readonly type = TimezoneActionTypes.timezoneUpdateTimezone;
  constructor(public payload: Timezone) {}
}

export class UpdateTimezoneSuccess implements Action {
  readonly type = TimezoneActionTypes.timezoneUpdateTimezoneSuccess;
  constructor(public payload: Timezone) {}
}

export class DeleteTimezone implements Action {
  readonly type = TimezoneActionTypes.timezoneDeleteTimezone;
  constructor(public payload: Timezone) {}
}

export class DeleteTimezoneSuccess implements Action {
  readonly type = TimezoneActionTypes.timezoneDeleteTimezoneSuccess;
  constructor(public payload: Timezone) {}
}

export class SearchTimezones implements Action {
  readonly type = TimezoneActionTypes.timezoneSearchTimezones;
  constructor(public payload: string) {}
}

export class SearchTimezonesSuccess implements Action {
  readonly type = TimezoneActionTypes.timezoneSearchTimezonesSuccess;
  constructor(public payload: Timezone[]) {}
}

export class SearchTimezonesReset implements Action {
  readonly type = TimezoneActionTypes.timezoneSearchTimezonesReset;
}

export class TimezoneError implements Action {
  readonly type = TimezoneActionTypes.timezoneError;
  constructor(public payload: any) {}
}

export type TimezoneActions =
  | GetTimezones
  | GetTimezonesSuccess
  | AddTimezone
  | AddTimezoneSuccess
  | GetTimezoneById
  | GetTimezoneByIdSuccess
  | UpdateTimezone
  | UpdateTimezoneSuccess
  | DeleteTimezone
  | DeleteTimezoneSuccess
  | SearchTimezones
  | SearchTimezonesSuccess
  | SearchTimezonesReset
  | TimezoneError;
