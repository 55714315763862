import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

import {
  AddAdminZone,
  AddAdminZoneSuccess,
  AdminZoneActionTypes,
  AdminZoneError,
  DeleteAdminZone,
  DeleteAdminZoneSuccess,
  GetAdminZoneById,
  GetAdminZoneByIdSuccess,
  GetAdminZonesSuccess,
  SearchAdminZones,
  SearchAdminZonesSuccess,
  UpdateAdminZoneSuccess
} from '@appStore/actions/core-masterdata/admin-zone/admin-zone.actions';
import {AdminZoneService} from '@appServices/core-masterdata/admin-zone/admin-zone.service';

import * as fromRouterActions from '@appStore/actions/router.actions';

@Injectable()
export class AdminZoneEffects {
  constructor(private actions$: Actions, private adminZoneService: AdminZoneService) {}

  // @Effect()
  // loadAdminZones$ = this.actions$.pipe(
  //   ofType(AdminZoneActionTypes.adminZoneGetAdminZones),
  //   mergeMap(() =>
  //     this.adminZoneService
  //       .getAdminZones()
  //       .pipe(
  //         map(adminZones => new GetAdminZonesSuccess(adminZones)),
  //         catchError(error => of(new AdminZoneError(error)))
  //       )
  //   )
  // );

  // load admin zones by country Id
  
  loadAdminZones$ = createEffect(() => this.actions$.pipe(
    ofType(AdminZoneActionTypes.adminZoneGetAdminZones),
    mergeMap(() =>
      this.adminZoneService
        .getAdminZoneByCountry(+localStorage.getItem('countryId'))
        .pipe(
          map(adminZones => new GetAdminZonesSuccess(adminZones)),
          catchError(error => of(new AdminZoneError(error)))
        )
    )
  ));

  
  getAdminZoneById$ = createEffect(() => this.actions$.pipe(
    ofType(AdminZoneActionTypes.adminZoneGetAdminZoneById),
    mergeMap((action: GetAdminZoneById) =>
      this.adminZoneService
        .getAdminZone(action.payload)
        .pipe(
          map(adminZone => new GetAdminZoneByIdSuccess(adminZone)),
          catchError(error => of(new AdminZoneError(error)))
        )
    )
  ));

  
  addAdminZone$ = createEffect(() => this.actions$.pipe(
    ofType(AdminZoneActionTypes.adminZoneAddAdminZone),
    switchMap((action: AddAdminZone) =>

      this.adminZoneService
        .addAdminZone(action.payload)
        .pipe(
          map(adminZone => new AddAdminZoneSuccess(adminZone)),
          catchError(error => of(new AdminZoneError(error)))
        )
    )
  ));

  
  updateAdminZone$ = createEffect(() => this.actions$.pipe(
    ofType(AdminZoneActionTypes.adminZoneUpdateAdminZone),
    mergeMap((action: AddAdminZone) =>
      this.adminZoneService
        .updateAdminZone(action.payload)
        .pipe(
          map(adminZone => new UpdateAdminZoneSuccess(action.payload)),
          catchError(error => of(new AdminZoneError(error)))
        )
    )
  ));

  
  deleteAdminZone$ = createEffect(() => this.actions$.pipe(
    ofType(AdminZoneActionTypes.adminZoneDeleteAdminZone),
    mergeMap((action: DeleteAdminZone) =>
      this.adminZoneService
        .deleteAdminZone(action.payload)
        .pipe(
          map(() => new DeleteAdminZoneSuccess(action.payload)),
          catchError(error => of(new AdminZoneError(error)))
        )
    )
  ));

  
  searchAdminZones$ = createEffect(() => this.actions$.pipe(
    ofType(AdminZoneActionTypes.adminZoneSearchAdminZones),
    mergeMap((action: SearchAdminZones) =>
      this.adminZoneService
        .searchAdminZones(action.payload)
        .pipe(
          map(adminZones => new SearchAdminZonesSuccess(adminZones)),
          catchError(error => of(new AdminZoneError(error)))
        )
    )
  ));

  
  updateAdminZoneSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(AdminZoneActionTypes.adminZoneUpdateAdminZoneSuccess),
    map(adminZone => new fromRouterActions.Go({ path: ['/setup/admin-zones'] }))
  ));
}
