import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getSalesStaffStore = createFeatureSelector('salesStaff');

export const getSalesStaffEntities = createSelector(
  getSalesStaffStore,
  fromReducers.salesStaff.salesStaffEntitySelectors.selectAll
);

export const getSalesStaffPaging = createSelector(
  getSalesStaffStore,
  (salesStaffStore: fromReducers.salesStaff.State) => salesStaffStore.paging
);


export const getPossibleLineManagers = createSelector(getSalesStaffEntities, entities => {
  return Object.values(entities);
});

export const getSalesStaffs = createSelector(getSalesStaffEntities, entities => {
  return Object.values(entities);
});

export const getTopSalesStaffs = createSelector(getSalesStaffEntities, entities => {
  return Object.values(entities).slice(1, 5);
});

export const getSalesStaffsLoaded = createSelector(
  getSalesStaffStore,
  (salesStaffStore: fromReducers.salesStaff.State) => salesStaffStore.loaded
);

export const getSalesStaffsLoading = createSelector(
  getSalesStaffStore,
  (salesStaffStore: fromReducers.salesStaff.State) => salesStaffStore.loading
);

export const getSelectedSalesStaffId = createSelector(
  getSalesStaffStore,
  (salesStaffStore: fromReducers.salesStaff.State) => salesStaffStore.selectedSalesStaffId
);
export const getSelectedSalesStaff = createSelector(
  getSalesStaffStore,
  (salesStaffStore: fromReducers.salesStaff.State) => salesStaffStore.selectedSalesStaff
);

export const getSearchSalesStaffs = createSelector(
  getSalesStaffStore,
  (salesStaffStore: fromReducers.salesStaff.State) => salesStaffStore.searchSalesStaffs
);

export const getSalesStaffById = createSelector(
  getSalesStaffEntities,
  getSelectedSalesStaffId,
  (entities, id) => entities.find(i => i.id === id)
);

export const getSalesStaffError = createSelector(
  getSalesStaffStore,
  (salesStaffStore: fromReducers.salesStaff.State) => salesStaffStore.error
);
