<div class="mt-3" *ngIf="mainLoading">
  <div class="flex flex-row justify-center items-center w-full  gap-4">
    <span class="m-auto  inline-block h-14 w-14 animate-spin rounded-full border-8 border-[#f1f2f3] border-l-dlight align-middle"></span>
  </div>
</div>

<div *ngIf="!mainLoading">


  <div >
    <div  >
      <form>
        <div class="form_group mt-3">
          <div class="input">
            <label>{{'Dealer/Stocking Point' | translate}}</label>
            <input type="text" class="form-input form-input-focus" (keyup)="getStockingPoints($event);" [(ngModel)]="dealerQuery" name="dealerQuery"
                   placeholder="search dealer">
            <div>
              <div *ngIf="checkDealerLoading || (stockingPoints != null && stockingPoints.length > 0)" style="padding: 10px; cursor: pointer;">
                <div class="dropdown">{{'Loading' | translate}}...</div>
                <div *ngFor="let stockingPoint of stockingPoints" (click)="selectDealer(stockingPoint)" class="dropdown">
                  {{ stockingPoint.businessName}} {{'(Dealer id: '}} {{ stockingPoint.dealerId + ' )'}}
                </div>
              </div>
            </div>
            <div class="notification error mt-3" *ngIf="!formValid.stockingPointId">
              {{'Dealer/Stocking Point is Invalid' | translate}}
            </div>
          </div>
        </div>

        <div class="form_group">
          <div class="input">
            <label>{{ 'Region' | translate }}</label>
            <input *ngIf="countryAdminZone.id" type="text" class="form-input form-input-focus" [(ngModel)]="countryAdminZone.countryAdministrativeZoneName" name="countryAdminZoneId" disabled/>
          </div>
        </div>

        <div class="form_group">
          <div class="input">
            <label>{{'Type' | translate}}</label>
            <select name="status" class="form-input form-input-focus"  (change)="setReturnType($event.target.value)">
              <option [ngValue]="type"
                      *ngFor="let type of returnType"> {{type | translate}}</option>
            </select>
          </div>
        </div>


        <div *ngIf="categories != null && categories != undefined && categories.length > 0" class="form_group">
          <div class="input">
            <label>{{'Categories' | translate}}</label>
            <select name="category" class="form-input form-input-focus"  [(ngModel)]="returnTicket.categoryId">
              <option [ngValue]="category.id"
                      *ngFor="let category of categories"> {{category.name}}</option>
            </select>
          </div>
        </div>


        <div *ngIf="repairCenters != null && repairCenters != undefined && repairCenters.length > 0" class="form_group">
          <div class="input">
            <label>{{'Repair Center' | translate}}</label>
            <select name="status" class="form-input form-input-focus" [(ngModel)]="returnTicket.repairCentreId">
              <option [ngValue]="repair.id" *ngFor="let repair of repairCenters"> {{repair.name}}</option>
            </select>
            <div class="notification error m-t-20" [hidden]="formValid.repairCentreId">
              {{'Repair centre is required' | translate}}
            </div>
          </div>
        </div>


        <div class="form_group">
          <div class="input">
            <label>{{'Reason for Return' | translate}}</label>
            <input type="text" class="form-input form-input-focus" [(ngModel)]="returnTicket.reasonForReturn" name="reasonForReturn" placeholder="">
            <div class="notification error"
                 [hidden]="formValid.reasonForReturn">{{'Reason For Return is Invalid' | translate}}
            </div>
          </div>
        </div>
        <div class="form_group">
          <div class="input">
            <label>{{'Customer Product Surrender Form' | translate}} </label>
            <input class="file" class="form-input form-input-focus" type="file" (change)="onSurrenderFormChange($event)" name="customer-surrender-form-file" placeholder="">
            <div class="notification error"
                 [hidden]="formValid.surrenderForm">{{'Please upload a Customer Product Surrender Form' | translate}}
            </div>
          </div>
        </div>
        <div class="form_group">
          <div class="input">
            <label>{{'Notes' | translate}}</label>
            <textarea class="form-input form-input-focus" [(ngModel)]="returnTicket.notes" name="notes" rows="5"
                      placeholder="Any additional notes..."></textarea>
          </div>
        </div>
        <div class="form_group">
          <div *ngIf="returnTicket.items != null && returnTicket.items != undefined && returnTicket.items.length > 0" class="datagrid" style="width: 100%">
            <label>{{'Subunits' | translate}}</label>
            <table>
              <tbody>
              <tr class="ticket-row" *ngFor="let subunit of returnTicket.items; let i = index">
                <td width="2%"><input [(ngModel)]="subunit.checked" type="checkbox" name="checked"></td>
                <td>{{subunit.name}}</td>
                <td>
                  <select class="form-input form-input-focus" [(ngModel)]="subunit.status" name="status">
                    <option value="" selected disabled>Select Status</option>
                    <option [ngValue]="status"
                            *ngFor="let status of productStates">{{status | translate}}</option>
                  </select>
                </td>
                <td>
<!--                  <input class="form-input form-input-focus btn btn-dlight" type="file" name="file" (change)="onFileChange($event,subunit) ">-->
<!--                  <div *ngIf="!validFile[subunit.id]" class="notification error m-t-5" >{{'Attachment is required' | translate}} </div>-->
<!--                  -->
                  <div class="flex flex-row justify-start">
                    <div >
                      <div class="flex">
                        <button type="button" class="btn btn-dlight rounded-r-none" (click)="triggerFileUploadButton(subunit)">Choose File</button>
                        <input id="addonsLeft" type="text"  placeholder="No File Selected" name="{{'file' + subunit.internalId}}" [(ngModel)]="subunit.fileName" style="width:auto" class="form-input-focus rounded-l-none" disabled />
                        <input type="file" id="{{'fileupload' + subunit.internalId}}" (change)="onFileChange($event,subunit)"  hidden>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="!validFile[subunit.id]" class="notification error m-t-5" >{{'Attachment is required' | translate}} </div>

                </td>
              </tr>
              </tbody>
            </table>
            <div class="notification error"
                 *ngIf="!formValid.subunits">{{'All Subunits and Product Surrender Form must be added' | translate}}
            </div>
          </div>
        </div>

        <div class="mt-3 flex flex-row justify-center w-full">
          <button class="btn btn-success" type="button" [disabled]="loading && disableSaveButton" (click)="returnProduct()" >Save</button>
        </div>
      </form>
    </div>

  </div>
</div>
