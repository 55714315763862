
<div class="flex flex-row justify-start my-8 mx-4">
  <div class="flex flex-row   gap-3 justify-start">
    <div class="text-xl text-black-dark-light">
      Home
    </div>
    <div class="text-xl font-black text-black-dark-light">
      /
    </div>
    <div class="text-xl text-black-dark-light  cursor-pointer hover:text-dlight ease-in-out duration-300" routerLink="/ticketing/calltoactionrules">
      Call To Action Rules
    </div>
    <div class="text-xl font-black text-black-dark-light">
      /
    </div>
    <div class="text-xl text-black-dark-light text-orange-600">
      New Rules
    </div>
  </div>

</div>

<div class="w-3/4 m-auto mt-3">
  <div style="border-radius:20px" class="bg-white box-shadow-pop p-4">
    <div class="flex flex-row justify-start gap-3">
      <div class="font-semibold text-lg">General Details</div>
    </div>

    <div class="mt-3" >
      <div class="text-semibold" >Name
        <span *ngIf="callToActionRuleMapping.name == null || callToActionRuleMapping.name == undefined || callToActionRuleMapping.name.length < 3" class="text-red-500">*</span>
        <span *ngIf="callToActionRuleMapping.name != null && callToActionRuleMapping.name != undefined && callToActionRuleMapping.name.length >=3 " class="text-green-500">*</span>
      </div>
      <div class="mt-1">
            <input type="text" class="form-input form-input-focus" [(ngModel)]="callToActionRuleMapping.name" name="mappingName">
      </div>
      <div class="text-semibold mt-3">Workflow
        <span *ngIf="callToActionRuleMapping.workflowId == null || callToActionRuleMapping.workflowId == undefined" class="text-red-500">*</span>
        <span *ngIf="callToActionRuleMapping.workflowId != null && callToActionRuleMapping.workflowId != undefined" class="text-green-500">*</span>
      </div>
      <div class="mt-1">
        <select  (change)="selectedWorkflow()" class="select_input form-input form-input-focus" placeholder="Select Workflow..."  [(ngModel)]="callToActionRuleMapping.workflowId" [ngModelOptions]="{standalone: true}" >
          <option value="" disabled>{{'Please select Workflow' | translate}}</option>
          <option [value]="workflow.id" *ngFor="let workflow of workflows"> {{workflow.name}} </option>
        </select>
      </div>
    </div>
  </div>
</div>

<div class="w-3/4 m-auto mt-3">
  <div style="border-radius:20px" class="bg-white box-shadow-pop p-4">
    <div class="flex flex-row justify-start gap-3">
      <div class="font-semibold text-lg">Rules</div>
    </div>

    <div class="bg-white ticket-table w-full mt-3" style="border-radius:20px">
      <div class="grid grid-cols-3 py-3 ticket-row w-full">
        <div class="col-span-1 m-auto">
          STATUS
        </div>
        <div class="col-span-1 m-auto">
          TYPE
        </div>
        <div class="col-span-1 m-auto">
          ACTIONS
        </div>

      </div>
    </div>

    <div *ngFor="let rule of callToActionRuleMapping.rules"  class=" bg-white ticket-table w-full relative z-10" style="border-radius:20px;">
      <div class="grid grid-cols-3 py-3 ticket-row w-full">
        <div (click)="rule.expanded = !rule.expanded" class="cursor-pointer col-span-1 m-auto flex flex-row justify-center gap-1 items-center">
          <svg class="ease-in-out duration-300" [ngClass]="{'rotate-90' : rule.expanded}" width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 5L15 12L9 19" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
          </svg>
          <div >
            <span class="badge bg-warning rounded-full text-white" [ngStyle]="{'background-color': rule.ticketStatusColorCode, 'color' : rule.ticketStatusTextColor}">{{rule.ticketStatusName}}</span>

          </div>
        </div>
        <div class="col-span-1 m-auto">
          {{ rule.callToActionTypeName }}
        </div>
        <div class="col-span-1 m-auto">
          <button (click)="removeRule(rule)" type="button" class="btn btn-sm rounded-full btn-outline-dlight "><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" xml:space="preserve" width="24" height="24"><path fill="none" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" d="M17.25 20.25h-9c-.825 0-1.5-.675-1.5-1.5V6h12v12.75c0 .825-.675 1.5-1.5 1.5zm3-14.25h-15m5.25 0V4.5c0-.45.3-.75.75-.75h3c.45 0 .75.3.75.75V6m-2.25 11.25V9m3 8.25V9m-6 8.25V9"/></svg>&nbsp;&nbsp;Remove </button>
        </div>
      </div>

      <ng-container>
      <div  [@slideDownUp]="!rule.expanded" class="relative z-20"  [ngClass]="{'mb-8':rule.expanded}">
        <div *ngIf="rule.messageTemplate != null" class="p-3">
          <div class="text-semibold" >Message Template:</div>
          <div class="mt-1">
            <textarea
              [mention]="placeholders" [mentionConfig]="{triggerChar:'$'}" [(ngModel)]="rule.messageTemplate"
              class="form-input form-input-focus w-full" rows="2" style="border-radius:10px"></textarea>
          </div>
          <div class="text-sm text-gray-400"> Type '$' to get Placeholders</div>
        </div>
      </div>
      </ng-container>


    </div>
  </div>



  <div style="border-radius:20px" class="bg-white box-shadow-pop p-4  mt-7">
    <div class="flex flex-row justify-start gap-3">
      <div class="font-semibold text-lg">New Rule</div>
    </div>

    <div class="grid grid-cols-2 mt-3 gap-3">
    <div class="col-span-1">
      <label class="label_title">Status Trigger
        <span *ngIf="newCallToActionRule.ticketStatusId == null" class="text-red-500">*</span>
        <span *ngIf="newCallToActionRule.ticketStatusId != null" class="text-green-500">*</span></label>
      <select [disabled]="callToActionRuleMapping.workflowId == null" class="select_input form-input form-input-focus" placeholder="Select Status..."  [(ngModel)]="newCallToActionRule.ticketStatusId" name="ticketStatusId">
        <option value="" disabled>{{'Please select Workflow' | translate}}</option>
        <option [value]="status.id" *ngFor="let status of ticketStatusList"> {{status.name}} </option>
      </select>
    </div>

      <div class="col-span-1">
        <label class="label_title">Call To Action Type
          <span *ngIf="newCallToActionRule.callToActionTypeId == null" class="text-red-500">*</span>
          <span *ngIf="newCallToActionRule.callToActionTypeId != null" class="text-green-500">*</span></label>
        <select [disabled]="callToActionRuleMapping.workflowId == null" class="select_input form-input form-input-focus" placeholder="Select Type..."  [(ngModel)]="newCallToActionRule.callToActionTypeId" name="callToActionTypeId">
          <option value="" disabled>{{'Please select Type' | translate}}</option>
          <option [value]="type.id" *ngFor="let type of callToActionTypes"> {{type.name}} </option>
        </select>
      </div>
    </div>

    <div class="mt-2" >
      <div  >Message Template:</div>
      <div class="mt-1">
            <textarea
              [disabled]="callToActionRuleMapping.workflowId == null || newCallToActionRule.callToActionTypeId == null || newCallToActionRule.ticketStatusId == null"
              [mention]="placeholders" [mentionConfig]="{triggerChar:'$'}" [(ngModel)]="newCallToActionRule.messageTemplate"
              class="form-input form-input-focus w-full" rows="2" style="border-radius:10px"></textarea>
      </div>
      <div class="text-sm text-gray-400"> Type '$' to get Placeholders</div>
    </div>

    <div class="flex flex-row mt-3 justify-center w-full">
      <div class="mt-3" *ngIf="loadingAdding">
        <div class="flex flex-row justify-center items-center w-full  gap-4">
          <span class="m-auto  inline-block h-14 w-14 animate-spin rounded-full border-8 border-[#f1f2f3] border-l-dlight align-middle"></span>
        </div>
      </div>
      <button *ngIf="!loadingAdding" class="btn btn-dlight flex-grow-0" (click)="addRule()">Add Rule</button>
    </div>


  </div>
</div>

<div class="mt-5 flex flex-row justify-center">
  <div class="mt-3" *ngIf="loadingCreate">
    <div class="flex flex-row justify-center items-center w-full  gap-4">
      <span class="m-auto  inline-block h-14 w-14 animate-spin rounded-full border-8 border-[#f1f2f3] border-l-dlight align-middle"></span>
    </div>
  </div>
  <button *ngIf="!loadingCreate" class="btn btn-success flex-grow-0" (click)="createRulesMapping()">Create Rules Mapping</button>
</div>

