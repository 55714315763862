import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { mergeMap, map, catchError, tap, debounceTime, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import {
  CustomerStatusActionTypes,
  GetCustomerStatuses,
  GetCustomerStatusesSuccess,
  CustomerStatusError,
  AddCustomerStatus,
  AddCustomerStatusSuccess,
  DeleteCustomerStatusSuccess,
  DeleteCustomerStatus,
  GetCustomerStatusById,
  GetCustomerStatusByIdSuccess,
  UpdateCustomerStatusSuccess,
  SearchCustomerStatuses,
  SearchCustomerStatusesSuccess
} from '@appStore/actions/core-crm/customer-status/customer-status.actions';
import { CustomerStatusService } from '@appServices/core-crm/customer-status/customer-status.service';
import { TenantService } from '@appServices/core-masterdata/tenant/tenant.service';

import * as fromRouterActions from '@appStore/actions/router.actions';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class CustomerStatusEffects {
  constructor(
    private actions$: Actions, 
    private customerStatusService: CustomerStatusService,
    private toastr: ToastrService
    ) {}

  
  loadCustomerStatuses$ = createEffect(() => this.actions$.pipe(
    ofType(CustomerStatusActionTypes.customerStatusGetCustomerStatuses),
    mergeMap(() =>
      this.customerStatusService
        .getCustomerStatuses()
        .pipe(
          map(customerStatuses => new GetCustomerStatusesSuccess(customerStatuses)),
          catchError(error => of(new CustomerStatusError(error)))
        )
    )
  ));

  
  getCustomerStatusById$ = createEffect(() => this.actions$.pipe(
    ofType(CustomerStatusActionTypes.customerStatusGetCustomerStatusById),
    mergeMap((action: GetCustomerStatusById) =>
      this.customerStatusService
        .getCustomerStatus(action.payload)
        .pipe(
          map(customerStatus => new GetCustomerStatusByIdSuccess(customerStatus)),
          catchError(error => of(new CustomerStatusError(error)))
        )
    )
  ));

  
  addCustomerStatus$ = createEffect(() => this.actions$.pipe(
    ofType(CustomerStatusActionTypes.customerStatusAddCustomerStatus),
    switchMap((action: AddCustomerStatus) =>
      this.customerStatusService
        .addCustomerStatus(action.payload)
        .pipe(
          map(
            customerStatus => {
              if (customerStatus !== undefined) {
                this.toastr.success('Status has been successfully added!', 'Successful!')
                return new AddCustomerStatusSuccess(customerStatus)
              }
              this.toastr.error('There was an error adding status!', "Unknown error")
              return new CustomerStatusError({ type: 500, message: 'Internal error' })

            }),
          catchError(error => of(new CustomerStatusError(error)))
        )
    )
  ));

    
  addAdditionalCustomerStatusInfo$ = createEffect(() => this.actions$.pipe(
    ofType(CustomerStatusActionTypes.customerStatusAddAdditionalCustomerStatusInfo),
    mergeMap((action: AddCustomerStatus) =>
      this.customerStatusService
        .addCustomerStatusAdditionalInfo(action.payload)
        .pipe(
          map(customerStatus => new GetCustomerStatuses()),
          catchError(error => of(new CustomerStatusError(error)))
        )
    )
  ));

  
  updateCustomerStatus$ = createEffect(() => this.actions$.pipe(
    ofType(CustomerStatusActionTypes.customerStatusUpdateCustomerStatus),
    mergeMap((action: AddCustomerStatus) =>
      this.customerStatusService
        .updateCustomerStatus(action.payload)
        .pipe(
          map(customerStatus => new UpdateCustomerStatusSuccess(action.payload)),
          catchError(error => of(new CustomerStatusError(error)))
        )
    )
  ));

  
  deleteCustomerStatus$ = createEffect(() => this.actions$.pipe(
    ofType(CustomerStatusActionTypes.customerStatusDeleteCustomerStatus),
    mergeMap((action: DeleteCustomerStatus) =>
      this.customerStatusService
        .deleteCustomerStatus(action.payload)
        .pipe(
          map(() => new DeleteCustomerStatusSuccess(action.payload)),
          catchError(error => of(new CustomerStatusError(error)))
        )
    )
  ));

  
  searchCustomerStatuses$ = createEffect(() => this.actions$.pipe(
    ofType(CustomerStatusActionTypes.customerStatusSearchCustomerStatuses),
    mergeMap((action: SearchCustomerStatuses) =>
      this.customerStatusService
        .searchCustomerStatuses(action.payload)
        .pipe(
          map(customerStatuses => new SearchCustomerStatusesSuccess(customerStatuses)),
          catchError(error => of(new CustomerStatusError(error)))
        )
    )
  ));

  
  updateCustomerStatusSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(CustomerStatusActionTypes.customerStatusUpdateCustomerStatusSuccess),
    map(customerStatus => new fromRouterActions.Go({ path: ['/crm/customer/status'] }))
  ));
}
