import {Action} from '@ngrx/store';
import {TenantHoliday} from '../../../../models/core-setup/tenant-calendar/tenant-holiday';

export enum TenantHolidayActionTypes {
  tenantHolidayGetAll = '[Tenant Holiday] Get All',
  tenantHolidayGetAllSuccess = '[Tenant Holiday] Get All Success',
  tenantHolidaySave = '[Tenant Holiday] Save',
  tenantHolidaySaveSuccess = '[Tenant Holiday] Save Success',
  tenantHolidayRemove = '[Tenant Holiday] Remove',
  tenantHolidayRemoveSuccess = '[Tenant Holiday] Remove Success',
  tenantHolidayError = '[Tenant Holiday] Error',
}

export class GetAllTenantHoliday implements Action {
  readonly type = TenantHolidayActionTypes.tenantHolidayGetAll;

  constructor(public payload: any) {
  }
}

export class GetAllTenantHolidaySuccess implements Action {
  readonly type = TenantHolidayActionTypes.tenantHolidayGetAllSuccess;

  constructor(public payload: any) {
  }
}

export class SaveTenantHoliday implements Action {
  readonly type = TenantHolidayActionTypes.tenantHolidaySave;

  constructor(public payload: TenantHoliday[]) {
  }
}

export class SaveTenantHolidaySuccess implements Action {
  readonly type = TenantHolidayActionTypes.tenantHolidaySaveSuccess;

  constructor() {
  }
}

export class RemoveTenantHoliday implements Action {
  readonly type = TenantHolidayActionTypes.tenantHolidayRemove;

  constructor(public payload: number) {
  }
}

export class RemoveTenantHolidaySuccess implements Action {
  readonly type = TenantHolidayActionTypes.tenantHolidayRemoveSuccess;

  constructor(public payload: number) {
  }
}

export class TenantHolidayError implements Action {
  readonly type = TenantHolidayActionTypes.tenantHolidayError;

  constructor(public payload: any) {
  }
}


export type TenantHolidayActions =
  | GetAllTenantHoliday
  | GetAllTenantHolidaySuccess
  | TenantHolidayError
  | SaveTenantHoliday
  | SaveTenantHolidaySuccess
  | RemoveTenantHoliday
  | RemoveTenantHolidaySuccess;




