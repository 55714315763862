import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {of} from "rxjs";
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {
  GetProductPrices,
  GetProductPricesSuccess,
  ProductPartPriceActionTypes,
  ProductPriceError,
  SaveProductPrice,
  SaveProductPriceSuccess,
  UpdateProductPrice,
  UpdateProductPriceSuccess
} from '../../../actions/core-masterdata/product-part-price/product-part-price.actions';
import {ProductPartPriceService} from '../../../../services/core-masterdata/product-part-price/product-part-price.service';

@Injectable()
export class ProductPartPriceEffects {

  
  productParts = createEffect(() => this.actions$.pipe(
    ofType(ProductPartPriceActionTypes.productPriceGetAll),
    mergeMap((action: GetProductPrices) =>
      this.productPartPriceService
        .getProductPartPrices(action.payload)
        .pipe(
          map(productPrices => {
            return new GetProductPricesSuccess(productPrices)
          }),
          catchError(error => of(new ProductPriceError(error)))
        )
    )));

  
  productPartsNew = createEffect(() => this.actions$.pipe(
    ofType(ProductPartPriceActionTypes.productPriceSave),
    mergeMap((action: SaveProductPrice) =>
      this.productPartPriceService
        .saveProductPrices(action.payload)
        .pipe(
          map(productPrices => {
            if (productPrices) {
              this.toastr.success('Product Part Prices Saved Successfully!', 'Successful!');
              this.router.navigateByUrl('/setup/priceconfiguration/parts');
              return new SaveProductPriceSuccess(productPrices);
            }
          }),
          catchError(error => of(new ProductPriceError(error)))
        )
    )));

  
  productPartsUpdate = createEffect(() => this.actions$.pipe(
    ofType(ProductPartPriceActionTypes.productPriceUpdate),
    mergeMap((action: UpdateProductPrice) =>
      this.productPartPriceService
        .updateProductPrices(action.payload)
        .pipe(
          map(productPrices => {
            if (productPrices) {
              this.toastr.success('Product Part Prices Updated Successfully!', 'Successful!');
              this.router.navigateByUrl('/setup/priceconfiguration/parts');
              return new UpdateProductPriceSuccess(productPrices);
            }
          }),
          catchError(error => of(new ProductPriceError(error)))
        )
    )));

  constructor(
    private actions$: Actions,
    private toastr: ToastrService,
    private router: Router,
    private productPartPriceService: ProductPartPriceService,
  ) {
  }
}
