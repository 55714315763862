import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

import {
  AddModule,
  AddModuleSuccess,
  DeleteModule,
  DeleteModuleSuccess,
  GetModuleById,
  GetModuleByIdSuccess,
  GetModulesSuccess,
  ModuleActionTypes,
  ModuleError,
  SearchModules,
  SearchModulesSuccess,
  UpdateModuleSuccess
} from '@appStore/actions/core-masterdata/module/module.actions';
import {ModuleService} from '@appServices/core-masterdata/module/module.service';

import * as fromRouterActions from '@appStore/actions/router.actions';

@Injectable()
export class ModuleEffects {
  constructor(private actions$: Actions, private moduleService: ModuleService) {}

  
  loadModules$ = createEffect(() => this.actions$.pipe(
    ofType(ModuleActionTypes.moduleGetModules),
    switchMap(() =>
      this.moduleService
        .getModules()
        .pipe(
          map(modules => new GetModulesSuccess(modules)),
          catchError(error => of(new ModuleError(error)))
        )
    )
  ));

  
  getModuleById$ = createEffect(() => this.actions$.pipe(
    ofType(ModuleActionTypes.moduleGetModuleById),
    mergeMap((action: GetModuleById) =>
      this.moduleService
        .getModule(action.payload)
        .pipe(
          map(module => new GetModuleByIdSuccess(module)),
          catchError(error => of(new ModuleError(error)))
        )
    )
  ));

  
  addModule$ = createEffect(() => this.actions$.pipe(
    ofType(ModuleActionTypes.moduleAddModule),
    switchMap((action: AddModule) =>
      this.moduleService
        .addModule(action.payload)
        .pipe(
          map(module => new AddModuleSuccess(module)),
          catchError(error => of(new ModuleError(error)))
        )
    )
  ));

  
  updateModule$ = createEffect(() => this.actions$.pipe(
    ofType(ModuleActionTypes.moduleUpdateModule),
    mergeMap((action: AddModule) =>
      this.moduleService
        .updateModule(action.payload)
        .pipe(
          map(module => new UpdateModuleSuccess(action.payload)),
          catchError(error => of(new ModuleError(error)))
        )
    )
  ));

  
  deleteModule$ = createEffect(() => this.actions$.pipe(
    ofType(ModuleActionTypes.moduleDeleteModule),
    mergeMap((action: DeleteModule) =>
      this.moduleService
        .deleteModule(action.payload)
        .pipe(
          map(() => new DeleteModuleSuccess(action.payload)),
          catchError(error => of(new ModuleError(error)))
        )
    )
  ));

  
  searchModules$ = createEffect(() => this.actions$.pipe(
    ofType(ModuleActionTypes.moduleSearchModules),
    mergeMap((action: SearchModules) =>
      this.moduleService
        .searchModules(action.payload)
        .pipe(
          map(modules => new SearchModulesSuccess(modules)),
          catchError(error => of(new ModuleError(error)))
        )
    )
  ));

  
  updateModuleSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(ModuleActionTypes.moduleUpdateModuleSuccess),
    map(module => new fromRouterActions.Go({path: ['/setup/moduleNames']}))
  ));
}
