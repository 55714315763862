import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';

import {CoreAftersalesService} from "../core-aftersales.service";
import {Ticket} from '../../../models/core-aftersales/ticket';
import { TicketStatus } from '@appModels/core-aftersales/ticketStatus';


@Injectable({providedIn: 'root'})
export class TicketService extends CoreAftersalesService {


  constructor(
    private http: HttpClient,
  ) {
    super(http, 'aftersalecustomerticket');
  }

  getTenantTickets(id: number): Observable<any> {

    const url = `${this.base_url}s/${id}`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched tickets id=${id}`)),
      catchError(this.handleError(`getTickets id=${id}`))
    );
  }

  getRepairCentreTickets(repairCentreId: number): Observable<any> {
    const url = `${this.base_url}s/repairCentre/${repairCentreId}`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched tickets id=${repairCentreId}`)),
      catchError(this.handleError(`getRepairCentreTickets id=${repairCentreId}`))
    );
  }

  getTicketsForAccountNumber(accountNumber: string): Observable<any> {
    const url = `${this.base_url}/accountNumber/${accountNumber}`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched tickets accountNumber=${accountNumber}`)),
      catchError(this.handleError(`getTicketsForAccountNumber id=${accountNumber}`))
    );
  }

  getTickets(accountNumber: string, contractProductId: Number): Observable<any> {
    const url = `${this.base_url}/search-using-or-condition?accountNumber=${accountNumber}&contractProductId=${contractProductId}`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched tickets`)),
      catchError(this.handleError(`getTickets`))
    );
  }

  filterTickets(query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        if (query[val] !== null && query[val] !== undefined && query[val] !== '')
          params = params.append(val, query[val]);
      });
    }

    console.log("filtering tickets");

    params = params.append('tenantId', localStorage.getItem('tenant'));
    return this.http.get<any>(this.base_url + 's', {params, headers: this.headers}).pipe(
      tap(customers => this.log(`fetched customers`))
      ,
      catchError(this.handleError<Ticket[]>('getSalesStaff')
      )
    );
  }

  generateTicketNumber(): Observable<any> {
    const url = `${this.base_url}/generateTicketNumber/${localStorage.getItem('tenant')}`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`generated ticket number`))
      ,
      catchError(this.handleError('getTickets id=${id}')
      )
    );
  }

  addTicket(entity: Ticket): Observable<Ticket> {
    console.log("adding ticket");
    return this.http.post<Ticket>(this.base_url + "/tenant/" + localStorage.getItem("tenant"), entity, {headers: this.headers}).pipe(
      tap(),
      catchError(this.handleError<Ticket>('addTicket'))
    );
  }

  getTicket(id: number): Observable<any> {
    const url = `${this.base_url}/${id}`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched ticket id=${id}`)),
      catchError(this.handleError(`getTicket id=${id}`))
    );
  }

  closeTicket(id: number, comment: string, dispositionId: number): Observable<any> {
    const url = `${this.base_url}/close/${id}`;
    return this.http.post(url, {closingComment: comment, dispositionId:dispositionId}, {headers: this.headers}).pipe(
      tap(_ => this.log(`closed ticket id=${id}`)),
      catchError(this.handleError(`closeTicket id=${id}`))
    );
  }


  getCustomerProducts(id: number): Observable<any> {
    const url = `${this.base_url}s/getCustomerProductsForTicket/customer/${id}`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched ticket id=${id}`)),
      catchError(this.handleError(`getTicket id=${id}`))
    );
  }

  getCustomerAccessories(id: number, accountNumber: string = null): Observable<any> {
    let url = `${this.base_url}s/getCustomerAccessoriesForTicket/customer/${id}`;
    if (accountNumber != null) {
      url = url.concat(`?accountNumber=${accountNumber}`);
    }
    return this.http.get(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched accessories id=${id}`)),
      catchError(this.handleError(`getCustomerAccessories id=${id}`))
    );
  }

  getCustomerProductByContractProductId(id: number): Observable<any> {
    const url = `${this.base_url}s/getCustomerProductByContractProductId/contractProductId/${id}`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched ticket id=${id}`)),
      catchError(this.handleError(`getTicket id=${id}`))
    );
  }

  calculateWarranty(id: number): Observable<any> {
    let tenantId = localStorage.getItem("tenant");
    const url = `${this.base_url}s/calculateWarranty/tenant/${tenantId}/subUnit/${id}`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched ticket id=${id}`)),
      catchError(this.handleError(`getTicket id=${id}`))
    );
  }

  getTicketsApplicableToGraceDays(query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        if (query[val] !== null && query[val] !== undefined && query[val] !== '')
          params = params.append(val, query[val]);
      });
    }

    const tenantId = localStorage.getItem('tenant');

    return this.http.get<any>(this.base_url + 's/gracecredits/' + tenantId, {params, headers: this.headers}).pipe(
      tap(customers => this.log(`fetched tickets`))
      ,
      catchError(this.handleError<Ticket[]>('get tickets for grace period')
      )
    );
  }

  saveGraceDaysEscalation(ticketId: number, numberOfDays: number): Observable<any> {
    const url = `${this.base_url}s/gracecredits//${ticketId}`;
    return this.http.post(url, numberOfDays, {headers: this.headers}).pipe(
      tap(_ => this.log(`saved grace days escalation id=${ticketId}`)),
      catchError(this.handleError(`saveGraceDaysEscalation id=${ticketId}`))
    );
  }

  getTicketClosingDispositions(): Observable<any> {
    const url = `${this.base_url}/dispositions`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched ticket `)),
      catchError(this.handleError(`getTicket `))
    );
  }

  getTicketStatuses(): Observable<TicketStatus[]> {
    const url = `${this.base_url}statuses/tenant/${+localStorage.getItem("tenant")}`;
    return this.http.get<TicketStatus[]>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched getTicketStatuses`)),
      catchError(this.handleError<TicketStatus[]>(`getTicketStatuses`))
    );
  }

  reassignTicket(ticketId: number, repairCentreId: number): Observable<any> {
    const url = `${this.base_url}/reassign/${ticketId}/repaircentre/${repairCentreId}`;
    return this.http.put(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`reassigned ticket id=${ticketId}`)),
      catchError(this.handleError(`reassingTicket id=${ticketId}`))
    );
  }

  getAccessoryPurchaseDate(globalProductVersionId, mainProductAccountNumber): Observable<any> {
    const url = `${this.base_url}s/getPurchaseDate/subUnit/${globalProductVersionId}?productAccountNumber=${mainProductAccountNumber}`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`getAccessoryPurchaseDate`)),
      catchError(this.handleError(`getAccessoryPurchaseDate`))
    );
  }

}
