import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getRepairCentreTechnicianStore = createFeatureSelector('repairCentreTechnician');

export const getRepairCentreTechnicianEntities = createSelector(
  getRepairCentreTechnicianStore,
  fromReducers.repairCentreTechnician.repairCentreTechnicianEntitySelectors.selectAll
);

export const getRepairCentreTechnicians = createSelector(getRepairCentreTechnicianEntities, entities => {
  return Object.values(entities);
});

export const getRepairCentreTechnicianSelectedId = createSelector(
  getRepairCentreTechnicianStore,
  (repairCentreTechnicianStore: fromReducers.repairCentreTechnician.State) => repairCentreTechnicianStore.selectedTechnicianId
);


export const getRepairCentreTechnicianById = createSelector(
  getRepairCentreTechnicianEntities,
  getRepairCentreTechnicianSelectedId,
  (entities, id) => entities.find(i => i.id === id)
);

export const getRepairCentreTechnicianPaging = createSelector(
  getRepairCentreTechnicianStore,
  (repairCentreTechnicianStore: fromReducers.repairCentreTechnician.State) => repairCentreTechnicianStore.paging
);

export const getRepairCentreTechniciansLoading = createSelector(
  getRepairCentreTechnicianStore,
  (repairCentreTechnicianStore: fromReducers.repairCentreTechnician.State) => repairCentreTechnicianStore.loading
);
