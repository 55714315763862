import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromReducers from '@appStore/reducers';

export const getUserStore = createFeatureSelector('user');

export const getUserEntities = createSelector(
  getUserStore,
  fromReducers.user.userEntitySelectors.selectAll
);

export const getUsers = createSelector(getUserEntities, entities => {
  return Object.values(entities);
});

export const getTopUsers = createSelector(getUserEntities, entities => {
  return Object.values(entities).slice(1, 5);
});

export const getUsersLoaded = createSelector(
  getUserStore,
  (userStore: fromReducers.user.State) => userStore.loaded
);

export const getUsersLoading = createSelector(
  getUserStore,
  (userStore: fromReducers.user.State) => userStore.loading
);

export const getSelectedUserId = createSelector(
  getUserStore,
  (userStore: fromReducers.user.State) => userStore.selectedUserId
);

export const getSearchUsers = createSelector(
  getUserStore,
  (userStore: fromReducers.user.State) => userStore.searchUsers
);

export const getUserById = createSelector(
  getUserEntities,
  getSelectedUserId,
  (entities, id) => entities.find(i => i.id === id)
);

export const getUsersError = createSelector(
  getUserStore,
  (userStore: fromReducers.user.State) => userStore.error
);
