import { Action } from '@ngrx/store';
import {
  EntityAdapter,
  createEntityAdapter,
  EntityState,
  Update
} from '@ngrx/entity';

import { SmsProvider } from '@appModels/core-messaging/sms-provider/sms-provider';
import { SmsProviderActions, SmsProviderActionTypes } from '@appStore/actions/core-messaging/sms-provider/sms-provider.actions';

export interface State extends EntityState<SmsProvider> {
  loaded: boolean;
  loading: boolean;
  error: any;
  selectedSmsProviderId: number;
  searchSmsProviders: SmsProvider[];
}

export const adapter: EntityAdapter<SmsProvider> = createEntityAdapter<SmsProvider>();

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
  selectedSmsProviderId: null,
  error: null,
  searchTerm: '',
  searchSmsProviders: null
});

export function reducer(state = initialState, action: SmsProviderActions): State {
  switch (action.type) {
    case SmsProviderActionTypes.smsProviderGetSmsProviders:
    case SmsProviderActionTypes.smsProviderAddSmsProvider:
    case SmsProviderActionTypes.smsProviderAddAdditionalSmsProviderInfo:
    case SmsProviderActionTypes.smsProviderDeleteSmsProvider:
    case SmsProviderActionTypes.smsProviderUpdateSmsProvider:
    case SmsProviderActionTypes.smsProviderSearchSmsProviders:
    case SmsProviderActionTypes.smsProviderGetSmsProviderById:
      return {
        ...state,
        loading: true
      };

    case SmsProviderActionTypes.smsProviderGetSmsProvidersSuccess:
      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });

    case SmsProviderActionTypes.smsProviderGetSmsProviderByIdSuccess:
      return { ...state, selectedSmsProviderId: action.payload.id, loading: false };

    case SmsProviderActionTypes.smsProviderAddSmsProviderSuccess:
      return adapter.addOne(action.payload, {
        ...state,
        selectedSmsProviderId: action.payload.id,
        loading: false,
        loaded: true
      });

    case SmsProviderActionTypes.smsProviderUpdateSmsProviderSuccess: {
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state,
          loading: false,
          loaded: true
        }
      );
    }

    case SmsProviderActionTypes.smsProviderDeleteSmsProviderSuccess: {
      return adapter.removeOne(action.payload.id, {
        ...state,
        loading: false,
        loaded: true
      });
    }

    case SmsProviderActionTypes.smsProviderSearchSmsProvidersSuccess:
      return {
        ...state,
        searchSmsProviders: action.payload,
        loading: false
      };

    case SmsProviderActionTypes.smsProviderSearchSmsProvidersReset:
      return {
        ...state,
        searchSmsProviders: null
      };

    case SmsProviderActionTypes.smsProviderError:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload
      };

    default:
      return state;
  }
}

export const smsProviderEntitySelectors = adapter.getSelectors();
