import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import {Store} from '@ngrx/store';

import {Observable, of} from 'rxjs';
import {catchError, mergeMap} from 'rxjs/operators';

import * as fromReducers from '@appStore/reducers';
import * as fromSelectors from '@appStore/selectors';
import {GetModules} from '@appStore/actions/core-masterdata/module/module.actions';

@Injectable({ providedIn: 'root' })
export class ModulesGuard  {
  constructor(
    private store: Store<fromReducers.module.State>
    ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.checkStore().pipe(
      mergeMap(() => {
        return of(true);
      }),
      catchError(() => of(false))
    );
  }

  checkStore(): Observable<boolean> {
    this.store.select(fromSelectors.getModulesLoaded).subscribe(loaded => {
      let tenant = localStorage.getItem('tenant')
      if (!loaded && tenant !== null ) {
        this.store.dispatch(new GetModules());
      }
    });
    return of(true);
  }
}
