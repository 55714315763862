import { featureFlagsFieldForce } from './feature-flags/feature-flags-fieldforce';
import { featureFlagsCRM } from "./feature-flags/feature-flags-crm";
import { featureFlagsSetup } from './feature-flags/feature-flags-setup';
import { featureFlagsGlobalSettings } from './feature-flags/feature-flags-global-settings';
import { featureFlagsInventory } from './feature-flags/feature-flags-inventory';
import { featureFlagsAfterSales } from './feature-flags/feature-flags-aftersales';
import { featureFlagsCommunication } from './feature-flags/feature-flags-communication';
import { featureFlagsFinance } from './feature-flags/feature-flags-finance';
import { featureFlagsIdentityManagement } from './feature-flags/feature-flags-identity-management';
import { featureFlagsKnowledgeBase } from './feature-flags/feature-flags-knowledge-base';
import { featureFlagsReporting } from './feature-flags/feature-flags-reporting';
import { featureFlagsWarrantyAndService } from './feature-flags/feature-flags-warranty-and-service';

export const featureFlags:Record<string, boolean> = {
  ...featureFlagsCRM, 
  ...featureFlagsFieldForce, 
  ...featureFlagsSetup, 
  ...featureFlagsGlobalSettings,
  ...featureFlagsInventory,
  ...featureFlagsAfterSales,
  ...featureFlagsCommunication,
  ...featureFlagsFinance,
  ...featureFlagsIdentityManagement,
  ...featureFlagsKnowledgeBase,
  ...featureFlagsReporting,
  ...featureFlagsWarrantyAndService,
}

